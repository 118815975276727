import { TbBuildingWarehouse, TbTransfer } from 'react-icons/tb';

import { GiJeep } from 'react-icons/gi';
import { MdConnectingAirports } from 'react-icons/md';
import { PiPark } from 'react-icons/pi';
import { getPropObj } from './generalFunctions';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

function getLabelForFinalValue(value) {
  switch (value) {
    case 'accommodation':
      return 'Accomm';
    case 'parkFees':
      return 'Park Fees';
    case 'discount':
      return 'Discount';
    case 'extraMeals':
      return 'Extra Meals';
    case 'extra':
      return 'Extra';
    case 'misc':
      return 'Misc';
    default:
      return 'Select';
  }
}

function getLabelForFinalTransferValue(value) {
  switch (value) {
    case 'extra':
      return 'Extra';
    case 'flight':
      return 'Flight';
    case 'misc':
      return 'Misc';
    case 'ownArrangements':
      return 'Own Arrangements';
    case 'roadTransfer':
      return 'Road Transfer';
    default:
      return 'Select';
  }
}

const toggleFinalTransferCollapseOld = (tuuid, finalPageTransferData, resetFinalInputs, setFinalPageTransferData, setFinalInputs) => {
  const updatedFinalPageTransferData = finalPageTransferData.map((item) => {
    if (item.tuuid === tuuid) {
      return {
        ...item,
        collapsed: !item.collapsed,
        addTransferItem: false,
        editTransferItem: false,
      }; // toggle collapsed and set addPropertyItem to false
    } else {
      return item;
    }
  });
  if (!resetFinalInputs) {
    resetFinalInputs(setFinalInputs);
  }

  setFinalPageTransferData(updatedFinalPageTransferData);
};

const toggleFinalTransferCollapse = (tuuid, finalPageTransferData, resetFinalInputs, setFinalPageTransferData, setFinalInputs) => {
  const updatedFinalPageTransferData = finalPageTransferData.map((item) => {
    if (item.tuuid === tuuid) {
      return {
        ...item,
        collapsed: !item.collapsed,
        addTransferItem: false,
        editTransferItem: false,
      }; // toggle collapsed and set addPropertyItem to false
    } else {
      return item;
    }
  });

  if (typeof resetFinalInputs === 'function') {
    resetFinalInputs(setFinalInputs);
  }

  setFinalPageTransferData(updatedFinalPageTransferData);
};

const toggleFinalCollapse = (uuid, finalPageData, resetFinalInputs, setFinalPageData, setFinalInputs) => {
  const updatedFinalPageData = finalPageData.map((item) => {
    if (item.uuid === uuid) {
      return {
        ...item,
        collapsed: !item.collapsed,
        addPropertyItem: false,
        editPropertyItem: false,
      }; // toggle collapsed and set addPropertyItem to false
    } else {
      return item;
    }
  });
  if (typeof resetFinalInputs === 'function') {
    resetFinalInputs(setFinalInputs);
  }

  setFinalPageData(updatedFinalPageData);
};

function countNorthernGuideNights(bookings) {
  // Initialize a variable to keep track of the total number of days
  let totalDays = 0;

  // Iterate through each booking
  bookings.forEach((booking) => {
    // Filter the rooms that are of vehicleType 'northernGuide'
    const northernGuideRooms = booking.rooms.filter((room) => room.vehicleType === 'northernGuide');

    // Count the rooms and multiply by the number of days for the booking
    const northernGuideDays = northernGuideRooms.length * (booking.days + 0);

    // Add the result to the total days
    totalDays += northernGuideDays;
  });

  // Return the total number of days
  return totalDays;
}

function getOtherArrangementsByType(ownArrangementsData, type) {
  // Find the object with the matching type
  const matchingObject = ownArrangementsData.find((arrangement) => arrangement.type === type);

  // If there is no matching object, return null or an appropriate message
  return matchingObject || false;
}

function categorizeChargeType(price) {
  //const id = price.chargetype.id;
  const id = price.chargetype && price.chargetype.id ? price.chargetype.id : 0;

  switch (id) {
    case 2:
    case 18:
    case 19:
    case 20:
      return 'accommodation';
    case 6:
    case 21:
      return 'parkFees';
    default:
      return 'extra';
  }
}

function categorizeChargeTypeGetUid(price) {
  //const id = price.chargetype.id;
  const id = price.chargetype && price.chargetype.id ? price.chargetype.id : 0;

  switch (id) {
    case 2:
    case 18:
    case 19:
    case 20:
      return '64a1caf7-e922-4629-b90b-164809033192'; // 3rd Party Accommodation
    case 6:
    case 21:
      return '3483e4ef-046b-4233-afdc-4a1e0902729d'; // Park Fees
    default:
      return 'c536f381-457d-45d1-a503-75c370798f27'; // Activities & Extras
  }
}

function categorizeChargeTypeId(price) {
  //const id = price.chargetype.id;
  const id = price.chargetype && price.chargetype.id ? price.chargetype.id : 0;

  return id;
}

const handleAddFinalTransferItem = (tuuid, transferSupplierUid, setFinalPageTransferData, resetFinalTransferInputs, resetEditTransferItem) => {
  // Initialize your new finalInputs
  resetFinalTransferInputs(transferSupplierUid);
  resetEditTransferItem();

  // Iterate over your finalPageData array and update addPropertyItem field
  setFinalPageTransferData((prevData) =>
    prevData.map((item) => {
      if (item.tuuid === tuuid) {
        return { ...item, addTransferItem: true };
      } else {
        return { ...item, addTransferItem: false };
      }
    }),
  );
};

const editFinalInputTransferItem = (parentUuid, uuid, finalPageTransferData, setFinalTransferInputs, setFinalPageTransferData) => {
  const updatedPageData = finalPageTransferData.map((data) => {
    if (data.uuid === parentUuid) {
      const transferItem = data.transferItems.find((item) => item.uuid === uuid);
      if (transferItem) {
        setFinalTransferInputs((inputs) => ({
          ...inputs,
          feeType: transferItem.feeType,
          feeTypeUid: transferItem.feeTypeUid,
          feeTypeName: transferItem.feeTypeName,
          supplier: transferItem.supplier,
          description: transferItem.description,
          units: transferItem.units,
          pax: transferItem.pax,
          net: transferItem.net,
          total: transferItem.total,
          uuid: transferItem.uuid,
          edit: transferItem.edit, // Ensure that "edit" exists in propertyItem, or replace it with the appropriate value
          star: transferItem.star, // Ensure that "star" exists in propertyItem, or replace it with the appropriate value
        }));

        return {
          ...data,
          editTransferItem: uuid,
          addTransferItem: false,
        };
      }
    }
    return data;
  });

  setFinalPageTransferData(updatedPageData);
};

const deleteFinalInputTransferItem = (parentUuid, uuid, finalPageTransferData, setFinalPageTransferData) => {
  const updatedPageData = finalPageTransferData.map((data) => {
    if (data.uuid === parentUuid) {
      return {
        ...data,
        transferItems: data.transferItems.filter((item) => item.uuid !== uuid),
      };
    }
    return data;
  });

  // Now updatedPageData has the data without the specific item.
  // You should update your state with this new data.
  setFinalPageTransferData(updatedPageData);
};

const saveFinalInputDataTransferEdit = (parentUuid, itemUuid, finalPageTransferData, finalTransferInputs, setFinalPageTransferData, resetFinalTransferInputs) => {
  const saveData = () => {
    const updatedPageData = finalPageTransferData.map((data) => {
      if (data.uuid === parentUuid) {
        return {
          ...data,
          transferItems: data.transferItems.map((item) => {
            if (item.uuid === itemUuid) {
              const total = finalTransferInputs.units * finalTransferInputs.pax * finalTransferInputs.net;
              return {
                ...finalTransferInputs,
                total: total,
                uuid: itemUuid, // Ensure that the uuid stays the same
                edit: item.edit, // Ensure that edit stays the same or provide appropriate value
                parentUuid: parentUuid,
              };
            }
            return item;
          }),
          editTransferItem: false, // Reset the editPropertyItem if needed
        };
      }
      return data;
    });

    resetFinalTransferInputs();

    setFinalPageTransferData(updatedPageData);
  };
  if (finalTransferInputs.supplier === '') {
    return toast.error('You must select a "SUPPLIER"');
  }
  console.log('finalInputs.feeType; ', finalTransferInputs.feeType);
  if (finalTransferInputs.feeTypeUid === '') {
    return toast.error("Please select a 'FEE TYPE'");
  } else if (finalTransferInputs.net > 0) {
    if (finalTransferInputs.units === 0) {
      return toast.error("Please enter a value for 'NTS/UNITS'");
    } else if (finalTransferInputs.pax === 0) {
      return toast.error("Please enter a value for 'PAX/UNITS'");
    }
    saveData();
  } else {
    saveData();
  }
};

const cancelFinalInputDataTransferEdit = (parentUuid, itemUuid, finalPageTransferData, resetFinalInputs, setFinalPageTransferData, setFinalInputs) => {
  const updatedPageData = finalPageTransferData.map((data) => {
    if (data.uuid === parentUuid) {
      const transferItem = data.transferItems.find((item) => item.uuid === itemUuid);
      if (transferItem) {
        return {
          ...data,
          editTransferItem: false,
        };
      }
    }
    return data;
  });

  resetFinalInputs(setFinalInputs);

  setFinalPageTransferData(updatedPageData);
};

const saveFinalInputTransferData = (tuuid, finalTransferInputs, setFinalPageTransferData, finalPageTransferData, resetFinalTransferInputs, transferSupplier) => {
  const supplier = finalTransferInputs.supplier === '' ? transferSupplier : finalTransferInputs.supplier;
  const saveData = () => {
    const total = finalTransferInputs.units * finalTransferInputs.pax * finalTransferInputs.net;
    setFinalPageTransferData(
      finalPageTransferData.map((item) => {
        if (item.tuuid === tuuid) {
          return {
            ...item,
            transferItems: [...(item.transferItems || []), { ...finalTransferInputs, total: total, uuid: uuidv4(), supplier: supplier, parentUuid: tuuid }],
          };
        }
        return item;
      }),
    );
    // Clear the inputs
    resetFinalTransferInputs();
  };
  // if (finalTransferInputs.supplier === '') {
  if (supplier === '' || supplier === undefined) {
    return toast.error('You must select a "SUPPLIER"');
  }
  console.log('finalInputs.feeType; ', finalTransferInputs.feeType);
  if (finalTransferInputs.feeTypeUid === '') {
    return toast.error("Please select a 'FEE TYPE'");
  } else if (finalTransferInputs.net > 0) {
    if (finalTransferInputs.units === 0) {
      return toast.error("Please enter a value for 'NTS/UNITS'");
    } else if (finalTransferInputs.pax === 0) {
      return toast.error("Please enter a value for 'PAX/UNITS'");
    }
    saveData();
  } else {
    saveData();
  }
};

const cancelFinalInputTransferData = (finalPageTransferData, setFinalPageTransferData) => {
  const updatedPageTransferData = finalPageTransferData.map((data) => {
    return {
      ...data,
      addTransferItem: false,
    };
  });
  //console.log("updatedPageData", updatedPageData);

  setFinalPageTransferData(updatedPageTransferData);
};

// const resetEditTransferItem = (finalPageTransferData, setFinalPageTransferData) => {
//   const updatedPageTransferData = finalPageTransferData.map((data) => {
//     return {
//       ...data,
//       editTransferItem: false,
//     };
//   });
//   //console.log("updatedPageData", updatedPageData);

//   setFinalPageTransferData(updatedPageTransferData);
// };

const editFinalInputItem = (parentUuid, itemUuid, finalPageData, setFinalInputs, setFinalPageData) => {
  const updatedPageData = finalPageData.map((data) => {
    if (data.uuid === parentUuid) {
      const propertyItem = data.propertyItems.find((item) => item.uuid === itemUuid);
      if (propertyItem) {
        setFinalInputs((inputs) => ({
          ...inputs,
          feeType: propertyItem.feeType,
          feeTypeUid: propertyItem.feeTypeUid,
          feeTypeName: propertyItem.feeTypeName,
          supplier: propertyItem.supplier,
          description: propertyItem.description,
          units: propertyItem.units,
          pax: propertyItem.pax,
          net: propertyItem.net,
          total: propertyItem.total,
          uuid: propertyItem.uuid,
          edit: propertyItem.edit, // Ensure that "edit" exists in propertyItem, or replace it with the appropriate value
          star: propertyItem.star, // Ensure that "star" exists in propertyItem, or replace it with the appropriate value
        }));

        return {
          ...data,
          editPropertyItem: itemUuid,
          addPropertyItem: false,
        };
      }
    }
    return data;
  });

  setFinalPageData(updatedPageData);
};

const deleteFinalInputItem = (parentUuid, itemUuid, finalPageData, setFinalPageData) => {
  const updatedPageData = finalPageData.map((data) => {
    if (data.uuid === parentUuid) {
      return {
        ...data,
        propertyItems: data.propertyItems.filter((item) => item.uuid !== itemUuid),
      };
    }
    return data;
  });

  // Now updatedPageData has the data without the specific item.
  // You should update your state with this new data.
  setFinalPageData(updatedPageData);
};

const saveFinalInputDataEdit = (parentUuid, itemUuid, finalPageData, setFinalPageData, finalInputs, resetFinalInputs, setFinalInputs) => {
  const saveData = () => {
    const updatedPageData = finalPageData.map((data) => {
      if (data.uuid === parentUuid) {
        return {
          ...data,
          propertyItems: data.propertyItems.map((item) => {
            if (item.uuid === itemUuid) {
              const total = finalInputs.units * finalInputs.pax * finalInputs.net;
              return {
                ...finalInputs,
                total: total,
                uuid: itemUuid, // Ensure that the uuid stays the same
                edit: item.edit, // Ensure that edit stays the same or provide appropriate value
                parentUuid: parentUuid,
              };
            }
            return item;
          }),
          editPropertyItem: false, // Reset the editPropertyItem if needed
        };
      }
      return data;
    });

    resetFinalInputs(setFinalInputs);

    setFinalPageData(updatedPageData);
  };
  if (finalInputs.feeTypeUid === '') {
    return toast.error("Please select a 'FEE TYPE'");
  } else if (finalInputs.supplier === '' || finalInputs.supplier === undefined) {
    return toast.error("Please enter a 'SUPPLIER'");
  } else if (finalInputs.net > 0) {
    if (finalInputs.pax === 0) {
      return toast.error("Please enter a value for 'PAX/UNITS'");
    } else if (finalInputs.units === 0) {
      return toast.error("Please enter a 'NTS/UNITS'");
    }
    saveData();
  } else {
    saveData();
  }
};

const cancelFinalInputDataEdit = (parentUuid, itemUuid, finalPageData, setFinalPageData, resetFinalInputs, setFinalInputs) => {
  const updatedPageData = finalPageData.map((data) => {
    if (data.uuid === parentUuid) {
      const propertyItem = data.propertyItems.find((item) => item.uuid === itemUuid);
      if (propertyItem) {
        return {
          ...data,
          editPropertyItem: false,
        };
      }
    }
    return data;
  });

  resetFinalInputs(setFinalInputs);

  setFinalPageData(updatedPageData);
};

const handleAddFinalPropertyItem = (uuid, propertySupplierUid, resetFinalInputs, resetEditPropertyItem, finalPageData, setFinalPageData, setFinalInputs) => {
  // Initialize your new finalInputs
  resetFinalInputs(setFinalInputs);
  resetEditPropertyItem(finalPageData, setFinalPageData);

  // Iterate over your finalPageData array and update addPropertyItem field
  setFinalPageData((prevData) =>
    prevData.map((item) => {
      if (item.uuid === uuid) {
        return { ...item, addPropertyItem: true };
      } else {
        return { ...item, addPropertyItem: false };
      }
    }),
  );

  // set supplier.
  setFinalInputs((prevInputs) => ({
    ...prevInputs,
    supplier: propertySupplierUid,
  }));
};

const resetEditPropertyItem = (finalPageData, setFinalPageData) => {
  const updatedPageData = finalPageData.map((data) => {
    return {
      ...data,
      editPropertyItem: false,
    };
  });
  //console.log("updatedPageData", updatedPageData);

  setFinalPageData(updatedPageData);
};

const saveFinalInputData = (uuid, finalInputs, finalPageData, setFinalPageData, resetFinalInputs, setFinalInputs, itemSupplier) => {
  const supplier = finalInputs.supplier === '' ? itemSupplier : finalInputs.supplier;
  const saveData = () => {
    const total = finalInputs.units * finalInputs.pax * finalInputs.net;

    setFinalPageData(
      finalPageData.map((item) => {
        if (item.uuid === uuid) {
          return {
            ...item,
            propertyItems: [...(item.propertyItems || []), { ...finalInputs, total: total, uuid: uuidv4(), supplier: supplier, parentUuid: uuid }],
          };
        }
        return item;
      }),
    );
    // Clear the inputs
    resetFinalInputs(setFinalInputs);
  };
  console.log('finalInputs.feeType; ', finalInputs.feeType);
  if (finalInputs.feeTypeUid === '') {
    return toast.error("Please select a 'FEE TYPE'");
    // } else if (finalInputs.supplier === '' || finalInputs.supplier === undefined) {
  } else if (supplier === '' || supplier === undefined) {
    return toast.error("Please enter a 'SUPPLIER'");
  } else if (finalInputs.net > 0) {
    if (finalInputs.pax === 0) {
      return toast.error("Please enter a value for 'PAX/UNITS'");
    } else if (finalInputs.units === 0) {
      return toast.error("Please enter a 'NTS/UNITS'");
    }
    saveData();
  } else {
    saveData();
  }
};

const cancelFinalInputData = (finalPageData, setFinalPageData) => {
  const updatedPageData = finalPageData.map((data) => {
    return {
      ...data,
      addPropertyItem: false,
    };
  });
  //console.log("updatedPageData", updatedPageData);

  setFinalPageData(updatedPageData);
};

const resetFinalInputs = (setFinalInputs) => {
  setFinalInputs((inputs) => ({
    ...inputs,
    feeType: '',
    feeTypeUid: '',
    feeTypeName: '',
    supplier: '',
    description: '',
    units: 0,
    pax: 0,
    net: 0,
    total: 0,
    uuid: uuidv4(),
    edit: true,
    star: true,
  }));
};

const moveTransferItemUp = (tuuid, transferItemUuid, finalPageTransferData, setFinalPageTransferData) => {
  console.log('Attempting to move up:', { tuuid, transferItemUuid });

  setFinalPageTransferData((prevData) => {
    // Map through the previous data to find and update the relevant transfer
    const updatedData = prevData.map((transfer) => {
      // Match the transfer by tuuid
      if (transfer.tuuid === tuuid) {
        const currentIndex = transfer.transferItems.findIndex((item) => item.uuid === transferItemUuid);

        console.log('Current Index:', currentIndex);

        // Ensure we're not attempting to move the first item and the item above is not being edited
        if (currentIndex > 0 && transfer.transferItems[currentIndex - 1].edit === true) {
          let newTransferItems = [...transfer.transferItems];
          // Swap the current item with the one above it
          [newTransferItems[currentIndex - 1], newTransferItems[currentIndex]] = [newTransferItems[currentIndex], newTransferItems[currentIndex - 1]];

          console.log(
            'After swap:',
            newTransferItems.map((item) => item.uuid),
          ); // Verify the new order

          // Return the transfer with the updated transferItems
          return { ...transfer, transferItems: newTransferItems };
        } else {
          console.log('No swap due to position or edit status of the item above.');
        }
      }
      return transfer;
    });

    console.log(
      'Updated Data:',
      updatedData.map((transfer) => transfer.transferItems.map((item) => item.uuid)),
    );
    return updatedData;
  });
};

const moveTransferItemDown = (tuuid, transferItemUuid, finalPageTransferData, setFinalPageTransferData) => {
  console.log('Attempting to move down:', { tuuid, transferItemUuid });

  setFinalPageTransferData((prevData) => {
    const updatedData = prevData.map((transfer) => {
      if (transfer.tuuid === tuuid) {
        const currentIndex = transfer.transferItems.findIndex((item) => item.uuid === transferItemUuid);
        console.log('Current Index:', currentIndex);

        // Check we're not attempting to move the last item
        if (currentIndex >= 0 && currentIndex < transfer.transferItems.length - 1) {
          let newTransferItems = [...transfer.transferItems];
          // Swap the current item with the one below it
          [newTransferItems[currentIndex], newTransferItems[currentIndex + 1]] = [newTransferItems[currentIndex + 1], newTransferItems[currentIndex]];

          console.log(
            'After swap:',
            newTransferItems.map((item) => item.uuid),
          ); // Verify the new order

          return { ...transfer, transferItems: newTransferItems };
        } else {
          console.log('No swap due to position or edit status of the item below.');
        }
      }
      return transfer;
    });

    console.log(
      'Updated Data:',
      updatedData.map((transfer) => transfer.transferItems.map((item) => item.uuid)),
    );
    return updatedData;
  });
};

const moveItemUp = (uuid, itemUuid, finalPageData, setFinalPageData) => {
  console.log('Attempting to move up:', { uuid, itemUuid });

  setFinalPageData((prevData) => {
    const updatedData = prevData.map((item) => {
      // Match the item by uuid
      if (item.uuid === uuid) {
        const currentIndex = item.propertyItems.findIndex((propertyItem) => propertyItem.uuid === itemUuid);
        console.log('Current Index:', currentIndex);

        // Ensure we're not attempting to move the first item
        if (currentIndex > 0 && item.propertyItems[currentIndex - 1].edit === true) {
          let newPropertyItems = [...item.propertyItems];
          // Swap the current item with the one above it
          [newPropertyItems[currentIndex - 1], newPropertyItems[currentIndex]] = [newPropertyItems[currentIndex], newPropertyItems[currentIndex - 1]];

          console.log(
            'After swap:',
            newPropertyItems.map((propertyItem) => propertyItem.uuid),
          ); // Verify the new order

          // Return the item with the updated propertyItems
          return { ...item, propertyItems: newPropertyItems };
        } else {
          console.log('No swap due to position or other constraints.');
        }
      }
      return item;
    });

    console.log(
      'Updated Data:',
      updatedData.map((item) => item.propertyItems.map((propertyItem) => propertyItem.uuid)),
    );
    return updatedData;
  });
};

const moveItemDown = (uuid, itemUuid, finalPageData, setFinalPageData) => {
  console.log('Attempting to move down:', { uuid, itemUuid });

  setFinalPageData((prevData) => {
    const updatedData = prevData.map((item) => {
      // Match the item by uuid
      if (item.uuid === uuid) {
        const currentIndex = item.propertyItems.findIndex((propertyItem) => propertyItem.uuid === itemUuid);
        console.log('Current Index:', currentIndex);

        // Check we're not attempting to move the last item
        if (currentIndex >= 0 && currentIndex < item.propertyItems.length - 1) {
          let newPropertyItems = [...item.propertyItems];
          // Swap the current item with the one below it
          [newPropertyItems[currentIndex], newPropertyItems[currentIndex + 1]] = [newPropertyItems[currentIndex + 1], newPropertyItems[currentIndex]];

          console.log(
            'After swap:',
            newPropertyItems.map((propertyItem) => propertyItem.uuid),
          ); // Verify the new order

          // Return the item with the updated propertyItems
          return { ...item, propertyItems: newPropertyItems };
        } else {
          console.log('No swap due to position or other constraints.');
        }
      }
      return item;
    });

    console.log(
      'Updated Data:',
      updatedData.map((item) => item.propertyItems.map((propertyItem) => propertyItem.uuid)),
    );
    return updatedData;
  });
};

const feeTypeOptions = [
  { value: '02f2a5eb-d622-43b3-abab-2fdfd8a02d67', label: 'Cars & Guides' },
  { value: '3483e4ef-046b-4233-afdc-4a1e0902729d', label: 'Park Fees' },
  { value: '537f3a40-d0dd-43d3-835a-8496f7cbeb77', label: 'Flights' },
  { value: '64a1caf7-e922-4629-b90b-164809033192', label: '3rd Party Accommodation' },
  { value: 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add', label: 'Transfers' },
  { value: 'c536f381-457d-45d1-a503-75c370798f27', label: 'Activities & Extras' },
  { value: 'e52813a4-3d2b-4f1a-acff-4c1c7b5ae158', label: 'Nomad Accommodation' },
];

function getLabelForFinalValues(value) {
  const option = feeTypeOptions.find((option) => option.value === value);
  return option ? option.label : 'Unknown'; // Returns 'Unknown' if no matching value is found
}

const feeTypeShortenedOptions = [
  { value: '02f2a5eb-d622-43b3-abab-2fdfd8a02d67', shortLabel: 'Cars & Guides' },
  { value: '3483e4ef-046b-4233-afdc-4a1e0902729d', shortLabel: 'Park Fees' },
  { value: '537f3a40-d0dd-43d3-835a-8496f7cbeb77', shortLabel: 'Flights' },
  { value: '64a1caf7-e922-4629-b90b-164809033192', shortLabel: '3rd Party Accomm' },
  { value: 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add', shortLabel: 'Transfers' },
  { value: 'c536f381-457d-45d1-a503-75c370798f27', shortLabel: 'Activities & Extras' },
  { value: 'e52813a4-3d2b-4f1a-acff-4c1c7b5ae158', shortLabel: 'Nomad Accomm' },
];

function getShortenedLabelForValue(value) {
  const option = feeTypeShortenedOptions.find((option) => option.value === value);
  return option ? option.shortLabel : 'Unknown'; // Return 'Unknown' if no matching value is found
}

function getLabelForFinalRename(feeType, nomadBooking) {
  const mapping = {
    accommodation: nomadBooking ? { value: 'e52813a4-3d2b-4f1a-acff-4c1c7b5ae158', label: 'Nomad Accommodation' } : { value: '64a1caf7-e922-4629-b90b-164809033192', label: '3rd Party Accommodation' },
    parkFees: { value: '3483e4ef-046b-4233-afdc-4a1e0902729d', label: 'Park Fees' },
    discount: { value: 'c536f381-457d-45d1-a503-75c370798f27', label: 'Activities & Extras' },
    extraMeals: { value: 'c536f381-457d-45d1-a503-75c370798f27', label: 'Activities & Extras' },
    extra: { value: 'c536f381-457d-45d1-a503-75c370798f27', label: 'Activities & Extras' },
    misc: { value: 'c536f381-457d-45d1-a503-75c370798f27', label: 'Activities & Extras' },
    flight: { value: '537f3a40-d0dd-43d3-835a-8496f7cbeb77', label: 'Flights' },
    ownArrangements: { value: 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add', label: 'Transfers' },
    roadTransfer: { value: 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add', label: 'Transfers' },
  };

  return mapping[feeType] || { value: 'c536f381-457d-45d1-a503-75c370798f27', label: 'Activities & Extras' };
}

function processFeeTypes(data, type, properties) {
  // Iterating over the main data array
  data.forEach((item) => {
    let itemsArray = []; // Hold either propertyItems or transferItems

    let nomadBooking = properties[getPropObj(item.propUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' ? true : false;

    if (type === 'properties' && item.propertyItems) {
      itemsArray = item.propertyItems;
    } else if (type === 'transfers' && item.transferItems) {
      itemsArray = item.transferItems;
    }

    // Process each item in the itemsArray
    itemsArray.forEach((subItem) => {
      // Skip processing if feeType is an empty string
      if (subItem.feeType !== '') {
        const labelInfo = getLabelForFinalRename(subItem.feeType, nomadBooking); // Process the feeType through function
        // Add the processed info to the subItem
        subItem.feeTypeUid = labelInfo.value;
        subItem.feeTypeName = labelInfo.label;
      }
    });
  });

  return data; // Return the processed data
}

// Mapping of feeTypeUids to Icon components
const iconMap = {
  '02f2a5eb-d622-43b3-abab-2fdfd8a02d67': <GiJeep size={30} />, // Cars & Guides
  '3483e4ef-046b-4233-afdc-4a1e0902729d': <PiPark size={30} />, // Park Fees
  '537f3a40-d0dd-43d3-835a-8496f7cbeb77': <MdConnectingAirports size={30} />, // Flights
  '64a1caf7-e922-4629-b90b-164809033192': <TbBuildingWarehouse size={30} />, // 3rd Party Accommodation
  'c4bce0fd-55a8-43f8-b5b1-bcf406c81add': <GiJeep size={30} />, // Transfers
  'c536f381-457d-45d1-a503-75c370798f27': <PiPark size={30} />, // Activities & Extras
  'e52813a4-3d2b-4f1a-acff-4c1c7b5ae158': <TbBuildingWarehouse size={30} />, // Nomad Accommodation
};

function updatePropertyData(finalPageData, setFinalPageData) {
  console.log('finalPageData - updatePropertyData:', JSON.stringify(finalPageData, null, 2));
  const updatedData = finalPageData.map((pageItem) => ({
    ...pageItem,
    propertyItems: pageItem.propertyItems.map((propertyItem) => {
      // Check if propertyItem is null or undefined before proceeding
      if (!propertyItem) return null; // or you could return propertyItem to keep the original item

      // Apply logic only if edit is true and feeTypeUid does not exist or is empty
      if (propertyItem.edit === true && (!propertyItem.feeTypeUid || propertyItem.feeTypeUid === '')) {
        let feeTypeUpdate = {};
        if (propertyItem.feeType === 'accommodation') {
          if (propertyItem.supplier === 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
            // Nomad
            feeTypeUpdate = {
              feeTypeUid: 'e52813a4-3d2b-4f1a-acff-4c1c7b5ae158',
              feeTypeName: 'Nomad Accommodation',
            };
          } else {
            feeTypeUpdate = {
              feeTypeUid: '64a1caf7-e922-4629-b90b-164809033192',
              feeTypeName: '3rd Party Accomm',
            };
          }
        } else if (propertyItem.feeType === 'parkFees') {
          feeTypeUpdate = {
            feeTypeUid: '3483e4ef-046b-4233-afdc-4a1e0902729d',
            feeTypeName: 'Park Fees',
          };
        } else {
          feeTypeUpdate = {
            feeTypeUid: 'c536f381-457d-45d1-a503-75c370798f27',
            feeTypeName: 'Activities & Extras',
          };
        }
        return { ...propertyItem, ...feeTypeUpdate };
      }
      // Return the item unchanged if the condition doesn't match
      return propertyItem;
    }),
    // .filter((item) => item !== null), // Add this line if you want to remove null items from the array
  }));

  // Update the state with the modified data
  setFinalPageData(updatedData);
}

const updateTransferData = (finalPageTransferData, setFinalPageTransferData) => {
  const updatedData = finalPageTransferData.map((dataItem) => ({
    ...dataItem,
    transferItems: dataItem.transferItems.map((transferItem) => {
      // Check if feeTypeUid does not exist or is empty, and edit is false
      if ((!transferItem.feeTypeUid || transferItem.feeTypeUid === '') && transferItem.edit === false) {
        let feeTypeUpdate = {};
        if (transferItem.feeType === 'flight' || transferItem.feeType === 'bookFlight') {
          feeTypeUpdate = {
            feeTypeUid: '537f3a40-d0dd-43d3-835a-8496f7cbeb77',
            feeTypeName: 'Flights',
          };
        } else {
          feeTypeUpdate = {
            feeTypeUid: 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add',
            feeTypeName: 'Transfers',
          };
        }
        return { ...transferItem, ...feeTypeUpdate };
      }
      // Additional logic if edit is true and feeTypeUid does not exist or is empty
      else if ((transferItem.feeTypeUid === '' || !transferItem.feeTypeUid) && transferItem.edit === true) {
        let feeTypeUpdate = {};
        if (transferItem.feeType === 'accommodation') {
          feeTypeUpdate = {
            feeTypeUid: '64a1caf7-e922-4629-b90b-164809033192',
            feeTypeName: '3rd Party Accomm',
          };
        } else if (transferItem.feeType === 'parkFees') {
          feeTypeUpdate = {
            feeTypeUid: '3483e4ef-046b-4233-afdc-4a1e0902729d',
            feeTypeName: 'Park Fees',
          };
        } else {
          feeTypeUpdate = {
            feeTypeUid: 'c536f381-457d-45d1-a503-75c370798f27',
            feeTypeName: 'Activities & Extras',
          };
        }
        return { ...transferItem, ...feeTypeUpdate };
      }
      // Return the item unchanged if none of the conditions match
      return transferItem;
    }),
  }));

  // Update the state with the modified data
  setFinalPageTransferData(updatedData);
};

function collapseExpandAllItems(finalPageData, setFinalPageData, finalPageTransferData, setFinalPageTransferData, customFinalItems, setCustomFinalItems, setFinalCollapse, shouldCollapse) {
  console.log('shouldCollapse:', shouldCollapse);

  // Create new arrays with updated collapsed values for finalPageData
  const updatedFinalPageData = finalPageData.map((item) => ({
    ...item,
    collapsed: shouldCollapse,
  }));

  // Create new arrays with updated collapsed values for finalPageTransferData
  const updatedFinalPageTransferData = finalPageTransferData.map((item) => ({
    ...item,
    collapsed: shouldCollapse,
  }));

  // Create new arrays with updated collapsed values for customFinalItems
  const updatedCustomFinalItems = customFinalItems.map((item) => ({
    ...item,
    collapsed: shouldCollapse,
  }));

  // Update the state with the new arrays
  setFinalPageData(updatedFinalPageData);
  setFinalPageTransferData(updatedFinalPageTransferData);
  setCustomFinalItems(updatedCustomFinalItems);
  setFinalCollapse(shouldCollapse);
}

export {
  getLabelForFinalValue,
  toggleFinalTransferCollapse,
  toggleFinalCollapse,
  getLabelForFinalTransferValue,
  countNorthernGuideNights,
  getOtherArrangementsByType,
  categorizeChargeType,
  handleAddFinalTransferItem,
  editFinalInputTransferItem,
  deleteFinalInputTransferItem,
  saveFinalInputDataTransferEdit,
  cancelFinalInputDataTransferEdit,
  saveFinalInputTransferData,
  cancelFinalInputTransferData,
  editFinalInputItem,
  deleteFinalInputItem,
  saveFinalInputDataEdit,
  cancelFinalInputDataEdit,
  handleAddFinalPropertyItem,
  resetEditPropertyItem,
  saveFinalInputData,
  cancelFinalInputData,
  resetFinalInputs,
  moveTransferItemUp,
  moveTransferItemDown,
  moveItemUp,
  moveItemDown,
  feeTypeOptions,
  getLabelForFinalValues,
  getShortenedLabelForValue,
  iconMap,
  categorizeChargeTypeGetUid,
  updateTransferData,
  updatePropertyData,
  collapseExpandAllItems,
  processFeeTypes,
  categorizeChargeTypeId,
};
