import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import propertiesService from "./propertiesService";

const initialState = {
  allProperties: [],
  propertiesError: false,
  propertiesSuccess: false,
  propertiesLoading: false,
  propertiesMessage: "",
};

// Get properties
export const getProperties = createAsyncThunk("properties/getProperties", async (property, thunkAPI) => {
  try {
    return await propertiesService.getProperties(property);
  } catch (error) {
    console.log(error);
    const message = "No properties";
    return thunkAPI.rejectWithValue(message);
  }
});

export const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    reset: (state) => {
      state.propertiesLoading = false;
      state.propertiesError = false;
      state.propertiesSuccess = false;
      state.propertiesMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProperties.pending, (state) => {
        state.propertiesLoading = true;
      })
      .addCase(getProperties.fulfilled, (state, action) => {
        state.propertiesLoading = false;
        state.propertiesSuccess = true;
        state.allProperties = action.payload.jsonResult;
      })
      .addCase(getProperties.rejected, (state, action) => {
        state.propertiesLoading = false;
        state.propertiesError = true;
        state.propertiesMessage = action.payload;
      });
  },
});

export const { reset } = propertiesSlice.actions;
export default propertiesSlice.reducer;
