// intercom.js
import ls from "@livesession/sdk";

// Function to initialize Intercom
export const bootIntercom = (user) => {
  if (window.Intercom) {
    window.Intercom("boot", {
      app_id: "hd6txnim",
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      created_at: user.createdAt ? Math.floor(new Date(user.createdAt).getTime() / 1000) : undefined,
    });
    // Adding LiveSession identification
    ls.identify({
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });
  }
};

// Function to shutdown Intercom
export const shutdownIntercom = () => {
  if (window.Intercom) {
    window.Intercom("shutdown");
  }
};

// Function to update Intercom
export const updateIntercom = () => {
  if (window.Intercom) {
    window.Intercom("update");
  }
};
