// Template for automatically sending a provisional itinerary email to customer
export const generateAutomaticProvisionalItineraryEmail = ({ booking }) => {
  const propertiesTable = generatePropertiesTable(booking.itineraryData);
  const expiryDate = booking.dateExpiry ? booking.dateExpiry.toDate() : null;
  const startDate = new Date(booking.dateStart);

  const html = `
  <html>
    <head>
      <style>
        body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
        .container { max-width: 600px; margin: 0 auto; padding: 20px; }
        h2 { color: #333; }
        .button { background-color: #4CAF50; color: white; padding: 10px 20px; text-align: center; text-decoration: none; display: inline-block; border-radius: 5px; }
        table { border-collapse: collapse; width: 100%; margin-top: 20px; }
        th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
        th { background-color: #f2f2f2; }
        .overview { background-color: #f9f9f9; padding: 15px; border-radius: 5px; margin: 20px 0; }
        .next-steps { background-color: #e8f5e9; padding: 15px; border-radius: 5px; margin: 20px 0; }
        .next-steps h3 { color: #2e7d32; margin-top: 0; }
        .next-steps ol { padding-left: 20px; }
        .next-steps li { margin-bottom: 10px; }
      </style>
    </head>
    <body>
      <div class="container">
        <h2>Dear ${booking.user.name},</h2>

        <p><strong>Ref: Expiry Notification for itinerary code: ${booking.code}</strong></p>

        <p>We're excited to confirm your provisional booking made through Hornbill</p>

        <p>Your provisional itinerary for <strong>${booking.clientName} (${formatMonthYear(startDate)})</strong> is now ready to view.</p>
        
        <p>This itinerary is currently on <strong>provisional</strong> status. It will expire at 11PM UTC on <strong>${formatDate(expiryDate)}</strong>.</p>
        
        <div class="overview">
          <h3>Quick overview of your itinerary details:</h3>
          <ul>
            <li><strong>Account Name:</strong> ${booking.agent.name}</li>
            <li><strong>Assigned Consultant:</strong> ${booking.bookingOwner?.name || booking.agent.seniorConsultant.name || 'Nomad Reservation Team'}</li>
            <li><strong>Client Name:</strong> ${booking.clientName}</li>
            <li><strong>Travel Dates:</strong> ${formatDate(new Date(booking.dateStart))} - ${formatDate(new Date(booking.dateEnd))}</li>
            <li><strong>Total Nights:</strong> ${booking.totalNights}</li>
          </ul>
        </div>
  
        ${propertiesTable}

        <div class="next-steps">
        <h3>Next Steps:</h3>
        <ol>
          <li><strong>Modify Your Booking:</strong> Changes are easy! Simply log in to the 'Manage Bookings' section on Hornbill, select your itinerary, and make any necessary updates. Remember, you can modify your booking anytime until confirmation.</li>
          <li><strong>Extend your provisional hold:</strong> You can extend your provisional hold via the "Manage Bookings" page. Please note, you can only do this once.</li>
          <li><strong>Confirm Your Booking:</strong> Ready to finalise? Just click the 'Confirm' button under 'Manage Bookings'. Once confirmed, you'll receive a confirmation email, and an invoice shortly after.</li>
        </ol>
        <p>We're here to ensure your booking experience is seamless and enjoyable. If you have any questions or need further assistance, don't hesitate to contact us.</p>
      </div>

      <p>Please review your provisional itinerary and let us know if you have any questions or if you'd like to make any changes.</p>
      
      <p>
        <a href="https://www.hornbill.app/itinerary/view/viewItinerary/${booking.uid}" class="button">
          View Itinerary
        </a>
      </p>
  
        <p>Thank you for choosing Nomad Tanzania. We look forward to helping you plan your perfect safari experience.</p>
        
        <p>Best regards,<br>Nomad Tanzania Team</p>
        
        <hr>
        <p style="font-size: 0.8em; color: #666;">PLEASE DO NOT REPLY TO THIS INBOX AS IT IS NOT MONITORED AND YOU WILL NOT RECEIVE A REPLY.</p>
      </div>
    </body>
  </html>
  `;

  const subject = `Provisional Itinerary for ${booking.clientName} - ${booking.code}`;

  return { html, subject };
};

// Template for sending a customised email of itinerary to customer
export const generateItineraryEmailHTML = ({ booking, userAddedContent, consultant }) => {
  const propertiesTable = generatePropertiesTable(booking.itineraryData);
  const expiryDate = booking.dateExpiry ? booking.dateExpiry.toDate() : null;
  const startDate = new Date(booking.dateStart);

  const header = `
  <html>
    <head>
      <style>
        body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
        .container { max-width: 600px; margin: 0 auto; padding: 20px; }
        h2 { color: #333; }
        .button { background-color: #4CAF50; color: white; padding: 10px 20px; text-align: center; text-decoration: none; display: inline-block; border-radius: 5px; }
        table { border-collapse: collapse; width: 100%; margin-top: 20px; }
        th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
        th { background-color: #f2f2f2; }
        .overview { background-color: #f9f9f9; padding: 15px; border-radius: 5px; margin: 20px 0; }
      </style>
    </head>
    <body>
      <div class="container">
        <h2>Dear ${consultant?.firstName || booking.consultant.name},</h2>
        
        <p>Your itinerary for <strong>${booking.clientName} (${formatMonthYear(startDate)})</strong> is now ready to view in Hornbill.</p>
        
        ${
          booking.status === 'provisional'
            ? `<p>Your itinerary is currently on <strong>${booking.status}</strong> status. It will expire at 11PM UTC on <strong>${formatDate(expiryDate)}</strong>.</p>`
            : `<p>Your itinerary is currently on <strong>${booking.status}</strong> status.</p>
        `
        }
        `;

  const footer = `
        <div class="overview">
          <h3>Quick overview of your itinerary details:</h3>
          <ul>
            <li><strong>Account Name:</strong> ${booking.agent.name}</li>
             <li><strong>Assigned Consultant:</strong> ${booking.bookingOwner?.name || booking.agent.seniorConsultant.name || 'Nomad Reservation Team'}</li>
            <li><strong>Client Name:</strong> ${booking.clientName}</li>
            <li><strong>Travel Dates:</strong> ${formatDate(new Date(booking.dateStart))} - ${formatDate(new Date(booking.dateEnd))}</li>
            <li><strong>Total Nights:</strong> ${booking.totalNights}</li>
          </ul>
        </div>
  
        ${propertiesTable}

        <br><!-- Add two line breaks here -->
    


        <p>If you have any questions, please let me know.</p>
        
        <p>
          <a href="https://www.hornbill.app/itinerary/view/viewItinerary/${booking.uid}" class="button">
            Manage Booking
          </a>
        </p>
  
        <p>Thank you,<br>Nomad Tanzania Team</p>
        
        <hr>
        <p style="font-size: 0.8em; color: #666;">Any replies to this email will go directly to your consultant</p>
      </div>
    </body>
  </html>
    `;

  const body = ``;

  const subject = `HB/${booking.code} [${booking.status.toUpperCase()}] Itinerary for ${booking.clientName}`;

  return { header, body, footer, subject };
};

// Notification email for extending provisional
export const generateExtensionEmail = ({ booking, newExpiryDate, recipient, isCustomer }) => {
  const monthYearFormatted = formatMonthYear(booking.dateStart);
  const newExpiryDateFormatted = newExpiryDate.toLocaleString('en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
    timeZoneName: 'short',
  });

  return `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
            .container { max-width: 600px; margin: 0 auto; padding: 20px; }
            h2 { color: #333; }
            .button { background-color: #4CAF50; color: white; padding: 10px 20px; text-align: center; text-decoration: none; display: inline-block; border-radius: 5px; }
            .overview { background-color: #f9f9f9; padding: 15px; border-radius: 5px; margin: 20px 0; }
          </style>
        </head>
        <body>
          <div class="container">
            <h2>Dear ${recipient},</h2>
            
            <p>The expiry date for the itinerary <strong>${booking.code}</strong> for <strong>${booking.clientName} (${monthYearFormatted})</strong> has been extended.</p>
            
            <p>Your itinerary is currently on <strong>${booking.status}</strong> status. It will now expire at 11PM UTC on <strong>${newExpiryDateFormatted}</strong>.</p>
            
            <div class="overview">
              <h3>Quick overview of your itinerary details:</h3>
              <ul>
                <li><strong>Account Name:</strong> ${booking.agent.name}</li>
                <li><strong>Assigned Consultant:</strong> ${booking.bookingOwner?.name || booking.agent.seniorConsultant.name || 'Nomad Reservation Team'}</li>
                <li><strong>Client Name:</strong> ${booking.clientName}</li>
                <li><strong>Travel Dates:</strong> ${formatDate(new Date(booking.dateStart))} - ${formatDate(new Date(booking.dateEnd))}</li>
                <li><strong>Total Nights:</strong> ${booking.totalNights}</li>
              </ul>
            </div>
  
            <p>If you have any questions, please don't hesitate to contact us.</p>
            
            <p>
              <a href="https://www.hornbill.app/itinerary/view/viewItinerary/${booking.uid}" class="button">
                Manage Booking
              </a>
            </p>
  
            <p>Thank you,<br>Nomad Tanzania Team</p>
            
            <hr>
            <p style="font-size: 0.8em; color: #666;">Any replies to this email will go directly to your consultant</p>
          </div>
        </body>
      </html>
    `;
};

// Template for sending a customised confirmation email to the customer
// Template for sending a customised confirmation email to the customer
export const generateConfirmationEmailHTML = ({ booking, userAddedContent, consultant, user }) => {
  const propertiesTable = generatePropertiesTable(booking.itineraryData);
  const startDate = new Date(booking.dateStart);
  const recipientName = consultant?.firstName || booking.consultant?.name || 'Valued Partner';
  const header = `
  <html>
    <head>
      <style>
        body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
        .container { max-width: 600px; margin: 0 auto; padding: 20px; }
        h2 { color: #333; }
        .button { background-color: #4CAF50; color: white; padding: 10px 20px; text-align: center; text-decoration: none; display: inline-block; border-radius: 5px; }
        table { border-collapse: collapse; width: 100%; margin-top: 20px; }
        th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
        th { background-color: #f2f2f2; }
        .overview { background-color: #f9f9f9; padding: 15px; border-radius: 5px; margin: 20px 0; }
         .key-dates {
          background-color: #f9f9f9;
          padding: 8px 12px;
          border-radius: 5px;
          margin: 20px 0;
          font-size: 0.95em;
        }
        .key-dates h3 {
          margin: 0 0 8px 0;
        }
        .key-dates p {
          margin: 4px 0;
        }
          .next-steps {
          background-color: #e8f5e9;
          padding: 15px;
          border-radius: 5px;
          margin: 20px 0;
        }
        .next-steps h3 {
          color: #2e7d32;
          margin-top: 0;
        }
        .next-steps ol {
          padding-left: 20px;
        }
        .next-steps li {
          margin-bottom: 10px;
        }
      </style>
    </head>
    <body>
      <div class="container">
        <h2>Dear ${recipientName},</h2>

                <p><strong>RE: Confirmation for ${booking.clientName} (${formatMonthYear(startDate)})</strong></p>

        <p>Thank you for choosing Nomad Tanzania, we are truly excited to welcome your guests into our wild corner of Africa.<p>

        <p>Our final itinerary checks have been ticked off and we’re delighted to confirm their safari arrangements. You'll find the final costing and invoice attached to this email.</p>
        

  `;

  const footer = `
        <div class="overview">
          <h3>Quick overview of your itinerary details:</h3>
          <ul>
            <li><strong>Account Name:</strong> ${booking.agent.name}</li>
            <li><strong>Assigned Consultant:</strong> ${booking.bookingOwner?.name || booking.agent.seniorConsultant.name || 'Nomad Reservation Team'}</li>
            <li><strong>Client Name:</strong> ${booking.clientName}</li>
            <li><strong>Travel Dates:</strong> ${formatDate(new Date(booking.dateStart))} - ${formatDate(new Date(booking.dateEnd))}</li>
            <li><strong>Total Nights:</strong> ${booking.totalNights}</li>
            <li><strong>Total Price:</strong> $${booking.invoice.totalInvoiced.toLocaleString()}</li>
          </ul>
        </div>
  
        ${propertiesTable}

          <div class="key-dates">
          <h3>Key Dates</h3>
          ${
            booking.invoice?.depositAmount > 0
              ? `<p><strong>Deposit Amount:</strong> $${booking.invoice.depositAmount.toLocaleString()} due before ${formatDate(new Date(booking.depositDueDate))}</p>`
              : '<p><strong>Deposit:</strong> No deposit due</p>'
          }
          <p><strong>Final Balance:</strong> ${
            booking.invoice
              ? `$${(booking.invoice.totalInvoiced - (booking.invoice.depositAmount || 0)).toLocaleString()} due before ${formatDate(new Date(booking.balanceDueDate))}`
              : 'To be confirmed'
          }</p>
        </div>
         <div class="next-steps">
          <h3>Next Steps:</h3>
          <ol>
            <li><strong>Add your guest information</strong> 
              <a href="https://www.hornbill.app/itinerary/view/viewItinerary/${booking.uid}">here</a> 
              – if you don't have it, don't worry. You can come back anytime between now and 30 days before travel to complete it – if we don't hear from you, we'll assume there is no specific requirements</li>
            
            <li><strong>Review our suggested pre-departure information</strong> 
              <a href="https://hornbill.app/travelinfo">here</a> 
              and suggested packing list, we've attached the info should you wish to share it with your clients</li>
            
            <li><strong>Special requests or updates?</strong> 
              If you have any special requests or itinerary updates ahead of travel, reach out to our dedicated Travel Desk 
              (<a href="mailto:traveldesk@nomad-tanzania.net">traveldesk@nomad-tanzania.net</a>) 
              and they'll will sort it</li>
          </ol>
        </div>
         <p>
          <a href="https://www.hornbill.app/itinerary/view/viewItinerary/${booking.uid}" class="button">
            View Itinerary
          </a>
        </p>

        <p>We are super excited to welcome your clients to Tanzania.</p>
        
        <p>Warm regards from the bush,</p>

        <p>${user.firstName} ${user.lastName}<br>Nomad Tanzania</p>
        
        <hr>
        <p style="font-size: 0.8em; color: #666;">Any replies to this email will go directly to your consultant</p>
      </div>
    </body>
  </html>
  `;

  const body = ``;

  const subject = `Confirmation: Itinerary for ${booking.clientName} - ${booking.code}`;

  return { header, body, footer, subject };
};

// Helper function to build table of properties in the itinerary
const generatePropertiesTable = (itineraryData) => {
  if (!itineraryData || itineraryData.length === 0) return '';

  return `
    <h3>Your Itinerary Properties</h3>
    <table>
      <tr>
        <th>Property</th>
        <th>Start Date</th>
        <th>End Date</th>
      </tr>
      ${itineraryData
        .map(
          (item) => `
        <tr>
          <td>${item.propName}</td>
          <td>${formatDate(new Date(item.startDay))}</td>
          <td>${formatDate(new Date(item.endDay))}</td>
        </tr>
      `,
        )
        .join('')}
    </table>
  `;
};

// helper to build date MMM YYYY
function formatMonthYear(date) {
  return date
    ? new Date(date.toUTCString()).toLocaleString('en-GB', {
        month: 'short',
        year: 'numeric',
        timeZone: 'UTC',
      })
    : '';
}

// helper to build date DD MMM YYYY
function formatDate(date) {
  return date
    ? new Date(date.toUTCString()).toLocaleString('en-GB', {
        weekday: 'long',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone: 'UTC',
      })
    : '';
}
