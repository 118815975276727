import React, { useEffect, useState } from 'react';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';

import ButtonOutline from '../components/Buttons/ButtonOutline';
//import CustomSidebar from '../partials/CustomSidebar';
import DashboardCard from '../components/DashboardCard';
import { FiDollarSign } from 'react-icons/fi';
import Header from '../partials/Header';
import { Link } from 'react-router-dom';
import { MdFlightTakeoff } from 'react-icons/md';
import { PiArrowCircleRightLight } from 'react-icons/pi';
import Sidebar from '../partials/Sidebar';
import { useSelector } from 'react-redux';
import DocumentDownloadsBox from './portal/components/DocumentDownloadsBox';
import { getTermsAndConditionsUrl } from '../constants/documentUrls';



function Home() {
  const { user } = useSelector((state) => state.auth);
  const { selectedCompanyNomadCompany, selectedCompanyRateBand } = useSelector((state) => state.companies);
  console.log('User object:', user);
  const [bookingsData, setBookingsData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [documents, setDocuments] = useState([
    { name: 'Terms and Conditions', file: 'placeholder_url' },
    { name: 'Nomad Rates 2025/26', file: '' },
  ]);

  const cards = [
    {
      title: 'Create Itinerary',
      subtitle: 'Create a new itinerary',
      image: 'https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/c5cfc025-795e-41e6-650c-cbba0c710300/640x400',
      link: '/itinerary/create/createItinerary',
      comingSoon: false,
    },
    {
      title: 'Check Availability',
      subtitle: 'Check live availability for properties',
      image: 'https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/b7f011b6-403c-45e0-b5b7-7641e51cb600/640x400',
      link: '/availability/checkAvailability',
      comingSoon: false,
    },
    {
      title: 'Manage Itineraries',
      subtitle: 'Manage your itineraries',
      image: 'https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/65f67cb7-fb91-40ed-db87-99d3a377e900/640x400',
      link: '/itinerary/manage/manageItineraries',
      comingSoon: false,
    },
    {
      title: 'Flight Checker',
      subtitle: 'Check live availability for flights',
      image: 'https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/eab38570-58a0-4d61-efba-21be59ce6600/640x400',
      link: '/#',
      comingSoon: true,
      hideForFullBoard: true,
    },
  ];

  useEffect(() => {
    // Fetch the correct Terms and Conditions based on nomadCompany
    const termsAndConditionsUrl = getTermsAndConditionsUrl(selectedCompanyNomadCompany);

    setDocuments(prevDocs => [
      { ...prevDocs[0], file: termsAndConditionsUrl },
      prevDocs[1]
    ]);

    // Fetch the correct rates PDF
    fetchDownloadLink();
  }, [selectedCompanyNomadCompany, selectedCompanyRateBand]);

  const fetchDownloadLink = () => {
    let link = '';
    const rateName = selectedCompanyRateBand.name;

    if (selectedCompanyNomadCompany === "ntl") {
      if (rateName === "PREF") {
        link = 'https://storage.googleapis.com/nomad-public/rates/dmc/DMC%20-%20Nomad%20-%20PREF%20-%202025-26%20JR48S%20-%205.0.pdf';
      } else if (rateName === "SPEC") {
        link = 'https://storage.googleapis.com/nomad-public/rates/dmc/DMC%20-%20Nomad%20-%20SPEC%20-%202025-26%20LK843%20-%205.0.pdf';
      } else if (rateName === "STO") {
        link = 'https://storage.googleapis.com/nomad-public/rates/dmc/DMC%20-%20Nomad%20-%20STO%20-%202025-26%20JH437S%20-%205.0.pdf';
      } else {
        link = 'https://storage.googleapis.com/nomad-public/rates/Nomad%20RACK%20%202025%2026%20H43KS.pdf'; // Default to RACK for NTL
      }
    } else if (selectedCompanyNomadCompany === "nsl") {
      if (rateName === "PREF") {
        link = 'https://storage.googleapis.com/nomad-public/rates/Nomad%20PREF%202025%2026%20%20HJ83S.pdf';
      } else if (rateName === "SPEC") {
        link = 'https://storage.googleapis.com/nomad-public/rates/Nomad%20SPEC%20-%202025-26%208HY34-%205.0.pdf';
      } else if (rateName === "STO") {
        link = 'https://storage.googleapis.com/nomad-public/rates/Nomad%20STO%202025%2026%20FU24S.pdf';
      } else if (rateName === "TRAVEL AGENT") {
        link = 'https://storage.googleapis.com/nomad-public/rates/Nomad%20%20TA%20%202025-26%20XYH43%20.pdf';
      } else {
        link = 'https://storage.googleapis.com/nomad-public/rates/Nomad%20RACK%20%202025%2026%20H43KS.pdf'; // Default to RACK for NSL
      }
    } else {
      // Default case for any other nomadCompany value
      link = 'https://storage.googleapis.com/nomad-public/rates/Nomad%20RACK%20%202025%2026%20H43KS.pdf';
    }

    setDocuments(prevDocs => prevDocs.map(doc => 
      doc.name === 'Nomad Rates 2025/26' ? { ...doc, file: link } : doc
    ));
  };

  const fetchMetrics = async () => {
    const db = getFirestore();
    const itinerariesRef = collection(db, 'itineraries');

    // Count of itineraries where "status" == "provisional"
    const provisionalQuery = query(itinerariesRef, where('status', '==', 'provisional'), where('user.uid', '==', user.uid));
    const provisionalSnapshot = await getDocs(provisionalQuery);
    const provisionalCount = provisionalSnapshot.size;

    // Count of itineraries where status = "confirmed" && dateStart is greater than today

    const todayString = new Date().toISOString().slice(0, 10);
    const confirmedQuery = query(itinerariesRef, where('status', '==', 'confirmed'), where('dateStart', '>', todayString), where('user.uid', '==', user.uid));
    const confirmedSnapshot = await getDocs(confirmedQuery);
    const confirmedCount = confirmedSnapshot.size;

    // Sum of totalPrice where user ID matches, status is "confirmed", and dateConfirmed is in the current month
    const currentDate = new Date();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
    const totalPriceQuery = query(
      itinerariesRef,
      where('status', '==', 'confirmed'),
      where('user.uid', '==', user.uid),
      where('dateConfirmed', '>=', startOfMonth),
      where('dateConfirmed', '<=', endOfMonth),
    );
    const totalPriceSnapshot = await getDocs(totalPriceQuery);
    let totalSalesThisMonth = 0;
    totalPriceSnapshot.forEach((doc) => {
      totalSalesThisMonth += doc.data().totalPrice;
    });

    // Sum of totalPrice where user ID matches, status is "confirmed", and dateConfirmed is in the current calendar year
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfYear = new Date(currentDate.getFullYear(), 11, 31);
    const totalPriceYearQuery = query(
      itinerariesRef,
      where('status', '==', 'confirmed'),
      where('user.uid', '==', user.uid),
      where('dateConfirmed', '>=', startOfYear),
      where('dateConfirmed', '<=', endOfYear),
    );
    const totalPriceYearSnapshot = await getDocs(totalPriceYearQuery);
    let totalSalesThisYear = 0;
    totalPriceYearSnapshot.forEach((doc) => {
      totalSalesThisYear += doc.data().totalPrice;
    });

    // Update the bookingsData and salesData state with the fetched metrics
    setBookingsData([
      {
        label: 'Open Provisionals',
        value: provisionalCount,
        link: '/itinerary/manage/manageItineraries',
        linkText: 'View Itineraries',
      },
      { label: 'Upcoming Bookings', value: confirmedCount },
    ]);

    setSalesData([
      {
        label: 'Total Sales This Month',
        value: `$${totalSalesThisMonth.toFixed(2)}`,
      },
      {
        label: 'Total Sales This Year',
        value: `$${totalSalesThisYear.toFixed(2)}`,
      },
    ]);
  };

  useEffect(() => {
    fetchMetrics();
    fetchDownloadLink();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      {/* <CustomSidebar /> */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
        <main className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <h1 className="text-4xl font-roca mb-8">Your Dashboard</h1>
          <div className="grid grid-cols-1 lg:grid-cols-10 gap-8">
            <div className="lg:col-span-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {cards
                  .filter((card) => !(user?.fullBoardOnly && card.hideForFullBoard))
                  .map((card, index) => (
                    <DashboardCard key={index} {...card} />
                  ))}
              </div>
            </div>
            <div className="lg:col-span-4">
              {/* 2025/26 Rates Release Box */}
              <DocumentDownloadsBox documents={documents} />
              {/* My Bookings Box */}
              <div className="bg-white shadow-md rounded-lg overflow-hidden mt-8 p-6 mb-8">
                <h2 className="text-lg font-bold text-nomadBlue Uppercase mb-4">
                  <MdFlightTakeoff className="inline-block mr-2 font-semibold" />
                  BOOKINGS SNAPSHOT
                </h2>
                <div className="grid grid-cols-2 gap-4 gap-y-8">
                  {bookingsData.map((item, index) => (
                    <div key={index}>
                      <p className="text-base text-gray-600">{item.label}</p>
                      <p className="text-3xl font-medium">{item.value}</p>
                      {item.link && (
                        <Link to={item.link} className="text-nomadBlue underline">
                          {item.linkText}
                          <PiArrowCircleRightLight className="inline-block ml-1 h-4 w-4 text-[#C04F3B]" />
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
                <p className="pt-4">*Does not include bookings made via our sales team</p>
              </div>

              {/* My Sales Box */}
              <div className="bg-white shadow-md rounded-lg overflow-hidden p-6">
                <h2 className="text-lg font-bold text-nomadBlue Uppercase mb-4">
                  <FiDollarSign className="inline-block mr-2 font-semibold" />
                  MY SALES
                </h2>
                <div className="grid grid-cols-2 gap-4 gap-y-8">
                  {salesData.map((item, index) => (
                    <div key={index}>
                      <p className="text-base text-gray-600">{item.label}</p>
                      <p className="text-3xl font-medium">{item.value}</p>
                      {item.link && (
                        <Link to={item.link} className="text-nomadBlue underline">
                          {item.linkText}
                          <PiArrowCircleRightLight className="inline-block ml-1 h-4 w-4 text-[#C04F3B]" />
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
                <p className="pt-4">*Does not include bookings made via our sales team</p>
              </div>
            </div>
          </div>
          <div></div>
        </main>
      </div>
    </div>
  );
}

export default Home;
