import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../db/firebase.config';

const fetchCompanies = async (userUid, userCompanyId) => {
  try {
    const agentsRef = collection(db, 'agents');
    const userQuery = query(agentsRef, where('companyId', '==', userCompanyId));
    const userQuerySnapshot = await getDocs(userQuery);

    if (userQuerySnapshot.empty) {
      console.log('No companies found for userId:', userCompanyId);
      return { companyData: [], selectedCompanyId: null };
    }

    const userData = userQuerySnapshot.docs[0].data();
    console.log('Company Found for User:', userData);

    let companyData = [
      {
        uid: userCompanyId,
        name: userData.name,
        permissions: {
          accessLevel: userData.accessLevel || null,
          nomadPropertiesOnly: userData.nomadPropertiesOnly || false,
          allowBookings: userData.allowBookings || false,
        },
        rateBand: {
          commission: userData.rateBand?.commission || null,
          name: userData.rateBand?.name || null,
        },
        nomadCompany: userData.nomadCompany || null,
      },
    ];

    if (userData.accessibleAccounts && userData.accessibleAccounts.length > 0) {
      const accessibleAccountsQuery = query(agentsRef, where('companyId', 'in', userData.accessibleAccounts), where('active', '==', true));
      const accessibleAccountsSnapshot = await getDocs(accessibleAccountsQuery);

      companyData = [
        ...companyData,
        ...accessibleAccountsSnapshot.docs.map((doc) => ({
          uid: doc.id,
          name: doc.data().name,
          permissions: {
            accessLevel: doc.data().accessLevel || null,
            nomadPropertiesOnly: doc.data().nomadPropertiesOnly || false,
            allowBookings: doc.data().allowBookings || false,
          },
          rateBand: {
            commission: doc.data().rateBand?.commission || null,
            name: doc.data().rateBand?.name || null,
          },
          nomadCompany: doc.data().nomadCompany || null,
        })),
      ];
    }

      const userRef = doc(db, 'users', userUid);
      const userSnapshot = await getDoc(userRef);
      const selectedCompanyId = userSnapshot.exists() ? userSnapshot.data().selectedCompanyId || userData?.companyId : userData?.companyId;
  
      return { companyData, selectedCompanyId };
    } catch (error) {
      console.error('Error fetching companies:', error);
      throw error;
    }
  };

const updateSelectedCompany = async (updatedCompanyId, userUid) => {
  try {
    const userRef = doc(db, 'users', userUid);
    await updateDoc(userRef, { selectedCompanyId: updatedCompanyId });
    return updatedCompanyId;
  } catch (error) {
    console.error('Error updating selected company:', error);
    throw error;
  }
};

const companiesService = {
  fetchCompanies,
  updateSelectedCompany,
};

export default companiesService;