import { IoIosArrowRoundForward } from 'react-icons/io';
import { LuGitBranch } from 'react-icons/lu';
import PropTypes from 'prop-types';
import React from 'react';
import { formatPrice } from '../functions/generalFunctions';

const TransferInfoFlightCard = ({
  flightCost,
  STD,
  STA,
  airlineName,
  route,
  duration,
  flightClass,
  timeToProperty,
  transfer,
  setSelectedFlightIndex,
  setShowButtons,
  selectedDirectMultipleValue,
  changeFlight,
  selectedFlight,
  handleOptionClickGetFlights,
  resetSearch,
  firstFlight,
}) => {
  const formattedTime = <span className="font-semibold">{timeToProperty} mins</span>;
  return (
    <div className="rounded-lg border border-zinc-300 mt-5 relative">
      <div className="w-full flex h-[108px]">
        <div className="flex justify-center items-center text-stone-950 text-xl font-bold bg-neutral-100 px-4">${formatPrice(flightCost)}</div>
        <div className="flex-1 pl-5 flex flex-col justify-center items-start">
          <div className="text-black text-sm font-normal flex items-center">
            <span>{STD}</span>
            <IoIosArrowRoundForward size={20} className="text-cyan-600 mx-1" />
            <span>{STA}</span>
            <span className="text-neutral-500 text-sm font-normal ml-2"> | {duration}</span>
          </div>
          <span className="text-neutral-500 text-sm font-normal">
            {airlineName} | {route}
          </span>
          <span className="text-neutral-500 text-sm font-normal">Class: {flightClass}</span>
          <span className="text-sm font-normal text-black">{transfer.objType === 'arrival' ? <>Airport is {formattedTime} to property</> : <>Airport is {formattedTime} from property</>}</span>
        </div>
      </div>
      {changeFlight ? (
        <div
          className="absolute top-4 right-4 text-cyan-600 text-[13px] font-normal cursor-pointer"
          onClick={() => {
            setSelectedFlightIndex(null);
            setShowButtons(false);
          }}
        >
          Change
        </div>
      ) : firstFlight ? (
        <div
          className="absolute top-4 right-4 text-cyan-600 text-[13px] font-normal cursor-pointer"
          onClick={async (event) => {
            if (transfer.objType === 'arrival') {
              resetSearch(event);
              handleOptionClickGetFlights(selectedFlight.fromcode, selectedFlight.tocode, true);
            } else if (transfer.objType === 'departure') {
              resetSearch(event);
              handleOptionClickGetFlights(selectedFlight.fromcode, selectedFlight.tocode, true);
            } else {
              await resetSearch(event);
              //handleOptionClickGetFlights(selectedFlight.fromcode, selectedFlight.tocode, true);
            }
            // if (selectedDirectMultipleValue === 'Direct') {
            //   //setSelectedFlightIndex(null);
            //   //setShowButtons(false);
            //   handleOptionClickGetFlights(selectedFlight.fromcode, selectedFlight.tocode, true);
            // } else {
            //   // setSelectedFlightIndex(null);
            //   // setShowButtons(false);
            //   handleOptionClickGetFlights(selectedFlight.fromcode, selectedFlight.tocode, true);
            // }
          }}
        >
          Search new flight
        </div>
      ) : null}
      <div className="absolute top-0 left-2 w-[82px] h-[19px] bg-green-100 rounded text-center text-stone-600 text-xs font-semibold bg-green-100">Selected</div>
    </div>
  );
};

export default TransferInfoFlightCard;
