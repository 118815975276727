import { CalendarIcon, CheckCircleIcon, ClockIcon, InformationCircleIcon } from '@heroicons/react/24/solid';

import React from 'react';
import { Timestamp } from 'firebase/firestore';
import { formatPrice } from '../functions/generalFunctions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const ViewCompHeader = ({ item, feeTypeIcon, description, transferTime, supplierName, supplierUid, date, total, collapse, expand, bookingMaster, manage, day, supplierReservations, hideStatusPill }) => {
  const { user } = useSelector((state) => state.auth);

  console.log('bookings ViewCompHeader ');
  console.log(JSON.stringify(bookingMaster, null, 2));
  if (!bookingMaster) {
    console.error('bookingMaster is undefined');
    return null; // or return something else
  }

  const status = bookingMaster?.status;

  console.log('supplierReservations', JSON.stringify(supplierReservations, null, 2));
  console.log('supplierUid', supplierUid);

  let matchingReservation;

  if (supplierReservations) {
    matchingReservation = supplierReservations.find((reservation) => reservation.supplierUid === supplierUid);
  }

  console.log('ViewCompHeader status', status);
  //console.log('booking', JSON.stringify(bookings, null, 2));

 
  let statusPillShow = false;
  let statusPillBgColor;
  let statusPillTextColor;
  let statusPillText;
  let statusIcon;

  if (supplierUid === "8ae5bed6-e15e-4e79-8ab0-e934c2645abc") {
    statusPillShow = true;
    statusPillBgColor = 'bg-purple-100';
    statusPillTextColor = 'text-purple-700';
    statusPillText = 'Own Arrangement';
    statusIcon = <InformationCircleIcon className="w-4 h-4 mr-1 text-purple-700" />;
  } else if (status === 'pendingProvisional' || status === 'provisional') {
    if (matchingReservation && matchingReservation.reservationStage === 'provisional') {
      statusPillBgColor = 'bg-blue-100';
      statusPillTextColor = 'text-blue-700';
      const expiryDate = matchingReservation.dateProvisionalExpiry.toDate();
      const expiryDateFormatted = moment(expiryDate).utc().format('MMMM Do, YYYY [at] HH:mm [UTC]');
      statusPillText = `Provisional held${user.internalUser ? ` - expires on ${expiryDateFormatted}` : ''}`;
      statusIcon = <ClockIcon className="w-4 h-4 mr-1 text-blue-700" />;
      statusPillShow = true;
    } else if (matchingReservation && matchingReservation.provisionalRequired) {
      statusPillBgColor = 'bg-yellow-100';
      statusPillTextColor = 'text-yellow-700';
      statusPillText = 'Pending provisional';
      statusIcon = <CalendarIcon className="w-4 h-4 mr-1 text-yellow-700" />;
      statusPillShow = true;
    } else {
      statusPillBgColor = 'bg-gray-100';
      statusPillTextColor = 'text-gray-700';
      statusPillText = 'Booked on confirmation';
      statusIcon = <InformationCircleIcon className="w-4 h-4 mr-1 text-gray-700" />;
      statusPillShow = true;
    }
  } else if (status === 'confirmed' || status === 'pendingConfirmation') {
    if (matchingReservation && matchingReservation.reservationStage === 'confirmed') {
      statusPillBgColor = 'bg-green-100';
      statusPillTextColor = 'text-green-700';
      statusPillText = 'Confirmed';
      statusIcon = <CheckCircleIcon className="w-4 h-4 mr-1 text-green-700" />;
      statusPillShow = true;
    } else {
      statusPillBgColor = 'bg-orange-100';
      statusPillTextColor = 'text-orange-700';
      statusPillText = 'Pending Confirmation';
      statusIcon = <CalendarIcon className="w-4 h-4 mr-1 text-orange-700" />;
      statusPillShow = true;
    }
  }

  return (
    <div key={uuidv4()} className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 p-4">
      <div className="absolute left-0 top-0 transform -translate-y-1/2">
        <span className="text-[12px] font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 bg-[#97C1e6] rounded-r-lg capitalize">Day {day}</span>
      </div>
      <div className="flex-shrink-0">
        <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">{feeTypeIcon}</div>
      </div>
      <div className="flex-grow">
        <div className="text-black text-base font-semibold leading-tight">{description}</div>
        <div className="text-neutral-500 text-sm font-normal leading-[18.20px] pt-2 flex flex-wrap items-center">
          <span className="mr-2">
              {supplierName} | {date}
            </span>
            {transferTime && <span className="mr-2"> | {transferTime}</span>}
          {statusPillShow && !hideStatusPill && (
            <div className={`mt-2 sm:mt-0 px-3 py-1 ${statusPillBgColor} rounded-full flex items-center`}>
              {statusIcon}
              <div className={`text-center ${statusPillTextColor} text-xs font-medium`}>{statusPillText}</div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4 mt-4 sm:mt-0">
        <div className="text-gray-900 text-sm font-medium leading-[21px]">Total: ${formatPrice(total)}</div>
        {!manage && <div className="flex items-center justify-center">{!item.collapsed ? collapse : expand}</div>}
      </div>
    </div>
  );
};

export default ViewCompHeader;
