import React from "react";

const PropertyRoomDetails = ({ rooms, formatPax }) => {
  return (
    <>
      <h3 className="text-base font-semibold mb-2">Rooms</h3>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="border px-4 py-2">#ID</th>
            <th className="border px-4 py-2">Room Type</th>
            <th className="border px-4 py-2">Quantity</th>
            <th className="border px-4 py-2">Guests in Room</th>
            <th className="border px-4 py-2">Configuration</th>
          </tr>
        </thead>
        <tbody>
          {rooms
            .filter((room) => room.roomType !== "Vehicle")
            .map((room, index) => (
              <tr key={room.id}>
                <td className="border px-4 py-2">{index + 1}</td>
                <td className="border px-4 py-2">{room.selectedName}</td>
                <td className="border px-4 py-2">1</td>
                <td className="border px-4 py-2">
                  {formatPax(room.selectedAgesAdults, room.selectedAges)}
                </td>
                <td className="border px-4 py-2">{room.roomConfig}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default PropertyRoomDetails;