// import { getFirestore } from "firebase/firestore";
// import { initializeApp } from "firebase/app";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FB_API_KEY,
//   authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FB_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FB_MESSAGING_APP_ID,
//   measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
// };

// // Initialize Firebase
// initializeApp(firebaseConfig);
// export const db = getFirestore();

import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_MESSAGING_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app); // Initialize and export storage
