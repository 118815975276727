import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import suppliersService from './suppliersService';

const initialState = {
  allSuppliers: [],
  suppliersError: false,
  suppliersSuccess: false,
  suppliersLoading: false,
  suppliersMessage: '',
};

// Get Airports
export const getSuppliers = createAsyncThunk('suppliers/getSuppliers', async (supplier, thunkAPI) => {
  try {
    return await suppliersService.getSuppliers(supplier);
  } catch (error) {
    console.log(error);
    const message = 'No suppliers';
    return thunkAPI.rejectWithValue(message);
  }
});

export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    reset: (state) => {
      state.suppliersLoading = false;
      state.suppliersError = false;
      state.suppliersSuccess = false;
      state.suppliersMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSuppliers.pending, (state) => {
        state.suppliersLoading = true;
      })
      .addCase(getSuppliers.fulfilled, (state, action) => {
        state.suppliersLoading = false;
        state.suppliersSuccess = true;
        state.allSuppliers = action.payload.jsonResult;
      })
      .addCase(getSuppliers.rejected, (state, action) => {
        state.suppliersLoading = false;
        state.suppliersError = true;
        state.suppliersMessage = action.payload;
      });
  },
});

export const { reset } = suppliersSlice.actions;
export default suppliersSlice.reducer;
