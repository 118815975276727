import React, { useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Textarea } from '@headlessui/react';
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../../db/firebase.config";

const SupplierRequestForm = ({ documentUid }) => {
  const [formData, setFormData] = useState({
    response: '',
    bookingReference: '',
    expiryDate: null,
    name: '',
    email: '',
    message: '',
  });

  const responseOptions = [
    { value: 'accept', label: 'Accept this booking request' },
    { value: 'decline', label: 'Decline this booking request' },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleResponseChange = (selectedOption) => {
    setFormData({ ...formData, response: selectedOption.value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, expiryDate: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!formData.response) {
      alert('Please select a response');
      return;
    }

    if (!formData.name) {
      alert('Please enter your name');
      return;
    }

    if (!formData.email) {
      alert('Please enter your email');
      return;
    }

    // Validate email format using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert('Please enter a valid email address');
      return;
    }

    if (formData.response === 'decline' && !formData.message) {
      alert('Please provide a reason for declining');
      return;
    }

    if (formData.response === 'accept' && !formData.expiryDate) {
      alert('Please select a provisional expiry date');
      return;
    }

    try {
      const response = {
        response: formData.response,
        bookingReference: formData.bookingReference,
        expiryDate: formData.expiryDate,
        name: formData.name,
        email: formData.email,
        message: formData.message,
      };

      await updateDoc(doc(db, "supplierRequests", documentUid), {
        response: response,
      });

      alert("Form submitted successfully");

      // Reset form data after successful submission
      setFormData({
        response: '',
        bookingReference: '',
        expiryDate: null,
        name: '',
        email: '',
        message: '',
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form");
    }
  };

  return (
    <div className="mx-auto mt-8 ">
        <h2 className="text-2xl font-bold mb-4">Supplier Response</h2>
        <p className="text-lg mb-4">
            Please complete the form below, which will be submitted back to your consultant. You will also receive an email.
          </p>
        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="response" className="block text-sm font-medium text-gray-700">
            Your Response <span className="text-red-500">*</span>
            </label>
            <Select
              id="response"
              name="response"
              value={responseOptions.find((option) => option.value === formData.response)}
              onChange={handleResponseChange}
              options={responseOptions}
              className="mt-1 block w-full"
            />
          </div>
          <div>
            <label htmlFor="bookingReference" className="block text-sm font-medium text-gray-700">
              Your Booking Reference
            </label>
            <input
              type="text"
              id="bookingReference"
              name="bookingReference"
              value={formData.bookingReference}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700">
              Provisional Expiry Date{' '}
          {formData.response === 'accept' && <span className="text-red-500">*</span>}
            </label>
            <DatePicker
              id="expiryDate"
              name="expiryDate"
              selected={formData.expiryDate}
              onChange={handleDateChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Your Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Your Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>


        {/* Add the new row for "Message to Consultant / Reason for declining" */}
        <div className="col-span-2">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Message to Consultant / Reason for declining{' '}
          {formData.response === 'decline' && <span className="text-red-500">*</span>}
        </label>
          <Textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            rows={4}
          />
        </div>


          <div className="col-span-2">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
  );
};

export default SupplierRequestForm;