import { FaRegCircleQuestion, FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdConnectingAirports, MdContentCopy, MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { TbArrowsRandom, TbBuildingWarehouse } from 'react-icons/tb';
import { addItem, deleteItem, handleCut, handlePaste } from '../functions/finalCustomFunctions';

import FinalFoundItemCard from './FinalFoundItem';
import { GiJeep } from 'react-icons/gi';
import { PiPark } from 'react-icons/pi';
import { formatPrice } from '../functions/generalFunctions';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const FinalUnallocated = ({
  item,
  bookings,
  booking,
  activitiesData,
  customFinalItems,
  setCustomFinalItems,
  customFinalCut,
  setCustomFinalCut,
  handleOpenSlideOut,
  setCustomItemToEdit,
  setAddFinalItemDetails,
  firstDay,
  setFinalSlideOutDate,
}) => {
  let searchId = 'unallocated';
  const foundItems = customFinalItems.filter((search) => search.id === searchId);

  const pickupDropoff = false;

  console.log('item activity', JSON.stringify(item, null, 2));

  const [localItem, setLocalItem] = useState({ ...item, collapsed: true }); // Initialize localItem with item prop and set collapsed to true

  useEffect(() => {
    // If you need to respond to changes in the item prop, update localItem here
    setLocalItem((current) => ({ ...current, ...item, collapsed: current.collapsed }));
  }, [item]);

  return (
    <>
      {/* main items */}

      <div
        className={`bg-white border border-slate-200 brand-text-grey-v2 mb-7`}
        style={{
          WebkitTransform: 'translateZ(0)' /* Safari */,
        }}
      >
        {/* ROW 1 (Property info) */}

        <div className={`mb-0`}>
          <div className={`grid grid-cols-12`}>
            {/* IMG */}

            {/* ROOM NAME + DESCRIPTION */}
            <div className="col-span-12 bg-white">
              {/* Number of days */}
              <div
                className="text-right fixed top-4 right-0"
                style={{
                  WebkitTransform: 'translateZ(0)',
                  perspective: '1000' /* Safari */,
                }}
              ></div>
              {/* Property name */}
              <div className="grid grid-cols-12 border-b border-slate-200">
                <div className="text-xs font-medium truncate text-ellipsis overflow-hidden col-span-10 ml-5 border-r border-slate-200 py-2">
                  <div className="flex items-center">
                    <FaRegCircleQuestion size={30} />
                    <div className="ml-2 flex flex-col">
                      <span className="flex">
                        {/* <span className="text-lg">{moment(item.selectedActivityDate).format('MMM D')}</span> */}
                        <span className="text-base font-semibold text-orange-600">Unallocated items</span>
                      </span>
                      <span>Move or delete items from 'Unallocated items' before proceeding.</span>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 text-xs ml-5 border-r border-slate-200 py-2"></div>
                <div className="col-span-1 py-2 flex justify-center">
                  {/* {!localItem.collapsed ? (
                    <IoIosArrowUp
                      size={20}
                      className={`cursor-pointer mt-1`}
                      onClick={() => setLocalItem({ ...localItem, collapsed: !localItem.collapsed })}
                      // onClick={() => toggleFinalActivityCollapse(item.uuid, finalPageData, resetFinalInputs, setFinalPageData, setFinalInputs)} // add this line
                    />
                  ) : (
                    <IoIosArrowDown
                      size={20}
                      className={`cursor-pointer mt-1`}
                      onClick={() => setLocalItem({ ...localItem, collapsed: !localItem.collapsed })}
                      // onClick={() => toggleFinalActivityCollapse(item.uuid, finalPageData, resetFinalInputs, setFinalPageData, setFinalInputs)} // add this line
                    />
                  )} */}
                </div>
              </div>

              {/* Column headers*/}
              {/* TODO Add description column*/}

              {/* {!localItem.collapsed && (
                <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                  <div className="col-span-1 px-2 border-r border-slate-200 py-2">FEE TYPE</div>
                  <div className="col-span-2 ml-5 border-r border-slate-200 py-2">SUPPLIER</div>
                  <div className="col-span-4">
                    <div className={`grid grid-cols-12 text-xs`}>
                      <div className="col-span-11 ml-5 border-r border-slate-200 py-2">DESCRIPTION</div>
                      <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center"></div>
                    </div>
                  </div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2">
                    <span className="block">NTS/UNITS</span>
                  </div>

                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2">
                    <span className="block">PAX/UNITS</span>
                  </div>
                  <div className="col-span-1 ml-4 border-r border-slate-200 py-2">NET RATE ($)</div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2">TOTAL</div>
                  <div className="col-span-1 ml-5 py-2"></div>
                </div>
              )} */}

              {/* Column propertyItems*/}
              {/* {!localItem.collapsed && (
                <div key={uuidv4()} className={`grid grid-cols-12 text-xs border-b border-slate-200`}>
                  <div className="col-span-1 px-2 border-r border-slate-200 py-2">Unallocated</div>
                  <div className="col-span-2 ml-5 border-r border-slate-200 py-2">Unallocated items</div>
                  <div className="col-span-4">
                    <div className={`grid grid-cols-12 text-xs`}>
                      <div className="col-span-11 ml-5 border-r border-slate-200 py-2 pr-5">Unallocated items</div>
                      <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group"></div>
                    </div>
                  </div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2"></div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2"></div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2"></div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2"></div>
                  <div className="col-span-1 ml-5 py-2 flex items-center"></div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>

      {/* found items */}
      {foundItems.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`bg-[rgba(42,153,163,0.1)] border border-slate-200 brand-text-grey-v2 mb-7 
                  ${customFinalCut && customFinalCut.uuid === foundItem.uuid ? 'animate-pulse' : ''}`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <FinalFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalCut={customFinalCut}
            handlePaste={handlePaste}
            addItem={addItem}
            handleCut={handleCut}
            deleteItem={deleteItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            setCustomFinalCut={setCustomFinalCut}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            handleOpenSlideOut={handleOpenSlideOut}
            setCustomItemToEdit={setCustomItemToEdit}
            setAddFinalItemDetails={setAddFinalItemDetails}
            formatPrice={formatPrice}
            firstDay={firstDay}
            setFinalSlideOutDate={setFinalSlideOutDate}
          />
        </div>
      ))}
    </>
  );
};

export default FinalUnallocated;
