import { formatPrice, getTimeDifference } from '../functions/generalFunctions';
import { getAeroCrsCode, getAirportName, getAirportProperties } from '../functions/transferFunctions';
import { loadPreferredAirlines } from '../functions/loadDataFunctions';
import { StarIcon } from '@heroicons/react/24/solid';
// FlightResultsComponent.jsx
import React from 'react';
import TransferInfoFlightCard from './TransferInfoFlightCard'; // Adjust the import path as needed
import moment from 'moment';
import { parse, isBefore, set } from 'date-fns';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';

const FlightResultsComponent = ({
  flightResults,
  selectedFlightIndex,
  selectedFlight,
  showButtons,
  currentPages,
  buttonsToShow,
  airports,
  flightToSearchAirport,
  localSelectedIndices,
  setLocalSelectedIndices,
  setLocalSelectedIndex,
  handleButtonClick,
  connectingAirport,
  airportId,
  connectingAirportUid,
  transfer,
  handleOptionClickGetFlights,
  flightCost,
  airlineName,
  flightPricing,
  setSelectedFlightIndex,
  setShowButtons,
  searchAgain,
  properties,
  secondFlight,
  flightResultsSecond,
  selectedDirectMultipleValue,
  changeFlight,
  resetSearch,
  firstFlight,
  selectedFlightFirst,
  selectedFlightSecond,
  setHasSufficientTime,
}) => {
  
  const [preferredAirlines, setPreferredAirlines] = useState(null);

  useEffect(() => {
    const fetchPreferredAirlines = async () => {
      try {
        const airlines = await loadPreferredAirlines();
        setPreferredAirlines(airlines);
      } catch (error) {
        console.error("Failed to load preferred airlines:", error);
        setPreferredAirlines([]); // Set to empty array in case of error
      }
    };
    fetchPreferredAirlines();
  }, []);

  const isPreferredAirline = (airlineDesignator) => {
    return preferredAirlines?.some(airline => airline.companyCode === airlineDesignator) || false;
  };

  const isBeforeNoon = (STA) => {
    const arrivalTime = parse(STA, "yyyy/MM/dd HH:mm:ss.SSS", new Date());
    const noon = set(arrivalTime, { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 });
    return isBefore(arrivalTime, noon);
  };

  const getSelectedOuterIndex = (selectedFlightIndex, flightResults) => {
    let accumulatedCount = 0;
    for (let i = 0; i < flightResults.length; i++) {
      accumulatedCount += flightResults[i].flight.length;
      if (selectedFlightIndex !== null && selectedFlight !== null && selectedFlightIndex < accumulatedCount) {
        return i;
      }
    }
    return null;
  };

  const renderFlightInfo = (data, outerIndex, flightResults) => {
    const flights = data.flight;
    const selectedOuterIndex = getSelectedOuterIndex(selectedFlightIndex, flightResults);
    let sufficientTime = true;
    
    if (selectedOuterIndex === null && !showButtons) {

      // This ensures the cards don't load until we've got all the data - thus avoiding a gap between preferred tag appearing
      if (preferredAirlines === null) {
        return null; // Don't render anything until preferredAirlines is loaded
      }

      return (
        <div>
          <div className="mb-3 text-black text-[25px] font-semibold">
            {flights.length > 0 ? (
              <>
                Flight {getAirportName(airports, getAeroCrsCode(airports, flights[0].fromcode))} ({flights[0].fromcode}) - {getAirportName(airports, getAeroCrsCode(airports, flights[0].tocode))} (
                {flights[0].tocode})
              </>
            ) : !changeFlight ? (
              <>
                Flight{' '}
                {getAirportName(airports, getAeroCrsCode(airports, selectedFlight.fromcode)) +
                  ' (' +
                  selectedFlight.fromcode +
                  ') - ' +
                  getAirportName(airports, getAeroCrsCode(airports, selectedFlight.tocode)) +
                  ' (' +
                  selectedFlight.tocode +
                  ')'}
              </>
            ) : (
              'Flight information unavailable'
            )}
          </div>
          <div className="text-black text-sm font-normal mt-5">All Flights ({flights.length})</div>
          <div className="w-full overflow-x-auto -mr-20">
            <div className="flex flex-nowrap mt-2 ml-0 gap-4 cursor-pointer">
              {!searchAgain &&
                flights.slice((currentPages[outerIndex] || 0) * buttonsToShow, (currentPages[outerIndex] || 0) * buttonsToShow + buttonsToShow).map((flight, index) => (
                  <div
                    key={index}
                    className={`flex-none rounded-lg border shadow-sm h-[231px] p-4 hover:bg-[#D6EEF0] hover:border-[#2A99A3] pointer relative ${
                      localSelectedIndices.length !== 0 && index === localSelectedIndices[outerIndex] ? 'border-4 border-[#2A99A3] bg-[#D6EEF0]' : 'bg-white'
                    }`}
                    style={{ width: `calc(33.3333% - 0.75rem)` }}
                    onClick={async (event) => {
                      // Check time between flights for connecting flights - START
                      function checkHasSufficientTime(firstFlightSTA, secondFlightSTD) {
                        const format = 'YYYY/MM/DD HH:mm:ss.SSS';
                        const firstTime = moment(firstFlightSTA, format);
                        const secondTime = moment(secondFlightSTD, format);

                        const differenceInMinutes = secondTime.diff(firstTime, 'minutes');

                        return differenceInMinutes >= 10;
                      }

                      let firstFlightSTA;
                      let secondFlightSTD;
                      if (firstFlight && flightResultsSecond && selectedFlightSecond) {
                        firstFlightSTA = flightResults[0].flight[index].STA;
                        secondFlightSTD = selectedFlightSecond.STD;
                        console.log('firstFlightSTA', firstFlightSTA);
                        console.log('secondFlightSTD', secondFlightSTD);
                      } else if (!firstFlight && flightResultsSecond) {
                        //console.log('selectedFlightFirst', JSON.stringify(selectedFlightFirst, null, 2));
                        console.log('selectedFlightFirst STA', selectedFlightFirst.STA);
                        firstFlightSTA = selectedFlightFirst.STA;
                        console.log('secondFlight', JSON.stringify(secondFlight, null, 2));
                        //console.log('flightResultsSecond', JSON.stringify(flightResultsSecond, null, 2));
                        console.log('flightResultsSecond STD', flightResultsSecond[0].flight[index].STD);
                        secondFlightSTD = flightResultsSecond[0].flight[index].STD;
                        console.log('index', index);
                        console.log('outerIndex', outerIndex);
                      }

                      if (flightResultsSecond && firstFlightSTA && secondFlightSTD && !checkHasSufficientTime(firstFlightSTA, secondFlightSTD)) {
                        console.log('firstFlightSTA', firstFlightSTA);
                        console.log('secondFlightSTD', secondFlightSTD);
                        toast.error('Select flights with at least 10 minutes between flights');
                        sufficientTime = false;
                        setHasSufficientTime(false);
                      } else {
                        sufficientTime = true;
                        setHasSufficientTime(true);
                      }

                      // Check time between flights for connecting flights - END

                      //   setLocalSelectedIndices([]);
                      //   setLocalSelectedIndex([]);
                      setShowButtons(true);
                      //   handleButtonClick(index, outerIndex, flights);
                      handleButtonClick(index, outerIndex, secondFlight);
                      toast.success('Flight selected');
                      console.log('DEBUG 1');
                      console.log('connectingAirport', connectingAirport);
                      console.log('secondFlight', secondFlight);
                      console.log('flightResultsSecond', flightResultsSecond);
                      console.log('connectingAirportUid', connectingAirportUid);
                      console.log('airportId', airportId);
                      console.log('flightToSearchAirport', flightToSearchAirport);
                      if (connectingAirport && connectingAirportUid && !secondFlight && !flightResultsSecond) {
                        let flightToSearch = airports.find((airport) => airport.uid === airportId);
                        let connectingFlightToSearch = airports.find((airport) => airport.uid === connectingAirportUid);
                        const flightToSearchCode = flightToSearch.code;
                        const connectingFlightToSearchCode = connectingFlightToSearch.code;
                        if (transfer.objType === 'arrival') {
                          await handleOptionClickGetFlights(connectingFlightToSearchCode, flightToSearchCode, false);
                          console.log('DEBUG 2');
                        } else if (transfer.objType === 'departure') {
                          //await handleOptionClickGetFlights(connectingFlightToSearchCode, flightToSearchAirport.code, false);
                          await handleOptionClickGetFlights(connectingFlightToSearchCode, flightToSearchAirport.code, false);
                          console.log('connectingFlightToSearchCode', connectingFlightToSearchCode);
                          console.log('flightToSearchAirport.code', flightToSearchAirport.code);
                          console.log('DEBUG 3');
                        } else {
                          await handleOptionClickGetFlights(event, false);
                          console.log('DEBUG 4');
                        }
                        // setLocalSelectedIndices([]);
                        // setLocalSelectedIndex([]);
                      }
                    }}
                  >
                                      {isPreferredAirline(flight.airlineDesignator) && isBeforeNoon(flight.STD) && (
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 bg-yellow-100 text-yellow-800 text-xs font-semibold px-3 py-1 rounded-md shadow-sm">
            Recommended
          </div>
        )}
                    <div className="text-stone-950 text-xl mt-4 font-bold">${formatPrice(flight.flightPricing.saleFare.total)}</div>
                    <div className="text-black text-sm font-normal mt-2 flex">
                      <div className="flex items-center">
                        <span>{moment(flight.STD).format('h:mm A')}</span>{' '}
                      </div>
                      <span className="text-neutral-400 text-xs font-normal ml-2">| {getTimeDifference(flight.STD, flight.STA)}</span>
                    </div>
                    <div className="text-neutral-500 text-sm font-normal flex items-center">
                  {flight.airlineName}
                  {isPreferredAirline(flight.airlineDesignator) && (
                    <StarIcon className="w-4 h-4 text-yellow-400 ml-1" aria-hidden="true" />
                  )}
                </div>
                    <div className="text-neutral-500 text-xs font-normal"># {flight.fltnum}</div>
                    <div className="text-black text-xs font-normal mt-2">
                      {getAirportName(airports, getAeroCrsCode(airports, flight.fromcode))} ({flight.fromcode}) -{' '}
                    </div>
                    <div className="text-black text-xs font-normal mt-2">{getAirportName(airports, getAeroCrsCode(airports, flight.tocode))}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    } else if (selectedOuterIndex === outerIndex || !changeFlight) {
      return (
        <div className="w-full">
          <div className="mb-3 text-black text-[25px] font-semibold">
            {flights.length > 0 ? (
              <>
                Flight {getAirportName(airports, getAeroCrsCode(airports, selectedFlight.fromcode))} ({selectedFlight.fromcode}) -{' '}
                {getAirportName(airports, getAeroCrsCode(airports, selectedFlight.tocode))} ({selectedFlight.tocode})
              </>
            ) : !changeFlight ? (
              <>
                Flight{' '}
                {getAirportName(airports, getAeroCrsCode(airports, selectedFlight.fromcode)) +
                  ' (' +
                  selectedFlight.fromcode +
                  ') - ' +
                  getAirportName(airports, getAeroCrsCode(airports, selectedFlight.tocode)) +
                  ' (' +
                  selectedFlight.tocode +
                  ')'}
              </>
            ) : (
              'Flight information unavailable'
            )}
          </div>
          <TransferInfoFlightCard
            flightCost={flightCost}
            STD={moment(selectedFlight.STD).format('h:mm A')}
            STA={moment(selectedFlight.STA).format('h:mm A')}
            airlineName={airlineName}
            route={
              getAirportName(airports, getAeroCrsCode(airports, selectedFlight.fromcode)) +
              ' (' +
              selectedFlight.fromcode +
              ') - ' +
              getAirportName(airports, getAeroCrsCode(airports, selectedFlight.tocode)) +
              ' (' +
              selectedFlight.tocode +
              ')'
            }
            duration={getTimeDifference(selectedFlight.STD, selectedFlight.STA)}
            flightClass={flightPricing?.saleFare?.selectedFlightClass}
            timeToProperty={getAirportProperties(transfer.propUid, properties).airport.distance}
            transfer={transfer}
            setSelectedFlightIndex={setSelectedFlightIndex}
            setShowButtons={setShowButtons}
            selectedDirectMultipleValue={selectedDirectMultipleValue}
            changeFlight={changeFlight}
            selectedFlight={selectedFlight}
            handleOptionClickGetFlights={handleOptionClickGetFlights}
            resetSearch={resetSearch}
            firstFlight={firstFlight}
          />
        </div>
      );
    } else {
      return (
        <div>
          {selectedOuterIndex} + {outerIndex}
        </div>
      );
    }
  };


  return (
    <div>
      {flightResults.map((data, outerIndex) => (
        <div className="w-full mt-5" key={outerIndex}>
          <div className="w-full">{renderFlightInfo(data, outerIndex, flightResults)}</div>
        </div>
      ))}
    </div>
  );
};

export default FlightResultsComponent;
