import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { addBooking, reCountBookings } from '../functions/itineraryFunctions';

import ButtonPrimary from './Buttons/ButtonPrimarySmall';
import { GiAirplaneArrival } from 'react-icons/gi';
import { GiAirplaneDeparture } from 'react-icons/gi';
import { StarIcon } from '@heroicons/react/20/solid';
import { HiMiniCheckCircle } from 'react-icons/hi2';
import InfoBox from './InfoBox';
import { LiaCampgroundSolid } from 'react-icons/lia';
import { FaStar } from 'react-icons/fa'; 
import { LiaUmbrellaBeachSolid } from 'react-icons/lia';
import { LuHotel } from 'react-icons/lu';
import { LuTent } from 'react-icons/lu';
import { MdLocalOffer } from 'react-icons/md';
import PricingDisplay from './ItineraryPricingDisplay';
import { getStock } from '../functions/stockFunctions';
import { processNorthernGuideVehicleSupplement } from '../functions/vehicleFunctions';
import { useSelector } from 'react-redux';

const propertyIcons = {
  city: LuHotel,
  beach: LiaUmbrellaBeachSolid,
  safariPark: LiaCampgroundSolid,
  // Commented out, grouping safari lodges and mobile camps for now
  // tent: LuTent,
  'ba371388-7f4f-40bc-bc71-d9bbf59bac37': LuTent,
  '29943f8e-2279-44b5-aef5-6b2e4c194045': LuTent,
  '1dd4e1f0-f1aa-4c0b-ab7b-1a4a1426c4bc': LuTent,
};

function ItineraryCard({
  itinerary,
  shuffledRoute, // used for to make images appear in random order
  bookings,
  setBookings,
  setProperties,
  dateStart,
  dateEnd,
  agentObject,
  agentRates,
  rooms,
  setRooms,
  packageTab,
  guests,
  setLoadingData,
  setShowTemplates,
  setNextTopInfo,
  setStartSearchButton,
  setFetchCustom,
}) {
  useEffect(() => {
    console.log(`ItineraryCard component created with UUID: ${itinerary.uuid}`);
  }, [itinerary.uuid]);

  const { user } = useSelector((state) => state.auth);

  // Generate a title based on parentPark if itinerary.name is empty
  const generateTitle = () => {
    if (itinerary.itineraryName) {
      const parentParks = itinerary.route.map((routeItem) => routeItem.nameShort || routeItem.name);
      const lastIndex = parentParks.length - 1;
      const parksString = parentParks
        .map((park, index) => {
          if (index === lastIndex && lastIndex > 0) {
            return `and ${park}`;
          }
          return park;
        })
        .join(', ')
        .replace(/, and/, ' and');
      return `${itinerary.itineraryName}: ${parksString}`;
    } else {
      const parentParks = itinerary.route.map((routeItem) => routeItem.nameShort || routeItem.name);
      const lastIndex = parentParks.length - 1;
      return parentParks
        .map((park, index) => {
          if (index === lastIndex && lastIndex > 0) {
            return `and ${park}`;
          }
          return park;
        })
        .join(', ')
        .replace(/, and/, ' and');
    }
  };

  // Get distinct countries from itinerary.properties
  const distinctCountries = [...new Set(itinerary.route.map((routeItem) => routeItem.country))];

  return (
    <div className="bg-white rounded-2xl shadow border mb-8 grid grid-cols-12 transition duration-500 ease-in-out">
      {/* HEADER ROW */}
      <div className="col-span-12 grid grid-cols-12 border-b min-h-[65px] border-gray-300 p-6 pb-1 pt-2">
        {/* Title column */}
        <div className="col-span-9 flex items-center gap-2">
  {distinctCountries.map((country, index) => (
    <div key={index} className="bg-stone-200 text-neutral-700 text-xs font-semibold px-3 py-1 rounded border border-stone-300">
      {country}
    </div>
  ))}
  {/* <div className="text-neutral-700 text-xs font-semibold px-3 py-1 rounded border border-gray-300">MAP</div> */}
  <div className="flex flex-wrap items-center">
    <h2 className="text-gray-900 text-xl font-bold mr-2">{generateTitle()}</h2>
    {itinerary.nomadFavourite && (
      <div className="flex items-center bg-yellow-100 text-yellow-800 text-xs font-semibold px-3 py-1 rounded border border-yellow-300">
        <StarIcon className="w-4 h-4 mr-1" />
        Nomad Favourite
      </div>
    )}
  </div>
</div>
        {/* Pricing column */}
        <div className="col-span-3 flex justify-end items-center">
          <PricingDisplay price={itinerary.price} totalPax={itinerary.totalPax} />
        </div>
      </div>

      {/* MAIN THREE COLUMN LAYOUT */}
      <div className="col-span-12 grid grid-cols-12 min-h-[300px]">
        {/*** Column 1 - Image carousel ***/}
        <div className="col-span-4 relative">
          {/* {itinerary.exactMatch && <div className=" top-5 left-0 bg-nomadPrimary-600 bg-opacity-70 text-white px-2 py-1 rounded-sm text-s z-10">Exact Match</div>} */}

          {itinerary.exactMatch && <div className="absolute top-5 left-0 bg-nomadPrimary-600 bg-opacity-70 text-white px-2 py-1 rounded-sm text-s z-[1]">Exact Match</div>}
         
          <Swiper spaceBetween={10} slidesPerView={1} navigation={true} modules={[Navigation, Pagination, Mousewheel, Keyboard]} pagination={{ clickable: true }} style={{ height: '100%', zIndex: 0 }}>
            {shuffledRoute
              .filter((routeItem) => routeItem.heroImageUrl)
              .map((routeItem, index) => (
                <SwiperSlide key={index}>
                  <img src={`${routeItem.heroImageUrl}/766x676`} alt={routeItem.propName} className="h-full w-full object-cover rounded-bl-2xl" />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>

        {/*** Column 2 - Itinerary and property content ***/}
        <div className="col-span-5 flex flex-col justify-between pl-7 pr-3 pt-12 pb-6">
          <div>
            {itinerary.nomadLongStay && (
              <div className="-mt-8 mb-4">
                {' '}
                {/* Adjust these values as needed */}
                <InfoBox icon={MdLocalOffer} text="Long Stay Rate Applied at Nomad Camps" bgColor="bg-green-50" textColor="text-green-700" fontSize="text-xs" />
              </div>
            )}
            {itinerary.route.map((routeItem, index) => {
              const PropertyIcon = propertyIcons[routeItem.property.locationCategory] || LiaCampgroundSolid;
              // Set the colour for availability status
              const availabilityColor = routeItem.availability ? (routeItem.availability > 0 ? 'text-[#546742]' : 'text-[#DCA390]') : 'text-[#687478]';
              const checkboxColor = routeItem.availability ? (routeItem.availability > 0 ? 'text-[#94A881]' : 'text-[#B58A7C]') : 'text-[#E4E0DC]';
              return (
                <div key={index} className="flex items-center gap-2 mb-2">
                  {PropertyIcon && <PropertyIcon className="w-5 h-5 text-gray-500" />}
                  <div className="flex flex-wrap items-center text-black text-sm">
                    <span>{routeItem.propName}</span>
                    <span className="text-gray-400 mx-1">•</span>
                    <span>{routeItem.nights} Nights</span>
                    <span className={`pl-2 text-sm flex items-center ${availabilityColor}`}>
                      <HiMiniCheckCircle className={`mr-1 ${checkboxColor}`} />
                      {routeItem.availability ? `${routeItem.availability} Rooms avail.` : 'On request'}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-gray-500 text-xs">Inclusions: {itinerary.inclusions.map((inclusion) => inclusion).join(', ')}</div>
        </div>

        {/*** Column 3 - Airports and Continue button ***/}
        <div className="col-span-3 flex flex-col ml-10 justify-between border-l border-gray-300 px-6 pt-12 pb-6 gap-4">
          <div className="space-y-4 pl-2">
            <div className="flex items-start">
              <GiAirplaneArrival className="text-lg mr-3" />
              <div>
                <div className="text-gray-600 text-xs pb-0.5 font-bold">Arrival airport:</div>
                <div className="text-gray-600 text-xs">{itinerary.route[0].airportName}</div>
              </div>
            </div>
            <div className="flex items-start">
              <GiAirplaneDeparture className="text-lg mr-3" />
              <div>
                <div className="text-gray-600 text-xs pb-0.5 font-bold">Departure airport:</div>
                <div className="text-gray-600 text-xs">{itinerary.route[itinerary.route.length - 1].airportName}</div>
              </div>
            </div>
          </div>
          <ButtonPrimary
            className="mt-auto"
            text="Customise"
            onClick={async () => {
              // RP
              setLoadingData(true);
              console.log(JSON.stringify(itinerary, null, 2));
              console.log('Route');
              console.log(JSON.stringify(itinerary.route, null, 2));
              const route = itinerary.route;
              //setBookings(itinerary.route);
              const uniqueProperties = route.reduce((acc, curr) => {
                if (!acc.find((prop) => prop.uid === curr.property.uid)) {
                  acc.push(curr.property);
                }
                return acc;
              }, []);

              setProperties(uniqueProperties);

              function getIndexByUid(uid) {
                return uniqueProperties.findIndex((property) => property.uid === uid);
              }

              for (const [index, item] of route.entries()) {
                console.log(`${index}\n${item.name}`);
                // Perform asynchronous operations here

                // NORTHERN GUIDE SUPPLEMENT
                const { northernGuideVehicleSupplement, northernGuideVehiclePrice, northernGuideVehicleResRequestId } = processNorthernGuideVehicleSupplement(
                  uniqueProperties,
                  getIndexByUid(item.property.uid),
                  agentObject,
                  agentRates,
                );

                addBooking(
                  getIndexByUid(item.property.uid),
                  item.property.uid,
                  bookings,
                  setBookings,
                  dateStart,
                  rooms,
                  packageTab,
                  uniqueProperties,
                  northernGuideVehicleSupplement,
                  northernGuideVehiclePrice,
                  northernGuideVehicleResRequestId,
                  guests,
                  user,
                  item.nights,
                  null,
                );

                getStock(getIndexByUid(item.property.uid), dateStart, dateEnd, uniqueProperties, setProperties, rooms, setRooms, null, reCountBookings, bookings, setBookings, dateStart, null);

                setFetchCustom(true);

                setTimeout(() => {
                  setStartSearchButton('start');
                  setLoadingData(false);
                  setShowTemplates(false);
                  setNextTopInfo(true);
                }, 500);
                // await console.log('Waiting for 1 second');
              }

              // setBookings(itinerary.route);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ItineraryCard;
