import React, { useState } from "react";

import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";

function Faqs() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto ">
        {/*  Site header */}
        {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="">
              {/* Page title */}
              <div className="mb-5">
                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Faqs</h1>
              </div>

              {/* Posts */}
              <div>
                {/* Post */}
                <article className="py-4 border-b border-slate-200">
                  <header className="flex items-start mb-2">
                    <div className="mt-0 mr-3">
                      <h3 className="text-xl leading-snug text-slate-800 font-bold">1.</h3>
                    </div>
                    <h3 className="text-xl leading-snug text-slate-800 font-bold">Error message adding property while creating a booking.</h3>
                  </header>
                  <div className="pl-7">
                    <div className="mb-2">
                      "The to date must be greater than or equal to the from date"
                      <br />
                      <br />
                      This can be caused if you do not select a date, please try reloading/refreshing the page and selecting a date selector.
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Faqs;
