// This function is used to count the number of guests that are used in a booking
function countUsedGuests(booking, guests) {
  const usedGuests = new Set();

  booking.itineraryData.forEach((item) => {
    item.rooms.forEach((room) => {
      if (room.selectedAgesAdults) {
        room.selectedAgesAdults.forEach((adult) => {
          const guestIndex = guests.findIndex((guest) => guest.uuid === adult.uuid);
          if (guestIndex !== -1 && !usedGuests.has(guestIndex)) {
            usedGuests.add(guestIndex);
          }
        });
      }
      if (room.selectedAges) {
        room.selectedAges.forEach((guest) => {
          const guestIndex = guests.findIndex((g) => g.uuid === guest.uuid);
          if (guestIndex !== -1 && !usedGuests.has(guestIndex)) {
            usedGuests.add(guestIndex);
          }
        });
      }
    });
  });

  return usedGuests.size;
}
export { countUsedGuests };
