import React, { useState, useEffect, useCallback, useRef } from 'react';
import { CheckCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { generateInvoice, updateItineraryStatusToConfirmed, updateItineraryHiddenStatus, fetchConsultantData } from '../../../../functions/viewItineraryFunctions';
import ButtonPrimarySmall from '../../../../components/Buttons/ButtonPrimarySmall';
import ButtonOutlineSmall from '../../../../components/Buttons/ButtonOutlineSmall';
import ItineraryEmailModal from '../../../../components/SendEmailModal';
import { generateConfirmationEmailHTML } from '../utils/emailTemplates';
import { toast } from 'react-toastify';
import { createUrl } from '../../../../functions/makeFunctions';

const ConfirmationStep = ({ title, status, error }) => (
  <div className="flex items-center space-x-4 mb-6">
    <div className="w-8">
      {status === 'pending' && <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-nomadBlue"></div>}
      {status === 'success' && <CheckCircleIcon className="h-6 w-6 text-green-500" />}
      {status === 'error' && <XCircleIcon className="h-6 w-6 text-red-500" />}
      {status === 'idle' && <div className="h-6 w-6 border-2 border-gray-300 rounded-full"></div>}
    </div>
    <div className="flex-grow">
      <span className={`font-medium ${status === 'error' ? 'text-red-500' : 'text-gray-700'}`}>{title}</span>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  </div>
);

const STEPS = {
  SYNC: 'sync',
  DEPOSIT: 'deposit',
  RESERVATIONS: 'reservations',
  INVOICE: 'invoice',
  UNHIDE: 'unhide',
  COMPLETE: 'complete',
  EMAIL: 'email',
};

const ConfirmationProcess = ({ booking, supplierReservations, onSuccess, onError, onClose, user }) => {
  // Determine if deposit check is required
  const requiresDepositCheck = booking.agent?.depositBeforeConfirmation || false;

  const [currentStep, setCurrentStep] = useState(STEPS.SYNC);
  const [stepStatuses, setStepStatuses] = useState({
    [STEPS.SYNC]: 'pending',
    ...(requiresDepositCheck && { [STEPS.DEPOSIT]: 'pending' }),
    [STEPS.RESERVATIONS]: 'pending',
    [STEPS.INVOICE]: 'pending',
    [STEPS.UNHIDE]: 'pending',
  });
  const [showConfirmationButtons, setShowConfirmationButtons] = useState(false);
  const [error, setError] = useState(null);
  const [errorStep, setErrorStep] = useState(null);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [generatingAttachments, setGeneratingAttachments] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [attachmentErrors, setAttachmentErrors] = useState({});
  const [consultant, setConsultant] = useState(null);
  const [emailContent, setEmailContent] = useState({ header: '', body: '', footer: '', subject: '' });

  const bookingRef = useRef(booking);
  const supplierReservationsRef = useRef(supplierReservations);
  const userRef = useRef(user);

  useEffect(() => {
    bookingRef.current = booking;
    supplierReservationsRef.current = supplierReservations;
    userRef.current = user;
  }, [booking, supplierReservations, user]);

  useEffect(() => {
    bookingRef.current = booking;
    supplierReservationsRef.current = supplierReservations;
    userRef.current = user;

    // Fetch consultant data
    const getConsultantData = async () => {
      if (booking.consultant && booking.consultant.uid) {
        const consultantData = await fetchConsultantData(booking.consultant.uid);
        setConsultant(consultantData);
      }
    };
    getConsultantData();
  }, [booking, supplierReservations, user]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const checkSyncStatus = async () => {
    setCurrentStep(STEPS.SYNC);
    await delay(1000);
    if (!bookingRef.current.creatioBookingUid && bookingRef.current.syncState === 'synced') {
      throw new Error('Booking not synced properly. Please ensure the booking is synced with Creatio before confirming.');
    }
    setStepStatuses((prev) => ({ ...prev, [STEPS.SYNC]: 'success' }));
  };

  const checkDepositStatus = async () => {
    setCurrentStep(STEPS.DEPOSIT);
    await delay(1000);

    if (bookingRef.current.agent?.depositBeforeConfirmation && !bookingRef.current.depositPaid) {
      throw new Error('Deposit payment is required before confirmation for this agent. Please ensure the deposit has been paid.');
    }
    setStepStatuses((prev) => ({ ...prev, [STEPS.DEPOSIT]: 'success' }));
  };

  const unhideItinerary = async () => {
    setCurrentStep(STEPS.UNHIDE);
    await delay(1000);
    if (bookingRef.current.hidden) {
      await updateItineraryHiddenStatus(bookingRef.current.uid, false);
    }
    setStepStatuses((prev) => ({ ...prev, [STEPS.UNHIDE]: 'success' }));
  };

  const checkReservations = async () => {
    setCurrentStep(STEPS.RESERVATIONS);
    await delay(1300);
    const unconfirmedReservations = supplierReservationsRef.current.filter((res) => res.reservationStage !== 'confirmed' && !res.deleted);
    if (unconfirmedReservations.length > 0) {
      throw new Error('Some reservations are not confirmed. Please confirm all non-deleted supplier reservations before proceeding.');
    }
    setStepStatuses((prev) => ({ ...prev, [STEPS.RESERVATIONS]: 'success' }));
  };

  const generateInvoiceStep = async () => {
    setCurrentStep(STEPS.INVOICE);
    await delay(1500);
    await generateInvoice(bookingRef.current.uid);
    setStepStatuses((prev) => ({ ...prev, [STEPS.INVOICE]: 'success' }));
  };

  const completeProcess = async () => {
    await delay(1000);
    setCurrentStep(STEPS.COMPLETE);
  };

  const updateItineraryStatus = async () => {
    await updateItineraryStatusToConfirmed(booking.uid);
    toast.success('Itinerary status updated to Confirmed', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    onSuccess();
  };

  const handleContinueAndConfirm = async () => {
    // Show confirmation buttons instead of immediately confirming
    setShowConfirmationButtons(true);
  };

  const handleFinalConfirm = async () => {
    await updateItineraryStatus();
    onClose();
  };

  const handleBack = () => {
    setShowConfirmationButtons(false);
  };

  const handleContinueAndEmail = async () => {
    if (currentStep === STEPS.COMPLETE) {
      // Generate the confirmation email content when needed
      const emailData = generateConfirmationEmailHTML({
        booking: bookingRef.current,
        userAddedContent: `Congratulations! Your itinerary for ${bookingRef.current.clientName} has been confirmed.`,
        consultant: consultant,
        user,
      });
      
      setEmailContent(emailData);
      setCurrentStep(STEPS.EMAIL);
      setIsEmailModalOpen(true);
    }
  };

  const handleError = (error) => {
    console.error('Error in confirmation process:', error);
    setStepStatuses((prev) => {
      const updatedStatuses = { ...prev };
      const stepKeys = Object.keys(updatedStatuses);
      const currentIndex = stepKeys.indexOf(currentStep);
      const errorStepIndex = Math.min(currentIndex + 1, stepKeys.length - 1);
      updatedStatuses[stepKeys[errorStepIndex]] = 'error';
      for (let i = errorStepIndex + 1; i < stepKeys.length; i++) {
        updatedStatuses[stepKeys[i]] = 'idle';
      }
      return updatedStatuses;
    });
    setError(error.message);
    const stepKeys = Object.keys(stepStatuses);
    const currentIndex = stepKeys.indexOf(currentStep);
    const errorStepIndex = Math.min(currentIndex + 1, stepKeys.length - 1);
    setErrorStep(stepKeys[errorStepIndex]);
    onError(error);
  };

  const runConfirmationProcess = useCallback(async () => {
    try {
      await checkSyncStatus();
      if (requiresDepositCheck) {
        await checkDepositStatus();
      }
      await checkReservations();
      await generateInvoiceStep();
      await unhideItinerary();
      await completeProcess();
    } catch (error) {
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    runConfirmationProcess();
  }, [runConfirmationProcess]);

  const generateAttachments = useCallback(async () => {
    setGeneratingAttachments(true);
    setAttachmentErrors({});
    let newAttachments = [];
    let errors = {};

    const generateDocument = async (type, name) => {
      try {
        const result = await createUrl(bookingRef.current.uid, type);
        if (result.error) {
          throw new Error(result.errorMsg);
        }
        if (!result.download || result.download === '') {
          throw new Error(`No download URL provided for ${name}`);
        }
        return {
          name: name,
          url: result.download,
          generating: false,
        };
      } catch (error) {
        console.error(`Error generating ${name}:`, error);
        errors[type] = error.message;
        return null;
      }
    };

    const costingAttachment = await generateDocument(
      'costing', 
      `${bookingRef.current.code} - ${bookingRef.current.clientName} - Itemised Quote.xlsx`
    );
    if (costingAttachment) newAttachments.push(costingAttachment);

    const invoiceAttachment = await generateDocument(
      'invoice', 
      `${bookingRef.current.invoice?.reference || 'Invoice'}.pdf`
    );
    if (invoiceAttachment) newAttachments.push(invoiceAttachment);

    setAttachments(newAttachments);
    setAttachmentErrors(errors);
    setGeneratingAttachments(false);

    if (Object.keys(errors).length > 0) {
      const errorMessages = Object.values(errors).join('. ');
      toast.error(`Failed to generate some attachments: ${errorMessages}. Please try again.`);
    }
  }, []);

  useEffect(() => {
    if (isEmailModalOpen) {
      generateAttachments();
    }
  }, [isEmailModalOpen, generateAttachments]);

  const handleRetry = useCallback(() => {
    setError(null);
    setErrorStep(null);
    setStepStatuses({
      [STEPS.SYNC]: 'pending',
      [STEPS.RESERVATIONS]: 'pending',
      [STEPS.INVOICE]: 'pending',
    });
    setCurrentStep(STEPS.SYNC);
    runConfirmationProcess();
  }, [runConfirmationProcess]);

  const steps = [
    { id: STEPS.SYNC, title: 'Checking sync with Creatio' },
    ...(requiresDepositCheck ? [{ id: STEPS.DEPOSIT, title: 'Verifying deposit payment status' }] : []),
    { id: STEPS.RESERVATIONS, title: 'Verifying all suppliers are set to confirmed' },
    { id: STEPS.INVOICE, title: 'Generating invoice details' },
    { id: STEPS.UNHIDE, title: 'Unhiding itinerary (if hidden)' },
  ];

  const isProcessComplete = currentStep === STEPS.COMPLETE;
  const hasError = errorStep !== null;

  const handleSendEmail = async () => {
    try {
      await updateItineraryStatusToConfirmed(bookingRef.current.uid);
      toast.success('Itinerary status updated to Confirmed', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsEmailModalOpen(false);
      onSuccess();
    } catch (error) {
      console.error('Error updating itinerary status:', error);
      toast.error('Failed to update itinerary status. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="w-full mx-auto">
    <div className="bg-white rounded-lg p-6 relative"> {/* Increased padding */}
      <button 
        onClick={onClose} 
        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors p-1 hover:bg-gray-100 rounded-full" /* Added hover effect */
        aria-label="Close"
      >
        <XMarkIcon className="h-6 w-6" />
      </button>
  
      <h2 className="text-3xl font-roca text-nomadBlue mb-6">Pre-Confirmation Checks</h2> {/* Increased margin */}
  
      <div className="space-y-4 mb-8 border border-[#f0ece7] rounded-lg p-6 bg-gray-50"> {/* Added rounded corners, background, increased padding */}
        {steps.map((step) => (
          <ConfirmationStep 
            key={step.id} 
            title={step.title} 
            status={stepStatuses[step.id]} 
            error={step.id === errorStep ? error : null} 
          />
        ))}
      </div>
  
      {/* Show processing state during steps */}
      {!isProcessComplete && !hasError && (
        <div className="flex justify-end">
          <ButtonPrimarySmall text="Processing..." isLoading={true} disabled={true} color="dark" />
        </div>
      )}
  
      {/* Show error state */}
      {hasError && (
        <div className="flex justify-end">
          <ButtonPrimarySmall text="Retry" onClick={handleRetry} color="dark" />
        </div>
      )}
  
      {/* Show action buttons when complete */}
      {isProcessComplete && !hasError && (
        <>
          <div className="mb-6 p-4 bg-green-50 border border-green-100 rounded-lg"> {/* Added success message box */}
            <p className="text-green-700 font-medium mb-2">✓ All checks completed successfully!</p>
            <p className="text-gray-600 mb-3">Choose how you'd like to proceed:</p>
            <ul className="ml-4 space-y-2 text-gray-600">
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Click <span className="font-medium">"Continue & Confirm"</span> to update the status without sending an email</span>
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Click <span className="font-medium">"Continue & Send Email"</span> to update the status and notify the agent via email</span>
              </li>
            </ul>
          </div>
  
          <div className="flex justify-end space-x-4">
            {!showConfirmationButtons ? (
              <>
                <ButtonOutlineSmall text="Continue & Confirm" onClick={handleContinueAndConfirm} color="dark" />
                <ButtonPrimarySmall text="Continue & Send Email" onClick={handleContinueAndEmail} color="dark" />
              </>
            ) : (
              <div className="flex items-center space-x-4 bg-yellow-50 p-4 rounded-lg w-full"> {/* Added confirmation warning box */}
                <div className="flex-grow">
                  <p className="text-yellow-700 font-medium">Are you sure you want to confirm immediately?</p>
                  <p className="text-sm text-gray-600 mt-1">This will update the itinerary status without sending an email.</p>
                </div>
                <div className="flex space-x-3">
                  <ButtonOutlineSmall text="Back" onClick={handleBack} color="dark" />
                  <ButtonPrimarySmall text="Confirm Itinerary" onClick={handleFinalConfirm} />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
    <ItineraryEmailModal
    modalTitle="Send Confirmation Email"
    isOpen={isEmailModalOpen}
    setIsOpen={setIsEmailModalOpen}
    prefillContent={emailContent.body}
    booking={bookingRef.current}
    generateEmailContent={generateConfirmationEmailHTML}
    staticHeader={emailContent.header}
    staticFooter={emailContent.footer}
    from={`${userRef.current.firstName} ${userRef.current.lastName} <${userRef.current.email}>`}
    to={consultant ? `${consultant.fullName} <${consultant.email}>` : ''}
    subject={emailContent.subject}
    onSend={handleSendEmail}
    generatingAttachments={generatingAttachments}
    attachments={attachments}
    attachmentErrors={attachmentErrors}
    onRegenerateAttachments={generateAttachments}
  />
  </div>
  );
};

export default ConfirmationProcess;
