import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs, Timestamp } from 'firebase/firestore';
import { format, isBefore, parseISO } from 'date-fns';
import moment from 'moment';
import { IoIosDocument, IoMdCheckmark } from 'react-icons/io';
import { v4 as uuidv4 } from 'uuid';

import { countUsedGuests } from '../functions/guestsFunctions';
import { formatPrice } from '../functions/generalFunctions';

const ManageBookingsHeader = ({ booking, guests, supplierReservations }) => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const status = booking.status;

  const [supplierCounts, setSupplierCounts] = useState({
    provisional: 0,
    pendingProvisional: 0,
    confirmed: 0,
    pendingConfirmation: 0
  });
  const [nearestExpiryDate, setNearestExpiryDate] = useState(null);

  useEffect(() => {
    const counts = {
      provisional: 0,           // provisionals with provisionalRequired
      pendingProvisional: 0,   // non-provisionals with provisionalRequired
      confirmed: 0,            // confirmed reservations
      pendingConfirmation: 0   // all non-confirmed reservations
    };
  
    console.log('Starting count calculation...');
    supplierReservations.forEach(reservation => {
      console.log('Processing reservation:', reservation);
      
      // Handle provisional counts
      if (reservation.provisionalRequired) {
        if (reservation.reservationStage === 'provisional') {
          counts.provisional++;
          console.log('Added provisional');
        } else {
          counts.pendingProvisional++;
          console.log('Added pendingProvisional');
        }
      }
  
      // Handle confirmation counts
      if (reservation.reservationStage === 'confirmed') {
        counts.confirmed++;
        console.log('Added confirmed');
      } else {
        counts.pendingConfirmation++;
        console.log('Added pendingConfirmation');
      }
    });
  
    console.log('Final counts:', counts);
    
    const expiryDate = booking.dateExpiry instanceof Timestamp 
      ? booking.dateExpiry.toDate() 
      : new Date(booking.dateExpiry);
  
    setSupplierCounts(counts);
    setNearestExpiryDate(expiryDate);
  }, [supplierReservations, booking.dateExpiry]);

  const guestsConfirmed = guests.filter((guest) => guest.detailsComplete).length;
  const guestsTotal = countUsedGuests(booking, guests);
  const guestsTask = guestsConfirmed === guestsTotal ? 0 : 1;
  const guestsTickColor = guestsTask === 0 ? 'brand-text-color-v2' : 'text-neutral-200';

  const { depositTitle, depositText, depositTickColor, depositTask } = getDepositInfo(booking);
  const { balanceTitle, balanceText, balanceTickColor, balanceTask } = getBalanceInfo(booking);

  const { headerBgColor, headerTextColor, headerIconColor, headerIconBgColor, headerText, headerSubText, btns } = getHeaderInfo(status, supplierCounts, nearestExpiryDate, guestsTask, balanceTask, depositTask);

  return (
    <div key={uuidv4()} style={{ zIndex: 1000, WebkitTransform: 'translateZ(0)' }} className="mb-5 mt-5">
      <div className={`rounded-[15px] ${headerBgColor}`}>
        <div key={uuidv4()} className="grid grid-cols-12">
          <div className="col-span-1 flex justify-end">
            <div className={`w-[46px] h-[46px] ${headerIconBgColor} rounded-lg flex items-center justify-center my-5`}>
              <IoIosDocument size={30} className={`${headerIconColor}`} />
            </div>
          </div>
          <div className="col-span-11 flex flex-row items-center justify-start ml-10">
            <div>
              <div className={`text-xl font-semibold leading-tight ${headerTextColor}`}>{headerText}</div>
              <div className="text-neutral-500 text-base font-normal leading-[18.20px] pt-2">{headerSubText}</div>
              {btns}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getDepositInfo = (booking) => {
  let depositTitle, depositText, depositTickColor = 'text-neutral-200', depositTask = 1;

  if (booking.totalDeposit === 0) {
    depositTitle = 'Pay Deposit';
    depositText = 'No deposit is due for this booking';
  } else if (booking.totalDeposit > 0 && booking.totalPaid === 0) {
    depositTitle = 'Pay Deposit';
    depositText = `Pay deposit of ${formatPrice(booking.totalDeposit)} before ${moment(booking.depositDueDate).format('MMMM Do, YYYY')}`;
  } else if (booking.totalDeposit > 0 && booking.totalPaid > 0 && booking.depositPaid === false) {
    depositTitle = 'Pay Deposit';
    depositText = `You've paid $${formatPrice(booking.totalPaid)} of your $${formatPrice(booking.totalDeposit)} deposit due before ${moment(booking.depositDueDate).format('MMMM Do, YYYY')}`;
  } else if (booking.depositPaid === true) {
    depositTitle = 'Deposit Paid';
    depositText = `We've received your deposit of $${formatPrice(booking.totalDeposit)} thank you`;
    depositTickColor = 'brand-text-color-v2';
    depositTask = 0;
  } else {
    depositTitle = 'Pay Deposit';
    depositText = `Unable to view. Please contact your consultant for details`;
  }

  return { depositTitle, depositText, depositTickColor, depositTask };
};

const getBalanceInfo = (booking) => {
  let balanceTitle, balanceText, balanceTickColor = 'text-neutral-200', balanceTask = 1;

  if (booking.totalPaid === booking.totalPrice) {
    balanceTitle = 'Balance Paid';
    balanceText = `We've received full payment for this booking, thank you`;
    balanceTickColor = 'brand-text-color-v2';
    balanceTask = 0;
  } else if (booking.depositPaid === false) {
    balanceTitle = 'Pay Balance';
    balanceText = `Pay total of $${formatPrice(booking.totalPrice - booking.totalDeposit)} before ${moment(booking.balanceDueDate).format('MMMM Do, YYYY')}`;
  } else if (booking.depositPaid === true) {
    balanceTitle = 'Balance Paid';
    balanceText = `You have $${formatPrice(booking.totalOutstanding)} to be paid before ${moment(booking.balanceDueDate).format('MMMM Do, YYYY')}`;
  } else {
    balanceTitle = 'Pay Balance';
    balanceText = `Unable to view. Please contact your consultant for details`;
  }

  return { balanceTitle, balanceText, balanceTickColor, balanceTask };
};

const getHeaderInfo = (status, supplierCounts, nearestExpiryDate, guestsTask, balanceTask, depositTask) => {
  let headerBgColor, headerTextColor, headerIconColor, headerIconBgColor, headerText, headerSubText, btns;

  switch (status) {
    case 'confirmed':
      headerBgColor = 'bg-nomadEvergreen-300';
      headerTextColor = 'text-black';
      headerIconColor = 'text-nomadEvergreen-600';
      headerIconBgColor = 'bg-nomadEvergreen-200';
      headerText = 'All services are booked and your trip is confirmed!';
      headerSubText = `You have ${guestsTask + balanceTask + depositTask} task${guestsTask + balanceTask + depositTask > 1 ? 's' : ''} to complete before departure`;
      break;
    case 'quotation':
    case 'draft':
      headerBgColor = 'bg-[#F0ECE7]';
      headerTextColor = 'text-black';
      headerIconColor = 'text-[#C5BDB4]';
      headerIconBgColor = 'bg-[#F4F3F2]';
      headerText = 'No services held.';
      headerSubText = 'You can edit this itinerary if you wish to progress.';
      break;
    case 'pendingCancellation':
      headerBgColor = 'bg-[#FEE2DD]';
      headerTextColor = 'text-nomadPrimary-900';
      headerIconColor = 'text-[#FC735B]';
      headerIconBgColor = 'bg-[#FFC7BD]';
      headerText = 'Pending Cancellation';
      headerSubText = 'Our team are reviewing your cancellation request and will be in touch soon.';
      break;
    case 'provisional':
      headerBgColor = 'bg-[#D4EBFF]';
      headerTextColor = 'text-black';
      headerIconColor = 'text-[#3699FF]';
      headerIconBgColor = 'bg-[#D0E3F6]';
      headerText = 'Your Itinerary is in Provisional State';
      headerSubText = `${supplierCounts.provisional} supplier${supplierCounts.provisional !== 1 ? 's' : ''} held on provisional status, all other services will be booked on confirmation. ${
        nearestExpiryDate 
        ? `Your closest provisional expires on ${moment(nearestExpiryDate).utc().format('MMMM Do, YYYY [at] HH:mm [UTC]')}`
        : 'No expiry date available'
    }`;
      break;
      case 'pendingProvisional':
        headerBgColor = 'bg-[#D4EBFF]';
        headerTextColor = 'text-black';
        headerIconColor = 'text-[#3699FF]';
        headerIconBgColor = 'bg-[#D0E3F6]';
        headerText = 'Pending Provisional: Awaiting supplier responses';
        const totalProvisionalRequired = supplierCounts.provisional + supplierCounts.pendingProvisional;
        if (totalProvisionalRequired === 0) {
          headerSubText = 'No suppliers require provisional status for this booking.';
        } else if (supplierCounts.pendingProvisional === 0) {
          headerSubText = `All ${totalProvisionalRequired} supplier${totalProvisionalRequired !== 1 ? 's have' : ' has'} confirmed provisional status.`;
        } else {
          let pendingText = `Waiting for ${supplierCounts.pendingProvisional} supplier${supplierCounts.pendingProvisional !== 1 ? 's' : ''} to confirm provisional status.`;
          
          headerSubText = `${pendingText} (${supplierCounts.provisional}/${totalProvisionalRequired} confirmed)`;
        }
        break;
    case 'pendingConfirmation':
      headerBgColor = 'bg-[#FCEEC1]';
      headerTextColor = 'text-black';
      headerIconColor = 'text-[#FFBB38]';
      headerIconBgColor = 'bg-[#F2D77F]';
      headerText = 'Pending Confirmation';
      headerSubText = `${supplierCounts.confirmed} supplier${supplierCounts.confirmed !== 1 ? 's' : ''} confirmed, pending confirmation from ${supplierCounts.pendingConfirmation} more supplier${supplierCounts.pendingConfirmation !== 1 ? 's' : ''}.`;
      break;
    case 'cancelled':
      headerBgColor = 'bg-[#F0ECE7]';
      headerTextColor = 'text-black';
      headerIconColor = 'text-[#C5BDB4]';
      headerIconBgColor = 'bg-[#F4F3F2]';
      headerText = 'Your Itinerary Has Been Cancelled.';
      headerSubText = 'All services booked for this reservation have been canceled.';
      break;
    case 'expired':
      headerBgColor = 'bg-[#F0ECE7]';
      headerTextColor = 'text-black';
      headerIconColor = 'text-[#C5BDB4]';
      headerIconBgColor = 'bg-[#F4F3F2]';
      headerText = 'Your Itinerary Has Expired.';
      headerSubText = 'You can edit this itinerary if you wish to progress.';
      break;
    default:
      headerBgColor = 'bg-[#F3F5F5]';
      headerTextColor = 'text-black';
      headerIconColor = 'text-[#CECECE]';
      headerIconBgColor = 'bg-[#E1E9E9]';
      headerText = 'Status unknown';
      break;
  }

  return { headerBgColor, headerTextColor, headerIconColor, headerIconBgColor, headerText, headerSubText, btns };
};

export default ManageBookingsHeader;