import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import { convertTimestamps } from "../../functions/generalFunctions";
import { db } from "../../db/firebase.config";

// Get users
const fetchName = "properties";

const getProperties = async () => {
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    //const q = query(fetchRef);
    const q = query(fetchRef, where("platformResRequest.active", "==", true), where("active", "==", true), orderBy("name", "asc")); // WORKING 2

    const querySnapshot = await getDocs(q);

    const fetch = [];

    querySnapshot.forEach((doc) => {
      let data = doc.data();

      // Convert all Firestore timestamps to JavaScript Date objects
      data = convertTimestamps(data);

      return fetch.push({
        ...data,
        uid: doc.id,
        selected: false,
        getAccom: false,
      });
    });
    var jsonResult = {
      result: fetch,
    };
    console.log("REDUX: getProperties");
    //console.log(JSON.stringify(jsonResult, undefined, 4));
    return { jsonResult };
  } catch (error) {
    console.log(error);
  }
};

const propertiesService = {
  getProperties,
};

export default propertiesService;
