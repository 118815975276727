import { Configure, Hits, InstantSearch, Pagination, connectHits, connectSearchBox } from 'react-instantsearch-dom';
import React, { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import {
  arrivalDepartureTimeOptions,
  findMatchingFlightRoutes,
  findTransfer,
  getAeroCrsCode,
  getAirportCodeFromUid,
  getAirportCodes,
  getAirportDetails,
  getAirportName,
  getAirportProperties,
  getLocationDetails,
} from '../functions/transferFunctions';
import { calculateFlightCost, calculateFlightPricing, getAvailability, getDeepLink, processFlights } from '../functions/aeroFunctions';
import { capitalizeFirstLetter, formatPrice, getPropObj, nomadVehicleRates, nonNomadVehicleRates } from '../functions/generalFunctions';
import { closeForm, handleFormSubmitAirport, handleFormSubmitLocation, handleFormSubmitOwnArrangementsSingle, handleOptionClick } from '../functions/transferFormsFunctions';
import { loadFlightLocations, loadFlightRoutes, loadTransferRoutes } from '../functions/loadDataFunctions';

import CustomModal from '../components/CustomModal';
import { LocationItem } from '../components/LocationItem';
import PackedLunch from './PackedLunch';
import SearchBox from '../components/SearchBox';
import algoliasearch from 'algoliasearch';
import { getTimeDifference } from '../functions/generalFunctions';
import moment from 'moment';
import qs from 'qs';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;

const searchClient = algoliasearch(appId, apiKey);

//export const INSTANT_SEARCH_INDEX_NAME = "locations";

//export const INSTANT_SEARCH_QUERY_SUGGESTIONS = "agents_query_suggestions";

//export const INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTES = ["name"];

const TransferSingle = ({
  transfer,
  deeShadow,
  SlPlane,
  timeRef,
  MdOutlineLocationOn,
  FaCampground,
  CgUnavailable,
  BiTransfer,
  setSelectedTransferTuuid,
  airports,
  transfers,
  properties,
  freshUuid,
  locations,
  customLocations,
  setTransfers,
  setIsModalOpen,
  setRefreshTransferRates,
  bookings,
  setBookings,
  booking,
  agentObject,
  suppliers,
  airlines,
}) => {
  // // Deal with departure flight details
  // const airportNameRef = useRef({ value: "" });
  // const airportUidRef = useRef({ value: "" });
  /// Deal with departure flight details
  const [flightToShow, setFlightToShow] = useState(null);
  const [matchingAirports, setMatchingAirports] = useState(null);
  const [locationToShow, setLocationToShow] = useState(null);

  // const [subLocationToShow, setSubLocationToShow] = useState(null);
  // const [subLocations, setSubLocations] = useState([]);

  // Handle border and showing forms:
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);

  // OWN ARRANGEMENTS
  const [sortedLocations, setSortedLocations] = useState([]);
  const [locationName, setLocationName] = useState(null);
  const [locationUid, setLocationUid] = useState(null);
  const [supplierName, setSupplierName] = useState(null);
  const [supplierUid, setSupplierUid] = useState(null);
  const [airport, setAirport] = useState(false);
  const [airportName, setAirportName] = useState(null);
  const [airportUid, setAirportUid] = useState(null);
  const [airlineNameOwnArrangements, setAirlineNameOwnArrangements] = useState(null);
  const [airlineUidOwnArrangements, setAirlineUidOwnArrangements] = useState(null);
  const [fltNum, setFltNum] = useState('');
  const [timeOwnArrangements, setTimeOwnArrangements] = useState('Select time');
  const [dateOwnArrangements, setDateOwnArrangements] = useState(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);
  const [timeFlightOwnArrangements, setTimeFlightOwnArrangements] = useState('Select time');
  const [dateFlightOwnArrangements, setDateFlightOwnArrangements] = useState(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);
  const [flash, setFlash] = useState(false);
  const [noTransfers, setNoTransfers] = useState(false);

  const [addFlightDetails, setAddFlightDetails] = useState(false);
  const [addFlightDetailsLater, setAddFlightDetailsLater] = useState(false);
  const [includeDepartureTransfer, setIncludeDepartureTransfer] = useState(true);
  const [includeArrivalTransfer, setIncludeArrivalTransfer] = useState(true);

  const [selectedLocation, setSelectedLocation] = useState(null);
  // const [selectedSubLocation, setSelectedSubLocation] = useState(null);
  // const [isFormVisible, setIsFormVisible] = useState(false);

  const [finalFlightUid, setFinalFlightUid] = useState(null);
  const [finalOriginFlightUid, setFinalOriginFlightUid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [flightResults, setFlightResults] = useState(false);
  //const [currentPage, setCurrentPage] = useState(0);
  const [currentPages, setCurrentPages] = useState([]);
  const [buttonsToShow, setButtonsToShow] = useState(7); // User 7
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [selectedFlightIndex, setSelectedFlightIndex] = useState(null);
  const [localSelectedIndex, setLocalSelectedIndex] = useState(null);
  const [showFlightDetails, setShowFlightDetails] = useState(false);
  const [localSelectedIndices, setLocalSelectedIndices] = useState([]);

  const [flightPage, setFlightPage] = useState(1);

  const [loadInfants, setLoadInfants] = useState(0);

  const [flightInfants, setFlightInfants] = useState(loadInfants);

  const [searchAgain, setSearchAgain] = useState(false);
  const [reCheckPax, setReCheckPax] = useState(false);

  const [flightCost, setFlightCost] = useState(0);

  const [connectionType, setConnectionType] = useState(null);
  const [connectionClick, setConnectionClick] = useState(null);

  const [airlineName, setAirlineName] = useState('');

  //const [dateAdjusted, setDateAdjusted] = useState(transfer.formValues && transfer.formValues.dateAdjusted ? transfer.formValues.dateAdjusted : false);
  const [dateAdjusted, setDateAdjusted] = useState(transfer.formValues && transfer.formValues.dateAdjusted === 'true' ? true : false);

  //filters for parks

  const [parkFilters, setParkFilters] = useState([]);
  const [airportFilters, setAirportFilters] = useState([]);

  const [searchStateLocation, setSearchStateLocation] = useState(null);
  const [searchStateAirport, setSearchStateAirport] = useState(null);
  const timerRef = useRef(null);

  const [numHits, setNumHits] = useState(1000);
  const [searchFilters, setSearchFilters] = useState([]);
  const [airportSearchFilters, setAirportSearchFilters] = useState([]);

  const [selectedFlightAlgolia, setSelectedFlightAlgolia] = useState('Search by airport');
  const [selectedFlightAlgoliaName, setSelectedFlightAlgoliaName] = useState('Search by airport');

  const [selectedLocationAlgolia, setSelectedLocationAlgolia] = useState('');
  const [selectedLocationAlgoliaName, setSelectedLocationAlgoliaName] = useState('Search by airport, property or other');
  const [selectedLocationAlgoliaUid, setSelectedLocationAlgoliaUid] = useState('');
  const [selectedParkAlgoliaUid, setSelectedParkAlgoliaUid] = useState('');
  const [selectedLocationAlgoliaType, setSelectedLocationAlgoliaType] = useState('');

  //const [locationTime, setLocationTime] = useState("Select time");

  const [locationTime, setLocationTime] = useState('Select time');
  const [addLater, setAddLater] = useState(false);

  const checkCustomLocations = customLocations.find((location) => location.propUid === transfer.propUid && location.uuid === transfer.uuid);

  const [parkId, setParkId] = useState(
    properties[getPropObj(transfer.propUid, properties)].mobileLocation ? checkCustomLocations.parkId : properties[getPropObj(transfer.propUid, properties)].park.id,
  );
  const [locationId, setLocationId] = useState(
    properties[getPropObj(transfer.propUid, properties)].mobileLocation ? checkCustomLocations.parkId : properties[getPropObj(transfer.propUid, properties)].park.id,
  );
  const [airportId, setAirportId] = useState(
    properties[getPropObj(transfer.propUid, properties)].mobileLocation ? checkCustomLocations.airportId : properties[getPropObj(transfer.propUid, properties)].airport.id,
  );

  const [modalOpen, setModalOpen] = useState(true);
  const [formattedMatchingAirports, setFormattedMatchingAirports] = useState([]);
  const [nearestAirport, setNearestAirport] = useState([]);

  const [flightPricing, setFlightPricing] = useState([]);

  const [packedLunch, setPackedLunch] = useState(false);
  const [packedLunchChargeable, setPackedLunchChargeable] = useState(false);

  const [flightLocations, setFlightLocations] = useState([]);

  useEffect(() => {
    const fetchAirportDetails = async () => {
      const fetchedAirportDetails = await getAirportDetails(airports, properties, transfer, customLocations);
      setNearestAirport(fetchedAirportDetails);
      console.log('fetchedAirportDetails');
      console.log(JSON.stringify(fetchedAirportDetails, undefined, 4));
    };

    fetchAirportDetails();
  }, []); // the empty array [] makes it run only once on component mount

  useEffect(() => {
    const formatAndSortMatchingAirports = (matchedAirports) => {
      if (!matchedAirports) return [];

      const tempAirports = airports.filter((airport) => matchedAirports.some((matchedAirport) => matchedAirport.destination.id === airport.uid));

      return tempAirports
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((airport) => ({
          value: airport.uid,
          label: airport.name,
        }));
    };

    if (matchingAirports) {
      const formatted = formatAndSortMatchingAirports(matchingAirports);
      setFormattedMatchingAirports(formatted);
    }
  }, [airports, formattedMatchingAirports, matchingAirports]);

  useEffect(() => {
    console.log('Locations:');
    console.log(JSON.stringify(locations, null, 2));
    const sorted = locations.sort((a, b) => a.name.localeCompare(b.name));
    setSortedLocations(sorted.map((loc) => ({ value: loc.uid, label: `${loc.name}` })));
  }, [locations]);

  useEffect(() => {
    if (!transfer.formValues || !transfer.edit) {
      setSelectedLocation(sortedLocations.find((location) => location.value === selectedOption));
    }
  }, [selectedOption, setSelectedLocation, sortedLocations, transfer.formValues, transfer.edit]);

  const [resetTimeButton, setResetTimeButton] = useState(false);
  const [loadAdults, setLoadAdults] = useState(0);
  const [loadChildren, setLoadChildren] = useState(0);

  const [flightAdults, setFlightAdults] = useState(0);
  const [flightChildren, setFlightChildren] = useState(0);
  const [selectedAges, setSelectedAges] = useState(transfer.selectedAges);
  const [selectedAgesAdults, setSelectedAgesAdults] = useState(transfer.selectedAgesAdults);

  useEffect(() => {
    if (transfer.formValues && transfer.formValues.adults) {
      setLoadAdults(transfer.formValues.adults);
      setFlightAdults(transfer.formValues.adults);
    } else {
      // setLoadAdults(
      //   booking.rooms.reduce((total, room) => {
      //     return room.roomType === 'Exclusive' ? total + room.ad : room.roomType === 'Room' ? total + room.ad : total;
      //   }, 0),
      // );
      setLoadAdults(
        booking.rooms.reduce((total, room) => {
          return room.roomType !== 'Vehicle' ? total + room.ad : total;
        }, 0),
      );

      // setFlightAdults(
      //   booking.rooms.reduce((total, room) => {
      //     return room.roomType === 'Exclusive' ? total + room.ad : room.roomType === 'Room' ? total + room.ad : total;
      //   }, 0),
      // );
      setFlightAdults(
        booking.rooms.reduce((total, room) => {
          return room.roomType !== 'Vehicle' ? total + room.ad : total;
        }, 0),
      );
    }
  }, [booking.rooms, transfer.formValues]);

  useEffect(() => {
    if (transfer.formValues && transfer.formValues.children) {
      setLoadChildren(transfer.formValues.children);
      setFlightChildren(transfer.formValues.children);
    } else {
      // setLoadChildren(
      //   booking.rooms.reduce((total, room) => {
      //     return room.roomType === 'Exclusive' ? total + room.ch + room.chi : room.roomType === 'Room' ? total + room.ch + room.chi : total;
      //   }, 0),
      // );
      setLoadChildren(
        booking.rooms.reduce((total, room) => {
          return room.roomType !== 'Vehicle' ? total + room.ch + room.chi : total;
        }, 0),
      );

      // setFlightChildren(
      //   booking.rooms.reduce((total, room) => {
      //     return room.roomType === 'Exclusive' ? total + room.ch + room.chi : room.roomType === 'Room' ? total + room.ch + room.chi : total;
      //   }, 0),
      // );
      setFlightChildren(
        booking.rooms.reduce((total, room) => {
          return room.roomType !== 'Vehicle' ? total + room.ch + room.chi : total;
        }, 0),
      );
    }
  }, [booking.rooms, transfer.formValues]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (reCheckPax) {
      if (flightAdults !== loadAdults || flightChildren !== loadChildren) {
        setSearchAgain(true);
      } else {
        setSearchAgain(false);
      }
      setReCheckPax(false);
    }
  }, [booking.rooms, flightAdults, flightChildren, loadAdults, loadChildren, reCheckPax]);

  // LOAD FLIGHTS
  // new search airport codes
  useEffect(() => {
    if (transfer.formValues && transfer.formValues.flightDetails) {
      const savedFlightDetails = transfer.formValues.flightDetails;
      const savedFlightIndex = savedFlightDetails.flightsArray.findIndex(
        (flight) => flight.fltnum === savedFlightDetails.fltnum && flight.fromcode === savedFlightDetails.fromcode && flight.tocode === savedFlightDetails.tocode,
      );
      // setFlightResults({
      // 	count: savedFlightDetails.flightsArray.length,
      // 	flight: savedFlightDetails.flightsArray,
      // });
      let availableFlightsData = [];
      availableFlightsData.push({
        count: savedFlightDetails.flightsArray.length,
        flight: savedFlightDetails.flightsArray,
      });
      setFlightResults(availableFlightsData);
      setSelectedFlightIndex(savedFlightIndex);
      setSelectedFlight(savedFlightDetails);
      setShowFlightDetails(true);
      setSelectedOption('bookAFlight');
      setAirlineName(transfer.formValues.airlineName);
      setFlightCost(
        transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.flightPricing.saleFare.total : 0,
      );
      setFlightPricing(transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.flightPricing : []);

      setConnectionType(transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.connectionType : null);
      setConnectionClick(transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.connectionClick : null);

      setLocationName(
        transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') && transfer.formValues.locationName ? transfer.formValues.locationName : null,
      );
      setLocationUid(
        transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') && transfer.formValues.locationUid ? transfer.formValues.locationUid : null,
      );

      setSupplierName(
        transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') && transfer.formValues.supplierName ? transfer.formValues.supplierName : null,
      );
      setSupplierUid(
        transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') && transfer.formValues.supplierUid ? transfer.formValues.supplierUid : null,
      );

      // get selected flight object -------- start
      // Assuming transfer.formValues object is available
      const { flightDetails } = transfer.formValues;
      const { STD, fltnum, fromcode, tocode } = flightDetails;
      const findFlightIndex = (flightsArray, STD, fltnum, fromcode, tocode) => {
        return flightsArray.findIndex((flight) => {
          return flight.STD === STD && flight.fltnum === fltnum && flight.fromcode === fromcode && flight.tocode === tocode;
        });
      };

      const index = findFlightIndex(flightDetails.flightsArray, STD, fltnum, fromcode, tocode);

      setLocalSelectedIndices([index]);
      // get selected flight object -------- end
      //load airports
      fetchAirportFilters();
    }
  }, []);

  useEffect(() => {
    if (transfer.formValues && transfer.formValues.flightDetails && flightResults) {
      let foundFlightIndex = -1;
      let foundOuterIndex = -1;

      for (let outerIndex = 0; outerIndex < flightResults.length; outerIndex++) {
        const innerFlights = flightResults[outerIndex].flight;

        const flightIndex = innerFlights.findIndex((flight) => flight.STD === transfer.formValues.flightDetails.STD && flight.STA === transfer.formValues.flightDetails.STA);

        if (flightIndex >= 0) {
          foundFlightIndex = flightIndex;
          foundOuterIndex = outerIndex;
          break;
        }
      }

      if (foundFlightIndex >= 0 && foundOuterIndex >= 0) {
        const localIndex = foundFlightIndex % buttonsToShow;
        const pageForThisFlight = Math.floor(foundFlightIndex / buttonsToShow);

        setLocalSelectedIndex(!resetTimeButton ? localIndex : []);
        setCurrentPages((prevPages) => {
          const newPages = [...prevPages];
          newPages[foundOuterIndex] = pageForThisFlight;
          return newPages;
        });
      }
    }
  }, [buttonsToShow, flightResults, transfer.formValues, resetTimeButton]);

  const matchingBooking = bookings.find((booking) => booking.id === transfer.uuid);
  const isSafariTransferWithMatchingBooking = () =>
    nonNomadVehicleRates(matchingBooking.rateName) || (properties[getPropObj(transfer.propUid, properties)].supplier.name === 'Nomad Tanzania' && nomadVehicleRates(matchingBooking.rateName));

  //const isTownTransfer = () => transfer.routeTransfer.transferType === 'townTransfer';

  const isTownTransfer = () => true;

  useEffect(() => {
    async function fetchData() {
      const parkFiltersLoad = await loadTransferRoutes(parkId, transfer.objType, isSafariTransferWithMatchingBooking());
      console.log('Specific onClick triggered! ', transfer.propUid);
      console.log('park.id: ', parkId);
      console.log('testLoad: ', parkFiltersLoad);
      console.log(JSON.stringify(parkFiltersLoad, undefined, 4));
      setParkFilters(parkFiltersLoad);

      // ! Set a town transfer filter

      // function
      // setParkFiltersTown(xxx);

      const parkFilters = parkFiltersLoad.map((id) => `park:${id}`).join(' OR ');

      console.log('parkFilters: ', parkFilters);
      console.log(JSON.stringify(parkFilters, undefined, 4));

      setSearchFilters(parkFilters);

      setSelectedParkAlgoliaUid(
        transfer.formValues && transfer.edit && (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp')
          ? transfer.objType === 'arrival'
            ? transfer.formValues.originUid
            : transfer.formValues.destinationUid
          : '',
      );

      setSelectedLocationAlgoliaName(
        transfer.formValues &&
          transfer.edit &&
          transfer.formValues.type &&
          (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp' || transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
          ? transfer.formValues.locationName
          : 'Search by airport, property or other',
      );
      setSelectedLocationAlgoliaUid(
        transfer.formValues &&
          transfer.edit &&
          (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp' || transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') &&
          transfer.formValues.locationUid,
      );

      setSelectedLocationAlgoliaType(
        transfer.formValues &&
          transfer.edit &&
          (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp' || transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
          ? transfer.formValues.locationType
          : '',
      );

      if (
        transfer.formValues &&
        transfer.edit &&
        (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp' || transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') &&
        transfer.formValues.airport === true
      ) {
        setSelectedLocationAlgoliaType('airport');
      }

      if (transfer.formValues && transfer.edit && (transfer.formValues.type === 'camp' || transfer.formValues.type === 'location') && transfer.formValues.addFlightDetailsLater) {
        setAddFlightDetailsLater(true);
      }

      if (transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' && transfer.formValues.addFlightDetailsLater) {
        setAddFlightDetailsLater(true);
      }

      if (transfer.formValues && transfer.edit && transfer.formValues.type === 'location' && transfer.formValues.addFlightDetailsLater) {
        setAddFlightDetailsLater(true);
      }

      setLocationTime(transfer.formValues && transfer.edit && transfer.formValues.time !== 'Select time' ? transfer.formValues.time : 'Select time');

      if (
        transfer.objType === 'arrival' &&
        transfer.formValues &&
        transfer.edit &&
        transfer.formValues.type === 'bookFlight' &&
        (transfer.formValues.includeArrivalTransfer || transfer.formValues.includeDepartureTransfer)
      ) {
        setIncludeArrivalTransfer(true);
      } else {
        setIncludeArrivalTransfer(false);
      }

      if (transfer.objType === 'departure' && transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' && includeDepartureTransfer) {
        setIncludeDepartureTransfer(true);
      } else {
        setIncludeDepartureTransfer(false);
      }

      if (transfer.objType === 'departure' && transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' && transfer.formValues.includeDepartureTransfer) {
        setIncludeDepartureTransfer(true);
      } else {
        setIncludeDepartureTransfer(false);
      }
    }

    if (transfer.formValues && (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp' || transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')) {
      fetchData();
    }

    setPackedLunch(transfer.formValues && transfer.formValues.packedLunch ? transfer.formValues.packedLunch : false);
    setPackedLunchChargeable(transfer.formValues && transfer.formValues.packedLunchChargeable ? transfer.formValues.packedLunchChargeable : false);
  }, []);

  useEffect(() => {
    console.log(flightPricing);
  }, [flightPricing]);

  // SET CAMP DETAILS
  useEffect(() => {
    if (transfer.formValues && transfer.edit && transfer.formValues.type === 'camp') {
      setLocationName(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.locationName ? transfer.formValues.locationName : null);
      setLocationUid(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.locationUid ? transfer.formValues.locationUid : null);
      if (transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.locationUid === '') {
        setAirport(false);
      } else {
        if (locations.find((location) => location.uid === transfer.formValues.locationUid).type === 'airport') {
          setAirport(true);
        } else {
          setAirport(false);
        }
      }

      setSupplierName(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.supplierName ? transfer.formValues.supplierName : null);
      setSupplierUid(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.supplierUid ? transfer.formValues.supplierUid : null);

      setDateOwnArrangements(
        transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.dateOwnArrangements
          ? transfer.formValues.dateOwnArrangements
          : transfer.objType === 'arrival'
          ? transfer.startDay
          : transfer.endDay,
      );

      const defaultTime = 'Select time';

      setTimeOwnArrangements(
        transfer.formValues &&
          transfer.formValues.type === 'camp' &&
          transfer.formValues.timeOwnArrangements &&
          arrivalDepartureTimeOptions.some((option) => option.value === transfer.formValues.timeOwnArrangements)
          ? transfer.formValues.timeOwnArrangements
          : defaultTime,
      );

      //setTimeOwnArrangements(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.timeOwnArrangements ? transfer.formValues.timeOwnArrangements : 'Select time');

      setAirlineUidOwnArrangements(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.airlineUidOwnArrangements ? transfer.formValues.airlineUidOwnArrangements : null);
      setAirlineNameOwnArrangements(
        transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.airlineNameOwnArrangements ? transfer.formValues.airlineNameOwnArrangements : null,
      );

      setFltNum(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.fltNum ? transfer.formValues.fltNum : null);

      setDateFlightOwnArrangements(
        transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.dateFlightOwnArrangements
          ? transfer.formValues.dateFlightOwnArrangements
          : transfer.objType === 'arrival'
          ? transfer.startDay
          : transfer.endDay,
      );

      setTimeFlightOwnArrangements(
        transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.timeFlightOwnArrangements ? transfer.formValues.timeFlightOwnArrangements : 'Select time',
      );
    }
  }, []);

  // SET LOCATION DETAILS
  useEffect(() => {
    if (transfer.formValues && transfer.edit && transfer.formValues.type === 'location') {
      setAirlineUidOwnArrangements(transfer.formValues && transfer.formValues.airlineUid ? transfer.formValues.airlineUid : null);
      setAirlineNameOwnArrangements(transfer.formValues && transfer.formValues.airlineName ? transfer.formValues.airlineName : null);

      setFltNum(transfer.formValues && transfer.formValues.fltNum ? transfer.formValues.fltNum : null);

      setDateFlightOwnArrangements(transfer.formValues && transfer.formValues.dateFlight ? transfer.formValues.dateFlight : transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);

      setTimeFlightOwnArrangements(transfer.formValues && transfer.formValues.timeFlight ? transfer.formValues.timeFlight : 'Select time');

      setAddLater(transfer.formValues && transfer.edit && transfer.formValues.type === 'location' && transfer.formValues.addLater && transfer.formValues.addLater);
    }
  }, []);

  useEffect(() => {
    if (transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight') {
      setAirlineUidOwnArrangements(transfer.formValues && transfer.formValues.airlineUid ? transfer.formValues.airlineUid : null);
      setAirlineNameOwnArrangements(transfer.formValues && transfer.formValues.airlineNameFlight ? transfer.formValues.airlineNameFlight : null);

      setFltNum(transfer.formValues && transfer.formValues.fltNumFlight ? transfer.formValues.fltNumFlight : null);

      setTimeFlightOwnArrangements(transfer.formValues && transfer.formValues.timeFlight ? transfer.formValues.timeFlight : 'Select time');
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const parkFiltersLoad = await loadTransferRoutes(parkId, transfer.objType, isSafariTransferWithMatchingBooking());
      if (!parkFiltersLoad || parkFiltersLoad.length === 0) {
        setNoTransfers(true);
        return;
      } else {
        setNoTransfers(false);
      }
      setParkFilters(parkFiltersLoad);

      const parkFilters = parkFiltersLoad.map((id) => `park:${id}`).join(' OR ');
      setSearchFilters(parkFilters);
    };

    fetchData();
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  async function handleOptionClickGetFlights(originCode, destinationCode) {
    console.log('destinationCode', destinationCode);
    console.log(JSON.stringify(destinationCode, undefined, 4));
    console.log('originCode', originCode);
    console.log(JSON.stringify(originCode, undefined, 4));
    setIsLoading(true);
    setShowFlightDetails(true);
    console.log('adults', flightAdults);
    console.log('children', flightChildren);

    //const destinationAirportCodes = getAirportCodes(airports, destinationCode);
    const originAirportCodes = Array.isArray(originCode) ? originCode : getAirportCodes(airports, originCode);
    const destinationAirportCodes = Array.isArray(destinationCode) ? destinationCode : getAirportCodes(airports, destinationCode);

    processFlights(
      originAirportCodes,
      destinationAirportCodes,
      properties,
      transfer.objType === 'arrival' ? transfer : transfer,
      transfer.objType === 'departure' ? transfer : false,
      customLocations,
      airports,
      flightAdults,
      flightChildren,
      flightInfants,
    )
      .then((results) => {
        console.log('All flights processed:', results);
        // After the main loop, you can then group or merge the results as needed
        let mergedResults = {};

        results.forEach((flightResult) => {
          if (flightResult.dateAdjusted) {
            setDateAdjusted(true);
          }
          const mergeKey = flightResult.merge;
          if (!mergedResults[mergeKey]) {
            mergedResults[mergeKey] = {
              merge: mergeKey,
              count: 0,
              flight: [],
              dateAdjusted: flightResult.dateAdjusted,
            };
          }

          // Check for duplicate flight entries
          const isDuplicate = mergedResults[mergeKey].flight.some(
            (existingFlight) => existingFlight.flightRouteUid === flightResult.flight[0].flightRouteUid && existingFlight.flightcode === flightResult.flight[0].flightcode,
          );

          if (!isDuplicate) {
            // If not a duplicate, increment the count and push the flight object
            mergedResults[mergeKey].count += flightResult.count;
            mergedResults[mergeKey].flight.push(...flightResult.flight);
          }
        });

        // Convert mergedResults object to an array
        const mergedFlightsArray = Object.values(mergedResults);

        console.log('mergedFlightsArray 1');
        console.log(JSON.stringify(mergedFlightsArray, undefined, 4));

        console.log(mergedFlightsArray);

        //sort by date
        mergedFlightsArray.forEach((result) => {
          result.flight.sort((a, b) => {
            const dateA = new Date(a.STD);
            const dateB = new Date(b.STD);
            return dateA - dateB;
          });
        });

        console.log('mergedFlightsArray');

        console.log(JSON.stringify(mergedFlightsArray, undefined, 4));

        //remove duplicate UID and sort by date - START
        mergedFlightsArray.forEach((flightGroup) => {
          const uniqueFlightsWithUID = {};
          const flightsWithoutUID = [];

          flightGroup.flight.forEach((flight) => {
            if (flight.uid) {
              if (!uniqueFlightsWithUID[flight.uid]) {
                uniqueFlightsWithUID[flight.uid] = flight;
              }
            } else {
              flightsWithoutUID.push(flight);
            }
          });

          flightGroup.flight = [...Object.values(uniqueFlightsWithUID), ...flightsWithoutUID];

          // Sort flights by STD
          flightGroup.flight.sort((a, b) => new Date(a.STD) - new Date(b.STD));
        });
        //remove duplicate UID and sort by date - END

        setLocalSelectedIndices([]);
        setLocalSelectedIndex([]);
        console.log('mergedFlightsArray NEW');

        console.log(JSON.stringify(mergedFlightsArray, undefined, 4));
        setFlightResults(mergedFlightsArray);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('An error occurred:', error);
        setIsLoading(false);
      });
  }

  function handleConfirmClickSubmitFlight(e) {
    // Check if any flight has been selected
    const checkPassed = localSelectedIndices.some((index) => index !== null);

    if (!checkPassed) {
      // Prevent form submission if the check fails
      e.preventDefault();
      toast.error('Please select a flight and time.');
    }
  }

  // load the airports for algolia search
  const fetchAirportFilters = async () => {
    const airportFiltersLoad = await loadFlightRoutes(airportId, transfer.objType);
    console.log('Specific onClick triggered! ', transfer.propUid);
    console.log('airport.id: ', airportId);
    console.log('testLoad: ', airportFiltersLoad);
    console.log(JSON.stringify(airportFiltersLoad, undefined, 4));
    setAirportFilters(airportFiltersLoad);

    const airportFilters = airportFiltersLoad.map((id) => `uid:${id}`).join(' OR ');
    console.log('airportFilters: ', airportFilters);
    console.log(JSON.stringify(airportFilters, undefined, 4));

    setAirportSearchFilters(airportFilters);
  };

  const handleArrowClick = (direction, outerIndex) => {
    const newPages = [...currentPages];
    if (direction === 'left') {
      newPages[outerIndex] = (newPages[outerIndex] || 0) - 1;
    } else {
      newPages[outerIndex] = (newPages[outerIndex] || 0) + 1;
    }
    setCurrentPages(newPages);
    setLocalSelectedIndex(null);
  };

  const handleButtonClick = async (localIndex, outerIndex) => {
    console.log('Checking flight vars');
    console.log(dateAdjusted);
    console.log(JSON.stringify(flightResults, undefined, 4));

    const currentPageForThisRow = currentPages[outerIndex] || 0;
    const globalIndex = currentPageForThisRow * buttonsToShow + localIndex;

    const flightsForOuterIndex = flightResults[outerIndex].flight;

    setLocalSelectedIndices((prevIndices) => {
      const newIndices = Array(prevIndices.length).fill(null);
      newIndices[outerIndex] = localIndex;
      console.log('Updated Indices:', newIndices); // Debugging line
      return newIndices;
    });

    setSelectedFlightIndex(globalIndex);
    setSelectedFlight({
      ...flightsForOuterIndex[globalIndex],
      flightsArray: flightsForOuterIndex,
    });
    console.log('selectedFLight');
    console.log(JSON.stringify(selectedFlight, undefined, 4));

    let airlineName = flightsForOuterIndex[globalIndex].airlineName;
    setAirlineName(airlineName);

    let airlineDesignator = flightsForOuterIndex[globalIndex].airlineDesignator;

    const flightPricing = await calculateFlightPricing(
      flightResults[outerIndex], // Pass the correct flight result for the outerIndex
      globalIndex,
      flightAdults,
      flightChildren,
      flightInfants,
      airlineDesignator,
      dateAdjusted,
      agentObject,
      selectedAges,
      selectedAgesAdults,
    );

    console.log('flightPricing');
    console.log(JSON.stringify(flightPricing, undefined, 4));

    setFlightPricing(flightPricing);
    setFlightCost(flightPricing.saleFare.total);
  };

  // deal with conditional classname for location and flights
  let containerClassName = 'p-5 bg-white rounded-lg';

  // Apply additional conditions to modify the class name
  if (transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'location') && !selectedContent) {
    containerClassName = ''; // Remove the "hidden" class
  } else if (selectedContent === 'dropOffLocation' || selectedContent === 'pickupAirport' || selectedContent === 'pickupLocation') {
    containerClassName = ''; // Remove the "hidden" class
  } else {
    containerClassName += ' hidden'; // Add the "hidden" class
  }

  const shouldHide = !(
    (transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'location')) ||
    selectedContent === 'dropOffLocation' ||
    selectedContent !== 'pickupAirport' ||
    selectedContent !== 'pickupLocation'
  );

  const VirtualSearchBox = connectSearchBox(() => null);

  const handleChangeBookFlightSelect = (selectedOption) => {
    // handleFormReset();
    //setSupplierName(null);
    //setSupplierUid(null);
    setDateFlightOwnArrangements(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);
    setTimeOwnArrangements('Select time');
    setTimeFlightOwnArrangements('Select time');
    setAirlineNameOwnArrangements(null);
    setAirlineUidOwnArrangements(null);
    setFltNum(null);
    setAirport(false);

    //setSelectedLocationAlgolia(selectedOption.value);
    setSelectedLocationAlgolia(selectedOption.value);
    setSelectedLocationAlgoliaName(selectedOption.label);
    console.log('Current selected value: ', selectedOption.value);

    console.log('selectedOption.type');
    console.log(selectedOption.type);
    setSelectedLocationAlgoliaType(selectedOption.type);

    console.log('selectedOption.location');
    console.log(selectedOption.location);
    setSelectedLocationAlgoliaUid(selectedOption.location);
    setSelectedParkAlgoliaUid(selectedOption.park);
  };

  const handleChange = (selectedOption) => {
    setSelectedLocationAlgolia(selectedOption.value);
    setSelectedLocationAlgoliaName(selectedOption.label);
    console.log('selectedOption.type');
    console.log(selectedOption.type);
    setSelectedLocationAlgoliaType(selectedOption.type);

    console.log('selectedOption.location');
    console.log(selectedOption.location);
    setSelectedLocationAlgoliaUid(selectedOption.location);
    setSelectedParkAlgoliaUid(selectedOption.park);
  };

  const handleChangeBookFlight = (selectedOption) => {
    setSelectedFlightAlgolia(selectedOption.codes);
    console.log(selectedFlightAlgolia);
    //setSelectedFlightAlgolia(selectedOption.codes);
    setSelectedFlightAlgoliaName(selectedOption.label);
    //(selectedOption.multipleCodes);
  };

  const CustomOption = ({ label, data }) => (
    <div key={data.value}>
      <div style={{ fontSize: '0.925rem' }}>{label}</div>
      <div style={{ fontSize: '0.725rem', textTransform: 'capitalize' }}>{data.type}</div>
    </div>
  );

  const CustomOptionAirports = ({ label, data }) => (
    <div key={data.value}>
      <div style={{ fontSize: '0.925rem' }}>{label}</div>
      <div style={{ fontSize: '0.725rem', textTransform: 'capitalize' }}>{data.name}</div>
      <div style={{ fontSize: '0.725rem', textTransform: 'capitalize' }}>{data.code}</div>
    </div>
  );

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      borderWidth: 2,
      borderColor: '#2A99A3',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#2A99A3',
      },
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      color: state.isSelected ? 'white' : '#000',
      backgroundColor: state.isSelected ? '#2A99A3' : 'white',
      ':hover': {
        backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
        color: state.isSelected ? 'white' : 'white',
      },
    }),
  };

  const customStylesBookFight = {
    control: (provided) => ({
      // ...provided,
      // height: '36px',
      // borderRadius: '8px',
      // border: '2px solid #2A99A3',
      // boxShadow: 'none',
      // width: '350px',
      // fontSize: '14px',
      // whiteSpace: 'nowrap',
      // textOverflow: 'ellipsis',
      // overflow: 'hidden',
      ...provided,
      height: '36px',
      borderRadius: '8px',
      border: '2px solid #2A99A3',
      boxShadow: 'none',
      width: '350px', // Adjust width as needed
      fontSize: '14px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    option: (provided, state) => ({
      // ...provided,
      // fontSize: '14px',
      // color: state.isSelected ? 'white' : state.isFocused ? 'white' : '#666',
      // backgroundColor: state.isSelected ? '#2A99A3' : state.isFocused ? '#2A99A3' : 'white',
      // ':active': {
      //   backgroundColor: !state.isDisabled && (state.isSelected ? '#2A99A3' : '#D3D3D3'),
      //   color: state.isSelected ? 'white' : '#666',
      // },
      ...provided,
      fontSize: '14px',
      color: state.isSelected ? 'white' : '#000',
      backgroundColor: state.isSelected ? '#2A99A3' : 'white',
      ':hover': {
        backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
        color: state.isSelected ? 'white' : 'white',
      },
    }),
    singleValue: (provided) => ({
      // ...provided,
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap',
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
      width: '350px',
      height: '125px',
      maxHeight: '125px', // Set the max height of the dropdown menu
      overflowY: 'auto', // Add a scrollbar if the content is larger than the max height
    }),
    // ... Add any other style overrides as needed
  };

  // control: (provided) => ({
  //   ...provided,
  //   height: '36px',
  //   borderRadius: '8px',
  //   border: '2px solid #2A99A3',
  //   boxShadow: 'none',
  //   width: '350px', // Adjust width as needed
  //   fontSize: '14px',
  //   whiteSpace: 'nowrap',
  //   textOverflow: 'ellipsis',
  //   overflow: 'hidden', // Ensure overflow is hidden
  // }),
  // // Adjust singleValue style for selected option
  // singleValue: (provided) => ({
  //   ...provided,
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  //   whiteSpace: 'nowrap',
  // }),
  // option: (provided, state) => ({
  //   ...provided,
  //   fontSize: '14px',
  //   color: state.isSelected ? 'white' : '#000',
  //   backgroundColor: state.isSelected ? '#2A99A3' : 'white',
  //   ':hover': {
  //     backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
  //     color: state.isSelected ? 'white' : 'white',
  //   },
  // }),

  // algolia hits and select for Locations
  const HitsLocations = connectHits(({ hits, index }) => {
    console.log(`Number of items in select: ${hits.length}`);
    return (
      <div className="w-80">
        {' '}
        {/* Change 'w-64' to your desired width */}
        <Select
          placeholder={selectedLocationAlgoliaName}
          options={hits.map((hit) => ({
            //value: hit.objectID,
            value: `${hit.objectID}-${index}`,
            label: hit.name,
            type: hit.type,
            park: hit.park,
            location: hit.uid,
          }))}
          onChange={handleChange}
          components={{
            Option: (props) => (
              <components.Option {...props}>
                <CustomOption label={props.data.label} data={props.data} />
              </components.Option>
            ),
          }}
          styles={customStyles}
        />
      </div>
    );
  });

  const HitsLocationsBookFlight = connectHits(({ hits, index }) => {
    console.log(`Number of items in select: ${hits.length}`);
    return (
      <div className="w-80">
        {' '}
        {/* Change 'w-64' to your desired width */}
        <Select
          placeholder={selectedLocationAlgoliaName}
          //value={hits.find((hit) => `${hit.objectID}-${index}` === `${selectedLocationAlgolia}-${index}`)}
          //value={hits.find((hit) => `${hit.objectID}-${index}` === selectedLocationAlgolia)}
          options={hits.map((hit, index) => {
            console.log(`hit ID: ${hit.objectID}, index: ${index}`);
            return {
              value: `${hit.objectID}-${index}`,
              label: hit.name,
              type: hit.type,
              park: hit.park,
              location: hit.uid,
            };
          })}
          onChange={handleChangeBookFlightSelect}
          //onChange={handleChangeBookFlightSelect}
          components={{
            Option: (props) => (
              <components.Option {...props}>
                <CustomOption label={props.data.label} data={props.data} />
              </components.Option>
            ),
          }}
          styles={customStylesBookFight}
        />
      </div>
    );
  });

  // algolia hits and select for Airports
  const HitsAirports = connectHits(({ hits }) => (
    <div className="w-80">
      {' '}
      {/* Change 'w-64' to your desired width */}
      <Select
        placeholder={selectedFlightAlgoliaName}
        options={hits.map((hit, index) => ({
          //value: hit.objectID,
          value: `${hit.objectID}-${index}`,
          label: hit.name,
          code: hit.code,
          codes: hit.codes,
          multipleCodes: hit.multipleCodes,
        }))}
        onChange={handleChangeBookFlight}
        components={{
          Option: (props) => (
            <components.Option {...props}>
              <CustomOptionAirports label={props.data.label} data={props.data} />
            </components.Option>
          ),
        }}
        styles={customStyles}
      />
    </div>
  ));

  const timeOptions = Array.from({ length: 24 * 4 }, (_, i) => i * 15).map((time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return { value: formattedTime, label: formattedTime };
  });

  const timeOptionsFive = Array.from({ length: 24 * 12 }, (_, i) => i * 5).map((time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return { value: formattedTime, label: formattedTime };
  });

  const handleChangeTime = (selectedOption) => {
    setLocationTime(selectedOption.value);
    console.log(locationTime);
  };

  const totalPax = flightAdults + flightChildren + flightInfants;

  // NEW OWN ARRANGEMENTS
  function handleFltNumInputChange(e) {
    setFltNum(e.target.value);
    // update your state here based on the name and value
  }

  const handleChangeTimeOwnArrangements = (selectedOption) => {
    setTimeOwnArrangements(selectedOption.value);
  };

  const handleChangeFlightTimeOwnArrangements = (selectedOption) => {
    setTimeFlightOwnArrangements(selectedOption.value);
  };

  const handleChangeDateOwnArrangements = (selectedOption) => {
    setDateOwnArrangements(selectedOption.value);
  };

  const handleChangeDateFlightOwnArrangements = (selectedOption) => {
    setDateFlightOwnArrangements(selectedOption.value);
  };

  // Handle form reset
  const handleFormReset = () => {
    // Reset your form values here

    setDateFlightOwnArrangements(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);
    setTimeOwnArrangements('Select time');
    setTimeFlightOwnArrangements('Select time');
    setAirlineNameOwnArrangements(null);
    setAirlineUidOwnArrangements(null);
    setFltNum(null);
    setAirport(false);
    setSupplierName(null);
    setSupplierUid(null);

    setSelectedLocationAlgolia('');
    setSelectedLocationAlgoliaName('Search by airport, property or other');
    setSelectedLocationAlgoliaUid('');
    setSelectedParkAlgoliaUid('');
    setSelectedLocationAlgoliaType('');

    setFlash(true);
    setTimeout(() => setFlash(false), 1000); // Reset the flash state after 1 second
  };
  return (
    <CustomModal isOpen={modalOpen} onClose={handleCloseModal}>
      <div key={uuidv4()} className="p-5 bg-white rounded-lg" style={deeShadow}>
        <p className="text-sm font-semibold pb-2">
          How will you {transfer.objType === 'departure' ? 'leave from ' : 'arrive at '} {properties[getPropObj(transfer.propUid, properties)].name}?
        </p>
        <div className="flex justify-center my-3 space-x-3">
          {/* Pick up from airport old */}

          {/* Pick up location */}
          {transfer.objType && (
            // && (isSafariTransferWithMatchingBooking() || isTownTransfer())
            <div
              className={`w-56 m-3 bg-white rounded-lg drop-shadow-md ${
                transfer.formValues && transfer.edit && transfer.formValues.type === 'location' && !selectedOption
                  ? 'selected-border-shadow'
                  : selectedOption === 'pickupLocation'
                  ? 'selected-border-shadow'
                  : ''
              }`}
              data-target="pickupLocation"
              onClick={(event) => {
                // Extract necessary data from the event before the async function
                const currentTarget = event.currentTarget;

                // Then start the async operation
                (async () => {
                  // const parkFiltersLoad = await loadTransferRoutes(parkId, transfer.objType, isSafariTransferWithMatchingBooking());
                  // if (!parkFiltersLoad || parkFiltersLoad.length === 0) {
                  //   toast.error('No transfers available.');
                  //   return;
                  // }
                  // setParkFilters(parkFiltersLoad);

                  // const parkFilters = parkFiltersLoad.map((id) => `park:${id}`).join(' OR ');
                  // setSearchFilters(parkFilters);
                  if (noTransfers) {
                    toast.error('No transfers available.');
                    return;
                  }

                  // Create a synthetic event to pass to handleOptionClick
                  const syntheticEvent = {
                    ...event,
                    currentTarget,
                  };

                  //reset values
                  handleFormReset();

                  // Call handleOptionClick only if parkFiltersLoad is not empty
                  handleOptionClick(syntheticEvent, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
                })();
              }}
              // onClick={(event) => {
              //   // Persist the event
              //   event.persist();
              //   // Then start the async operation
              //   (async () => {
              //     //alert(isSafariTransferWithMatchingBooking());
              //     const parkFiltersLoad = await loadTransferRoutes(parkId, transfer.objType, isSafariTransferWithMatchingBooking());
              //     console.log('Specific onClick triggered! ', transfer.propUid);
              //     console.log('park.id: ', parkId);
              //     console.log('testLoad: ', parkFiltersLoad);
              //     console.log(JSON.stringify(parkFiltersLoad, undefined, 4));
              //     if (!parkFiltersLoad || parkFiltersLoad.length === 0) {
              //       toast.error('No transfers available.');
              //       return;
              //     }
              //     setParkFilters(parkFiltersLoad);

              //     const parkFilters = parkFiltersLoad.map((id) => `park:${id}`).join(' OR ');
              //     console.log('parkFilters: ', parkFilters);
              //     console.log(JSON.stringify(parkFilters, undefined, 4));

              //     //};
              //     setSearchFilters(parkFilters);
              //     handleOptionClick(event, setSelectedOption, setSelectedContent);
              //   })();
              // }}
              //onClick={handleClick}
            >
              <div className="flex">
                <div className="ml-2 mt-2 relative rounded-full brand-bg-color-light-v2 w-9 h-7 flex items-center justify-center">
                  <MdOutlineLocationOn className="inline-block text-black" />
                </div>
                <div className="pl-3 pt-1">
                  {/* Your text goes here */}
                  <p className="text-sm">Arrange a road transfer</p>
                  <p className="text-xs mb-2 brand-text-grey-1-v2">
                    {transfer.objType === 'departure' ? "We'll drop you off at a local airstrip or other location" : "We'll pick you up from local airstrip or other location"}
                  </p>
                </div>
              </div>
            </div>
          )}

          {/*  Book a flight */}
          {(transfer.objType === 'arrival' || transfer.objType === 'departure') && (
            <div
              className={`w-56 m-3 bg-white rounded-lg drop-shadow-md ${
                transfer.formValues && transfer.edit && transfer.formValues.type === 'bookAFlight' && !selectedOption
                  ? 'selected-border-shadow'
                  : selectedOption === 'bookAFlight'
                  ? 'selected-border-shadow'
                  : ''
              }`}
              data-target="bookAFlight"
              onClick={(event) => {
                handleFormReset();
                fetchAirportFilters();
                handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
              }}
            >
              {' '}
              <div className="flex">
                <div className="ml-2 mt-2 relative rounded-full brand-bg-color-light-v2  w-8 h-7 flex items-center justify-center">
                  <SlPlane className="inline-block text-black" />
                </div>
                <div className="pl-3 pt-1">
                  {/* Your text goes here */}
                  <p className="text-sm">Book a flight</p>
                  <p className="text-xs mb-2 brand-text-grey-1-v2">{transfer.objType === 'arrival' ? 'Book a flight to a local airstrip' : 'Book a flight from a local airstrip'}</p>
                </div>
              </div>
            </div>
          )}

          {/* Arrive at camp */}
          <div
            className={`w-56 m-3 bg-white rounded-lg drop-shadow-md ${
              transfer.formValues && transfer.edit && transfer.formValues.type === 'camp' && !selectedOption
                ? 'selected-border-shadow'
                : selectedOption === 'arrivalCamp'
                ? 'selected-border-shadow'
                : ''
            }`}
            data-target="arrivalCamp"
            onClick={(event) => {
              handleFormReset();
              handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
            }}
          >
            {' '}
            <div className="flex">
              <div className="ml-2 mt-2 relative rounded-full brand-bg-color-light-v2 w-7 h-7 flex items-center justify-center">
                <FaCampground className="inline-block text-black" />
              </div>
              <div className="pl-3 pt-1">
                {/* Your text goes here */}
                <p className="text-sm">{transfer.objType === 'departure' ? 'Own Arrangements' : 'Own Arrangements'}</p>
                <p className="text-xs mb-2 brand-text-grey-1-v2">{transfer.objType === 'departure' ? "You'll leave by own arrangements" : "You'll make your own way to the property"}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Pick up from airport */}

        {/* Location pick up NEW */}
        <form
          id="pickupLocation"
          className={`${
            transfer.formValues && transfer.edit && transfer.formValues.type === 'location' && !selectedContent ? '' : selectedContent === 'pickupLocation' ? '' : 'hidden'
          } p-5 bg-white rounded-lg ${flash ? 'flash-effect' : ''}`}
          onSubmit={(e) => {
            e.preventDefault(); // Prevent the form from refreshing the page
            if (selectedLocationAlgoliaName !== 'Search by airport, property or other') {
              handleFormSubmitLocation(e, transfers, setTransfers, setIsModalOpen, bookings, setBookings, properties);
            } else {
              // Show a toast error
              toast.error('Please select a location.');
            }
          }}
        >
          <input type="hidden" name="formType" value={transfer.objType} />
          <input type="hidden" name="tuuid" value={transfer.tuuid} />
          <input type="hidden" name="locationUid" value={selectedLocationAlgoliaUid} />
          {/* <input type="hidden" name="parkUid" value={selectedParkAlgoliaUid} /> */}

          <input type="hidden" name="locationName" value={selectedLocationAlgoliaName} />
          <input type="hidden" name="time" value={locationTime} />
          {/* <input type="hidden" name="originUid" value={transfer.objType === 'arrival' ? selectedLocationAlgoliaUid : parkId} />
          <input type="hidden" name="destinationUid" value={transfer.objType === 'arrival' ? parkId : selectedLocationAlgoliaUid} /> */}
          <input type="hidden" name="originUid" value={transfer.objType === 'arrival' ? selectedParkAlgoliaUid : parkId} />
          <input type="hidden" name="destinationUid" value={transfer.objType === 'arrival' ? parkId : selectedParkAlgoliaUid} />

          <input type="hidden" name="locationType" value={selectedLocationAlgoliaType} />
          <input type="hidden" name="totalPax" value={totalPax} />

          {/* New from Own Arrangements */}
          <input type="hidden" name="airlineUidOwnArrangements" value={airlineUidOwnArrangements} />
          <input type="hidden" name="airlineNameOwnArrangements" value={airlineNameOwnArrangements} />
          <input type="hidden" name="airport" value={selectedLocationAlgoliaType} />
          <input type="hidden" name="dateFlightOwnArrangements" value={dateFlightOwnArrangements} />
          <input type="hidden" name="timeFlightOwnArrangements" value={timeFlightOwnArrangements} />
          <input type="hidden" name="addFlightDetailsLater" value={addFlightDetailsLater} />

          <div>
            <div className="text-sm mb-3">
              <span className="underline">{transfer.objType === 'departure' ? 'Choose your drop off location' : 'Choose your pick up location'}</span>
              <span className="text-red-500 pl-1">*</span>
            </div>
            {/* <div className="text-sm mb-2">{transfer.objType === 'departure' ? 'Choose your drop off location' : 'Choose your pick up location'}</div> */}
            <div>
              {!properties[getPropObj(transfer.propUid, properties)].mobileLocation ? (
                <p className="text-xs mb-2">
                  The closest airstrip {transfer.objType === 'arrival' ? 'to' : 'from'} {properties[getPropObj(transfer.propUid, properties)].name}:{' '}
                  {getAirportProperties(transfer.propUid, properties).airport.name} ({getAirportProperties(transfer.propUid, properties).airport.code}) -{' '}
                  {getAirportProperties(transfer.propUid, properties).airport.distance} mins
                </p>
              ) : (
                <p className="text-xs mb-2">
                  The closest airstrip {transfer.objType === 'arrival' ? 'to' : 'from'} {properties[getPropObj(transfer.propUid, properties)].name}:{' '}
                  {getAirportName(airports, getAirportCodeFromUid(airports, checkCustomLocations.airportId))} ({getAirportCodeFromUid(airports, checkCustomLocations.airportId)}) -{' '}
                  {checkCustomLocations.airportDistance} mins
                </p>
              )}
            </div>
            <div className="flex justify-left my-3 space-x-3">
              <div className="m-3">
                <p className="text-sm">Location</p>
                <div className="relative w-80">
                  {/* TEST SEARCH */}

                  <InstantSearch indexName="locations" searchClient={searchClient} searchState={searchStateLocation} onSearchStateChange={setSearchStateLocation}>
                    <Configure filters={searchFilters} hitsPerPage={searchFilters === '' ? 0 : numHits} />
                    <div className="flex flex-wrap manageBooking">
                      <div className="flex">
                        <div></div>
                        <div className="ml-auto float-right mr-10">
                          {/* <SearchBox /> */}
                          <div className="flex">
                            <div className="relative"></div>
                            {/* <SearchBox onChange={handleChangeSearch} /> */}
                          </div>
                        </div>
                      </div>
                      <div id="" className="mt-2">
                        <HitsLocations />
                        <VirtualSearchBox />
                      </div>
                    </div>
                  </InstantSearch>
                </div>
              </div>
              {/* {selectedLocationAlgoliaName !== 'Search by airport, property or other' && selectedLocationAlgoliaType === 'airport' && (
                <div className="w-56 my-3 bg-white">
                  <p className="text-sm mb-2">Flight number</p>
                  <input
                    className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10"
                    type="text"
                    placeholder="Flight number"
                    name="fltnum"
                    defaultValue={transfer.formValues && transfer.edit && transfer.formValues.type === 'location' ? transfer.formValues.fltnum : null} // set the initial value of the input
                    // onInput={(event) => {
                    //   flightNumber = event.target.value; // update the value of the input as the user types
                    // }}
                  />
                </div>
              )} */}

              {selectedLocationAlgoliaName !== 'Search by airport, property or other' && selectedLocationAlgoliaType !== 'airport' && (
                <div className="w-56 my-3">
                  <p className="text-sm mb-2">{transfer.objType === 'departure' ? 'Drop off' : 'Pick up'} time</p>

                  <div className="">
                    <Select
                      placeholder={locationTime}
                      className="w-44 rounded-lg text-sm h-10"
                      options={timeOptions}
                      defaultValue={timeOptions.find((option) => option.value === locationTime)}
                      onChange={handleChangeTime}
                      isSearchable={false}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderWidth: 2,
                          borderColor: state.isFocused ? '#2A99A3' : '#2A99A3',
                          boxShadow: state.isFocused ? null : null,
                          borderRadius: '8px',
                          '&:hover': {
                            borderColor: '#2A99A3',
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '14px',
                          color: state.isSelected ? 'white' : '#000',
                          backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                          ':hover': {
                            backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
                            color: state.isSelected ? 'white' : 'white',
                          },
                        }),
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {selectedLocationAlgoliaName !== 'Search by airport, property or other' && (
              <div className={`ml-3 flex items-start ${selectedLocationAlgoliaType === 'airport' ? 'hidden' : ''}`}>
                <input
                  type="checkbox"
                  name="addLater"
                  className="checkbox checkbox-accent"
                  checked={addLater}
                  onChange={(e) => {
                    let isChecked = e.target.checked;
                    setAddLater(isChecked);
                    // Set your variable here based on the isChecked value
                  }}

                  //defaultChecked={transfer.formValues && transfer.edit && transfer.formValues.type === 'location' ? transfer.formValues.addLater : false}
                />{' '}
                {selectedLocationAlgoliaType === 'airport' ? (
                  <span className="label-text ml-2 text-sm">{transfer.objType === 'departure' ? 'Add drop off time later' : 'Add arrival time later'}</span>
                ) : (
                  <span className="label-text ml-2 text-sm">{transfer.objType === 'departure' ? 'Add drop off time later' : 'Add arrival time later'}</span>
                )}
              </div>
            )}

            {selectedLocationAlgoliaType === 'airport' && (
              <>
                <div className="text-sm underline pt-0 mb-3">{transfer.objType === 'departure' ? 'Enter your departure flight details' : 'Enter your arrival flight details'}</div>

                <div className="flex justify-left mb-3 space-x-3">
                  <div className="w-1/4 ml-3">
                    {/* <p className="text-sm mb-2">What time will you {transfer.objType === 'departure' ? 'leave or get picked up' : 'arrive'}?</p> */}
                    <p className="text-sm mb-2">Airline</p>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isSearchable={true}
                      maxMenuHeight={120}
                      // defaultValue={
                      //   transfer.formValues && transfer.edit && transfer.formValues.type === 'camp'
                      //     ? {
                      //         value: transfer.formValues.airlineUidCamp,
                      //         label: transfer.formValues.airlineNameCamp,
                      //       }
                      //     : airlineUidOwnArrangements !== null
                      //     ? {
                      //         value: airlineUidOwnArrangements,
                      //         label: airlineNameOwnArrangements,
                      //       }
                      //     : null
                      // }
                      //placeholder="Select airline"
                      placeholder={airlineNameOwnArrangements === null ? 'Select airline' : airlineNameOwnArrangements}
                      value={airlineNameOwnArrangements && airlineUidOwnArrangements ? { label: airlineNameOwnArrangements, value: airlineUidOwnArrangements } : null}
                      onChange={(selectedOption) => {
                        setAirlineNameOwnArrangements(selectedOption.label);
                        setAirlineUidOwnArrangements(selectedOption.value);
                      }}
                      options={airlines
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((airport) => ({
                          value: airport.uid,
                          label: airport.name,
                        }))}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: '36px',
                          borderRadius: '8px',
                          border: '2px solid #2A99A3',
                          boxShadow: 'none',
                          width: '175px', // Adjust width as needed
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden', // Ensure overflow is hidden
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '14px',
                          color: state.isSelected ? 'white' : '#666',
                          backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                          ':hover': {
                            backgroundColor: state.isSelected ? '#2A99A3' : '#F5F5F5',
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 1000,
                          width: '250px',
                        }),
                      }}
                    />
                  </div>

                  <div className="w-1/4 mx-3">
                    <p className="text-sm mb-2">Flight number</p>
                    {/* <input className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10" type="text" placeholder="Flight number" name="fltNum" value={fltNum} onChange={handleFltNumInputChange} /> */}
                    <input
                      className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10"
                      type="text"
                      placeholder="Flight number"
                      name="fltNum"
                      //value={fltNum}
                      onBlur={handleFltNumInputChange}
                      defaultValue={fltNum}
                    />

                    {/* <input className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10" type="text" name="fltNum" placeholder={fltNum === '' ? 'Flight number' : fltNum} /> */}
                  </div>
                  <div className="w-1/4 mx-3">
                    <p className="text-sm mb-2">Flight date</p>
                    <Select
                      //placeholder={dateFlightOwnArrangements}
                      placeholder={moment(dateFlightOwnArrangements).format('Do MMM YYYY')}
                      value={
                        dateFlightOwnArrangements
                          ? {
                              value: dateFlightOwnArrangements,
                              label: moment(dateFlightOwnArrangements).format('Do MMM YYYY'),
                            }
                          : null
                      }
                      className="w-44 rounded-lg text-sm h-10"
                      //options={timeOptions}
                      options={[
                        {
                          value: transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay,
                          label: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay).format('Do MMM YYYY'),
                        },
                        {
                          value: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                            // .add(transfer.objType === 'arrival' ? -1 : 1, 'days')
                            .add(1, 'days')
                            .format('YYYY-MM-DD'),
                          label: moment(
                            moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                              //.add(transfer.objType === 'arrival' ? -1 : 1, 'days')
                              .add(1, 'days')
                              .format('YYYY-MM-DD'),
                          ).format('Do MMM YYYY'),
                        },
                        // {
                        //   value: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                        //     .add(1, 'days')
                        //     .format('YYYY-MM-DD'),
                        //   label: moment(
                        //     moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                        //       .add(1, 'days')
                        //       .format('YYYY-MM-DD'),
                        //   ).format('Do MMM YYYY'),
                        // },
                      ]}
                      // defaultValue={
                      //   transfer.formValues && transfer.edit && transfer.formValues.type === 'camp' && transfer.formValues.dateFlightOwnArrangements
                      //     ? {
                      //         value: transfer.formValues.dateFlightOwnArrangements,
                      //         label: transfer.formValues.dateFlightOwnArrangements,
                      //       }
                      //     : dateFlightOwnArrangements
                      // }
                      onChange={handleChangeDateFlightOwnArrangements}
                      isSearchable={false}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderWidth: 2,
                          borderColor: state.isFocused ? '#2A99A3' : '#2A99A3',
                          boxShadow: state.isFocused ? null : null,
                          borderRadius: '8px',
                          '&:hover': {
                            borderColor: '#2A99A3',
                          },
                        }),
                      }}
                    />{' '}
                  </div>
                  <div className="w-1/4 mx-3">
                    <p className="text-sm mb-2">Flight {transfer.objType} time</p>
                    <Select
                      placeholder={timeFlightOwnArrangements}
                      className="w-44 rounded-lg text-sm h-10"
                      options={timeOptionsFive}
                      defaultValue={timeOptionsFive.find((option) => option.value === timeFlightOwnArrangements)}
                      onChange={handleChangeFlightTimeOwnArrangements}
                      isSearchable={false}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderWidth: 2,
                          borderColor: state.isFocused ? '#2A99A3' : '#2A99A3',
                          boxShadow: state.isFocused ? null : null,
                          borderRadius: '8px',
                          '&:hover': {
                            borderColor: '#2A99A3',
                          },
                        }),
                      }}
                    />{' '}
                  </div>
                </div>

                <div className="ml-3 mb-3 mt-5 flex items-start">
                  <input
                    type="checkbox"
                    name="addFlightDetailsLater"
                    className="checkbox checkbox-accent"
                    checked={addFlightDetailsLater}
                    onChange={(e) => {
                      let isChecked = e.target.checked;
                      setAddFlightDetailsLater(isChecked);
                      // Set your variable here based on the isChecked value
                    }}
                  />

                  <span className="label-text ml-2 text-sm">Complete details later</span>
                </div>
              </>
            )}

            <PackedLunch
              packedLunch={packedLunch}
              setPackedLunch={setPackedLunch}
              packedLunchChargeable={packedLunchChargeable}
              transfer={transfer}
              properties={properties}
              booking={booking}
              show={true}
            />
            {/* <div className="border-b border-slate-400 my-5"></div>
            <div className="text-sm mb-5">Choose your extras</div>
            <div className="ml-3 flex items-start mb-5">
              <div>
                <input type="checkbox" name="packedLunchCheck" className="checkbox checkbox-accent" defaultChecked={packedLunch} onChange={(e) => setPackedLunch(e.target.checked)} />{' '}
                <span className="label-text ml-2 text-sm">Depart with packed lunch</span>
              </div>
              <div className={`ml-5 ${packedLunch ? '' : 'hidden'}`}>
                <input type="checkbox" name="packedLunchChargeableCheck" className="checkbox checkbox-accent" defaultChecked={packedLunchChargeable} />{' '}
                <span className="label-text ml-2 text-sm">Chargeable</span>
              </div>
            </div> */}
            <div className="flex mt-5 justify-end">
              <div>
                <button
                  className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                  type="button"
                  onClick={(e) => {
                    if (!transfer.formValues) {
                      setSelectedTransferTuuid(null);
                      handleCloseModal();
                    } else {
                      closeForm(e, transfer.tuuid, transfers, setTransfers, setIsModalOpen);
                    }
                  }}
                >
                  Close
                </button>
                <button
                  className="confirm-button ml-auto text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit focus:outline-none"
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </form>

        {/* Arrive at camp */}
        <form
          id="arrivalCamp"
          className={`${
            transfer.formValues && transfer.edit && transfer.formValues.type === 'camp' && !selectedContent ? '' : selectedContent === 'arrivalCamp' ? '' : 'hidden'
          } p-5 bg-white rounded-lg ${flash ? 'flash-effect' : ''}`}
          onSubmit={(e) => handleFormSubmitOwnArrangementsSingle(e, transfers, setTransfers, setIsModalOpen, bookings, setBookings, properties)}
        >
          <input type="hidden" name="formType" value={transfer.objType === 'departure' ? 'departure' : 'arrival'} />
          <input type="hidden" name="tuuid" value={transfer.tuuid} />
          <input type="hidden" name="transferType" value={transfer.objType === 'departure' ? 'departure' : 'arrival'} />
          <input type="hidden" name="locationUid" value={selectedLocationAlgoliaUid} />
          <input type="hidden" name="locationName" value={selectedLocationAlgoliaName} />
          <input type="hidden" name="airport" value={selectedLocationAlgoliaType} />
          <input type="hidden" name="supplierUid" value={supplierUid} />
          <input type="hidden" name="supplierName" value={supplierName} />
          {/* <input type="hidden" name="dateOwnArrangements" value={dateOwnArrangements} /> */}
          <input type="hidden" name="timeOwnArrangements" value={timeOwnArrangements} />

          {/* <input type="hidden" name="addFlightDetails" value={addFlightDetails} /> */}
          <input type="hidden" name="airlineUidOwnArrangements" value={airlineUidOwnArrangements} />
          <input type="hidden" name="airlineNameOwnArrangements" value={airlineNameOwnArrangements} />
          <input type="hidden" name="dateFlightOwnArrangements" value={dateFlightOwnArrangements} />
          <input type="hidden" name="timeFlightOwnArrangements" value={timeFlightOwnArrangements} />
          <input type="hidden" name="addFlightDetailsLater" value={addFlightDetailsLater} />

          <div>
            <div className="text-sm mb-3">
              <span className="underline">Specify your transport provider and {transfer.objType === 'departure' ? 'departure time' : 'arrival time'}</span>
              <span className="text-red-500 pl-1">*</span>
            </div>
            <div className="flex justify-left mb-3 space-x-3">
              <div className="w-2/4 ml-3 mx-0">
                {/* <p className="text-sm mb-2">What time will you {transfer.objType === 'departure' ? 'leave or get picked up' : 'arrive'}?</p> */}
                {/* <p className="text-sm mb-2">Supplier</p> */}
                <p className="text-sm mb-2">Transfer by</p>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isSearchable={true}
                  maxMenuHeight={120}
                  // defaultValue={
                  //   transfer.formValues && transfer.edit && transfer.formValues.type === 'camp'
                  //     ? {
                  //         value: transfer.formValues.supplierUid,
                  //         label: transfer.formValues.supplierName,
                  //       }
                  //     : supplierUid !== null
                  //     ? {
                  //         value: supplierUid,
                  //         label: supplierName,
                  //       }
                  //     : null
                  // }
                  //placeholder="Select supplier"
                  placeholder={supplierName === null ? 'Select supplier' : supplierName}
                  onChange={(selectedOption) => {
                    setSupplierName(selectedOption.label);
                    setSupplierUid(selectedOption.value);
                  }}
                  options={suppliers
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((supplier) => ({
                      value: supplier.uid,
                      label: supplier.name,
                    }))}
                  value={supplierName && supplierUid ? { label: supplierName, value: supplierUid } : null}
                  styles={{
                    // control: (provided) => ({
                    //   ...provided,
                    //   height: '36px',
                    //   borderRadius: '8px',
                    //   border: '2px solid #2A99A3', // change border color here
                    //   boxShadow: 'none',
                    //   width: '175px',
                    //   fontSize: '14px',
                    // }),
                    control: (provided) => ({
                      ...provided,
                      height: '36px',
                      borderRadius: '8px',
                      border: '2px solid #2A99A3',
                      boxShadow: 'none',
                      width: '350px', // Adjust width as needed
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden', // Ensure overflow is hidden
                    }),
                    // Adjust singleValue style for selected option
                    singleValue: (provided) => ({
                      ...provided,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '14px',
                      color: state.isSelected ? 'white' : '#000',
                      backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                      ':hover': {
                        backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
                        color: state.isSelected ? 'white' : 'white',
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 1000,
                      width: '350px',
                    }),
                  }}
                />
              </div>

              <div className="w-1/4 pl-3 mx-3">
                <p className="text-sm mb-2">{capitalizeFirstLetter(transfer.objType)} time</p>
                <Select
                  placeholder={timeOwnArrangements}
                  className="w-48 rounded-lg text-sm h-10"
                  // options={timeOptionsFive}
                  options={arrivalDepartureTimeOptions}
                  defaultValue={timeOwnArrangements}
                  onChange={handleChangeTimeOwnArrangements}
                  isSearchable={false}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderWidth: 2,
                      borderColor: state.isFocused ? '#2A99A3' : '#2A99A3',
                      boxShadow: state.isFocused ? null : null,
                      borderRadius: '8px',
                      '&:hover': {
                        borderColor: '#2A99A3',
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '14px',
                      color: state.isSelected ? 'white' : '#000',
                      backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                      ':hover': {
                        backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
                        color: state.isSelected ? 'white' : 'white',
                      },
                    }),
                  }}
                />{' '}
              </div>
              <div className="w-1/4 mx-3">
                {' '}
                {/* <>
                <p className="text-sm mb-2">{capitalizeFirstLetter(transfer.objType)} date</p>
                <Select
                  placeholder={moment(dateOwnArrangements).format('Do MMM YYYY')}
                  value={dateOwnArrangements ? { label: moment(dateOwnArrangements).format('Do MMM YYYY'), value: dateOwnArrangements } : null}
                  className="w-44 rounded-lg text-sm h-10"
                  //options={timeOptions}
                  options={[
                    {
                      value: transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay,
                      label: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay).format('Do MMM YYYY'),
                    },

                    {
                      value: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                        //.add(transfer.objType === 'arrival' ? -1 : 1, 'days')
                        .add(1, 'days')
                        .format('YYYY-MM-DD'),
                      label: moment(
                        moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                          // .add(transfer.objType === 'arrival' ? -1 : 1, 'days')
                          .add(1, 'days')
                          .format('YYYY-MM-DD'),
                      ).format('Do MMM YYYY'),
                    },
                    // {
                    //   value: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                    //     .add(1, 'days')
                    //     .format('YYYY-MM-DD'),
                    //   label: moment(
                    //     moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                    //       .add(1, 'days')
                    //       .format('YYYY-MM-DD'),
                    //   ).format('Do MMM YYYY'),
                    // },
                  ]}
                  // defaultValue={
                  //   transfer.formValues && transfer.edit && transfer.formValues.type === 'camp' && transfer.formValues.dateOwnArrangements
                  //     ? {
                  //         value: transfer.formValues.dateOwnArrangements,
                  //         label: transfer.formValues.dateOwnArrangements,
                  //       }
                  //     : dateOwnArrangements
                  // }
                  onChange={handleChangeDateOwnArrangements}
                  isSearchable={false}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderWidth: 2,
                      borderColor: state.isFocused ? '#2A99A3' : '#2A99A3',
                      boxShadow: state.isFocused ? null : null,
                      borderRadius: '8px',
                      '&:hover': {
                        borderColor: '#2A99A3',
                      },
                    }),
                  }}
                />
                </> */}
              </div>
              {/* <div className="w-1/4 mx-3 bg-white"> </div> */}
            </div>

            <div className="text-sm pt-3 mb-3">
              <span className="underline">{transfer.objType === 'departure' ? 'Where are you departing to?' : 'Where are you arriving from?'}</span>
              <span className="text-red-500 pl-1">*</span>
            </div>
            <div className="flex justify-left mb-3 space-x-3">
              <div className="w-2/4 mx-3">
                {/* <p className="text-sm mb-2">What time will you {transfer.objType === 'departure' ? 'leave or get picked up' : 'arrive'}?</p> */}
                <p className="text-sm mb-0">Location</p>
                {/* <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isSearchable={true}
                  maxMenuHeight={120}
                  // value={{
                  //   label: 'Select location',
                  //   value: '',
                  // }}
                  // defaultValue={
                  //   transfer.formValues && transfer.edit && transfer.formValues.type === 'camp'
                  //     ? {
                  //         value: transfer.formValues.locationUid,
                  //         label: transfer.formValues.locationName,
                  //       }
                  //     : locationUid !== null
                  //     ? {
                  //         value: locationUid,
                  //         label: locationName,
                  //       }
                  //     : null
                  // }
                  placeholder={locationName === null ? 'Select location' : locationName}
                  value={locationName && locationUid ? { label: locationName, value: locationUid } : null}
                  //placeholder="Select location"
                  onChange={(selectedOption) => {
                    handleFormReset();
                    setLocationName(selectedOption.label);
                    setLocationUid(selectedOption.value);
                    setDateOwnArrangements(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);
                    setTimeOwnArrangements('Select time');
                    setTimeFlightOwnArrangements('Select time');
                    setSupplierName(null);
                    setSupplierUid(null);
                    setAirlineNameOwnArrangements(null);
                    setAirlineUidOwnArrangements(null);
                    setFltNum(null);
                    if (locations.find((location) => location.uid === selectedOption.value).type === 'airport') {
                      setAirport(true);
                    } else {
                      setAirport(false);
                    }
                  }}
                  options={sortedLocations}
                  //menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: '36px',
                      borderRadius: '8px',
                      border: '2px solid #2A99A3',
                      boxShadow: 'none',
                      width: '350px', // Adjust width as needed
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden', // Ensure overflow is hidden
                    }),
                    // Adjust singleValue style for selected option
                    singleValue: (provided) => ({
                      ...provided,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '14px',
                      color: state.isSelected ? 'white' : '#666',
                      backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                      ':hover': {
                        backgroundColor: state.isSelected ? '#2A99A3' : '#F5F5F5',
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 1000,
                      width: '350px',
                    }),
                  }}
                  // placeholder="Select"
                  // components={{
                  //   DropdownIndicator: null, // This line removes the dropdown arrow
                  // }}
                /> */}

                <InstantSearch indexName="locations" searchClient={searchClient} searchState={searchStateLocation} onSearchStateChange={setSearchStateLocation}>
                  <Configure filters={searchFilters} hitsPerPage={searchFilters === '' ? 0 : numHits} />
                  <div className="flex flex-wrap manageBooking">
                    <div className="flex">
                      <div></div>
                      <div className="ml-auto float-right mr-0">
                        {/* <SearchBox /> */}
                        <div className="flex">
                          <div className="relative"></div>
                          {/* <SearchBox onChange={handleChangeSearch} /> */}
                        </div>
                      </div>
                    </div>
                    <div id="" className="mt-2">
                      <HitsLocations />
                      <VirtualSearchBox />
                    </div>
                  </div>
                </InstantSearch>
              </div>
            </div>

            {/* 
            {locationUid !== null && locations.find((location) => location.uid === locationUid).type === 'airport' && (
              <div className="ml-3 mb-3 flex items-start">
                <input
                  type="checkbox"
                  name="addFlightDetails"
                  className="checkbox checkbox-accent"
                  defaultChecked={transfer.formValues && transfer.edit && transfer.formValues.type === 'ownArrangements' ? transfer.formValues.addFlightDetails : addFlightDetails}
                  onChange={(e) => {
                    let isChecked = e.target.checked;
                    setAddFlightDetails(isChecked);
                    setDateOwnArrangements(transfer.startDay);
                    // Set your variable here based on the isChecked value
                  }}
                />

                <span className="label-text ml-2 text-sm">Add flight details</span>
              </div>
            )} */}

            {/* {selectedLocationAlgoliaName !== 'Search by airport, property or other' && selectedLocationAlgoliaType === 'airport' && ( */}
            {selectedLocationAlgoliaType === 'airport' && (
              <>
                <div className="text-sm underline pt-3 mb-3">Enter your flight details in case on delays</div>

                <div className="flex justify-left mb-3 space-x-3">
                  <div className="w-1/4 ml-3">
                    {/* <p className="text-sm mb-2">What time will you {transfer.objType === 'departure' ? 'leave or get picked up' : 'arrive'}?</p> */}
                    <p className="text-sm mb-2">Airline</p>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isSearchable={true}
                      maxMenuHeight={120}
                      // defaultValue={
                      //   transfer.formValues && transfer.edit && transfer.formValues.type === 'camp'
                      //     ? {
                      //         value: transfer.formValues.airlineUidCamp,
                      //         label: transfer.formValues.airlineNameCamp,
                      //       }
                      //     : airlineUidOwnArrangements !== null
                      //     ? {
                      //         value: airlineUidOwnArrangements,
                      //         label: airlineNameOwnArrangements,
                      //       }
                      //     : null
                      // }
                      //placeholder="Select airline"
                      placeholder={airlineNameOwnArrangements === null ? 'Select airline' : airlineNameOwnArrangements}
                      value={airlineNameOwnArrangements && airlineUidOwnArrangements ? { label: airlineNameOwnArrangements, value: airlineUidOwnArrangements } : null}
                      onChange={(selectedOption) => {
                        setAirlineNameOwnArrangements(selectedOption.label);
                        setAirlineUidOwnArrangements(selectedOption.value);
                      }}
                      options={airlines
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((airport) => ({
                          value: airport.uid,
                          label: airport.name,
                        }))}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: '36px',
                          borderRadius: '8px',
                          border: '2px solid #2A99A3',
                          boxShadow: 'none',
                          width: '175px', // Adjust width as needed
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden', // Ensure overflow is hidden
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '14px',
                          color: state.isSelected ? 'white' : '#666',
                          backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                          ':hover': {
                            backgroundColor: state.isSelected ? '#2A99A3' : '#F5F5F5',
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 1000,
                          width: '250px',
                        }),
                      }}
                    />
                  </div>

                  <div className="w-1/4 mx-3">
                    <p className="text-sm mb-2">Flight number</p>
                    {/* <input className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10" type="text" placeholder="Flight number" name="fltNum" value={fltNum} onChange={handleFltNumInputChange} /> */}
                    <input
                      className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10"
                      type="text"
                      placeholder="Flight number"
                      name="fltNum"
                      //value={fltNum}
                      onBlur={handleFltNumInputChange}
                      defaultValue={fltNum}
                    />

                    {/* <input className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10" type="text" name="fltNum" placeholder={fltNum === '' ? 'Flight number' : fltNum} /> */}
                  </div>
                  <div className="w-1/4 mx-3">
                    <p className="text-sm mb-2">Flight date</p>
                    <Select
                      //placeholder={dateFlightOwnArrangements}
                      placeholder={moment(dateFlightOwnArrangements).format('Do MMM YYYY')}
                      className="w-44 rounded-lg text-sm h-10"
                      //options={timeOptions}
                      options={[
                        {
                          value: transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay,
                          label: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay).format('Do MMM YYYY'),
                        },
                        {
                          value: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                            //.add(transfer.objType === 'arrival' ? -1 : 1, 'days')
                            .add(1, 'days')
                            .format('YYYY-MM-DD'),
                          label: moment(
                            moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                              // .add(transfer.objType === 'arrival' ? -1 : 1, 'days')
                              .add(1, 'days')
                              .format('YYYY-MM-DD'),
                          ).format('Do MMM YYYY'),
                        },
                        // {
                        //   value: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                        //     .add(1, 'days')
                        //     .format('YYYY-MM-DD'),
                        //   label: moment(
                        //     moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                        //       .add(1, 'days')
                        //       .format('YYYY-MM-DD'),
                        //   ).format('Do MMM YYYY'),
                        // },
                      ]}
                      value={dateFlightOwnArrangements ? { label: moment(dateFlightOwnArrangements).format('Do MMM YYYY'), value: dateFlightOwnArrangements } : null}
                      // defaultValue={
                      //   transfer.formValues && transfer.edit && transfer.formValues.type === 'camp' && transfer.formValues.dateFlightOwnArrangements
                      //     ? {
                      //         value: transfer.formValues.dateFlightOwnArrangements,
                      //         label: transfer.formValues.dateFlightOwnArrangements,
                      //       }
                      //     : dateFlightOwnArrangements
                      // }
                      onChange={handleChangeDateFlightOwnArrangements}
                      isSearchable={false}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderWidth: 2,
                          borderColor: state.isFocused ? '#2A99A3' : '#2A99A3',
                          boxShadow: state.isFocused ? null : null,
                          borderRadius: '8px',
                          '&:hover': {
                            borderColor: '#2A99A3',
                          },
                        }),
                      }}
                    />{' '}
                  </div>
                  <div className="w-1/4 mx-3">
                    <p className="text-sm mb-2">Flight {transfer.objType} time</p>
                    <Select
                      placeholder={timeFlightOwnArrangements}
                      className="w-44 rounded-lg text-sm h-10"
                      options={timeOptionsFive}
                      defaultValue={timeOptionsFive.find((option) => option.value === timeFlightOwnArrangements)}
                      onChange={handleChangeFlightTimeOwnArrangements}
                      isSearchable={false}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderWidth: 2,
                          borderColor: state.isFocused ? '#2A99A3' : '#2A99A3',
                          boxShadow: state.isFocused ? null : null,
                          borderRadius: '8px',
                          '&:hover': {
                            borderColor: '#2A99A3',
                          },
                        }),
                      }}
                    />{' '}
                  </div>
                </div>

                <div className="ml-3 mt-5 mb-3 flex items-start">
                  <input
                    type="checkbox"
                    name="addFlightDetailsLater"
                    className="checkbox checkbox-accent"
                    defaultChecked={transfer.formValues && transfer.edit && transfer.formValues.type === 'camp' ? transfer.formValues.addFlightDetailsLater : addFlightDetailsLater}
                    onChange={(e) => {
                      let isChecked = e.target.checked;
                      setAddFlightDetailsLater(isChecked);
                      // Set your variable here based on the isChecked value
                    }}
                  />

                  <span className="label-text ml-2 text-sm">Complete details later</span>
                </div>
              </>
            )}

            <p className="text-sm mt-6 mb-2">
              No transfers are included. Services
              {transfer.objType === 'departure' ? ' end when you leave the property' : ' start when you arrive at the property '}
            </p>
            <PackedLunch
              packedLunch={packedLunch}
              setPackedLunch={setPackedLunch}
              packedLunchChargeable={packedLunchChargeable}
              transfer={transfer}
              properties={properties}
              booking={booking}
              show={true}
            />
            <div className="flex mt-5 justify-end">
              <div>
                <button
                  className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                  type="button"
                  onClick={(e) => {
                    if (!transfer.formValues) {
                      setSelectedTransferTuuid(null);
                      handleCloseModal();
                    } else {
                      closeForm(e, transfer.tuuid, transfers, setTransfers, setIsModalOpen);
                    }
                  }}
                >
                  Close
                </button>
                <button
                  className="confirm-button ml-auto text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit focus:outline-none"
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </form>

        {/* No transfers*/}
        <form
          key={uuidv4()}
          id="ownArrangements"
          className={`${
            transfer.formValues && transfer.edit && transfer.formValues.type === 'ownArrangements' && !selectedContent ? '' : selectedContent === 'ownArrangements' ? '' : 'hidden'
          } p-5 bg-white rounded-lg`}
          onSubmit={(e) => handleFormSubmitOwnArrangementsSingle(e)}
        >
          <input type="hidden" name="formType" value={transfer.objType === 'departure' ? 'departure' : 'arrival'} />
          <input type="hidden" name="tuuid" value={transfer.tuuid} />

          <div>
            <div className="flex mt-5 justify-end">
              <div>
                <button
                  className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                  type="button"
                  onClick={(e) => {
                    if (!transfer.formValues) {
                      setSelectedTransferTuuid(null);
                      handleCloseModal();
                    } else {
                      closeForm(e, transfer.tuuid, transfers, setTransfers, setIsModalOpen);
                    }
                  }}
                >
                  Close
                </button>
                <button
                  className="confirm-button ml-auto text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit focus:outline-none"
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </form>

        {/* Book flight*/}
        <form
          id="bookAFlight"
          className={`${
            transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' && !selectedContent ? '' : selectedContent === 'bookAFlight' ? '' : 'hidden'
          } px-5 bg-white rounded-lg`}
          onSubmit={(e) => handleFormSubmitAirport(e, transfers, setTransfers, setIsModalOpen, setRefreshTransferRates, bookings, setBookings, properties, customLocations, airports)}
        >
          {!showFlightDetails ? (
            <div>
              <input type="hidden" name="formType" value={transfer.objType} />
              <input type="hidden" name="tuuid" value={transfer.tuuid} />
              <input type="hidden" name="formName" value="bookFlight" />
              <input type="hidden" name="flightDetails" value={selectedFlight ? JSON.stringify(selectedFlight) : ''} />
              <input type="hidden" name="flightAdults" value={flightAdults} />
              <input type="hidden" name="flightChildren" value={flightChildren} />
              <input type="hidden" name="flightInfants" value={flightInfants} />
              <input type="hidden" name="airlineName" value={airlineName} />
              <input type="hidden" name="dateAdjusted" value={dateAdjusted} />

              <input type="hidden" name="flightPricing" value={JSON.stringify(flightPricing)} />

              <div>
                {!properties[getPropObj(transfer.propUid, properties)].mobileLocation ? (
                  <p className="text-xs mb-2">
                    Search flights {transfer.objType === 'arrival' ? 'to' : 'from'} {properties[getPropObj(transfer.propUid, properties)].name}
                    's closest airstrip: {getAirportProperties(transfer.propUid, properties).airport.name} - {getAirportProperties(transfer.propUid, properties).airport.distance} mins
                  </p>
                ) : (
                  <p className="text-xs mb-2">
                    Search flights {transfer.objType === 'arrival' ? 'to' : 'from'} {properties[getPropObj(transfer.propUid, properties)].name}
                    's closest airstrip: {getAirportName(airports, getAirportCodeFromUid(airports, checkCustomLocations.airportId))} - {checkCustomLocations.airportDistance} mins
                  </p>
                )}
                <div className="flex justify-left mx-3 space-x-3">
                  <div className="w-80 mx-3 bg-white">
                    <p className="text-sm mb-2">{transfer.objType === 'arrival' ? 'Select your origin airport' : 'Select your destination airport'}</p>
                    <InstantSearch indexName="airports" searchClient={searchClient} searchState={searchStateAirport} onSearchStateChange={setSearchStateAirport}>
                      <Configure filters={airportSearchFilters} hitsPerPage={airportSearchFilters === '' ? 0 : numHits} />
                      <div className="flex flex-wrap manageBooking">
                        <div className="flex w-[800px]">
                          <div></div>
                          <div className="ml-auto float-right mr-10">
                            {/* <SearchBox /> */}
                            <div className="flex">
                              <div className="relative"></div>
                              {/* <SearchBox onChange={handleChangeSearch} /> */}
                            </div>
                          </div>
                        </div>
                        <div key={uuidv4()} id="" className="mt-2">
                          <HitsAirports />
                          <VirtualSearchBox />
                        </div>
                      </div>
                    </InstantSearch>
                    <div className="hidden">{selectedFlightAlgolia}</div>
                  </div>
                  <div className="w-56 m-3 bg-white hidden">
                    <p className="text-sm mb-2">Destination airport</p>
                    {formattedMatchingAirports.length > 0 && (
                      <select
                        className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10"
                        value={finalFlightUid}
                        name="airport"
                        required
                        onChange={async (event) => {
                          const selectedValue = event.target.value;
                          setFinalFlightUid(selectedValue);
                        }}
                      >
                        <option value="" disabled selected={transfer.objType === 'departure' && !transfer.formValues?.flightDetails}>
                          Select airport
                        </option>
                        {formattedMatchingAirports.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>

                  {selectedFlightAlgolia !== 'Search by airport' && (
                    <div className="w-56 m-3 bg-white">
                      <p className="text-sm text-white mb-2"> - </p>
                      <button
                        className="confirm-button ml-auto text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit focus:outline-none"
                        type="button"
                        onClick={async (event) => {
                          //console.log(finalFlightUid);
                          const nonAlgoliaAirport = airports.find(
                            (airport) => airport.uid === airportId,
                            //&& airport.active !== false
                          );

                          // new search airport codes
                          const nonAlgoliaCode = nonAlgoliaAirport.code;
                          if (transfer.objType === 'arrival') {
                            await handleOptionClickGetFlights(selectedFlightAlgolia, nonAlgoliaCode);
                          } else {
                            await handleOptionClickGetFlights(nonAlgoliaCode, selectedFlightAlgolia);
                          }
                          setLocalSelectedIndices([]);
                          setLocalSelectedIndex([]);
                        }}
                      >
                        Search flights
                      </button>
                    </div>
                  )}
                </div>

                <div className="flex mt-5 justify-end">
                  <div>
                    <button
                      className="ml-auto mr-0 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                      type="button"
                      onClick={(e) => {
                        if (!transfer.formValues) {
                          setSelectedTransferTuuid(null);
                          handleCloseModal();
                        } else {
                          closeForm(e, transfer.tuuid, transfers, setTransfers, setIsModalOpen);
                        }
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <input type="hidden" name="formType" value={transfer.objType} />
              <input type="hidden" name="tuuid" value={transfer.tuuid} />
              <input type="hidden" name="formName" value="bookFlight" />
              <input type="hidden" name="flightDetails" value={selectedFlight ? JSON.stringify(selectedFlight) : ''} />
              <input type="hidden" name="flightAdults" value={flightAdults} />
              <input type="hidden" name="flightChildren" value={flightChildren} />
              <input type="hidden" name="flightInfants" value={flightInfants} />
              <input type="hidden" name="airlineName" value={airlineName} />
              <input type="hidden" name="dateAdjusted" value={dateAdjusted} />

              <input type="hidden" name="flightPricing" value={JSON.stringify(flightPricing)} />

              <input type="hidden" name="connectionType" value={connectionType} />
              <input type="hidden" name="connectionClick" value={connectionClick} />

              <input type="hidden" name="locationUid" value={selectedLocationAlgoliaUid} />
              <input type="hidden" name="locationName" value={selectedLocationAlgoliaName} />

              <input type="hidden" name="supplierName" value={supplierName} />
              <input type="hidden" name="supplierUid" value={supplierUid} />

              <input type="hidden" name="airlineUidFlight" value={airlineUidOwnArrangements} />
              <input type="hidden" name="airlineNameFlight" value={airlineNameOwnArrangements} />
              <input type="hidden" name="timeFlightFlight" value={timeFlightOwnArrangements} />

              <div>
                <div className="flex flex-wrap my-3">
                  <>
                    {isLoading ? (
                      <div className="text-sm animate-pulse">Searching for flights...</div>
                    ) : flightResults && flightResults.length > 0 ? (
                      <div>
                        {(() => {
                          const getSelectedOuterIndex = (selectedFlightIndex, flightResults) => {
                            let accumulatedCount = 0;
                            for (let i = 0; i < flightResults.length; i++) {
                              for (let j = 0; j < flightResults[i].length; j++) {
                                accumulatedCount += flightResults[i][j].flight.length;
                                if (selectedFlightIndex < accumulatedCount) {
                                  return { outerIndex: i, innerIndex: j }; // Return both indices
                                }
                              }
                            }

                            return null;
                          };

                          const selectedOuterIndex = getSelectedOuterIndex(selectedFlightIndex, flightResults);

                          if (selectedFlightIndex !== null && selectedOuterIndex !== null && selectedFlightIndex < flightResults[selectedOuterIndex].flight.length) {
                            return (
                              <>
                                <div className="text-sm mb-3">Select passengers</div>
                                <div className="row-2 grid grid-cols-12">
                                  <div className="col-span-3 px-2">
                                    <div className="text-xs mb-1">Adults</div>
                                    {/* BUTTON START */}
                                    <div className="grid grid-rows-1 grid-flow-col bg-white border-2 border-slate-200 rounded-lg h-9">
                                      <div
                                        className="border-r border-slate-200 text-center align-middle w-10 h-8 flex items-center justify-center cursor-pointer"
                                        onClick={() => {
                                          if (flightAdults > 1) {
                                            setFlightAdults(flightAdults - 1);
                                            setReCheckPax(true);
                                          } else {
                                            toast.error(`Room must have at least 1 adult`);
                                          }
                                        }}
                                      >
                                        -
                                      </div>
                                      <div className="text-xs font-normal text-center align-middle w-10 h-8 flex items-center justify-center">{flightAdults}</div>
                                      <div
                                        className="border-l border-slate-200 text-center align-middle w-10 h-8 flex items-center justify-center cursor-pointer"
                                        onClick={() => {
                                          setFlightAdults(flightAdults + 1);
                                          setReCheckPax(true);
                                        }}
                                      >
                                        +
                                      </div>
                                    </div>
                                    {/* BUTTON END */}
                                  </div>

                                  <div className="col-span-3 px-2">
                                    <div className="text-xs mb-1">Children</div>
                                    {/* BUTTON START */}
                                    <div className="grid grid-rows-1 grid-flow-col bg-white border-2 border-slate-200 rounded-lg h-9">
                                      <div
                                        className="border-r border-slate-200 text-center align-middle w-10 h-8 flex items-center justify-center cursor-pointer"
                                        onClick={() => {
                                          if (flightChildren >= 1) {
                                            setFlightChildren(flightChildren - 1);
                                            setReCheckPax(true);
                                          } else {
                                            toast.error(`Room must have at least 1 adult`);
                                          }
                                        }}
                                      >
                                        -
                                      </div>
                                      <div className="text-xs font-normal text-center align-middle w-10 h-8 flex items-center justify-center">{flightChildren}</div>
                                      <div
                                        className="border-l border-slate-200 text-center align-middle w-10 h-8 flex items-center justify-center cursor-pointer"
                                        onClick={() => {
                                          setFlightChildren(flightChildren + 1);
                                          setReCheckPax(true);
                                        }}
                                      >
                                        +
                                      </div>
                                    </div>
                                    {/* BUTTON END */}
                                  </div>
                                  {searchAgain ? (
                                    <div className="text-xs col-span-6 px-2 text-orange-600">
                                      <div className="text-xs font-semibold text-orange-600"> Please note:</div>
                                      <div className="text-xs text-orange-600 mt-1">Adults and children have changed</div>
                                      <div className="text-xs text-orange-600">Click the 'Search flights' or 'Back' to cancel change.</div>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </>
                            );
                          }
                        })()}

                        {!searchAgain ? (
                          <div className="text-sm my-3"></div>
                        ) : (
                          <div>
                            <div
                              className="confirm-button mt-5 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal pt-2 px-4 border brand-border-color-v2 rounded h-10 w-fit focus:outline-none cursor-pointer"
                              onClick={async () => {
                                setLoadAdults(flightAdults);
                                setLoadChildren(flightChildren);
                                setLoadInfants(flightInfants);
                                setReCheckPax(true);
                                // new check code
                                setIsLoading(true);
                                setShowFlightDetails(true);

                                const nonAlgoliaAirport = airports.find(
                                  (airport) => airport.uid === airportId,
                                  //&& airport.active !== false
                                );
                                const nonAlgoliaCode = nonAlgoliaAirport.code;

                                const originCode = transfer.objType === 'arrival' ? selectedFlightAlgolia : nonAlgoliaCode;
                                const destinationCode = transfer.objType === 'arrival' ? nonAlgoliaCode : selectedFlightAlgolia;

                                const getAvailableFlights = await getDeepLink(
                                  properties,
                                  transfer,
                                  false,
                                  customLocations,
                                  originCode,
                                  destinationCode,
                                  airports,
                                  flightAdults,
                                  flightChildren,
                                  flightInfants,
                                );
                                const availableFlightsData = getAvailableFlights.aerocrs.flights;
                                console.log('availableFlightsData', availableFlightsData);
                                setFlightResults(availableFlightsData);
                                setSelectedFlightIndex(null);
                                setSelectedFlight(null);
                                setLocalSelectedIndex(null);
                                setIsLoading(false);
                              }}
                            >
                              Search flights
                            </div>
                          </div>
                        )}
                        {/* ORIGINAL CODE */}
                        {/* {flightResults.map((data, outerIndex) => {
													const flights = data.flight;
													return (
														<div key={outerIndex}>
															<div className="text-sm my-3">
																{getAirportName(
																	airports,
																	flights[outerIndex].fromcode
																)}{" "}
																({flights[outerIndex].fromcode}) -{" "}
																{getAirportName(
																	airports,
																	flights[outerIndex].tocode
																)}{" "}
																({flights[outerIndex].tocode})
															</div>

															{!searchAgain &&
																(currentPages[outerIndex] || 0) > 0 && (
																	<button
																		className="arrow-button mr-2"
																		onClick={() =>
																			handleArrowClick("left", outerIndex)
																		}>
																		{"<"}
																	</button>
																)}

															{!searchAgain &&
																flights
																	.slice(
																		(currentPages[outerIndex] || 0) *
																			buttonsToShow,
																		(currentPages[outerIndex] || 0) *
																			buttonsToShow +
																			buttonsToShow
																	)
																	.map((flight, index) => (
																		<button
																			key={index}
																			className={`mr-2 mb-2 bg-white font-normal text-base text-black border border-gray-400 hover:border-[#2A99A3] hover:bg-[#D6EEF0] py-1 px-4 rounded-lg h-10 w-auto 
																			
																			setLocalSelectedIndices([]);
																			setLocalSelectedIndex([]);
																			
																			${
																				localSelectedIndices !== [] &&
																				index ===
																					localSelectedIndices[outerIndex]
																					? "border-[#2A99A3] bg-[#D6EEF0]"
																					: ""
																			}
																			${
																				localSelectedIndex !== [] &&
																				index === localSelectedIndex
																					? "border-[#2A99A3] bg-[#D6EEF0]"
																					: ""
																			}
																			`}
																			type="button"
																			onClick={() => {
																				setLocalSelectedIndices([]);
																				setLocalSelectedIndex([]);
																				handleButtonClick(
																					index,
																					outerIndex,
																					flights
																				);
																			}}>
																			{moment(flight.STD).format("HH:mm")}
																		</button>
																	))}

															{!searchAgain &&
																(currentPages[outerIndex] || 0) *
																	buttonsToShow +
																	buttonsToShow <
																	flights.length && (
																	<button
																		className="arrow-button"
																		onClick={() =>
																			handleArrowClick("right", outerIndex)
																		}>
																		{">"}
																	</button>
																)}
														</div>
													);
												})} */}
                        {flightPage === 1 &&
                          flightResults.map((data, outerIndex) => {
                            const flights = data.flight;
                            return (
                              <div className="">
                                <div className="text-sm mb-3">
                                  <span className="underline">Select your flight time</span>
                                  <span className="text-red-500 pl-1">*</span>
                                </div>
                                <div key={outerIndex}>
                                  <div className="text-sm my-3">
                                    {flights.length > 0 ? (
                                      <>
                                        {getAirportName(
                                          airports,
                                          //data.flight[0].fromcode
                                          getAeroCrsCode(airports, flights[0].fromcode),
                                        )}{' '}
                                        ({flights[0].fromcode}) -{' '}
                                        {getAirportName(
                                          airports,
                                          //data.flight[0].tocode
                                          getAeroCrsCode(airports, flights[0].tocode),
                                        )}{' '}
                                        ({flights[0].tocode})
                                      </>
                                    ) : (
                                      'Flight information unavailable'
                                    )}
                                  </div>

                                  {!searchAgain && (currentPages[outerIndex] || 0) > 0 && (
                                    <button type="button" className="arrow-button mr-2" onClick={() => handleArrowClick('left', outerIndex)}>
                                      {'<'}
                                    </button>
                                  )}
                                  {/* SELECT FLIGHT THAT IS CLICKED */}
                                  {!searchAgain &&
                                    selectedFlight !== transfer.formValues?.flightDetails &&
                                    flights.slice((currentPages[outerIndex] || 0) * buttonsToShow, (currentPages[outerIndex] || 0) * buttonsToShow + buttonsToShow).map((flight, index) => (
                                      <button
                                        key={index}
                                        className={`mr-2 mb-2 bg-white font-normal text-base text-black border border-[#2A99A3] hover:border-[#2A99A3] hover:bg-[#D6EEF0] py-1 px-4 rounded-lg h-10 w-auto
                                        ${localSelectedIndices.length !== 0 && index === localSelectedIndices[outerIndex] ? 'border-4 border-[#2A99A3] bg-[#D6EEF0]' : ''}`}
                                        type="button"
                                        onClick={() => {
                                          setLocalSelectedIndices([]);
                                          setLocalSelectedIndex([]);
                                          handleButtonClick(index, outerIndex, flights);
                                        }}
                                      >
                                        {moment(flight.STD).format('HH:mm')}
                                      </button>
                                    ))}

                                  {/* SELECT FLIGHT WITH FORM VALUES SELECTED */}
                                  {!searchAgain &&
                                    selectedFlight === transfer.formValues?.flightDetails &&
                                    flights.slice((currentPages[outerIndex] || 0) * buttonsToShow, (currentPages[outerIndex] || 0) * buttonsToShow + buttonsToShow).map((flight, index) => (
                                      <button
                                        key={index}
                                        // className={`mr-2 mb-2 bg-white font-normal text-base text-black border border-[#2A99A3] hover:border-[#2A99A3] hover:bg-[#D6EEF0] py-1 px-4 rounded-lg h-10 w-auto
                                        // ${localSelectedIndices.length !== 0 && index === localSelectedIndices[outerIndex] ? 'border-4 border-[#2A99A3] bg-[#D6EEF0]' : ''}`}
                                        className={`mr-2 mb-2 bg-white font-normal text-base text-black border border-[#2A99A3] hover:border-[#2A99A3] hover:bg-[#D6EEF0] py-1 px-4 rounded-lg h-10 w-auto
                                        ${
                                          localSelectedIndices.length !== 0 && transfer.formValues?.flightDetails?.fltnum !== 'TBA' && flight.fltnum === transfer.formValues?.flightDetails?.fltnum
                                            ? 'border-4 border-[#2A99A3] bg-[#D6EEF0]'
                                            : ''
                                        }

                                          ${
                                            localSelectedIndices.length !== 0 && transfer.formValues?.flightDetails?.fltnum === 'TBA' && flight.uid === transfer.formValues?.flightDetails?.uid
                                              ? 'border-4 border-[#2A99A3] bg-[#D6EEF0]'
                                              : ''
                                          }

                                          `}
                                        type="button"
                                        onClick={() => {
                                          setLocalSelectedIndices([]);
                                          setLocalSelectedIndex([]);
                                          handleButtonClick(index, outerIndex, flights);
                                        }}
                                      >
                                        {moment(flight.STD).format('HH:mm')}
                                      </button>
                                    ))}
                                  {!searchAgain && (currentPages[outerIndex] || 0) * buttonsToShow + buttonsToShow < flights.length && (
                                    <button type="button" className="arrow-button" onClick={() => handleArrowClick('right', outerIndex)}>
                                      {'>'}
                                    </button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        {flightPage === 2 && (
                          <div className="text-sm mb-3">
                            <span className="underline">Flight details</span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="text-sm">No flights available</div>
                    )}
                  </>
                </div>
                {!searchAgain &&
                  !isLoading &&
                  (() => {
                    const getSelectedOuterIndex = (selectedFlightIndex, flightResults) => {
                      let accumulatedCount = 0;
                      for (let i = 0; i < flightResults.length; i++) {
                        accumulatedCount += flightResults[i].flight.length;
                        if (selectedFlightIndex < accumulatedCount) {
                          return i;
                        }
                      }
                      return null;
                    };

                    const selectedOuterIndex = getSelectedOuterIndex(selectedFlightIndex, flightResults);

                    return selectedFlightIndex !== null && selectedOuterIndex !== null && selectedFlightIndex < flightResults[selectedOuterIndex].flight.length;
                  })() && (
                    <div>
                      {!searchAgain && (
                        <div>
                          <div className="flex mt-0">
                            <div className="flex flex-col mt-0 items-start">
                              {(() => {
                                const getSelectedOuterIndex = (selectedFlightIndex, flightResults) => {
                                  let accumulatedCount = 0;
                                  for (let i = 0; i < flightResults.length; i++) {
                                    accumulatedCount += flightResults[i].flight.length;
                                    if (selectedFlightIndex < accumulatedCount) {
                                      return i;
                                    }
                                  }
                                  return null;
                                };

                                const selectedOuterIndex = getSelectedOuterIndex(selectedFlightIndex, flightResults);
                                if (selectedOuterIndex === null) return null;

                                const localIndex = selectedFlightIndex - (selectedOuterIndex > 0 ? flightResults[selectedOuterIndex - 1].flight.length : 0);

                                return (
                                  <>
                                    <p className="text-sm mb-2 brand-text-grey-1-v2">
                                      {getAirportName(airports, getAeroCrsCode(airports, selectedFlight.fromcode))} ({selectedFlight.fromcode}) -{' '}
                                      {getAirportName(airports, getAeroCrsCode(airports, selectedFlight.tocode))}
                                      {/* {airports.find(
																				(a) => a.code === selectedFlight.tocode
																			)?.name || null} */}{' '}
                                      ({selectedFlight.tocode}) {' > '}
                                      {getTimeDifference(selectedFlight.STD, selectedFlight.STA)}
                                    </p>
                                    <p className="text-xs mb-2 brand-text-grey-1-v2">Drive from property {getAirportProperties(transfer.propUid, properties).airport.distance} min</p>
                                    <p className="text-xs mb-4 brand-text-grey-1-v2">
                                      Cost: ${formatPrice(flightCost)} ({airlineName}) {flightPricing?.saleFare?.selectedFlightClass && `(Class: ${flightPricing.saleFare.selectedFlightClass})`}
                                    </p>
                                  </>
                                );
                              })()}
                            </div>
                          </div>

                          <>
                            {transfer.objType === 'arrival' && (
                              <div className={`mt-2 ml-0 flex items-start ${flightPage === 2 && 'hidden'}`}>
                                <input
                                  type="checkbox"
                                  name="includeArrivalTransfer"
                                  className={`checkbox checkbox-accent`}
                                  checked={includeArrivalTransfer}
                                  onChange={(e) => {
                                    let isChecked = e.target.checked;
                                    setIncludeArrivalTransfer(isChecked);
                                  }}
                                />

                                <span className="label-text ml-2 text-sm">Include transfer from arrival airport to {properties[getPropObj(transfer.propUid, properties)].name}</span>
                              </div>
                            )}
                            {transfer.objType === 'departure' && (
                              <div className={`mt-2 ml-0 flex items-start ${flightPage === 2 && 'hidden'}`}>
                                <input
                                  type="checkbox"
                                  name="includeDepartureTransfer"
                                  className="checkbox checkbox-accent"
                                  checked={includeDepartureTransfer}
                                  //defaultChecked={transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' ? transfer.formValues.includeDepartureTransfer : true}
                                  onChange={(e) => {
                                    let isChecked = e.target.checked;
                                    setIncludeDepartureTransfer(isChecked);
                                  }}
                                />

                                <span className="label-text ml-2 text-sm">Include transfer to departure airport from {properties[getPropObj(transfer.propUid, properties)].name}</span>
                              </div>
                            )}
                          </>

                          {flightPage === 2 && (
                            <>
                              <div className="text-sm pt-5 mb-3">
                                <span className="underline">
                                  {transfer.objType === 'arrival' ? 'How will you arrive at the airport?' : 'Where are you connecting to?'} {connectionType}
                                </span>
                              </div>
                              <div className="text-sm mb-3 flex items-center">
                                <div className="ml-3 flex items-center">
                                  <input
                                    type="radio"
                                    name="connectionType"
                                    value="true"
                                    className="form-radio bg-white"
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      setConnectionClick(e.target.value === 'true');
                                      setConnectionType('flight');
                                    }}
                                    checked={connectionClick === true}
                                    required
                                  />
                                  <span className="label-text ml-2 text-sm">{transfer.objType === 'arrival' ? 'Inbound' : 'Outbound'} flight</span>
                                </div>
                                <div className="ml-3 flex items-center">
                                  <input
                                    type="radio"
                                    name="connectionType"
                                    value="false"
                                    className="form-radio bg-white"
                                    // onChange={(e) => {
                                    //   console.log(e.target.value);
                                    //   setConnectionClick(e.target.value === 'true');
                                    //   setConnectionType('road');
                                    // }}
                                    onChange={(e) => {
                                      const handleOnChange = async () => {
                                        console.log(e.target.value);
                                        setConnectionClick(e.target.value === 'true');

                                        // const flightFiltersLoad = await loadFlightLocations(selectedFlight, transfer.objType, airports);

                                        // // console.log('Specific onClick triggered! ', transfer.propUid);
                                        // // console.log('park.id: ', parkId);
                                        // // console.log('testLoad: ', parkFiltersLoad);
                                        // // console.log(JSON.stringify(parkFiltersLoad, undefined, 4));
                                        // const airportParkFilters = flightFiltersLoad.map((id) => `park:${id}`).join(' OR ');
                                        // setFlightLocations(airportParkFilters);

                                        setConnectionType('road');
                                      };
                                      handleOnChange();
                                    }}
                                    checked={connectionClick === false}
                                    required
                                  />
                                  <span className="label-text ml-2 text-sm">Road transfer</span>
                                </div>
                              </div>

                              {/* <span className="underline">
                            connectionClick: {connectionClick === true ? 'true' : 'false'} connectionType: {connectionType}
                          </span> */}

                              {connectionType === 'flight' && (
                                <>
                                  <div className="flex justify-left mb-3 space-x-3">
                                    <div className="w-1/4 ml-3">
                                      {/* <p className="text-sm mb-2">What time will you {transfer.objType === 'departure' ? 'leave or get picked up' : 'arrive'}?</p> */}
                                      <p className="text-sm mb-2">Airline</p>
                                      <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        isSearchable={true}
                                        maxMenuHeight={120}
                                        // defaultValue={
                                        //   transfer.formValues && transfer.edit && transfer.formValues.type === 'camp'
                                        //     ? {
                                        //         value: transfer.formValues.airlineUidCamp,
                                        //         label: transfer.formValues.airlineNameCamp,
                                        //       }
                                        //     : airlineUidOwnArrangements !== null
                                        //     ? {
                                        //         value: airlineUidOwnArrangements,
                                        //         label: airlineNameOwnArrangements,
                                        //       }
                                        //     : null
                                        // }
                                        //placeholder="Select airline"
                                        placeholder={airlineNameOwnArrangements === null ? 'Select airline' : airlineNameOwnArrangements}
                                        value={airlineNameOwnArrangements && airlineUidOwnArrangements ? { label: airlineNameOwnArrangements, value: airlineUidOwnArrangements } : null}
                                        onChange={(selectedOption) => {
                                          setAirlineNameOwnArrangements(selectedOption.label);
                                          setAirlineUidOwnArrangements(selectedOption.value);
                                        }}
                                        options={airlines
                                          .sort((a, b) => a.name.localeCompare(b.name))
                                          .map((airport) => ({
                                            value: airport.uid,
                                            label: airport.name,
                                          }))}
                                        styles={{
                                          control: (provided) => ({
                                            ...provided,
                                            height: '36px',
                                            borderRadius: '8px',
                                            border: '2px solid #2A99A3',
                                            boxShadow: 'none',
                                            width: '175px', // Adjust width as needed
                                            fontSize: '14px',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden', // Ensure overflow is hidden
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '14px',
                                            color: state.isSelected ? 'white' : '#000',
                                            backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                                            ':hover': {
                                              backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
                                              color: state.isSelected ? 'white' : 'white',
                                            },
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: 1000,
                                            width: '250px',
                                            height: '125px',
                                            maxHeight: '125px', // Set the max height of the dropdown menu
                                            overflowY: 'auto',
                                          }),
                                        }}
                                      />
                                    </div>

                                    <div className="w-1/4 mx-3">
                                      <p className="text-sm mb-2">Flight number</p>
                                      {/* <input className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10" type="text" placeholder="Flight number" name="fltNum" value={fltNum} onChange={handleFltNumInputChange} /> */}
                                      <input
                                        className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10"
                                        type="text"
                                        placeholder="Flight number"
                                        name="fltNum"
                                        //value={fltNum}
                                        onBlur={handleFltNumInputChange}
                                        defaultValue={fltNum}
                                      />

                                      {/* <input className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10" type="text" name="fltNum" placeholder={fltNum === '' ? 'Flight number' : fltNum} /> */}
                                    </div>
                                    <div className="w-1/4 mx-3">
                                      <p className="text-sm mb-2">Flight {transfer.objType} time</p>
                                      <Select
                                        placeholder={timeFlightOwnArrangements}
                                        className="w-44 rounded-lg text-sm h-10"
                                        options={timeOptionsFive}
                                        defaultValue={timeOptionsFive.find((option) => option.value === timeFlightOwnArrangements)}
                                        onChange={handleChangeFlightTimeOwnArrangements}
                                        isSearchable={false}
                                        styles={{
                                          control: (base, state) => ({
                                            ...base,
                                            borderWidth: 2,
                                            borderColor: state.isFocused ? '#2A99A3' : '#2A99A3',
                                            boxShadow: state.isFocused ? null : null,
                                            borderRadius: '8px',
                                            '&:hover': {
                                              borderColor: '#2A99A3',
                                            },
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '14px',
                                            color: state.isSelected ? 'white' : '#000',
                                            backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                                            ':hover': {
                                              backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
                                              color: state.isSelected ? 'white' : 'white',
                                            },
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: 1000,
                                            //width: '350px',
                                            height: '125px',
                                            maxHeight: '125px', // Set the max height of the dropdown menu
                                            overflowY: 'auto',
                                          }),
                                        }}
                                      />{' '}
                                    </div>
                                  </div>
                                  <div className="ml-3 mb-3 mt-5 flex items-start">
                                    <input
                                      type="checkbox"
                                      name="addFlightDetailsLater"
                                      className="checkbox checkbox-accent"
                                      checked={addFlightDetailsLater}
                                      onChange={(e) => {
                                        let isChecked = e.target.checked;
                                        setAddFlightDetailsLater(isChecked);
                                        // Set your variable here based on the isChecked value
                                      }}
                                    />

                                    <span className="label-text ml-2 text-sm">Complete details later</span>
                                  </div>{' '}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}

                      {connectionType === 'road' && flightPage === 2 && (
                        <>
                          <div className="flex justify-left mb-3 space-x-3">
                            <div className="w-2/4 ml-3 mx-0">
                              {/* <p className="text-sm mb-2">What time will you {transfer.objType === 'departure' ? 'leave or get picked up' : 'arrive'}?</p> */}
                              {/* <p className="text-sm mb-2">Supplier</p> */}
                              <p className="text-sm mb-2">Transfer by</p>
                              <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                isSearchable={true}
                                maxMenuHeight={120}
                                // defaultValue={
                                //   transfer.formValues && transfer.edit && transfer.formValues.type === 'camp'
                                //     ? {
                                //         value: transfer.formValues.supplierUid,
                                //         label: transfer.formValues.supplierName,
                                //       }
                                //     : supplierUid !== null
                                //     ? {
                                //         value: supplierUid,
                                //         label: supplierName,
                                //       }
                                //     : null
                                // }
                                //placeholder="Select supplier"
                                placeholder={supplierName === null ? 'Select supplier' : supplierName}
                                onChange={(selectedOption) => {
                                  setSupplierName(selectedOption.label);
                                  setSupplierUid(selectedOption.value);
                                }}
                                options={suppliers
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((supplier) => ({
                                    value: supplier.uid,
                                    label: supplier.name,
                                  }))}
                                value={supplierName && supplierUid ? { label: supplierName, value: supplierUid } : null}
                                styles={{
                                  // control: (provided) => ({
                                  //   ...provided,
                                  //   height: '36px',
                                  //   borderRadius: '8px',
                                  //   border: '2px solid #2A99A3', // change border color here
                                  //   boxShadow: 'none',
                                  //   width: '175px',
                                  //   fontSize: '14px',
                                  // }),
                                  control: (provided) => ({
                                    ...provided,
                                    height: '36px',
                                    borderRadius: '8px',
                                    border: '2px solid #2A99A3',
                                    boxShadow: 'none',
                                    width: '350px', // Adjust width as needed
                                    fontSize: '14px',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden', // Ensure overflow is hidden
                                  }),
                                  // Adjust singleValue style for selected option
                                  singleValue: (provided) => ({
                                    ...provided,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    fontSize: '14px',
                                    color: state.isSelected ? 'white' : '#000',
                                    backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                                    ':hover': {
                                      backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
                                      color: state.isSelected ? 'white' : 'white',
                                    },
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 1000,
                                    width: '350px',
                                    height: '125px',
                                    maxHeight: '125px', // Set the max height of the dropdown menu
                                    overflowY: 'auto',
                                  }),
                                }}
                              />
                            </div>
                            <div className="w-2/4 mx-3">
                              {/* <p className="text-sm mb-2">What time will you {transfer.objType === 'departure' ? 'leave or get picked up' : 'arrive'}?</p> */}
                              <p className="text-sm mb-0">{transfer.objType === 'departure' ? 'To' : 'From'} Location</p>
                              <InstantSearch indexName="locations" searchClient={searchClient} searchState={searchStateLocation} onSearchStateChange={setSearchStateLocation}>
                                <Configure filters={flightLocations} hitsPerPage={flightLocations === '' ? 0 : numHits} />
                                <div className="flex flex-wrap manageBooking">
                                  <div className="flex">
                                    <div></div>
                                    <div className="ml-auto float-right mr-0">
                                      {/* <SearchBox /> */}
                                      <div className="flex">
                                        <div className="relative"></div>
                                        {/* <SearchBox onChange={handleChangeSearch} /> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div id="" className="mt-2">
                                    <HitsLocationsBookFlight />
                                    <VirtualSearchBox />
                                  </div>
                                </div>
                              </InstantSearch>
                            </div>

                            {/* <div className="w-1/4 mx-3 bg-white"> </div> */}
                          </div>
                          <div className="ml-3 mb-3 mt-5 flex items-start">
                            <input
                              type="checkbox"
                              name="addFlightDetailsLater"
                              className="checkbox checkbox-accent"
                              checked={addFlightDetailsLater}
                              onChange={(e) => {
                                let isChecked = e.target.checked;
                                setAddFlightDetailsLater(isChecked);
                                // Set your variable here based on the isChecked value
                              }}
                            />

                            <span className="label-text ml-2 text-sm">Complete details later</span>
                          </div>
                        </>
                      )}
                    </div>
                  )}
              </div>
              {!searchAgain && (
                <PackedLunch
                  packedLunch={packedLunch}
                  setPackedLunch={setPackedLunch}
                  packedLunchChargeable={packedLunchChargeable}
                  transfer={transfer}
                  properties={properties}
                  booking={booking}
                  show={false}
                />
              )}
              <div className="flex mt-5 justify-between">
                <div>
                  <button
                    className="bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                    type="button"
                    onClick={async (event) => {
                      setResetTimeButton(false);
                      setShowFlightDetails(false);
                      setFlightPage(1);
                    }}
                  >
                    Search again
                  </button>
                  <button
                    className="ml-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                    type="button"
                    onClick={(e) => {
                      if (!transfer.formValues) {
                        setSelectedTransferTuuid(null);
                        handleCloseModal();
                      } else {
                        closeForm(e, transfer.tuuid, transfers, setTransfers, setIsModalOpen);
                      }
                    }}
                  >
                    Close
                  </button>
                </div>

                <div className="flex">
                  {flightPage === 2 && (
                    <button
                      className="ml-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                      type="button"
                      disabled={searchAgain}
                      onClick={() => setFlightPage(1)}
                    >
                      Back
                    </button>
                  )}
                  {flightResults && flightResults.length > 0 && flightResults.some((data) => data.flight && data.flight.length > 0) && flightPage === 2 ? (
                    <button
                      className="confirm-button ml-5 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit focus:outline-none"
                      type="submit"
                      disabled={searchAgain}
                      onClick={handleConfirmClickSubmitFlight}
                    >
                      Confirm
                    </button>
                  ) : (
                    <button
                      className="confirm-button ml-5 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit focus:outline-none"
                      type="submit"
                      disabled={searchAgain}
                      onClick={() => {
                        const handleOnClick = async () => {
                          const flightFiltersLoad = await loadFlightLocations(selectedFlight, transfer.objType, airports);
                          const airportParkFilters = flightFiltersLoad.map((id) => `park:${id}`).join(' OR ');
                          setFlightLocations(airportParkFilters);

                          //setConnectionType('road');
                        };
                        handleOnClick();
                        setFlightPage(2);
                      }}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </form>

        {/* Click an option above*/}
        <div id="clickOptionAbove" className="text-sm flex justify-center">
          {!transfer.edit && selectedOption === null && (
            <div className="flex flex-col items-center justify-center">
              <div className="mb-3 mt-3">Click an option above</div>
              <button
                className="mt-2 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                type="button"
                onClick={(e) => {
                  if (!transfer.formValues) {
                    setSelectedTransferTuuid(null);
                    handleCloseModal();
                  } else {
                    closeForm(e, transfer.tuuid, transfers, setTransfers, setIsModalOpen);
                  }
                }}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default TransferSingle;
