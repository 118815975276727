import { calculateDaysToAddForVehicles, findNextBooking, getPropObj, getPropertyName, nomadVehicleRates, nonNomadVehicleRates } from './generalFunctions';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// ************************************* // Check availability for activities

function getVehiclesConfig(pax, stock) {
  let vehicles = Object.keys(stock).sort((a, b) => parseInt(b.substring(1)) - parseInt(a.substring(1))); // sort vehicle types by seat count
  let combinations = [];

  // Generate all combinations of vehicles
  function generateCombinations(pax, combination, index) {
    if (pax <= 0) {
      if (combination.length > 0) {
        // Calculate cost of combination
        let cost = combination.reduce((total, { vehicle, count }) => total + (100 * vehicle + 100) * count, 0);

        combinations.push({ combination, cost });
      }
      return;
    }

    if (index >= vehicles.length) return;

    let vehicle = parseInt(vehicles[index].substring(1)); // get the seat count from the vehicle type (e.g., "v4" -> 4)

    for (let i = 0; i <= Math.min(stock[vehicles[index]], Math.ceil(pax / vehicle)); i++) {
      combination.push({ vehicle, count: i });
      generateCombinations(pax - vehicle * i, combination.slice(), index + 1);
      combination.pop();
    }
  }

  // Sort combinations by cost
  generateCombinations(pax, [], 0);
  combinations.sort((a, b) => a.cost - b.cost);

  // Format output
  return combinations.map(({ combination, cost }) =>
    combination
      .filter(({ count }) => count > 0)
      .reduce((result, { vehicle, count }) => {
        result[`v${vehicle}`] = count;
        return result;
      }, {}),
  );
}

function getAvailableVehicles(properties, uid, startDay, endDay, northernRule) {
  const availableVehicles = [];

  // Find the property with the given uid and other conditions (active, supplier, northernGuide)
  const property = properties.find((p) => p.uid === uid && p.active === true && p.supplier.name === 'Nomad Tanzania' && p.northernGuide === northernRule && p.vehicles === true);
  //  !const property = properties.find((p) => p.uid === uid && p.active === true && p.supplier.name === 'Nomad Tanzania' && p.vehicles === true);

  if (property === undefined) return null;

  // Get the rooms from the property
  const { rooms } = property;

  // Filter the rooms
  const filteredRooms = rooms.filter((r) => r.type === 'Vehicle' && r.active === true);

  // Convert the date strings to Date objects
  const startDate = new Date(startDay);
  const endDate = new Date(endDay);

  // Iterate over the filtered rooms
  for (let room of filteredRooms) {
    // Initialize minimum stock to be a very large number
    let minStock = Number.MAX_SAFE_INTEGER;

    // Iterate over the date range
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      // Format the date as a string
      const dateStr = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;

      // Get the stock for the current date
      const stock = room.stock.total[dateStr];

      // If the stock is less than the current minimum, update the minimum
      if (stock < minStock) {
        minStock = stock;
      }
    }

    // If the minimum stock is still the initial value, no stock was found for the given date range
    if (minStock === Number.MAX_SAFE_INTEGER) {
      minStock = 0;
    }

    // Add the room to the result array, including the minimum stock
    availableVehicles.push({
      name: room.name,
      max_capacity: room.max_capacity,
      max_adults: room.max_adults,
      id: room.id,
      stock: minStock,
    });
  }

  return availableVehicles;
}

// Get vehicles stock from array created in getAvailableVehicles
function calculateVehicleStock(vehicles, stock) {
  for (let vehicle of vehicles) {
    // Check if max_capacity is greater than 0
    if (vehicle.max_capacity > 0) {
      let vehicleKey = 'v' + vehicle.max_capacity;

      // Check if this key already exists in the stock object
      if (stock.hasOwnProperty(vehicleKey)) {
        // If the key already exists, add the vehicle's stock to the existing value
        stock[vehicleKey] += vehicle.stock;
      } else {
        // If the key doesn't exist yet, create it and set it to the vehicle's stock
        stock[vehicleKey] = vehicle.stock;
      }
    }
  }
  return stock;
}

async function calculatePaxStock(bookings, requiredIndex, properties, northernRule) {
  // Calculate vehicles -> START
  console.log('Calculate vehicles -> START');
  //await getAvailableVehicles(properties, uid, startDay, endDay)
  console.log(bookings[requiredIndex].propUid);
  const propUid = bookings[requiredIndex].propUid;
  const startDay = moment(bookings[requiredIndex].startDay).format('YYYY-MM-DD');
  const endDay = moment(bookings[requiredIndex].endDay).subtract(1, 'days').format('YYYY-MM-DD');

  console.log(startDay);
  console.log(endDay);
  const vehicles = await getAvailableVehicles(properties, propUid, startDay, endDay, northernRule);

  // Only proceed if there are the vehicles condition in getAvailableVehicles is met
  if (
    vehicles === null ||
    (properties[getPropObj(propUid, properties)].supplier.name !== 'Nomad Tanzania' && !nonNomadVehicleRates(bookings[requiredIndex].rateName)) ||
    (properties[getPropObj(propUid, properties)].supplier.name === 'Nomad Tanzania' && !nomadVehicleRates(bookings[requiredIndex].rateName))
  ) {
    console.log('Vehicles condition not met');
    return 'na';
    //return "none";
  }
  console.log(JSON.stringify(vehicles, undefined, 4));
  let totalMaxCapacity = 0;

  // Calculate the combined total
  let combinedTotal = 0;

  // Iterate over each room and accumulate the combined total
  bookings[requiredIndex].rooms.forEach((room) => {
    combinedTotal += room.ad + room.ch + room.chi + room.inf;
  });

  // Display the combined total
  console.log('Combined Total:', combinedTotal);

  // for (let vehicle of vehicles) {
  //   totalMaxCapacity += vehicle.max_capacity;
  // }
  for (let vehicle of vehicles) {
    totalMaxCapacity += vehicle.max_capacity * vehicle.stock;
  }

  if (combinedTotal <= totalMaxCapacity) {
    // call availability
    // prepare data
    let stock = {};
    // Ensure vehicles is defined and iterable
    if (vehicles && Array.isArray(vehicles)) {
      stock = calculateVehicleStock(vehicles, stock);
    } else {
      console.log('No vehicles found');
    }

    console.log('Stock');
    //console.log(JSON.stringify(stock, undefined, 4));

    const availableVehicles = getVehiclesConfig(combinedTotal, stock);

    if (availableVehicles.length > 0) {
      console.log('vehicleArray');
      //console.log(JSON.stringify(availableVehicles, undefined, 4));
      //console.log(JSON.stringify(vehicles, undefined, 4));
      let vehicleArray = {
        vehiclesData: vehicles,
        availableVehicles: availableVehicles,
      };
      console.log(JSON.stringify(vehicleArray, undefined, 4));
      return vehicleArray;
      //return vehicles;
    } else {
      console.log('No vehicles available/no configuration');
      // No available configuration
      return 'none';
    }
  } else {
    console.log('No vehicles available/no capacity');
    // No available capacity
    return 'none';
  }

  // Calculate vehicles -> END
}

// getNorthernGuide
function getNorthernGuide(properties, uid) {
  console.log('getNorthernGuide');
  console.log(uid);
  const property = properties.find((property) => property.uid === uid);
  if (property) {
    return property.northernGuide;
  }
  // Handle case when no matching property is found
  return null;
}

const northernGuideVehicles = [
  {
    id: '11eda2f3f29e93ef9681ac1f6b1b6a6e',
    name: 'Guide Vehicle - 6 Seats',
    max_capacity: '6',
    max_adults: '6',
  },
  {
    id: '11eda2f5c07037cb9681ac1f6b1b6a6e',
    name: 'Guide Vehicle - 5 Seats',
    max_capacity: '5',
    max_adults: '5',
  },
];

async function processBookingNorthernGuideVehicles(bookings, indexBooking, properties) {
  console.log('PROCESS NORTHERN GUIDE VEHICLES');

  if (!Array.isArray(northernGuideVehicles)) {
    throw new Error('northernGuideVehicles must be an array');
  }

  let newBookings = [...bookings];

  let nonNomadVehicleRatesCheck = properties[getPropObj(newBookings[indexBooking].propUid, properties)].supplier.name !== 'Nomad Tanzania' && nonNomadVehicleRates(bookings[indexBooking].rateName);
  let nomadVehicleRatesCheck = properties[getPropObj(newBookings[indexBooking].propUid, properties)].supplier.name === 'Nomad Tanzania' && nomadVehicleRates(bookings[indexBooking].rateName);

  console.log('nonNomadVehicleRatesCheck ', nonNomadVehicleRatesCheck);
  console.log('nomadVehicleRatesCheck ', nomadVehicleRatesCheck);
  if (
    (properties[getPropObj(newBookings[indexBooking].propUid, properties)].supplier.name !== 'Nomad Tanzania' && !nonNomadVehicleRatesCheck) ||
    (properties[getPropObj(newBookings[indexBooking].propUid, properties)].supplier.name === 'Nomad Tanzania' && !nomadVehicleRatesCheck)
  ) {
    console.log('returning newBookings');
    return newBookings;
  }

  console.log('process northern guide vehicles');

  newBookings[indexBooking].rooms = newBookings[indexBooking].rooms.filter((room) => room.roomType !== 'Vehicle');
  console.log(JSON.stringify(newBookings[indexBooking].rooms, undefined, 4));
  // Calculate total number of passengers
  let combinedTotal = 0;
  newBookings[indexBooking].rooms.forEach((room) => {
    combinedTotal += room.ad + room.ch + room.chi + room.inf;
  });

  console.log('combinedTotal ', combinedTotal);

  const bestVehicleCombination = [];
  const sixSeater = northernGuideVehicles.find((v) => parseInt(v.max_capacity) === 6);
  const fiveSeater = northernGuideVehicles.find((v) => parseInt(v.max_capacity) === 5);

  // Using a switch statement to determine the best vehicle combination
  switch (combinedTotal) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      bestVehicleCombination.push(fiveSeater);
      break;
    case 6:
      bestVehicleCombination.push(sixSeater);
      break;
    case 7:
    case 8:
    case 9:
    case 10:
      bestVehicleCombination.push(fiveSeater, fiveSeater);
      break;
    case 11:
      bestVehicleCombination.push(sixSeater, fiveSeater);
      break;
    case 12:
      bestVehicleCombination.push(sixSeater, sixSeater);
      break;
    case 13:
    case 14:
    case 15:
      bestVehicleCombination.push(fiveSeater, fiveSeater, fiveSeater);
      break;
    case 16:
    case 17:
      bestVehicleCombination.push(sixSeater, sixSeater, fiveSeater);
      break;
    case 18:
      bestVehicleCombination.push(sixSeater, sixSeater, sixSeater);
      break;
    case 19:
    case 20:
      bestVehicleCombination.push(fiveSeater, fiveSeater, fiveSeater, fiveSeater);
      break;
    case 21:
    case 22:
    case 23:
      bestVehicleCombination.push(sixSeater, sixSeater, sixSeater, fiveSeater);
      break;
    case 24:
      bestVehicleCombination.push(sixSeater, sixSeater, sixSeater, sixSeater);
      break;
    case 25:
      bestVehicleCombination.push(fiveSeater, fiveSeater, fiveSeater, fiveSeater, fiveSeater);
      break;
    case 26:
    case 27:
    case 28:
    case 29:
      bestVehicleCombination.push(sixSeater, sixSeater, sixSeater, sixSeater, fiveSeater);
      break;
    case 30:
      bestVehicleCombination.push(fiveSeater, fiveSeater, fiveSeater, fiveSeater, fiveSeater, fiveSeater);
      break;
    case 31:
    case 32:
    case 33:
    case 34:
    case 35:
      bestVehicleCombination.push(sixSeater, sixSeater, sixSeater, sixSeater, sixSeater, fiveSeater);
      break;
    case 36:
      bestVehicleCombination.push(sixSeater, sixSeater, sixSeater, sixSeater, sixSeater, sixSeater);
      break;
    default:
      throw new Error('Invalid passenger count');
  }

  // Add vehicles to the booking
  for (const vehicle of bestVehicleCombination) {
    console.log('adding vehicle');
    newBookings = await addVehicleToBooking(vehicle, newBookings, indexBooking);
  }

  return newBookings;
}

// ! most recent
async function processBookingNorthernGuideVehicles2(bookings, indexBooking, properties) {
  console.log('PROCESS NORTHERN GUIDE VEHICLES');
  if (!Array.isArray(northernGuideVehicles)) {
    throw new Error('northernGuideVehicles must be an array');
  }

  let newBookings = [...bookings];

  let nonNomadVehicleRatesCheck = properties[getPropObj(newBookings[indexBooking].propUid, properties)].supplier.name !== 'Nomad Tanzania' && nonNomadVehicleRates(bookings[indexBooking].rateName);
  let nomadVehicleRatesCheck = properties[getPropObj(newBookings[indexBooking].propUid, properties)].supplier.name === 'Nomad Tanzania' && nomadVehicleRates(bookings[indexBooking].rateName);

  if (
    (properties[getPropObj(newBookings[indexBooking].propUid, properties)].supplier.name !== 'Nomad Tanzania' && !nonNomadVehicleRatesCheck) ||
    (properties[getPropObj(newBookings[indexBooking].propUid, properties)].supplier.name === 'Nomad Tanzania' && !nomadVehicleRatesCheck)
  ) {
    return newBookings;
  }

  newBookings[indexBooking].rooms = newBookings[indexBooking].rooms.filter((room) => room.roomType !== 'Vehicle');

  // Calculate total number of passengers
  let combinedTotal = 0;
  newBookings[indexBooking].rooms.forEach((room) => {
    combinedTotal += room.ad + room.ch + room.chi + room.inf;
  });

  // Sort vehicles by their max_capacity in ascending order
  northernGuideVehicles.sort((a, b) => parseInt(a.max_capacity) - parseInt(b.max_capacity));

  const bestVehicleCombination = [];

  while (combinedTotal > 0) {
    let remaining = combinedTotal;
    let bestFitVehicle = null;
    let exactFitVehicle = null;

    // Find the vehicle that would leave the least number of remaining passengers
    for (const vehicle of northernGuideVehicles) {
      const vehicleCapacity = parseInt(vehicle.max_capacity);
      if (vehicleCapacity <= remaining) {
        const tempRemaining = remaining - vehicleCapacity;

        // Check for an exact fit
        if (tempRemaining === 0) {
          exactFitVehicle = vehicle;
          break;
        }

        // Check for the best fit
        if (bestFitVehicle == null || tempRemaining < combinedTotal - parseInt(bestFitVehicle.max_capacity)) {
          bestFitVehicle = vehicle;
          remaining = tempRemaining;
        }
      }
    }

    // Prioritize exact fit over best fit
    if (exactFitVehicle) {
      bestVehicleCombination.push(exactFitVehicle);
      combinedTotal = 0; // All passengers fit exactly
    } else if (bestFitVehicle) {
      bestVehicleCombination.push(bestFitVehicle);
      combinedTotal = remaining;
    } else {
      // Use the smallest available vehicle if no suitable vehicle is found
      bestVehicleCombination.push(northernGuideVehicles[0]);
      combinedTotal -= parseInt(northernGuideVehicles[0].max_capacity);
    }
  }

  // Add vehicles to the booking
  for (const vehicle of bestVehicleCombination) {
    newBookings = await addVehicleToBooking(vehicle, newBookings, indexBooking);
  }

  return newBookings;
}

function addVehicleToBooking(vehicle, bookings, indexBooking) {
  console.log('addVehicleToBooking');
  // NORTHERN GUIDE SUPPLEMENT - START
  const northernGuideVehicleSupplement = bookings[indexBooking].northernGuideVehicleSupplement;
  console.log('northernGuideVehicleSupplement ', northernGuideVehicleSupplement);
  //  let northernGuideVehiclePrice;
  //  let northernGuideVehicleResRequestId;
  // if(bookings[indexBooking].northernGuideVehicleSupplement) {
  // 	northernGuideVehiclePrice = bookings[indexBooking].northernGuideVehiclePrice;
  // 	northernGuideVehicleResRequestId = bookings[indexBooking].northernGuideVehicleResRequestId;

  // }
  // NORTHERN GUIDE SUPPLEMENT - END
  const newVehicle = {
    selectedRoom: vehicle.id,
    selectedName: vehicle.name,
    ad: 0,
    ch: 0,
    chi: 0,
    inf: 0,
    available: true,
    edit: false,
    roomType: 'Vehicle',
    vehicleType: 'northernGuide',
    maxCapacity: vehicle.max_capacity,
    maxAdults: vehicle.max_adults,
    uuid: uuidv4(),
    northernGuideVehicleSupplement: northernGuideVehicleSupplement,
    ...(northernGuideVehicleSupplement && {
      northernGuideVehiclePrice: bookings[indexBooking].northernGuideVehiclePrice,
      northernGuideVehicleResRequestId: bookings[indexBooking].northernGuideVehicleResRequestId,
    }),
  };
  const newBookings = [...bookings];
  newBookings[indexBooking].rooms.push(newVehicle);
  console.log('newBookings[indexBooking].rooms');
  console.log(JSON.stringify(newBookings, undefined, 4));

  return newBookings;
}

function processNorthernGuideVehicleSupplement(properties, propIndexCall, agentObject, agentRates) {
  let northernGuideVehicleSupplement = false;
  let northernGuideVehiclePrice = 0;
  let northernGuideVehicleResRequestId = 0;

  if (properties[propIndexCall].supplier.name !== 'Nomad Tanzania') {
    northernGuideVehicleSupplement = true;
    if (agentObject.directBookingAgent) {
      northernGuideVehiclePrice = agentRates.northernGuideSupplement.rack.price;
      northernGuideVehicleResRequestId = agentRates.northernGuideSupplement.rack.resRequestId;
    } else {
      northernGuideVehiclePrice = agentRates.northernGuideSupplement.net.price;
      northernGuideVehicleResRequestId = agentRates.northernGuideSupplement.net.resRequestId;
    }
  }

  return {
    northernGuideVehicleSupplement,
    northernGuideVehiclePrice,
    northernGuideVehicleResRequestId,
  };
}

function processBookingCampVehicles(bookings, setBookings, indexBooking) {
  console.log('processBookingCampVehicles called');

  if (bookings && bookings[indexBooking]) {
    // Calculate totalPassengers from rooms where roomType is "Room"
    const totalPassengers = bookings[indexBooking].rooms.reduce((acc, room) => {
      if (room.roomType !== 'Vehicle') {
        return acc + room.ad + room.ch + room.chi + room.inf;
      }
      return acc;
    }, 0);

    console.log('Calculated totalPassengers:', totalPassengers);

    if (bookings[indexBooking].vehicles) {
      console.log('Vehicles data exists');
      console.log(JSON.stringify(bookings[indexBooking].vehicles, undefined, 4));
      const { vehiclesData } = bookings[indexBooking].vehicles;
      console.log('Debug vehiclesData:', JSON.stringify(vehiclesData));

      if (vehiclesData && vehiclesData.length > 0) {
        console.log('Creating vehicleStockMap');
        const vehicleStockMap = {};
        vehiclesData.forEach((vehicle) => {
          vehicleStockMap[vehicle.id] = vehicle.stock;
        });

        console.log('vehicleStockMap', vehicleStockMap);

        let remainingPassengers = totalPassengers;

        while (remainingPassengers > 0) {
          console.log('Remaining passengers:', remainingPassengers);

          // const vehicle = vehiclesData.find((v) => {
          //   console.log('Checking vehicle:', v.name, ', max_capacity:', v.max_capacity, ', stock:', vehicleStockMap[v.id]);
          //   return vehicleStockMap[v.id] > 0 && v.max_capacity <= remainingPassengers;
          // });
          const vehicle = vehiclesData.find((v) => {
            console.log('Checking vehicle:', v.name, ', max_capacity:', v.max_capacity, ', stock:', vehicleStockMap[v.id]);
            return vehicleStockMap[v.id] > 0; // Only check for available stock
          });

          if (!vehicle) {
            console.log('No more vehicles available to accommodate all passengers.');
            break;
          }

          console.log('Adding vehicle:', vehicle.name);

          const newRoom = {
            selectedRoom: vehicle.id,
            selectedName: vehicle.name,
            ad: 0,
            ch: 0,
            chi: 0,
            inf: 0,
            available: true,
            edit: false,
            roomType: 'Vehicle',
            vehicleType: 'camp',
            maxCapacity: vehicle.max_capacity,
            maxAdults: vehicle.max_adults,
            uuid: uuidv4(),
          };

          bookings[indexBooking].rooms.push(newRoom);

          vehicleStockMap[vehicle.id] -= 1; // Decrement the stock for this vehicle
          remainingPassengers -= vehicle.max_capacity; // Update remaining passengers
        }

        setBookings([...bookings]);
      } else {
        console.log('Invalid vehiclesData');
      }
    } else {
      console.log('Invalid vehicles data');
    }
  } else {
    console.log('Invalid bookings or indexBooking');
  }
}

// ! New 5 looking better!
const calculatePropertyVehicles = async (bookings, setBookings, transfers, tuuid, properties, skipRateCheck) => {
  console.log('calculatePropertyVehicles');
  //bookings = filterVehicleRoomsNoSet(bookings);
  for (let indexTransfer = 0; indexTransfer < transfers.length; indexTransfer++) {
    console.log('calculatePropertyVehicles 1 ', indexTransfer);
    const transfer = transfers[indexTransfer];
    const indexBooking = bookings.findIndex((b) => b.id === transfer.uuid);

    // Initial conditions
    const propertyObj = properties[getPropObj(transfer.propUid, properties)];
    // let nonNomadVehicleRatesCheck;
    // if (skipRateCheck) {
    //   nonNomadVehicleRatesCheck = true;
    // } else {
    //   nonNomadVehicleRatesCheck = propertyObj.supplier.name !== 'Nomad Tanzania' && nonNomadVehicleRates(bookings[indexBooking].rateName);
    // }
    console.log('indexBooking:', indexBooking);
    console.log('bookings[indexBooking].rateName:', JSON.stringify(bookings[indexBooking], null, 2));

    if (indexBooking === -1) {
      return bookings;
    }
    const nonNomadVehicleRatesCheck = propertyObj.supplier.name !== 'Nomad Tanzania' && nonNomadVehicleRates(bookings[indexBooking].rateName);

    const nomadVehicleRatesCheck = propertyObj.supplier.name === 'Nomad Tanzania' && nomadVehicleRates(bookings[indexBooking].rateName);
    const vehiclesCheck = propertyObj.vehicles;

    console.log('Property name: ' + propertyObj.name);

    if (indexBooking === -1 || (!nonNomadVehicleRatesCheck && propertyObj.supplier.name !== 'Nomad Tanzania') || (!nomadVehicleRatesCheck && propertyObj.supplier.name === 'Nomad Tanzania')) {
      console.log('continue');
      continue;
    }

    let isSafariTransfer = false;
    const nextTransfer = transfers[indexTransfer + 1];

    if (transfer.objType === 'arrival') {
      if (nextTransfer && nextTransfer.formValues && nextTransfer.formValues.transferType === 'safariTransfer') {
        isSafariTransfer = true;
      }
    } else {
      const previousTransfer = transfers[indexTransfer - 1];
      console.log('calculatePropertyVehicles 2 ', indexTransfer);
      if (transfer.formValues && transfer.formValues.transferType === 'safariTransfer') {
        console.log('calculatePropertyVehicles 3 true', indexTransfer);
        isSafariTransfer = true;
      } else if (previousTransfer && previousTransfer.formValues && previousTransfer.formValues.transferType === 'safariTransfer') {
        console.log('calculatePropertyVehicles 4 true', indexTransfer);
        isSafariTransfer = true;
      }
    }

    if (transfer.objType === 'departure') {
      const secondLastTransfer = transfers[indexTransfer - 2];
      if (secondLastTransfer && secondLastTransfer.formValues && secondLastTransfer.formValues.transferType === 'safariTransfer') {
        isSafariTransfer = true;
      }
    }

    if (nonNomadVehicleRatesCheck) {
      isSafariTransfer = true;
    }

    console.log('Actions based on isSafariTransfer and northernGuide');

    // Actions based on isSafariTransfer and northernGuide
    if (isSafariTransfer) {
      console.log('isSafariTransfer is true');
      const northernGuide = getNorthernGuide(properties, transfer.propUid);
      const hasNorthernGuideVehicle = bookings[indexBooking].rooms.some((room) => room.vehicleType === 'northernGuide');
      if (northernGuide) {
        if (!hasNorthernGuideVehicle) {
          console.log('processBookingNorthernGuideVehicles called');
          bookings = await processBookingNorthernGuideVehicles(bookings, indexBooking, properties);
        }
        //bookings = await processBookingNorthernGuideVehicles(bookings, indexBooking, properties);
      } else {
        // Check if "vehicleType": "camp" already exists in the rooms for the booking
        const hasNorthernGuideVehicle = bookings[indexBooking].rooms.some((room) => room.vehicleType === 'northernGuide');
        const hasCampVehicle = bookings[indexBooking].rooms.some((room) => room.vehicleType === 'camp');
        if (!hasCampVehicle && !hasNorthernGuideVehicle) {
          console.log('Calling processBookingCampVehicles for booking index', indexBooking);
          if (bookings[indexBooking].vehicles !== 'none' && vehiclesCheck) {
            console.log('processBookingCampVehicles called');

            processBookingCampVehicles(bookings, setBookings, indexBooking);
          } else if (northernGuide) {
            if (!hasNorthernGuideVehicle && vehiclesCheck) {
              console.log('processBookingNorthernGuideVehicles called instead of processBookingCampVehicles 1');

              bookings = await processBookingNorthernGuideVehicles(bookings, indexBooking, properties);
            }
          }
        }
      }
    } else {
      console.log('isSafariTransfer is false');
      // Check if "vehicleType": "camp" already exists in the rooms for the booking
      const hasCampVehicle = bookings[indexBooking].rooms.some((room) => room.vehicleType === 'camp');
      const hasNorthernGuideVehicle = bookings[indexBooking].rooms.some((room) => room.vehicleType === 'northernGuide');
      const northernGuide = getNorthernGuide(properties, transfer.propUid);
      if (!hasCampVehicle && !hasNorthernGuideVehicle) {
        console.log('Calling processBookingCampVehicles for booking index', indexBooking);
        if (bookings[indexBooking].vehicles !== 'none' && bookings[indexBooking].vehicles !== 'na' && vehiclesCheck) {
          console.log('processBookingCampVehicles called');
          processBookingCampVehicles(bookings, setBookings, indexBooking);
        } else if (northernGuide && !hasNorthernGuideVehicle && !hasCampVehicle && vehiclesCheck) {
          console.log('processBookingNorthernGuideVehicles called instead of processBookingCampVehicles 2');

          bookings = await processBookingNorthernGuideVehicles(bookings, indexBooking, properties);
        }
      }
    }
  }
  setBookings(bookings);
  return bookings;
};

function getVehicleRoomDetails(booking) {
  // Filter to only get matching rooms
  const matchingRooms = booking.rooms.filter((room) => room.roomType === 'Vehicle' && room.northernGuideVehicleSupplement);

  // Create an object to track counts of each selectedRoom
  const roomCounts = {};
  matchingRooms.forEach((room) => {
    if (roomCounts[room.selectedRoom]) {
      roomCounts[room.selectedRoom].count++;
    } else {
      roomCounts[room.selectedRoom] = {
        count: 1,
        name: room.selectedName,
        type: room.vehicleType,
        northernGuideVehicleSupplement: room.northernGuideVehicleSupplement ? room.northernGuideVehicleSupplement : false,
      };
    }
  });

  // Convert the count object to a string representation
  const names = Object.values(roomCounts)
    .map((room) => `${room.count}x ${room.name}`)
    .join(' | ');

  // Create an array of unique room types from matchingRooms
  const types = [...new Set(matchingRooms.map((room) => room.vehicleType))];
  const northernGuideVehicleSupplement = [...new Set(matchingRooms.map((room) => room.northernGuideVehicleSupplement))];
  return {
    exists: matchingRooms.length > 0,
    count: matchingRooms.length,
    names,
    types,
    northernGuideVehicleSupplement,
  };
}

function getVehicleRoomDetailsNotSupplement(booking) {
  // Filter to only get matching rooms
  const matchingRooms = booking.rooms.filter((room) => room.roomType === 'Vehicle');

  // Create an object to track counts of each selectedRoom
  const roomCounts = {};
  matchingRooms.forEach((room) => {
    if (roomCounts[room.selectedRoom]) {
      roomCounts[room.selectedRoom].count++;
    } else {
      roomCounts[room.selectedRoom] = {
        count: 1,
        name: room.selectedName,
        type: room.vehicleType,
        northernGuideVehicleSupplement: room.northernGuideVehicleSupplement ? room.northernGuideVehicleSupplement : false,
      };
    }
  });

  // Convert the count object to a string representation
  const names = Object.values(roomCounts)
    .map((room) => `${room.count}x ${room.name}`)
    .join(' | ');

  // Create an array of unique room types from matchingRooms
  const types = [...new Set(matchingRooms.map((room) => room.vehicleType))];

  const northernGuideVehicleSupplement = [...new Set(matchingRooms.map((room) => room.northernGuideVehicleSupplement))];

  return {
    exists: matchingRooms.length > 0,
    count: matchingRooms.length,
    names,
    types,
    northernGuideVehicleSupplement,
  };
}

function generateVehicleMessage(item, bookings, properties) {
  let vehicleTypesCount = {};
  let selectedNames = {};

  item.rooms.forEach((room) => {
    if (room.roomType === 'Vehicle') {
      if (vehicleTypesCount[room.vehicleType]) {
        vehicleTypesCount[room.vehicleType]++;
        selectedNames[room.vehicleType].push(room.selectedName);
      } else {
        vehicleTypesCount[room.vehicleType] = 1;
        selectedNames[room.vehicleType] = [room.selectedName];
      }
    }
  });

  let messages = [];
  for (let vehicleType in vehicleTypesCount) {
    let vehicleTypeLong = '';
    switch (vehicleType) {
      case 'camp':
        vehicleTypeLong = 'Camp';
        break;
      case 'northernGuide':
        vehicleTypeLong = 'Northern Guide';
        break;
      // Add more cases if there are other vehicle types
      default:
        vehicleTypeLong = vehicleType;
        break;
    }
    let vehicleCount = vehicleTypesCount[vehicleType];
    let vehiclePlural = vehicleCount > 1 ? 'Vehicles' : 'Vehicle';
    let namesString = selectedNames[vehicleType].join(', ');
    const vehiclesResult = getVehicleRoomDetails(item);
    const vehiclesResultNotSupplement = getVehicleRoomDetailsNotSupplement(item);
    // number of days
    let startDay = moment(item.startDay, 'YYYY-MM-DD');
    let endDay = moment(item.endDay, 'YYYY-MM-DD');

    // Northern Guide Vehicle Nights
    // Adding 1 to include the end day

    const nextBooking = findNextBooking(bookings, item.id);

    const daysToAdd = calculateDaysToAddForVehicles(item, nextBooking, properties);

    let totalDays = endDay.diff(startDay, 'days') + daysToAdd;
    //let totalDays = endDay.diff(startDay, 'days');
    if (item.northernGuideVehicleSupplement) {
      messages.push(
        `This stay will include the use of ${vehicleCount} X ${vehicleTypeLong} ${vehiclePlural} (${vehicleType}).<br>Vehicle types: ${vehiclesResult.names}. <br>Cost: $${
          vehiclesResult.count * item.northernGuideVehiclePrice * totalDays
        }`,
      );
    } else {
      messages.push(`This stay will include the use of ${vehicleCount} X ${vehicleTypeLong} ${vehiclePlural} (${vehicleType}).<br>Vehicle types: ${vehiclesResultNotSupplement.names}`);
    }
  }

  if (messages.length === 0) {
    // check for northern guide
    return null;
  } else {
    return messages.join(' ');
  }
}

function filterVehicleRooms(bookings, setBookings) {
  const updatedBookings =
    bookings.length > 0
      ? bookings.map((booking) => {
          return {
            ...booking,
            rooms: booking.rooms.filter((room) => room.roomType !== 'Vehicle'),
          };
        })
      : [];

  setBookings(updatedBookings);
}

function filterVehicleRoomsNew(bookings, setBookings) {
  const updatedBookings =
    bookings.length > 0
      ? bookings.map((booking) => {
          return {
            ...booking,
            rooms: booking.rooms.filter((room) => room.roomType !== 'Vehicle'),
          };
        })
      : [];

  setBookings(updatedBookings);
}

function filterVehicleRoomsBeforeAfter(bookingID, bookings, setBookings) {
  const bookingIndex = bookings.findIndex((booking) => booking.id === bookingID);

  const updatedBookings = bookings.map((booking, index) => {
    if (index === bookingIndex - 1 || index === bookingIndex || index === bookingIndex + 1) {
      return {
        ...booking,
        rooms: booking.rooms.filter((room) => room.roomType !== 'Vehicle'),
      };
    }
    return booking;
  });

  setBookings(updatedBookings);
}

function filterVehicleRoom(bookingID, bookings, setBookings) {
  const updatedBookings = bookings.map((booking) => {
    if (booking.id === bookingID) {
      return {
        ...booking,
        rooms: booking.rooms.filter((room) => room.roomType !== 'Vehicle'),
      };
    }
    return booking;
  });

  setBookings(updatedBookings);
}

function filterVehicleRoomsNoSet(bookings) {
  return bookings.map((booking) => {
    return {
      ...booking,
      rooms: booking.rooms.filter((room) => room.roomType !== 'Vehicle'),
    };
  });
}

const removeVehicleRooms = (booking) => {
  // Check if the booking object has a "rooms" array
  if (Array.isArray(booking.rooms)) {
    // Filter out the rooms where the roomType is "Vehicle"
    const updatedRooms = booking.rooms.filter((room) => room.roomType !== 'Vehicle');

    // Update the rooms array in the booking object
    booking.rooms = updatedRooms;
  }

  return booking;
};

function filterVehicleTransfers(item, transfers, setTransfers, transfersLatest, setTransfersLatest) {
  const updatedTransfers = transfers.map((transfer) => {
    if (transfer.uuid === item.id && (transfer.formValues?.type === 'location' || transfer.formValues?.type === 'safari')) {
      const { formValues, ...remainingProps } = transfer; // eslint-disable-line no-unused-vars
      return remainingProps;
    }
    return transfer;
  });

  setTransfers(updatedTransfers);

  const updatedTransfersLatest = transfersLatest.map((transfer) => {
    if (transfer.uuid === item.id && (transfer.formValues?.type === 'location' || transfer.formValues?.type === 'safari')) {
      const { formValues, ...remainingProps } = transfer; // eslint-disable-line no-unused-vars
      return remainingProps;
    }
    return transfer;
  });

  setTransfersLatest(updatedTransfersLatest);
}

export {
  getVehiclesConfig,
  getAvailableVehicles,
  calculatePaxStock,
  calculatePropertyVehicles,
  generateVehicleMessage,
  processNorthernGuideVehicleSupplement,
  filterVehicleRooms,
  filterVehicleRoomsNoSet,
  filterVehicleRoomsBeforeAfter,
  filterVehicleRoom,
  filterVehicleTransfers,
  getVehicleRoomDetails,
  getVehicleRoomDetailsNotSupplement,
  removeVehicleRooms,
};
