import React from 'react';

const ItinerariesViewMenu = ({ viewItinerariesMenu }) => {
  return (
    <div className="top-[-60px] w-[320px] flex justify-end items-end">
      <div className="flex mt-1">
        <div className="text-gray-800 text-base font-semibold">{viewItinerariesMenu}</div>
      </div>
    </div>
  );
};

export default ItinerariesViewMenu;
