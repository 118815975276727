import React, { useState } from "react";
import SpecialRequestPopup from "./SpecialRequestPopup";
import PropertyRoomDetails from "./PropertyRoomDetails";
import { updateSpecialRequest } from "../../../functions/supplierRequestsFunctions";

const PropertyRequestDetails = ({
  propertyRequest,
  formatDate,
  formatDateWithDay,
  formatPax,
  isInternalUser,
  supplierRequestId,
  specialRequests,
  isSent,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };


  // This function handles updating the special request array in the supplierRequest document
  const handleSpecialRequestSubmit = async (specialRequest) => {
    try {
      await updateSpecialRequest(supplierRequestId, propertyRequest.id, specialRequest);
      closePopup();
    } catch (error) {
      console.error("Error saving special request:", error);
    }
  };

  // Filter rooms to exclude "Vehicle" room type
  const filteredRooms = propertyRequest.rooms.filter(
    (room) => room.roomType !== "Vehicle"
  );

  // Calculate total adults and children based on filtered rooms array
  const totalAdults = filteredRooms.reduce(
    (sum, room) => sum + room.selectedAgesAdults.length,
    0
  );
  const totalChildren = filteredRooms.reduce(
    (sum, room) => sum + room.selectedAges.length,
    0
  );

  // Find the special request for the current property
  const currentSpecialRequest = specialRequests.find(
    (request) => request.propertyUid === propertyRequest.id
  );

  const canEditSpecialRequest = isInternalUser && !isSent;

  return (
    <div
      key={propertyRequest.id}
      className="border border-gray-300 rounded-md p-4 mb-4"
    >
      <h2 className="text-lg font-semibold mb-4">{propertyRequest.propName}</h2>
      <table className="table-auto w-full mb-4">
        <tbody>
          <tr>
            <td className="font-medium w-40">Dates:</td>
            <td>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                Check-In: {formatDate(propertyRequest.startDay)}
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
                Check-Out: {formatDate(propertyRequest.endDay)}
              </span>
            </td>
          </tr>
          <tr>
            <td className="font-medium w-40 pt-4 pb-2">Total Guests:</td>
            <td className="font-medium pt-4 pb-2">
              {totalAdults} Adults
              {totalChildren > 0 && ` and ${totalChildren} Children`}
            </td>
          </tr>
          <tr>
            <td className="font-medium w-40 pb-2">Rate:</td>
            <td>{propertyRequest.rateFullName}</td>
          </tr>
          <tr>
            <td className="font-medium w-40 pb-2">Arrival:</td>
            <td>{formatDateWithDay(propertyRequest.startDay)}</td>
          </tr>
          <tr>
            <td className="font-medium w-40">Departure:</td>
            <td>{formatDateWithDay(propertyRequest.endDay)}</td>
          </tr>
        </tbody>
      </table>

      {/* Render room details */}
      <PropertyRoomDetails rooms={propertyRequest.rooms} formatPax={formatPax} />

      {/* Render Special Request if it exists */}

      {currentSpecialRequest ? (
        <div className="mt-4">
          <div className="flex items-center">
            <h3 className="text-base font-semibold mb-2">Special Request</h3>
            {canEditSpecialRequest && (
              <button
                className="bg-blue-500 text-white px-2 py-1 rounded ml-2 text-sm"
                onClick={openPopup}
              >
                Edit Special Request
              </button>
            )}
          </div>
          <p>{currentSpecialRequest.message}</p>
        </div>
      ) : (
        canEditSpecialRequest && (
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
            onClick={openPopup}
          >
            Add Special Request
          </button>
        )
      )}

      {isPopupOpen && (
        <SpecialRequestPopup
          onSubmit={handleSpecialRequestSubmit}
          onClose={closePopup}
          existingRequest={currentSpecialRequest?.message}
        />
      )}
    </div>
  );
};

export default PropertyRequestDetails;
