import React, { useState, useEffect } from "react";

const SpecialRequestPopup = ({ onSubmit, onClose, existingRequest }) => {
  const [specialRequest, setSpecialRequest] = useState("");

  useEffect(() => {
    if (existingRequest) {
      setSpecialRequest(existingRequest);
    }
  }, [existingRequest]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(specialRequest);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded w-1/3">
        <h3 className="text-lg font-semibold mb-4">
          {existingRequest ? "Edit Special Request" : "Add Special Request"}
        </h3>
        <form onSubmit={handleSubmit}>
          <textarea
            className="w-full h-32 p-2 border border-gray-300 rounded"
            value={specialRequest}
            onChange={(e) => setSpecialRequest(e.target.value)}
          ></textarea>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              {existingRequest ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SpecialRequestPopup;