import React, { useState } from 'react';

import Header from '../partials/Header';
import { Link } from 'react-router-dom';
import Sidebar from '../partials/Sidebar';
import logo from '../images/branding/hornbill-blue.png';
import { useSelector } from 'react-redux';

//import NotFoundImage from '../../images/404-illustration.svg';

function PageNotFound() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      {!user ? (
        <div className="flex-1">
          <div className="flex items-center justify-between h-16 left-0 top-0">
            {/* Logo */}
            <Link className="block mt-10 ml-5" to="/">
              <img src={logo} alt="" width="30%" />
            </Link>
          </div>
        </div>
      ) : null}
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        {user ? <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> : null}

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          {user ? {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */} : null}

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <div className="max-w-2xl m-auto mt-16">
                <div className="text-center px-4">
                  <div className="inline-flex mb-8">{/* <img src={NotFoundImage} width="176" height="176" alt="404 illustration" /> */}</div>
                  <h1 className="text-9xl font-bold brand-text-color-500">404</h1>
                  <div className="mb-6 brand-text-color-500">This page doesn’t exist.</div>
                  <Link to="/" className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3">
                    Back To Home
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
