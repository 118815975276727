import React from 'react';
import { urlFor } from '../../../../db/sanity';
import { FaCheckCircle } from 'react-icons/fa';
import RoomSlider from './RoomSlider';

function CampInfoTab({ camp }) {
  return (
    <div className="mt-8">
      {/* SECTION - DESCRIPTION AND KEY DETAILS BOX */}
      <div className="grid grid-cols-1 md:grid-cols-10 gap-8">
        <div className="md:col-span-6 text-base font-sans">
        <div className="text-4xl font-roca text-nomadBlue-800 mb-6">Overview</div>
          <p>{camp.campInformation.description}</p>
        </div>
        <div className="md:col-start-8 col-span-3">
          <div className="bg-white rounded-lg border border-[#f0ece7] p-6 font-['Open Sans']">
            <h3 className="text-sm font-bold uppercase text-gray-600 mb-4">Key Details</h3>
            <ul className="list-none">
              {camp.keyFacts && camp.keyFacts.keyDetails ? (
                camp.keyFacts.keyDetails.map((detail, index) => (
                  <li key={index} className="flex items-center text-sm text-gray-600 mb-2">
                    <FaCheckCircle className="mr-2 text-[#073937]" />
                    <span>{detail}</span>
                  </li>
                ))
              ) : (
                <li>No key details available.</li>
              )}
            </ul>
          </div>
        </div>
      </div>


     {/* SECTION - CAMP MANAGER */}
<div className="mt-12">
  {camp.campManager && (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
      <div className="relative min-h-[400px]">
        <img
          src={urlFor(camp.campManager.image.asset._ref)}
          alt={camp.campManager.name}
          className="absolute inset-0 w-full h-full object-cover"
        />
      </div>
      <div className="flex flex-col justify-center font-sans">
        <div className="text-lg font-sans uppercase text-nomadPrimary-800">Camp Manager</div>
        <div className="text-3xl font-roca text-nomadBlue-800 mb-6">{camp.campManager.name}</div>
        <p>{camp.campManager.description}</p>
      </div>
    </div>
  )}
</div>
      {/* SECTION - ROOM SLIDER */}
      {camp.roomSlides && camp.roomSlides.length > 0 && (
        <RoomSlider slides={camp.roomSlides} />
      )}
    </div>
  );
}

export default CampInfoTab;