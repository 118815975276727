import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { urlFor } from '../../../../db/sanity';

function RoomSlider({ slides }) {
  const [activeSlide, setActiveSlide] = useState(0);
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex);
  };

  const handleTabClick = (index) => {
    setActiveSlide(index);
    swiperRef.current.slideTo(index);
  };

  return (
    <div className="relative mt-12">
      <div className="text-4xl font-roca text-nomadBlue-800 mb-6">Rooms and Areas</div>
      {/* Tabs */}
      <div className="flex mb-4">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`px-4 py-2 text-center text-sm font-semibold uppercase tracking-wide cursor-pointer transition-colors duration-300 ${
              activeSlide === index
                ? 'bg-nomadBlue-600 text-white'
                : 'bg-white text-nomadBlue hover:bg-nomadBlue/10'
            }`}
            onClick={() => handleTabClick(index)}
          >
            {slide.tabTitle}
          </div>
        ))}
      </div>

      {/* Slider */}
      <div className="bg-stone-100 rounded-lg overflow-hidden">
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          onSlideChange={handleSlideChange}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="relative">
                <img
                  className="w-full h-[500px] object-cover"
                  src={urlFor(slide.image.asset._ref).url()}
                  alt={slide.heading}
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end text-white p-8">
                  <h4 className="text-3xl font-roca mb-2">{slide.heading}</h4>
                  <p className="text-base font-['Open Sans']">{slide.description}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default RoomSlider;