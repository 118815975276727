import './index.css';

import * as serviceWorker from './serviceWorker';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
//import Gleap from "gleap";
import { bootIntercom } from './intercom/intercom';
//import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import ls from '@livesession/sdk';
import { store } from './app/store';

ls.init('708a014a.dd45e3c8');
ls.newPageView();

//import ReactDOM from "react-dom/client";
//const root = ReactDOM.createRoot(document.getElementById("root"));

const root = createRoot(document.getElementById('root'));
//Gleap.initialize("FGgtUeenjDRqcgEBmuKUuakC3jSBHCvX");

// Directly initialize Intercom
(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;

    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/hd6txnim';
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (d.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();

const storedUser = localStorage.getItem('user');
if (storedUser) {
  const user = JSON.parse(storedUser);
  bootIntercom(user);
}

root.render(
  // https://github.com/atlassian/react-beautiful-dnd/issues/2396 -> React.StrictMode issue
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,

  //document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
