import 'react-confirm-alert/src/react-confirm-alert.css';

import ButtonPrimarySmall from '../components/Buttons/ButtonPrimarySmall';
import ButtonOutlineSmall from '../components/Buttons/ButtonOutlineSmall';
import { checkBookingsForMinusOne } from './paxFunctions';
import { confirmAlert } from 'react-confirm-alert';
import { getPropObj } from './generalFunctions';
import { toast } from 'react-toastify';

const moment = require('moment');

function validateBookingRules(bookings, properties, user, customLocations, setQuoteMode, setStep, setBookings) {
  for (const booking of bookings) {
    const property = properties[getPropObj(booking.propUid, properties)];

    // Rate selection validation
    if (booking.rateName === 'select') {
      toast.error(`Please select a rate for ${booking.propName}`);
      return true;
    }

    // Day room validations
    if (booking.days === 0 && booking.rateName !== 'dayRoom') {
      toast.error(`Day room is only available for 'Day Room' rate type, select another rate type to continue for ${booking.propName}`);
      return true;
    }

    if (booking.rateName === 'dayRoom' && booking.days !== 0) {
      toast.error(`Day room is only available for 'Day Room' nights, select different nights to continue for ${booking.propName}`);
      return true;
    }

    if (booking.rateName === 'dayRoom' && booking.days === 0) {
      for (const room of booking.rooms.filter((r) => r.roomType !== 'Vehicle')) {
        const selectedRoom = property.rooms.find((r) => r.id === room.selectedRoom);
        if (!(selectedRoom && selectedRoom.dayRoom)) {
          toast.error(`The room '${selectedRoom.name}' is not available for 'Day Room' rate & nights, change rate and/or nights to continue for ${booking.propName}`);
          return true;
        }
      }
    }

    if (booking.rateName !== 'dayRoom' || booking.days !== 0) {
      for (const room of booking.rooms.filter((r) => r.roomType !== 'Vehicle')) {
        const selectedRoom = property.rooms.find((r) => r.id === room.selectedRoom);
        if (selectedRoom && selectedRoom.dayRoom === true && !selectedRoom.overnight) {
          //toast.error(`Day room is only available for 'Day Room' rate & nights, change rate and/or nights to continue for ${booking.propName}`);
          toast.error(`The room '${selectedRoom.name}' is only available as a day room for ${booking.propName} change room, rates and/or nights to continue`);
          return true;
        }
      }
    }

    //overnight room validation
    if (booking.days > 0) {
      for (const room of booking.rooms.filter((r) => r.roomType !== 'Vehicle')) {
        const selectedRoom = property.rooms.find((r) => r.id === room.selectedRoom);
        if (!(selectedRoom && selectedRoom.overnight) && !booking.propUid.includes('_D-I-R-E-C-T_')) {
          toast.error(`The room '${selectedRoom.name}' is only available as a day room for ${booking.propName} change room to continue`);
          return true;
        }
      }
    }

    // Minimum nights validation
    const minNights = user.internalUser ? property.minNightsInternalUser : property.minNights;
    for (const room of booking.rooms.filter((r) => r.roomType !== 'Vehicle')) {
      const propRoom = property.rooms.find((r) => r.id === room.selectedRoom);
      if (propRoom && !propRoom.dayRoom && booking.days < minNights) {
        toast.error(`You must enter ${minNights} or more nights for ${booking.propName}.`);
        return true;
      }
    }

    // Specific property validations
    // ! Check for:
    // ! Chada Katavi (e867a3b5-1b63-4913-a052-f69d08523647)
    // ! Greystoke Mahale (cd3dc792-9604-4363-8246-7a55421ff961)
    if (booking.propUid === 'e867a3b5-1b63-4913-a052-f69d08523647' || booking.propUid === 'cd3dc792-9604-4363-8246-7a55421ff961') {
      const startDayOfWeek = moment(booking.startDay).day();
      const endDayOfWeek = moment(booking.endDay).day();
      if (startDayOfWeek !== 1 && startDayOfWeek !== 4) {
        toast.error(`The first day for ${booking.propName} should be a Monday or a Thursday.`);
        return true;
      }
      if (endDayOfWeek !== 1 && endDayOfWeek !== 4) {
        toast.error(`The last day for ${booking.propName} should be a Monday or a Thursday.`);
        return true;
      }
    }

    // Vehicle availability check
    if ((booking.vehiclesPropertiesRule === 'none' || booking.vehicles === 'none') && booking.rooms.length > 0 && !property.northernGuide) {
      toast.error(`No Vehicles available for this rate type for ${booking.propName}, select new dates or change rate type.`);
      return true;
    }

    // Custom location check
    const customLocation = customLocations.find((location) => location.propUid === booking.propUid);
    let displayText;

    if (property.mobileLocation && customLocation) {
      const park = property.customLocations.find((park) => park.parkId === customLocation.parkId);
      displayText = park ? park.parkName : 'Camp location closed';
    } else {
      displayText = property.park.name;
    }

    if (displayText === 'Camp location closed') {
      toast.error(`No available location for ${booking.propName}, please change property or date.`);
      return true;
    }

    // Private property check
    if (property.propertyType === 'private' && (booking.rooms.length < 2 || !booking.rooms.some((room) => room.roomType === 'Exclusive'))) {
      toast.error(`You must select the Exclusive Room and at least one other room for ${booking.propName}`);
      return true;
    }

    // Room selection check
    if (booking.rooms.length < 1) {
      toast.error(`Please select a room for all properties`);
      return true;
    }
  }

  // Room availability check
  const notAllRoomsAvailable = bookings.some((booking) => {
    if (properties[getPropObj(booking.propUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
      return booking.rooms.some((room) => room.available === false);
    }
    return false;
  });

  if (notAllRoomsAvailable) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="p-5 bg-white rounded-lg" style={{ boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
            <p className="text-sm font-semibold pb-2">Limited Property Availability</p>
            <p className="text-sm pb-2">Some properties you've selected are no longer available.</p>
            <p className="text-sm pb-2">You can still proceed, but you will only have the option to save as a quotation.</p>
            <div className="flex mt-5 justify-end">
              <div className="flex justify-end gap-4">
                <ButtonOutlineSmall
                  onClick={() => {
                    setQuoteMode(false);
                    updateBookingsNotModified(bookings, setBookings);
                    setStep('bookings');
                    onClose();
                  }}
                  text="Go Back"
                  color="dark"
                />
                <ButtonPrimarySmall
                  onClick={() => {
                    setQuoteMode(true);
                    onClose();
                  }}
                  text="Continue"
                  color="dark"
                />
              </div>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
    //return true;
  } else {
    setQuoteMode(false);
  }

  // Check for "Accommodation Booked Direct" only
  if (bookings.length === 1 && bookings[0].propName === 'Accommodation Booked Direct') {
    toast.error(`You can't add "Accommodation Booked Direct" only`);
    return true;
  }

  if (!checkBookingsForMinusOne(bookings)) {
    toast.error(`You must select an age for each child at each property/room`);
    return true;
  }

  return false; // No errors found
}

function updateBookingsNotModified(bookings, setBookings) {
  const updatedBookings = bookings.map((booking) => ({
    ...booking,
    notModified: true,
  }));

  setBookings(updatedBookings);
}

export { validateBookingRules, updateBookingsNotModified };
