import '../css/customPagination.css'; // This is your custom CSS file.

import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';

// Custom Pagination component

const CustomPagination = ({ currentRefinement, nbPages, refine }) => {
  // Define the maximum number of pages to display, not including the first and last pages
  const totalPagesToShow = 2;
  const visiblePages = Math.min(totalPagesToShow, nbPages);

  let start = Math.max(currentRefinement - Math.floor(visiblePages / 2), 2);
  let end = start + visiblePages - 1;

  if (end > nbPages - 1) {
    end = nbPages - 1;
    start = Math.max(end - visiblePages + 1, 2);
  }

  const pages = ['1']; // Always include the first page

  if (start > 2) {
    pages.push('...'); // Ellipsis after the first page
  }

  for (let i = start; i <= end; i++) {
    pages.push(i.toString());
  }

  if (end < nbPages - 1) {
    pages.push('...'); // Ellipsis before the last page
    pages.push(nbPages.toString());
  }

  return (
    <div className="pagination">
      <button onClick={() => refine(currentRefinement - 1)} disabled={currentRefinement === 1} className={`pagination-item ${currentRefinement === 1 ? 'disabled' : ''}`}>
        {'<'}
      </button>
      {pages.map((page, index) => {
        const isEllipsis = page === '...';
        const key = isEllipsis ? `ellipsis-${index}` : page;
        return isEllipsis ? (
          <span key={key} className="pagination-item ellipsis">
            {page}
          </span>
        ) : (
          <button key={page} onClick={() => refine(parseInt(page, 10))} className={`pagination-item ${currentRefinement === parseInt(page, 10) ? 'active' : ''}`}>
            {page}
          </button>
        );
      })}
      <button onClick={() => refine(currentRefinement + 1)} disabled={currentRefinement === nbPages} className={`pagination-item ${currentRefinement === nbPages ? 'disabled' : ''}`}>
        {'>'}
      </button>
    </div>
  );
};

const ConnectedPagination = connectPagination(CustomPagination);

export default ConnectedPagination;
