import React, { useState } from 'react';
import { 
  FaWifi, 
  FaParking, 
  FaSwimmingPool, 
  FaShieldAlt, 
  FaCheckCircle, 
  FaUtensils, 
  FaConciergeBell, 
  FaCocktail, 
  FaAppleAlt, 
  FaBath, 
  FaShower, 
  FaLock, 
  FaTshirt, 
  FaToilet, 
  FaWater, 
  FaSoap 
} from 'react-icons/fa';


function PropertyAmenities({ keyFacts, rooms, spokenLanguages, tripAdvisorData }) {
  const [expandedSections, setExpandedSections] = useState({});

  if (!keyFacts) {
    return <div>No amenities information available</div>;
  }

  const sections = [
    { title: 'Property Amenities', items: keyFacts.propertyAmenities || [] },
    { title: 'Room Features', items: keyFacts.roomFeatures || [] },
    {
      title: 'Room Types',
      items: rooms
        .filter(room => room.type === 'Room' && room.active && !room.hideExternalUsers)
        .map(room => room.name)
    },
  ].filter(section => section.items.length > 0);

  const getIcon = (item) => {
    const lowerItem = item.toLowerCase();
    if (lowerItem.includes('wifi')) return <FaWifi />;
    if (lowerItem.includes('parking')) return <FaParking />;
    if (lowerItem.includes('pool')) return <FaSwimmingPool />;
    if (lowerItem.includes('security')) return <FaShieldAlt />;
    if (lowerItem.includes('dining')) return <FaUtensils />;
    if (lowerItem.includes('menu')) return <FaConciergeBell />;
    if (lowerItem.includes('sundowners')) return <FaCocktail />;
    if (lowerItem.includes('diet')) return <FaAppleAlt />;
    if (lowerItem.includes('bathroom')) return <FaBath />;
    if (lowerItem.includes('shower')) return <FaShower />;
    if (lowerItem.includes('safe')) return <FaLock />;
    if (lowerItem.includes('laundry')) return <FaTshirt />;
    if (lowerItem.includes('flush loos')) return <FaToilet />;
    if (lowerItem.includes('hot & cold running water')) return <FaWater />;
    if (lowerItem.includes('body wash') || lowerItem.includes('shampoo') || lowerItem.includes('conditioner')) return <FaSoap />;
    return <FaCheckCircle />;
  };

  const toggleSection = (title) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [title]: !prevState[title]
    }));
  };

  return (
    <div className="bg-white rounded-lg border border-[#f0ece7] p-6 font-['Open Sans']">
      {sections.map((section) => (
        <div key={section.title} className="mb-8">
          <h3 className="text-sm font-bold uppercase text-gray-600 mb-4">{section.title}</h3>
          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
            {section.items.slice(0, expandedSections[section.title] ? section.items.length : 6).map((item, index) => (
              <div key={index} className="flex items-center text-sm text-gray-600">
                <span className="mr-2 text-[#073937]">{getIcon(item)}</span>
                <span>{item}</span>
              </div>
            ))}
          </div>
          {section.items.length > 6 && (
            <button 
              className="text-[#25363a] text-sm font-semibold underline mt-2"
              onClick={() => toggleSection(section.title)}
            >
              {expandedSections[section.title] ? 'Show less' : 'Show more'}
            </button>
          )}
        </div>
      ))}
      {spokenLanguages && spokenLanguages.length > 0 && (
      <div className="mt-8">
        <h3 className="text-sm font-bold uppercase text-gray-600 mb-4">Good to Know</h3>
        
          <p className="text-sm text-gray-600">
            <span className="font-bold">Language spoken</span><br />
            {spokenLanguages.join(', ')}
          </p>
      </div>
       )}
    </div>
  );
}

export default PropertyAmenities;