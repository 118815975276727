//import {getLabelForFinalValue} from '../functions/finalPageFunctions';

import { FaEdit, FaPlusCircle, FaSave, FaTrashAlt, FaWindowClose } from 'react-icons/fa';
import { FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { TbArrowsRandom, TbBuildingWarehouse } from 'react-icons/tb';
import { feeTypeOptions, getLabelForFinalValues, getShortenedLabelForValue, iconMap } from '../functions/finalPageFunctions';

//import { GiJeep } from 'react-icons/gi';
import Select from 'react-select';
import ViewCompDay from './ViewCompDay';
import ViewCompHeader from './ViewCompHeader';
import ViewCompTableHeader from './ViewCompTableHeader';
import ViewCompTableRow from './ViewCompTableRow';
import { getSupplierName } from '../functions/generalFunctions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

//import { cancelFinalInputDataEdit } from '../functions/finalPageFunctions';

const ViewFoundItemCard = ({ foundItem, customFinalItems, setCustomFinalItems, setCustomFinalCut, searchId, pickupDropoff, formatPrice, firstDay, bookingMaster }) => {
  // USER
  const { allSuppliers } = useSelector((state) => state.suppliers);
  var suppliersShort = allSuppliers.result;
  let tempSuppliers = JSON.parse(JSON.stringify(suppliersShort));

  const toggleCollapse = (uuid) => {
    setCustomFinalItems((items) => items.map((item) => (item.uuid === uuid ? { ...item, collapsed: !item.collapsed } : item)));
  };

  const FeeTypeIcon = ({ feeTypeUid }) => {
    return iconMap[feeTypeUid] || <div>Unknown Type</div>; // Render a default or nothing if the type is unknown
  };

  // DAY
  const firstDayMoment = moment(firstDay, 'YYYY-MM-DD');
  const dayMoment = moment(foundItem.fields[0].date, 'YYYY-MM-DD');

  let day = 1;
  if (foundItem.objType === 'arrival') {
    day = firstDay === foundItem.fields[0].date ? 1 : dayMoment.diff(firstDayMoment, 'days');
  } else {
    day = firstDay === foundItem.fields[0].date ? 1 : dayMoment.diff(firstDayMoment, 'days') + 1;
  }

  return (
    <div className="bg-gray-50">
      {/* CUSTOM FINAL ITEMS */}

      <div className="bg-white rounded-[15px] border border-gray-200 shadow-md hover:shadow-lg transition-shadow duration-300">
        {/* HEADER */}
        <ViewCompHeader
          day={day} 
          item={foundItem}
          feeTypeIcon={<FeeTypeIcon feeTypeUid={foundItem.fields[0].feeTypeUid} />}
          description={foundItem.fields[0].description}
          supplierName={foundItem.fields[0].supplierName}
          supplierUid={foundItem.fields[0].supplierUid}
          date={moment(foundItem.fields[0].date).format('Do MMM YYYY')}
          total={foundItem.fields.reduce((sum, field) => sum + field.total, 0)}
          collapse={<FiChevronsUp size={30} className="cursor-pointer text-zinc-300" onClick={() => toggleCollapse(foundItem.uuid)} />}
          expand={<FiChevronsDown size={30} className=" cursor-pointer text-zinc-300" onClick={() => toggleCollapse(foundItem.uuid)} />}
          bookingMaster={bookingMaster}
        />
        {/* <div className="grid grid-cols-12">
          <div className="col-span-1 flex justify-center">
            <div className="w-[46px] h-[46px] bg-gray-200 rounded-full flex items-center justify-center my-5">
              <FeeTypeIcon feeTypeUid={foundItem.fields[0].feeTypeUid} />
            </div>
          </div>
          <div className="col-span-8 flex flex-row items-center">
            <div className="">
              <div className="text-black text-base font-semibold leading-tight">
                {foundItem.fields[0].description} {foundItem.fields[0].additionalInfo ? ` - ${foundItem.fields[0].additionalInfo}` : ''}
              </div>
              <div className="text-neutral-500 text-sm font-normal leading-[18.20px] pt-2">
                {foundItem.fields[0].supplierName} | {moment(foundItem.fields[0].date).format('Do MMM YYYY')}
                <div className="ml-3 px-3 h-[27px] px-2.5 py-0.5 bg-green-100 rounded-[10px] justify-center items-center inline-flex">
                  <div className="text-center text-emerald-900 text-xs font-normal font-['Inter'] leading-[18px]">Booking Confirmed</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="text-gray-900 text-sm font-medium leading-[21px]">Total: ${formatPrice(foundItem.fields.reduce((sum, field) => sum + field.total, 0))}</div>
          </div>
          <div className="col-span-1 flex justify-center">
            <div className="flex items-center justify-center m-5">
              {!foundItem.collapsed ? (
                <FiChevronsUp size={30} className="cursor-pointer text-zinc-300" onClick={() => toggleCollapse(foundItem.uuid)} />
              ) : (
                <FiChevronsDown size={30} className=" cursor-pointer text-zinc-300" onClick={() => toggleCollapse(foundItem.uuid)} />
              )}{' '}
            </div>
          </div>
        </div> */}
        {/* ROW TITLES */}
        {!foundItem.collapsed && (
          // <div className={`grid grid-cols-12 text-xs font-medium border-b border-gray-200 bg-gray-50 mx-5`}>
          //   <div className="col-span-5">
          //     <div className={`grid grid-cols-12 text-xs`}>
          //       <div className="col-span-12 ml-5 py-2 text-gray-500 font-semibold">DESCRIPTION</div>
          //     </div>
          //   </div>
          //   <div className="col-span-3 ml-5 py-2 text-gray-500 font-semibold">SUPPLIER</div>

          //   <div className="col-span-1 ml-5 py-2 text-gray-500">
          //     <span className="block font-semibold">NIGHTS</span>
          //   </div>

          //   <div className="col-span-1 ml-5 py-2 text-gray-500">
          //     <span className="block font-semibold">UNITS</span>
          //   </div>
          //   <div className="col-span-1 ml-4 py-2 text-gray-500 font-semibold">UNIT PRICE</div>
          //   <div className="col-span-1 ml-5 py-2 text-gray-500 font-semibold">AMOUNT</div>
          // </div>
          <ViewCompTableHeader />
        )}

        {/* ROW ITEMS*/}
        <div className={`${!foundItem.collapsed ? 'pb-5' : ''}`}>
          {!foundItem.collapsed &&
            foundItem.fields.map(
              (field, index) =>
                !field.edit && (
                  // <div key={field.uuid} className={`grid grid-cols-12 text-xs ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} ${index === foundItem.fields.length - 1 ? 'rounded-[15px]' : ''} mx-5`}>
                  //   <div className="col-span-5">
                  //     <div className={`grid grid-cols-12 text-xs h-full`}>
                  //       <div className="col-span-12 ml-5 py-2 pr-5 w-[23rem] truncate">
                  //         {field.description} {field.additionalInfo ? ` - ${field.additionalInfo}` : ''}
                  //       </div>
                  //     </div>
                  //   </div>
                  //   <div className="col-span-3 ml-5 py-2">{field.supplierName}</div>

                  //   <div className="col-span-1 ml-5 py-2">{field.units}</div>
                  //   <div className="col-span-1 ml-5 py-2">{field.pax}</div>
                  //   <div className="col-span-1 ml-5 py-2">${formatPrice(field.net)}</div>
                  //   <div className="col-span-1 ml-5 py-2">${formatPrice(field.total)}</div>
                  // </div>
                  <div key={uuidv4()}>
                    <ViewCompTableRow
                      index={index}
                      lengthCheck={foundItem.fields}
                      description={`${field.description} ${field.additionalInfo ? ` - ${field.additionalInfo}` : ''}`}
                      supplierName={field.supplierName}
                      units={field.units}
                      pax={field.pax}
                      net={field.net}
                      total={field.total}
                    />
                  </div>
                ),
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewFoundItemCard;
