import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../components/Spinner";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import { updateUser } from "../../features/auth/authSlice";

// import Image from "../../images/user-avatar-80.png";

function AccountPanel() {
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

  //Form
  const [formData, setFormData] = useState({
    firstName: user && user.firstName,
    lastName: user && user.lastName,
    email: user && user.email,
  });

  const { firstName, lastName, email } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const userCopy = { ...user };
    userCopy.firstName = firstName;
    userCopy.lastName = lastName;
    //userCopy.email = email;

    console.log(userCopy);
    dispatch(updateUser(userCopy));
    // if (auth.currentUser.displayName !== name) {
    //   dispatch(updateUser(userCopy));
    // } else {
    //   toast.error("No change made");
    //   //dispatch(updateUser(userCopy));
    // }
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      toast.success("Changes saved");
    }
  }, [isError, isSuccess, message]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="grow">
      <form onSubmit={onSubmit}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl brand-text-color-800 font-bold mb-5">My Account</h2>

          {/* First Name */}
          <section>
            <h2 className="text-xl leading-snug brand-text-color-800 font-bold mb-1">Profile</h2>
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
              <div className="sm:w-1/3">
                <label className="block text-sm font-medium mb-1" htmlFor="name">
                  First Name
                </label>

                <input id="firstName" className="form-input w-full" type="text" name="firstName" value={firstName} onChange={onChange} required />
              </div>
            </div>
          </section>

          {/* Last Name */}
          <section>
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
              <div className="sm:w-1/3">
                <label className="block text-sm font-medium mb-1" htmlFor="name">
                  Last Name
                </label>

                <input id="lastName" className="form-input w-full" type="text" name="lastName" value={lastName} onChange={onChange} required />
              </div>
            </div>
          </section>

          {/* Email */}
          <section>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                Email
              </label>
              {email}
              {/* <input
                id="email"
                className="form-input w-full"
                type="email"
                name="email"
                value={email}
                onChange={onChange}
                placeholder="Please enter your email"
                disabled
              /> */}
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t brand-border-color-200">
            <div className="flex self-end">
              <button className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3">Save Changes</button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}

export default AccountPanel;
