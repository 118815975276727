import React from 'react';

function PropertyCard({ camp }) {
  const placeholderImage = 'https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/4ccc5804-560b-478d-b844-134c9360ef00/770x450';

  const imageUrl = camp.imageUrl
    ? camp.imageUrl.includes('imagedelivery.net')
      ? `${camp.imageUrl}/770x450`
      : camp.imageUrl
    : placeholderImage;

  return (
    <div className="relative bg-white rounded-lg border border-[#f0ece7] h-full">
      <img className="w-full h-52 object-cover rounded-t-lg" src={imageUrl} alt={camp.name} />
      <div className="p-4">
        <p className="text-[#b1b1b1] text-xs font-semibold uppercase mb-1">{camp.park}</p>
        <h2 className="text-[#1e1e1e] text-lg font-semibold font-['Open Sans'] leading-[27px] mb-2">{camp.name}</h2>
        <p className="text-[#636366] text-sm font-normal font-['Inter'] leading-tight mb-4">{camp.description}</p>
        <div className="flex flex-wrap gap-2 mt-auto">
          {camp.locationCategory && (
            <span className="px-4 py-1 bg-[#f2f2f2] rounded-full border text-[#333333] text-sm font-normal">
              {camp.locationCategory}
            </span>
          )}
          {camp.mobileLocation && (
            <span className="px-4 py-1 bg-[#f2f2f2] rounded-full border text-[#333333] text-sm font-normal">
              Mobile Camp
            </span>
          )}
          {camp.propertyType === 'private' && (
            <span className="px-4 py-1 bg-[#f2f2f2] rounded-full border text-[#333333] text-sm font-normal">
              Private Camp
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default PropertyCard;