import { collection, getDocs, query } from "firebase/firestore";

import { db } from "../../db/firebase.config";

// Get users
const fetchName = "locations";
const getLocations = async () => {
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    const q = query(fetchRef);

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];
    console.log(`querySnapshot.docs.length ${querySnapshot.docs.length}`);
    querySnapshot.forEach((doc) => {
      return fetch.push({
        ...doc.data(),
      });
    });
    var jsonResult = {
      result: fetch,
    };
    console.log("REDUX: getLocations");
    //console.log(JSON.stringify(jsonResult, undefined, 4));
    return { jsonResult };
  } catch (error) {
    console.log(error);
  }
};
const locationsService = {
  getLocations,
};

export default locationsService;
