import { availableTransfers, calculateRateTransferUnitsPax, findTransferRate, findTransferRatesAll } from './transferFunctions';
import { calculateActivity, filterActivities, handleClickActivities } from './activityFunctions';
import { calculatePropertyVehicles, filterVehicleRoomsNoSet } from './vehicleFunctions';

import { TbSquareChevronsDownFilled } from 'react-icons/tb';
import { getAvailability } from './aeroFunctions';
import { getPropObj } from './generalFunctions';
import { loadTransferRoute } from './loadDataFunctions';
import { loadTransferRouteUid } from './loadDataFunctions';
import moment from 'moment';
import { processVip } from './transferFunctions';
import semver from 'semver';
import { toast } from 'react-toastify';

// CHECK BOOKINGS
const checkBookingArrays = (bookings, bookingsStore) => {
  console.log('checkBookingArrays TUESDAY');
  if (bookings.length !== bookingsStore.length) {
    console.log('not matched 1');
    return false;
  }

  for (let booking of bookings) {
    const bookingStore = bookingsStore.find((bs) => bs.propUid === booking.propUid && bs.id === booking.id);

    if (!bookingStore) {
      console.log('not matched 2');
      return false;
    }

    if (
      new Date(booking.startDay).getTime() !== new Date(bookingStore.startDay).getTime() ||
      new Date(booking.endDay).getTime() !== new Date(bookingStore.endDay).getTime() ||
      booking.rooms.length !== bookingStore.rooms.length
    ) {
      console.log('not matched 3');
      return false;
    }

    for (let room of booking.rooms) {
      const roomStore = bookingStore.rooms.find((r) => r.uuid === room.uuid && r.selectedRoom === room.selectedRoom);

      if (!roomStore || room.ad !== roomStore.ad || room.ch !== roomStore.ch || room.chi !== roomStore.chi || room.inf !== roomStore.inf) {
        console.log('not matched 4');
        return false;
      }
    }

    if (booking.rebuild) {
      console.log('not matched 5');
      return false;
    }
  }

  console.log('matched');
  console.log('bookings.length ', bookings.length); // Should print 2
  console.log('bookingsStore.length ', bookingsStore.length); // Should print 1
  //return true; // Set to true does not rebuild transfers
  return false; // Set to false rebuilds transfers
};

// CHECK FLIGHTS
async function checkFlights(transfer) {
  console.log('checkFlights');
  //console.log(JSON.stringify(transfer.formValues, undefined, 4));

  const originCode = transfer.formValues.flightDetails.fromcode;
  const destinationCode = transfer.formValues.flightDetails.tocode;

  // New code

  const getAvailableFlights = await getAvailability(false, transfer, false, false, originCode, destinationCode, false);
  const availableFlightsData = getAvailableFlights.aerocrs.flights;
  console.log('availableFlightsData');
  console.log(JSON.stringify(availableFlightsData, undefined, 4));
  if (availableFlightsData.count === 0) {
    console.log('Matching flights count: ', availableFlightsData.count);
    return false;
  } else {
    console.log('Matching flights count: ', availableFlightsData.count);
    console.log('Transfer: ', transfer.objType);
    let formattedDate = moment(transfer.formValues.flightDetails.STD, 'YYYY/MM/DD HH:mm:ss.SSS').format('YYYY-MM-DD HH:mm:ss.SSS');

    let matchingFlight = availableFlightsData.flight.find((flight) => flight.STD === formattedDate);

    if (matchingFlight) {
      console.log('Matching flight found: ', matchingFlight);

      return {
        ...matchingFlight,
        flightsArray: availableFlightsData.flight,
      };
    } else {
      // ! check custom flights

      return false;
    }
  }
}

// get totals from booking
function calculateTotals(booking) {
  let totalAd = 0;
  let totalCh = 0;
  let totalChi = 0;
  let totalInf = 0;

  booking.rooms.forEach((room) => {
    totalAd += room.ad;
    totalCh += room.ch;
    totalChi += room.chi;
    totalInf += room.inf;
  });

  return {
    totalAd: totalAd,
    totalCh: totalCh,
    totalChi: totalChi,
    totalInf: totalInf,
  };
}

// get totals from transfer
function calculateTotalsTransfer(transfer) {
  let totalAd = transfer.totalAd;
  let totalCh = transfer.totalCh;
  let totalChi = transfer.totalChi;
  let totalInf = transfer.totalInf;

  return {
    totalAd: totalAd,
    totalCh: totalCh,
    totalChi: totalChi,
    totalInf: totalInf,
  };
}

// update road transfers
async function updateRoadTransfers(updatedNewTransfersData) {
  for (const transfer of updatedNewTransfersData) {
    if (transfer.formValues && transfer.formValues.type === 'location' && transfer.formValues.transferType === 'townTransfer') {
      console.log('match found for location & townTransfer');
      const locationRoute = await loadTransferRoute(transfer.formValues.originUid, transfer.formValues.destinationUid);

      // new rates
      const rates = locationRoute.rates;
      const rateObject = findTransferRate(rates, transfer.endDay);

      const totalPax = transfer.totalAd + transfer.totalCh + transfer.totalChi + transfer.totalInf;
      //const totalPax = transfer.formValues.ad + transfer.formValues.ch + transfer.formValues.chi + transfer.formValues.inf;
      const transferUnits = calculateRateTransferUnitsPax(totalPax, rateObject.maxCapacity);
      const transferUnitPrice = rateObject.stoPrice;

      // Update the transfer object
      transfer.formValues.rackUnitCost = rateObject.rackPrice;
      transfer.formValues.netUnitCost = rateObject.stoPrice;
      transfer.formValues.saleTotal = transferUnits * transferUnitPrice;

      console.log('calculated rates: townTransfer');
    }
  }

  return updatedNewTransfersData;
}

// NEW TRANSFER ROUTE CODE FOR ROAD TRANSFERS: 14/07/24
async function handleTransferRoute(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties) {
  console.log('handleTransferRoute');
  console.log(transfer.objType);
  console.log('booking.notModified: ', booking.notModified);

  // set date to check - start
  let bookingDay;
  let transferDay;
  if (transfer.objType === 'arrival') {
    bookingDay = booking.startDay;
    transferDay = newTransfersLatest[transferLatestIndex].startDay;
  } else {
    bookingDay = booking.endDay;
    transferDay = newTransfersLatest[transferLatestIndex].endDay;
  }
  // set date to check - end

  // Check start date
  if (bookingDay === transferDay) {
    console.log('rebuild transfer 1');
    console.log(JSON.stringify(newTransfersLatest[transferLatestIndex].formValues, null, 2));
    const maxCapacity = newTransfersLatest[transferLatestIndex].formValues.maxCapacity;

    //handle legacy - start
    let oldUnits = newTransfersLatest[transferLatestIndex].formValues.units;
    let oldSaleTotal = newTransfersLatest[transferLatestIndex].formValues.saleTotal;
    let oldCost = oldSaleTotal / oldUnits;
    //let cost = newTransfersLatest[transferLatestIndex].formValues.netUnitCost ? newTransfersLatest[transferLatestIndex].formValues.netUnitCost : newTransfersLatest[transferLatestIndex].formValues.stoPriceCost ? newTransfersLatest[transferLatestIndex].formValues.stoPriceCost :oldNetUnitCost;

    let cost =
      newTransfersLatest[transferLatestIndex].formValues.netUnitCost !== undefined && newTransfersLatest[transferLatestIndex].formValues.netUnitCost !== null
        ? newTransfersLatest[transferLatestIndex].formValues.netUnitCost
        : newTransfersLatest[transferLatestIndex].formValues.stoPriceCost !== undefined && newTransfersLatest[transferLatestIndex].formValues.stoPriceCost !== null
        ? newTransfersLatest[transferLatestIndex].formValues.stoPriceCost
        : oldCost;
    //handle legacy - end

    const units = totalPax > 0 && maxCapacity > 0 ? Math.ceil(totalPax / maxCapacity) : 0;
    newTransfersData[transferIndex].formValues.units = units;

    //handle legacy - start
    newTransfersData[transferIndex].formValues.saleTotal = cost * units;
    //handle legacy - end

    // newTransfersData[transferIndex].formValues.saleTotal = newTransfersLatest[transferLatestIndex].formValues.netUnitCost
    //   ? units * newTransfersLatest[transferLatestIndex].formValues.netUnitCost
    //   : units * newTransfersLatest[transferLatestIndex].formValues.stoPriceCost;

    console.log(JSON.stringify(newTransfersLatest[transferLatestIndex].formValues, null, 2));
  }
  // Check rateId
  else if (transfer.formValues?.rateId) {
    console.log('rebuild transfer 2');
    console.log(JSON.stringify(transfer, null, 2));
    const getTransferRoutes = await loadTransferRouteUid(transfer.formValues.transferRouteUid);
    const rates = getTransferRoutes.rates;
    console.log('transfer.endDay: ', transfer.endDay);
    console.log('newTransfersLatest[transferLatestIndex].endDay: ', newTransfersLatest[transferLatestIndex].endDay);
    console.log('booking.endDay: ', booking.endDay);

    const getRateObjectAll = findTransferRatesAll(rates, booking.endDay);
    console.log('rebuild transfer 3');
    console.log(JSON.stringify(getRateObjectAll, null, 2));
    const filteredRates = getRateObjectAll;

    let index = -1;

    // old
    // const index = filteredRates.findIndex((rate) => rate.supplier.id === transfer.formValues.supplierUid);
    index = filteredRates.findIndex((rate) => rate.id === transfer.formValues.rateId);
    if (index === -1) {
      index = filteredRates.findIndex((rate) => rate.active === true && rate.type === transfer.formValues.rateType && rate.supplier.id === transfer.formValues.supplierUid);
    }

    // index found
    if (index !== -1) {
      if (transfer.formValues.locationType === 'airport' && properties[getPropObj(transfer.propUid, properties)].handlesOwnAirportTransfer) {
        console.log('rebuild transfer 4');
        const maxCapacity = filteredRates[index].maxCapacity;
        const units = totalPax > 0 && maxCapacity > 0 ? Math.ceil(totalPax / maxCapacity) : 0;
        newTransfersData[transferIndex].formValues.units = units;
        newTransfersData[transferIndex].formValues.saleTotal = 0;
        newTransfersData[transferIndex].formValues.rateId = '';
        newTransfersData[transferIndex].formValues.netUnitCost = 0;
        newTransfersData[transferIndex].formValues.stoPriceCost = 0;
        newTransfersData[transferIndex].formValues.rackUnitCost = 0;
        // reset flight details for road transfers
        if (transfer.formValues.dateFlight) {
          newTransfersData[transferIndex].formValues.dateFlight = '';
          newTransfersData[transferIndex].formValues.airlineName = '';
          newTransfersData[transferIndex].formValues.airlineUid = '';
          newTransfersData[transferIndex].formValues.fltNum = '';
          newTransfersData[transferIndex].formValues.timeFlight = 'Select time';
          newTransfersData[transferIndex].formValues.addFlightDetailsLater = true;
        }
      } else {
        console.log('rebuild transfer 4a');
        const maxCapacity = filteredRates[index].maxCapacity;
        const units = totalPax > 0 && maxCapacity > 0 ? Math.ceil(totalPax / maxCapacity) : 0;
        newTransfersData[transferIndex].formValues.units = units;
        newTransfersData[transferIndex].formValues.saleTotal = units * filteredRates[index].stoPrice;
        newTransfersData[transferIndex].formValues.rateId = filteredRates[index].id;
        newTransfersData[transferIndex].formValues.netUnitCost = filteredRates[index].stoPrice;
        newTransfersData[transferIndex].formValues.stoPriceCost = filteredRates[index].stoPrice;
        newTransfersData[transferIndex].formValues.rackUnitCost = filteredRates[index].rackPrice;
        // reset flight details for road transfers
        if (transfer.formValues.dateFlight) {
          newTransfersData[transferIndex].formValues.dateFlight = '';
          newTransfersData[transferIndex].formValues.airlineName = '';
          newTransfersData[transferIndex].formValues.airlineUid = '';
          newTransfersData[transferIndex].formValues.fltNum = '';
          newTransfersData[transferIndex].formValues.timeFlight = 'Select time';
          newTransfersData[transferIndex].formValues.addFlightDetailsLater = true;
        }
      }
    } else {
      // Delete transfer
      console.log('rebuild transfer 5');
      newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
      newTransfersData[transferIndex].isInvalid = true;
    }
  }
}

async function handleTransferRouteVip(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties, airports, airportCode, objType) {
  console.log('handleTransferRouteVip');
  console.log(transfer.objType);
  console.log('booking.notModified: ', booking.notModified);

  // set date to check - start
  let bookingDay;
  let transferDay;
  if (transfer.objType === 'arrival') {
    bookingDay = booking.startDay;
    transferDay = newTransfersLatest[transferLatestIndex].startDay;
  } else {
    bookingDay = booking.endDay;
    transferDay = newTransfersLatest[transferLatestIndex].endDay;
  }
  // set date to check - end

  if (bookingDay === transferDay) {
    console.log('rebuild transfer VIP 1');
  } else {
    console.log('rebuild transfer VIP 2');
    const airportService = processVip(airports, airportCode, newTransfersData[transferIndex], objType);

    if (airportService) {
      console.log('airportService VIP: ', JSON.stringify(airportService, null, 2));
      if (transfer.objType === 'arrival') {
        newTransfersData[transferIndex].formValues.arrivalSupplierNameVip = airportService.supplier.displayName;
        newTransfersData[transferIndex].formValues.arrivalSupplierUidVip = airportService.supplier.id;
        //newTransfersData[transferIndex].formValues.arrivalUnitsVip = ''; // figure out calculation
        newTransfersData[transferIndex].formValues.arrivalPerGroupPriceVip = airportService.supplier.perGroupPrice;
        newTransfersData[transferIndex].formValues.arrivalPerPersonPriceVip = airportService.supplier.perPersonPrice;
        newTransfersData[transferIndex].formValues.arrivalSaleTotalVip =
          airportService.supplier.perGroupPrice + newTransfersData[transferIndex].formValues.arrivalUnitsVip * airportService.supplier.perPersonPrice;
      } else if (transfer.objType === 'departure') {
        newTransfersData[transferIndex].formValues.departureSupplierNameVip = airportService.supplier.displayName;
        newTransfersData[transferIndex].formValues.departureSupplierUidVip = airportService.supplier.id;
        // newTransfersData[transferIndex].formValues.departureUnitsVip = ''; // figure out calculation
        newTransfersData[transferIndex].formValues.departurePerGroupPriceVip = airportService.supplier.perGroupPrice;
        newTransfersData[transferIndex].formValues.departurePerPersonPriceVip = airportService.supplier.perPersonPrice;
        newTransfersData[transferIndex].formValues.departureSaleTotalVip =
          airportService.supplier.perGroupPrice + newTransfersData[transferIndex].formValues.departureUnitsVip * airportService.supplier.perPersonPrice;
      } else if (transfer.objType === 'ownArrangements') {
        // departure
        if (objType === 'departure') {
          newTransfersData[transferIndex].formValues.departureSupplierNameVip = airportService.supplier.displayName;
          newTransfersData[transferIndex].formValues.departureSupplierUidVip = airportService.supplier.id;
          //newTransfersData[transferIndex].formValues.departureUnitsVip = 1;
          newTransfersData[transferIndex].formValues.departurePerGroupPriceVip = airportService.supplier.perGroupPrice;
          newTransfersData[transferIndex].formValues.departurePerPersonPriceVip = airportService.supplier.perPersonPrice;
          newTransfersData[transferIndex].formValues.departureSaleTotalVip =
            airportService.supplier.perGroupPrice + newTransfersData[transferIndex].formValues.departureUnitsVip * airportService.supplier.perPersonPrice;
        } else if (objType === 'arrival') {
          // arrival
          newTransfersData[transferIndex].formValues.arrivalSupplierNameVip = airportService.supplier.displayName;
          newTransfersData[transferIndex].formValues.arrivalSupplierUidVip = airportService.supplier.id;
          //newTransfersData[transferIndex].formValues.arrivalUnitsVip = 1;
          newTransfersData[transferIndex].formValues.arrivalPerGroupPriceVip = airportService.supplier.perGroupPrice;
          newTransfersData[transferIndex].formValues.arrivalPerPersonPriceVip = airportService.supplier.perPersonPrice;
          newTransfersData[transferIndex].formValues.arrivalSaleTotalVip =
            airportService.supplier.perGroupPrice + newTransfersData[transferIndex].formValues.arrivalUnitsVip * airportService.supplier.perPersonPrice;
        }
      }
    } else {
      // Delete transfer
      console.log('rebuild transfer 5');
      newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
      newTransfersData[transferIndex].isInvalid = true;
    }

    if (transfer.formValues.dateFlightOwnArrangements) {
      newTransfersData[transferIndex].formValues.dateFlightOwnArrangements = '';
      newTransfersData[transferIndex].formValues.timeFlightOwnArrangements = 'Select time';
      newTransfersData[transferIndex].formValues.airlineUidOwnArrangements = '';
      newTransfersData[transferIndex].formValues.airlineNameOwnArrangements = '';
      newTransfersData[transferIndex].formValues.fltNum = '';
      newTransfersData[transferIndex].formValues.addFlightDetailsLater = true;
      // remove flight details airline and flight number and departure time
    }
  }
}

// reset flight details for own arrangements/camp
async function handleTransferCamp(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties) {
  console.log('handleTransferCamp');
  // set date to check - start
  let bookingDay;
  let transferDay;
  if (transfer.objType === 'arrival') {
    bookingDay = booking.startDay;
    transferDay = newTransfersLatest[transferLatestIndex].startDay;
  } else {
    bookingDay = booking.endDay;
    transferDay = newTransfersLatest[transferLatestIndex].endDay;
  }
  if (bookingDay === transferDay) {
    console.log('rebuild transfer 1');
  } else {
    console.log('rebuild transfer 2');
    console.log('transfer.formValues.dateFlightOwnArrangements: ', transfer.formValues.dateFlightOwnArrangements);
    if (transfer.formValues.dateFlightOwnArrangements) {
      newTransfersData[transferIndex].formValues.dateFlightOwnArrangements = '';
      newTransfersData[transferIndex].formValues.timeFlightOwnArrangements = 'Select time';
      newTransfersData[transferIndex].formValues.airlineUidOwnArrangements = '';
      newTransfersData[transferIndex].formValues.airlineNameOwnArrangements = '';
      newTransfersData[transferIndex].formValues.fltNum = '';
      newTransfersData[transferIndex].formValues.addFlightDetailsLater = true;
      // remove flight details airline and flight number and departure time
    }
  }
}

// Here we check if the same number of adults and children are at the property and decide if we are keeping the flight or not.
const validateBookingAndTransferGuests = (booking, transfer, companyChildAgeEnd) => {
  console.log('validateBookingAndTransferGuests');
  // 1. Merge booking guests
  // const bookingGuestsMerged = booking.rooms.reduce((acc, room) => {
  //   return [...acc, ...room.selectedAgesAdults, ...room.selectedAges];
  // }, []);
  // const bookingGuestsMerged = booking.rooms
  // .filter(room => room.type !== 'Vehicle')
  // .reduce((acc, room) => {
  //   return [...acc, ...room.selectedAgesAdults, ...room.selectedAges];
  // }, []);
  const bookingGuestsMerged = booking.rooms
    .filter((room) => room.type !== 'Vehicle')
    .reduce((acc, room) => {
      const adultsAges = Array.isArray(room.selectedAgesAdults) ? room.selectedAgesAdults : [];
      const childrenAges = Array.isArray(room.selectedAges) ? room.selectedAges : [];
      return [...acc, ...adultsAges, ...childrenAges];
    }, []);

  // 2. Merge transfer guests
  const transferGuestsMerged = [...transfer.selectedAgesAdults, ...transfer.selectedAges];

  // 3. Check if lengths match
  if (bookingGuestsMerged.length !== transferGuestsMerged.length) {
    console.log('validateBookingAndTransferGuests 1');
    return true;
  }

  // 4. Calculate children and adults from bookingGuestsMerged
  const { children, adults } = bookingGuestsMerged.reduce(
    (acc, guest) => {
      if (guest.age <= companyChildAgeEnd) {
        acc.children++;
      } else {
        acc.adults++;
      }
      return acc;
    },
    { children: 0, adults: 0 },
  );

  // 5. Check if calculated numbers match transfer data
  const transferPassengers = transfer.formValues.flightPricing.passengers;
  if (children !== transferPassengers.children || adults !== transferPassengers.adultsTrue) {
    console.log('validateBookingAndTransferGuests 2');
    return true;
  }

  // If all checks pass, return true
  console.log('validateBookingAndTransferGuests 3');
  return false;
};

// check booking pax ages against transfer pax ages
function shouldUpdateTransfer(booking, transfer) {
  if (transfer.formValues.flightDetails.CompanyChildAgeEnd && transfer.selectedAges && transfer.selectedAgesAdults && transfer.formValues.flightPricing.passengers) {
    console.log('shouldUpdate 1');
    return validateBookingAndTransferGuests(booking, transfer, transfer.formValues.flightDetails.CompanyChildAgeEnd);
  } else {
    console.log('shouldUpdate 2');
    return !booking.notModified;
  }
}

// TRANSFERS
async function updateTransfers(bookings, transfers, transfersLatest, properties, setTransfers, airports, locations, customLocations, setBookings, bookingsStore, createVersionCheck) {
  console.log('updateTransfers debug start');
  // Shallow copying the arrays to avoid any mutation issues.
  let newTransfersData = [...transfers];

  let newTransfersLatest = [...transfersLatest];

  // delete transfer.formValues not only where booking.id doesn't match transfer.uuid, but also where booking.id === transfer.uuid AND booking.rebuild === true
  for (let i = 0; i < newTransfersLatest.length; i++) {
    const transfer = newTransfersLatest[i];

    // Find the booking associated with the current transfer
    //const associatedBooking = bookings.find((booking) => booking.id === transfer.uuid);
    // const associatedBooking = bookings.find((booking) => {
    //   console.log('booking.id: ', booking.id);
    //   console.log('transfer.uuid: ', transfer.uuid);
    //   return booking.id === transfer.uuid;
    // });

    // Determine if we should delete formValues
    // const shouldDeleteFormValues = !associatedBooking || (associatedBooking && associatedBooking.rebuild);
    //const shouldDeleteFormValues = false;

    // if (shouldDeleteFormValues) {
    //   alert('shouldDeleteFormValues: ');
    // }

    // if (shouldDeleteFormValues) {
    //   // Remove from current transfer
    //   console.log('Remove from current transfer');
    //   delete newTransfersLatest[i].formValues;
    //   newTransfersLatest[i].isInvalid = true;
    //   // Remove from the previous transfer, if it exists
    //   if (i > 0) {
    //     console.log('Remove from previous transfer');
    //     newTransfersLatest[i - 1].isInvalid = true;
    //   }

    //   // Remove from the next transfer, if it exists
    //   if (i < newTransfersLatest.length - 1) {
    //     console.log('Remove from next transfer');
    //     newTransfersLatest[i + 1].isInvalid = true;
    //   }
    // }

    // return { newTransfersData, newTransfersLatest };
    // ! Deal with all transfers
    if (transfer.objType === 'arrival') {
      // Deal with "objType": "arrival" first in transfers and transfersLatest
      console.log('Loop: arrival');
      let booking = bookings[0];
      let transferIndex = newTransfersData.findIndex((transfer) => transfer.objType === 'arrival' && transfer.propUid === booking.propUid && transfer.uuid === booking.id);
      let transferLatestIndex = newTransfersLatest.findIndex((transfer) => transfer.objType === 'arrival' && transfer.propUid === booking.propUid && transfer.uuid === booking.id);
      if (transfer.formValues) {
        if (transferIndex !== -1 && transferLatestIndex !== -1) {
          newTransfersLatest[transferLatestIndex].removalReason = '';
          newTransfersLatest[transferLatestIndex].removed = false;
          //const bookingIndex = bookings.findIndex((b) => b.id === newTransfersLatest[transferLatestIndex].uuid);
          if (
            newTransfersLatest[transferLatestIndex].formValues.type === 'bookFlight'
            // ||
            // (newTransfersLatest[transferLatestIndex].formValues.type === 'location' && newTransfersLatest[transferLatestIndex].formValues.transferType === 'townTransfer')
          ) {
            // check booking pax ages against transfer pax ages
            let shouldUpdate = shouldUpdateTransfer(booking, transfer);

            console.log('shouldUpdate arrival', shouldUpdate);
            console.log('transfer.formValues.flightDetails.CompanyChildAgeEnd', transfer.formValues.flightDetails.CompanyChildAgeEnd);

            if (booking.startDay === newTransfersLatest[transferLatestIndex].startDay && !shouldUpdate) {
              //transfer.formValues = newTransfersLatest[transferLatestIndex].formValues;
              newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
              console.log('COPIED arrival FORM VALUES');
              console.log(JSON.stringify(newTransfersData[transferIndex].objType, undefined, 4));

              console.log(JSON.stringify(newTransfersLatest[transferLatestIndex].objType, undefined, 4));
              if (newTransfersLatest[transferLatestIndex].isInvalid) {
                // ! CHECK
                newTransfersData[transferIndex].isInvalid = true;
              }
            } else {
              newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
              newTransfersData[transferIndex].isInvalid = true;
              newTransfersLatest[transferLatestIndex].removalReason = 'Property details changed';
              newTransfersLatest[transferLatestIndex].removed = true;
              console.log('DELETED arrival FORM VALUES');
              //return;
            }
            // if (booking.endDay === newTransfersLatest[transferLatestIndex].endDay && !booking.rebuild) {
            //   //transfer.formValues = newTransfersLatest[transferLatestIndex].formValues;
            //   newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
            //   console.log('COPIED arrival FORM VALUES');
            //   console.log(JSON.stringify(newTransfersData[transferIndex].objType, undefined, 4));

            //   console.log(JSON.stringify(newTransfersLatest[transferLatestIndex].objType, undefined, 4));
            //   if (newTransfersLatest[transferLatestIndex].isInvalid) {
            //     // ! CHECK
            //     newTransfersData[transferIndex].isInvalid = true;
            //   }
            // } else {
            //   newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
            //   newTransfersData[transferIndex].isInvalid = true;
            //   newTransfersLatest[transferLatestIndex].removalReason = 'Property details changed';
            //   newTransfersLatest[transferLatestIndex].removed = true;
            //   console.log('DELETED arrival FORM VALUES');
            //   //return;
            // }
          } else {
            if (
              (booking.startDay === newTransfersLatest[transferLatestIndex].startDay && booking.notModified) ||
              newTransfersLatest[transferLatestIndex].formValues.type === 'camp' ||
              newTransfersLatest[transferLatestIndex].formValues.type === 'ownArrangements' ||
              newTransfersLatest[transferLatestIndex].formValues.type === 'safari' ||
              newTransfersLatest[transferLatestIndex].formValues.type === 'location'
            ) {
              //transfer.formValues = newTransfersLatest[transferLatestIndex].formValues;
              newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
              console.log('COPIED ARRIVAL FORM VALUES');
              console.log(JSON.stringify(newTransfersData[transferIndex].objType, undefined, 4));
              if (newTransfersLatest[transferLatestIndex].isInvalid) {
                // ! CHECK
                newTransfersData[transferIndex].isInvalid = true;
              }

              // // get bookings totals
              const totals = calculateTotals(booking);
              newTransfersData[transferIndex].totalAd = totals.totalAd;
              newTransfersData[transferIndex].totalCh = totals.totalCh;
              newTransfersData[transferIndex].totalChi = totals.totalChi;
              newTransfersData[transferIndex].totalInf = totals.totalInf;
              const totalPax = totals.totalAd + totals.totalCh + totals.totalChi + totals.totalInf;

              const totalsTransfer = calculateTotalsTransfer(newTransfersLatest[transferLatestIndex]);
              const totalTransferPax = totalsTransfer.totalAd + totalsTransfer.totalCh + totalsTransfer.totalChi + totalsTransfer.totalInf;

              console.log('totalPax: ', totalPax);
              console.log('totalTransferPax arrival: ', totalTransferPax);

              if (booking.startDay === newTransfersLatest[transferLatestIndex].startDay && totalsTransfer === totalPax) {
                newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
                console.log('COPIED arrival FORM VALUES handleTransferRoute');
                if (newTransfersLatest[transferLatestIndex].isInvalid) {
                  // ! CHECK
                  newTransfersData[transferIndex].isInvalid = true;
                }
              } else if (newTransfersLatest[transferLatestIndex].formValues.type === 'location' && newTransfersLatest[transferLatestIndex].formValues.maxCapacity) {
                // NEW TRANSFER ROUTE CODE - START
                await handleTransferRoute(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties);
                if (newTransfersLatest[transferLatestIndex].formValues.type === 'location' && newTransfersLatest[transferLatestIndex].formValues.includeArrivalVip) {
                  const airportCode = newTransfersLatest[transferLatestIndex].formValues.airportCodeVip;
                  await handleTransferRouteVip(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties, airports, airportCode, 'arrival');
                }
                // NEW TRANSFER ROUTE CODE - END
              } else if (newTransfersLatest[transferLatestIndex].formValues.type === 'safari') {
                let maxCapacity = newTransfersLatest[transferLatestIndex].routeTransfer.maxCapacity;
                console.log('maxCapacity NEW 1: ', maxCapacity);
                if (maxCapacity >= 0 || newTransfersLatest[transferLatestIndex].formValues.maxCapacity >= 0) {
                  await handleTransferRoute(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties);
                  // maxCapacity = maxCapacity > 0 ? maxCapacity : newTransfersLatest[transferLatestIndex].formValues.maxCapacity;
                  // const units = totalPax > 0 && maxCapacity > 0 ? Math.ceil(totalPax / maxCapacity) : 0;
                  // newTransfersData[transferIndex].formValues.units = units;
                  // const netOrStoPrice =
                  //   newTransfersLatest[transferLatestIndex].formValues.transferType === 'townTransfer'
                  //     ? newTransfersLatest[transferLatestIndex].formValues.stoPriceCost
                  //     : newTransfersLatest[transferLatestIndex].formValues.netRackCost;
                  // newTransfersData[transferIndex].formValues.saleTotal = units * Number(netOrStoPrice);
                } else {
                  //toast.error('Delete transfer 1');

                  newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
                  newTransfersData[transferIndex].isInvalid = true;
                }
              } else if (newTransfersLatest[transferLatestIndex].formValues.type === 'camp') {
                console.log('CAMP COPIED arrival');
                await handleTransferCamp(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties);
                const airportCode = newTransfersLatest[transferLatestIndex].formValues.airportCodeVip;
                await handleTransferRouteVip(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties, airports, airportCode, 'arrival');
              }

              console.log(JSON.stringify(newTransfersLatest[transferLatestIndex].objType, undefined, 4));
            } else {
              newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
              newTransfersData[transferIndex].isInvalid = true;
              newTransfersLatest[transferLatestIndex].removalReason = 'Property details changed';
              newTransfersLatest[transferLatestIndex].removed = true;
              console.log('DELETED ARRIVAL FORM VALUES');
              //return;
            }

            // PACKED LUNCH
            const totals = calculateTotals(booking);
            newTransfersData[transferIndex].totalAd = totals.totalAd;
            newTransfersData[transferIndex].totalCh = totals.totalCh;
            newTransfersData[transferIndex].totalChi = totals.totalChi;
            newTransfersData[transferIndex].totalInf = totals.totalInf;
            const totalPax = totals.totalAd + totals.totalCh + totals.totalChi + totals.totalInf;
            if (newTransfersLatest[transferLatestIndex].formValues?.packedLunch) {
              newTransfersData[transferIndex].formValues.packedLunchUnits = totalPax;
              newTransfersData[transferIndex].formValues.packedLunchSaleTotal = newTransfersData[transferIndex].formValues.packedLunchChargeable
                ? totalPax * newTransfersLatest[transferLatestIndex].formValues.packedLunchUnitPrice
                : 0;
            }
          }
        } else {
          // ! remove only arrival flight
          // let transferLatestIndexFlight = newTransfersLatest.findIndex((transfer) => transfer.objType === 'arrival' && transfer.formValues.type === 'bookFlight');
          // if (booking.startDay === newTransfersLatest[transferLatestIndexFlight].startDay) {
          //   //transfer.formValues = newTransfersLatest[transferLatestIndex].formValues;
          //   newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndexFlight].formValues;
          //   console.log('COPIED arrival FORM VALUES 1');
          //   console.log(JSON.stringify(newTransfersLatest[transferLatestIndex].objType, undefined, 4));
          //   if (newTransfersLatest[transferLatestIndexFlight].isInvalid) {
          //     // ! CHECK
          //     newTransfersData[transferIndex].isInvalid = true;
          //   }
          // }
        }
      }
      console.log('newTransfersData[transferIndex].formValues xxx');
      console.log(JSON.stringify(newTransfersData[transferIndex], null, 2));
    } else if (transfer.objType === 'propertyTransfer') {
      // Deal with "objType": "propertyTransfer"
      console.log('Loop: propertyTransfer');
      if (transfer.formValues) {
        const bookingIndex = bookings.findIndex((b) => b.id === transfer.uuid);
        if (bookingIndex !== -1) {
          let booking = bookings[bookingIndex];
          let transferIndex = newTransfersData.findIndex(
            (foundTransfer) => foundTransfer && foundTransfer.objType === 'propertyTransfer' && foundTransfer.propUid === booking.propUid && foundTransfer.uuid === booking.id,
          );
          let transferLatestIndex = newTransfersLatest.findIndex(
            (foundTransfer) => foundTransfer && foundTransfer.objType === 'propertyTransfer' && foundTransfer.propUid === booking.propUid && foundTransfer.uuid === booking.id,
          );
          if (
            transferIndex !== -1 &&
            transferLatestIndex !== -1 &&
            newTransfersLatest[transferLatestIndex + 1].objType === 'propertyTransfer' &&
            newTransfersData[transferIndex + 1].objType === 'propertyTransfer' &&
            newTransfersLatest[transferLatestIndex].propUid === newTransfersData[transferIndex].propUid &&
            newTransfersLatest[transferLatestIndex + 1].propUid === newTransfersData[transferIndex + 1].propUid
            // &&
            // bookings[bookingIndex].propUid === bookingsStore[bookingIndex].propUid
          ) {
            console.log('bookings[bookingIndex].propUid ', bookings[bookingIndex].propUid);
            //console.log('bookingsStore[bookingIndex].propUid ', bookingsStore[bookingIndex].propUid);
            if (newTransfersLatest[transferLatestIndex].formValues.type === 'flight') {
              console.log('REBUILDING propertyTransfer flight');
              console.log(bookings[bookingIndex].endDay);
              console.log(booking.endDay);
              console.log(newTransfersLatest[transferLatestIndex].endDay);
              console.log(booking.rebuild);
              console.log('bookings[bookingIndex].notModified', bookings[bookingIndex].notModified);
              console.log('booking.notModified', booking.notModified);
              console.log(booking.propName);

              // check pax for flights
              // let update = false;
              // if(!transfer.formValues.flightDetails.CompanyChildAgeEnd && !semver.gte(createVersionCheck, '2.5.13')){

              //   const totals = calculateTotals(booking);
              //   const totalPax = totals.totalAd + totals.totalCh + totals.totalChi + totals.totalInf;
              //   const totalChildren = totals.totalCh + totals.totalChi + totals.totalInf;
              //   const totalAdults = totals.totalAd;

              //   const totalsTransfer = calculateTotalsTransfer(transfer);
              //   const totalTransferPax = totalsTransfer.totalAd + totalsTransfer.totalCh + totalsTransfer.totalChi + totalsTransfer.totalInf;
              //   const totalTransferChildren = totalsTransfer.totalCh + totalsTransfer.totalChi + totalsTransfer.totalInf;
              //   const totalTransferAdults = totalsTransfer.totalAd;
              //   if(totalChildren === totalTransferChildren && totalAdults === totalTransferAdults){
              //     update = false;
              //   } else {
              //     update = true;
              //   }
              // } else if(transfer.formValues.flightDetails.CompanyChildAgeEnd && !transfer.selectedAges && !transfer.selectedAgesAdults){

              //   const totals = calculateTotals(booking);
              //   const totalPax = totals.totalAd + totals.totalCh + totals.totalChi + totals.totalInf;
              //   const totalChildren = totals.totalCh + totals.totalChi + totals.totalInf;
              //   const totalAdults = totals.totalAd;

              //   const totalsTransfer = calculateTotalsTransfer(transfer);
              //   const totalTransferPax = totalsTransfer.totalAd + totalsTransfer.totalCh + totalsTransfer.totalChi + totalsTransfer.totalInf;
              //   const totalTransferChildren = totalsTransfer.totalCh + totalsTransfer.totalChi + totalsTransfer.totalInf;
              //   const totalTransferAdults = totalsTransfer.totalAd;
              //   if(totalChildren === totalTransferChildren && totalAdults === totalTransferAdults){
              //     update = false;
              //   } else {
              //     update = true;
              //   }
              // }

              // check booking pax ages against transfer pax ages
              let shouldUpdate = shouldUpdateTransfer(booking, transfer);

              console.log('shouldUpdate property', shouldUpdate);
              console.log('transfer.formValues.flightDetails.CompanyChildAgeEnd', transfer.formValues.flightDetails.CompanyChildAgeEnd);

              if (bookings[bookingIndex].endDay === newTransfersLatest[transferLatestIndex].endDay && !shouldUpdate) {
                //transfer.formValues = newTransfersLatest[transferLatestIndex].formValues;
                newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
                if (newTransfersLatest[transferLatestIndex].isInvalid) {
                  // ! CHECK
                  newTransfersData[transferIndex].isInvalid = true;
                }
                console.log('COPIED propertyTransfer FORM VALUES');
                console.log(JSON.stringify(newTransfersData[transferIndex].objType, undefined, 4));

                console.log(JSON.stringify(newTransfersLatest[transferLatestIndex].objType, undefined, 4));
              } else {
                newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
                newTransfersData[transferIndex].isInvalid = true;
                newTransfersLatest[transferLatestIndex].removalReason = 'Property details changed';
                newTransfersLatest[transferLatestIndex].removed = true;
                console.log('DEBUG DELETED propertyTransfer FORM VALUES 1');
                //return;
              }
            } else {
              // ! set startDay and endDay
              //if (bookings[bookingIndex].endDay === newTransfersLatest[transferLatestIndex].endDay) {
              if (
                (bookings[bookingIndex].endDay === newTransfersLatest[transferLatestIndex].endDay && booking.notModified) ||
                newTransfersLatest[transferLatestIndex].formValues.type === 'camp' ||
                newTransfersLatest[transferLatestIndex].formValues.type === 'ownArrangements' ||
                newTransfersLatest[transferLatestIndex].formValues.type === 'safari' ||
                newTransfersLatest[transferLatestIndex].formValues.type === 'location'
              ) {
                //transfer.formValues = newTransfersLatest[transferLatestIndex].formValues;
                newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
                if (newTransfersLatest[transferLatestIndex].isInvalid) {
                  // ! CHECK
                  newTransfersData[transferIndex].isInvalid = true;
                }
                console.log('COPIED propertyTransfer FORM VALUES');
                console.log(JSON.stringify(newTransfersData[transferIndex].objType, undefined, 4));

                // get bookings totals
                const totals = calculateTotals(booking);
                newTransfersData[transferIndex].totalAd = totals.totalAd;
                newTransfersData[transferIndex].totalCh = totals.totalCh;
                newTransfersData[transferIndex].totalChi = totals.totalChi;
                newTransfersData[transferIndex].totalInf = totals.totalInf;
                const totalPax = totals.totalAd + totals.totalCh + totals.totalChi + totals.totalInf;

                const totalsTransfer = calculateTotalsTransfer(newTransfersLatest[transferLatestIndex]);
                const totalTransferPax = totalsTransfer.totalAd + totalsTransfer.totalCh + totalsTransfer.totalChi + totalsTransfer.totalInf;

                console.log('totalPax: ', totalPax);
                console.log('totalTransferPax property transfer: ', totalTransferPax);

                // ! check total pax booking vs transfer pax ---------------------------------------------------------------------------------

                if (bookings[bookingIndex].endDay === newTransfersLatest[transferLatestIndex].endDay && totalsTransfer === totalPax) {
                  newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
                  console.log('COPIED propertyTransfer FORM VALUES handleTransferRoute');
                  if (newTransfersLatest[transferLatestIndex].isInvalid) {
                    // ! CHECK
                    newTransfersData[transferIndex].isInvalid = true;
                  }
                } else if (newTransfersLatest[transferLatestIndex].formValues.type === 'location' && newTransfersLatest[transferLatestIndex].formValues.maxCapacity) {
                  // NEW TRANSFER ROUTE CODE - START
                  await handleTransferRoute(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties);
                  // NEW TRANSFER ROUTE CODE - END
                } else if (newTransfersLatest[transferLatestIndex].formValues.type === 'safari') {
                  // NEW TRANSFER ROUTE CODE - START
                  await handleTransferRoute(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties);
                  // NEW TRANSFER ROUTE CODE - END
                } else if (newTransfersLatest[transferLatestIndex].formValues.type === 'ownArrangements') {
                  const airportCodeArrival = newTransfersLatest[transferLatestIndex].formValues.arrivalAirportCodeVip;
                  await handleTransferRouteVip(
                    transfer,
                    booking,
                    newTransfersData,
                    newTransfersLatest,
                    transferIndex,
                    transferLatestIndex,
                    totalPax,
                    properties,
                    airports,
                    airportCodeArrival,
                    'arrival',
                  );
                  const airportCodeDeparture = newTransfersLatest[transferLatestIndex].formValues.departureAirportCodeVip;
                  await handleTransferRouteVip(
                    transfer,
                    booking,
                    newTransfersData,
                    newTransfersLatest,
                    transferIndex,
                    transferLatestIndex,
                    totalPax,
                    properties,
                    airports,
                    airportCodeDeparture,
                    'departure',
                  );
                }

                console.log(JSON.stringify(newTransfersLatest[transferLatestIndex].objType, undefined, 4));
              } else {
                newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
                newTransfersData[transferIndex].isInvalid = true;
                newTransfersLatest[transferLatestIndex].removalReason = 'Dates changed';
                newTransfersLatest[transferLatestIndex].removed = true;
                console.log('DEBUG DELETED propertyTransfer FORM VALUES 2');
              }
            }
            // PACKED LUNCH
            const totals = calculateTotals(booking);
            // set formValues ad, ch, chi, inf
            newTransfersData[transferIndex].totalAd = totals.totalAd;
            newTransfersData[transferIndex].totalCh = totals.totalCh;
            newTransfersData[transferIndex].totalChi = totals.totalChi;
            newTransfersData[transferIndex].totalInf = totals.totalInf;
            const totalPax = totals.totalAd + totals.totalCh + totals.totalChi + totals.totalInf;
            if (newTransfersLatest[transferLatestIndex].formValues?.packedLunch) {
              newTransfersData[transferIndex].formValues.packedLunchUnits = totalPax;
              newTransfersData[transferIndex].formValues.packedLunchSaleTotal = newTransfersData[transferIndex].formValues.packedLunchChargeable
                ? totalPax * newTransfersLatest[transferLatestIndex].formValues.packedLunchUnitPrice
                : 0;
            }
          } else {
            console.log('DEBUG DELETED propertyTransfer FORM VALUES both 3');
            // delete current transfer formValues
            // newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
            // newTransfersData[transferIndex].isInvalid = true;
            delete newTransfersLatest[transferLatestIndex].formValues;
            newTransfersLatest[transferLatestIndex].removalReason = 'Property removed';
            newTransfersLatest[transferLatestIndex].removed = true;
            // delete previous transfer formValues
            // newTransfersData[transferIndex - 1].formValues = newTransfersLatest[transferLatestIndex - 1].formValues;
            // newTransfersData[transferIndex - 1].isInvalid = true;
            // newTransfersLatest[transferLatestIndex - 1].removalReason = 'Property removed';
            // newTransfersLatest[transferLatestIndex - 1].removed = true;
            // console.log('DEBUG DELETED propertyTransfer FORM VALUES both 1');
          }
        } else {
          let transferLatestIndex = newTransfersLatest.findIndex((foundTransfer) => foundTransfer && foundTransfer.uuid === transfer.uuid);
          let transferIndex = newTransfersData.findIndex((foundTransfer) => foundTransfer && foundTransfer.uuid === transfer.uuid);
          console.log('transferLatestIndex: ', transferLatestIndex);
          console.log('transfer.uuid: ', transfer.uuid);
          console.log('transfer.propUid: ', transfer.propUid);
          // delete current transfer formValues
          //newTransfersData[transferLatestIndex].formValues = newTransfersLatest[transferLatestIndex].formValues; //! CHECK
          //newTransfersData[transferLatestIndex].isInvalid = true; //! CHECK
          delete newTransfersLatest[transferLatestIndex].formValues;
          newTransfersLatest[transferLatestIndex].removalReason = 'Property removed';
          newTransfersLatest[transferLatestIndex].removed = true;
          // delete previous transfer formValues
          if (transferLatestIndex > 0) {
            //newTransfersData[transferLatestIndex - 1].formValues = newTransfersLatest[transferLatestIndex - 1].formValues; //! CHECK
            //newTransfersData[transferLatestIndex - 1].isInvalid = true; //! CHECK
            delete newTransfersLatest[transferLatestIndex - 1].formValues;
            newTransfersLatest[transferLatestIndex - 1].removalReason = 'Property removed';
            newTransfersLatest[transferLatestIndex - 1].removed = true;
            console.log('DEBUG DELETED propertyTransfer FORM VALUES both 4');
          } else if (
            newTransfersLatest[transferLatestIndex - 1] &&
            newTransfersLatest[transferLatestIndex - 1].formValues &&
            newTransfersLatest[transferLatestIndex - 1].formValues.type === 'flight'
          ) {
            if (bookings[bookingIndex].startDay === newTransfersLatest[transferLatestIndex - 1].startDay) {
              //transfer.formValues = newTransfersLatest[transferLatestIndex].formValues;
              newTransfersData[transferIndex - 1].formValues = newTransfersLatest[transferLatestIndex - 1].formValues;
              console.log('DEBUG COPIED propertyTransfer FORM VALUES 1');
            }
          }
        }
      }
    } else if (transfer.objType === 'departure') {
      if (transfer.formValues) {
        let booking = bookings[bookings.length - 1];
        let transferIndex = newTransfersData.findIndex((transfer) => transfer.objType === 'departure' && transfer.propUid === booking.propUid && transfer.uuid === booking.id);
        let transferLatestIndex = newTransfersLatest.findIndex((transfer) => transfer.objType === 'departure' && transfer.propUid === booking.propUid && transfer.uuid === booking.id);

        console.log('Loop: departure');
        if (transferIndex !== -1 && transferLatestIndex !== -1) {
          if (
            newTransfersLatest[transferLatestIndex] &&
            newTransfersLatest[transferLatestIndex].formValues &&
            newTransfersLatest[transferLatestIndex].formValues.type === 'bookFlight'
            // ||
            //   (newTransfersLatest[transferLatestIndex].formValues.type === 'location' && newTransfersLatest[transferLatestIndex].formValues.transferType === 'townTransfer')
          ) {
            // check booking pax ages against transfer pax ages
            let shouldUpdate = shouldUpdateTransfer(booking, transfer);

            console.log('shouldUpdate departure', shouldUpdate);
            console.log('transfer.formValues.flightDetails.CompanyChildAgeEnd', transfer.formValues.flightDetails.CompanyChildAgeEnd);

            if (booking.endDay === newTransfersLatest[transferLatestIndex].endDay && !shouldUpdate) {
              //transfer.formValues = newTransfersLatest[transferLatestIndex].formValues;
              newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
              if (newTransfersLatest[transferLatestIndex].isInvalid) {
                // ! CHECK
                newTransfersData[transferIndex].isInvalid = true;
              }
              console.log('COPIED departure FORM VALUES 1');
              console.log(JSON.stringify(newTransfersData[transferIndex].objType, undefined, 4));

              console.log(JSON.stringify(newTransfersLatest[transferLatestIndex].objType, undefined, 4));
            } else {
              newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
              newTransfersData[transferIndex].isInvalid = true;
              newTransfersLatest[transferLatestIndex].removalReason = 'Property details changed';
              newTransfersLatest[transferLatestIndex].removed = true;
              console.log('DELETED departure FORM VALUES');
              //return;
            }
          } else if (newTransfersLatest[transferLatestIndex] && newTransfersLatest[transferLatestIndex].formValues) {
            if (
              booking.endDay === newTransfersLatest[transferLatestIndex].endDay ||
              newTransfersLatest[transferLatestIndex].formValues.type === 'camp' ||
              newTransfersLatest[transferLatestIndex].formValues.type === 'ownArrangements' ||
              newTransfersLatest[transferLatestIndex].formValues.type === 'safari' ||
              newTransfersLatest[transferLatestIndex].formValues.type === 'location'
            ) {
              //transfer.formValues = newTransfersLatest[transferLatestIndex].formValues;
              newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
              if (newTransfersLatest[transferLatestIndex].isInvalid) {
                // ! CHECK
                newTransfersData[transferIndex].isInvalid = true;
              }
              console.log('COPIED departure FORM VALUES 2');
              console.log(JSON.stringify(newTransfersData[transferIndex].objType, undefined, 4));

              // get bookings totals
              const totals = calculateTotals(booking);
              newTransfersData[transferIndex].totalAd = totals.totalAd;
              newTransfersData[transferIndex].totalCh = totals.totalCh;
              newTransfersData[transferIndex].totalChi = totals.totalChi;
              newTransfersData[transferIndex].totalInf = totals.totalInf;

              const totalPax = totals.totalAd + totals.totalCh + totals.totalChi + totals.totalInf;

              const totalsTransfer = calculateTotalsTransfer(newTransfersLatest[transferLatestIndex]);
              const totalTransferPax = totalsTransfer.totalAd + totalsTransfer.totalCh + totalsTransfer.totalChi + totalsTransfer.totalInf;

              console.log('totalPax: ', totalPax);
              console.log('totalTransferPax departure: ', totalTransferPax);

              if (booking.endDay === newTransfersLatest[transferLatestIndex].endDay && totalsTransfer === totalPax) {
                //transfer.formValues = newTransfersLatest[transferLatestIndex].formValues;
                newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
                console.log('COPIED departure FORM VALUES handleTransferRoute');
                if (newTransfersLatest[transferLatestIndex].isInvalid) {
                  // ! CHECK
                  newTransfersData[transferIndex].isInvalid = true;
                }
              } else if (newTransfersLatest[transferLatestIndex].formValues.type === 'location' && newTransfersLatest[transferLatestIndex].formValues.maxCapacity) {
                // NEW TRANSFER ROUTE CODE - START
                await handleTransferRoute(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties);
                if (newTransfersLatest[transferLatestIndex].formValues.type === 'location' && newTransfersLatest[transferLatestIndex].formValues.includeDepartureVip) {
                  const airportCode = newTransfersLatest[transferLatestIndex].formValues.airportCodeVip;
                  await handleTransferRouteVip(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties, airports, airportCode, 'departure');
                }
                // NEW TRANSFER ROUTE CODE - END
              } else if (newTransfersLatest[transferLatestIndex].formValues.type === 'safari') {
                // NEW TRANSFER ROUTE CODE - START
                await handleTransferRoute(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties);
                // NEW TRANSFER ROUTE CODE - END
              } else if (newTransfersLatest[transferLatestIndex].formValues.type === 'camp') {
                // NEW TRANSFER ROUTE CODE - START
                console.log('CAMP COPIED departure');
                await handleTransferCamp(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties);
                const airportCode = newTransfersLatest[transferLatestIndex].formValues.airportCodeVip;
                await handleTransferRouteVip(transfer, booking, newTransfersData, newTransfersLatest, transferIndex, transferLatestIndex, totalPax, properties, airports, airportCode, 'departure');
                // NEW TRANSFER ROUTE CODE - END
              }

              console.log(JSON.stringify(newTransfersLatest[transferLatestIndex].objType, undefined, 4));
            } else {
              newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
              newTransfersData[transferIndex].isInvalid = true;
              newTransfersLatest[transferLatestIndex].removalReason = 'Dates changed';
              newTransfersLatest[transferLatestIndex].removed = true;
              console.log('DELETED departure FORM VALUES 1');
              //return;
            }
          }
          // PACKED LUNCH
          const totals = calculateTotals(booking);
          newTransfersData[transferIndex].totalAd = totals.totalAd;
          newTransfersData[transferIndex].totalCh = totals.totalCh;
          newTransfersData[transferIndex].totalChi = totals.totalChi;
          newTransfersData[transferIndex].totalInf = totals.totalInf;
          const totalPax = totals.totalAd + totals.totalCh + totals.totalChi + totals.totalInf;
          if (newTransfersLatest[transferLatestIndex].formValues?.packedLunch) {
            newTransfersData[transferIndex].formValues.packedLunchUnits = totalPax;
            newTransfersData[transferIndex].formValues.packedLunchSaleTotal = newTransfersData[transferIndex].formValues.packedLunchChargeable
              ? totalPax * newTransfersLatest[transferLatestIndex].formValues.packedLunchUnitPrice
              : 0;
          }
        } else {
          // transferIndex = newTransfersData.findIndex((transfer) => transfer.objType === 'departure');
          // transferLatestIndex = newTransfersLatest.findIndex((transfer) => transfer.objType === 'departure');
          // newTransfersData[transferIndex].formValues = newTransfersLatest[transferLatestIndex].formValues;
          // newTransfersData[transferIndex].isInvalid = true;
          // newTransfersLatest[transferLatestIndex].removalReason = 'Dates changed';
          // newTransfersLatest[transferLatestIndex].removed = true;
          console.log('departure FORM VALUES 2');
        }
      }
    } //
  }

  console.log('newTransfersData before return');
  console.log(JSON.stringify(newTransfersData, undefined, 4));
  const updatedNewTransfersData = newTransfersData.map((transferData) => {
    // Find the matching booking for this transferData
    const matchingBooking = bookings.find((booking) => booking.id === transferData.uuid);

    // If a matching booking is found, update the startDay and endDay
    if (matchingBooking) {
      return {
        ...transferData,
        startDay: matchingBooking.startDay,
        endDay: matchingBooking.endDay,
      };
    }

    // If no matching booking is found, return the original transferData
    return transferData;
  });

  console.log('updatedNewTransfersData before return');
  console.log(JSON.stringify(updatedNewTransfersData, undefined, 4));

  // update road transfer costs

  // NEW WORKING CODE BUT DATE ISSUE

  await Promise.all(
    updatedNewTransfersData.map(async (transfer) => {
      if (
        transfer.formValues &&
        transfer.formValues.airport &&
        ((transfer.formValues.type === 'location' && transfer.formValues && transfer.formValues.transferType === 'townTransfer') || transfer.formValues.type === 'camp')
      ) {
        console.log('match found for location & townTransfer');

        if (transfer.formValues.type === 'location') {
          // LOCATION
          transfer.formValues.dateFlight = transfer.formValues.dateFlight ? transfer.formValues.dateFlight : transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay;
          transfer.formValues.addFlightDetailsLater = transfer.formValues.addFlightDetailsLater ? transfer.formValues.addFlightDetailsLater : false;
          transfer.formValues.timeFlight = transfer.formValues.timeFlight === 'Select time' ? 'Select time' : transfer.formValues.timeFlight;
        }

        if (transfer.formValues.type === 'camp') {
          // LOCATION AND CAMP
          transfer.formValues.dateFlightOwnArrangements = transfer.formValues.dateFlightOwnArrangements
            ? transfer.formValues.dateFlightOwnArrangements
            : transfer.objType === 'arrival'
            ? transfer.startDay
            : transfer.endDay;
          transfer.formValues.addFlightDetailsLater = transfer.formValues.addFlightDetailsLater ? transfer.formValues.addFlightDetailsLater : false;
          transfer.formValues.timeFlightOwnArrangements = transfer.formValues.timeFlightOwnArrangements === 'Select time' ? 'Select time' : transfer.formValues.timeFlightOwnArrangements;
        }

        // reset time and Complete details later
        //transfer.formValues.dateFlight = transfer.endDay; // End or start day?

        console.log('calculated rates: townTransfer');
      }
    }),
  );
  console.log('updateTransfers debug end');
  return { newTransfersData, newTransfersLatest };
}
// TRANSFERS

async function updateTransfersOriginal(bookings, transfers, transfersLatest, properties, setTransfers, airports, locations, customLocations, setBookings) {
  // Shallow copying the arrays to avoid any mutation issues.
  let newTransfersData = [...transfers];
  // newTransfersData.forEach((transfer) => {
  //   // Check if the uuid of the transfer exists in the bookings array
  //   const bookingExists = bookings.some((booking) => booking.id === transfer.uuid);

  //   // If not, remove formValues from the transfer object
  //   if (!bookingExists) {
  //     delete transfer.formValues;
  //   }
  // });

  // console.log("newTransfersData");
  // console.log(JSON.stringify(newTransfersData, undefined, 4));

  let newTransfersLatest = [...transfersLatest];
  // for (let i = 0; i < newTransfersLatest.length; i++) {
  //   const transfer = newTransfersLatest[i];

  //   // Check if the uuid of the transfer exists in the bookings array
  //   const bookingExists = bookings.some((booking) => booking.id === transfer.uuid);

  //   // If the uuid doesn't exist in the bookings array, remove formValues
  //   if (!bookingExists) {
  //     // Remove from current transfer
  //     delete transfer.formValues;

  //     // Remove from the previous transfer, if it exists
  //     if (i > 0) {
  //       delete newTransfersLatest[i - 1].formValues;
  //     }

  //     // Remove from the next transfer, if it exists
  //     if (i < newTransfersLatest.length - 1) {
  //       delete newTransfersLatest[i + 1].formValues;
  //     }
  //   }
  // }

  // delete transfer.formValues not only where booking.id doesn't match transfer.uuid, but also where booking.id === transfer.uuid AND booking.rebuild === true
  for (let i = 0; i < newTransfersLatest.length; i++) {
    const transfer = newTransfersLatest[i];

    // Find the booking associated with the current transfer
    const associatedBooking = bookings.find((booking) => booking.id === transfer.uuid);

    // Determine if we should delete formValues
    const shouldDeleteFormValues = !associatedBooking || (associatedBooking && associatedBooking.rebuild);

    if (shouldDeleteFormValues) {
      // Remove from current transfer
      delete transfer.formValues;

      // Remove from the previous transfer, if it exists
      if (i > 0) {
        delete newTransfersLatest[i - 1].formValues;
      }

      // Remove from the next transfer, if it exists
      if (i < newTransfersLatest.length - 1) {
        delete newTransfersLatest[i + 1].formValues;
      }
    }
  }

  // newTransfersLatest.forEach((transfer) => {
  //   // Check if the uuid of the transfer exists in the bookings array
  //   const bookingExists = bookings.some((booking) => booking.id === transfer.uuid);

  //   // If not, remove formValues from the transfer object
  //   if (!bookingExists) {
  //     delete transfer.formValues;
  //   }
  // });

  // console.log("newTransfersLatest");
  // console.log(JSON.stringify(newTransfersLatest, undefined, 4));

  // Deal with "objType": "arrival" first in transfers and transfersLatest
  console.log('CHECK AVAILABLE TRANSFERS 1');
  let firstBooking = bookings[0];
  let arrivalTransferLatestIndex = newTransfersLatest.findIndex((transfer) => transfer.objType === 'arrival' && transfer.propUid === firstBooking.propUid && transfer.uuid === firstBooking.id);

  if (arrivalTransferLatestIndex !== -1) {
    let arrivalTransferIndex = newTransfersData.findIndex((transfer) => transfer.objType === 'arrival' && transfer.propUid === firstBooking.propUid && transfer.uuid === firstBooking.id);
    if (arrivalTransferIndex !== -1 && newTransfersLatest[arrivalTransferIndex] && newTransfersLatest[arrivalTransferIndex].formValues) {
      // ! New code
      newTransfersLatest[arrivalTransferLatestIndex].removalReason = '';
      newTransfersLatest[arrivalTransferLatestIndex].removed = false;
      if (newTransfersLatest[arrivalTransferLatestIndex].formValues.type === 'bookFlight') {
        console.log('Calling checkFlights() function 1');
        // Call checkFlights function here
        //newTransfersData[departureTransferIndex].formValues = newTransfersLatest[departureTransferLatestIndex].formValues;
        // ! New code
        const bookingIndex = bookings.findIndex((b) => b.id === newTransfersLatest[arrivalTransferLatestIndex].uuid);

        console.log('arrival dates:');
        console.log(bookings[bookingIndex].startDay);
        console.log(newTransfersData[arrivalTransferIndex].startDay);

        if (bookings[bookingIndex].startDay === newTransfersData[arrivalTransferIndex].startDay) {
          newTransfersData[arrivalTransferIndex].formValues = newTransfersLatest[arrivalTransferLatestIndex].formValues;
        } else {
          delete newTransfersData[arrivalTransferIndex].formValues;
          newTransfersLatest[arrivalTransferLatestIndex].removalReason = 'Dates changed';
          newTransfersLatest[arrivalTransferLatestIndex].removed = true;
          return;
        }
      } else {
        newTransfersData[arrivalTransferIndex].formValues = newTransfersLatest[arrivalTransferLatestIndex].formValues;
      }

      //

      const index = newTransfersData.findIndex((transfer) => transfer.objType === 'arrival');
      const transfer = newTransfersData.find((transfer) => transfer.objType === 'arrival');

      // get Transfer object & add variables to updateTransfers call

      console.log('CHECK AVAILABLE TRANSFERS ARRIVAL');

      await availableTransfers(transfer, newTransfersData, index, properties, 'arrivalTransfer', setTransfers, airports, newTransfersData, locations, customLocations);
    }
    newTransfersLatest[arrivalTransferLatestIndex].removalReason = '';
    newTransfersLatest[arrivalTransferLatestIndex].removed = false;
  } else {
    let arrivalTransferIndex = newTransfersLatest.findIndex((transfer) => transfer.objType === 'arrival');
    //let arrivalTransferDataIndex = newTransfersData.findIndex((transfer) => transfer.objType === "arrivalTransfer");

    if (arrivalTransferIndex !== -1) {
      newTransfersLatest[arrivalTransferIndex].removalReason = 'First property moved or removed.';
      newTransfersLatest[arrivalTransferIndex].removed = true;
      //  newTransfersLatest[arrivalTransferDataIndex].removalReason = "First property moved or removed.";
      //  newTransfersLatest[arrivalTransferDataIndex].removed = true;
    }
  }

  // Deal with "objType": "arrivalTransfer"
  // let arrivalTransferDataIndex = newTransfersData.findIndex((transfer) => transfer.objType === 'arrivalTransfer');
  // let arrivalDataIndex = newTransfersData.findIndex((transfer) => transfer.objType === 'arrival');

  // console.log('arrivalTransferDataIndex:', arrivalTransferDataIndex);

  // if (arrivalDataIndex !== -1 && newTransfersData[arrivalDataIndex].formValues) {
  //   console.log('newTransfersData[arrivalDataIndex].formValues:', newTransfersData[arrivalDataIndex].formValues);
  //   if (newTransfersData[arrivalDataIndex].formValues.type !== 'camp') {
  //     let arrivalTransferLatestIndex = newTransfersLatest.findIndex((transfer) => transfer.objType === 'arrivalTransfer');

  //     console.log('arrivalTransferLatestIndex:', arrivalTransferLatestIndex);

  //     if (arrivalTransferLatestIndex !== -1 && newTransfersData[arrivalTransferLatestIndex].formValues) {
  //       console.log('newTransfersLatest[arrivalTransferLatestIndex].formValues:', newTransfersLatest[arrivalTransferLatestIndex].formValues);
  //       if (
  //         (newTransfersLatest[arrivalTransferLatestIndex].formValues.type === 'safari' && newTransfersData[arrivalTransferDataIndex].routeTransfer?.available) ||
  //         (newTransfersLatest[arrivalTransferLatestIndex].formValues.type === 'town' && newTransfersData[arrivalTransferDataIndex].townTransfer?.available)
  //       ) {
  //         console.log('Copying formValues from newTransfersLatest to newTransfersData');
  //         newTransfersData[arrivalTransferDataIndex].formValues = newTransfersLatest[arrivalTransferLatestIndex].formValues;
  //       } else if (newTransfersLatest[arrivalTransferLatestIndex].formValues.type === 'flight') {
  //         console.log('Calling checkFlights() function');
  //         // Call checkFlights function here
  //         const flightInfo = await checkFlights(newTransfersLatest[arrivalTransferLatestIndex]); // Call checkFlights function here
  //         if (!flightInfo) {
  //           newTransfersLatest[arrivalTransferLatestIndex].removalReason = 'No matching flights';
  //           newTransfersLatest[arrivalTransferLatestIndex].removed = true;
  //         } else {
  //           if (!flightInfo) {
  //             newTransfersLatest[arrivalTransferLatestIndex].removalReason = 'No matching flights';
  //             newTransfersLatest[arrivalTransferLatestIndex].removed = true;
  //           } else {
  //             newTransfersData[arrivalTransferDataIndex].formValues = newTransfersLatest[arrivalTransferLatestIndex].formValues;
  //             newTransfersData[arrivalTransferDataIndex].formValues.flightDetails = flightInfo;
  //           }
  //         }
  //       } else {
  //         newTransfersLatest[arrivalTransferLatestIndex].removalReason = 'No transfers available';
  //         newTransfersLatest[arrivalTransferLatestIndex].removed = true;
  //       }
  //     }
  //   }
  // }

  // Deal with "objType": "propertyTransfer"
  console.log('Deal with "objType": "propertyTransfer"');
  let transferPropUids = newTransfersLatest.filter((item) => item.objType === 'propertyTransfer').map((item) => ({ propUid: item.propUid, tuuid: item.tuuid }));

  for (let i = 0; i < transferPropUids.length - 1; i++) {
    let currentUid = transferPropUids[i].propUid;
    let nextUid = transferPropUids[i + 1]?.propUid;

    // Find index of matching current item in newTransfersData
    let currentIndex = newTransfersData.findIndex((item) => item.objType === 'propertyTransfer' && item.propUid === currentUid && !item.formValues);

    if (currentIndex !== -1) {
      // Check if the next item in newTransfersData after the matched current item is also a match for the nextUid
      let nextItem = newTransfersData[currentIndex + 1];
      if (nextItem && nextItem.objType === 'propertyTransfer' && nextItem.propUid === nextUid) {
        console.log('propertyTransfer MATCHED');
        console.log('Matched tuuid in newTransfersLatest:', transferPropUids[i].tuuid);
        console.log('Matched tuuid in newTransfersData:', newTransfersData[currentIndex].tuuid, 'and', nextItem.tuuid);
        let propertyTransferIndex = newTransfersLatest.findIndex((transfer) => transfer.tuuid === transferPropUids[i].tuuid);

        if (propertyTransferIndex !== -1 && newTransfersLatest[propertyTransferIndex].formValues) {
          newTransfersLatest[propertyTransferIndex].removalReason = '';
          newTransfersLatest[propertyTransferIndex].removed = false;

          console.log('propertyTransfer CHECK 1');
          console.log('propertyTransferIndex: ', propertyTransferIndex);
          console.log('currentIndex: ', currentIndex);
          console.log('newTransfersLatest[propertyTransferIndex].formValues.type: ', newTransfersLatest[propertyTransferIndex].formValues.type);
          console.log('newTransfersData[currentIndex].routeTransfer?.available: ', newTransfersData[currentIndex].routeTransfer?.available);
          console.log('newTransfersLatest[propertyTransferIndex].tuuid: ', newTransfersLatest[propertyTransferIndex].tuuid);
          console.log('newTransfersData[currentIndex].tuuid: ', newTransfersData[currentIndex].tuuid);
          // if (
          //   newTransfersLatest[propertyTransferIndex].formValues.type === 'safari' &&
          //   newTransfersData[currentIndex].routeTransfer?.available
          //   // (newTransfersLatest[propertyTransferIndex].formValues.type === 'safari' && newTransfersData[currentIndex].routeTransfer?.available) ||
          //   // (newTransfersLatest[propertyTransferIndex].formValues.type === 'town' && newTransfersData[currentIndex].townTransfer?.available)
          // ) {
          //   console.log('FAILED');
          // } else {
          //   console.log('PASSED');
          //   console.log('Copying formValues from newTransfersLatest to newTransfersData');
          //   newTransfersData[currentIndex].formValues = newTransfersLatest[propertyTransferIndex].formValues;
          // }

          if (
            newTransfersLatest[propertyTransferIndex].formValues.type === 'safari' &&
            newTransfersData[currentIndex].routeTransfer?.available
            // (newTransfersLatest[propertyTransferIndex].formValues.type === 'safari' && newTransfersData[currentIndex].routeTransfer?.available) ||
            // (newTransfersLatest[propertyTransferIndex].formValues.type === 'town' && newTransfersData[currentIndex].townTransfer?.available)
          ) {
            console.log('Copying formValues from newTransfersLatest to newTransfersData');
            newTransfersData[currentIndex].formValues = newTransfersLatest[propertyTransferIndex].formValues;

            // property transfer safari or town

            // TRYING TO FIX
            //console.log('CHECK AVAILABLE TRANSFERS PROPERTY');

            // await availableTransfers(
            //   newTransfersLatest[propertyTransferIndex],
            //   newTransfersLatest[propertyTransferIndex + 1],
            //   currentIndex,
            //   properties,
            //   'propertyTransfer',
            //   setTransfers,
            //   airports,
            //   newTransfersData,
            //   locations,
            //   customLocations,
            // );
          } else if (newTransfersLatest[propertyTransferIndex].formValues.type === 'flight') {
            console.log('Calling checkFlights() function 2');
            // ! New code
            const bookingIndex = bookings.findIndex((b) => b.id === newTransfersLatest[propertyTransferIndex].uuid);

            console.log('property dates:');
            console.log(bookings[bookingIndex].endDay);
            console.log(newTransfersData[currentIndex].endDay);
            if (bookings[bookingIndex].endDay === newTransfersData[currentIndex].endDay) {
              newTransfersData[currentIndex].formValues = newTransfersLatest[propertyTransferIndex].formValues;
            } else {
              delete newTransfersData[currentIndex].formValues;
              newTransfersLatest[propertyTransferIndex].removalReason = 'Dates changed';
              newTransfersLatest[propertyTransferIndex].removed = true;
              return;
            }

            // Call checkFlights function here
            // ! Commented out for now
            // const flightInfo = await checkFlights(newTransfersLatest[propertyTransferIndex]); // Call checkFlights function here
            // if (!flightInfo) {
            //   newTransfersLatest[propertyTransferIndex].removalReason = 'No matching flights';
            //   newTransfersLatest[propertyTransferIndex].removed = true;
            // } else {
            //   if (!flightInfo) {
            //     newTransfersLatest[propertyTransferIndex].removalReason = 'No matching flights';
            //     newTransfersLatest[propertyTransferIndex].removed = true;
            //   } else {
            //     newTransfersData[currentIndex].formValues = newTransfersLatest[propertyTransferIndex].formValues;
            //     newTransfersData[currentIndex].formValues.flightDetails = flightInfo;
            //   }
            // }
            // ! Commented out for now
          } else if (newTransfersLatest[propertyTransferIndex].formValues.type === 'ownArrangements') {
            newTransfersData[currentIndex].formValues = newTransfersLatest[propertyTransferIndex].formValues;
          }
          // } else if (newTransfersLatest[propertyTransferIndex].formValues) {
          //   newTransfersLatest[propertyTransferIndex].removalReason = "No transfers were set";
          //   newTransfersLatest[propertyTransferIndex].removed = false;
        } else {
          newTransfersLatest[propertyTransferIndex].removalReason = 'No transfers available';
          newTransfersLatest[propertyTransferIndex].removed = true;
        }
      }
    } else {
      console.log('propertyTransfer NOT MATCHED');
      let propertyTransferIndex = newTransfersLatest.findIndex((transfer) => transfer.tuuid === transferPropUids[i].tuuid);
      if (propertyTransferIndex !== -1) {
        newTransfersLatest[propertyTransferIndex].removalReason = 'Property moved or removed.';
        newTransfersLatest[propertyTransferIndex].removed = true;
      }
    }
  }

  // Then deal with "objType": "departure" in transfers and transfersLatest
  let lastBooking = bookings[bookings.length - 1];
  let departureTransferLatestIndex = newTransfersLatest.findIndex((transfer) => transfer.objType === 'departure' && transfer.propUid === lastBooking.propUid && transfer.uuid === lastBooking.id);

  if (departureTransferLatestIndex !== -1) {
    let departureTransferIndex = newTransfersData.findIndex((transfer) => transfer.objType === 'departure' && transfer.propUid === lastBooking.propUid && transfer.uuid === lastBooking.id);
    if (departureTransferIndex !== -1 && newTransfersLatest[departureTransferIndex] && newTransfersLatest[departureTransferIndex].formValues) {
      newTransfersLatest[departureTransferLatestIndex].removalReason = '';
      newTransfersLatest[departureTransferLatestIndex].removed = false;
      if (newTransfersLatest[departureTransferLatestIndex].formValues.type === 'bookFlight') {
        console.log('Calling checkFlights() function 3');
        // Call checkFlights function here
        //newTransfersData[departureTransferIndex].formValues = newTransfersLatest[departureTransferLatestIndex].formValues;
        // ! New code
        const bookingIndex = bookings.findIndex((b) => b.id === newTransfersLatest[departureTransferLatestIndex].uuid);
        console.log('departure dates:');
        console.log(bookings[bookingIndex].endDay);
        console.log(newTransfersData[departureTransferIndex].endDay);

        if (bookings[bookingIndex].endDay === newTransfersData[departureTransferIndex].endDay) {
          newTransfersData[departureTransferIndex].formValues = newTransfersLatest[departureTransferLatestIndex].formValues;
        } else {
          delete newTransfersData[departureTransferIndex].formValues;
          newTransfersLatest[departureTransferLatestIndex].removalReason = 'Dates changed';
          newTransfersLatest[departureTransferLatestIndex].removed = true;
          return;
        }
        // ! Commented out for now
        // const flightInfo = await checkFlights(newTransfersLatest[departureTransferLatestIndex]); // Call checkFlights function here
        // if (!flightInfo) {
        //   newTransfersLatest[departureTransferLatestIndex].removalReason = 'No matching flights';
        //   newTransfersLatest[departureTransferLatestIndex].removed = true;
        // } else {
        //   if (!flightInfo) {
        //     newTransfersLatest[departureTransferLatestIndex].removalReason = 'No matching flights';
        //     newTransfersLatest[departureTransferLatestIndex].removed = true;
        //   } else {
        //     newTransfersData[departureTransferIndex].formValues = newTransfersLatest[departureTransferLatestIndex].formValues;
        //     newTransfersData[departureTransferIndex].formValues.flightDetails = flightInfo;
        //   }
        // }
        // ! Commented out for now
      } else {
        newTransfersData[departureTransferIndex].formValues = newTransfersLatest[departureTransferLatestIndex].formValues;
      }
    }
    // else {
    //   newTransfersLatest[departureTransferIndex].removalReason = "No transfers were set";
    //   newTransfersLatest[departureTransferIndex].removed = false;
    // }
  } else {
    let departureTransferIndex = newTransfersLatest.findIndex((transfer) => transfer.objType === 'departure');
    if (departureTransferIndex !== -1) {
      newTransfersLatest[departureTransferIndex].removalReason = 'Last property moved or removed.';
      newTransfersLatest[departureTransferIndex].removed = true;
    }
  }

  // console.log("newTransfersData after checkTransfers():");
  // console.log(JSON.stringify(newTransfersData, undefined, 4));

  newTransfersData.forEach(async (item) => {
    console.log('calculatePropertyVehicles rebuilding');
    await calculatePropertyVehicles(bookings, setBookings, newTransfersData, item.tuuid, properties);
    console.log(item.tuuid);
  });

  return { newTransfersData, newTransfersLatest };
}

// ACTIVITIES
function updateActivitiesData(bookings, activitiesData, rooms, properties) {
  const newActivitiesData = [];
  const removedActivities = JSON.parse(JSON.stringify(activitiesData));
  const bookingIds = new Set(bookings.map((b) => b.id));

  markRemovedActivities(removedActivities, bookingIds);
  processBookings(bookings, activitiesData, newActivitiesData, removedActivities, rooms);

  // calculateActivity here
  newActivitiesData.forEach((property) => {
    const booking = bookings.find((b) => b.propUid === property.propUid);
    property.activities.forEach((day, index) => {
      day.forEach((activity) => {
        let cost = 0;
        if (activity.type === 'resRequestAccom' || activity.type === 'resRequestExtra' || activity.type === 'hornbill') {
          if (
            booking.rateName.toLowerCase().includes('fullboard') &&
            properties[getPropObj(property.propUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' // Nomad Tanzania
          ) {
            cost = activity.priceFullBoard;
          } else {
            cost = activity.price;
          }
          // if (booking.rateName.toLowerCase().includes('fullboard')) {
          //   cost = activity.priceFullBoard;
          // } else {
          //   cost = activity.price;
          // }
        }
        const bookingIndex = bookings.findIndex((b) => b.propUid === property.propUid && b.id === property.id);
        const { tempCost, activityUnits, maxUnits, caution } = calculateActivity(activity, bookings, bookingIndex, cost, false);
        console.log('updateActivitiesData -> calculateActivity', tempCost, activityUnits, maxUnits, caution);

        // update properties in activity object
        activity.cost = tempCost;
        activity.activityUnits = activityUnits;
        activity.maxUnits = maxUnits;
        activity.caution = caution;
      });
    });
  });

  //

  return { newActivitiesData, removedActivities };
}

function markRemovedActivities(removedActivities, bookingIds) {
  for (const activity of removedActivities) {
    if (!bookingIds.has(activity.id)) {
      for (const dayActivities of activity.activities) {
        for (const act of dayActivities) {
          act.removed = true;
          act.removalReason = 'Property removed';
        }
      }
    }
  }
}

// function processBookings(bookings, activitiesData, newActivitiesData, removedActivities, rooms) {
//   for (const booking of bookings) {
//     const matchingActivity = activitiesData.find((a) => a.id === booking.id && a.propUid === booking.propUid);

//     if (matchingActivity) {
//       processMatchingBooking(booking, matchingActivity, newActivitiesData, removedActivities);
//     } else {
//       newActivitiesData.push({
//         propUid: booking.propUid,
//         id: booking.id,
//         activities: Array(booking.days + 1).fill([]),
//       });
//     }
//   }
//   filterActivitiesForAvailability(newActivitiesData, rooms, removedActivities); // Moved inside processBookings
// }

function processBookings(bookings, activitiesData, newActivitiesData, removedActivities, rooms) {
  for (const booking of bookings) {
    const matchingActivity = activitiesData.find((a) => a.id === booking.id && a.propUid === booking.propUid);

    if (matchingActivity) {
      processMatchingBooking(booking, matchingActivity, newActivitiesData, removedActivities);
    } else {
      const { propUid, days, id } = booking;
      const activities = Array.from({ length: days + 1 }, () => []);
      newActivitiesData.push({ propUid, id, activities });
    }
  }
  filterActivitiesForAvailability(newActivitiesData, rooms, removedActivities); // Moved inside processBookings
}

function processMatchingBooking(booking, matchingActivity, newActivitiesData, removedActivities) {
  const clonedMatchingActivity = JSON.parse(JSON.stringify(matchingActivity));
  adjustActivitiesLength(booking, clonedMatchingActivity, removedActivities);
  processActivityDays(booking, clonedMatchingActivity, removedActivities);
  newActivitiesData.push(clonedMatchingActivity);
}

function adjustActivitiesLength(booking, clonedMatchingActivity, removedActivities) {
  if (clonedMatchingActivity.activities.length !== booking.days + 1) {
    if (clonedMatchingActivity.activities.length < booking.days + 1) {
      for (let i = clonedMatchingActivity.activities.length; i < booking.days + 1; i++) {
        clonedMatchingActivity.activities.push([]);
      }
    } else {
      let lastNonEmptyDayIndex = clonedMatchingActivity.activities.reduceRight((lastIndex, dayActivities, index) => {
        return dayActivities.length > 0 ? index : lastIndex;
      }, -1); // change this line

      for (let i = booking.days + 1; i < clonedMatchingActivity.activities.length; i++) {
        let dayActivities = clonedMatchingActivity.activities[i];
        for (let activity of dayActivities) {
          activity.removed = true;
          activity.removalReason = i === lastNonEmptyDayIndex ? 'Not available on last day' : 'day removed';

          let removedActivity = removedActivities.find((a) => a.id === booking.id && a.propUid === booking.propUid)?.activities[i]?.find((a) => a.uuid === activity.uuid);
          if (removedActivity) {
            removedActivity.removed = activity.removed;
            removedActivity.removalReason = activity.removalReason;
          }
        }
      }
      clonedMatchingActivity.activities = clonedMatchingActivity.activities.slice(0, booking.days + 1);
    }
  }
}

function processActivityDays(booking, clonedMatchingActivity, removedActivities) {
  for (let i = 0; i < clonedMatchingActivity.activities.length; i++) {
    let dayActivities = clonedMatchingActivity.activities[i];

    for (let activity of dayActivities) {
      activity.removed = false;
      let removalReason = '';
      if (i === 0 && !activity.availableFirstDay) {
        activity.removed = true;
        removalReason = 'Not available on first day';
      } else if (i === clonedMatchingActivity.activities.length - 1 && !activity.availableLastDay) {
        activity.removed = true;
        removalReason = 'Not available on last day';
      }

      let removedActivity = removedActivities.find((a) => a.id === booking.id && a.propUid === booking.propUid)?.activities[i]?.find((a) => a.uuid === activity.uuid);
      if (removedActivity) {
        removedActivity.removed = activity.removed;
        removedActivity.removalReason = removalReason;
      }
    }

    // If any activity is removed due to being not available on last day, remove all activities for that day.
    if (dayActivities.some((activity) => activity.removed && activity.removalReason === 'Not available on last day')) {
      clonedMatchingActivity.activities[i] = [];
    } else {
      // Filter out removed activities from dayActivities
      clonedMatchingActivity.activities[i] = dayActivities.filter((activity) => !activity.removed);
    }
  }
}

function filterActivitiesForAvailability(newActivitiesData, rooms, removedActivities) {
  console.log('filterActivitiesForAvailability');
  for (const activityData of newActivitiesData) {
    for (let i = 0; i < activityData.activities.length; i++) {
      let dayActivities = activityData.activities[i];
      const selectedActivityDate = dayActivities.length > 0 ? dayActivities[0].selectedActivityDate : null;
      console.log('selectedActivityDate:', selectedActivityDate);
      const selectedActivityPropertyUid = activityData.propUid;

      let filteredActivitiesWithAvailability = filterActivities(dayActivities, rooms, selectedActivityDate, selectedActivityPropertyUid);
      if (filteredActivitiesWithAvailability.length !== dayActivities.length) {
        let removedActivitiesThisDay = dayActivities.filter((activity) => !filteredActivitiesWithAvailability.find((filteredActivity) => filteredActivity.uuid === activity.uuid));
        for (let removedActivity of removedActivitiesThisDay) {
          removedActivity.removed = true;
          removedActivity.removalReason = 'No availability';

          let removedActivityInRemovedActivities = removedActivities
            .find((a) => a.id === activityData.id && a.propUid === activityData.propUid)
            ?.activities[i]?.find((a) => a.uuid === removedActivity.uuid);
          if (removedActivityInRemovedActivities) {
            removedActivityInRemovedActivities.removed = removedActivity.removed;
            removedActivityInRemovedActivities.removalReason = removedActivity.removalReason;
          }
        }
        activityData.activities[i] = filteredActivitiesWithAvailability;
      }
    }
  }
}

export { checkBookingArrays, updateActivitiesData, updateTransfers };
