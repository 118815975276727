import React, { useEffect, useState, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { checkUserExistence, register, reset } from '../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../db/firebase.config';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from '../components/Buttons/ButtonPrimary';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

function Signup() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    password2: '',
  });

  const { firstName, lastName, password, password2 } = formData;
  const [emailError, setEmailError] = useState(null);

  // lead form for new users
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applicationForm, setApplicationForm] = useState({
    name: '',
    email: '',
    companyName: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message, userAllowed, userExistenceResult } = useSelector((state) => state.auth);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleApplicationChange = (e) => {
    setApplicationForm({ ...applicationForm, [e.target.name]: e.target.value });
  };

  const submitApplication = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'userApplications'), applicationForm);
      toast.success('Your application has been submitted successfully!');
      closeModal();
    } catch (error) {
      toast.error('Failed to submit application. Please try again.');
    }
  };


  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess) {
      setShowSuccessMessage(true);
    }

    dispatch(reset());
  }, [isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    if (step === 1) {
      setEmail(e.target.value);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const onSubmitEmail = async (e) => {
    e.preventDefault();
    setEmailError(null); // Reset error message
    try {
      const result = await dispatch(checkUserExistence({ email })).unwrap();
      if (result && result.userAllowed) {
        setStep(2);
      } else {
        setEmailError("Sorry, we couldn't find that email address in our system. Please note you must be registered with Nomad to sign up. Please try another email, or contact us");
      }
    } catch (error) {
      setEmailError("Sorry, we couldn't find that email address in our system. Please note you must be registered with Nomad to sign up. Please try another email, or contact us");
    }
  };

  const onSubmitFull = async (e) => {
    e.preventDefault();

    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();
    const lowerCasedEmail = email.toLowerCase().trim();
    const trimmedPassword = password.trim();

    if (trimmedFirstName.length <= 2) {
      toast.error('First name must be more than 2 characters long');
    } else if (trimmedLastName.length <= 3) {
      toast.error('Last name must be more than 3 characters long');
    } else if (trimmedPassword.length < 8) {
      toast.error('Password must be at least 8 characters long');
    } else if (trimmedPassword !== password2.trim()) {
      toast.error('Passwords do not match');
    } else {
      const userData = {
        firstName: trimmedFirstName,
        lastName: trimmedLastName,
        email: lowerCasedEmail,
        password: trimmedPassword,
        companyId: userExistenceResult.companyId,
        uid: userExistenceResult.uid,
        accessLevel: userExistenceResult.accessLevel,
        nomadPropertiesOnly: userExistenceResult.nomadPropertiesOnly,
      };

      dispatch(register(userData));
    }
  };



  return (
    <div className="flex h-screen overflow-hidden bg-white">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main className="flex-grow flex items-center justify-center">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-lg"
          >
            <div className="text-center mb-8">
              <h1 className="text-4xl font-roca text-nomadBlue mb-2">
                {step === 1 ? 'Enter your email' : 'Create your Account'}
              </h1>
              <p className="text-gray-600">Start your journey with Hornbill</p>
            </div>

            {showSuccessMessage ? (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="bg-white rounded-lg border border-[#f0ece7] p-6"
              >
                <div className="text-center">
                  <svg className="w-16 h-16 mx-auto mb-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <h2 className="text-2xl font-semibold mb-2">Success!</h2>
                  <p className="text-gray-600">Please check your email to verify your account.</p>
                </div>
              </motion.div>
            ) : (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="bg-white rounded-lg border border-[#f0ece7] p-6"
              >
                {emailError && (
                  <motion.div 
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded"
                  >
                    <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {emailError}{' '}
                      <button
                        onClick={openModal}
                        className="font-medium underline hover:text-red-800"
                      >
                        Click here
                      </button>{' '}
                      to apply for access.
                    </p>
                  </div>
                </div>
                  </motion.div>
                )}
                <form onSubmit={step === 1 ? onSubmitEmail : onSubmitFull} className="space-y-4">
                  {step === 1 ? (
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="email">
                        Email Address <span className="text-rose-500">*</span>
                      </label>
                      <input
                        id="email"
                        className="form-input w-full"
                        type="email"
                        name="email"
                        value={email}
                        onChange={onChange}
                        placeholder="Please enter your email"
                        required
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="firstName">
                          First Name <span className="text-rose-500">*</span>
                        </label>
                        <input
                          id="firstName"
                          className="form-input w-full"
                          type="text"
                          name="firstName"
                          value={firstName}
                          onChange={onChange}
                          placeholder="Please enter your first name"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="lastName">
                          Last Name <span className="text-rose-500">*</span>
                        </label>
                        <input
                          id="lastName"
                          className="form-input w-full"
                          type="text"
                          name="lastName"
                          value={lastName}
                          onChange={onChange}
                          placeholder="Please enter your last name"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="password">
                          Password <span className="text-rose-500">*</span>
                        </label>
                        <input
                          id="password"
                          className="form-input w-full"
                          type="password"
                          autoComplete="new-password"
                          name="password"
                          value={password}
                          onChange={onChange}
                          placeholder="Please enter your password"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="password2">
                          Confirm Password <span className="text-rose-500">*</span>
                        </label>
                        <input
                          id="password2"
                          className="form-input w-full"
                          type="password"
                          autoComplete="new-password"
                          name="password2"
                          value={password2}
                          onChange={onChange}
                          placeholder="Please confirm your password"
                          required
                        />
                      </div>
                    </>
                  )}
                  <div className="mt-6">
                    <ButtonPrimary
                      text={step === 1 ? 'Next' : 'Sign Up'}
                      onClick={step === 1 ? onSubmitEmail : onSubmitFull}
                      color="dark"
                      className="w-full"
                      isLoading={isLoading}
                    />
                  </div>
                </form>
                <div className="pt-5 mt-6 border-t border-[#f0ece7]">
                  <div className="text-sm text-center">
                    Have an account?{' '}
                    <Link className="font-medium text-nomadBlue hover:underline" to="/signin">
                      Sign In
                    </Link>
                  </div>
                </div>
              </motion.div>
            )}
          </motion.div>
        </main>
      </div>
      
     {/* Right side - Background Image and Logo */}
     <div className="hidden lg:block relative w-1/2 bg-[#20373b]">
       
        <div className="absolute inset-0 flex flex-col items-center justify-between p-8 pb-2">
          <div className="w-full flex flex-col items-center mt-40">
            <img
              src="https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/ab92d013-3bbb-42b9-7040-6d91875e6800/public"
              alt="Hornbill Agent Platform"
              className="w-80 mb-16"
            />
            <p className="text-white text-xl mb-8 max-w-md text-center shadow-text">
              A REVOLUTIONARY PLATFORM HELPING SAFARI SPECIALISTS
              BUILD INSPIRATIONAL SAFARIS
            </p>
          </div>
          <div className="text-center">
            <p className="text-white text-md">
              PROVIDED AND OPERATED BY
            </p>
            <img
              src="https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/b77d6736-4f33-4f25-e69f-64e304b00b00/public"
              alt="Nomad DMC"
              className="w-32 mx-auto mt-2"
            />
          </div>
        </div>
      </div>

       {/* Application Modal */}
       <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Apply for Access
                  </Dialog.Title>
                  <form onSubmit={submitApplication} className="mt-4 space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Full Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-nomadBlue focus:ring-nomadBlue sm:text-sm"
                        value={applicationForm.name}
                        onChange={handleApplicationChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-nomadBlue focus:ring-nomadBlue sm:text-sm"
                        value={applicationForm.email}
                        onChange={handleApplicationChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">
                        Company Name
                      </label>
                      <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-nomadBlue focus:ring-nomadBlue sm:text-sm"
                        value={applicationForm.companyName}
                        onChange={handleApplicationChange}
                      />
                    </div>
                    <div className="mt-6">
                      <ButtonPrimary
                        text="Submit Application"
                        type="submit"
                        color="dark"
                        className="w-full"
                      />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default Signup;