import { Configure, Hits, InstantSearch, Pagination, connectHits, connectSearchBox } from 'react-instantsearch-dom';
import React, { useEffect, useRef, useState } from 'react';

import { Autocomplete } from '../components/Autocomplete';
import { BookedDirectItem } from '../components/BookedDirectItem';
import CustomModal from '../components//CustomModal';
import SearchBox from '../components/SearchBox';
import algoliasearch from 'algoliasearch';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { v4 as uuidv4 } from 'uuid';

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
export const INSTANT_SEARCH_INDEX_NAME = 'properties';

const searchClient = algoliasearch(appId, apiKey);

const BookedDirect = ({ hit, properties, SetGetPropertiesAndStock, setCallMakeRooms, setFetchCustom }) => {
  // state
  const [selectedPropertyName, setSelectedPropertyName] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(false);
  const [keyProperties, setKeyProperties] = useState(Date.now());

  // useEffects

  // Search
  const VirtualSearchBox = connectSearchBox(() => null);
  function onReset() {
    setSelectedPropertyName(false);
    setSelectedProperty(false);
  }

  function AddProperty(stock, num, updatedSelectedProperty) {
    // If the value of stock is truthy, then push the hit object into the result array of the properties object
    if (stock) {
      const fetch = properties;
      const property = updatedSelectedProperty;
      // Filter out inactive rooms
      property.rooms = property.rooms.filter((room) => room.active);
      fetch.push(property);
      setFetchCustom(true);
    }

    // Call the SetGetPropertiesAndStock function with the value of stock as an argument
    SetGetPropertiesAndStock(stock);

    // Call the setCallMakeRooms function with the value of num as an argument
    setCallMakeRooms(num);
    console.log('add');
  }

  return (
    <div key={uuidv4()}>
      <InstantSearch searchClient={searchClient} indexName={INSTANT_SEARCH_INDEX_NAME}>
        <VirtualSearchBox />

        <Autocomplete
          key={keyProperties}
          placeholder="Search properties"
          detachedMediaQuery="none"
          initialState={{
            query: !selectedPropertyName ? '' : selectedPropertyName,
          }}
          openOnFocus={true}
          onReset={onReset}
          getSources={({ query }) => [
            {
              sourceId: 'name',
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: 'properties',
                      query,
                      params: {
                        filters: 'availableForBookedDirect:true',
                      },
                    },
                  ],
                });
              },
              templates: {
                item({ item, components }) {
                  return (
                    <BookedDirectItem
                      hit={item}
                      components={components}
                      selectedPropertyName={selectedPropertyName}
                      setSelectedPropertyName={setSelectedPropertyName}
                      setSelectedProperty={setSelectedProperty}
                    />
                  );
                },
              },
            },
          ]}
        />
      </InstantSearch>
      {selectedPropertyName ? (
        <div>
          {/* <p className="text-sm font-semibold pb-2 mt-5">Selected property:</p>
          <p className="text-sm font-normal pb-2 w-72">{selectedPropertyName}</p> */}
          <div className="flex mt-5 justify-center">
            <div>
              <button
                className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                onClick={async () => {
                  //setModalOpen(false);
                  console.log('selectedProperty: ' + selectedProperty.name);
                  console.log(JSON.stringify(selectedProperty, undefined, 4));

                  // redefine property - start
                  // Your static newRooms, newPlatformResRequest
                  const newRooms = [
                    {
                      min_adults: 1,
                      img: 'https://firebasestorage.googleapis.com/v0/b/nomadhornbill.appspot.com/o/images%2Fproperties%2Frooms%2FGREYSTOKE%20MAHALE%20-%20bandas.jpeg?alt=media&token=9a511458-33aa-41c5-97b9-42d86010f6bb',
                      max_capacity: 100,
                      name: 'Pick me',
                      active: true,
                      max_adults: 100,
                      id: '',
                      type: 'Room',
                    },
                  ];

                  const newPlatformResRequest = {
                    linkId: '',
                    rates: false,
                    active: false,
                    principalId: '',
                    id: '',
                    createBooking: false,
                  };

                  const newSupplier = {
                    name: 'Booked Direct',
                    id: '8ae5bed6-e15e-4e79-8ab0-e934c2645abc',
                  };

                  const newRates = {
                    fullBoard: false,
                    fullBoardExclusive: true,
                    gamePackage: false,
                    gamePackageExclusive: true,
                  };

                  const newThirdPartyRates = [
                    {
                      creatioUid: 'c61b4fe4-89d6-4255-be4f-3f32428b95a9',
                      rateBand: '',
                      name: 'Own Arrangment',
                      active: true,
                      id: '',
                      type: 'fullBoard',
                    },
                  ];

                  // Create a new object with the updated and added properties
                  const updatedSelectedProperty = {
                    ...selectedProperty,
                    rooms: newRooms,
                    platformResRequest: newPlatformResRequest,
                    supplier: newSupplier,
                    rates: newRates,
                    thirdPartyRates: newThirdPartyRates,
                    propertyType: '',
                    minNights: 1,
                    northernGuide: false,
                    vehicles: false,
                    bookedDirect: true,
                    uid: `${selectedProperty.uid}_D-I-R-E-C-T_${uuidv4()}`,
                    originalUid: selectedProperty.uid,
                  };

                  // redefine property - end

                  console.log('Updated property: ' + updatedSelectedProperty.uid);
                  console.log(JSON.stringify(updatedSelectedProperty, undefined, 4));

                  // define where to add
                  if (properties.length === 0) {
                    AddProperty(true, 0, updatedSelectedProperty);
                    console.log('first');

                    return null;
                  } else {
                    AddProperty(true, properties.length, updatedSelectedProperty);
                    return null;
                  }
                }}
              >
                Add property to itinerary
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BookedDirect;
