import Select, { components } from 'react-select';

import React from 'react';

const CustomValueContainer = ({ children, ...props }) => {
  const iconContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '8px',
  };

  const childrenWithoutPlaceholder = React.Children.toArray(children).filter((child) => child.type !== components.Placeholder);

  return (
    <components.ValueContainer {...props}>
      <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
        {props.selectProps.icon && <div style={iconContainerStyle}>{props.selectProps.icon}</div>}
        {childrenWithoutPlaceholder}
      </div>
    </components.ValueContainer>
  );
};

const CustomSelect = ({
  value,
  defaultValue,
  onChange,
  options,
  placeholder,
  instanceId,
  icon,
  width,
  menuHeight = 200,
  isSearchable = true,
  showDropdownIndicator = true,
  customStyles,
  customComponents = {},
  isDisabled = false,
  color1 = '#1D253F', // Default color1
  color2 = '#7F7F7F', // Default color2
  color3 = '#000000', // Default color3
  color4 = '#FFFFFF', // Default color4
  ...props
}) => {
  const defaultStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '50px',
      borderRadius: '8px',
      border: state.isFocused ? `1px solid ${color1}` : `1px solid ${color2}`,
      boxShadow: state.isFocused ? `0 0 0 1px ${color1}` : 'none',
      width: width ? `${width}px` : '100%',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '&:hover': {
        border: `1px solid ${color1}`,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      color: state.isSelected ? color4 : color3,
      backgroundColor: state.isSelected ? color1 : color4,
      ':hover': {
        backgroundColor: color1,
        color: color4,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
      width: width ? `${width}px` : '100%', // Adjust width dynamically
      height: `${menuHeight}px`,
    }),
  };

  const styles = customStyles ? customStyles : defaultStyles;
  const mergedComponents = {
    ValueContainer: CustomValueContainer,
    DropdownIndicator: showDropdownIndicator ? components.DropdownIndicator : null,
    ...customComponents,
  };

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      isSearchable={isSearchable}
      maxMenuHeight={menuHeight}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      value={value}
      defaultValue={defaultValue}
      styles={styles}
      instanceId={instanceId}
      components={mergedComponents}
      isDisabled={isDisabled}
      icon={icon}
      {...props}
    />
  );
};

export default CustomSelect;
