import { agentEnquiry, agentSelect } from '../functions/itineraryFunctions';
export function AgentItem({
  hit,
  components,
  setSelectedAgentName,
  setSelectedAgentUid,
  consultants,
  setConsultants,
  setSelectedAgent,
  setSelectedAgentItems,
  setSelectedConsultant,
  setSelectedConsultantName,
  setConsultantResRequestId,
  setSelectedConsultantUid,
  setRateName,
  setRateId,
  setAgentResRequestId,
  setAgentRates,
  setRefreshRatesEffect,
  setAgentCommission,
  setAgentObject,
  bookings,
  setBookings,
  setTransfers,
  setTransfersLatest,
  user,
}) {
  return (
    <div
      className=" z-20"
      onClick={(event) => {
        console.log('clicked: ' + hit.name + ' ' + hit.uid);
        console.log('Rateband: ' + hit.rateBand.id + ' ' + hit.rateBand.name);

        let rateBandName = hit.rateBand.name;
        let rateBandId = hit.rateBand.id;
        let rateBandCommission = hit.rateBand.commission;
        if (user.internalUser) {
          rateBandName = hit.rateBandOffline.name;
          rateBandId = hit.rateBandOffline.id;
        }
        setRateName(rateBandName);
        setRateId(rateBandId);
        setSelectedAgentName(hit.name);
        setSelectedAgentUid(hit.uid);
        setAgentResRequestId(hit.resRequestId);
        setAgentCommission(rateBandCommission);
        setAgentObject(hit);
        // console.log('agentObject hit');
        // console.log(JSON.stringify(hit, undefined, 4));
        // if (!searchEnquiries) {
        //   agentSelect(
        //     hit.uid,
        //     consultants,
        //     setConsultants,
        //     setSelectedAgent,
        //     setSelectedAgentItems,
        //     setSelectedConsultant,
        //     setSelectedConsultantName,
        //     hit.rateBand.id,
        //     setAgentRates,
        //     setSelectedConsultantUid,
        //     setConsultantResRequestId,
        //     hit.companyId
        //   );
        //} else {
        agentEnquiry(rateBandId, setAgentRates, hit, bookings, setBookings);
        //setSelectedAgent((agentUid) => [...hit.uid]);
        //   //agentUid(hit.uid, consultants, setSelectedAgent);
        //}
        setTransfers([]); // clear transfers
        setTransfersLatest([]); // clear transfers
        setRefreshRatesEffect(true);
      }}
    >
      <button className="aa-ItemActionButton">
        {/* <a href={hit.url} className="aa-ItemLink"> */}
        <div className="aa-ItemContent">
          <div className="aa-ItemTitle text-left">
            <components.Highlight hit={hit} attribute="name" />
          </div>
        </div>
        {/* </a> */}
        {/* Show name and email
        
        <a href={hit.url} className="aa-ItemLink">
          <div className="aa-ItemContent">
            <div className="aa-ItemTitle">
              <div style={{ textAlign: "left" }}>{hit.name}</div>
              <div style={{ textAlign: "left" }}>({hit.email})</div>
            </div>
          </div>
        </a> */}
      </button>
    </div>
  );
}
