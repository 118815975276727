import { MdClose, MdOutlineSearch } from "react-icons/md";

import { connectSearchBox } from "react-instantsearch-dom";

const SearchBox = ({ refine, currentRefinement, onChange }) => (
  <form>
    <div className={`relative`}>
      <MdOutlineSearch size={26} className="absolute left-2 top-2" />
      <input
        type="text"
        value={currentRefinement}
        className={`pl-10 w-full rounded border-slate-200`}
        placeholder="Search..."
        onChange={(event) => {
          refine(event.currentTarget.value);
          onChange(event);
        }}
      />
      <MdClose
        size={26}
        className={`absolute right-2 top-2 cursor-pointer`}
        onClick={() => {
          refine("");
          onChange({ currentTarget: { value: "" } });
        }}
      />
    </div>
  </form>
);

export default connectSearchBox(SearchBox);
