import { FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdConnectingAirports, MdContentCopy, MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { TbArrowsRandom, TbBuildingWarehouse } from 'react-icons/tb';
import { addItem, deleteItem, handleCut, handlePaste } from '../functions/finalCustomFunctions';

import { FaTrashAlt } from 'react-icons/fa';
import FinalFoundItemCard from './FinalFoundItem';
import { GiJeep } from 'react-icons/gi';
import { formatPrice } from '../functions/generalFunctions';
import { getPropObj } from '../functions/generalFunctions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const FinalDropPickup = ({
  item,
  bookings,
  booking,
  airports,
  pickupDropoff,
  customFinalItems,
  setCustomFinalItems,
  customFinalCut,
  setCustomFinalCut,
  handleOpenSlideOut,
  setCustomItemToEdit,
  setAddFinalItemDetails,
  properties,
  transfers,
  shouldCollapse,
  firstDay,
  setFinalSlideOutDate,
}) => {
  // USER
  const { user } = useSelector((state) => state.auth);
  let airportTo;
  let airportFrom;
  let airportToCode;
  let airportFromCode;
  if (item.formValues.type === 'ownArrangements') {
    airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(item.formValues.destinationAirportCode));
    airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(item.formValues.originAirportCode));
    airportToCode = item.formValues.destinationAirportCode;
    airportFromCode = item.formValues.originAirportCode;
  } else {
    let flight;
    let flightSecond;
    if (item.formValues.flightType === 'Multiple' && item.objType === 'arrival') {
      flight = item.formValues.flightDetailsSecond;
      airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flight.tocode));
      airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flight.fromcode));
      airportToCode = flight.tocode;
      airportFromCode = flight.tocode;
    } else if (item.formValues.flightType === 'Multiple' && item.objType === 'propertyTransfer') {
      flight = item.formValues.flightDetails;
      flightSecond = item.formValues.flightDetailsSecond;
      airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightSecond.tocode));
      airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flight.fromcode));
      airportToCode = flightSecond.tocode;
      airportFromCode = flight.fromcode;
    } else if (item.formValues.flightType === 'Multiple' && item.objType === 'departure') {
      flight = item.formValues.flightDetails;
      airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flight.tocode));
      airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flight.fromcode));
      airportToCode = flight.tocode;
      airportFromCode = flight.fromcode;
    } else {
      flight = item.formValues.flightDetails;
      airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flight.tocode));
      airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flight.fromcode));
      airportToCode = item.formValues.flightDetails.tocode;
      airportFromCode = item.formValues.flightDetails.fromcode;
    }
  }

  const getNextBookingOrSame = (bookings, bookingId) => {
    const index = bookings.findIndex((booking) => booking.id === bookingId);

    if (index === -1) {
      throw new Error('Booking not found');
    } else if (index < bookings.length - 1) {
      return bookings[index + 1];
    } else {
      return bookings[index];
    }
  };

  const bookingResult = getNextBookingOrSame(bookings, booking.id);

  const pickupFrom = airportTo.name;
  const dropoffTo = airportFrom.name;
  const arrivalTo = booking.propName;
  const arrivalToNext = bookingResult.propName;

  let transferUnits = 1;

  if (item.objType === 'arrival') {
    transferUnits = item.formValues.arrivalTransferUnits;
  } else if (item.objType === 'departure') {
    transferUnits = item.formValues.departureTransferUnits;
  } else if (item.objType === 'propertyTransfer') {
    if (pickupDropoff === 'pickup') {
      transferUnits = item.formValues.arrivalTransferUnits;
    } else if (pickupDropoff === 'dropoff') {
      transferUnits = item.formValues.departureTransferUnits;
    }
  }

  let searchId = item.tuuid;
  const foundItems = customFinalItems.filter((search) => search.id === searchId && !search.departure && search.pickupDropoff === pickupDropoff);

  //const foundItemsDeparture = customFinalItems.filter((search) => search.id === searchId && search.departure && search.pickupDropoff === pickupDropoff);

  const [localItem, setLocalItem] = useState({ ...item, collapsed: shouldCollapse }); // Initialize localItem with item prop and set collapsed to true

  useEffect(() => {
    // If you need to respond to changes in the item prop, update localItem here
    setLocalItem((current) => ({ ...current, ...item, collapsed: current.collapsed }));
  }, [item]);

  function getTransferAndNextIfNotDeparture(transfers, tuuidToFind) {
    // Find the index of the transfer in the transfers array where the tuuid matches tuuidToFind
    const index = transfers.findIndex((transfer) => transfer.tuuid === tuuidToFind);

    // Initialize an object to hold the current and next transfer if applicable
    const result = {
      currentTransfer: null,
      nextTransfer: null,
    };

    // If the transfer was found (index !== -1), set the current transfer
    if (index !== -1) {
      result.currentTransfer = transfers[index];

      // Check if there is a next transfer and if its objType is not 'departure'
      if (index + 1 < transfers.length && transfers[index + 1].objType !== 'departure') {
        result.nextTransfer = transfers[index + 1];
      }
    }

    // Return the result object
    return result;
  }

  // DEAL WITH HEADER
  const transfersResult = getTransferAndNextIfNotDeparture(transfers, item.tuuid);

  const transfer = transfersResult.currentTransfer;
  const nextTransfer = transfersResult.nextTransfer;

  let transferMessage;
  // ARRIVAL
  if (item.objType === 'arrival') {
    transferMessage = `Airport transfer from ${pickupFrom} (${airportFromCode}) to ${properties[getPropObj(item.propUid, properties)].name}`;
    // PROPERTY TRANSFER
  } else if (item.objType === 'propertyTransfer') {
    if (pickupDropoff === 'dropoff') {
      transferMessage = `Airport transfer from ${properties[getPropObj(item.propUid, properties)].name} to ${dropoffTo} (${airportFromCode})`;
    } else if (pickupDropoff === 'pickup') {
      transferMessage = `Airport transfer from ${pickupFrom} (${airportToCode}) to ${properties[getPropObj(nextTransfer.propUid, properties)].name}`;
    }
    // DEPARTURE
  } else if (item.objType === 'departure') {
    transferMessage = `Airport transfer from ${properties[getPropObj(item.propUid, properties)].name} to ${dropoffTo} (${airportFromCode})`;
  }

  // DAY
  const firstDayMoment = moment(firstDay, 'YYYY-MM-DD');
  const dayMoment = moment(item.endDay, 'YYYY-MM-DD');

  let day = 1;
  if (item.objType === 'arrival') {
    day = firstDay === item.startDay ? 1 : dayMoment.diff(firstDayMoment, 'days');
  } else {
    day = firstDay === item.endDay ? 1 : dayMoment.diff(firstDayMoment, 'days') + 1;
  }

  console.log('day', day);
  console.log('firstDay', firstDay);
  console.log('item.startDay', item.startDay);
  let parentDate = item.objType === 'arrival' ? item.startDay : item.endDay;

  return (
    <>
      {/* found items */}
      {foundItems.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`bg-[rgba(42,153,163,0.1)] border border-slate-200 brand-text-grey-v2 mb-7 
                   ${customFinalCut && customFinalCut.uuid === foundItem.uuid ? 'animate-pulse' : ''}`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <FinalFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalCut={customFinalCut}
            handlePaste={handlePaste}
            addItem={addItem}
            handleCut={handleCut}
            deleteItem={deleteItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            setCustomFinalCut={setCustomFinalCut}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            handleOpenSlideOut={handleOpenSlideOut}
            setCustomItemToEdit={setCustomItemToEdit}
            setAddFinalItemDetails={setAddFinalItemDetails}
            formatPrice={formatPrice}
            firstDay={firstDay}
            setFinalSlideOutDate={setFinalSlideOutDate}
          />
        </div>
      ))}

      {/* main items */}
      <div
        className={`bg-white border border-slate-200 brand-text-grey-v2 mb-7`}
        style={{
          WebkitTransform: 'translateZ(0)' /* Safari */,
        }}
      >
        {/* add / paste top */}
        {user.internalUser && (
          <div className="fixed -top-7 left-0 right-0 flex justify-center group">
            {customFinalCut ? (
              <button
                className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                onClick={() => handlePaste('bottom', null, customFinalCut, customFinalItems, searchId, pickupDropoff, setCustomFinalItems, setCustomFinalCut, parentDate, false)}
                disabled={!customFinalCut}
              >
                <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-right text-nomadEvergreen-700`}>
                  <span className="mr-1.5">Paste item</span>
                  <FaRegPaste size={16} />
                </span>
              </button>
            ) : (
              <button
                className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                onClick={() => {
                  setFinalSlideOutDate(item.objType === 'arrival' ? item.startDay : item.endDay);
                  //setFinalSlideOutDate(item.endDay);
                  addItem('bottom', null, searchId, pickupDropoff, handleOpenSlideOut, setAddFinalItemDetails, false);
                }}
              >
                <span className={`text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-center text-nomadEvergreen-700`}>+ Add Item</span>
              </button>
            )}
          </div>
        )}

        {/* DAY */}
        {/* <div className="fixed top-4 -left-12 flex -rotate-90"> */}
        <div className="fixed -top-6 -left-0 flex">
          <div className="text-left" style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}>
            {/* <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-right text-nomadEvergreen-700`}> */}
            <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-0 pr-3 py-0.5 text-right text-nomadEvergreen-700`}>
              <span className="tracking-widest">DAY {day}</span>
              {/* <FaRegPaste size={16} /> */}
            </span>
          </div>
        </div>

        <div className={`mb-0`}>
          <div className={`grid grid-cols-12`}>
            {/* IMG */}

            {/* ROOM NAME + DESCRIPTION */}
            <div className="col-span-12 bg-white">
              {/* Number of days */}
              <div
                className="text-right fixed top-4 right-0"
                style={{
                  WebkitTransform: 'translateZ(0)',
                  perspective: '1000' /* Safari */,
                }}
              ></div>
              {/* Property name */}
              <div className="grid grid-cols-12 border-b border-slate-200">
                <div className="text-xs font-medium truncate text-ellipsis overflow-hidden col-span-10 ml-2 border-r border-slate-200 py-2">
                  <div className="flex items-center">
                    {/* {!item.collapsed ? (
                    <IoIosArrowDropupCircle
                      size={18}
                      className="text-nomadEvergreen-700 cursor-pointer	"
                      onClick={() => toggleFinalTransferCollapse(item.tuuid, finalPageTransferData, resetFinalTransferInputs, setFinalPageTransferData, setFinalInputs)} // add this line
                    />
                  ) : (
                    <IoIosArrowDropdownCircle
                      size={18}
                      className="text-nomadEvergreen-700 cursor-pointer	"
                      onClick={() => toggleFinalTransferCollapse(item.tuuid, finalPageTransferData, resetFinalTransferInputs, setFinalPageTransferData, setFinalInputs)} // add this line
                    />
                  )} */}
                    {/* ICON AND CIRCLE */}
                    <div className="row-span-1 relative">
                      <div className="ml-0 mr-2 object-cover w-10 h-10 rounded-full bg-slate-200 flex justify-center items-center">
                        <GiJeep size={30} />
                      </div>
                    </div>
                    {/* <MdConnectingAirports size={30} /> */}
                    <div className="ml-2 flex flex-col">
                      <span className="flex">
                        <span className="text-base w-14">
                          {item.objType === 'arrival' && `${moment(item.startDay).format('MMM D')}`}

                          {item.objType === 'propertyTransfer' && pickupDropoff === 'dropoff' && `${moment(item.endDay).format('MMM D')}`}

                          {item.objType === 'propertyTransfer' && pickupDropoff === 'pickup' && `${moment(item.endDay).format('MMM D')}`}

                          {item.objType === 'departure' && `${moment(item.endDay).format('MMM D')}`}
                        </span>
                        <span className={`text-base text-black font-semibold ml-5`}>{transferMessage}</span>
                      </span>

                      <span className="flex">
                        <span className="w-14">
                          {item.objType === 'arrival' && `${moment(item.startDay).format('ddd')}`}

                          {item.objType === 'propertyTransfer' && pickupDropoff === 'dropoff' && `${moment(item.endDay).format('ddd')}`}

                          {item.objType === 'propertyTransfer' && pickupDropoff === 'pickup' && `${moment(item.endDay).format('ddd')}`}

                          {item.objType === 'departure' && `${moment(item.endDay).format('ddd')}`}
                        </span>
                        <span className="ml-5">
                          {pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSupplierName : item.formValues.departureTransferSupplierName} |{' '}
                          {item.objType === 'arrival' && `${moment(item.startDay).format('Do MMM YYYY')}`}
                          {item.objType === 'propertyTransfer' && pickupDropoff === 'dropoff' && `${moment(item.endDay).format('Do MMM YYYY')}`}
                          {item.objType === 'propertyTransfer' && pickupDropoff === 'pickup' && `${moment(item.endDay).format('Do MMM YYYY')}`}
                          {item.objType === 'departure' && `Dropoff to ${dropoffTo} airport from ${arrivalTo} - ${moment(item.endDay).format('Do MMM YYYY')}`}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 text-xs  border-r border-slate-200 py-2 text-nomadBlue-500 flex items-center justify-center">
                  ${formatPrice(pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSaleTotal : item.formValues.departureTransferSaleTotal)}
                </div>
                <div className="col-span-1 py-2 flex justify-center items-center">
                  {!localItem.collapsed ? (
                    <IoIosArrowUp size={20} className={`cursor-pointer mt-1 text-nomadEvergreen-700`} onClick={() => setLocalItem({ ...localItem, collapsed: !localItem.collapsed })} />
                  ) : (
                    <IoIosArrowDown size={20} className={`cursor-pointer mt-1 text-nomadEvergreen-700`} onClick={() => setLocalItem({ ...localItem, collapsed: !localItem.collapsed })} />
                  )}
                </div>
              </div>

              {/* Column headers*/}
              {/* TODO Add description column*/}

              {!localItem.collapsed && (
                <div className={`grid grid-cols-12 text-xs font-medium border-b border-slate-200`}>
                  <div className="col-span-1 px-2 border-r border-slate-200 py-2 text-black">FEE TYPE</div>
                  <div className="col-span-2 ml-5 border-r border-slate-200 py-2 text-black">SUPPLIER</div>
                  <div className="col-span-4">
                    <div className={`grid grid-cols-12 text-xs`}>
                      <div className="col-span-11 ml-5 border-r border-slate-200 py-2 text-black">DESCRIPTION</div>
                      <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center"></div>
                    </div>
                  </div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">
                    <span className="block">NTS/UNITS</span>
                  </div>

                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">
                    <span className="block">PAX/UNITS</span>
                  </div>
                  <div className="col-span-1 ml-4 border-r border-slate-200 py-2 text-black">NET RATE ($)</div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">TOTAL</div>
                  <div className="col-span-1 ml-5 py-2"></div>
                </div>
              )}

              {/* Column propertyItems*/}
              {!localItem.collapsed && (
                <div key={uuidv4()} className={`grid grid-cols-12 text-xs border-b border-slate-200`}>
                  <div className="col-span-1 px-2 border-r border-slate-200 py-2">{pickupDropoff === 'pickup' ? 'Pickup' : 'Dropoff'}</div>
                  <div className="col-span-2 ml-5 border-r border-slate-200 py-2">
                    {pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSupplierName : item.formValues.departureTransferSupplierName}
                  </div>
                  <div className="col-span-4">
                    <div className={`grid grid-cols-12 text-xs`}>
                      <div className="col-span-11 ml-5 border-r border-slate-200 py-2 pr-5">{pickupDropoff === 'pickup' ? 'Pickup airport transfer' : 'Dropoff airport transfer'}</div>
                      <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group"></div>
                      {/* <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center">{propertyItem.star}</div> */}
                    </div>
                  </div>
                  {/* <div className="col-span-5 ml-5 border-r border-slate-200 py-2">{propertyItem.description}</div> */}
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{transferUnits}</div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{item.formValues.totalAllPax}</div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2">
                    ${formatPrice(pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSaleTotal : item.formValues.departureTransferSaleTotal)}
                  </div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2">
                    {' '}
                    ${formatPrice(pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSaleTotal : item.formValues.departureTransferSaleTotal)}
                  </div>
                  <div className="col-span-1 ml-5 py-2 flex items-center"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinalDropPickup;
