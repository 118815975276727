import React from 'react';
import { Tab } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TabbedLayout = ({ children }) => {
  const validChildren = React.Children.toArray(children).filter(child => child);

  const tabs = validChildren.map((child, index) => ({
    name: child.props.tabName || `Tab ${index + 1}`,
    icon: child.props.icon,
    component: child,
  }));

  return (
    <div className="w-full bg-white rounded-lg shadow-md overflow-hidden">
      <Tab.Group>
        <Tab.List className="flex space-x-1 mb-8 rounded-xl bg-white shadow-md">
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-4 text-sm font-medium leading-5 flex items-center justify-center',
                  selected
                    ? 'bg-[#073937] text-white shadow'
                    : 'text-[#3e3d3d] hover:bg-gray-100'
                )
              }
            >
              <div className="flex items-center justify-center space-x-2">
                {tab.icon && (
                  <tab.icon
                    className={classNames(
                      'h-5 w-5'
                    )}
                    aria-hidden="true"
                  />
                )}
                <span>{tab.name}</span>
              </div>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {tabs.map((tab, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                'bg-white p-4',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60'
              )}
            >
              {tab.component}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default TabbedLayout;