import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropertyContent from '../pages/portal/propertyLibrary/components/PropertyContent';
import { fetchPropertyData } from '../pages/portal/functions/propertyFunctions';

function FullScreenPropertyModal({ isOpen, onClose, propertyUid }) {
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadPropertyData() {
      if (!isOpen) return;
      try {
        setLoading(true);
        const data = await fetchPropertyData(propertyUid);
        setPropertyData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    loadPropertyData();
  }, [isOpen, propertyUid]);

  if (!isOpen) return null;

  const modalContent = (
    <div 
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
      onClick={onClose}
    >
      <div 
        className="relative top-20 mx-auto p-5 border w-full max-w-7xl shadow-lg rounded-md bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center pb-3">
          <h3 className="text-lg font-medium">Property Details</h3>
          <button onClick={onClose} className="text-black close-modal">×</button>
        </div>
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}
        {propertyData && <PropertyContent propertyData={propertyData} />}
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.body
  );
}

export default FullScreenPropertyModal;