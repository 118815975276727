import { IoAirplaneOutline } from 'react-icons/io5';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { PiTentLight } from 'react-icons/pi';
import { PiVanLight } from 'react-icons/pi';
import React from 'react';
import { getPropObj } from '../functions/generalFunctions';

const TransferOptions = ({
  transfer,
  nextTransfer,
  properties,
  arrivalOrDeparture,
  includeArrivalTransferCheck,
  setIncludeArrivalTransferCheck,
  includeDepartureTransferCheck,
  setIncludeDepartureTransferCheck,
}) => {
  console.log('transfer checked: ', JSON.stringify(transfer, null, 2));
  let checkedValue = true;
  // if (transfer.formValues && transfer.edit) {
  //   if (arrivalOrDeparture === 'arrival') {
  //     checkedValue = transfer.formValues?.includeArrivalTransfer === true ? true : false;
  //     console.log('checkedValue arrival: ' + checkedValue);
  //   } else {
  //     checkedValue = transfer.formValues?.includeDepartureTransfer === true ? true : false;
  //     console.log('checkedValue departure: ' + checkedValue);
  //   }
  // }
  if (transfer.formValues) {
    if (arrivalOrDeparture === 'arrival') {
      checkedValue = transfer.formValues.includeArrivalTransfer !== undefined ? transfer.formValues.includeArrivalTransfer : true;
      console.log('checkedValue arrival: ' + checkedValue);
    } else {
      checkedValue = transfer.formValues.includeDepartureTransfer !== undefined ? transfer.formValues.includeDepartureTransfer : true;
      console.log('checkedValue departure: ' + checkedValue);
    }
  }
  return (
    <div className={`col-span-4 flex flex-col items-left p-4 bg-white rounded-lg border shadow-sm h-full`}>
      <div className={``}>
        <div className="flex items-center">
          <PiVanLight size={30} className="text-black" />
          <IoIosArrowRoundForward size={20} className="text-cyan-600 mx-1" />
          {arrivalOrDeparture === 'arrival' ? <PiTentLight size={30} className="text-black" /> : <IoAirplaneOutline size={30} className="text-black" />}

          <div className="ml-auto">
            <input
              type="checkbox"
              name={arrivalOrDeparture === 'arrival' ? 'includeArrivalTransfer' : 'includeDepartureTransfer'}
              //className="checkbox checkbox-accent"
              className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]" 
              defaultChecked={arrivalOrDeparture === 'arrival' ? includeArrivalTransferCheck : includeDepartureTransferCheck}
              onChange={(e) => {
                console.log('Checkbox changed:', e.target.checked);
                if (arrivalOrDeparture === 'arrival') {
                  setIncludeArrivalTransferCheck(e.target.checked);
                } else {
                  setIncludeDepartureTransferCheck(e.target.checked);
                }
              }}
            />
          </div>
        </div>

        <div className="text-stone-950 text-[15px] font-semibold my-3">{arrivalOrDeparture === 'arrival' ? 'Transfer to Property' : 'Transfer to airport'}</div>
        {/* <div className="text-black text-sm font-normal mt-5"></div> */}
        {arrivalOrDeparture === 'arrival' ? (
          <span className="text-sm text-neutral-500">Transfer from arrival airport to {properties[getPropObj(nextTransfer.propUid, properties)].name}</span>
        ) : (
          <span className="text-sm text-neutral-500">Transfer to departure airport from {properties[getPropObj(transfer.propUid, properties)].name}</span>
        )}
      </div>
    </div>
  );
};

const TransferArrivalOrDeparture = ({
  transfer,
  nextTransfer,
  properties,
  arrivalOrDeparture,
  includeArrivalTransferCheck,
  setIncludeArrivalTransferCheck,
  includeDepartureTransferCheck,
  setIncludeDepartureTransferCheck,
}) => {
  return (
    <TransferOptions
      transfer={transfer}
      nextTransfer={nextTransfer}
      properties={properties}
      arrivalOrDeparture={arrivalOrDeparture}
      includeArrivalTransferCheck={includeArrivalTransferCheck}
      setIncludeArrivalTransferCheck={setIncludeArrivalTransferCheck}
      includeDepartureTransferCheck={includeDepartureTransferCheck}
      setIncludeDepartureTransferCheck={setIncludeDepartureTransferCheck}
    />
  );
};

export default TransferArrivalOrDeparture;
