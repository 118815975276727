import React from 'react';
import { MdNightsStay } from 'react-icons/md';
import moment from 'moment';

const NightCount = ({ bookings }) => {
  const totalNights = moment(bookings[bookings.length - 1].endDay).diff(moment(bookings[0].startDay), 'days');

  return (
    <div className="flex text-left mt-5">
      <MdNightsStay size={20} />
      <div className="ml-2 text-zinc-800 text-sm font-normal">
        {totalNights} Night{totalNights > 1 ? 's' : ''}
      </div>
    </div>
  );
};

export default NightCount;
