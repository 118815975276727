import { clearSelectedCompany, fetchCompanies } from '../companies/companiesSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import authService from './authService';
import { setInitialSelectedCompany } from '../companies/companiesSlice';

// Get user from local storage
const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  intendedUrl: null,
  userAllowed: null,
  userExistenceResult: null,
};

// Register new user
export const register = createAsyncThunk('auth/register', async (userData, thunkAPI) => {
  try {
    return await authService.register(userData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    const userData = await authService.login(user);

     // Clear companies state before fetching new data
     thunkAPI.dispatch(clearSelectedCompany());

    // Dispatch fetchCompanies with the correct parameters
    thunkAPI.dispatch(
      fetchCompanies({
        userUid: userData.uid,
        userCompanyId: userData.consultant?.companyId,
        forceRefresh: true,
      })
    );

    return userData;
  } catch (error) {
    const message = error.message || 'Invalid details';
    return thunkAPI.rejectWithValue(message);
  }
});

// Logout users
export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  await authService.logout();
  // Clear companies state on logout
  thunkAPI.dispatch(clearSelectedCompany());
});

// Update user
export const updateUser = createAsyncThunk('auth/updateUser', async (formData, thunkAPI) => {
  try {
    return await authService.updateUser(formData);
  } catch (error) {
    const message = 'Could not update details.';
    return thunkAPI.rejectWithValue(message);
  }
});

// Forgot password
export const forgotPassword = createAsyncThunk('auth/forgot', async (formData, thunkAPI) => {
  try {
    return await authService.forgotPassword(formData);
  } catch (error) {
    //const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const message = 'Could not send reset email.';
    return thunkAPI.rejectWithValue(message);
  }
});

export const setIntendedUrl = createAsyncThunk('auth/setIntendedUrl', async (url, thunkAPI) => {
  return url;
});

// Check if user is allowed to sign up
export const checkUserExistence = createAsyncThunk(
  'auth/checkUserExistence',
  async (userData, thunkAPI) => {
    try {
      const result = await authService.checkUserExistence(userData);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Add the new async thunk action
export const updateEarlyAccessRequested = createAsyncThunk(
  'auth/updateEarlyAccessRequested',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uid = state.auth.user.uid;
      const updatedUser = await authService.updateEarlyAccessRequested(uid);
      return updatedUser;
    } catch (error) {
      const message = error.message || 'Failed to update early access request.';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        //state.user = action.payload;
        state.user = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload || 'An error occurred during login';
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        //state.user = null;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(setIntendedUrl.fulfilled, (state, action) => {
        state.intendedUrl = action.payload;
      })
      .addCase(checkUserExistence.pending, (state) => {
        state.isLoading = true;
        state.userAllowed = null;
      })
      .addCase(checkUserExistence.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userAllowed = true;
        state.userExistenceResult = action.payload;
      })
      .addCase(checkUserExistence.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = false; // Change this to false
        state.message = ''; // Set this to an empty string
        state.userAllowed = false;
      })
      .addCase(updateEarlyAccessRequested.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEarlyAccessRequested.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload; // Update the user state with the updated user
      })
      .addCase(updateEarlyAccessRequested.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload || 'Failed to update early access request.';
      })
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
