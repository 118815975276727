import React, { useState, useMemo } from 'react';
import { IoImagesOutline } from 'react-icons/io5';
import { urlFor } from '../../../../db/sanity';
import { motion, AnimatePresence } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

function ImageGallery({ camp }) {
  const heroImage = camp.campInformation?.heroImage?.asset?._ref;


  // Function to get image URL from Sanity reference or Wetu URL
  const getImageUrl = (image) => {
    if (!image) return '';
    if (typeof image === 'string') return image; // Wetu URL
    if (image.asset && image.asset._ref) return urlFor(image.asset._ref).url(); // Sanity image
    return ''; // Invalid image format
  };

  // Combine hero image with gallery images, preferring Sanity gallery if available
  const allImages = useMemo(() => {
    const sanityGallery = camp.campInformation.galleryImages || [];
    const wetuGallery = camp.wetuImageGallery?.galleryImages || [];
    const gallery = sanityGallery.length > 0 ? sanityGallery : wetuGallery;
    return [
      { image: heroImage ? { asset: { _ref: heroImage } } : gallery[0], heading: 'Hero Image' },
      ...gallery.slice(1).map((image, index) => ({
        image,
        heading: `Image ${index + 2}`
      }))
    ].filter((img) => img.image);
  }, [camp, heroImage]);

  const [isFullView, setIsFullView] = useState(false);

  // Calculate displayImages directly
  const displayImages = isFullView ? allImages : allImages.slice(0, 5);

  // State to track loading and error status of images
  const [imageStatus, setImageStatus] = useState(allImages.slice(0, 5).map(() => ({ loading: true, error: false })));

  const handleImageLoad = (index) => {
    setImageStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = { loading: false, error: false };
      return newStatus;
    });
  };

  const handleImageError = (index) => {
    setImageStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = { loading: false, error: true };
      return newStatus;
    });
  };

  // Fallback image URL
  const fallbackImageUrl = 'https://example.com/fallback-image.jpg'; // Replace with your fallback image URL

  return (
    <div className="w-full mt-8 relative">
      <AnimatePresence>
        <motion.div
          className="h-[374px] grid grid-cols-12 gap-4"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.2,
              },
            },
          }}
        >
          <div className="col-span-6">
            {imageStatus[0].loading && <div className="w-full h-full bg-gray-200 animate-pulse rounded-l-2xl"></div>}
            <motion.img
              className={`w-full h-full object-cover rounded-l-2xl max-w-full max-h-[390px] ${imageStatus[0].loading ? 'hidden' : ''}`}
              src={imageStatus[0].error ? fallbackImageUrl : getImageUrl(allImages[0].image)}
              alt={allImages[0].heading}
              onLoad={() => handleImageLoad(0)}
              onError={() => handleImageError(0)}
              initial={{ opacity: 0 }}
              animate={{ opacity: imageStatus[0].loading ? 0 : 1 }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <div className="col-span-6 grid grid-cols-2 gap-4">
            {displayImages.slice(1, 5).map((image, index) => (
              <div key={index} className="relative">
                {imageStatus[index + 1].loading && <div className="w-full h-full bg-gray-200 animate-pulse rounded-lg"></div>}
                <motion.img
                  className={`w-full h-full object-cover ${index === 1 ? 'rounded-tr-2xl' : ''} ${index === 3 ? 'rounded-br-2xl' : ''} max-w-full max-h-[187px] ${imageStatus[index + 1].loading ? 'hidden' : ''}`}
                  src={imageStatus[index + 1].error ? fallbackImageUrl : getImageUrl(image.image)}
                  alt={image.heading}
                  onLoad={() => handleImageLoad(index + 1)}
                  onError={() => handleImageError(index + 1)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: imageStatus[index + 1].loading ? 0 : 1 }}
                  transition={{ duration: 0.5 }}
                />
              </div>
            ))}
          </div>
        </motion.div>
      </AnimatePresence>
      <button 
        className="absolute right-4 bottom-4 px-4 py-2 bg-white text-gray-800 rounded-lg shadow flex items-center space-x-2"
        onClick={() => setIsFullView(true)}
      >
        <IoImagesOutline className="h-5 w-5" />
        <span>Show all photos ({allImages.length})</span>
      </button>
      {isFullView && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center">
          <button 
            className="absolute top-4 right-4 text-white text-2xl z-10"
            onClick={() => setIsFullView(false)}
          >
            &times;
          </button>
          <Swiper
            modules={[Navigation, Pagination, Zoom]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            zoom={true}
            lazy={{
              loadPrevNext: true,
              loadPrevNextAmount: 2,
            }}
            className="w-full h-full"
          >
            {displayImages.map((img, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-zoom-container">
                  <img 
                    src={getImageUrl(img.image)} 
                    alt={img.heading}
                    className="w-full h-full object-contain swiper-lazy w-full h-full object-contain"
                  />
                   <div className="swiper-lazy-preloader"></div>
                </div>
                <div className="absolute bottom-4 left-4 text-white bg-black bg-opacity-50 px-2 py-1 rounded">
                  {img.heading}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
}

export default ImageGallery;