import React from "react";
import ItinerarySearch from "../components/ItinerarySearch";

function Templates() {
  const searchRequest = {
    locations: [
      {
        name: "Tarangire",
        id: "2366f81f-dcf0-4bc8-aa46-ac41984969db",
        parentParkId: "2366f81f-dcf0-4bc8-aa46-ac41984969db",
        type: "park",
      },
    ],
    dateStart: "2024-07-22",
    totalRooms: 1,
    priceCategoryId: "29943f8e-2279-44b5-aef5-6b2e4c194045",
    totalPax: 2,
    availablePropertiesOnly: true,
  };

  return (
    <div>
      <div className="px-4 sm:px-4 lg:px-8 py-8 max-w-7xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Featured Itineraries</h2>
        <ItinerarySearch searchRequest={searchRequest} />
      </div>
    </div>
  );
}

export default Templates;