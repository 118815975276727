export const ITINERARY_STATUSES = {
    draft: 'Draft',
    quotation: 'Quotation',
    pendingProvisional: 'Pending Provisional',
    provisional: 'Provisional',
    pendingConfirmation: 'pendingConfirmation',
    confirmed: 'Confirmed',
    expired: 'Expired',
    cancelled: 'Cancelled'
  };

  export const getItineraryStatusLabel = (status) => ITINERARY_STATUSES[status] || 'Unknown';
  
  export const RESERVATION_STAGES = {
    new: 'New',
    provisionalRequested: 'Provisional Requested',
    provisional: 'Provisional',
    confirmationRequested: 'Confirmation Requested',
    confirmed: 'Confirmed',
    cancelled: 'Cancelled',
    rejected: 'Rejected'
  };
  
  export const getReservationStageLabel = (stage) => RESERVATION_STAGES[stage] || 'Unknown';
  
  export const PAYMENT_STATUSES = {
    pending: 'Pending',
    paid: 'Paid',
    refunded: 'Refunded',
    // ... other statuses
  };
  
  export const getPaymentStatusLabel = (status) => PAYMENT_STATUSES[status] || 'Unknown';
  
  // Add more lookups and getter functions as needed