import { getPropObj } from './generalFunctions';

function resRequestDisplay(propUid, properties) {
  if (properties[getPropObj(propUid, properties)].platformResRequest && properties[getPropObj(propUid, properties)].platformResRequest.active === true) {
    return true;
  } else {
    return false;
  }
}

function resRequestPayable(propUid, properties) {
  if (
    properties[getPropObj(propUid, properties)].platformResRequest &&
    properties[getPropObj(propUid, properties)].platformResRequest.active === true &&
    properties[getPropObj(propUid, properties)].platformResRequest.createBooking === true
  ) {
    return true;
  } else {
    return false;
  }
}

function resRequestStock(propIndex, properties) {
  console.log('resRequestStock', properties);
  if (properties[propIndex].platformResRequest && properties[propIndex].platformResRequest.active === true && properties[propIndex].platformResRequest.principalId !== null) {
    return true;
  } else {
    return false;
  }
}

function resRequestRates(propIndex, properties) {
  if (properties[propIndex].platformResRequest && properties[propIndex].platformResRequest.active === true && properties[propIndex].platformResRequest.rates === true) {
    return true;
  } else {
    return false;
  }
}

function resRequestRatesFilter(propUid, properties) {
  if (
    properties[getPropObj(propUid, properties)].platformResRequest &&
    properties[getPropObj(propUid, properties)].platformResRequest.active === true &&
    properties[getPropObj(propUid, properties)].platformResRequest.rates === true
  ) {
    return true;
  } else {
    return false;
  }
}

function resRequestCreate(propUid, properties) {
  if (
    properties[getPropObj(propUid, properties)].platformResRequest &&
    properties[getPropObj(propUid, properties)].platformResRequest.active === true &&
    properties[getPropObj(propUid, properties)].platformResRequest.createBooking === true
  ) {
    return true;
  } else {
    return false;
  }
}

export { resRequestDisplay, resRequestStock, resRequestCreate, resRequestRates, resRequestPayable, resRequestRatesFilter };
