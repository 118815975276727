import React, { useState } from 'react';
import { findCustomLocation, getPropObj } from './generalFunctions';

import { calculateRoomTotals } from './itineraryFunctions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

// ************************************* // Check availability for activities

const calculateActivity = (activity, bookings, selectedActivityProperty, cost, showToast) => {
  console.log('calculateActivity');
  console.log('calculateActivity selectedActivityProperty', selectedActivityProperty);
  let tempCost = 0;
  let activityUnits = 0;
  let maxUnits = 0;
  let caution = false;
  // ROOM
  if (activity.priceType === 'room') {
    if (activity.type === 'resRequestAccom' && bookings[selectedActivityProperty].rooms.length > activity.stockCount) {
      console.log('calculateActivity 1');
      if (showToast) {
        toast.error(`There are only ${activity.stockCount} ${activity.name} available for this date`);
      }
      caution = true;
      tempCost = cost * activity.stockCount;
      activityUnits = activity.stockCount;
      maxUnits = activity.stockCount;
    } else if (activity.type === 'resRequestAccom' && bookings[selectedActivityProperty].rooms.length <= activity.stockCount) {
      console.log('calculateActivity 2');
      tempCost = cost * bookings[selectedActivityProperty].rooms.length;
      activityUnits = bookings[selectedActivityProperty].rooms.length;
      maxUnits = activity.stockCount;
    } else if (bookings[selectedActivityProperty].rooms.length >= activity.maximum) {
      console.log('calculateActivity 3');
      if (showToast) {
        toast.error(`There are only ${activity.maximum} ${activity.name} available for this date`);
      }
      tempCost = cost * activity.maximum;
      activityUnits = activity.maximum;
      maxUnits = activity.maximum;
    } else {
      console.log('calculateActivity 4');
      tempCost = cost * bookings[selectedActivityProperty].rooms.length;
      activityUnits = bookings[selectedActivityProperty].rooms.length;
      maxUnits = activity.maximum;
    }
    // PERSON
  } else if (activity.priceType === 'person') {
    console.log('calculateActivity 5');
    const totals = calculateRoomTotals(bookings, selectedActivityProperty);
    const totalPeople = parseInt(totals.totalAd) + parseInt(totals.totalCh) + parseInt(totals.totalChi) + parseInt(totals.totalInf);
    if (activity.type === 'resRequestAccom' && totalPeople > activity.stockCount) {
      console.log('calculateActivity 6');
      if (showToast) {
        toast.error(`There are only ${activity.stockCount} ${activity.name} available for this date`);
      }
      caution = true;
      tempCost = cost * activity.stockCount;
      activityUnits = activity.stockCount;
      maxUnits = activity.stockCount;
    } else if (activity.type === 'resRequestAccom' && totalPeople <= activity.stockCount) {
      console.log('calculateActivity 7');
      tempCost = cost * totalPeople;
      activityUnits = totalPeople;
      maxUnits = activity.stockCount;
    } else if (totalPeople >= activity.maximum) {
      console.log('calculateActivity 8');
      if (showToast) {
        toast.error(`There are only ${activity.maximum} ${activity.name} available for this date`);
      }
      tempCost = cost * activity.maximum;
      activityUnits = activity.maximum;
      maxUnits = activity.maximum;
    } else {
      console.log('calculateActivity 9');
      tempCost = cost * (parseInt(totals.totalAd) + parseInt(totals.totalCh) + parseInt(totals.totalChi) + parseInt(totals.totalInf));
      activityUnits = totalPeople;
      maxUnits = activity.maximum;
    }
    // GROUP
  } else if (activity.priceType === 'group') {
    console.log('calculateActivity 10');
    const totals = calculateRoomTotals(bookings, selectedActivityProperty);
    const totalPeople = parseInt(totals.totalAd) + parseInt(totals.totalCh) + parseInt(totals.totalChi) + parseInt(totals.totalInf);
    if (activity.type === 'resRequestAccom' && Math.ceil(totalPeople / activity.maxCapacity) > activity.stockCount) {
      console.log('calculateActivity 11');
      if (showToast) {
        toast.error(`There are only ${activity.stockCount} ${activity.name} available for this date`);
      }
      caution = true;
      tempCost = cost * activity.stockCount;
      activityUnits = activity.stockCount;
      maxUnits = activity.stockCount;
    } else if (activity.type === 'resRequestAccom' && Math.ceil(totalPeople / activity.maxCapacity) <= activity.stockCount) {
      console.log('calculateActivity 12');
      tempCost = cost * Math.ceil(totalPeople / activity.maxCapacity);
      activityUnits = Math.ceil(totalPeople / activity.maxCapacity);
      maxUnits = activity.stockCount;
    } else if (Math.ceil(totalPeople / activity.maxCapacity) >= activity.maximum) {
      console.log('calculateActivity 33');
      const temp = activity.maximum * cost;
      tempCost = activity.maximum;
      activityUnits = activity.maximum;
      maxUnits = activity.maximum;
    } else {
      console.log('calculateActivity 4');
      const temp = Math.ceil((totals.totalAd + totals.totalCh + totals.totalChi + totals.totalInf) / activity.maxCapacity) * cost;
      tempCost = temp;
      activityUnits = Math.ceil((totals.totalAd + totals.totalCh + totals.totalChi + totals.totalInf) / activity.maxCapacity);
      maxUnits = activity.maximum;
    }
  }

  return {
    tempCost,
    activityUnits,
    maxUnits,
    caution,
  };
};

const addActivity = (
  activitiesTab,
  activity,
  bookings,
  selectedActivityProperty,
  selectedActivityPropertyUid,
  selectedActivityPropertyId,
  selectedActivityDate,
  selectedActivityDay,
  activitiesData,
  setActivitiesData,
  setActivitiesCost,
  properties,
) => {
  console.log('selectedActivityProperty ' + selectedActivityProperty);
  console.log('selectedActivityPropertyUid ' + selectedActivityPropertyUid);
  console.log('selectedActivityPropertyId ' + selectedActivityPropertyId);
  console.log('selectedActivityDay ' + selectedActivityDay);
  if (activitiesTab === 'prebooked') {
    let cost = 0;
    if (activity.type === 'resRequestAccom' || activity.type === 'resRequestExtra' || activity.type === 'hornbill') {
      if (
        bookings[selectedActivityProperty].rateName.toLowerCase().includes('fullboard') &&
        properties[getPropObj(selectedActivityPropertyUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' // Nomad Tanzania
      ) {
        cost = activity.priceFullBoard;
      } else {
        cost = activity.price;
      }
    }
    const costPerUnit = cost;

    const { tempCost, activityUnits, maxUnits, caution } = calculateActivity(activity, bookings, selectedActivityProperty, cost, true);

    cost = tempCost;
    const uuid = uuidv4();

    const newActivity = {
      ...activity,
      selectedActivityDate,
      cost,
      activityUnits,
      costPerUnit,
      maxUnits,
      caution,
      uuid,
    }; // add selectedActivityDate to newActivity object
    const newActivitiesData = [...activitiesData];
    if (!newActivitiesData[selectedActivityProperty]) {
      newActivitiesData[selectedActivityProperty] = {
        propUid: bookings[selectedActivityProperty].propUid,
        activities: [],
      };
    }
    const activities = newActivitiesData[selectedActivityProperty].activities;
    if (!Array.isArray(activities[selectedActivityDay])) {
      activities[selectedActivityDay] = [];
    }
    // Check if an activity with the same uid already exists for the current day
    const activityExists = activities[selectedActivityDay].some((a) => a.uid === newActivity.uid);
    if (!activityExists) {
      activities[selectedActivityDay].push(newActivity);
      //setActivitiesData(newActivitiesData);
      setActivitiesData((newActivitiesData) => [...newActivitiesData]);
    }
    // Get the total cost of activities
    let totalCost = 0;
    newActivitiesData.forEach((property) => {
      property.activities.forEach((day) => {
        day.forEach((activity) => {
          totalCost += activity.cost;
        });
      });
    });
    console.log('totalCost: ' + totalCost);
    setActivitiesCost(totalCost);
  }
};

const handleClickActivities = (
  index,
  indx,
  item,
  activities,
  setSelectedActivityProperty,
  setSelectedActivityDay,
  setSelectedActivityDate,
  setSelectedActivityPropertyUid,
  setSelectedActivityPropertyId,
  customLocations,
) => {
  let filteredActivities = [];
  const parkUid = findCustomLocation(customLocations, item.propUid).parkId;

  if (indx === 0) {
    filteredActivities = Object.keys(activities).reduce((acc, key) => {
      const filtered = activities[key].filter((activity) => (activity.properties.includes(item.propUid) || (activity.parks && activity.parks.includes(parkUid))) && activity.availableFirstDay);
      return acc.concat(filtered);
    }, []);
  } else if (indx === item.days) {
    filteredActivities = Object.keys(activities).reduce((acc, key) => {
      const filtered = activities[key].filter((activity) => (activity.properties.includes(item.propUid) || (activity.parks && activity.parks.includes(parkUid))) && activity.availableLastDay);
      return acc.concat(filtered);
    }, []);
  } else {
    filteredActivities = Object.keys(activities).reduce((acc, key) => {
      const filtered = activities[key].filter((activity) => activity.properties.includes(item.propUid) || (activity.parks && activity.parks.includes(parkUid)));
      return acc.concat(filtered);
    }, []);
  }

  const uniqueActivities = [];
  const activityUids = new Set();
  for (const activity of filteredActivities) {
    if (!activityUids.has(activity.uid)) {
      uniqueActivities.push(activity);
      activityUids.add(activity.uid);
    }
  }

  setSelectedActivityProperty(index);
  setSelectedActivityDay(indx);
  setSelectedActivityDate(moment(new Date(item.startDay)).add(indx, 'day').format('YYYY-MM-DD'));
  setSelectedActivityPropertyUid(item.propUid);
  setSelectedActivityPropertyId(item.id);

  return uniqueActivities;
};

const filterActivities = (resetActivities, rooms, selectedActivityDate, selectedActivityPropertyUid) => {
  if (resetActivities !== null) {
    const filteredActivitiesWithAvailability = resetActivities.filter((activity) => {
      if (activity.type !== 'resRequestAccom') {
        return true;
      }

      const { ids } = activity;
      const roomId = ids.resRequestRoom;
      const room = rooms.find(
        (room) =>
          room.propUid === selectedActivityPropertyUid &&
          room.stock.some((stock) => {
            const isMatchingDate = moment(selectedActivityDate).subtract(0, 'day').isBetween(stock.stockStart, stock.stockEnd, null, '[]');

            const hasAvailableStock = stock.stockId === roomId && stock.stockSplit === 'total' && isMatchingDate && stock.stockCount > 0;

            if (hasAvailableStock) {
              activity.stockCount = stock.stockCount;
            }
            return hasAvailableStock;
          }),
      );

      return !!room;
    });
    console.log('resetActivities');
    return filteredActivitiesWithAvailability;
  }
  return null;
};

// Check if a property has any activities available
function getActivitiesLength(propUid, parkUid, activities) {
  // Iterate through each key (activity ID) in the activities object
  return Object.values(activities).reduce((count, activityArray) => {
    // Iterate through each activity in the array
    return (
      count +
      activityArray.reduce((innerCount, activity) => {
        // Check if the propUid exists in the properties array
        if (activity.properties && activity.properties.includes(propUid)) {
          return innerCount + 1;
        }
        // Check if the parkUid exists in the parks array, if it exists
        else if (activity.parks && activity.parks.includes(parkUid)) {
          return innerCount + 1;
        }
        return innerCount;
      }, 0)
    );
  }, 0);
}

// Check if a property has any activities available
// function getActivitiesLength(propUid, activities) {
// 	// Access the array using the propUid
// 	const activityArray = activities[propUid];

// 	// Return the length of the array, or 0 if it is undefined
// 	return activityArray ? activityArray.length : 0;
// }

// Check is a day has activities
function checkIfAllActivitiesEmpty(activitiesData, index) {
  const activities = activitiesData[index].activities;

  // Return true if every activity array is empty, otherwise false.
  return activities.every((activityArray) => activityArray.length === 0);
}

function updateActivityDates(bookings, activitiesData) {
  // Helper function to add days to a date
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  };

  // Deep copy the activitiesData to avoid modifying the original data
  const newActivitiesData = JSON.parse(JSON.stringify(activitiesData));

  // Iterate through all copied activities data
  newActivitiesData.forEach((activityData) => {
    // Find the corresponding booking
    const booking = bookings.find((b) => b.propUid === activityData.propUid && b.id === activityData.id);
    if (booking) {
      // Iterate through each day of activities
      activityData.activities.forEach((dayActivities, index) => {
        // Calculate the new date for this day based on the booking's startDay
        const newDate = addDays(booking.startDay, index);
        // Update the selectedActivityDate for each activity
        dayActivities.forEach((activity) => {
          activity.selectedActivityDate = newDate;
        });
      });
    }
  });

  // Return the updated copy of activities data
  return newActivitiesData;
}

export { addActivity, handleClickActivities, filterActivities, calculateActivity, getActivitiesLength, checkIfAllActivitiesEmpty, updateActivityDates };
