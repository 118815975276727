import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import { Timestamp } from 'firebase/firestore';
import { getAirportName } from './transferFunctions';
import { getCreatioTypeForValue } from './finalPageFunctions';
import { getVehicleRoomDetailsNotSupplement } from './vehicleFunctions';
import { loadAirlineDetails } from './loadDataFunctions';
import moment from 'moment';
import { rateLabelMap } from './itineraryFunctions';
import { toast } from 'react-toastify';

// ************************************* // make a number 2 digits
function make2digits(num) {
  return (num < 10 ? '0' : '') + num;
}

// ************************************* // Add days
function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

// ************************************* // Minus days
function minusDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

// ************************************* // Add months
function addMonths(date, months) {
  const result = new Date();
  result.setMonth(result.getMonth() + months);
  return result;
}

// ************************************* // Convert date to YYYY-MM-DD format
// function dateShort(date) {
//   console.log("dateShort");

//   console.log(date);
//   console.log(JSON.stringify(date, undefined, 4));
//   console.log("New");
//   console.log(date.format("YYYY-MM-DD"));
//   console.log(JSON.stringify(date.format("YYYY-MM-DD"), undefined, 4));

//   // var Month2Dig = date.getMonth() + 1;
//   // var Day2Dig = make2digits(date.getDate());
//   // var shortDate = date.getFullYear() + "-" + Month2Dig + "-" + Day2Dig;

//   // return shortDate;
//   return date.format("YYYY-MM-DD");
// }
function dateShort(date) {
  console.log('dateShort');
  console.log(date);
  console.log(JSON.stringify(date, undefined, 4));

  // If date is a moment object
  if (moment.isMoment(date)) {
    return date.format('YYYY-MM-DD');
  }

  // If date is a string
  let parsedDate = moment(new Date(date));
  if (parsedDate.isValid()) {
    return parsedDate.format('YYYY-MM-DD');
  }

  // Handle invalid date input
  console.error('Invalid date format provided');
  return null;
}

// ************************************* // Convert date to Jan 01 23
// function formatDateDisplay(date) {
//   const options = { year: "2-digit", month: "short", day: "2-digit" };
//   const formatter = new Intl.DateTimeFormat("en-US", options);
//   return formatter.format(date);
// }
function formatDateDisplay(date) {
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const n = month[date.getMonth()];
  return date.getDate() + ' ' + n + ' ' + date.getFullYear();
}

function getPropObj(propUid, properties) {
  const requiredIndex = properties.findIndex((el) => {
    return el.uid === String(propUid);
  });
  return requiredIndex;
}

function getRoomImg(propIndex, roomId, properties) {
  let room = properties[propIndex].rooms.find((r) => r.id === roomId);
  // console.log("propIndex " + propIndex + " roomId " + roomId);
  // console.log(room);
  return room !== undefined
    ? room.img
    : 'https://firebasestorage.googleapis.com/v0/b/nomadhornbill.appspot.com/o/images%2Fproperties%2Fdefault%2Fdefault.png?alt=media&token=60f73247-fa43-4829-9c56-260803159067';
}

function numberWithCommas(x) {
  return x.toLocaleString();
}
function formatNumber(value) {
  if (isNaN(value)) return '0.00'; // Handle NaN values

  // Convert to two decimal points
  let result = parseFloat(value).toFixed(2);

  // Add commas as thousands separators
  const parts = result.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

// const formatFireStoreDate = (seconds, nanoSeconds) => {
// 	var date = new Date(seconds * 1000 + nanoSeconds / 1000000);
// 	var formattedDate = date.toISOString();
// 	return formattedDate;
// };

// function isBST(date) {
// 	const year = date.getUTCFullYear();
// 	const BSTStart = new Date(Date.UTC(year, 2, 31)); // March 31
// 	const BSTEnd = new Date(Date.UTC(year, 9, 27)); // October 27
// 	BSTStart.setDate(31 - ((BSTStart.getUTCDay() + 6) % 7));
// 	BSTEnd.setDate(27 - ((BSTEnd.getUTCDay() + 6) % 7));
// 	return date >= BSTStart && date <= BSTEnd;
// }

// Function to find the last Sunday of a specific month and year

// Function to check if a date is within the BST period
// const bstDates = [
// 	{ start: new Date(2023, 2, 26), end: new Date(2023, 9, 29) },
// 	{ start: new Date(2024, 2, 31), end: new Date(2024, 9, 27) },
// 	{ start: new Date(2025, 2, 30), end: new Date(2025, 9, 26) },
// 	{ start: new Date(2026, 2, 29), end: new Date(2026, 9, 25) },
// 	{ start: new Date(2027, 2, 28), end: new Date(2027, 9, 31) },
// 	{ start: new Date(2028, 2, 26), end: new Date(2028, 9, 29) },
// 	{ start: new Date(2029, 2, 25), end: new Date(2029, 9, 28) },
// 	{ start: new Date(2030, 2, 31), end: new Date(2030, 9, 27) },
// 	{ start: new Date(2031, 2, 30), end: new Date(2031, 9, 26) },
// 	{ start: new Date(2032, 2, 28), end: new Date(2032, 9, 31) },
// 	{ start: new Date(2033, 2, 27), end: new Date(2033, 9, 30) },
// 	{ start: new Date(2034, 2, 26), end: new Date(2034, 9, 29) },
// 	{ start: new Date(2035, 2, 25), end: new Date(2035, 9, 28) },
// 	{ start: new Date(2036, 2, 30), end: new Date(2036, 9, 26) },
// 	{ start: new Date(2037, 2, 29), end: new Date(2037, 9, 25) },
// 	{ start: new Date(2038, 2, 28), end: new Date(2038, 9, 31) },
// 	{ start: new Date(2039, 2, 27), end: new Date(2039, 9, 30) },
// 	{ start: new Date(2040, 2, 25), end: new Date(2040, 9, 28) },
// 	{ start: new Date(2041, 2, 31), end: new Date(2041, 9, 27) },
// 	{ start: new Date(2042, 2, 30), end: new Date(2042, 9, 26) },
// ];
// Function to check if a date is in British Summer Time (BST)
const bstDates = [
  {
    start: new Date(Date.UTC(2023, 2, 26, 12, 0, 0)),
    end: new Date(Date.UTC(2023, 9, 29, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2024, 2, 31, 12, 0, 0)),
    end: new Date(Date.UTC(2024, 9, 27, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2025, 2, 30, 12, 0, 0)),
    end: new Date(Date.UTC(2025, 9, 26, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2026, 2, 29, 12, 0, 0)),
    end: new Date(Date.UTC(2026, 9, 25, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2027, 2, 28, 12, 0, 0)),
    end: new Date(Date.UTC(2027, 9, 31, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2028, 2, 26, 12, 0, 0)),
    end: new Date(Date.UTC(2028, 9, 29, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2029, 2, 25, 12, 0, 0)),
    end: new Date(Date.UTC(2029, 9, 28, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2030, 2, 31, 12, 0, 0)),
    end: new Date(Date.UTC(2030, 9, 27, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2031, 2, 30, 12, 0, 0)),
    end: new Date(Date.UTC(2031, 9, 26, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2032, 2, 28, 12, 0, 0)),
    end: new Date(Date.UTC(2032, 9, 31, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2033, 2, 27, 12, 0, 0)),
    end: new Date(Date.UTC(2033, 9, 30, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2034, 2, 26, 12, 0, 0)),
    end: new Date(Date.UTC(2034, 9, 29, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2035, 2, 25, 12, 0, 0)),
    end: new Date(Date.UTC(2035, 9, 28, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2036, 2, 30, 12, 0, 0)),
    end: new Date(Date.UTC(2036, 9, 26, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2037, 2, 29, 12, 0, 0)),
    end: new Date(Date.UTC(2037, 9, 25, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2038, 2, 28, 12, 0, 0)),
    end: new Date(Date.UTC(2038, 9, 31, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2039, 2, 27, 12, 0, 0)),
    end: new Date(Date.UTC(2039, 9, 30, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2040, 2, 25, 12, 0, 0)),
    end: new Date(Date.UTC(2040, 9, 28, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2041, 2, 31, 12, 0, 0)),
    end: new Date(Date.UTC(2041, 9, 27, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2042, 2, 30, 12, 0, 0)),
    end: new Date(Date.UTC(2042, 9, 26, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2043, 2, 29, 12, 0, 0)),
    end: new Date(Date.UTC(2043, 9, 25, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2044, 2, 27, 12, 0, 0)),
    end: new Date(Date.UTC(2044, 9, 30, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2045, 2, 26, 12, 0, 0)),
    end: new Date(Date.UTC(2045, 9, 29, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2046, 2, 25, 12, 0, 0)),
    end: new Date(Date.UTC(2046, 9, 28, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2047, 2, 31, 12, 0, 0)),
    end: new Date(Date.UTC(2047, 9, 27, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2048, 2, 29, 12, 0, 0)),
    end: new Date(Date.UTC(2048, 9, 25, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2049, 2, 28, 12, 0, 0)),
    end: new Date(Date.UTC(2049, 9, 31, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2050, 2, 27, 12, 0, 0)),
    end: new Date(Date.UTC(2050, 9, 30, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2051, 2, 26, 12, 0, 0)),
    end: new Date(Date.UTC(2051, 9, 29, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2052, 2, 31, 12, 0, 0)),
    end: new Date(Date.UTC(2052, 9, 27, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2053, 2, 30, 12, 0, 0)),
    end: new Date(Date.UTC(2053, 9, 26, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2054, 2, 29, 12, 0, 0)),
    end: new Date(Date.UTC(2054, 9, 25, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2055, 2, 28, 12, 0, 0)),
    end: new Date(Date.UTC(2055, 9, 31, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2056, 2, 26, 12, 0, 0)),
    end: new Date(Date.UTC(2056, 9, 29, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2057, 2, 25, 12, 0, 0)),
    end: new Date(Date.UTC(2057, 9, 28, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2058, 2, 31, 12, 0, 0)),
    end: new Date(Date.UTC(2058, 9, 27, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2059, 2, 30, 12, 0, 0)),
    end: new Date(Date.UTC(2059, 9, 26, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2060, 2, 28, 12, 0, 0)),
    end: new Date(Date.UTC(2060, 9, 31, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2061, 2, 27, 12, 0, 0)),
    end: new Date(Date.UTC(2061, 9, 30, 0, 0, 0)),
  },
  {
    start: new Date(Date.UTC(2062, 2, 26, 12, 0, 0)),
    end: new Date(Date.UTC(2062, 9, 29, 0, 0, 0)),
  },
];

function adjustDateForBST(date) {
  if (isBST(date)) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    return new Date(Date.UTC(year, month, day, 12, 0, 0));
  }
  return date;
}
function convertToUTCDate(date) {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
}

function isBST(date) {
  const dateUTC = convertToUTCDate(date);
  for (const { start, end } of bstDates) {
    if (dateUTC >= start && dateUTC <= end) {
      console.log('isBST: true');
      return true;
    }
  }
  console.log('isBST: false');
  return false;
}

function isDayAfterBSTEnd(date) {
  const dateUTC = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  const dateUTCTime = dateUTC.getTime();

  for (const { end } of bstDates) {
    const nextDayAfterEnd = new Date(end.getTime() + 86400000); // Add 1 day
    const nextDayAfterEndUTC = new Date(Date.UTC(nextDayAfterEnd.getUTCFullYear(), nextDayAfterEnd.getUTCMonth(), nextDayAfterEnd.getUTCDate()));
    const nextDayAfterEndUTCTime = nextDayAfterEndUTC.getTime();

    console.log('dateUTCTime:', dateUTCTime);
    console.log('nextDayAfterEndUTCTime:', nextDayAfterEndUTCTime);

    if (dateUTCTime === nextDayAfterEndUTCTime) {
      console.log('isDayAfterBSTEnd: true');
      return true;
    }
  }

  console.log('isDayAfterBSTEnd: false');
  return false;
}

function addOneDayIfBST(date) {
  console.log('Date being checked in addOneDayIfBST: ', date);
  console.log('Type of date in addOneDayIfBST:', typeof date);

  let dateObj;

  // If date is already a Date object, use it. Otherwise, create a new Date object.
  if (date instanceof Date) {
    dateObj = date;
  } else if (typeof date === 'string') {
    dateObj = new Date(date);
  } else {
    console.error('Unrecognized date format in addOneDayIfBST');
    return date;
  }

  const dateUTC = convertToUTCDate(dateObj);

  if (isBST(dateUTC)) {
    return new Date(dateObj.getTime() + 86400000); // Add 1 day
  } else if (isDayAfterBSTEnd(dateUTC)) {
    return new Date(dateObj.getTime() + 86400000 / 2); // Add 2 days
  }
  return dateObj;
}

// function addOneDayIfBST(date) {
// 	if (isBST(date)) {
// 		return moment(date).add(1, "days").toDate();
// 	}
// 	return date;
// }

// const formatFireStoreDate = (seconds, nanoSeconds) => {
//   // Calculate the full date in UTC.
//   let date = new Date(0); // epoch
//   date.setUTCSeconds(seconds, nanoSeconds / 1000000);

//   // Check if the date falls within the BST period and adjust accordingly
//   console.log("Date before adjustment: ", date);
//   date = adjustDateForBST(date); // Use the new function here
//   console.log("Date after adjustment: ", date);

//   // Extract the UTC components.
//   let year = date.getUTCFullYear();
//   let month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-11 in JavaScript
//   let day = String(date.getUTCDate()).padStart(2, "0");

//   // Construct the formatted date string.
//   let formattedDate = `${year}-${month}-${day}T12:00:00Z`; // Explicitly set to 12:00 midday UTC
//   console.log("formattedDate: ", formattedDate);
//   return formattedDate;
// };

const formatFireStoreDate = (seconds, nanoSeconds) => {
  // Convert Firestore timestamp to milliseconds
  const milliseconds = seconds * 1000 + nanoSeconds / 1000000;

  // Adjust for the local time zone offset and set to midday in UTC
  const adjustedTime = milliseconds + new Date().getTimezoneOffset() * -60000;
  const date = moment.utc(adjustedTime).hour(12).minute(0).second(0).millisecond(0);

  // Format the date
  const formattedDate = date.format('YYYY-MM-DDTHH:mm:ss[Z]'); // ISO format

  return formattedDate;
};

// function lastSunday(year, month) {
// 	const lastDayOfMonth = new Date(Date.UTC(year, month + 1, 0, 12, 0, 0)); // Last day of the month, at midday
// 	let day = lastDayOfMonth.getUTCDay();
// 	let lastSunday = lastDayOfMonth.getUTCDate() - day + 7;
// 	return new Date(Date.UTC(year, month, lastSunday, 12, 0, 0)); // At midday
// }

// function isBST(date) {
// 	const year = date.getUTCFullYear();
// 	const BSTStart = lastSunday(year, 2); // Last Sunday of March
// 	const BSTEnd = lastSunday(year, 9); // Last Sunday of October

// 	// Create a new date object at midday for comparison
// 	const compareDate = new Date(
// 		Date.UTC(year, date.getUTCMonth(), date.getUTCDate(), 12, 0, 0)
// 	);

// 	return compareDate >= BSTStart && compareDate <= BSTEnd;
// }

// const formatFireStoreDate = (seconds, nanoSeconds) => {
// 	// Calculate the full date in UTC.
// 	let date = new Date(0); // epoch
// 	date.setUTCSeconds(seconds, nanoSeconds / 1000000);

// 	// Check if the date falls within the BST period and adjust accordingly
// 	console.log("Date before adjustment: ", date);
// 	if (isBST(date)) {
// 		date.setUTCDate(date.getUTCDate() + 1);
// 	}
// 	console.log("Date after adjustment: ", date);

// 	// Extract the UTC components.
// 	let year = date.getUTCFullYear();
// 	let month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-11 in JavaScript
// 	let day = String(date.getUTCDate()).padStart(2, "0");

// 	// Construct the formatted date string.
// 	let formattedDate = `${year}-${month}-${day}T12:00:00Z`; // Explicitly set to 12:00 midday UTC

// 	return formattedDate;
// };

// const formatFireStoreDate = (seconds, nanoSeconds) => {
// 	// Calculate the full date in UTC.
// 	let date = new Date(0); // epoch
// 	date.setUTCSeconds(seconds, nanoSeconds / 1000000);

// 	// Extract the UTC components.
// 	let year = date.getUTCFullYear();
// 	let month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-11 in JavaScript
// 	let day = String(date.getUTCDate()).padStart(2, "0");

// 	// Construct the formatted date string.
// 	let formattedDate = `${year}-${month}-${day}T12:00:00Z`; // Explicitly set to 12:00 midday UTC

// 	return formattedDate;
// };

// const formatFireStoreDate = (seconds, nanoSeconds) => {
//   const date = moment.unix(seconds).add(nanoSeconds / 1000000000, 'seconds');
//   return date.toISOString();
// };

function getTimeDifference(startDateTime, endDateTime) {
  // const startMoment = moment(startDateTime);
  // const endMoment = moment(endDateTime);

  const startMoment = moment(startDateTime, 'YYYY/MM/DD HH:mm:ss.SSS');
  const endMoment = moment(endDateTime, 'YYYY/MM/DD HH:mm:ss.SSS');

  const duration = moment.duration(endMoment.diff(startMoment));

  const hours = duration.hours();
  const minutes = duration.minutes();

  return `${hours} ${hours === 1 ? 'hr' : 'hrs'} and ${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
}

const getPropertyName = (uid, properties) => {
  let property = properties.find((property) => property.uid === uid);
  return property ? property.name : 'No property found';
};

const getPropertyLocation = (uid, properties) => {
  let property = properties.find((property) => property.uid === uid);
  return property ? property.location : 'No property found';
};

const getSupplier = (uid, properties) => {
  let property = properties.find((property) => property.uid === uid);
  return property ? property.supplier : 'No property found';
};

const getPropertySafariPortalCode = (uid, properties) => {
  let property = properties.find((property) => property.uid === uid);
  return property ? property.safariPortalCode : 'No property found';
};

function convertTimestamps(object) {
  if (object instanceof Timestamp) {
    return object.toMillis(); // returns Unix timestamp in milliseconds
  }
  if (typeof object !== 'object' || object === null) {
    return object;
  }
  if (Array.isArray(object)) {
    return object.map(convertTimestamps);
  }
  return Object.fromEntries(Object.entries(object).map(([key, value]) => [key, convertTimestamps(value)]));
}

function convertFirestoreTimestampToMoment(timestamp) {
  if (timestamp && timestamp.seconds) {
    return moment.unix(timestamp.seconds);
  }
  return moment(timestamp);
}

function findValidPackedLunch(extras, checkDateStr, unitPriceType) {
  const checkDate = moment(checkDateStr, 'YYYY-MM-DD');
  let maxPriceExtra = null;
  let maxPrice = 0;

  for (let extra of extras) {
    // Check if type is 'packedLunch' and the extra is active
    if (extra && extra.type === 'packedLunch' && extra.active) {
      const validFrom = convertFirestoreTimestampToMoment(extra.validFrom);
      const validUntil = convertFirestoreTimestampToMoment(extra.validUntil);

      // Check if the checkDate is within the valid date range
      if (checkDate.isBetween(validFrom, validUntil, undefined, '[]')) {
        return extra;
      }

      // Update maxPrice and maxPriceExtra if current extra has a higher price
      if (extra[unitPriceType] > maxPrice) {
        maxPrice = extra[unitPriceType];
        maxPriceExtra = extra;
      }
    }
  }

  // Return the extra with the highest price if no valid date range match is found,
  // or null if no 'packedLunch' and active extra is found
  return maxPriceExtra;
}

function findValidPackedLunchOld(extras, checkDateStr, unitPriceType) {
  const checkDate = moment(checkDateStr, 'YYYY-MM-DD');
  let maxPriceExtra = null;
  let maxPrice = 0;

  for (let extra of extras) {
    // Check if type is 'packedLunch' and the extra is active
    if (extra && extra.type === 'packedLunch' && extra.active) {
      const validFrom = moment(extra.validFrom);
      const validUntil = moment(extra.validUntil);

      // Check if the checkDate is within the valid date range
      if (checkDate.isBetween(validFrom, validUntil, undefined, '[]')) {
        return extra;
      }

      // Update maxPrice and maxPriceExtra if current extra has a higher price
      if (extra[unitPriceType] > maxPrice) {
        maxPrice = extra[unitPriceType];
        maxPriceExtra = extra;
      }
    }
  }

  // Return the extra with the highest price if no valid date range match is found,
  // or null if no 'packedLunch' and active extra is found
  return maxPriceExtra;
}

function countNorthernGuideVehiclesInBooking(booking) {
  // Filter rooms with "vehicleType": "northernGuide"
  const northernGuideVehicles = booking.rooms.filter((room) => room.vehicleType === 'northernGuide');

  // Return the count of such rooms
  return northernGuideVehicles.length;
}

function getSearchIdTransfer(transfers, bookingId) {
  // Find the transfer that matches the booking ID
  const foundTransfer = transfers.find((transfer) => transfer.uuid === bookingId);

  // Initialize the searchIdTransfer variable
  let searchIdTransfer = null;

  // If a matching transfer is found, set the searchIdTransfer to its tuuid
  if (foundTransfer) {
    searchIdTransfer = foundTransfer.uuid;
  }

  // Return the searchIdTransfer value, or you can directly use it as needed
  return searchIdTransfer;
}

// rearrange finalPageData & finalPageTransferData - START
function orderByFeeType(data, type) {
  const feeTypeOrder = ['Nomad Accommodation', '3rd Party Accommodation', 'Park Fees', 'Cars & Guides', 'Activities & Extras', 'Flights', 'Transfers'];

  // Function to get the sort order index based on feeTypeName
  function getOrderIndex(feeTypeName) {
    const index = feeTypeOrder.indexOf(feeTypeName);
    return index === -1 ? feeTypeOrder.length : index; // Put unrecognized items at the end
  }

  data.forEach((item) => {
    let itemsArray = [];

    if (type === 'properties' && item.propertyItems && item.edit === false) {
      itemsArray = item.propertyItems;
    } else if (type === 'transfers' && item.transferItems && item.edit === false) {
      itemsArray = item.transferItems;
    }

    // Sort based on feeTypeName
    itemsArray.sort((a, b) => {
      const orderA = getOrderIndex(a.feeTypeName);
      const orderB = getOrderIndex(b.feeTypeName);
      return orderA - orderB;
    });
  });

  return data;
}

async function createJsonObject(properties, bookings, transfers, activitiesData, airports, finalPageData, otherArrangementsData, finalPageTransferData, suppliers, customLocations, customFinalItems) {
  // Create deep copies
  properties = JSON.parse(JSON.stringify(properties));
  bookings = JSON.parse(JSON.stringify(bookings));
  transfers = JSON.parse(JSON.stringify(transfers));
  activitiesData = JSON.parse(JSON.stringify(activitiesData));

  finalPageData = JSON.parse(JSON.stringify(finalPageData));
  //finalPageTransferData = JSON.parse(JSON.stringify(finalPageTransferData));

  // // rearrange finalPageData & finalPageTransferData - START
  // function orderByFeeType(data, type) {
  //   const feeTypeOrder = ['Nomad Accommodation', '3rd Party Accommodation', 'Park Fees', 'Cars & Guides', 'Activities & Extras', 'Flights', 'Transfers'];

  //   // Function to get the sort order index based on feeTypeName
  //   function getOrderIndex(feeTypeName) {
  //     const index = feeTypeOrder.indexOf(feeTypeName);
  //     return index === -1 ? feeTypeOrder.length : index; // Put unrecognized items at the end
  //   }

  //   data.forEach((item) => {
  //     let itemsArray = [];

  //     if (type === 'properties' && item.propertyItems) {
  //       itemsArray = item.propertyItems;
  //     } else if (type === 'transfers' && item.transferItems) {
  //       itemsArray = item.transferItems;
  //     }

  //     // Sort based on feeTypeName
  //     itemsArray.sort((a, b) => {
  //       const orderA = getOrderIndex(a.feeTypeName);
  //       const orderB = getOrderIndex(b.feeTypeName);
  //       return orderA - orderB;
  //     });
  //   });

  //   return data;
  // }

  finalPageData = orderByFeeType(finalPageData, 'properties');
  // finalPageTransferData = orderByFeeType(finalPageTransferData, 'transfers');

  //rearrange finalPageData & finalPageTransferData - END

  let jsonSql = [];

  for (const [bookingIdx, booking] of bookings.entries()) {
    let bookingTransfers = transfers.filter((transfer) => transfer.uuid === booking.id && transfer.formValues);

    let arrivalTransfers = bookingTransfers.filter((transfer) => transfer.objType === 'arrival');

    let propertyTransfers = bookingTransfers.filter((transfer) => transfer.objType === 'propertyTransfer' || transfer.objType === 'departure');

    // Get next properties name/uid
    let nextPropertyName = 'na';
    let nextPropertyUid = 'na';
    let propertyName = getPropertyName(bookings[bookingIdx].propUid, properties);
    let propertyUid = bookings[bookingIdx].propUid;
    if (bookingIdx < bookings.length - 1) {
      propertyUid = bookings[bookingIdx].propUid;
      propertyName = getPropertyName(bookings[bookingIdx].propUid, properties);
      nextPropertyUid = bookings[bookingIdx + 1].propUid;
      nextPropertyName = getPropertyName(nextPropertyUid, properties);
    }

    function findAirportByUid(uid) {
      const foundAirport = airports.find((airport) => airport.uid === uid);
      return foundAirport || null; // Return the found airport or null if not found
    }

    async function processFoundItems(foundItems) {
      console.log('processFoundItems', JSON.stringify(foundItems, null, 2));

      for (let foundItem of foundItems) {
        console.log('foundItem', JSON.stringify(foundItem, null, 2));
        foundItem.fields.forEach((foundItemField, index) => {
          let childItem = index !== 0; // false if the first item, true otherwise
          let creatioType = foundItem.type; // customOther, customFlight, customRoadTransfer
          let discount = foundItem.discount;

          // Create the base object for jsonSql
          let jsonSqlItem = {
            sequence: jsonSql.length + 1,
            bUuid: booking.id,
            type: 'customItem',
            childItem: childItem,
            creatioType: creatioType,
            supplierUid: foundItemField.supplierUid,
            supplierName: foundItemField.supplierName,
            units: foundItemField.units,
            net: foundItemField.net,
            totalPrice: foundItemField.total,
            description: foundItemField.description,
            feeType: foundItemField.feeTypeName,
            feeTypeName: foundItemField.feeTypeName,
            feeTypeUid: foundItemField.feeTypeUid,
            startDay: foundItemField.date,
            pax: foundItemField.pax,
            uuid: foundItemField.uuid,
            ...(foundItemField.transferRouteUid ? { transferRouteUid: foundItemField.transferRouteUid } : {}),
            ...(discount ? { discount: true } : {}), // Add discount property if true
          };

          // Add additional fields based on the creatioType for non-child items
          if (!childItem) {
            if (creatioType === 'customFlight') {
              jsonSqlItem.destinationAirportLocation = findAirportByUid(foundItemField.destinationAirportUid).location.id;
              jsonSqlItem.originAirportLocation = findAirportByUid(foundItemField.originAirportUid).location.id;
              jsonSqlItem.originAirportUid = foundItemField.originAirportUid;
              jsonSqlItem.originAirportName = foundItemField.originAirportName;
              jsonSqlItem.destinationAirportUid = foundItemField.destinationAirportUid;
              jsonSqlItem.destinationAirportName = foundItemField.destinationAirportName;
              jsonSqlItem.fltnum = foundItemField.fltnum;
              jsonSqlItem.flightType = foundItemField.flightType;
              jsonSqlItem.arrivalTime = foundItemField.arrivalTime;
              jsonSqlItem.departureTime = foundItemField.departureTime;
              jsonSqlItem.additionalInfo = foundItemField.additionalInfo;
              jsonSqlItem.bookedDirect = foundItemField.bookedDirect;
            } else if (creatioType === 'customRoadTransfer') {
              jsonSqlItem.originLocationUid = foundItemField.originLocationUid;
              jsonSqlItem.originLocationName = foundItemField.originLocationName;
              if (foundItemField.originLocationPark) {
                jsonSqlItem.originLocationPark = foundItemField.originLocationPark;
              }
              jsonSqlItem.destinationLocationUid = foundItemField.destinationLocationUid;
              jsonSqlItem.destinationLocationName = foundItemField.destinationLocationName;
              if (foundItemField.destinationLocationPark) {
                jsonSqlItem.destinationLocationPark = foundItemField.destinationLocationPark;
              }
              jsonSqlItem.additionalInfo = foundItemField.additionalInfo;
            }
          }

          // Push the jsonSqlItem to the jsonSql array
          jsonSql.push(jsonSqlItem);
        });
      }
    }

    async function processExtras(transfer, transferType, airportUid, airportName, airportCode, airportLocationUid, nextOrCurrent, ownArrangements) {
      console.log('processExtras transfer', transferType);
      console.log('ownArrangements', ownArrangements);
      console.log(JSON.stringify(transfer, null, 2));
      // Extract supplier details based on transferType
      let supplierUid, supplierName, creatioType;
      let quantity = 1;
      let price = 0;
      let totalPrice = 0;

      // if (transferType === 'pickup') {
      //   supplierUid = transfer.objType !== 'arrival' ? getSupplier(nextPropertyUid, properties).id || undefined : getSupplier(booking.propUid, properties).id || undefined;
      //   supplierName = transfer.objType !== 'arrival' ? getSupplier(nextPropertyUid, properties).name || undefined : getSupplier(booking.propUid, properties).name || undefined;
      //   creatioType = ownArrangements ? 'pickupOwnArrangement' : transferType;
      // } else {
      //   supplierUid = getSupplier(booking.propUid, properties).id || undefined;
      //   supplierName = getSupplier(booking.propUid, properties).name || undefined;
      //   creatioType = ownArrangements ? 'dropoffOwnArrangement' : transferType;
      // }

      if (transferType === 'pickup') {
        //if (transfer.formValues.arrivalTransferSaleTotal) {
        if ((transfer.formValues?.arrivalTransferSaleTotal ?? null) !== null) {
          supplierUid = transfer.formValues.arrivalTransferSupplierUid;
          supplierName = transfer.formValues.arrivalTransferSupplierName;
          quantity = transfer.formValues.arrivalTransferUnits;
          price = transfer.formValues.arrivalTransferUnitPrice;
          totalPrice = transfer.formValues.arrivalTransferSaleTotal;
        } else {
          supplierUid = transfer.objType !== 'arrival' ? getSupplier(nextPropertyUid, properties).id || undefined : getSupplier(booking.propUid, properties).id || undefined;
          supplierName = transfer.objType !== 'arrival' ? getSupplier(nextPropertyUid, properties).name || undefined : getSupplier(booking.propUid, properties).name || undefined;
        }
        creatioType = ownArrangements && transfer.formValues.includeArrivalTransfer ? 'pickup' : 'pickupOwnArrangement';
      } else {
        //if (transfer.formValues.departureTransferSaleTotal) {
        if ((transfer.formValues?.departureTransferSaleTotal ?? null) !== null) {
          supplierUid = transfer.formValues.departureTransferSupplierUid;
          supplierName = transfer.formValues.departureTransferSupplierName;
          quantity = transfer.formValues.departureTransferUnits;
          price = transfer.formValues.departureTransferUnitPrice;
          totalPrice = transfer.formValues.departureTransferSaleTotal;
        } else {
          supplierUid = getSupplier(booking.propUid, properties).id || undefined;
          supplierName = getSupplier(booking.propUid, properties).name || undefined;
        }
        creatioType = ownArrangements && transfer.formValues.includeDepartureTransfer ? 'dropoff' : 'dropoffOwnArrangement';
        //creatioType = ownArrangements ? 'dropoffOwnArrangement' : transferType;
        //includeDepartureTransfer
        //includeDepartureTransfer
      }

      console.log('formType', transfer.formValues.type);
      console.log('ownArrangements', ownArrangements);
      console.log('creatioType', creatioType);

      // CUSTOM FINAL ITEMS add customFinalItems ----------------------------------------------
      let searchId = transfer.tuuid;
      const foundItems = customFinalItems.filter((search) => search.id === searchId && !search.departure && search.pickupDropoff === transferType);
      console.log('foundItems', JSON.stringify(foundItems, null, 2));
      processFoundItems(foundItems);

      jsonSql.push({
        sequence: jsonSql.length + 1,
        bUuid: booking.id,
        type: 'airportTransfer',
        //creatioType: 'airportTransfer',
        formType: transfer.formValues.type,
        transferType: transferType,
        creatioType: creatioType,
        originPropertyLocation: transfer.objType !== 'arrival' ? getPropertyLocation(transfer.propUid, properties) : null,
        destinationPropertyLocation: transfer.objType !== 'arrival' ? getPropertyLocation(nextPropertyUid, properties) : getPropertyLocation(transfer.propUid, properties),
        supplierUid: supplierUid,
        supplierName: supplierName,
        // startDay:
        //   transferType === 'pickup'
        //     ? transfer.formValues.flightDetails
        //       ? moment(transfer.formValues.flightDetails.STA).format('YYYY-MM-DD')
        //       : transfer.endDay
        //     : transfer.formValues.flightDetails
        //     ? moment(transfer.formValues.flightDetails.STA).format('YYYY-MM-DD')
        //     : transfer.endDay,
        startDay: transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay,
        originPropertyName: transfer.objType !== 'arrival' ? getPropertyName(booking.propUid, properties) : null,
        originPropertyUid: transfer.objType !== 'arrival' ? booking.propUid : null,
        destinationPropertyName: transfer.objType !== 'arrival' ? nextPropertyName : getPropertyName(booking.propUid, properties),
        destinationPropertyUid: transfer.objType !== 'arrival' ? nextPropertyUid : booking.propUid,
        airportLocationUid: airportLocationUid,
        originPropertySupplierName: transfer.objType !== 'arrival' ? getSupplier(booking.propUid, properties).name || undefined : null,
        originPropertySupplierUid: transfer.objType !== 'arrival' ? getSupplier(booking.propUid, properties).id || undefined : null,
        destinationPropertySupplierName: transfer.objType !== 'arrival' ? getSupplier(nextPropertyUid, properties).name || undefined : getSupplier(booking.propUid, properties).name || undefined,
        destinationPropertySupplierUid: transfer.objType !== 'arrival' ? getSupplier(nextPropertyUid, properties).id || undefined : getSupplier(booking.propUid, properties).id || undefined,
        airportUid: airportUid,
        airportName: airportName,
        airportCode: airportCode,
        quantity: quantity,
        price: price,
        totalPrice: totalPrice,
        // units: quantity,
        // unitPrice: price,
        // saleTotal: totalPrice,
      });
    }

    function processVipItem(vipItem, arrivalDeparture) {
      console.log('processVipItem', JSON.stringify(vipItem, null, 2));
      let totalPax = calculateTotalPax(booking);
      let airportCode;
      let airportName;
      let startDay; // ROGER TO ADD IN
      if (vipItem.objType === 'arrival') {
        startDay = vipItem.startDay;
      } else {
        startDay = vipItem.endDay;
      }
      if (vipItem.formValues.type === 'bookFlight') {
        if (arrivalDeparture === 'arrival') {
          //airportCode = vipItem.formValues.flightDetailsSecond ? vipItem.formValues.flightDetailsSecond.tocode : vipItem.formValues.flightDetails.tocode;
          airportCode = vipItem.formValues.flightDetails.fromcode;
        } else {
          airportCode = vipItem.formValues.flightDetails.fromcode;
        }
        const airport = airports.find((airport) => airport.code === airportCode);
        airportName = airport.name;
      } else if (vipItem.formValues.type === 'flight') {
        if (arrivalDeparture === 'arrival') {
          airportCode = vipItem.formValues.flightDetailsSecond ? vipItem.formValues.flightDetailsSecond.tocode : vipItem.formValues.flightDetails.tocode;
        } else {
          airportCode = vipItem.formValues.flightDetails.fromcode;
        }
        const airport = airports.find((airport) => airport.code === airportCode);
        airportName = airport.name;
      } else if (vipItem.formValues.type === 'location') {
        const airport = airports.find((airport) => airport.code === vipItem.formValues.airportCodeVip);
        airportCode = vipItem.formValues.airportCodeVip;
        airportName = airport.name;
      } else if (vipItem.formValues.type === 'camp') {
        const airport = airports.find((airport) => airport.code === vipItem.formValues.airportCodeVip);
        airportCode = vipItem.formValues.airportCodeVip;
        airportName = airport.name;
      } else if (vipItem.formValues.type === 'ownArrangements') {
        if (arrivalDeparture === 'arrival') {
          airportCode = vipItem.formValues.arrivalAirportCodeVip;
        } else {
          airportCode = vipItem.formValues.departureAirportCodeVip;
        }
        const airport = airports.find((airport) => airport.code === airportCode);
        airportName = airport.name;
      }
      jsonSql.push({
        sequence: jsonSql.length + 1,
        bUuid: booking.id,
        type: 'vipAirportService',
        startDay:  startDay || '', // ROGER TO ADD
        creatioType: arrivalDeparture === 'arrival' ? 'arrivalVipAirportService' : 'departureVipAirportService',
        supplierName: arrivalDeparture === 'arrival' ? vipItem.formValues.arrivalSupplierNameVip : vipItem.formValues.departureSupplierNameVip,
        supplierUid: arrivalDeparture === 'arrival' ? vipItem.formValues.arrivalSupplierUidVip : vipItem.formValues.departureSupplierUidVip,
        units: 1,
        totalPax: totalPax,
        perGroupPriceVip: arrivalDeparture === 'arrival' ? vipItem.formValues.arrivalPerGroupPriceVip : vipItem.formValues.departurePerGroupPriceVip,
        perPersonPriceVip: arrivalDeparture === 'arrival' ? vipItem.formValues.arrivalPerPersonPriceVip : vipItem.formValues.departurePerPersonPriceVip,
        totalPrice: arrivalDeparture === 'arrival' ? vipItem.formValues.arrivalSaleTotalVip : vipItem.formValues.departureSaleTotalVip,
        airportCode: airportCode,
        airportName: airportName,
        description: `VIP meet and assist at ${airportName}`,
      });
    }

    function processVipType(vipTransfer, arrivalDeparture) {
      if (vipTransfer.objType === 'arrival') {
        return 'arrival';
      } else if (vipTransfer.objType === 'departure') {
        return 'arrival';
      } else if (vipTransfer.objType === 'propertyTransfer') {
        if (arrivalDeparture === 'arrival') {
          return 'departure';
        } else if (arrivalDeparture === 'departure') {
          return 'arrival';
        }
      }
    }

    // PROCESS ROOMS
    function getRoomDetails(bookings, id, config) {
      // Filter out the booking with the given propUid
      const booking = bookings.find((booking) => booking.id === id);

      if (!booking) {
        return 'No booking found for the given propUid.';
      }

      // Create a map to count the rooms
      const roomCountMap = {};

      booking.rooms.forEach((room) => {
        if (room.roomType !== 'Vehicle') {
          const adultCount = booking.rooms.filter((r) => r.selectedName === room.selectedName).reduce((count, r) => count + r.selectedAgesAdults.length, 0);

          const childAges = booking.rooms.filter((r) => r.selectedName === room.selectedName).reduce((ages, r) => [...ages, ...r.selectedAges.map((child) => child.age)], []);
          let roomKey;
          if (config) {
            roomKey = `${room.selectedName} (${adultCount > 0 ? 'A,'.repeat(adultCount).slice(0, -1) : ''}${childAges.length > 0 ? `,${childAges.join(',')}` : ''}) - ${
              room.roomConfig ? (room.roomConfig !== 'Other' ? room.roomConfig : room.customRoomConfig) : ''
            }`;
          } else {
            roomKey = `${room.selectedName} (${adultCount > 0 ? 'A,'.repeat(adultCount).slice(0, -1) : ''}${childAges.length > 0 ? `,${childAges.join(',')}` : ''})`;
          }
          roomCountMap[roomKey] = (roomCountMap[roomKey] || 0) + 1;
        }
      });

      console.log('roomCountMap', JSON.stringify(roomCountMap, null, 2));

      // Convert the map to the desired format
      const roomDetails = Object.entries(roomCountMap)
        .map(([roomKey, count]) => `${count}x ${roomKey}`)
        .join(' | ');
      console.log('roomDetails', JSON.stringify(roomDetails, null, 2));
      // Split the room details by the '|' delimiter, sort alphabetically based on room name, and join back together
      const sortedRoomDetails = roomDetails
        .split(' | ')
        .sort((a, b) => {
          const roomNameA = a.match(/\b[A-Za-z][A-Za-z\s-]*\b/)[0];
          const roomNameB = b.match(/\b[A-Za-z][A-Za-z\s-]*\b/)[0];
          return roomNameA.localeCompare(roomNameB);
        })
        .join(' | ');

      console.log('sortedRoomDetails', JSON.stringify(sortedRoomDetails, null, 2));

      return sortedRoomDetails;
      //return roomDetails;
    }

    // ARRIVAL TRANSFERS
    for (const transfer of arrivalTransfers) {
      const filteredTransferData = finalPageTransferData.filter((item) => item.propUid === booking.propUid && item.uuid === booking.id);

      let formValues = { ...transfer.formValues };

      if (formValues && (formValues.type === 'bookFlight' || formValues.type === 'flight')) {
        if (formValues.flightDetails && formValues.flightDetails.classes && formValues.flightDetails.flightsArray) {
          delete formValues.flightDetails.classes;
          delete formValues.flightDetails.flightsArray;
        }

        // TRANSFER
        let airlineDetails = await loadAirlineDetails(transfer.formValues.flightDetails.airlineDesignator);
        let airlineDetailsSecond = false;
        if (formValues.flightType === 'Multiple') {
          airlineDetailsSecond = await loadAirlineDetails(transfer.formValues.flightDetailsSecond.airlineDesignator);
        }
        //const airportTo = airports.find((airport) => airport.code === formValues.flightDetails.tocode);
        const airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.flightDetails.tocode));

        //const airportFrom = airports.find((airport) => airport.code === formValues.flightDetails.fromcode);
        const airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.flightDetails.fromcode));
        console.log('FIXING AIRPORTS');
        console.log(JSON.stringify(formValues, undefined, 4));
        console.log(JSON.stringify(airportTo, undefined, 4));
        console.log(JSON.stringify(airportFrom, undefined, 4));

        let creatioType;
        let adjustedCalculatedTotal = false;
        if (formValues.type === 'flight') {
          adjustedCalculatedTotal = 0;
          creatioType = 'scheduledFlight';
        } else if (formValues.type === 'bookFlight') {
          adjustedCalculatedTotal = 0;
          if (transfer.objType === 'arrival') {
            creatioType = 'arrivalScheduledFlight';
          } else if (transfer.objType === 'departure') {
            creatioType = 'departureScheduledFlight';
          } else {
            creatioType = null;
          }
        } else if (formValues.type === 'camp') {
          if (transfer.objType === 'arrival') {
            creatioType = 'arrivalOwnArrangements';
          } else if (transfer.objType === 'departure') {
            creatioType = 'departureOwnArrangements';
          } else {
            creatioType = null;
          }
        } else if (formValues.type === 'ownArrangements') {
          creatioType = 'ownArrangements';
        } else {
          creatioType = null;
        }

        // CUSTOM FINAL ITEMS add customFinalItems ---------------------------------------------- arrival customFinalItems
        let searchId;
        let foundItems;
        searchId = transfer.tuuid;
        foundItems = customFinalItems.filter(
          (search) => search.vip && search.arrivalDeparture === processVipType(transfer, 'departure') && search.id === searchId && !search.departure && !search.pickupDropoff,
        );
        processFoundItems(foundItems);

        if (transfer.formValues.includeArrivalVip) {
          processVipItem(transfer, 'arrival');
        }

        searchId = transfer.tuuid;
        foundItems = customFinalItems.filter((search) => !search.vip && search.id === searchId && !search.departure && !search.pickupDropoff);
        processFoundItems(foundItems);

        let connectingName = false;
        if (formValues.flightType === 'Multiple') {
          connectingName = getAirportName(airports, formValues.flightDetailsSecond.fromcode);
        }
        const multiAirline = airlineDetailsSecond ? (airlineDetailsSecond.supplier.id === airlineDetails.supplier.id ? false : true) : false;
        jsonSql.push({
          sequence: jsonSql.length + 1,
          bUuid: booking.id,
          type: 'transfer',
          transferType: transfer.objType,
          startDay: moment(transfer.startDay).format('YYYY-MM-DD'),
          propertyUid: transfer.propUid,
          propertyName: getPropertyName(transfer.propUid, properties),
          originPropertyLocation: getPropertyLocation(transfer.propUid, properties),
          destinationPropertyLocation: getPropertyLocation(nextPropertyUid, properties),
          originPropertyName: getPropertyName(booking.propUid, properties),
          destinationPropertyName: nextPropertyName,
          originPropertyUid: booking.propUid,
          destinationPropertyUid: nextPropertyUid,
          destinationAirportLocation: airportTo.location.id,
          originAirportLocation: airportFrom.location.id,
          supplierName: airlineDetails.supplier.name,
          supplierUid: airlineDetails.supplier.id,
          //...(airlineDetailsSecond  ? { supplierNameSecond: airlineDetailsSecond.supplier.name } : {}),
          //...(airlineDetailsSecond ? { supplierUidSecond: airlineDetailsSecond.supplier.id } : {}),
          ...(airlineDetailsSecond ? { multiAirline: multiAirline } : {}),
          ...(airlineDetailsSecond && multiAirline ? { flight: 1 } : {}),
          originAirportName: getAirportName(airports, formValues.flightDetails.fromcode) !== null ? getAirportName(airports, formValues.flightDetails.fromcode) : null,
          //destinationAirportName: getAirportName(airports, formValues.flightDetails.tocode) !== null ? getAirportName(airports, formValues.flightDetails.tocode) : null,
          destinationAirportName:
            formValues.flightType === 'Multiple'
              ? getAirportName(airports, formValues.flightDetailsSecond.tocode) !== null
                ? getAirportName(airports, formValues.flightDetailsSecond.tocode)
                : null
              : getAirportName(airports, formValues.flightDetails.tocode) !== null
              ? getAirportName(airports, formValues.flightDetails.tocode)
              : null,
          formValues: formValues,
          creatioType: creatioType,
          ...(adjustedCalculatedTotal !== false ? { adjustedCalculatedTotal: adjustedCalculatedTotal } : {}),
          ...(connectingName ? { connectingAirportName: connectingName } : {}),
        });

        // Add second object for second flight
        if (formValues.flightType === 'Multiple' && multiAirline) {
          jsonSql.push({
            sequence: jsonSql.length + 1,
            bUuid: booking.id,
            type: 'transfer',
            transferType: transfer.objType,
            startDay: moment(transfer.startDay).format('YYYY-MM-DD'),
            propertyUid: transfer.propUid,
            propertyName: getPropertyName(transfer.propUid, properties),
            originPropertyLocation: getPropertyLocation(transfer.propUid, properties),
            destinationPropertyLocation: getPropertyLocation(nextPropertyUid, properties),
            originPropertyName: getPropertyName(booking.propUid, properties),
            destinationPropertyName: nextPropertyName,
            originPropertyUid: booking.propUid,
            destinationPropertyUid: nextPropertyUid,
            destinationAirportLocation: airportTo.location.id,
            originAirportLocation: airportFrom.location.id,
            //supplierName: airlineDetails.supplier.name,
            //supplierUid: airlineDetails.supplier.id,
            ...(airlineDetailsSecond ? { supplierName: airlineDetailsSecond.supplier.name } : {}),
            ...(airlineDetailsSecond ? { supplierUid: airlineDetailsSecond.supplier.id } : {}),
            ...(airlineDetailsSecond ? { multiAirline: multiAirline } : {}),
            ...(airlineDetailsSecond && multiAirline ? { flight: 2 } : {}),
            originAirportName: getAirportName(airports, formValues.flightDetails.fromcode) !== null ? getAirportName(airports, formValues.flightDetails.fromcode) : null,
            //destinationAirportName: getAirportName(airports, formValues.flightDetails.tocode) !== null ? getAirportName(airports, formValues.flightDetails.tocode) : null,
            destinationAirportName:
              formValues.flightType === 'Multiple'
                ? getAirportName(airports, formValues.flightDetailsSecond.tocode) !== null
                  ? getAirportName(airports, formValues.flightDetailsSecond.tocode)
                  : null
                : getAirportName(airports, formValues.flightDetails.tocode) !== null
                ? getAirportName(airports, formValues.flightDetails.tocode)
                : null,
            formValues: formValues,
            creatioType: creatioType,
            ...(adjustedCalculatedTotal !== false ? { adjustedCalculatedTotal: adjustedCalculatedTotal } : {}),
            ...(connectingName ? { connectingAirportName: connectingName } : {}),
          });
        }

        // if(transfer.formValues.includeArrivalVip){
        //   processVipItem(transfer, 'arrival');
        // }
      } else {
        // TRANSFER
        let creatioType;
        let adjustedCalculatedTotal = false;
        let supplierName = formValues.supplierName;
        let supplierUid = formValues.supplierUid;
        if (formValues.type === 'flight') {
          adjustedCalculatedTotal = 0;
          creatioType = 'scheduledFlight';
        } else if (formValues.type === 'bookFlight') {
          adjustedCalculatedTotal = 0;
          if (transfer.objType === 'arrival') {
            creatioType = 'arrivalScheduledFlight';
          } else if (transfer.objType === 'departure') {
            creatioType = 'departureScheduledFlight';
          } else {
            creatioType = null;
          }
        } else if (formValues.type === 'location' || formValues.type === 'safari') {
          if (transfer.objType === 'arrival') {
            creatioType = 'arrivalRoadTransferTown';
          } else if (transfer.objType === 'departure') {
            creatioType = 'departureRoadTransferTown';
          } else if (transfer.objType === 'propertyTransfer') {
            creatioType = 'roadTransferTown';
          }
        } else if (formValues.type === 'camp') {
          if (transfer.objType === 'arrival') {
            creatioType = 'arrivalOwnArrangements';
            supplierName = 'Booked Direct';
            supplierUid = '8ae5bed6-e15e-4e79-8ab0-e934c2645abc';
          } else if (transfer.objType === 'departure') {
            creatioType = 'departureOwnArrangements';
            supplierName = 'Booked Direct';
            supplierUid = '8ae5bed6-e15e-4e79-8ab0-e934c2645abc';
          } else {
            creatioType = null;
          }
        } else if (formValues.type === 'ownArrangements') {
          if (formValues.airportTransfer) {
            creatioType = 'ownArrangementsFlight';
          } else {
            creatioType = 'ownArrangements';
          }
        } else {
          creatioType = null;
        }

        // CUSTOM FINAL ITEMS add customFinalItems ---------------------------------------------- arrival customFinalItems fix
        let searchId;
        let foundItems;
        searchId = transfer.tuuid;
        foundItems = customFinalItems.filter((search) => search.vip && search.arrivalDeparture === processVipType(transfer, 'departure') && search.id === searchId && !search.departure && !search.pickupDropoff);
        console.log('foundItems transfer 1', JSON.stringify(foundItems, null, 2));
        processFoundItems(foundItems);

        if (transfer.formValues.includeArrivalVip) {
          processVipItem(transfer, 'arrival');
        }

        searchId = transfer.tuuid;
        foundItems = customFinalItems.filter((search) => !search.vip && search.id === searchId && !search.departure && !search.pickupDropoff);
        console.log('foundItems transfer 1', JSON.stringify(foundItems, null, 2));
        processFoundItems(foundItems);

        jsonSql.push({
          sequence: jsonSql.length + 1,
          bUuid: booking.id,
          type: 'transfer',
          transferType: transfer.objType,
          startDay: moment(transfer.startDay).format('YYYY-MM-DD'),
          propertyUid: transfer.propUid,
          propertyName: getPropertyName(transfer.propUid, properties),
          originPropertyLocation: getPropertyLocation(transfer.propUid, properties),
          destinationPropertyLocation: getPropertyLocation(nextPropertyUid, properties),
          originPropertyName: getPropertyName(booking.propUid, properties),
          destinationPropertyName: nextPropertyName,
          originPropertyUid: booking.propUid,
          destinationPropertyUid: nextPropertyUid,
          supplierName: supplierName,
          supplierUid: supplierUid,
          formValues: formValues,
          creatioType: creatioType,
          ...(adjustedCalculatedTotal !== false ? { adjustedCalculatedTotal: adjustedCalculatedTotal } : {}),
          ...(transfer.formValues && transfer.formValues.transferRouteUid ? { transferRouteUid: transfer.formValues.transferRouteUid } : {}),
        });

        
      }

      // FInal page filteredTransferData
      console.log('filteredTransferData');
      console.log(JSON.stringify(filteredTransferData, undefined, 4));
      filteredTransferData.forEach((finalBooking) => {
        finalBooking.transferItems.forEach((item) => {
          if (finalBooking.objType === 'arrival' && ((transfer.formValues && transfer.formValues.type === 'bookFlight') || (transfer.formValues && transfer.formValues.type === 'flight'))) {
            jsonSql.push({
              sequence: jsonSql.length + 1,
              bUuid: booking.id,
              type: 'extraTransfers',
              creatioType: 'extraTransfers',
              propertyUid: booking.propUid,
              propertyName: getPropertyName(booking.propUid, properties),
              supplierName: getSupplierName(item.supplier, suppliers),
              supplierUid: item.supplier,
              startDay: moment(booking.startDay).format('YYYY-MM-DD'),
              feeType: item.feeTypeName ? item.feeTypeName : item.feeType ? item.feeType : '',
              ...(item.feeTypeUid ? { feeTypeUid: item.feeTypeUid } : {}),
              description: item.description,
              units: item.units,
              pax: item.pax,
              net: item.net,
              saleTotal: Number(parseFloat(item.total).toFixed(2)),
              star: item.star,
              uuid: item.uuid,
            });
          } else if (item.star && item.edit && finalBooking.objType === 'arrival') {
            // Check if item.star is true
            jsonSql.push({
              sequence: jsonSql.length + 1,
              bUuid: booking.id,
              type: 'extraTransfers',
              creatioType: 'extraTransfers',
              propertyUid: booking.propUid,
              propertyName: getPropertyName(booking.propUid, properties),
              supplierName: getSupplierName(item.supplier, suppliers),
              supplierUid: item.supplier,
              startDay: moment(booking.startDay).format('YYYY-MM-DD'),
              feeType: item.feeTypeName ? item.feeTypeName : item.feeType ? item.feeType : '',
              ...(item.feeTypeUid ? { feeTypeUid: item.feeTypeUid } : {}),
              description: item.description,
              units: item.units,
              pax: item.pax,
              net: item.net,
              saleTotal: Number(parseFloat(item.total).toFixed(2)),
              star: item.star,
              uuid: item.uuid,
            });
          } else if (item.star && !item.edit && finalBooking.objType === 'arrival' && item.include) {
            // Check if item.star is true
            jsonSql.push({
              sequence: jsonSql.length + 1,
              bUuid: booking.id,
              type: 'extraTransfers',
              creatioType: 'extraTransfers',
              propertyUid: booking.propUid,
              propertyName: getPropertyName(booking.propUid, properties),
              supplierName: getSupplierName(item.supplier, suppliers),
              supplierUid: item.supplier,
              startDay: moment(booking.endDay).format('YYYY-MM-DD'),
              feeType: item.feeTypeName ? item.feeTypeName : item.feeType ? item.feeType : '',
              ...(item.feeTypeUid ? { feeTypeUid: item.feeTypeUid } : {}),
              description: item.description,
              units: item.units,
              pax: item.pax,
              net: item.net,
              saleTotal: Number(parseFloat(item.total).toFixed(2)),
              star: item.star,
              uuid: item.uuid,
            });
          }
        });
      });

      // EXTRA AFTER TRANSFER ARRIVAL

      if (formValues.includeDepartureTransfer && formValues.type === 'bookFlight') {
        const airport = airports.find(
          (airport) =>
            airport.aerocrsCodes &&
            airport.aerocrsCodes.includes(formValues.flightType && formValues.flightType === 'Multiple' ? formValues.flightDetailsSecond.tocode : formValues.flightDetails.tocode),
        );
        await processExtras(transfer, 'pickup', airport.uid, airport.name, airport.code, airport.location.id, 'current', true);
      } else if (!formValues.includeDepartureTransfer && formValues.type === 'bookFlight') {
        const airport = airports.find(
          (airport) =>
            airport.aerocrsCodes &&
            airport.aerocrsCodes.includes(formValues.flightType && formValues.flightType === 'Multiple' ? formValues.flightDetailsSecond.tocode : formValues.flightDetails.tocode),
        );
        await processExtras(transfer, 'pickup', airport.uid, airport.name, airport.code, airport.location.id, 'current', true);
      }
    }

    // DEAL WITH PROPERTIES

    // get calculated total
    const filteredData = finalPageData.filter((item) => item.propUid === booking.propUid && item.uuid === booking.id);
    const filteredTransferData = finalPageTransferData.filter((item) => item.propUid === booking.propUid && item.uuid === booking.id);
    // const filteredDataFalseStar = finalPageData.filter((item) => item.propUid === booking.propUid && item.uuid === booking.id);

    let calculatedTotal = 0;
    if (filteredData && filteredData.length > 0) {
      calculatedTotal = filteredData.reduce((acc, item) => {
        const propertyItemTotal = item.propertyItems.reduce((innerAcc, propertyItem) => {
          return innerAcc + propertyItem.total;
        }, 0);

        return acc + propertyItemTotal;
      }, 0);
    } else {
      // Iterate through the 'rooms' array and get the 'payable' || 'nett' value
      booking.rooms.forEach((room) => {
        if (room.roomType !== 'Vehicle') {
          if (room.rate && room.rate.payable) {
            calculatedTotal += room.rate.payable;
          } else if (room.rate && room.rate.nett) {
            calculatedTotal += room.rate.nett;
          }
        }
      });
    }

    let filteredFinalPageTotal = 0;
    if (filteredData && filteredData.length > 0) {
      filteredFinalPageTotal = filteredData.reduce((acc, item) => {
        const propertyItemTotal = item.propertyItems.reduce((innerAcc, propertyItem) => {
          //if ((propertyItem.star && !propertyItem.edit) || (!propertyItem.star && propertyItem.edit)) {
          // if ((propertyItem.star && !propertyItem.edit && propertyItem.feeType === `accommodation`) || (!propertyItem.star && propertyItem.edit)) {
          if (!propertyItem.star) {
            return innerAcc + propertyItem.total;
          } else {
            return innerAcc;
          }
        }, 0);
        return acc + propertyItemTotal;
      }, 0);
    } else {
      // Iterate through the 'rooms' array and get the 'payable' || 'nett' value
      booking.rooms.forEach((room) => {
        if (room.roomType !== 'Vehicle') {
          if (room.rate && room.rate.payable) {
            filteredFinalPageTotal += room.rate.payable;
          } else if (room.rate && room.rate.nett) {
            filteredFinalPageTotal += room.rate.nett;
          }
        }
      });
    }

    console.log('calculatePropertiesTotal');
    console.log(calculatedTotal);

    console.log('filteredFinalPageTotal');
    console.log(filteredFinalPageTotal);

    const adjustedCalculatedTotal = filteredFinalPageTotal;
    // Check if any room has room.rate.originalPayable
    const hasOriginalPayable = booking.rooms.some((room) => room.roomType !== 'Vehicle' && room.rate && room.rate.originalPayable !== undefined);

    // Calculate costPrice if necessary
    let costTotal = 0;
    if (hasOriginalPayable) {
      costTotal = booking.rooms.reduce((total, room) => {
        if (room.roomType !== 'Vehicle' && room.rate) {
          return total + room.rate.originalPayable;
        }
        return total;
      }, 0);
    }

    let cleanPropertyUid = booking.propUid;
    if (booking.propUid.indexOf('_D-I-R-E-C-T_') !== -1) {
      cleanPropertyUid = booking.propUid.split('_D-I-R-E-C-T_')[0];
    }

    // CUSTOM FINAL ITEMS add customFinalItems ----------------------------------------------
    let searchId = booking.id;
    const foundItems = customFinalItems.filter((search) => search.id === searchId && !search.departure && !search.pickupDropoff);
    console.log('foundItems transfer 2', JSON.stringify(foundItems, null, 2));
    processFoundItems(foundItems);

    const customLocation = findCustomLocation(customLocations, booking.propUid);

    // get arrival time
    // Find the current transfer
    //const currentTransfer = transfers.find((transfer) => transfer.propUid === booking.propUid);

    // Find the index of the current transfer in the transfers array
    const currentTransferIndex = transfers.findIndex((transfer) => transfer.propUid === booking.propUid);

    // Find the previous transfer based on the current booking's position in the bookings array
    const previousTransfer = bookings.indexOf(booking) === 0 ? transfers.find((transfer) => transfer.objType === 'arrival') : transfers[currentTransferIndex - 1];

    // Get the arrival time from the previous transfer's formValues
    const arrivalTime = previousTransfer && previousTransfer.formValues ? previousTransfer.formValues.arrivalTime || previousTransfer.formValues.timeOwnArrangements : null;
    // PROPERTIES Vehicle
    jsonSql.push({
      sequence: jsonSql.length + 1,
      bUuid: booking.id,
      type: 'property',
      creatioType: 'property',
      propertyUid: cleanPropertyUid,
      propertyName: getPropertyName(booking.propUid, properties),
      //safariPortalCode: getPropertySafariPortalCode(booking.propUid, properties),
      supplierName: getSupplier(booking.propUid, properties).name,
      supplierUid: getSupplier(booking.propUid, properties).id,
      startDay: moment(booking.startDay).format('YYYY-MM-DD'),
      endDay: moment(booking.endDay).format('YYYY-MM-DD'),
      nights: booking.days,
      rateType: booking.rateName,
      rateName: booking.rateFullName
        ? booking.rateFullName
        : properties[getPropObj(booking.propUid, properties)].supplier.name === 'Nomad Tanzania'
        ? rateLabelMap[booking.rateName]
        : properties[getPropObj(booking.propUid, properties)].thirdPartyRates.find((rateObj) => rateObj.type === booking.rateName).name,
      totalRooms: booking.rooms.filter((room) => room.roomType !== 'Vehicle').length,
      totalAdults: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.ad : 0), 0),
      totalYoungAdults: 0,
      totalChildren: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.ch : 0), 0),
      totalInfants: 0,
      // totalYoungAdults: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.ch : 0), 0),
      // totalChildren: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.chi : 0), 0),
      // totalInfants: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.inf : 0), 0),
      totalAllPax: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.ad + room.ch + room.chi + room.inf : 0), 0),
      saleTotal: Number(
        parseFloat(
          booking.rooms
            .reduce((total, room) => {
              if (room.roomType !== 'Vehicle' && room.rate) {
                return total + (room.rate.nett || room.rate.payable || 0);
              }
              return total;
            }, 0)
            .toFixed(2),
        ),
      ),

      calculatedTotal: calculatedTotal,
      adjustedCalculatedTotal: adjustedCalculatedTotal,
      rooms: getRoomDetails(bookings, booking.id, false),
      roomsConfig: getRoomDetails(bookings, booking.id, true),
      ...(hasOriginalPayable ? { costTotal } : {}),
      safariPortalCode: customLocation.safariPortalCode !== '' ? customLocation.safariPortalCode : getPropertySafariPortalCode(booking.propUid, properties),
      ...(arrivalTime ? { arrivalTime } : {}),
    });

    //Final page
    console.log('filteredData');
    console.log(JSON.stringify(filteredData, undefined, 4));
    filteredData.forEach((finalBooking) => {
      finalBooking.propertyItems.forEach((item) => {
        //if (item.star && item.edit) {
        //if (item.star && (item.edit || (!item.edit && item.feeType !== `accommodation`))) {
        // Changed to show all accommodation items in json object
        if (item.star) {
          // Check if item.star is true
          jsonSql.push({
            sequence: jsonSql.length + 1,
            bUuid: booking.id,
            type: 'extra',
            creatioType: 'extra',
            propertyUid: booking.propUid,
            propertyName: getPropertyName(booking.propUid, properties),
            supplierName: getSupplierName(item.supplier, suppliers),
            supplierUid: item.supplier,
            startDay: moment(booking.startDay).format('YYYY-MM-DD'),
            endDay: moment(booking.endDay).format('YYYY-MM-DD'),
            propertyNights: booking.days,
            propertyAdults: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.ad : 0), 0),
            propertyYoungAdults: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.ch : 0), 0),
            propertyChildren: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.chi : 0), 0),
            propertyInfants: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.inf : 0), 0),
            propertyTotalAllPax: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.ad + room.ch + room.chi + room.inf : 0), 0),
            feeType: item.feeTypeName ? item.feeTypeName : item.feeType ? item.feeType : '',
            ...(item.feeTypeUid ? { feeTypeUid: item.feeTypeUid } : {}),
            description: item.description,
            units: item.units,
            pax: item.pax,
            net: item.net,
            saleTotal: Number(parseFloat(item.total).toFixed(2)),
            star: item.star,
            uuid: item.uuid,
          });
        }
      });
    });

    // CUSTOM FINAL ITEMS add customFinalItems ---------------------------------------------- NEW FIX AFTER PROPERTY
    //let searchId = transfer.tuuid;
    const foundItemsDeparture = customFinalItems.filter((search) => search.id === searchId && search.departure && !search.pickupDropoff);
    console.log('foundItems after property', JSON.stringify(foundItems, null, 2));
    processFoundItems(foundItemsDeparture);

    // VEHICLES vehiclesSupplementCost
    // const vehiclesResult = getVehicleRoomDetailsNotSupplement(booking);

    // let vehiclesSupplementCost;
    // let typeName;
    // if (vehiclesResult.northernGuideVehicleSupplement[0] && booking.northernGuideVehiclePrice) {
    //   vehiclesSupplementCost = Number(booking.northernGuideVehiclePrice);
    //   typeName = 'vehiclesSupplement';
    // } else {
    //   vehiclesSupplementCost = 0;
    //   typeName = vehiclesResult.types[0] === 'camp' ? 'vehiclesNomadCamp' : 'vehiclesNomadGuide';
    // }

    // if (vehiclesResult.exists) {
    //   // Vehicles
    //   jsonSql.push({
    //     sequence: jsonSql.length + 1,
    //     type: typeName,
    //     creatioType: typeName,
    //     supplierName: 'Nomad Tanzania',
    //     supplierUid: 'ded3a3ed-aeaf-4495-9069-7754a649de67',
    //     propertyName: getPropertyName(booking.propUid, properties),
    //     startDay: moment(booking.startDay).format('YYYY-MM-DD'),
    //     endDay: moment(booking.endDay).format('YYYY-MM-DD'),
    //     days: booking.days + 1,
    //     nights: booking.days,
    //     rooms: vehiclesResult.names,
    //     totalAdults: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.ad : 0), 0),
    //     totalYoungAdults: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.ch : 0), 0),
    //     totalChildren: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.chi : 0), 0),
    //     totalInfants: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.inf : 0), 0),
    //     totalAllPax: booking.rooms.reduce((total, room) => total + (room.roomType !== 'Vehicle' ? room.ad + room.ch + room.chi + room.inf : 0), 0),
    //     units: vehiclesResult.count,
    //     unitCost: vehiclesSupplementCost,
    //     //saleTotal: vehiclesResult.count * vehiclesSupplementCost * (booking.days + 1),   OLD
    //     saleTotal: vehiclesResult.count * vehiclesSupplementCost * booking.days,
    //   });
    // }

    // ACTIVITIES
    activitiesData
      .filter((activity) => activity.propUid === booking.propUid && activity.id === booking.id)
      .forEach((activity) => {
        activity.activities
          .filter((day) => day.length > 0)
          .forEach((day) => {
            day.forEach((singleActivity) => {
              // CUSTOM FINAL ITEMS add customFinalItems ----------------------------------------------
              let searchId = singleActivity.uuid;
              const foundItems = customFinalItems.filter((search) => search.id === searchId && !search.departure && !search.pickupDropoff);
              console.log('foundItems', JSON.stringify(foundItems, null, 2));
              processFoundItems(foundItems);

              jsonSql.push({
                sequence: jsonSql.length + 1,
                bUuid: booking.id,
                type: 'activity',
                creatioType: 'activity',
                startDay: singleActivity.selectedActivityDate,
                propertyUid: activity.propUid,
                propertyName: getPropertyName(activity.propUid, properties),
                activityUid: singleActivity.uid,
                activityName: singleActivity.name,
                displayName: singleActivity.displayName,
                supplierName: singleActivity.supplier.name,
                supplierUid: singleActivity.supplier.id,
                quantity: singleActivity.activityUnits,
                price: singleActivity.costPerUnit,
                totalPrice: singleActivity.cost,
                priceType: singleActivity.priceType,
              });

              let searchIActivity = singleActivity.uuid;
              const foundItemsActivity = customFinalItems.filter((search) => search.id === searchIActivity && search.departure);
              console.log('foundItems activity 1', JSON.stringify(foundItemsActivity, null, 2));
              processFoundItems(foundItemsActivity);
            });
          });
      });

    //deal with picnics - packed lunch
    // for (const transfer of propertyTransfers) {
    //   let formValues = { ...transfer.formValues };
    //   if (formValues && formValues.packedLunch) {
    //     let unitPrice = 0;
    //     const extras = properties[getPropObj(booking.propUid, properties)].extras;
    //     if (extras && extras.length > 0) {
    //       const unitPriceType = booking.propUid.includes('_D-I-R-E-C-T_') ? 'rackPrice' : 'stoPrice';
    //       let extra = findValidPackedLunch(extras, booking.endDay, unitPriceType);

    //       // if (formValues.packedLunchChargeable) {
    //       //   unitPrice = extra ? (unitPriceType === 'rackPrice' ? extra.rackPrice : extra.stoPrice) : 0;
    //       // }

    //       // const units = booking.rooms.reduce((total, room) => total + (room.roomType === 'Room' ? room.ad + room.ch + room.chi + room.inf : 0), 0);
    //       if (extra) {
    //         const guideVehicles = countNorthernGuideVehiclesInBooking(booking);
    //         jsonSql.push({
    //           sequence: jsonSql.length + 1,
    //           type: 'transferPicnics',
    //           creatioType: 'transferPicnics',
    //           propertyUid: booking.propUid,
    //           propertyName: getPropertyName(booking.propUid, properties),
    //           startDay: booking.endDay,
    //           supplierName: getSupplier(booking.propUid, properties).name,
    //           supplierUid: getSupplier(booking.propUid, properties).id,
    //           units: formValues.packedLunchUnits,
    //           unitPrice: formValues.packedLunchUnitPrice,
    //           saleTotal: formValues.packedLunchSaleTotal,
    //           chargeable: formValues.packedLunchChargeable,
    //           guideVehicles: guideVehicles, // calculate number of guide vehicles
    //         });
    //       }
    //     }
    //   }
    // }

    // Process property & departure transfers
    for (const transfer of propertyTransfers) {
      let formValues = { ...transfer.formValues };
      if (formValues && formValues.type === 'ownArrangements') {
        if (formValues.flightDetails && formValues.flightDetails.classes) {
          delete formValues.flightDetails.classes;
          delete formValues.flightDetails.flightsArray;
        }
      }

      // EXTRA BEFORE TRANSFER

      if (formValues.includeDepartureTransfer && formValues.type === 'flight') {
        const airport = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.flightDetails.fromcode));
        await processExtras(transfer, 'dropoff', airport.uid, airport.name, airport.code, airport.location.id, 'current', true);
      } else if (formValues.includeDepartureTransfer && formValues.type === 'bookFlight') {
        console.log('errror');
        console.log(formValues.flightDetails.fromcode);
        console.log(JSON.stringify(airports, undefined, 4));
        const airport = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.flightDetails.fromcode));
        await processExtras(transfer, 'dropoff', airport.uid, airport.name, airport.code, airport.location.id, 'current', true); // just edited
      } else if (formValues.includeDepartureTransfer && formValues.type === 'ownArrangements') {
        await processExtras(
          transfer,
          'dropoff',
          transfer.formValues.originAirportUid,
          transfer.formValues.originAirportName,
          transfer.formValues.originAirportCode,
          transfer.formValues.originAirportLocationUid,
          'current',
          true,
        );
      }
      // Extra own arrangements
      if (!formValues.includeDepartureTransfer && formValues.type === 'flight') {
        const airport = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.flightDetails.fromcode));
        await processExtras(transfer, 'dropoff', airport.uid, airport.name, airport.code, airport.location.id, 'current', true);
      } else if (!formValues.includeDepartureTransfer && formValues.type === 'bookFlight') {
        console.log('errror');
        console.log(formValues.flightDetails.fromcode);
        console.log(JSON.stringify(airports, undefined, 4));
        const airport = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.flightDetails.fromcode));
        await processExtras(transfer, 'dropoff', airport.uid, airport.name, airport.code, airport.location.id, 'current', true);
      } else if (!formValues.includeDepartureTransfer && formValues.type === 'ownArrangements' && formValues.airportTransfer) {
        await processExtras(
          transfer,
          'dropoff',
          transfer.formValues.originAirportUid,
          transfer.formValues.originAirportName,
          transfer.formValues.originAirportCode,
          transfer.formValues.originAirportLocationUid,
          'current',
          true,
        );
      }

      let getAirport = null;

      if (formValues.includeDepartureTransfer) {
        getAirport = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.originAirportCode));
        //getAirport = airports.find((airport) => airport.code === formValues.originAirportCode);
      }

      let getAirportTocode = null;
      let getAirportFromcode = null;
      if (formValues.type === 'bookFlight' || formValues.type === 'flight') {
        getAirportTocode = formValues.flightDetails.tocode;
        getAirportFromcode = formValues.flightDetails.fromcode;
      } else if (formValues.type === 'ownArrangements' || formValues.type === 'location') {
        getAirportTocode = 'na';
        getAirportFromcode = 'na';
      }

      // delete classes and flightsArray from formValues
      if (formValues.flightDetails && formValues.flightDetails.classes && formValues.flightDetails.flightsArray) {
        delete formValues.flightDetails.classes;
        delete formValues.flightDetails.flightsArray;
      }
      // TRANSFER with check for road transfers
      let creatioType;
      let adjustedCalculatedTotal = false;
      let supplierName = getSupplier(booking.propUid, properties).name;
      let supplierUid = getSupplier(booking.propUid, properties).id;

      if (formValues.type === 'flight') {
        creatioType = 'scheduledFlight';
        adjustedCalculatedTotal = 0;
      } else if (formValues.type === 'bookFlight') {
        adjustedCalculatedTotal = 0;
        if (transfer.objType === 'arrival') {
          creatioType = 'arrivalScheduledFlight';
        } else if (transfer.objType === 'departure') {
          creatioType = 'departureScheduledFlight';
        } else {
          creatioType = null;
        }
      } else if (formValues.type === 'location' || formValues.type === 'safari') {
        if (transfer.objType === 'arrival') {
          creatioType = 'arrivalRoadTransferTown';
        } else if (transfer.objType === 'departure') {
          creatioType = 'departureRoadTransferTown';
        } else if (transfer.objType === 'propertyTransfer') {
          creatioType = 'roadTransferTown';
        }
      } else if (formValues.type === 'camp') {
        if (transfer.objType === 'arrival') {
          creatioType = 'arrivalOwnArrangements';
          supplierName = 'Booked Direct';
          supplierUid = '8ae5bed6-e15e-4e79-8ab0-e934c2645abc';
        } else if (transfer.objType === 'departure') {
          creatioType = 'departureOwnArrangements';
          supplierName = 'Booked Direct';
          supplierUid = '8ae5bed6-e15e-4e79-8ab0-e934c2645abc';
        } else {
          creatioType = null;
        }
      } else if (formValues.type === 'ownArrangements') {
        supplierName = 'Booked Direct';
        supplierUid = '8ae5bed6-e15e-4e79-8ab0-e934c2645abc';
        if (formValues.airportTransfer) {
          creatioType = 'ownArrangementsFlight';
        } else {
          creatioType = 'ownArrangements';
        }
      } else {
        creatioType = null;
      }

      const transferObj = {
        sequence: jsonSql.length + 1,
        bUuid: booking.id,
        type: 'transfer',
        transferType: transfer.objType,
        startDay: moment(transfer.endDay).format('YYYY-MM-DD'),
        propertyUid: transfer.propUid,
        propertyName: getPropertyName(transfer.propUid, properties),
        originPropertyLocation: getPropertyLocation(transfer.propUid, properties),
        destinationPropertyLocation: getPropertyLocation(nextPropertyUid, properties),
        originPropertyName: getPropertyName(booking.propUid, properties),
        destinationPropertyName: nextPropertyName,
        originPropertyUid: booking.propUid,
        destinationPropertyUid: nextPropertyUid,
        supplierName: supplierName,
        supplierUid: supplierUid,
        formValues: formValues,
        creatioType: creatioType,
        ...(adjustedCalculatedTotal !== false ? { adjustedCalculatedTotal: adjustedCalculatedTotal } : {}),
        ...(transfer.formValues && transfer.formValues.transferRouteUid ? { transferRouteUid: transfer.formValues.transferRouteUid } : {}),
      };

      // Conditionally add destinationAirportName and originAirportName
      if (formValues.type !== 'safari') {
        //   transferObj.destinationAirportName = getAirportTocode !== "na" ? getAirportName(airports, getAirportTocode) : "na";
        //   transferObj.originAirportName = getAirportFromcode !== "na" ? getAirportName(airports, getAirportFromcode) : "na";
        //const destinationName = getAirportTocode !== 'na' ? getAirportName(airports, getAirportTocode) : 'na';
        const destinationName = formValues.flightType === 'Multiple' ? getAirportName(airports, formValues.flightDetailsSecond.tocode) : getAirportName(airports, getAirportTocode);
        transferObj.destinationAirportName = destinationName !== null ? destinationName : null;

        const originName = getAirportFromcode !== 'na' ? getAirportName(airports, getAirportFromcode) : 'na';
        transferObj.originAirportName = originName !== null ? originName : null;
      }

      // deal with suppliers
      if (formValues.type === 'bookFlight' || formValues.type === 'flight') {
        let connectingName = false;
        let multiAirline = false;

        if (formValues.flightType === 'Multiple') {
          connectingName = getAirportName(airports, formValues.flightDetailsSecond.fromcode);
        }

        let airlineDetails = await loadAirlineDetails(transfer.formValues.flightDetails.airlineDesignator);
        let airlineDetailsSecond = false;
        let airportToSecond = false;

        if (formValues.flightType === 'Multiple') {
          airlineDetailsSecond = await loadAirlineDetails(transfer.formValues.flightDetailsSecond.airlineDesignator);
          airportToSecond = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.flightDetailsSecond.tocode));
          multiAirline = airlineDetailsSecond ? (airlineDetailsSecond.supplier.id === airlineDetails.supplier.id ? false : true) : false;
        }
        //const airportTo = airports.find((airport) => airport.code === formValues.flightDetails.tocode);
        const airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.flightDetails.tocode));

        console.log('airportTo');
        console.log(JSON.stringify(airportTo, undefined, 4));

        //const airportFrom = airports.find((airport) => airport.code === formValues.flightDetails.fromcode);

        const airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.flightDetails.fromcode));
        if (multiAirline) {
          transferObj.flight = 1;
          transferObj.multiAirline = multiAirline;
        } else {
          transferObj.multiAirline = multiAirline;
        }

        transferObj.supplierName = airlineDetails.supplier.name;
        transferObj.supplierUid = airlineDetails.supplier.id;
        //transferObj.destinationAirportLocation = airportTo.location.id;
        transferObj.destinationAirportLocation = formValues.flightType === 'Multiple' ? airportToSecond.location.id : airportTo.location.id;

        transferObj.originAirportLocation = airportFrom.location.id;
        if (formValues.flightType === 'Multiple') {
          //transferObj.supplierNameSecond = airlineDetailsSecond.supplier.name;
          //transferObj.supplierUidSecond = airlineDetailsSecond.supplier.id;
          if (connectingName) {
            transferObj.connectingAirportName = connectingName;
          }
        }
      } else if (formValues.type === 'location' || formValues.type === 'safari') {
        transferObj.supplierName = formValues.supplierName;
        transferObj.supplierUid = formValues.supplierUid;
      }

      // CUSTOM FINAL ITEMS add customFinalItems ----------------------------------------------
      let searchId = transfer.tuuid;
      let foundItems;
      foundItems = customFinalItems.filter((search) => search.vip && search.arrivalDeparture === processVipType(transfer, 'arrival') && search.id === searchId && !search.departure && !search.pickupDropoff);
      console.log('foundItems transfer 3', JSON.stringify(foundItems, null, 2));
      processFoundItems(foundItems);

      if (transfer.formValues.includeDepartureVip) {
        processVipItem(transfer, 'departure');
      }
      foundItems = customFinalItems.filter((search) => !search.vip && search.id === searchId && !search.departure && !search.pickupDropoff);
      console.log('foundItems transfer 3', JSON.stringify(foundItems, null, 2));
      processFoundItems(foundItems);

      // Push the object into jsonSql array
      jsonSql.push(transferObj);

      foundItems = customFinalItems.filter((search) => search.vip && search.arrivalDeparture === processVipType(transfer, 'departure') && search.id === searchId && !search.departure && !search.pickupDropoff);
      console.log('foundItems transfer 3', JSON.stringify(foundItems, null, 2));
      processFoundItems(foundItems);

      if (transfer.formValues.includeArrivalVip) {
        processVipItem(transfer, 'arrival');
      }

      if (formValues.type === 'bookFlight' || formValues.type === 'flight') {
        const transferObj2 = { ...transferObj };
        let airlineDetails = await loadAirlineDetails(transfer.formValues.flightDetails.airlineDesignator);
        let airlineDetailsSecond = false;
        //let airportToSecond = false;

        let multiAirline = false;

        if (formValues.flightType === 'Multiple') {
          airlineDetailsSecond = await loadAirlineDetails(transfer.formValues.flightDetailsSecond.airlineDesignator);
          // airportToSecond = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(formValues.flightDetailsSecond.tocode));
          multiAirline = airlineDetailsSecond ? (airlineDetailsSecond.supplier.id === airlineDetails.supplier.id ? false : true) : false;
        }
        // const multiAirline = airlineDetailsSecond ? airlineDetailsSecond.supplier.id === airlineDetails.supplier.id ? false : true : false;

        if (formValues.flightType === 'Multiple' && multiAirline) {
          transferObj2.flight = 2;
          transferObj2.sequence = jsonSql.length + 1;
          transferObj2.multiAirline = multiAirline;
          transferObj2.supplierName = airlineDetailsSecond.supplier.name;
          transferObj2.supplierUid = airlineDetailsSecond.supplier.id;
          jsonSql.push(transferObj2);
        }
      }

      // FInal page filteredTransferData
      console.log('filteredTransferData');
      console.log(JSON.stringify(filteredTransferData, undefined, 4));
      filteredTransferData.forEach((finalBooking) => {
        finalBooking.transferItems.forEach((item) => {
          if (finalBooking.objType !== 'arrival' && ((transfer.formValues && transfer.formValues.type === 'bookFlight') || (transfer.formValues && transfer.formValues.type === 'flight'))) {
            jsonSql.push({
              sequence: jsonSql.length + 1,
              bUuid: booking.id,
              type: 'extraTransfers',
              creatioType: 'extraTransfers',
              propertyUid: booking.propUid,
              propertyName: getPropertyName(booking.propUid, properties),
              supplierName: getSupplierName(item.supplier, suppliers),
              supplierUid: item.supplier,
              startDay: moment(booking.endDay).format('YYYY-MM-DD'),
              feeType: item.feeTypeName ? item.feeTypeName : item.feeType ? item.feeType : '',
              ...(item.feeTypeUid ? { feeTypeUid: item.feeTypeUid } : {}),
              description: item.description,
              units: item.units,
              pax: item.pax,
              net: item.net,
              saleTotal: Number(parseFloat(item.total).toFixed(2)),
              star: item.star,
              uuid: item.uuid,
            });
          } else if (item.star && item.edit && finalBooking.objType !== 'arrival') {
            // Check if item.star is true
            jsonSql.push({
              sequence: jsonSql.length + 1,
              bUuid: booking.id,
              type: 'extraTransfers',
              creatioType: 'extraTransfers',
              propertyUid: booking.propUid,
              propertyName: getPropertyName(booking.propUid, properties),
              supplierName: getSupplierName(item.supplier, suppliers),
              supplierUid: item.supplier,
              startDay: moment(booking.endDay).format('YYYY-MM-DD'),
              feeType: item.feeTypeName ? item.feeTypeName : item.feeType ? item.feeType : '',
              ...(item.feeTypeUid ? { feeTypeUid: item.feeTypeUid } : {}),
              description: item.description,
              units: item.units,
              pax: item.pax,
              net: item.net,
              saleTotal: Number(parseFloat(item.total).toFixed(2)),
              star: item.star,
              uuid: item.uuid,
            });
          } else if (item.star && !item.edit && finalBooking.objType !== 'arrival' && item.include) {
            // Check if item.star is true
            jsonSql.push({
              sequence: jsonSql.length + 1,
              bUuid: booking.id,
              type: 'extraTransfers',
              creatioType: 'extraTransfers',
              propertyUid: booking.propUid,
              propertyName: getPropertyName(booking.propUid, properties),
              supplierName: getSupplierName(item.supplier, suppliers),
              supplierUid: item.supplier,
              startDay: moment(booking.endDay).format('YYYY-MM-DD'),
              feeType: item.feeTypeName ? item.feeTypeName : item.feeType ? item.feeType : '',
              ...(item.feeTypeUid ? { feeTypeUid: item.feeTypeUid } : {}),
              description: item.description,
              units: item.units,
              pax: item.pax,
              net: item.net,
              saleTotal: Number(parseFloat(item.total).toFixed(2)),
              star: item.star,
              uuid: item.uuid,
            });
          }
        });
      });
      // CUSTOM FINAL ITEMS add customFinalItems ---------------------------------------------- NEW AFTER EXTRAS
      //let searchId = transfer.tuuid;
      const foundItemsDepartureNew = customFinalItems.filter((search) => search.id === searchId && search.departure && !search.pickupDropoff);
      console.log('foundItems after extras', JSON.stringify(foundItemsDepartureNew, null, 2));
      processFoundItems(foundItemsDepartureNew);

      // EXTRA AFTER TRANSFER NOT ARRIVAL
      if (formValues.includeArrivalTransfer && formValues.type === 'flight') {
        const airport = airports.find(
          (airport) =>
            airport.aerocrsCodes &&
            airport.aerocrsCodes.includes(formValues.flightType && formValues.flightType === 'Multiple' ? formValues.flightDetailsSecond.tocode : formValues.flightDetails.tocode),
        );
        await processExtras(transfer, 'pickup', airport.uid, airport.name, airport.code, airport.location.id, 'next', true);
      } else if (formValues && formValues.type === 'ownArrangements') {
        if (formValues.includeArrivalTransfer) {
          // const airport = airports.find(
          //   (airport) =>
          //     airport.aerocrsCodes &&
          //     airport.aerocrsCodes.includes(formValues.flightType && formValues.flightType === 'Multiple' ? formValues.flightDetailsSecond.tocode : formValues.flightDetails.tocode),
          // );
          const airport = airports.find((airport) => airport.code === formValues.destinationAirportCode);
          await processExtras(transfer, 'pickup', airport.uid, airport.name, airport.code, airport.location.id, 'next', true);
        }
      }
      // EXTRA ownArrangements
      if (!formValues.includeArrivalTransfer && formValues.type === 'flight') {
        const airport = airports.find(
          (airport) =>
            airport.aerocrsCodes &&
            airport.aerocrsCodes.includes(formValues.flightType && formValues.flightType === 'Multiple' ? formValues.flightDetailsSecond.tocode : formValues.flightDetails.tocode),
        );
        await processExtras(transfer, 'pickup', airport.uid, airport.name, airport.code, airport.location.id, 'next', true);
      } else if (formValues && formValues.type === 'ownArrangements') {
        if (!formValues.includeArrivalTransfer && formValues.airportTransfer) {
          // const airport = airports.find(
          //   (airport) =>
          //     airport.aerocrsCodes &&
          //     airport.aerocrsCodes.includes(formValues.flightType && formValues.flightType === 'Multiple' ? formValues.flightDetailsSecond.tocode : formValues.flightDetails.tocode),
          // );
          const airport = airports.find((airport) => airport.code === formValues.destinationAirportCode);
          await processExtras(transfer, 'pickup', airport.uid, airport.name, airport.code, airport.location.id, 'next', true);
        }
      }
    }
  }


// ! move discount to the end - start
// console.log('discount jsonSql');
// console.log(JSON.stringify(jsonSql, undefined, 4));

function reorderDiscountItems(array) {
  // Sort the array, putting discount items last
  const sortedArray = array.sort((a, b) => {
      if (a.discount === b.discount) return 0;
      return a.discount ? 1 : -1;
  });
  
  // Update sequence numbers
  return sortedArray.map((item, index) => ({
      ...item,
      sequence: index + 1
  }));
}

jsonSql = reorderDiscountItems(jsonSql);
// ! move discount to the end - end

  // OWN ARRANGEMENTS
  otherArrangementsData.forEach((otherArrangement) => {
    if (otherArrangement.saleTotal > 0) {
      jsonSql.push({
        sequence: jsonSql.length + 1,
        startDay: otherArrangement.startDay,
        units: otherArrangement.units,
        unitPrice: otherArrangement.unitPrice,
        description: otherArrangement.description,
        supplierName: otherArrangement.supplierName,
        supplierUid: otherArrangement.supplierUid,
        saleTotal: Number(parseFloat(otherArrangement.saleTotal).toFixed(2)),
        type: otherArrangement.type,
        creatioType: otherArrangement.creatioType,
      });
    }
  });

  // const checkDates = () => {
  //   let prevDate = null; // Initialize prevDate outside the loop

  //   for (let i = 0; i < jsonSql.length; i++) {
  //     // Start from the first element
  //     // Skip the check for "otherArrangementsFlyingDoctor"
  //     if (jsonSql[i].creatioType === 'otherArrangementsFlyingDoctor' || jsonSql[i].creatioType === 'otherArrangementsPreferredGuide') {
  //       continue; // Skip this iteration and proceed with the next one
  //     }

  //     if (prevDate !== null) {
  //       // Check if prevDate has been set yet
  //       const currentDate = moment(jsonSql[i].startDay, 'YYYY-MM-DD');
  //       if (currentDate.isBefore(prevDate)) {
  //         toast.error('Dates of custom items must be in sequential order.');
  //         return false; // Stop checking after the first error
  //       }
  //     }

  //     // Update prevDate with the current date for the next iteration
  //     // This is inside the loop but outside the 'if' so it updates correctly when not skipped
  //     prevDate = moment(jsonSql[i].startDay, 'YYYY-MM-DD');
  //   }
  // };

  console.log('control jsonSql');
  console.log(JSON.stringify(jsonSql, undefined, 4));

  const checkDates = () => {
    let prevDate = null; // Initialize prevDate outside the loop

    for (let i = 0; i < jsonSql.length; i++) {
      // Start from the first element
      // Skip the check for "otherArrangementsFlyingDoctor"
      if (jsonSql[i].creatioType === 'otherArrangementsFlyingDoctor' || jsonSql[i].creatioType === 'otherArrangementsPreferredGuide' || jsonSql[i].type === 'transferPicnics') {
        continue; // Skip this iteration and proceed with the next one
      }

      if (prevDate !== null) {
        // Check if prevDate has been set yet
        const currentDate = moment(jsonSql[i].startDay, 'YYYY-MM-DD');
        if (currentDate.isBefore(prevDate)) {
          toast.error('Dates of custom items must be in sequential order.');
          console.log('Dates of custom items must be in sequential order.')
          console.log('error', JSON.stringify(jsonSql[i], null, 2));
          return false; // Stop checking after the first error
        }
      }

      // Update prevDate with the current date for the next iteration
      // This is inside the loop but outside the 'if' so it updates correctly when not skipped
      prevDate = moment(jsonSql[i].startDay, 'YYYY-MM-DD');
    }

    return true; // Explicitly return true if all dates are in sequential order
  };
  if (!checkDates()) return false;
  //checkDates();

  return jsonSql;
}

function getMaxCapacity(properties, propertyId, roomId) {
  // Find the property with the matching uid
  const property = properties.find((property) => property.uid === propertyId);

  // If no matching property is found, return null
  if (!property) return null;
  // Find the room within the property with the matching id
  const room = property.rooms.find((room) => room.id === roomId);

  // If no matching room is found, return null
  if (!room) return null;
  // If both property and room are found, return the max_capacity
  return room.max_capacity;
}

function getMaxAdults(properties, propertyId, roomId) {
  // Find the property with the matching uid
  const property = properties.find((property) => property.uid === propertyId);

  // If no matching property is found, return null
  if (!property) return null;

  // Find the room within the property with the matching id
  const room = property.rooms.find((room) => room.id === roomId);

  // If no matching room is found, return null
  if (!room) return null;

  // If both property and room are found, return the max_adults
  return room.max_adults;
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function formatPrice(num) {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
}

function formatPriceNearestDollar(num) {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.round(num));
}

const getHighestPropertyPax = (bookings) => {
  let highestTotal = 0;

  for (const booking of bookings) {
    const rooms = booking.rooms;
    const totalForThisBooking = rooms.reduce((acc, room) => {
      if (room.roomType !== 'Vehicle') {
        return acc + room.ad + room.ch + room.chi + room.inf;
      }
      return acc;
    }, 0);

    if (totalForThisBooking > highestTotal) {
      highestTotal = totalForThisBooking;
    }
  }

  return highestTotal;
};

function getTotalFromOtherArrangements(otherArrangementsData) {
  return otherArrangementsData.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.saleTotal;
  }, 0);
}

function getTotalFromBookings(bookings) {
  return bookings.reduce((acc, booking) => {
    let roomTotal = 0;

    if (booking.price) {
      roomTotal = booking.price;
    } else {
      roomTotal = booking.rooms.reduce((roomAcc, room) => {
        if (room.rate && room.roomType !== 'Vehicle') {
          return roomAcc + Number(room.rate.payable || room.rate.nett || 0);
        }
        return roomAcc;
      }, 0);
    }

    return acc + roomTotal;
  }, 0);
}

// NEW code before working with Harry
function getTotalFromBookingsForVehiclesHarry(bookings, properties) {
  // Northern Guide Vehicle Nights
  return bookings.reduce((acc, booking, index) => {
    let daysToAdd = 0;
    const nextBooking = bookings[index + 1];

    if (nextBooking) {
      // Check the condition based on the next booking
      if (
        properties[getPropObj(booking.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67' &&
        booking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide') &&
        (properties[getPropObj(nextBooking.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67' ||
          !nextBooking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide'))

        // nextBooking.rooms.some(room => room.roomType === 'Vehicle' && room.vehicleType === "northernGuide") &&
        // booking.rooms.some(room => room.roomType === 'Vehicle' && room.northernGuideVehicleSupplement) &&
        // nextBooking.rooms.some(room => room.roomType === 'Vehicle' && room.northernGuideVehicleSupplement)
      ) {
        daysToAdd = 1;
      }
    } else {
      if (booking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide')) {
        daysToAdd = 1;
      }
    }

    if (nextBooking && properties[getPropObj(nextBooking.propUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
      daysToAdd = 0;
    }

    let days = moment(booking.endDay).diff(moment(booking.startDay), 'days') + daysToAdd;

    let vehicleTotal = booking.rooms.reduce((roomAcc, room) => {
      if (room.roomType === 'Vehicle' && room.northernGuideVehicleSupplement) {
        return roomAcc + Number(room.northernGuideVehiclePrice) * days;
      }
      return roomAcc;
    }, 0);

    return acc + vehicleTotal;
  }, 0);
}

function calculateDaysToAddForVehicles(booking, nextBooking, properties) {
  let daysToAdd = 0;
  if (!nextBooking) {
    if (booking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide')) {
      daysToAdd = 1;
    }
  } else if (
    booking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide') &&
    !nextBooking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide') &&
    properties[getPropObj(nextBooking.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67'
  ) {
    daysToAdd = 1;
  }
  return daysToAdd;
}

function findNextBooking(bookings, id) {
  const index = bookings.findIndex((booking) => booking.id === id);

  if (index !== -1 && index < bookings.length - 1) {
    return bookings[index + 1];
  }

  return null; // Return null if no next booking exists
}

function getTotalFromBookingsForVehicles(bookings, properties) {
  // Northern Guide Vehicle Nights
  return bookings.reduce((acc, booking, index) => {
    const nextBooking = bookings[index + 1];
    // let daysToAdd = 0;
    //     if (!nextBooking) {
    //       if (booking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide')) {
    //         daysToAdd = 1;
    //       }
    //     } else if (
    //       (booking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide') &&
    //         !nextBooking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide')) &&
    //       properties[getPropObj(nextBooking.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67'
    //     ) {
    //       //if (!nextBooking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide') || properties[getPropObj(nextBooking.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67'){
    //       daysToAdd = 1;
    //       // }
    //     }

    const daysToAdd = calculateDaysToAddForVehicles(booking, nextBooking, properties);
    // if (nextBooking && properties[getPropObj(nextBooking.propUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
    //   daysToAdd = 0;
    // }

    // if (nextBooking) {
    //   // Check the condition based on the next booking
    //   if (
    //     properties[getPropObj(booking.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67' &&
    //     booking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide') &&
    //     (properties[getPropObj(nextBooking.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67' ||
    //       !nextBooking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide'))

    //     // nextBooking.rooms.some(room => room.roomType === 'Vehicle' && room.vehicleType === "northernGuide") &&
    //     // booking.rooms.some(room => room.roomType === 'Vehicle' && room.northernGuideVehicleSupplement) &&
    //     // nextBooking.rooms.some(room => room.roomType === 'Vehicle' && room.northernGuideVehicleSupplement)
    //   ) {
    //     daysToAdd = 1;
    //   }
    // } else {
    //   if (booking.rooms.some((room) => room.roomType === 'Vehicle' && room.vehicleType === 'northernGuide')) {
    //     daysToAdd = 1;
    //   }
    // }

    // if (nextBooking && properties[getPropObj(nextBooking.propUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
    //   daysToAdd = 0;
    // }

    let days = moment(booking.endDay).diff(moment(booking.startDay), 'days') + daysToAdd;

    let vehicleTotal = booking.rooms.reduce((roomAcc, room) => {
      if (room.roomType === 'Vehicle' && room.northernGuideVehicleSupplement) {
        return roomAcc + Number(room.northernGuideVehiclePrice) * days;
      }
      return roomAcc;
    }, 0);

    return acc + vehicleTotal;
  }, 0);
}

function getTotalFromTransfers(transfers) {
  console.log('getTotalFromTransfers');
  console.log(JSON.stringify(transfers, null, 2));

  if (transfers && transfers.length > 0) {
    const transfersDataTotal = transfers.reduce((acc, transfer) => {
      if (transfer.formValues) {
        const { formValues } = transfer;
        let subtotal = Number(formValues.saleTotal) || 0;

        if (formValues.flightPricing && formValues.flightPricing.passengers) {
          const { adults, children, infants } = formValues.flightPricing.passengers;
          const taxAmount = Number(formValues.flightPricing.saleFare.tax) || 0;
          //subtotal += (adults + children + infants) * taxAmount; // already adding in the flight pricing
        }

        console.log('subtotal flight pricing', subtotal);

        if (formValues.type === 'bookFlight' || formValues.type === 'flight' || formValues.type === 'ownArrangements' || formValues.type === 'camp') {
          if (formValues.includeDepartureTransfer && formValues.departureTransferSaleTotal) {
            subtotal += Number(formValues.departureTransferSaleTotal);
          }

          if (formValues.includeArrivalTransfer && formValues.arrivalTransferSaleTotal) {
            subtotal += Number(formValues.arrivalTransferSaleTotal);
          }

          // VIP transfers arrival and departure
          if (formValues.includeDepartureVip && formValues.departureSaleTotalVip) {
            subtotal += Number(formValues.departureSaleTotalVip);
          }

          if (formValues.includeArrivalVip && formValues.arrivalSaleTotalVip) {
            subtotal += Number(formValues.arrivalSaleTotalVip);
          }
        }

        if (formValues.packedLunchChargeable && formValues.packedLunchSaleTotal) {
          subtotal += Number(formValues.packedLunchSaleTotal);
        }

        return acc + subtotal;
      }
      return acc;
    }, 0);

    console.log('transfersDataTotal', transfersDataTotal);
    return transfersDataTotal;
  } else {
    return 0;
  }
}

function getTotalFromCustomItems(customFinalItems) {
  console.log('getTotalFromCustomItems');

  // We'll use the reduce method on the customFinalItems array to sum up all totals
  return customFinalItems.reduce((acc, item) => {
    // For each item, we sum up the 'total' from each field
    const fieldsTotal = item.fields.reduce((fieldsAcc, field) => {
      return fieldsAcc + field.total; // Add up the total from each field
    }, 0);

    return acc + fieldsTotal; // Add the fields total to the overall accumulator
  }, 0);
}

function getTotalFromPropertyTransfers(transfers, uuid) {
  // Filter the transfers by the given uuid
  if (transfers && transfers.length > 0) {
    const filteredTransfers = transfers.filter((transfer) => transfer.uuid === uuid);

    // Sum up the saleTotal for the filtered transfers
    const transfersDataTotal = filteredTransfers.reduce((acc, transfer) => {
      if (transfer.formValues && transfer.formValues.saleTotal) {
        return acc + transfer.formValues.saleTotal;
      }
      return acc;
    }, 0);

    return transfersDataTotal;
  } else {
    return 0;
  }
}

function getTotalFromFinalPageData(finalPageData) {
  console.log('getTotalFromFinalPageData');
  console.log(JSON.stringify(finalPageData, undefined, 4));
  return finalPageData.reduce((acc, data) => {
    return (
      acc +
      data.propertyItems.reduce((innerAcc, item) => {
        // Check if item is not undefined before accessing its properties
        return item ? (item.edit ? innerAcc + item.total : innerAcc) : innerAcc;
      }, 0)
    );
  }, 0);
}

// }

function getTotalFromFinalPageTransferData(finalPageTransferData) {
  console.log('getTotalFromFinalPageData');
  console.log(JSON.stringify(finalPageTransferData, undefined, 4));
  return finalPageTransferData.reduce((acc, data) => {
    return (
      acc +
      data.transferItems.reduce((innerAcc, item) => {
        // Check if item is not undefined before accessing its properties
        return item ? (item.edit ? innerAcc + item.total : innerAcc) : innerAcc;
      }, 0)
    );
  }, 0);
}

function getTotalFromPropertyFinalPageData(finalPageData, uuid) {
  if (finalPageData.length > 0) {
    // Filter the data by the given uuid
    const filteredData = finalPageData.filter((data) => data.uuid === uuid);

    // Calculate the total for the filtered data
    const total = filteredData.reduce((acc, data) => {
      return (
        acc +
        data.propertyItems.reduce((innerAcc, item) => {
          return item.edit ? innerAcc + item.total : innerAcc;
        }, 0)
      );
    }, 0);

    return total;
  } else {
    return 0;
  }
}

function getTotalFromActivities(activitiesData) {
  return activitiesData.reduce((acc, property) => {
    return (
      acc +
      property.activities.reduce((innerAcc, day) => {
        let activityArray;

        // Check the type of 'day' to determine where the activities are stored
        if (Array.isArray(day)) {
          activityArray = day;
        } else if (day.values && Array.isArray(day.values)) {
          activityArray = day.values;
        } else {
          return innerAcc;
        }

        // Check if activityArray is not empty
        if (activityArray.length > 0) {
          return (
            innerAcc +
            activityArray.reduce((activityAcc, activity) => {
              return activityAcc + (activity.cost || 0);
            }, 0)
          );
        }
        return innerAcc;
      }, 0)
    );
  }, 0);
}

function getTotalFromPropertyActivities(activitiesData, id) {
  // Find the specific property object by id
  const property = activitiesData.find((prop) => prop.id === id);

  // If the property is not found, return 0
  if (!property) return 0;

  // If found, continue with the summing logic
  return property.activities.reduce((innerAcc, day) => {
    // Check if day.values is an array and not empty
    if (Array.isArray(day.values) && day.values.length > 0) {
      return (
        innerAcc +
        day.values.reduce((activityAcc, activity) => {
          return activityAcc + (activity.cost || 0);
        }, 0)
      );
    }
    return innerAcc;
  }, 0);
}

// function getTotalForProperty(rooms) {
//   return rooms.filter((room) => room.roomType !== 'Vehicle' && room.rate).reduce((acc, room) => acc + (room.rate.payable || room.rate.nett || 0), 0);
// }

function getTotalForProperty(item) {
  if (item.price) {
    return item.price;
  } else {
    const rooms = item.rooms;
    return rooms.filter((room) => room.roomType !== 'Vehicle' && room.rate).reduce((acc, room) => acc + (room.rate.payable || room.rate.nett || 0), 0);
  }
}

function nomadVehicleRates(rate) {
  console.log('nomadVehicleRates', rate);
  const rates = ['gamePackage', 'gamePackageExclusive'];
  return rates.includes(rate);
}

function nonNomadVehicleRates(rate) {
  const rates = ['fullBoardNomad'];
  return rates.includes(rate);
}

const getSupplierName = (uid, suppliers) => {
  for (let supplier of suppliers) {
    if (supplier.uid === uid) {
      return supplier.name;
    }
  }
  return null; // or throw an error if a match is not found
};

const findAirlineByCompanyCode = (airlines, companyCode) => {
  return airlines.find((airline) => airline.companyCode === companyCode);
};

function findCustomLocation(customLocations, propUid) {
  return customLocations.find((location) => location.propUid === propUid);
}

function findBookingById(bookings, id) {
  return bookings.find((booking) => booking.id === id);
}

function processPackedLunch(properties, booking, packedLunchChargeable, packedLunch) {
  let unitPrice = 0;
  let units = 0; // Declare units here so it's accessible outside the if block
  const extras = properties[getPropObj(booking.propUid, properties)].extras;

  if (extras && extras.length > 0) {
    const unitPriceType = booking.propUid.includes('_D-I-R-E-C-T_') ? 'rackPrice' : 'stoPrice';
    let extra = findValidPackedLunch(extras, booking.endDay, unitPriceType);

    if (packedLunchChargeable && packedLunch) {
      unitPrice = extra ? (unitPriceType === 'rackPrice' ? extra.rackPrice : extra.stoPrice) : 0;
    }
    if (packedLunch) {
      units = booking.rooms.reduce((total, room) => {
        return total + (room.roomType !== 'Vehicle' ? room.ad + room.ch + room.chi + room.inf : 0);
      }, 0);
    }
  }
  const saleTotal = units * unitPrice;

  // Return an object containing both unitPrice and units
  return { unitPrice, units, saleTotal };
}

function getRoomType(roomsArray, selectedRoomId) {
  const room = roomsArray.find((room) => room.id === selectedRoomId);
  return room ? room.type : null;
}

function findAirportByUid(airports, uid) {
  // Find the airport in the array that has the matching uid
  const airport = airports.find((airport) => airport.uid === uid);

  // Return the found airport or null if not found
  return airport || null;
}

function getIdFromStatus(name) {
  switch (name) {
    case 'quotation':
      return '0';
    case 'draft':
      return '0';
    case 'expired':
      return '0';
    case 'provisional':
    case 'pendingProvisional':
      return '20';
    case 'confirmed':
    case 'pendingConfirmation':
      return '30';
    case 'cancelled':
      return '90';
    default:
      return 'x';
  }
}

const calculateTotalPax = (bookingItem) => {
  let total = 0;

  bookingItem.rooms?.forEach((room) => {
    total += room.selectedAges?.length || 0;
    total += room.selectedAgesAdults?.length || 0;
  });

  return total;
};

export {
  make2digits,
  addDays,
  minusDays,
  addMonths,
  getPropObj,
  dateShort,
  formatDateDisplay,
  numberWithCommas,
  formatFireStoreDate,
  getRoomImg,
  getTimeDifference,
  getPropertyName,
  convertTimestamps,
  createJsonObject,
  getMaxCapacity,
  getMaxAdults,
  capitalizeFirstLetter,
  getSupplier,
  formatNumber,
  formatPrice,
  isBST,
  adjustDateForBST,
  addOneDayIfBST,
  getHighestPropertyPax,
  getTotalFromOtherArrangements,
  getTotalFromBookings,
  getTotalFromFinalPageData,
  getTotalFromPropertyFinalPageData,
  getTotalFromTransfers,
  getTotalFromPropertyTransfers,
  getTotalFromActivities,
  getTotalFromPropertyActivities,
  // getTotalFromBooking,
  getTotalFromBookingsForVehicles,
  getTotalForProperty,
  nomadVehicleRates,
  nonNomadVehicleRates,
  getSupplierName,
  findAirlineByCompanyCode,
  getTotalFromFinalPageTransferData,
  findValidPackedLunch,
  findCustomLocation,
  findBookingById,
  processPackedLunch,
  getRoomType,
  findAirportByUid,
  getTotalFromCustomItems,
  calculateDaysToAddForVehicles,
  findNextBooking,
  getIdFromStatus,
  orderByFeeType,
  calculateTotalPax,
  formatPriceNearestDollar,
};
