import React from 'react';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import ButtonPrimary from './Buttons/ButtonPrimary';
import { updateEarlyAccessRequested } from '../features/auth/authSlice';

function EarlyAccessHero({ user }) {
  const dispatch = useDispatch();

  const handleEarlyAccess = () => {
    dispatch(updateEarlyAccessRequested());
    toast.success('Early access requested');
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gray-50">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <div className="flex-grow flex items-center justify-center py-12">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="px-4 sm:px-6 lg:px-8 w-full max-w-7xl"
          >
            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="flex flex-col md:flex-row">
                {/* Content Section - Left */}
                <div className="w-full md:w-1/2 p-8 md:p-12">
                  <div className="max-w-md mx-auto">
                    <div className="text-center mb-8">
                      <h1 className="text-4xl font-roca text-nomadBlue mb-4">Welcome to Hornbill</h1>
                      <p className="text-gray-600">Your revolutionary safari planning platform</p>
                    </div>

                    <motion.div 
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.2, duration: 0.5 }}
                      className="bg-white rounded-lg border border-[#f0ece7] p-6"
                    >
                      <div className="space-y-6">
                        <p className="text-gray-600">
                          Be among the first to experience Hornbill's revolutionary features. Sign up now for exclusive early access and stay ahead of the curve.
                        </p>

                        {!user?.earlyAccessRequested ? (
                          <ButtonPrimary
                            color="dark"
                            text="Register for Early Access"
                            className="w-full"
                            onClick={handleEarlyAccess}
                          />
                        ) : (
                          <div className="bg-nomadEvergreen-700/10 text-nomadEvergreen-700 px-6 py-4 rounded-lg text-center">
                            <p className="text-lg font-semibold mb-1">Early access requested</p>
                            <p className="text-sm">We'll notify you when Hornbill is ready!</p>
                          </div>
                        )}
                      </div>
                    </motion.div>
                  </div>
                </div>

                {/* Video Section - Right */}
                <div className="w-full md:w-1/2 bg-nomadBlue-800 p-8 md:p-12">
                  <div className="aspect-video rounded-lg overflow-hidden shadow-lg">
                    <ReactPlayer 
                      url="https://vimeo.com/24572796" 
                      controls={true} 
                      width="100%" 
                      height="100%" 
                      className="rounded-lg"
                    />
                  </div>
                  <div className="mt-8 text-center">
                    <p className="text-nomadBlue-100 text-sm">
                      PROVIDED AND OPERATED BY
                    </p>
                    <img
                      src="https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/b77d6736-4f33-4f25-e69f-64e304b00b00/public"
                      alt="Nomad DMC"
                      className="w-32 mx-auto mt-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default EarlyAccessHero;