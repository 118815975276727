import {
  handleRemoveAdultUuid,
  handleRemoveChildUuid,
  incrementAdults,
  incrementChildren,
  isUuidUsedInBookings,
} from '../functions/paxFunctions';
import { toast } from 'react-toastify';
import React, { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { RiCloseCircleLine } from 'react-icons/ri';
import Select from 'react-select';
import ButtonPrimarySmall from '../components/Buttons/ButtonPrimarySmall';
import { useSelector } from 'react-redux';

const GuestsPopover = ({ guests, setGuests, bookings }) => {
   
  return (
    <Popover className="relative">
      <Popover.Button className="border border-white focus:outline-none focus:border-white">
        <div
          id="totalPax"
          className="rounded-sm border border-white flex items-center pl-0 focus:outline-none focus:border-white"
        >
          <span className="ml-auto text-gray-800 text-base font-semibold underline brand-text-color-v2-hover">
            {guests.adults} adult{guests.adults !== 1 && 's'}{' '}
            {guests.children} kid{guests.children !== 1 && 's'}
          </span>
        </div>
      </Popover.Button>

      {/* Popover content */}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className="absolute z-[9999] left-1/4 transform -translate-x-1/4 mt-2 w-screen max-w-max"
          style={{ zIndex: 9999 }}
        >
          <GuestPopoverContent
            guests={guests}
            setGuests={setGuests}
            bookings={bookings}
            toast={toast}
          />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

const GuestPopoverContent = ({ guests, setGuests, bookings }) => {
  const { user } = useSelector((state) => state.auth);
  // State to control the visibility of the "isGuide" checkboxes
  const [showIsGuide, setShowIsGuide] = useState(false);

  return (
    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
      <div className="m-5">
        {/* Header with "Guests" and the new checkbox */}
        <div className="flex justify-between items-center">
          <p className="text-md font-semibold">Guests</p>
          {user.internalUser && (
            <div className="flex items-center">
              <span className="ml-2 text-neutral-500 text-sm font-normal">
              Show Guides
            </span>
            <input
              type="checkbox"
              name="showIsGuide"
              className="ml-2 w-5 h-5 checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
              checked={showIsGuide}
              onChange={(e) => setShowIsGuide(e.target.checked)}
            />
          </div>
          )}
        </div>

        {/* Main Content */}
        <div>
          {/* Display Adults and Children Count with Counter Functionality */}
          <div className="row-2 grid grid-cols-12 m-5">
            {/* Total Label */}
            <div className="col-span-2 px-2 flex items-center">Total:</div>
            {/* Adult Counter */}
            <div className="col-span-3 px-2">
              <div className="text-xs mb-1">Adults</div>
              <div className="grid grid-rows-1 grid-flow-col bg-white border border-slate-200 rounded-lg h-9 py-0.5">
                <div className="px-2 text-xs font-normal text-center align-middle leading-6">
                  {guests.adults}
                </div>
                <button
                  className="border-l border-slate-200 px-2"
                  onClick={() => {
                    incrementAdults(guests, setGuests);
                  }}
                >
                  +
                </button>
              </div>
            </div>

            {/* Child Counter */}
            <div className="col-span-3 px-2">
              <div className="text-xs mb-1">Children</div>
              <div className="grid grid-rows-1 grid-flow-col bg-white border border-slate-200 rounded-lg h-9 py-0.5">
                <div className="px-2 text-xs font-normal text-center align-middle leading-6">
                  {guests.children}
                </div>
                <button
                  className="border-l border-slate-200 px-2"
                  onClick={() => {
                    incrementChildren(guests, setGuests);
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>

          {/* Adult Age Selectors */}
          <div className="row-2 grid grid-cols-12 ml-5 mb-5">
            {guests.adultAges.map((adult, adultIndex) => (
              <React.Fragment key={adult.uuid}>
                {adultIndex === 0 ? (
                  <div className="col-span-2 px-2 mb-0">
                    <div className="ml-5 mt text-md">Adults:</div>
                  </div>
                ) : (
                  adultIndex % 5 === 0 && (
                    <div className="col-span-2 px-2 mb-0"></div>
                  )
                )}
                <div className="col-span-2 px-2 mb-0">
                  <div className="flex items-center">
                    <div className="text-xs mb-1">
                      Adult {adultIndex + 1}
                    </div>

                    {!isUuidUsedInBookings(adult.uuid, bookings) && (
                      <RiCloseCircleLine
                        size={16}
                        className="ml-1 mb-1 cursor-pointer text-red-600"
                        onClick={() => {
                          if (guests.adultAges.length === 1) {
                            toast.error('You must have at least 1 adult');
                          } else {
                            handleRemoveAdultUuid(
                              adult.uuid,
                              setGuests,
                              bookings
                            );
                          }
                        }}
                      />
                    )}
                  </div>

                  {/* Conditionally render the isGuide checkbox */}
                  {showIsGuide && (
                    <div className="flex items-center text-left text-gray-800 text-sm font-normal mt-1">
                      <input
                        type="checkbox"
                        name="isGuide"
                        className="w-4 h-4 checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
                        checked={adult.isGuide}
                        onChange={(e) => {
                          let isChecked = e.target.checked;
                          setGuests((prevGuests) => {
                            const updatedAdultAges = prevGuests.adultAges.map(
                              (a) =>
                                a.uuid === adult.uuid
                                  ? { ...a, isGuide: isChecked }
                                  : a
                            );
                            return {
                              ...prevGuests,
                              adultAges: updatedAdultAges,
                            };
                          });
                        }}
                      />
                      <span className="ml-2 text-neutral-500 text-xs font-normal">
                        Guide
                      </span>
                    </div>
                  )}
                </div>
              </React.Fragment>
            ))}
          </div>

          {/* Child Age Selectors */}
          <div className="row-2 grid grid-cols-12 ml-5">
            {guests.childAges.map((child, childIndex) => (
              <React.Fragment key={child.uuid}>
                {childIndex === 0 ? (
                  <div className="col-span-2 px-2 mb-5">
                    <div className="m-5 mt text-md">Children:</div>
                  </div>
                ) : (
                  childIndex % 5 === 0 && (
                    <div className="col-span-2 px-2 mb-5"></div>
                  )
                )}
                <div className="col-span-2 px-2 mb-5">
                  <div className="flex items-center">
                    <div className="text-xs mb-1">
                      Child {childIndex + 1} age
                    </div>

                    {!isUuidUsedInBookings(child.uuid, bookings) && (
                      <RiCloseCircleLine
                        size={16}
                        className="ml-1 text-red-600 cursor-pointer"
                        onClick={() =>
                          handleRemoveChildUuid(
                            child.uuid,
                            setGuests,
                            bookings
                          )
                        }
                      />
                    )}
                  </div>
                  <Select
                    className="border-r border-l border-slate-200 w-[84px]"
                    options={[...Array(21).keys()].map((num) => ({
                      value: num,
                      label: num.toString(),
                    }))}
                    value={
                      child.age === -1
                        ? { label: '-', value: -1 }
                        : {
                            label: child.age.toString(),
                            value: child.age,
                          }
                    }
                    onChange={(selectedOption) => {
                      setGuests((prevGuests) => {
                        const updatedChildAges = prevGuests.childAges.map(
                          (c) =>
                            c.uuid === child.uuid
                              ? {
                                  ...c,
                                  age: selectedOption.value,
                                }
                              : c
                        );
                        return {
                          ...prevGuests,
                          childAges: updatedChildAges,
                        };
                      });
                    }}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    isDisabled={isUuidUsedInBookings(child.uuid, bookings)}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>

          {/* Done Button */}
          <div className="row-2 grid grid-cols-12 ml-5">
            <div className="col-span-10 px-2 mt-5">
              <span className="font-normal italic"></span>
            </div>
            <div className="col-span-2 px-2 flex justify-end items-center">
              {/* Use Popover.Button to close the Popover */}
              <Popover.Button as="div">
                <ButtonPrimarySmall
                  color={'dark'}
                  text={'Done'}
                  className={'float-right mt-4'}
                />
              </Popover.Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default GuestsPopover;
