// itinerarySearchFunctions.js
import { collection, getDocs, query, where, limit, orderBy } from 'firebase/firestore';
import { db } from '../db/firebase.config';
import { longStayCheck } from './itineraryFunctions';
import axios from 'axios';

// Function to allocate Nights
export const allocateNights = (tempRoute, safariNights) => {
  console.log('Allocate Nights function called');
  console.log('Input - tempRoute:', JSON.stringify(tempRoute, null, 2));
  console.log('Input - safariNights:', safariNights);

  // Apply minNights to each safari route item
  tempRoute.forEach((routeItem) => {
    if (routeItem.safariProperty !== false) {
      console.log(`Applying minNights to routeItem: ${routeItem.name}`);
      routeItem.nights = routeItem.minNights;
    }
  });

  // Calculate remaining nights based on safari route items only
  let remainingNights = safariNights - tempRoute.reduce((total, routeItem) => (routeItem.safariProperty !== false ? total + (routeItem.nights || 0) : total), 0);

  console.log('Remaining Nights after applying minNights:', remainingNights);

  // Distribute remaining nights until idealNights is reached or all nights are used up
  while (remainingNights > 0) {
    let nightsAdded = false;
    for (const routeItem of tempRoute) {
      if (routeItem.safariProperty !== false && routeItem.nights < routeItem.idealNights) {
        console.log(`Adding night to routeItem: ${routeItem.name}`);
        routeItem.nights++;
        remainingNights--;
        nightsAdded = true;
        if (remainingNights === 0) break;
      }
    }
    if (!nightsAdded) break;
  }

  console.log('Remaining Nights after distributing until idealNights:', remainingNights);

  // Distribute any remaining nights evenly among safari route items
  let index = 0;
  while (remainingNights > 0) {
    if (tempRoute[index].safariProperty !== false) {
      console.log(`Distributing remaining night to routeItem: ${tempRoute[index].name}`);
      tempRoute[index].nights++;
      remainingNights--;
    }
    index = (index + 1) % tempRoute.length;
  }

  console.log('output - tempRoute:', JSON.stringify(tempRoute, null, 2));

  return tempRoute;
};

// Function to update route item dates
export const updateRouteItemDates = (routeItem, currentDate) => {
  const startDate = new Date(currentDate);
  const endDate = new Date(currentDate);
  endDate.setDate(endDate.getDate() + routeItem.nights);

  const updatedRouteItem = {
    ...routeItem,
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0],
  };

  // Update currentDate for the next item
  currentDate.setDate(currentDate.getDate() + routeItem.nights);

  return { updatedRouteItem, newCurrentDate: currentDate };
};

// Function to append the start and end locations (either city or beach)
export const appendLocationsToRoute = (route, searchRequest, beachLocation) => {
  if (searchRequest.beachNights === 0) {
    // Append city to the start of the route if arrivalCityNight is true
    if (searchRequest.arrivalCityNight) {
      const firstPark = route.parks[0];
      route.parks.unshift({
        id: firstPark.entryCity.id,
        order: 0,
        country: 'Tanzania', // We might want to make this dynamic if we have multiple countries
        nameShort: firstPark.entryCity.name,
        name: firstPark.entryCity.name,
        nights: 1,
        safariProperty: false,
      });
    }

    // Append city to the end of the route if departureCityNight is true
    if (searchRequest.departureCityNight) {
      const lastPark = route.parks[route.parks.length - 1];
      route.parks.push({
        id: lastPark.entryCity.id,
        order: route.parks.length,
        country: 'Tanzania', // We might want to make this dynamic if we have multiple countries
        nameShort: lastPark.entryCity.name,
        name: lastPark.entryCity.name,
        nights: 1,
        safariProperty: false,
      });
    }
  } else if (searchRequest.beachNights > 0) {
    if (searchRequest.arrivalCityNight) {
      route.parks.unshift({
        id: 'd03eeb65-41c0-4eb7-9550-378a3c48c7c1',
        order: 0,
        country: 'Tanzania',
        nameShort: 'Arusha',
        name: 'Arusha',
        nights: 1,
        safariProperty: false,
      });
    }

    // Append the beach location to the end of the route
    if (beachLocation) {
      route.parks.push({
        id: beachLocation.id,
        order: route.parks.length,
        country: beachLocation.country,
        nameShort: beachLocation.nameShort,
        name: beachLocation.name,
        nights: searchRequest.beachNights,
        safariProperty: false,
      });
    } else {
      // Fallback to Zanzibar if no beach location is found in the search request
      route.parks.push({
        id: 'ce7809b0-7680-44bb-ad7b-5fbcc0270e30',
        order: route.parks.length,
        country: 'Tanzania',
        nameShort: 'Zanzibar',
        name: 'Zanzibar',
        nights: searchRequest.beachNights,
        safariProperty: false,
      });
    }
  }

  return route;
};

// Get minimum number of nights for the itineraries so that we can increase from user search if needed. 
export const getMinimumNights = async (parentParkIds) => {
  const q = query(
    collection(db, 'templateItineraries'),
    where('parkIds', 'array-contains-any', parentParkIds),
    orderBy('minNights', 'asc'),
    limit(5000)  // Using a high limit to ensure we get all relevant itineraries
  );

  const querySnapshot = await getDocs(q);
  console.log(`Found ${querySnapshot.size} potential itineraries`);

  // Filter itineraries to those that contain all requested parks
  const relevantItineraries = querySnapshot.docs
    .map(doc => doc.data())
    .filter(itinerary => parentParkIds.every(id => itinerary.parkIds.includes(id)));

  console.log(`${relevantItineraries.length} itineraries contain all requested parks`);

  if (relevantItineraries.length > 0) {
    // Find the minimum nights among the relevant itineraries
    const minNights = Math.min(...relevantItineraries.map(itinerary => itinerary.minNights));
    console.log('Minimum nights found:', minNights);
    return minNights;
  }

  console.log('No relevant itineraries found');
  return null;
};

// Function to get the matching itineraries from database
export const fetchItinerariesFromDatabase = async (parentParkIds, adjustedNights) => {
  // Construct Firestore query
  const q = query(
    collection(db, 'templateItineraries'),
    where('parkIds', 'array-contains-any', parentParkIds),
    where('minNights', '<=', adjustedNights),
    limit(5000)
  );

  // Execute the query and retrieve the matching itineraries
  const querySnapshot = await getDocs(q);
  const fetchedItineraries = querySnapshot.docs
    .map((doc) => doc.data())
    .filter((itinerary) => parentParkIds.every((id) => itinerary.parkIds.includes(id)));

  console.log(`FOUND ${fetchedItineraries.length} FETCHED ITINERARIES FROM DATABASE`);
  console.log('Fetched Itineraries 1:', fetchedItineraries);

  return fetchedItineraries;
};

// function to sort itineraries by recommended!
export const sortItinerariesRecommended = (itineraries) => {
  // Helper function to count Nomad properties in an itinerary
  const countNomadProperties = (itinerary) => {
    return itinerary.route.filter(item => 
      item.property.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67'
    ).length;
  };

  // Initial sort function
  const initialSort = (a, b) => {
    if (a.priority !== b.priority) {
      return a.priority - b.priority;
    }

    const aAvgRanking = a.route.reduce((sum, item) => sum + item.property.rankingPriority, 0) / a.route.length;
    const bAvgRanking = b.route.reduce((sum, item) => sum + item.property.rankingPriority, 0) / b.route.length;

    return aAvgRanking - bAvgRanking;
  };

  // Sort itineraries
  const sortedItineraries = itineraries.sort(initialSort);

  // Separate exact matches and non-exact matches
  const exactMatches = sortedItineraries.filter((itinerary) => itinerary.exactMatch);
  const nonExactMatches = sortedItineraries.filter((itinerary) => !itinerary.exactMatch);

  // Function to redistribute itineraries to avoid consecutive same priorities
  const redistributeItineraries = (itineraries) => {
    const result = [];
    const priorityGroups = {};

    // Group itineraries by priority
    itineraries.forEach((itinerary) => {
      if (!priorityGroups[itinerary.priority]) {
        priorityGroups[itinerary.priority] = [];
      }
      priorityGroups[itinerary.priority].push(itinerary);
    });

    // Redistribute itineraries
    while (Object.keys(priorityGroups).length > 0) {
      for (let priority of Object.keys(priorityGroups).sort((a, b) => a - b)) {
        if (priorityGroups[priority].length > 0) {
          result.push(priorityGroups[priority].shift());
          if (priorityGroups[priority].length === 0) {
            delete priorityGroups[priority];
          }
        }
        if (Object.keys(priorityGroups).length === 0) break;
      }
    }

    return result;
  };


  // Function to prioritize Nomad itineraries and Nomad favorites
  const prioritizeNomadAndFavorites = (itineraries) => {
    return itineraries.sort((a, b) => {
      const aNomadCount = countNomadProperties(a);
      const bNomadCount = countNomadProperties(b);
      
      if (aNomadCount !== bNomadCount) {
        return bNomadCount - aNomadCount; // Higher Nomad count first
      }
      
      if (a.nomadFavourite !== b.nomadFavourite) {
        return a.nomadFavourite ? -1 : 1; // Nomad favorites first
      }
      
      return 0; // Maintain original order if all else is equal
    });
  };

  // Apply redistribution and prioritization to both sets
  const redistributedExactMatches = redistributeItineraries(exactMatches);
  const redistributedNonExactMatches = redistributeItineraries(nonExactMatches);

  const finalExactMatches = prioritizeNomadAndFavorites(redistributedExactMatches);
  const finalNonExactMatches = prioritizeNomadAndFavorites(redistributedNonExactMatches);

  // Combine the final sets
  return [...finalExactMatches, ...finalNonExactMatches];
};

export const fetchPricesForItineraries = async (itineraries, guests, agentRateBand) => {
  const itinerariesWithPrices = await Promise.all(
    itineraries.map(async (itinerary) => {
      // Calculate the necessary values for longStayCheck
      const totalNomadTanzaniaDays = itinerary.route.reduce((total, item) => 
        total + (item.property.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' ? item.nights : 0), 0);
      
      const totalSafariNights = itinerary.route.reduce((total, item) => 
        total + (item.property.locationCategory === 'safariPark' ? item.nights : 0), 0);
      
      const createVersionCheck = process.env.REACT_APP_VERSION || '2.5.16';
      const startDay = itinerary.route[0].startDate;

      // Determine if nomadLongStay applies
      const nomadLongStay = longStayCheck(totalNomadTanzaniaDays, startDay, createVersionCheck, totalSafariNights);

      // Fetch price, passing nomadLongStay
      const price = await fetchPriceForItinerary(itinerary, guests, agentRateBand, nomadLongStay);

      return { ...itinerary, price, nomadLongStay };
    }),
  );
  return itinerariesWithPrices;
};

// Get pricing for an itinerary
const fetchPriceForItinerary = async (itinerary, guests, agentRateBand, nomadLongStay) => {
  try {
    const occupancyAges = [...guests.adultAges.map((adult) => adult.age), ...guests.childAges.map((child) => child.age)].join(',');
    const totalPax = guests.adults + guests.children;

    const camps = itinerary.route.map((item, index) => {
      const rateTypeId = item.property.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' ? agentRateBand.karaniIdNomad : agentRateBand.karaniIdThirdParty;

      let boardId = null;
      const activeRates = item.property.thirdPartyRates.filter((rate) => rate.active && rate.type !== 'dayRoom');

      if (item.property.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
        const gamePackageRate = activeRates.find((rate) => rate.type === 'gamePackage');
        if (gamePackageRate) {
          boardId = gamePackageRate.karaniId;
        }
      } else {
        const fullBoardNomadRate = activeRates.find((rate) => rate.type === 'fullBoardNomad');
        const gamePackageRate = activeRates.find((rate) => rate.type === 'gamePackage');
        const bedBreakfastRate = activeRates.find((rate) => rate.type === 'bedBreakfast');
        const fullBoardRate = activeRates.find((rate) => rate.type === 'fullBoard');

        if (fullBoardNomadRate) {
          boardId = fullBoardNomadRate.karaniId;
        } else if (gamePackageRate) {
          boardId = gamePackageRate.karaniId;
        } else if (bedBreakfastRate) {
          boardId = bedBreakfastRate.karaniId;
        } else if (fullBoardRate) {
          boardId = fullBoardRate.karaniId;
        } else if (activeRates.length > 0) {
          boardId = activeRates[0].karaniId;
        }
      }

      return {
        Index: index + 1,
        PricePoint: {
          ID: item.property.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' && nomadLongStay ? 1 : null,
        },
        Camp: {
          ID: item.property.platformKarani.id,
          Name: item.propertyName,
        },
        Board: {
          ID: boardId,
        },
        StartDate: item.startDate,
        Nights: item.nights,
        RateTypes: [{ ID: rateTypeId }],
        Pax: totalPax,
        OccupancyAges: occupancyAges,
      };
    });

    const data = {
      Pax: totalPax,
      Camps: camps,
      Response: 3,
    };

    const response = await axios.post('/api/karani', {
      data,
      path: 'Pricing/PriceItinerary/',
    });

    return response.data.price;
  } catch (error) {
    console.error('Error fetching itinerary price:', error);
    return null;
  }
};

// Deduplicate itineraries
export const deduplicateItineraries = (mergedItineraries) => {
  console.log('Starting deduplication process with', mergedItineraries.length, 'itineraries');

  // First, filter out itineraries with duplicate properties within themselves
  const noDuplicatePropsItineraries = mergedItineraries.filter((itinerary) => {
    // Get all safari property IDs from the route
    const propUids = itinerary.route
      .filter(routeItem => routeItem && routeItem.safariProperty !== false)
      .map(routeItem => routeItem.propUid);

    // Check for duplicates within this itinerary
    const uniqueProps = new Set(propUids);
    const hasDuplicates = propUids.length !== uniqueProps.size;

    if (hasDuplicates) {
      console.log(`Filtering out itinerary ${itinerary.uuid} - contains duplicate properties:`, propUids);
    }

    return !hasDuplicates;
  });

  console.log('Itineraries after removing those with duplicate properties:', noDuplicatePropsItineraries.length);

  // Then, deduplicate similar itineraries (those with the same properties in different orders)
  const uniqueItineraries = noDuplicatePropsItineraries.filter((currentItinerary, index, array) => {
    return index === array.findIndex((comparisonItinerary) => {
      // Get arrays of propUids for safari properties only
      const comparisonPropUids = comparisonItinerary.route
        .filter(routeItem => routeItem && routeItem.safariProperty !== false)
        .map(routeItem => routeItem.propUid)
        .sort();
      const currentPropUids = currentItinerary.route
        .filter(routeItem => routeItem && routeItem.safariProperty !== false)
        .map(routeItem => routeItem.propUid)
        .sort();
    
      // Compare the sorted arrays
      return comparisonPropUids.length === currentPropUids.length &&
             comparisonPropUids.every((propUid, i) => propUid === currentPropUids[i]);
    });
  });

  console.log('Final unique itineraries:', uniqueItineraries.length);

  return {
    uniqueItineraries,
    filteredOutItineraries: mergedItineraries.filter(
      itinerary => !uniqueItineraries.some(
        uniqueItinerary => uniqueItinerary.uuid === itinerary.uuid
      )
    )
  };
};