import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIntendedUrl } from "../features/auth/authSlice";
import Spinner from "./Spinner";
import { useAuthStatus } from "../hooks/useAuthStatus";

const PrivateRoute = () => {
  const { loggedIn, checkingStatus } = useAuthStatus();
  const location = useLocation();
  const dispatch = useDispatch();

  if (checkingStatus) {
    return <Spinner />;
  }

  if (!loggedIn) {
    dispatch(setIntendedUrl(location.pathname));
    return <Navigate to="/signin" />;
  }

  return <Outlet />;
};

export default PrivateRoute;