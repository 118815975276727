import React from 'react';

const StatusFilters = ({ statuses, onStatusChange }) => {
  return (
    <div>
      <p className="text-lg brand-text-color-800 font-semibold mt-5 ml-5">Filter itineraries</p>
      <div className="ml-5 z-50">
        <span className="text-sm brand-text-color-800 font-semibold">Status</span>

        {Object.entries(statuses).map(([key, value]) => (
          <div key={key}>
            <input
              type="checkbox"
              id={`status${key}`}
              checked={value}
              onChange={() => onStatusChange(key)}
              disabled={Object.values(statuses).filter(Boolean).length === 1 && value}
            />
            <label htmlFor={`status${key}`} className="ml-2 text-sm">
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </label>
          </div>
        ))}

        <div className="text-xs brand-text-color-800 font-normal mt-3">(you must select at least one)</div>
      </div>
    </div>
  );
};

export default StatusFilters;