import { MdClose, MdOutlineSearch } from 'react-icons/md';

import { connectSearchBox } from 'react-instantsearch-dom';
import { useState } from 'react';

const ManageBookingsSearchBox = ({ refine, currentRefinement }) => {
  const [searchQuery, setSearchQuery] = useState(currentRefinement);

  const handleSubmit = (event) => {
    event.preventDefault();
    refine(searchQuery);
  };

  const handleClear = () => {
    setSearchQuery('');
    refine('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="relative">
        <MdOutlineSearch size={26} className="absolute left-2 top-2 cursor-pointer" onClick={handleSubmit} />
        <input
          type="text"
          value={searchQuery}
          style={{ width: '400px' }}
          className="pl-10 w-full rounded border-slate-200"
          placeholder="Search..."
          onChange={(event) => {
            setSearchQuery(event.currentTarget.value);
          }}
        />
        <MdClose size={26} className="absolute right-2 top-2 cursor-pointer" onClick={handleClear} />
      </div>
    </form>
  );
};

export default connectSearchBox(ManageBookingsSearchBox);

// import { MdClose, MdOutlineSearch } from 'react-icons/md';

// import { connectSearchBox } from 'react-instantsearch-dom';

// const ManageBookingsSearchBox = ({ refine, currentRefinement, onChange }) => (
//   <form>
//     <div className={`relative`}>
//       <MdOutlineSearch size={26} className="absolute left-2 top-2" />
//       <input
//         type="text"
//         value={currentRefinement}
//         style={{ width: '400px' }}
//         className={`pl-10 w-full rounded border-slate-200`}
//         placeholder="Search..."
//         onChange={(event) => {
//           refine(event.currentTarget.value);
//           onChange(event);
//         }}
//       />
//       <MdClose
//         size={26}
//         className={`absolute right-2 top-2 cursor-pointer`}
//         onClick={() => {
//           refine('');
//           onChange({ currentTarget: { value: '' } });
//         }}
//       />
//     </div>
//   </form>
// );

// export default connectSearchBox(ManageBookingsSearchBox);
