import 'react-datepicker/dist/react-datepicker.css';
import 'flatpickr/dist/flatpickr.css';
import '../css/datePickerStyles.css';

import { Dialog, Transition } from '@headlessui/react';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp, IoIosLink } from 'react-icons/io';
import { MdCheck, MdClose } from 'react-icons/md';
import Select, { components } from 'react-select';
import { buildGuestsFromSubcollection, rateLabelMap } from '../functions/itineraryFunctions';
import { collapseExpandAllItems} from '../functions/finalPageFunctions';
import { createUrl } from '../functions/makeFunctions';
import { formatPrice, getPropObj } from '../functions/generalFunctions';
import { loadBooking, loadProperties, loadPropertiesManageBookings } from '../functions/loadDataFunctions';
import { getFirestore, collection, getDocs, Timestamp } from 'firebase/firestore';
import { isBefore, parseISO } from 'date-fns';

import ButtonOutlineSmall from './Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from './Buttons/ButtonPrimarySmall';
import ExportDocuments from './ExportDocuments';

import ManageBookingsHeader from './ManageBookingsHeader';
import ManageBookingsSlideOutFooter from './ManageBookingsSlideOutFooter';
import ViewActivity from './ViewActivity';
import ViewDropPickup from './ViewDropPickup';
import ViewPageComp from './ViewPageComp';
import ViewTransferItem from './ViewTransferItem';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { db } from '../db/firebase.config';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import InfoBox from './InfoBox';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

//import Flatpickr from 'flatpickr'; // Import the Flatpickr

// ... (rest of your imports and team array)

function ManageBookingsSlideOut({ isOpen, onClose, selectedItinerary, selectedHit, user }) {
  const navigate = useNavigate();

  // reset variables
  const resetForm = useCallback(() => {
    setFirstName(null);
    setMiddleName(null);
    setLastName(null);
    setDateOfBirth(null);
    setAge(null);
    setTitle(null);
    setSex(null);
    //setWeight(null);
    setNationality(null);
    // setNationality(null);
    setDietaryRequirements(null);
    setDietaryRequirementsInfo(null);
    setHealthRequirements(null);
    setHealthRequirementsInfo(null);
    setOtherInterestsInfo(null);
    setIsLeadPassenger(null);
    setPassportNumber(null);
    setGuestEdit(null);
    setEdit(false);
    setAdd(false);
  });

  const getInitialGuestEdit = () => ({
    age: 99,
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: null,
    nationality: null,
    passportNumber: null,
    isLeadPassenger: null,
    sex: null,
    //weight: 0,
    dietaryRequirements: null,
    dietaryRequirementsInfo: '',
    healthRequirements: null,
    healthRequirementsInfo: '',
    otherInterestsInfo: '',
    used: false,
  });

  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [guestEdit, setGuestEdit] = useState(null);
  const [guests, setGuests] = useState([]);
  // Form values
  const [firstName, setFirstName] = useState(null);
  const [middleName, setMiddleName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [age, setAge] = useState(null);
  //const [weight, setWeight] = useState(null);
  const [nationality, setNationality] = useState(null);
  //const [nationality, setNationality] = useState(null);
  const [dietaryRequirements, setDietaryRequirements] = useState(null);
  const [dietaryRequirementsInfo, setDietaryRequirementsInfo] = useState(null);
  const [healthRequirements, setHealthRequirements] = useState(null);
  const [healthRequirementsInfo, setHealthRequirementsInfo] = useState(null);
  const [otherInterestsInfo, setOtherInterestsInfo] = useState(null);
  const [isLeadPassenger, setIsLeadPassenger] = useState(false);
  const [passportNumber, setPassportNumber] = useState(null);
  const [title, setTitle] = useState(null);
  const [sex, setSex] = useState(null);

  const [sortedBy, setSortedBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  // load data for view page
  console.log('selectedItinerary: ', JSON.stringify(selectedItinerary, null, 2));
  console.log('selectedHit: ', JSON.stringify(selectedHit, null, 2));

  const [initialRender, setInitialRender] = useState(false);


  const [supplierReservations, setSupplierReservations] = useState([]);

  
  const [transfers, setTransfers] = useState([]);
  const [finalPageTransferData, setFinalPageTransferData] = useState([]);
  const [activitiesData, setActivitiesData] = useState([]);
  const [finalPageData, setFinalPageData] = useState([]);
  const [customFinalItems, setCustomFinalItems] = useState([]);
  const [finalCollapse, setFinalCollapse] = useState(false);

  const { allSuppliers } = useSelector((state) => state.suppliers);
  var suppliersShort = allSuppliers.result;
  let tempSuppliers = JSON.parse(JSON.stringify(suppliersShort));
  const [suppliers, setSuppliers] = useState(tempSuppliers);

  const { allAirports } = useSelector((state) => state.airports);
  var airportsShort = allAirports.result;
  let tempAirports = JSON.parse(JSON.stringify(airportsShort));

  const [airports, setAirports] = useState(tempAirports);

  const [properties, setProperties] = useState([]);
  const [loadedBooking, setLoadedBooking] = useState(null);
  //load properties
  // useEffect(() => {
  //   const loadPropertiesForBookings = async () => {
  //     console.log('loadPropertiesForBookings');
  //     for (let i = 0; i < selectedHit.itineraryData.length; i++) {
  //       //console.log('selectedHit.itineraryData[i].propName', selectedHit.itineraryData[i].propName);
  //       await loadProperties(selectedHit.itineraryData[i].propUid, properties, navigate);
  //     }
  //   };
  //   if (selectedHit !== null) {
  //     loadPropertiesForBookings();
  //   }
  // }, [navigate, properties, selectedHit]);

  useEffect(() => {
    const loadPropertiesForBookings = async () => {
      console.log('loadPropertiesForBookings');
      const updatedProperties = [];
      for (let i = 0; i < selectedHit.itineraryData.length; i++) {
        const propUid = selectedHit.itineraryData[i].propUid;
        const loadedBooking = await loadBooking(selectedHit.uid);
        setLoadedBooking(loadedBooking);
        // set all variables
        setTransfers(loadedBooking && loadedBooking.transfersData ? loadedBooking.transfersData : []);
        setFinalPageTransferData(loadedBooking && loadedBooking.transfersPricing ? loadedBooking.transfersPricing : []);
        setFinalPageData(loadedBooking && loadedBooking.calculatedPricing ? loadedBooking.calculatedPricing : []);
        setCustomFinalItems(loadedBooking && loadedBooking.customItems ? loadedBooking.customItems : []);
        setFinalCollapse(loadedBooking && loadedBooking.finalCollapse ? loadedBooking.finalCollapse : false);
        if (loadedBooking && loadedBooking.activitiesData) {
          const originalActivitiesData = loadedBooking.activitiesData.map((activityData) => {
            const { activities, ...rest } = activityData;
            const originalActivities = activities.map((activity) => {
              return activity.values;
            });
            return { ...rest, activities: originalActivities };
          });
          setActivitiesData(originalActivitiesData);
          try {
            const guests = await buildGuestsFromSubcollection(db, loadedBooking.uid);
            setGuests(guests);
            console.log('Guests:', guests);
          } catch (error) {
            console.error('Error fetching guests:', error);
          }
          // !setGuests(mergePaxData(loadedBooking));
        }

        const loadedProperty = await loadPropertiesManageBookings(propUid, navigate);
        if (loadedProperty) {
          updatedProperties.push(loadedProperty);
        }
      }
      console.log('updatedProperties: ', JSON.stringify(updatedProperties, null, 2));
      setProperties(updatedProperties);
    };

    if (selectedHit !== null) {
      loadPropertiesForBookings();
    }
  }, [selectedHit]);

  useEffect(() => {
    const fetchSupplierReservations = async () => {
      if (selectedHit && selectedHit.uid) {
        const db = getFirestore();
        const supplierReservationsRef = collection(db, 'itineraries', selectedHit.uid, 'supplierReservations');
        const querySnapshot = await getDocs(supplierReservationsRef);
        
        const reservations = [];
        querySnapshot.forEach(doc => {
          reservations.push(doc.data());
        });

        setSupplierReservations(reservations);
      }
    };

    fetchSupplierReservations();
  }, [selectedHit]);

  const resetFormClose = () => {
    resetForm();
    onClose();
  };

  const [generatedCopy, setGeneratedCopy] = useState(null);
  const [generatedDownload, setGeneratedDownload] = useState(null);

  useEffect(() => {
    if (isOpen) {
      resetForm();

      setProperties([]);
      setInitialRender(false);
      setLoadedBooking(null);
      setGeneratedCopy(null);
      setGeneratedDownload(null);
      setProperties([]);
      setInitialRender(false);
    }
  }, [isOpen]);

  const [download, setDownload] = useState('costing');
  const [generating, setGenerating] = useState(false);

  const handleClickOpen = () => {
    window.open(generatedDownload, '_blank');
  };

  const handleCopy = async () => {
    if (generatedCopy !== null) {
      try {
        await navigator.clipboard.writeText(generatedCopy);
        toast.success('Link copied to clipboard');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    } else {
      toast.error('You must generate a link first');
    }
  };

  const handleClickUrl = async (firestoreUid, item) => {
    setGenerating(true);
    setGeneratedCopy(null);
    setGeneratedDownload(null);
    const result = await createUrl(firestoreUid, item);
    console.log('result new:', JSON.stringify(result, null, 2));
    if (result.error) {
      toast.error(result.errorMsg);
    } else {
      setGeneratedCopy(result.copy);
      setGeneratedDownload(result.download);
    }
    setGenerating(false);
  };

  useEffect(() => {
    console.log('properties updated: ', JSON.stringify(properties, null, 2));
  }, [properties]);

  if (selectedHit === null) {
    return null;
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-20 transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-0 flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="flex-1 h-full overflow-y-auto">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto">
                        <div className="bg-white px-4 pt-6 sm:px-6">
                          <Dialog.Title className={`mt-5 text-2xl font-base font-normal leading-6 `}>
                            <div className="flex justify-between">
                              <div className="flex">
                                <div className="ml-0 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="relative rounded-md bg-white text-black hover:text-black"
                                    onClick={() => {
                                      if (edit || add) {
                                        setEdit(false);
                                        setAdd(false);
                                        resetForm();
                                      } else {
                                        setEdit(false);
                                        setAdd(false);
                                        resetFormClose();
                                      }
                                    }}
                                  >
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                  </button>
                                  <span className={`text-black text-base ml-5`}>{selectedHit ? selectedHit.code : 'No code'} OVERVIEW</span>
                                </div>

                                {add && <span className={`text-black`}>{`>`} Add Guest</span>}
                              </div>{' '}
                              <div key={uuidv4()} className="col-span-1 mt-0">
                                <div className="flex justify-end gap-4">
                                  {/* {user.internalUser && (user.email === 'prideaux@gmail.com' || user.email === 'harry.prowse@nomad-tanzania.net') && ( */}
                                  <ButtonOutlineSmall
                                    text="Manage Itinerary"
                                    color="dark"
                                    onClick={() => {
                                      navigate(`/itinerary/view/viewItinerary/${selectedHit.uid}`, {
                                        replace: true,
                                      });
                                    }}
                                  />
                                  {/* )} */}

                                  {user.internalUser && (
                                    <ButtonOutlineSmall
                                      text="Edit itinerary"
                                      color="dark"
                                      onClick={() => {
                                        navigate(`/itinerary/edit/editItinerary/${selectedHit.uid}`, {
                                          replace: true,
                                        });
                                      }}
                                    />
                                  )}

                                  {!user.internalUser && !selectedHit.user.internalUser && selectedHit.status !== 'confirmed' && selectedHit.status !== 'cancelled' && (
                                    <ButtonOutlineSmall
                                      text="Edit itinerary"
                                      color="dark"
                                      onClick={() => {
                                        navigate(`/itinerary/edit/editItinerary/${selectedHit.uid}`, {
                                          replace: true,
                                        });
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </Dialog.Title>

                          <div className="mt-6 text-2xl font-semibold">{selectedHit && selectedHit.itineraryName ? selectedHit.itineraryName : ''}</div>
                          {selectedHit !== null && (
                            <ManageBookingsHeader
                              booking={selectedHit}
                              guests={guests}
                              supplierReservations={supplierReservations}
                            />
                          )}
                          {selectedHit && selectedHit.hidden && !user.internalUser && (
                            <div className="mb-8">
                              <InfoBox
                                icon={InformationCircleIcon}
                                text="This itinerary is currently being edited or updated and may not be complete or reflect the most recent changes. During this time you are unable to edit or download documents."
                                bgColor="bg-[#F09D38]"
                                textColor="text-white"
                                fontSize="text-base"
                              />
                            </div>
                          )}
                          {((selectedHit && !selectedHit.hidden) || user.internalUser) && (
                            <div className="mb-8">
                              <ExportDocuments booking={selectedHit} user={user} />
                            </div>
                          )}
                          {/* BUILD ITINERARY DETAILS HERE */}
                          <div className="mb-12 text-xs">ITINERARY DETAILS</div>

                          {properties.length !== 0 &&
                            selectedHit !== null &&
                            selectedHit.itineraryData.map((booking, bi) => {
                              const firstDay = selectedHit.dateStart;
                              let arrivalTransfer = false;
                              if (bi === 0) {
                                arrivalTransfer = transfers.find((transfer) => transfer.uuid === booking.id && transfer.objType === 'arrival' && transfer.formValues);
                                console.log('arrivalTransfer check');
                                console.log(JSON.stringify(arrivalTransfer, null, 2));
                                if (arrivalTransfer === undefined) {
                                  arrivalTransfer = false;
                                }
                              }

                              let departureTransfer = false;
                              if (bi === selectedHit.itineraryData.length - 1) {
                                departureTransfer = transfers.find((transfer) => transfer.uuid === booking.id && transfer.objType === 'departure' && transfer.formValues);
                                console.log('departureTransfer check');
                                console.log(JSON.stringify(departureTransfer, null, 2));
                                if (departureTransfer === undefined) {
                                  departureTransfer = false;
                                }
                              }

                              let propertyTransfer = false;
                              if (bi >= 0 && bi < selectedHit.itineraryData.length - 1) {
                                propertyTransfer = transfers.find((transfer) => transfer.uuid === booking.id && transfer.objType === 'propertyTransfer' && transfer.formValues);
                                console.log('propertyTransfer check');
                                console.log(JSON.stringify(propertyTransfer, null, 2));
                                if (propertyTransfer === undefined) {
                                  propertyTransfer = false;
                                }
                              }
                              // propertyTransfer = false;
                              // FLATTEN ACTIVITIES
                              const flattenActivities = (activitiesData) => {
                                return activitiesData.reduce((acc, item) => {
                                  const flattenedActivities = item.activities.flat().map((activity) => ({ ...activity, parent: item }));

                                  return [...acc, ...flattenedActivities];
                                }, []);
                              };

                              console.log('FINAL ITEM VARS');
                              console.log('bookings.length', selectedHit.itineraryData.length);
                              console.log('bi', bi);
                              console.log('arrivalTdepartureTransferransfer', departureTransfer);
                              if (!initialRender) {
                                collapseExpandAllItems(finalPageData, setFinalPageData, finalPageTransferData, setFinalPageTransferData, customFinalItems, setCustomFinalItems, setFinalCollapse, true);
                                setInitialRender(true);
                              }
                              return (
                                <div key={uuidv4()} className="mx-0 mt-5">
                                  {/* slideout */}
                                  {/* <FinalSlideOut isOpen={isSlideOutOpen} onClose={handleCloseSlideOut} /> */}

                                  {/* {bi + 1}: {booking.propName} */}
                                  {/* CHECK FOR CUSTOM */}

                                  {/* ARRIVAL TRANSFER */}
                                  {arrivalTransfer &&
                                    finalPageTransferData
                                      .filter((item) => item.uuid === booking.id && item.objType === 'arrival')
                                      .map((item, index) => {
                                        // const nextItem = index < bookings.length - 1 ? bookings[index + 1] : null;
                                        // const prevItem = index > 0 ? bookings[index - 1] : null;
                                        console.log('arrivalTransfer check 1');
                                        console.log(JSON.stringify(item, null, 2));

                                        return (
                                          <div key={uuidv4()} style={{ zIndex: 1000 - index }}>
                                            <ViewTransferItem
                                              item={item}
                                              index={index}
                                              suppliers={suppliers}
                                              // finalTransferInputs={finalTransferInputs}
                                              // setFinalTransferInputs={setFinalTransferInputs}
                                              finalPageTransferData={finalPageTransferData}
                                              setFinalPageTransferData={setFinalPageTransferData}
                                              // resetFinalTransferInputs={resetFinalTransferInputs}
                                              // resetEditTransferItem={resetEditTransferItem}
                                              ///setFinalInputs={setFinalInputs}
                                              customFinalItems={customFinalItems}
                                              setCustomFinalItems={setCustomFinalItems}
                                              // customFinalCut={customFinalCut}
                                              //setCustomFinalCut={setCustomFinalCut}
                                              //handleOpenSlideOut={handleOpenSlideOut}
                                              // setCustomItemToEdit={setCustomItemToEdit}
                                              // setAddFinalItemDetails={setAddFinalItemDetails}
                                              transfers={transfers}
                                              properties={properties}
                                              firstDay={firstDay}
                                              bookingMaster={loadedBooking}
                                              manage={true}
                                              //setFinalSlideOutDate={setFinalSlideOutDate}
                                              supplierReservations={supplierReservations}
                                            />
                                          </div>
                                        );
                                      })}

                                  {/* ARRIVAL PICKUP */}
                                  {arrivalTransfer && arrivalTransfer?.formValues?.includeArrivalTransfer && (
                                    <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                      <ViewDropPickup
                                        item={arrivalTransfer}
                                        bookings={selectedHit.itineraryData}
                                        booking={booking}
                                        airports={airports}
                                        pickupDropoff={'pickup'}
                                        customFinalItems={customFinalItems}
                                        setCustomFinalItems={setCustomFinalItems}
                                        properties={properties}
                                        transfers={transfers}
                                        shouldCollapse={finalCollapse}
                                        firstDay={firstDay}
                                        bookingMaster={loadedBooking}
                                        manage={true}
                                        supplierReservations={supplierReservations}
                                      />
                                    </div>
                                  )}

                                  {/* PROPERTY */}
                                  {finalPageData
                                    .filter((item) => item.uuid === booking.id)
                                    .map((item, index) => (
                                      <ViewPageComp
                                        key={item.uuid}
                                        item={item}
                                        index={index}
                                        finalPageData={finalPageData}
                                        setFinalPageData={setFinalPageData}
                                        properties={properties}
                                        getPropObj={getPropObj}
                                        rateLabelMap={rateLabelMap}
                                        formatPrice={formatPrice}
                                        suppliers={suppliers}
                                        customFinalItems={customFinalItems}
                                        setCustomFinalItems={setCustomFinalItems}
                                        bookings={selectedHit.itineraryData}
                                        finalItem={selectedHit.itineraryData.length === bi + 1 && !departureTransfer?.formValues ? true : false}
                                        firstDay={firstDay}
                                        bookingMaster={loadedBooking}
                                        manage={true}
                                        supplierReservations={supplierReservations}
                                      />
                                    ))}

                                  {/* ACTIVITIES */}
                                  {flattenActivities(activitiesData)
                                    .filter((activity) => activity.parent.id === booking.id)
                                    .map((activity, index) => (
                                      <div key={activity.uuid} style={{ zIndex: 1000 - index }}>
                                        <ViewActivity
                                          item={activity}
                                          index={index}
                                          bookings={selectedHit.itineraryData}
                                          booking={booking}
                                          activitiesData={activitiesData}
                                          customFinalItems={customFinalItems}
                                          setCustomFinalItems={setCustomFinalItems}
                                          shouldCollapse={finalCollapse}
                                          firstDay={firstDay}
                                          finalItem={selectedHit.itineraryData.length === bi + 1 && !departureTransfer?.formValues ? true : false}
                                          bookingMaster={loadedBooking}
                                          manage={true}
                                          supplierReservations={supplierReservations}
                                        />
                                      </div>
                                    ))}

                                  {propertyTransfer && propertyTransfer?.formValues?.includeDepartureTransfer && propertyTransfer.objType === 'propertyTransfer' && (
                                    <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                      <ViewDropPickup
                                        item={propertyTransfer}
                                        bookings={selectedHit.itineraryData}
                                        booking={booking}
                                        airports={airports}
                                        pickupDropoff={'pickup'}
                                        customFinalItems={customFinalItems}
                                        setCustomFinalItems={setCustomFinalItems}
                                        properties={properties}
                                        transfers={transfers}
                                        shouldCollapse={finalCollapse}
                                        firstDay={firstDay}
                                        bookingMaster={loadedBooking}
                                        manage={true}
                                        supplierReservations={supplierReservations}
                                      />
                                    </div>
                                  )}

                                  {/* INTER PROPERTY TRANSFER */}
                                  {propertyTransfer &&
                                    finalPageTransferData
                                      .filter((item) => item.uuid === booking.id && item.objType === 'propertyTransfer')
                                      .map((item, index) => {
                                        // const nextItem = index < bookings.length - 1 ? bookings[index + 1] : null;
                                        // const prevItem = index > 0 ? bookings[index - 1] : null;

                                        return (
                                          <div key={uuidv4()} style={{ zIndex: 1000 - index }}>
                                            <ViewTransferItem
                                              item={item}
                                              index={index}
                                              suppliers={suppliers}
                                              // finalTransferInputs={finalTransferInputs}
                                              // setFinalTransferInputs={setFinalTransferInputs}
                                              finalPageTransferData={finalPageTransferData}
                                              setFinalPageTransferData={setFinalPageTransferData}
                                              // resetFinalTransferInputs={resetFinalTransferInputs}
                                              // resetEditTransferItem={resetEditTransferItem}
                                              ///setFinalInputs={setFinalInputs}
                                              customFinalItems={customFinalItems}
                                              setCustomFinalItems={setCustomFinalItems}
                                              // customFinalCut={customFinalCut}
                                              //setCustomFinalCut={setCustomFinalCut}
                                              //handleOpenSlideOut={handleOpenSlideOut}
                                              // setCustomItemToEdit={setCustomItemToEdit}
                                              // setAddFinalItemDetails={setAddFinalItemDetails}
                                              transfers={transfers}
                                              properties={properties}
                                              firstDay={firstDay}
                                              //setFinalSlideOutDate={setFinalSlideOutDate}
                                              bookingMaster={loadedBooking}
                                              manage={true}
                                              supplierReservations={supplierReservations}
                                            />
                                          </div>
                                        );
                                      })}

                                  {/* ARRIVAL PICKUP */}
                                  {propertyTransfer && propertyTransfer?.formValues?.includeArrivalTransfer && propertyTransfer.objType === 'propertyTransfer' && (
                                    <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                      <ViewDropPickup
                                        item={propertyTransfer}
                                        bookings={selectedHit.itineraryData}
                                        booking={booking}
                                        airports={airports}
                                        pickupDropoff={'pickup'}
                                        customFinalItems={customFinalItems}
                                        setCustomFinalItems={setCustomFinalItems}
                                        properties={properties}
                                        transfers={transfers}
                                        shouldCollapse={finalCollapse}
                                        firstDay={firstDay}
                                        bookingMaster={loadedBooking}
                                        manage={true}
                                        supplierReservations={supplierReservations}
                                      />
                                    </div>
                                  )}

                                  {/* DEPARTURE DROPOFF */}
                                  {departureTransfer && departureTransfer?.formValues?.includeDepartureTransfer && (
                                    <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                      <ViewDropPickup
                                        item={departureTransfer}
                                        bookings={selectedHit.itineraryData}
                                        booking={booking}
                                        airports={airports}
                                        pickupDropoff={'pickup'}
                                        customFinalItems={customFinalItems}
                                        setCustomFinalItems={setCustomFinalItems}
                                        properties={properties}
                                        transfers={transfers}
                                        shouldCollapse={finalCollapse}
                                        firstDay={firstDay}
                                        bookingMaster={loadedBooking}
                                        manage={true}
                                        supplierReservations={supplierReservations}
                                      />
                                    </div>
                                  )}

                                  {/* DEPARTURE TRANSFER */}
                                  {departureTransfer &&
                                    finalPageTransferData
                                      .filter((item) => item.uuid === booking.id && item.objType === 'departure')
                                      .map((item, index) => {
                                        // const nextItem = index < bookings.length - 1 ? bookings[index + 1] : null;
                                        // const prevItem = index > 0 ? bookings[index - 1] : null;

                                        return (
                                          <div key={uuidv4()} style={{ zIndex: 1000 - index }}>
                                            <ViewTransferItem
                                              item={item}
                                              index={index}
                                              suppliers={suppliers}
                                              // finalTransferInputs={finalTransferInputs}
                                              // setFinalTransferInputs={setFinalTransferInputs}
                                              finalPageTransferData={finalPageTransferData}
                                              setFinalPageTransferData={setFinalPageTransferData}
                                              // resetFinalTransferInputs={resetFinalTransferInputs}
                                              // resetEditTransferItem={resetEditTransferItem}
                                              ///setFinalInputs={setFinalInputs}
                                              customFinalItems={customFinalItems}
                                              setCustomFinalItems={setCustomFinalItems}
                                              // customFinalCut={customFinalCut}
                                              //setCustomFinalCut={setCustomFinalCut}
                                              //handleOpenSlideOut={handleOpenSlideOut}
                                              // setCustomItemToEdit={setCustomItemToEdit}
                                              // setAddFinalItemDetails={setAddFinalItemDetails}
                                              transfers={transfers}
                                              properties={properties}
                                              firstDay={firstDay}
                                              bookingMaster={loadedBooking}
                                              manage={true}
                                              //setFinalSlideOutDate={setFinalSlideOutDate}
                                              departureButtons={false}
                                              supplierReservations={supplierReservations}
                                            />
                                          </div>
                                        );
                                      })}
                                </div>
                              );
                            })}

                          {/* BOTTOM SECTION */}
                          {loadedBooking && (
                            <ManageBookingsSlideOutFooter
                              booking={loadedBooking}
                              total={loadedBooking.jsonData.reduce((total, current) => total + current.saleTotalFinal, 0)}
                              otherArrangementsData={loadedBooking.otherArrangementsData}
                            />
                          )}

                          {/* <div className="bg-stone-50 rounded-[15px] shadow p-5">TEST</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ManageBookingsSlideOut;
