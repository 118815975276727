import React from 'react';

const CustomDateInput = React.forwardRef(({ value, onClick, placeholderText }, ref) => (
  <button
    ref={ref}
    className="block w-full rounded-md border-0 py-1.5 text-left pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-9 bg-white"
    onClick={onClick}
  >
    {value || <span className="text-gray-500">{placeholderText}</span>}
  </button>
));

const CustomDateInputNoBorder = React.forwardRef(({ value, onClick }, ref) => (
  <button
    ref={ref} // Attach the forwarded ref to the button element
    className="underline form-input text-sm brand-text-color-v2 text-left brand-text-color-v2-hover font-medium hover:border-0 border-0 focus:border-0 border-0 outline-none shadow-white underline-offset-2 w-36"
    onClick={onClick}
  >
    {value}
  </button>
));

const CustomDateInputNoBorderNewHeader = React.forwardRef(({ value, onClick }, ref) => (
  <button
    ref={ref} // Attach the forwarded ref to the button element
    className="underline text-nomadBlue-600 text-base font-semibold text-left brand-text-color-v2-hover hover:border-0 border-0 focus:border-0 border-0 outline-none shadow-white underline-offset-2"
    onClick={onClick}
  >
    {value}
  </button>
));

const CustomDateInputBorder = React.forwardRef(({ value, onClick }, ref) => (
  <button
    ref={ref} // Attach the forwarded ref to the button element
    className="block w-full rounded-md border-0 py-1.5 text-left pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-9"
    onClick={onClick}
  >
    {value}
  </button>
));

const CustomDateInputBorderRound = React.forwardRef(({ value, onClick }, ref) => (
  <button
    ref={ref} // Attach the forwarded ref to the button element
    className="block w-full rounded-xl border border-gray-200 py-1.5 text-left pl-10 text-gray-900  focus:border-[#999075] focus:ring-1 focus:ring-[#999075] sm:text-sm sm:leading-6 h-12"
    onClick={onClick}
  >
    {value || 'Select a date...'} {/* Fallback placeholder text */}
  </button>
));

const CustomDateInputBorderNomadBlue = React.forwardRef(({ value, onClick }, ref) => (
  <button
    ref={ref} // Attach the forwarded ref to the button element
    className="block w-full rounded-xl border border-gray-200 py-1.5 text-left pl-10 text-gray-900  focus:border-[#489EF8] focus:ring-1 focus:ring-[#6996A0] sm:text-sm sm:leading-6 h-12"
    onClick={onClick}
  >
    {value || 'Select a date...'} {/* Fallback placeholder text */}
  </button>
));

const CustomDateInputAvailability = React.forwardRef(({ value, onClick }, ref) => (
  <button
    ref={ref} // Attach the forwarded ref to the button element
    className="form-input pl-9 text-slate-500 text-left hover:text-slate-400 text-sm font-medium focus:border-slate-400 w-[150px] h-10 rounded-md border-slate-400"
    onClick={onClick}
  >
    {value}
  </button>
));
export { CustomDateInput, CustomDateInputNoBorder, CustomDateInputBorder, CustomDateInputAvailability, CustomDateInputBorderRound, CustomDateInputNoBorderNewHeader, CustomDateInputBorderNomadBlue };
