import PropTypes from 'prop-types';
import React from 'react';

// Import the icon component dynamically if required; alternatively, pass the icon component itself as a prop
//import { IoMdInformationCircleOutline } from 'react-icons/io';

const TransferInfoCard = ({ icon: Icon, title, text }) => {
  return (
    <div className="w-full h-fit pr-6 py-2 bg-slate-200 rounded-lg border justify-start items-center mt-5">
      <div className="grid grid-cols-12">
        <div className="col-span-1 justify-center items-center gap-1 flex">
          {/* Render the Icon component that is passed as a prop */}
          <Icon size={30} className="text-cyan-700" />
        </div>
        <div className="col-span-11">
          <div className="justify-start items-center gap-1 flex">
            <span className="text-cyan-700 text-base font-bold leading-normal">{title}</span>
          </div>
          <div className="justify-start items-center gap-1 flex">
            <span className="text-zinc-900 text-sm font-normal leading-[21px]">{text}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Prop types for validation
TransferInfoCard.propTypes = {
  icon: PropTypes.elementType.isRequired, // Expect a component type
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default TransferInfoCard;
