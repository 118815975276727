import React from 'react';
import { ListBulletIcon, Squares2X2Icon } from '@heroicons/react/24/solid';

const ViewToggle = ({ view, setView }) => {
  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      <button
        type="button"
        className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
          view === 'list'
            ? 'bg-nomadBlue-600 text-white'
            : 'bg-white text-gray-700 hover:bg-gray-50'
        }`}
        onClick={() => setView('list')}
      >
        <ListBulletIcon className="h-5 w-5 inline-block mr-2" />
        List
      </button>
      <button
        type="button"
        className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
          view === 'kanban'
            ? 'bg-nomadBlue-600 text-white'
            : 'bg-white text-gray-700 hover:bg-gray-50'
        }`}
        onClick={() => setView('kanban')}
      >
        < Squares2X2Icon className="h-5 w-5 inline-block mr-2" />
        Kanban
      </button>
    </div>
  );
};

export default ViewToggle;