import { FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import { IoIosDocument, IoMdCheckmarkCircle } from 'react-icons/io';
import { MdConnectingAirports, MdContentCopy, MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { TbArrowsRandom, TbBuildingWarehouse } from 'react-icons/tb';
import { addItem, deleteItem, handleCut, handlePaste } from '../functions/finalCustomFunctions';

import { GiJeep } from 'react-icons/gi';
import { PiPark } from 'react-icons/pi';
import ViewCompDay from './ViewCompDay';
import ViewCompHeader from './ViewCompHeader';
import ViewCompTableHeader from './ViewCompTableHeader';
import ViewCompTableRow from './ViewCompTableRow';
import ViewFoundItemCard from './ViewFoundItem';
import { formatPrice } from '../functions/generalFunctions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const ManageBookingsSlideOutFooter = ({ booking, total, otherArrangementsData }) => {
  // USER
  const { user } = useSelector((state) => state.auth);

  const status = booking.status;

  if (status === 'confirmed') {
  } else if (status === 'quotation') {
  }

  // HEADER STUFF
  let headerBgColor;
  let headerTextColor;
  let headerIconColor;
  let headerIconBgColor;
  let headerText;
  // FOOTER STUFF
  let depositText;
  let depositAmount;
  let balanceText;
  let balanceAmount;
  depositText = `Deposit due ${booking.agent.paymentTerms.depositTermDays} days after booked date`;
  depositAmount = booking.totalDeposit;
  balanceText = `Balance due ${Math.abs(booking.agent.paymentTerms.balanceTermDays)} days ${booking.agent.paymentTerms.balanceTermDays < 0 ? 'before' : 'after'} travel date`;
  balanceAmount = total - booking.totalDeposit;

  if (status === 'confirmed') {
    headerBgColor = 'bg-cyan-50';
    headerTextColor = 'text-teal-600';
    headerIconColor = 'text-teal-400';
    headerIconBgColor = 'bg-cyan-100';
    headerText = 'All services are booked and your trip is confirmed!';
    depositText = 'Deposit due before ' + moment(booking.depositDueDate).format('Do MMMM YYYY');
    depositAmount = booking.totalDeposit;
    balanceText = 'Balance due before ' + moment(booking.balanceDueDate).format('Do MMMM YYYY');
    balanceAmount = total - booking.totalDeposit;
  } else if (status === 'quotation') {
    headerBgColor = 'bg-gray-50';
    headerTextColor = 'text-black';
    headerIconColor = 'text-stone-300';
    headerIconBgColor = 'bg-neutral-100';
    headerText = 'No services held.';
  } else {
    headerBgColor = 'bg-white';
    headerTextColor = 'text-black';
    headerIconColor = 'text-stone-300';
    headerIconBgColor = 'bg-neutral-100';
    // Text
    headerText = 'Default text';
  }

  const checkedOtherArrangementsDataTotal = otherArrangementsData.reduce((acc, item) => {
    return item.checked ? acc + item.saleTotal : acc;
  }, 0);

  return (
    <>
      {/* main items */}

      <div key={uuidv4()} style={{ zIndex: 1000, WebkitTransform: 'translateZ(0)' }} className={`mb-10 mt-5`}>
        <div className="bg-white rounded-[15px] shadow p-5">
          <div className="bg-white">
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-4 flex justify-start bg-gray-50 rounded-[15px] px-5">
                <div className="ml-5 my-5">
                  <div className="text-zinc-800 text-base font-medium leading-tight">Add-on</div>
                  {otherArrangementsData.map(
                    (item) =>
                      item.checked && (
                        <div key={uuidv4()} className="mt-5 text-zinc-500 text-xs font-normal leading-tight">
                          {item.name} ${item.saleTotal}
                        </div>
                      ),
                  )}
                </div>
              </div>
              <div className="col-span-8 grid grid-cols-12 bg-gray-50 rounded-[15px] py-0 px-5">
                <div className="col-span-9 ml-5 h-14 border-b border-gray-200 flex items-center text-base text-zinc-800">
                  <span>Subtotal</span>
                </div>
                <div className="col-span-3 flex justify-end mr-5 h-14 border-b border-gray-200 flex items-center text-base text-zinc-800">
                  <span>${formatPrice(total - checkedOtherArrangementsDataTotal)}</span>
                </div>
                <div className="col-span-9 ml-5 h-14 border-b border-gray-200 flex items-center text-xs text-zinc-500 text-base text-zinc-500">
                  <span>{depositText}</span>
                </div>
                <div className="col-span-3 flex justify-end mr-5 h-14 border-b border-gray-200 flex items-center text-xs text-zinc-500">
                  <span>${formatPrice(depositAmount)}</span>
                </div>
                <div className="col-span-9 ml-5 h-14 border-b border-gray-200 flex items-center text-xs text-zinc-500">
                  <span>{balanceText}</span>
                </div>
                <div className="col-span-3 flex justify-end mr-5 h-14 border-b border-gray-200 flex items-center text-xs text-zinc-500">
                  <span>${formatPrice(balanceAmount)}</span>
                </div>
                <div className="col-span-9 ml-5 h-14 flex items-center text-xl text-zinc-8000 font-semibold">
                  <span>Total</span>
                </div>
                <div className="col-span-3 flex justify-end mr-5 h-14 flex items-center text-xl text-zinc-800 font-semibold">
                  <span>${formatPrice(total)}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="text-xs text-zinc-400 text-right mt-5 mr-2">Avg. price per person $10,065</div> */}
        </div>
      </div>
    </>
  );
};

export default ManageBookingsSlideOutFooter;
