import React from 'react';



function SeasonalGuideTable({ parkData }) {
    const getThreeLetterMonth = (monthName) => {
        return monthName.slice(0, 3);
      };
      
  return (
    <table className="min-w-full divide-y divide-gray-200 ">
      <thead className="bg-nomadGreenLight">
        <tr>
          <th scope="col" className="px-6 py-3 text-left text-base font-semibold font-sans text-white tracking-wider">
            Park
          </th>
          <th scope="col" className="px-6 py-3 text-left text-base font-semibold font-sans text-white  tracking-wider">
            Month
          </th>
          <th scope="col" className="px-6 py-3 text-left text-base font-semibold font-sans text-white  tracking-wider">
            Rainfall
          </th>
          <th scope="col" className="px-6 py-3 text-left text-base font-semibold font-sans text-white  tracking-wider">
            High Temp
          </th>
          <th scope="col" className="px-6 py-3 text-left text-base font-semibold font-sans text-white  tracking-wider">
            Park Info
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {parkData.map((park) =>
          park.months.map((month) => (
            <tr key={`${park.name}-${month.month}`}>
              <td className="px-6 py-4 font-sans whitespace-nowrap text-3xl text-nomadBlue">{park.name}</td>
              <td className="px-6 py-4 font-sans uppercase whitespace-nowrap text-nomadRed">{getThreeLetterMonth(month.month)}</td>
              <td className="px-6 py-4 font-sans whitespace-nowrap text-nomadBlue">{month.rainfall}</td>
              <td className="px-6 py-4 font-sans whitespace-nowrap text-nomadBlue">{month.highTemp}</td>
              <td className="px-6 py-4 font-sans text-nomadBlue">{month.description}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}

export default SeasonalGuideTable;