import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { getAirlines } from '../features/airlines/airlinesSlice';
import { getAirports } from '../features/airports/airportsSlice';
import { getCountries } from '../features/countries/countriesSlice';
//import { getFlightRoutes } from "../features/flightRoutes/flightRoutesSlice";
import { getLocations } from '../features/locations/locationsSlice';
import { getSuppliers } from '../features/suppliers/suppliersSlice';
import { getTemplateSearchOptions } from '../features/templateSearchOptions/templateSearchOptionsSlice';

//import { getProperties } from "../features/properties/propertiesSlice";

export const useAuthStatus = () => {
  const dispatch = useDispatch();
  //const { propertiesSuccess } = useSelector((state) => state.properties);
  const { airportsSuccess } = useSelector((state) => state.airports);
  const { airlinesSuccess } = useSelector((state) => state.airlines);
  // const { flightRoutesSuccess } = useSelector((state) => state.flightRoutes);
  const { locationsSuccess } = useSelector((state) => state.locations);
  const { suppliersSuccess } = useSelector((state) => state.suppliers);
  const { templateSearchOptionsSuccess } = useSelector((state) => state.templateSearchOptions);

  const [loggedIn, setLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);

  const [calledProperties, setCalledProperties] = useState(false);
  const [calledAirports, setCalledAirports] = useState(false);
  const [calledAirlines, setCalledAirlines] = useState(false);
  const [calledFlightRoutes, setCalledFlightRoutes] = useState(false);
  const [calledLocations, setCalledLocations] = useState(false);
  const [calledSuppliers, setCalledSuppliers] = useState(false);
  const [calledCountries, setCalledCountries] = useState(false);
  const [calledTemplateSearchOptions, setCalledTemplateSearchOptions] = useState(false);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    //!calledProperties && dispatch(getProperties());
    //setCalledProperties(true);
    !calledAirlines && dispatch(getAirlines());
    setCalledAirlines(true);
    !calledAirports && dispatch(getAirports());
    setCalledAirports(true);
    // !calledFlightRoutes && dispatch(getFlightRoutes());
    // setCalledFlightRoutes(true);
    !calledLocations && dispatch(getLocations());
    setCalledLocations(true);
    //!calledAgents && dispatch(getAgents());
    //setCalledAgents(true);
    !calledSuppliers && dispatch(getSuppliers());
    setCalledSuppliers(true);

    !calledCountries && dispatch(getCountries());
    setCalledCountries(true);

    !calledTemplateSearchOptions && dispatch(getTemplateSearchOptions());
    setCalledTemplateSearchOptions(true);

    //if (propertiesSuccess && agentsSuccess) {
    if (airportsSuccess && airlinesSuccess && locationsSuccess && suppliersSuccess && calledSuppliers && templateSearchOptionsSuccess) {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
      setCheckingStatus(false);
    }
  }, [
    airlinesSuccess,
    airportsSuccess,
    calledAirlines,
    calledAirports,
    calledCountries,
    calledFlightRoutes,
    calledLocations,
    calledProperties,
    calledSuppliers,
    calledTemplateSearchOptions,
    dispatch,
    locationsSuccess,
    suppliersSuccess,
    templateSearchOptionsSuccess,
    user,
  ]);

  // useEffect(() => {
  //   if (user) {
  //     setLoggedIn(true);
  //   } else {
  //     setLoggedIn(false);
  //   }
  //   setCheckingStatus(false);
  // }, [allProperties, user]);
  return { loggedIn, checkingStatus };
};
