import { MdOutlineModeEdit, MdOutlineSave } from 'react-icons/md';
import React, { useState } from 'react';

const ItineraryNameEditor = ({ editItineraryName, itineraryName, handleTextChange, handleItinerarySaveClick, handleEditItineraryClick }) => {
  //   const [editItineraryName, setEditItineraryName] = useState(false);
  //   const [itineraryName, setItineraryName] = useState(initialName);

  //   const handleTextChange = (e) => {
  //     setItineraryName(e.target.value);
  //   };

  //   const handleItinerarySaveClick = () => {
  //     setEditItineraryName(false);
  //     // Add any additional logic to save the itinerary name
  //   };

  //   const handleEditItineraryClick = () => {
  //     setEditItineraryName(true);
  //   };

  return (
    <div className="mb-3">
      <div>
        {editItineraryName ? (
          <div className="flex items-center">
            <input
              type="text"
              value={itineraryName}
              placeholder="Itinerary name"
              onChange={handleTextChange}
              className="appearance-none h-5 w-64 font-normal bg-white border-grey-light text-grey-darker focus:outline-0 focus:border-blue-500 rounded-sm"
            />
            <MdOutlineSave size={20} onClick={handleItinerarySaveClick} className="ml-2 text-nomadEvergreen-700 cursor-pointer" />
          </div>
        ) : (
          <div className="flex items-center">
            <div className="text-black text-xl font-bold w-64 truncate">{itineraryName}</div>
            <MdOutlineModeEdit size={20} onClick={handleEditItineraryClick} className="ml-2 text-nomadEvergreen-700 cursor-pointer" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ItineraryNameEditor;
