import '../../../css/instantSearch/index.css';
import '../../../css/manageBookingsPagination.css';

import { Configure, Hits, InstantSearch, SearchBox } from "react-instantsearch-dom";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropertyCard from "../components/PropertyCard";
import Sidebar from "../../../partials/Sidebar";
import algoliasearch from "algoliasearch";
import PropertyLibrarySearchBox from '../components/PropertyLibrarySearchBox';
import ConnectedPagination from '../../../components/CustomPagination';

// Algolia details
const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

const PropertyHit = ({ hit }) => {
  const linkUrl = `/propertyLibrary/${hit.uid}`;

  return (
    <div className="col-span-1">
      <Link key={hit.id} to={linkUrl}>
        <PropertyCard
          camp={{
            id: hit.uid,
            name: hit.name,
            supplier: hit.supplier.id,
            park: hit.park.name,
            description: hit.caption,
            imageUrl: hit.heroImageUrl,
          }}
        />
      </Link>
    </div>
  );
};

function PropertyLibrary() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      <InstantSearch searchClient={searchClient} indexName="properties">
        <Configure filters="active:true AND tripAdvisorId.active:true" hitsPerPage={9} />
        
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
              <div className="max-w-7xl mx-auto">
                <header className="mb-8">
                  <h1 className="text-4xl font-roca text-nomadBlue mb-2">
                    Property Library
                  </h1>
                  <p className="text-lg font-sans max-w-4xl">
                    Browse our full range of properties, available via Hornbill or booked on request. 
                  </p>
                </header>

                {/* Search bar */}
                <div className="bg-white rounded-md shadow px-5 py-4 mb-5">
                  <PropertyLibrarySearchBox />
                </div>

                {/* Results */}
                <div className="bg-white rounded-md shadow p-5">
                  <div className="hits-wrapper propertyLibrary">
                    <Hits hitComponent={PropertyHit} />
                  </div>
                  <div className="mt-4 flex justify-center">
                    <ConnectedPagination />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </InstantSearch>
    </div>
  );
}

export default PropertyLibrary;