import React, { useState } from 'react';
import { addMonths, make2digits } from './generalFunctions';

import moment from 'moment';
//import { updateDates } from "./stockFunctions";
import { v4 as uuidv4 } from 'uuid';

// ************************************* // Availablity for days
const getRoomsAll = (date, propertyCount, rooms) => {
  const stockCount = rooms[propertyCount].stock.filter((e) => e.stockStart === date);
  return stockCount;
};

const getRooms = (arrowDate, propertyCount, rooms, stockSplit) => {
  var testDate = arrowDate;
  const stockCount = rooms[propertyCount].stock.filter((e) => e.stockStart === testDate && e.stockSplit === stockSplit);

  if (stockCount[0]) {
    for (let i = 0; i < stockCount.length; i++) {
      if (stockCount[i].stockCount === '0') {
        const stockCount = rooms[propertyCount].stock.filter((e) => e.stockStart === testDate && e.stockSplit === 'provisional');
        //console.log("GET ROOMS 1: " + testDate + " " + stockSplit + " " + stockCount[0].stockCount);
        //console.log(JSON.stringify(stockCount, undefined, 4));
        return stockCount;
      } else {
        //console.log("GET ROOMS 2: " + testDate + " " + stockSplit + " " + stockCount[0].stockCount);
        //console.log(JSON.stringify(stockCount, undefined, 4));
        return stockCount;
      }
    }
  }
  //console.log("GET ROOMS 3: " + testDate + " " + stockSplit);
  return stockCount;
};

// ************************************* // Render / populate months for month drop down

const renderMonths = () => {
  const monthList = [
    <option key={uuidv4()} value="select">
      Select month
    </option>,
  ];
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  for (let i = currentMonth; i < currentMonth + 24; i++) {
    let monthIndex = i % 12;
    let year = currentYear + Math.floor(i / 12);
    monthList.push(
      <option key={uuidv4()} value={year + '-' + (monthIndex + 1)}>
        {new Date(year, monthIndex, 1).toLocaleString('default', {
          month: 'short',
        })}{' '}
        {year}
      </option>,
    );
  }
  return monthList;
};

// ************************************* // Remove all rooms from results
const removeAll = async (properties, setProperties, setLoadingData, setRooms) => {
  setLoadingData(true);

  for (var i = 0; i < properties.length; i++) {
    properties[i].selected = false;
    properties[i].getAccom = false;
  }
  setRooms([]);
  //setProperties({ result: [] });
  // ! setNoRes(true);
  setLoadingData(false);
};

const removeAllAvailability = async (properties, setProperties, setLoadingData, setRooms) => {
  setLoadingData(true);

  for (var i = 0; i < properties.length; i++) {
    properties[i].selected = false;
    properties[i].getAccom = false;
  }
  setRooms([]);
  setProperties([]);
  // ! setNoRes(true);
  setLoadingData(false);
};

// ************************************* // Remove one room from results
const removeOne = async (propUid, rooms) => {
  // ! setLoadingData(true);

  const requiredIndex = rooms.findIndex((el) => {
    return el.propUid === String(propUid);
  });
  if (requiredIndex === -1) {
    return false;
  }
  return !!rooms.splice(requiredIndex, 1);
};

// ************************************* // Unselect property
const propertySelected = (num, properties) => {
  properties[num].selected = !properties[num].selected;
};

// ************************************* // Change getAccom property
const propertyAccom = (num, properties) => {
  properties[num].getAccom = !properties[num].getAccom;
};

// ************************************* // Previous button function
const prevDate = (setArrowDate, arrowDate) => {
  setArrowDate(moment(arrowDate).subtract(7, 'days').format('YYYY-MM-DD'));
};

// ************************************* // Next button function
const nextDate = (setArrowDate, arrowDate) => {
  setArrowDate(moment(arrowDate).add(7, 'days').format('YYYY-MM-DD'));
};

const prevDate21 = (setArrowDate, arrowDate) => {
  setArrowDate(moment(arrowDate).subtract(21, 'days').format('YYYY-MM-DD'));
};

// ************************************* // Next button function
const nextDate21 = (setArrowDate, arrowDate) => {
  setArrowDate(moment(arrowDate).add(21, 'days').format('YYYY-MM-DD'));
};

// ************************************* // Next button function
const sameDate = (setArrowDate, arrowDate) => {
  setArrowDate(moment(arrowDate).add(0, 'days').format('YYYY-MM-DD'));
};

// ************************************* // Reset date function
const resetDate = (startDay, endDay, property, setProperty) => {
  // ! console.log("reset");
  // ! console.log(startDay);
  // ! console.log(endDay);
  setProperty(property);
};

// ************************************* // Date change for month drop down
const handleDateChange = (
  e,
  setMonthDropDown,
  setFlatPickrDateStart,
  setFlatPickrDateEnd,

  setDateStart,
  setDateEnd,
  setArrowDate,
  rooms,
  properties,
  setRooms,
  setProperties,

  dateStart,
  dateEnd,
  setCallUpdateDates,
) => {
  //return alert(e.target.value); // 2023-10
  //const currentYearMonth = new Date().getFullYear() + "-" + (new Date().getMonth() + 1);
  //const currentDay = make2digits(new Date().getDate());

  //const yearArray = e.target.value.split("-");
  //const selectYear = yearArray[0];
  //const selectMonth = yearArray[1];
  //const lastDayOfMonth = new Date(selectYear, selectMonth, 0).getDate();

  setMonthDropDown(e.target.value);

  console.log(e.target.value);

  setFlatPickrDateStart(null);
  setFlatPickrDateEnd(null);
  if (e.target.value !== 'select') {
    setDateStart(e.target.value + `-01`);
    setArrowDate(e.target.value + `-01`);
    setDateEnd(
      moment(e.target.value + `-01`)
        .endOf('month')
        .format('YYYY-MM-DD'),
    );

    setCallUpdateDates(true);
  } else {
    setDateStart(moment().format('YYYY-MM-DD'));
    setArrowDate(moment().format('YYYY-MM-DD'));
    setDateEnd(moment().add(7, 'days').format('YYYY-MM-DD'));
  }
};

// Options for flatpickr date range selector
const flatpickrOptions = {
  mode: 'range',
  static: false,
  position: 'left',
  monthSelectorType: 'static',
  dateFormat: "D d M 'y",
  defaultDate: [new Date().setDate(new Date().getDate()), new Date()],
  prevArrow: '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
  nextArrow: '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
  onReady: (selectedDates, dateStr, instance) => {
    instance.element.value = dateStr.replace('to', '-');
    const customClass = 'right';
    instance.calendarContainer.classList.add(`flatpickr-${customClass}`);
  },
};

// Options for flatpickr date range selector
const flatpickrOptionsBooking = {
  //mode: "range",
  static: false,
  theme: 'default',
  position: 'left',
  monthSelectorType: 'static',
  dateFormat: "D d M 'y",
  defaultDate: [new Date().setDate(new Date().getDate() - 1), new Date()],
  // dateFormat: "D d M 'y",
  // defaultDate: moment
  // 	.utc()
  // 	.startOf("day")
  // 	.subtract(2, "days")
  // 	.add(12, "hours")
  // 	.toDate(),
  //utc: true, // Ensure Flatpickr operates in UTC mode
  prevArrow: '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
  nextArrow: '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
};

// ************************************* // Check availability for activities
const checkActivityAvailability = (activitiesList, rooms, selectedActivityDate) => {
  const filteredActivityList = activitiesList.reduce((acc, activities, propId) => {
    const filteredActivities = activities.filter((activity) => {
      if (activity.type !== 'resRequestAccom') {
        return true; // skip non-accommodation activities
      }

      const roomIds = activity.Ids.resRequestRoom;
      if (!roomIds) {
        return true; // skip activities with no room Ids
      }

      // find availability for the given property and room
      const propertyRooms = rooms.find((room) => room.propUid === propId)?.stock;
      if (!propertyRooms) {
        return false; // no availability for the property, remove the activity
      }

      const roomAvailability = propertyRooms.find(
        (room) => roomIds.includes(room.stockId) && room.stockSplit === 'total' && moment(selectedActivityDate).isBetween(room.stockStart, room.stockEnd, 'day', '[]'),
      );
      if (!roomAvailability || roomAvailability.stockCount === 0) {
        return false; // no availability for the room, remove the activity
      }

      return true;
    });

    if (filteredActivities.length > 0) {
      acc[propId] = filteredActivities;
    }

    return acc;
  }, {});
  return filteredActivityList;
};

const removePropertyByUid = (uid, properties, setProperties) => {
  // properties array is stored in a state variable called 'properties'
  const updatedProperties = properties.filter((property) => property.uid !== uid);

  // Update the state with the modified properties array
  setProperties(updatedProperties);
};

export {
  flatpickrOptions,
  flatpickrOptionsBooking,
  handleDateChange,
  renderMonths,
  resetDate,
  prevDate,
  nextDate,
  getRooms,
  getRoomsAll,
  removeAll,
  removeOne,
  propertySelected,
  propertyAccom,
  checkActivityAvailability,
  removePropertyByUid,
  sameDate,
  removeAllAvailability,
  prevDate21,
  nextDate21,
};
