import React from 'react';
import ReviewCard from '../../components/ReviewCard';

function ReviewsTab({ camp, fireStoreData, tripAdvisorData }) {
  const reviews = tripAdvisorData?.reviews?.data || [];

  return (
    <div className="mt-8 font-['Open Sans']">
      <h3 className="text-4xl font-roca text-nomadBlue-800 mb-6">Recent reviews</h3>
      {reviews.length > 0 ? (
        <div className="grid gap-4">
          {reviews.map((review) => (
            <ReviewCard
              key={review.id}
              title={review.title}
              text={review.text}
              name={review.user?.username}
              date={review.published_date}
              rating={review.rating}
            />
          ))}
        </div>
      ) : (
        <p>No reviews available at the moment.</p>
      )}
    </div>
  );
}

export default ReviewsTab;