import airlinesReducer from '../features/airlines/airlinesSlice';
import airportsReducer from '../features/airports/airportsSlice';
import authReducer from '../features/auth/authSlice';
//import usersReducer from "../features/users/usersSlice";
import companiesReducer from '../features/companies/companiesSlice';
import { configureStore } from '@reduxjs/toolkit';
import countriesReducer from '../features/countries/countriesSlice';
//import flightRoutesReducer from "../features/flightRoutes/flightRoutesSlice";
import locationsReducer from '../features/locations/locationsSlice';
import propertiesReducer from '../features/properties/propertiesSlice';
import suppliersReducer from '../features/suppliers/suppliersSlice';
import templateSearchOptionsReducer from '../features/templateSearchOptions/templateSearchOptionsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    //users: usersReducer,
    properties: propertiesReducer,
    locations: locationsReducer,
    airports: airportsReducer,
    airlines: airlinesReducer,
    suppliers: suppliersReducer,
    countries: countriesReducer,
    //flightRoutes: flightRoutesReducer,
    templateSearchOptions: templateSearchOptionsReducer,
    companies: companiesReducer,
  },
});
// import airportsReducer from "../features/airports/airportsSlice";
// import authReducer from "../features/auth/authSlice";
// import { configureStore } from "@reduxjs/toolkit";
// import locationsReducer from "../features/locations/locationsSlice";
// import propertiesReducer from "../features/properties/propertiesSlice";

// export const store = configureStore({
//   reducer: {
//     auth: authReducer,
//     properties: propertiesReducer,
//     locations: locationsReducer,
//     airports: airportsReducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: ["properties/getProperties/fulfilled"],
//         ignoredPaths: ["properties.allProperties.result"],
//       },
//     }),
// });
