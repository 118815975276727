import React from 'react';
import { IoStar, IoStarHalf, IoStarOutline } from "react-icons/io5";

const RatingsSection = ({ rating, subratings, numReviews }) => {
  const renderRatingClassification = () => {
    const ratingValue = parseFloat(rating);
    if (ratingValue >= 4.5) return 'Exceptional';
    if (ratingValue >= 4) return 'Excellent';
    if (ratingValue >= 3.5) return 'Very Good';
    if (ratingValue >= 3) return 'Good';
    if (ratingValue >= 2) return 'Average';
    if (ratingValue >= 1) return 'Poor';
    return 'Very Poor';
  };

  const renderStars = (value, size = 20) => {
    return Array(5).fill().map((_, i) => {
      const isFullStar = i < Math.floor(value);
      const isHalfStar = !isFullStar && value % 1 !== 0 && i === Math.floor(value);
      return (
        isFullStar ? <IoStar key={i} className="text-nomadAccentAmber" size={size} /> :
        isHalfStar ? <IoStarHalf key={i} className="text-nomadAccentAmber" size={size} /> :
        <IoStarOutline key={i} className="text-nomadAccentAmber" size={size} />
      );
    });
  };

  const renderSubratings = () => {
    return Object.entries(subratings).map(([key, subrating]) => (
      <div key={key} className="flex items-center justify-between mt-2">
        <span className="text-sm text-gray-600 w-1/3">{subrating.localized_name}</span>
        <div className="flex items-center w-2/3">
          <div className="flex mr-2">
            {renderStars(parseFloat(subrating.value), 15)}
          </div>
          <span className="text-sm text-gray-500">{parseFloat(subrating.value).toFixed(1)}</span>
        </div>
      </div>
    ));
  };

  return (
    <div className="bg-white rounded-lg border border-[#f0ece7] p-6 font-['Open Sans']">
      <div className="flex items-center justify-between mb-4">
        <div>
          <div className="flex items-baseline">
            <span className="text-3xl font-bold text-nomadAccentEmerald mr-2">{parseFloat(rating).toFixed(1)}</span>
            <span className="text-lg font-semibold text-gray-700">{renderRatingClassification()}</span>
          </div>
          <div className="flex items-center mt-1">
            {renderStars(parseFloat(rating))}
          </div>
        </div>
        <div className="text-right">
          <span className="text-sm font-semibold text-gray-600">Based on</span>
          <p className="text-lg font-bold text-gray-800">
          {parseInt(numReviews, 10).toLocaleString()} reviews
        </p>
        </div>
      </div>
      <div className="border-t border-gray-200 pt-4">
        <h4 className="text-sm font-semibold text-gray-600 mb-2">Rating Details</h4>
        {renderSubratings()}
      </div>
    </div>
  );
};

export default RatingsSection;