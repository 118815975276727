import { getPropObj } from './generalFunctions';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import React, { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { RiCloseCircleLine } from 'react-icons/ri';
import Select from 'react-select';
import ButtonPrimarySmall from '../components/Buttons/ButtonPrimarySmall';

const handleAdultChange = (roomIndex, delta, setRoomPax) => {
  setRoomPax((currentRooms) =>
    currentRooms.map((room, index) => {
      if (index === roomIndex) {
        const newCount = Math.max(0, room.adultAges.length + delta);
        const adultAges = room.adultAges.slice(0, newCount);
        while (adultAges.length < newCount) {
          adultAges.push({ age: 99, uuid: uuidv4() }); // Default age 99 for new adults
        }
        return { ...room, adults: adultAges.length, adultAges };
      }
      return room;
    }),
  );
};

const handleChildChange = (roomIndex, delta, setRoomPax) => {
  setRoomPax((currentRooms) =>
    currentRooms.map((room, index) => {
      if (index === roomIndex) {
        const newCount = Math.max(0, room.childAges.length + delta);
        const childAges = room.childAges.slice(0, newCount);
        while (childAges.length < newCount) {
          childAges.push({ age: 0, uuid: uuidv4() }); // Default age 0
        }
        return { ...room, children: childAges.length, childAges };
      }
      return room;
    }),
  );
};

const handleChildAgeChange = (roomIndex, childIndex, age, setRoomPax) => {
  setRoomPax((currentRooms) =>
    currentRooms.map((room, index) => {
      if (index === roomIndex) {
        // Clone the childAges array
        const newChildAges = [...room.childAges];

        // Update the age of the specific child, preserving the UUID
        if (newChildAges[childIndex]) {
          newChildAges[childIndex] = { ...newChildAges[childIndex], age };
        }

        // Return the updated room object
        return { ...room, childAges: newChildAges };
      }
      return room;
    }),
  );
};

const addNewRoom = (setRoomPax) => {
  setRoomPax((currentRooms) => [
    ...currentRooms,
    {
      adults: 0,
      children: 0,
      childAges: [],
      adultAges: [],
      uuid: uuidv4(), // Ensure this is imported from a library like 'uuid'
    },
  ]);
};

const duplicateRoom = (roomIndex, setRoomPax) => {
  setRoomPax((currentRooms) => {
    const roomToDuplicate = currentRooms[roomIndex];

    // Duplicate childAges and adultAges with new UUIDs
    const newChildAges = roomToDuplicate.childAges.map((child) => ({ ...child, uuid: uuidv4() }));
    const newAdultAges = roomToDuplicate.adultAges.map((adult) => ({ ...adult, uuid: uuidv4() }));

    // Create a new room with new UUIDs for the room, childAges, and adultAges
    const newRoom = {
      ...roomToDuplicate,
      uuid: uuidv4(),
      childAges: newChildAges,
      adultAges: newAdultAges,
    };

    // Insert the new room into the array
    return [...currentRooms.slice(0, roomIndex + 1), newRoom, ...currentRooms.slice(roomIndex + 1)];
  });
};

const deleteRoom = (roomIndex, setRoomPax) => {
  setRoomPax((currentRooms) => {
    // Check if there's more than one room
    if (currentRooms.length > 1) {
      return currentRooms.filter((_, index) => index !== roomIndex);
    }
    return currentRooms; // Do nothing if it's the last room
  });
};

const handleChildAgeChangeRoom = (itemID, childIndex, selectedChild, setSelectedAges) => {
  setSelectedAges((prev) => {
    const newAges = [...prev];
    newAges[childIndex] = selectedChild; // Update the object at the specific index
    return newAges;
  });
};

const handleAdultAgeChangeRoom = (itemID, adultIndex, selectedAdult, setSelectedAgesAdults) => {
  console.log('handleAdultAgeChangeRoom'); // Debugging line
  console.log('Selected Adult:', selectedAdult); // Debugging line
  console.log('Adult Index:', adultIndex); // Debugging line
  console.log('Item ID:', itemID); // Debugging line
  setSelectedAgesAdults((prev) => {
    const newAges = [...prev];
    newAges[adultIndex] = selectedAdult; // Update the object at the specific index
    return newAges;
  });
};

const removeLastAge = (setSelectedAges, selectedAges) => {
  // Remove the last element from selectedAges
  setSelectedAges(selectedAges.slice(0, -1));
};

function checkBookingsForMinusOne(bookings) {
  for (let booking of bookings) {
    // Check in roomPaxMerged.childAges
    if (booking.roomPaxMerged && booking.roomPaxMerged.childAges.includes(-1)) {
      return false;
    }

    // Check in each room's selectedAges
    for (let room of booking.rooms) {
      if (room.selectedAges && room.selectedAges.includes(-1)) {
        return false;
      }
    }
  }
  return true;
}

const handleRemoveChild = (roomIndex, childUuid, setRoomPax) => {
  setRoomPax((currentRooms) =>
    currentRooms.map((room, index) => {
      if (index === roomIndex) {
        const filteredChildAges = room.childAges.filter((child) => child.uuid !== childUuid);
        return { ...room, children: filteredChildAges.length, childAges: filteredChildAges };
      }
      return room;
    }),
  );
};

const aggregateGuests = (roomPax, setGuests) => {
  const aggregatedGuests = roomPax.reduce(
    (acc, room) => {
      acc.adults += room.adults;
      acc.children += room.children;
      acc.childAges = [...acc.childAges, ...room.childAges];
      acc.adultAges = [...acc.adultAges, ...room.adultAges];
      return acc;
    },
    { adults: 0, children: 0, childAges: [], adultAges: [] },
  );
  setGuests(aggregatedGuests);
};

function incrementAdults(guests, setGuests) {
  setGuests((prevGuests) => ({
    ...prevGuests,
    adults: prevGuests.adults + 1,
    adultAges: [...prevGuests.adultAges, { age: 99, uuid: uuidv4() }],
  }));
}

function decrementAdults(guests, setGuests, roomPax) {
  if (guests.adults > 1) {
    const isUuidInRoomPax = roomPax.some((room) => room.adultAges.some((adultAge) => adultAge.uuid === guests.adultAges[guests.adultAges.length - 1].uuid));

    if (!isUuidInRoomPax) {
      setGuests((prevGuests) => ({
        ...prevGuests,
        adults: prevGuests.adults - 1,
        adultAges: prevGuests.adultAges.slice(0, -1),
      }));
    } else {
      // Handle case where UUID exists in roomPax
      // e.g., display an error message
      toast.error('This guest cannot be removed');
    }
  } else {
    // Handle case where there's only one adult
    // e.g., display an error message
    toast.error('There must be at least one adult at a property');
  }
}

function incrementChildren(guests, setGuests) {
  setGuests((prevGuests) => ({
    ...prevGuests,
    children: prevGuests.children + 1,
    childAges: [...prevGuests.childAges, { age: 0, uuid: uuidv4() }], // Assuming age 0 for a new child
  }));
}

function decrementChildren(guests, setGuests, roomPax) {
  if (guests.children > 0) {
    const lastChildUuid = guests.childAges[guests.childAges.length - 1]?.uuid;
    const isUuidInRoomPax = roomPax.some((room) => room.childAges.some((childAge) => childAge.uuid === lastChildUuid));

    if (!isUuidInRoomPax || !lastChildUuid) {
      setGuests((prevGuests) => ({
        ...prevGuests,
        children: prevGuests.children - 1,
        childAges: prevGuests.childAges.slice(0, -1),
      }));
    } else {
      // Handle case where UUID exists in roomPax
      // e.g., display an error message
      toast.error('This guest cannot be removed');
    }
  } else {
    // Handle case where there are no children to remove
    // e.g., display an error message
  }
}

function handleRemoveChildUuid(uuidToRemove, setGuests, bookings) {
  // Check if the UUID is used in any bookings
  const isUuidUsedInBookings = bookings.some((booking) =>
    booking.rooms.some((room) => room.selectedAgesAdults?.some((adult) => adult.uuid === uuidToRemove) || room.selectedAges?.some((child) => child.uuid === uuidToRemove)),
  );

  if (isUuidUsedInBookings) {
    toast.error('This child cannot be removed as it is being used in a property');
    return;
  }

  // Proceed with removal if UUID is not used in bookings
  setGuests((prevGuests) => ({
    ...prevGuests,
    children: prevGuests.children - 1,
    childAges: prevGuests.childAges.filter((child) => child.uuid !== uuidToRemove),
  }));
}

function handleRemoveAdultUuid(uuidToRemove, setGuests, bookings) {
  // Check if the UUID is used in any bookings
  console.log('UUID to remove:', uuidToRemove); // Debugging line

  const isUuidUsedInBookings = bookings.some((booking) =>
    booking.rooms.some((room) => room.selectedAgesAdults?.some((adult) => adult.uuid === uuidToRemove) || room.selectedAges?.some((child) => child.uuid === uuidToRemove)),
  );

  if (isUuidUsedInBookings) {
    toast.error('This adult cannot be removed as it is being used in a property');
    return;
  }

  // Proceed with removal if UUID is not used in bookings
  setGuests((prevGuests) => ({
    ...prevGuests,
    adults: prevGuests.adults - 1,
    adultAges: prevGuests.adultAges.filter((adult) => adult.uuid !== uuidToRemove),
  }));
}

const isUuidUsedInBookings = (uuid, bookings) => {
  return bookings.some((booking) =>
    booking.rooms.some((room) => room.roomType !== 'Vehicle' && (room.selectedAgesAdults.some((adult) => adult.uuid === uuid) || room.selectedAges.some((child) => child.uuid === uuid))),
  );
};

function removeSelectedAgesUuid(bookings, itemId, roomUuid, selectedUuid, setBookings, selectedAges, setSelectedAges) {
  console.log('selectedUuid:', selectedUuid); // Debugging line
  // Update bookings
  const updatedBookings = bookings.map((booking) => {
    // Check if the booking matches the itemId
    if (booking.id === itemId) {
      return {
        ...booking,
        rooms: booking.rooms.map((room) => {
          // Check if the room matches the roomUuid
          if (room.uuid === roomUuid) {
            const isChildUuidInSelectedAges = room.selectedAges.some((child) => child.uuid === selectedUuid);
            return {
              ...room,
              selectedAges: room.selectedAges.filter((child) => child.uuid !== selectedUuid),
              //ch: isChildUuidInSelectedAges || selectedUuid === undefined ? room.ch - 1 : room.ch,
              ch: room.ch - 1,
            };
          }
          return room;
        }),
      };
    }
    return booking;
  });

  // Update selectedAges
  const updatedSelectedAges = selectedAges.filter((age) => age.uuid !== selectedUuid);

  // Set updated states
  setBookings(updatedBookings);
  setSelectedAges(updatedSelectedAges);
}
function removeSelectedAgesAdultUuid(bookings, itemId, roomUuid, selectedUuid, setBookings, selectedAgesAdults, setSelectedAgesAdults) {
  // Update bookings
  const updatedBookings = bookings.map((booking) => {
    // Check if the booking matches the itemId
    if (booking.id === itemId) {
      return {
        ...booking,
        rooms: booking.rooms.map((room) => {
          // Check if the room matches the roomUuid
          if (room.uuid === roomUuid) {
            const isAdultUuidInSelectedAges = room.selectedAgesAdults.some((adult) => adult.uuid === selectedUuid);
            console.log('Is UUID in Selected Ages:', isAdultUuidInSelectedAges); // Debugging line
            console.log('Is UUID in Selected selectedUuid:', selectedUuid); // Debugging line

            return {
              ...room,
              selectedAgesAdults: room.selectedAgesAdults.filter((adult) => adult.uuid !== selectedUuid),
              //ad: isAdultUuidInSelectedAges || selectedUuid === undefined ? room.ad - 1 : room.ad,
              ad: room.ad - 1,
            };
          }
          return room;
        }),
      };
    }
    return booking;
  });

  // Update selectedAges
  const updatedSelectedAgesAdults = selectedAgesAdults.filter((age) => age.uuid !== selectedUuid);

  // Set updated states
  setBookings(updatedBookings);
  setSelectedAgesAdults(updatedSelectedAgesAdults);
}

function updateGuestsMergedInBookings(bookings, guests, properties, setBookings) {
  const updatedBookings = bookings.map((booking) => {
    const maxChildAge = properties[getPropObj(booking.propUid, properties)].maxChildAge;

    const guestsMerged = {
      adults: guests.adults,
      children: 0,
      childAges: [],
      adultAges: [...guests.adultAges],
    };

    guests.childAges.forEach((child) => {
      if (child.age > maxChildAge) {
        guestsMerged.adults += 1;
        guestsMerged.adultAges.push(child);
      } else {
        guestsMerged.children += 1;
        guestsMerged.childAges.push(child);
      }
    });

    guestsMerged.childAges.sort((a, b) => b.age - a.age);
    guestsMerged.adultAges.sort((a, b) => b.age - a.age);

    return {
      ...booking,
      guestsMerged: guestsMerged,
    };
  });

  setBookings(updatedBookings);
}

function calculateUsedGuestsCount(item) {
  let totalAdults = 0;
  let totalChildren = 0;

  item.rooms.forEach((room) => {
    totalAdults += room.ad || 0; // Add adults count
    totalChildren += (room.ch || 0) + (room.chi || 0) + (room.inf || 0); // Add children and infants count
  });

  const usedGuestsCount = {
    adults: totalAdults,
    children: totalChildren,
    total: totalAdults + totalChildren,
  };

  return usedGuestsCount;
}

const checkGuestUsage = (item, guests) => {
  // Step 1: Merge selectedAges and selectedAgesAdults into one array
  const guestsMerged = [];
  // item.rooms.forEach((room) => {
  //   guestsMerged.push(...room.selectedAges, ...room.selectedAgesAdults);
  // });

  item.rooms.forEach((room) => {
    if (room.selectedAges) {
      guestsMerged.push(...room.selectedAges);
    }
    if (room.selectedAgesAdults) {
      guestsMerged.push(...room.selectedAgesAdults);
    }
  });

  // Step 2: Flatten and merge childAges and adultAges from guests object
  const allGuests = [...guests.childAges, ...guests.adultAges];

  // Step 3: Check each guest in guestsMerged exists in allGuests
  //const usedGuests = guestsMerged.filter((guestMerged) => allGuests.some((guest) => guest.uuid === guestMerged.uuid));

  // Step 4: Return the appropriate message
  const totalGuests = allGuests.length;
  //const usedGuestsCount = usedGuests.length + (item.ad || 0) + (item.ch || 0);

  console.log('usedGuestsCount before');
  const usedGuestsCount = calculateUsedGuestsCount(item).total + (item.ad || 0) + (item.ch || 0);

  console.log('Used Guests Count:', usedGuestsCount); // Debugging line

  if (usedGuestsCount === totalGuests) {
    return <span className={`text-sm ml-5 text-green-600`}>You've added all guests</span>;
  } else if (usedGuestsCount > totalGuests) {
    return <span className={`text-sm ml-5 text-red-600`}>{usedGuestsCount} guests added - Attention needed: guest count mismatch</span>;
  } else {
    const remainingGuests = totalGuests - usedGuestsCount;
    return (
      <span className={`text-sm ml-5 text-orange-600`}>
        Add {remainingGuests} more {remainingGuests === 1 ? 'person' : 'people'}
      </span>
    );
  }
};

function removeLastAdultWithoutMutating(originalRoom) {
  // Check if the originalRoom object and the selectedAgesAdults array are defined
  if (originalRoom && Array.isArray(originalRoom.selectedAgesAdults) && originalRoom.selectedAgesAdults.length > 0) {
    // Create a new array without the last element
    const newSelectedAgesAdults = originalRoom.selectedAgesAdults.slice(0, -1);

    // Return a new room object with the updated array
    return {
      ...originalRoom,
      selectedAgesAdults: newSelectedAgesAdults,
    };
  }
  return originalRoom;
}

// const GuestPopover = ({ guests, setGuests, bookings }) => {
//   return (
//     <Popover className="relative">
//       <Popover.Button className="border border-white focus:outline-none focus:border-white">
//         <div
//           id="totalPax"
//           className="rounded-sm border border-white flex items-center pl-0 focus:outline-none focus:border-white"
//         >
//           <span className="ml-auto text-gray-800 text-base font-semibold underline brand-text-color-v2-hover">
//             {guests.adults} adult{guests.adults !== 1 && 's'}{' '}
//             {guests.children} kid{guests.children !== 1 && 's'}
//           </span>
//         </div>
//       </Popover.Button>

//       {/* Popover content */}
//       <Transition
//         as={Fragment}
//         enter="transition ease-out duration-200"
//         enterFrom="opacity-0 translate-y-1"
//         enterTo="opacity-100 translate-y-0"
//         leave="transition ease-in duration-150"
//         leaveFrom="opacity-100 translate-y-0"
//         leaveTo="opacity-0 translate-y-1"
//       >
//         <Popover.Panel
//           className="absolute z-[9999] left-1/4 transform -translate-x-1/4 mt-2 w-screen max-w-max"
//           style={{ zIndex: 9999 }}
//         >
//           <GuestPopoverContent
//             guests={guests}
//             setGuests={setGuests}
//             bookings={bookings}
//             toast={toast}
//           />
//         </Popover.Panel>
//       </Transition>
//     </Popover>
//   );
// };

// const GuestPopoverContent = ({ guests, setGuests, bookings }) => {
//   // State to control the visibility of the "isGuide" checkboxes
//   const [showIsGuide, setShowIsGuide] = useState(false);

//   return (
//     <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
//       <div className="m-5">
//         {/* Header with "Guests" and the new checkbox */}
//         <div className="flex justify-between items-center">
//           <p className="text-md font-semibold">Guests</p>
//           <div className="flex items-center">
//             <span className="ml-2 text-neutral-500 text-sm font-normal">
//               Show Guides
//             </span>
//             <input
//               type="checkbox"
//               name="showIsGuide"
//               className="ml-2 w-5 h-5 checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
//               checked={showIsGuide}
//               onChange={(e) => setShowIsGuide(e.target.checked)}
//             />
//           </div>
//         </div>

//         {/* Main Content */}
//         <div>
//           {/* Display Adults and Children Count with Counter Functionality */}
//           <div className="row-2 grid grid-cols-12 m-5">
//             {/* Total Label */}
//             <div className="col-span-2 px-2 flex items-center">Total:</div>
//             {/* Adult Counter */}
//             <div className="col-span-3 px-2">
//               <div className="text-xs mb-1">Adults</div>
//               <div className="grid grid-rows-1 grid-flow-col bg-white border border-slate-200 rounded-lg h-9 py-0.5">
//                 <div className="px-2 text-xs font-normal text-center align-middle leading-6">
//                   {guests.adults}
//                 </div>
//                 <button
//                   className="border-l border-slate-200 px-2"
//                   onClick={() => {
//                     incrementAdults(guests, setGuests);
//                   }}
//                 >
//                   +
//                 </button>
//               </div>
//             </div>

//             {/* Child Counter */}
//             <div className="col-span-3 px-2">
//               <div className="text-xs mb-1">Children</div>
//               <div className="grid grid-rows-1 grid-flow-col bg-white border border-slate-200 rounded-lg h-9 py-0.5">
//                 <div className="px-2 text-xs font-normal text-center align-middle leading-6">
//                   {guests.children}
//                 </div>
//                 <button
//                   className="border-l border-slate-200 px-2"
//                   onClick={() => {
//                     incrementChildren(guests, setGuests);
//                   }}
//                 >
//                   +
//                 </button>
//               </div>
//             </div>
//           </div>

//           {/* Adult Age Selectors */}
//           <div className="row-2 grid grid-cols-12 ml-5 mb-5">
//             {guests.adultAges.map((adult, adultIndex) => (
//               <React.Fragment key={adult.uuid}>
//                 {adultIndex === 0 ? (
//                   <div className="col-span-2 px-2 mb-0">
//                     <div className="ml-5 mt text-md">Adults:</div>
//                   </div>
//                 ) : (
//                   adultIndex % 5 === 0 && (
//                     <div className="col-span-2 px-2 mb-0"></div>
//                   )
//                 )}
//                 <div className="col-span-2 px-2 mb-0">
//                   <div className="flex items-center">
//                     <div className="text-xs mb-1">
//                       Adult {adultIndex + 1}
//                     </div>

//                     {!isUuidUsedInBookings(adult.uuid, bookings) && (
//                       <RiCloseCircleLine
//                         size={16}
//                         className="ml-1 mb-1 cursor-pointer text-red-600"
//                         onClick={() => {
//                           if (guests.adultAges.length === 1) {
//                             toast.error('You must have at least 1 adult');
//                           } else {
//                             handleRemoveAdultUuid(
//                               adult.uuid,
//                               setGuests,
//                               bookings
//                             );
//                           }
//                         }}
//                       />
//                     )}
//                   </div>

//                   {/* Conditionally render the isGuide checkbox */}
//                   {showIsGuide && (
//                     <div className="flex items-center text-left text-gray-800 text-sm font-normal mt-1">
//                       <input
//                         type="checkbox"
//                         name="isGuide"
//                         className="w-4 h-4 checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
//                         checked={adult.isGuide}
//                         onChange={(e) => {
//                           let isChecked = e.target.checked;
//                           setGuests((prevGuests) => {
//                             const updatedAdultAges = prevGuests.adultAges.map(
//                               (a) =>
//                                 a.uuid === adult.uuid
//                                   ? { ...a, isGuide: isChecked }
//                                   : a
//                             );
//                             return {
//                               ...prevGuests,
//                               adultAges: updatedAdultAges,
//                             };
//                           });
//                         }}
//                       />
//                       <span className="ml-2 text-neutral-500 text-xs font-normal">
//                         Guide
//                       </span>
//                     </div>
//                   )}
//                 </div>
//               </React.Fragment>
//             ))}
//           </div>

//           {/* Child Age Selectors */}
//           <div className="row-2 grid grid-cols-12 ml-5">
//             {guests.childAges.map((child, childIndex) => (
//               <React.Fragment key={child.uuid}>
//                 {childIndex === 0 ? (
//                   <div className="col-span-2 px-2 mb-5">
//                     <div className="m-5 mt text-md">Children:</div>
//                   </div>
//                 ) : (
//                   childIndex % 5 === 0 && (
//                     <div className="col-span-2 px-2 mb-5"></div>
//                   )
//                 )}
//                 <div className="col-span-2 px-2 mb-5">
//                   <div className="flex items-center">
//                     <div className="text-xs mb-1">
//                       Child {childIndex + 1} age
//                     </div>

//                     {!isUuidUsedInBookings(child.uuid, bookings) && (
//                       <RiCloseCircleLine
//                         size={16}
//                         className="ml-1 text-red-600 cursor-pointer"
//                         onClick={() =>
//                           handleRemoveChildUuid(
//                             child.uuid,
//                             setGuests,
//                             bookings
//                           )
//                         }
//                       />
//                     )}
//                   </div>
//                   <Select
//                     className="border-r border-l border-slate-200 w-[84px]"
//                     options={[...Array(21).keys()].map((num) => ({
//                       value: num,
//                       label: num.toString(),
//                     }))}
//                     value={
//                       child.age === -1
//                         ? { label: '-', value: -1 }
//                         : {
//                             label: child.age.toString(),
//                             value: child.age,
//                           }
//                     }
//                     onChange={(selectedOption) => {
//                       setGuests((prevGuests) => {
//                         const updatedChildAges = prevGuests.childAges.map(
//                           (c) =>
//                             c.uuid === child.uuid
//                               ? {
//                                   ...c,
//                                   age: selectedOption.value,
//                                 }
//                               : c
//                         );
//                         return {
//                           ...prevGuests,
//                           childAges: updatedChildAges,
//                         };
//                       });
//                     }}
//                     menuPortalTarget={document.body}
//                     styles={{
//                       menuPortal: (base) => ({
//                         ...base,
//                         zIndex: 9999,
//                       }),
//                     }}
//                     isDisabled={isUuidUsedInBookings(child.uuid, bookings)}
//                   />
//                 </div>
//               </React.Fragment>
//             ))}
//           </div>

//           {/* Done Button */}
//           <div className="row-2 grid grid-cols-12 ml-5">
//             <div className="col-span-10 px-2 mt-5">
//               <span className="font-normal italic"></span>
//             </div>
//             <div className="col-span-2 px-2 flex justify-end items-center">
//               {/* Use Popover.Button to close the Popover */}
//               <Popover.Button as="div">
//                 <ButtonPrimarySmall
//                   color={'dark'}
//                   text={'Done'}
//                   className={'float-right mt-4'}
//                 />
//               </Popover.Button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

function isGuideCheck(guests, uuid) {
  console.log('isGuideCheck')
  // Check adultAges
  for (const adult of guests.adultAges) {
    if (adult.uuid === uuid) {
      console.log('adult.isGuideCheck', adult.isGuide);
      return adult.isGuide === true;
    }
  }

  // Check childAges
  for (const child of guests.childAges) {
    if (child.uuid === uuid) {
      console.log('child.isGuideCheck', child.isGuide);
      return child.isGuide === true;
    }
  }
  console.log('no isGuideCheck');
  // If UUID is not found, return false
  return false;
}

function countGuides(guests) {
  const adultGuides = guests.adultAges.filter(adult => adult.isGuide === true).length;
  const childGuides = guests.childAges.filter(child => child.isGuide === true).length;
  
  return adultGuides + childGuides;
}

export {
  handleAdultChange,
  handleChildChange,
  handleChildAgeChange,
  addNewRoom,
  duplicateRoom,
  deleteRoom,
  handleChildAgeChangeRoom,
  removeLastAge,
  checkBookingsForMinusOne,
  handleRemoveChild,
  aggregateGuests,
  incrementAdults,
  decrementAdults,
  incrementChildren,
  decrementChildren,
  handleRemoveChildUuid,
  removeSelectedAgesUuid,
  updateGuestsMergedInBookings,
  handleRemoveAdultUuid,
  handleAdultAgeChangeRoom,
  removeSelectedAgesAdultUuid,
  isUuidUsedInBookings,
  checkGuestUsage,
  removeLastAdultWithoutMutating,
  isGuideCheck,
  countGuides,
};
