import React, { useCallback, useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  allocateNights,
  appendLocationsToRoute,
  fetchItinerariesFromDatabase,
  updateRouteItemDates,
  sortItinerariesRecommended,
  fetchPricesForItineraries,
  getMinimumNights,
  deduplicateItineraries,
} from '../functions/itinerarySearchFunctions';
import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon, CalendarIcon, MapPinIcon } from '@heroicons/react/24/outline';
import ButtonOutlineSmall from './Buttons/ButtonOutlineSmall';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { motion, AnimatePresence } from 'framer-motion';
import ButtonPrimarySmall from './Buttons/ButtonPrimarySmall';
import InfoBox from './InfoBox';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from './ui/Dropdown';
import ItineraryCard from './ItineraryCard';
import { db } from '../db/firebase.config';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { isMonday, isThursday } from 'date-fns';
import { useNavigate } from 'react-router-dom';

function ItinerarySearch({
  searchRequest,
  bookings,
  setBookings,
  setProperties,
  dateStart,
  dateEnd,
  agentObject,
  agentRates,
  rooms,
  setRooms,
  packageTab,
  guests,
  setLoadingData,
  setShowTemplates,
  setNextTopInfo,
  setStartSearchButton,
  setFetchCustom,
}) {

  // Inside the component, add:
const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  console.log('Guests', JSON.stringify(guests, null, 2));
  console.log('Search Request:', searchRequest);
  const [itineraries, setItineraries] = useState([]);
  const [loading, setLoading] = useState(false);
  // Logic for rendering the itineraries on UI
  const [displayedItineraries, setDisplayedItineraries] = useState([]);
  const [displayCount, setDisplayCount] = useState(10);
  const [sortOption, setSortOption] = useState('Recommended');
  const [loadingStep, setLoadingStep] = useState(0);
  const loadingMessages = [
    'Searching our database for safari itineraries matching your preferences',
    'Checking real-time availability across all potential properties',
    'Selecting the optimal route taking into account proprty availabiility',
    'Fetching current rates, special offers and exclusive deals',
    "Almost there! Select your favourite itinerary, then customise with transfers and activities",
  ];

  // Adjusting the requested safari nights when finding itineraries
  const [adjustedNights, setAdjustedNights] = useState(null);
  const [nightsAdjusted, setNightsAdjusted] = useState(false);

  // Keeping track if requested properties have been included in the itineraries
  const [unavailableProperties, setUnavailableProperties] = useState([]);

  useEffect(() => {
    let timer;
    if (loading && loadingStep < loadingMessages.length - 1) {
      timer = setTimeout(() => {
        setLoadingStep((prevStep) => prevStep + 1);
      }, 2000); // Change message every 2 seconds (8 seconds total for 4 changes)
    }
    return () => clearTimeout(timer);
  }, [loading, loadingStep, loadingMessages.length]);

  // Sort function for itineraries.
  const sortItineraries = (itineraries, option) => {
    switch (option) {
      case 'Lowest Price':
        return [...itineraries].sort((a, b) => a.price - b.price);
      case 'Highest Price':
        return [...itineraries].sort((a, b) => b.price - a.price);
      default:
        return itineraries; // 'Recommended' is the default sort
    }
  };

  useEffect(() => {
    const sortedItineraries = sortItineraries(itineraries, sortOption);
    setDisplayedItineraries(sortedItineraries.slice(0, displayCount));
  }, [itineraries, sortOption, displayCount]);

  const getPriceCategories = (searchRequest) => {
    const CATEGORIES = {
      LOW: '1dd4e1f0-f1aa-4c0b-ab7b-1a4a1426c4bc',
      MEDIUM: '29943f8e-2279-44b5-aef5-6b2e4c194045',
      HIGH: 'ba371388-7f4f-40bc-bc71-d9bbf59bac37',
      NOMAD: 'nomad', // Special category for Nomad properties
    };

    if (searchRequest.priceCategoryId === 'all') {
      return [CATEGORIES.MEDIUM, CATEGORIES.LOW, CATEGORIES.HIGH, CATEGORIES.NOMAD];
    }

    switch (searchRequest.priceCategoryId) {
      case CATEGORIES.LOW:
        return [CATEGORIES.LOW, CATEGORIES.MEDIUM, CATEGORIES.NOMAD];
      case CATEGORIES.MEDIUM:
        return [CATEGORIES.MEDIUM, CATEGORIES.LOW, CATEGORIES.HIGH, CATEGORIES.NOMAD];
      case CATEGORIES.HIGH:
        return [CATEGORIES.HIGH, CATEGORIES.MEDIUM, CATEGORIES.NOMAD];
      default:
        return [CATEGORIES.MEDIUM, CATEGORIES.LOW, CATEGORIES.HIGH, CATEGORIES.NOMAD];
    }
  };

  const RESTRICTED_PROPERTIES = {
    'cd3dc792-9604-4363-8246-7a55421ff961': true,
    'e867a3b5-1b63-4913-a052-f69d08523647': true,
  };

  // Helper function to check if date is Monday or Thursday
  const isValidArrivalDay = (date) => {
    const dateObj = new Date(date);
    return isMonday(dateObj) || isThursday(dateObj);
  };

  // Function that gets itineraries for each pricing category.
  const fetchItinerariesForCategory = useCallback(
    async (priceCategoryId, parentParkIds, preferredProperties, beachLocation, adjustedNights, fetchedItineraries) => {
      setLoading(true);
      console.log('Fetching itineraries for category:', priceCategoryId);
      // **** STEP 2 - FOR EACH ITINERARY, FIND THE PERFECT ROUTE OR DISREGARD IT **** //
      // Update each itinerary by calculating start and end dates for each route item
      const updatedItineraries = await Promise.all(
        fetchedItineraries.map(async (itinerary, index) => {
          console.log(`BEGINNING MATCHING PROCESS FOR ITINERARY ${index + 1} out of ${fetchedItineraries.length}`);
          // Sort the routes array based on priority in ascending order
          const sortedRoutes = itinerary.routes.sort((a, b) => a.priority - b.priority);

          let bestRoute = null;
          let bestRouteScore = {
            priceCategoryMatches: 0,
            requestedPropertiesCount: 0,
            routePriority: Infinity,
            nomadMatches: 0,
          };

          // Iterate over the sorted routes until a valid route is found
          for (const route of sortedRoutes) {
            console.log(`Trying route with priority: ${route.priority}`);

            // Append city and beach based on the search request type
            const updatedRoute = appendLocationsToRoute(route, searchRequest, beachLocation);

            // Resort the route after adding the additional parks.
            const tempRoute = updatedRoute.parks.sort((a, b) => a.order - b.order);

            // Allocate the total nights to each park based on user search request
            const updatedTempRoute = allocateNights(tempRoute, adjustedNights);

            // Add the start and end date to each item/park in the route.
            let currentDate = new Date(searchRequest.dateStart);
            const updatedRouteWithDates = updatedTempRoute.map((routeItem) => {
              const { updatedRouteItem, newCurrentDate } = updateRouteItemDates(routeItem, currentDate);
              currentDate = newCurrentDate;
              return updatedRouteItem;
            });

            console.log('Updated Route with Dates:', updatedRouteWithDates);
            // Fetch matching properties for each route item in the temporary route
            const tempRouteWithProperties = await Promise.all(
              updatedRouteWithDates.map(async (routeItem) => {
                // Define the price category order based on the requested category
                // Update the priceCategoryOrder logic in fetchItinerariesForCategory
                const priceCategoryOrder = (() => {
                  const PRICE_CATEGORIES = {
                    LOW: '1dd4e1f0-f1aa-4c0b-ab7b-1a4a1426c4bc',
                    MEDIUM: '29943f8e-2279-44b5-aef5-6b2e4c194045',
                    HIGH: 'ba371388-7f4f-40bc-bc71-d9bbf59bac37',
                  };

                  switch (priceCategoryId) {
                    case PRICE_CATEGORIES.LOW:
                      return [PRICE_CATEGORIES.LOW, PRICE_CATEGORIES.MEDIUM];
                    case PRICE_CATEGORIES.MEDIUM:
                      return [PRICE_CATEGORIES.MEDIUM, PRICE_CATEGORIES.LOW, PRICE_CATEGORIES.HIGH];
                    case PRICE_CATEGORIES.HIGH:
                      return [PRICE_CATEGORIES.HIGH, PRICE_CATEGORIES.MEDIUM];
                    default:
                      return [PRICE_CATEGORIES.MEDIUM, PRICE_CATEGORIES.LOW, PRICE_CATEGORIES.HIGH];
                  }
                })();

                // Get the user-selected property IDs for the current route item
                const selectedPropertyIds = searchRequest.locations.filter((location) => location.parentParkId === routeItem.id && location.type === 'property').map((location) => location.id);
                console.log('selected Properties1', selectedPropertyIds);
                // Query the "properties" collection based on multiple criteria
                let matchingProperty = null;

                // Create the base query conditions
                const baseConditions = [where('rankingPriority', '>', 0), where('platformKarani.active', '==', true)];

                // Query 1: Search for non-mobile properties
                const nonMobileQuery = query(
                  collection(db, 'properties'),
                  ...baseConditions,
                  where('park.id', '==', routeItem.id),
                  where('mobileLocation', '==', false),
                  orderBy('rankingPriority', 'asc'),
                );

                // Query 2: Search for mobile safari camps
                const mobileSafariQuery = query(collection(db, 'properties'), ...baseConditions, where('mobileLocation', '==', true), orderBy('rankingPriority', 'asc'));

                // // Query 3: Search for selected properties
                // const selectedPropertiesQuery =
                //   selectedPropertyIds.length > 0 ? query(collection(db, 'properties'), ...baseConditions, where('uid', 'in', selectedPropertyIds), orderBy('rankingPriority', 'asc')) : null;

                // Execute the queries
                const [nonMobileSnapshot, mobileSafariSnapshot] = await Promise.all([getDocs(nonMobileQuery), getDocs(mobileSafariQuery)]);

                // Process non-mobile properties
                const nonMobileProperties = nonMobileSnapshot.docs.map((doc) => doc.data());

                // Process mobile safari camps
                const mobileSafariProperties = mobileSafariSnapshot.docs
                  .map((doc) => doc.data())
                  .filter((property) => {
                    const matchingLocation = property.customLocations.find((location) => {
                      const periodStartDate = location.periodStartDate.toDate();
                      const periodEndDate = location.periodEndDate.toDate();
                      const searchStartDate = new Date(searchRequest.dateStart);

                      // Special case for NCAA camps that should show up in Serengeti
                      const isNCAAinSerengeti = location.parentParkId === '853eb152-f1e6-4103-bd5c-c5878a5c3b37' && routeItem.id === 'f496cbd4-f1cd-4230-bad2-42dfe5a5d44d';

                      return (location.parentParkId === routeItem.id || isNCAAinSerengeti) && periodStartDate <= searchStartDate && periodEndDate >= searchStartDate;
                    });

                    if (matchingLocation) {
                      property.matchingCustomLocation = matchingLocation;
                    }
                    return matchingLocation !== undefined;
                  });

                // Merge the search results and remove duplicates based on property.uid
                const mergedProperties = [...nonMobileProperties, ...mobileSafariProperties];

                const uniqueProperties = mergedProperties.filter((property, index, self) => index === self.findIndex((p) => p.uid === property.uid));

                // Filter properties based on minNights condition
                const filteredProperties = uniqueProperties.filter((property) => {
                  const meetsMinNightsCondition = property.minNights <= routeItem.nights;
                  const isActive = property.active;
                  const isAppropriateForGroupSize = property.propertyType !== 'private' || searchRequest.totalPax >= 6;
                  return meetsMinNightsCondition && isAppropriateForGroupSize && isActive;
                });

                // Sort properties based on user selection, price category, availability, and ranking priority
                // Inside the property matching logic
                filteredProperties.sort((a, b) => {
                  // Always check user-selected properties first, regardless of category
                  const aSelected = selectedPropertyIds.includes(a.uid);
                  const bSelected = selectedPropertyIds.includes(b.uid);

                  if (aSelected && !bSelected) return -1;
                  if (!aSelected && bSelected) return 1;

                  // Then check category-specific criteria
                  if (priceCategoryId === 'nomad') {
                    const aNomad = a.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67';
                    const bNomad = b.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67';

                    if (aNomad && !bNomad) return -1;
                    if (!aNomad && bNomad) return 1;
                  } else {
                    const aCategoryIndex = priceCategoryOrder.indexOf(a.priceCategory.id);
                    const bCategoryIndex = priceCategoryOrder.indexOf(b.priceCategory.id);
                    if (aCategoryIndex !== bCategoryIndex) {
                      return aCategoryIndex - bCategoryIndex;
                    }
                  }

                  // Finally, use ranking priority as a tiebreaker
                  if (a.rankingPriority !== b.rankingPriority) {
                    return a.rankingPriority - b.rankingPriority;
                  }

                  return 0;
                });

                // Check availability for each filtered property
                for (const property of filteredProperties) {
                  if (RESTRICTED_PROPERTIES[property.uid]) {
                    if (!isValidArrivalDay(routeItem.startDate)) {
                      console.log(`Property ${property.name} skipped - Invalid arrival day`);
                      continue; // Skip to next property
                    }
                  }

                  if (property.platformResRequest.active) {
                    // Query the availability subcollection for the current property and date range
                    const availabilityQuery = query(
                      collection(db, 'propertiesAvailability', property.uid, 'availability'),
                      where('date', '>=', routeItem.startDate),
                      where('date', '<', routeItem.endDate),
                      orderBy('date'),
                    );

                    // Execute the availability query and retrieve all the documents within the date range
                    const availabilitySnapshot = await getDocs(availabilityQuery);

                    //  console.log("Checking Availability for:", property.name);

                    if (!availabilitySnapshot.empty) {
                      let minAvailableRooms = Infinity;

                      // Iterate through each document and find the minimum available rooms
                      availabilitySnapshot.forEach((doc) => {
                        const availabilityDoc = doc.data();
                        minAvailableRooms = Math.min(minAvailableRooms, availabilityDoc.rooms);
                      });

                      //  console.log("Minimum Available Rooms:", minAvailableRooms);

                      // Check if the minimum available rooms is greater than or equal to the required rooms
                      if (minAvailableRooms >= searchRequest.totalRooms) {
                        matchingProperty = property;
                        routeItem.availability = minAvailableRooms;
                        break;
                      } else {
                        console.log(`Property Not Suitable: ${property.name} - Availability ${minAvailableRooms} - Not enough rooms`);
                      }
                    } else {
                      console.log(`No availability found for property: ${property.name}`);
                    }
                  } else {
                    matchingProperty = property;
                    break;
                  }
                }

                if (matchingProperty) {
                  console.log(`Property Matched: ${matchingProperty.name} - Availability ${routeItem.availability}`);
                } else {
                  console.log(`No matching property found for route item: ${routeItem.name}`);
                }

                // If no matching property is found, return null
                if (!matchingProperty) {
                  return null;
                }

                matchingProperty.rooms = matchingProperty.rooms.filter((room) => room.active);
                if (user.internalUser === false) {
                  matchingProperty.rooms = matchingProperty.rooms.filter((room) => !room.hideForExternalUsers);
                }

                // If a matching property is found, update the route item with property details
                //   console.log('matchingProperty', JSON.stringify(matchingProperty, null, 2));
                return {
                  ...routeItem,
                  propUid: matchingProperty.uid,
                  propName: matchingProperty.name,
                  minNights: matchingProperty.minNights,
                  propertyPriceCategory: matchingProperty.priceCategory,
                  airportName: matchingProperty.mobileLocation ? matchingProperty.matchingCustomLocation.airportName : matchingProperty.airport.name,
                  airportCode: matchingProperty.mobileLocation ? matchingProperty.matchingCustomLocation.airportCode : matchingProperty.airport.code,
                  airportUid: matchingProperty.mobileLocation ? matchingProperty.matchingCustomLocation.airportId : matchingProperty.airport.id,
                  heroImageUrl: matchingProperty.heroImageUrl,
                  property: matchingProperty, // RP
                };
              }),
            );

            // Check if this is a valid route (has properties for all stops)
            if (tempRouteWithProperties.every((routeItem) => routeItem !== null)) {
              const safariStops = tempRouteWithProperties.filter(
                routeItem => routeItem.safariProperty !== false
              );
            
              // Calculate scores differently based on category
              const requestedPropertiesCount = preferredProperties.length > 0 
                ? safariStops.filter(routeItem => 
                    preferredProperties.includes(routeItem.propUid)
                  ).length
                : 0;
            
              if (priceCategoryId === 'nomad') {
                // For Nomad category, count Nomad properties but prioritize route priority
                const nomadMatches = safariStops.filter(
                  routeItem => routeItem.property.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67'
                ).length;
            
                const isBetterRoute = 
                  // More requested properties
                  requestedPropertiesCount > bestRouteScore.requestedPropertiesCount ||
                  // Same requested properties, better route priority
                  (requestedPropertiesCount === bestRouteScore.requestedPropertiesCount && 
                   route.priority < bestRouteScore.routePriority);
            
                if (isBetterRoute) {
                  bestRoute = tempRouteWithProperties;
                  bestRouteScore = {
                    priceCategoryMatches: 0, // Not relevant for Nomad
                    requestedPropertiesCount,
                    routePriority: route.priority,
                    nomadMatches
                  };
                  console.log(`New best Nomad route found! Priority: ${route.priority}, Nomad matches: ${nomadMatches}`);
                }
              } else {
                // Original price category logic
                const priceCategoryMatches = safariStops.filter(
                  routeItem => routeItem.property.priceCategory.id === priceCategoryId
                ).length;
            
                const isBetterRoute = 
                  // More price category matches
                  priceCategoryMatches > bestRouteScore.priceCategoryMatches ||
                  // Same price matches but more requested properties
                  (priceCategoryMatches === bestRouteScore.priceCategoryMatches && 
                   requestedPropertiesCount > bestRouteScore.requestedPropertiesCount) ||
                  // Same matches but better priority
                  (priceCategoryMatches === bestRouteScore.priceCategoryMatches && 
                   requestedPropertiesCount === bestRouteScore.requestedPropertiesCount &&
                   route.priority < bestRouteScore.routePriority);
            
                if (isBetterRoute) {
                  bestRoute = tempRouteWithProperties;
                  bestRouteScore = {
                    priceCategoryMatches,
                    requestedPropertiesCount,
                    routePriority: route.priority,
                    nomadMatches: 0 // Not relevant for price categories
                  };
                  console.log(`New best route found! Score:`, bestRouteScore);
                }
              }
            }
          }

          // After checking all routes, return the best one found
          if (bestRoute) {
            console.log(`Final best route - Price matches: ${bestRouteScore.priceCategoryMatches}, Requested: ${bestRouteScore.requestedPropertiesCount}`);
          } else {
            console.log('No valid route found');
          }
          // Create the foundItinerary object with the necessary properties
          const foundItinerary = {
            uuid: uuidv4(),
            itineraryName: itinerary.name,
            route: bestRoute,
            shuffledRoute: bestRoute ? [...bestRoute].sort(() => Math.random() - 0.5) : null,
            dateStart: searchRequest.dateStart,
            totalPax: searchRequest.totalPax,
            totalRooms: searchRequest.totalRooms,
            price: 0,
            exactMatch:
              bestRoute !== null &&
              itinerary.parkIds.length === parentParkIds.length &&
              itinerary.parkIds.every((parkId) => bestRoute.some((routeItem) => routeItem.id === parkId && routeItem.safariProperty !== false)) &&
              preferredProperties.length > 0 &&
              preferredProperties.every((propId) => bestRoute.some((routeItem) => routeItem.propUid === propId)),
            inclusions: itinerary.inclusions,
            parkIds: itinerary.parkIds,
            type: itinerary.type,
            nomadFavourite:
              itinerary.nomadFavourite && bestRoute && bestRoute.some((routeItem) => routeItem.property && routeItem.property.supplier && routeItem.property.supplier.name === 'Nomad Tanzania'),
            priority: itinerary.priority,
            // DOES NOT WORK - HAS ERROR THAT BREAKS - CHECK CONSOLE Used for debugging and also displaying which properties weren't part of the category searched.
            // priceCategories: {
            //   requestedCategory: priceCategoryId,
            //   matches: bestRouteScore.priceCategoryMatches,
            //   total: bestRoute.filter((routeItem) => routeItem.safariProperty !== false).length,
            //   properties: bestRoute
            //     .filter((routeItem) => routeItem.safariProperty !== false)
            //     .map((routeItem) => ({
            //       name: routeItem.propName,
            //       category: routeItem.property.priceCategory.id,
            //     })),
            // },
          };

          if (bestRoute) {
            console.log(`MATCH FOUND FOR ITINERARY ${index + 1}`);
          } else {
            console.log(`MATCH NOT FOUND FOR ITINERARY ${index + 1}`);
          }
          return foundItinerary;
        }),
      );

      // Assign the updated itineraries to the finalItineraries variable
      const finalItineraries = updatedItineraries;

      // Filter out itineraries with missing properties for any route item
      const filteredItineraries = finalItineraries.filter((itinerary) => itinerary.route && itinerary.route.every((routeItem) => routeItem !== null));

      // Sort the filtered itineraries based on the exactMatch property and priority
      const sortedItineraries = filteredItineraries.sort((a, b) => {
        if (a.exactMatch && !b.exactMatch) {
          return -1; // a is an exact match, b is not, so a should come first
        } else if (!a.exactMatch && b.exactMatch) {
          return 1; // a is not an exact match, b is, so b should come first
        } else {
          // Both are either exact matches or not, sort based on priority
          return a.priority - b.priority;
        }
      });

      // Check if an exact match was found
      const exactMatchFound = sortedItineraries.some((itinerary) => itinerary.exactMatch);

      if (exactMatchFound) {
        console.log('Exact match found!');
      } else {
        console.log('No exact match found.');
      }

      console.log('Filtered and sorted Itineraries State:', JSON.stringify(sortedItineraries, null, 2));
      return sortedItineraries;
    },
    [searchRequest, user.internalUser],
  );

  // Main Function
  const fetchAllItineraries = useCallback(async () => {
    setLoading(true);
    setLoadingStep(0);
    try {
      // **** STEP 1 - FETCH RELEVANT ITINERARIES BASED ON USERS SEARCH REQUEST *** //

      const categoriesToFetch = getPriceCategories(searchRequest);
      console.log('Categories to fetch:', categoriesToFetch);

      // fetch the parent park ids and check minimum nights
      const parentParkIds = [...new Set(searchRequest.locations.filter((location) => location.category === 'safariPark').map((location) => location.parentParkId))];
      console.log('Parent Park IDs:', parentParkIds);

      const minNights = await getMinimumNights(parentParkIds);
      console.log('Minimum nights from database:', minNights);

      let adjustedNights = searchRequest.safariNights;
      console.log('Original requested safari nights:', adjustedNights);

      let nightsAdjusted = false;

      // If min nights is actually less than requested, increase adjusted nights to the minimum
      if (minNights && searchRequest.safariNights < minNights) {
        adjustedNights = minNights;
        nightsAdjusted = true;
        console.log('Nights adjusted. New adjusted nights:', adjustedNights);
      } else {
        console.log('No adjustment needed. Keeping original nights:', adjustedNights);
      }

      setAdjustedNights(adjustedNights);
      setNightsAdjusted(nightsAdjusted);
      console.log('Final adjusted nights:', adjustedNights);
      console.log('Nights adjusted flag:', nightsAdjusted);

      // Fetch all itineraries once
      const allFetchedItineraries = await fetchItinerariesFromDatabase(parentParkIds, adjustedNights);
      console.log('All fetched itineraries:', allFetchedItineraries.length);

      // Fetch the user's preferred properties from the search request
      const preferredProperties = searchRequest.locations.filter((location) => location.type === 'property').map((location) => location.id);

      // Check if the search request contains a beach location
      const beachLocation = searchRequest.locations.find((location) => location.category === 'Beach');

      // **** STEP 2 - FOR EACH CATEGORY FIND A SET OF ITINERARIES *** //
      const allItineraries = await Promise.all(
        categoriesToFetch.map((category) =>
          fetchItinerariesForCategory(
            category,
            parentParkIds,
            preferredProperties,
            beachLocation,
            adjustedNights,
            _.cloneDeep(allFetchedItineraries), // Use Lodash's cloneDeep here
          ),
        ),
      );

      // **** STEP 3 - MERGE AND DEDUPLICATE THE COMBINED SET *** //
      const mergedItineraries = allItineraries.flat();
      console.log('mergedItineraries length:', mergedItineraries.length);

      const { uniqueItineraries, filteredOutItineraries } = deduplicateItineraries(mergedItineraries);

      console.log('Filtered out itineraries:', filteredOutItineraries.length);
      console.log(
        'Filtered out itinerary UUIDs:',
        filteredOutItineraries.map((i) => i.uuid),
      );

      // Sort the unique itineraries
      const sortedUniqueItineraries = sortItinerariesRecommended(uniqueItineraries);

      // Take only the top 30 itineraries
      const top30Itineraries = sortedUniqueItineraries.slice(0, 27);

      // Fetch prices only for the top 30 itineraries
      const itinerariesWithPrices = await fetchPricesForItineraries(top30Itineraries, guests, user.internalUser ? agentObject.rateBandOffline : agentObject.rateBand);

      // Check which properties are missing from all itineraries
      const missingProperties = preferredProperties.filter((preferred) => {
        return !itinerariesWithPrices.some((itinerary) => itinerary.route.some((routeItem) => routeItem.propUid === preferred));
      });

      // Get the property names from searchRequest for the missing properties
      const missingPropertiesWithNames = missingProperties.map((propId) => {
        const property = searchRequest.locations.find((loc) => loc.id === propId);
        return {
          id: propId,
          name: property ? property.name : propId,
        };
      });

      setUnavailableProperties(missingPropertiesWithNames);
      setItineraries(itinerariesWithPrices);
    } catch (error) {
      console.error('Error fetching itineraries:', error);
    } finally {
      // Remove the timeout and just set loading false after everything is done
      setLoading(false);
    }
  }, [fetchItinerariesForCategory, guests, user.internalUser, agentObject, searchRequest]);

  useEffect(() => {
    setItineraries([]);
    fetchAllItineraries();
  }, [fetchAllItineraries]);

  useEffect(() => {
    setDisplayedItineraries(itineraries.slice(0, displayCount));
  }, [itineraries, displayCount]);

  const loadMore = () => {
    setDisplayCount((prevCount) => prevCount + 10);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      {loading ? (
         <div className="flex flex-col justify-center items-center h-full p-8">
         <div className="w-full max-w-md">
           {/* Progress bar */}
           <div className="h-1 w-full bg-gray-200 rounded-full mb-8">
             <motion.div 
               className="h-full bg-nomadEvergreen-600 rounded-full"
               initial={{ width: "0%" }}
               animate={{ width: `${(loadingStep + 1) * 25}%` }}
               transition={{ duration: 0.5 }}
             />
           </div>
     
           {/* Loading spinner and message */}
           <motion.div
             key={loadingStep}
             initial={{ opacity: 0, y: 20 }}
             animate={{ opacity: 1, y: 0 }}
             exit={{ opacity: 0, y: -20 }}
             transition={{ duration: 0.5 }}
             className="flex flex-col items-center"
           >
             <div className="relative mb-6">
               <div className="w-16 h-16 border-4 border-gray-200 rounded-full animate-spin-slow">
                 <div className="absolute top-0 right-0 w-4 h-4 bg-nomadEvergreen-600 rounded-full" />
               </div>
             </div>
             
             <h3 className="text-xl font-semibold text-gray-900 mb-3">
          {loadingStep === 0 && "Finding Matching Itineraries"}
          {loadingStep === 1 && "Checking Property Availability"}
          {loadingStep === 2 && "Optimizing itinerary Routes"}
          {loadingStep === 3 && "Calculating Latest Pricing"}
          {loadingStep === 4 && "Finalising Your Options"}
        </h3>
             
             <p className="text-gray-600 text-center">
               {loadingMessages[loadingStep]}
             </p>
           </motion.div>
         </div>
       </div>
      ) : displayedItineraries.length > 0 ? (
        <div>
          {!loading && displayedItineraries.length > 0 && (
  <div className="mb-6">
    <InfoBox
      icon={InformationCircleIcon}
       text="Prices shown are for accommodation only and based on default rooming. Select an itinerary to customise your rooms, add transfers and activities to get your live quote."
      bgColor="bg-blue-50"
      textColor="text-nomadBlue-700"
      fontSize="text-md"
    />
  </div>
)}
          {/* Show message if requested nights has changed */}
          {nightsAdjusted && (
            <div className="flex items-center bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-2 mb-4 rounded" role="alert">
              <InformationCircleIcon className="w-5 h-5 mr-2" />
              <span>We've adjusted your itinerary to {adjustedNights} nights to provide more options. You can also try reducing the number of parks or properties in your search.</span>
            </div>
          )}
          {/* Show message if property hasn't been found */}
          {unavailableProperties.length > 0 &&
            unavailableProperties.map((property) => (
              <div key={property.id} className="flex items-center bg-yellow-50 border-l-4 border-yellow-400 text-yellow-800 p-2 mb-4 rounded" role="alert">
                <ExclamationCircleIcon className="w-5 h-5 mr-2" />
                <span>No availability for {property.name}. Showing next best options. Consider adjusting your search criteria.</span>
              </div>
            ))}
          {/* New row for results count and sort */}
          <div className="flex justify-between items-center mb-4 px-4">
            <div className="text-gray-700 font-semibold">
              {itineraries.length} {itineraries.length === 1 ? 'result' : 'results'}
            </div>
            <Dropdown>
              <DropdownButton transparent className="dark:bg-transparent border-transparent">
                <span className="min-w-0 max-w-42">
                  <span className="block truncate text-sm/5 text-gray-700 dark:text-gray-700">
                    Sort: <span className="font-semibold">{sortOption}</span>
                  </span>
                </span>
                <ChevronDownIcon className="w-5 h-5 ml-1 text-gray-700" />
              </DropdownButton>
              <DropdownMenu>
                <DropdownItem onClick={() => setSortOption('Recommended')}>Recommended</DropdownItem>
                <DropdownItem onClick={() => setSortOption('Lowest Price')}>Lowest Price</DropdownItem>
                <DropdownItem onClick={() => setSortOption('Highest Price')}>Highest Price</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>

          <AnimatePresence>
            {displayedItineraries.map((foundItinerary, index) => (
              <motion.div key={foundItinerary.uuid} variants={cardVariants} initial="hidden" animate="visible" exit="hidden" transition={{ duration: 0.5, delay: index * 0.1 }}>
                <ItineraryCard
                  itinerary={foundItinerary}
                  shuffledRoute={foundItinerary.shuffledRoute}
                  bookings={bookings}
                  setBookings={setBookings}
                  setProperties={setProperties}
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  agentObject={agentObject}
                  agentRates={agentRates}
                  rooms={rooms}
                  setRooms={setRooms}
                  packageTab={packageTab}
                  guests={guests}
                  setLoadingData={setLoadingData}
                  setShowTemplates={setShowTemplates}
                  setNextTopInfo={setNextTopInfo}
                  setStartSearchButton={setStartSearchButton}
                  setFetchCustom={setFetchCustom}
                />
              </motion.div>
            ))}
          </AnimatePresence>
          {itineraries.length > displayCount && (
            <div className="flex justify-center mt-8">
              <ButtonPrimarySmall text="Load More" onClick={loadMore} className="px-8 py-3" />
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center h-full">
          <div className="text-center">
            {searchRequest.locations.length > 0 ? (
             <div className="flex flex-col items-center justify-center h-full p-8">
             <MagnifyingGlassIcon className="w-16 h-16 text-gray-400 mb-4" />
             <h3 className="text-lg font-semibold text-gray-900 mb-2">
               No itineraries found
             </h3>
             <p className="text-gray-600 text-center mb-6 max-w-md">
               We couldn't find any itineraries matching your criteria. Try adjusting your search or build a custom itinerary with Advanced Mode.
             </p>
           </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

export default ItinerarySearch;
