import 'react-datepicker/dist/react-datepicker.css';
import 'flatpickr/dist/flatpickr.css';
import '../css/datePickerStyles.css';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { IoMdInformationCircleOutline } from 'react-icons/io';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { addActivity } from '../functions/activityFunctions';
import { confirmAlert } from 'react-confirm-alert';
import { getPropObj } from '../functions/generalFunctions';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import ButtonOutlineSmall from './Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from './Buttons/ButtonPrimarySmall';

function ActivitiesSlideOutComp({
  isOpen,
  onClose,
  deeShadow,
  properties,
  activitiesTab,
  setActivitiesTab,
  activitiesList,
  bookings,
  selectedActivityProperty,
  selectedActivityPropertyUid,
  selectedActivityPropertyId,
  selectedActivityDate,
  selectedActivityDay,
  activitiesData,
  setActivitiesData,
  setActivitiesCost,
}) {
  const resetFormClose = () => {
    //resetForm();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      //resetForm();
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-0 flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-fit">
                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="flex-1 h-full overflow-y-auto">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto">
                        <div className="bg-white px-4 pt-6 sm:px-6">
                          <div className="flex items-center ">
                            {' '}
                            <div className="ml-0 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative rounded-md bg-white text-black hover:text-black"
                                onClick={() => {
                                  resetFormClose();
                                }}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                              <span className={`text-base text-black ml-5`}>Add Activities</span>
                            </div>
                          </div>{' '}
                          <Dialog.Title className={`mt-0 text-2xl font-base font-semibold leading-6 `}> </Dialog.Title>
                        </div>

                        <div className="mb-5 mx-6">
                          {/* PROPERTIES -> START */}
                          <div className="pt-3 bg-white rounded-lg w-min h-[670px] top-0">
                            {/* TABS */}
                            {/* TODO: Deal with border color and dynamic colors */}
                            <div className={``}>
                              <div className="">
                                {/* TABS */}
                                {/* TODO: Deal with border color and dynamic colors */}
                                <div className=" pt-3 bg-white rounded-lg w-[500px]">
                                  {/* TABS */}
                                  {/* TODO: Deal with border color and dynamic colors */}
                                  <div className={``}>
                                    <div className="">
                                      {/* TABS */}
                                      {/* TODO: Deal with border color and dynamic colors */}
                                      <div className={`flex items-center pb-5`}>
                                        <div
                                          className={`text-lg brand-text-color-800 mb-3 ${activitiesTab === 'prebooked' ? 'font-semibold' : 'font-normal'}`}
                                          // onClick={() => {
                                          //   setActivitiesTab('prebooked');
                                          // }}
                                        >
                                          Prebooked activities
                                        </div>
                                        {/* <div
                                          className={`text-lg brand-text-color-800 mb-3 ml-3 cursor-pointer ${activitiesTab === 'other' ? 'font-semibold' : 'font-normal'}`}
                                          onClick={() => {
                                            setActivitiesTab('other');
                                          }}
                                        >
                                          Other activities
                                        </div> */}
                                      </div>
                                      <div className="mb-5 pb-5">
                                        {activitiesList.filter((activity) => (activitiesTab === 'prebooked' ? activity.bookable === true : activity.bookable === false)).length > 0 ? (
                                          <div className="grid grid-cols-2 gap-4">
                                            {activitiesList
                                              .filter((activity) => {
                                                // Existing filter
                                                const prebookedFilter = activitiesTab === 'prebooked' ? activity.bookable === true : activity.bookable === false;

                                                // New filter condition
                                                const fullBoardFilter =
                                                  bookings[selectedActivityProperty] &&
                                                  bookings[selectedActivityProperty].rateName.toLowerCase().includes('fullboard') &&
                                                  activity.excludeFullBoard === true &&
                                                  activity.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67'; // Nomad Tanzania;

                                                // Combine both filters
                                                return prebookedFilter && !fullBoardFilter;
                                              })
                                              .map((activity, ai) => (
                                                <div key={uuidv4()} className={`bg-white overflow-hidden drop-shadow rounded-md`}>
                                                  <div className="">
                                                    <div className="flex flex-col">
                                                      <div
                                                        className="w-full cursor-pointer"
                                                        onClick={() => {
                                                          addActivity(
                                                            activitiesTab,
                                                            activity,
                                                            bookings,
                                                            selectedActivityProperty,
                                                            selectedActivityPropertyUid,
                                                            selectedActivityPropertyId,
                                                            selectedActivityDate,
                                                            selectedActivityDay,
                                                            activitiesData,
                                                            setActivitiesData,
                                                            setActivitiesCost,
                                                            properties,
                                                          );
                                                        }}
                                                      >
                                                        <img className="object-cover w-full h-24 rounded-t-md" src={activity.images[0].img} alt={activity.images[0].description} />
                                                      </div>
                                                      <div className="grid grid-cols-12 mx-3 mt-3">
                                                        <div className="col-span-10 text-xs font-semibold truncate">{activity.displayName}</div>
                                                        <div className="col-span-2 flex items-start justify-end">
                                                          <IoMdInformationCircleOutline
                                                            size={18}
                                                            className="ml-auto cursor-pointer"
                                                            onClick={() => {
                                                              confirmAlert({
                                                                customUI: ({ onClose }) => {
                                                                  return (
                                                                    <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                                                                      <p className="text-sm font-semibold pb-2">{activity.displayName}</p>
                                                                      <div className="text-xs font-normal pb-2z">{activity.description}</div>
                                                                      <div className="text-xs font-semibold">Price: {activity.price}</div>
                                                                      <div className="text-xs font-semibold">Per: {activity.priceType}</div>

                                                                      <div className="flex mt-5 justify-end gap-4">
                                                                     
                                                                          <ButtonOutlineSmall
                                                                           onClick={() => {
                                                                              onClose();
                                                                            }}
                                                                          
                                                                            text="Cancel"
                                                                            color="dark"
                                                                          />
                                                                          <ButtonPrimarySmall
                                                                           className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                                                                            onClick={async () => {
                                                                              addActivity(
                                                                                activitiesTab,
                                                                                activity,
                                                                                bookings,
                                                                                selectedActivityProperty,
                                                                                selectedActivityPropertyUid,
                                                                                selectedActivityPropertyId,
                                                                                selectedActivityDate,
                                                                                selectedActivityDay,
                                                                                activitiesData,
                                                                                setActivitiesData,
                                                                                setActivitiesCost,
                                                                                properties,
                                                                              );
                                                                              // ADD ACTIVITY
                                                                              onClose();
                                                                            }}
                                                                          
                                                                          text="Add Activity"
                                                                          color="dark"
                                                                          />
                                                                        
                                                                      </div>
                                                                    </div>
                                                                  );
                                                                },
                                                              });
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="flex items-start ml-3 mb-3 cursor-pointer"
                                                        onClick={() => {
                                                          addActivity(
                                                            activitiesTab,
                                                            activity,
                                                            bookings,
                                                            selectedActivityProperty,
                                                            selectedActivityPropertyUid,
                                                            selectedActivityPropertyId,
                                                            selectedActivityDate,
                                                            selectedActivityDay,
                                                            activitiesData,
                                                            setActivitiesData,
                                                            setActivitiesCost,
                                                            properties,
                                                          );
                                                        }}
                                                      >
                                                        <div className="text-xs font-normal">
                                                          <div className="text-xs font-normal">{activity.supplier.name}</div>
                                                          <div className="text-xs font-normal">{moment(selectedActivityDate).format('Do MMM YYYY')}</div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        ) : (
                                          <div className="pb-5">{selectedActivityProperty === null ? 'Please select a day to the left' : 'No activities available'}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PROPERTIES -> END */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ActivitiesSlideOutComp;
