import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useMemo } from 'react'
import { format, differenceInDays } from 'date-fns'
// Helper function to format numbers with comma-separated thousands
const formatNumber = (num) => {
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  
  export default function PricingSummaryModal({ isOpen, closeModal, propertyInfo }) {
    const hasPrices = propertyInfo?.prices && propertyInfo.prices.length > 0;
  
    const totalPrice = useMemo(() => {
      if (!hasPrices) return 0;
      return propertyInfo.prices.reduce((sum, item) => sum + item.saletotal, 0);
    }, [hasPrices, propertyInfo?.prices]);
  
    const nights = useMemo(() => {
      if (!propertyInfo?.startDay || !propertyInfo?.endDay) return 0;
      return differenceInDays(new Date(propertyInfo.endDay), new Date(propertyInfo.startDay));
    }, [propertyInfo?.startDay, propertyInfo?.endDay]);
  
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
  
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="max-h-[80vh] overflow-y-auto">
                    <div className="sticky top-0 bg-white z-10 border-b">
                      <div className="px-6 py-4 flex justify-between items-start">
                        <div>
                          <DialogTitle
                            as="h3"
                            className="text-2xl font-semibold leading-6 text-gray-900"
                          >
                            {propertyInfo?.propName}
                          </DialogTitle>
                          <p className="mt-1 text-sm text-gray-500">
                            Pricing Summary
                          </p>
                          {propertyInfo?.startDay && propertyInfo?.endDay && (
                            <p className="mt-2 text-sm text-gray-600">
                              {format(new Date(propertyInfo.startDay), 'EEE, do MMM yyyy')} - {format(new Date(propertyInfo.endDay), 'EEE, do MMM yyyy')}
                            </p>
                          )}
                          {nights > 0 && (
                            <p className="mt-1 text-sm font-medium text-gray-700">
                              {nights} {nights === 1 ? 'night' : 'nights'}
                            </p>
                          )}
                        </div>
                        <button
                          onClick={closeModal}
                          className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    
                    <div className="p-6">
                      {!hasPrices ? (
                        <p className="text-center text-gray-500">
                          Pricing not available yet. Please refresh rates first, then check again.
                        </p>
                      ) : (
                        <div className="overflow-x-auto">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/3">Summary</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nights</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Count</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rate</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {propertyInfo.prices.map((item, index) => (
                                <tr key={index}>
                                  <td className="px-6 py-4 text-sm font-medium text-gray-900 break-words">{item.summary}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.nights}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.count}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${formatNumber(item.salerate)}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">${formatNumber(item.saletotal)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
  
                  {hasPrices && (
                    <div className="sticky bottom-0 bg-white z-10 p-6 border-t">
                      <div className="flex justify-between items-center">
                        <span className="text-lg font-semibold text-gray-900">Total Price:</span>
                        <span className="text-xl font-bold text-nomadBlue-700">${formatNumber(totalPrice)}</span>
                      </div>
                    </div>
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  }