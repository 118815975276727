import React from 'react';
import { StarIcon as StarIconSolid } from '@heroicons/react/20/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline';

function NomadCampHeaderSection({ camp, firestoreData, tripAdvisorData }) {
  const rating = parseFloat(tripAdvisorData.rating);
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;

  return (
    <div className="mb-8">
      <h1 className="text-4xl font-normal font-['Roca One'] text-[#20373b] mb-2">{camp.campInformation.campName}</h1>
      <div className="flex items-center space-x-4 mb-4">
        <div className="flex items-center">
          {[...Array(5)].map((_, i) => (
            <span key={i}>
              {i < fullStars ? (
                <StarIconSolid className="h-5 w-5 text-nomadAccentAmber" />
              ) : i === fullStars && hasHalfStar ? (
                <div className="relative">
                  <StarIconOutline className="h-5 w-5 text-nomadAccentAmber" />
                  <div className="absolute inset-0 overflow-hidden" style={{ width: '50%' }}>
                    <StarIconSolid className="h-5 w-5 text-nomadAccentAmber" />
                  </div>
                </div>
              ) : (
                <StarIconOutline className="h-5 w-5 text-nomadAccentAmber" />
              )}
            </span>
          ))}
          <span className="ml-2 text-sm font-semibold text-[#20373b]">{rating.toFixed(1)}</span>
        </div>
        <span className="text-sm font-semibold text-[#20373b] underline">
          {parseInt(tripAdvisorData.num_reviews, 10).toLocaleString()} Reviews
        </span>
      </div>
      <div className="flex justify-between items-center">
      <div className="bg-[#8cb560]/20 text-[#21574a] text-xs font-medium px-3 py-1 rounded-full">
          {camp.campInformation.location || firestoreData.park.name}, {firestoreData.country.name}
        </div>
      </div>
    </div>
  );
}

export default NomadCampHeaderSection;