import { client } from '../../../db/sanity';
import { db } from '../../../db/firebase.config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getTripAdvisorData } from './tripAdvFunctions';

export async function fetchSanityData(uid) {
  const sanityQuery = `*[_type == "nomadCamp" && campInformation.propertyUid.current == $uid][0]`;
  const sanityParams = { uid };
  return await client.fetch(sanityQuery, sanityParams);
}

export async function fetchFirestoreData(uid) {
  const q = query(collection(db, 'properties'), where('uid', '==', uid));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty ? querySnapshot.docs[0].data() : null;
}

export async function fetchPropertyData(uid) {
  try {
    const [sanityData, firestoreData] = await Promise.all([
      fetchSanityData(uid),
      fetchFirestoreData(uid)
    ]);

    let tripAdvisorData = null;
    if (firestoreData && firestoreData.tripAdvisorId && firestoreData.tripAdvisorId.id) {
      tripAdvisorData = await getTripAdvisorData(firestoreData.tripAdvisorId.id);
    }

    return {
      camp: sanityData,
      campDataFirestore: firestoreData,
      tripAdvisorData: tripAdvisorData
    };
  } catch (error) {
    console.error("Error fetching property data:", error);
    throw error;
  }
}

export async function fetchTravelInfoData(country) {
  const query = `*[_type == "travelInfo" && country == $country][0]`;
  const params = { country };
  return await client.fetch(query, params);
}

export async function fetchSeasonalGuideData() {
  const query = `*[_type == "seasonalGuideDoc"]`;
  return await client.fetch(query);
}