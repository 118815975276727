import React from 'react';

function Modal({ show, children }) {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-11/12 text-center">
        {children}
      </div>
    </div>
  );
}

export default Modal;