import React, { Fragment, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

export function ManageBookingHeader() {
  // Return a div element
  return (
    <div className="w-full">
      <div
        key={uuidv4()}
        className={`z-10 cursor-pointer items-center justify-center text-center`}
        // onClick={() => {
        //   if (!isOpen) {
        //     if (selectedItinerary === hit.uid) {
        //       setSelectedItinerary(null);
        //       setSelectedHit(null);
        //       //setBookings(null);
        //     } else {
        //       setSelectedItinerary(hit.uid);
        //       setSelectedHit(hit);
        //       //setBookings(hit);
        //       setGeneratedLink(hit.itineraryUrl);
        //       setGeneratedLinkSummary(hit.resRequestSummaryUrl);
        //       setGeneratedExcelCostingLink(hit.costingUrl);
        //       setInvoiceLink(hit.invoiceUrl);
        //       console.log('hit:');
        //       console.log(JSON.stringify(hit, undefined, 4));
        //     }
        //   }
        // }}
      >
        <div className={`grid grid-cols-12 w-full h-12 bg-stone-100 items-center px-3 rounded-lg`}>
          <div key={uuidv4()} className="col-span-1 text-left text-nomadBlue-600 text-xs font-semibold">
            CODE
          </div>
          <div key={uuidv4()} className="col-span-4 text-left text-nomadBlue-600 text-xs font-semibold">
            NAME
          </div>
          <div key={uuidv4()} className="col-span-3 text-left text-nomadBlue-600 text-xs font-semibold">
            ACCOUNT
          </div>
          <div key={uuidv4()} className="col-span-2 text-left text-nomadBlue-600 text-xs font-semibold">
            START DATE
          </div>
          <div key={uuidv4()} className="col-span-1 text-left text-nomadBlue-600 text-xs font-semibold">
            STATUS
          </div>

          <div key={uuidv4()} className="col-span-1 text-left text-nomadBlue-600 text-xs font-semibold">
            {' '}
          </div>
        </div>
      </div>
    </div>
  );
}
