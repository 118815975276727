import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import locationsService from "./locationsService";

const initialState = {
  allLocations: [],
  locationsError: false,
  locationsSuccess: false,
  locationsLoading: false,
  locationsMessage: "",
};

// Get Locations
export const getLocations = createAsyncThunk("locations/getLocations", async (location, thunkAPI) => {
  try {
    return await locationsService.getLocations(location);
  } catch (error) {
    console.log(error);
    const message = "No locations";
    return thunkAPI.rejectWithValue(message);
  }
});

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    reset: (state) => {
      state.locationsLoading = false;
      state.locationsError = false;
      state.locationsSuccess = false;
      state.locationsMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        state.locationsLoading = true;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locationsLoading = false;
        state.locationsSuccess = true;
        state.allLocations = action.payload.jsonResult;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.locationsLoading = false;
        state.locationsError = true;
        state.locationsMessage = action.payload;
      });
  },
});

export const { reset } = locationsSlice.actions;
export default locationsSlice.reducer;
