import { bootIntercom, shutdownIntercom } from '../../intercom/intercom';
import { collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';

//import axios from "axios";
import { db } from '../../db/firebase.config';

//import { endLiveSession, startLiveSession } from "../../intercom/livesession";

// Register user
const register = async (userData) => {
  const auth = getAuth();
  const { firstName, lastName, email, password, companyId, uid, accessLevel, nomadPropertiesOnly } = userData;
  try {
    const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
    console.log('Access Level:', accessLevel); // Add this line
    console.log('Nomad Properties Only:', nomadPropertiesOnly); // Add this line

    const fullName = firstName + ' ' + lastName;
    updateProfile(auth.currentUser, { displayName: fullName });

    const user = {
      firstName: firstName,
      lastName: lastName,
      email: userCredentials.user.email,
      uid: userCredentials.user.uid,
      consultant: { companyId: companyId, uid: uid },
      accessLevel: accessLevel,
      fullBoardOnly: nomadPropertiesOnly,
      resRequestId: '',
    };
    const userCopy = { ...user };
    userCopy.created = serverTimestamp();
    userCopy.active = true;
    userCopy.betaTester = false;
    userCopy.createNewEnquiry = true;
    userCopy.internalUser = false;
    userCopy.logout = true;

    // COMMENTED OUT SO AS TO AVOID THE USER FROM LOGGING IN.
    // if (user.uid) {
    //  localStorage.setItem('user', JSON.stringify(user));
    //  bootIntercom(user);
    //startLiveSession(user);
    // }
    await setDoc(doc(db, 'users', user.uid), userCopy);

    // Send email verification
    await sendEmailVerification(userCredentials.user);
    await logout();
    await auth.signOut();

    console.log(user);
    return { message: 'Verification email sent' };
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      throw new Error('Email is already registered');
    } else {
      throw error;
    }
  }
};

// login user
const login = async (userData) => {
  const auth = getAuth();
  const { email, password } = userData;
  const userCredentials = await signInWithEmailAndPassword(auth, email, password);
  // set logout to false
  const userRef = doc(db, 'users', auth.currentUser.uid);
  await updateDoc(userRef, { logout: false, updated: serverTimestamp() });
  //
  const docRef = doc(db, 'users', userCredentials.user.uid);
  const docSnapshot = await getDoc(docRef);

  if (docSnapshot.exists() && docSnapshot.data().active) {
    const createdAt = docSnapshot.data().created?.toDate();
    const emailVerified = userCredentials.user.emailVerified;

    if ((createdAt && createdAt < new Date('2024-05-20')) || emailVerified) {
      console.log(docSnapshot.data());
      console.log(docSnapshot.data().uid);
    } else {
      throw new Error('Please verify your email before signing in. Check your email for a verification link.');
    }
  } else {
    throw new Error('User not found or inactive');
  }

  // Logout user if active = false
  // onSnapshot(doc(db, "users", userCredentials.user.uid), { includeMetadataChanges: true }, (doc) => {
  //   console.log(doc.data().active);

  //   if (doc.data() && !doc.data().active) {
  //     logout();
  //     //window.location.reload();
  //     console.log("FALSE: " + doc.data().active);
  //     //throw new Error();
  //   }
  // });

  const user = {
    firstName: docSnapshot.data().firstName,
    lastName: docSnapshot.data().lastName,
    email: docSnapshot.data().email,
    uid: docSnapshot.data().uid,
    active: docSnapshot.data().active,
    isAdmin: docSnapshot.data().isAdmin,
    resRequestId: docSnapshot.data().resRequestId,
    betaTester: docSnapshot.data().betaTester,
    consultant: docSnapshot.data().consultant,
    ...(docSnapshot.data().fullBoardOnly !== undefined ? { fullBoardOnly: docSnapshot.data().fullBoardOnly } : {}),
    ...(docSnapshot.data().createNewEnquiry !== undefined ? { createNewEnquiry: docSnapshot.data().createNewEnquiry } : {}),
    ...(docSnapshot.data().internalUser !== undefined ? { internalUser: docSnapshot.data().internalUser } : {}),
    ...(docSnapshot.data().consultant ? { consultant: docSnapshot.data().consultant } : {}),
    ...(docSnapshot.data().accessLevel !== undefined ? { accessLevel: docSnapshot.data().accessLevel } : {}),
    earlyAccessRequested: docSnapshot.data().earlyAccessRequested,
  };

  if (user.uid) {
    localStorage.setItem('user', JSON.stringify(user));
    bootIntercom(user);
    //startLiveSession(user);
  }
  console.log(user);

  // set logout to false

  return user;
};

// Logout users
const logout = async () => {
  const auth = getAuth();
  auth.signOut(auth.currentUser);
  auth.currentUser = null;
  shutdownIntercom();
  localStorage.removeItem('user');
};

// const logout = async () => {
//   try {
//     const auth = getAuth();
//     await auth.signOut(); // Wait for sign out to complete

//     shutdownIntercom(); // Your custom function

//     localStorage.removeItem("user"); // Remove user from local storage

//     // If you really need to make sure currentUser is null, you could check it here
//     // But usually, you don't need to set this manually.
//     if (auth.currentUser !== null) {
//       // handle error or take some action
//     }
//   } catch (error) {
//     console.error('Error during sign out:', error);
//   }
// };

// Update user
const updateUser = async (formData) => {
  const auth = getAuth();
  const { firstName, lastName } = formData;
  const fullName = firstName + ' ' + lastName;

  await updateProfile(auth.currentUser, { displayName: fullName });
  //Update email in Firebase
  //await updateEmail(auth.currentUser, email);
  //Update display name in Firestore
  const userRef = doc(db, 'users', auth.currentUser.uid);
  await updateDoc(userRef, { firstName, lastName, updated: serverTimestamp() });
  //await updateDoc(userRef, formData);

  localStorage.setItem('user', JSON.stringify(formData));

  return formData;
};

// Forgot password
const forgotPassword = async (formData) => {
  const auth = getAuth();
  await sendPasswordResetEmail(auth, formData.email);
};

// Check if user is allowed to sign up
const checkUserExistence = async (userData) => {
  const { email } = userData;
  const consultantsRef = collection(db, 'consultants');
  const q = query(consultantsRef, where('email', '==', email), where('active', '==', true));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    throw new Error('User not allowed to sign up');
  } else {
    const consultantDoc = querySnapshot.docs[0];
    const companyId = consultantDoc.data().companyId;

    // Check the connected agent account
    const agentsRef = collection(db, 'agents');
    const agentQuery = query(agentsRef, where('companyId', '==', companyId), where('active', '==', true));
    const agentSnapshot = await getDocs(agentQuery);

    if (agentSnapshot.empty) {
      throw new Error('User not allowed to sign up');
    } else {
      const agentDoc = agentSnapshot.docs[0];
      const accessLevel = agentDoc.data().accessLevel;
      const nomadPropertiesOnly = agentDoc.data().nomadPropertiesOnly;

      // Added userAllowed flag to the return object
      return {
        userAllowed: true,
        companyId: companyId,
        uid: consultantDoc.id,
        accessLevel: accessLevel,
        nomadPropertiesOnly: nomadPropertiesOnly,
      };
    }
  }
};

const updateEarlyAccessRequested = async (uid) => {
  // Update Firestore
  const userRef = doc(db, 'users', uid);
  await updateDoc(userRef, {
    earlyAccessRequested: true,
    updated: serverTimestamp(),
  });

  // Update localStorage
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.uid === uid) {
    user.earlyAccessRequested = true;
    localStorage.setItem('user', JSON.stringify(user));
  }

  // Return the updated user
  return user;
};


const authService = {
  register,
  logout,
  login,
  updateUser,
  forgotPassword,
  checkUserExistence,
  updateEarlyAccessRequested,
};

export default authService;
