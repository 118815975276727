import { FaEdit, FaPlusCircle, FaSave, FaTrashAlt, FaWindowClose } from 'react-icons/fa';
import { FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowDropdownCircle, IoIosArrowDropupCircle, IoIosArrowUp, IoMdWarning } from 'react-icons/io';
import { MdConnectingAirports, MdContentCopy, MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useRef, useState } from 'react';
import { TbArrowsRandom, TbBuildingWarehouse } from 'react-icons/tb';
import { addItem, deleteItem, handleCut, handlePaste } from '../functions/finalCustomFunctions';
import {
  cancelFinalInputData,
  cancelFinalInputDataEdit,
  deleteFinalInputItem,
  editFinalInputItem,
  feeTypeOptions,
  getLabelForFinalValue,
  getShortenedLabelForValue,
  handleAddFinalPropertyItem,
  moveItemDown,
  moveItemUp,
  resetEditPropertyItem,
  resetFinalInputs,
  saveFinalInputData,
  saveFinalInputDataEdit,
  toggleFinalCollapse,
} from '../functions/finalPageFunctions';
import { findBookingById, getSupplierName } from '../functions/generalFunctions';

import { GiJeep } from 'react-icons/gi';
import Select from 'react-select';
import ViewCompDay from './ViewCompDay';
import ViewCompHeader from './ViewCompHeader';
import ViewCompTableHeader from './ViewCompTableHeader';
import ViewCompTableRow from './ViewCompTableRow';
import ViewFoundItemCard from './ViewFoundItem';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const ViewPageComp = ({
  item,
  index,
  finalPageData,
  setFinalPageData,
  properties,
  getPropObj,
  rateLabelMap,
  formatPrice,
  suppliers,
  finalInputs,
  customFinalItems,
  setCustomFinalItems,
  bookings,
  finalItem,
  firstDay,
  bookingMaster,
  manage,
  supplierReservations,
  hideStatusPill
}) => {
  // USER
  const { user } = useSelector((state) => state.auth);
  // ...
  let searchId = item.uuid;
  const foundItems = customFinalItems.filter((searchItem) => searchItem.id === searchId && !searchItem.departure);
  const foundItemsDeparture = customFinalItems.filter((search) => search.id === searchId && search.departure && finalItem && (search.pickupDropoff === undefined || search.pickupDropoff === false));

  const pickupDropoff = false;
  // DAY
  const firstDayMoment = moment(firstDay, 'YYYY-MM-DD');
  const dayMoment = moment(item.startDay, 'YYYY-MM-DD');
  let day = firstDay === item.startDay ? 1 : dayMoment.diff(firstDayMoment, 'days') + 1;

  let parentDate = item.startDay;

  return (
    <>
      {/* found items */}
      {foundItems.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`brand-text-grey-v2 mb-7`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <ViewFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            formatPrice={formatPrice}
            firstDay={firstDay}
            bookingMaster={bookingMaster}
          />
        </div>
      ))}

      {/* main items */}
      <div key={uuidv4()} style={{ zIndex: 1000 - index, WebkitTransform: 'translateZ(0)' }} className={` my-10`}>

      <div className="bg-white rounded-[15px] border border-gray-200 shadow-md hover:shadow-lg transition-shadow duration-300">
          {/* HEADER */}
          <ViewCompHeader
            day={day}
            item={item}
            feeTypeIcon={
              <img
                className="ml-0 mr-0 object-cover w-11 h-11 rounded-full"
                src={
                  properties[getPropObj(item.propUid, properties)].heroImageUrl
                    ? `${properties[getPropObj(item.propUid, properties)].heroImageUrl}/320x200`
                    : 'https://firebasestorage.googleapis.com/v0/b/nomadhornbill.appspot.com/o/images%2Fproperties%2Fdefault%2Fdefault.png?alt=media&token=60f73247-fa43-4829-9c56-260803159067'
                }
                alt={properties[getPropObj(item.propUid, properties)].name || 'Property image'}
              />
            }
            description={`${item.propName} for ${item.days} night${item.days === 1 ? '' : 's'}`}
            supplierName={
              properties[getPropObj(item.propUid, properties)].supplier.name === 'Booked Direct' ? (
                <p className="brand-text-custom-color-warning ml-1">Booked Direct</p>
              ) : (
                properties[getPropObj(item.propUid, properties)].supplier.name
              )
            }
            supplierUid={properties[getPropObj(item.propUid, properties)].supplier.id}
            date={moment(item.startDay).format('Do MMM YYYY')}
            total={item.propertyItems.reduce((totals, currentItem) => totals + currentItem?.total ?? 0, 0)}
            collapse={<FiChevronsUp size={30} className="cursor-pointer text-zinc-300" onClick={() => toggleFinalCollapse(item.uuid, finalPageData, false, setFinalPageData, false)} />}
            expand={<FiChevronsDown size={30} className=" cursor-pointer text-zinc-300" onClick={() => toggleFinalCollapse(item.uuid, finalPageData, false, setFinalPageData, false)} />}
            bookingMaster={bookingMaster}
            manage={manage}
            supplierReservations={supplierReservations}
            hideStatusPill={hideStatusPill}
          />

          {/* ROW TITLES */}

          {!item.collapsed && <ViewCompTableHeader />}

          {/* Column propertyItems*/}
          <div className={`${!item.collapsed ? 'pb-5' : ''}`}>
            {!item.collapsed &&
              item.propertyItems
                .sort((a, b) => (a.edit === b.edit ? 0 : a.edit ? 1 : -1))
                .map(
                  (propertyItem, itemIndex) =>
                    item.editPropertyItem !== propertyItem.uuid && (
                      <div key={uuidv4()} className="">
                        <ViewCompTableRow
                          index={itemIndex}
                          lengthCheck={item.propertyItems}
                          description={propertyItem.description}
                          supplierName={getSupplierName(propertyItem.supplier, suppliers)}
                          units={propertyItem.units}
                          pax={propertyItem.pax}
                          net={propertyItem.net}
                          total={propertyItem.total}
                        />
                      </div>

                      // <div key={uuidv4()} className={`grid grid-cols-12 text-xs border-b border-slate-200`}>
                      //   <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{getShortenedLabelForValue(propertyItem.feeTypeUid)}</div>
                      //   <div className="col-span-2 ml-5 border-r border-slate-200 py-2">{getSupplierName(propertyItem.supplier, suppliers)}</div>
                      //   <div className="col-span-4">
                      //     <div className={`grid grid-cols-12 text-xs`}>
                      //       <div className="col-span-11 ml-5 border-r border-slate-200 py-2 pr-5">{propertyItem.description}</div>
                      //       <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group">
                      //         <svg viewBox="0 0 24 24" className={`w-6 h-6 ${propertyItem.star ? 'brand-text-color-v2' : 'text-gray-300'}`}>
                      //           <path d="M12 2l2.4 7.2h7.6l-6.2 4.6l2.4 7.2L12 16.4l-6.2 4.6l2.4-7.2L2 9.2h7.6L12 2z" className="star-path fill-current"></path>
                      //         </svg>
                      //       </div>
                      //       {/* <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center">{propertyItem.star}</div> */}
                      //     </div>
                      //   </div>
                      //   {/* <div className="col-span-5 ml-5 border-r border-slate-200 py-2">{propertyItem.description}</div> */}
                      //   <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{propertyItem.units}</div>
                      //   <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{propertyItem.pax}</div>
                      //   <div className="col-span-1 ml-5 border-r border-slate-200 py-2">${formatPrice(propertyItem.net)}</div>
                      //   <div className="col-span-1 ml-5 border-r border-slate-200 py-2"> ${formatPrice(propertyItem.total)}</div>
                      //   <div className="col-span-1 ml-5 py-2 flex items-center"></div>
                      // </div>
                    ),
                )}
          </div>
        </div>

        {/* TODO TRANSFERS AND ACTIVITIES*/}
      </div>

      {/* found items final */}
      {foundItemsDeparture.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`brand-text-grey-v2 mb-7`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <ViewFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            formatPrice={formatPrice}
            firstDay={firstDay}
            bookingMaster={bookingMaster}
          />
        </div>
      ))}
    </>
  );
};

export default ViewPageComp;
