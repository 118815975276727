import { FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdConnectingAirports, MdContentCopy, MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { TbArrowsRandom, TbBuildingWarehouse } from 'react-icons/tb';
import { addItem, deleteItem, handleCut, handlePaste } from '../functions/finalCustomFunctions';

import FinalFoundItemCard from './FinalFoundItem';
import { GiJeep } from 'react-icons/gi';
import { PiPark } from 'react-icons/pi';
import { formatPrice } from '../functions/generalFunctions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const FinalActivity = ({
  item,
  bookings,
  booking,
  activitiesData,
  customFinalItems,
  setCustomFinalItems,
  customFinalCut,
  setCustomFinalCut,
  handleOpenSlideOut,
  setCustomItemToEdit,
  setAddFinalItemDetails,
  shouldCollapse,
  firstDay,
  setFinalSlideOutDate,
  finalItem,
}) => {
  // USER
  const { user } = useSelector((state) => state.auth);
  let searchId = item.uuid;
  const foundItems = customFinalItems.filter((search) => search.id === searchId && !search.departure);
  const foundItemsDeparture = customFinalItems.filter((search) => search.id === searchId && search.departure && finalItem && (search.pickupDropoff === undefined || search.pickupDropoff === false));

  const pickupDropoff = false;

  console.log('item activity', JSON.stringify(item, null, 2));

  const [localItem, setLocalItem] = useState({ ...item, collapsed: shouldCollapse }); // Initialize localItem with item prop and set collapsed to true

  useEffect(() => {
    // If you need to respond to changes in the item prop, update localItem here
    setLocalItem((current) => ({ ...current, ...item, collapsed: current.collapsed }));
  }, [item]);

  // DAY
  const firstDayMoment = moment(firstDay, 'YYYY-MM-DD');
  const dayMoment = moment(item.selectedActivityDate, 'YYYY-MM-DD');
  let day = firstDay === item.selectedActivityDate ? 1 : dayMoment.diff(firstDayMoment, 'days') + 1;
  let parentDate = item.selectedActivityDate;
  return (
    <>
      {/* found items */}
      {foundItems.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`bg-[rgba(42,153,163,0.1)] border border-slate-200 brand-text-grey-v2 mb-7 
                  ${customFinalCut && customFinalCut.uuid === foundItem.uuid ? 'animate-pulse' : ''}`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <FinalFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalCut={customFinalCut}
            handlePaste={handlePaste}
            addItem={addItem}
            handleCut={handleCut}
            deleteItem={deleteItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            setCustomFinalCut={setCustomFinalCut}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            handleOpenSlideOut={handleOpenSlideOut}
            setCustomItemToEdit={setCustomItemToEdit}
            setAddFinalItemDetails={setAddFinalItemDetails}
            formatPrice={formatPrice}
            firstDay={firstDay}
            setFinalSlideOutDate={setFinalSlideOutDate}
          />
        </div>
      ))}

      {/* main items */}

      <div
        className={`bg-white border border-slate-200 brand-text-grey-v2 mb-7`}
        style={{
          WebkitTransform: 'translateZ(0)' /* Safari */,
        }}
      >
        {/* ROW 1 (Property info) */}
        {user.internalUser && (
          <div className="fixed -top-7 left-0 right-0 flex justify-center group">
            {customFinalCut ? (
              <button
                className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                onClick={() => handlePaste('bottom', null, customFinalCut, customFinalItems, searchId, pickupDropoff, setCustomFinalItems, setCustomFinalCut, parentDate)}
                disabled={!customFinalCut}
              >
                <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-nomadEvergreen-700`}>
                  <span className="mr-1.5">Paste item</span>
                  <FaRegPaste size={16} />
                </span>
              </button>
            ) : (
              <button
                className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                onClick={() => {
                  setFinalSlideOutDate(item.selectedActivityDate);
                  addItem('bottom', null, searchId, pickupDropoff, handleOpenSlideOut, setAddFinalItemDetails);
                }}
              >
                <span className={`text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-center brand-text-color-v2`}>+ Add Item</span>
              </button>
            )}
          </div>
        )}

        {/* DAY */}
        {/* <div className="fixed top-4 -left-12 flex -rotate-90"> */}
        <div className="fixed -top-6 -left-0 flex">
          <div className="text-left" style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}>
            {/* <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-nomadEvergreen-700`}> */}
            <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-0 pr-3 py-0.5 text-nomadEvergreen-700`}>
              <span className="tracking-widest">DAY {day}</span>
              {/* <FaRegPaste size={16} /> */}
            </span>
          </div>
        </div>

        {/* add / paste bottom */}
        {user.internalUser && (finalItem || foundItemsDeparture.length > 0) && (
          <div className="fixed -bottom-7 left-0 right-0 flex justify-center group">
            {customFinalCut ? (
              <button
                className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                onClick={() => handlePaste('top', null, customFinalCut, customFinalItems, searchId, pickupDropoff, setCustomFinalItems, setCustomFinalCut, parentDate, true)}
                disabled={!customFinalCut}
              >
                <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5  text-nomadEvergreen-700`}>
                  <span className="mr-1.5">Paste item</span>
                  <FaRegPaste size={16} />
                </span>
              </button>
            ) : (
              <button
                className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                onClick={() => {
                  setFinalSlideOutDate(item.selectedActivityDate);
                  addItem('top', null, searchId, pickupDropoff, handleOpenSlideOut, setAddFinalItemDetails, true);
                }}
              >
                <span className={`text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-center brand-text-color-v2`}>+ Add Item</span>
              </button>
            )}
          </div>
        )}

        <div className={`mb-0`}>
          <div className={`grid grid-cols-12`}>
            {/* IMG */}

            {/* ROOM NAME + DESCRIPTION */}
            <div className="col-span-12 bg-white">
              {/* Number of days */}
              <div
                className="text-right fixed top-4 right-0"
                style={{
                  WebkitTransform: 'translateZ(0)',
                  perspective: '1000' /* Safari */,
                }}
              ></div>
              {/* Property name */}
              <div className="grid grid-cols-12 border-b border-slate-200">
                <div className="text-xs font-medium truncate text-ellipsis overflow-hidden col-span-10 ml-2 border-r border-slate-200 py-2">
                  <div className="flex items-center">
                    {/* ICON AND CIRCLE */}
                    <div className="row-span-1 relative">
                      <div className="ml-0 mr-2 object-cover w-10 h-10 rounded-full bg-slate-200 flex justify-center items-center">
                        <PiPark size={30} />
                      </div>
                    </div>

                    {/* <PiPark size={30} /> */}
                    <div className="ml-2 flex flex-col">
                      <span className="flex">
                        <span className="text-base w-14">{moment(item.selectedActivityDate).format('MMM D')}</span>
                        <span className="text-base font-semibold ml-5 text-black">{item.displayName}</span>
                      </span>
                      <span className="flex">
                        <span className="w-14">{moment(item.selectedActivityDate).format('ddd')}</span>
                        <span className="ml-5">
                          {item.supplier.name} | {moment(item.selectedActivityDate).format('Do MMM YYYY')} {finalItem ? 'true' : 'false'}
                          {/* {item.supplier.name} | {moment(item.selectedActivityDate).format('Do MMM YYYY')} */}
                        </span>
                      </span>
                      {/* <span>
                        {item.supplier.name} | {moment(item.selectedActivityDate).format('Do MMM YYYY')}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-span-1 text-xs border-r border-slate-200 py-2 flex items-center justify-center text-nomadEvergreen-700">${formatPrice(item.cost)}</div>
                <div className="col-span-1 py-2 flex justify-center items-center">
                  {!localItem.collapsed ? (
                    <IoIosArrowUp
                      size={20}
                      className={`cursor-pointer mt-1 text-nomadEvergreen-700`}
                      onClick={() => setLocalItem({ ...localItem, collapsed: !localItem.collapsed })}
                      // onClick={() => toggleFinalActivityCollapse(item.uuid, finalPageData, resetFinalInputs, setFinalPageData, setFinalInputs)} // add this line
                    />
                  ) : (
                    <IoIosArrowDown
                      size={20}
                      className={`cursor-pointer mt-1 text-nomadEvergreen-700`}
                      onClick={() => setLocalItem({ ...localItem, collapsed: !localItem.collapsed })}
                      // onClick={() => toggleFinalActivityCollapse(item.uuid, finalPageData, resetFinalInputs, setFinalPageData, setFinalInputs)} // add this line
                    />
                  )}
                </div>
              </div>

              {/* Column headers*/}
              {/* TODO Add description column*/}

              {!localItem.collapsed && (
                <div className={`grid grid-cols-12 text-xs font-medium border-b border-slate-200`}>
                  <div className="col-span-1 px-2 border-r border-slate-200 py-2 text-black">FEE TYPE</div>
                  <div className="col-span-2 ml-5 border-r border-slate-200 py-2 text-black">SUPPLIER</div>
                  <div className="col-span-4">
                    <div className={`grid grid-cols-12 text-xs`}>
                      <div className="col-span-11 ml-5 border-r border-slate-200 py-2 text-black">DESCRIPTION</div>
                      <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center"></div>
                    </div>
                  </div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">
                    <span className="block">NTS/UNITS</span>
                  </div>

                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">
                    <span className="block">PAX/UNITS</span>
                  </div>
                  <div className="col-span-1 ml-4 border-r border-slate-200 py-2 text-black">NET RATE ($)</div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">TOTAL</div>
                  <div className="col-span-1 ml-5 py-2"></div>
                </div>
              )}

              {/* Column propertyItems*/}
              {!localItem.collapsed && (
                <div key={uuidv4()} className={`grid grid-cols-12 text-xs border-b border-slate-200`}>
                  <div className="col-span-1 px-2 border-r border-slate-200 py-2">Activities & Extras</div>
                  <div className="col-span-2 ml-5 border-r border-slate-200 py-2">{item.supplier.name}</div>
                  <div className="col-span-4">
                    <div className={`grid grid-cols-12 text-xs`}>
                      <div className="col-span-11 ml-5 border-r border-slate-200 py-2 pr-5">{item.displayName}</div>
                      <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group"></div>
                      {/* <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center">{propertyItem.star}</div> */}
                    </div>
                  </div>
                  {/* <div className="col-span-5 ml-5 border-r border-slate-200 py-2">{propertyItem.description}</div> */}
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2">1</div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{item.activityUnits}</div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2">${formatPrice(item.costPerUnit)}</div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2"> ${formatPrice(item.cost)}</div>
                  <div className="col-span-1 ml-5 py-2 flex items-center"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* found items final */}
      {foundItemsDeparture.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`bg-[rgba(42,153,163,0.1)] border border-slate-200 brand-text-grey-v2 mb-7 
                  ${customFinalCut && customFinalCut.uuid === foundItem.uuid ? 'animate-pulse' : ''}`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <FinalFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalCut={customFinalCut}
            handlePaste={handlePaste}
            addItem={addItem}
            handleCut={handleCut}
            deleteItem={deleteItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            setCustomFinalCut={setCustomFinalCut}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            handleOpenSlideOut={handleOpenSlideOut}
            setCustomItemToEdit={setCustomItemToEdit}
            setAddFinalItemDetails={setAddFinalItemDetails}
            formatPrice={formatPrice}
            firstDay={firstDay}
            setFinalSlideOutDate={setFinalSlideOutDate}
          />
        </div>
      ))}
    </>
  );
};

export default FinalActivity;
