import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ButtonPrimarySmall from './Buttons/ButtonPrimarySmall';

function ExcelCostingModal({ isOpen, closeModal, maxVersion, onGenerate }) {
  const [selectedOption, setSelectedOption] = useState('latest');
  const [customVersions, setCustomVersions] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      setSelectedOption('latest');
      setCustomVersions('');
      setError('');
    }
  }, [isOpen]);

  const validateInput = (input) => {
    if (!input) {
      setError('');
      return true;
    }
    const versions = input.split(',').map(v => v.trim());
    const isValid = versions.every(v => {
      return /^\d+$/.test(v) && parseInt(v, 10) > 0 && parseInt(v, 10) <= maxVersion;
    });
    setError(isValid ? '' : `Please enter valid version numbers between 1 and ${maxVersion}`);
    return isValid;
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setError('');
  };

  const handleCustomInputChange = (e) => {
    const input = e.target.value;
    setCustomVersions(input);
    validateInput(input);
  };

  const handleGenerate = () => {
    let versions = [];
    if (selectedOption === 'latest') {
      versions = [maxVersion];
    } else if (selectedOption === 'all') {
      versions = Array.from({length: maxVersion}, (_, i) => i + 1);
    } else if (selectedOption === 'custom') {
      if (!validateInput(customVersions)) return;
      versions = customVersions.split(',').map(v => parseInt(v.trim(), 10));
    }
    onGenerate(versions);
    closeModal();
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Generate Excel Costing
                    </Dialog.Title>
                    <div className="mt-4">
                      <div className="flex space-x-2">
                        {['latest', 'all', 'custom'].map((option) => (
                          <button
                            key={option}
                            type="button"
                            className={`flex-1 py-2 px-4 rounded-md text-sm font-medium ${
                              selectedOption === option
                                ? 'bg-indigo-600 text-white'
                                : 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50'
                            }`}
                            onClick={() => handleOptionClick(option)}
                          >
                            {option === 'latest' ? 'Latest Version' : option === 'all' ? 'All Versions' : 'Custom'}
                          </button>
                        ))}
                      </div>
                      {selectedOption === 'custom' && (
                        <div className="mt-4">
                          <label htmlFor="versions" className="block text-sm font-medium text-gray-700">
                            Enter version numbers (comma-separated)
                          </label>
                          <input
                            type="text"
                            name="versions"
                            id="versions"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="e.g., 1,2,5"
                            value={customVersions}
                            onChange={handleCustomInputChange}
                          />
                          {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                        </div>
                      )}
                      <p className="mt-2 text-sm text-gray-500">Available versions: 1-{maxVersion}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <ButtonPrimarySmall
                    text="Generate"
                    onClick={handleGenerate}
                    className="w-full sm:w-auto sm:ml-3"
                    disabled={selectedOption === 'custom' && !!error}
                    color='dark'
                  />
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ExcelCostingModal;