import React from 'react';
import NomadCampHeaderSection from './NomadCampHeaderSection';
import ImageGallery from './ImageGallery';
import TabSection from './TabSection';

function PropertyContent({ propertyData }) {
  const { camp, campDataFirestore, tripAdvisorData } = propertyData;

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-7xl bg-white rounded-md my-4 mx-auto">
      <NomadCampHeaderSection camp={camp} firestoreData={campDataFirestore} tripAdvisorData={tripAdvisorData} />
      <ImageGallery camp={camp} firestoreData={campDataFirestore} />
      <div className="mt-12">
        <TabSection camp={camp} firestoreData={campDataFirestore} tripAdvisorData={tripAdvisorData} />
      </div>
    </div>
  );
}

export default PropertyContent;