//import {getLabelForFinalValue} from '../functions/finalPageFunctions';

import { FaEdit, FaPlusCircle, FaSave, FaTrashAlt, FaWindowClose } from 'react-icons/fa';
import { FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { TbArrowsRandom, TbBuildingWarehouse } from 'react-icons/tb';
import { feeTypeOptions, getLabelForFinalValues, getShortenedLabelForValue, iconMap } from '../functions/finalPageFunctions';

import { MdOutlineDiscount } from "react-icons/md";
//import { GiJeep } from 'react-icons/gi';
import Select from 'react-select';
import { getSupplierName } from '../functions/generalFunctions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

//import { cancelFinalInputDataEdit } from '../functions/finalPageFunctions';

const FinalDiscount = ({
  foundItem,
  customFinalCut,
  handlePaste,
  addItem,
  handleCut,
  deleteItem,
  customFinalItems,
  setCustomFinalItems,
  setCustomFinalCut,
  searchId,
  pickupDropoff,
  handleOpenSlideOut,
  setCustomItemToEdit,
  setAddFinalItemDetails,
  formatPrice,
  firstDay,
  setFinalSlideOutDate,
  vip,
  arrivalDeparture,
}) => {
  // USER
  const { user } = useSelector((state) => state.auth);
  const { allSuppliers } = useSelector((state) => state.suppliers);
  var suppliersShort = allSuppliers.result;
  let tempSuppliers = JSON.parse(JSON.stringify(suppliersShort));
  const [suppliers, setSuppliers] = useState(tempSuppliers);

  // function getLabelForFinalValue(value) {
  //   switch (value) {
  //     case 'accommodation':
  //       return 'Accomm';
  //     case 'parkFees':
  //       return 'Park Fees';
  //     case 'discount':
  //       return 'Discount';
  //     case 'extraMeals':
  //       return 'Extra Meals';
  //     case 'extra':
  //       return 'Extra';
  //     case 'misc':
  //       return 'Misc';
  //     default:
  //       return value;
  //   }
  // }

  const toggleCollapse = (uuid) => {
    setCustomFinalItems((items) => items.map((item) => (item.uuid === uuid ? { ...item, collapsed: !item.collapsed } : item)));
  };

  const toggleAdd = (uuid) => {
    resetFinalInputs(setFinalInputs);
    setCustomFinalItems((prevItems) =>
      prevItems.map((item) => {
        // Reset all 'edit' properties in fields for every item
        const updatedFields = item.fields.map((field) => ({ ...field, edit: false }));

        if (item.uuid === uuid) {
          // For the item to toggle, toggle its 'add' and use the updatedFields
          return { ...item, add: !item.add, fields: updatedFields };
        } else {
          // For all other items, set 'add' to false and also use the updatedFields
          return { ...item, add: false, fields: updatedFields };
        }
      }),
    );
  };

  const defaultFinalInputs = {
    feeTypeUid: '',
    feeTypeName: '',
    supplier: '',
    description: '',
    units: 0,
    pax: 0,
    net: 0,
    //total: 0,
    // uuid: uuidv4(),
    // edit: true,
    // star: true,
  };

  const [finalInputs, setFinalInputs] = useState(defaultFinalInputs);

  const resetFinalInputs = () => {
    setFinalInputs(defaultFinalInputs);
  };

  const editFinalInputItem = (uuid, fieldUuid) => {
    setCustomFinalItems((prevItems) =>
      prevItems.map((item) => {
        if (item.uuid === uuid) {
          // This is the targeted item, now update its fields
          const updatedFields = item.fields.map((field) => ({
            ...field,
            // Toggle 'edit' for the targeted field, reset 'edit' for others
            edit: field.uuid === fieldUuid ? !field.edit : false,
          }));

          return {
            ...item,
            add: false, // Reset 'add' property for the targeted item
            fields: updatedFields,
          };
        } else {
          // For all other items, reset 'add' to false and keep their fields unchanged
          return {
            ...item,
            add: false, // Optionally, reset 'add' for all items when one is edited
            fields: item.fields.map((field) => ({
              ...field,
              edit: false, // Reset 'edit' for fields in all other items
            })),
          };
        }
      }),
    );
  };

  // useEffect to set finalInputs after customFinalItems is updated
  useEffect(() => {
    const fieldToEdit = customFinalItems.flatMap((item) => item.fields).find((field) => field.edit);
    if (fieldToEdit) {
      setFinalInputs({
        feeTypeUid: fieldToEdit.feeTypeUid,
        feeTypeName: fieldToEdit.feeTypeName,
        supplier: fieldToEdit.supplierUid,
        description: fieldToEdit.description,
        units: fieldToEdit.units,
        pax: fieldToEdit.pax,
        net: fieldToEdit.net,
      });
    }
  }, [customFinalItems]);

  const cancelFinalInputDataEdit = (fieldUuid) => {
    setCustomFinalItems((prevItems) =>
      prevItems.map((item) => {
        // Map through fields array and update the 'edit' property of the matched item
        const newFields = item.fields.map((field) => (field.uuid === fieldUuid ? { ...field, edit: !field.edit } : field));
        //setFinalInputs(newFields);
        return { ...item, fields: newFields };
      }),
    );
  };

  const deleteFinalInputItem = (uuid, fieldUuid) => {
    setCustomFinalItems((prevItems) =>
      prevItems.map((item) => {
        // Check if this is the targeted item
        if (item.uuid === uuid) {
          // Filter out the field with the matching fieldUuid
          const newFields = item.fields.filter((field) => field.uuid !== fieldUuid);
          return { ...item, fields: newFields };
        }
        return item; // Return other items unmodified
      }),
    );
  };

  const saveFinalInputData = (uuid) => {
    console.log(JSON.stringify(finalInputs, null, 2));

    const supplier = finalInputs.supplier === '' || finalInputs.value === '' || finalInputs.label === '' ? foundItem.fields[0].supplierUid : finalInputs.supplier;

    if (finalInputs.feeTypeUid === '' || supplier === '' || finalInputs.description === '') {
      toast.error('Fee type, Supplier and Description are required');
      return;
    }

    const newField = {
      feeTypeUid: finalInputs.feeTypeUid,
      feeTypeName: finalInputs.feeTypeName,
      supplierUid: supplier,
      supplierName: getSupplierName(supplier, suppliers),
      description: finalInputs.description,
      units: finalInputs.units,
      pax: finalInputs.pax,
      net: finalInputs.net,
      total: finalInputs.units * finalInputs.pax * finalInputs.net,
      uuid: uuidv4(),
      edit: false,
      star: true,
      date: foundItem.fields[0].date,
      parentUuid: foundItem.uuid,
    };

    setCustomFinalItems((prevItems) => prevItems.map((item) => (item.uuid === uuid ? { ...item, fields: [...item.fields, newField] } : item)));

    setFinalInputs(defaultFinalInputs);
  };

  const saveFinalInputDataEdit = (uuid, fieldUuid) => {
    console.log(JSON.stringify(finalInputs, null, 2));
    const supplier = finalInputs.supplier === '' ? foundItem.fields[0].supplierUid : finalInputs.supplier;

    if (finalInputs.feeTypeUid === '' || supplier === '' || finalInputs.description === '') {
      toast.error('Fee type, Supplier and Description are required');
      return;
    }

    const newField = {
      feeTypeUid: finalInputs.feeTypeUid,
      feeTypeName: finalInputs.feeTypeName,
      supplierUid: supplier,
      supplierName: getSupplierName(supplier, suppliers),
      description: finalInputs.description,
      units: finalInputs.units,
      pax: finalInputs.pax,
      net: finalInputs.net,
      total: finalInputs.units * finalInputs.pax * finalInputs.net,
      uuid: fieldUuid, // Use the existing UUID to replace the field
      edit: false,
      star: true,
      date: foundItem.fields[0].date,
      parentUuid: foundItem.uuid,
    };

    setCustomFinalItems((prevItems) =>
      prevItems.map((item) => {
        if (item.uuid === uuid) {
          // Find the field to update
          const updatedFields = item.fields.map((field) => (field.uuid === fieldUuid ? newField : field));
          return { ...item, fields: updatedFields };
        }
        return item;
      }),
    );

    setFinalInputs(defaultFinalInputs); // Reset finalInputs to its default state
  };

  const moveItemUp = (uuid, fieldUuid) => {
    setCustomFinalItems((prevItems) =>
      prevItems.map((item) => {
        if (item.uuid === uuid) {
          const fieldIndex = item.fields.findIndex((field) => field.uuid === fieldUuid);

          if (fieldIndex > 1) {
            const newFields = [...item.fields];
            [newFields[fieldIndex - 1], newFields[fieldIndex]] = [newFields[fieldIndex], newFields[fieldIndex - 1]];
            return { ...item, fields: newFields };
          }
        }
        return item;
      }),
    );
  };

  const moveItemDown = (uuid, fieldUuid) => {
    setCustomFinalItems((prevItems) =>
      prevItems.map((item) => {
        if (item.uuid === uuid) {
          const fieldIndex = item.fields.findIndex((field) => field.uuid === fieldUuid);

          if (fieldIndex >= 0 && fieldIndex < item.fields.length - 1) {
            const newFields = [...item.fields];
            [newFields[fieldIndex + 1], newFields[fieldIndex]] = [newFields[fieldIndex], newFields[fieldIndex + 1]];
            return { ...item, fields: newFields };
          }
        }
        return item;
      }),
    );
  };

  const FeeTypeIcon = ({ feeTypeUid }) => {
    return iconMap[feeTypeUid] || <div>Unknown Type</div>; // Render a default or nothing if the type is unknown
  };

  // DAY
  const firstDayMoment = moment(firstDay, 'YYYY-MM-DD');
  const dayMoment = moment(foundItem.fields[0].date, 'YYYY-MM-DD');

  let day = 1;
  if (foundItem.objType === 'arrival') {
    day = firstDay === foundItem.fields[0].date ? 1 : dayMoment.diff(firstDayMoment, 'days');
  } else {
    day = firstDay === foundItem.fields[0].date ? 1 : dayMoment.diff(firstDayMoment, 'days') + 1;
  }

  console.log('day found', day);
  console.log('firstDay', firstDay);
  console.log('item.startDay', foundItem.fields[0].date);

  // useEffect(() => {
  //   if (finalInputs.supplier === '') {
  //     setFinalInputs({
  //       ...finalInputs,
  //       supplier: foundItem.fields[0].supplierUid,
  //     });
  //   }
  // }, []); //

  let parentDate = foundItem.fields[0].date;

  return (
    <div>
      {/* ROW 1 (Property info) */}
      <div className={`mb-0`}>
        <div className={`grid grid-cols-12`}>
          {/* IMG */}
          {/* ROOM NAME + DESCRIPTION */}
          <div className="col-span-12">
            {/* Number of days */}
            <div
              className="text-right fixed top-4 right-0"
              style={{
                WebkitTransform: 'translateZ(0)',
                perspective: '1000' /* Safari */,
              }}
            ></div>
            {/* Property name */}
            <div className="grid grid-cols-12 border border-slate-200 bg-green-50">
              <div className="text-xs font-medium truncate text-ellipsis overflow-hidden col-span-10 ml-2 border-r border-slate-200 py-2">
                <div className="flex items-center">
                  {/* {foundItem.fields[0].feeTypeUid === 'Property' && <TbBuildingWarehouse size={30} />}
                  {foundItem.fields[0].feeTypeUid === 'Flight' && <MdFlight size={30} />}
                  {foundItem.fields[0].feeTypeUid === 'Road Transfer' && <GiJeep size={30} />}
                  {foundItem.fields[0].feeTypeUid === 'Other' && <TbArrowsRandom size={30} />} */}
                  {/* ICON AND CIRCLE */}
                  <div className="row-span-1 relative">
                    <div className="ml-0 mr-2 object-cover w-10 h-10 rounded-full bg-green-200 flex justify-center items-center">
                      {foundItem.discount ? <MdOutlineDiscount size={30} className="text-green-700" /> : <FeeTypeIcon feeTypeUid={foundItem.fields[0].feeTypeUid} />}
                    </div>
                  </div>
                  {/* <FeeTypeIcon feeTypeUid={foundItem.fields[0].feeTypeUid} /> */}

                  <div className="ml-2 flex flex-col">
                    <span className="flex ">
                      <span className="text-base w-14">{moment(foundItem.fields[0].date).format('MMM D')}</span>
                      <span className="text-base font-semibold ml-5 text-black w-[55rem] truncate">
                        {foundItem.fields[0].description} {foundItem.fields[0].additionalInfo ? ` - ${foundItem.fields[0].additionalInfo}` : ''}
                      </span>
                    </span>

                    <span className="flex ">
                      <span className="w-14">{moment(foundItem.fields[0].date).format('ddd')}</span>
                      <span className="ml-5">
                        {foundItem.fields[0].supplierName} | {moment(foundItem.fields[0].date).format('Do MMM YYYY')}
                      </span>
                    </span>
                  </div>
                  {/* <span className="ml-2 flex">{foundItem.fields[0].feeType}</span> */}
                </div>
              </div>
              <div className="col-span-1 text-xs  border-r border-slate-200 py-2 text-nomadBlue-500 flex items-center justify-center">
                ${formatPrice(foundItem.fields.reduce((sum, field) => sum + field.total, 0))}
              </div>

              <div className="grid grid-cols-12 items-center justify-center">
                <div className={`${user.internalUser ? 'col-span-12 py-2' : 'col-span-12 pt-2 pb-4'} flex justify-center items-center px-2 `}>
                  {!foundItem.collapsed ? (
                    <IoIosArrowUp size={20} className="cursor-pointer text-nomadEvergreen-700" onClick={() => toggleCollapse(foundItem.uuid)} />
                  ) : (
                    <IoIosArrowDown size={20} className=" cursor-pointer text-nomadEvergreen-700" onClick={() => toggleCollapse(foundItem.uuid)} />
                  )}
                </div>
              </div>
            </div>

            {/* Column headers*/}
            {/* TODO Add description column*/}

            {!foundItem.collapsed && (
              <div className={`grid grid-cols-12 text-xs font-medium border-b border-slate-200 bg-green-50`}>
                <div className="col-span-1 px-2 border-x border-slate-200 py-2 text-black">FEE TYPE</div>
                <div className="col-span-2 ml-5 border-r border-slate-200 py-2 text-black">SUPPLIER</div>
                <div className="col-span-4">
                  <div className={`grid grid-cols-12 text-xs`}>
                    <div className="col-span-11 ml-5 border-r border-slate-200 py-2 text-black">DESCRIPTION</div>
                    <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center"></div>
                  </div>
                </div>
                <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">
                  <span className="block">NTS/UNITS</span>
                </div>

                <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">
                  <span className="block">PAX/UNITS</span>
                </div>
                <div className="col-span-1 ml-4 border-r border-slate-200 py-2 text-black">NET RATE ($)</div>
                <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">TOTAL</div>
                <div className="col-span-1 ml-5 py-2 border-r"></div>
              </div>
            )}

            {/* Column propertyItems*/}
            {/* Column propertyItems*/}
            {!foundItem.collapsed &&
              foundItem.fields.map((field, index) =>
                !field.edit ? (
                  <div key={field.uuid} className={`grid grid-cols-12 text-xs border-b border-slate-200 bg-green-50`}>
                    <div className="col-span-1 px-2 border-x border-slate-200 py-2">{getShortenedLabelForValue(field.feeTypeUid)}</div>
                    <div className="col-span-2 ml-5 border-r border-slate-200 py-2">{field.supplierName}</div>
                    <div className="col-span-4">
                      <div className={`grid grid-cols-12 text-xs h-full`}>
                        <div className="col-span-11 ml-5 py-2 pr-5 w-[23rem] truncate">
                          {field.description} {field.additionalInfo ? ` - ${field.additionalInfo}` : ''}
                        </div>
                        <div className="col-span-1 flex justify-center py-2 border-x border-slate-200">
                          {' '}
                          <svg viewBox="0 0 24 24" className={`w-6 h-6 ${field.star ? 'text-nomadEvergreen-700' : 'text-gray-300'}`}>
                            <path d="M12 2l2.4 7.2h7.6l-6.2 4.6l2.4 7.2L12 16.4l-6.2 4.6l2.4-7.2L2 9.2h7.6L12 2z" className="star-path fill-current"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{field.units}</div>
                    <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{field.pax}</div>
                    <div className="col-span-1 ml-5 border-r border-slate-200 py-2">${formatPrice(field.net)}</div>
                    <div className="col-span-1 ml-5 border-r border-slate-200 py-2">${formatPrice(field.total)}</div>
                    {user.internalUser && (
                      <div className="col-span-1 flex items-center border-r">
                        {' '}
                        {field.uuid !== foundItem.fields[0].uuid && (
                          <div className="grid grid-cols-12 items-center justify-center">
                            <div className="col-span-4 flex justify-center items-center px-2 py-2">
                              <FaEdit size={20} className="text-nomadEvergreen-700 cursor-pointer" onClick={() => editFinalInputItem(foundItem.uuid, field.uuid)} />
                            </div>

                            <div className="col-span-4 flex justify-center items-center px-2 py-2">
                              <FaTrashAlt size={18} className="text-red-700 cursor-pointer" onClick={() => deleteFinalInputItem(foundItem.uuid, field.uuid)} />
                            </div>
                            <div className="col-span-4 flex flex-col justify-center items-center px-2 py-1">
                              <IoIosArrowUp size={18} className="cursor-pointer mb-1" onClick={() => moveItemUp(foundItem.uuid, field.uuid)} />
                              <IoIosArrowDown size={18} className="cursor-pointer" onClick={() => moveItemDown(foundItem.uuid, field.uuid)} />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : user.internalUser ? (
                  <div key={uuidv4()}>
                    <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                      <div className="col-span-1 px-3  border-r border-slate-200 py-2">
                        <Select
                          value={
                            finalInputs.feeTypeUid
                              ? {
                                  label: getLabelForFinalValues(finalInputs.feeTypeUid),
                                  value: finalInputs.feeTypeUid,
                                }
                              : null
                          }
                          onChange={(selectedOption) =>
                            setFinalInputs({
                              ...finalInputs,
                              feeTypeUid: selectedOption.value,
                              feeTypeName: selectedOption.label,
                            })
                          }
                          options={feeTypeOptions}
                          menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '5rem',
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }), // Set the z-index for the dropdown menu
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: '10rem', // width for the dropdown menu
                            }),
                            option: (provided) => ({
                              ...provided,
                              fontSize: '12px', // equivalent to text-xs in Tailwind
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              fontSize: '12px', // equivalent to text-xs in Tailwind
                            }),
                          }}
                          placeholder="Select"
                          components={{
                            DropdownIndicator: null, // This line removes the dropdown arrow
                          }}
                        />
                      </div>
                      <div className="col-span-2 px-5  border-r border-slate-200 py-2">
                        <Select
                          value={
                            finalInputs.supplier
                              ? {
                                  label: getSupplierName(finalInputs.supplier, suppliers),
                                  value: finalInputs.supplier,
                                }
                              : {
                                  label: getSupplierName(foundItem.fields[0].supplier, suppliers),
                                  value: foundItem.fields[0].supplier,
                                }
                          }
                          onChange={(selectedOption) => {
                            setFinalInputs({
                              ...finalInputs,
                              supplier: selectedOption.value,
                              //star: selectedOption.value !== item.supplier ? true : finalInputs.star,
                            });
                          }}
                          options={suppliers
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((supplier) => ({
                              value: supplier.uid,
                              label: supplier.name,
                            }))}
                          menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '10.5rem',
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }), // Set the z-index for the dropdown menu
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: '20rem', // width for the dropdown menu
                            }),
                            option: (provided) => ({
                              ...provided,
                              fontSize: '12px', // equivalent to text-xs in Tailwind
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              fontSize: '12px', // equivalent to text-xs in Tailwind
                            }),
                          }}
                          placeholder="Select"
                          components={{
                            DropdownIndicator: null, // This line removes the dropdown arrow
                          }}
                        />
                      </div>
                      <div className="col-span-4">
                        <div className={`grid grid-cols-12 text-xs`}>
                          <div className="col-span-11 ml-5 border-r border-slate-200 py-2 pr-5">
                            {' '}
                            <input
                              type="text"
                              maxLength="255"
                              defaultValue={finalInputs.description}
                              //onBlur={(e) => setFinalInputs({ ...finalInputs, description: e.target.value })}
                              onBlur={(e) =>
                                setFinalInputs({
                                  ...finalInputs,
                                  //description: e.target.value,
                                  description: e.target.value,
                                })
                              }
                              className="text-xs w-full"
                            />
                          </div>
                          <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group">
                            <input
                              type="checkbox"
                              id="starCheckbox"
                              className="hidden"
                              //checked={finalInputs.star}
                              onClick={() => {
                                if (finalInputs.supplier !== field.supplier) {
                                  toast.error(`Supplier must be '${getSupplierName(field.supplier, suppliers)}' to be unstarred`);
                                  return;
                                }
                                const newStarValue = !finalInputs.star;
                                setFinalInputs({
                                  ...finalInputs,
                                  star: newStarValue,
                                });
                                console.log(newStarValue);
                              }}
                            />
                            <label htmlFor="starCheckbox" className="cursor-pointer">
                              <svg viewBox="0 0 24 24" className={`w-6 h-6 text-nomadEvergreen-700`}>
                                <path d="M12 2l2.4 7.2h7.6l-6.2 4.6l2.4 7.2L12 16.4l-6.2 4.6l2.4-7.2L2 9.2h7.6L12 2z" className="star-path fill-current"></path>
                              </svg>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                        <input
                          type="number"
                          min="0"
                          defaultValue={finalInputs.units}
                          onBlur={(e) => {
                            let value = Math.floor(Number(e.target.value));
                            value = value < 0 ? 0 : value; // check if value is less than 0
                            setFinalInputs({
                              ...finalInputs,
                              units: value,
                            });
                          }}
                          className="w-full text-xs inputPax"
                          style={{
                            appearance: 'textfield',
                          }} // add this
                        />
                      </div>
                      <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                        <input
                          type="number"
                          min="0"
                          defaultValue={finalInputs.pax}
                          onBlur={(e) => {
                            let value = Math.floor(Number(e.target.value));
                            value = value < 0 ? 0 : value; // check if value is less than 0
                            setFinalInputs({
                              ...finalInputs,
                              pax: value,
                            });
                          }}
                          className="w-full text-xs inputPax"
                          style={{
                            appearance: 'textfield',
                          }} // add this
                        />
                      </div>
                      <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                        <input
                          type="number"
                          //min="0"
                          onInput={(e) => {
                            const value = e.target.value;
                            if (!/^-?\d*\.?\d{0,2}$/.test(value)) {
                              e.target.value = value.slice(0, -1);
                            }
                          }}
                          defaultValue={finalInputs.net}
                          onBlur={(e) => {
                            //let value = Math.floor(Number(e.target.value));
                            let value = Number(Number(e.target.value).toFixed(2));
                            //value = value < 0 ? 0 : value; // check if value is less than 0
                            setFinalInputs({
                              ...finalInputs,
                              net: value,
                            });
                          }}
                          className="w-full text-xs inputPax"
                          style={{
                            appearance: 'textfield',
                          }} // add this
                        />
                      </div>
                      <div className="col-span-1 ml-5 border-r border-slate-200 py-2 flex items-center">${finalInputs.units * finalInputs.pax * finalInputs.net}</div>

                      <div className="col-span-1 ml-5 py-2 flex items-center"></div>
                    </div>
                    <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                      <div className="col-span-4 ml-5 py-2">
                        <div className="flex items-center cursor-pointer">
                          <div className="flex items-center cursor-pointer" onClick={() => saveFinalInputDataEdit(foundItem.uuid, field.uuid)}>
                            <FaSave size={18} className="text-nomadEvergreen-700" />
                            <span className="ml-2">Save row</span>
                          </div>
                          <div className="flex items-center cursor-pointer ml-5" onClick={() => cancelFinalInputDataEdit(field.uuid)}>
                            <FaWindowClose size={18} className="text-nomadEvergreen-700" />
                            <span className="ml-2">Cancel</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 ml-5 py-2"></div>
                      <div className="col-span-2 ml-5 py-2"></div>
                      <div className="col-span-2 ml-5 py-2"></div>
                      <div className="col-span-2 ml-5 py-2"></div>
                    </div>{' '}
                  </div>
                ) : null,
              )}
          </div>
        </div>
      </div>

      {/* Add new item */}
      {!foundItem.collapsed && foundItem.add && user.internalUser && (
        <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
          <div className="col-span-1 px-3  border-r border-slate-200 py-2">
            <Select
              value={
                finalInputs.feeTypeUid
                  ? {
                      label: getLabelForFinalValues(finalInputs.feeTypeUid),
                      value: finalInputs.feeTypeUid,
                    }
                  : null
              }
              onChange={(selectedOption) =>
                setFinalInputs({
                  ...finalInputs,
                  feeTypeUid: selectedOption.value,
                  feeTypeName: selectedOption.label,
                })
              }
              options={feeTypeOptions}
              menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '5rem',
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }), // Set the z-index for the dropdown menu
                }),
                menu: (provided) => ({
                  ...provided,
                  width: '10rem', // width for the dropdown menu
                }),
                option: (provided) => ({
                  ...provided,
                  fontSize: '12px', // equivalent to text-xs in Tailwind
                }),
                singleValue: (provided) => ({
                  ...provided,
                  fontSize: '12px', // equivalent to text-xs in Tailwind
                }),
              }}
              placeholder="Select"
              components={{
                DropdownIndicator: null, // This line removes the dropdown arrow
              }}
            />
          </div>
          <div className="col-span-2 px-5  border-r border-slate-200 py-2">
            <Select
              value={
                finalInputs.supplier
                  ? {
                      label: getSupplierName(finalInputs.supplier, suppliers),
                      value: finalInputs.supplier,
                    }
                  : {
                      label: getSupplierName(foundItem.fields[0].supplierUid, suppliers),
                      value: foundItem.fields[0].supplierUid,
                    }
              }
              onChange={(selectedOption) => {
                setFinalInputs({
                  ...finalInputs,
                  supplier: selectedOption.value,
                  //star: selectedOption.value !== foundItem.fields[0].supplier ? true : finalInputs.star,
                });
              }}
              options={suppliers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((supplier) => ({
                  value: supplier.uid,
                  label: supplier.name,
                }))}
              menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '10.5rem',
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }), // Set the z-index for the dropdown menu
                }),
                menu: (provided) => ({
                  ...provided,
                  width: '20rem', // width for the dropdown menu
                }),
                option: (provided) => ({
                  ...provided,
                  fontSize: '12px', // equivalent to text-xs in Tailwind
                }),
                singleValue: (provided) => ({
                  ...provided,
                  fontSize: '12px', // equivalent to text-xs in Tailwind
                }),
              }}
              placeholder="Select"
              components={{
                DropdownIndicator: null, // This line removes the dropdown arrow
              }}
            />
          </div>
          <div className="col-span-4">
            <div className={`grid grid-cols-12 text-xs`}>
              <div className="col-span-11 ml-5 border-r border-slate-200 py-2 flex pr-5">
                {' '}
                <input
                  type="text"
                  maxLength="255"
                  defaultValue={finalInputs.description}
                  //onBlur={(e) => setFinalInputs({ ...finalInputs, description: e.target.value })}
                  onBlur={(e) =>
                    setFinalInputs({
                      ...finalInputs,
                      //description: e.target.value.replace(/[^\w\s]/g, ''),
                      description: e.target.value,
                    })
                  }
                  className="text-xs w-full"
                />
              </div>
              <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group">
                <input
                  type="checkbox"
                  id="starCheckbox"
                  className="hidden"
                  //checked={finalInputs.star}
                  onClick={() => {
                    if (finalInputs.supplier !== foundItem.fields[0].supplier) {
                      toast.error(`Custom items must be starred`);
                      return;
                    }
                    const newStarValue = !finalInputs.star;
                    setFinalInputs({
                      ...finalInputs,
                      star: newStarValue,
                    });
                    console.log(newStarValue);
                  }}
                />
                <label htmlFor="starCheckbox" className="cursor-pointer">
                  <svg viewBox="0 0 24 24" className={`w-6 h-6 text-nomadEvergreen-700`}>
                    <path d="M12 2l2.4 7.2h7.6l-6.2 4.6l2.4 7.2L12 16.4l-6.2 4.6l2.4-7.2L2 9.2h7.6L12 2z" className="star-path fill-current"></path>
                  </svg>
                </label>
              </div>
            </div>
          </div>

          <div className="col-span-1 px-5 border-r border-slate-200 py-2">
            <input
              type="number"
              min="0"
              defaultValue={finalInputs.units}
              onBlur={(e) => {
                let value = Math.floor(Number(e.target.value));
                value = value < 0 ? 0 : value; // check if value is less than 0
                setFinalInputs({
                  ...finalInputs,
                  units: value,
                });
              }}
              className="w-full text-xs inputPax"
              style={{
                appearance: 'textfield',
              }} // add this
            />
          </div>
          <div className="col-span-1 px-5 border-r border-slate-200 py-2">
            <input
              type="number"
              min="0"
              defaultValue={finalInputs.pax}
              onBlur={(e) => {
                let value = Math.floor(Number(e.target.value));
                value = value < 0 ? 0 : value; // check if value is less than 0
                setFinalInputs({
                  ...finalInputs,
                  pax: value,
                });
              }}
              className="w-full text-xs inputPax"
              style={{
                appearance: 'textfield',
              }} // add this
            />
          </div>
          <div className="col-span-1 px-5 border-r border-slate-200 py-2">
            <input
              type="number"
              //min="0"
              onInput={(e) => {
                const value = e.target.value;
                if (!/^-?\d*\.?\d{0,2}$/.test(value)) {
                  e.target.value = value.slice(0, -1);
                }
              }}
              defaultValue={finalInputs.net}
              onBlur={(e) => {
                //let value = Math.floor(Number(e.target.value));
                let value = Number(Number(e.target.value).toFixed(2));
                //value = value < 0 ? 0 : value; // check if value is less than 0
                setFinalInputs({
                  ...finalInputs,
                  net: value,
                });
              }}
              className="w-full text-xs inputPax"
              style={{
                appearance: 'textfield',
              }} // add this
            />
          </div>
          <div className="col-span-1 ml-5 border-r border-slate-200 py-2 flex items-center">${finalInputs.units * finalInputs.pax * finalInputs.net}</div>

          <div className="col-span-1 ml-5 py-2 flex items-center"></div>
        </div>
      )}
    </div>
  );
};

export default FinalDiscount;
