import React, { useEffect, useState } from "react";
import { collection, getDocs, limit, orderBy, query, startAfter } from "firebase/firestore";

import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Spinner from "../../components/Spinner";
import { db } from "../../db/firebase.config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Users() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  //const { users, isLoading } = useSelector((state) => state.users);
  const [fetch, setFetch] = useState(null);
  const [fetchName] = useState("users");
  const [isLoading, setIsLoading] = useState(true);
  const [lastFetched, setLastFetched] = useState(null);

  const navigate = useNavigate();

  // DB CALL
  useEffect(() => {
    // Get fetch
    const getFetch = async () => {
      try {
        //Get reference
        const fetchRef = collection(db, fetchName);

        //Create query
        //const q = query(usersRef, where("active", "==", true), orderBy("created", "desc"), limit(10));
        const q = query(fetchRef, orderBy("created", "asc"), limit(10));

        //Execute query
        const querySnapshot = await getDocs(q);

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastFetched(lastVisible);

        const fetch = [];

        querySnapshot.forEach((doc) => {
          return fetch.push({
            uid: doc.id,
            data: {
              ...doc.data(),
              created: doc.data().created === undefined || doc.data().created === null ? null : doc.data().created.toDate().toDateString(),
              updated: doc.data().updated === undefined || doc.data().updated === null ? null : doc.data().updated.toDate().toDateString(),
            },
          });
        });
        console.log(fetch);
        setFetch(fetch);
        setIsLoading(false);
      } catch (error) {
        console.log(error);

        toast.error("Could not fetch " + fetchName);
      }
    };

    getFetch();
  }, [fetchName]);

  // DB CALL
  const more = async () => {
    try {
      setIsLoading(true);
      //Get reference
      const fetchRef = collection(db, fetchName);

      //Create query
      //const q = query(usersRef, where("active", "==", true), orderBy("created", "desc"), limit(10));
      const q = query(fetchRef, orderBy("created", "asc"), startAfter(lastFetched), limit(10));

      //Execute query
      const querySnapshot = await getDocs(q);

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastFetched(lastVisible);

      const fetch = [];

      querySnapshot.forEach((doc) => {
        return fetch.push({
          uid: doc.id,
          data: {
            ...doc.data(),
            created: doc.data().created === undefined || doc.data().created === null ? null : doc.data().created.toDate().toDateString(),
            updated: doc.data().updated === undefined || doc.data().updated === null ? null : doc.data().updated.toDate().toDateString(),
          },
        });
      });
      console.log(fetch);
      setFetch((prevState) => [...prevState, ...fetch]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Could not fetch " + fetchName);
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
        {/*  Site header */}
        {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl brand-text-color-800 font-bold">Users</h1>
              </div>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="border-t brand-border-color-200">
                {fetch.length > 0 ? (
                  <>
                    <table className="brand-bg-color-100 brand-text-color-900 w-full text-left shadow-none">
                      <thead>
                        <tr>
                          <th className="brand-bg-color-700 text-white p-2 uppercase">Name</th>
                          <th className="brand-bg-color-700 text-white p-2 uppercase">Email</th>
                          <th className="brand-bg-color-700 text-white p-2 uppercase">Created</th>
                          <th className="brand-bg-color-700 text-white p-2 uppercase">Active</th>
                          <th className="brand-bg-color-700 text-white p-2 uppercase">Admin</th>
                          <th className="brand-bg-color-700 text-white p-2 w-auto uppercase">-</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fetch.map((fetch) => (
                          <tr key={fetch.uid} className="brand-odd-color-dark-100 brand-even-color-dark-200 text-black">
                            <td className="p-2">{fetch.data.firstName + " " + fetch.data.lastName}</td>
                            <td className="p-2">{fetch.data.email}</td>
                            <td className="p-2">{fetch.data.created}</td>
                            <td className="p-2">{fetch.data.active ? "true" : "false"}</td>
                            <td className="p-2">{fetch.data.isAdmin ? "true" : "false"}</td>
                            <td className="p-2">
                              <button
                                onClick={() => navigate("/" + fetchName + "/edit-user/" + fetch.uid)}
                                className="btn-xs brand-btn-color brand-btn-color-hover brand-btn-color-text"
                              >
                                EDIT
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>No {fetchName}</p>
                )}
              </div>
            )}
          </div>
          {/* Panel footer */}
          <footer>
            <div className="flex flex-col px-6 py-5 border-t brand-border-color-200">
              {lastFetched && (
                <div className="flex self-center">
                  <button onClick={more} className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3">
                    More
                  </button>
                </div>
              )}
            </div>
          </footer>
        </main>
      </div>
    </div>
  );
}

export default Users;
