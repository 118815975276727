import React from 'react';
import { CiCalendar } from 'react-icons/ci';
import moment from 'moment';

const DateRangeComp = ({ bookings }) => {
  // const formatDate = (date) => {
  //   return new Date(date).toLocaleDateString('en-US', {
  //     weekday: 'short',
  //     month: 'short',
  //     day: 'numeric',
  //     year: 'numeric',
  //   });
  // };
  const formatDate = (date) => {
    return moment(date).format('ddd, MMM D, YYYY');
  };

  return (
    <div className="flex text-left mt-5">
      <CiCalendar size={20} />
      <div className="ml-2 text-zinc-800 text-sm font-normal">
        <span>
          {bookings.length > 1
            ? `${formatDate(bookings[0].startDay)} - ${formatDate(bookings[bookings.length - 1].endDay)}`
            : `${formatDate(bookings[0].startDay)} - ${formatDate(bookings[0].endDay)}`}
        </span>
      </div>
    </div>
  );
};

export default DateRangeComp;
