import React from 'react';
import ReadMoreButton from '../../components/ReadMoreButton';
import DocumentDownloadsBox from '../../components/DocumentDownloadsBox';
import { urlFor } from '../../../../db/sanity';
import { FaCheckCircle } from 'react-icons/fa';

function TravelInfoTab({ tabData }) {
  const { sectionsContent, documentDownloads } = tabData;

  return (
    <div className="mt-8">
      {/* SECTION - DESCRIPTION AND OVERVIEW BOX */}
      <div className="grid grid-cols-1 md:grid-cols-10 gap-8">
        <div className="md:col-span-7 text-base font-sans">
          <div className="text-4xl font-roca text-nomadBlue-800 mb-6">{tabData.tabTitle}</div>
          {sectionsContent && sectionsContent[0] ? (
            <ReadMoreButton text={sectionsContent[0].text} maxRows={5} buttonText="Show" />
          ) : (
            <p>No information available.</p>
          )}
        </div>
        {documentDownloads && documentDownloads.length > 0 && (
        <div className="md:col-start-8 md:col-span-3">
          <DocumentDownloadsBox documents={documentDownloads} />
        </div>
        )}
      </div>

      {/* SECTION - CONTENT TYPES */}
      <div className="mt-12">
        {sectionsContent && sectionsContent.slice(1).map((section, index) => (
          <div key={index} className={`grid grid-cols-1 md:grid-cols-2 gap-8 mb-12 ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}`}>
            <div className={`relative min-h-[400px] ${index % 2 === 0 ? 'md:order-1' : 'md:order-2'}`}>
              <img
                src={section.image ? urlFor(section.image).width(800).url() : 'https://via.placeholder.com/400x400?text=No+Image'}
                alt={section.title}
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
            <div className={`flex flex-col justify-center font-sans ${index % 2 === 0 ? 'md:order-2' : 'md:order-1'}`}>
              <div className="text-4xl font-roca text-nomadBlue mb-6">{section.title}</div>
              <ReadMoreButton text={section.text} maxRows={5} buttonText="Show" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TravelInfoTab;