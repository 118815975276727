import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function MapComponent({ lat, long }) {
  const mapContainerStyle = {
    height: '300px', // Ensure the container has a defined height
    width: '100%',
  };

  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(long),
  };

  const mapOptions = {
    streetViewControl: false,
  };

  return (
<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} loadingElement={<div style={{ height: '100%' }} />}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        options={mapOptions}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
}

export default MapComponent;