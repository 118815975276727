import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import countriesService from './countriesService';

const initialState = {
  allCountries: [],
  countriesError: false,
  countriesSuccess: false,
  countriesLoading: false,
  countriesMessage: '',
};

// Get Airports
export const getCountries = createAsyncThunk('countries/getCountries', async (country, thunkAPI) => {
  try {
    return await countriesService.getCountries(country);
  } catch (error) {
    console.log(error);
    const message = 'No countries';
    return thunkAPI.rejectWithValue(message);
  }
});

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    reset: (state) => {
      state.countriesLoading = false;
      state.countriesError = false;
      state.countriesSuccess = false;
      state.countriesMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.countriesLoading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countriesLoading = false;
        state.countriesSuccess = true;
        state.allCountries = action.payload.jsonResult;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.countriesLoading = false;
        state.countriesError = true;
        state.countriesMessage = action.payload;
      });
  },
});

export const { reset } = countriesSlice.actions;
export default countriesSlice.reducer;
