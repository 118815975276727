import { findCustomLocation, getAirportCodeFromUid } from './transferFunctions';
import { loadAirlineDetails, loadCustomFlights, loadFlightRoute } from './loadDataFunctions';
import React, {  useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';
import { formatPrice } from './generalFunctions';
import moment from 'moment';


const findProperty = (properties, uid) => {
  return properties.find((property) => property.uid === uid);
};

const getFlightLocation = async (properties, propId, endDay, transfer, customLocations, airports) => {
  const property = findProperty(properties, propId);

  if (!property || !property.active) {
    return false;
  }

  if (!property.mobileLocation) {
    console.log('property.airport.code ', property.airport.code);
    return property.airport.code;
  } else {
    //const result = await findCustomLocation(propId, endDay);
    console.log('no mobileLocation');
    const result = customLocations.find((location) => location.propUid === propId && location.uuid === transfer.uuid);
    console.log('result ', result);
    console.log('propId ', propId);
    console.log('transfer.uuid ', transfer.uuid);
    return getAirportCodeFromUid(airports, result.airportId, airports);
  }
};

const getAvailability = async (properties, transfer, nextTransfer, customLocations, originCode, destinationCode, airports) => {
  const firstPropIdID = transfer.propUid;
  const secondPropIdID = nextTransfer ? nextTransfer.propUid : null;
  // const endDay = transfer.endDay;
  // console.log("endDay ", endDay);
  // const startDay = transfer.startDay;
  // console.log("startDay ", startDay);

  let startDay = transfer.startDay;
  let endDay = transfer.endDay;

  // // Check if startDay is an object, then convert to date
  // if (typeof startDay === "object" && startDay !== null && "seconds" in startDay) {
  //   startDay = moment.unix(startDay.seconds).format("ddd MMM D YYYY HH:mm:ss [GMT]Z [(]ZZ[)]");
  // }

  // // Check if endDay is an object, then convert to date
  // if (typeof endDay === "object" && endDay !== null && "seconds" in endDay) {
  //   endDay = moment.unix(endDay.seconds).format("ddd MMM D YYYY HH:mm:ss [`Total]Z [(]ZZ[)]");
  // }

  // Check if startDay is an object, then convert to date
  if (typeof startDay === 'object' && startDay !== null && 'seconds' in startDay) {
    let date = moment.unix(startDay.seconds);
    startDay = date.format('ddd MMM D YYYY HH:mm:ss [GMT]Z [(]ZZ[)]').utc();
  }

  // Check if endDay is an object, then convert to date
  if (typeof endDay === 'object' && endDay !== null && 'seconds' in endDay) {
    let date = moment.unix(endDay.seconds);
    endDay = date.format('ddd MMM D YYYY HH:mm:ss [GMT]Z [(]ZZ[)]').utc();
  }

  let flightOrigin;
  if (!originCode) {
    flightOrigin = await getFlightLocation(properties, firstPropIdID, endDay, transfer, customLocations, airports);
  } else {
    flightOrigin = originCode;
  }

  let flightDestination;
  if (!destinationCode) {
    flightDestination = await getFlightLocation(properties, secondPropIdID, endDay, nextTransfer, customLocations, airports);
  } else {
    flightDestination = destinationCode;
  }

  // console.log("flightOrigin ", flightOrigin);
  // const flightDestination = await getFlightLocation(properties, secondPropIdID, endDay, transfer);

  //console.log("secondPropIdID ", secondPropIdID);
  console.log('flightDestination ', flightDestination);
  const formattedDateStart = moment(transfer.objType === 'arrivalTransfer' ? startDay : endDay).format('YYYY/MM/DD');

  const formattedDateEnd = moment(transfer.objType === 'arrivalTransfer' ? startDay : endDay)
    .add(1, 'days')
    .format('YYYY/MM/DD');

  console.log('formattedDateStart ', formattedDateStart);
  console.log('formattedDateEnd ', formattedDateEnd);
  if (flightDestination === false || flightOrigin === false) {
    return false;
  } else {
    const url = '/api/aero';

    var data = JSON.stringify({
      method: 'GET',
      path: '/getAvailability',
      data: {
        from: flightOrigin,
        to: flightDestination,
        start: formattedDateStart,
        end: formattedDateStart,
      },
    });

    console.log('data ', data);
    console.log(JSON.stringify(JSON.parse(data), undefined, 4));

    var config = {
      method: 'post',
      url: url,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      data: data,
    };
    console.log('config.data ', config);
    console.log(JSON.stringify(config, undefined, 4));

    try {
      const response = await axios(config);
      console.log('response.status: ' + response.status);
      console.log(JSON.stringify(response.data, undefined, 4));

      if (response.status === 200) {
        console.log('IT WORKED 1');

        // sort the flights by time
        if (response.data.aerocrs.flights.count > 0) {
          const sortedFlights = response.data.aerocrs.flights.flight.sort((a, b) => new Date(a.STD) - new Date(b.STD));

          const sortedResponseData = {
            aerocrs: {
              ...response.data.aerocrs,
              flights: {
                ...response.data.aerocrs.flights,
                flight: sortedFlights,
              },
            },
          };

          return sortedResponseData;
        } else {
          return response.data;
        }
      } else {
        console.log('No flights');
        return false;
      }
    } catch (error) {
      console.log("IT DIDN'T WORK");
      return Promise.reject(error);
    }
  }
};

const getDeepLink = async (properties, transfer, nextTransfer, customLocations, originCode, destinationCode, airports, adults, children, infants, merge, createEdit, createVersion, airlines) => {

  console.log('getDeepLink all variables');
  console.log('properties ', properties, transfer, nextTransfer, customLocations, originCode, destinationCode, airports, adults, children, infants, merge);
  console.log('passengers ', adults, children, infants);
  console.log('destinationCode ', destinationCode);
  const firstPropIdID = transfer.propUid;
  const secondPropIdID = nextTransfer ? nextTransfer.propUid : null;

  let startDay = transfer.startDay;
  let endDay = transfer.endDay;

  // Check if startDay is an object, then convert to date
  if (typeof startDay === 'object' && startDay !== null && 'seconds' in startDay) {
    let date = moment.unix(startDay.seconds);
    startDay = date.format('ddd MMM D YYYY HH:mm:ss [GMT]Z [(]ZZ[)]').utc();
  }

  // Check if endDay is an object, then convert to date
  if (typeof endDay === 'object' && endDay !== null && 'seconds' in endDay) {
    let date = moment.unix(endDay.seconds);
    endDay = date.format('ddd MMM D YYYY HH:mm:ss [GMT]Z [(]ZZ[)]').utc();
  }

  let flightOrigin;
  if (!originCode) {
    flightOrigin = await getFlightLocation(properties, firstPropIdID, endDay, transfer, customLocations, airports);
  } else {
    flightOrigin = originCode;
  }

  let flightDestination;
  if (!destinationCode) {
    flightDestination = await getFlightLocation(properties, secondPropIdID, endDay, nextTransfer, customLocations, airports);
  } else {
    flightDestination = destinationCode;
  }

  console.log('flightDestination ', flightDestination);
  console.log('startDay ', startDay);
  console.log('endDay ', endDay);
  const formattedDateOriginal = moment(transfer.objType === 'arrival' ? startDay : endDay).format('YYYY/MM/DD');
  console.log('formattedDateOriginal ', formattedDateOriginal);

  // ! check that date is more than 365 days away
  const formattedDate = adjustDate(formattedDateOriginal);

  let dateAdjusted = false;
  if (formattedDate !== formattedDateOriginal) {
    dateAdjusted = true;
  }
  //const formattedDate = moment(transfer.objType === 'arrival' ? startDay : endDay).format('YYYY/MM/DD');
  // const formattedDateEnd = moment(transfer.objType === "arrivalTransfer" ? startDay : endDay).format("YYYY/MM/DD")
  //   .add(1, "days")
  //   .format("YYYY/MM/DD");

  console.log('formattedDate ', formattedDate);
  //console.log("formattedDateEnd ", formattedDateEnd);
  if (flightDestination === false || flightDestination === '' || flightOrigin === false || flightOrigin === '') {
    return false;
  } else {
    const url = '/api/aero';

    var data = JSON.stringify({
      method: 'GET',
      path: '/getDeepLink',
      // data: {
      //   adults: adults,
      //   child: children,
      //   infant: infants,
      //   from: flightOrigin,
      //   to: flightDestination,
      //   start: formattedDate,
      //   //end: formattedDateStart,
      // },
      data: {
        adults: 1,
        child: 1,
        infant: 1,
        from: flightOrigin,
        to: flightDestination,
        start: formattedDate,
        //end: formattedDateStart,
      },
    });

    console.log('data ', data);
    console.log(JSON.stringify(JSON.parse(data), undefined, 4));

    var config = {
      method: 'post',
      url: url,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      data: data,
    };
    console.log('config.data ', config);
    console.log(JSON.stringify(config, undefined, 4));

    try {
      let response = await axios(config);
      console.log('response.status: ' + response.status);
      console.log(JSON.stringify(response.data, undefined, 4));

      console.log('flightOrigin: ' + flightOrigin);
      console.log('flightDestination: ' + flightDestination);
      //console.log(JSON.stringify(response.data, undefined, 4));

      // Get flightRoute
      let airportOrigin, airportDestination;
      if (flightOrigin) {
        airportOrigin = airports.find((ap) => ap.aerocrsCodes && ap.aerocrsCodes.includes(flightOrigin));

        //airportOrigin = airports.find((ap) => ap.code === flightOrigin);
      }

      //const airportOrigin = airports.find((ap) => ap.code === flightOrigin);
      // const airportOrigin = airports.find((ap) =>
      // 	ap.aerocrsCodes.includes(flightOrigin)
      // );
      // const airportOrigin = airports.find((ap) => {
      // 	//console.log("Checking aerocrsCodes:", ap.aerocrsCodes);
      // 	return ap.aerocrsCodes && ap.code.includes(flightOrigin);
      // });

      console.log('airportOrigin check');
      console.log(JSON.stringify(airportOrigin, undefined, 4));
      if (flightDestination) {
        airportDestination = airports.find((ap) => ap.aerocrsCodes && ap.aerocrsCodes.includes(flightDestination));
        // airportDestination = airports.find((ap) => ap.code === flightDestination);
      }
      // const airportDestination = airports.find(
      // 	(ap) => ap.code === flightDestination
      // );
      // const airportDestination = airports.find((ap) =>
      // 	ap.aerocrsCodes.includes(flightDestination)
      // );
      // const airportDestination = airports.find((ap) => {
      // 	//console.log("Checking aerocrsCodes:", ap.aerocrsCodes);
      // 	return ap.aerocrsCodes && ap.aerocrsCodes.includes(flightDestination);
      // });

      console.log('airportDestination check');
      console.log(JSON.stringify(airportDestination, undefined, 4));

      // const flightRoute = await loadFlightRoute(
      // 	airportOrigin.uid,
      // 	airportDestination.uid
      // );
      let flightRoute = null;

      console.log('airportOrigin WED');
      console.log(JSON.stringify(airportOrigin, undefined, 4));
      console.log('airportDestination ');
      console.log(JSON.stringify(airportDestination, undefined, 4));

      if (airportOrigin && airportDestination) {
        try {
          flightRoute = await loadFlightRoute(airportOrigin.uid, airportDestination.uid);
          console.log('flightRoute WED');
          console.log(JSON.stringify(flightRoute, undefined, 4));
        } catch (error) {
          console.error('Error calling loadFlightRoute:', error);
        }
      } else {
        console.log('Either airportOrigin or airportDestination is not defined.');
      }
      //console.log("flightRoute.uid ", flightRoute.uid);

      if (response.status === 200) {
        console.log('IT WORKED 1');

        // convert dates correctly
        // const flights = response.data.aerocrs.flights.flight;
        // flights.forEach((flight) => {
        //   flight.STD = moment(flight.STD, "YYYY/MM/DD HH:mm:ss.SSS").format("YYYY/MM/DD HH:mm");
        //   flight.STA = moment(flight.STA, "YYYY/MM/DD HH:mm:ss.SSS").format("YYYY/MM/DD HH:mm");
        //   flight.STDinUTC = moment(flight.STD, "YYYY/MM/DD HH:mm:ss.SSS").format("YYYY/MM/DD HH:mm");
        //   flight.STAinUTC = moment(flight.STA, "YYYY/MM/DD HH:mm:ss.SSS").format("YYYY/MM/DD HH:mm");
        // });
        // console.log(JSON.stringify(response.data, undefined, 4));

        // const flights = response.data.aerocrs.flights.flight;
        // flights.count > 0 &&
        // 	flights.forEach((flight) => {
        // 		flight.STD = moment(flight.STD, "YYYY/MM/DD HH:mm:ss.SSS").format(
        // 			"YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        // 		);
        // 		flight.STA = moment(flight.STA, "YYYY/MM/DD HH:mm:ss.SSS").format(
        // 			"YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        // 		);
        // 	});
        // console.log(JSON.stringify(response.data, undefined, 4));

        // sort the flights by time

        console.log('response.data.aerocrs.flights.count ', response.data.aerocrs.flights.count);

        if (response.data.aerocrs.flights.count >= 0) {
          response.data.aerocrs.flights.merge = merge;
          response.data.aerocrs.flights.dateAdjusted = dateAdjusted;
          const availableDay = moment(formattedDate).format('ddd').toLowerCase();
          //console.log("flightRoute.customFlight ", flightRoute.customFlight);
          console.log('check 1');
          console.log('availableDay ', availableDay);
          console.log('flightRoute ');
          console.log(JSON.stringify(flightRoute, undefined, 4));

          if (flightRoute && flightRoute.customFlight !== null && flightRoute.customFlight && flightRoute.uid) {
            console.log('CUSTOM FLIGHT CHECK 2');
            console.log('check 2');
            console.log('flightRoute.uid ', flightRoute.uid);

            const customFlights = await loadCustomFlights(flightRoute.uid, availableDay, formattedDate, createEdit, createVersion);

            if (customFlights.length > 0) {
              console.log('check 3');
              if (response.data.aerocrs.flights.flight === 'No Results') {
                response.data.aerocrs.flights.flight = customFlights;
                response.data.aerocrs.flights.count = customFlights.length;
                response.data.aerocrs.flights.dateAdjusted = dateAdjusted;
              } else {
                response.data.aerocrs.flights.flight = [...response.data.aerocrs.flights.flight, ...customFlights];
                response.data.aerocrs.flights.count = response.data.aerocrs.flights.count + customFlights.length;
                response.data.aerocrs.flights.dateAdjusted = dateAdjusted;
                console.log(JSON.stringify(response.data.aerocrs.flights.flight, undefined, 4));
              }
              console.log('Added custom flights');
              console.log(JSON.stringify(customFlights, undefined, 4));
              console.log('response.data.aerocrs.flights.flight');
              console.log(JSON.stringify(response, undefined, 4));
            } else {
              console.log('No custom flights');

              const noResultsResponse = {
                aerocrs: {
                  success: true,
                  flights: {
                    count: 0,
                    flight: 'No Results',
                  },
                },
              };

              //return noResultsResponse; // was causing issues with the no results message missing real flights
            }

            // const sortedFlights = response.data.aerocrs.flights.flight.sort((a, b) => new Date(a.STD) - new Date(b.STD));

            // console.log('sortedFlights', sortedFlights);

            // console.log('AFTER AERO DATES');

            // const sortedResponseData = {
            //   aerocrs: {
            //     ...response.data.aerocrs,
            //     flights: {
            //       ...response.data.aerocrs.flights,
            //       flight: sortedFlights,
            //     },
            //   },
            // };
            // console.log('sortedResponseData  1');
            // console.log(JSON.stringify(sortedResponseData, undefined, 4));
            // return sortedResponseData;
            if (response.data.aerocrs.flights.count > 0) {
              const sortedFlights = response.data.aerocrs.flights.flight.sort((a, b) => new Date(a.STD) - new Date(b.STD));

              console.log('sortedFlights', sortedFlights);

              console.log('AFTER AERO DATES');

              const sortedResponseData = {
                aerocrs: {
                  ...response.data.aerocrs,
                  flights: {
                    ...response.data.aerocrs.flights,
                    flight: sortedFlights,
                  },
                },
              };
              console.log('sortedResponseData  1');
              console.log(JSON.stringify(sortedResponseData, undefined, 4));
              return sortedResponseData;
            } else {
              return response.data;
            }
          } else if (response && response.data && response.data.aerocrs && response.data.aerocrs.flights && response.data.aerocrs.flights.count && response.data.aerocrs.flights.count > 0) {
            console.log('check 4');
            const sortedFlights = response.data.aerocrs.flights.flight.sort((a, b) => new Date(a.STD) - new Date(b.STD));

            console.log('AFTER AERO DATES');

            const sortedResponseData = {
              aerocrs: {
                ...response.data.aerocrs,
                flights: {
                  ...response.data.aerocrs.flights,
                  flight: sortedFlights,
                },
              },
            };
            console.log('sortedResponseData  2');
            console.log(JSON.stringify(sortedResponseData, undefined, 4));
            return sortedResponseData;
          } else {
            console.log('check 5');
            const noResultsResponse = {
              aerocrs: {
                success: true,
                flights: {
                  count: 0,
                  flight: 'No Results',
                  dateAdjusted: dateAdjusted,
                },
              },
            };

            return noResultsResponse;
          }
        }
      } else {
        console.log('CUSTOM FLIGHT CHECK 2');
        const availableDay = moment(formattedDate).format('ddd').toLowerCase();

        console.log('availableDay ', availableDay);
        console.log('flightRoute.uid ', flightRoute.uid);

        if (flightRoute.customFlight && flightRoute.uid) {
          const customFlights = await loadCustomFlights(flightRoute.uid, availableDay, formattedDate, createEdit, createVersion);

          if (customFlights.length > 0) {
            const responseData = {
              aerocrs: {
                success: true,
                flights: {
                  count: customFlights.length,
                  flight: customFlights,
                  dateAdjusted: dateAdjusted,
                  //uid: customFlights.uid,
                },
              },
            };
            const sortedFlights = responseData.aerocrs.flights.flight.sort((a, b) => new Date(a.STD) - new Date(b.STD));

            console.log('AFTER AERO DATES');

            const sortedResponseData = {
              aerocrs: {
                ...responseData.aerocrs,
                flights: {
                  ...responseData.aerocrs.flights,
                  flight: sortedFlights,
                },
              },
            };
            console.log('Added custom flights');
            return sortedResponseData;
          } else {
            console.log('No flights');
            return false;
          }
        }
      }
    } catch (error) {
      console.log("IT DIDN'T WORK");
      return Promise.reject(error);
    }
  }
};

const loadFlightAvailability = async (startDay, endDay) => {
  console.log('loadFlightAvailability');
  // const flightOrigin = await getFlightLocation(properties, firstPropIdID, endDay);
  // console.log("flightOrigin ", flightOrigin);
  // const flightDestination = await getFlightLocation(properties, secondPropIdID, endDay);
  // console.log("secondPropIdID ", secondPropIdID);
  // console.log("flightDestination ", flightDestination);
  const formattedDateStart = moment(endDay).format('YYYY/MM/DD');
  const formattedDateEnd = moment(endDay).add(1, 'days').format('YYYY/MM/DD');
  // console.log("formattedDateStart ", formattedDateStart);
  // console.log("formattedDateEnd ", formattedDateEnd);
  // if (flightDestination === false || flightOrigin === false) {
  //   return false;
  // } else {
  const url = '/api/aero';

  var data = JSON.stringify({
    method: 'GET',
    path: '/getAvailability',
    data: {
      start: formattedDateStart,
      end: formattedDateEnd,
    },
  });

  var config = {
    method: 'post',
    url: url,
    headers: { accept: 'application/json', 'content-type': 'application/json' },
    data: data,
  };

  try {
    const response = await axios(config);
    console.log('response.status: ' + response.status);
    console.log(JSON.stringify(response.data, undefined, 4));

    if (response.status === 200) {
      console.log('IT WORKED 1');
      console.log(response && response.data ? response.data : false);
      return response.data;
    } else {
      console.log('No flights');
      return false;
    }
  } catch (error) {
    console.log("IT DIDN'T WORK");
    return Promise.reject(error);
  }
  //}
};

const getFares = async () => {
  console.log('getFares');
  const url = '/api/aero';

  var data = JSON.stringify({
    method: 'POST',
    path: '/getFares',
    data: {
      dates: {
        start: '2023/11/09',
        end: '2023/11/09',
      },
      destinations: {
        from: 'SEU',
        to: 'LKY',
      },
    },
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios(config);
    console.log('response.status: ' + response.status);
    console.log(JSON.stringify(response.data, undefined, 4));

    if (response.status === 200) {
      console.log('IT WORKED');
    }
  } catch (error) {
    console.log("IT DIDN'T WORK");
    return Promise.reject(error);
  }
};
// If the difference is more than 365, go back that many days plus 2 from the input date.

function adjustDate(inputDate) {


  console.log('Adjusting date:', inputDate);
  const date = moment(inputDate, 'YYYY/MM/DD');
  const now = moment();
  const maxFutureDate = now.clone().add(360, 'days');
  const daysDifference = date.diff(now, 'days');

  console.log('Original date:', date.format('YYYY/MM/DD'), 'Day:', date.format('dddd'));
  console.log('Days difference:', daysDifference);
  console.log('Max future date:', maxFutureDate.format('YYYY/MM/DD'));

  if (daysDifference > 360) {
    console.log('Date is more than 360 days in the future, adjusting...');

    // Set the year to the current year
    let adjustedDate = date.clone().year(now.year());

    // If the adjusted date is in the past, set it to next year
    if (adjustedDate.isBefore(now)) {
      adjustedDate.add(1, 'year');
    }

    // Ensure the date is at least 7 days in the future
    const minDate = now.clone().add(7, 'days');
    if (adjustedDate.isBefore(minDate)) {
      adjustedDate = minDate.clone();
    }

    // If the adjusted date is beyond 360 days, subtract 11 months and 2 weeks
    if (adjustedDate.isAfter(maxFutureDate)) {
      adjustedDate.subtract(11, 'months').subtract(2, 'weeks');
    }

    // Adjust to the same day of the week as the original date
    while (adjustedDate.day() !== date.day()) {
      adjustedDate.add(1, 'day');
    }

    // If we've gone beyond 360 days, subtract weeks until we're in the correct range
    while (adjustedDate.isAfter(maxFutureDate)) {
      adjustedDate.subtract(1, 'week');
    }

    // If we've gone into the past or too close to the present, add weeks until we're in the correct range
    while (adjustedDate.isBefore(now.clone().add(7, 'days'))) {
      adjustedDate.add(1, 'week');
    }

    console.log('Final adjusted date:', adjustedDate.format('YYYY/MM/DD'), 'Day:', adjustedDate.format('dddd'));
    return adjustedDate.format('YYYY/MM/DD');
  }

  console.log('No adjustment needed, returning original date');
  return date.format('YYYY/MM/DD');
}

// Updated code:
function calculateFlightCost(flightResults, selectedFlightIndex, flightAdults, flightChildren, flightInfants, fare) {
  let selectedFlight = flightResults.flight[selectedFlightIndex];

  // Streamlined fare type check and selection
  let selectedClass = selectedFlight.classes[Object.keys(selectedFlight.classes)[0]][fare];

  // Ensuring valid fare type
  if (!selectedClass) {
    throw new Error(`Invalid fare type: ${fare}`);
  }

  let adultCost = Number(flightAdults) * parseFloat(selectedClass.adultFare);
  let childCost = Number(flightChildren) * parseFloat(selectedClass.childFare);
  let infantCost = Number(flightInfants) * parseFloat(selectedClass.infantFare);

  // Tax computation with validation
  let tax = selectedClass.tax ? parseFloat(selectedClass.tax) * (Number(flightAdults) + Number(flightChildren) + Number(flightInfants)) : 0;

  let totalCost = adultCost + childCost + infantCost + tax;

  // Rounding to two decimal places
  let roundedCost = parseFloat(totalCost.toFixed(2));

  return roundedCost;
}

async function calculateFlightPricing(
  flightResults,
  selectedFlightIndex,
  flightAdults,
  flightChildren,
  flightInfants,
  airlineICAOcode,
  dateAdjusted,
  agentObject,
  selectedAges,
  selectedAgesAdults,
  justPricing,
  classXl,
  transfersData,
  transferGetDate,
) {
  let selectedFlight;
  if (justPricing) {
    selectedFlight = flightResults;
  } else {
    selectedFlight = flightResults.flight[selectedFlightIndex];
  }

  // Function to check if flight rate exists in the version before editing
  // Used to ensure new rates aren't retrieved for existing flight accidently removed
  const findBookedFlight = (selectedFlight, transfersData = []) => {
    for (const transfer of transfersData) {
      if (transfer.formValues?.flightDetails) {
        if (matchFlight(selectedFlight, transfer.formValues.flightDetails)) {
          return transfer.formValues.flightPricing;
        }
        if (transfer.formValues.flightDetailsSecond && matchFlight(selectedFlight, transfer.formValues.flightDetailsSecond)) {
          return transfer.formValues.flightPricingSecond;
        }
      }
    }
    return null; // No match found
  };

  // Logic to determine how to match a flight
  const matchFlight = (selectedFlight, flightDetails) => {
    const isMatch =
      flightDetails.airlineName === selectedFlight.airlineName &&
      flightDetails.fltnum === selectedFlight.fltnum &&
      flightDetails.fromcode === selectedFlight.fromcode &&
      flightDetails.tocode === selectedFlight.tocode &&
      // Added to exclude custom flights
      // !flightDetails.availableDays;
      (!flightDetails.availableDays || selectedFlight.airlineDesignator === 'NOM');
    console.log(`Matching flight: ${isMatch ? 'Yes' : 'No'}`);
    console.log('Selected flight:', selectedFlight);
    console.log('Flight details:', flightDetails);
    return isMatch;
  };

  let CompanyChildAgeEnd = selectedFlight.CompanyChildAgeEnd;
  // MAKE NEW PASSENGERS ------------------------------------- START
  // Initialize passengers as an empty array
  let passengers = [];

  // NEW CODE

  // Assuming selectedAges is empty as you provided

  passengers = [...selectedAges, ...selectedAgesAdults];

  let adults = passengers.filter((passenger) => passenger.age > CompanyChildAgeEnd);
  let children = passengers.filter((passenger) => passenger.age <= CompanyChildAgeEnd);

  flightAdults = adults.length;
  flightChildren = children.length;
  flightInfants = 0; // Assuming there's logic elsewhere to define infants

  console.log('passengers: ' + JSON.stringify(passengers, null, 2));
  console.log('Number of adults: ' + flightAdults); // Should now reflect the correct count

  console.log('passengers: ' + JSON.stringify(passengers, null, 2));
  // MAKE NEW PASSENGERS ------------------------------------- END

  //let customerCommission = 0;
  let flightMarkup = 0;
  console.log('before airlineDetails called');
  console.log('agentObject.directBookingAgent ', agentObject.directBookingAgent);

  let airlineDetails = await loadAirlineDetails(airlineICAOcode);
  if (!agentObject.directBookingAgent) {
    //let airlineDetails = await loadAirlineDetails(airlineICAOcode);
    console.log('airlineICAOcode ', airlineICAOcode);
    console.log('airlineDetails ', airlineDetails);
    //customerCommission = airlineDetails.customerCommission;
    console.log('airlineDetails called');
    flightMarkup = airlineDetails.markup;
  }
  console.log('airline ', airlineICAOcode);

  //console.log('calculateFlightPricing: customerCommission: ', customerCommission);

  //console.log("customerCommission ", customerCommission);

  let adultsTrue = flightAdults;
  let inducement = false;
  //variable of all passengers
  const allPassengers = flightAdults + flightChildren + flightInfants;
  if (allPassengers < selectedFlight.minimumPassengersForBooking) {
    flightAdults += selectedFlight.minimumPassengersForBooking - allPassengers;
    inducement = true;
  }

  console.log('Number of adults 2: ' + flightAdults);
  console.log('selectedFlight.minimumPassengersForBooking: ' + selectedFlight.minimumPassengersForBooking);

  // function calculateFare(baseFare, customerCommission, dateAdjusted) {
  //   let fare = parseFloat((baseFare * (1 - customerCommission / 100)).toFixed(2));
  //   return dateAdjusted ? fare * 1.1 : fare;
  // }

  // Function to calculate the sale fare
  function calculateFare(baseFare, flightMarkup, dateAdjusted, flightDate, airlineDesignator) {
    console.log('--- calculateFare Input ---');
    console.log('baseFare:', baseFare);
    console.log('flightMarkup:', flightMarkup);
    console.log('dateAdjusted:', dateAdjusted);
    console.log('flightDate:', flightDate);

    let fare = parseFloat((baseFare * (1 + flightMarkup / 100)).toFixed(2));
    console.log('Initial fare after markup:', fare);

    if (dateAdjusted && airlineDesignator !== 'NOM') {
      const currentDate = new Date();
      const flightDateTime = new Date(flightDate);
      const yearDifference = flightDateTime.getFullYear() - currentDate.getFullYear();

      console.log('Current date:', currentDate);
      console.log('Flight date:', flightDateTime);
      console.log('Year difference:', yearDifference);

      if (yearDifference >= 1) {
        const adjustmentFactor = Math.pow(1.1, yearDifference);
        console.log('Adjustment factor:', adjustmentFactor);

        fare *= adjustmentFactor;
        console.log('Fare after year adjustment:', fare);
      } else {
        console.log('No year adjustment needed');
      }
    } else {
      console.log('No date adjustment needed');
    }

    const finalFare = parseFloat(fare.toFixed(2));
    console.log('Final fare:', finalFare);

    return finalFare;
  }

  console.log('selectedFlight.classes[Object.keys(selectedFlight.classes)[0]] ', selectedFlight.classes[Object.keys(selectedFlight.classes)[0]]);
  console.log('Object.keys(selectedFlight.classes)[0] ', Object.keys(selectedFlight.classes)[0]);

  //let flightVar = selectedFlight.classes[Object.keys(selectedFlight.classes)[0]];

  // new code
  let selectedFlightClass;
  let flightVar;

  if (classXl && Object.keys(selectedFlight.classes).some((key) => key.includes('XL'))) {
    const xlKey = Object.keys(selectedFlight.classes).find((key) => key.includes('XL'));
    if (xlKey) {
      flightVar = selectedFlight.classes[xlKey];
      selectedFlightClass = xlKey;
    }
  } else if (airlineDetails.defaultFareClass && selectedFlight.classes.hasOwnProperty(airlineDetails.defaultFareClass)) {
    flightVar = selectedFlight.classes[airlineDetails.defaultFareClass];
    selectedFlightClass = airlineDetails.defaultFareClass;
  } else {
    selectedFlightClass = Object.keys(selectedFlight.classes)[0];
    flightVar = selectedFlight.classes[selectedFlightClass];
  }

  console.log('selectedFlightClass ', selectedFlightClass);

  let { adultFare, childFare, infantFare } = agentObject.directBookingAgent ? flightVar.rackFare : flightVar.fare;
  let taxCalc = flightVar.fare.tax;

  // ADDED BY HARRY
  // In your main function:
  const matchedFlightDetails = findBookedFlight(selectedFlight, transfersData);
  //const flightDate = selectedFlight.STA; // ! use transfer date or get date from calculateFlightPricing / flightResults ? console.log and test RP
  const flightDate = transferGetDate.startDay;
  const airlineDesignator = selectedFlight.airlineDesignator;

  if (matchedFlightDetails && matchedFlightDetails?.saleFare) {
    console.log('Matched flight found:', matchedFlightDetails);
    adultFare = matchedFlightDetails.saleFare.adultFare;
    childFare = matchedFlightDetails.saleFare.childFare;
    infantFare = matchedFlightDetails.saleFare.infantFare;
    taxCalc = matchedFlightDetails.saleFare.tax;
  } else {
    console.log('Calculating new fares for flight date:', flightDate);
    adultFare = calculateFare(adultFare, flightMarkup, dateAdjusted, flightDate, airlineDesignator);
    childFare = calculateFare(childFare, flightMarkup, dateAdjusted, flightDate, airlineDesignator);
    infantFare = calculateFare(infantFare, flightMarkup, dateAdjusted, flightDate, airlineDesignator);
  }

  // Calculate total including tax
  const totalBeforeTax = adultFare * flightAdults + childFare * flightChildren + infantFare * flightInfants;
  const totalTax = taxCalc * (flightAdults + flightChildren + flightInfants);
  const totalWithTax = totalBeforeTax + totalTax;

  // New
  const saleFareObject = {
    fare: {
      adultFare: Number(parseFloat(flightVar.fare.adultFare).toFixed(2)),
      childFare: Number(parseFloat(flightVar.fare.childFare).toFixed(2)),
      infantFare: Number(parseFloat(flightVar.fare.infantFare).toFixed(2)),
      tax: Number(parseFloat(flightVar.fare.tax).toFixed(2)),
    },
    rackFare: {
      adultFare: Number(parseFloat(flightVar.rackFare.adultFare).toFixed(2)),
      childFare: Number(parseFloat(flightVar.rackFare.childFare).toFixed(2)),
      infantFare: Number(parseFloat(flightVar.rackFare.infantFare).toFixed(2)),
    },
    saleFare: {
      adultFare: Number(parseFloat(adultFare).toFixed(2)),
      childFare: Number(parseFloat(childFare).toFixed(2)),
      infantFare: Number(parseFloat(infantFare).toFixed(2)),
      tax: Number(parseFloat(taxCalc).toFixed(2)),
      total: Number(parseFloat(totalWithTax).toFixed(2)),
      selectedFlightClass: selectedFlightClass,
      isBookedPrice: !!matchedFlightDetails,
    },
    passengers: {
      adults: flightAdults,
      adultsMin: selectedFlight.minimumPassengersForBooking,
      adultsTrue: adultsTrue,
      children: flightChildren,
      infants: flightInfants,
      inducement: inducement,
    },
  };
  //
  console.log(JSON.stringify(saleFareObject, undefined, 4));
  return saleFareObject;
}

async function processFlights(
  originAirportCodes,
  destinationAirportCodes,
  properties,
  transfer,
  nextTransfer,
  customLocations,
  airports,
  flightAdults,
  flightChildren,
  flightInfants,
  createEdit,
  createVersion,
  airlines,
) {
  console.log('processFlights all variables');
  console.log(
    'originAirportCodes ',
    originAirportCodes,
    'destinationAirportCodes ',
    destinationAirportCodes,
    'properties ',
    properties,
    'transfer ',
    transfer,
    'nextTransfer ',
    nextTransfer,
    'customLocations ',
    customLocations,
    'airports ',
    airports,
    'flightAdults ',
    flightAdults,
    'flightChildren ',
    flightChildren,
    'flightInfants ',
    flightInfants,
  );
  let availableFlightsResults = [];
  const deepLinkPromises = [];

  if (Array.isArray(originAirportCodes) && Array.isArray(destinationAirportCodes)) {
    for (let origin of originAirportCodes) {
      const originAirport = airports.find((airport) => airport.codes.includes(origin));
      const originCodesToSearch = originAirport && originAirport.alternativeAirports ? originAirport.codes : [origin];

      for (let destination of destinationAirportCodes) {
        const destinationAirport = airports.find((airport) => airport.codes.includes(destination));
        const destinationCodesToSearch = destinationAirport && destinationAirport.alternativeAirports ? destinationAirport.codes : [destination];

        for (let originCode of originCodesToSearch) {
          for (let destinationCode of destinationCodesToSearch) {
            const merge = `${originCode}-${destinationCode}`;

            const originAeroCodes = (airports.find((a) => a.codes.includes(originCode)) || {}).aerocrsCodes || [originCode];
            const destinationAeroCodes = (airports.find((a) => a.codes.includes(destinationCode)) || {}).aerocrsCodes || [destinationCode];

            for (let originAeroCode of originAeroCodes) {
              for (let destinationAeroCode of destinationAeroCodes) {
                // Push a function that when invoked returns the promise
                deepLinkPromises.push(async () => {
                  try {
                    const result = await getDeepLink(
                      properties,
                      transfer,
                      false,
                      customLocations,
                      originAeroCode,
                      destinationAeroCode,
                      airports,
                      flightAdults,
                      flightChildren,
                      flightInfants,
                      merge,
                      createEdit,
                      createVersion,
                      airlines,
                    );

                    if (result && result.aerocrs && result.aerocrs.flights) {
                      if (result.aerocrs.flights.count > 0) {
                        result.aerocrs.flights.merge = merge;
                        //result.aerocrs.flights.dateAdjusted = result.aerocrs.flights.dateAdjusted;

                        // NEW MERGE FIX ME TO DO

                        // Check if availableFlightsResults already contains a flight with the same uid
                        // if (!availableFlightsResults.some((flight) => flight.uid === result.aerocrs.flights.uid)) {
                        //   availableFlightsResults.push(result.aerocrs.flights);
                        // }
                        //availableFlightsResults.push(result.aerocrs.flights); // ORIGINAL
                        if (availableFlightsResults.length === 0) {
                          availableFlightsResults.push(result.aerocrs.flights);
                        } else {
                          availableFlightsResults[0].flight = [...availableFlightsResults[0].flight, ...result.aerocrs.flights.flight];
                          availableFlightsResults[0].count = availableFlightsResults[0].count + result.aerocrs.flights.count;
                        }
                      }
                    } else {
                      console.error('Invalid or unexpected result structure:', result);
                    }
                  } catch (error) {
                    console.error('Error calling getDeepLink:', error);
                  }
                });
              }
            }
          }
        }
      }
    }

    // Run all the deepLink promises concurrently
    await Promise.all(deepLinkPromises.map((func) => func()));
  }

  console.log('availableFlightsResults');
  console.log(JSON.stringify(availableFlightsResults, undefined, 4));

  // filter .uid and merge
  let seen_uids = new Set();
  const newResults = [];

  for (const obj of availableFlightsResults) {
    const flight = obj['flight'][0]; // Assuming each "flight" array has only one item as per your example

    if (flight.uid) {
      // Only considering flights with a uid
      if (!seen_uids.has(flight['uid'])) {
        seen_uids.add(flight['uid']);
        newResults.push(obj);
      }
    } else {
      // If there's no uid, add the flight object as it is
      newResults.push(obj);
    }
  }

  // Update count for each object
  const updatedResults = newResults.map((obj) => ({
    ...obj,
    count: obj['flight'].length,
  }));

  return updatedResults;

  //return availableFlightsResults;
}

function processVipB4arrivalDeparture(airports, selectedFlight, transfer, arrivalDeparture) {
  console.log('processVip')
  const airportCode = transfer.objType === 'arrival' || transfer.objType === 'propertyTransfer' ? selectedFlight.tocode : selectedFlight.fromcode
  const airport = airports.find((airport) => airport.code === airportCode);
  console.log('airportCode ', airportCode); 
  console.log('airport ', JSON.stringify(airport, null, 2));
  const transferDate = transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay;

  if (!airport?.airportServices || !Array.isArray(airport.airportServices)) {
    console.log('No airport services found');
    return null;
  }

  const transferMoment = moment(transferDate);
  console.log('Transfer date:', transferMoment.format());

  const airportService = airport.airportServices.find(service => {
    if (!service.active) {
      console.log('Service not active');
      return false;
    }

    const validFrom = moment(service.validFrom);
    const validUntil = moment(service.validUntil);

    console.log('Service dates:', validFrom.format(), 'to', validUntil.format());
    console.log('Is transfer date between?', transferMoment.isBetween(validFrom, validUntil, null, '[]'));

    return transferMoment.isBetween(validFrom, validUntil, null, '[]');
  });

  if (airportService) {
    console.log('Matching airport service found');
    return airportService;
  }

  console.log('No matching airport service found');
  return null;
}

export { getAvailability, loadFlightAvailability, getFares, getDeepLink, calculateFlightCost, calculateFlightPricing, getFlightLocation, processFlights };
