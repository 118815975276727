export function BookedDirectItem({ hit, components, selectedPropertyName, setSelectedPropertyName, setSelectedProperty }) {
  return (
    <div
      className="fixed --aa-base-z-index z-50"
      onClick={(event) => {
        console.log('clicked: ' + hit.name + ' ' + hit.uid);
        setSelectedPropertyName(hit.name);
        setSelectedProperty(hit);
      }}
    >
      <button className="aa-ItemActionButton">
        <div className="aa-ItemContent w-72">
          <div className="aa-ItemTitle text-left">
            <components.Highlight hit={hit} attribute="name" />
          </div>
        </div>
      </button>
    </div>
  );
}
