import React, { useEffect, useState } from 'react';

import { IoIosArrowRoundForward } from 'react-icons/io';
import { formatPrice } from '../functions/generalFunctions';
import moment from 'moment';
import { toast } from 'react-toastify';

const TransferRouteComponent = ({
  transferRouteRates,
  selectedRouteIndex,
  setSelectedRouteIndex,
  showButtonsRoute,
  setShowButtonsRoute,
  transfer,
  //transferUnits,
  setStoPriceCost,
  setNetRackCost,
  setSaleTotal,
  setRateId,
  passengers,
  setTotalUnits,
  setSupplierName,
  setSupplierId,
  setMaxCapacity,
  selectedLocationAlgoliaType,
  handlesOwnAirportTransfer,
  setRateType,
}) => {
  const [localSelectedIndex, setLocalSelectedIndex] = useState(null);
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    if (selectedRouteIndex !== null) {
      setSelectedRouteIndex(selectedRouteIndex);
      console.log('index selectedRouteIndex set');
      //setLocalSelectedIndex(selectedRouteIndex);
    } else if (transfer.formValues && transfer.formValues.rateId && showButtonsRoute) {
      const index = transferRouteRates.findIndex((rate) => rate.id === transfer.formValues.rateId);
      if (index !== -1) {
        setSelectedRouteIndex(index);
        //setLocalSelectedIndex(index);
        setShowButtonsRoute(true);
        setIsChanging(false);
        console.log('index selectedRouteIndex', index);
      } else {
        console.log('index selectedRouteIndex null', index);
        setSelectedRouteIndex(null);
        // setLocalSelectedIndex(null);
      }
    } else {
      console.log('index selectedRouteIndex none');
    }
  }, [selectedRouteIndex, transferRouteRates, transfer.formValues, setSelectedRouteIndex, setShowButtonsRoute]);

  const renderRouteInfo = (route, index) => {
    const isSelected = localSelectedIndex === index;
    const isInitialSelection = selectedRouteIndex === index && !isChanging;

    return (
      <div
        key={index}
        className={`flex-none rounded-lg border shadow-sm h-[131px] p-4 hover:bg-[#D6EEF0] hover:border-[#2A99A3] pointer ${isSelected ? 'border-4 border-[#2A99A3] bg-[#D6EEF0]' : 'bg-white'} ${
          isInitialSelection ? '' : ''
        }`}
        style={{ width: `calc(33.3333% - 0.75rem)` }}
        onClick={() => handleRouteClick(route, index)}
      >
        <div className="text-stone-950 text-xl font-bold">
          {/* ${selectedLocationAlgoliaType === 'airport' && handlesOwnAirportTransfer ? formatPrice(0) : formatPrice(Math.ceil(passengers / route.maxCapacity) * route.stoPrice)} */}$
          {formatPrice(Math.ceil(passengers / route.maxCapacity) * route.stoPrice)}
        </div>
        {/* <div className="text-black text-sm font-normal mt-2 flex">
          <div className="flex items-center">
            <span>{moment.unix(route.validFrom.seconds).format('MMM D, YYYY')}</span>
          </div>
          <span className="text-neutral-400 text-xs font-normal ml-2">to</span>
          <div className="flex items-center ml-2">
            <span>{moment.unix(route.validUntil.seconds).format('MMM D, YYYY')}</span>
          </div>
        </div> */}
        <div className="text-neutral-500 text-sm font-normal">{route.supplier.name}</div>
        {/* <div className="text-neutral-500 text-xs font-normal">Max Capacity: {route.maxCapacity}</div>
        <div className="text-black text-xs font-normal mt-2">Net Cost: ${formatPrice(route.netCost)}</div>
        <div className="text-black text-xs font-normal mt-2">STO Price: ${formatPrice(route.stoPrice)}</div> */}
      </div>
    );
  };

  const handleRouteClick = (route, index) => {
    if (isChanging) {
      setSelectedRouteIndex(index);
      setLocalSelectedIndex(index);
      setShowButtonsRoute(true);
      setIsChanging(false);
      toast.success('Route selection updated');
    } else {
      setLocalSelectedIndex(index);
      setSelectedRouteIndex(index);
      setShowButtonsRoute(true);
      toast.success('Route selected');
    }
    setStoPriceCost(route.stoPrice);
    setNetRackCost(route.netCost);
    setSaleTotal(Math.ceil(passengers / route.maxCapacity) * route.stoPrice);
    setRateId(route.id);
    setTotalUnits(Math.ceil(passengers / route.maxCapacity));
    setSupplierName(route.supplier.name);
    setSupplierId(route.supplier.id);
    setMaxCapacity(route.maxCapacity);
    setRateType(route.type);
  };

  const handleChangeClick = () => {
    setIsChanging(true);
    setShowButtonsRoute(false);
  };

  console.log('transfer.formValues.type', transfer.formValues ? transfer.formValues.type : '');
  console.log('selectedRouteIndex', selectedRouteIndex);
  console.log('showButtonsRoute', showButtonsRoute);
  console.log('isChanging', isChanging);

  const renderSelectedRouteInfo = (route) => {
    return (
      <div className="rounded-lg border border-zinc-300 mt-5 relative">
        <div className="w-full flex h-[108px]">
          <div className="flex justify-center items-center text-stone-950 text-xl font-bold bg-neutral-100 px-4 min-w-[100px]">
            {/* ${selectedLocationAlgoliaType === 'airport' && handlesOwnAirportTransfer ? formatPrice(0) : formatPrice(Math.ceil(passengers / route.maxCapacity) * route.stoPrice)} */}
            {formatPrice(Math.ceil(passengers / route.maxCapacity) * route.stoPrice)}
          </div>
          <div className="flex-1 pl-5 flex flex-col justify-center items-start">
            {/* <div className="text-black text-sm font-normal flex items-center">
              <span>{moment.unix(route.validFrom.seconds).format('MMM D, YYYY')}</span>
              <IoIosArrowRoundForward size={20} className="text-cyan-600 mx-1" />
              <span>{moment.unix(route.validUntil.seconds).format('MMM D, YYYY')}</span>
            </div> */}
            <span className="text-neutral-500 text-sm font-normal">{route.supplier.name}</span>
            {/* <span className="text-neutral-500 text-sm font-normal">Max Capacity: {route.maxCapacity}</span>
            <span className="text-sm font-normal text-black">
              Net Cost: ${formatPrice(route.netCost)} | STO Price: ${formatPrice(route.stoPrice)}
            </span> */}
          </div>
        </div>
        <div className="absolute top-4 right-4 text-cyan-600 text-[13px] font-normal cursor-pointer" onClick={handleChangeClick}>
          Change
        </div>
        <div className="absolute top-0 left-2 w-[82px] h-[19px] bg-green-100 rounded text-center text-stone-600 text-xs font-semibold">Selected</div>
      </div>
    );
  };

  return (
    <div>
      {/* {selectedRouteIndex === null || !showButtonsRoute || isChanging || (transfer.formValues && transfer.formValues.type !== 'safari' && transfer.formValues.type !== 'location') ? ( */}

      {selectedRouteIndex === null || !showButtonsRoute || isChanging ? (
        <div>
          <div className="text-black text-sm font-normal mt-5">{isChanging ? 'Select a supplier:' : `Please select supplier option (${transferRouteRates.length})`}</div>
          <div className="w-full overflow-x-auto -mr-20">
            <div className="flex flex-nowrap mt-2 ml-0 gap-4 cursor-pointer">{transferRouteRates.map((route, index) => renderRouteInfo(route, index))}</div>
          </div>
        </div>
      ) : (
        renderSelectedRouteInfo(transferRouteRates[selectedRouteIndex])
      )}
    </div>
  );
};

export default TransferRouteComponent;
