import { FaEdit, FaPlusCircle, FaSave, FaTrashAlt, FaWindowClose } from 'react-icons/fa';
import { FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { IoIosArrowDown, IoIosArrowDropdownCircle, IoIosArrowDropupCircle, IoIosArrowUp, IoMdWarning } from 'react-icons/io';
import { MdConnectingAirports, MdContentCopy, MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { TbArrowsRandom, TbBuildingWarehouse, TbTransfer, TbTransferIn, TbTransferOut } from 'react-icons/tb';
import { addItem, deleteItem, handleCut, handlePaste } from '../functions/finalCustomFunctions';
import {
  cancelFinalInputDataTransferEdit,
  cancelFinalInputTransferData,
  deleteFinalInputTransferItem,
  editFinalInputTransferItem,
  feeTypeOptions,
  getLabelForFinalTransferValue,
  getShortenedLabelForValue,
  handleAddFinalTransferItem,
  moveTransferItemDown,
  moveTransferItemUp,
  resetFinalInputs,
  saveFinalInputDataTransferEdit,
  saveFinalInputTransferData,
  toggleFinalTransferCollapse,
} from '../functions/finalPageFunctions';
import { formatPrice, getSupplierName } from '../functions/generalFunctions';

import FinalFoundItemCard from './FinalFoundItem';
import { GiJeep } from 'react-icons/gi';
import Select from 'react-select'; // assuming you're using react-select
import { getPropObj } from '../functions/generalFunctions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const FinalTransferItem = ({
  item,
  index,
  suppliers,
  finalTransferInputs,
  setFinalTransferInputs,
  finalPageTransferData,
  setFinalPageTransferData,
  resetFinalTransferInputs,
  resetEditTransferItem,
  setFinalInputs,
  customFinalItems,
  setCustomFinalItems,
  customFinalCut,
  setCustomFinalCut,
  handleOpenSlideOut,
  setCustomItemToEdit,
  setAddFinalItemDetails,
  transfers,
  properties,
  firstDay,
  setFinalSlideOutDate,
  departureButtons,
}) => {
  // USER
  const { user } = useSelector((state) => state.auth);
  // redux airports
  const { allAirports } = useSelector((state) => state.airports);
  var airportsShort = allAirports.result;
  let tempAirports = JSON.parse(JSON.stringify(airportsShort));
  const [airports, setAirports] = useState(tempAirports);

  // custom item
  let searchId = item.tuuid;

  console.log('item transfer', JSON.stringify(item, null, 2));

  const foundItems = customFinalItems.filter((search) => !search.vip && search.id === searchId && !search.departure && (search.pickupDropoff === undefined || search.pickupDropoff === false));
  const foundItemsDeparture = customFinalItems.filter((search) => !search.vip && search.id === searchId && search.departure && (search.pickupDropoff === undefined || search.pickupDropoff === false));

  const pickupDropoff = false;

  // HEADER FOR CARD
  // function getTransferByTuuid(transfers, tuuidToFind) {
  //   // Find the transfer in the transfers array where the tuuid matches tuuidToFind
  //   const foundTransfer = transfers.find((transfer) => transfer.tuuid === tuuidToFind);

  //   // Return the found transfer object or null if not found
  //   return foundTransfer || null;
  // }
  // const transfer = getTransferByTuuid(transfers, item.tuuid);

  function getTransferAndNextIfNotDeparture(transfers, tuuidToFind) {
    // Find the index of the transfer in the transfers array where the tuuid matches tuuidToFind
    const index = transfers.findIndex((transfer) => transfer.tuuid === tuuidToFind);

    // Initialize an object to hold the current and next transfer if applicable
    const result = {
      currentTransfer: null,
      nextTransfer: null,
    };

    // If the transfer was found (index !== -1), set the current transfer
    if (index !== -1) {
      result.currentTransfer = transfers[index];

      // Check if there is a next transfer and if its objType is not 'departure'
      if (index + 1 < transfers.length && transfers[index + 1].objType !== 'departure') {
        result.nextTransfer = transfers[index + 1];
      }
    }

    // Return the result object
    return result;
  }

  // DEAL WITH HEADER
  const transfersResult = getTransferAndNextIfNotDeparture(transfers, item.tuuid);

  const transfer = transfersResult.currentTransfer;
  const nextTransfer = transfersResult.nextTransfer;

  let transferMessage;
  // ARRIVAL
  if (item.objType === 'arrival') {
    if (transfer.formValues?.transferType && (transfer.formValues.transferType === 'townTransfer' || transfer.formValues.transferType === 'safariTransfer')) {
      transferMessage = 'Road transfer from ' + transfer.formValues.locationName + ' to ' + properties[getPropObj(item.propUid, properties)].name;
    } else if (transfer.formValues.type === 'bookFlight') {
      let airportTo;
      let airportFrom;
      if (transfer.formValues.flightDetailsSecond) {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.tocode));
        const airportSecond = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.fromcode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        transferMessage =
          'Flight from ' +
          airportFrom.name +
          ' (' +
          transfer.formValues.flightDetails.fromcode +
          ') to ' +
          airportTo.name +
          ' (' +
          transfer.formValues.flightDetailsSecond.tocode +
          ') - Via ' +
          airportSecond.name +
          ' (' +
          transfer.formValues.flightDetailsSecond.fromcode +
          ')';
      } else {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.tocode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        transferMessage = 'Flight from ' + airportFrom.name + ' (' + transfer.formValues.flightDetails.fromcode + ') to ' + airportTo.name + ' (' + transfer.formValues.flightDetails.tocode + ')';
      }
    } else if (transfer.formValues.type === 'camp') {
      transferMessage = 'Arrival at ' + properties[getPropObj(item.propUid, properties)].name + ' by own arrangements at ' + transfer.formValues.timeOwnArrangements;
    } else {
      transferMessage = `Arrival to ${item.destinationName}`;
    }
    // PROPERTY TRANSFER
  } else if (item.objType === 'propertyTransfer') {
    if (transfer.formValues?.transferType === 'townTransfer' || transfer.formValues?.transferType === 'safariTransfer') {
      transferMessage = 'Road transfer from ' + properties[getPropObj(item.propUid, properties)].name + ' to ' + properties[getPropObj(nextTransfer.propUid, properties)].name;
    } else if (transfer.formValues.type === 'flight') {
      let airportTo;
      let airportFrom;
      if (transfer.formValues.flightDetailsSecond) {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.tocode));
        const airportSecond = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.fromcode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        transferMessage =
          'Flight from ' +
          airportFrom.name +
          ' (' +
          transfer.formValues.flightDetails.fromcode +
          ') to ' +
          airportTo.name +
          ' (' +
          transfer.formValues.flightDetailsSecond.tocode +
          ') - Via ' +
          airportSecond.name +
          ' (' +
          transfer.formValues.flightDetailsSecond.fromcode +
          ')';
      } else {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.tocode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        transferMessage = 'Flight from ' + airportFrom.name + ' (' + transfer.formValues.flightDetails.fromcode + ') to ' + airportTo.name + ' (' + transfer.formValues.flightDetails.tocode + ')';
      }
    } else if (transfer.formValues.type === 'ownArrangements') {
      if (transfer.formValues.airportTransfer) {
        const airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.destinationAirportCode));
        const airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.originAirportCode));
        transferMessage =
          'Flight from ' + airportFrom.name + ' (' + transfer.formValues.originAirportCode + ') to ' + airportTo.name + ' (' + transfer.formValues.destinationAirportCode + ') - Own Arrangement';
      } else {
        transferMessage = 'Transfer from ' + properties[getPropObj(item.propUid, properties)].name + ' to ' + properties[getPropObj(nextTransfer.propUid, properties)].name + ' - Own Arrangement';
      }
    } else {
      transferMessage = `Transfer | ${item.originName} to ${item.destinationName}`;
    }
    // DEPARTURE
  } else if (item.objType === 'departure') {
    if (transfer.formValues?.transferType && (transfer.formValues.transferType === 'townTransfer' || transfer.formValues.transferType === 'safariTransfer')) {
      transferMessage = 'Road transfer from ' + properties[getPropObj(item.propUid, properties)].name + ' to ' + transfer.formValues.locationName;
    } else if (transfer.formValues.type === 'bookFlight') {
      let airportTo;
      let airportFrom;
      if (transfer.formValues.flightDetailsSecond) {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.tocode));
        const airportSecond = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.fromcode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        transferMessage =
          'Flight from ' +
          airportFrom.name +
          ' (' +
          transfer.formValues.flightDetails.fromcode +
          ') to ' +
          airportTo.name +
          ' (' +
          transfer.formValues.flightDetailsSecond.tocode +
          ') - Via ' +
          airportSecond.name +
          ' (' +
          transfer.formValues.flightDetailsSecond.fromcode +
          ')';
      } else {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.tocode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        transferMessage = 'Flight from ' + airportFrom.name + ' (' + transfer.formValues.flightDetails.fromcode + ') to ' + airportTo.name + ' (' + transfer.formValues.flightDetails.tocode + ')';
      }
    } else if (transfer.formValues.type === 'camp') {
      transferMessage = 'Departure from ' + properties[getPropObj(item.propUid, properties)].name + ' by own arrangements at ' + transfer.formValues.timeOwnArrangements;
    } else {
      transferMessage = `Departure from ${item.originName}`;
    }
  }

  // DAY
  const firstDayMoment = moment(firstDay, 'YYYY-MM-DD');
  const dayMoment = moment(item.endDay, 'YYYY-MM-DD');

  let day = 1;
  if (item.objType === 'arrival') {
    day = firstDay === item.startDay ? 1 : dayMoment.diff(firstDayMoment, 'days');
  } else {
    day = firstDay === item.endDay ? 1 : dayMoment.diff(firstDayMoment, 'days') + 1;
  }

  // useEffect(() => {
  //   if (finalTransferInputs.supplier === '') {
  //     setFinalTransferInputs({
  //       ...finalTransferInputs,
  //       supplier: item.transferItems[0].supplier,
  //     });
  //   }
  // }, []); //

  let parentDate = item.objType === 'arrival' ? item.startDay : item.endDay;

  return (
    <>
      {/* found items */}
      {foundItems.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`bg-[rgba(42,153,163,0.1)] border border-slate-200 brand-text-grey-v2 mb-7 
                  ${customFinalCut && customFinalCut.uuid === foundItem.uuid ? 'animate-pulse' : ''}`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <FinalFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalCut={customFinalCut}
            handlePaste={handlePaste}
            addItem={addItem}
            handleCut={handleCut}
            deleteItem={deleteItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            setCustomFinalCut={setCustomFinalCut}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            handleOpenSlideOut={handleOpenSlideOut}
            setCustomItemToEdit={setCustomItemToEdit}
            setAddFinalItemDetails={setAddFinalItemDetails}
            formatPrice={formatPrice}
            firstDay={firstDay}
            setFinalSlideOutDate={setFinalSlideOutDate}
          />
        </div>
      ))}

      <div
        className={`bg-white border border-slate-200 brand-text-grey-v2 mb-7`}
        style={{
          WebkitTransform: 'translateZ(0)' /* Safari */,
        }}
      >
        {/* add / paste top */}
        {user.internalUser && (
          <div className="fixed -top-7 left-0 right-0 flex justify-center group">
            {customFinalCut ? (
              <button
                className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                onClick={() => handlePaste('bottom', null, customFinalCut, customFinalItems, searchId, pickupDropoff, setCustomFinalItems, setCustomFinalCut, parentDate, false)}
                disabled={!customFinalCut}
              >
                <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5  text-right text-nomadEvergreen-700`}>
                  <span className="mr-1.5">Paste item</span>
                  <FaRegPaste size={16} />
                </span>
              </button>
            ) : (
              <button
                className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                onClick={() => {
                  let transferDate = item.objType === 'arrival' ? item.startDay : item.endDay;
                  setFinalSlideOutDate(transferDate);
                  addItem('bottom', null, searchId, pickupDropoff, handleOpenSlideOut, setAddFinalItemDetails, false);
                }}
              >
                <span className={`text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-center text-nomadEvergreen-700`}>+ Add Item</span>
              </button>
            )}
          </div>
        )}
        {/* DAY */}
        {/* <div className="fixed top-4 -left-12 flex -rotate-90"> */}

        <div className="fixed -top-6 -left-0 flex">
          <div className="text-left" style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}>
            {/* <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-right text-nomadEvergreen-700`}> */}
            <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-0 pr-3 py-0.5 text-right text-nomadEvergreen-700`}>
              <span className="tracking-widest">DAY {day}</span>
              {/* <FaRegPaste size={16} /> */}
            </span>
          </div>
        </div>

        {/* add / paste bottom */}
        {!departureButtons && user.internalUser && (
          <div className="fixed -bottom-7 left-0 right-0 flex justify-center group">
            {customFinalCut ? (
              <button
                className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                onClick={() => handlePaste('top', null, customFinalCut, customFinalItems, searchId, pickupDropoff, setCustomFinalItems, setCustomFinalCut, parentDate, true)}
                disabled={!customFinalCut}
              >
                <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5  text-right text-nomadEvergreen-700`}>
                  <span className="mr-1.5">Paste item</span>
                  <FaRegPaste size={16} />
                </span>
              </button>
            ) : (
              <button
                className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                onClick={() => {
                  let transferDate = item.objType === 'arrival' ? item.startDay : item.endDay;
                  setFinalSlideOutDate(transferDate);
                  addItem('top', null, searchId, pickupDropoff, handleOpenSlideOut, setAddFinalItemDetails, true);
                }}
              >
                <span className={`text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-center text-nomadEvergreen-700`}>+ Add Item</span>
              </button>
            )}
          </div>
        )}

        <div className={`mb-0`}>
          <div className={`grid grid-cols-12`}>
            {/* IMG */}

            {/* ROOM NAME + DESCRIPTION */}
            <div className="col-span-12 bg-white">
              {/* Number of days */}
              <div
                className="text-right fixed top-4 right-0"
                style={{
                  WebkitTransform: 'translateZ(0)',
                  perspective: '1000' /* Safari */,
                }}
              ></div>
              {/* Property name */}
              <div className="grid grid-cols-12 border-b border-slate-200">
                <div className="text-xs font-medium truncate text-ellipsis overflow-hidden col-span-10 ml-2 border-r border-slate-200 py-2">
                  <div className="flex items-center">
                    {/* ICON AND CIRCLE */}
                    <div className="row-span-1 relative">
                      <div className="ml-0 mr-2 object-cover w-10 h-10 rounded-full bg-slate-200 flex justify-center items-center">
                        {item.objType === 'arrival' && transfer.formValues.type === 'bookFlight' && <MdConnectingAirports size={30} />}
                        {item.objType === 'arrival' && transfer.formValues.type !== 'bookFlight' && <GiJeep size={30} />}
                        {item.objType === 'propertyTransfer' && transfer.formValues.type === 'flight' && <MdConnectingAirports size={30} />}
                        {item.objType === 'propertyTransfer' && transfer.formValues.type === 'ownArrangements' && transfer.formValues.airportTransfer && <MdConnectingAirports size={30} />}
                        {item.objType === 'propertyTransfer' && transfer.formValues.type === 'ownArrangements' && !transfer.formValues.airportTransfer && <GiJeep size={30} />}
                        {item.objType === 'propertyTransfer' && transfer.formValues.type !== 'ownArrangements' && transfer.formValues.type !== 'flight' && <GiJeep size={30} />}
                        {item.objType === 'departure' && transfer.formValues.type === 'bookFlight' && <MdConnectingAirports size={30} />}
                        {item.objType === 'departure' && transfer.formValues.type !== 'bookFlight' && <GiJeep size={30} />}
                      </div>
                    </div>
                    {/* {item.objType === 'arrival' && <TbTransferIn size={30} />}
                    {item.objType === 'propertyTransfer' && <TbTransfer size={30} />}
                    {item.objType === 'departure' && <TbTransferOut size={30} />} */}
                    <div className="ml-2 flex flex-col">
                      <span className="flex">
                        <span className="text-base w-14">
                          {item.objType === 'arrival' && moment(item.startDay).format('MMM D')}
                          {item.objType === 'propertyTransfer' && moment(item.endDay).format('MMM D')}
                          {item.objType === 'departure' && moment(item.endDay).format('MMM D')}
                        </span>
                        <span className="text-base font-semibold ml-5 text-black">
                          {transferMessage}
                          {/* ARRIVAL */}
                          {/* {item.objType === 'arrival' && `Arrival to ${item.destinationName}`} */}

                          {/* PROPERTY TRANSFER */}
                          {/* {item.objType === 'propertyTransfer' && `Transfer | ${item.originName} to ${item.destinationName}`} */}

                          {/* DEPARTURE */}
                          {/* {item.objType === 'departure' && `Departure from ${item.originName}`} */}
                        </span>
                      </span>
                      <span className="flex">
                        <span className=" w-14">
                          {item.objType === 'arrival' && moment(item.startDay).format('ddd')}
                          {item.objType === 'propertyTransfer' && moment(item.endDay).format('ddd')}
                          {item.objType === 'departure' && moment(item.endDay).format('ddd')}
                        </span>

                        <span className="ml-5">
                          {(() => {
                            // let supplierName = getSupplierName(item.transferItems[0].supplier, suppliers);

                            // if (transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight') {
                            //   if (transfer.formValues.flightDetailsSecond) {
                            //     supplierName = getSupplierName(item.transferItems[0].supplier, suppliers) + ' & ' + getSupplierName(item.transferItems[x].supplier, suppliers);
                            //   } else {
                            //     supplierName = getSupplierName(item.transferItems[0].supplier, suppliers);
                            //   }
                            // }

                            let supplierName = getSupplierName(item.transferItems[0].supplier, suppliers);

                            if (transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight') {
                              if (transfer.formValues.flightDetailsSecond) {
                                const nextItem = item.transferItems.find((transferItem, index) => index > 0 && transferItem.supplier !== item.transferItems[0].supplier);

                                if (nextItem) {
                                  const nextSupplierName = getSupplierName(nextItem.supplier, suppliers);
                                  supplierName = `${supplierName} & ${nextSupplierName}`;
                                }
                                // else {
                                //   supplierName = '';
                                // }
                              } else {
                                supplierName = getSupplierName(item.transferItems[0].supplier, suppliers);
                              }
                            }

                            switch (item.objType) {
                              case 'arrival':
                                return (
                                  <>
                                    {supplierName} | {moment(item.startDay).format('Do MMM YYYY')}
                                  </>
                                );
                              case 'propertyTransfer':
                                return (
                                  <>
                                    {supplierName} | {moment(item.endDay).format('Do MMM YYYY')}
                                  </>
                                );
                              case 'departure':
                                return (
                                  <>
                                    {supplierName} | {moment(item.endDay).format('Do MMM YYYY')}
                                  </>
                                );
                              default:
                                return null;
                            }
                          })()}
                        </span>

                        {/* 
                        <span className="ml-5">
                          {item.objType === 'arrival' && getSupplierName(item.transferItems[0].supplier, suppliers) + ' | ' + moment(item.startDay).format('Do MMM YYYY')}
                          {item.objType === 'propertyTransfer' && getSupplierName(item.transferItems[0].supplier, suppliers) + ' | ' + moment(item.endDay).format('Do MMM YYYY')}
                          {item.objType === 'departure' && getSupplierName(item.transferItems[0].supplier, suppliers) + ' | ' + moment(item.endDay).format('Do MMM YYYY')}
                        </span> */}
                      </span>
                      {/* <span>
                        {' '}
                        {item.objType === 'arrival' && getSupplierName(item.transferItems[0].supplier, suppliers) + ' | ' + moment(item.startDay).format('Do MMM YYYY')}
                        {item.objType === 'propertyTransfer' && getSupplierName(item.transferItems[0].supplier, suppliers) + ' | ' + moment(item.endDay).format('Do MMM YYYY')}
                        {item.objType === 'departure' && getSupplierName(item.transferItems[0].supplier, suppliers) + ' | ' + moment(item.endDay).format('Do MMM YYYY')}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-span-1 text-xs border-r border-slate-200 py-2 text-nomadBlue-500 flex items-center justify-center">
                  ${formatPrice(item.transferItems.reduce((totals, currentItem) => totals + currentItem.total, 0))}
                </div>
                <div className="col-span-1 py-2 flex justify-center items-center">
                  {item.transferItems.length === 0 && <IoMdWarning size={20} className="text-orange-600" />}

                  {!item.collapsed ? (
                    <IoIosArrowUp
                      size={20}
                      className={`cursor-pointer mt-1 ${item.transferItems.length === 0 && 'ml-3'} text-nomadEvergreen-700`}
                      onClick={() => toggleFinalTransferCollapse(item.tuuid, finalPageTransferData, resetFinalTransferInputs, setFinalPageTransferData, setFinalInputs)} // add this line
                    />
                  ) : (
                    <IoIosArrowDown
                      size={20}
                      className={`cursor-pointer mt-1 ${item.transferItems.length === 0 && 'ml-3'} text-nomadEvergreen-700`}
                      onClick={() => toggleFinalTransferCollapse(item.tuuid, finalPageTransferData, resetFinalTransferInputs, setFinalPageTransferData, setFinalInputs)} // add this line
                    />
                  )}
                </div>
              </div>

              {/* Column headers*/}
              {/* TODO Add description column*/}

              {!item.collapsed && (
                <div className={`grid grid-cols-12 text-xs font-medium border-b border-slate-200`}>
                  <div className="col-span-1 px-2 border-r border-slate-200 py-2 text-black">FEE TYPE</div>
                  <div className="col-span-2 ml-5 border-r border-slate-200 py-2 text-black">SUPPLIER</div>
                  <div className="col-span-4">
                    <div className={`grid grid-cols-12 text-xs`}>
                      <div className="col-span-11 ml-5 border-r border-slate-200 py-2 text-black">DESCRIPTION</div>
                      <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center"></div>
                    </div>
                  </div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">
                    <span className="block">NTS/UNITS</span>
                  </div>

                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">
                    <span className="block">PAX/UNITS</span>
                  </div>
                  <div className="col-span-1 ml-4 border-r border-slate-200 py-2 text-black">NET RATE ($)</div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">TOTAL</div>
                  <div className="col-span-1 ml-5 py-2"></div>
                </div>
              )}

              {/* Column propertyItems*/}
              {!item.collapsed &&
                item.transferItems
                  .sort((a, b) => (a.edit === b.edit ? 0 : a.edit ? 1 : -1))
                  .map((transferItem, itemIndex) =>
                    item.editTransferItem !== transferItem.uuid ? (
                      <div key={uuidv4()} className={`grid grid-cols-12 text-xs border-b border-slate-200`}>
                        <div className="col-span-1 px-2 border-r border-slate-200 py-2">{getShortenedLabelForValue(transferItem.feeTypeUid)}</div>
                        <div className="col-span-2 ml-5 border-r border-slate-200 py-2">{getSupplierName(transferItem.supplier, suppliers)}</div>
                        <div className="col-span-4">
                          <div className={`grid grid-cols-12 text-xs`}>
                            <div className="col-span-11 ml-5 border-r border-slate-200 py-2 pr-5">{transferItem.description}</div>
                            <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group">
                              <svg viewBox="0 0 24 24" className={`w-6 h-6 ${transferItem.star ? 'text-nomadEvergreen-700' : 'text-gray-300'}`}>
                                <path d="M12 2l2.4 7.2h7.6l-6.2 4.6l2.4 7.2L12 16.4l-6.2 4.6l2.4-7.2L2 9.2h7.6L12 2z" className="star-path fill-current"></path>
                              </svg>
                            </div>
                            {/* <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center">{propertyItem.star}</div> */}
                          </div>
                        </div>
                        {/* <div className="col-span-5 ml-5 border-r border-slate-200 py-2">{propertyItem.description}</div> */}
                        <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{transferItem.units}</div>
                        <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{transferItem.pax}</div>
                        <div className="col-span-1 ml-5 border-r border-slate-200 py-2">${formatPrice(transferItem.net)}</div>
                        <div className="col-span-1 ml-5 border-r border-slate-200 py-2"> ${formatPrice(transferItem.total)}</div>
                        <div className="col-span-1 ml-5 py-2 flex items-center">
                          {transferItem.edit && user.internalUser && (
                            <div className="grid grid-cols-12 items-center justify-center">
                              <div className="col-span-4 flex justify-center items-center px-2 py-2">
                                <FaEdit
                                  size={20}
                                  className="text-nomadEvergreen-700 cursor-pointer"
                                  onClick={() => editFinalInputTransferItem(item.uuid, transferItem.uuid, finalPageTransferData, setFinalTransferInputs, setFinalPageTransferData)}
                                />
                              </div>

                              <div className="col-span-4 flex justify-center items-center px-2 py-2">
                                <FaTrashAlt
                                  size={18}
                                  className="text-red-700 cursor-pointer"
                                  onClick={() => deleteFinalInputTransferItem(item.uuid, transferItem.uuid, finalPageTransferData, setFinalPageTransferData)}
                                />
                              </div>
                              <div className="col-span-4 flex flex-col justify-center items-center px-2 py-1">
                                <IoIosArrowUp
                                  size={18}
                                  className="cursor-pointer mb-1"
                                  onClick={() => moveTransferItemUp(item.tuuid, transferItem.uuid, finalPageTransferData, setFinalPageTransferData)}
                                />
                                <IoIosArrowDown
                                  size={18}
                                  className="cursor-pointer"
                                  onClick={() => moveTransferItemDown(item.tuuid, transferItem.uuid, finalPageTransferData, setFinalPageTransferData)}
                                />
                              </div>
                            </div>
                            // <div className="flex items-center cursor-pointer">
                            //   <div
                            //     className="flex items-center cursor-pointer"
                            //     onClick={() => editFinalInputTransferItem(item.uuid, transferItem.uuid, finalPageTransferData, setFinalTransferInputs, setFinalPageTransferData)}
                            //   >
                            //     <FaEdit size={18} className="text-nomadEvergreen-700" />
                            //   </div>
                            //   <div
                            //     className="flex items-center cursor-pointer ml-5"
                            //     onClick={() => deleteFinalInputTransferItem(item.uuid, transferItem.uuid, finalPageTransferData, setFinalPageTransferData)}
                            //   >
                            //     <FaTrashAlt size={18} className="text-red-700" />
                            //   </div>
                            // </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div key={uuidv4()}>
                        <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                          <div className="col-span-1 px-3  border-r border-slate-200 py-2">
                            <Select
                              value={
                                finalTransferInputs.feeTypeUid
                                  ? {
                                      label: finalTransferInputs.feeTypeName,
                                      value: finalTransferInputs.feeTypeUid,
                                    }
                                  : null
                              }
                              onChange={(selectedOption) =>
                                setFinalTransferInputs({
                                  ...finalTransferInputs,
                                  feeTypeUid: selectedOption.value,
                                  feeTypeName: selectedOption.label,
                                })
                              }
                              options={feeTypeOptions}
                              menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: '5rem',
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }), // Set the z-index for the dropdown menu
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  width: '10rem', // width for the dropdown menu
                                }),
                                option: (provided) => ({
                                  ...provided,
                                  fontSize: '12px', // equivalent to text-xs in Tailwind
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  fontSize: '12px', // equivalent to text-xs in Tailwind
                                }),
                              }}
                              placeholder="Select"
                              components={{
                                DropdownIndicator: null, // This line removes the dropdown arrow
                              }}
                            />
                          </div>
                          <div className="col-span-2 px-5  border-r border-slate-200 py-2">
                            <Select
                              value={
                                finalTransferInputs.supplier
                                  ? {
                                      label: getSupplierName(finalTransferInputs.supplier, suppliers),
                                      value: finalTransferInputs.supplier,
                                    }
                                  : null
                              }
                              onChange={(selectedOption) => {
                                setFinalTransferInputs({
                                  ...finalTransferInputs,
                                  supplier: selectedOption.value,
                                  star: selectedOption.value !== item.supplier ? true : finalTransferInputs.star,
                                });
                              }}
                              options={suppliers
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((supplier) => ({
                                  value: supplier.uid,
                                  label: supplier.name,
                                }))}
                              menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: '10.5rem',
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }), // Set the z-index for the dropdown menu
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  width: '20rem', // width for the dropdown menu
                                }),
                                option: (provided) => ({
                                  ...provided,
                                  fontSize: '12px', // equivalent to text-xs in Tailwind
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  fontSize: '12px', // equivalent to text-xs in Tailwind
                                }),
                              }}
                              placeholder="Select"
                              components={{
                                DropdownIndicator: null, // This line removes the dropdown arrow
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            <div className={`grid grid-cols-12 text-xs`}>
                              <div className="col-span-11 ml-5 border-r border-slate-200 py-2 pr-5">
                                {' '}
                                <input
                                  type="text"
                                  maxLength="255"
                                  defaultValue={finalTransferInputs.description}
                                  //onBlur={(e) => setFinalInputs({ ...finalInputs, description: e.target.value })}
                                  onBlur={(e) =>
                                    setFinalTransferInputs({
                                      ...finalTransferInputs,
                                      description: e.target.value,
                                    })
                                  }
                                  className="text-xs w-full"
                                />
                              </div>
                              <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group">
                                <input
                                  type="checkbox"
                                  id="starCheckbox"
                                  className="hidden"
                                  //checked={finalInputs.star}
                                  onClick={() => {
                                    if (finalTransferInputs.supplier !== item.supplier) {
                                      toast.error(`Transfers must be starred`);
                                      return;
                                    }
                                    const newStarValue = !finalTransferInputs.star;
                                    setFinalTransferInputs({
                                      ...finalTransferInputs,
                                      star: newStarValue,
                                    });
                                    console.log(newStarValue);
                                  }}
                                />
                                <label htmlFor="starCheckbox" className="cursor-pointer">
                                  <svg viewBox="0 0 24 24" className={`w-6 h-6 ${finalTransferInputs.star ? 'text-nomadEvergreen-700' : 'text-gray-300'}`}>
                                    <path d="M12 2l2.4 7.2h7.6l-6.2 4.6l2.4 7.2L12 16.4l-6.2 4.6l2.4-7.2L2 9.2h7.6L12 2z" className="star-path fill-current"></path>
                                  </svg>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                            {/* <input
                              type="number"
                              min="0"
                              defaultValue={finalTransferInputs.units}
                              onBlur={(e) => {
                                let value = Math.floor(Number(e.target.value));
                                value = value < 0 ? 0 : value; // check if value is less than 0
                                setFinalTransferInputs({
                                  ...finalTransferInputs,
                                  units: value,
                                });
                              }}
                              className="w-full text-xs inputPax"
                              style={{
                                appearance: 'textfield',
                              }} // add this
                            /> */}
                            <input
                              type="number"
                              min="1"
                              defaultValue={finalTransferInputs.units === 0 ? 1 : finalTransferInputs.units}
                              // onChange={(e) => {
                              //   let value = Math.max(1, Math.floor(Number(e.target.value)));
                              //   setFinalTransferInputs({
                              //     ...finalTransferInputs,
                              //     units: value,
                              //   });
                              // }}
                              onBlur={(e) => {
                                let value = e.target.value === '' ? 1 : Math.max(1, Math.floor(Number(e.target.value)));
                                setFinalTransferInputs({
                                  ...finalTransferInputs,
                                  units: value,
                                });
                              }}
                              className="w-full text-xs inputPax"
                              style={{
                                appearance: 'textfield',
                              }}
                            />
                          </div>
                          <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                            <input
                              type="number"
                              min="0"
                              defaultValue={finalTransferInputs.pax > 0 ? finalTransferInputs.pax : item.transferItems[0].pax}
                              onBlur={(e) => {
                                let value = Math.floor(Number(e.target.value));
                                value = value < 0 ? 0 : value; // check if value is less than 0
                                setFinalTransferInputs({
                                  ...finalTransferInputs,
                                  pax: value,
                                });
                              }}
                              className="w-full text-xs inputPax"
                              style={{
                                appearance: 'textfield',
                              }} // add this
                            />
                          </div>
                          <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                            <input
                              type="number"
                              //min="0"
                              step="0.01"
                              defaultValue={finalTransferInputs.net}
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^-?\d*\.?\d{0,2}$/.test(value)) {
                                  e.target.value = value.slice(0, -1);
                                }
                              }}
                              onBlur={(e) => {
                                //let value = Math.floor(Number(e.target.value));
                                // Rounds up to 2 decimal points
                                let value = Number(Number(e.target.value).toFixed(2));
                                //value = value < 0 ? 0 : value; // check if value is less than 0
                                setFinalTransferInputs({
                                  ...finalTransferInputs,
                                  net: value,
                                });
                              }}
                              className="w-full text-xs inputPax"
                              style={{
                                appearance: 'textfield',
                              }} // add this
                            />
                          </div>
                          <div className="col-span-1 ml-5 border-r border-slate-200 py-2 flex items-center">${finalTransferInputs.units * finalTransferInputs.pax * finalTransferInputs.net}</div>

                          <div className="col-span-1 ml-5 py-2 flex items-center"></div>
                        </div>
                        <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                          <div className="col-span-4 ml-5 py-2">
                            <div className="flex items-center cursor-pointer">
                              <div
                                className="flex items-center cursor-pointer"
                                onClick={() =>
                                  saveFinalInputDataTransferEdit(item.uuid, transferItem.uuid, finalPageTransferData, finalTransferInputs, setFinalPageTransferData, resetFinalTransferInputs)
                                }
                              >
                                <FaSave size={18} className="text-nomadEvergreen-700" />
                                <span className="ml-2">Save row</span>
                              </div>
                              <div
                                className="flex items-center cursor-pointer ml-5"
                                onClick={() => cancelFinalInputDataTransferEdit(item.uuid, transferItem.uuid, finalPageTransferData, resetFinalInputs, setFinalPageTransferData, setFinalInputs)}
                              >
                                <FaWindowClose size={18} className="text-nomadEvergreen-700" />
                                <span className="ml-2">Cancel</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-2 ml-5 py-2"></div>
                          <div className="col-span-2 ml-5 py-2"></div>
                          <div className="col-span-2 ml-5 py-2"></div>
                          <div className="col-span-2 ml-5 py-2"></div>
                        </div>{' '}
                      </div>
                    ),
                  )}

              {/* Add new item */}
              {!item.collapsed && item.addTransferItem && (
                <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                  <div className="col-span-1 px-3  border-r border-slate-200 py-2">
                    <Select
                      value={
                        finalTransferInputs.feeTypeUid
                          ? {
                              label: finalTransferInputs.feeTypeName,
                              value: finalTransferInputs.feeTypeUid,
                            }
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFinalTransferInputs({
                          ...finalTransferInputs,
                          feeTypeUid: selectedOption.value,
                          feeTypeName: selectedOption.label,
                        })
                      }
                      options={feeTypeOptions}
                      menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '5rem',
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }), // Set the z-index for the dropdown menu
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: '10rem', // width for the dropdown menu
                        }),
                        option: (provided) => ({
                          ...provided,
                          fontSize: '12px', // equivalent to text-xs in Tailwind
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          fontSize: '12px', // equivalent to text-xs in Tailwind
                        }),
                      }}
                      placeholder="Select"
                      components={{
                        DropdownIndicator: null, // This line removes the dropdown arrow
                      }}
                    />
                  </div>
                  <div className="col-span-2 px-5  border-r border-slate-200 py-2">
                    <Select
                      value={
                        finalTransferInputs.supplier
                          ? {
                              label: getSupplierName(finalTransferInputs.supplier, suppliers),
                              value: finalTransferInputs.supplier,
                            }
                          : {
                              label: getSupplierName(item.transferItems[0].supplier, suppliers),
                              value: item.transferItems[0].supplier,
                            }
                      }
                      onChange={(selectedOption) => {
                        setFinalTransferInputs({
                          ...finalTransferInputs,
                          supplier: selectedOption.value,
                          star: selectedOption.value !== item.supplier ? true : finalTransferInputs.star,
                        });
                      }}
                      options={suppliers
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((supplier) => ({
                          value: supplier.uid,
                          label: supplier.name,
                        }))}
                      menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '10.5rem',
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }), // Set the z-index for the dropdown menu
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: '20rem', // width for the dropdown menu
                        }),
                        option: (provided) => ({
                          ...provided,
                          fontSize: '12px', // equivalent to text-xs in Tailwind
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          fontSize: '12px', // equivalent to text-xs in Tailwind
                        }),
                      }}
                      placeholder="Select"
                      components={{
                        DropdownIndicator: null, // This line removes the dropdown arrow
                      }}
                    />
                  </div>
                  <div className="col-span-4">
                    <div className={`grid grid-cols-12 text-xs`}>
                      <div className="col-span-11 ml-5 border-r border-slate-200 py-2 flex pr-5">
                        {' '}
                        <input
                          type="text"
                          maxLength="255"
                          defaultValue={finalTransferInputs.description}
                          //onBlur={(e) => setFinalInputs({ ...finalInputs, description: e.target.value })}
                          onBlur={(e) =>
                            setFinalTransferInputs({
                              ...finalTransferInputs,
                              description: e.target.value,
                            })
                          }
                          className="text-xs w-full"
                        />
                      </div>
                      <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group">
                        <input
                          type="checkbox"
                          id="starCheckbox"
                          className="hidden"
                          //checked={finalInputs.star}
                          onClick={() => {
                            if (finalTransferInputs.supplier !== item.supplier) {
                              toast.error(`Transfers must be starred`);
                              return;
                            }
                            const newStarValue = !finalTransferInputs.star;
                            setFinalTransferInputs({
                              ...finalTransferInputs,
                              star: newStarValue,
                            });
                            console.log(newStarValue);
                          }}
                        />
                        <label htmlFor="starCheckbox" className="cursor-pointer">
                          <svg viewBox="0 0 24 24" className={`w-6 h-6 ${finalTransferInputs.star ? 'text-nomadEvergreen-700' : 'text-gray-300'}`}>
                            <path d="M12 2l2.4 7.2h7.6l-6.2 4.6l2.4 7.2L12 16.4l-6.2 4.6l2.4-7.2L2 9.2h7.6L12 2z" className="star-path fill-current"></path>
                          </svg>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                    {/* <input
                      type="number"
                      min="0"
                      defaultValue={finalTransferInputs.units > 0 ? finalTransferInputs.units : 1}
                      onBlur={(e) => {
                        let value = Math.floor(Number(e.target.value));
                        value = value < 0 ? 0 : value; // check if value is less than 0
                        setFinalTransferInputs({
                          ...finalTransferInputs,
                          units: value,
                        });
                      }}
                      className="w-full text-xs inputPax"
                      style={{
                        appearance: 'textfield',
                      }} // add this
                    /> */}
                    <input
                      type="number"
                      min="1"
                      defaultValue={finalTransferInputs.units === 0 ? 1 : finalTransferInputs.units}
                      // onChange={(e) => {
                      //   let value = Math.max(1, Math.floor(Number(e.target.value)));
                      //   setFinalTransferInputs({
                      //     ...finalTransferInputs,
                      //     units: value,
                      //   });
                      // }}
                      onBlur={(e) => {
                        let value = e.target.value === '' ? 1 : Math.max(1, Math.floor(Number(e.target.value)));
                        setFinalTransferInputs({
                          ...finalTransferInputs,
                          units: value,
                        });
                      }}
                      className="w-full text-xs inputPax"
                      style={{
                        appearance: 'textfield',
                      }}
                    />
                  </div>
                  <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                    <input
                      type="number"
                      min="1"
                      defaultValue={finalTransferInputs.pax > 0 ? finalTransferInputs.pax : item.transferItems[0].pax}
                      onBlur={(e) => {
                        let value = Math.floor(Number(e.target.value));
                        value = value < 0 ? 0 : value; // check if value is less than 0
                        setFinalTransferInputs({
                          ...finalTransferInputs,
                          pax: value,
                        });
                      }}
                      className="w-full text-xs inputPax"
                      style={{
                        appearance: 'textfield',
                      }} // add this
                    />
                  </div>
                  <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                    <input
                      type="number"
                      //min="0"
                      step="0.01"
                      onInput={(e) => {
                        const value = e.target.value;
                        if (!/^-?\d*\.?\d{0,2}$/.test(value)) {
                          e.target.value = value.slice(0, -1);
                        }
                      }}
                      defaultValue={finalTransferInputs.net}
                      onBlur={(e) => {
                        // Rounds up to 2 decimal points
                        let value = Number(Number(e.target.value).toFixed(2));
                        //let value = Math.floor(Number(e.target.value));
                        //value = value < 0 ? 0 : value; // check if value is less than 0
                        setFinalTransferInputs({
                          ...finalTransferInputs,
                          net: value,
                        });
                      }}
                      className="w-full text-xs inputPax"
                      style={{
                        appearance: 'textfield',
                      }} // add this
                    />
                  </div>
                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2 flex items-center">${finalTransferInputs.units * finalTransferInputs.pax * finalTransferInputs.net}</div>

                  <div className="col-span-1 ml-5 py-2 flex items-center"></div>
                </div>
              )}

              {/* Columns add item*/}
              {!item.collapsed && !item.addTransferItem && user.internalUser && (
                <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                  <div className="col-span-4 ml-5 py-2">
                    <div
                      className="flex cursor-pointer"
                      onClick={() => handleAddFinalTransferItem(item.tuuid, item.supplier, setFinalPageTransferData, resetFinalTransferInputs, resetEditTransferItem)}
                    >
                      <FaPlusCircle size={18} className="text-green-600 self-center" />
                      <span className="ml-2 text-green-600 pt-1">Add new item</span>
                    </div>
                  </div>
                  <div className="col-span-2 ml-5 py-2"></div>
                  <div className="col-span-2 ml-5 py-2"></div>
                  <div className="col-span-2 ml-5 py-2"></div>
                  <div className="col-span-2 ml-5 py-2"></div>
                </div>
              )}

              {/* Close * Save buttons*/}
              {!item.collapsed && item.addTransferItem && (
                <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                  <div className="col-span-4 ml-5 py-2">
                    <div className="flex items-center cursor-pointer">
                      <div
                        className="flex items-center cursor-pointer"
                        // onClick={() =>
                        //   saveFinalInputTransferData(item.tuuid, finalTransferInputs, setFinalPageTransferData, finalPageTransferData, resetFinalTransferInputs, item.transferItems[0].supplier)
                        // }
                        onClick={() => {
                          const updatedFinalTransferInputs = {
                            ...finalTransferInputs,
                            units: finalTransferInputs.units === 0 ? 1 : finalTransferInputs.units,
                            pax: finalTransferInputs.pax === 0 ? item.transferItems[0].pax : finalTransferInputs.pax,
                          };
                          saveFinalInputTransferData(item.tuuid, updatedFinalTransferInputs, setFinalPageTransferData, finalPageTransferData, resetFinalTransferInputs, item.transferItems[0].supplier);
                        }}
                      >
                        <FaSave size={18} className="text-nomadEvergreen-700" />
                        <span className="ml-2">Save row</span>
                      </div>
                      <div className="flex items-center cursor-pointer ml-5" onClick={() => cancelFinalInputTransferData(finalPageTransferData, setFinalPageTransferData)}>
                        <FaWindowClose size={18} className="text-nomadEvergreen-700" />
                        <span className="ml-2">Cancel</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 ml-5 py-2"></div>
                  <div className="col-span-2 ml-5 py-2"></div>
                  <div className="col-span-2 ml-5 py-2"></div>
                  <div className="col-span-2 ml-5 py-2"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* found items final */}
      {foundItemsDeparture.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`bg-[rgba(42,153,163,0.1)] border border-slate-200 brand-text-grey-v2 mb-7 
                  ${customFinalCut && customFinalCut.uuid === foundItem.uuid ? 'animate-pulse' : ''}`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <FinalFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalCut={customFinalCut}
            handlePaste={handlePaste}
            addItem={addItem}
            handleCut={handleCut}
            deleteItem={deleteItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            setCustomFinalCut={setCustomFinalCut}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            handleOpenSlideOut={handleOpenSlideOut}
            setCustomItemToEdit={setCustomItemToEdit}
            setAddFinalItemDetails={setAddFinalItemDetails}
            formatPrice={formatPrice}
            firstDay={firstDay}
            setFinalSlideOutDate={setFinalSlideOutDate}
          />
        </div>
      ))}
    </>
  );
};

export default FinalTransferItem;
