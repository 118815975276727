import React, { useState } from 'react';
import { FiPlus } from 'react-icons/fi';

const ButtonTransferAdd = ({ onClick, text, className = '' }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className={`bg-nomadBlue-400 text-white text-sm hover:bg-nomadBlue-600 font-normal py-1 px-4 border rounded-full h-10 w-fit flex items-center ${className}`}
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <span className="animate-spin mr-2">&#9696;</span>
      ) : (
        <FiPlus className="mr-1 w-5 h-5" />
      )}
      {text}
    </button>
  );
};

export default ButtonTransferAdd;