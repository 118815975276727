import React, { useEffect, useRef, useState } from "react";
import { logout, reset } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Signout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  }, [dispatch, navigate]);

  return <div>Signing out...</div>;
};

export default Signout;
