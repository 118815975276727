import React from 'react';
import { GoPerson } from 'react-icons/go';

const GuestCount = ({ bookings }) => {
  let maxGuests = 0;
  let maxBooking = null;

  bookings.forEach((booking) => {
    const { rooms } = booking;
    const totalGuests = rooms.reduce((sum, room) => {
      sum += room.ad + room.ch + room.chi + room.inf;
      return sum;
    }, 0);
    if (totalGuests > maxGuests) {
      maxGuests = totalGuests;
      maxBooking = booking;
    }
  });

  return (
    <div className="flex text-left mt-5">
      <GoPerson size={20} />
      <div className="ml-2 text-zinc-800 text-sm font-normal">
        {maxGuests} Guest{maxGuests === 1 ? '' : 's'} (Booked)
      </div>
    </div>
  );
};

export default GuestCount;
