import React, { useMemo } from 'react';
import { Index, Configure, connectInfiniteHits } from 'react-instantsearch-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { CalendarIcon, UserIcon } from '@heroicons/react/24/solid';
import { EyeIcon } from '@heroicons/react/24/outline';
import { format, differenceInDays } from 'date-fns';

const SkeletonCard = () => (
  <div className="bg-white p-4 mb-4 rounded shadow animate-pulse">
    <div className="flex justify-between items-start mb-2">
      <div className="w-3/4">
        <div className="h-5 bg-gray-200 rounded w-full mb-2"></div>
        <div className="h-3 bg-gray-200 rounded w-1/2"></div>
      </div>
      <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
    </div>
    <div className="flex justify-between items-center mt-4">
      <div className="h-3 bg-gray-200 rounded w-1/4"></div>
      <div className="h-3 bg-gray-200 rounded w-1/4"></div>
    </div>
  </div>
);


const Card = React.memo(({ card, onQuickView }) => {
  // Add date calculation logic
  const formatExpiryDate = (dateExpiryMillis) => {
    if (!dateExpiryMillis || isNaN(new Date(dateExpiryMillis).getTime())) {
      return 'Invalid date';
    }
    const date = new Date(dateExpiryMillis);
    return format(date, "EEE, MMM d 'at' HH:mm");
  };

  const now = new Date();
  const expiryDate = new Date(card.dateProvisionalExpiry);
  const daysDifference = !isNaN(expiryDate.getTime()) ? differenceInDays(expiryDate, now) : null;

  return (
    <motion.div 
      className="bg-white p-4 mb-4 rounded-lg border border-[#f0ece7] hover:shadow-md transition-all hover:border-nomadBlue-100" 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 0.3 }}
    >
      <div className="flex justify-between items-start mb-3">
        <div className="flex-grow mr-3">
          {/* Primary Info */}
          <h4 className="font-semibold text-nomadBlue-600 text-base mb-1">{card.supplierName}</h4>
          <div className="space-y-1">
            <p className="text-sm text-gray-700">
              <span className="font-medium">{card.clientName}</span> • {card.itineraryCode}
            </p>
            <div className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md">
              <span className="text-xs text-gray-600">{card.reservationStage}</span>
            </div>
          </div>
        </div>
        {/* <button 
          onClick={() => onQuickView(card)}
          className="bg-nomadEvergreen-50 hover:bg-nomadEvergreen-100 border-none rounded-full p-2 transition-colors"
        >
        <EyeIcon className="text-nomadEvergreen-700 w-4 h-4" />
        </button> */}
      </div>

  {/* Expiry Info - for provisional cards */}
{card.reservationStage === 'provisional' && daysDifference !== null && (
  <div className="mt-2 flex items-center space-x-1.5 text-xs">
    <div className="inline-flex items-center px-2 py-1 bg-red-50 text-red-600 rounded-md border border-red-100">
      <span className="font-medium">Expires:</span>
      <span className="ml-1">{formatExpiryDate(card.dateProvisionalExpiry)}</span>
      <span className="ml-1 font-medium">({daysDifference + 1} day{daysDifference !== 0 && 's'})</span>
    </div>
  </div>
)}
      
      {/* Footer Info */}
      <div className="flex justify-between items-center pt-2 border-t border-[#f0ece7] mt-2">
        <div className="flex items-center text-xs text-gray-600">
          <UserIcon className="w-3 h-3 mr-1.5" />
          <span className="truncate">{card.agent?.name || 'Unassigned'}</span>
        </div>
        <div className="flex items-center text-xs text-gray-600">
          <CalendarIcon className="w-3 h-3 mr-1.5" />
        
        </div>
      </div>
    </motion.div>
  );
});

const getLaneColor = (laneId) => {
  switch (laneId) {
    case 'expiringProvisional':
      return {
        strip: 'bg-red-500',
        background: 'bg-red-50/30'
      };
    case 'nomad_provisional':
      return {
        strip: 'bg-nomadBlue-500',
        background: 'bg-nomadBlue-50/30'
      };
    case 'third_party_provisional':
      return {
        strip: 'bg-purple-500',
        background: 'bg-purple-50/30'
      };
    default:
      return {
        strip: 'bg-gray-300',
        background: 'bg-gray-50/30'
      };
  }
};

const Lane = connectInfiniteHits(({ hits, hasMore, refineNext, title, onQuickView, laneId }) => {
  const colors = getLaneColor(laneId);
  
  const handleLoadMore = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight && hasMore) {
      refineNext();
    }
  };

  if (!hits) return null;

  return (
    <div className="flex-1 rounded-lg border border-[#f0ece7] shadow-sm min-w-[300px] max-w-[350px] overflow-hidden flex flex-col h-full">
      {/* Color Strip */}
      <div className={`h-1 ${colors.strip}`} />
      
      {/* Lane Header */}
      <div className={`p-4 border-b border-[#f0ece7] ${colors.background}`}>
        <div className="flex items-center justify-between mb-1">
          <h3 className="text-lg font-semibold text-nomadBlue-600">{title}</h3>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-white/80 text-nomadBlue-700 border border-[#f0ece7]">
            {hits.length}
          </span>
        </div>
      </div>

      {/* Lane Content */}
      <div 
        className={`flex-1 p-4 max-h-[calc(100vh-180px)] overflow-y-auto scrollbar-thin scrollbar-thumb-nomadBlue-100 scrollbar-track-transparent ${colors.background}`}
        onScroll={handleLoadMore}
      >
        <AnimatePresence>
          {hits.map((hit) => (
            <Card 
              key={hit.objectID} 
              card={hit} 
              onQuickView={onQuickView}
            />
          ))}
        </AnimatePresence>
        {hasMore && (
          <div className="py-2">
            <SkeletonCard />
          </div>
        )}
      </div>
    </div>
  );
});


const SupplierKanbanBoard = ({ indexName, laneKeys, refreshingLanes, filters, onQuickView }) => {
  const laneDefinitions = useMemo(
    () => {
      // Calculate Unix timestamps for the next 24 hours
      const now = Date.now();
      const in24Hours  = now + (24 * 60 * 60 * 1000);

      return [
        {
          id: 'expiringProvisional',
          title: 'Provisionals Expiring < 24h',
          filter: `reservationStage:provisional AND dateProvisionalExpiry:${now} TO ${in24Hours}`,
        },
        {
          id: 'nomad_provisional',
          title: 'Nomad Provisionals',
          filter: 'reservationStage:provisional AND supplierUid:ded3a3ed-aeaf-4495-9069-7754a649de67',
        },
        {
          id: 'third_party_provisional',
          title: '3rd Party Provisionals',
          filter: 'reservationStage:provisional AND NOT supplierUid:ded3a3ed-aeaf-4495-9069-7754a649de67',
        },
      ];
    }, 
    [], 
  );

  return (
    <div className="h-full">
      <div className="flex space-x-6 h-full overflow-x-auto overflow-y-hidden pb-6 px-2 -mx-2">
        {laneDefinitions.map((lane) => (
          <Index 
            key={`${lane.id}_${laneKeys[lane.id] || 0}`} 
            indexName={indexName} 
            indexId={`${indexName}_${lane.id}`}
          >
            <Configure 
              filters={filters ? `${filters} AND ${lane.filter}` : lane.filter} 
              hitsPerPage={100} 
              distinct 
              enablePersonalization={false} 
            />
            {refreshingLanes[lane.id] ? (
              <div className="flex-1 bg-white rounded-lg border border-[#f0ece7] shadow-sm min-w-[350px] max-w-[400px]">
                <div className="p-4 border-b border-[#f0ece7]">
                  <h3 className="text-lg font-semibold text-nomadBlue-600">{lane.title}</h3>
                  <span className="text-sm text-gray-500">Refreshing...</span>
                </div>
                <div className="p-4">
                  {[...Array(3)].map((_, index) => (
                    <SkeletonCard key={index} />
                  ))}
                </div>
              </div>
            ) : (
              <Lane title={lane.title} laneId={lane.id}  onQuickView={onQuickView}/>
            )}
          </Index>
        ))}
      </div>
    </div>
  );
};

export default SupplierKanbanBoard;
