import React from "react";
import { HiOutlineBanknotes } from "react-icons/hi2";

function PricingDisplay({ price, totalPax }) {
  if (price === null || typeof price !== 'number') {
    return <div className="text-zinc-800 text-xl font-bold">Price unavailable</div>;
  }

  return (
    <div className="text-right">
      <div className="text-gray-600 text-xs uppercase flex items-center justify-end">
        <HiOutlineBanknotes className="mr-1" />
        Accommodation for {totalPax} guests
      </div>
      <div className="text-zinc-800 text-xl font-bold">${price.toLocaleString('en-US')}</div>
    </div>
  );
}

export default PricingDisplay;