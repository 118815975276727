import { collection, getDocs, query } from 'firebase/firestore';

import { db } from '../../db/firebase.config';

// Get users
const fetchName = 'templateSearchOptions';
const getTemplateSearchOptions = async () => {
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    const q = query(fetchRef);

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];
    console.log(`querySnapshot.docs.length ${querySnapshot.docs.length}`);
    querySnapshot.forEach((doc) => {
      return fetch.push({
        ...doc.data(),
      });
    });
    var jsonResult = {
      result: fetch,
    };
    console.log('REDUX: getTemplateSearchOptions');
    //console.log('templateSearchOptions jsonResult: ' + jsonResult.length);
    return { jsonResult };
  } catch (error) {
    console.log(error);
  }
};
const templateSearchOptionsService = {
  getTemplateSearchOptions,
};

export default templateSearchOptionsService;
