import 'react-confirm-alert/src/react-confirm-alert.css';

import { Timestamp, addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { addDays, dateShort, getPropObj, minusDays } from './generalFunctions';
import { propertyAccom, propertySelected, removeOne } from './availabilityFunctions';

import PropertiesSearchBox from '../components/PropertiesSearchBox';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { db } from '../../src/db/firebase.config';
import { deeShadow } from '../css/additional-styles/custom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const moment = require('moment');

const deleteGuestByUid = async (db, itineraryId, guestUid) => {
  try {
    const guestDocRef = doc(db, 'itineraries', itineraryId, 'guests', guestUid);
    await deleteDoc(guestDocRef);
    console.log(`Guest with UID ${guestUid} deleted successfully.`);
    return true;
  } catch (error) {
    console.error(`Error deleting guest with UID ${guestUid}:`, error);
    throw error;
  }
};

const upsertGuestByUuid = async (db, itineraryId, newGuests, uuid) => {
  // Find the guest in newGuests
  const allGuests = [...newGuests.childAges, ...newGuests.adultAges];
  const guestToUpsert = allGuests.find((guest) => guest.uuid === uuid);

  if (!guestToUpsert) {
    console.error(`Guest with UUID ${uuid} not found in newGuests`);
    return null;
  }

  const guestDocRef = doc(db, 'itineraries', itineraryId, 'guests', uuid);

  try {
    // Check if the document already exists
    const docSnap = await getDoc(guestDocRef);

    if (docSnap.exists()) {
      // Update existing document
      await setDoc(guestDocRef, guestToUpsert, { merge: true });
      console.log(`Guest with UUID ${uuid} updated successfully`);
    } else {
      // Create new document
      await setDoc(guestDocRef, guestToUpsert);
      console.log(`Guest with UUID ${uuid} added successfully`);
    }

    return guestToUpsert;
  } catch (error) {
    console.error(`Error upserting guest with UUID ${uuid}:`, error);
    throw error;
  }
};

const sendUpdateItineraryGuests = async (uid, newGuests, updatedItineraryData, updatedTransfersData, updatedRoomPax, uuid, deleteGuest) => {
  console.log('newGuests:', JSON.stringify(newGuests, null, 2));
  const docRef = doc(db, 'itineraries', uid);

  try {
    // ! await updateDoc(docRef, { guests: newGuests, itineraryData: updatedItineraryData, transfersData: updatedTransfersData, roomPax: updatedRoomPax });
    if (deleteGuest) {
      await deleteGuestByUid(db, uid, uuid);
    } else {
      try {
        const upsertedGuest = await upsertGuestByUuid(db, uid, newGuests, uuid);
        if (upsertedGuest) {
          console.log('Upserted guest:', upsertedGuest);
          // Handle successful upsert (e.g., update UI, refresh guest list)
        }
      } catch (error) {
        console.error('Failed to upsert guest:', error);
        // Handle error (e.g., show error message to user)
      }
    }
    await updateDoc(docRef, { itineraryData: updatedItineraryData, transfersData: updatedTransfersData, roomPax: updatedRoomPax });

    console.log('Guests array updated successfully');
    return Promise.resolve({ success: true, message: 'Guests array updated successfully' });
  } catch (error) {
    console.error('Error updating guests array:', error);
    return Promise.resolve({ success: false, message: 'Error updating guests array' });
  }
};

// ViewMainHeader.jsx
const requestCancellation = async (uid) => {
  const docRef = doc(db, 'itineraries', uid);
  try {
    await updateDoc(docRef, { status: 'pendingCancellation', resRequestStatus: '90' });
    console.log('Itinerary status updated');
    return Promise.resolve({ success: true, message: 'Itinerary status updated' });
  } catch (error) {
    console.error('Error updating itinerary status:', error);
    return Promise.resolve({ success: false, message: 'Error updating itinerary status' });
  }
};

const requestConfirmation = async (uid) => {
  const docRef = doc(db, 'itineraries', uid);
  try {
    await updateDoc(docRef, { status: 'pendingConfirmation', resRequestStatus: '30' });
    console.log('Itinerary status updated');
    return Promise.resolve({ success: true, message: 'Itinerary status updated' });
  } catch (error) {
    console.error('Error updating itinerary status:', error);
    return Promise.resolve({ success: false, message: 'Error updating itinerary status' });
  }
};

const releaseHold = async (uid) => {
  const docRef = doc(db, 'itineraries', uid);
  try {
    await updateDoc(docRef, { status: 'quotation', resRequestStatus: '0', releaseProvisionalHolds: true });
    console.log('Itinerary status updated');
    return Promise.resolve({ success: true, message: 'Itinerary status updated' });
  } catch (error) {
    console.error('Error updating itinerary status:', error);
    return Promise.resolve({ success: false, message: 'Error updating itinerary status' });
  }
};

const cancelRequestPendingProvisional = async (uid) => {
  const docRef = doc(db, 'itineraries', uid);
  try {
    await updateDoc(docRef, { status: 'quotation', cancelProvisionalRequests: true });
    console.log('Itinerary status updated');
    return Promise.resolve({ success: true, message: 'Itinerary status updated' });
  } catch (error) {
    console.error('Error updating itinerary status:', error);
    return Promise.resolve({ success: false, message: 'Error updating itinerary status' });
  }
};

const cancelRequestPendingConfirmation = async (uid) => {
  const docRef = doc(db, 'itineraries', uid);
  try {
    await updateDoc(docRef, { status: 'quotation', cancelConfirmationRequests: true });
    console.log('Itinerary status updated');
    return Promise.resolve({ success: true, message: 'Itinerary status updated' });
  } catch (error) {
    console.error('Error updating itinerary status:', error);
    return Promise.resolve({ success: false, message: 'Error updating itinerary status' });
  }
};

const createLogEntry = async (type, user, itineraryUid, itineraryCode, action) => {
  try {
    const logData = {
      type,
      date: Timestamp.now(),
      user: {
        uid: user.uid,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      },
      itineraryUid,
      itineraryCode,
      action,
    };

    const logRef = collection(db, 'logs');
    await addDoc(logRef, logData);
    console.log('Log entry created successfully');
    return Promise.resolve({ success: true, message: 'Log entry created successfully' });
  } catch (error) {
    console.error('Error creating log entry:', error);
    return Promise.resolve({ success: false, message: 'Error creating log entry' });
  }
};

const modifiedDetails = async (uid, user, field) => {
  const docRef = doc(db, 'itineraries', uid);
  try {
    if (field === 'pendingCancellation') {
      await updateDoc(docRef, { dateModified: Timestamp.now(), dateCancelled: Timestamp.now(), modifiedBy: user.uid });
    } else if (field === 'pendingConfirmation') {
      await updateDoc(docRef, { dateModified: Timestamp.now(), dateConfirmed: Timestamp.now(), modifiedBy: user.uid });
    } else {
      await updateDoc(docRef, { dateModified: Timestamp.now(), modifiedBy: user.uid });
    }

    console.log('modifiedDetails updated');
    return Promise.resolve({ success: true, message: 'Database updated' });
  } catch (error) {
    console.error('Error updating modifiedDetails:', error);
    return Promise.resolve({ success: false, message: 'Error updating' });
  }
};

export { sendUpdateItineraryGuests, requestCancellation, requestConfirmation, releaseHold, cancelRequestPendingProvisional, cancelRequestPendingConfirmation, createLogEntry, modifiedDetails };
