// import { collection, getDocs, query } from "firebase/firestore";

// import { db } from "../../db/firebase.config";

// // Get users
// const fetchName = "airports";
// const getAirports = async () => {
//   try {
//     //Get reference
//     const fetchRef = collection(db, fetchName);

//     //Create query
//     const q = query(fetchRef);

//     //Execute query
//     const querySnapshot = await getDocs(q);

//     const fetch = [];
//     console.log(`querySnapshot.docs.length ${querySnapshot.docs.length}`);
//     querySnapshot.forEach((doc) => {
//       return fetch.push({
//         ...doc.data(),
//       });
//     });
//     var jsonResult = {
//       result: fetch,
//     };
//     console.log("REDUX: getAirports");
//     return { jsonResult };
//   } catch (error) {
//     console.log(error);
//   }
// };
// const airportsService = {
//   getAirports,
// };

// export default airportsService;

import { collection, getDocs, query } from "firebase/firestore";

import { db } from "../../db/firebase.config";

const fetchName = "airports";

const getAirports = async () => {
  try {
    const fetchRef = collection(db, fetchName);
    const q = query(fetchRef);
    const querySnapshot = await getDocs(q);

    const fetch = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      // Convert Timestamp objects to ISO strings
      console.log('data: ' + JSON.stringify(data, null, 2));
      if (data.airportServices) {
        data.airportServices = data.airportServices.map((service) => ({
          ...service,
          //validFrom: service.validFrom.toDate().toISOString(),
          //validUntil: service.validUntil.toDate().toISOString(),
        }));
      }
      return data;
    });

    console.log(`querySnapshot.docs.length ${querySnapshot.docs.length}`);
    console.log("REDUX: getAirports");
    return { jsonResult: { result: fetch } };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const airportsService = {
  getAirports,
};

export default airportsService;
