import React from 'react';

function ButtonOutlineSmall({ text, onClick, className, color = 'green', icon, iconPosition = 'left' }) {
  const handleClick = async (event) => {
    if (onClick) {
      try {
        await onClick(event);
      } catch (error) {
        console.error('Error executing onClick function:', error);
      }
    }
  };

  const textColor = color === 'dark' ? 'text-nomadBlue-500' : 'text-nomadEvergreen-700';
  const borderColor = color === 'dark' ? 'border-nomadBlue-500' : 'border-gray-500';
  const hoverTextColor = color === 'dark' ? 'hover:text-nomadBlue-500' : 'hover:text-nomadEvergreen-800';
  const hoverBorderColor = color === 'dark' ? 'hover:border-nomadBlue-700' : 'hover:border-nomadGray-900';

  return (
    <button
    className={`bg-white ${textColor} border ${borderColor} text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out ${hoverTextColor} ${hoverBorderColor} ${className} flex items-center justify-center`}
    onClick={handleClick}
  >
    {icon && iconPosition === 'left' && <span className="mr-2">{icon}</span>}
    {text}
    {icon && iconPosition === 'right' && <span className="ml-2">{icon}</span>}
  </button>
  );
}

export default ButtonOutlineSmall;

// import React from 'react';

// function ButtonOutlineSmall({ text, onClick, className }) {
//   return (
//     <button
//       className={`bg-white text-nomadPrimary-900 border border-nomadPrimary-900 text-xs font-semibold px-6 py-3 rounded-full shadow transition duration-300 ease-in-out hover:bg-nomadPrimary-100 ${className}`}
//       onClick={onClick}
//     >
//       {text}
//     </button>
//   );
// }

// export default ButtonOutlineSmall;
