import 'react-toastify/dist/ReactToastify.css';
import './css/style.scss';

import * as Sentry from '@sentry/react';

import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { logout, reset } from './features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';

import Account from './pages/settings/Account';
import Availability from './pages/availability/checkAvailability';
import ButtonOutlineSmall from './components/Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from './components/Buttons/ButtonPrimarySmall';
import Changelog from './pages/info/Changelog';
import ConfirmItinerary from './pages/itinerary/confirm/confirmItinerary';
import CreateItinerary from './pages/itinerary/create/createItinerary';
import EditItinerary from './pages/itinerary/edit/editItinerary';
import EditUser from './pages/users/EditUser';
import ErrorPage from './pages/Error';
import Faqs from './pages/faqs/faqs';
import Forgot from './pages/ForgotPassword';
import { GlobalDebug } from './utils/remove-consoles';
import Home from './pages/Home';
import ManageItineraries from './pages/itinerary/manage/manageItineraries';
import Modal from './components/Modal';
//Added by Harry
import NomadCamps from './pages/portal/nomadCamps/nomadCamps';
import PageNotFound from './pages/PageNotFound';
import PrivateRoute from './components/PrivateRoute';
import Property from './pages/portal/propertyLibrary/property/property';
import PropertyLibrary from './pages/portal/propertyLibrary/propertyLibrary';
import SearchUser from './pages/users/SearchUser';
import SeasonalGuides from './pages/portal/seasonalGuides/seasonalGuides';
import Signin from './pages/Signin';
import Signout from './pages/Signout';
import Signup from './pages/Signup';
import Spinner from './components/Spinner';
import SupplierRequest from './pages/supplierRequests/SupplierRequest';
import ManageSuppliers from './pages/management/suppliers/suppliers';
import Templates from './pages/Templates';
//import Test from "./pages/api/Test";
import { ToastContainer } from 'react-toastify';
import TravelInfo from './pages/portal/travelInfo/travelInfo';
import Users from './pages/users/Users';
import Version from './pages/Version';
import ViewItinerary from './pages/itinerary/view/viewItinerary';
import { db } from './db/firebase.config';
import ls from '@livesession/sdk';
import { updateIntercom } from './intercom/intercom';
import { useNavigate } from 'react-router-dom';

// import useErrorBoundary from "./hooks/useErrorBoundary";

// Sentry
//if (process.env.NODE_ENV !== 'development') {
if (process.env.REACT_APP_ENV_CODE !== 'd') {
  Sentry.init({
    dsn: 'https://f18176fb51aff302b57206d7c1e672b2@o4505843029508096.ingest.sentry.io/4505843032588288',
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/nomad-.*\.vercel\.app/, /^https:\/\/test\.hornbill\.app/, /^https:\/\/hornbill\.app/],
      }),
      //new Sentry.Integrations.Breadcrumbs({ console: false }),
      //...(process.env.NODE_ENV !== 'development' ? [new Sentry.Replay()] : []),
      //new Sentry.Replay(),
      new Sentry.Replay({
        maskAllText: false,
        unmask: ['.sentry-unmask', '[data-sentry-unmask]'], // Add your own selectors here
      }),
    ],
  });
  const originalConsoleError = console.error;

  console.error = function (message, ...rest) {
    Sentry.captureMessage(message, 'error');
    originalConsoleError.apply(console, [message, ...rest]);
  };

  const originalConsoleWarn = console.warn;

  console.warn = function (message, ...rest) {
    Sentry.captureMessage(message, 'warning');
    originalConsoleWarn.apply(console, [message, ...rest]);
  };
}

function App() {
  // let navigate = useNavigate();

  // const [error, catchError] = useErrorBoundary(null);

  // useEffect(() => {
  //   if (error) {
  //     console.log("error: " + error);
  //     navigate("/error");
  //   }
  // }, [error, navigate]);

  // useEffect(() => {
  //   process.env.REACT_APP_ENV_CODE === 'l' &&
  //     // || process.env.REACT_APP_ENV_CODE === 'p'
  //     GlobalDebug(false);
  // }, []);

  //App active
  const [active, setActive] = useState(false);
  const [userActive, setUserActive] = useState(false);
  // console.log(process.env.REACT_APP_FB_APIKEY); // remove this after you've confirmed it is working
  //console.log(JSON.stringify(process.env, undefined, 4));

  //const [appVersion] = useState(process.env.REACT_APP_VERSION);
  // const [dbVersion, setDbVersion] = useState("");

  // listen for app active status
  onSnapshot(doc(db, 'app', 'status'), { includeMetadataChanges: true }, (doc) => {
    setActive(doc.data().active);
    // setDbVersion(doc.data().version);
    // console.log("dbVersion: " + dbVersion);
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  if (user && user.uid) {
    onSnapshot(doc(db, 'users', user.uid), { includeMetadataChanges: true }, (doc) => {
      if (doc.data().logout === true) {
        console.log('logged out');
        dispatch(logout());
        dispatch(reset());
        window.location.reload();
      }
    });

    // Assuming user info is available at this point
    Sentry.setUser({
      email: user.email,
      fullName: user.firstName + ' ' + user.lastName,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }

  useEffect(() => {
    if (
      window.location.hostname.includes('hornbill.app') &&
      user &&
      user.email &&
      user.email !== 'prideaux@gmail.com' &&
      user.email !== 'roger.prideaux@gmail.com' &&
      user.email !== 'harry.prowse@nomad-tanzania.net'
    ) {
      GlobalDebug(false);
    }
  }, []);

  //console.log(process.env.REACT_APP_VERSION);

  const location = useLocation();

  // if (localStorage.getItem("user")) {
  //   const localUser = JSON.parse(localStorage.getItem("user"));

  //   console.log(localUser.uid);
  // } else {
  //   console.log("no user");
  // }
  useEffect(() => {
    updateIntercom();
  }, []);

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]); // triggered on route change

  // Disable back
  const [showModal, setShowModal] = useState(false);

  const handlePopState = useCallback(
    (e) => {
      e.preventDefault();
      setShowModal(true);
      window.history.pushState(null, null, location.pathname);
    },
    [location.pathname],
  );

  useEffect(() => {
    window.history.pushState(null, null, location.pathname);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handlePopState, location.pathname]);

  const handleContinue = () => {
    setShowModal(false);

    // Clear the entire history
    const currentPage = window.location.href;
    window.history.pushState(null, '', currentPage);
    window.history.pushState(null, '', currentPage);
    window.history.replaceState(null, '', '/');

    navigate('/', { replace: true });
  };

  const handleCancel = () => {
    setShowModal(false);
    window.history.pushState(null, null, location.pathname);
  };

  if (active) {
    return (
      <>
        <div>
          <Modal show={showModal}>
            <h2 className="text-xl font-bold mb-4">Are you sure you want to go back?</h2>
            <p className="mb-6">Continuing will take you to your Dashboard.</p>
            <div className="flex justify-center space-x-4">
              <ButtonOutlineSmall onClick={handleCancel} text="Cancel" />
              <ButtonPrimarySmall onClick={handleContinue} text="Continue" />
            </div>
          </Modal>
        </div>
        <Routes>
          <Route path="/nomadCamps" element={<PrivateRoute />}>
            <Route path="/nomadCamps" element={<NomadCamps />} />
          </Route>

          <Route path="/propertyLibrary" element={<PrivateRoute />}>
            <Route path="/propertyLibrary" element={<PropertyLibrary />} />
          </Route>

          <Route path="/propertyLibrary/:uid" element={<PrivateRoute />}>
            <Route path="/propertyLibrary/:uid" element={<Property />} />
          </Route>

          <Route path="/travelInfo" element={<PrivateRoute />}>
            <Route path="/travelInfo" element={<TravelInfo />} />
          </Route>

          <Route path="/seasonalGuides" element={<PrivateRoute />}>
            <Route path="/seasonalGuides" element={<SeasonalGuides />} />
          </Route>

          <Route path="/templates" element={<PrivateRoute />}>
            <Route path="/templates" element={<Templates />} />
          </Route>

          <Route path="/supplier-Request/:documentUid" element={<SupplierRequest />} />

          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/signout" element={<Signout />} />

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
          </Route>

          <Route path="/settings/account" element={<PrivateRoute />}>
            <Route path="/settings/account" element={<Account />} />
          </Route>

          <Route path="/settings/account" element={<PrivateRoute />}>
            <Route path="/settings/account" element={<Account />} />
          </Route>

          <Route path="/account/" element={<PrivateRoute />}>
            <Route path="/account/" element={<Account />} />
          </Route>

          <Route path="/info/changelog" element={<PrivateRoute />}>
            <Route path="/info/changelog" element={<Changelog />} />
          </Route>

          <Route path="/users/users" element={<PrivateRoute />}>
            <Route path="/users/users" element={<Users />} />
          </Route>
          <Route path="/users/edit-user/:getUid" element={<PrivateRoute />}>
            <Route path="/users/edit-user/:getUid" element={<EditUser />} />
          </Route>
          <Route path="/users/search" element={<PrivateRoute />}>
            <Route path="/users/search" element={<SearchUser />} />
          </Route>

          <Route path="/availability/checkAvailability" element={<PrivateRoute />}>
            <Route path="/availability/checkAvailability" element={<Availability />} />
          </Route>

          <Route path="/itinerary/create/createItinerary" element={<PrivateRoute />}>
            <Route path="/itinerary/create/createItinerary" element={<CreateItinerary />} />
          </Route>
          <Route path="/itinerary/confirm/confirmItinerary/:uid" element={<PrivateRoute />}>
            <Route path="/itinerary/confirm/confirmItinerary/:uid" element={<ConfirmItinerary />} />
          </Route>
          <Route path="/itinerary/manage/manageItineraries" element={<PrivateRoute />}>
            <Route path="/itinerary/manage/manageItineraries" element={<ManageItineraries />} />
          </Route>
          <Route path="/itinerary/edit/editItinerary/:uid" element={<PrivateRoute />}>
            <Route path="/itinerary/edit/editItinerary/:uid" element={<EditItinerary />} />
          </Route>
          <Route path="/itinerary/view/viewItinerary/:uid" element={<PrivateRoute />}>
            <Route path="/itinerary/view/viewItinerary/:uid" element={<ViewItinerary />} />
          </Route>

          <Route path="/management/suppliers" element={<PrivateRoute />}>
            <Route path="/management/suppliers" element={<ManageSuppliers />} />
          </Route>

          <Route path="/faqs/faqs" element={<PrivateRoute />}>
            <Route path="/faqs/faqs" element={<Faqs />} />
          </Route>

          <Route path="/version" element={<Version />} />

          <Route path="/error" element={<ErrorPage />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ToastContainer />
      </>
    );
  } else return <Spinner />;
}

export default App;
