import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import templateSearchOptionsService from './templateSearchOptionsService';

const initialState = {
  allTemplateSearchOptions: [],
  templateSearchOptionsError: false,
  templateSearchOptionsSuccess: false,
  templateSearchOptionsLoading: false,
  templateSearchOptionsMessage: '',
};

// Get Airports
export const getTemplateSearchOptions = createAsyncThunk('templateSearchOptions/getTemplateSearchOptions', async (templateSearchOption, thunkAPI) => {
  try {
    return await templateSearchOptionsService.getTemplateSearchOptions(templateSearchOption);
  } catch (error) {
    console.log(error);
    const message = 'No templateSearchOptions';
    return thunkAPI.rejectWithValue(message);
  }
});

export const templateSearchOptionsSlice = createSlice({
  name: 'templateSearchOptions',
  initialState,
  reducers: {
    reset: (state) => {
      state.templateSearchOptionsLoading = false;
      state.templateSearchOptionsError = false;
      state.templateSearchOptionsSuccess = false;
      state.templateSearchOptionsMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplateSearchOptions.pending, (state) => {
        state.templateSearchOptionsLoading = true;
      })
      .addCase(getTemplateSearchOptions.fulfilled, (state, action) => {
        state.templateSearchOptionsLoading = false;
        state.templateSearchOptionsSuccess = true;
        state.allTemplateSearchOptions = action.payload.jsonResult;
      })
      .addCase(getTemplateSearchOptions.rejected, (state, action) => {
        state.templateSearchOptionsLoading = false;
        state.templateSearchOptionsError = true;
        state.templateSearchOptionsMessage = action.payload;
      });
  },
});

export const { reset } = templateSearchOptionsSlice.actions;
export default templateSearchOptionsSlice.reducer;
