import { agentSelect } from '../functions/itineraryFunctions';
export function EnquiryItem({
  hit,
  components,
  setSelectedEnquiryTitle,
  setClientName,
  setSelectedConsultantName,
  setSelectedConsultantUid,
  setSelectedConsultant,
  setConsultantResRequestId,
  setEnquiryUid,
}) {
  return (
    <div
      className="--aa-base-z-index z-20"
      onClick={(event) => {
        setEnquiryUid(hit.uid);
        setSelectedEnquiryTitle(hit.title);
        setClientName(hit.clientName);
        setSelectedConsultantUid(hit.agentConsultant.id); // set to uid
        setConsultantResRequestId('NA');
        setSelectedConsultantName(hit.agentConsultant.name === '' ? null : hit.agentConsultant.name);
        setSelectedConsultant(true);
      }}
    >
      <button className="aa-ItemActionButton">
        <div className="aa-ItemContent">
          <div className="aa-ItemTitle text-left">
            <components.Highlight hit={hit} attribute="title" />
          </div>
        </div>
      </button>
    </div>
  );
}
