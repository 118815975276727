import React, { useEffect, useState } from 'react';
import SeasonalGuideTable from './components/SeasonalGuideTable';
import Sidebar from '../../../partials/Sidebar';
import { fetchSeasonalGuideData } from '../functions/propertyFunctions';

function SeasonalGuides() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [seasonalGuides, setSeasonalGuides] = useState([]);
  const [selectedPark, setSelectedPark] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const guides = await fetchSeasonalGuideData();
        setSeasonalGuides(guides);
  
        if (guides.length > 0) {
          setSelectedPark(guides[0].name);
        }
      } catch (error) {
        console.error('Error fetching seasonal guides:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchGuides();
  }, []);

  const parkOptions = ['Select Park', ...new Set(seasonalGuides.map((guide) => guide.name))];
  const monthOptions = ['Select Month', ...new Set(seasonalGuides.flatMap((guide) => guide.months.map((month) => month.month)))];

  const filteredData = seasonalGuides
    .filter((guide) => selectedPark === '' || guide.name === selectedPark)
    .map((guide) => ({
      ...guide,
      months: guide.months.filter((month) => selectedMonth === '' || month.month === selectedMonth),
    }))
    .filter((guide) => guide.months.length > 0);

  const handleParkChange = (e) => {
    setSelectedPark(e.target.value);
    setSelectedMonth('');
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setSelectedPark('');
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      
      <div className="flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="max-w-7xl mx-auto">
              <header className="mb-8">
                <h1 className="text-4xl font-roca text-nomadBlue mb-2">
                  Seasonal Guides
                </h1>
                <p className="text-lg font-sans max-w-4xl">
                  Browse seasonal information by park or month.
                </p>
              </header>

              <div className="bg-white rounded-md shadow p-6 mb-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="park-select" className="block text-sm font-medium text-gray-700 mb-2">
                      Select a park:
                    </label>
                    <select
                      id="park-select"
                      className="w-full p-2 border-gray-300 rounded-md focus:ring-nomadBlue focus:border-nomadBlue"
                      value={selectedPark}
                      onChange={handleParkChange}
                    >
                      {parkOptions.map((park) => (
                        <option key={park} value={park}>{park}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="month-select" className="block text-sm font-medium text-gray-700 mb-2">
                      Select a month:
                    </label>
                    <select
                      id="month-select"
                      className="w-full p-2 border-gray-300 rounded-md focus:ring-nomadBlue focus:border-nomadBlue"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      {monthOptions.map((month) => (
                        <option key={month} value={month}>{month}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-md shadow overflow-hidden">
                {isLoading ? (
                  <div className="p-4 text-center">Loading...</div>
                ) : (
                  <SeasonalGuideTable parkData={filteredData} />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default SeasonalGuides;