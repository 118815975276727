import 'react-datepicker/dist/react-datepicker.css';
import 'flatpickr/dist/flatpickr.css';
import '../css/datePickerStyles.css';

import { Configure, Hits, InstantSearch, Pagination, connectSearchBox } from 'react-instantsearch-dom';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import PropertiesSearchBox from './PropertiesSearchBox';
import { PropertyItem } from './PropertyItem';
import { XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import qs from 'qs';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

function PropertiesSlideOutComp({
  isOpen,
  onClose,
  deeShadow,
  searchClient,
  searchState,
  setSearchState,
  filters,
  properties,
  setLoadingData,
  setCallMakeRooms,
  SetGetPropertiesAndStock,
  customLocations,
  setFetchCustom,
  VirtualSearchBox,
  setSwitchParkId,
  setSwitchId,
  setSwitchStartDate,
  setSwitchEndDate,
  onOpenPropertyModal,
  switchId, 
}) {
  const handleChangePropertiesSearch = (event) => {
    // !console.log(event.currentTarget.value);
  };

  const resetFormClose = () => {
    //resetForm();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      //resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setSwitchParkId(null);
      setSwitchId(null);
      setSwitchStartDate(null);
      setSwitchEndDate(null);
    }
  }, [isOpen, setSwitchEndDate, setSwitchId, setSwitchParkId, setSwitchStartDate]);



  

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-0 flex justify-end">
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <DialogPanel className="pointer-events-auto w-fit">
                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="flex-1 h-full overflow-y-auto">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto">
                        <div className="bg-white px-4 pt-6 sm:px-6">
                          <div className="flex items-center ">
                            {' '}
                            <div className="ml-0 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative rounded-md bg-white text-black hover:text-black"
                                onClick={() => {
                                  resetFormClose();
                                  setSwitchParkId(null);
                                  setSwitchId(null);
                                  setSwitchStartDate(null);
                                  setSwitchEndDate(null);
                                }}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                              <span className={`text-base text-black ml-5`}>{switchId ? 'Switch Property' : 'Add Properties'}</span>
                            </div>
                          </div>{' '}
                          <DialogTitle className={`mt-0 text-2xl font-base font-semibold leading-6 `}> </DialogTitle>
                        </div>

                        <div className="mb-5 mx-6">
                          {/* PROPERTIES -> START */}
                          <div className="pt-3 bg-white rounded-lg w-min h-[670px] top-0">
                            {/* TABS */}
                            {/* TODO: Deal with border color and dynamic colors */}
                            <div className={``}>
                              <div className="">
                                {/* TABS */}
                                {/* TODO: Deal with border color and dynamic colors */}
                                <div className="text-slate-800 text-sm font-normal mb-3">{switchId ? 'Switch to a property in the same location or click "More Info" to discover details about a property.' : 'Select a property to add to your itinerary or click "More Info" to discover details about a property. '}</div>

                                <InstantSearch indexName="properties" searchClient={searchClient} searchState={searchState} onSearchStateChange={setSearchState}>
                                  <Configure filters={filters} hitsPerPage={9} />
                                  {/* Add a wrapper div element with the desired CSS styles */}

                                  <div className="hits-wrapper createBooking">
                                    {/* <div className="results">
                                      <div className="mb-4">
                                        <PropertiesSearchBox onChange={handleChangePropertiesSearch} />
                                      </div>
                                    </div> */}
                                    <div id="" className="">
                                      <div className="mb-5">
                                        {' '}
                                        <PropertiesSearchBox onChange={handleChangePropertiesSearch} />
                                      </div>

                                      {/* <div id="scrollableDiv" className="overflow-y-scroll h-full"> */}
                                      {/* <div id="scrollableDiv" className="overflow-y-auto h-[700px]"> */}
                                      <div className="z-[1000]">
                                        <Hits
                                          hitComponent={(props) => (
                                            <PropertyItem
                                              {...props}
                                              properties={properties}
                                              setLoadingData={setLoadingData}
                                              setCallMakeRooms={setCallMakeRooms}
                                              SetGetPropertiesAndStock={SetGetPropertiesAndStock}
                                              customLocations={customLocations}
                                              setFetchCustom={setFetchCustom}
                                              onOpenPropertyModal={onOpenPropertyModal} 
                                              //roomPax={roomPax}
                                              // buildCustomLocations={buildCustomLocations}
                                              // setCustomLocations={setCustomLocations}
                                            />
                                          )}
                                        />
                                      </div>
                                      {/* <Hits hitComponent={Hit} /> */}
                                      <Pagination />
                                      <VirtualSearchBox />

                                      {/* ... */}
                                    </div>
                                  </div>
                                </InstantSearch>
                              </div>
                            </div>
                          </div>
                          {/* PROPERTIES -> END */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PropertiesSlideOutComp;
