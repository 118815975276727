import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import airlinesService from './airlinesService';

const initialState = {
  allAirlines: [],
  airlinesError: false,
  airlinesSuccess: false,
  airlinesLoading: false,
  airlinesMessage: '',
};

// Get Airports
export const getAirlines = createAsyncThunk('airlines/getAirlines', async (airline, thunkAPI) => {
  try {
    return await airlinesService.getAirlines(airline);
  } catch (error) {
    console.log(error);
    const message = 'No airlines';
    return thunkAPI.rejectWithValue(message);
  }
});

export const airlinesSlice = createSlice({
  name: 'airlines',
  initialState,
  reducers: {
    reset: (state) => {
      state.airlinesLoading = false;
      state.airlinesError = false;
      state.airlinesSuccess = false;
      state.airlinesMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAirlines.pending, (state) => {
        state.airlinesLoading = true;
      })
      .addCase(getAirlines.fulfilled, (state, action) => {
        state.airlinesLoading = false;
        state.airlinesSuccess = true;
        state.allAirlines = action.payload.jsonResult;
      })
      .addCase(getAirlines.rejected, (state, action) => {
        state.airlinesLoading = false;
        state.airlinesError = true;
        state.airlinesMessage = action.payload;
      });
  },
});

export const { reset } = airlinesSlice.actions;
export default airlinesSlice.reducer;
