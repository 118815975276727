import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import companiesService from './companiesService';

export const fetchCompanies = createAsyncThunk(
  'companies/fetchCompanies',
  async ({ userUid, userCompanyId, forceRefresh = false }, { getState }) => {
    if (forceRefresh) {
      return await companiesService.fetchCompanies(userUid, userCompanyId);
    }
    
    const { companies, selectedCompany } = getState().companies;
    if (companies.length > 0 && selectedCompany) {
      console.log('Companies and selected company already exist in state');
      return { companyData: companies, selectedCompanyId: selectedCompany.uid };
    }
    
    return await companiesService.fetchCompanies(userUid, userCompanyId);
  }
);

export const updateSelectedCompany = createAsyncThunk(
  'companies/updateSelectedCompany',
  async ({ updatedCompanyId, userUid }, { getState }) => {
    await companiesService.updateSelectedCompany(updatedCompanyId, userUid);
    const { companies } = getState().companies;
    return companies.find((company) => company.uid === updatedCompanyId);
  }
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    companies: [],
    selectedCompany: null,
    selectedCompanyPermissions: {
      accessLevel: null,
      nomadPropertiesOnly: false,
      allowBookings: false,
    },
    selectedCompanyRateBand: {
      commission: null,
      name: null,
    },
    selectedCompanyNomadCompany: null,
  },
  reducers: {
    clearSelectedCompany: (state) => {
      console.log('Clearing selected company');
      state.selectedCompany = null;
      state.selectedCompanyPermissions = {
        accessLevel: null,
        nomadPropertiesOnly: false,
        allowBookings: false,
      };
      state.selectedCompanyRateBand = {
        commission: null,
        name: null,
      };
      state.selectedCompanyNomadCompany = null;
      state.companies = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        console.log('fetchCompanies pending');
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        console.log('fetchCompanies fulfilled');
        state.companies = action.payload.companyData;
        const selectedCompany = action.payload.companyData.find((company) => company.uid === action.payload.selectedCompanyId);
        state.selectedCompany = selectedCompany || action.payload.companyData[0];
        state.selectedCompanyPermissions = state.selectedCompany ? state.selectedCompany.permissions : {
          accessLevel: null,
          nomadPropertiesOnly: false,
          allowBookings: false,
        };
        state.selectedCompanyRateBand = state.selectedCompany ? state.selectedCompany.rateBand : {
          commission: null,
          name: null,
        };
        state.selectedCompanyNomadCompany = state.selectedCompany ? state.selectedCompany.nomadCompany : null;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        console.log('fetchCompanies rejected');
        console.error('Error in fetchCompanies:', action.error);
      })
      .addCase(updateSelectedCompany.fulfilled, (state, action) => {
        state.selectedCompany = action.payload;
        state.selectedCompanyPermissions = action.payload ? action.payload.permissions : {
          accessLevel: null,
          nomadPropertiesOnly: false,
          allowBookings: false,
        };
        state.selectedCompanyRateBand = action.payload ? action.payload.rateBand : {
          commission: null,
          name: null,
        };
        state.selectedCompanyNomadCompany = action.payload ? action.payload.nomadCompany : null;
      });
  },
});

export const { clearSelectedCompany } = companiesSlice.actions;

export default companiesSlice.reducer;