import React, { useState} from 'react';
import { FaFilePdf, FaFileExcel } from 'react-icons/fa';
import { IoIosLink } from 'react-icons/io';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ButtonPrimarySmall from './Buttons/ButtonPrimarySmall';
import { createUrl } from '../functions/makeFunctions';
import ExcelCostingModal from './ExcelCostingModal';

function ExportDocuments({ booking, user }) {
  const [download, setDownload] = useState('costing');
  const [generating, setGenerating] = useState(false);
  const [generatedCopy, setGeneratedCopy] = useState(null);
  const [generatedDownload, setGeneratedDownload] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const maxVersion = booking.userVersion;

  const handleClickOpen = () => {
    window.open(generatedDownload, '_blank');
  };

  const handleCopy = async () => {
    if (generatedCopy !== null) {
      try {
        await navigator.clipboard.writeText(generatedCopy);
        toast.success('Link copied to clipboard');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    } else {
      toast.error('You must generate a link first');
    }
  };

  const handleGenerateExcelCosting = (versions) => {
    const item = 'costing';
    const versionParam = versions.length > 0 ? `&versions=${versions.join(',')}` : '';
    const multipleVersions = versions.length !== 1;
    handleClickUrl(booking.uid, item, versionParam, multipleVersions);
  };

  const handleClickUrl = async (firestoreUid, item, versionParam = '', multipleVersions = false) => {
    setGenerating(true);
    setGeneratedCopy(null);
    setGeneratedDownload(null);
    const result = await createUrl(firestoreUid, item, versionParam, multipleVersions);
    console.log('result new:', JSON.stringify(result, null, 2));
    if (result.error) {
      toast.error(result.errorMsg);
    } else {
      setGeneratedCopy(result.copy);
      setGeneratedDownload(result.download);
    }
    setGenerating(false);
  };

  const options = [
    {
      value: 'brochure',
      label: (
        <div className="flex items-center text-zinc-600">
          <FaFilePdf size={20} className="mr-2 text-[#eb5c2a]" /> Itinerary Brochure (.pdf)
        </div>
      ),
    },
    {
      value: 'costing',
      label: (
        <div className="flex items-center text-zinc-600">
          <FaFileExcel size={20} className="mr-2 text-[#337230]" /> Itemised Quote (.xlsx)
        </div>
      ),
    },
    {
      value: 'invoice',
      label: (
        <div className="flex items-center text-zinc-600">
          <FaFilePdf size={20} className="mr-2 text-[#eb5c2a]" /> Invoice (.pdf)
        </div>
      ),
    },
    user.internalUser && {
      value: 'summary',
      label: (
        <div className="flex items-center text-zinc-600">
          <FaFilePdf size={20} className="mr-2 text-[#eb5c2a]" /> Nomad RR Summary (.pdf)
        </div>
      ),
    },
    booking &&
      booking.status === 'confirmed' && {
        value: 'operational',
        label: (
          <div className="flex items-center text-zinc-600">
            <FaFileExcel size={20} className="mr-2 text-[#337230]" /> Operational View (.xlsx)
          </div>
        ),
      },
  ].filter(Boolean);

  return (
    <div className="bg-white rounded-lg shadow p-4 sm:p-6">
      <h2 className="text-sm font-medium text-gray-500 mb-3">EXPORT DOCUMENTS</h2>
      <div className="flex flex-col lg:flex-row lg:items-center space-y-3 lg:space-y-0 lg:space-x-4">
        <Select
          className="w-full md:w-64"
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderRadius: '0.375rem',
              borderColor: state.isFocused ? '#2A99A3' : provided.borderColor,
              boxShadow: state.isFocused ? '0 0 0 2px rgba(42, 153, 163, 0.3)' : provided.boxShadow,
              '&:hover': {
                borderColor: state.isFocused ? '#2A99A3' : provided.borderColor,
              },
              height: '2.75rem',
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? '#EEECEC' : 'white',
              '&:hover': {
                backgroundColor: 'rgb(245 245 244)',
              },
              height: '2.75rem',
              display: 'flex',
              alignItems: 'center',
            }),
            menu: (provided) => ({
              ...provided,
              borderRadius: '0.375rem',
              width: 'max-content',
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none',
            }),
          }}
          options={options}
          onChange={(selectedOption) => {
            setGeneratedCopy(null);
            setGeneratedDownload(null);
            setDownload(selectedOption.value);
          }}
          defaultValue={options[1]} // Set default to 'costing'
        />
        <div className="flex flex-col md:flex-row lg:flex-row space-y-3 md:space-y-0 md:space-x-4 lg:space-x-4">
        <ButtonPrimarySmall
        text={generating ? 'Generating' : generatedDownload !== null ? 'Download' : 'Generate'}
        onClick={async () => {
          if (generating || (generatedDownload !== null && generatedDownload === '')) {
            return;
          }
          if (generatedDownload !== null) {
            handleClickOpen();
          } else if (download === 'costing') {
            setIsModalOpen(true);
          } else {
            handleClickUrl(booking.uid, download);
          }
        }}
        className={`w-full sm:w-auto min-w-[150px] ${generating || (generatedDownload !== null && generatedDownload === '') ? 'opacity-50 cursor-not-allowed' : ''}`}
        color="dark"
        isLoading={generating}
        disabled={generating || (generatedDownload !== null && generatedDownload === '')}
      />
          <button
            onClick={handleCopy}
            disabled={generating || generatedCopy === null}
            className={`flex items-center justify-center w-full sm:w-auto px-4 py-2 text-sm font-medium rounded-md ${
              generating || generatedCopy === null ? 'text-gray-300 bg-gray-100' : 'text-blue-700 bg-blue-100 hover:bg-blue-200'
            }`}
          >
            <IoIosLink className="mr-2" /> Copy
          </button>
        </div>
      </div>
      <ExcelCostingModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        maxVersion={maxVersion}
        onGenerate={handleGenerateExcelCosting}
      />
    </div>
  );
}

export default ExportDocuments;
