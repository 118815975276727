import { collection, getDocs, query, where } from 'firebase/firestore';

import { db } from '../../db/firebase.config';

// Get users
const fetchName = 'suppliers';
const getSuppliers = async () => {
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    const q = query(fetchRef, where('active', '==', true));

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];
    console.log(`querySnapshot.docs.length ${querySnapshot.docs.length}`);
    querySnapshot.forEach((doc) => {
      return fetch.push({
        ...doc.data(),
        uid: doc.id,
      });
    });
    var jsonResult = {
      result: fetch,
    };
    console.log('REDUX: getSuppliers');
    return { jsonResult };
  } catch (error) {
    console.log(error);
  }
};
const suppliersService = {
  getSuppliers,
};

export default suppliersService;
