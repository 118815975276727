// STAYS OPEN WHEN CLICKED OUTSIDE
// This function is called when the user clicks on the button
// It checks if the user input is valid and then sends the input to the server

import React from 'react';

const CustomModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return <div className="fixed inset-0 bg-white bg-opacity-90 flex justify-center items-center z-[50]">{children}</div>;
};

export default CustomModal;

// Custom variable to close/open on click outside:
//<CustomModal isOpen={modalOpen} onClose={handleCloseModal} closeOnBackdropClick={false}>

// const CustomModal = ({ isOpen, onClose, children, closeOnBackdropClick,  }) => {
//   if (!isOpen) return null;

//   const handleBackdropClick = (e) => {
//     if (closeOnBackdropClick && e.target === e.currentTarget) {
//       onClose();
//     }
//   };

//   return (
//     <div
//       className="fixed inset-0 bg-white bg-opacity-90 flex justify-center items-center z-50"
//       onClick={handleBackdropClick}
//     >
//       <div onClick={(e) => e.stopPropagation()}>
//         {children}
//       </div>
//     </div>
//   );
// };

// CustomModal.defaultProps = {
//   closeOnBackdropClick: true, // Default to true if not provided
// };

// export default CustomModal;

// CLOSES WHEN CLICKED OUTSIDE
// import React from "react";

// const CustomModal = ({ isOpen, onClose, children }) => {
//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
//       <div className="bg-white rounded p-6 max-w-[90%] z-20">
//         {children}
//         <button className="mt-4 bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500 transition duration-200" onClick={onClose}>
//           Close
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CustomModal;
