import React, { useState, Fragment, useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { DialogPanel, Dialog, TabPanel, TabPanels, Transition, Tab, TabList, TabGroup, TransitionChild, Switch } from '@headlessui/react';
import ButtonOutlineSmall from './Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from './Buttons/ButtonPrimarySmall';
import { FaFileExcel, FaFilePdf, FaSpinner, FaExclamationTriangle } from 'react-icons/fa';
import { XMarkIcon } from '@heroicons/react/24/solid';


// component for adding/editing email addresses
const EmailInput = ({ value, onChange, placeholder, label }) => {
  const [inputValue, setInputValue] = useState('');
  
  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const email = inputValue.trim();
      if (email && validateEmail(email) && !value.includes(email)) {
        onChange([...value, email]);
        setInputValue('');
      }
    } else if (e.key === 'Backspace' && !inputValue && value.length > 0) {
      onChange(value.slice(0, -1));
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      const uniqueEmails = [...new Set([...value, ...emails])];
      onChange(uniqueEmails);
    }
  };

  const removeEmail = (indexToRemove) => {
    onChange(value.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <div className="min-h-[38px] p-1 border rounded-md focus-within:ring-2 focus-within:ring-nomadBlue focus-within:border-nomadBlue bg-white">
        <div className="flex flex-wrap gap-1">
          {value.map((email, index) => (
            <span
              key={index}
              className="inline-flex items-center px-2 py-1 rounded-md text-sm bg-gray-100"
            >
              {email}
              <button
                type="button"
                onClick={() => removeEmail(index)}
                className="ml-1 text-gray-500 hover:text-gray-700"
              >
                <XMarkIcon className="h-4 w-4" />
              </button>
            </span>
          ))}
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            className="flex-grow outline-none border-none p-1 text-sm min-w-[120px]"
            placeholder={value.length === 0 ? placeholder : ''}
          />
        </div>
      </div>
      <p className="text-xs text-gray-500 mt-1">
        Press Enter or comma to add email. Paste multiple emails at once.
      </p>
    </div>
  );
};

const ItineraryEmailForm = ({ prefillContent, staticHeader, staticFooter, onClose, modalTitle, from, to, subject, onSend, generatingAttachments, attachments = [], attachmentErrors, onRegenerateAttachments }) => {
  const [emailContent, setEmailContent] = useState(prefillContent);
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const iframeRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [bccMyself, setBccMyself] = useState(false);

  // State for additional emails
  const [additionalRecipients, setAdditionalRecipients] = useState([]);
  const [ccRecipients, setCcRecipients] = useState([]);
  const [bccRecipients, setBccRecipients] = useState([]);

  const formatEmailContent = (content) => {
    return content.split('\n').map((paragraph, index) => (
      <p key={index} style={{ marginBottom: '1em' }}>{paragraph}</p>
    ));
  };

  const updateIframeContent = useCallback(() => {
    console.log('Updating iframe content');
    const formattedEmailContent = formatEmailContent(emailContent).map(p => p.props.children).join('<br/>');
    const fullEmailContent = `${staticHeader}${formattedEmailContent}${staticFooter}`;
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(fullEmailContent);
      iframeDoc.close();
      console.log('Iframe content updated');
    } else {
      console.log('Iframe not ready');
    }
  }, [staticHeader, emailContent, staticFooter]);

  useEffect(() => {
    if (selectedIndex === 1) {
      // Preview tab is selected
      const timer = setTimeout(() => {
        updateIframeContent();
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [selectedIndex, updateIframeContent]);

  useEffect(() => {
    // Update iframe content whenever emailContent changes
    if (selectedIndex === 1) {
      updateIframeContent();
    }
  }, [selectedIndex, updateIframeContent]);

  const handleSend = async () => {
    setIsLoading(true);
    setError(null);

    const fullEmailContent = `${staticHeader}${emailContent}${staticFooter}`;

    try {
      const requestBody = {
        to: [to, ...additionalRecipients],
        from: from,
        cc: ccRecipients,
        bcc: [...(bccMyself ? [from] : []), ...bccRecipients],
        subject: subject,
        html: fullEmailContent,
      };
    
      if (attachments && attachments.length > 0) {
        requestBody.attachments = attachments.map(attachment => ({
          filename: attachment.name,
          path: attachment.url,
        }));
      }
    
      const response = await fetch('/api/resend', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      let data;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        data = await response.json();
      } else {
        data = await response.text();
      }

      if (!response.ok) {
        throw new Error(data.error || data || 'Failed to send email');
      }

      setIsSent(true);

      // Call the onSend prop if it exists
      if (onSend) {
        await onSend();
      }

      setTimeout(() => {
        onClose();
        setIsSent(false);
      }, 2000);
    } catch (err) {
      console.error('Error details:', err);
      setError(err.message || 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const hasAttachments = attachments && attachments.length > 0;
  const hasAttachmentErrors = attachmentErrors && Object.keys(attachmentErrors).length > 0;

  return (
    <div className="w-full max-w-3xl mx-auto">
      <h2 className="text-3xl font-roca text-nomadBlue mb-4">{modalTitle || 'Send Itinerary'}</h2>
      <div className="bg-white rounded-lg border border-[#f0ece7] p-4">
        <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <TabList className="flex space-x-1 mb-4 rounded-xl bg-white shadow-md">
            {['Edit', 'Preview'].map((tabName) => (
              <Tab
                key={tabName}
                className={({ selected }) =>
                  `w-full rounded-lg py-2 text-sm font-medium leading-5 flex items-center justify-center
                  ${selected ? 'bg-[#073937] text-white shadow' : 'text-[#3e3d3d] hover:bg-gray-100'}`
                }
              >
                {tabName}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <div className="h-64 overflow-y-auto mb-4">
                <textarea
                  value={emailContent}
                  onChange={(e) => setEmailContent(e.target.value)}
                  className="w-full h-full p-2 border rounded resize-none focus:outline-none focus:ring-2 focus:ring-nomadBlue"
                  placeholder="Add your custom message here..."
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="h-64 overflow-hidden mb-4">
                <iframe ref={iframeRef} title="Email Preview" className="w-full h-full border" sandbox="allow-same-origin" />
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
  
        <div className="text-sm text-gray-600 mb-2">

        <EmailInput
          value={[to, ...additionalRecipients]}
          onChange={(emails) => setAdditionalRecipients(emails.slice(1))}
          placeholder="Add more recipients..."
          label="To"
        />

        {/* <EmailInput
          value={ccRecipients}
          onChange={setCcRecipients}
          placeholder="Add CC recipients..."
          label="CC"
        /> */}

        {/* <EmailInput
          value={bccRecipients}
          onChange={setBccRecipients}
          placeholder="Add BCC recipients..."
          label="BCC"
        /> */}
        
        {/* <p>To: {to}</p> */}
      </div>
  
      <div className="flex items-center mb-4">
  <Switch
    checked={bccMyself}
    onChange={setBccMyself}
    className={`${
      bccMyself ? 'bg-nomadFormsBlue' : 'bg-gray-300'
    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-nomadBlue`}
  >
    <span className="sr-only">BCC myself</span>
    <span
      className={`${
        bccMyself ? 'translate-x-6' : 'translate-x-1'
      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
    />
  </Switch>
  <span className="ml-2 text-sm text-gray-700">BCC myself</span>
</div>
  
{(hasAttachments || generatingAttachments) && (
  <div className="mb-6">
    <h3 className="font-semibold text-nomadBlue mb-3">Attachments</h3>
    {generatingAttachments ? (
      <div className="flex items-center text-gray-600 bg-white p-3 rounded-lg border border-gray-200">
        <FaSpinner className="animate-spin mr-3" />
        Generating attachments...
      </div>
    ) : hasAttachmentErrors ? (
      <div className="flex items-center justify-between bg-red-50 p-3 rounded-lg border border-red-200">
        <div className="flex items-center text-red-600">
          <FaExclamationTriangle className="mr-2" />
          <span>Some attachments failed to generate</span>
        </div>
        <ButtonPrimarySmall 
          text="Regenerate" 
          onClick={onRegenerateAttachments}
          color="dark"
        />
      </div>
    ) : attachments.length > 0 ? (
      <ul className="space-y-1 max-h-24 overflow-y-auto">
        {attachments.map((attachment, index) => (
          <li key={index} className="flex items-center justify-between bg-gray-100 p-1 rounded text-sm">
            <div className="flex items-center">
              {attachment.name.endsWith('.xlsx') ? <FaFileExcel className="text-green-600 mr-1" /> : <FaFilePdf className="text-red-600 mr-1" />}
              <span>{attachment.name}</span>
            </div>
            <a href={attachment.url} target="_blank" rel="noopener noreferrer" className="text-nomadBlue hover:underline">Preview</a>
          </li>
        ))}
      </ul>
    ) : (
      <div className="text-gray-600 bg-white p-3 rounded-lg border border-gray-200">
        No attachments generated
      </div>
    )}
  </div>
)}
  

  <div className="flex justify-between mt-4 mb-2">
  <div className="text-sm text-gray-500 flex-1 mr-4">
    <p>Email will be sent from:</p>
    <p>{from}</p>
  </div>
  <div className="flex space-x-2 flex-shrink-0">
    <ButtonOutlineSmall text="Cancel" onClick={onClose} color="dark" />
    <ButtonPrimarySmall 
      text={isLoading ? 'Sending...' : 'Send Email'} 
      onClick={handleSend} 
      isLoading={isLoading} 
      color="dark" 
      disabled={generatingAttachments || isLoading || hasAttachmentErrors || (hasAttachments && attachments.length === 0)}
    />
  </div>
</div>
  
        {isSent && (
          <div className="mt-2 p-2 bg-green-100 border-l-4 border-green-500 text-green-700 rounded text-sm">
            <p className="font-bold">Success!</p>
            <p>Your email has been sent.</p>
          </div>
        )}
        {error && (
          <div className="mt-2 p-2 bg-red-100 border-l-4 border-red-500 text-red-700 rounded text-sm">
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const ItineraryEmailModal = ({ isOpen, setIsOpen, onSend, generatingAttachments, attachments, ...formProps }) => {
  return ReactDOM.createPortal(
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 max-w-5xl" onClose={() => setIsOpen(false)}>
        <TransitionChild as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
          <ItineraryEmailForm
            {...formProps}
            onClose={() => setIsOpen(false)}
            onSend={onSend}
            generatingAttachments={generatingAttachments}
            attachments={attachments}
          />
        </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>,
    document.body
  );
};

export default ItineraryEmailModal;
