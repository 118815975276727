import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import airportsService from "./airportsService";

const initialState = {
  allAirports: [],
  airportsError: false,
  airportsSuccess: false,
  airportsLoading: false,
  airportsMessage: "",
};

// Get Airports
export const getAirports = createAsyncThunk("airports/getAirports", async (airport, thunkAPI) => {
  try {
    return await airportsService.getAirports(airport);
  } catch (error) {
    console.log(error);
    const message = "No airports";
    return thunkAPI.rejectWithValue(message);
  }
});

export const airportsSlice = createSlice({
  name: "airports",
  initialState,
  reducers: {
    reset: (state) => {
      state.airportsLoading = false;
      state.airportsError = false;
      state.airportsSuccess = false;
      state.airportsMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAirports.pending, (state) => {
        state.airportsLoading = true;
      })
      .addCase(getAirports.fulfilled, (state, action) => {
        state.airportsLoading = false;
        state.airportsSuccess = true;
        state.allAirports = action.payload.jsonResult;
      })
      .addCase(getAirports.rejected, (state, action) => {
        state.airportsLoading = false;
        state.airportsError = true;
        state.airportsMessage = action.payload;
      });
  },
});

export const { reset } = airportsSlice.actions;
export default airportsSlice.reducer;
