import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { MdClose, MdOutlineSearch } from 'react-icons/md';

const PropertyLibrarySearchBox = ({ refine, currentRefinement }) => {
  const handleChange = (event) => {
    refine(event.currentTarget.value);
  };

  const handleClear = () => {
    refine('');
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="relative">
        <MdOutlineSearch size={26} className="absolute left-2 top-2 text-gray-400" />
        <input
          type="text"
          value={currentRefinement}
          onChange={handleChange}
          style={{ width: '400px' }}
          className="pl-10 pr-10 py-2 w-full rounded border border-slate-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="Search properties..."
        />
        {currentRefinement && (
          <MdClose
            size={26}
            className="absolute right-2 top-2 cursor-pointer text-gray-400 hover:text-gray-600"
            onClick={handleClear}
          />
        )}
      </div>
    </form>
  );
};

export default connectSearchBox(PropertyLibrarySearchBox);