import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";

function ReadMoreButton({ text, maxRows = 3, buttonText = "Read More" }) {
  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkTextHeight = () => {
      const textElement = textRef.current;
      if (textElement) {
        const lineHeight = parseInt(getComputedStyle(textElement).lineHeight);
        const textHeight = textElement.scrollHeight;
        const rowCount = Math.ceil(textHeight / lineHeight);
        setShowButton(rowCount > maxRows);
      }
    };

    checkTextHeight();
    window.addEventListener("resize", checkTextHeight);
    return () => {
      window.removeEventListener("resize", checkTextHeight);
    };
  }, [maxRows]);

  return (
    <div>
      <div
        ref={textRef}
        className={`text-gray-700 ${expanded ? "" : "line-clamp"}`}
        style={{
          WebkitLineClamp: expanded ? "unset" : maxRows,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {text}
      </div>
      {showButton && (
        <button
          className="text-nomadBlue uppercase text-base hover:underline flex items-center mt-2"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? `${buttonText} Less` : `${buttonText} More`}
          <IoIosArrowDown className="ml-1 text-nomadRed" size={25} />
        </button>
      )}
    </div>
  );
}

export default ReadMoreButton;