import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import Select, { components } from 'react-select';

import Flatpickr from 'react-flatpickr';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { feeTypeOptions } from '../functions/finalPageFunctions';
import { findAirportByUid } from '../functions/generalFunctions';
import { loadTransferRoute } from '../functions/loadDataFunctions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// ... (rest of your imports and team array)

function FinalSlideOut({ isOpen, onClose, customFinalItems, setCustomFinalItems, addFinalItemDetails, customItemToEdit, finalSlideOutDate }) {
  //load data
  const { allSuppliers } = useSelector((state) => state.suppliers);
  var suppliersShort = allSuppliers.result;
  let tempSuppliers = JSON.parse(JSON.stringify(suppliersShort));

  tempSuppliers = tempSuppliers.filter((supplier) => supplier.active === true);

  const { allAirports } = useSelector((state) => state.airports);
  var airportsShort = allAirports.result;
  let tempAirports = JSON.parse(JSON.stringify(airportsShort));
  tempAirports = tempAirports.filter((airport) => airport.active === true && airport.alternativeAirports === false);

  const { allAirlines } = useSelector((state) => state.airlines);
  var airlinesShort = allAirlines.result;
  let tempAirlines = JSON.parse(JSON.stringify(airlinesShort));
  tempAirlines = tempAirlines.filter((airline) => airline.active === true);

  const { allLocations } = useSelector((state) => state.locations);
  var locationsShort = allLocations.result;
  let tempLocations = JSON.parse(JSON.stringify(locationsShort));
  tempLocations = tempLocations.filter((location) => location.active === true);

  // const { allProperties } = useSelector((state) => state.properties);
  // var propertiesShort = allProperties.result;

  // let tempProperties = JSON.parse(JSON.stringify(propertiesShort));

  const [airports, setAirports] = useState(tempAirports);
  const [airlines, setAirlines] = useState(tempAirlines);

  const [locations, setLocations] = useState(tempLocations);
  const [suppliers, setSuppliers] = useState(tempSuppliers);
  const tabNameTypes = ['customFlight', 'customRoadTransfer', 'customOther'];

  function findTabPosition(customItemToEdit, tabNameTypes) {
    if (customItemToEdit && customItemToEdit.type) {
      return tabNameTypes.indexOf(customItemToEdit.type);
    }
    return -1; // Return -1 if customItemToEdit is null or type is not found
  }
  const position = findTabPosition(customItemToEdit, tabNameTypes);

  const [selectedTab, setSelectedTab] = useState(position);

  console.log('selectedTab', selectedTab);
  // const tabCount = 4;
  // const tabNames = ['Property', 'Flight', 'Road Transfer', 'Other']; // Custom tab names
  const tabCount = customItemToEdit ? 1 : 3;
  const tabNames = ['Flight', 'Road Transfer', 'Other']; // Custom tab names

  const [originLocation, setOriginLocation] = useState(null);
  const [destinationLocation, setDestinationLocation] = useState(null);
  const [originAirport, setOriginAirport] = useState(null);
  const [destinationAirport, setDestinationAirport] = useState(null);
  const [date, setDate] = useState(new Date());
  const [supplier, setSupplier] = useState(null);
  const [description, setDescription] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  const [nightsUnits, setNightsUnits] = useState(1);

  const [paxUnits, setPaxUnits] = useState(1);

  const [netRate, setNetRate] = useState(0);

  const [feeType, setFeeType] = useState(null);

  const [fltnum, setFltnum] = useState('');
  const [flightType, setFlightType] = useState('');

  const [arrivalTime, setArrivalTime] = useState('');
  const [departureTime, setDepartureTime] = useState('');

  const [bookedDirect, setBookedDirect] = useState(false);

  const [uuid, setUuid] = useState(uuidv4());

  // reset variables
  const resetForm = useCallback(() => {
    if (customItemToEdit) {
    }

    setFeeType(customItemToEdit ? { value: customItemToEdit.fields[0].feeTypeUid, label: customItemToEdit.fields[0].feeTypeName } : null);

    setOriginLocation(
      customItemToEdit
        ? {
            value: customItemToEdit.fields[0].originLocationUid,
            label: customItemToEdit.fields[0].originLocationName,
            type: customItemToEdit.fields[0].originLocationType,
            park: customItemToEdit.fields[0].originLocationPark ? customItemToEdit.fields[0].originLocationPark : null,
          }
        : null,
    );
    setDestinationLocation(
      customItemToEdit
        ? {
            value: customItemToEdit.fields[0].destinationLocationUid,
            label: customItemToEdit.fields[0].destinationLocationName,
            type: customItemToEdit.fields[0].destinationLocationType,
            park: customItemToEdit.fields[0].destinationLocationPark ? customItemToEdit.fields[0].destinationLocationPark : null,
          }
        : null,
    );
    setOriginAirport(customItemToEdit ? { value: customItemToEdit.fields[0].originAirportUid, label: customItemToEdit.fields[0].originAirportName } : null);
    setDestinationAirport(customItemToEdit ? { value: customItemToEdit.fields[0].destinationAirportUid, label: customItemToEdit.fields[0].destinationAirportName } : null);
    setFltnum(customItemToEdit ? customItemToEdit.fields[0].fltnum : '');
    setFlightType(customItemToEdit ? { value: customItemToEdit.fields[0].flightType, label: customItemToEdit.fields[0].flightType } : '');
    setArrivalTime(customItemToEdit && customItemToEdit.fields[0].arrivalTime ? { value: customItemToEdit.fields[0].arrivalTime, label: customItemToEdit.fields[0].arrivalTime } : null);
    setDepartureTime(customItemToEdit && customItemToEdit.fields[0].departureTime ? { value: customItemToEdit.fields[0].departureTime, label: customItemToEdit.fields[0].departureTime } : null);

    setDate(customItemToEdit ? new Date(customItemToEdit.fields[0].date) : finalSlideOutDate ? new Date(finalSlideOutDate) : new Date());
    setSupplier(customItemToEdit ? { value: customItemToEdit.fields[0].supplierUid, label: customItemToEdit.fields[0].supplierName } : null);
    setDescription(customItemToEdit ? customItemToEdit.fields[0].description : '');
    setAdditionalInfo(customItemToEdit ? customItemToEdit.fields[0].additionalInfo : '');
    setNightsUnits(customItemToEdit ? customItemToEdit.fields[0].units : 1);
    setPaxUnits(customItemToEdit ? customItemToEdit.fields[0].pax : 1);
    setNetRate(customItemToEdit ? customItemToEdit.fields[0].net : 0);
    setUuid(customItemToEdit ? customItemToEdit.uuid : uuidv4());
    setSelectedTab(position);

    setBookedDirect(customItemToEdit ? customItemToEdit.fields[0].bookedDirect : false);
  });

  const resetFormAndClose = () => {
    resetForm();
    onClose();
  };

  const updateFieldInCustomFinalItems = (customItemToEditUuid, newField) => {
    // Clone the original array to avoid direct mutations
    const updatedItems = customFinalItems.map((item) => {
      // Find the item by uuid
      if (item.uuid === customItemToEditUuid) {
        // Update the first field of this item, if fields array exists and has elements
        if (item.fields && item.fields.length > 0) {
          return {
            ...item, // Copy all existing item properties
            fields: [newField, ...item.fields.slice(1)], // Replace the first field and keep the rest unchanged
          };
        }
      }
      // Return the item unchanged if no updates were necessary
      return item;
    });

    return updatedItems;
  };

  const handleSubmitFlight = (event) => {
    event.preventDefault();
    // Check if all fields exist
    if (originAirport && destinationAirport && date && supplier && nightsUnits && paxUnits && flightType) {
      let desc = `${flightType.label.charAt(0).toUpperCase() + flightType.label.slice(1)} flight from ${originAirport.label} (${findAirportByUid(airports, originAirport.value).code}) to ${
        destinationAirport.label
      } (${findAirportByUid(airports, destinationAirport.value).code}) ${fltnum && '(Flight Num: ' + fltnum + ')'} ${
        departureTime && departureTime.value !== 'Select time' && arrivalTime && arrivalTime.value !== 'Select time' ? ' - (' + arrivalTime.value + ' > ' + departureTime.value + ')' : ''
      }`;
      console.log('complete');
      if (customItemToEdit) {
        const newField = {
          feeTypeUid: feeType.value,
          feeTypeName: feeType.label,
          date: moment(date).format('YYYY-MM-DD'),
          supplierUid: supplier.value,
          supplierName: supplier.label,
          description: desc,
          additionalInfo: additionalInfo,
          units: Number(nightsUnits),
          pax: Number(paxUnits),
          net: Number(netRate),
          total: nightsUnits * paxUnits * netRate,
          originAirportUid: originAirport.value,
          originAirportName: originAirport.label,
          destinationAirportUid: destinationAirport.value,
          destinationAirportName: destinationAirport.label,
          fltnum: fltnum,
          arrivalTime: arrivalTime && arrivalTime.value !== 'Select time' ? arrivalTime.value : '',
          departureTime: departureTime && departureTime.value !== 'Select time' ? departureTime.value : '',
          flightType: flightType.value,
          bookedDirect: bookedDirect,
          uuid: uuid,
          edit: false,
          star: true,
          ...(customItemToEdit.vip ? { vip: customItemToEdit.vip } : {}),
          ...(customItemToEdit.arrivalDeparture ? { arrivalDeparture: customItemToEdit.arrivalDeparture } : {}),
        };

        // Update the customFinalItems with the new field data
        const updatedCustomFinalItems = updateFieldInCustomFinalItems(customItemToEdit.uuid, newField);

        // Update the state to reflect these changes
        setCustomFinalItems(updatedCustomFinalItems);
      } else {
        // make object
        const newItem = {
          id: addFinalItemDetails.searchId,
          uuid: addFinalItemDetails.uuid,
          collapsed: true, // Set the collapsed field to true by default
          add: false,
          edit: false,
          ...(addFinalItemDetails.pickupDropoff ? { pickupDropoff: addFinalItemDetails.pickupDropoff } : {}),
          ...(addFinalItemDetails.departure ? { departure: addFinalItemDetails.departure } : {}),
          type: 'customFlight',
          ...(addFinalItemDetails.vip ? { vip: addFinalItemDetails.vip } : {}),
          ...(addFinalItemDetails.arrivalDeparture ? { arrivalDeparture: addFinalItemDetails.arrivalDeparture } : {}),
          fields: [
            {
              feeTypeUid: '537f3a40-d0dd-43d3-835a-8496f7cbeb77', // Transfers
              feeTypeName: 'Flights',
              originAirportUid: originAirport.value,
              originAirportName: originAirport.label,
              destinationAirportUid: destinationAirport.value,
              destinationAirportName: destinationAirport.label,
              fltnum: fltnum,
              flightType: flightType.value,
              arrivalTime: arrivalTime && arrivalTime.value !== 'Select time' ? arrivalTime.value : '',
              departureTime: departureTime && departureTime.value !== 'Select time' ? departureTime.value : '',
              date: moment(date).format('YYYY-MM-DD'),
              supplierUid: supplier.value,
              supplierName: supplier.label,
              description: desc,
              additionalInfo: additionalInfo,
              units: Number(nightsUnits),
              pax: Number(paxUnits),
              net: Number(netRate),
              total: nightsUnits * paxUnits * netRate,
              uuid: addFinalItemDetails.uuid,
              edit: false,
              star: true,
              bookedDirect: bookedDirect,
              ...(addFinalItemDetails.vip ? { vip: addFinalItemDetails.vip } : {}),
              ...(addFinalItemDetails.arrivalDeparture !== undefined ? { arrivalDeparture: addFinalItemDetails.arrivalDeparture } : {}),
            },
          ],
        };

        const currentIndex = customFinalItems.findIndex((item) => item.uuid === addFinalItemDetails.itemId);

        if (addFinalItemDetails.position === 'above') {
          if (currentIndex !== -1) {
            // Create a new array and insert newItem just before the item at currentIndex
            const newArray = [...customFinalItems];
            newArray.splice(currentIndex, 0, newItem);
            setCustomFinalItems(newArray);
          } else {
            // If the item is not found, add newItem to the top of the list
            setCustomFinalItems([newItem, ...customFinalItems]);
          }
        } else if (addFinalItemDetails.position === 'below') {
          if (currentIndex !== -1) {
            // Create a new array and insert newItem just after the item at currentIndex
            const newArray = [...customFinalItems];
            newArray.splice(currentIndex + 1, 0, newItem);
            setCustomFinalItems(newArray);
          } else {
            // If the item is not found, add newItem to the bottom of the list
            setCustomFinalItems([...customFinalItems, newItem]);
          }
        } else if (addFinalItemDetails.position === 'top') {
          // Place newItem at the very top of the list
          setCustomFinalItems([newItem, ...customFinalItems]);
        } else {
          // For any other position, or if position is not specified, add newItem to the bottom of the list
          setCustomFinalItems([...customFinalItems, newItem]);
        }

        // if (addFinalItemDetails.position === 'above') {
        //   if (currentIndex !== -1) {
        //     const newArray = [...customFinalItems];
        //     newArray.splice(currentIndex, 0, newItem);
        //     setCustomFinalItems(newArray);
        //   } else {
        //     // If the item with the specified uuid is not found, add it to the top by default
        //     setCustomFinalItems([newItem, ...customFinalItems]);
        //   }
        // } else {
        //   if (addFinalItemDetails.position === 'top') {
        //     setCustomFinalItems([newItem, ...customFinalItems]);
        //   } else {
        //     setCustomFinalItems([...customFinalItems, newItem]);
        //   }
        // }
      }
      resetFormAndClose();
    } else {
      // Display error toast if any field is missing
      toast.error('Please complete all mandatory fields');
    }
  };

  const handleSubmitRoadTransfer = async (event) => {
    event.preventDefault();
    // Check if all fields exist
    if (originLocation && originLocation.value && destinationLocation && destinationLocation.value && date && supplier && supplier.value && nightsUnits && paxUnits) {
      console.log('complete');

      let desc = `Road Transfer from ${originLocation.label}${originLocation.type === 'airport' ? ' airport' : ''} to ${destinationLocation.label}${
        destinationLocation.type === 'airport' ? ' airport' : ''
      }`;

      const transferRoute = await loadTransferRoute(originLocation.park ? originLocation.park : originLocation.label, destinationLocation.park ? destinationLocation.park : destinationLocation.label);

      let transferRouteUid = null;
      if (transferRoute) {
        transferRouteUid = transferRoute.uid;
      }

      if (customItemToEdit) {
        const newField = {
          feeTypeUid: feeType.value,
          feeTypeName: feeType.label,
          date: moment(date).format('YYYY-MM-DD'),
          supplierUid: supplier.value,
          supplierName: supplier.label,
          description: desc,
          additionalInfo: additionalInfo,
          units: Number(nightsUnits),
          pax: Number(paxUnits),
          net: Number(netRate),
          total: nightsUnits * paxUnits * netRate,
          originLocationUid: originLocation.value,
          originLocationName: originLocation.label,
          originLocationType: originLocation.type,
          originLocationPark: originLocation.park,
          destinationLocationUid: destinationLocation.value,
          destinationLocationName: destinationLocation.label,
          destinationLocationType: destinationLocation.type,
          destinationLocationPark: destinationLocation.park,
          uuid: uuid,
          edit: false,
          star: true,
          transferRouteUid: transferRouteUid,
          ...(customItemToEdit.vip ? { vip: customItemToEdit.vip } : {}),
          ...(customItemToEdit.arrivalDeparture !== undefined ? { arrivalDeparture: customItemToEdit.arrivalDeparture } : {}),
        };

        // Update the customFinalItems with the new field data
        const updatedCustomFinalItems = updateFieldInCustomFinalItems(customItemToEdit.uuid, newField);

        // Update the state to reflect these changes
        setCustomFinalItems(updatedCustomFinalItems);
      } else {
        // make object
        const newItem = {
          id: addFinalItemDetails.searchId,
          uuid: addFinalItemDetails.uuid,
          collapsed: true, // Set the collapsed field to true by default
          add: false,
          edit: false,
          ...(addFinalItemDetails.pickupDropoff ? { pickupDropoff: addFinalItemDetails.pickupDropoff } : {}),
          ...(addFinalItemDetails.departure ? { departure: addFinalItemDetails.departure } : {}),
          type: 'customRoadTransfer',
          ...(addFinalItemDetails.vip ? { vip: addFinalItemDetails.vip } : {}),
          ...(addFinalItemDetails.arrivalDeparture !== undefined ? { arrivalDeparture: addFinalItemDetails.arrivalDeparture } : {}),
          fields: [
            {
              feeTypeUid: 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add', // Transfers
              feeTypeName: 'Transfers',
              originLocationUid: originLocation.value,
              originLocationName: originLocation.label,
              originLocationType: originLocation.type,
              originLocationPark: originLocation.park,
              destinationLocationUid: destinationLocation.value,
              destinationLocationName: destinationLocation.label,
              destinationLocationType: destinationLocation.type,
              destinationLocationPark: destinationLocation.park,
              date: moment(date).format('YYYY-MM-DD'),
              supplierUid: supplier.value,
              supplierName: supplier.label,
              description: desc,
              additionalInfo: additionalInfo,
              units: Number(nightsUnits),
              pax: Number(paxUnits),
              net: Number(netRate),
              total: nightsUnits * paxUnits * netRate,
              uuid: uuid,
              edit: false,
              star: true,
              transferRouteUid: transferRouteUid,
            },
          ],
        };

        const currentIndex = customFinalItems.findIndex((item) => item.uuid === addFinalItemDetails.itemId);

        if (addFinalItemDetails.position === 'above') {
          if (currentIndex !== -1) {
            // Create a new array and insert newItem just before the item at currentIndex
            const newArray = [...customFinalItems];
            newArray.splice(currentIndex, 0, newItem);
            setCustomFinalItems(newArray);
          } else {
            // If the item is not found, add newItem to the top of the list
            setCustomFinalItems([newItem, ...customFinalItems]);
          }
        } else if (addFinalItemDetails.position === 'below') {
          if (currentIndex !== -1) {
            // Create a new array and insert newItem just after the item at currentIndex
            const newArray = [...customFinalItems];
            newArray.splice(currentIndex + 1, 0, newItem);
            setCustomFinalItems(newArray);
          } else {
            // If the item is not found, add newItem to the bottom of the list
            setCustomFinalItems([...customFinalItems, newItem]);
          }
        } else if (addFinalItemDetails.position === 'top') {
          // Place newItem at the very top of the list
          setCustomFinalItems([newItem, ...customFinalItems]);
        } else {
          // For any other position, or if position is not specified, add newItem to the bottom of the list
          setCustomFinalItems([...customFinalItems, newItem]);
        }

        // if (addFinalItemDetails.position === 'above') {
        //   if (currentIndex !== -1) {
        //     const newArray = [...customFinalItems];
        //     newArray.splice(currentIndex, 0, newItem);
        //     setCustomFinalItems(newArray);
        //   } else {
        //     // If the item with the specified uuid is not found, add it to the top by default
        //     setCustomFinalItems([newItem, ...customFinalItems]);
        //   }
        // } else {
        //   if (addFinalItemDetails.position === 'top') {
        //     setCustomFinalItems([newItem, ...customFinalItems]);
        //   } else {
        //     setCustomFinalItems([...customFinalItems, newItem]);
        //   }
        // }
      }
      resetFormAndClose();
    } else {
      // Display error toast if any field is missing
      toast.error('Please complete mandatory all fields');
    }
  };

  const handleSubmitOther = (event) => {
    event.preventDefault();
    // Check if all fields exist
    if (feeType && date && supplier && supplier.value && description && nightsUnits && paxUnits) {
      if (customItemToEdit) {
        const newField = {
          feeTypeUid: feeType.value,
          feeTypeName: feeType.label,
          date: moment(date).format('YYYY-MM-DD'),
          supplierUid: supplier.value,
          supplierName: supplier.label,
          description: description,
          units: Number(nightsUnits),
          pax: Number(paxUnits),
          net: Number(netRate),
          total: nightsUnits * paxUnits * netRate,
          uuid: customItemToEdit.uuid,
          edit: false,
          star: true,
        };

        // Update the customFinalItems with the new field data
        const updatedCustomFinalItems = updateFieldInCustomFinalItems(customItemToEdit.uuid, newField);

        // Update the state to reflect these changes
        setCustomFinalItems(updatedCustomFinalItems);
      } else {
        console.log('complete');
        // make object
        const newItem = {
          id: addFinalItemDetails.searchId,
          uuid: addFinalItemDetails.uuid,
          collapsed: true, // Set the collapsed field to true by default
          add: false,
          edit: false,
          ...(addFinalItemDetails.pickupDropoff ? { pickupDropoff: addFinalItemDetails.pickupDropoff } : {}),
          ...(addFinalItemDetails.departure ? { departure: addFinalItemDetails.departure } : {}),
          type: 'customOther',
          ...(addFinalItemDetails.vip ? { vip: addFinalItemDetails.vip } : {}),
          ...(addFinalItemDetails.arrivalDeparture !== undefined ? { arrivalDeparture: addFinalItemDetails.arrivalDeparture } : {}),
          fields: [
            {
              feeTypeUid: feeType.value,
              feeTypeName: feeType.label,
              date: moment(date).format('YYYY-MM-DD'),
              supplierUid: supplier.value,
              supplierName: supplier.label,
              description: description,
              units: Number(nightsUnits),
              pax: Number(paxUnits),
              net: Number(netRate),
              total: nightsUnits * paxUnits * netRate,
              uuid: addFinalItemDetails.uuid,
              edit: false,
              star: true,
            },
          ],
        };

        const currentIndex = customFinalItems.findIndex((item) => item.uuid === addFinalItemDetails.itemId);

        if (addFinalItemDetails.position === 'above') {
          if (currentIndex !== -1) {
            // Create a new array and insert newItem just before the item at currentIndex
            const newArray = [...customFinalItems];
            newArray.splice(currentIndex, 0, newItem);
            setCustomFinalItems(newArray);
          } else {
            // If the item is not found, add newItem to the top of the list
            setCustomFinalItems([newItem, ...customFinalItems]);
          }
        } else if (addFinalItemDetails.position === 'below') {
          if (currentIndex !== -1) {
            // Create a new array and insert newItem just after the item at currentIndex
            const newArray = [...customFinalItems];
            newArray.splice(currentIndex + 1, 0, newItem);
            setCustomFinalItems(newArray);
          } else {
            // If the item is not found, add newItem to the bottom of the list
            setCustomFinalItems([...customFinalItems, newItem]);
          }
        } else if (addFinalItemDetails.position === 'top') {
          // Place newItem at the very top of the list
          setCustomFinalItems([newItem, ...customFinalItems]);
        } else {
          // For any other position, or if position is not specified, add newItem to the bottom of the list
          setCustomFinalItems([...customFinalItems, newItem]);
        }

        // if (addFinalItemDetails.position === 'above') {
        //   if (currentIndex !== -1) {
        //     const newArray = [...customFinalItems];
        //     newArray.splice(currentIndex, 0, newItem);
        //     setCustomFinalItems(newArray);
        //   } else {
        //     // If the item with the specified uuid is not found, add it to the top by default
        //     setCustomFinalItems([newItem, ...customFinalItems]);
        //   }
        // } else {
        //   if (addFinalItemDetails.position === 'top') {
        //     setCustomFinalItems([newItem, ...customFinalItems]);
        //   } else {
        //     setCustomFinalItems([...customFinalItems, newItem]);
        //   }
        // }
      }
      resetFormAndClose();
    } else {
      // Display error toast if any field is missing
      toast.error('Please complete all mandatory fields');
    }
  };

  useEffect(() => {
    if (isOpen) {
      resetForm();
      //setSelectedTab(-1);
    }
  }, [isOpen]);

  // Custom Option component
  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div>{props.data.label}</div>
        {/* Add subtext using the type property or any other property you wish */}
        <div style={{ fontSize: 'smaller', color: '#666' }}>{props.data.type.charAt(0).toUpperCase() + props.data.type.slice(1)}</div>
      </components.Option>
    );
  };

  const LocationSelect = ({ locations, selectedLocation, setSelectedLocation, placeholder }) => {
    return (
      <Select
        options={locations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((location) => ({
            value: location.uid,
            label: location.name,
            type: location.type, // Assuming 'type' is available and desired as subtext
            park: location.park,
          }))}
        value={selectedLocation}
        placeholder={placeholder}
        onChange={setSelectedLocation}
        components={{ Option: CustomOption }}
      />
    );
  };

  //const flatpickrDate = finalSlideOutDate ? finalSlideOutDate : date;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 bg-black bg-opacity-25" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            {/* Adjusted positioning to open halfway down the page and always float there */}
            <div className="pointer-events-none fixed top-1/2 right-0 flex max-w-full pl-10 sm:pl-16 transform -translate-y-1/2">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-white px-4 py-6 sm:px-6">
                        <div className="flex items-center ">
                          {' '}
                          <div className="ml-3 flex h-7 items-center">
                            <button type="button" className="relative rounded-md bg-white text-black hover:text-black" onClick={() => onClose()}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                          <Dialog.Title className="ml-3 text-base font-base leading-6 text-black">{!customItemToEdit ? 'Add item' : 'Edit item'}</Dialog.Title>
                        </div>
                      </div>
                      <div className="mt-5 mx-5">
                        <div className="">
                          <nav className="flex divide-x divide-gray-300 rounded-xl overflow-hidden">
                            {tabNames.map(
                              (name, index) =>
                                (!customItemToEdit || selectedTab === index) && (
                                  <button
                                    key={index}
                                    type="button"
                                    className={`flex-1 py-3 text-sm font-medium 
                                ${selectedTab === index ? 'bg-black text-white' : 'bg-gray-300 text-black'}
                                hover:bg-black hover:text-white`} // Simplified and corrected hover effect
                                    onClick={() => setSelectedTab(index)}
                                  >
                                    {name}
                                  </button>
                                ),
                            )}
                          </nav>
                        </div>
                        <div className="py-6">
                          {selectedTab === -1 && <div className="text-center">Select an option above {customItemToEdit && customItemToEdit.type}</div>}
                          {selectedTab === 0 && (
                            <form className="p-4" onSubmit={handleSubmitFlight}>
                              <h2 className="text-lg font-semibold mb-4">Enter your flight information</h2>

                              {/* Row 1 & 2 */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Date <span className="text-red-600">*</span>
                                  </label>
                                  <Flatpickr value={date} onChange={(date) => setDate(date[0])} className="w-full p-2 border border-gray-300 rounded-md" options={{ dateFormat: "D d M 'y" }} />
                                </div>
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Flight Type <span className="text-red-600">*</span>
                                  </label>
                                  <Select
                                    options={[
                                      { value: 'charter', label: customItemToEdit && customItemToEdit.flightType === 'charter' ? 'Charter' : 'Charter' },
                                      { value: 'scheduled', label: customItemToEdit && customItemToEdit.flightType === 'scheduled' ? 'Scheduled' : 'Scheduled' },
                                    ]}
                                    //defaultValue={{ label: 'Select', value: '' }}
                                    placeholder="Select"
                                    value={flightType.label ? { ...flightType, label: flightType.label.charAt(0).toUpperCase() + flightType.label.slice(1) } : flightType}
                                    onChange={setFlightType}
                                  />
                                </div>
                              </div>

                              {/* Row 3 & 4 */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Origin Airport <span className="text-red-600">*</span>
                                  </label>
                                  <Select
                                    options={airports
                                      .sort((a, b) => a.name.localeCompare(b.name))
                                      .map((airport) => ({
                                        value: airport.uid,
                                        label: airport.name,
                                      }))}
                                    value={originAirport}
                                    placeholder="Select Origin Airport"
                                    onChange={setOriginAirport}
                                  />{' '}
                                </div>
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Destination Airport <span className="text-red-600">*</span>
                                  </label>
                                  <Select
                                    options={airports
                                      .sort((a, b) => a.name.localeCompare(b.name))
                                      .map((airport) => ({
                                        value: airport.uid,
                                        label: airport.name,
                                      }))}
                                    value={destinationAirport} // Set the value of the Select
                                    placeholder="Select Destination Airport"
                                    onChange={setDestinationAirport}
                                  />
                                </div>
                              </div>

                              {/* Row 5 & 6 */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Airline <span className="text-red-600">*</span>
                                  </label>
                                  <Select
                                    options={airlines
                                      .sort((a, b) => a.name.localeCompare(b.name))
                                      .map((airline) => ({
                                        value: airline.supplier.id,
                                        label: airline.supplier.name,
                                      }))}
                                    value={supplier}
                                    placeholder="Select Airline"
                                    onChange={setSupplier}
                                  />
                                </div>
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">Client own arrangements?</label>
                                  <div className="flex items-center">
                                    <label className="flex items-center">
                                      <input type="radio" name="bookedDirect" value="yes" checked={bookedDirect === true} onChange={() => setBookedDirect(true)} className="mr-2" />
                                      Yes
                                    </label>
                                    <label className="flex items-center ml-4">
                                      <input type="radio" name="bookedDirect" value="no" checked={bookedDirect === false} onChange={() => setBookedDirect(false)} className="mr-2" />
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>

                              {/* Row 7 & 8 */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">Departure Time</label>
                                  <Select
                                    options={[
                                      { value: 'Select time', label: 'Select time' },
                                      ...Array.from({ length: 24 * 12 }).map((_, index) => {
                                        const hours = Math.floor(index / 12)
                                          .toString()
                                          .padStart(2, '0');
                                        const minutes = (index % 12) * 5;
                                        const time = `${hours}:${minutes.toString().padStart(2, '0')}`;
                                        return { value: time, label: time };
                                      }),
                                    ]}
                                    value={arrivalTime}
                                    placeholder="Select time"
                                    onChange={setArrivalTime}
                                  />
                                </div>
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">Arrival Time</label>
                                  <Select
                                    options={[
                                      { value: 'Select time', label: 'Select time' },
                                      ...Array.from({ length: 24 * 12 }).map((_, index) => {
                                        const hours = Math.floor(index / 12)
                                          .toString()
                                          .padStart(2, '0');
                                        const minutes = (index % 12) * 5;
                                        const time = `${hours}:${minutes.toString().padStart(2, '0')}`;
                                        return { value: time, label: time };
                                      }),
                                    ]}
                                    value={departureTime}
                                    placeholder="Select time"
                                    onChange={setDepartureTime}
                                  />
                                </div>
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">Flight Number</label>
                                  <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    placeholder="Enter Flight Number"
                                    value={fltnum}
                                    onChange={(e) => setFltnum(e.target.value)}
                                  />
                                </div>
                              </div>

                              {/* Row 9 */}
                              <div className="mb-4">
                                <label className="block text-xs font-medium text-gray-700 mb-1">
                                  Description <span className="text-red-600">*</span>
                                </label>
                                <textarea
                                  maxLength="250"
                                  className="w-full p-2 border border-gray-300 rounded-md"
                                  placeholder="Select Flight type, Origin airport and Destination airport"
                                  rows={2}
                                  value={
                                    flightType &&
                                    flightType.label &&
                                    originAirport &&
                                    originAirport.label &&
                                    destinationAirport &&
                                    destinationAirport.label &&
                                    `${flightType.label.charAt(0).toUpperCase() + flightType.label.slice(1)} flight from ${originAirport.label} (${
                                      findAirportByUid(airports, originAirport.value).code
                                    }) to ${destinationAirport.label} (${findAirportByUid(airports, destinationAirport.value).code}) ${fltnum && '(Flight Num: ' + fltnum + ')'} ${
                                      departureTime && departureTime.value !== 'Select time' && arrivalTime && arrivalTime.value !== 'Select time'
                                        ? ' - (' + arrivalTime.value + ' > ' + departureTime.value + ')'
                                        : ''
                                    }`
                                  }
                                  onChange={(e) => setDescription(e.target.value)}
                                  disabled
                                />{' '}
                              </div>

                              {/* Row 10 */}
                              <div className="mb-4">
                                <label className="block text-xs font-medium text-gray-700 mb-1">Additional info</label>
                                <textarea
                                  className="w-full p-2 border border-gray-300 rounded-md"
                                  placeholder="Additional info"
                                  rows={1}
                                  value={additionalInfo}
                                  onChange={(e) => setAdditionalInfo(e.target.value)}
                                />{' '}
                              </div>

                              {/* Row 11 pricing */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="w-1/4">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Nts/Units <span className="text-red-600">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    defaultValue={customItemToEdit ? nightsUnits : 1}
                                    className="w-full p-2 border border-gray-300 rounded-md inputPax"
                                    onChange={(e) => setNightsUnits(e.target.value)}
                                    style={{
                                      appearance: 'textfield',
                                    }} // add this
                                  />
                                </div>
                                <div className="w-1/4">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Pax/Units <span className="text-red-600">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    defaultValue={customItemToEdit ? paxUnits : 1}
                                    className="w-full p-2 border border-gray-300 rounded-md inputPax"
                                    onChange={(e) => setPaxUnits(e.target.value)}
                                    style={{
                                      appearance: 'textfield',
                                    }} // add this
                                  />
                                </div>
                                <div className="w-1/4">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Net Rate (Can be 0)<span className="text-red-600">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    //min="0"
                                    step="0.01"
                                    onInput={(e) => {
                                      const value = e.target.value;
                                      if (!/^-?\d*\.?\d{0,2}$/.test(value)) {
                                        e.target.value = value.slice(0, -1);
                                      }
                                    }}
                                    defaultValue={customItemToEdit ? netRate : 0}
                                    className="w-full p-2 border border-gray-300 rounded-md inputPax"
                                    onChange={(e) => setNetRate(e.target.value)}
                                    style={{
                                      appearance: 'textfield',
                                    }} // add this
                                  />
                                </div>
                                <div className="w-1/4">
                                  <div className="w-full">
                                    <label className="block text-xs font-medium text-gray-700 mb-1">
                                      Total (Can be 0)<span className="text-red-600">*</span>
                                    </label>
                                    <span className="block w-full p-2 border border-gray-300 rounded-md">{nightsUnits * paxUnits * netRate}</span>
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-shrink-0 justify-end px-0 py-4">
                                <button
                                  type="button"
                                  className="bg-white text-[#2A99A3] text-base hover:text-white hover:bg-[#2A99A3] font-normal py-1 px-4 mr-5 mt-5 border brand-border-color-v2 rounded h-10 w-fit mb-5"
                                  onClick={() => onClose()}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="bg-[#2A99A3] text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 mr-0 mt-5 border brand-border-color-v2 rounded h-10 w-fit mb-5"
                                >
                                  Save
                                </button>
                              </div>
                            </form>
                          )}
                          {selectedTab === 1 && (
                            <form className="p-4" onSubmit={handleSubmitRoadTransfer}>
                              <h2 className="text-lg font-semibold mb-4">Enter your Road transfer information</h2>

                              {/* Row 1 & 2 */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Date <span className="text-red-600">*</span>
                                  </label>
                                  <Flatpickr value={date} onChange={(date) => setDate(date[0])} className="w-full p-2 border border-gray-300 rounded-md" options={{ dateFormat: "D d M 'y" }} />
                                </div>
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Supplier <span className="text-red-600">*</span>
                                  </label>
                                  <Select
                                    options={suppliers
                                      .sort((a, b) => a.name.localeCompare(b.name))
                                      .map((supplier) => ({
                                        value: supplier.uid,
                                        label: supplier.name,
                                      }))}
                                    value={supplier}
                                    placeholder="Select Supplier"
                                    onChange={setSupplier}
                                  />
                                </div>
                              </div>

                              {/* Row 3 */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Origin location <span className="text-red-600">*</span>
                                  </label>
                                  <LocationSelect locations={locations} selectedLocation={originLocation} setSelectedLocation={setOriginLocation} placeholder="Select Origin Location" />
                                </div>
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Destination location <span className="text-red-600">*</span>
                                  </label>
                                  <LocationSelect locations={locations} selectedLocation={destinationLocation} setSelectedLocation={setDestinationLocation} placeholder="Select Destination Location" />
                                </div>
                              </div>
                              {/* <div className="flex items-center gap-4 mb-4">
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">Origin location</label>
                                  <Select
                                    options={locations
                                      .sort((a, b) => a.name.localeCompare(b.name))
                                      .map((location) => ({
                                        value: location.uid,
                                        label: location.name,
                                      }))}
                                    value={originLocation}
                                    placeholder="Select Origin Location"
                                    onChange={setOriginLocation}
                                  />{' '}
                                </div>
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">Destination location</label>
                                  <Select
                                    options={locations
                                      .sort((a, b) => a.name.localeCompare(b.name))
                                      .map((location) => ({
                                        value: location.uid,
                                        label: location.name,
                                      }))}
                                    value={destinationLocation} // Set the value of the Select
                                    placeholder="Select Destination Location"
                                    onChange={setDestinationLocation}
                                  />
                                </div>
                              </div> */}

                              {/* Row 4 */}
                              <div className="mb-4">
                                <label className="block text-xs font-medium text-gray-700 mb-1">
                                  Description <span className="text-red-600">*</span>
                                </label>
                                <textarea
                                  maxLength="250"
                                  className="w-full p-2 border border-gray-300 rounded-md"
                                  placeholder="Select Origin Location and Destination Location"
                                  rows={2}
                                  value={
                                    originLocation &&
                                    originLocation.label &&
                                    destinationLocation &&
                                    destinationLocation.label &&
                                    `Road Transfer from ${originLocation.label}${originLocation.type === 'airport' ? ' airport' : ''} to ${destinationLocation.label}${
                                      destinationLocation.type === 'airport' ? ' airport' : ''
                                    }`
                                  }
                                  //onChange={(e) => setDescription(e.target.value)}
                                  disabled
                                />
                              </div>

                              {/* Row 5 */}
                              <div className="mb-4">
                                <label className="block text-xs font-medium text-gray-700 mb-1">Additional info</label>
                                <textarea
                                  className="w-full p-2 border border-gray-300 rounded-md"
                                  placeholder="Additional info"
                                  rows={1}
                                  value={additionalInfo}
                                  onChange={(e) => setAdditionalInfo(e.target.value)}
                                />{' '}
                              </div>

                              {/* Row 6 pricing */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="w-1/4">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Nts/Units <span className="text-red-600">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    defaultValue={customItemToEdit ? nightsUnits : 1}
                                    className="w-full p-2 border border-gray-300 rounded-md inputPax"
                                    onChange={(e) => setNightsUnits(e.target.value)}
                                    style={{
                                      appearance: 'textfield',
                                    }} //
                                  />
                                </div>
                                <div className="w-1/4">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Pax/Units <span className="text-red-600">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    defaultValue={customItemToEdit ? paxUnits : 1}
                                    className="w-full p-2 border border-gray-300 rounded-md inputPax"
                                    onChange={(e) => setPaxUnits(e.target.value)}
                                    style={{
                                      appearance: 'textfield',
                                    }} //
                                  />
                                </div>
                                <div className="w-1/4">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Net Rate (Can be 0) <span className="text-red-600">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    //min="0"
                                    onInput={(e) => {
                                      const value = e.target.value;
                                      if (!/^-?\d*\.?\d{0,2}$/.test(value)) {
                                        e.target.value = value.slice(0, -1);
                                      }
                                    }}
                                    defaultValue={customItemToEdit ? netRate : 0}
                                    className="w-full p-2 border border-gray-300 rounded-md inputPax"
                                    onChange={(e) => setNetRate(e.target.value)}
                                    style={{
                                      appearance: 'textfield',
                                    }} //
                                  />
                                </div>
                                <div className="w-1/4">
                                  <div className="w-full">
                                    <label className="block text-xs font-medium text-gray-700 mb-1">
                                      Total (Can be 0) <span className="text-red-600">*</span>
                                    </label>
                                    <span className="block w-full p-2 border border-gray-300 rounded-md">{nightsUnits * paxUnits * netRate}</span>
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-shrink-0 justify-end px-0 py-4">
                                <button
                                  type="button"
                                  className="bg-white text-[#2A99A3] text-base hover:text-white hover:bg-[#2A99A3] font-normal py-1 px-4 mr-5 mt-5 border brand-border-color-v2 rounded h-10 w-fit mb-5"
                                  onClick={() => onClose()}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="bg-[#2A99A3] text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 mr-0 mt-5 border brand-border-color-v2 rounded h-10 w-fit mb-5"
                                >
                                  Save
                                </button>
                              </div>
                            </form>
                          )}
                          {selectedTab === 2 && (
                            <form className="p-4" onSubmit={handleSubmitOther}>
                              <h2 className="text-lg font-semibold mb-4">Enter your information</h2>

                              {/* Row 1 */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Fee Type <span className="text-red-600">*</span>
                                  </label>
                                  <Select options={feeTypeOptions} defaultValue={customItemToEdit ? feeType : { label: 'Select', value: '' }} placeholder="Select" onChange={setFeeType} />
                                </div>
                                <div className="flex-1"></div>
                              </div>

                              {/* Row 2 & 3 */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Date <span className="text-red-600">*</span>
                                  </label>
                                  <Flatpickr
                                    value={date}
                                    onChange={(date) => {
                                      setDate(date[0]);
                                    }}
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    options={{ dateFormat: "D d M 'y" }}
                                  />
                                </div>
                                <div className="flex-1">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Supplier <span className="text-red-600">*</span>
                                  </label>
                                  <Select
                                    options={suppliers
                                      .sort((a, b) => a.name.localeCompare(b.name))
                                      .map((supplier) => ({
                                        value: supplier.uid,
                                        label: supplier.name,
                                      }))}
                                    value={supplier}
                                    placeholder="Select Supplier"
                                    onChange={setSupplier}
                                  />
                                </div>
                              </div>

                              {/* Row 4 */}
                              <div className="mb-4">
                                <label className="block text-xs font-medium text-gray-700 mb-1">
                                  Description <span className="text-red-600">*</span>
                                </label>
                                <textarea
                                  maxLength="250"
                                  className="w-full p-2 border border-gray-300 rounded-md"
                                  placeholder="Description (Max 250 characters)"
                                  rows={2}
                                  value={description}
                                  onChange={(e) => setDescription(e.target.value)}
                                />{' '}
                              </div>

                              {/* Row 5 pricing */}
                              <div className="flex items-center gap-4 mb-4">
                                <div className="w-1/4">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Nts/Units <span className="text-red-600">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    defaultValue={customItemToEdit ? nightsUnits : 1}
                                    className="w-full p-2 border border-gray-300 rounded-md inputPax"
                                    onChange={(e) => setNightsUnits(e.target.value)}
                                    style={{
                                      appearance: 'textfield',
                                    }} //
                                  />
                                </div>
                                <div className="w-1/4">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Pax/Units <span className="text-red-600">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    defaultValue={customItemToEdit ? paxUnits : 1}
                                    className="w-full p-2 border border-gray-300 rounded-md inputPax"
                                    onChange={(e) => setPaxUnits(e.target.value)}
                                    style={{
                                      appearance: 'textfield',
                                    }} //
                                  />
                                </div>
                                <div className="w-1/4">
                                  <label className="block text-xs font-medium text-gray-700 mb-1">
                                    Net Rate (Can be 0) <span className="text-red-600">*</span>
                                  </label>

                                  <input
                                    type="number"
                                    //min="0"
                                    step="0.01"
                                    onInput={(e) => {
                                      const value = e.target.value;
                                      if (!/^-?\d*\.?\d{0,2}$/.test(value)) {
                                        e.target.value = value.slice(0, -1);
                                      }
                                    }}
                                    defaultValue={customItemToEdit ? netRate : 0}
                                    className="w-full p-2 border border-gray-300 rounded-md inputPax"
                                    onChange={(e) => setNetRate(e.target.value)}
                                    style={{
                                      appearance: 'textfield',
                                    }} //
                                  />
                                </div>
                                <div className="w-1/4">
                                  <div className="w-full">
                                    <label className="block text-xs font-medium text-gray-700 mb-1">
                                      Total (Can be 0) <span className="text-red-600">*</span>
                                    </label>
                                    <span className="block w-full p-2 border border-gray-300 rounded-md">{nightsUnits * paxUnits * netRate}</span>
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-shrink-0 justify-end px-0 py-4">
                                <button
                                  type="button"
                                  className="bg-white text-[#2A99A3] text-base hover:text-white hover:bg-[#2A99A3] font-normal py-1 px-4 mr-5 mt-5 border brand-border-color-v2 rounded h-10 w-fit mb-5"
                                  onClick={() => {
                                    setSelectedTab(-1);
                                    onClose();
                                  }}
                                >
                                  Cancel
                                </button>
                                {!customItemToEdit ? (
                                  <button
                                    type="submit"
                                    className="bg-[#2A99A3] text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 mr-0 mt-5 border brand-border-color-v2 rounded h-10 w-fit mb-5"
                                  >
                                    Save
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="bg-[#2A99A3] text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 mr-0 mt-5 border brand-border-color-v2 rounded h-10 w-fit mb-5"
                                  >
                                    Save Edit
                                  </button>
                                )}
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default FinalSlideOut;
