import React from 'react';
import { formatPrice } from '../functions/generalFunctions';
import { v4 as uuidv4 } from 'uuid';

const ViewCompTableRow = ({ index, lengthCheck, description, supplierName, units, pax, net, total }) => {
  return (
    <div key={uuidv4()} className={`grid grid-cols-1 md:grid-cols-16 gap-2 p-4 text-sm ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
      <div className="md:col-span-5 space-y-1">
        <div className="font-medium md:hidden">Description:</div>
        <div>{description}</div>
      </div>
      <div className="md:col-span-3 space-y-1">
        <div className="font-medium md:hidden">Supplier:</div>
        <div>{supplierName}</div>
      </div>
      <div className="md:col-span-2 flex justify-between md:justify-center space-y-1">
        <div className="font-medium md:hidden">Nights:</div>
        <div>{units}</div>
      </div>
      <div className="md:col-span-2 flex justify-between md:justify-center space-y-1">
        <div className="font-medium md:hidden">Units:</div>
        <div>{pax}</div>
      </div>
      <div className="md:col-span-2 flex justify-between md:justify-start space-y-1">
        <div className="font-medium md:hidden">Unit Price:</div>
        <div>${formatPrice(net)}</div>
      </div>
      <div className="md:col-span-2 flex justify-between md:justify-start space-y-1">
        <div className="font-medium md:hidden">Total:</div>
        <div>${formatPrice(total)}</div>
      </div>
    </div>
  );
};

export default ViewCompTableRow;