import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';

import Header from '../../../partials/Header';
import { Link } from 'react-router-dom';
import PropertyCard from '../components/PropertyCard';
import Sidebar from '../../../partials/Sidebar';
import { db } from '../../../db/firebase.config';

function NomadCamps() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [campsData, setCampsData] = useState([]);

  useEffect(() => {
    const fetchCamps = async () => {
      try {
        const campsCollection = collection(db, 'properties');
        const supplierId = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
        const campsQuery = query(campsCollection, where('supplier.id', '==', supplierId));
        const campsSnapshot = await getDocs(campsQuery);
        const fetchedCampsData = campsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          park: doc.data().park.name,
          caption: doc.data().caption,
          heroImageUrl: doc.data().heroImageUrl,
          locationCategory: doc.data().locationCategory,
          mobileLocation: doc.data().mobileLocation,
          propertyType: doc.data().propertyType,
        }));

         // Sort the camps data alphabetically by name
         const sortedCampsData = fetchedCampsData.sort((a, b) => a.name.localeCompare(b.name));
        

  
         setCampsData(sortedCampsData);
         console.log('Fetched camps data:', sortedCampsData);
       } catch (error) {
         console.error('Error fetching camps:', error);
       }
     };
 
     fetchCamps();
   }, []);


    return (
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
  
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
        <main className="flex-grow flex justify-center">
          <div className="px-4 sm:px-4 lg:px-8 py-8 max-w-7xl">
            <h1 className="text-4xl font-roca text-nomadBlue text-left">Nomad Camps</h1>
            <p className="text-lg font-sans mb-8 text-left">
              Discover our exclusive collection of nomad camps, offering unique and immersive experiences in the heart of nature.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 bg-white rounded-md shadow p-5">
      {campsData.map((camp) => (
        <Link key={camp.id} to={`/propertyLibrary/${camp.id}`}>
          <PropertyCard
            camp={{
              id: camp.id,
              name: camp.name,
              park: camp.park,
              description: camp.caption,
              imageUrl: camp.heroImageUrl,
              locationCategory: camp.locationCategory,
              mobileLocation: camp.mobileLocation,
              propertyType: camp.propertyType,
            }}
          />
        </Link>
      ))}
    </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default NomadCamps;