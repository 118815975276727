import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { CalendarIcon, UserIcon } from '@heroicons/react/24/solid';
import { EyeIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import ReactTooltip from 'react-tooltip';
import { Index, Configure, connectInfiniteHits } from 'react-instantsearch-dom';
import { format, differenceInDays, startOfDay, addDays } from 'date-fns';
import ButtonPrimarySmall from '../../../../components/Buttons/ButtonPrimarySmall';
import ButtonOutlineSmall from '../../../../components/Buttons/ButtonOutlineSmall';

import { passToTravelDesk, updateItineraryHiddenStatus } from '../../../../functions/viewItineraryFunctions';

const SkeletonCard = () => (
  <div className="bg-white p-4 mb-4 rounded shadow animate-pulse">
    <div className="flex justify-between items-start mb-2">
      <div className="w-3/4">
        <div className="h-5 bg-gray-200 rounded w-full mb-2"></div>
        <div className="h-3 bg-gray-200 rounded w-1/2"></div>
      </div>
      <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
    </div>
    <div className="flex justify-between items-center mt-4">
      <div className="h-3 bg-gray-200 rounded w-1/4"></div>
      <div className="h-3 bg-gray-200 rounded w-1/4"></div>
    </div>
  </div>
);

const Card = React.memo(({ card, onQuickView, onPassToTravelDesk, showPassToTravelDesk, onUnhide, showUnhide, title }) => {
  const [confirmSend, setConfirmSend] = useState(false);
  const [confirmUnhide, setConfirmUnhide] = useState(false);

  const handleSendToTravelDesk = async () => {
    if (confirmSend) {
      try {
        await passToTravelDesk(card.objectID);
        onPassToTravelDesk(card);
        setConfirmSend(false);
      } catch (error) {
        console.error('Error passing to Travel Desk:', error);
        // Handle the error as needed
      }
    } else {
      setConfirmSend(true);
    }
  };

  const handleUnhide = async () => {
    if (confirmUnhide) {
      try {
        await updateItineraryHiddenStatus(card.objectID, false);
        onUnhide(card);
        setConfirmUnhide(false);
      } catch (error) {
        console.error('Error unhiding itinerary:', error);
        // Handle the error as needed
      }
    } else {
      setConfirmUnhide(true);
    }
  };

  const formatExpiryDate = (dateExpiryMillis) => {
    if (!dateExpiryMillis || isNaN(new Date(dateExpiryMillis).getTime())) {
      return 'Invalid date';
    }
    const date = new Date(dateExpiryMillis);
    return format(date, "EEE, MMM d 'at' HH:mm"); 
  };

  const now = new Date();
  const expiryDate = new Date(card.dateExpiry);
  const daysDifference = !isNaN(expiryDate.getTime()) ? differenceInDays(expiryDate, now) : null;
  const isExpiringSoon = daysDifference !== null && daysDifference <= 1; // Within 24 hours
  const isProvisionalStatus = card.status === 'provisional' || card.status === 'pendingProvisional';

  
  return (
    <motion.div 
      className={`p-4 mb-4 rounded-lg border hover:shadow-md transition-all hover:border-nomadBlue-100 
        ${isExpiringSoon && isProvisionalStatus ? 'bg-red-50 border-red-100' : 'bg-white border-[#f0ece7]'}`}
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 0.3 }}
    >
      <div className="flex justify-between items-start mb-3">
        <div className="flex-grow mr-3">
          {/* Primary Info */}
          <h4 className="font-semibold text-nomadBlue-600 text-base mb-1">
  {card.clientName} × {((card.totalAdults || 0) + (card.totalChildren || 0) - (card.totalGuides || 0))}
</h4>
<div className="space-y-1">
  <p className="text-sm text-gray-700 flex items-center gap-2">
    <span className="font-medium">Reference</span> : {card.code || 'N/A'}
    {!card.user?.internalUser && (
      <span 
      className="inline-flex items-center px-2 py-0.5 text-xs font-medium bg-nomadBlue-50 text-nomadBlue-600 rounded-full shadow-sm ring-1 ring-nomadBlue-400/20"
      data-tip={`External Booking by ${card.user?.name || 'Unknown User'}`}
      data-for={`external-${card.objectID}`}
    >
      EXTERNAL
    </span>
    )}
  </p>
  <ReactTooltip id={`external-${card.objectID}`} effect="solid" />
            {title === 'Hidden/In Progress' && card.status && (
              <div className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md">
                <span className="text-xs text-gray-600">
                  {card.status.charAt(0).toUpperCase() + card.status.slice(1)}
                </span>
              </div>
            )}
          </div>
        </div>
        <button 
          onClick={() => onQuickView(card)} // Change this line
          className="bg-nomadEvergreen-50 hover:bg-nomadEvergreen-100 border-none rounded-full p-2 transition-colors"
        >
          <EyeIcon className="text-nomadEvergreen-700 w-4 h-4" />
        </button>
      </div>

      {/* Footer Info */}
      <div className="flex justify-between items-center pt-2 border-t border-[#f0ece7]">
        <div className="flex items-center text-xs text-gray-600">
          <UserIcon className="w-3 h-3 mr-1.5" />
          <span className="truncate">{card.agent?.name || 'N/A'}</span>
        </div>
        <div className="flex items-center text-xs text-gray-600">
          <CalendarIcon className="w-3 h-3 mr-1.5" />
          <span>{format(new Date(card.dateStart), 'dd MMM yyyy')}</span>
        </div>
      </div>

      {/* Expiry Info - if needed */}
      {card.status === 'provisional' && daysDifference !== null && (
        <div className="mt-2 flex items-center space-x-1.5 text-xs">
          <div className="inline-flex items-center px-2 py-1 bg-red-50 text-red-600 rounded-md border border-red-100">
            <span className="font-medium">{isExpiringSoon ? 'Expires soon:' : 'Expires:'}</span>
            <span className="ml-1">{formatExpiryDate(card.dateExpiry)}</span>
            {!isExpiringSoon && (
              <span className="ml-1 font-medium">({daysDifference + 1} day{daysDifference !== 0 && 's'})</span>
            )}
          </div>
        </div>
      )}

      {/* Updated Action Buttons */}
      {(showPassToTravelDesk || showUnhide) && (
        <div className="mt-4 flex flex-col space-y-2">
          {showPassToTravelDesk && (
            <div className="flex space-x-2">
              <ButtonPrimarySmall
                text={confirmSend ? 'Confirm Send' : 'Send to Travel Desk'}
                onClick={handleSendToTravelDesk}
                color="dark"
                className="flex-1"
              />
              {confirmSend && (
                <ButtonOutlineSmall
                  text="Cancel"
                  onClick={() => setConfirmSend(false)}
                  color="dark"
                  className="flex-1"
                />
              )}
            </div>
          )}
          {showUnhide && (
            <div className="flex space-x-2">
              <ButtonPrimarySmall
                text={confirmUnhide ? 'Confirm Unhide' : 'Unhide'}
                onClick={handleUnhide}
                color="dark"
                className="flex-1"
              />
              {confirmUnhide && (
                <ButtonOutlineSmall
                  text="Cancel"
                  onClick={() => setConfirmUnhide(false)}
                  color="dark"
                  className="flex-1"
                />
              )}
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
});

const getLaneColor = (title) => {
  switch (title.toLowerCase()) {
    case 'quotation':
      return {
        strip: 'bg-yellow-500',
        background: 'bg-yellow-50/30'
      };
    case 'hidden/in progress':
      return {
        strip: 'bg-gray-500',
        background: 'bg-gray-50/30'
      };
    case 'pending provisional':
      return {
        strip: 'bg-orange-500',
        background: 'bg-orange-50/30'
      };
    case 'provisional':
      return {
        strip: 'bg-blue-500',
        background: 'bg-blue-50/30'
      };
    case 'pending confirmation':
      return {
        strip: 'bg-purple-500',
        background: 'bg-purple-50/30'
      };
    case 'confirmed':
      return {
        strip: 'bg-green-500',
        background: 'bg-green-50/30'
      };
    case 'awaiting travel desk':
      return {
        strip: 'bg-nomadBlue-500',
        background: 'bg-nomadBlue-50/30'
      };
    case 'with travel desk':
      return {
        strip: 'bg-nomadEvergreen-500',
        background: 'bg-nomadEvergreen-50/30'
      };
    case 'currently travelling':
      return {
        strip: 'bg-teal-500',
        background: 'bg-teal-50/30'
      };
    default:
      return {
        strip: 'bg-gray-300',
        background: 'bg-gray-50/30'
      };
  }
};

const Lane = connectInfiniteHits(({ hits, hasMore, refineNext, title, onQuickView, onPassToTravelDesk, onUnhide }) => {
  const tooltipId = `tooltip-${title.toLowerCase().replace(/\s+/g, '-')}`;
  const colors = getLaneColor(title);

    // Sort hits by expiry date if it's a provisional lane
    const sortedHits = useMemo(() => {
      if (title === 'Provisional' || title === 'Pending Provisional') {
        return [...hits].sort((a, b) => {
          const aExpiry = new Date(a.dateExpiry).getTime();
          const bExpiry = new Date(b.dateExpiry).getTime();
          return aExpiry - bExpiry; // Ascending order (closest expiry first)
        });
      }
      return hits;
    }, [hits, title]);

  const handleLoadMore = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight && hasMore) {
      refineNext();
    }
  };

  const getStatusDescription = (status) => {
    switch (status.toLowerCase()) {
      case 'quotation':
        return 'Itineraries in the quotation stage';
      case 'hidden/in progress':
        return 'Itineraries that are hidden or in progress';
      case 'pending provisional':
        return 'Itineraries waiting to be made provisional';
      case 'provisional':
        return 'Provisionally booked itineraries';
      case 'pending confirmation':
        return 'Itineraries waiting to be confirmed';
      case 'confirmed':
        return 'Fully confirmed itineraries still due to travel';
      case 'currently travelling':
        return 'Active itineraries with travelers currently on their journey. These trips have started but not yet concluded.';
      case 'awaiting travel desk':
        return 'Confirmed bookings awaiting processing to the travel desk';
      case 'with travel desk':
        return 'Confirmed bookings currently with the travel desk and due for travel';
      default:
        return 'Status description not available.';
    }
  };

  return (
    <div className="flex-1 rounded-lg border border-[#f0ece7] shadow-sm min-w-[350px] max-w-[400px] overflow-hidden flex flex-col h-full">
      {/* Color Strip */}
      <div className={`h-1 ${colors.strip}`} />
      
      {/* Lane Header */}
      <div className={`p-4 border-b border-[#f0ece7] ${colors.background}`}>
        <div className="flex items-center justify-between mb-1">
          <div className="flex items-center">
            <h3 className="text-lg font-semibold text-nomadBlue-600">{title}</h3>
            <InformationCircleIcon 
              className="w-4 h-4 ml-2 text-gray-400 hover:text-gray-600 cursor-help"
              data-tip
              data-for={tooltipId}
            />
            <ReactTooltip 
              id={tooltipId}
              effect="solid"
              className="max-w-xs"
            >
              {getStatusDescription(title)}
            </ReactTooltip>
          </div>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-white/80 text-nomadBlue-700 border border-[#f0ece7]">
            {hits.length}
          </span>
        </div>
      </div>


      {/* Lane Content */}
      <div 
        className={`flex-1 p-4 max-h-[calc(100vh-180px)] overflow-y-auto scrollbar-thin scrollbar-thumb-nomadBlue-100 scrollbar-track-transparent ${colors.background}`}
        onScroll={handleLoadMore}
      >
           <AnimatePresence>
          {sortedHits.map((hit) => (
            <Card 
              key={hit.objectID} 
              card={hit} 
              onQuickView={onQuickView}
              onPassToTravelDesk={onPassToTravelDesk}
              onUnhide={onUnhide}
              showPassToTravelDesk={title === 'Awaiting Travel Desk'}
              showUnhide={title === 'Hidden/In Progress'}
              title={title}
            />
          ))}
        </AnimatePresence>
        {hasMore && (
          <div className="py-2">
            <SkeletonCard />
          </div>
        )}
      </div>
    </div>
  );
});

const KanbanBoard = ({ searchClient, indexName, filters, onQuickView, user, laneKeys, refreshingLanes, refreshLane }) => {
  const todayTimestamp = useMemo(() => Math.floor(startOfDay(new Date()).getTime()), []);

  const laneDefinitions = useMemo(
    () => [
      { id: 'quotation', title: 'Quotation', filter: 'status:quotation' },
      { id: 'hidden', title: 'Hidden/In Progress', filter: 'hidden:true' },
      { id: 'pendingProvisional', title: 'Pending Provisional', filter: 'status:pendingProvisional' },
      { id: 'provisional', title: 'Provisional', filter: 'status:provisional' },
      { id: 'pendingConfirmation', title: 'Pending Confirmation', filter: 'status:pendingConfirmation' },
      { id: 'confirmed', title: 'Confirmed', filter: `status:confirmed AND dateEndTimestamp >= ${todayTimestamp}` },
      { id: 'awaitingTravelDesk', title: 'Awaiting Travel Desk', filter: `status:confirmed AND NOT withTravelDesk:true AND dateEndTimestamp >= ${todayTimestamp}` },
      { id: 'withTravelDesk', title: 'With Travel Desk', filter: `status:confirmed AND withTravelDesk:true AND dateEndTimestamp >= ${todayTimestamp}` },
      { id: 'onTripNow', title: 'Currently Travelling', filter: `status:confirmed AND dateStartTimestamp <= ${todayTimestamp} AND dateEndTimestamp >= ${todayTimestamp}` },

    ],
    [todayTimestamp]
  );

  const visibleLanes = useMemo(() => {
    if (user.internalUser) {
      return ['hidden', 'pendingProvisional', 'provisional', 'pendingConfirmation', 'awaitingTravelDesk', 'withTravelDesk', 'onTripNow'];
    } else {
      return ['quotation', 'pendingProvisional', 'provisional', 'pendingConfirmation', 'confirmed', 'onTripNow'];
    }
  }, [user.internalUser]);

  const handlePassToTravelDesk = useCallback(
    (card) => {
      refreshLane('awaitingTravelDesk');
      refreshLane('confirmed');
    },
    [refreshLane],
  );

  const handleUnhide = useCallback(
    (card) => {
      refreshLane('hidden');
      // Refresh other lanes that might be affected by unhiding
      refreshLane('pendingProvisional');
      refreshLane('provisional');
      refreshLane('pendingConfirmation');
      refreshLane('confirmed');
    },
    [refreshLane],
  );

  return (
    <div className="flex overflow-x-auto pb-4 space-x-4">
      {laneDefinitions
        .filter((lane) => visibleLanes.includes(lane.id))
        .map((lane) => {
          const laneFilters = `${filters} AND ${lane.filter}`;
          return (
            <Index key={`${lane.id}_${laneKeys[lane.id] || 0}`} indexName={indexName} indexId={`${indexName}_${lane.id}`}>
              <Configure filters={laneFilters} hitsPerPage={100} />
              {refreshingLanes[lane.id] ? (
                <div className="flex-1 bg-gray-100 p-4 rounded-lg mr-4 last:mr-0 min-w-[300px] max-w-[350px]">
                  <div className="mb-4">
                    <h3 className="text-lg font-bold mr-1">{lane.title}</h3>
                    <span className="text-sm text-gray-600">Refreshing...</span>
                  </div>
                  {[...Array(3)].map((_, index) => (
                    <SkeletonCard key={index} />
                  ))}
                </div>
              ) : (
                <Lane title={lane.title} onQuickView={onQuickView} onPassToTravelDesk={handlePassToTravelDesk} onUnhide={handleUnhide} />
              )}
            </Index>
          );
        })}
    </div>
  );
};

export default KanbanBoard;
