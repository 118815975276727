import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import MapComponent from './MapComponent';
import { Badge } from '../../../../components/ui/Badge'; 
function OverviewTab({ camp, firestoreData }) {
  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
  };

  const MobileLocationsTable = () => {
    const currentDate = new Date();
    const futureLocations = firestoreData.customLocations.filter((location) => location.periodEndDate.toDate() > currentDate).sort((a, b) => a.periodStartDate.toDate() - b.periodStartDate.toDate());

    return (
      <div className="bg-white rounded-lg border border-[#f0ece7] flex flex-col p-6 gap-2">
        <div className="flex justify-between items-center">
          <h3 className="text-[#162716] text-lg font-semibold leading-[27px]">Mobile Locations</h3>
        </div>
        {futureLocations.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-[#f4f3f2] rounded-lg">
                  <th className="p-4 text-left text-gray-600 text-sm font-bold uppercase">Period</th>
                  <th className="p-4 text-left text-gray-600 text-sm font-bold uppercase">Location</th>
                  <th className="p-4 text-left text-gray-600 text-sm font-bold uppercase">Closest Airport</th>
                </tr>
              </thead>
              <tbody>
                {futureLocations.map((location, index) => (
                  <tr key={index} className="border-b border-[#f0ece7]">
                    <td className="p-4 text-gray-600 text-sm">
                      {formatDate(location.periodStartDate)} - {formatDate(location.periodEndDate)}
                    </td>
                    <td className="p-4 text-gray-600 text-sm">{location.parkName}</td>
                    <td className="p-4 text-gray-600 text-sm">{location.airportName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-gray-600 text-sm">No upcoming mobile locations scheduled.</p>
        )}
      </div>
    );
  };

  const sections = [
    { title: 'About', content: camp.campInformation.description },
    {
      title: 'Key Facts',
      content: (
        <div className="flex flex-col space-y-2">
          <div className="bg-[#f4f3f2] rounded-lg p-6 mt-2">
            <div className="grid grid-cols-2 gap-x-4 text-sm">
              <div className="space-y-2 font-bold uppercase text-gray-600">
                <div>SUPPLIER</div>
                <div>PRODUCT TIER</div>
                <div>NUMBER OF ROOMS</div>
                <div>LOCATION</div>
                <div>NEAREST AIRPORT</div>
                <div>CHECK-IN TIME</div>
                <div>CHECK-OUT TIME</div>
              </div>
              <div className="space-y-2 text-gray-600">
                <div>{firestoreData.supplier.name|| 'Not specified'}</div>
                <div>Most Preferred</div>
                <div>{camp.campInformation.rooms|| 'Not specified'}</div>
                <div>{firestoreData.mobileLocation ? 'Check Location Tab' : (firestoreData.park.name || 'Not specified')}</div>
                <div>{firestoreData.mobileLocation ? 'Check Location Tab' : (firestoreData.airport.name || 'Not specified')}</div>
                <div>{camp.campInformation.check_in_time || 'Not specified'}</div>
                <div>{camp.campInformation.check_out_time || 'Not specified'}</div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Location',
      content: (
        <div className="flex flex-col space-y-4">
          <div className="text-[#636366]">
            {firestoreData.park.name}, {firestoreData.country.name}
          </div>
          {firestoreData.mobileLocation && <MobileLocationsTable />}
          <MapComponent lat={camp.campInformation.lat} long={camp.campInformation.long} />
        </div>
      ),
    },
    { title: 'Seasons and closures', content: camp.closures, comingSoon: true },
   // { title: 'Seasons', content: camp.seasons },
  //  { title: 'Activities', content: camp.activities },
  //  { title: 'General Facilities', content: camp.generalFacilities },
  { title: 'Dining', content: camp.dining, comingSoon: true },
  ];

  return (
    <div className="w-full space-y-4">
      {sections.map((section, index) => (
        <Disclosure as="div" key={section.title} className="border border-[#f0ece7] rounded-lg overflow-hidden" defaultOpen={index < 3}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`flex w-full justify-between items-center px-6 py-4 text-left text-lg focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 ${
                open ? 'bg-white text-nomadEvergreen-900 font-semibold' : 'bg-nomadGray-300 text-black'
              }`}
            >
              <div className="flex items-center space-x-2">
                <span>{section.title}</span>
                {section.comingSoon && (
                  <Badge variant="warning" size="sm">Coming Soon</Badge>
                )}
              </div>
              <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-6 w-6 ${open ? 'text-nomadEvergreen-900' : 'text-black'}`} />
            </Disclosure.Button>
            <Disclosure.Panel className="px-6 pt-4 pb-6 text-base text-[#636366] bg-white">{section.content}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
      ))}
    </div>
  );
}

export default OverviewTab;

