import { FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdConnectingAirports, MdContentCopy, MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { TbArrowsRandom, TbBuildingWarehouse } from 'react-icons/tb';
import { addItem, deleteItem, handleCut, handlePaste } from '../functions/finalCustomFunctions';

import { GiJeep } from 'react-icons/gi';
import { PiPark } from 'react-icons/pi';
import ViewCompDay from './ViewCompDay';
import ViewCompHeader from './ViewCompHeader';
import ViewCompTableHeader from './ViewCompTableHeader';
import ViewCompTableRow from './ViewCompTableRow';
import ViewFoundItemCard from './ViewFoundItem';
import { formatPrice } from '../functions/generalFunctions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const ViewActivity = ({ item, bookings, booking, activitiesData, customFinalItems, setCustomFinalItems, shouldCollapse, firstDay, setFinalSlideOutDate, finalItem, bookingMaster, manage,  supplierReservations, hideStatusPill }) => {
  // USER
  const { user } = useSelector((state) => state.auth);
  let searchId = item.uuid;
  const foundItems = customFinalItems.filter((search) => search.id === searchId && !search.departure);
  const foundItemsDeparture = customFinalItems.filter((search) => search.id === searchId && search.departure && finalItem && (search.pickupDropoff === undefined || search.pickupDropoff === false));

  const pickupDropoff = false;

  console.log('item activity', JSON.stringify(item, null, 2));

  const [localItem, setLocalItem] = useState({ ...item, collapsed: shouldCollapse }); // Initialize localItem with item prop and set collapsed to true

  useEffect(() => {
    // If you need to respond to changes in the item prop, update localItem here
    setLocalItem((current) => ({ ...current, ...item, collapsed: current.collapsed }));
  }, [item]);

  // DAY
  const firstDayMoment = moment(firstDay, 'YYYY-MM-DD');
  const dayMoment = moment(item.selectedActivityDate, 'YYYY-MM-DD');
  let day = firstDay === item.selectedActivityDate ? 1 : dayMoment.diff(firstDayMoment, 'days') + 1;
  let parentDate = item.selectedActivityDate;
  return (
    <>
      {/* found items */}
      {foundItems.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`brand-text-grey-v2 mb-7 `}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <ViewFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            formatPrice={formatPrice}
            firstDay={firstDay}
            bookingMaster={bookingMaster}
            supplierReservations={supplierReservations}
          />
        </div>
      ))}

      {/* main items */}

      <div key={uuidv4()} style={{ zIndex: 1000, WebkitTransform: 'translateZ(0)' }} className={`my-10`}>
        {/* DAY */}
        
        <div className="bg-white rounded-[15px] border border-gray-200 shadow-md hover:shadow-lg transition-shadow duration-300">
          {/* HEADER */}
          <ViewCompHeader
            day={day}
            item={localItem}
            feeTypeIcon={<PiPark size={30} />}
            description={item.displayName}
            supplierName={item.supplier.name}
            supplierUid={item.supplier.id}
            date={moment(item.selectedActivityDate).format('Do MMM YYYY')}
            total={item.cost}
            collapse={<FiChevronsUp size={30} className="cursor-pointer text-zinc-300" onClick={() => setLocalItem({ ...localItem, collapsed: !localItem.collapsed })} />}
            expand={<FiChevronsDown size={30} className=" cursor-pointer text-zinc-300" onClick={() => setLocalItem({ ...localItem, collapsed: !localItem.collapsed })} />}
            bookingMaster={bookingMaster}
            manage={manage}
            supplierReservationsCollection={supplierReservations}
            hideStatusPill={hideStatusPill}
          />

          {/* ROW TITLES */}
          {!localItem.collapsed && <ViewCompTableHeader />}

          {/* Column propertyItems*/}
          <div className={`${!localItem.collapsed ? 'pb-5' : ''}`}>
            {!localItem.collapsed && (
              <div key={uuidv4()} className="">
                <ViewCompTableRow
                  index={0}
                  lengthCheck={1}
                  description={item.displayName}
                  supplierName={item.supplier.name}
                  units={1}
                  pax={item.activityUnits}
                  net={item.costPerUnit}
                  total={item.cost}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* found items final */}
      {foundItemsDeparture.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`brand-text-grey-v2 mb-7`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <ViewFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            formatPrice={formatPrice}
            firstDay={firstDay}
            bookingMaster={bookingMaster}
          />
        </div>
      ))}
    </>
  );
};

export default ViewActivity;
