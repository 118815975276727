import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../db/firebase.config";
import { useSelector } from "react-redux";
import SupplierRequestForm from "./components/SupplierRequestForm";
import PropertyRequestDetails from "./components/PropertyRequestDetails";

import {
  fetchSupplierRequestData,
  formatDate,
  formatDateWithDay,
  formatPax,
} from "../../functions/supplierRequestsFunctions";

const SupplierRequest = () => {
  const { documentUid } = useParams();
  const [itinerary, setItinerary] = useState(null);
  const [supplierRequestData, setSupplierRequestData] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSupplierRequestData(documentUid);
        setSupplierRequestData(data);
  
        if (data.expired) {
          // Display message if the request has been withdrawn
          setItinerary(null);
          setLoading(false);
          return;
        }
  
        if (data.response) {
          // Display message if a response already exists
          setItinerary(null);
          setLoading(false);
          return;
        }
  
        const itineraryDoc = await getDoc(doc(db, "itineraries", data.itineraryUid));
        if (itineraryDoc.exists()) {
          const itineraryData = itineraryDoc.data();
          setItinerary(itineraryData);
        }
      } catch (error) {
        console.error("Error fetching supplier request data:", error);
      }
      setLoading(false);
    };
  
    const unsubscribe = onSnapshot(
      doc(db, "supplierRequests", documentUid),
      (doc) => {
        if (doc.exists()) {
          const supplierRequestData = doc.data();
          setSupplierRequestData(supplierRequestData);
  
          if (supplierRequestData.expired || supplierRequestData.response) {
            // Display message if the request has been withdrawn or a response already exists
            setItinerary(null);
          }
        }
      }
    );
  
    if (documentUid) {
      fetchData();
    }
  
    return () => {
      unsubscribe();
    };
  }, [documentUid]);



  if (loading) {
    return (
      <div className="flex-grow">
        <div className="px-4 sm:px-4 lg:px-8 py-8 max-w-7xl mx-auto">
          <div className="bg-white rounded-md shadow px-24 py-12 mb-5">
            <p className="text-lg">Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  if (!itinerary) {
    return (
      <div className="flex-grow">
        <div className="px-4 sm:px-4 lg:px-8 py-8 max-w-7xl mx-auto">
          <div className="bg-white rounded-md shadow px-24 py-12 mb-5">
            {supplierRequestData?.expired ? (
              <p className="text-lg">
                This Request has been withdrawn, if you are holding space please
                do not release it until you have spoken to your consultant.
              </p>
            ) : (
              <p className="text-lg">
                This request has already been responded to. Please contact your
                sales consultant if you have any questions.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (supplierRequestData?.sent === false) {
    // Check if the user is logged in and has internalUser set to true
    if (!user || !user.internalUser) {
      return (
        <div className="flex-grow">
          <div className="px-4 sm:px-4 lg:px-8 py-8 max-w-7xl mx-auto">
            <div className="bg-white rounded-md shadow px-24 py-12 mb-5">
              <p className="text-lg">This page is not yet available.</p>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="flex-grow">
      <div className="px-4 sm:px-4 lg:px-8 py-8 max-w-7xl mx-auto">
        <div className="bg-white rounded-md shadow px-24 py-12 mb-5">
          <img
            src="https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/63049197-d40a-4457-eb69-0ed30456d900/640x400"
            alt="Logo"
            className="mx-auto mb-4 w-72 pb-8"
          />
          <h1 className="text-2xl font-bold mb-4 text-center">
            PROVISIONAL BOOKING REQUEST
          </h1>
          <p className="text-base mb-4">
            This is a provisional request from{" "}
            {itinerary.agent.seniorConsultant.name} at Nomad to hold
            accommodation as per the below request details.
          </p>
          <p className="text-base mb-4 font-semibold">
            Please complete the form at the bottom as soon as possible.
          </p>
          <table className="table-auto mb-4">
            <tbody>
              <tr>
                <td className="font-semibold w-40">Our Itinerary Code:</td>
                <td>#{itinerary.code}</td>
              </tr>
              <tr>
                <td className="font-semibold w-40">Party Name:</td>
                <td>{itinerary.clientName}</td>
              </tr>
              <tr>
                <td className="font-semibold w-40">Originating Agent:</td>
                <td>{itinerary.originatingAgent}</td>
              </tr>
            </tbody>
          </table>

          {/* Render property details for each property in the request document  */}
          {supplierRequestData.properties
            .sort((a, b) => a.roomsNum - b.roomsNum)
            .map((propertyRequest) => (
              <PropertyRequestDetails
                key={propertyRequest.id}
                propertyRequest={propertyRequest}
                formatDate={formatDate}
                formatDateWithDay={formatDateWithDay}
                formatPax={formatPax}
                isInternalUser={user && user.internalUser}
                isSent={supplierRequestData.sent}
                supplierRequestId={documentUid}
                specialRequests={supplierRequestData.specialRequests || []}
              />
            ))}

          <hr className="my-8 border-t border-gray-300" />
          {/* Render the supplier form */}
          <SupplierRequestForm documentUid={documentUid} />
        </div>
      </div>
    </div>
  );
};

export default SupplierRequest;
