import React from 'react';

const Breadcrumbs = ({ startSearchButton, step, createEdit }) => {
  const steps = [
    ...(startSearchButton === 'search' ? [{ 
      id: 'bookings',  // This matches the original logic where 'bookings' is used for SELECT ITINERARY
      label: 'SELECT ITINERARY' 
    }] : []),
    { 
      id: startSearchButton === 'start' ? 'bookings' : null,  // Only highlight if startSearchButton is 'start'
      label: startSearchButton === 'start' ? 'PROPERTIES' : 'CUSTOMISE PROPERTIES'
    },
    { id: 'transfers', label: 'TRANSFERS' },
    { id: 'activities', label: 'ACTIVITIES' },
    { id: 'final', label: 'REVIEW' },
    ...(startSearchButton === 'start' ? [{ id: '', label: 'COMPLETE' }] : [])
  ];

  return (
    <nav aria-label="Booking progress" className="mb-2">
      <ol className="relative flex items-center justify-between" style={{ width: 'min-content', minWidth: (step === 'final' || startSearchButton === 'search' || createEdit === 'create') ? '860px' : '860px' }}>
        <div className="absolute h-[2px] bg-zinc-200 left-0 right-0 top-3" />
        
        {steps.map((item, index) => (
          <li key={index} className="relative flex flex-col items-center">
            <div className={`relative z-10 flex items-center justify-center w-6 h-6 rounded-full text-sm
              ${(step === item.id)
                ? 'bg-[#489EF8] text-white' 
                : 'bg-white border-2 border-zinc-300 text-zinc-400'
              } transition-all duration-300`}
            >
              {index + 1}
            </div>
            
            <span className={`mt-1 text-[11px] font-medium uppercase whitespace-nowrap
              ${(step === item.id)
                ? 'text-[#489EF8]' 
                : 'text-zinc-400'
              } transition-colors duration-300`}
            >
              {item.label}
            </span>
          </li>
        ))}
      </ol>
    </nav>
  );
};
export default Breadcrumbs;
