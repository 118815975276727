import axios from 'axios';

const createUrl = async (uid, item, versionParam = '', multipleVersions = false) => {
  const url = '/api/createUrl';
  const config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: { uid, item, versionParam, multipleVersions },
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Failed to create URL:', error);
    return { error: true, errorMsg: 'Failed to generate document' };
  }
};

export { createUrl};