import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import {
  arrivalDepartureTimeOptions,
  calculateRateTransferUnits,
  filterTransferRouteRates,
  findTransferRate,
  findTransferRatesAll,
  getAeroCrsCode,
  getAirportCodeFromUid,
  getAirportCodes,
  getAirportDetails,
  getAirportName,
  getAirportProperties,
  getOriginPropertyDetails,
  hideOwnArrangements,
} from '../functions/transferFunctions';
import { calculateFlightCost, calculateFlightPricing, getAvailability, getDeepLink, getFlightLocation, processFlights } from '../functions/aeroFunctions';
import { capitalizeFirstLetter, formatPrice, getPropObj } from '../functions/generalFunctions';
import { closeForm, handleFormSubmitAirport, handleFormSubmitOwnArrangements, handleFormSubmitSafari, handleFormSubmitTown, handleOptionClickTransfer } from '../functions/transferFormsFunctions';
import { getTimeDifference, nomadVehicleRates, nonNomadVehicleRates } from '../functions/generalFunctions';
import { loadConnectingAirports, loadTransferRouteUid } from '../functions/loadDataFunctions';

import CustomModal from '../components/CustomModal';
import CustomSelect from '../components/CustomSelect';
import CustomSelectNoBorderTransfers from '../components/CustomSelectNoBorderTransfers';
import { FaLocationDot } from 'react-icons/fa6';
import { FaLongArrowAltRight } from 'react-icons/fa';
import FlightResultsComponent from '../components/FlightResultsComponent';
import { IoLocationOutline } from 'react-icons/io5';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { LiaPlaneSolid } from 'react-icons/lia';
import { LuChevronDown } from 'react-icons/lu';
import PackedLunch from './PackedLunch';
import { TbTriangle } from 'react-icons/tb';
import TransferArrivalDepartureVip from './TransferArrivalDepartureVip';
import TransferArrivalOrDeparture from './TransferArrivalOrDeparture';
import TransferInfoCard from './TransferInfoCard';
import TransferInfoFlightCard from './TransferInfoFlightCard';
import TransferRouteComponent from '../components/TransferRouteComponent';
import { XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { processVip } from '../functions/transferFunctions';
import { set } from 'lodash';
import { toast } from 'react-toastify';

function TransferSlideOutContent({
  isOpen,
  onClose,
  transfer,
  nextTransfer,
  prevTransfer,
  transfers,
  setTransfers,
  deeShadow,
  SlPlane,
  timeRef,
  setSelectedTransferTuuid,
  GiJeep,
  MdOutlineLocationOn,
  CgUnavailable,
  BiTransfer,
  TbTransferIn,
  freshUuid,
  routeTransfer,
  availableFlights,
  airports,
  properties,
  transferType,
  customLocations,
  setIsModalOpen,
  setRefreshTransferRates,
  bookings,
  setBookings,
  booking,
  airlines,
  agentObject,
  createEdit,
  createVersion,
  transfersDataFlights,
  user,
  suppliers,
  advancedMode,
}) {
  //advancedMode = false;
  // reset variables
  //const resetForm = useCallback(() => {});

  // useEffect(() => {
  //   if (isOpen && !transfer) {
  //     // If the slide-out is open but the required data is not available, close the slide-out
  //     onClose();
  //   }
  // }, [isOpen, transfer, onClose]);

  useEffect(() => {
    if (isOpen && !transfer) {
      // If the slide-out is open but the required data is not available, close the slide-out

      onClose();
      //return null;
    }
  }, [isOpen, transfer, onClose]);

  // if (!transfer) {
  //   // If the required data is not available, don't render the slide-out
  //   return null;
  // }
  // ORIGINAL START

  // #region
  const [flightResults, setFlightResults] = useState(false);
  const [flightResultsSecond, setFlightResultsSecond] = useState(false);
  const [currentPages, setCurrentPages] = useState([]);

  const [flightsAvailable, setFlightsAvailable] = useState(availableFlights === 'No Results' ? false : true);
  const [selectedFlightIndex, setSelectedFlightIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [buttonsToShow, setButtonsToShow] = useState(100); // Use 7
  const [localSelectedIndex, setLocalSelectedIndex] = useState(null);
  const [localSelectedIndexSecond, setLocalSelectedIndexSecond] = useState(null);
  const [localSelectedIndices, setLocalSelectedIndices] = useState([]);
  const [originDetails, setOriginDetails] = useState(getAirportProperties(transfer.propUid, properties));
  const [destinationDetails, setDestinationDetails] = useState(getAirportProperties(nextTransfer.propUid, properties));
  const [airportTransfer, setAirportTransfer] = useState(null);

  const [departureTimeOwnArrangements, setDepartureTimeOwnArrangements] = useState('Select time');
  const [arrivalTimeOwnArrangements, setArrivalTimeOwnArrangements] = useState('Select time');
  const [airlineUidOwnArrangements, setAirlineUidOwnArrangements] = useState('');
  const [airlineNameOwnArrangements, setAirlineNameOwnArrangements] = useState('Select Airline');

  const [finalOriginFlightUid, setFinalOriginFlightUid] = useState(null);
  const [finalDestinationFlightUid, setFinalDestinationFlightUid] = useState(null);
  const [originAirportName, setOriginAirportName] = useState(null);
  const [destinationAirportName, setDestinationAirportName] = useState(null);
  const [originAirportUid, setOriginAirportUid] = useState(null);
  const [originAirportCode, setOriginAirportCode] = useState(null);
  const [destinationAirportCode, setDestinationAirportCode] = useState(null);
  const [destinationAirportUid, setDestinationAirportUid] = useState(null);
  const [originLocationUid, setOriginLocationUid] = useState(null);
  const [destinationLocationUid, setDestinationLocationUid] = useState(null);
  const [transferRoute, setTransferRoute] = useState(null);
  const [rateObject, setRateObject] = useState(null);
  const [rateType, setRateType] = useState(null);
  const [transferUnits, setTransferUnits] = useState(null);

  const [loadInfants, setLoadInfants] = useState(0);

  const [loadAdults, setLoadAdults] = useState(0);
  const [loadChildren, setLoadChildren] = useState(0);
  const [flightAdults, setFlightAdults] = useState(0);
  const [flightChildren, setFlightChildren] = useState(0);
  useEffect(() => {
    if (!isOpen || isOpen) {
      setLoadAdults(transfer.totalAd);
      setLoadChildren(transfer.totalCh);
      setFlightAdults(transfer.totalAd);
      setFlightChildren(transfer.totalCh);
    }
  }, [isOpen, transfer.totalAd, transfer.totalCh]);

  const [flightInfants, setFlightInfants] = useState(loadInfants);

  const [selectedAges, setSelectedAges] = useState(transfer.selectedAges);
  const [selectedAgesAdults, setSelectedAgesAdults] = useState(transfer.selectedAgesAdults);

  useEffect(() => {
    setSelectedAges(transfer.selectedAges);
    setSelectedAgesAdults(transfer.selectedAgesAdults);
  }, [transfer.selectedAges, transfer.selectedAgesAdults]);

  const [searchAgain, setSearchAgain] = useState(false);
  const [reCheckPax, setReCheckPax] = useState(false);
  const [flightCost, setFlightCost] = useState(0);
  const [flightCostSecond, setFlightCostSecond] = useState(0);

  const [airlineName, setAirlineName] = useState(transfer.formValues && transfer.edit && transfer.formValues.type === 'flight' ? transfer.formValues.airlineName : '');
  const [airlineNameSecond, setAirlineNameSecond] = useState('');

  const [flightPricing, setFlightPricing] = useState([]);
  const [flightPricingSecond, setFlightPricingSecond] = useState([]);
  const [fltNum, setFltNum] = useState('');

  const [dateAdjusted, setDateAdjusted] = useState(transfer.formValues && transfer.formValues.dateAdjusted === 'true' ? true : false);

  const [packedLunch, setPackedLunch] = useState(false);
  const [packedLunchChargeable, setPackedLunchChargeable] = useState(false);

  // NEW ARRIVAL DEPARTURE CHECK BOXES - START
  const [includeArrivalTransferCheck, setIncludeArrivalTransferCheck] = useState(
    transfer.formValues && transfer.formValues.includeArrivalTransfer !== undefined
      ? transfer.formValues.includeArrivalTransfer
      : transfer.formValues && transfer.formValues.includeArrivalTransfer === undefined
      ? false
      : true,
  );
  const [includeDepartureTransferCheck, setIncludeDepartureTransferCheck] = useState(
    transfer.formValues && transfer.formValues.includeDepartureTransfer !== undefined
      ? transfer.formValues.includeDepartureTransfer
      : transfer.formValues && transfer.formValues.includeDepartureTransfer === undefined
      ? false
      : true,
  );

  const [includeArrivalTransferVip, setIncludeArrivalTransferVip] = useState(
    transfer.formValues && transfer.formValues.includeArrivalVip !== undefined ? transfer.formValues.includeArrivalVip : false,
  );
  const [includeDepartureTransferVip, setIncludeDepartureTransferVip] = useState(
    transfer.formValues && transfer.formValues.includeDepartureVip !== undefined ? transfer.formValues.includeDepartureVip : false,
  );

  // useEffect(() => {
  //   if (isOpen) {
  //     setIncludeArrivalTransferCheck(
  //       transfer.formValues && transfer.formValues.includeArrivalTransfer !== undefined
  //         ? transfer.formValues.includeArrivalTransfer
  //         : transfer.formValues && transfer.formValues.includeArrivalTransfer === undefined
  //         ? false
  //         : true,
  //     );
  //     setIncludeDepartureTransferCheck(
  //       transfer.formValues && transfer.formValues.includeDepartureTransfer !== undefined
  //         ? transfer.formValues.includeDepartureTransfer
  //         : transfer.formValues && transfer.formValues.includeDepartureTransfer === undefined
  //         ? false
  //         : true,
  //     );
  //     console.log('transfer.formValues.includeDepartureTransfer', transfer.formValues.includeDepartureTransfer);
  //   }
  // }, [isOpen, transfer]);

  useEffect(() => {
    if (!isOpen) {
      // setIncludeArrivalTransferCheck(true);
      //setIncludeDepartureTransferCheck(true);
    }
  }, [isOpen]);

  // NEW ARRIVAL DEPARTURE CHECK BOXES - END

  const [resetPackedLunch, setResetPackedLunch] = useState(false);
  const [arrivalTime, setArrivalTime] = useState('Select time');
  const [departureTime, setDepartureTime] = useState('Select time');
  const [addLater, setAddLater] = useState(false);

  const [accessLevel, setAccessLevel] = useState('limited');
  const [accessLevelHideOwnArrangements, setAccessLevelHideOwnArrangements] = useState('xxx');

  const [showButtons, setShowButtons] = useState(transfer.formValues && transfer.edit && transfer.formValues.type === 'flight' ? true : false);
  const [showButtonsSecond, setShowButtonsSecond] = useState(transfer.formValues && transfer.edit && transfer.formValues.type === 'flight' ? true : false);

  useEffect(() => {
    if (resetPackedLunch) {
      setPackedLunch(false);
      setPackedLunchChargeable(true);
      setResetPackedLunch(false);
    }
  }, [resetPackedLunch]);

  console.log(' : ', flightsAvailable);
  console.log(JSON.stringify(availableFlights, undefined, 4));
  console.log('transferType: ' + transferType);

  const getFlightPricing = async (flight, liveDateAdjusted) => {
    let airlineDesignator = flight.airlineDesignator;

    const flightPricing = await calculateFlightPricing(
      flight, // Pass the correct flight result for the outerIndex
      null,
      flightAdults,
      flightChildren,
      flightInfants,
      airlineDesignator,
      liveDateAdjusted,
      agentObject,
      selectedAges,
      selectedAgesAdults,
      true,
      classXl,
      transfersDataFlights,
      transfer,
    );

    return flightPricing;
  };

  const handleOptionClickGetFlights = useCallback(async (event, firstFlight) => {
    console.log('event', event);
    setShowButtons(false);
    console.log('handleOptionClickGetFlights 1');
    setArrivalTime(() => 'Select time');
    if (firstFlight) {
      handleOptionClickTransfer(event, setSelectedContent, setResetPackedLunch);
    }

    const isValidJSON = (str) => {
      try {
        JSON.parse(str);
        return true;
      } catch {
        return false;
      }
    };

    const savedFlightDetails = false;
    if (savedFlightDetails && savedFlightDetails.flightsArray) {
      console.log('handleOptionClickGetFlights 2');
      const savedFlightIndex = savedFlightDetails.flightsArray.findIndex((flight) => flight.flightid === savedFlightDetails.flightid);
      setFlightResults({
        count: savedFlightDetails.flightsArray.length,
        flight: savedFlightDetails.flightsArray,
      });

      setSelectedFlightIndex(savedFlightIndex);
      setSelectedFlight(savedFlightDetails);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      console.log('handleOptionClickGetFlights 3');

      // New code
      let getOriginDetails;
      // new search airport codes
      let originCode = false;
      let destinationCode = false;

      if (transfer.objType === 'arrivalTransfer') {
        console.log('handleOptionClickGetFlights 4');
        const res = getOriginPropertyDetails(transfer, transfers, properties, airports);
        // new search airport codes
        originCode = res.code;
        getOriginDetails = res.property;

        console.log('res ', originCode);
      } else {
        console.log('handleOptionClickGetFlights 5');
        originCode = false;
        getOriginDetails = getAirportProperties(transfer.propUid, properties);
        console.log('res 2', originCode);
      }

      // New code
      if (savedFlightDetails) {
        console.log('handleOptionClickGetFlights 6s');
        const savedFlightDetails = transfer.formValues.flightDetails;
        const savedFlightIndex = savedFlightDetails.flightsArray.findIndex(
          (flight) => flight.fltnum === savedFlightDetails.fltnum && flight.fromcode === savedFlightDetails.fromcode && flight.tocode === savedFlightDetails.tocode,
        );
        setFlightResults({
          count: savedFlightDetails.flightsArray.length,
          flight: savedFlightDetails.flightsArray,
        });

        setSelectedFlightIndex(savedFlightIndex);
        setSelectedFlight(savedFlightDetails);
      } else {
        console.log('No flight results');
        // new search airport codes
        // build new flight check here for multiple flights

        // get dates
        let startDay = transfer.startDay;
        let endDay = transfer.endDay;

        // Check if startDay is an object, then convert to date
        if (typeof startDay === 'object' && startDay !== null && 'seconds' in startDay) {
          let date = moment.unix(startDay.seconds);
          startDay = date.format('ddd MMM D YYYY HH:mm:ss [GMT]Z [(]ZZ[)]').utc();
        }

        // Check if endDay is an object, then convert to date
        if (typeof endDay === 'object' && endDay !== null && 'seconds' in endDay) {
          let date = moment.unix(endDay.seconds);
          endDay = date.format('ddd MMM D YYYY HH:mm:ss [GMT]Z [(]ZZ[)]').utc();
        }

        // get property ids
        const firstPropIdID = transfer.propUid;
        const secondPropIdID = nextTransfer ? nextTransfer.propUid : null;

        // NEW 04/06/24
        // build origin code
        let flightOrigin;
        let flightDestination;

        if (selectedDirectMultipleValue === 'Direct') {
          if (!originCode) {
            flightOrigin = await getFlightLocation(properties, firstPropIdID, endDay, transfer, customLocations, airports);
          } else {
            flightOrigin = originCode;
          }

          // build destination code

          if (!destinationCode) {
            flightDestination = await getFlightLocation(properties, secondPropIdID, endDay, nextTransfer, customLocations, airports);
          } else {
            flightDestination = destinationCode;
          }
          let flightToSearch = airports.find((airport) => airport.code === flightDestination);
          setFlightToSearchAirport(flightToSearch);
        } else {
          let flightToSearch = airports.find((airport) => airport.uid === connectingAirportUid);
          if (firstFlight && connectingAirportUid) {
            if (!originCode) {
              flightOrigin = await getFlightLocation(properties, firstPropIdID, endDay, transfer, customLocations, airports);
            } else {
              flightOrigin = flightToSearch.code;
            }

            // build destination code

            console.log('connectingAirportUid 1', connectingAirportUid);
            console.log('flightToSearch', flightToSearch);
            flightDestination = flightToSearch.code;

            flightToSearch = airports.find((airport) => airport.code === flightDestination);
            setFlightToSearchAirport(flightToSearch);
          } else if (!firstFlight && connectingAirportUid) {
            // build origin code
            let flightToSearch = airports.find((airport) => airport.uid === connectingAirportUid);
            console.log('connectingAirportUid 2', connectingAirportUid);
            console.log('flightToSearch', flightToSearch);
            //flightDestination = flightToSearch.code;
            flightOrigin = flightToSearch.code;

            //let flightToSearchSecond = airports.find((airport) => airport.code === flightDestination);
            // build destination code
            if (!destinationCode) {
              flightDestination = await getFlightLocation(properties, secondPropIdID, endDay, nextTransfer, customLocations, airports);
            } else {
              flightDestination = destinationCode;
            }
            console.log('connectingAirportUid 3 flightDestination', flightDestination);
            let flightToSearchSecond = airports.find((airport) => airport.code === flightDestination);
            setFlightToSearchAirportSecond(flightToSearchSecond);
            // setAirportIdSecond(flightToSearchSecond.uid);
          } else {
            console.log('connectingAirportUid 3', connectingAirportUid);
          }
        }

        // get origin and destination airport codes
        console.log('check values');
        console.log('flightOrigin');
        console.log(JSON.stringify(flightOrigin, undefined, 4));
        console.log('flightDestination');
        console.log(JSON.stringify(flightDestination, undefined, 4));

        let originAirportCodes;
        let destinationAirportCodes;
        if (selectedDirectMultipleValue === 'Direct') {
          if (Array.isArray(flightOrigin)) {
            originAirportCodes = flightOrigin.length > 1 ? flightOrigin : getAirportCodes(airports, flightOrigin[0]);
          } else {
            originAirportCodes = getAirportCodes(airports, flightOrigin);
          }

          if (Array.isArray(flightDestination)) {
            destinationAirportCodes = flightDestination.length > 1 ? flightDestination : getAirportCodes(airports, flightDestination[0]);
          } else {
            destinationAirportCodes = getAirportCodes(airports, flightDestination);
          }
        } else {
          originAirportCodes = [flightOrigin];
          destinationAirportCodes = [flightDestination];
        }

        console.log('originAirportCodes');
        console.log(JSON.stringify(originAirportCodes, undefined, 4));
        console.log('destinationAirportCodes');
        console.log(JSON.stringify(destinationAirportCodes, undefined, 4));

        //let availableFlightsResults = [];

        processFlights(
          originAirportCodes,
          destinationAirportCodes,
          properties,
          transfer,
          nextTransfer,
          customLocations,
          airports,
          flightAdults,
          flightChildren,
          flightInfants,
          createEdit,
          createVersion,
          airlines,
        )
          .then(async (results) => {
            console.log('All flights processed:');
            console.log(JSON.stringify(results, undefined, 4));

            // update results to check for flights without flightRouteUid but with matching fltnum, STD, STA, and flightcode and filter them out
            // This fixes duplicate flights from AeroCRS
            const filteredFlightResults = results.map((obj) => {
              return {
                ...obj,
                flight: obj.flight.filter((flight, index, self) => {
                  if (!flight.flightRouteUid) {
                    return (
                      self.findIndex(
                        (f) =>
                          f.fltnum === flight.fltnum &&
                          f.STD === flight.STD &&
                          f.STA === flight.STA &&
                          f.flightcode === flight.flightcode &&
                          f.fromcode === flight.fromcode &&
                          f.tocode === flight.tocode,
                      ) === index
                    );
                  }
                  return true;
                }),
              };
            });

            let mergedResults = {};

            filteredFlightResults.forEach((flightResult) => {
              if (flightResult.dateAdjusted) {
                setDateAdjusted(true);
              }
              const mergeKey = flightResult.merge;
              if (!mergedResults[mergeKey]) {
                mergedResults[mergeKey] = {
                  merge: mergeKey,
                  count: 0,
                  flight: [],
                  dateAdjusted: flightResult.dateAdjusted,
                };
              }

              // Check for duplicate flight entries
              const isDuplicate = mergedResults[mergeKey].flight.some(
                (existingFlight) => existingFlight.flightRouteUid === flightResult.flight[0].flightRouteUid && existingFlight.flightcode === flightResult.flight[0].flightcode,
              );

              if (!isDuplicate) {
                // If not a duplicate, increment the count and push the flight object
                mergedResults[mergeKey].count += flightResult.count;
                mergedResults[mergeKey].flight.push(...flightResult.flight);
              }
            });

            // Convert mergedResults object to an array
            const mergedFlightsArray = Object.values(mergedResults);

            console.log('mergedFlightsArray 1');
            console.log(JSON.stringify(mergedFlightsArray, undefined, 4));

            //remove duplicate UID and sort by date
            mergedFlightsArray.forEach((flightGroup) => {
              const uniqueFlightsWithUID = {};
              const flightsWithoutUID = [];

              flightGroup.flight.forEach((flight) => {
                if (flight.uid) {
                  if (!uniqueFlightsWithUID[flight.uid]) {
                    uniqueFlightsWithUID[flight.uid] = flight;
                  }
                } else {
                  flightsWithoutUID.push(flight);
                }
              });

              flightGroup.flight = [...Object.values(uniqueFlightsWithUID), ...flightsWithoutUID];

              // Sort flights by STD
              flightGroup.flight.sort((a, b) => new Date(a.STD) - new Date(b.STD));
            });

            //setFlightResults(mergedFlightsArray);

            console.log('mergedFlightsArray 3');

            console.log(JSON.stringify(mergedFlightsArray, undefined, 4));
            // Step 2: Update your component's state with the processed data

            // Add flight pricing to each flight in mergedFlightsArray
            const updatedFlightsArray = await Promise.all(
              mergedFlightsArray.map(async (mergedFlight) => {
                const updatedFlightsArray = await Promise.all(
                  mergedFlight.flight.map(async (flight) => {
                    const flightPricing = await getFlightPricing(flight, mergedFlight.dateAdjusted);
                    return { ...flight, flightPricing };
                  }),
                );
                return { ...mergedFlight, flight: updatedFlightsArray };
              }),
            );

            // Log the updated flights array with flightPricing
            console.log('Updated Flights Array with Pricing:');
            console.log(JSON.stringify(updatedFlightsArray, null, 2));

            // ! NEW CODE START - If property is Greystoke Mahale or Chada Katavi only show flight 'NOM'
            let result;
            if (agentObject.accessLevel === accessLevel && !user.internalUser) {
              if (isSpecialProperty(transfer.propUid) || isSpecialProperty(nextTransfer.propUid)) {
                const filteredFlightsArray = updatedFlightsArray
                  .map((item) => ({
                    ...item,
                    flight: item.flight.filter((flight) => flight.airlineDesignator === 'NOM'),
                  }))
                  .filter((item) => item.flight.length > 0);

                // If no flights remain, return an empty array
                result = filteredFlightsArray.length > 0 ? filteredFlightsArray : [];
              } else {
                result = updatedFlightsArray;
              }
            } else {
              result = updatedFlightsArray;
            }

            // ! NEW CODE END

            // update count of flights
            const updatedFlightsArrayCount = result.map((obj) => {
              return {
                ...obj,
                count: obj.flight.length,
              };
            });

            // Log the updated flights array with flightPricing
            console.log('Updated Flights Array with Count:');
            console.log(JSON.stringify(updatedFlightsArrayCount, null, 2));

            if (firstFlight) {
              setFlightResults(updatedFlightsArrayCount);
            } else {
              setFlightResultsSecond(updatedFlightsArrayCount);
            }

            //setFlightResults(updatedFlightsArrayCount);

            //setFlightResults(mergedFlightsArray);

            //setFlightResults(mergedFlightsArray);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log('An error occurred:', error);
            setIsLoading(false);
          });
      }
    }
  });

  const [modalOpen, setModalOpen] = useState(true);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleButtonClick = async (localIndex, outerIndex, secondFlight) => {
    console.log('Checking flight vars');
    console.log(dateAdjusted);
    console.log(JSON.stringify(flightResults, undefined, 4));

    const currentPageForThisRow = currentPages[outerIndex] || 0;
    const globalIndex = currentPageForThisRow * buttonsToShow + localIndex;

    if (!secondFlight) {
      // If the first flight is selected
      const flightsForOuterIndex = flightResults[outerIndex].flight;
      setLocalSelectedIndices((prevIndices) => {
        const newIndices = Array(prevIndices.length).fill(null);
        newIndices[outerIndex] = localIndex;
        console.log('Updated Indices:', newIndices); // Debugging
        return newIndices;
      });

      setSelectedFlightIndex(globalIndex);
      setSelectedFlight({
        ...flightsForOuterIndex[globalIndex],
        flightsArray: flightsForOuterIndex,
      });
      console.log('selectedFLight');
      console.log(JSON.stringify(selectedFlight, undefined, 4));
      let airlineName = flightsForOuterIndex[globalIndex].airlineName;
      setAirlineName(airlineName);
      let airlineDesignator = flightsForOuterIndex[globalIndex].airlineDesignator;
      const flightPricing = await calculateFlightPricing(
        flightResults[outerIndex], // Pass the correct flight result for the outerIndex
        globalIndex,
        flightAdults,
        flightChildren,
        flightInfants,
        airlineDesignator,
        dateAdjusted,
        agentObject,
        selectedAges,
        selectedAgesAdults,
        false,
        classXl,
        transfersDataFlights,
        transfer,
      );

      console.log('flightPricing');
      console.log(JSON.stringify(flightPricing, undefined, 4));

      setFlightPricing(flightPricing);
      setFlightCost(flightPricing.saleFare.total);
    } else {
      // If the second flight is selected
      const flightsForOuterIndex = flightResultsSecond[outerIndex].flight;
      setLocalSelectedIndicesSecond((prevIndices) => {
        const newIndices = Array(prevIndices.length).fill(null);
        newIndices[outerIndex] = localIndex;
        console.log('Updated Indices:', newIndices); // Debugging line
        return newIndices;
      });

      setSelectedFlightIndexSecond(globalIndex);
      setSelectedFlightSecond({
        ...flightsForOuterIndex[globalIndex],
        flightsArray: flightsForOuterIndex,
      });
      console.log('selectedFLight');
      console.log(JSON.stringify(selectedFlight, undefined, 4));
      let airlineName = flightsForOuterIndex[globalIndex].airlineName;
      setAirlineNameSecond(airlineName);
      let airlineDesignator = flightsForOuterIndex[globalIndex].airlineDesignator;
      const flightPricing = await calculateFlightPricing(
        flightResultsSecond[outerIndex], // Pass the correct flight result for the outerIndex
        globalIndex,
        flightAdults,
        flightChildren,
        flightInfants,
        airlineDesignator,
        dateAdjusted,
        agentObject,
        selectedAges,
        selectedAgesAdults,
        false,
        classXl,
        transfersDataFlights,
        transfer,
      );

      console.log('flightPricing');
      console.log(JSON.stringify(flightPricing, undefined, 4));

      setFlightPricingSecond(flightPricing);
      setFlightCostSecond(flightPricing.saleFare.total);
    }
  };

  // Handle border and showing forms:
  const [selectedContent, setSelectedContent] = useState(null);
  const [flightToShow, setFlightToShow] = useState(null);
  const [matchingAirport, setMatchingAirport] = useState(null);

  // LOAD FLIGHTS

  useEffect(() => {
    const fetchData = async () => {
      if (transfer.formValues && transfer.formValues.flightDetails) {
        // Assuming transfer.formValues object is available
        const { flightDetails } = transfer.formValues;
        const { STD, fltnum, fromcode, tocode, uid } = flightDetails;

        const savedFlightIndex = 0;

        // Removing flightsArray 12/06/24
        let availableFlightsData = [];
        availableFlightsData.push({
          count: 0,
          flight: [],
        });

        // Add flight pricing to each flight in mergedFlightsArray
        const updatedFlightsArray = await Promise.all(
          availableFlightsData.map(async (mergedFlight) => {
            const updatedFlightsArray = await Promise.all(
              mergedFlight.flight.map(async (flight) => {
                const flightPricing = await getFlightPricing(flight, mergedFlight.dateAdjusted);
                return { ...flight, flightPricing };
              }),
            );
            return { ...mergedFlight, flight: updatedFlightsArray };
          }),
        );

        // Log the updated flights array with flightPricing
        console.log('Updated Flights Array with Pricing:');
        console.log(JSON.stringify(updatedFlightsArray, null, 2));

        setFlightResults(updatedFlightsArray);
        setSelectedFlightIndex(savedFlightIndex);
        setSelectedFlight(flightDetails);

        // get selected flight object -------- start
        // Removing flightsArray 12/06/24
        const index = 0;

        setLocalSelectedIndices([index]);
        // get selected flight object -------- end

        setFlightCost(
          transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.flightPricing.saleFare.total : 0,
        );
        setFlightPricing(transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.flightPricing : []);
      }
    };

    fetchData();
  }, [transfer]);

  useEffect(() => {
    if (transfer.formValues && transfer.formValues.flightDetails && flightResults && flightResults.flight) {
      const flightIndex = flightResults.flight.findIndex((flight) => flight.STD === transfer.formValues.flightDetails.STD && flight.STA === transfer.formValues.flightDetails.STA);
      if (flightIndex >= 0) {
        setLocalSelectedIndex(flightIndex % buttonsToShow);
        setCurrentPage(Math.floor(flightIndex / buttonsToShow));
      }
    }
  }, [buttonsToShow, flightResults, transfer.formValues]);

  // new search airport codes
  function handleConfirmClickSubmitFlight(e) {
    // Check if any flight has been selected
    const checkPassed = localSelectedIndices.some((index) => index !== null);

    if (!checkPassed) {
      // Prevent form submission if the check fails
      e.preventDefault();
      toast.error('Please select a flight and time.');
    }
  }

  // new search airport codes
  const handleArrowClick = (direction, outerIndex) => {
    const newPages = [...currentPages];
    if (direction === 'left') {
      newPages[outerIndex] = (newPages[outerIndex] || 0) - 1;
    } else {
      newPages[outerIndex] = (newPages[outerIndex] || 0) + 1;
    }
    setCurrentPages(newPages);
    setLocalSelectedIndex(null);
  };

  // Own arrangements
  useEffect(() => {
    if (transfer && transfer.objType !== 'propertyTransfer') return;
    const fetchData = async () => {
      //if (transfer.objType !== "propertyTransfer") return;

      const fetchedAirportDetails = await getAirportDetails(airports, properties, transfer, customLocations);
      setFlightToShow(fetchedAirportDetails);

      if (matchingAirport !== null) return;

      // Now get matching flights for Departure
      const resultFirst = customLocations.find((location) => location.propUid === transfer.propUid && location.uuid === transfer.uuid);
      const propertyAirportId = resultFirst.airportId;
      setFinalOriginFlightUid(propertyAirportId);

      // Now get matching flights for Departure
      const resultSecond = customLocations.find((location) => location.propUid === nextTransfer.propUid && location.uuid === nextTransfer.uuid);
      const propertyAirportIdArrival = resultSecond.airportId;
      setFinalDestinationFlightUid(propertyAirportIdArrival);
    };

    if (airports && properties && transfer && nextTransfer && customLocations) {
      fetchData();
    }
  }, [airports, customLocations, properties, transfer, matchingAirport, nextTransfer]);

  useEffect(() => {
    if (transfer && transfer.objType !== 'propertyTransfer') return;

    if (!airports || !finalOriginFlightUid || !finalDestinationFlightUid) return;

    const originAirport = airports.find((airport) => airport.uid === finalOriginFlightUid);
    const destinationAirport = airports.find((airport) => airport.uid === finalDestinationFlightUid);

    setOriginAirportName(originAirport ? originAirport.name : 'Not found');
    setDestinationAirportName(destinationAirport ? destinationAirport.name : 'Not found');
    setOriginAirportCode(originAirport ? originAirport.code : 'Not found');
    setDestinationAirportCode(destinationAirport ? destinationAirport.code : 'Not found');
    setOriginAirportUid(originAirport ? originAirport.uid : 'Not found');
    setDestinationAirportUid(destinationAirport ? destinationAirport.uid : 'Not found');
    setDestinationLocationUid(destinationAirport ? destinationAirport.location.id : 'Not found');
    setOriginLocationUid(originAirport ? originAirport.location.id : 'Not found');
  }, [airports, finalOriginFlightUid, finalDestinationFlightUid, transfer.objType, transfer]);

  // Create the hours array once and store it outside the component
  const hoursArray = Array.from({ length: 24 * 60 }, (_, i) => {
    const formattedTime = `${Math.floor(i / 60)
      .toString()
      .padStart(2, '0')}:${(i % 60).toString().padStart(2, '0')}`;
    return formattedTime;
  });

  useEffect(() => {
    if (reCheckPax) {
      if (flightAdults !== loadAdults || flightChildren !== loadChildren) {
        setSearchAgain(true);
      } else {
        setSearchAgain(false);
      }
      setReCheckPax(false);
    }
  }, [booking.rooms, flightAdults, flightChildren, loadAdults, loadChildren, reCheckPax]);

  useEffect(() => {
    console.log('transfer.formValues.type.airportTransfer debug 1');
    async function fetchData() {
      console.log('transfer.formValues.type.airportTransfer debug 2');
      setDepartureTimeOwnArrangements(transfer.formValues && transfer.edit && transfer.formValues.timeDeparture !== 'Select time' ? transfer.formValues.timeDeparture : 'Select time');
      setArrivalTimeOwnArrangements(transfer.formValues && transfer.edit && transfer.formValues.timeArrival !== 'Select time' ? transfer.formValues.timeArrival : 'Select time');
      setAirlineUidOwnArrangements(transfer.formValues && transfer.edit && transfer.formValues.airlineUid !== null ? transfer.formValues.airlineUid : '');
      setAirlineNameOwnArrangements(transfer.formValues && transfer.edit && transfer.formValues.airlineName !== null ? transfer.formValues.airlineName : '');
      setFltNum(transfer.formValues && transfer.edit && transfer.formValues.fltnum ? transfer.formValues.fltnum : '');
    }

    async function fetchData1() {
      setDepartureTime(transfer.formValues && transfer.edit && transfer.formValues.departureTime ? transfer.formValues.departureTime : 'Select time');
      setArrivalTime(transfer.formValues && transfer.edit && transfer.formValues.arrivalTime ? transfer.formValues.arrivalTime : 'Select time');
      setSupplierName(transfer.formValues && transfer.edit && transfer.formValues.supplierName ? transfer.formValues.supplierName : null);
      setSupplierUid(transfer.formValues && transfer.edit && transfer.formValues.supplierUid ? transfer.formValues.supplierUid : null);
    }

    // if (transfer.formValues && transfer.formValues.type === 'ownArrangements' && transfer.formValues.type.airportTransfer === true) {
    //   fetchData();
    // }

    if (isOpen) {
      if (transfer.formValues && transfer.formValues.type === 'ownArrangements' && transfer.formValues.airportTransfer === true) {
        fetchData();
      } else if (transfer.formValues && transfer.formValues.type === 'ownArrangements' && transfer.formValues.airportTransfer === false) {
        //fetchData1();
      }
    }
  }, [isOpen, transfer]);

  // Fix flight number input issue
  // useEffect(() => {
  //   if (transfer.formValues && transfer.formValues.type === 'ownArrangements' && transfer.formValues.fltnum) {
  //     setFltNum(transfer.formValues.fltnum);
  //   } else {
  //     setFltNum('');
  //   }
  // }, []);

  const units = transfer.routeTransfer.available && transfer.routeTransfer.maxCapacity !== 0 ? Math.ceil((flightAdults + flightChildren + flightInfants) / transfer.routeTransfer.maxCapacity) : 0;

  const timeOptions = Array.from({ length: 24 * 12 }, (_, i) => i * 5).map((time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return { value: formattedTime, label: formattedTime };
  });

  const handleChangeTimeArrival = (selectedOption) => {
    setArrivalTimeOwnArrangements(selectedOption.value);
    console.log(arrivalTimeOwnArrangements);
  };

  const handleChangeArrivalTime = (selectedOption) => {
    setArrivalTime(selectedOption);
  };

  const handleChangeDepartureTime = (selectedOption) => {
    setDepartureTime(selectedOption);
  };

  const handleChangeAirline = (selectedOption) => {
    setAirlineUidOwnArrangements(selectedOption.value);
    setAirlineNameOwnArrangements(selectedOption.label);
    console.log('airlineOwnArrangements');
    console.log(selectedOption.value);
  };

  const handleChangeTimeDeparture = (selectedOption) => {
    setDepartureTimeOwnArrangements(selectedOption.value);
    console.log(departureTimeOwnArrangements);
  };

  // fixing default input issue

  function handleFltNumInputChange(e) {
    setFltNum(e.target.value);
    // update your state here based on the name and value
  }

  const matchingBooking = bookings.find((booking) => booking.id === transfer.uuid);
  //const matchingBookingPrev = bookings.find((booking) => booking.id === prevTransfer.uuid);
  const matchingBookingNext = bookings.find((booking) => booking.id === nextTransfer.uuid);

  // Safari and town transfer logic

  const hasRouteTransferAvailable = () => transfer.routeTransfer && transfer.routeTransfer.available;

  const isSafariTransferWithMatchingBooking = () => {
    if (properties[getPropObj(transfer.propUid, properties)].supplier.name !== 'Nomad Tanzania' && nonNomadVehicleRates(matchingBooking.rateName)) {
      console.log('true 1');
      return true;
    }
    if (properties[getPropObj(transfer.propUid, properties)].supplier.name === 'Nomad Tanzania' && nomadVehicleRates(matchingBooking.rateName)) {
      console.log('true 2');
      return true;
    }
    if (properties[getPropObj(nextTransfer.propUid, properties)].supplier.name !== 'Nomad Tanzania' && nonNomadVehicleRates(matchingBookingNext.rateName)) {
      console.log('true 3');
      return true;
    }
    if (properties[getPropObj(nextTransfer.propUid, properties)].supplier.name === 'Nomad Tanzania' && nomadVehicleRates(matchingBookingNext.rateName)) {
      console.log('true 4');
      return true;
    }

    if (properties[getPropObj(transfer.propUid, properties)].supplier.name !== 'Nomad Tanzania' && !nonNomadVehicleRates(matchingBooking.rateName)) {
      console.log('false 1');
      return false;
    }
    if (properties[getPropObj(nextTransfer.propUid, properties)].supplier.name !== 'Nomad Tanzania' && !nonNomadVehicleRates(matchingBookingNext.rateName)) {
      console.log('false 2');
      return false;
    }
    if (properties[getPropObj(transfer.propUid, properties)].supplier.name === 'Nomad Tanzania' && !nomadVehicleRates(matchingBooking.rateName)) {
      console.log('false 3');
      console.log(properties[getPropObj(transfer.propUid, properties)].supplier.name);
      console.log('matchingBooking.rateName: ' + matchingBooking.rateName);

      return false;
    }
    if (properties[getPropObj(nextTransfer.propUid, properties)].supplier.name === 'Nomad Tanzania' && !nomadVehicleRates(matchingBookingNext.rateName)) {
      console.log('false 4');
      console.log(properties[getPropObj(nextTransfer.propUid, properties)].supplier.name);
      console.log('matchingBookingNext.rateName: ' + matchingBookingNext.rateName);
      return false;
    }
    if (transfer.routeTransfer.transferType !== 'safariTransfer') {
      console.log('false 5');
      return false;
    }
    console.log('true 5');
    return true;
  };

  const isTownTransfer = () => transfer.routeTransfer.transferType === 'townTransfer';

  // New transfer route

  const [transferRouteRates, setTransferRouteRates] = useState(null);
  const [selectedRouteIndex, setSelectedRouteIndex] = useState(null);
  const [showButtonsRoute, setShowButtonsRoute] = useState(false);

  const [stoPriceCost, setStoPriceCost] = useState(0);
  const [netRackCost, setNetRackCost] = useState(0);
  const [saleTotal, setSaleTotal] = useState(0);
  const [rateId, setRateId] = useState(null);
  const [totalUnits, setTotalUnits] = useState(0);
  const [supplierName, setSupplierName] = useState(null);
  const [supplierUid, setSupplierUid] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const [maxCapacity, setMaxCapacity] = useState(0);

  // Vip object
  const [airportCode, setAirportCode] = useState(null);

  //let vipObject = null;
  let vipObjectArrival = null;
  let vipObjectDeparture = null;
  // Only use the same supplier.id once per 'transferRouteRates'
  // function filterTransferRouteRates(transferRouteRates, properties, matchingBooking, matchingBookingNext) {
  //   // Get supplier IDs from properties based on matching bookings
  //   const supplierId1 = properties[getPropObj(matchingBooking.propUid, properties)].supplier.id;
  //   const supplierId2 = properties[getPropObj(matchingBookingNext.propUid, properties)].supplier.id;

  //   // Use a Set to keep track of added supplier IDs
  //   const addedSupplierIds = new Set();
  //   const filteredRates = [];

  //   for (let rate of transferRouteRates) {
  //     //if ((rate.supplier.id === supplierId1 || rate.supplier.id === supplierId2) && !addedSupplierIds.has(rate.supplier.id)) {
  //     if (rate.active && (rate.supplier.id === supplierId1 || rate.supplier.id === supplierId2 || rate.availableAll)) {
  //       filteredRates.push(rate);
  //       addedSupplierIds.add(rate.supplier.id);
  //     }
  //   }

  //   return filteredRates;
  // }
  // function filterTransferRouteRates(transferRouteRates, properties, matchingBooking, matchingBookingNext) {
  //   //HPFUNCTION
  //   // Get supplier IDs from properties based on matching bookings
  //   const supplierId1 = properties[getPropObj(matchingBooking.propUid, properties)].supplier.id;
  //   const supplierId2 = properties[getPropObj(matchingBookingNext.propUid, properties)].supplier.id;
  //   const selectedRate1 = matchingBooking.rateName;
  //   const selectedRate2 = matchingBookingNext.rateName;

  //   // Check if either selected rate is "fullBoardNomad"
  //   const isFullBoardNomad = selectedRate1 === 'fullBoardNomad' || selectedRate2 === 'fullBoardNomad';
  //   const nomadSupplierId = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
  //   const isNomadGamePackage = (selectedRate1 === 'gamePackage' && supplierId1 === nomadSupplierId) || (selectedRate2 === 'gamePackage' && supplierId2 === nomadSupplierId);
  //   // Use a Set to keep track of added supplier IDs
  //   const addedSupplierIds = new Set();
  //   const filteredRates = [];

  //   for (let rate of transferRouteRates) {
  //     if (rate.active) {
  //       if (isFullBoardNomad || isNomadGamePackage) {
  //         // If fullBoardNomad, include "Safari Vehicle" rates and rates meeting other conditions
  //         if (rate.type !== 'propertyAirportTransfer' && (rate.type === 'nomadGuideVehicle' || rate.supplier.id === supplierId1 || rate.supplier.id === supplierId2 || rate.availableAll)) {
  //           filteredRates.push(rate);
  //           addedSupplierIds.add(rate.supplier.id);
  //         }
  //       } else {
  //         // For non-fullBoardNomad, exclude "Safari Vehicle" rates
  //         if (rate.type !== 'nomadGuideVehicle' && rate.type !== 'propertyAirportTransfer' && (rate.supplier.id === supplierId1 || rate.supplier.id === supplierId2 || rate.availableAll)) {
  //           filteredRates.push(rate);
  //           addedSupplierIds.add(rate.supplier.id);
  //         }
  //       }
  //     }
  //   }

  //   return filteredRates;
  // }

  useEffect(() => {
    const handleTownTransfer = async () => {
      if (transfer.routeTransfer.transferType === 'townTransfer' || transfer.routeTransfer.transferType === 'safariTransfer') {
        console.log('safariTransfer: town transfer');
        const getTransferRoute = await loadTransferRouteUid(transfer.routeTransfer.transferRouteUid);
        setTransferRoute(getTransferRoute);
        const rates = getTransferRoute.rates;
        const getRateObject = findTransferRate(rates, transfer.endDay);
        const getRateObjectAll = findTransferRatesAll(rates, transfer.endDay);
        setTransferRouteRates(filterTransferRouteRates(getRateObjectAll, properties, matchingBooking, matchingBookingNext));
        setRateObject(getRateObject);
        const getTransferUnits = calculateRateTransferUnits(flightAdults, flightChildren, flightInfants, getTransferRoute.maxCapacity);
        setTransferUnits(getTransferUnits);

        //setStoredEvent(event);
        console.log('getRateObject');
        console.log(JSON.stringify(rates, null, 4));
      }
      if (transfer.formValues && transfer.formValues.type === 'safari') {
        setSelectedContent('safariTransfer');
        // set new variables for slider and card
        setShowButtonsRoute(true);
        setRateId(transfer.formValues.rateId ? transfer.formValues.rateId : null);
        setRateType(transfer.formValues.rateType ? transfer.formValues.rateType : null);
        setSupplierName(transfer.formValues.supplierName ? transfer.formValues.supplierName : null);
        setSupplierId(transfer.formValues.supplierUid ? transfer.formValues.supplierUid : null);
        setStoPriceCost(transfer.formValues.stoPriceCost ? transfer.formValues.stoPriceCost : null);
        setNetRackCost(transfer.formValues.netCost ? transfer.formValues.netCost : null);
        setSaleTotal(transfer.formValues.saleTotal ? transfer.formValues.saleTotal : null);
        setTotalUnits(transfer.formValues.units ? transfer.formValues.units : null);
        setMaxCapacity(transfer.formValues.maxCapacity ? transfer.formValues.maxCapacity : null);
      }
    };
    handleTownTransfer();
  }, [transfer]);

  useEffect(() => {
    setArrivalTime(transfer.formValues && transfer.formValues.arrivalTime ? { value: transfer.formValues.arrivalTime, label: transfer.formValues.arrivalTime } : 'Select time');
    setDepartureTime(transfer.formValues && transfer.formValues.departureTime ? { value: transfer.formValues.departureTime, label: transfer.formValues.departureTime } : 'Select time');
    setSupplierName(transfer.formValues && transfer.edit && transfer.formValues.supplierName ? transfer.formValues.supplierName : null);
    setSupplierUid(transfer.formValues && transfer.edit && transfer.formValues.supplierUid ? transfer.formValues.supplierUid : null);
  }, [transfer]);

  // #endregion

  //

  // NEW DESIGN

  // USER ACCESSLEVEL LOGIC

  // if (agentObject.accessLevel === 'limited') {
  //   if (
  //     transfer.propUid === 'e867a3b5-1b63-4913-a052-f69d08523647' ||
  //     transfer.propUid === 'cd3dc792-9604-4363-8246-7a55421ff961' ||
  //     nextTransfer.propUid === 'e867a3b5-1b63-4913-a052-f69d08523647' ||
  //     nextTransfer.propUid === 'cd3dc792-9604-4363-8246-7a55421ff961'
  //   ) {
  //     tabNames = ['Book Flight', 'Own Arrangements']; // property before or after is either of these: Greystoke Mahale or Chada Katavi
  //   } else {
  //     tabNames = ['Own Arrangements']; // Just show option for own arrangements
  //   }
  // } else {
  //   tabNames = ['Book Flight', 'Road Transfer', 'Other arrangements']; // original
  // }

  function isSpecialProperty(propUid) {
    const specialPropertyIds = ['e867a3b5-1b63-4913-a052-f69d08523647', 'cd3dc792-9604-4363-8246-7a55421ff961'];
    return specialPropertyIds.includes(propUid);
  }

  const [selectedTab, setSelectedTab] = useState(-1);
  let tabNames;
  // Custom tab names
  // first condition shows flights in some cases and own arrangements
  if (agentObject.accessLevel === accessLevel && !user.internalUser) {
    if (isSpecialProperty(transfer.propUid) || isSpecialProperty(nextTransfer.propUid)) {
      tabNames = ['Book Flight', 'Own Arrangements'];
    } else {
      tabNames = ['Own Arrangements'];
    }
    // second condition hide own arrangements
    //} else if (agentObject.accessLevel === accessLevelHideOwnArrangements && !user.internalUser) {
  }
  // else if (hideOwnArrangements(advancedMode)) {
  //   tabNames = ['Book Flight', 'Road Transfer'];
  //   // else show all
  // }
  else {
    tabNames = ['Book Flight', 'Road Transfer', 'Own Arrangements'];
  }

  console.log('user level');
  console.log(JSON.stringify(user, null, 2));

  function getSelectedTab(type) {
    if (agentObject.accessLevel === accessLevel && !user.internalUser) {
      if (isSpecialProperty(transfer.propUid) || isSpecialProperty(nextTransfer.propUid)) {
        if (type === 'flight') {
          return 0;
        } else if (type === 'ownArrangements') {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (type === 'ownArrangements') {
          return 0;
        } else {
          return -1;
        }
      }
    } else if (hideOwnArrangements(advancedMode)) {
      if (type === 'flight') {
        return 0;
      } else if (type === 'safari') {
        return 1;
      } else {
        return -1;
      }
    } else {
      if (type === 'flight') {
        return 0;
      } else if (type === 'safari') {
        return 1;
      } else if (type === 'ownArrangements') {
        return 2;
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      if (transfer.formValues && transfer.edit) {
        //resetForm();
        setSelectedContent(null);
        setPackedLunch(transfer.formValues && transfer.formValues.packedLunch ? transfer.formValues.packedLunch : false);
        setPackedLunchChargeable(transfer.formValues && transfer.formValues.packedLunchChargeable ? transfer.formValues.packedLunchChargeable : false);
        if (transfer.formValues.type === 'flight') {
          //setSelectedTab(0);
          setSelectedTab(getSelectedTab('flight'));
          setShowButtons(true);
          setShowButtonsSecond(true);
          setArrivalTime(transfer.formValues.arrivalTime !== undefined ? { value: transfer.formValues.arrivalTime, label: transfer.formValues.arrivalTime } : 'Select time');
          setSelectedContent('flightTransfer');
          console.log('setSelectedContent: ', selectedContent);
        } else if (transfer.formValues.type === 'safari') {
          //setSelectedTab(1);
          setSelectedTab(getSelectedTab('safari'));
          setArrivalTime(transfer.formValues.arrivalTime !== undefined ? { value: transfer.formValues.arrivalTime, label: transfer.formValues.arrivalTime } : 'Select time');

          setSelectedContent('safariTransfer');
          console.log('setSelectedContent: ', selectedContent);
        } else if (transfer.formValues.type === 'ownArrangements') {
          setAirportTransfer(transfer.formValues.airportTransfer !== undefined ? transfer.formValues.airportTransfer : null);
          setArrivalTime(transfer.formValues.arrivalTime !== undefined ? { value: transfer.formValues.arrivalTime, label: transfer.formValues.arrivalTime } : 'Select time');

          //setSelectedTab(2);
          setSelectedTab(getSelectedTab('ownArrangements'));
          setSelectedContent('ownArrangements');
          console.log('setSelectedContent: ', selectedContent);
        } else {
          setFlightResults(null);

          setSelectedFlightIndex(null);
          setSelectedFlight(null);

          setAirportTransfer(null);
          setArrivalTime('Select time');
        }
      }
      console.log('setSelectedContent effect: ', selectedContent);
    } else {
      setSelectedContent(null);
      setSelectedTab(-1);

      return;
    }
  }, [isOpen, transfer]);

  const [allowClose, setAllowClose] = useState(false);

  const [addFlightDetailsLater, setAddFlightDetailsLater] = useState(
    transfer.formValues && transfer.edit && transfer.formValues.type === 'ownArrangements' ? transfer.formValues.addDetailsLater : false,
  );

  // Missing code from single
  const [selectedDirectMultipleValue, setSelectedDirectMultipleValue] = useState('Direct');
  const checkCustomLocations = customLocations.find((location) => location.propUid === transfer.propUid && location.uuid === transfer.uuid);
  const [firstLoad, setFirstLoad] = useState(true);
  const [flightToSearchAirport, setFlightToSearchAirport] = useState(null);
  useEffect(() => {
    if (selectedDirectMultipleValue !== 'Direct') {
      const fetchConnectingAirports = async () => {
        const searchFromAirport = !properties[getPropObj(transfer.propUid, properties)].mobileLocation ? getAirportProperties(transfer.propUid, properties).airport.id : checkCustomLocations.airportId;

        // NEW 04/06/24
        const secondPropIdID = nextTransfer ? nextTransfer.propUid : null;

        let endDay = transfer.endDay;

        // Check if endDay is an object, then convert to date
        if (typeof endDay === 'object' && endDay !== null && 'seconds' in endDay) {
          let date = moment.unix(endDay.seconds);
          endDay = date.format('ddd MMM D YYYY HH:mm:ss [GMT]Z [(]ZZ[)]').utc();
        }

        const flightDestination = await getFlightLocation(properties, secondPropIdID, endDay, nextTransfer, customLocations, airports);

        let flightToSearch = airports.find((airport) => airport.code === flightDestination);
        setFlightToSearchAirport(flightToSearch);

        const getConnectingAirports = await loadConnectingAirports(searchFromAirport, flightToSearch.uid);
        const connectingAirportsDropDownResults = createConnectingAirportsDropdown(getConnectingAirports, airports);
        setConnectingAirportsDropDownOptions(connectingAirportsDropDownResults);
        console.log('connectingAirportsDropDownResults 1: ', JSON.stringify(connectingAirportsDropDownResults, null, 2));
        if (connectingAirportsDropDownResults.length > 0) {
          setDirectMultipleOptions([
            { value: 'Direct', label: 'Direct' },
            { value: 'Multiple', label: 'Multiple' },
          ]);
        } else {
          setDirectMultipleOptions([{ value: 'Direct', label: 'Direct' }]);
        }
      };
      fetchConnectingAirports();
    }
  }, [selectedDirectMultipleValue, checkCustomLocations, transfer, airports, firstLoad, properties, nextTransfer, customLocations]);

  const [airportId, setAirportId] = useState(
    properties[getPropObj(nextTransfer.propUid, properties)].mobileLocation ? checkCustomLocations.airportId : properties[getPropObj(nextTransfer.propUid, properties)].airport.id,
  );
  const [connectingAirportCall, setConnectingAirportCall] = useState(false);
  const [lastEvent, setLastEvent] = useState(null);

  useEffect(() => {
    if (connectingAirportCall && lastEvent) {
      handleOptionClickGetFlights(lastEvent, true);
      setConnectingAirportCall(false);
      setLastEvent(null); // Reset the event after handling
    }
  }, [connectingAirportCall, lastEvent]);

  const CustomOptionAirports = ({ label, data }) => (
    <div key={data.value}>
      <div style={{ fontSize: '0.925rem' }}>{label}</div>
      <div style={{ fontSize: '0.725rem', textTransform: 'capitalize' }}>{data.name}</div>
      <div style={{ fontSize: '0.725rem', textTransform: 'capitalize' }}>{data.code}</div>
    </div>
  );

  const CustomOption = ({ label, data }) => (
    <div key={data.value}>
      <div style={{ fontSize: '0.925rem' }}>{label}</div>
      <div style={{ fontSize: '0.725rem', textTransform: 'capitalize' }}>{data.type}</div>
    </div>
  );

  // NEW MULTIPLE FLIGHTS NEW FLIGHTS

  function createConnectingAirportsDropdown(getConnectingAirports, airports) {
    const results =
      getConnectingAirports && getConnectingAirports.connectingAirports
        ? airports
            .filter((airport) => getConnectingAirports.connectingAirports.includes(airport.uid) && airport.alternativeAirports === false)
            .map((airport, index) => ({
              value: `${airport.objectID}-${index}`,
              label: airport.name,
              code: airport.code,
              codes: airport.codes,
              multipleCodes: airport.multipleCodes,
              uid: airport.uid,
            }))
        : [];
    return results;
  }

  const [changeFlight, setChangeFlight] = useState(true);

  // Load back in flights
  useEffect(() => {
    const fetchData = async () => {
      if (transfer.formValues && transfer.formValues.flightDetails) {
        setChangeFlight(false);
        const savedFlightDetails = transfer.formValues.flightDetails;

        // Removing flightsArray 12/06/24
        let availableFlightsData = [];
        availableFlightsData.push({
          count: 0,
          flight: [],
        });

        // Add flight pricing to each flight in mergedFlightsArray
        const updatedFlightsArray = await Promise.all(
          availableFlightsData.map(async (mergedFlight) => {
            const updatedFlightsArray = await Promise.all(
              mergedFlight.flight.map(async (flight) => {
                const flightPricing = await getFlightPricing(flight, mergedFlight.dateAdjusted);
                return { ...flight, flightPricing };
              }),
            );
            return { ...mergedFlight, flight: updatedFlightsArray };
          }),
        );

        // Log the updated flights array with flightPricing
        console.log('Updated Flights Array with Pricing:');
        console.log(JSON.stringify(updatedFlightsArray, null, 2));

        setFlightResults(updatedFlightsArray);
        const savedFlightIndex = 0;
        setSelectedFlight(savedFlightDetails);

        setAirlineName(transfer.formValues.airlineName);

        //Second flight
        if (transfer.formValues.flightDetailsSecond) {
          setSelectedDirectMultipleValue('Multiple');
          setSelectedDirectMultipleLabel('Multiple');
          setConnectingAirport(false);
          const savedFlightDetailsSecond = transfer.formValues.flightDetailsSecond;
          // Removing flightsArray 12/06/24
          let availableFlightsDataSecond = [];
          availableFlightsDataSecond.push({
            count: 0,
            flight: [],
          });

          setFlightResultsSecond(availableFlightsDataSecond);
          setSelectedConnectingLabel(
            getAirportName(airports, getAeroCrsCode(airports, transfer.formValues.flightDetailsSecond.fromcode)) + ' (' + transfer.formValues.flightDetailsSecond.fromcode + ')',
          );
          setSelectedFlightIndexSecond(0);
          setSelectedFlightSecond(savedFlightDetailsSecond);
          setAirlineNameSecond(transfer.formValues.airlineNameSecond ? transfer.formValues.airlineNameSecond : '');

          setFlightCostSecond(
            transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
              ? transfer.formValues.flightPricingSecond
                ? transfer.formValues.flightPricingSecond.saleFare.total
                : 0
              : 0,
          );

          setFlightPricingSecond(
            transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
              ? transfer.formValues.flightPricingSecond
                ? transfer.formValues.flightPricingSecond
                : []
              : [],
          );
          const index = 0;

          setLocalSelectedIndicesSecond([index]);
        }

        setFlightCost(
          transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.flightPricing.saleFare.total : 0,
        );

        setFlightPricing(transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.flightPricing : []);

        const index = 0;

        setLocalSelectedIndices([index]);
      }
    };

    fetchData();
  }, [transfer]);

  const [searchAgainDirect, setSearchAgainDirect] = useState(false);

  useEffect(() => {
    if (searchAgainDirect && selectedDirectMultipleValue === 'Direct') {
      (async () => {
        setSearchAgainDirect(false);
        setChangeFlight(true);
        resetFlights();
        setSelectedFlightIndex(null);
        setSelectedFlight(null);
        setFlightResults(null);
        setLocalSelectedIndices([]);
        setLocalSelectedIndex([]);
        setSelectedDirectMultipleValue('Direct');

        // Assuming `event` is available in the scope or passed somehow
        await handleOptionClickGetFlights(true, true);
      })();
    }
  }, [searchAgainDirect]);

  const [changeRoute, setChangeRoute] = useState(false);

  const resetSearch = async (event) => {
    setSearchAgainDirect(true);
    setSelectedDirectMultipleValue('Direct');
  };

  const resetFlights = () => {
    resetFlightsOpen();

    setSelectedConnectingLabel("Select 'Connecting airport'");
    setFlightToSearchAirport(null);
    setShowButtons(false);
    setShowButtonsSecond(false);
  };

  const resetFlightsOpen = () => {
    setSelectedDirectMultipleValue('Direct');
    setSelectedDirectMultipleLabel('Direct');
    setConnectingAirport(false);
    setFlightResults(false);
    setFlightResultsSecond(false);
    setSelectedConnectingValue(null);

    setSelectedFlightIndex(null);
    setSelectedFlightIndexSecond(null);
    setSelectedFlightSecond(null);

    setFlightPricingSecond(0);
    setFlightPricing(0);
    setFlightCost(0);
    setFlightCostSecond(0);
    setConnectingAirportsDropDownOptions([]);
    setConnectingAirportUid(null);
    setLocalSelectedIndices([]);
    setLocalSelectedIndex([]);
    setLocalSelectedIndicesSecond([]);

    setClassXl(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setChangeRoute(false);
      resetFlightsOpen();
      setSelectedRouteIndex(null);
    }
  }, [isOpen]);

  const [selectedDirectMultipleLabel, setSelectedDirectMultipleLabel] = useState('Direct');
  const [connectingAirportsDropDownOptions, setConnectingAirportsDropDownOptions] = useState([]);
  const [connectingAirport, setConnectingAirport] = useState(false);
  const [connectingAirportUid, setConnectingAirportUid] = useState(null);

  const [flightToSearchAirportSecond, setFlightToSearchAirportSecond] = useState(null);
  const [selectedFlightIndexSecond, setSelectedFlightIndexSecond] = useState(null);
  const [selectedFlightSecond, setSelectedFlightSecond] = useState(null);

  const [localSelectedIndicesSecond, setLocalSelectedIndicesSecond] = useState([]);
  const [currentPagesSecond, setCurrentPagesSecond] = useState([]);

  const [selectedConnectingValue, setSelectedConnectingValue] = useState(null);
  const [selectedConnectingLabel, setSelectedConnectingLabel] = useState("Select 'Connecting airport'");

  useEffect(() => {
    if (selectedDirectMultipleValue === 'Direct' && changeRoute) {
      const performAsyncOperation = async () => {
        setChangeRoute(false);
        resetFlightsOpen();
        await handleOptionClickGetFlights(true, true);
      };

      performAsyncOperation();
    }
  }, [selectedDirectMultipleValue, changeRoute, handleOptionClickGetFlights]);

  const handleDirectMultiple = async (selectedOption, event) => {
    resetFlights();
    setConnectingAirport(false);
    setSelectedDirectMultipleLabel(selectedOption.label);
    setSelectedDirectMultipleValue(selectedOption.value);
    setChangeFlight(true);

    if (selectedOption.value === 'Direct') {
      setChangeRoute(true);
    } else {
      setConnectingAirport(true);
      // Set flightToSearchAirport for 'Multiple' case
      const destinationAirport = airports.find((airport) => airport.uid === finalDestinationFlightUid);
      setFlightToSearchAirport(destinationAirport);
      fetchConnectingAirports();
    }
  };

  // const directMultipleOptions = [
  //   { value: 'Direct', label: 'Direct' },
  //   { value: 'Multiple', label: 'Multiple' },
  // ];

  const [hasSufficientTime, setHasSufficientTime] = useState(true);

  const [classXl, setClassXl] = useState(false);

  useEffect(() => {
    console.log('flightToSearchAirport:', flightToSearchAirport);
    console.log('selectedDirectMultipleValue:', selectedDirectMultipleValue);
  }, [flightToSearchAirport, selectedDirectMultipleValue]);

  const [directMultipleOptions, setDirectMultipleOptions] = useState([
    { value: 'Direct', label: 'Direct' },
    { value: 'Multiple', label: 'Multiple' },
  ]);

  const fetchConnectingAirports = async () => {
    const searchFromAirport = !properties[getPropObj(transfer.propUid, properties)].mobileLocation ? getAirportProperties(transfer.propUid, properties).airport.id : checkCustomLocations.airportId;

    const secondPropIdID = nextTransfer ? nextTransfer.propUid : null;

    let endDay = transfer.endDay;

    if (typeof endDay === 'object' && endDay !== null && 'seconds' in endDay) {
      let date = moment.unix(endDay.seconds);
      endDay = date.format('ddd MMM D YYYY HH:mm:ss [GMT]Z [(]ZZ[)]').utc();
    }

    const flightDestination = await getFlightLocation(properties, secondPropIdID, endDay, nextTransfer, customLocations, airports);

    let flightToSearch = airports.find((airport) => airport.code === flightDestination);
    setFlightToSearchAirport(flightToSearch);

    const getConnectingAirports = await loadConnectingAirports(searchFromAirport, flightToSearch.uid);
    const connectingAirportsDropDownResults = createConnectingAirportsDropdown(getConnectingAirports, airports);
    setConnectingAirportsDropDownOptions(connectingAirportsDropDownResults);
    console.log('connectingAirportsDropDownResults 2: ', JSON.stringify(connectingAirportsDropDownResults, null, 2));
    if (connectingAirportsDropDownResults.length > 0) {
      setDirectMultipleOptions([
        { value: 'Direct', label: 'Direct' },
        { value: 'Multiple', label: 'Multiple' },
      ]);
    } else {
      setDirectMultipleOptions([{ value: 'Direct', label: 'Direct' }]);
    }
  };

  // useEffect(() => {
  //   fetchConnectingAirports();
  // }, []); // Empty dependency array means this effect runs once on mount

  function isRoadTransferAvailable(name) {
    if (name === 'Road Transfer') {
      const isAvailable = hasRouteTransferAvailable() && (isSafariTransferWithMatchingBooking() || isTownTransfer());
      console.log('Road Transfer available:', isAvailable);
      return isAvailable;
    }
    return true; // Not a road transfer tab
  }

  function isBookFlightAvailable(name) {
    if (name === 'Book Flight') {
      const isAvailable = transfer.flightTransfer.available && availableFlights !== false && flightsAvailable;
      console.log('Book Flight available:', isAvailable);
      return isAvailable;
    }
    return true; // Not a book flight tab
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={() => allowClose && onClose()}>
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-0 flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="flex-1 h-full overflow-y-auto">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto">
                        <div className="bg-white px-4 pt-6 sm:px-6">
                          <Dialog.Title className={`mt-5 text-2xl font-base font-normal leading-6 `}>
                            <div className="flex justify-between">
                              <div className="flex">
                                <div className="ml-0 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="relative rounded-md bg-white text-black hover:text-black"
                                    onClick={() => {
                                      if (!transfer.formValues) {
                                        //setSelectedTransferTuuid(null);
                                        //onClose();
                                        closeForm(null, transfer.tuuid, transfers, setTransfers, onClose);
                                      } else {
                                        setArrivalTime('Select time');
                                        closeForm(null, transfer.tuuid, transfers, setTransfers, onClose);
                                      }
                                    }}
                                  >
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                  </button>
                                  <span className={`text-base text-black ml-5`}>Add transfer</span>
                                  {(user.email === 'prideaux@gmail.com' || user.email === 'roger.prideaux@gmail.com') && (
                                    <div className="flex text-xs">
                                      <div className="w-4"></div>
                                      <button
                                        onClick={async () => {
                                          console.log(JSON.stringify(selectedFlight, undefined, 4));
                                        }}
                                      >
                                        selectedFlight
                                      </button>
                                      <div className="w-4"></div>
                                      <button
                                        onClick={async () => {
                                          console.log(JSON.stringify(selectedFlightSecond, undefined, 4));
                                        }}
                                      >
                                        selectedFlightSecond
                                      </button>
                                      <div className="w-4"></div>
                                      <button
                                        onClick={async () => {
                                          console.log(JSON.stringify(vipObjectDeparture, undefined, 4));
                                        }}
                                      >
                                        vipObjectDeparture
                                      </button>
                                      <div className="w-4"></div>
                                      <button
                                        onClick={async () => {
                                          console.log(JSON.stringify(vipObjectArrival, undefined, 4));
                                        }}
                                      >
                                        vipObjectArrival
                                      </button>

                                      {/* <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(flightToSearchAirport, undefined, 4));
                                      }}
                                    >
                                      flightToSearchAirport
                                    </button>

                                    <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(selectedContent, undefined, 4));
                                      }}
                                    >
                                      selectedContent
                                    </button>
                                    <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(transferRouteRates, undefined, 4));
                                      }}
                                    >
                                      transferRouteRates
                                    </button> */}
                                    </div>
                                  )}
                                  {/* <div className="flex text-xs">
                                    <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(flightResults, undefined, 4));
                                      }}
                                    >
                                      flightResults
                                    </button>
                                    <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(flightResultsSecond, undefined, 4));
                                      }}
                                    >
                                      flightResultsSecond
                                    </button>
                                    <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(flightToSearchAirport, undefined, 4));
                                      }}
                                    >
                                      flightToSearchAirport
                                    </button>

                                    <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(selectedContent, undefined, 4));
                                      }}
                                    >
                                      selectedContent
                                    </button>
                                    <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(transferRouteRates, undefined, 4));
                                      }}
                                    >
                                      transferRouteRates
                                    </button>
                                  </div> */}
                                </div>
                              </div>{' '}
                            </div>
                          </Dialog.Title>
                          <div className="mt-8 mb-5 text-2xl font-semibold">
                            {transfer.objType === 'departure' ? 'Departure from ' : 'Arrival to '} {properties[getPropObj(nextTransfer.propUid, properties)].name}
                          </div>
                          <div className="">
                            <nav className="flex divide-x divide-gray-300 rounded-xl overflow-hidden">
                              {tabNames.map((name, index) => {
                                console.log(`Evaluating tab: ${name}`);

                                const isAvailable =
                                  (name === 'Own Arrangements' && !hideOwnArrangements(advancedMode)) ||
                                  (name === 'Road Transfer' && isRoadTransferAvailable(name)) ||
                                  (name === 'Book Flight' && isBookFlightAvailable(name));

                                // selectedTab === index && (
                                return isAvailable ? (
                                  <button
                                    //disabled={isAvailable ? false : true}
                                    key={index}
                                    type="button"
                                    className={`flex-1 py-3 text-sm font-medium 
                                ${selectedTab === index ? 'bg-[#1D253F] text-white' : 'bg-gray-300 text-black'}
                                hover:bg-[#1D253F] hover:text-white h-[67px]
                                `} // Simplified and corrected hover effect
                                    // data-target={index === 0 ? 'flightTransfer' : index === 1 ? 'safariTransfer' : 'ownArrangements'}
                                    data-target={(() => {
                                      // first condition shows flights in some cases and own arrangements
                                      if (agentObject.accessLevel === accessLevel && !user.internalUser) {
                                        if (isSpecialProperty(transfer.propUid) || isSpecialProperty(nextTransfer.propUid)) {
                                          if (index === 0) return 'flightTransfer';
                                          else if (index === 1) return 'ownArrangements';
                                        } else {
                                          if (index === 0) return 'ownArrangements';
                                        }
                                        // second condition hide own arrangements
                                      }
                                      // else if (hideOwnArrangements(advancedMode)) {
                                      //   if (index === 0) return 'flightTransfer';
                                      //   else if (index === 1) return 'safariTransfer';
                                      //   // else show all
                                      // }
                                      else {
                                        if (index === 0) return 'flightTransfer';
                                        else if (index === 1) return 'safariTransfer';
                                        else return 'ownArrangements';
                                      }
                                    })()}
                                    onClick={async (event) => {
                                      // if(name === "Road Transfer"){
                                      //   if (hasRouteTransferAvailable() && (isSafariTransferWithMatchingBooking() || isTownTransfer())) {
                                      //     return true;
                                      //   } else {
                                      //     return false;
                                      //   }
                                      // }
                                      console.log(`Clicked tab index: ${index}`);
                                      // first condition shows flights in some cases and own arrangements
                                      if (agentObject.accessLevel === accessLevel && !user.internalUser) {
                                        if (isSpecialProperty(transfer.propUid) || isSpecialProperty(nextTransfer.propUid)) {
                                          if (index === 0) {
                                            fetchConnectingAirports();
                                            if (transfer.flightTransfer.available && availableFlights !== false && flightsAvailable) {
                                              fetchConnectingAirports();
                                              setChangeFlight(true);
                                              resetFlights();
                                              setSelectedFlightIndex(null);
                                              setSelectedFlight(null);
                                              setFlightResults(null);
                                              setLocalSelectedIndices([]);
                                              setLocalSelectedIndex([]);
                                              await handleOptionClickGetFlights(event, true);
                                              setSelectedTab(index);
                                            } else {
                                              toast.error('No flights available.');
                                            }
                                          } else if (index === 1) {
                                            setArrivalTime('Select time');
                                            setAirportTransfer(null);
                                            setIncludeArrivalTransferCheck(true);
                                            setIncludeDepartureTransferCheck(true);
                                            setFltNum('');
                                            setArrivalTimeOwnArrangements('Select time');
                                            setDepartureTimeOwnArrangements('Select time');
                                            setAirlineNameOwnArrangements('Select Airline');
                                            handleOptionClickTransfer(event, setSelectedContent, setResetPackedLunch);
                                            console.log(arrivalTime);
                                            setSelectedTab(index);
                                            setChangeFlight(true);
                                            resetFlights();
                                          }
                                        } else {
                                          if (index === 0) {
                                            setArrivalTime('Select time');
                                            setAirportTransfer(null);
                                            setIncludeArrivalTransferCheck(true);
                                            setIncludeDepartureTransferCheck(true);
                                            setFltNum('');
                                            setArrivalTimeOwnArrangements('Select time');
                                            setDepartureTimeOwnArrangements('Select time');
                                            setAirlineNameOwnArrangements('Select Airline');
                                            handleOptionClickTransfer(event, setSelectedContent, setResetPackedLunch);
                                            console.log(arrivalTime);
                                            setSelectedTab(index);
                                            setChangeFlight(true);
                                            resetFlights();
                                          }
                                        }
                                        // second condition hide own arrangements
                                      }
                                      // else if (hideOwnArrangements(advancedMode)) {
                                      //   if (index === 0) {
                                      //     if (transfer.flightTransfer.available && availableFlights !== false && flightsAvailable) {
                                      //       fetchConnectingAirports();
                                      //       setChangeFlight(true);
                                      //       resetFlights();
                                      //       setSelectedFlightIndex(null);
                                      //       setSelectedFlight(null);
                                      //       setFlightResults(null);
                                      //       setLocalSelectedIndices([]);
                                      //       setLocalSelectedIndex([]);
                                      //       await handleOptionClickGetFlights(event, true);
                                      //       setSelectedTab(index);
                                      //     } else {
                                      //       toast.error('No flights available.');
                                      //     }
                                      //   } else if (index === 1) {
                                      //     if (hasRouteTransferAvailable() && (isSafariTransferWithMatchingBooking() || isTownTransfer())) {
                                      //       setArrivalTime(() => 'Select time');
                                      //       handleOptionClickTransfer(event, setSelectedContent, setResetPackedLunch);
                                      //       setSelectedTab(index);
                                      //     } else {
                                      //       toast.error('No transfers available.');
                                      //       return;
                                      //     }
                                      //     setSelectedRouteIndex(null);
                                      //     setShowButtonsRoute(false);
                                      //     setChangeFlight(true);
                                      //     resetFlights();
                                      //   }
                                      //   // else show all
                                      // }
                                      else {
                                        if (index === 0) {
                                          if (transfer.flightTransfer.available && availableFlights !== false && flightsAvailable) {
                                            fetchConnectingAirports();
                                            setChangeFlight(true);
                                            resetFlights();
                                            setSelectedFlightIndex(null);
                                            setSelectedFlight(null);
                                            setFlightResults(null);
                                            setLocalSelectedIndices([]);
                                            setLocalSelectedIndex([]);
                                            await handleOptionClickGetFlights(event, true);
                                            setSelectedTab(index);
                                          } else {
                                            toast.error('No flights available.');
                                          }
                                        } else if (index === 1) {
                                          if (hasRouteTransferAvailable() && (isSafariTransferWithMatchingBooking() || isTownTransfer())) {
                                            setArrivalTime(() => 'Select time');
                                            handleOptionClickTransfer(event, setSelectedContent, setResetPackedLunch);
                                            setSelectedTab(index);
                                          } else {
                                            toast.error('No transfers available.');
                                            return;
                                          }
                                          setSelectedRouteIndex(null);
                                          setShowButtonsRoute(false);
                                          setChangeFlight(true);
                                          resetFlights();
                                        } else if (index === 2) {
                                          setArrivalTime('Select time');
                                          setAirportTransfer(null);
                                          setIncludeArrivalTransferCheck(true);
                                          setIncludeDepartureTransferCheck(true);
                                          setFltNum('');
                                          setArrivalTimeOwnArrangements('Select time');
                                          setDepartureTimeOwnArrangements('Select time');
                                          setAirlineNameOwnArrangements('Select Airline');
                                          handleOptionClickTransfer(event, setSelectedContent, setResetPackedLunch);
                                          console.log(arrivalTime);
                                          setSelectedTab(index);
                                          setChangeFlight(true);
                                          resetFlights();
                                        }
                                      }
                                    }}
                                  >
                                    <div className="flex justify-center">
                                      {(() => {
                                        // first condition shows flights in some cases and own arrangements
                                        if (agentObject.accessLevel === accessLevel && !user.internalUser) {
                                          if (isSpecialProperty(transfer.propUid) || isSpecialProperty(nextTransfer.propUid)) {
                                            if (index === 0) {
                                              return <LiaPlaneSolid size={25} className="mr-2" />;
                                            } else if (index === 1) {
                                              return <TbTriangle size={25} className="mr-2" />;
                                            }
                                          } else {
                                            if (index === 0) {
                                              return <TbTriangle size={25} className="mr-2" />;
                                            }
                                          }
                                          // second condition hide own arrangements
                                        }
                                        // else if (hideOwnArrangements(advancedMode)) {
                                        //   if (index === 0) {
                                        //     return <LiaPlaneSolid size={25} className="mr-2" />;
                                        //   } else if (index === 1) {
                                        //     return <IoLocationOutline size={25} className="mr-2" />;
                                        //   }
                                        //   // else show all
                                        // }
                                        else {
                                          if (index === 0) {
                                            return <LiaPlaneSolid size={25} className="mr-2" />;
                                          } else if (index === 1) {
                                            return <IoLocationOutline size={25} className="mr-2" />;
                                          } else {
                                            return <TbTriangle size={25} className="mr-2" />;
                                          }
                                        }
                                      })()}
                                      <span>{name}</span>
                                    </div>
                                  </button>
                                ) : null;
                              })}
                            </nav>
                          </div>
                          {selectedTab === -1 && <div className="text-center text-sm my-10">Select an option above</div>}

                          <div className="">
                            {/* FORM: Arrive at by safari vehicle  */}

                            <form
                              id="safariTransfer"
                              className={`${
                                transfer.formValues && transfer.edit && transfer.formValues.type === 'safari' && !selectedContent ? '' : selectedContent === 'safariTransfer' ? '' : 'hidden'
                              } p-0 bg-white rounded-lg`}
                              onSubmit={(e) => handleFormSubmitSafari(e, transfers, setTransfers, onClose, bookings, setBookings, properties)}
                            >
                              {selectedContent === 'safariTransfer' && (
                                <>
                                  <input type="hidden" name="formType" value={transferType} />
                                  <input type="hidden" name="tuuid" value={transfer.tuuid} />
                                  <input type="hidden" name="tuuid" value={transfer.tuuid} />
                                  <input type="hidden" name="transferType" value={transfer.routeTransfer.transferType} />
                                  <input type="hidden" name="gameDriveTime" value={transfer.routeTransfer.gameDriveTime} />
                                  <input type="hidden" name="distanceMins" value={transfer.routeTransfer.distanceMins} />
                                  <input type="hidden" name="maxCapacity" value={maxCapacity} />
                                  <input type="hidden" name="arrivalTime" value={arrivalTime.value} />
                                  <input
                                    type="hidden"
                                    name="supplier"
                                    value={JSON.stringify({
                                      id: supplierId,
                                      name: supplierName,
                                    })}
                                  />
                                  <input type="hidden" name="units" value={transferUnits} />
                                  <input type="hidden" name="stoPriceCost" value={stoPriceCost} />
                                  <input type="hidden" name="netRackCost" value={netRackCost} />
                                  <input type="hidden" name="saleTotal" value={saleTotal} />
                                  <input type="hidden" name="rateId" value={rateId} />
                                  <input type="hidden" name="totalUnits" value={totalUnits} />
                                  <input type="hidden" name="rateType" value={rateType} />

                                  <div>
                                    <div className="flex w-full mt-10">
                                      <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                        {/* Close to 5.5 columns  */}
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Departure from
                                        </label>
                                        <CustomSelect
                                          placeholder={properties[getPropObj(transfer.propUid, properties)].name}
                                          defaultValue={{ label: properties[getPropObj(transfer.propUid, properties)].name, value: properties[getPropObj(transfer.propUid, properties)].name }}
                                          onChange={console.log('clicked')}
                                          isSearchable={false}
                                          icon={<FaLocationDot size={25} />} // Pass the icon as a React component
                                          showDropdownIndicator={false}
                                          isDisabled={true}
                                          //width={380}
                                        />
                                      </div>
                                      <div className="flex-1" style={{ flexBasis: '8.33%' }}>
                                        {' '}
                                        {/* Exactly 1 column */}
                                        <div className="p-4 text-white">
                                          <div className="flex-1 flex items-center justify-center mt-5">
                                            <FaLongArrowAltRight size={30} className="text-[#1D253F]" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                        {' '}
                                        {/* Close to 5.5 columns again */}
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Arrival at
                                        </label>
                                        <CustomSelect
                                          placeholder={properties[getPropObj(nextTransfer.propUid, properties)].name}
                                          defaultValue={{ label: properties[getPropObj(nextTransfer.propUid, properties)].name, value: properties[getPropObj(nextTransfer.propUid, properties)].name }}
                                          onChange={console.log('clicked')}
                                          isSearchable={false}
                                          icon={<FaLocationDot size={25} />}
                                          showDropdownIndicator={false}
                                          isDisabled={true}
                                          //width={380}
                                        />
                                      </div>
                                    </div>

                                    {transferRouteRates && transferRouteRates.length > 0 ? (
                                      <div className="w-full">
                                        <div>
                                          <TransferRouteComponent
                                            transferRouteRates={transferRouteRates}
                                            selectedRouteIndex={selectedRouteIndex}
                                            setSelectedRouteIndex={setSelectedRouteIndex}
                                            showButtonsRoute={showButtonsRoute}
                                            setShowButtonsRoute={setShowButtonsRoute}
                                            transfer={transfer}
                                            //transferUnits={transferUnits}
                                            setStoPriceCost={setStoPriceCost}
                                            setNetRackCost={setNetRackCost}
                                            setSaleTotal={setSaleTotal}
                                            setRateId={setRateId}
                                            passengers={flightAdults + flightChildren + flightInfants}
                                            setTotalUnits={setTotalUnits}
                                            setSupplierName={setSupplierName}
                                            setSupplierId={setSupplierId}
                                            setMaxCapacity={setMaxCapacity}
                                            selectedLocationAlgoliaType={'property'}
                                            handlesOwnAirportTransfer={false}
                                            setRateType={setRateType}
                                          />
                                        </div>
                                      </div>
                                    ) : transferRouteRates !== null ? (
                                      <TransferInfoCard icon={IoMdInformationCircleOutline} title="No transfers Found" text={'Try searching another location'} />
                                    ) : null}

                                    {showButtonsRoute && selectedRouteIndex !== null && (
                                      <div className="my-10">
                                        <div className="pl-0 mx-0">
                                          <p className="text-sm mb-2 w-max">What time will you arrive at {properties[getPropObj(nextTransfer.propUid, properties)].name}?</p>
                                        </div>
                                        <div className="grid grid-cols-12 gap-4">
                                          <div className="col-span-4">
                                            <CustomSelect
                                              placeholder={arrivalTime}
                                              options={arrivalDepartureTimeOptions}
                                              onChange={handleChangeArrivalTime}
                                              value={arrivalTime === 'Select time' ? { label: arrivalTime, value: null } : arrivalTime}
                                              instanceId="arrival-select"
                                              isSearchable={false}
                                              showDropdownIndicator={false}
                                            />
                                          </div>
                                          <div className="col-span-4"></div>
                                          <div className="col-span-4"></div>
                                        </div>
                                      </div>
                                    )}

                                    {!resetPackedLunch && arrivalTime !== 'Select time' && (
                                      <>
                                        <div className="text-black text-[25px] font-semibold mt-5">Add-on</div>
                                        <div className="grid grid-cols-12 gap-4 mt-3">
                                          <PackedLunch
                                            packedLunch={packedLunch}
                                            setPackedLunch={setPackedLunch}
                                            packedLunchChargeable={packedLunchChargeable}
                                            setPackedLunchChargeable={setPackedLunchChargeable}
                                            transfer={transfer}
                                            properties={properties}
                                            booking={booking}
                                            show={true}
                                          />
                                          <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                          <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                        </div>
                                      </>
                                    )}

                                    {/* ARRIVAL TIME */}
                                    <div className="flex">
                                      <div className="flex mt-7 justify-end w-full gap-4 mb-20">
                                        <div>
                                          <button
                                            className="ml-auto mr-5 bg-white text-nomadBlue-500 border border-nomadBlue-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadBlue-500 hover:border-nomadBlue-700flex items-center justify-center"
                                            type="button"
                                            onClick={(e) => {
                                              if (!transfer.formValues) {
                                                setSelectedTransferTuuid(null);
                                                onClose();
                                              } else {
                                                closeForm(e, transfer.tuuid, transfers, setTransfers, onClose);
                                              }
                                            }}
                                          >
                                            Cancel
                                          </button>
                                          {showButtonsRoute && selectedRouteIndex !== null && (
                                            <button
                                              className="bg-nomadBlue-800 text-white text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadBlue-700 hover:text-white"
                                              type="submit"
                                            >
                                              Select
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </form>

                            {/* FORM: Arrive at town */}
                            {/* <form
                              id="townTransfer"
                              className={`${
                                transfer.formValues && transfer.edit && transfer.formValues.type === 'town' && !selectedContent ? '' : selectedContent === 'townTransfer' ? '' : 'hidden'
                              } p-5 bg-white rounded-lg`}
                              onSubmit={(e) => handleFormSubmitTown(e, transfers, setTransfers, setIsModalOpen, bookings, setBookings, properties)}
                            >
                              <input type="hidden" name="formType" value={transferType} />
                              <input type="hidden" name="tuuid" value={transfer.tuuid} />

                              <div>
                                <div className="text-sm mb-2">Local town transfer</div>
                                <div className="flex mt-5 justify-end">
                                  <div>
                                    <button
                                      className="ml-auto mr-5 bg-white text-nomadBlue-500 border border-nomadBlue-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadBlue-500 hover:border-nomadBlue-700flex items-center justify-center"
                                      type="button"
                                      onClick={(e) => {
                                        if (!transfer.formValues) {
                                          setSelectedTransferTuuid(null);
                                          onClose();
                                        } else {
                                          closeForm(e, transfer.tuuid, transfers, setTransfers, onClose);
                                        }
                                      }}
                                    >
                                      Close
                                    </button>
                                    <button
                                      className="confirm-button ml-auto text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit focus:outline-none"
                                      type="submit"
                                      onClick={(e) => {
                                        console.log('confirm town transfer: ' + transfer.tuuid);
                                      }}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form> */}

                            {/* FORM: flightTransfer airport */}
                            <form
                              id="flightTransfer"
                              className={`${
                                transfer.formValues && transfer.edit && transfer.formValues.type === 'flight' && !selectedContent ? '' : selectedContent === 'flightTransfer' ? '' : 'hidden'
                              } p-0 bg-white rounded-lg`}
                              onSubmit={(e) => handleFormSubmitAirport(e, transfers, setTransfers, onClose, setRefreshTransferRates, bookings, setBookings, properties, customLocations, airports)}
                            >
                              <input type="hidden" name="formType" value={transferType} />
                              <input type="hidden" name="tuuid" value={transfer.tuuid} />
                              <input type="hidden" name="formName" value="flight" />
                              <input type="hidden" name="flightDetails" value={selectedFlight ? JSON.stringify(selectedFlight) : ''} />
                              <input type="hidden" name="flightAdults" value={flightAdults} />
                              <input type="hidden" name="flightChildren" value={flightChildren} />
                              <input type="hidden" name="flightInfants" value={flightInfants} />
                              <input type="hidden" name="airlineName" value={airlineName} />
                              <input type="hidden" name="dateAdjusted" value={dateAdjusted} />
                              <input type="hidden" name="flightPricing" value={JSON.stringify(flightPricing)} />
                              <input type="hidden" name="arrivalTime" value={arrivalTime.value} />
                              <input type="hidden" name="flightDetailsSecond" value={selectedFlightSecond ? JSON.stringify(selectedFlightSecond) : ''} />
                              <input type="hidden" name="airlineNameSecond" value={airlineNameSecond} />
                              <input type="hidden" name="flightPricingSecond" value={JSON.stringify(flightPricingSecond)} />
                              <input type="hidden" name="directMultiple" value={selectedDirectMultipleValue} />

                              <div>
                                <div className="mt-5">
                                  <>
                                    {!isLoading && (
                                      <div className="flex">
                                        {/* {changeFlight && !flightResults && ( */}
                                        <div>
                                          <CustomSelectNoBorderTransfers
                                            value={{
                                              label: (
                                                <div className="flex items-center">
                                                  {selectedDirectMultipleLabel} <LuChevronDown className="ml-2" />
                                                </div>
                                              ),
                                              value: selectedDirectMultipleValue,
                                            }}
                                            onChange={(selectedOption, event) => handleDirectMultiple(selectedOption, event)}
                                            options={directMultipleOptions.map((directMultiple) => ({
                                              label: directMultiple.label,
                                              value: directMultiple.value,
                                            }))}
                                            instanceId="directMultiple-select"
                                            isSearchable={true}
                                            showDropdownIndicator={false}
                                            width={100}
                                            menuHeight={directMultipleOptions.length === 1 ? 40 : 80}
                                          />
                                        </div>
                                        {/* )} */}
                                        {/* {changeFlight && !flightResults && ( */}
                                        <div className="mr-4 flex items-center justify-center ">
                                          <input
                                            type="checkbox"
                                            name="classXl"
                                            // className="checkbox checkbox-accent"
                                            className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
                                            checked={classXl}
                                            onChange={(e) => {
                                              let isChecked = e.target.checked;
                                              setClassXl(isChecked);
                                            }}
                                          />

                                          <span className="label-text ml-2 text-sm">XL Fares</span>
                                        </div>
                                        {/* )}*/}

                                        {/* <div
                                          className="ml-auto mr-2 mt-1 text-sm text-cyan-600 cursor-pointer underline"
                                          onClick={async (event) => {
                                            console.log('Resetting flights');
                                            setChangeFlight(true);
                                            resetFlights();
                                          }}
                                        >
                                          Route Options
                                        </div> */}
                                      </div>
                                    )}
                                    {flightToSearchAirport && (
                                      <div className="flex w-full">
                                        <div className="flex-1" style={{ flexBasis: selectedDirectMultipleValue === 'Direct' ? '45.83%' : '32%' }}>
                                          {/* Close to 5.5 columns  */}

                                          <label htmlFor="supplier-select" className="text-xs">
                                            {transfer.objType === 'departure' ? 'Departure from' : 'Departure from'}
                                          </label>
                                          <CustomSelect
                                            defaultValue={{
                                              label: !properties[getPropObj(transfer.propUid, properties)].mobileLocation
                                                ? getAirportProperties(transfer.propUid, properties).airport.name
                                                : getAirportName(airports, getAirportCodeFromUid(airports, checkCustomLocations.airportId)),
                                              value: !properties[getPropObj(transfer.propUid, properties)].mobileLocation
                                                ? getAirportProperties(transfer.propUid, properties).airport.name
                                                : getAirportName(airports, getAirportCodeFromUid(airports, checkCustomLocations.airportId)),
                                            }}
                                            onChange={(event) => {
                                              console.log('onChange event:', event);
                                              // handleChange(event);
                                            }}
                                            customComponents={{
                                              Option: (props) => (
                                                <components.Option {...props}>
                                                  <CustomOption label={props.data.label} data={props.data} />
                                                </components.Option>
                                              ),
                                            }}
                                            isSearchable={true}
                                            icon={<FaLocationDot size={25} />}
                                            showDropdownIndicator={false}
                                            isDisabled={true}
                                          />
                                        </div>
                                        {selectedDirectMultipleValue === 'Direct' ? (
                                          <div className="flex-1" style={{ flexBasis: '8.33%' }}>
                                            {' '}
                                            {/* Exactly 1 column */}
                                            <div className="p-4 text-white">
                                              <div className="flex-1 flex items-center justify-center mt-5">
                                                <FaLongArrowAltRight size={30} className="text-[#1D253F]" />
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="flex" style={{ width: '36%' }}>
                                            <div className="flex-1" style={{ width: '7%' }}>
                                              {/* Content here */}
                                            </div>
                                            <div className="flex-1" style={{ flexBasis: '86%' }}>
                                              {' '}
                                              {/* Close to 5.5 columns again */}
                                              <label htmlFor="connecting-select" className="text-xs">
                                                Connecting Airport
                                              </label>
                                              <CustomSelect
                                                placeholder={selectedConnectingLabel}
                                                defaultValue={{
                                                  label: selectedConnectingLabel,
                                                  value: selectedConnectingValue,
                                                }}
                                                options={connectingAirportsDropDownOptions}
                                                onChange={async (event) => {
                                                  setConnectingAirport(true);
                                                  console.log('event', event);
                                                  setConnectingAirportUid(event.uid);
                                                  setSelectedConnectingValue(event.value);
                                                  setSelectedConnectingLabel(event.label);
                                                  setLastEvent(event);
                                                  setConnectingAirportCall(true);
                                                }}
                                                customComponents={{
                                                  Option: (props) => (
                                                    <components.Option {...props}>
                                                      <CustomOptionAirports label={props.data.label} data={props.data} />
                                                    </components.Option>
                                                  ),
                                                }}
                                                isSearchable={true}
                                                icon={<FaLocationDot size={25} />}
                                                showDropdownIndicator={false}
                                                isDisabled={flightResultsSecond.length > 0 ? true : false}
                                                //width={380}
                                              />
                                            </div>
                                            <div className="flex-1" style={{ width: '7%' }}>
                                              {/* Content here */}
                                            </div>
                                          </div>
                                        )}
                                        <div className="flex-1" style={{ flexBasis: selectedDirectMultipleValue === 'Direct' ? '45.83%' : '32%' }}>
                                          {' '}
                                          {/* Close to 5.5 columns again */}
                                          <label htmlFor="supplier-select" className="text-xs">
                                            {transfer.objType === 'departure' ? 'Arrival at' : 'Arrival at'}
                                          </label>
                                          <CustomSelect
                                            defaultValue={{
                                              label: flightToSearchAirport.name + ' (' + flightToSearchAirport.code + ')',
                                              value: flightToSearchAirport.uid,
                                            }}
                                            onChange={(event) => {
                                              console.log('onChange event:', event);
                                              // handleChange(event);
                                            }}
                                            customComponents={{
                                              Option: (props) => (
                                                <components.Option {...props}>
                                                  <CustomOption label={props.data.label} data={props.data} />
                                                </components.Option>
                                              ),
                                            }}
                                            isSearchable={true}
                                            icon={<FaLocationDot size={25} />}
                                            showDropdownIndicator={false}
                                            isDisabled={true}
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {isLoading ? (
                                      <div className="mt-10 flex items-center justify-center w-full">
                                        <div className="flex items-center">
                                          <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 50 50">
                                            <circle
                                              className="path"
                                              cx="25"
                                              cy="25"
                                              r="20"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="5"
                                              strokeLinecap="round"
                                              strokeDasharray="30, 70"
                                              strokeDashoffset="0"
                                            />
                                          </svg>
                                          <div className="text-sm animate-pulse">Getting flights...</div>
                                        </div>
                                      </div>
                                    ) : flightResults && flightResults.length > 0 ? (
                                      <div className="w-full">
                                        <>
                                          <FlightResultsComponent
                                            flightResults={flightResults}
                                            selectedFlightIndex={selectedFlightIndex}
                                            selectedFlight={selectedFlight}
                                            showButtons={showButtons}
                                            currentPages={currentPages}
                                            buttonsToShow={buttonsToShow}
                                            airports={airports}
                                            //flightToSearchAirport={transfer.objType === 'arrival' ? flightToSearchAirport : airports.find((airport) => airport.code === selectedFlightAlgolia[0])}
                                            flightToSearchAirport={flightToSearchAirport}
                                            localSelectedIndices={localSelectedIndices}
                                            setLocalSelectedIndices={setLocalSelectedIndices}
                                            setLocalSelectedIndex={setSelectedFlightIndex}
                                            handleButtonClick={handleButtonClick}
                                            connectingAirport={connectingAirport}
                                            airportId={airportId}
                                            connectingAirportUid={connectingAirportUid}
                                            transfer={transfer}
                                            handleOptionClickGetFlights={handleOptionClickGetFlights}
                                            flightCost={flightCost}
                                            airlineName={airlineName}
                                            flightPricing={flightPricing}
                                            setSelectedFlightIndex={setSelectedFlightIndex}
                                            setShowButtons={setShowButtons}
                                            searchAgain={searchAgain}
                                            properties={properties}
                                            secondFlight={false}
                                            flightResultsSecond={flightResultsSecond}
                                            selectedDirectMultipleValue={selectedDirectMultipleValue}
                                            changeFlight={changeFlight}
                                            resetSearch={resetSearch}
                                            firstFlight={true}
                                            selectedFlightFirst={selectedFlight}
                                            selectedFlightSecond={selectedFlightSecond}
                                            setHasSufficientTime={setHasSufficientTime}
                                          />

                                          {flightResultsSecond &&
                                            flightResultsSecond.length > 0 &&
                                            (() => {
                                              //let flightToSearchSecond = airports.find((airport) => airport.uid === airportId);
                                              console.log('selectedFlightIndexSecond FIX', selectedFlightIndexSecond);
                                              console.log('showButtonsSecond FIX', showButtonsSecond);
                                              return (
                                                <FlightResultsComponent
                                                  flightResults={flightResultsSecond}
                                                  selectedFlightIndex={selectedFlightIndexSecond}
                                                  selectedFlight={selectedFlightSecond}
                                                  showButtons={showButtonsSecond}
                                                  currentPages={currentPagesSecond}
                                                  buttonsToShow={buttonsToShow}
                                                  airports={airports}
                                                  flightToSearchAirport={flightToSearchAirportSecond}
                                                  localSelectedIndices={localSelectedIndicesSecond}
                                                  setLocalSelectedIndices={setLocalSelectedIndicesSecond}
                                                  setLocalSelectedIndex={setLocalSelectedIndexSecond}
                                                  handleButtonClick={handleButtonClick}
                                                  connectingAirport={connectingAirport}
                                                  airportId={airportId}
                                                  connectingAirportUid={connectingAirportUid}
                                                  transfer={transfer}
                                                  handleOptionClickGetFlights={handleOptionClickGetFlights}
                                                  flightCost={flightCostSecond}
                                                  airlineName={airlineNameSecond}
                                                  flightPricing={flightPricingSecond}
                                                  setSelectedFlightIndex={setSelectedFlightIndexSecond}
                                                  setShowButtons={setShowButtonsSecond}
                                                  properties={properties}
                                                  secondFlight={true}
                                                  flightResultsSecond={flightResultsSecond}
                                                  selectedDirectMultipleValue={selectedDirectMultipleValue}
                                                  changeFlight={changeFlight}
                                                  resetSearch={resetSearch}
                                                  firstFlight={false}
                                                  selectedFlightFirst={selectedFlight}
                                                  selectedFlightSecond={selectedFlightSecond}
                                                  setHasSufficientTime={setHasSufficientTime}
                                                />
                                              );
                                            })()}

                                          {selectedDirectMultipleValue === 'Multiple' && flightResultsSecond.length === 0 ? (
                                            <TransferInfoCard
                                              icon={IoMdInformationCircleOutline}
                                              title="No flights Found"
                                              text={'Try searching to another airport, or choose a multi-sector connection'}
                                            />
                                          ) : selectedDirectMultipleValue === 'Multiple' && !flightResultsSecond ? (
                                            <div className="mt-5 text-sm mb-3">
                                              <span className="">Select first flight to search for second flight</span>
                                            </div>
                                          ) : null}
                                        </>
                                      </div>
                                    ) : selectedDirectMultipleValue === 'Direct' && flightResults.length === 0 ? (
                                      <TransferInfoCard icon={IoMdInformationCircleOutline} title="No flights Found" text={'Try searching to another airport, or choose a multi-sector connection'} />
                                    ) : (
                                      <div className="text-sm">{selectedDirectMultipleValue === 'Direct' ? 'Select Direct or Multiple to search flights' : 'Select an airport to search flights'}</div>
                                    )}
                                  </>
                                </div>
                              </div>

                              {((selectedDirectMultipleValue === 'Direct' && showButtons) || (selectedDirectMultipleValue === 'Multiple' && showButtonsSecond)) && (
                                <div className="my-10">
                                  <div className="pl-0 mx-0">
                                    <p className="text-sm mb-2 w-max">What time will you arrive at {properties[getPropObj(nextTransfer.propUid, properties)].name}?</p>
                                  </div>
                                  <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-4">
                                      <CustomSelect
                                        placeholder={arrivalTime}
                                        options={arrivalDepartureTimeOptions}
                                        onChange={handleChangeArrivalTime}
                                        value={arrivalTime === 'Select time' ? { label: arrivalTime, value: null } : arrivalTime}
                                        instanceId="arrival-select"
                                        isSearchable={false}
                                        showDropdownIndicator={false}
                                      />
                                    </div>
                                    <div className="col-span-4"></div>
                                    <div className="col-span-4"></div>
                                  </div>
                                </div>
                              )}

                              {!resetPackedLunch &&
                              arrivalTime !== 'Select time' &&
                              ((selectedDirectMultipleValue === 'Direct' && showButtons) || (selectedDirectMultipleValue === 'Multiple' && showButtonsSecond)) ? (
                                <>
                                  <div className="text-black text-[25px] font-semibold mt-5">Add-on</div>
                                  <div className="grid grid-cols-12 gap-4 mt-3">
                                    <TransferArrivalOrDeparture
                                      transfer={transfer}
                                      nextTransfer={nextTransfer}
                                      properties={properties}
                                      arrivalOrDeparture={'departure'}
                                      includeArrivalTransferCheck={includeArrivalTransferCheck}
                                      setIncludeArrivalTransferCheck={setIncludeArrivalTransferCheck}
                                      includeDepartureTransferCheck={includeDepartureTransferCheck}
                                      setIncludeDepartureTransferCheck={setIncludeDepartureTransferCheck}
                                    />

                                    <TransferArrivalOrDeparture
                                      transfer={transfer}
                                      nextTransfer={nextTransfer}
                                      properties={properties}
                                      arrivalOrDeparture={'arrival'}
                                      includeArrivalTransferCheck={includeArrivalTransferCheck}
                                      setIncludeArrivalTransferCheck={setIncludeArrivalTransferCheck}
                                      includeDepartureTransferCheck={includeDepartureTransferCheck}
                                      setIncludeDepartureTransferCheck={setIncludeDepartureTransferCheck}
                                    />

                                    <PackedLunch
                                      packedLunch={packedLunch}
                                      setPackedLunch={setPackedLunch}
                                      packedLunchChargeable={packedLunchChargeable}
                                      setPackedLunchChargeable={setPackedLunchChargeable}
                                      transfer={transfer}
                                      properties={properties}
                                      booking={booking}
                                      show={true}
                                    />

                                    {(() => {
                                      const getFlight = selectedFlight;
                                      const airportCode = getFlight.fromcode;
                                      const airportService = processVip(airports, airportCode, transfer, 'departure');

                                      if (airportService) {
                                        // Set the VIP object in state
                                        //setVipObject(airportService);
                                        vipObjectDeparture = airportService;

                                        return (
                                          <>
                                            <input type="hidden" name="vipObjectDeparture" value={JSON.stringify(vipObjectDeparture)} />
                                            <TransferArrivalDepartureVip
                                              transfer={transfer}
                                              includeArrivalTransferVip={includeArrivalTransferVip}
                                              setIncludeArrivalTransferVip={setIncludeArrivalTransferVip}
                                              includeDepartureTransferVip={includeDepartureTransferVip}
                                              setIncludeDepartureTransferVip={setIncludeDepartureTransferVip}
                                              vipObject={vipObjectDeparture}
                                              totalPax={flightAdults + flightChildren + flightInfants}
                                              arrivalType={'Departure'}
                                              airportCode={airportCode}
                                              airports={airports}
                                            />
                                          </>
                                        );
                                      }

                                      return null;
                                    })()}

                                    {(() => {
                                      const getFlight = selectedDirectMultipleValue === 'Direct' ? selectedFlight : selectedFlightSecond;
                                      const airportCode = getFlight.tocode;
                                      const airportService = processVip(airports, airportCode, transfer, 'arrival');

                                      if (airportService) {
                                        // Set the VIP object in state
                                        //setVipObject(airportService);
                                        vipObjectArrival = airportService;

                                        return (
                                          <>
                                            <input type="hidden" name="vipObjectArrival" value={JSON.stringify(vipObjectArrival)} />
                                            <TransferArrivalDepartureVip
                                              transfer={transfer}
                                              includeArrivalTransferVip={includeArrivalTransferVip}
                                              setIncludeArrivalTransferVip={setIncludeArrivalTransferVip}
                                              includeDepartureTransferVip={includeDepartureTransferVip}
                                              setIncludeDepartureTransferVip={setIncludeDepartureTransferVip}
                                              vipObject={vipObjectArrival}
                                              totalPax={flightAdults + flightChildren + flightInfants}
                                              arrivalType={'Arrival'}
                                              airportCode={airportCode}
                                              airports={airports}
                                            />
                                          </>
                                        );
                                      }

                                      return null;
                                    })()}
                                  </div>
                                </>
                              ) : (
                                airportTransfer !== null &&
                                arrivalTime !== 'Select time' && (
                                  <>
                                    <div className="text-black text-[25px] font-semibold mt-5">Add-on</div>
                                    <div className="grid grid-cols-12 gap-4 mt-3">
                                      <PackedLunch
                                        packedLunch={packedLunch}
                                        setPackedLunch={setPackedLunch}
                                        packedLunchChargeable={packedLunchChargeable}
                                        setPackedLunchChargeable={setPackedLunchChargeable}
                                        transfer={transfer}
                                        properties={properties}
                                        booking={booking}
                                        show={true}
                                      />
                                      <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                      <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                    </div>
                                  </>
                                )
                              )}

                              {/* ARRIVAL TIME */}
                              <div className="flex">
                                <div className="flex mt-7 justify-end w-full gap-4 mb-20">
                                  <button
                                    className="bg-white text-nomadBlue-500 border border-nomadBlue-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadBlue-500 hover:border-nomadBlue-700 flex items-center justify-center"
                                    type="button"
                                    onClick={(e) => {
                                      if (!transfer.formValues) {
                                        setSelectedTransferTuuid(null);
                                        onClose();
                                      } else {
                                        setArrivalTime('Select time');
                                        closeForm(e, transfer.tuuid, transfers, setTransfers, onClose);
                                      }
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  {/* {((selectedDirectMultipleValue === 'Direct' && showButtons) || (selectedDirectMultipleValue === 'Multiple' && showButtonsSecond)) &&
                                    arrivalTime !== 'Select time' &&
                                    flightResults &&
                                    flightResults.length > 0 && (
                                     
                                      <button
                                        className="bg-nomadBlue-800 text-white text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadBlue-700 hover:text-white"
                                        type="submit"
                                        disabled={searchAgain}
                                        onClick={(e) => {
                                          if (hasSufficientTime) {
                                            handleConfirmClickSubmitFlight(e);
                                          } else {
                                            e.preventDefault();
                                            toast.error('Select flights with at least 10 minutes between flights');
                                          }
                                        }}
                                      >
                                        Select
                                      </button>
                                    )} */}
                                  {((selectedDirectMultipleValue === 'Direct' && showButtons && selectedFlight) ||
                                    (selectedDirectMultipleValue === 'Multiple' && showButtonsSecond && selectedFlightSecond)) &&
                                    arrivalTime !== 'Select time' &&
                                    flightResults &&
                                    flightResults.length > 0 && (
                                      <button
                                        className="bg-nomadBlue-800 text-white text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadBlue-700 hover:text-white"
                                        type="submit"
                                        disabled={searchAgain}
                                        onClick={(e) => {
                                          if (hasSufficientTime) {
                                            handleConfirmClickSubmitFlight(e);
                                          } else {
                                            e.preventDefault();
                                            toast.error('Select flights with at least 10 minutes between flights');
                                          }
                                        }}
                                      >
                                        Select
                                      </button>
                                    )}
                                </div>
                              </div>
                            </form>

                            {/* FORM: ownArrangements */}
                            <form
                              id="ownArrangements"
                              className={`${
                                transfer.formValues && transfer.edit && transfer.formValues.type === 'ownArrangements' && !selectedContent ? '' : selectedContent === 'ownArrangements' ? '' : 'hidden'
                              } p-0 bg-white rounded-lg`}
                              onSubmit={(e) => handleFormSubmitOwnArrangements(e, transfers, setTransfers, onClose, bookings, setBookings, properties, customLocations, airports)}
                            >
                              <input type="hidden" name="formType" value={transferType} />
                              <input type="hidden" name="tuuid" value={transfer.tuuid} />
                              <input type="hidden" name="formName" value="ownArrangements" />
                              <input type="hidden" name="originAirportName" value={originAirportName || ''} />
                              <input type="hidden" name="destinationAirportName" value={destinationAirportName || ''} />
                              <input type="hidden" name="originAirportUid" value={originAirportUid || ''} />
                              <input type="hidden" name="destinationAirportUid" value={destinationAirportUid || ''} />
                              <input type="hidden" name="originAirportCode" value={originAirportCode || ''} />
                              <input type="hidden" name="originLocationUid" value={originLocationUid || ''} />
                              <input type="hidden" name="destinationLocationUid" value={destinationLocationUid || ''} />

                              <input type="hidden" name="destinationAirportCode" value={destinationAirportCode || ''} />
                              <input type="hidden" name="timeDeparture" value={departureTimeOwnArrangements || ''} />
                              <input type="hidden" name="timeArrival" value={arrivalTimeOwnArrangements || ''} />
                              <input type="hidden" name="airlineUid" value={airlineUidOwnArrangements || ''} />
                              <input type="hidden" name="airlineName" value={airlineNameOwnArrangements || ''} />
                              <input type="hidden" name="arrivalTime" value={arrivalTime.value} />
                              <input type="hidden" name="departureTime" value={departureTime.value} />
                              <input type="hidden" name="supplierName" value={supplierName} />
                              <input type="hidden" name="supplierUid" value={supplierUid} />
                              <input type="hidden" name="addLater" value={addLater} />

                              <div className="flex w-full mt-10">
                                <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                  {/* Close to 5.5 columns  */}
                                  <label htmlFor="supplier-select" className="text-xs">
                                    Departure from
                                  </label>
                                  <CustomSelect
                                    placeholder={properties[getPropObj(transfer.propUid, properties)].name}
                                    defaultValue={{ label: properties[getPropObj(transfer.propUid, properties)].name, value: properties[getPropObj(transfer.propUid, properties)].name }}
                                    onChange={console.log('clicked')}
                                    isSearchable={false}
                                    icon={<FaLocationDot size={25} />}
                                    showDropdownIndicator={false}
                                    isDisabled={true}
                                  />
                                </div>
                                <div className="flex-1" style={{ flexBasis: '8.33%' }}>
                                  {' '}
                                  {/* Exactly 1 column */}
                                  <div className="p-4 text-white">
                                    <div className="flex-1 flex items-center justify-center mt-5">
                                      <FaLongArrowAltRight size={30} className="text-[#1D253F]" />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                  {' '}
                                  {/* Close to 5.5 columns again */}
                                  <label htmlFor="supplier-select" className="text-xs">
                                    Arrival at
                                  </label>
                                  <CustomSelect
                                    placeholder={properties[getPropObj(nextTransfer.propUid, properties)].name}
                                    defaultValue={{ label: properties[getPropObj(nextTransfer.propUid, properties)].name, value: properties[getPropObj(nextTransfer.propUid, properties)].name }}
                                    onChange={console.log('clicked')}
                                    isSearchable={false}
                                    icon={<FaLocationDot size={25} />}
                                    showDropdownIndicator={false}
                                    isDisabled={true}
                                  />
                                </div>
                              </div>

                              {/* <div className="text-sm flex items-center my-10">
                                Travelling via air?
                                <div className="ml-3 flex items-center">
                                  <input
                                    type="radio"
                                    name="airportTransfer"
                                    value="true"
                                    className="form-radio bg-white"
                                    onChange={(e) => setAirportTransfer(e.target.value === true)}
                                    checked={airportTransfer === true}
                                    required
                                  />
                                  <span className="label-text ml-2 text-sm">Yes</span>
                                </div>
                                <div className="ml-3 flex items-center">
                                  <input
                                    type="radio"
                                    name="airportTransfer"
                                    value="false"
                                    className="form-radio bg-white"
                                    onChange={(e) => setAirportTransfer(e.target.value === false)}
                                    checked={airportTransfer === false}
                                    required
                                  />
                                  <span className="label-text ml-2 text-sm">No</span>
                                </div>
                              </div> */}
                              <div className="text-sm flex items-center my-10">
                                Travelling via air?
                                <div className="ml-3 flex items-center">
                                  <input
                                    type="radio"
                                    name="airportTransfer"
                                    value="true"
                                    className="form-radio bg-white"
                                    onChange={() => setAirportTransfer(true)}
                                    checked={airportTransfer === true}
                                    required
                                  />
                                  <span className="label-text ml-2 text-sm">Yes</span>
                                </div>
                                <div className="ml-3 flex items-center">
                                  <input
                                    type="radio"
                                    name="airportTransfer"
                                    value="false"
                                    className="form-radio bg-white"
                                    onChange={() => setAirportTransfer(false)}
                                    checked={airportTransfer === false}
                                    required
                                  />
                                  <span className="label-text ml-2 text-sm">No</span>
                                </div>
                              </div>

                              {airportTransfer === true && (
                                <div className="mb-10">
                                  <div>
                                    <div className="text-sm mb-2">Enter your flight details</div>

                                    <div className="grid grid-cols-12 gap-4">
                                      <div className="col-span-4">
                                        <label htmlFor="departure" className="text-xs">
                                          Departure airport
                                        </label>
                                        <input
                                          type="text"
                                          className="w-full rounded-lg text-sm h-[50px] border border-[#7F7F7F] focus:border-2 focus:border-[#1D253F] focus:ring-0 focus:outline-none hover:border-[#1D253F]"
                                          value={originAirportName || ''}
                                          name="airportOrigin"
                                          disabled
                                        />
                                      </div>
                                      <div className="col-span-4">
                                        <label htmlFor="destination" className="text-xs">
                                          Destination airport
                                        </label>

                                        <input
                                          type="text"
                                          className="w-full rounded-lg text-sm h-[50px] border border-[#7F7F7F] focus:border-2 focus:border-[#1D253F] focus:ring-0 focus:outline-none hover:border-[#1D253F]"
                                          value={destinationAirportName || ''}
                                          name="airportArrival"
                                          disabled
                                        />
                                      </div>
                                      <div className="col-span-4">
                                        <label htmlFor="fltnum" className="text-xs">
                                          Flight number
                                        </label>
                                        <input
                                          className="w-full rounded-lg text-sm h-[50px] border border-[#7F7F7F] focus:border-2 focus:border-[#1D253F] focus:ring-0 focus:outline-none hover:border-[#1D253F]"
                                          type="text"
                                          placeholder="Flight number"
                                          name="fltnum"
                                          value={fltNum}
                                          onChange={handleFltNumInputChange}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="grid grid-cols-12 gap-4 mt-5">
                                      <div className="col-span-4">
                                        <label htmlFor="airline" className="text-xs">
                                          Airline
                                        </label>
                                        <CustomSelect
                                          placeholder={airlineNameOwnArrangements}
                                          options={airlines.sort((a, b) => a.name.localeCompare(b.name)).map((airline) => ({ value: airline.uid, label: airline.name }))}
                                          onChange={handleChangeAirline}
                                          defaultValue={{ label: airlineNameOwnArrangements, value: airlineNameOwnArrangements }}
                                          instanceId="airline-select"
                                          isSearchable={true}
                                          showDropdownIndicator={false}
                                          // width={180}
                                        />
                                      </div>
                                      <div className="col-span-4">
                                        <label htmlFor="departureTime" className="text-xs">
                                          Departure time
                                        </label>
                                        <CustomSelect
                                          placeholder={departureTimeOwnArrangements}
                                          options={timeOptions}
                                          onChange={handleChangeTimeDeparture}
                                          defaultValue={departureTimeOwnArrangements ? { label: departureTimeOwnArrangements, value: departureTimeOwnArrangements } : null}
                                          instanceId="airline-select"
                                          isSearchable={true}
                                          showDropdownIndicator={false}
                                        />
                                      </div>
                                      <div className="col-span-4">
                                        <label htmlFor="arrivalTime" className="text-xs">
                                          Arrival Time
                                        </label>
                                        <CustomSelect
                                          placeholder={arrivalTimeOwnArrangements}
                                          options={timeOptions}
                                          onChange={handleChangeTimeArrival}
                                          defaultValue={arrivalTimeOwnArrangements ? { label: arrivalTimeOwnArrangements, value: arrivalTimeOwnArrangements } : null}
                                          instanceId="airline-select"
                                          isSearchable={true}
                                          showDropdownIndicator={false}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="ml-0 mt-5 flex items-start">
                                    {/* <input
                                      type="checkbox"
                                      name="addFlightDetailsLater"
                                      className="checkbox checkbox-accent"
                                      defaultChecked={transfer.formValues && transfer.edit && transfer.formValues.type === 'ownArrangements' ? transfer.formValues.addDetailsLater : false}
                                    /> */}
                                    <input
                                      type="checkbox"
                                      name="addFlightDetailsLater"
                                      //className="checkbox checkbox-accent"
                                      className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
                                      checked={addFlightDetailsLater}
                                      onChange={(e) => setAddFlightDetailsLater(e.target.checked)}
                                    />

                                    <span className="label-text ml-2 text-sm">Add flight number, airline, departure time and arrival time later</span>
                                  </div>
                                  <TransferInfoCard icon={IoMdInformationCircleOutline} title="No transfer included!" text={` You'll book your own flight between properties `} />
                                </div>
                              )}

                              {airportTransfer === false && (
                                <div className="mb-10">
                                  <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-6">
                                      <div className="pl-0 mx-0">
                                        <p className="text-xs mb-2 w-max">Departure time from {properties[getPropObj(transfer.propUid, properties)].name}</p>
                                      </div>
                                      <CustomSelect
                                        placeholder={departureTime}
                                        options={arrivalDepartureTimeOptions}
                                        onChange={handleChangeDepartureTime}
                                        value={departureTime === 'Select time' ? { label: departureTime, value: null } : departureTime}
                                        instanceId="departure-select"
                                        isSearchable={false}
                                        showDropdownIndicator={false}
                                      />
                                    </div>
                                    <div className="col-span-6">
                                      <div className="pl-0 mx-0">
                                        <p className="text-xs mb-2 w-max">Arrival time at {properties[getPropObj(nextTransfer.propUid, properties)].name}</p>
                                      </div>
                                      <CustomSelect
                                        placeholder={arrivalTime}
                                        options={arrivalDepartureTimeOptions}
                                        onChange={handleChangeArrivalTime}
                                        value={arrivalTime === 'Select time' ? { label: arrivalTime, value: null } : arrivalTime}
                                        instanceId="arrival-select"
                                        isSearchable={false}
                                        showDropdownIndicator={false}
                                      />
                                    </div>
                                  </div>

                                  <div className="mt-5 grid grid-cols-12 gap-4">
                                    <div className="col-span-6">
                                      <div htmlFor="supplier-select" className="text-xs mb-2">
                                        Transfer by
                                      </div>
                                      <CustomSelect
                                        placeholder={supplierName === null ? 'Select supplier' : supplierName}
                                        onChange={(selectedOption) => {
                                          setSupplierName(selectedOption.label);
                                          setSupplierUid(selectedOption.value);
                                        }}
                                        options={suppliers
                                          .sort((a, b) => a.name.localeCompare(b.name))
                                          .map((supplier) => ({
                                            value: supplier.uid,
                                            label: supplier.name,
                                          }))}
                                        value={supplierName && supplierUid ? { label: supplierName, value: supplierUid } : { label: 'Select supplier', value: null }}
                                        instanceId="supplier-select"
                                        isSearchable={true}
                                        showDropdownIndicator={false}
                                      />
                                    </div>
                                  </div>
                                  <div className={`mt-3 flex items-start`}>
                                    <input
                                      type="checkbox"
                                      name="addLater"
                                      // className="checkbox checkbox-accent"
                                      className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
                                      checked={addLater}
                                      onChange={(e) => {
                                        let isChecked = e.target.checked;
                                        setAddLater(isChecked);
                                      }}
                                    />{' '}
                                    <span className="label-text ml-2 text-sm">Add departure, arrival and supplier details later</span>
                                  </div>

                                  <TransferInfoCard icon={IoMdInformationCircleOutline} title="No transfer included!" text={` You'll make your own way between properties `} />
                                </div>
                              )}

                              {(!resetPackedLunch &&
                                fltNum !== '' &&
                                airlineNameOwnArrangements !== '' &&
                                departureTimeOwnArrangements !== 'Select time' &&
                                arrivalTimeOwnArrangements !== 'Select time' &&
                                airportTransfer) ||
                              (addFlightDetailsLater && airportTransfer) ? (
                                <>
                                  <div className="text-black text-[25px] font-semibold mt-5">Add-on</div>
                                  <div className="grid grid-cols-12 gap-4 mt-3">
                                    <TransferArrivalOrDeparture
                                      transfer={transfer}
                                      nextTransfer={nextTransfer}
                                      properties={properties}
                                      arrivalOrDeparture={'departure'}
                                      includeArrivalTransferCheck={includeArrivalTransferCheck}
                                      setIncludeArrivalTransferCheck={setIncludeArrivalTransferCheck}
                                      includeDepartureTransferCheck={includeDepartureTransferCheck}
                                      setIncludeDepartureTransferCheck={setIncludeDepartureTransferCheck}
                                    />

                                    <TransferArrivalOrDeparture
                                      transfer={transfer}
                                      nextTransfer={nextTransfer}
                                      properties={properties}
                                      arrivalOrDeparture={'arrival'}
                                      includeArrivalTransferCheck={includeArrivalTransferCheck}
                                      setIncludeArrivalTransferCheck={setIncludeArrivalTransferCheck}
                                      includeDepartureTransferCheck={includeDepartureTransferCheck}
                                      setIncludeDepartureTransferCheck={setIncludeDepartureTransferCheck}
                                    />

                                    <PackedLunch
                                      packedLunch={packedLunch}
                                      setPackedLunch={setPackedLunch}
                                      packedLunchChargeable={packedLunchChargeable}
                                      setPackedLunchChargeable={setPackedLunchChargeable}
                                      transfer={transfer}
                                      properties={properties}
                                      booking={booking}
                                      show={true}
                                    />

                                    {(() => {
                                      // const getFlight = selectedFlight;
                                      const airportCode = originAirportCode;
                                      const airportService = processVip(airports, airportCode, transfer, 'departure');

                                      if (airportService) {
                                        // Set the VIP object in state
                                        //setVipObject(airportService);
                                        vipObjectDeparture = airportService;
                                        vipObjectDeparture.airportCode = airportCode;

                                        return (
                                          <>
                                            <input type="hidden" name="vipObjectDeparture" value={JSON.stringify(vipObjectDeparture)} />
                                            <TransferArrivalDepartureVip
                                              transfer={transfer}
                                              includeArrivalTransferVip={includeArrivalTransferVip}
                                              setIncludeArrivalTransferVip={setIncludeArrivalTransferVip}
                                              includeDepartureTransferVip={includeDepartureTransferVip}
                                              setIncludeDepartureTransferVip={setIncludeDepartureTransferVip}
                                              vipObject={vipObjectDeparture}
                                              totalPax={flightAdults + flightChildren + flightInfants}
                                              arrivalType={'Departure'}
                                              airportCode={airportCode}
                                              airports={airports}
                                            />
                                          </>
                                        );
                                      }

                                      return null;
                                    })()}

                                    {(() => {
                                      //const getFlight = selectedDirectMultipleValue === 'Direct' ? selectedFlight : selectedFlightSecond;
                                      const airportCode = destinationAirportCode;
                                      const airportService = processVip(airports, airportCode, transfer, 'arrival');

                                      if (airportService) {
                                        // Set the VIP object in state
                                        //setVipObject(airportService);
                                        vipObjectArrival = airportService;
                                        vipObjectArrival.airportCode = airportCode;

                                        return (
                                          <>
                                            <input type="hidden" name="vipObjectArrival" value={JSON.stringify(vipObjectArrival)} />
                                            <TransferArrivalDepartureVip
                                              transfer={transfer}
                                              includeArrivalTransferVip={includeArrivalTransferVip}
                                              setIncludeArrivalTransferVip={setIncludeArrivalTransferVip}
                                              includeDepartureTransferVip={includeDepartureTransferVip}
                                              setIncludeDepartureTransferVip={setIncludeDepartureTransferVip}
                                              vipObject={vipObjectArrival}
                                              totalPax={flightAdults + flightChildren + flightInfants}
                                              arrivalType={'Arrival'}
                                              airportCode={airportCode}
                                              airports={airports}
                                            />
                                          </>
                                        );
                                      }

                                      return null;
                                    })()}
                                  </div>
                                </>
                              ) : // (airportTransfer !== null && arrivalTime !== 'Select time' && departureTime !== 'Select time' && supplierName !== null && addLater === false) ||
                              // (addLater && (
                              //   <>
                              //     <div className="text-black text-[25px] font-semibold mt-5">Add-on</div>
                              //     <div className="grid grid-cols-12 gap-4 mt-3">
                              //       <PackedLunch
                              //         packedLunch={packedLunch}
                              //         setPackedLunch={setPackedLunch}
                              //         packedLunchChargeable={packedLunchChargeable}
                              //         setPackedLunchChargeable={setPackedLunchChargeable}
                              //         transfer={transfer}
                              //         properties={properties}
                              //         booking={booking}
                              //         show={true}
                              //       />
                              //       <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                              //       <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                              //     </div>
                              //   </>
                              // ))
                              (!airportTransfer && arrivalTime !== 'Select time' && departureTime !== 'Select time' && supplierName !== null && addLater === false) || addLater ? (
                                <>
                                  <div className="text-black text-[25px] font-semibold mt-5">Add-on</div>
                                  <div className="grid grid-cols-12 gap-4 mt-3">
                                    <PackedLunch
                                      packedLunch={packedLunch}
                                      setPackedLunch={setPackedLunch}
                                      packedLunchChargeable={packedLunchChargeable}
                                      setPackedLunchChargeable={setPackedLunchChargeable}
                                      transfer={transfer}
                                      properties={properties}
                                      booking={booking}
                                      show={true}
                                    />
                                    <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                    <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                  </div>
                                </>
                              ) : null}

                              {airportTransfer !== null ? (
                                <div className="flex">
                                  <div className="flex mt-7 justify-end w-full gap-4 mb-20">
                                    <button
                                      className="bg-white text-nomadBlue-500 border border-nomadBlue-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadBlue-500 hover:border-nomadBlue-700 flex items-center justify-center"
                                      type="button"
                                      onClick={(e) => {
                                        if (!transfer.formValues) {
                                          setSelectedTransferTuuid(null);
                                          onClose();
                                        } else {
                                          closeForm(e, transfer.tuuid, transfers, setTransfers, onClose);
                                        }
                                      }}
                                    >
                                      Cancel
                                    </button>

                                    <button
                                      className="bg-nomadBlue-800 text-white text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadBlue-700 hover:text-white"
                                      type="submit"
                                    >
                                      Select
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex mt-5 justify-end">
                                  <button
                                    className="bg-white text-nomadBlue-500 border border-nomadBlue-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadBlue-500 hover:border-nomadBlue-700 flex items-center justify-center"
                                    type="button"
                                    onClick={(e) => {
                                      if (!transfer.formValues) {
                                        setSelectedTransferTuuid(null);
                                        onClose();
                                      } else {
                                        closeForm(e, transfer.tuuid, transfers, setTransfers, onClose);
                                      }
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function TransferSlideOutInter({
  isOpen,
  onClose,
  transfer,
  nextTransfer,
  prevTransfer,
  transfers,
  setTransfers,
  deeShadow,
  SlPlane,
  timeRef,
  setSelectedTransferTuuid,
  GiJeep,
  MdOutlineLocationOn,
  CgUnavailable,
  BiTransfer,
  TbTransferIn,
  freshUuid,
  routeTransfer,
  availableFlights,
  airports,
  properties,
  transferType,
  customLocations,
  setIsModalOpen,
  setRefreshTransferRates,
  bookings,
  setBookings,
  booking,
  airlines,
  agentObject,
  createEdit,
  createVersion,
  transfersDataFlights,
  user,
  suppliers,
  advancedMode,
}) {
  const hasMatchingTransfer = (transfers, tuuid) => {
    return transfers.some((transfer) => transfer.tuuid === tuuid && transfer.edit === true);
  };

  if (!transfer || !transfer.edit || !hasMatchingTransfer(transfers, transfer.tuuid)) {
    // If the required data is not available, don't render the slide-out
    //onClose();
    console.log('no transfer');
    return null;
  } else if (transfer.objType !== 'propertyTransfer') {
    console.log('transfer is: ', transfer.objType);
    return null;
  } else {
    console.log('there is inter');
    console.log('transfers', JSON.stringify(transfers, null, 2));
  }

  return (
    <TransferSlideOutContent
      isOpen={isOpen}
      onClose={onClose}
      transfer={transfer}
      nextTransfer={nextTransfer}
      prevTransfer={prevTransfer}
      transfers={transfers}
      setTransfers={setTransfers}
      deeShadow={deeShadow}
      SlPlane={SlPlane}
      timeRef={timeRef}
      setSelectedTransferTuuid={setSelectedTransferTuuid}
      GiJeep={GiJeep}
      MdOutlineLocationOn={MdOutlineLocationOn}
      CgUnavailable={CgUnavailable}
      BiTransfer={BiTransfer}
      TbTransferIn={TbTransferIn}
      freshUuid={freshUuid}
      routeTransfer={routeTransfer}
      availableFlights={availableFlights}
      airports={airports}
      properties={properties}
      transferType={transferType}
      customLocations={customLocations}
      setIsModalOpen={setIsModalOpen}
      setRefreshTransferRates={setRefreshTransferRates}
      bookings={bookings}
      setBookings={setBookings}
      booking={booking}
      airlines={airlines}
      agentObject={agentObject}
      createEdit={createEdit}
      createVersion={createVersion}
      transfersDataFlights={transfersDataFlights}
      user={user}
      suppliers={suppliers}
      advancedMode={advancedMode}
    />
  );
}

export default TransferSlideOutInter;
