import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function LocationAndTravelTab({ camp }) {
  const mapContainerStyle = {
    minHeight: '485px',
    width: '100%',
  };

  const center = {
    lat: parseFloat(camp.campInformation.lat),
    lng: parseFloat(camp.campInformation.long),
  };

  const mapOptions = {
    streetViewControl: false,
  };

  return (
    <div className="mt-8">
      <h3 className="text-lg font-semibold font-sans mb-2">Location</h3>
      <p className="text-base mb-8 font-sans">{camp.location}</p>

      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={10}
          options={mapOptions}
        >
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default LocationAndTravelTab;