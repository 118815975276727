import { doc, getDoc, updateDoc, setDoc, collection } from "firebase/firestore";
import { db } from "../db/firebase.config";


// Fetch the supplier request from the database on load of page. 
export const fetchSupplierRequestData = async (supplierRequestId) => {
  try {
    const supplierRequestDoc = await getDoc(doc(db, "supplierRequests", supplierRequestId));
    if (supplierRequestDoc.exists()) {
      return supplierRequestDoc.data();
    } else {
      throw new Error("Supplier request document does not exist");
    }
  } catch (error) {
    console.error("Error fetching supplier request data:", error);
    throw error;
  }
};


// Function updates the special request info in the supplierRequest document
export const updateSpecialRequest = async (supplierRequestId, propertyRequestId, specialRequest) => {
  try {
    const supplierRequestDoc = await getDoc(doc(db, "supplierRequests", supplierRequestId));
    if (supplierRequestDoc.exists()) {
      const supplierRequestData = supplierRequestDoc.data();
      const existingSpecialRequests = supplierRequestData.specialRequests || [];

      const updatedSpecialRequests = existingSpecialRequests.filter(
        (request) => request.propertyUid !== propertyRequestId
      );
      updatedSpecialRequests.push({
        message: specialRequest,
        propertyUid: propertyRequestId,
      });

      await updateDoc(doc(db, "supplierRequests", supplierRequestId), {
        specialRequests: updatedSpecialRequests,
      });
    } else {
      throw new Error("Supplier request document does not exist");
    }
  } catch (error) {
    console.error("Error updating special request:", error);
    throw error;
  }
};

  
  export const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };
  
  export const formatDateWithDay = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("en-US", options);
  };
  
  export const formatPax = (selectedAgesAdults, selectedAges) => {
    const adultCount = selectedAgesAdults.length;
    const childrenCount = selectedAges.length;
    const childrenAges = selectedAges.map((item) => item.age).join(",");
  
    let paxString = `${adultCount} Adult${adultCount !== 1 ? "s" : ""}`;
    if (childrenCount > 0) {
      paxString += ` and ${childrenCount} Child${
        childrenCount !== 1 ? "ren" : ""
      } (Ages: ${childrenAges})`;
    }
  
    return paxString;
  };


  export const createSupplierRequests = async (itineraryUid) => {
    try {
      const itineraryRef = doc(db, 'itineraries', itineraryUid);
      const itineraryDoc = await getDoc(itineraryRef);
  
      if (!itineraryDoc.exists()) {
        console.log('Itinerary not found');
        return;
      }
  
      const itineraryData = itineraryDoc.data().itineraryData;
      const creatioBookingUid = itineraryDoc.data().creatioBookingUid;
      console.log('Itinerary Data:', itineraryData);
  
      // Filter out items with the nomad supplierUid
      const filteredItineraryData = itineraryData.filter(item => item.supplierUid !== "ded3a3ed-aeaf-4495-9069-7754a649de67");
  
      const supplierUids = [...new Set(filteredItineraryData.map(item => item.supplierUid))];
      console.log('Supplier UIDs:', supplierUids);
  
      for (const uid of supplierUids) {
        const supplierItems = filteredItineraryData.filter(item => item.supplierUid === uid);
        console.log('Supplier Items:', supplierItems);
  
        if (supplierItems.length > 0) {
          const supplierRequestRef = doc(collection(db, 'supplierRequests'));
          const supplierRequestData = {
            itineraryUid: itineraryUid,
            uid: supplierRequestRef.id,
            properties: supplierItems,
            expired: false,
            sent: false
          };
      
          // Only add creatioBookingUid if it exists
          if (creatioBookingUid) {
            supplierRequestData.creatioBookingUid = creatioBookingUid;
          }
      
          await setDoc(supplierRequestRef, supplierRequestData);
          console.log('Supplier request created with UID:', supplierRequestRef.id);
        }
      }
  
      console.log('Supplier requests created successfully');
    } catch (error) {
      console.error('Error creating supplier requests:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };