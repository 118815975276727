import 'react-datepicker/dist/react-datepicker.css';
import 'flatpickr/dist/flatpickr.css';
import '../css/datePickerStyles.css';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import { AiFillFilePdf } from 'react-icons/ai'; // For PDF icon
import { BiArrowBack } from 'react-icons/bi';
import ButtonOutlineSmall from './Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from './Buttons/ButtonPrimarySmall';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { CustomDateInput } from '../functions/datePickerFunctions';
import DatePicker from 'react-datepicker';
import { FaEdit } from 'react-icons/fa';
import { FaRegTrashCan } from 'react-icons/fa6';
import { IoMdCheckmark } from 'react-icons/io';
import Select from 'react-select';
import { Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { createNewGuests } from '../functions/viewItineraryFunctions';
import { getMetadata } from 'firebase/storage';
import { isValid } from 'date-fns';
import moment from 'moment';
import { storage } from '../db/firebase.config';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

function GuestsSlideOut({ isOpen, onClose, guests, setGuests, booking, setIsLoading, countries, flyingDoctor }) {
  // reset variables

  // function to handle invalid dates "01/01/0001" which were added to database on import from Creatio
  const handleInvalidDate = (dateString) => {
    if (!dateString || dateString === '01/01/0001' || dateString === '0001-01-01' || !isValid(new Date(dateString))) {
      return null;
    }
    return moment(dateString, ['YYYY-MM-DD', 'DD/MM/YYYY']).toDate();
  };

  const itineraryStartDate = booking.dateStart;

  const resetForm = useCallback(() => {
    setFirstName(null);
    setMiddleName(null);
    setLastName(null);
    setDateOfBirth(null);
    setAge(null);
    setTitle(null);
    setSex(null);
    setNationality(null);
    setDietaryRequirements(null);
    setDietaryRequirementsInfo(null);
    setHealthRequirements(null);
    setHealthRequirementsInfo(null);
    setOtherInterestsInfo(null);
    setIsLeadPassenger(null);
    setPassportNumber(null);
    setContactDetails(null);
    setPassportImage(null);
    setPassportImageURL(null);
    setGuestEdit(null);
    setEdit(false);
    setAdd(false);
    setIsPassportImageDeleted(false);
    setIsloading(false);
  });

  const getInitialGuestEdit = () => ({
    uuid: uuidv4(),
    age: 99,
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: null,
    nationality: null,
    passportNumber: null,
    isLeadPassenger: null,
    sex: null,
    dietaryRequirements: null,
    dietaryRequirementsInfo: '',
    healthRequirements: null,
    healthRequirementsInfo: '',
    otherInterestsInfo: '',
    contactDetails: '',
    passportImageURL: null,
    used: false,
  });

  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [guestEdit, setGuestEdit] = useState(null);

  // Form values
  const [firstName, setFirstName] = useState(null);
  const [middleName, setMiddleName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [age, setAge] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [dietaryRequirements, setDietaryRequirements] = useState(null);
  const [dietaryRequirementsInfo, setDietaryRequirementsInfo] = useState(null);
  const [healthRequirements, setHealthRequirements] = useState(null);
  const [healthRequirementsInfo, setHealthRequirementsInfo] = useState(null);
  const [otherInterestsInfo, setOtherInterestsInfo] = useState(null);
  const [isLeadPassenger, setIsLeadPassenger] = useState(false);
  const [passportNumber, setPassportNumber] = useState(null);
  const [title, setTitle] = useState(null);
  const [sex, setSex] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [passportImage, setPassportImage] = useState(null);
  const [passportImageURL, setPassportImageURL] = useState(null);

  const [sortedBy, setSortedBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [isloading, setIsloading] = useState(false);
  const [isPassportImageDeleted, setIsPassportImageDeleted] = useState(false);

  useEffect(() => {
    if (guestEdit !== null) {
      setFirstName(guestEdit.firstName);
      setMiddleName(guestEdit.middleName);
      setLastName(guestEdit.lastName);
      setDateOfBirth(guestEdit.dateOfBirth);
      setAge(guestEdit.age);
      setSex(guestEdit.sex);
      setNationality(guestEdit.nationality);
      setDietaryRequirements(guestEdit.dietaryRequirements);
      setDietaryRequirementsInfo(guestEdit.dietaryRequirementsInfo);
      setHealthRequirements(guestEdit.healthRequirements);
      setHealthRequirementsInfo(guestEdit.healthRequirementsInfo);
      setOtherInterestsInfo(guestEdit.otherInterestsInfo);
      setPassportNumber(guestEdit.passportNumber);
      setIsLeadPassenger(guestEdit.isLeadPassenger);
      setTitle(guestEdit.title);
      setContactDetails(guestEdit.contactDetails);
      setPassportImageURL(guestEdit.passportImageURL);
    }
  }, [guestEdit]);

  const requiredData = async () => {
    if (!firstName) {
      //toast.error('First Name is required');
      return false;
    } else if (!lastName) {
      //toast.error('Last Name is required');
      return false;
    } else if (!dateOfBirth) {
      //toast.error('Date of Birth is required');
      return false;
    } else if (dietaryRequirements === true && !dietaryRequirementsInfo) {
      toast.error('Dietary Requirements Info is required');
      return false;
    } else if (healthRequirements === true && !healthRequirementsInfo) {
      toast.error('Health Requirements Info is required');
      return false;
    } else if (dietaryRequirements === null) {
      toast.error('Dietary Requirements is required');
      return false;
    } else if (healthRequirements === null) {
      toast.error('Health Requirements is required');
      return false;
    } else if (flyingDoctor && (!nationality || nationality === 'Select')) {
      //toast.error('Nationality is required');
      return false;
    } else if (flyingDoctor && !passportNumber) {
      //toast.error('Passport Number is required');
      return false;
    } else if (flyingDoctor && (!sex || sex === 'Select')) {
      //toast.error('Sex is required');
      return false;
    } else {
      return true;
    }
  };

  async function uploadPassportImage(guestUuid) {
    if (passportImage) {
      // Check if the image size exceeds 10MB (10 * 1024 * 1024 bytes)
      if (passportImage.size > 10 * 1024 * 1024) {
        toast.error('File size should be less than 10MB');
        return null;
      }

      // Proceed with uploading the image
      // setIsUploading(true); // Uncomment if using isUploading state
      //const storageRef = ref(storage, `passports/${guestUuid}`);
      const storageRef = ref(storage, `bookingFiles/${booking.uid}/passports/${guestUuid}`);
      try {
        await uploadBytes(storageRef, passportImage);
        const downloadURL = await getDownloadURL(storageRef);
        // setIsUploading(false); // Uncomment if using isUploading state
        return downloadURL;
      } catch (error) {
        console.error('Error uploading passport image:', error);
        toast.error('Failed to upload passport image');
        // setIsUploading(false); // Uncomment if using isUploading state
        return null;
      }
    } else {
      // No new image to upload
      return guestEdit.passportImageURL;
    }
  }


  const deletePassportImage = (guestUuid) => {
    // Update local state to remove the image from display
    setPassportImage(null);
    setPassportImageURL(null);

    // Do NOT set guestEdit.passportImageURL to null here
    if (guestEdit) {
      setGuestEdit({ ...guestEdit, passportImageURL: null });
    }

    // Set the flag indicating the image is marked for deletion
    setIsPassportImageDeleted(true);
  };

  const deleteImageFromFirebaseStorage = async (guestUuid) => {
    //const storageRef = ref(storage, `passports/${guestUuid}`);
    const storageRef = ref(storage, `bookingFiles/${booking.uid}/passports/${guestUuid}`);
    try {
      await deleteObject(storageRef);
      toast.success('Passport image deleted successfully');
    } catch (error) {
      console.error('Error deleting passport image:', error);
      toast.error('Failed to delete passport image');
    }
  };


  const [isPDF, setIsPDF] = useState(false);

  useEffect(() => {
    if (passportImage) {
      // User has uploaded a new file
      setIsPDF(passportImage.type === 'application/pdf');
    } else if (passportImageURL && guestEdit?.uuid) {
      // No new file uploaded; fetch metadata for existing file
      //const fileRef = ref(storage, `passports/${guestEdit.uuid}`);
      const fileRef = ref(storage, `bookingFiles/${booking.uid}/passports/${guestEdit.uuid}`);
      getMetadata(fileRef)
        .then((metadata) => {
          setIsPDF(metadata.contentType === 'application/pdf');
        })
        .catch((error) => {
          console.error('Error fetching metadata:', error);
        });
    } else {
      // No file available
      setIsPDF(false);
    }
  }, [passportImage, passportImageURL, guestEdit, booking]);





  const saveGuest = async () => {
    setIsloading(true);
    const isValidData = await requiredData();
    // if (!isValidData) {
    //   return;
    // }
    if (edit) {
      let updatedPassportImageURL = guestEdit.passportImageURL;

      if (isPassportImageDeleted) {
        // Delete image from Firebase Storage
        await deleteImageFromFirebaseStorage(guestEdit.uuid);
        updatedPassportImageURL = ''; // Set the updated URL to null
      } else if (passportImage) {
        // Upload new passport image
        updatedPassportImageURL = await uploadPassportImage(guestEdit.uuid);
      }

      //toast.success('Passport image updated successfully', updatedPassportImageURL);

      const updatedGuests = guests.map((guest) => {
        if (guest.uuid === guestEdit.uuid) {
          //toast.success('Edit guest', updatedPassportImageURL);

          return {
            ...guest,
            firstName,
            middleName,
            lastName,
            dateOfBirth,
            age,
            title,
            sex,
            nationality,
            dietaryRequirements,
            dietaryRequirementsInfo,
            healthRequirements,
            healthRequirementsInfo,
            isLeadPassenger,
            passportNumber,
            otherInterestsInfo,
            contactDetails,
            passportImageURL: updatedPassportImageURL, // Set updated URL
          };
        } else {
          return guest;
        }
      });

      setGuests(updatedGuests);
      await createNewGuests(booking, updatedGuests, setIsLoading, guestEdit.uuid, false);
      setIsloading(false);
      setGuestEdit(null);
      // Reset isPassportImageDeleted flag
      setIsPassportImageDeleted(false);
    }
  };

  // const saveGuest = async () => {
  //   const isValidData = await requiredData();
  //   // if (!isValidData) {
  //   //   return;
  //   // }
  //   if (edit) {
  //     const passportImageURL = await uploadPassportImage(guestEdit.uuid);

  //     const updatedGuests = guests.map((guest) => {
  //       if (guest.uuid === guestEdit.uuid) {
  //         return {
  //           ...guest,
  //           firstName: firstName,
  //           middleName: middleName,
  //           lastName: lastName,
  //           dateOfBirth: dateOfBirth,
  //           age: age,
  //           title: title,
  //           sex: sex,
  //           nationality: nationality,
  //           dietaryRequirements: dietaryRequirements,
  //           dietaryRequirementsInfo: dietaryRequirementsInfo,
  //           healthRequirements: healthRequirements,
  //           healthRequirementsInfo: healthRequirementsInfo,
  //           isLeadPassenger: isLeadPassenger,
  //           passportNumber: passportNumber,
  //           otherInterestsInfo: otherInterestsInfo,
  //           contactDetails: contactDetails,
  //           passportImageURL: passportImageURL,
  //         };
  //       } else {
  //         return guest;
  //       }
  //     });

  //     setGuests(updatedGuests);
  //     await createNewGuests(booking, updatedGuests, setIsLoading, guestEdit.uuid, false);
  //     setGuestEdit(null);
  //   }
  // };

  const addGuest = async () => {
    setIsloading(true);
    const isValidData = await requiredData();
    // if (!isValidData) {
    //   return;
    // }
    if (add) {
      const guestUuid = uuidv4();
      const passportImageURL = await uploadPassportImage(guestUuid);

      const newGuest = {
        uuid: guestUuid,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        age: age,
        title: title,
        sex: sex,
        nationality: nationality,
        dietaryRequirements: dietaryRequirements,
        dietaryRequirementsInfo: dietaryRequirementsInfo,
        healthRequirements: healthRequirements,
        healthRequirementsInfo: healthRequirementsInfo,
        isLeadPassenger: isLeadPassenger,
        passportNumber: passportNumber,
        otherInterestsInfo: otherInterestsInfo,
        used: false,
        contactDetails: contactDetails,
        passportImageURL: passportImageURL,
      };

      const tempGuests = [...guests, newGuest];
      setGuests(tempGuests);
      await createNewGuests(booking, tempGuests, setIsLoading, guestUuid, false);
      setIsloading(false);
      setGuestEdit(null);
    }
  };

  const deleteGuest = async (uuid) => {
    setIsloading(true);
    const guestToDelete = guests.find((guest) => guest.uuid === uuid);
    if (guestToDelete.passportImageURL) {
      await deletePassportImage(uuid);
    }

    const updatedGuests = guests.filter((guest) => guest.uuid !== uuid);

    setGuests(updatedGuests);
    await createNewGuests(booking, updatedGuests, setIsLoading, uuid, true);
    setIsloading(false);
    setGuestEdit(null);
  };

  const handleLeadPassengerChange = (value) => {
    if (value === true) {
      const isLeadPassenger = guests.some((guest) => guest.isLeadPassenger === true && guest.uuid !== guestEdit.uuid);
      if (isLeadPassenger) {
        toast.error('Only one guest can be the lead passenger, change the lead passenger and try again');
        return;
      }
    }
    setIsLeadPassenger(value);
  };

  const sortGuests = (field) => {
    const sortedGuests = [...guests].sort((a, b) => {
      if (a[field] < b[field]) return sortOrder === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
    setGuests(sortedGuests);
    setSortedBy(field);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const resetFormClose = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  // Dropzone setup
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        toast.error('File size should be less than 10MB');
        return;
      }
      setPassportImage(file);
      setPassportImageURL(URL.createObjectURL(file));
      // Set `isPDF` based on the file type
      setIsPDF(file.type === 'application/pdf');
    }
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
    multiple: false,
  });


  // Determine if the file is a PDF
  // const isPDF =
  //   (passportImage && passportImage.type === 'application/pdf') ||
  //   (!passportImage && passportImageURL && passportImageURL.endsWith('.pdf')) ||
  //   (!passportImage && passportImageURL && passportImageURL.type === 'application/pdf');

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-0 flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="flex-1 h-full overflow-y-auto">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto">
                        <div className="bg-white px-4 pt-6 sm:px-6">
                          <div className="flex items-center ">
                            {' '}
                            <div className="ml-0 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative rounded-md bg-white text-black hover:text-black"
                                onClick={() => {
                                  if (edit || add) {
                                    setEdit(false);
                                    setAdd(false);
                                    resetForm();
                                  } else {
                                    setEdit(false);
                                    setAdd(false);
                                    resetFormClose();
                                  }
                                }}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>{' '}
                          <Dialog.Title className={`mt-5 text-2xl font-base font-semibold leading-6 `}>
                            <span className={`${edit || add ? 'text-cyan-600' : 'text-black'}`}>Guests</span> {edit && <span className={`text-black`}>{`>`} Edit Guest {guestEdit.isGuide && '(Guide)'}</span>}{' '}
                            {add && <span className={`text-black`}>{`>`} Add Guest</span>}
                          </Dialog.Title>
                        </div>

                        <div className="mb-5 mx-0">
                          {/* LIST/EDIT/ADD OF GUESTS -> START */}
                          {!edit && !add ? (
                            <div className="px-6 sm:px-6 lg:px-6">
                              {/* LIST OF GUESTS -> START */}
                              <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                  <p className="mt-5 text-sm neutral-500">
                                    Guests listed below are included in your itinerary. The lead passenger is highlighted in <span className={`text-green-700`}>green.</span>
                                    <span className={``}></span>
                                  </p>
                                </div>
                              </div>
                              <div className="mt-8 flow-root">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div className="overflow-hidden">
                                      <table className="min-w-full no-outer-borders" style={{ border: 'none' }}>
                                        <thead>
                                          <tr>
                                            <th scope="col" className="py-4 pl-6 pr-3 text-left text-sm font-normal text-gray-900 min-w-52">
                                              <div className="group inline-flex">
                                                Name
                                                <span
                                                  className={`ml-2 flex-none text-gray-900 ${sortedBy === 'firstName' ? 'rounded bg-gray-100' : 'invisible group-hover:visible group-focus:visible'}`}
                                                >
                                                  <ChevronDownIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" onClick={() => sortGuests('firstName')} />
                                                </span>
                                              </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
                                              <div className="group inline-flex">
                                                Age
                                                <span className={`ml-2 flex-none text-gray-900 ${sortedBy === 'age' ? 'rounded bg-gray-100' : 'invisible group-hover:visible group-focus:visible'}`}>
                                                  <ChevronDownIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" onClick={() => sortGuests('age')} />
                                                </span>
                                              </div>
                                            </th>

                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900 min-w-40">
                                              <div className="group inline-flex"></div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                              {''}
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                                          </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                          {guests.map((guest) => (
                                            <tr key={uuidv4()} className="hover:bg-gray-50">
                                              <td className={`py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ${guest.isLeadPassenger ? 'text-green-700 font-semibold' : ''}`}>
                                                <div className="break-words min-w-72">
                                                  {guest.firstName === '' && guest.lastName === '' ? 'Please add name...'  : `${guest.firstName} ${guest.middleName} ${guest.lastName}`.trim()} {guest.isGuide && '(Guide)'}
                                                </div>
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{guest.age}</td>

                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {guest.detailsComplete ? (
                                                  <div className="flex items-center">
                                                    <IoMdCheckmark size={20} className="text-teal-600 mr-1" />
                                                    <div className="text-teal-600">Confirmed</div>
                                                  </div>
                                                ) : (
                                                  <div className="text-stone-300">Awaiting information</div>
                                                )}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {guest.used ? (
                                                  <FaRegTrashCan size={20} />
                                                ) : (
                                                  <FaRegTrashCan
                                                    size={20}
                                                    className="cursor-pointer text-red-700"
                                                    onClick={() => {
                                                      deleteGuest(guest.uuid);
                                                    }}
                                                  />
                                                )}
                                              </td>
                                              <td
                                                className="cursor-pointer relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-nomadEvergreen-700"
                                                onClick={() => {
                                                  setGuestEdit(guest);
                                                  setEdit(true);
                                                }}
                                              >
                                                <FaEdit
                                                  size={20}
                                                  className="text-nomadEvergreen-700 cursor-pointer"
                                                  onClick={() => {
                                                    setGuestEdit(guest);
                                                    setEdit(true);
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-10 sm:ml-0 sm:mt-10 sm:flex-none">
                                {/* <ButtonPrimarySmall
                                  onClick={() => {
                                    setGuestEdit(getInitialGuestEdit());
                                    setAdd(true);
                                  }}
                                  color="dark"
                                  text="+ Add Guest"
                                /> */}
                              </div>
                            </div>
                          ) : (
                            <div className="mx-7 mt-5">
                              <div className="space-y-5">
                                {/* Name info */}

                                <p className="mb-5 text-sm leading-6 text-gray-600">Enter details.</p>
                                <div className="bg-neutral-50 rounded-lg p-5">
                                  <p className="mb-5 text-sm leading-6 text-neutral-500">Basic info</p>
                                  <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12">
                                    {/* First Name */}
                                    <div className="sm:col-span-4">
                                      <label htmlFor="first-name" className="block text-xs font-medium leading-6 text-neutral-500">
                                        First name <span className="text-red-500">*</span>
                                      </label>
                                      <div className="mt-1">
                                        <input
                                          placeholder="First Name"
                                          type="text"
                                          name="first-name"
                                          id="first-name"
                                          value={firstName !== null ? firstName : guestEdit.firstName || ''}
                                          onChange={(e) => setFirstName(e.target.value)}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                      <label htmlFor="middle-name" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Middle name(s)
                                      </label>
                                      <div className="mt-1">
                                        <input
                                          placeholder="Middle Name"
                                          type="text"
                                          name="middle-name"
                                          id="middle-name"
                                          autoComplete="second-name"
                                          value={middleName !== null ? middleName : guestEdit.middleName || ''}
                                          onChange={(e) => setMiddleName(e.target.value)}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                      <label htmlFor="last-name" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Last name <span className="text-red-500">*</span>
                                      </label>
                                      <div className="mt-1">
                                        <input
                                          placeholder="Last Name"
                                          type="text"
                                          name="last-name"
                                          id="last-name"
                                          autoComplete="family-name"
                                          value={lastName !== null ? lastName : guestEdit.lastName || ''}
                                          onChange={(e) => setLastName(e.target.value)}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                      <label htmlFor="dateOfBirth" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Date of birth <span className="text-red-500">*</span>
                                      </label>
                                      <div className="mt-1">
                                        <DatePicker
                                          id="dateOfBirth"
                                          selected={handleInvalidDate(dateOfBirth || guestEdit.dateOfBirth)}
                                          onChange={(date) => {
                                            // Age is calculated based on itinerary start date
                                            const itineraryStartMoment = moment(itineraryStartDate);
                                            const selectedAge = itineraryStartMoment.diff(date, 'years');
                                            const formattedDate = moment(date).format('YYYY-MM-DD');
                                            setDateOfBirth(formattedDate);
                                            setAge(selectedAge);
                                          }}
                                          dateFormat="dd MMM yyyy"
                                          maxDate={new Date()}
                                          minDate={moment(itineraryStartDate).subtract(99, 'years').toDate()}
                                          showYearDropdown
                                          scrollableYearDropdown
                                          yearDropdownItemNumber={100}
                                          customInput={<CustomDateInput placeholderText="Date of Birth" />}
                                          className="form-control"
                                          calendarClassName="custom-datepicker datepicker-padding"
                                          showPopperArrow={false}
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                      <div className="sm:grid sm:grid-cols-12 sm:gap-4">
                                        <div className="sm:col-span-4">
                                          {/* AGE */}
                                          <label htmlFor="age" className="block text-xs font-medium leading-6 text-neutral-500">
                                            Age
                                          </label>
                                          <div className="mt-1">
                                            <input
                                              placeholder="Age"
                                              type="text"
                                              id="age"
                                              name="age"
                                              value={age !== null ? age : guestEdit.age}
                                              disabled
                                              className="block w-full h-9 rounded-md border-gray-300 bg-gray-100 py-0 text-gray-900 placeholder:text-gray-500 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                          </div>
                                        </div>

                                        <div className="sm:col-span-8">
                                          {/* TITLE */}
                                          <label htmlFor="title" className="block text-xs font-medium leading-6 text-neutral-500">
                                            Title
                                          </label>
                                          <div className="mt-1">
                                            <Select
                                              name="title"
                                              id="title"
                                              value={
                                                title
                                                  ? {
                                                      value: title,
                                                      label: title,
                                                    }
                                                  : guestEdit.title
                                                  ? {
                                                      value: guestEdit.title,
                                                      label: guestEdit.title,
                                                    }
                                                  : {
                                                      value: 'Select',
                                                      label: 'Select',
                                                    }
                                              }
                                              onChange={(option) => {
                                                setTitle(option.value);
                                              }}
                                              options={[
                                                {
                                                  value: 'Select',
                                                  label: 'Select',
                                                  isDisabled: true,
                                                },
                                                { value: 'Mr', label: 'Mr' },
                                                { value: 'Mrs', label: 'Mrs' },
                                                { value: 'Ms', label: 'Ms' },
                                                { value: 'Miss', label: 'Miss' },
                                                { value: 'Dr', label: 'Dr' },
                                              ]}
                                              className="block w-full rounded-md border-0 py-0 text-gray-900 placeholder:text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              classNamePrefix="select"
                                              styles={{
                                                singleValue: (provided, state) => ({
                                                  ...provided,
                                                  color: state.data.value !== 'Select' ? 'rgb(17 24 39)' : 'rgb(107 114 128)',
                                                }),
                                                placeholder: (provided, state) => ({
                                                  ...provided,
                                                  color: state.data.value !== 'Select' ? 'rgb(17 24 39)' : 'rgb(107 114 128)',
                                                }),
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label htmlFor="isLeadPassenger" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Lead Passenger
                                      </label>
                                      <div className="mt-1">
                                        <Switch
                                          checked={isLeadPassenger}
                                          onChange={handleLeadPassengerChange}
                                          className="ml-2 group relative inline-flex h-8 w-16 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-[#2A99A3] focus:ring-offset-2"
                                        >
                                          <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-full bg-neutral-50" />
                                          <span
                                            aria-hidden="true"
                                            className={`${
                                              isLeadPassenger ? 'bg-[#2A99A3]' : 'bg-gray-200'
                                            } pointer-events-none absolute mx-auto h-7 w-14 rounded-full transition-colors duration-200 ease-in-out`}
                                          />
                                          <span
                                            aria-hidden="true"
                                            className={`${
                                              isLeadPassenger ? 'translate-x-8' : 'translate-x-0'
                                            } pointer-events-none absolute left-0 inline-block h-8 w-8 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
                                          />
                                        </Switch>
                                      </div>
                                    </div>

                                    <div className="sm:col-span-8 mt-2">
                                      <p className="text-xs text-neutral-500 italic">Age is calculated based on the date of travel</p>
                                    </div>

                                    <div className="sm:col-span-2"></div>

                                    {/* Contact Details */}
                                    <div className="sm:col-span-12">
                                      <label htmlFor="contactDetails" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Contact Details
                                      </label>
                                      <div className="mt-1">
                                        <input
                                          placeholder="Contact Details"
                                          type="text"
                                          name="contactDetails"
                                          id="contactDetails"
                                          value={contactDetails !== null ? contactDetails : guestEdit.contactDetails || ''}
                                          onChange={(e) => setContactDetails(e.target.value)}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="bg-neutral-50 rounded-lg p-5">
                                  <p className="mb-5 text-sm leading-6 text-neutral-500">Guest Requirements</p>
                                  <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                      <label htmlFor="dietaryRequirements" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Dietary Requirements <span className="text-red-500">*</span>
                                      </label>
                                      <div className="mt-1">
                                        <Select
                                          name="dietaryRequirements"
                                          id="dietaryRequirements"
                                          value={
                                            dietaryRequirements !== null
                                              ? {
                                                  value: dietaryRequirements,
                                                  label: dietaryRequirements === true ? 'Yes' : 'No',
                                                }
                                              : guestEdit.dietaryRequirements !== null
                                              ? {
                                                  value: guestEdit.dietaryRequirements,
                                                  label: guestEdit.dietaryRequirements === true ? 'Yes' : 'No',
                                                }
                                              : {
                                                  value: 'Select',
                                                  label: 'Select',
                                                }
                                          }
                                          onChange={(option) => {
                                            setDietaryRequirements(option.value);
                                            if (option.value === false) {
                                              setDietaryRequirementsInfo(null);
                                            } else if (option.value === true) {
                                              setDietaryRequirementsInfo(guestEdit.dietaryRequirementsInfo !== null ? guestEdit.dietaryRequirementsInfo : null);
                                            }
                                          }}
                                          options={[
                                            {
                                              value: 'Select',
                                              label: 'Select',
                                              isDisabled: true,
                                            },
                                            { value: true, label: 'Yes' },
                                            { value: false, label: 'No' },
                                          ]}
                                          className="block w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          classNamePrefix="select"
                                          styles={{
                                            singleValue: (provided, state) => ({
                                              ...provided,
                                              color: state.data.value !== 'Select' ? 'rgb(17 24 39)' : 'rgb(107 114 128)',
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              color: state.data.value !== 'Select' ? 'rgb(17 24 39)' : 'rgb(107 114 128)',
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                      <label htmlFor="dietaryRequirementsInfo" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Dietary Requirements Info {dietaryRequirements && <span className="text-red-500">*</span>}
                                      </label>
                                      <div className="mt-1">
                                        <textarea
                                          placeholder="Dietary Requirements Info"
                                          name="dietaryRequirementsInfo"
                                          id="dietaryRequirementsInfo"
                                          value={
                                            dietaryRequirementsInfo !== null
                                              ? dietaryRequirements === true
                                                ? dietaryRequirementsInfo
                                                : ''
                                              : dietaryRequirements === true
                                              ? guestEdit.dietaryRequirementsInfo
                                              : ''
                                          }
                                          onChange={(e) => setDietaryRequirementsInfo(e.target.value)}
                                          rows="1"
                                          disabled={!dietaryRequirements}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 placeholder:text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        ></textarea>
                                      </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                      <label htmlFor="healthRequirements" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Health Requirements <span className="text-red-500">*</span>
                                      </label>
                                      <div className="mt-1">
                                        <Select
                                          name="healthRequirements"
                                          id="healthRequirements"
                                          value={
                                            healthRequirements !== null
                                              ? {
                                                  value: healthRequirements,
                                                  label: healthRequirements === true ? 'Yes' : 'No',
                                                }
                                              : guestEdit.healthRequirements !== null
                                              ? {
                                                  value: guestEdit.healthRequirements,
                                                  label: guestEdit.healthRequirements === true ? 'Yes' : 'No',
                                                }
                                              : {
                                                  value: 'Select',
                                                  label: 'Select',
                                                }
                                          }
                                          onChange={(option) => {
                                            setHealthRequirements(option.value);
                                            if (option.value === false) {
                                              setHealthRequirementsInfo(null);
                                            } else if (option.value === true) {
                                              setHealthRequirementsInfo(guestEdit.healthRequirementsInfo !== null ? guestEdit.healthRequirementsInfo : null);
                                            }
                                          }}
                                          options={[
                                            {
                                              value: 'Select',
                                              label: 'Select',
                                              isDisabled: true,
                                            },
                                            { value: true, label: 'Yes' },
                                            { value: false, label: 'No' },
                                          ]}
                                          className="block w-full rounded-md border-0 py-0 text-gray-900 placeholder:text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          classNamePrefix="select"
                                          styles={{
                                            singleValue: (provided, state) => ({
                                              ...provided,
                                              color: state.data.value !== 'Select' ? 'rgb(17 24 39)' : 'rgb(107 114 128)',
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              color: state.data.value !== 'Select' ? 'rgb(17 24 39)' : 'rgb(107 114 128)',
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                      <label htmlFor="healthRequirementsInfo" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Health Requirements Info {healthRequirements && <span className="text-red-500">*</span>}
                                      </label>
                                      <div className="mt-1">
                                        <textarea
                                          placeholder="Health Requirements Info"
                                          name="healthRequirementsInfo"
                                          id="healthRequirementsInfo"
                                          value={
                                            healthRequirementsInfo !== null
                                              ? healthRequirements === true
                                                ? healthRequirementsInfo
                                                : ''
                                              : healthRequirements === true
                                              ? guestEdit.healthRequirementsInfo
                                              : ''
                                          }
                                          onChange={(e) => setHealthRequirementsInfo(e.target.value)}
                                          rows="1"
                                          disabled={!healthRequirements}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 placeholder:text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        ></textarea>
                                      </div>
                                    </div>

                                    <div className="sm:col-span-6">
                                      <label htmlFor="otherInterestsInfo" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Other interests and special requests
                                      </label>
                                      <div className="mt-1">
                                        <textarea
                                          placeholder="Other interests and special requests info"
                                          name="otherInterestsInfo"
                                          id="otherInterestsInfo"
                                          value={otherInterestsInfo !== null ? otherInterestsInfo : guestEdit.otherInterestsInfo}
                                          onChange={(e) => setOtherInterestsInfo(e.target.value)}
                                          rows="1"
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="bg-neutral-50 rounded-lg p-5 mt-5">
                                  <p className="mb-5 text-sm leading-6 text-neutral-500">Passport Info</p>
                                  <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                      <label htmlFor="nationality" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Nationality {flyingDoctor && <span className="text-red-500">*</span>}
                                      </label>
                                      <div className="mt-1">
                                        <Select
                                          name="nationality"
                                          id="nationality"
                                          value={
                                            nationality
                                              ? {
                                                  value: nationality,
                                                  label: countries.find((country) => country.uid === nationality)?.name,
                                                }
                                              : guestEdit.nationality
                                              ? {
                                                  value: guestEdit.nationality,
                                                  label: countries.find((country) => country.uid === guestEdit.nationality)?.name,
                                                }
                                              : {
                                                  value: 'Select',
                                                  label: 'Select',
                                                }
                                          }
                                          onChange={(option) => setNationality(option.value)}
                                          options={[
                                            {
                                              value: 'Select',
                                              label: 'Select',
                                              isDisabled: true,
                                            },
                                            ...countries
                                              .sort((a, b) => a.name.localeCompare(b.name))
                                              .map((country) => ({
                                                value: country.uid,
                                                label: country.name,
                                              })),
                                          ]}
                                          className="block w-full rounded-md border-0 py-0 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          classNamePrefix="select"
                                          styles={{
                                            singleValue: (provided, state) => ({
                                              ...provided,
                                              color: state.data.value !== 'Select' ? 'rgb(17 24 39)' : 'rgb(107 114 128)',
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              color: state.data.value !== 'Select' ? 'rgb(17 24 39)' : 'rgb(107 114 128)',
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                      <label htmlFor="passportNumber" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Passport Number {flyingDoctor && <span className="text-red-500">*</span>}
                                      </label>
                                      <div className="mt-1">
                                        <input
                                          placeholder="Passport Number"
                                          type="text"
                                          name="passportNumber"
                                          id="passportNumber"
                                          value={passportNumber !== null ? passportNumber : guestEdit.passportNumber || ''}
                                          onChange={(e) => setPassportNumber(e.target.value)}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                      <label htmlFor="sex" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Sex {flyingDoctor && <span className="text-red-500">*</span>}
                                      </label>
                                      <div className="mt-1">
                                        <Select
                                          name="sex"
                                          id="sex"
                                          value={
                                            sex
                                              ? { value: sex, label: sex }
                                              : guestEdit.sex
                                              ? {
                                                  value: guestEdit.sex,
                                                  label: guestEdit.sex,
                                                }
                                              : { value: 'Select', label: 'Select' }
                                          }
                                          onChange={(option) => {
                                            setSex(option.value);
                                          }}
                                          options={[
                                            {
                                              value: 'Select',
                                              label: 'Select',
                                              isDisabled: true,
                                            },
                                            { value: 'Male', label: 'Male' },
                                            { value: 'Female', label: 'Female' },
                                          ]}
                                          className="block w-full rounded-md border-0 py-0 text-gray-900 placeholder:text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          classNamePrefix="select"
                                          styles={{
                                            singleValue: (provided, state) => ({
                                              ...provided,
                                              color: state.data.value !== 'Select' ? 'rgb(17 24 39)' : 'rgb(107 114 128)',
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              color: state.data.value !== 'Select' ? 'rgb(17 24 39)' : 'rgb(107 114 128)',
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>

                                    {/* Passport Image Upload */}
                                    <div className="sm:col-span-6">
                                      <label htmlFor="passportImage" className="block text-xs font-medium leading-6 text-neutral-500">
                                        Passport Image <span className="text-red-500">*</span>
                                      </label>
                                      <div
                                        {...getRootProps()}
                                        className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md ${isDragActive ? 'border-indigo-500' : 'border-gray-300'} cursor-pointer`}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="space-y-1 text-center">
                                          {/* {passportImageURL ? (
                                            <img src={passportImageURL} alt="Passport" className="mx-auto h-48" />
                                          ) : (
                                            <>
                                              <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a2 2 0 00-2 2v28a2 2 0 002 2h24a2 2 0 002-2V20l-8-12z" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                              </svg>
                                              <div className="flex text-sm text-gray-600">
                                                <p className="pl-1">Drag & drop your passport image here, or click to select file</p>
                                              </div>
                                              <p className="text-xs text-gray-500">PNG, JPG up to 10MB</p>
                                            </>
                                          )} */}
                                          {passportImageURL ? (
                                            isPDF ? (
                                              // Display PDF icon or preview
                                              <div className="flex flex-col items-center">
                                                {/* Option 1: Display PDF icon */}
                                                <AiFillFilePdf size={48} className="text-red-600 mx-auto" />
                                                <p className="text-sm text-gray-600">PDF Document</p>

                                                {/* Option 2: Preview the first page of the PDF (requires react-pdf) */}
                                                {/* <Document file={passportImageURL}>
                <Page pageNumber={1} width={200} />
              </Document> */}
                                              </div>
                                            ) : (
                                              // Display image preview
                                              <img src={passportImageURL} alt="Passport" className="mx-auto h-48" />
                                            )
                                          ) : (
                                            // Default placeholder when no file is selected
                                            <>
                                              <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a2 2 0 00-2 2v28a2 2 0 002 2h24a2 2 0 002-2V20l-8-12z" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                              </svg>
                                              <div className="flex text-sm text-gray-600">
                                                <p className="pl-1">Drag & drop your passport image or PDF here, or click to select file</p>
                                              </div>
                                              <p className="text-xs text-gray-500">PNG, JPG, PDF up to 10MB</p>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      {passportImageURL && (
                                        <div className="mt-2">
                                          <button
                                            type="button"
                                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200"
                                            onClick={() => deletePassportImage(guestEdit?.uuid)}
                                          >
                                            Delete {isPDF ? 'PDF' : 'Image'}
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* Form input end */}
                              </div>

                              <div className="mt-5 flex items-center justify-end gap-x-6 mb-20">
                                {isloading ? (
                                  <p>Saving...</p>
                                ) : (
                                  <>
                                    <ButtonOutlineSmall
                                      color="dark"
                                      onClick={() => {
                                        setEdit(false);
                                        setAdd(false);
                                        resetForm();
                                      }}
                                      text="Back"
                                      disabled={isloading}
                                    />

                                    <ButtonPrimarySmall
                                      onClick={async () => {
                                        if (edit) {
                                          setIsloading(true);
                                          await saveGuest();
                                          setEdit(false);
                                          setAdd(false);
                                        } else if (add) {
                                          await addGuest();
                                          setEdit(false);
                                          setAdd(false);
                                        }
                                        resetForm();
                                      }}
                                      color="dark"
                                      text="Save"
                                      disabled={isloading}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                          {/* LIST/EDIT/ADD OF GUESTS -> END */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default GuestsSlideOut;
