import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../../partials/Sidebar';
import PropertyContent from '../components/PropertyContent';
import { fetchPropertyData } from '../../functions/propertyFunctions';

function Property() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { uid } = useParams();

  useEffect(() => {
    async function loadPropertyData() {
      try {
        setLoading(true);
        const data = await fetchPropertyData(uid);
        setPropertyData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    if (uid) {
      loadPropertyData();
    }
  }, [uid]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          {loading ? (
            <div className="flex justify-center items-center h-screen">Loading...</div>
          ) : error ? (
            <div className="flex justify-center items-center h-screen">Error: {error.message}</div>
          ) : (
            <PropertyContent propertyData={propertyData} />
          )}
        </main>
      </div>
    </div>
  );
}

export default Property;