import { MdBlock, MdEventAvailable } from 'react-icons/md';
import { addDays, getPropObj } from './generalFunctions';
import { addDoc, collection, doc, getDocs, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { getRooms, removePropertyByUid } from './availabilityFunctions';
import { removeBookingsByPropUid, removeRoomsByPropUid } from './itineraryFunctions';

import { BsQuestionCircle } from 'react-icons/bs';
import axios from 'axios';
import { db } from '../db/firebase.config';
import moment from 'moment';
import { resRequestStock } from './platformFunctions';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

// SORT ROOMS BY TYPE AND DAY ROOM
const sortRoomsByType = (rooms) => {
  const typeOrder = [
    "Exclusive",
    "Room",
    "Guide/Pilot Bed",  // This matches "Tent - Guide/Pilot" in your data
    "Activity",
    "Vehicle"
  ];

  return rooms.sort((a, b) => {
    // First, handle the special case for "Room" type
    if (a.type === "Room" && b.type === "Room") {
      if (a.dayRoom === b.dayRoom) return 0;
      return a.dayRoom ? 1 : -1; // false comes before true
    }

    // Then, proceed with the general type ordering
    const aIndex = typeOrder.indexOf(a.type);
    const bIndex = typeOrder.indexOf(b.type);

    // If both types are in the typeOrder array
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }

    // If only a's type is in the typeOrder array
    if (aIndex !== -1) {
      return -1;
    }

    // If only b's type is in the typeOrder array
    if (bIndex !== -1) {
      return 1;
    }

    // If neither type is in the typeOrder array, maintain their relative order
    return 0;
  });
};

// SORT ROOMS BY TYPE
const sortRoomsByTypeOld = (rooms) => {
  const typeOrder = [
    "Exclusive",
    "Room",
    "Guide/Pilot Bed",
    "Activity",
    "Vehicle"
  ];

  return rooms.sort((a, b) => {
    const aIndex = typeOrder.indexOf(a.type);
    const bIndex = typeOrder.indexOf(b.type);

    // If both types are in the typeOrder array
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }

    // If only a's type is in the typeOrder array
    if (aIndex !== -1) {
      return -1;
    }

    // If only b's type is in the typeOrder array
    if (bIndex !== -1) {
      return 1;
    }

    // If neither type is in the typeOrder array, maintain their relative order
    return 0;
  });
};

// ************************************* // Main get stock function
const getStock = async (
  propIndex,
  dateStart,
  dateEnd,
  properties,
  setProperties,
  rooms,
  setRooms,
  update,
  reCountBookings,
  bookings,
  setBookings,
  dateCount,
  setRefreshRatesEffect,
  bookingsStore,
  editBooking,
  activitiesData,
) => {
  console.log('getStock 1');
  // DATES
  const startDay = dateStart;
  const endDay = dateEnd;

  console.log('GET STOCK startDay: ' + startDay);
  console.log('GET STOCK dateEnd: ' + dateEnd);

  console.log('NEW INFO / getStock/ getPropObj: ' + propIndex);
  console.log('getStock: ' + properties.length);

  // Check if resRequestStock is true and startDay is today or in the future
  let roomJsonNum;
  if (resRequestStock(propIndex, properties) && moment(startDay).isSameOrAfter(moment().startOf('day'))) {
    //console.log('getStock rooms', JSON.stringify(properties[propIndex].rooms, null, 2)); 
    // SORT ROOMS BY TYPE
    properties[propIndex].rooms = sortRoomsByType(properties[propIndex].rooms);
    properties[propIndex].roomLoop = 0;
    properties[propIndex].roomNames = [];
    properties[propIndex].resRequestErrors = false; // Reset errors for this property

    if (update === null) {
      let newData = {
        collapsed: false,
        propUid: Object(properties[propIndex]).uid,
        name: Object(properties[propIndex]).name,
        loading: true,
      };
      roomJsonNum = rooms.length;
      rooms[roomJsonNum] = newData;
    } else {
      roomJsonNum = update;
    }

    const roomPromises = properties[propIndex].rooms.map((room, i) =>
      fetchStockReqRes(
        propIndex,
        i,
        room.id,
        properties[propIndex].rooms.length - 1,
        roomJsonNum,
        startDay,
        endDay,
        dateStart,
        dateEnd,
        properties,
        setProperties,
        rooms,
        setRooms,
        reCountBookings,
        bookings,
        setBookings,
        dateCount,
        setRefreshRatesEffect,
        bookingsStore,
        editBooking,
        activitiesData,
      ),
    );

    await Promise.all(roomPromises);

    // ! STOCK NEW: check if property.platformResRequest.active is true else remove rooms object
    //Filter out rooms where the corresponding property has platformResRequest.active === false
    const filteredRooms = rooms.filter((room) => {
      const property = properties.find((prop) => prop.uid === room.propUid);
      return property && property.platformResRequest.active !== false;
    });

    // Update rooms state if any rooms were filtered out
    if (filteredRooms.length !== rooms.length) {
      setRooms(filteredRooms);
      rooms = filteredRooms;
    }
    // ! end

    // After all rooms have been processed
    buildStockDisplay(propIndex, roomJsonNum, properties, rooms, setRooms, reCountBookings, bookings, setBookings, dateCount, setRefreshRatesEffect, bookingsStore, editBooking);
  } else {
    if (properties[propIndex].bookedDirect) {
      console.log('getStock 2');
      return;
    } else {
      console.log('getStock 3');
      return;
      //return toast.error(`Availability not available for ${properties[propIndex].name}`);
    }
    // if (properties[propIndex].bookedDirect) {
    //   console.log('getStock 2');
    // } else {
    //   console.log('getStock 3');
    //   // Uncomment the following line if you want to show an error toast
    //   // return toast.error(`Availability not available for ${properties[propIndex].name}`);
    // }

    // // Remove the room from the array based on matching propUid
    // rooms = rooms.filter((room) => room.propUid !== properties[propIndex].uid);
    // setRooms(rooms);
    // // If you need to do something with the removed room, you can use find() before filter():
    // // const removedRoom = rooms.find(room => room.propUid === properties[propIndex].uid);
    // // if (removedRoom) {
    // //   console.log('Removed room:', removedRoom);
    // // }

    // return;
  }
};

const getStockOld = async (
  propIndex,
  dateStart,
  dateEnd,
  properties,
  setProperties,
  rooms,
  setRooms,
  update,
  reCountBookings,
  bookings,
  setBookings,
  dateCount,
  setRefreshRatesEffect,
  bookingsStore,
  editBooking,
  activitiesData,
) => {
  // Only call for resRequest

  // DATES
  const startDay = dateStart;
  const endDay = dateEnd;

  console.log('GET STOCK startDay: ' + startDay);
  console.log('GET STOCK dateEnd: ' + dateEnd);

  // console.log(properties[num].platformResRequest.active);
  // console.log(properties[num].platformResRequest.id);
  //const num = getPropObj(propUid, properties);
  console.log('NEW INFO / getStock/ getPropObj: ' + propIndex);
  console.log('getStock: ' + properties.length);
  // Check if resRequestStock is true and startDay is today or in the future
  if (resRequestStock(propIndex, properties) && moment(startDay).isSameOrAfter(moment().startOf('day'))) {
    //if (resRequestStock(propIndex, properties)) {
    // ********************************************
    //const roomLength = null;
    if (update === null) {
      let newData = {
        collapsed: false,
        propUid: Object(properties[propIndex]).uid,
        name: Object(properties[propIndex]).name,
        loading: true,
      };
      var roomLength = rooms.length;
      rooms[roomLength] = newData;
    } else {
      var roomLength = update;
    }
    // ********************************************

    // Original code start
    properties[propIndex].roomLoop = 0;
    properties[propIndex].roomNames = [];
    for (let i = 0; i < properties[propIndex].rooms.length; i++) {
      const loopLength = properties[propIndex].rooms.length - 1;
      var roomNum = i;
      var roomId = properties[propIndex].rooms[i].id;

      fetchStockReqRes(
        propIndex,
        roomNum,
        roomId,
        loopLength,
        roomLength,
        startDay,
        endDay,
        dateStart,
        dateEnd,
        properties,
        setProperties,
        rooms,
        setRooms,
        reCountBookings,
        bookings,
        setBookings,
        dateCount,
        setRefreshRatesEffect,
        bookingsStore,
        editBooking,
        activitiesData,
      );
    }
  } else {
    if (properties[propIndex].bookedDirect) {
      return;
    } else {
      return;
      //return toast.error(`Availability not available for ${properties[propIndex].name}`);
    }
  }

  // Original code end
};

const updateResRequestErrors = async (propertyUid) => {
  try {
    const propertyRef = doc(db, 'properties', propertyUid);
    await updateDoc(propertyRef, {
      resRequestErrors: true,
    });
    console.log(`Updated resRequestErrors for property ${propertyUid}`);
  } catch (error) {
    console.error(`Error updating resRequestErrors for property ${propertyUid}:`, error);
  }
};

// ************************************* // Fetch stock from ac_get_stock
const fetchStockReqRes = async (
  propNum,
  roomNum,
  roomId,
  loopLength,
  roomJsonNum,
  startDay,
  endDay,
  dateStart,
  dateEnd,
  properties,
  setProperties,
  rooms,
  setRooms,
  reCountBookings,
  bookings,
  setBookings,
  dateCount,
  setRefreshRatesEffect,
  bookingsStore,
  editBooking,
  activitiesData,
) => {
  console.log('dateStart: ' + dateStart);
  console.log('dateEnd: ' + dateEnd);

  // Check if roomId is valid
  if (!roomId) {
    console.error(`Invalid roomId for room ${roomNum} in property ${properties[propNum].name}`);
    properties[propNum].resRequestErrors = true;
    properties[propNum].rooms[roomNum].fetchError = true; // Use fetchError instead of hasError
    properties[propNum].roomLoop += 1;
    //toast.error(`Error fetching data for ${properties[propNum].name}, room ${properties[propNum].rooms[roomNum].name}: Invalid room ID`);
    //setProperties([...properties]);
    return; // Exit the function early
  }

  const url = '/api/resreq';
  const principal_id = properties[propNum].platformResRequest.principalId;

  var data = JSON.stringify({
    method: 'ac_get_stock',
    params: [
      {
        bridge_username: '',
        bridge_password: '',
        principal_id: principal_id,
      },
      roomId,
      dateStart,
      dateEnd,
      '',
      {
        total: '1',
        provisional: properties[propNum].supplier.name === 'Nomad Tanzania' ? '1' : '0',
        allocation: '1',
      },
    ],
    id: 1,
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios(config);

    if (response.data.error !== null) {
      console.log(`Error for room ${roomId} in property ${properties[propNum].name}: ${response.data.error.faultString}`);
      properties[propNum].resRequestErrors = true;
      //toast.error(`Error 1 fetching data for ${properties[propNum].name}, room ${properties[propNum].rooms[roomNum].name}: ${response.data.error.faultString}`);
      // set to not resRequest - start
      // properties[propNum].resRequestErrors = true;
      properties[propNum].platformResRequest.active = false;
      updateResRequestErrors(properties[propNum].uid);
      //rooms = removeRoomsByPropUid(rooms, properties[propNum].uid);
      //setRooms(newRooms);
      // set to not resRequest - end

      // Mark the room as having an error
      properties[propNum].rooms[roomNum].fetchError = true; // Use fetchError instead of hasError
    } else {
      // Process successful response
      let result = response.data.result;

      // ! Add extra nights for nights in booking to total which becomes stockCount:
      if (editBooking) {
        for (let i = 0; i < bookingsStore.length; i++) {
          const rooms = bookingsStore[i].rooms;

          for (let j = 0; j < rooms.length; j++) {
            const selectedRoom = rooms[j].selectedRoom;
            if (selectedRoom === roomId) {
              const periodFrom = moment(bookingsStore[i].startDay);
              const periodTo = moment(bookingsStore[i].endDay);

              while (periodFrom.isBefore(periodTo)) {
                const dateString = periodFrom.format('YYYY-MM-DD');
                // ! idea for future to handle non nomad properties correctly
                // if(properties[propNum].platformResRequest.createBooking) {
                //   result.total[dateString] = result.total[dateString] + 1;
                // }
                result.total[dateString] = result.total[dateString] + 1;
                periodFrom.add(1, 'day');
              }
            }
          }
        }
      }

      // Check for activities availability and increase as required
      if (activitiesData && editBooking) {
        const propUid = properties[propNum].uid;
        const activityId = roomId;

        activitiesData.forEach((activityData) => {
          activityData.activities.forEach((dailyActivities) => {
            dailyActivities.forEach((activity) => {
              if (activity.type === 'resRequestAccom') {
                const { selectedActivityDate, ids } = activity;

                if (activityData.propUid === propUid && ids.resRequestRoom === activityId) {
                  if (result.total.hasOwnProperty(selectedActivityDate)) {
                    result.total[selectedActivityDate] += activity.activityUnits;
                  }
                }
              }
            });
          });
        });
      }

      properties[propNum].rooms[roomNum].stock = result;
      properties[propNum].rooms[roomNum].fetchError = false; // Explicitly set fetchError to false on success
    }

    properties[propNum].roomLoop += 1;
    properties[propNum].roomNames.push(properties[propNum].rooms[roomNum].name);

    //setProperties([...properties]);
  } catch (error) {
    console.error(`Error fetching stock for room ${roomId} in property ${properties[propNum].name}:`, error);
    properties[propNum].resRequestErrors = true;
    properties[propNum].rooms[roomNum].fetchError = true; // Use fetchError instead of hasError
    properties[propNum].roomLoop += 1;
    //toast.error(`Error 2 fetching data for ${properties[propNum].name}, room ${properties[propNum].rooms[roomNum].name}`);
    //setProperties([...properties]);
    // set to not resRequest - start
    // properties[propNum].resRequestErrors = true;
    properties[propNum].platformResRequest.active = false;
    updateResRequestErrors(properties[propNum].uid);
    //rooms = removeRoomsByPropUid(rooms, properties[propNum].uid);
    //setRooms(newRooms);
    // set to not resRequest - end
  }

  // Debug logging
  console.log(`Room ${properties[propNum].rooms[roomNum].name} processed:`, {
    fetchError: properties[propNum].rooms[roomNum].fetchError,
    hasStock: !!properties[propNum].rooms[roomNum].stock,
    roomLoop: properties[propNum].roomLoop,
  });
};
const fetchStockReqResOld = async (
  propNum,
  roomNum,
  roomId,
  loopLength,
  roomJsonNum,
  startDay,
  endDay,
  dateStart,
  dateEnd,
  properties,
  setProperties,
  rooms,
  setRooms,
  reCountBookings,
  bookings,
  setBookings,
  dateCount,
  setRefreshRatesEffect,
  bookingsStore,
  editBooking,
  activitiesData,
) => {
  // ! console.log("get_stock");
  // ! console.log(startDay.getFullYear() + "-" + make2digits(startDay.getMonth() + 1) + "-" + make2digits(startDay.getDate()));
  // ! console.log(endDay.getFullYear() + "-" + make2digits(endDay.getMonth() + 1) + "-" + make2digits(endDay.getDate()));
  // Vars
  // ! const bridge_username = "nomadapi";
  // ! const bridge_password = "Qt8iWxWqDvfPhGc";
  //const principal_id = process.env.REACT_APP_RES_REQ_PRINCIPLE_ID;
  const principal_id = properties[propNum].platformResRequest.principalId;
  // console.log("dateStart");
  // console.log(dateStart);
  // console.log("dateEnd");
  // console.log(dateEnd);
  console.log('dateStart: ' + dateStart);
  console.log('dateEnd: ' + dateEnd);
  const url = '/api/resreq';
  // First call
  var data = JSON.stringify({
    method: 'ac_get_stock',
    params: [
      {
        bridge_username: '',
        bridge_password: '',
        principal_id: principal_id,
      },
      roomId,
      dateStart,
      dateEnd,
      '',
      {
        total: '1',
        //provisional: "1",
        provisional: properties[propNum].supplier.name === 'Nomad Tanzania' ? '1' : '0',
        allocation: '1',
      },
    ],
    id: 1,
  });

  // console.log("data");
  // console.log(data);
  // console.log(JSON.stringify(data, undefined, 4));

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  axios(config)
    .then(async function (response) {
      // !console.log(JSON.stringify(response, undefined, 4));
      if (response.data.error !== null) {
        console.log('faultCode: ' + response.data.error.faultCode);
        console.log(properties[propNum].name);
        console.log('properties[propNum].roomLoop', properties[propNum].roomLoop);

        properties[propNum].roomLoop = properties[propNum].roomLoop + 1;

        if (properties[propNum].roomLoop === 1) {
          //if (properties[propNum].roomLoop >= 1) {
          // ! Remove bookings and rooms for the property // ! remove issue properties from the list
          // if (bookings && bookings.length > 0) {
          //   const newBookings = removeBookingsByPropUid(bookings, properties[propNum].uid);
          //   setBookings(newBookings);
          // }
          properties[propNum].resRequestErrors = true;
          //properties[propNum].platformResRequest.active = false;
          if (roomId !== '') {
            const newRooms = removeRoomsByPropUid(rooms, properties[propNum].uid);
            setRooms(newRooms);
          } else {
            // properties[propNum].roomLoop = properties[propNum].roomLoop + 1;
            //rooms[roomJsonNum].loading = false;
            //rooms[roomJsonNum].collapsed = false;
          }

          // toast.error('Removing property');
          toast.error(response.data.error.faultString);
          toast.error('STOCK 1');
          console.log('roomId: ' + roomId);
          updateResRequestErrors(properties[propNum].uid);
        } else {
          // ! Remove bookings and rooms for the property // ! remove issue properties from the list
          // if (bookings && bookings.length > 0) {
          //   const newBookings = removeBookingsByPropUid(bookings, properties[propNum].uid);
          //   setBookings(newBookings);
          // }
          properties[propNum].resRequestErrors = true;
          // properties[propNum].platformResRequest.active = false;
          if (roomId !== '') {
            const newRooms = removeRoomsByPropUid(rooms, properties[propNum].uid);
            setRooms(newRooms);
          } else {
            // properties[propNum].roomLoop = properties[propNum].roomLoop + 1;
            //rooms[roomJsonNum].loading = false;
            //rooms[roomJsonNum].collapsed = false;
          }

          //toast.error('Error property');
          toast.error(response.data.error.faultString);
          toast.error('STOCK 2');
          console.log('roomId: ' + roomId);
          updateResRequestErrors(properties[propNum].uid);
        }
      } else {
        // Update rooms availability
        if (editBooking) {
          const result = response.data.result;
          for (let i = 0; i < bookingsStore.length; i++) {
            const rooms = bookingsStore[i].rooms;

            for (let j = 0; j < rooms.length; j++) {
              const selectedRoom = rooms[j].selectedRoom;
              if (selectedRoom === roomId) {
                const periodFrom = moment(bookingsStore[i].startDay);
                console.log('periodFrom: ' + periodFrom.format());
                const periodTo = moment(bookingsStore[i].endDay);
                console.log('periodTo: ' + periodTo.format());

                while (periodFrom.isBefore(periodTo)) {
                  console.log('periodFrom: ' + periodFrom.format() + ' periodTo: ' + periodTo.format());
                  const dateString = periodFrom.format('YYYY-MM-DD');
                  result.total[dateString] = result.total[dateString] + 1;

                  periodFrom.add(1, 'day');
                }

                console.log('MATCHED ROOM: ' + periodFrom.format() + ' -> ' + periodTo.format());
              }
            }
          }
          properties[propNum].rooms[roomNum].stock = result;
          //properties[propNum].rooms[roomNum].stock = response.data.result;
          console.log('EDIT_BOOKING_RESPONSE_DATA_RESULT: ' + roomId + JSON.stringify(response.data.result, undefined, 4));
          //properties[propNum].rooms[roomNum].stock = response.data.result;
        } else {
          console.log('CREATE_BOOKING_RESPONSE_DATA_RESULT: ' + roomId);
          properties[propNum].rooms[roomNum].stock = response.data.result;
        }

        // Check for activities availability and increase as required
        if (activitiesData && editBooking) {
          const result = response.data.result;
          const propUid = properties[propNum].uid;
          const activityId = roomId;

          activitiesData.forEach((activityData) => {
            activityData.activities.forEach((dailyActivities) => {
              dailyActivities.forEach((activity) => {
                if (activity.type === 'resRequestAccom') {
                  const { selectedActivityDate, ids } = activity;

                  if (activityData.propUid === propUid && ids.resRequestRoom === activityId) {
                    if (result.total.hasOwnProperty(selectedActivityDate)) {
                      console.log('ACTIVITIES MATCHED: ' + selectedActivityDate + ' => ' + result.total[selectedActivityDate] + ' -> ' + activity.activityUnits);
                      result.total[selectedActivityDate] += activity.activityUnits;
                      //return (result.total[selectedActivityDate] += activity.activityUnits);
                    }
                  }
                }
              });
            });
          });
        }

        //properties[propNum].rooms[roomNum].stock = response.data.result;
        setProperties(properties);

        if (response.status === 200) {
          properties[propNum].roomLoop = properties[propNum].roomLoop + 1;
          properties[propNum].roomNames.push(properties[propNum].rooms[roomNum].name);
        }

        if (properties[propNum].roomLoop > loopLength) {
          // ! setIsLoading(false);
          buildStockDisplay(propNum, roomJsonNum, properties, rooms, setRooms, reCountBookings, bookings, setBookings, dateCount, setRefreshRatesEffect, bookingsStore, editBooking);
        }
      }
    })
    .catch(function (error) {
      //console.log(error);
      properties[propNum].roomLoop = properties[propNum].roomLoop + 1;
      // ! setIsLoading(false);
    });
};

// ************************************* // Build stock display
const buildStockDisplay = (propNum, roomJsonNum, properties, rooms, setRooms, reCountBookings, bookings, setBookings, dateCount, setRefreshRatesEffect, bookingsStore, editBooking) => {
  const roomList = [];
  console.log(`Building stock display for property ${properties[propNum].name}`);

  for (let i = 0; i < properties[propNum].rooms.length; i++) {
    const room = properties[propNum].rooms[i];
    console.log(`Processing room: ${room.name}, fetchError: ${room.fetchError}, hasStock: ${!!room.stock}`);

    if (!room.fetchError && room.stock) {
      console.log(`Adding stock for room: ${room.name}`);
      // Process room stock only if there's no fetch error and stock exists
      Object.keys(room.stock.total).forEach(function (key) {
        let date = key;

        roomList.push({
          stockStart: date,
          stockEnd: date,
          stockCount: room.stock.total[key],
          stockName: room.name,
          stockId: room.id,
          stockColor: 'brand-bg-custom-color',
          stockSplit: 'total',
          stockType: room.type,
          maxAdults: room.max_adults,
          maxCapacity: room.max_capacity,
          stockUuid: uuidv4(),
          hideExternalUsers: room.hideExternalUsers,
        });
      });
      if (room.stock.provisional) {
        Object.keys(room.stock.provisional).forEach(function (key) {
          let date = key;

          roomList.push({
            stockStart: date,
            stockEnd: date,
            stockCount: room.stock.provisional[key],
            stockName: room.name,
            stockId: room.id,
            stockColor: 'brand-bg-custom-color',
            stockSplit: 'provisional',
            stockType: room.type,
            maxAdults: room.max_adults,
            maxCapacity: room.max_capacity,
            stockUuid: uuidv4(),
            hideExternalUsers: room.hideExternalUsers,
          });
        });
      } else {
        // Create a provisional stock object based on total stock object days
        Object.keys(room.stock.total).forEach(function (key) {
          let date = key;

          roomList.push({
            stockStart: date,
            stockEnd: date,
            stockCount: 0,
            stockName: room.name,
            stockId: room.id,
            stockColor: 'brand-bg-custom-color',
            stockSplit: 'provisional',
            stockType: room.type,
            maxAdults: room.max_adults,
            maxCapacity: room.max_capacity,
            stockUuid: uuidv4(),
            hideExternalUsers: room.hideExternalUsers,
          });
        });
      }
    } else {
      console.log(`Skipping room ${room.name} due to fetch error or missing stock`);
    }
  }

  console.log(`Total rooms with stock: ${roomList.length}`);

  // ! STOCK NEW: check if rooms[roomJsonNum] exists and roomList.length > 0
  if (roomList.length > 0 && rooms[roomJsonNum]) {
    rooms[roomJsonNum].stock = roomList;
    rooms[roomJsonNum].loading = false;
    rooms[roomJsonNum].collapsed = false;
  }

  if (bookings !== false) {
    if (bookings !== null && bookings !== undefined) {
      setBookings([...bookings]);
      if (setRefreshRatesEffect !== null && setRefreshRatesEffect !== undefined) {
        console.log('buildStockDisplay &&&&&&&&&&&&&&&&&&&&&');
      }
      setTimeout(() => {}, 500);
      reCountBookings(bookings, setBookings, dateCount, rooms, setRooms, properties, setRefreshRatesEffect, true);
    }
  }

  setRooms([...rooms]);
  console.log(`Stock display built for property ${properties[propNum].name}`);
};
const buildStockDisplayOld = (propNum, roomJsonNum, properties, rooms, setRooms, reCountBookings, bookings, setBookings, dateCount, setRefreshRatesEffect, bookingsStore, editBooking) => {
  // ! console.log("buildStockDisplay :" + propNum);
  const roomList = [];
  for (let i = 0; i < properties[propNum].rooms.length; i++) {
    Object.keys(properties[propNum].rooms[i].stock.total).forEach(function (key) {
      //const dateArray = key.split("-");
      // const dateYear = dateArray[0];
      // const dateMonth = dateArray[1];
      // const dateDay = dateArray[2];
      //let date = new Date(dateYear, dateMonth - 1, dateDay);
      let date = key;

      // console.log("CHECK DATES HERE 1");
      // console.log(key);
      // console.log(dateArray);
      // console.log(dateYear);
      // console.log(dateMonth);
      // console.log(dateDay);
      // console.log(date);

      roomList.push({
        stockStart: date,
        stockEnd: date,
        stockCount: properties[propNum].rooms[i].stock.total[key],
        stockName: Object(properties[propNum].rooms[i]).name,
        stockId: Object(properties[propNum].rooms[i]).id,
        stockColor: 'brand-bg-custom-color',
        stockSplit: 'total',
        stockType: Object(properties[propNum].rooms[i]).type,
        maxAdults: Object(properties[propNum].rooms[i]).max_adults,
        maxCapacity: Object(properties[propNum].rooms[i]).max_capacity,
        stockUuid: uuidv4(),
      });
      //setNoRes(false);
    });
    if (properties[propNum].rooms[i].stock && properties[propNum].rooms[i].stock.provisional) {
      Object.keys(properties[propNum].rooms[i].stock.provisional).forEach(function (key) {
        // const dateArray = key.split("-");
        // const dateYear = dateArray[0];
        // const dateMonth = dateArray[1];
        // const dateDay = dateArray[2];
        //let date = new Date(dateYear, dateMonth - 1, dateDay);
        let date = key;

        // console.log("CHECK DATES HERE 2");
        // console.log(dateArray);
        // console.log(dateYear);
        // console.log(dateMonth);
        // console.log(dateDay);
        // console.log(date);

        roomList.push({
          stockStart: date,
          stockEnd: date,
          stockCount: properties[propNum].rooms[i].stock.provisional[key],
          stockName: Object(properties[propNum].rooms[i]).name,
          stockId: Object(properties[propNum].rooms[i]).id,
          stockColor: 'brand-bg-custom-color',
          stockSplit: 'provisional',
          stockType: Object(properties[propNum].rooms[i]).type,
          maxAdults: Object(properties[propNum].rooms[i]).max_adults,
          maxCapacity: Object(properties[propNum].rooms[i]).max_capacity,
          stockUuid: uuidv4(),
        });
      });
    } else {
      // Create a provisional stock object based on total stock object days
      Object.keys(properties[propNum].rooms[i].stock.total).forEach(function (key) {
        // const dateArray = key.split("-");
        // const dateYear = dateArray[0];
        // const dateMonth = dateArray[1];
        // const dateDay = dateArray[2];
        //let date = new Date(dateYear, dateMonth - 1, dateDay);
        let date = key;

        // console.log("CHECK DATES HERE 3");
        // console.log(dateArray);
        // console.log(dateYear);
        // console.log(dateMonth);
        // console.log(dateDay);
        // console.log(date);

        // ! console.log(date + " : ");
        roomList.push({
          stockStart: date,
          stockEnd: date,
          stockCount: 0,
          stockName: Object(properties[propNum].rooms[i]).name,
          stockId: Object(properties[propNum].rooms[i]).id,
          stockColor: 'brand-bg-custom-color',
          stockSplit: 'provisional',
          stockType: Object(properties[propNum].rooms[i]).type,
          maxAdults: Object(properties[propNum].rooms[i]).max_adults,
          maxCapacity: Object(properties[propNum].rooms[i]).max_capacity,
          stockUuid: uuidv4(),
        });
        //setNoRes(false);
      });
    }

    // Object.keys(properties[propNum].rooms[i].stock.provisional).forEach(function (key) {
    //   const dateArray = key.split("-");
    //   const dateYear = dateArray[0];
    //   const dateMonth = dateArray[1];
    //   const dateDay = dateArray[2];
    //   let date = new Date(dateYear, dateMonth - 1, dateDay);
    //   // ! console.log("key.value");
    //   // ! console.log(properties[propNum].rooms[i].stock.provisional[key]);

    //   // ! console.log(date + " : ");
    //   roomList.push({
    //     stockStart: date,
    //     stockEnd: date,
    //     stockCount: properties[propNum].rooms[i].stock.provisional[key],
    //     stockName: Object(properties[propNum].rooms[i]).name,
    //     stockId: Object(properties[propNum].rooms[i]).id,
    //     stockColor: "brand-bg-custom-color",
    //     stockSplit: "provisional",
    //     stockType: Object(properties[propNum].rooms[i]).type,
    //     maxAdults: Object(properties[propNum].rooms[i]).max_adults,
    //     maxCapacity: Object(properties[propNum].rooms[i]).max_capacity,
    //   });
    // });
  }
  //if (properties[propNum].selected) {
  // console.log("ROOMS: " + roomJsonNum);
  // console.log(JSON.stringify(rooms, undefined, 4));
  console.log('ROOM LIST: ' + roomJsonNum);
  console.log(JSON.stringify(roomList, undefined, 4));
  rooms[roomJsonNum].stock = roomList;
  rooms[roomJsonNum].loading = false;
  rooms[roomJsonNum].collapsed = false;
  // console.log("ROOMS: " + roomJsonNum);
  // console.log(JSON.stringify(rooms, undefined, 4));
  //}

  // ! console.log(JSON.stringify(rooms, undefined, 4));

  // if (typeof bookings !== "undefined") {
  //   setBookings((bookings) => [...bookings]);
  //   reCountBookings(bookings, setBookings, date, rooms, setRooms, properties, false);
  //   setTimeout(() => {}, 500);
  //   //reCountBookings(bookings, setBookings, date, rooms, setRooms, properties);
  // }

  if (bookings !== false) {
    if (bookings !== null && bookings !== undefined) {
      setBookings((bookings) => [...bookings]);
      if (setRefreshRatesEffect !== null && setRefreshRatesEffect !== undefined) {
        console.log('buildStockDisplay &&&&&&&&&&&&&&&&&&&&&');
        // const modifiedBookings = bookings.map((booking) => ({
        //   ...booking,
        //   rooms: booking.rooms.map((room) => {
        //     const { rate, ...rest } = room;
        //     return rest;
        //   }),
        // }));
        // setBookings((bookings) => [...modifiedBookings]);
        // reCountBookings(modifiedBookings, setBookings, dateCount, rooms, setRooms, properties, setRefreshRatesEffect, true);
        //setRefreshRatesEffect(true);
      }
      setTimeout(() => {}, 500);
      // const modifiedBookings = bookings.map((booking) => ({
      //   ...booking,
      //   rooms: booking.rooms.map((room) => {
      //     const { rate, ...rest } = room;
      //     return rest;
      //   }),
      // }));
      // setBookings((bookings) => [...modifiedBookings]);
      reCountBookings(bookings, setBookings, dateCount, rooms, setRooms, properties, setRefreshRatesEffect, true);
      //reCountBookings(bookings, setBookings, date, rooms, setRooms, properties);
    }
  }
  //setRooms(rooms);

  setRooms((rooms) => [...rooms]);
};

// ************************************* // Availability icon
const stockCountCheck = (day, propertyCount, startDay, rooms) => {
  console.log('stockCountCheck 1: ' + day + ' ' + propertyCount + ' ' + startDay + ' ' + rooms);
  const newStartDay = moment(startDay).add(day, 'days').format('YYYY-MM-DD');

  const gRooms = getRooms(newStartDay, propertyCount, rooms, 'total');

  /*
  Check that there is at least one room available (gRooms[i].stockCount > 0) and then stop the for loop
  
  This can also be used to check for any other condition to show:
  Full Availability
  Availability On Request
  Per Person Sharing Rate
  Unit Rate
  */
  var accomAvailable = false;
  // TODO: Clean this up start
  // ! console.log("gRooms CHECK -----------------------------------");
  const roomTotals = mergeRoomCount(gRooms);
  // ! console.log("roomTotals: " + roomTotals);
  // ! console.log(JSON.stringify(roomTotals, undefined, 4));
  // ! console.log("gRooms.length: " + gRooms.length);
  // ! console.log(JSON.stringify(gRooms, undefined, 4));
  // TODO: Clean this up end
  for (var i = 0; i < gRooms.length; i++) {
    // ! console.log("stockCountCheck: " + i);
    if (roomTotals > 0) {
      accomAvailable = true;
      return (
        <div key={uuidv4()} className="h-full flex flex-col justify-center items-center">
          <MdEventAvailable size={20} />
        </div>
      );
      //break;
    } else if (accomAvailable === false) {
      return (
        <div key={uuidv4()} className="h-full flex flex-col justify-center items-center">
          <MdBlock size={20} />
        </div>
      );
    }
  }
  // If no rooms are available, show the "Not Available" icon
  // The if statement is not really required but maybe useful for other conditions
  if (accomAvailable === false) {
    return (
      <div key={uuidv4()} className="h-full flex flex-col justify-center items-center">
        <BsQuestionCircle size={18} />
      </div>
    );
  }
};

// Count total rooms available on one day
const mergeRoomCount = (data) => {
  console.log('mergeRoomCount: ' + data.length);
  console.log(JSON.stringify(data, undefined, 4));

  // NEW CODE
  const tempData = data;
  for (var i = 0; i < tempData.length; i++) {
    if (tempData[i].stockCount < 0) {
      tempData[i].stockCount = 0;
    }
  }
  // NEW CODE END
  // ! console.log("mergeRoomCount: " + tempData.length);
  // ! console.log(JSON.stringify(data, undefined, 4));
  if (tempData.length > 0) {
    let result = tempData.reduce((a, c) => ({
      stockCount: a.stockCount + c.stockCount,
    }));
    console.log('mergeRoomCount result: ' + result.stockCount);
    return result['stockCount'];
    //return result.stockCount;
  }
};

// ************************************* // Availablity for displaying a zeroed out day

const stockCountCheckZero = (day, propertyCount, startDay, rooms, stockSplit, res) => {
  // !console.log("res: ");
  // !console.log(JSON.stringify(res, undefined, 4));
  // ! console.log("stockCountCheckZero");
  // ! console.log(day + " : " + propertyCount + " : " + startDay + " : " + rooms + " : " + stockSplit);
  const newStartDay = moment(startDay).add(day, 'days').format('YYYY-MM-DD');

  const gRooms = getRooms(newStartDay, propertyCount, rooms, stockSplit);

  // console.log("gRooms");
  // console.log(gRooms);
  // Check that there is at least one room available (gRooms[i].stockCount > 0) and then stop the for loop
  //
  // This can also be used to check for any other condition to show:
  // Full Availability
  // Availability On Request
  // Per Person Sharing Rate
  // Unit Rate
  var accomAvailable = false;
  const roomTotals = mergeRoomCount(gRooms);
  // ! console.log("roomTotals: " + roomTotals);
  // !console.log("gRooms.length: " + gRooms.length);
  for (var i = 0; i < gRooms.length; i++) {
    // ! console.log("stockCountCheck: " + i);
    // !console.log("gRooms[i].stockCount 0: " + gRooms[i].stockStart + " " + gRooms[i].stockName + " " + gRooms[i].stockCount);
    if (roomTotals > 0) {
      accomAvailable = true;
      if (stockSplit === 'total') {
        // !console.log("gRooms[i].stockCount 1: " + gRooms[i].stockStart + " " + gRooms[i].stockName + " " + gRooms[i].stockCount);
        return true;
      } else {
        if (gRooms[i].stockId === res.stockId) {
          // !console.log("gRooms[i].stockCount 2: " + gRooms[i].stockStart + " " + gRooms[i].stockName + " " + gRooms[i].stockCount);
          // !console.log(JSON.stringify(gRooms, undefined, 4));
          //return "a";
          return gRooms[i].stockCount;
        }
      }
    }
    // If no rooms are available, show the "Not Available" icon
    // The if statement is not really required but maybe useful for other conditions
    if (accomAvailable === false) {
      if (stockSplit === 'total') {
        // TODO - Change to false for dash
        // !console.log("gRooms[i].stockCount 3: " + gRooms[i].stockStart + " " + gRooms[i].stockName + " " + gRooms[i].stockCount);
        return false;
      } else {
        //console.log("gRooms[i].stockCount 2: " + day + " " + gRooms[i].stockCount);
        // !console.log("gRooms[i].stockCount 4: " + gRooms[i].stockStart + " " + gRooms[i].stockName + " " + gRooms[i].stockCount);
        //return "b";
        return gRooms[i].stockCount;
      }
    }
  }
};

const updateDates = (
  rooms,
  properties,
  setRooms,
  setProperties,
  dateStart,
  dateEnd,
  reCountBookings,
  bookings,
  setBookings,
  date,
  setRefreshRatesEffect,
  bookingsStore,
  updateRoomAvailability,
  activitiesData,
) => {
  // DATES
  const startDay = dateStart;
  const endDay = dateEnd;

  console.log('updateDates 1: ' + startDay + ' ' + endDay);

  const timer = (ms) => new Promise((res) => setTimeout(res, ms));
  async function load() {
    if (rooms.length === 0) {
      console.log('updateDates 2');
      if (setRefreshRatesEffect !== false) {
        //setRefreshRatesEffect(false);

        // New code for setting stock on dates that were historical - start
        for (var i = 0; i < properties.length; i++) {
          getStock(
            getPropObj(properties[i].uid, properties), //
            startDay, //
            endDay, //
            properties, //
            setProperties, //
            rooms, //
            setRooms, //
            null, //
            reCountBookings,
            bookings,
            setBookings,
            date,
            setRefreshRatesEffect,
            bookingsStore,
            updateRoomAvailability,
            activitiesData,
          );
        }
        // New code for setting stock on dates that were historical - end

        setRefreshRatesEffect(true);
        console.log('setRefreshRatesEffect: false');
      }
    } else {
      console.log('updateDates 3');
      for (var i = 0; i < rooms.length; i++) {
        // ! console.log(rooms[i].name);
        rooms[i].collapsed = true;
        rooms[i].loading = true;
        getStock(
          getPropObj(rooms[i].propUid, properties), //
          startDay, //
          endDay, //
          properties, //
          setProperties, //
          rooms, //
          setRooms, //
          i, //
          reCountBookings,
          bookings,
          setBookings,
          date,
          setRefreshRatesEffect,
          bookingsStore,
          updateRoomAvailability,
          activitiesData,
        );
      }
    }
  }
  load();
};

const getRoomAvailability = (id, item, rooms) => {
  // console.log('getRoomAvailability id: ' + id);
  // console.log('getRoomAvailability item: ' + JSON.stringify(item, undefined, 4));
  // console.log('getRoomAvailability rooms: ' + JSON.stringify(rooms, undefined, 4));
  const { startDay, endDay } = item;
  if (rooms.length === 0) {
    return '';
  }

  if (id === 'Select room type') {
    return;
  }

  let targetRoom = null;
  for (let i = 0; i < rooms.length; i++) {
    const room = rooms[i].stock.find((r) => r.stockSplit === 'total' && r.stockId === id);
    if (room) {
      targetRoom = room;
      break;
    }
  }

  if (!targetRoom) {
    return '';
  }

  let minAvailability = Infinity;
  const startDate = moment(startDay);
  const endDate = moment(endDay).subtract(1, 'day');

  // Count the existing rooms in the item.rooms array
  const existingRooms = item.rooms.filter((room) => room.selectedRoom === id);
  const existingRoomsCount = existingRooms.length;

  while (startDate.isSameOrBefore(endDate)) {
    const availableRoom = rooms
      .flatMap((r) => r.stock)
      .find((room) => room.stockSplit === 'total' && room.stockId === id && startDate.isBetween(moment(room.stockStart), moment(room.stockEnd), null, '[]'));

    const availability = availableRoom ? availableRoom.stockCount - existingRoomsCount : 0;
    minAvailability = Math.min(minAvailability, availability);
    startDate.add(1, 'day');
  }

  if (minAvailability < 0) {
    minAvailability = 0;
  }

  const text = minAvailability === 1 ? minAvailability + ' room available' : minAvailability + ' rooms available';

  return '[' + text + ']';
};

export { updateDates, getStock, fetchStockReqRes, buildStockDisplay, stockCountCheck, stockCountCheckZero, getRoomAvailability };
