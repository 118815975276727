import React from 'react';
import { Tab } from '@headlessui/react';
import { Badge } from '../../../../components/ui/Badge';
import OverviewTab from './OverviewTab';
import ActivityTab from './ActivityTab';
import SeasonalGuidesTab from './SeasonalGuidesTab';
import LocationAndTravelTab from './LocationTravelTab';
import PropertyAmenities from './PropertyAmenities';
import ReviewsTab from './ReviewsTab';
import CampInfoTab from './CampInfoTab';
import DocumentDownloadsBox from '../../components/DocumentDownloadsBox';
import RatingsSection from './RatingsSection'


function TabSection({ camp, firestoreData, tripAdvisorData }) {
  const isNomadProperty = firestoreData.supplier.name === "Nomad Tanzania";
  const tabs = [
    { name: 'Overview', component: <OverviewTab camp={camp} firestoreData={firestoreData} /> },
    ...(isNomadProperty ? [{ name: 'Camp Info', component: <CampInfoTab camp={camp} firestoreData={firestoreData} /> }] : []),
    ...(camp.activities ? [{ name: 'Activities', component: <ActivityTab camp={camp} firestoreData={firestoreData} /> }] : []),
    { name: 'Location', component: <LocationAndTravelTab camp={camp} firestoreData={firestoreData} /> },
    { name: 'Reviews', component: <ReviewsTab camp={camp} firestoreData={firestoreData} tripAdvisorData={tripAdvisorData} /> },
    { name: 'Rates', component: <div>Rates & Availability content</div>, comingSoon: true },
  ];

  return (
    <Tab.Group>
      <Tab.List className="flex space-x-1 mb-8 rounded-xl bg-white shadow-md">
        {tabs.map((tab) => (
          <Tab
            key={tab.name}
            className={({ selected }) =>
              `w-full rounded-lg py-4 text-sm font-medium leading-5 flex items-center justify-center space-x-2
              ${selected ? 'bg-[#073937] text-white shadow' : 'text-[#3e3d3d] hover:bg-gray-100'}`
            }
          >
            <span>{tab.name}</span>
            {tab.comingSoon && (
              <Badge variant="warning" size="sm">Coming Soon</Badge>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="mt-2">
        {tabs.map((tab, idx) => (
          <Tab.Panel key={idx} className="">
            {idx === 0 ? (
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div className="lg:col-span-2">{tab.component}</div>
                <div className="space-y-8">
                {camp.seasonalGuides?.documentDownloads && camp.seasonalGuides.documentDownloads.length > 0 && (
                    <DocumentDownloadsBox
                      title="Seasonal Guides"
                      documents={camp.seasonalGuides.documentDownloads}
                    />
                  )}
                  <PropertyAmenities keyFacts={camp.keyFacts} rooms={firestoreData.rooms} spokenLanguages={camp.campInformation.spoken_languages} tripAdvisorData={tripAdvisorData} />
                  <RatingsSection 
          rating={tripAdvisorData.rating}
          subratings={tripAdvisorData.subratings}
          numReviews={tripAdvisorData.num_reviews}
        />
                </div>
              </div>
            ) : (
              tab.component
            )}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

export default TabSection;