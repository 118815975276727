import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword, reset } from '../features/auth/authSlice';
import SideImage from '../images/pages/home/homepage.jpg';
import { motion } from 'framer-motion';
import ButtonPrimary from '../components/Buttons/ButtonPrimary';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [resetError, setResetError] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isError) {
      setResetError(message);
    }

    if (isSuccess) {
      setResetSuccess(true);
      setEmail('');
    }

    dispatch(reset());
  }, [isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setResetError(null);
    dispatch(forgotPassword({ email }));
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main className="flex-grow flex items-center justify-center">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-lg"
          >
            <div className="text-center mb-8">
              <h1 className="text-4xl font-roca text-nomadBlue mb-2">Forgot Password</h1>
              <p className="text-gray-600">Enter your email to reset your password</p>
            </div>

            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="bg-white rounded-lg border border-[#f0ece7] p-6"
            >
              {resetSuccess ? (
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.5 }}
                  className="text-center"
                >
                  <svg className="w-16 h-16 mx-auto mb-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <h2 className="text-2xl font-semibold mb-2">Email Sent</h2>
                  <p className="text-gray-600">Please check your email for password reset instructions.</p>
                </motion.div>
              ) : (
                <>
                  {resetError && (
                    <motion.div 
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded"
                    >
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{resetError}</p>
                        </div>
                      </div>
                    </motion.div>
                  )}
                  <form onSubmit={onSubmit} className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="email">
                        Email Address <span className="text-rose-500">*</span>
                      </label>
                      <input
                        id="email"
                        className="form-input w-full"
                        type="email"
                        name="email"
                        value={email}
                        onChange={onChange}
                        placeholder="Please enter your email"
                        required
                      />
                    </div>
                    <div className="mt-6">
                      <ButtonPrimary
                        text="Reset Password"
                        onClick={onSubmit}
                        color="dark"
                        className="w-full"
                        isLoading={isLoading}
                      />
                    </div>
                  </form>
                </>
              )}
              <div className="pt-5 mt-6 border-t border-[#f0ece7]">
                <div className="text-sm text-center">
                  Remember your password?{' '}
                  <Link className="font-medium text-nomadBlue hover:underline" to="/signin">
                    Sign In
                  </Link>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </main>
      </div>
      
      <div className="hidden lg:block relative w-1/2 bg-[#20373b]">
       
       <div className="absolute inset-0 flex flex-col items-center justify-between p-8 pb-2">
         <div className="w-full flex flex-col items-center mt-40">
           <img
             src="https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/ab92d013-3bbb-42b9-7040-6d91875e6800/public"
             alt="Hornbill Agent Platform"
             className="w-80 mb-16"
           />
           <p className="text-white text-xl mb-8 max-w-md text-center shadow-text">
             A REVOLUTIONARY PLATFORM HELPING SAFARI SPECIALISTS
             BUILD INSPIRATIONAL SAFARIS
           </p>
         </div>
         <div className="text-center">
           <p className="text-white text-md">
             PROVIDED AND OPERATED BY
           </p>
           <img
             src="https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/b77d6736-4f33-4f25-e69f-64e304b00b00/public"
             alt="Nomad DMC"
             className="w-32 mx-auto mt-2"
           />
         </div>
       </div>
     </div>
    </div>
  );
}

export default ForgotPassword;