import { findValidPackedLunch, getPropObj } from '../functions/generalFunctions';

import { GiHamburger } from 'react-icons/gi';
import { LuSandwich } from 'react-icons/lu';
import React from 'react';
import { useSelector } from 'react-redux';

const PackedLunchOptions = ({ transfer, show, packedLunch, setPackedLunch, packedLunchChargeable, setPackedLunchChargeable, user }) => (
  <div className={`col-span-4 flex flex-col items-left p-4 ${transfer.objType !== 'arrival' && show ? 'bg-white rounded-lg border shadow-sm' : ''} h-full`}>
    <div className={`${transfer.objType !== 'arrival' && show ? '' : 'hidden'}`}>
      <div className="flex items-center">
        <LuSandwich size={30} className="text-black mr-2" />
        <div className="ml-auto">
          <input type="checkbox" name="packedLunchCheck" 
          //className="checkbox checkbox-accent"
          className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]" 
          checked={packedLunch} onChange={(e) => setPackedLunch(e.target.checked)} />
        </div>
      </div>

      <div className="text-stone-950 text-[15px] font-semibold my-3">Packed Lunch</div>
      <div className="text-neutral-500 text-sm font-normal">Depart with packed lunch</div>
      {user.internalUser ? (
        <div className="flex items-center">
          <div className={`text-neutral-500 text-sm font-normal ${packedLunch ? '' : 'hidden'}`}>Chargeable</div>
          <div className={`ml-auto ${packedLunch ? '' : 'hidden'}`}>
            {/* <input type="checkbox" name="packedLunchChargeableCheck" className="checkbox checkbox-accent" defaultChecked={packedLunchChargeable} />{' '} */}
            <input
              type="checkbox"
              name="packedLunchChargeableCheck"
              //className="checkbox checkbox-accent mt-2"
              className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white] mt-2"
              checked={packedLunchChargeable}
              onChange={(e) => {
                if (user.internalUser) {
                  setPackedLunchChargeable(e.target.checked);
                }
              }}
              disabled={!user.internalUser}
            />{' '}
          </div>
        </div>
      ) : (
        <input
          type="hidden"
          name="packedLunchChargeableCheck"
          //className="checkbox checkbox-accent mt-2"
          className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white] mt-2"
          checked={packedLunchChargeable}
          onChange={(e) => {
            if (user.internalUser) {
              setPackedLunchChargeable(e.target.checked);
            }
          }}
          disabled={!user.internalUser}
        />
      )}
    </div>
  </div>
);

const PackedLunch = ({ packedLunch, setPackedLunch, packedLunchChargeable, setPackedLunchChargeable, transfer, properties, booking, show }) => {
  const { user } = useSelector((state) => state.auth);
  const extras = properties[getPropObj(booking.propUid, properties)].extras;
  const unitPriceType = booking.propUid.includes('_D-I-R-E-C-T_') ? 'rackPrice' : 'netPrice';
  const extra = extras ? findValidPackedLunch(extras, booking.endDay, unitPriceType) : null;
  console.log('extras: ', JSON.stringify(extras, null, 2));
  console.log('booking.endDay: ', booking.endDay);
  console.log('unitPriceType: ', unitPriceType);
  console.log('extra: ', extra);

  // if (!extras || extras.length === 0 || !extra) {
  //   return null;
  // }

  console.log('packedLunch: ', packedLunch);
  console.log('packedLunchChargeable: ', packedLunchChargeable);
  //console.log('transfer.formValues.packedLunch: ', transfer.formValues.packedLunch);

  if (!extras || extras.length === 0 || !extra) {
    return (
      <>
        <input
          type="hidden"
          name="packedLunchChargeableCheck"
          defaultValue={false}
          onChange={(e) => {
            console.log('e: ', e.target.checked);
          }}
          disabled={!user.internalUser}
        />
        <input
          type="hidden"
          name="packedLunchCheck"
          defaultValue={false}
          onChange={(e) => {
            console.log('e: ', e.target.checked);
          }}
          disabled={!user.internalUser}
        />
      </>
    );
  }

  // console.log('packedLunch: ', packedLunch);
  // console.log('packedLunchChargeable: ', packedLunchChargeable);
  return (
    <PackedLunchOptions
      transfer={transfer}
      show={show}
      packedLunch={packedLunch}
      setPackedLunch={setPackedLunch}
      packedLunchChargeable={packedLunchChargeable}
      setPackedLunchChargeable={setPackedLunchChargeable}
      user={user}
    />
  );
};

export default PackedLunch;
