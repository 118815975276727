import { MdClose, MdOutlineSearch } from 'react-icons/md';

import { connectSearchBox } from 'react-instantsearch-dom';

const PropertiesSearchBox = ({ refine, currentRefinement, onChange }) => (
  <form>
    <div className={`relative`}>
      <MdOutlineSearch size={26} className="absolute left-2 top-2" />
      <input
        type="text"
        value={currentRefinement}
        className={`pl-10 w-[700px] rounded`}
        placeholder="Search Properties..."
        onChange={(event) => {
          refine(event.currentTarget.value);
          onChange(event);
        }}
      />
      <MdClose
        size={26}
        className={`absolute right-2 top-2 cursor-pointer`}
        onClick={() => {
          refine('');
          onChange({ currentTarget: { value: '' } });
        }}
      />
    </div>
  </form>
);

export default connectSearchBox(PropertiesSearchBox);
