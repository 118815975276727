import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../css/additional-styles/stepper.scss';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { FaCalendarAlt, FaCheckCircle, FaRegClock } from 'react-icons/fa';
import { MdErrorOutline, MdOutlineOtherHouses, MdPersonOutline } from 'react-icons/md';
import React, { useEffect, useRef, useState } from 'react';
import { addDays, differenceInDays, format, parseISO, startOfDay } from 'date-fns';
import { getPropObj, numberWithCommas } from '../../../functions/generalFunctions';
import { loadBooking, loadProperties } from '../../../functions/loadDataFunctions';
import { resRequestDisplay, resRequestPayable } from '../../../functions/platformFunctions';
import { updateItineraryStatus, updateItineraryStatusMojo, updateItineraryStatusQuotation } from '../../../functions/itineraryFunctions';
import { useNavigate, useParams } from 'react-router-dom';

import ActivityDetails from '../../../components/ActivityDetails';
import ButtonOutlineSmall from '../../../components/Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from '../../../components/Buttons/ButtonPrimarySmall';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
//import { MdPersonOutline } from 'react-icons/fa';
import { FiClipboard } from 'react-icons/fi';
import { GiJeep } from 'react-icons/gi';
import { ImNewTab } from 'react-icons/im';
import JSConfetti from 'js-confetti';
import JsonDetails from '../../../components/JsonDetails';
import { PiAirplaneTiltLight } from 'react-icons/pi';
import Sidebar from '../../../partials/Sidebar';
import TransferDetails from '../../../components/TransferDetails';
import { confirmAlert } from 'react-confirm-alert';
import { createUrl } from '../../../functions/makeFunctions';
import { deeShadow } from '../../../css/additional-styles/custom';
import { getTermsAndConditionsUrl } from '../../../constants/documentUrls';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// Nomad Supplier Uid and Booked Direct Supplier
const NOMAD_SUPPLIER_IDS = ['ded3a3ed-aeaf-4495-9069-7754a649de67', '8ae5bed6-e15e-4e79-8ab0-e934c2645abc'];

function ConfirmItinerary() {
  const { user } = useSelector((state) => state.auth);
  const { selectedCompanyNomadCompany } = useSelector((state) => state.companies);
  const navigate = useNavigate();

  const { allAirports } = useSelector((state) => state.airports);
  var airportsShort = allAirports.result;
  let tempAirports = JSON.parse(JSON.stringify(airportsShort));
  const [airports, setAirports] = useState(tempAirports);

  //Gererate link & copy to clipboard
  const inputRef = useRef(null);

  const handleCopyClick = async () => {
    if (generatedLink.length > 1) {
      try {
        await navigator.clipboard.writeText(generatedLink);
        toast.success('Link copied to clipboard');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    } else {
      toast.error('You must generate a link first');
    }
  };

  const handleCopyClickSummary = async () => {
    if (generatedLinkSummary.length > 1) {
      try {
        await navigator.clipboard.writeText(generatedLinkSummary);
        toast.success('Link copied to clipboard');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    } else {
      toast.error('You must generate a link first');
    }
  };

  const handleClickSummary = async (firestoreUid) => {
    setIsLoading(true);

    handleClickUrl(firestoreUid, 'summary');
  };

  const handleCopyClickExcel = async () => {
    if (generatedExcelCostingLink.length > 1) {
      try {
        await navigator.clipboard.writeText(generatedExcelCostingLink);
        toast.success('Link copied to clipboard');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    } else {
      toast.error('You must generate a link first');
    }
  };

  const handleClickExcel = async (firestoreUid) => {
    setIsLoading(true);
    handleClickUrl(firestoreUid, 'costing');
  };

  const handleCopyClickInvoice = async () => {
    if (makeInvoice.length > 1) {
      try {
        await navigator.clipboard.writeText(generatedInvoiceLink);
        toast.success('Link copied to clipboard');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    } else {
      toast.error('You must generate a link first');
    }
  };

  const handleClickInvoice = async (firestoreUid) => {
    setIsLoading(true);
    handleClickUrl(firestoreUid, 'invoice');
  };

  const handleClick = async (firestoreUid) => {
    setIsLoading(true);
    handleClickUrl(firestoreUid, 'brochure');
  };

  const handleClickTab = () => {
    window.open(generatedLink, '_blank');
  };

  const handleClickTabSummary = () => {
    window.open(generatedLinkSummary, '_blank');
  };

  const handleClickTabExcel = () => {
    window.open(generatedExcelCostingLink, '_blank');
  };

  const handleClickTabInvoice = () => {
    window.open(generatedInvoiceLink, '_blank');
  };

  const handleHoldProvisional = async () => {
    try {
      console.log('Expiry Date when holding provisional:', storeExpiryDate);
      await updateBooking('20', 'pendingProvisional');

      // show success message
      setSuccessMsg('provisional');
      toast.success('Booking held provisionally');
    } catch (error) {
      console.error('Error holding provisional:', error);
      toast.error('Failed to hold booking provisionally. Please try again.');
    }
  };

  // Terms and conditions
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState('');

  useEffect(() => {
    // Set the Terms and Conditions URL based on the selected company
    setTermsAndConditionsUrl(getTermsAndConditionsUrl(selectedCompanyNomadCompany));
  }, [selectedCompanyNomadCompany]);

  // ************************************* // STATE START
  //const [bookings, setBookings] = useState([]);
  const { uid } = useParams();

  const [rooms, setRooms] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isLoadingStock, setIsLoadingStock] = useState(false);

  // Dates etc

  // Properties
  const [properties, setProperties] = useState([]);

  const [bookings, setBookings] = useState([]);
  const [nomadOnlyItinerary, setNomadOnlyItinerary] = useState(true);

  const [show, setShow] = useState(true);

  const [successMsg, setSuccessMsg] = useState(null);

  const [rightSideBar, setRightSideBar] = useState('itinerary');
  const [resReqReservationId, setResReqReservationId] = useState('');
  const [bookingStatus, setBookingStatus] = useState(null);
  const [bookingStatusText, setBookingStatusText] = useState(null);
  const [bookingDateStart, setBookingDateStart] = useState(null);
  const [bookingDateExpiry, setBookingDateExpiry] = useState(null);
  const [bookingDateCancelled, setBookingDateCancelled] = useState(null);
  const [bookingDateConfirmed, setBookingconfirmed] = useState(null);
  const [bookingDateModified, setBookingDateModified] = useState(null);
  const [storeExpiryDate, setStoreExpiryDate] = useState(null);
  const [expiryEffect, setExpiryEffect] = useState(false);
  const [mojoItinerary, setMojoItinerary] = useState(false);
  const [quoteMode, setQuoteMode] = useState(false);

  const [makeStatus, setMakeStatus] = useState(0);
  const [generatedLink, setGeneratedLink] = useState('');
  const [makeStatusSummary, setMakeStatusSummary] = useState(0);
  const [generatedLinkSummary, setGeneratedLinkSummary] = useState('');

  const [makeExcelCosting, setMakeExcelCosting] = useState(0);
  const [generatedExcelCostingLink, setGeneratedExcelCostingLink] = useState('');

  const [makeInvoice, setMakeInvoice] = useState(0);
  const [generatedInvoiceLink, setInvoiceLink] = useState('');

  // Form states
  // const [height, setHeight] = useState(null);
  // const [width, setWidth] = useState(null);
  // const confetiRef = useRef(null);
  // Activities
  const [tempBookings, setTempBookings] = useState(null);
  const [activitiesData, setActivitiesData] = useState([]);
  const [activitiesCost, setActivitiesCost] = useState(0);
  const [transfersData, setTransfersData] = useState([]);
  const [jsonData, setJsonData] = useState([]);

  // ************************************* // STATE END

  const [confettiLoaded, setConfettiLoaded] = useState(true);

  useEffect(() => {
    if (!confettiLoaded && bookingDateModified === undefined && !isLoading) {
      const jsConfetti = new JSConfetti();
      jsConfetti.addConfetti();
      setConfettiLoaded(true);
    }
  }, [bookingDateModified, isLoading, confettiLoaded]);

  const makeExpiryDate = (tempDateStart) => {
    console.log('tempDateStart:', tempDateStart);
    const dateStart = startOfDay(parseISO(tempDateStart));
    const today = startOfDay(new Date());
    const daysDifference = differenceInDays(dateStart, today);

    let newExpiryDate;
    if (daysDifference <= 30) {
      newExpiryDate = addDays(today, 3);
    } else {
      newExpiryDate = addDays(today, 7);
    }
    const formattedExpiryDate = format(newExpiryDate, 'yyyy-MM-dd');
    console.log('New expiry date:', formattedExpiryDate);
    setStoreExpiryDate(formattedExpiryDate);
    return formattedExpiryDate;
  };

  // Load bookings loadBooking
  useEffect(() => {
    async function fetchData() {
      // You can await here
      let tempBookings = await loadBooking(uid);
      setTempBookings(tempBookings);
      if (tempBookings.activitiesData) {
        const originalActivitiesData = tempBookings.activitiesData.map((activityData) => {
          const { activities, ...rest } = activityData;
          const originalActivities = activities.map((activity) => {
            return activity.values;
          });
          return { ...rest, activities: originalActivities };
        });
        setActivitiesData(originalActivitiesData);
        console.log('originalActivitiesData: ' + JSON.stringify(originalActivitiesData, undefined, 4));
        let totalCost = 0;
        originalActivitiesData.forEach((property) => {
          property.activities.forEach((day) => {
            day.forEach((activity) => {
              totalCost += activity.cost;
            });
          });
        });
        setActivitiesCost(totalCost);
        console.log('total cost: ' + totalCost);
      }

      if (tempBookings.jsonData.length > 0) {
        setJsonData(tempBookings.jsonData);
      }

      if (tempBookings.transfersData) {
        setTransfersData(tempBookings.transfersData);
      }
      setBookings(tempBookings.itineraryData);
      console.log('bookings:', JSON.stringify(bookings, null, 2));
      setResReqReservationId(tempBookings.resReqReservationId);
      setBookingStatus(tempBookings.resRequestStatus);
      setBookingStatusText(tempBookings.status);
      setBookingDateStart(tempBookings.dateStart);
      setBookingDateExpiry(tempBookings.dateExpiry);
      setBookingDateCancelled(tempBookings.dateCancelled);
      //setBookingDateConfirmed(tempBookings.dateConfirmed);
      setBookingDateModified(tempBookings.dateModified);
      setMojoItinerary(tempBookings.mojoItinerary ? tempBookings.mojoItinerary : false);
      makeExpiryDate(tempBookings.dateStart);
      setQuoteMode(tempBookings.quoteMode);
      //console.log("Properties: " + JSON.stringify(properties, undefined, 4));
      //console.log("Bookings: " + JSON.stringify(tempBookings.itineraryData, undefined, 4));

      for (let i = 0; i < tempBookings.itineraryData.length; i++) {
        await loadProperties(tempBookings.itineraryData[i].propUid, properties, setProperties);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [properties, uid]);

  // Add this useEffect to check for 3rd party suppliers
  useEffect(() => {
    if (bookings.length > 0) {
      const hasThirdPartySupplier = bookings.some((booking) => !NOMAD_SUPPLIER_IDS.includes(booking.supplierUid));
      setNomadOnlyItinerary(!hasThirdPartySupplier);
    }
  }, [bookings]);

  const updateBooking = async (code, text, itineraryCode, mojo) => {
    //console.log(itineraryCode ? itineraryCode : "No itinerary code");
    setIsLoading(true);
    setSuccessMsg(text);
    console.log('CODE: ' + code);

    const nomadTanzaniaBookings = bookings.filter((booking) => resRequestDisplay(booking.propUid, properties));
    const count = nomadTanzaniaBookings.length;
    console.log('count: ' + count);

    console.log('resReqReservationId: ' + resReqReservationId);

    try {
      if (quoteMode) {
        await updateItineraryStatusQuotation(resReqReservationId, code, text, uid, setShow, setIsLoading, user.uid, false);
      } else if (mojo) {
        await updateItineraryStatusMojo(resReqReservationId, code, text, uid, setShow, setIsLoading, count, user.uid, storeExpiryDate, false, itineraryCode && itineraryCode);
      } else {
        await updateItineraryStatus(resReqReservationId, code, text, uid, setShow, setIsLoading, count, user.uid, storeExpiryDate, false, itineraryCode && itineraryCode);
      }
    } catch (error) {
      console.error('Error updating booking:', error);
      setIsLoading(false);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  // Save booking to FB
  // console.log("bookings.length");
  // console.log(bookings.result.accommodationData.data.length);
  //alert(quoteMode);

  // New download code
  const handleClickUrl = async (firestoreUid, item) => {
    const result = await createUrl(firestoreUid, item);
    console.log('result new:', JSON.stringify(result, null, 2));
    if (result.error) {
      toast.error(result.errorMsg);
      setIsLoading(false);
    } else {
      if (item === 'brochure') {
        setMakeStatus(1);
        setGeneratedLink(result.copy);
        setIsLoading(false);
      } else if (item === 'invoice') {
        setMakeInvoice(1);
        setInvoiceLink(result.download);
        setIsLoading(false);
      } else if (item === 'summary') {
        setMakeStatusSummary(1);
        setGeneratedLinkSummary(result.copy);
        setIsLoading(false);
      } else if (item === 'costing') {
        setMakeExcelCosting(1);
        setGeneratedExcelCostingLink(result.copy);
        setIsLoading(false);
      }

      //setMakeExcelCosting
    }
    // setGenerating(false);
  };

  return (
    <div id="confetti-canvas">
      <div className="flex h-screen overflow-hidden">
        <CircleSpinnerOverlay loading={loadingData} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />

        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto ">
          {/*  Site header */}
          {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

          <main>
            <div className="container mx-auto min-w-[1300px] max-w-7xl px-4 sm:px-6 lg:px-8">
              {/* <div className="confettie-wrap" ref={confetiRef}>
              <h3>Confettie Effect </h3>
              <Confetti numberOfPieces={150} width={width} height={height} />
            </div> */}
              {/* Page header */}

              {/* Left: Title */}
              <div className="h-14 flex items-center pl-5 text-xs">
                {/* <h1 className="text-2xl brand-text-color-800 font-semibold">Confirm your itinerary</h1> */}
                <div className="w-4"></div>
                {user.uid === 'cusv2NSxlYOBeL3QnHvPfBHWjQ63' ? (
                  <>
                    <button
                      onClick={() => {
                        console.log(JSON.stringify(storeExpiryDate, undefined, 4));
                      }}
                    >
                      console.log(storeExpiryDate);
                    </button>
                    <div className="w-4"></div>
                    <button
                      onClick={() => {
                        console.log('ROOMS');
                        console.log(JSON.stringify(rooms, undefined, 4));
                      }}
                    >
                      console.log(rooms);
                    </button>
                    <div className="w-4"></div>
                    <button
                      onClick={() => {
                        console.log('itineraries');
                        console.log(JSON.stringify(bookings, undefined, 4));
                      }}
                    >
                      console.log(bookings);
                    </button>
                    <div className="w-4"></div>
                    <button
                      onClick={() => {
                        console.log('bookingDateStart');
                        console.log(bookingDateStart);
                        console.log(moment(bookingDateStart.seconds * 1000).format('YYYY-MM-DD'));
                      }}
                    >
                      console.log("bookingDateStart");
                    </button>
                    <div className="w-4"></div>
                    <button
                      onClick={() => {
                        console.log('bookingDateExpiry');
                        console.log(bookingDateExpiry);
                      }}
                    >
                      console.log("bookingDateExpiry");
                    </button>
                    <div className="w-4"></div>
                    <button
                      onClick={() => {
                        console.log('generatedLink');
                        console.log(generatedLink);
                      }}
                    >
                      console.log("generatedLink");
                    </button>
                    <div className="w-4"></div>
                    <button
                      onClick={() => {
                        console.log('transfersData');
                        console.log(JSON.stringify(transfersData, undefined, 4));
                      }}
                    >
                      console.log("transfersData");
                    </button>
                    <div className="w-4"></div>
                    <button
                      onClick={() => {
                        console.log('jsonData');
                        console.log(JSON.stringify(jsonData, undefined, 4));
                      }}
                    >
                      console.log("jsonData");
                    </button>
                  </>
                ) : null}
              </div>
              {isLoading ? (
                // <Spinner />
                <CircleSpinnerOverlay loading={isLoading} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />
              ) : (
                <div>
                  <div>
                    {/* LEFT AND RIGHT LAYOUT FOR PROPERTIES & RESULTS START */}
                    {/* <div className="grid grid-cols-2 gap-5 px-5  w-[1640px]"> */}
                    <div className="grid grid-flow-col auto-cols-max px-5">
                      {/* LEFT COLUMN FOR PROPERTIES START */}
                      <div>
                        {' '}
                        {/* PROPERTIES START */}
                        <>
                          {/* SECTION 1: PROPERTIES START */}
                          <div style={deeShadow} className="p-5 bg-white rounded-lg w-[1000px]">
                            {show ? (
                              <div>
                                {bookingDateModified === undefined || bookingStatus === '0' || bookingStatusText === 'draft' || bookingStatusText === 'quotation' || bookingStatusText === 'expired' ? (
                                  <div className="text-2xl brand-text-color-800 font-semibold mb-5">Confirmation options</div>
                                ) : (
                                  <div className="text-2xl brand-text-color-800 font-semibold mb-5">Itinerary status already set</div>
                                )}

                                {bookingDateModified === undefined || bookingStatus === '0' || bookingStatusText === 'draft' || bookingStatusText === 'quotation' || bookingStatusText === 'expired' ? (
                                  bookingStatus !== 'x' ? (
                                    <div className="ml-5">
                                      {/* {bookingStatusText !== 'quotation' && ( */}
                                      {!quoteMode && (
                                        <>
                                          {' '}
                                          <div className="text-sm brand-text-color-800 font-normal mb-5">
                                            <p className="text-xl font-semibold mb-5 flex items-center">
                                              <FaCalendarAlt className="mr-2" /> Hold Your Itinerary
                                            </p>

                                            <p className="mb-3">By selecting this option, you will:</p>

                                            <ul className="list-disc pl-5 mb-3">
                                              <li className="mb-2">Secure a temporary hold on all Nomad Tanzania properties in your itinerary.</li>
                                              {!nomadOnlyItinerary && <li className="mb-2">Initiate hold requests for any third-party properties included in your booking.</li>}
                                            </ul>

                                            <p className="mb-3">
                                              <strong>Important:</strong> Your hold will automatically expire on{' '}
                                              <span className="font-semibold">
                                                {moment.utc(storeExpiryDate).subtract(0, 'days').startOf('day').add(12, 'hours').format('MMMM D, YYYY')} at 23:59 UTC
                                              </span>{' '}
                                              unless you confirm your itinerary before then.
                                            </p>

                                            {!nomadOnlyItinerary && (
                                              <p className="mb-3">
                                                <strong>Note:</strong> Third-party properties may have different hold policies. We'll promptly notify you of any variations in hold durations upon
                                                receiving their responses.
                                              </p>
                                            )}

                                            <p>To finalize your booking, please ensure you confirm your itinerary before the expiration date.</p>

                                            <div className="flex justify-end pt-3 gap-8 mr-4">
                                              <ButtonPrimarySmall
                                                onClick={async () => {
                                                  confirmAlert({
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                        <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                                                          <p className="text-sm font-semibold pb-2">Are you sure you would like to hold this booking on provisional status?</p>
                                                          <p className="text-sm pb-2">This will immediately update your Nomad services as “Provisional”. Other services will be requested.</p>
                                                          <p className="text-sm pb-2">
                                                            This booking will automatically expire on{' '}
                                                            {moment.utc(storeExpiryDate).subtract(0, 'days').startOf('day').add(12, 'hours').format('Do MMM YYYY')} at 23:59 UTC.
                                                          </p>

                                                          <div className="flex justify-end mt-4 gap-4">
                                                            <ButtonOutlineSmall
                                                              onClick={() => {
                                                                onClose();
                                                              }}
                                                              text="Back"
                                                            />
                                                            <ButtonPrimarySmall
                                                              onClick={async () => {
                                                                await handleHoldProvisional();
                                                                onClose();
                                                              }}
                                                              text="Hold Provisionally"
                                                            />
                                                          </div>
                                                        </div>
                                                      );
                                                    },
                                                  });
                                                }}
                                                text="Hold on Provisional"
                                              />
                                            </div>
                                            <div className="border-b border-slate-400 my-5 mr-5"></div>
                                          </div>
                                          <div className="text-sm brand-text-color-800 font-normal mb-5">
                                            <p className="text-xl font-semibold mb-5 flex items-center">
                                              <FaCheckCircle className="mr-2" /> Confirm Your Itinerary
                                            </p>

                                            <p className="mb-3">By selecting this option, you will initiate the following process:</p>

                                            <ul className="list-disc pl-5 mb-3">
                                              <li className="mb-2">All Nomad Tanzania properties in your itinerary will be confirmed immediately.</li>
                                              {!nomadOnlyItinerary && <li className="mb-2">Our team will send confirmation requests to all third-party properties included in your booking.</li>}
                                              <li className="mb-2">Our sales consultant will review your itinerary, offering any suggestions or improvements.</li>
                                              <li className="mb-2">Once reviewed, we will send you the invoice and confirmation details.</li>
                                            </ul>

                                            <div className="flex justify-end gap-8 mr-4">
                                              <ButtonPrimarySmall
                                                onClick={async () => {
                                                  confirmAlert({
                                                    customUI: ({ onClose }) => {
                                                      const handleRadioButtonClick = (event) => {
                                                        document.getElementById('yesContent').style.display = event.target.value === 'yes' ? 'block' : 'none';
                                                        document.getElementById('noContent').style.display = event.target.value === 'no' ? 'block' : 'none';
                                                        document.getElementById('backButton').style.display = 'none';
                                                      };
                                                      //document.getElementById('backButton').style.display = 'block';

                                                      return (
                                                        <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                                                          {!user.internalUser ? (
                                                            <div className="mb-3">
                                                              <p className="text-sm font-semibold pb-2">Confirm your booking</p>
                                                              <p className="text-sm pb-2">Are you ready to confirm this booking?</p>
                                                              <p className="text-sm pb-0">Please ensure all information is accurate before proceeding.</p>
                                                              <p className="text-sm pb-0">By confirming, you agree to finalise this booking and adhere</p>
                                                              <p className="text-sm pb-2">
                                                                to the{' '}
                                                                <a href={termsAndConditionsUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 underline">
                                                                  Booking Terms and Conditions
                                                                </a>
                                                                .
                                                              </p>
                                                              <div className="flex mt-5 justify-end gap-4">
                                                                <ButtonOutlineSmall
                                                                  onClick={() => {
                                                                    onClose();
                                                                  }}
                                                                  text="Close"
                                                                />
                                                                <ButtonPrimarySmall
                                                                  onClick={async () => {
                                                                    const itineraryCode = '0';
                                                                    // NEW updateBooking
                                                                    await updateBooking('30', 'pendingConfirmation', itineraryCode.replace(/,/g, ''), false);

                                                                    // await updateBooking(
                                                                    //   '30',
                                                                    //   'confirmed',
                                                                    //   selectedHit.resReqReservationId,
                                                                    //   selectedHit.uid,
                                                                    //   selectedHit.resRequestStatus === 'x' ? 0 : 1,
                                                                    //   itineraryCode.replace(/,/g, ''),
                                                                    //   false,
                                                                    // );
                                                                    onClose();
                                                                  }}
                                                                  text="Confirm Booking"
                                                                />
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <div>
                                                              <div className="mb-3">
                                                                <p className="text-sm font-semibold pb-2">Will the full itinerary be created in Mojo?</p>
                                                                {mojoItinerary ? null : (
                                                                  <div className="flex">
                                                                    <div>
                                                                      <input type="radio" id="yes" name="itineraryChoice" value="yes" onClick={handleRadioButtonClick} />{' '}
                                                                      <label htmlFor="yes">Yes</label>
                                                                    </div>
                                                                    <div className="ml-5">
                                                                      <input type="radio" id="no" name="itineraryChoice" value="no" onClick={handleRadioButtonClick} /> <label htmlFor="no">No</label>
                                                                    </div>
                                                                  </div>
                                                                )}
                                                              </div>
                                                              <div id="yesContent" style={{ display: mojoItinerary ? 'block' : 'none' }}>
                                                                {/* <p className="text-sm font-semibold pb-2">Are you sure you would like to confirm this booking?</p> */}
                                                                <p className="text-sm pb-2">Enter the Mojo itinerary code to confirm your itinerary</p>
                                                                <input
                                                                  id="itineraryCodeInput"
                                                                  className="text-sm border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                  type="text"
                                                                  placeholder="Enter Mojo Itinerary Code"
                                                                />
                                                                <div className="flex mt-5 justify-end gap-4">
                                                                  <ButtonOutlineSmall
                                                                    onClick={() => {
                                                                      onClose();
                                                                    }}
                                                                    text="Back"
                                                                  />
                                                                  <ButtonPrimarySmall
                                                                    onClick={async () => {
                                                                      const itineraryCode = document.getElementById('itineraryCodeInput').value;

                                                                      if (!itineraryCode) {
                                                                        toast.error('Please enter Mojo Itinerary Code');
                                                                        return;
                                                                      }

                                                                      const itineraryCodeNumber = parseInt(itineraryCode.replace(/,/g, ''), 10);
                                                                      if (isNaN(itineraryCodeNumber) || itineraryCodeNumber < 10000 || itineraryCodeNumber > 99999) {
                                                                        toast.error('Itinerary code must be a number between 10,000 and 99,999');
                                                                        return;
                                                                      }
                                                                      await updateBooking('30', 'pendingConfirmation', itineraryCode.replace(/,/g, ''), true);

                                                                      // await updateBooking(
                                                                      //   '30',
                                                                      //   'confirmed',
                                                                      //   selectedHit.resReqReservationId,
                                                                      //   selectedHit.uid,
                                                                      //   selectedHit.resRequestStatus === 'x' ? 0 : 1,
                                                                      //   itineraryCode.replace(/,/g, ''),
                                                                      //   true,
                                                                      // );
                                                                      onClose();
                                                                    }}
                                                                    text="Confirm Booking"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div id="noContent" style={{ display: 'none' }}>
                                                                <p className="text-sm pb-2">Click to confirm this as a Hornbill itinerary and process to Creatio.</p>
                                                                <div className="flex mt-5 justify-end gap-4">
                                                                  <ButtonOutlineSmall
                                                                    onClick={() => {
                                                                      onClose();
                                                                    }}
                                                                    text="Back"
                                                                  />
                                                                  <ButtonPrimarySmall
                                                                    onClick={async () => {
                                                                      const itineraryCode = '0';
                                                                      await updateBooking('30', 'pendingConfirmation', itineraryCode.replace(/,/g, ''), false);

                                                                      // await updateBooking(
                                                                      //   '30',
                                                                      //   'confirmed',
                                                                      //   selectedHit.resReqReservationId,
                                                                      //   selectedHit.uid,
                                                                      //   selectedHit.resRequestStatus === 'x' ? 0 : 1,
                                                                      //   itineraryCode.replace(/,/g, ''),
                                                                      //   false,
                                                                      // );
                                                                      onClose();
                                                                    }}
                                                                    text="Confirm Booking"
                                                                  />
                                                                </div>
                                                              </div>

                                                              <div id="backButton" style={{ display: mojoItinerary ? 'none' : 'block' }}>
                                                                <div className="flex mt-5 justify-end">
                                                                  <ButtonOutlineSmall
                                                                    onClick={() => {
                                                                      onClose();
                                                                    }}
                                                                    text="Back"
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      );
                                                    },
                                                  });
                                                }}
                                                disabled={true}
                                                text="Confirm Now" 
                                              />
                                            </div>
                                            <div className="border-b border-slate-400 my-5 mr-5"></div>
                                          </div>
                                        </>
                                      )}
                                      <div className="text-sm brand-text-color-800 font-normal mb-5">
                                        <p className="text-xl font-semibold mb-5 flex items-center">
                                          <FaRegClock className="mr-2" /> Not Ready to Book?
                                        </p>
                                        <p>If you're not ready to book your trip yet, you can save your itinerary as a quote. This option does not secure your travel arrangements.</p>
                                        <div className="flex justify-end gap-8 pt-3 mr-4">
                                          <ButtonOutlineSmall
                                            onClick={async () => {
                                              confirmAlert({
                                                customUI: ({ onClose }) => {
                                                  return (
                                                    <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                                                      <p className="text-sm font-semibold pb-2">Are you sure you want to save this booking as a quotation?</p>
                                                      <p className="text-sm pb-2">The space is currently being held while you confirm the booking.</p>
                                                      <p className="text-sm pb-2"> If you click confirm your booking will be converted to a quotation.</p>
                                                      <div className="flex mt-5 justify-end gap-4">
                                                        <ButtonOutlineSmall
                                                          onClick={() => {
                                                            onClose();
                                                          }}
                                                          text="Close"
                                                        />
                                                        <ButtonPrimarySmall
                                                          onClick={async () => {
                                                            if (bookingStatusText === 'quotation') {
                                                              navigate('/itinerary/manage/manageItineraries/', {
                                                                replace: true,
                                                              });
                                                            } else {
                                                              await updateBooking('0', 'quotation');
                                                            }

                                                            onClose();
                                                          }}
                                                          text="Save as Quote"
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                },
                                              });
                                            }}
                                            text="Save as Quote"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="text-sm brand-text-color-800 font-normal mb-5">
                                      <div>You are unable to confirm this itinerary as it does not contain any Nomad Properties.</div>
                                    </div>
                                  )
                                ) : bookingStatus !== 'x' ? (
                                  <div>
                                    <div className="text-sm brand-text-color-800 font-normal mb-5 ml-5">
                                      <div>This itinerary's status has already been set, please click the "Manage Itineraries" button.</div>
                                    </div>
                                    <div className="flex justify-between align-items-center my-5 brand-text-grey-v2 text-sm">
                                      <div className="flex items-center flex-1 ml-5">Booking reference: {resReqReservationId}</div>
                                      <ButtonOutlineSmall
                                        onClick={async () => {
                                          navigate('/itinerary/manage/manageItineraries/', { replace: true });
                                        }}
                                        color="dark"
                                        text="Manage Itineraries"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="text-sm brand-text-color-800 font-normal mb-5">
                                    <div>You are unable to confirm this itinerary as it does not contain any Nomad Properties.</div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            ) : successMsg === 'provisional' ? (
                              <div>
                                <h2 className="text-2xl brand-text-color-800 font-semibold mb-5">Next steps</h2>
                                <div className="mx-5">
                                  <p className="text-xl brand-text-color-800 font-semibold mb-5">Provisional Hold Confirmed</p>

                                  <div className="text-sm brand-text-grey-v2 font-normal mb-5">
                                    <p className="mb-5">
                                      Your Nomad properties have been temporarily held with a provisional status. Please note that this hold will expire automatically on{' '}
                                      {moment.utc(storeExpiryDate).subtract(0, 'days').startOf('day').add(12, 'hours').format('Do MMM YYYY')} at 23:59 UTC unless you confirm your itinerary.
                                    </p>
                                    <p>
                                      If you need more time, you can extend your hold for 7 days within 2 days of the expiry date. Simply go to the "Manage Itineraries" section, locate your Itinerary,
                                      and click "Extend.
                                    </p>

                                    <div className="flex justify-between align-items-center my-5 brand-text-grey-v2">
                                      <div className="flex items-center flex-1">Booking reference: {resReqReservationId}</div>
                                      <ButtonOutlineSmall
                                        onClick={async () => {
                                          navigate('/itinerary/manage/manageItineraries/', { replace: true });
                                        }}
                                        text="Manage Itineraries"
                                        color="dark"
                                      />
                                    </div>
                                    <div className="border-b border-slate-400 my-5"></div>
                                    <p className="text-xl brand-text-color-800 font-semibold mb-5">Documents</p>

                                    <>
                                      <div className="brand-text-grey-v2">Generate a Safari Portal itinerary</div>
                                      <div className="flex justify-between align-items-center my-5">
                                        <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClick(uid)}>
                                          Click to generate
                                        </div>
                                        <input
                                          className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                          type="text"
                                          value={generatedLink}
                                          placeholder="Click to the left to generate a link"
                                          readOnly
                                          ref={inputRef}
                                          onClick={handleCopyClick}
                                        />

                                        <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClick} />
                                        {makeStatus === 1 && generatedLink !== undefined && <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTab} />}
                                      </div>

                                      {user.internalUser && (
                                        <>
                                          <div className="brand-text-grey-v2 pt-5">Generate a ResRequest Summary</div>
                                          <div className="flex justify-between align-items-center my-5">
                                            <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClickSummary(uid)}>
                                              Click to generate
                                            </div>
                                            <input
                                              className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                              type="text"
                                              value={generatedLinkSummary}
                                              placeholder="Click to the left to generate a link"
                                              readOnly
                                              ref={inputRef}
                                              onClick={handleCopyClickSummary}
                                            />

                                            <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClickSummary} />
                                            {makeStatusSummary === 1 && generatedLinkSummary !== undefined && (
                                              <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTabSummary} />
                                            )}
                                          </div>
                                        </>
                                      )}

                                      <div className="brand-text-grey-v2 pt-5">Generate an Excel costing</div>
                                      <div className="flex justify-between align-items-center my-5">
                                        <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClickExcel(uid)}>
                                          Click to generate
                                        </div>
                                        <input
                                          className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                          type="text"
                                          value={generatedExcelCostingLink}
                                          placeholder="Click to the left to generate a link"
                                          readOnly
                                          ref={inputRef}
                                          onClick={handleCopyClickExcel}
                                        />

                                        <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClickExcel} />
                                        {makeExcelCosting === 1 && generatedExcelCostingLink !== undefined && (
                                          <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTabExcel} />
                                        )}
                                      </div>
                                      {(bookingStatus === 'confirmed' || bookingStatus === 'cancelled') && (
                                        <>
                                          <div className="brand-text-grey-v2 pt-5">Generate an invoice</div>
                                          <div className="flex justify-between align-items-center my-5">
                                            <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClickInvoice(uid)}>
                                              Click to generate
                                            </div>
                                            <input
                                              className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                              type="text"
                                              value={generatedInvoiceLink}
                                              placeholder="Click to the left to generate a link"
                                              readOnly
                                              ref={inputRef}
                                              onClick={handleCopyClickInvoice}
                                            />

                                            <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClickInvoice} />
                                            {makeInvoice === 1 && generatedInvoiceLink !== undefined && (
                                              <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTabInvoice} />
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  </div>
                                </div>
                              </div>
                            ) : successMsg === 'confirmed' ? (
                              <div>
                                <h2 className="text-2xl brand-text-color-800 font-semibold mb-5">Next steps</h2>
                                <div className="mx-5">
                                  <p className="text-xl brand-text-color-800 font-semibold mb-5">Reservation Confirmed</p>
                                  <div className="text-sm brand-text-grey-v2">
                                    Your Nomad properties have been successfully confirmed! You can view and manage your itinerary by clicking on the "Manage Itinerary" button below.
                                  </div>
                                  <div className="text-sm brand-text-grey-v2 font-normal mb-5">
                                    <div className="flex justify-between align-items-center my-5">
                                      <div className="flex items-center flex-1">Booking reference: {resReqReservationId}</div>
                                      <ButtonOutlineSmall
                                        onClick={async () => {
                                          navigate('/itinerary/manage/manageItineraries/', { replace: true });
                                        }}
                                        text="Manage Itineraries"
                                        color="dark"
                                      />
                                    </div>
                                    <div className="border-b border-slate-400 my-5"></div>
                                    <p className="text-xl brand-text-color-800 font-semibold mb-5">Documents</p>
                                    <div className="brand-text-grey-v2">Generate a Safari Portal itinerary</div>
                                    <div className="flex justify-between align-items-center my-5">
                                      <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClick(uid)}>
                                        Click to generate
                                      </div>
                                      <input
                                        className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                        type="text"
                                        value={generatedLink}
                                        placeholder="Click to the left to generate a link"
                                        readOnly
                                        ref={inputRef}
                                        onClick={handleCopyClick}
                                      />

                                      <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClick} />
                                      {makeStatus === 1 && generatedLink !== undefined && <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTab} />}
                                    </div>

                                    {user.internalUser && (
                                      <>
                                        <div className="brand-text-grey-v2 pt-5">Generate a ResRequest Summary</div>
                                        <div className="flex justify-between align-items-center my-5">
                                          <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClickSummary(uid)}>
                                            Click to generate
                                          </div>
                                          <input
                                            className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                            type="text"
                                            value={generatedLinkSummary}
                                            placeholder="Click to the left to generate a link"
                                            readOnly
                                            ref={inputRef}
                                            onClick={handleCopyClickSummary}
                                          />

                                          <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClickSummary} />
                                          {makeStatusSummary === 1 && generatedLinkSummary !== undefined && (
                                            <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTabSummary} />
                                          )}
                                        </div>
                                      </>
                                    )}

                                    <div className="brand-text-grey-v2 pt-5">Generate an Excel costing</div>
                                    <div className="flex justify-between align-items-center my-5">
                                      <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClickExcel(uid)}>
                                        Click to generate
                                      </div>
                                      <input
                                        className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                        type="text"
                                        value={generatedExcelCostingLink}
                                        placeholder="Click to the left to generate a link"
                                        readOnly
                                        ref={inputRef}
                                        onClick={handleCopyClickExcel}
                                      />

                                      <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClickExcel} />
                                      {makeExcelCosting === 1 && generatedExcelCostingLink !== undefined && (
                                        <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTabExcel} />
                                      )}
                                    </div>
                                    {(bookingStatus === 'confirmed' || bookingStatus === 'cancelled') && (
                                      <>
                                        <div className="brand-text-grey-v2 pt-5">Generate an invoice</div>
                                        <div className="flex justify-between align-items-center my-5">
                                          <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClickInvoice(uid)}>
                                            Click to generate
                                          </div>
                                          <input
                                            className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                            type="text"
                                            value={generatedInvoiceLink}
                                            placeholder="Click to the left to generate a link"
                                            readOnly
                                            ref={inputRef}
                                            onClick={handleCopyClickInvoice}
                                          />

                                          <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClickInvoice} />
                                          {makeInvoice === 1 && generatedInvoiceLink !== undefined && (
                                            <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTabInvoice} />
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              successMsg === 'quotation' && (
                                <div>
                                  <p className="text-2xl brand-text-color-800 font-semibold mb-5">Next steps</p>

                                  <div className="ml-5">
                                    <p className="text-xl brand-text-color-800 font-semibold mb-5">Itinerary Saved as Quote</p>
                                    <div className="text-sm brand-text-grey-v2 font-normal mb-5">
                                      <p className="text-sm">Your itinerary has been saved as a quote. You can view and manage your itinerary by clicking on the "Manage Itineraries" button below.</p>
                                      <div>
                                        <br />
                                      </div>
                                      <div className="text-sm brand-text-grey-v2 font-normal mb-5">
                                        <div className="flex justify-between align-items-center">
                                          <div className="flex items-center flex-1">Booking reference: {resReqReservationId}</div>
                                          <ButtonOutlineSmall
                                            onClick={async () => {
                                              navigate('/itinerary/manage/manageItineraries/', { replace: true });
                                            }}
                                            text="Manage Itineraries"
                                            color="dark"
                                          />
                                        </div>
                                        <div className="border-b border-slate-400 my-5"></div>
                                        <p className="text-xl brand-text-color-800 font-semibold mb-5">Documents</p>
                                        <div className="brand-text-grey-v2">Generate a Safari Portal itinerary</div>
                                        <div className="flex justify-between align-items-center my-5">
                                          <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClick(uid)}>
                                            Click to generate
                                          </div>
                                          <input
                                            className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                            type="text"
                                            value={generatedLink}
                                            placeholder="Click to the left to generate a link"
                                            readOnly
                                            ref={inputRef}
                                            onClick={handleCopyClick}
                                          />

                                          <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClick} />
                                          {makeStatus === 1 && generatedLink !== undefined && <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTab} />}
                                        </div>

                                        {user.internalUser && (
                                          <>
                                            <div className="brand-text-grey-v2 pt-5">Generate a ResRequest Summary</div>
                                            <div className="flex justify-between align-items-center my-5">
                                              <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClickSummary(uid)}>
                                                Click to generate
                                              </div>
                                              <input
                                                className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                                type="text"
                                                value={generatedLinkSummary}
                                                placeholder="Click to the left to generate a link"
                                                readOnly
                                                ref={inputRef}
                                                onClick={handleCopyClickSummary}
                                              />

                                              <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClickSummary} />
                                              {makeStatusSummary === 1 && generatedLinkSummary !== undefined && (
                                                <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTabSummary} />
                                              )}
                                            </div>
                                          </>
                                        )}

                                        <div className="brand-text-grey-v2 pt-5">Generate an Excel costing</div>
                                        <div className="flex justify-between align-items-center my-5">
                                          <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClickExcel(uid)}>
                                            Click to generate
                                          </div>
                                          <input
                                            className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                            type="text"
                                            value={generatedExcelCostingLink}
                                            placeholder="Click to the left to generate a link"
                                            readOnly
                                            ref={inputRef}
                                            onClick={handleCopyClickExcel}
                                          />

                                          <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClickExcel} />
                                          {makeExcelCosting === 1 && generatedExcelCostingLink !== undefined && (
                                            <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTabExcel} />
                                          )}
                                        </div>

                                        {(bookingStatus === 'confirmed' || bookingStatus === 'cancelled') && (
                                          <>
                                            <div className="brand-text-grey-v2 pt-5">Generate an invoice</div>
                                            <div className="flex justify-between align-items-center my-5">
                                              <div className="flex items-center flex-1 mr-5 whitespace-nowrap cursor-pointer text-nomadEvergreen-700" onClick={() => handleClickInvoice(uid)}>
                                                Click to generate
                                              </div>
                                              <input
                                                className="rounded-sm  w-full flex-grow-1 text-sm hover:border-slate-400 focus:border-slate-400 focus:outline-none shadow-white"
                                                type="text"
                                                value={generatedInvoiceLink}
                                                placeholder="Click to the left to generate a link"
                                                readOnly
                                                ref={inputRef}
                                                onClick={handleCopyClickInvoice}
                                              />

                                              <FiClipboard size={40} className="inline-block ml-5 cursor-pointer" onClick={handleCopyClickInvoice} />
                                              {makeInvoice === 1 && generatedInvoiceLink !== undefined && (
                                                <ImNewTab size={40} className="inline-block ml-5 cursor-pointer" onClick={handleClickTabInvoice} />
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                            {/* SPACER */}
                          </div>
                          {/* SECTION 1: PROPERTIES END */}
                          {/* SPACER */}
                          <div className="h-5"></div>

                          {/* SPACER */}
                          <div className="h-5"></div>
                          {/* SECTION 3: PROPERTIES START */}

                          {/* SECTION 3: PROPERTIES END */}
                        </>
                        {/* PROPERTIES END */}
                      </div>
                      {/* LEFT COLUMN FOR PROPERTIES END */}

                      {/* RIGHT COLUMN FOR RESULTS START */}
                      {/* Show and hide availability */}

                      {/* NEW JSON OBJECT */}
                      {jsonData.length > 0 && 1 > 2 && (
                        <div className="pl-5">
                          <div style={deeShadow} className="p-5 mb-5 bg-white rounded-lg w-[340px]">
                            {/* TABS */}
                            {/* TODO: Deal with border color and dynamic colors */}

                            {/* MAKE CONTENT OF DIV BELOW A SCROLLABLE AREA */}
                            <div className={`grid grid-cols-12`}>
                              {/* <div className="col-span-3">
                            <div className="text-base brand-text-color-800 font-bold">Your itinerary</div>
                          </div> */}
                              {/* <Scrollbar style={{ width: 320, height: 675 }}> */}

                              <div className="col-span-12">
                                <div className="flex">
                                  <div
                                    className={`text-sm mr-2 cursor-pointer ${rightSideBar === 'itinerary' ? 'brand-text-color-800 font-bold' : 'brand-text-grey-v2 font-normal'}`}
                                    onClick={() => setRightSideBar('itinerary')}
                                  >
                                    Itinerary details
                                  </div>
                                  <div
                                    className={`text-sm mr-2 cursor-pointer ${rightSideBar === 'booking' ? 'brand-text-color-800 font-bold' : 'brand-text-grey-v2 font-normal'}`}
                                    onClick={() => setRightSideBar('booking')}
                                  >
                                    Booking details
                                  </div>
                                </div>

                                {/* START */}

                                <div className="wrapper">
                                  <ol className="c-timeline">
                                    {bookings.map((a, num) =>
                                      rightSideBar === 'booking' ? (
                                        <div key={uuidv4()}>
                                          {num === 0 && (
                                            <>
                                              <div className="border-b border-slate-400 mb-2"></div>
                                              <div className="mx-3">
                                                <p className="font-normal text-sm text-ellipsis overflow-hidden mb-5">Properties</p>
                                              </div>
                                            </>
                                          )}
                                          <div className="mx-5">
                                            <div className="flex mb-2">
                                              <p className="font-normal text-xs brand-text-grey-v2 text-ellipsis overflow-hidden mb-px">
                                                {properties[getPropObj(a.propUid, properties)].name}: {a.days > 1 ? a.days + ' Nights' : a.days + ' Night'}
                                              </p>
                                              {resRequestPayable(a.propUid, properties) && (
                                                <p className="font-normal text-xs text-gray-600 float-right ml-auto">
                                                  $
                                                  {/* {numberWithCommas(
                                                a.rooms.reduce((acc, room) => {
                                                  acc += room.rate.payable;
                                                  return acc;
                                                }, 0)
                                              )} */}
                                                  {numberWithCommas(
                                                    a.rooms.reduce((acc, room) => {
                                                      acc += (room.rate && (room.rate.payable || room.rate.nett)) || 0;
                                                      return acc;
                                                    }, 0),
                                                  )}
                                                </p>
                                              )}
                                            </div>
                                            {num + 1 === bookings.length && <div className="border-b border-slate-400 my-2 mt-3"></div>}
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <>
                                            {jsonData.map((jsonObject, num) => (
                                              <JsonDetails jsonObject={jsonObject} num={num} rightSideBar={rightSideBar} />
                                            ))}
                                          </>

                                          {rightSideBar !== 'booking' && (
                                            <div></div>
                                            // <div className="border-b border-slate-400 mb-5 ml-11"></div>
                                          )}
                                        </>
                                      ),
                                    )}
                                  </ol>
                                  {rightSideBar !== 'booking' && <div className="border-b border-2 border-[#8CDBE2] mb-5 ml-5"></div>}
                                  {rightSideBar === 'booking' && (
                                    <div className="flex">
                                      <p
                                        className={`font-normal text-xs brand-text-grey-v2 text-ellipsis overflow-hidden mb-px ${rightSideBar === 'booking' && 'ml-5'} ${
                                          rightSideBar === 'booking' && 'mt-5'
                                        }`}
                                      >
                                        Activities:
                                      </p>

                                      <p className={`font-normal text-xs text-gray-600 float-right ml-auto ${rightSideBar === 'booking' && 'mr-5'} ${rightSideBar === 'booking' && 'mt-5'}`}>
                                        $ {activitiesCost}
                                      </p>
                                    </div>
                                  )}
                                  {/* {rightSideBar !== "booking" && (
																<div className="border-b border-slate-400 my-5 ml-11"></div>
															)} */}
                                  {rightSideBar === 'booking' && <div className="border-b border-slate-400 my-5 mx-5"></div>}
                                  <div className="flex">
                                    <p className={`font-normal text-sm brand-text-grey-v2 text-ellipsis overflow-hidden mb-px ml-5 ${rightSideBar !== 'booking' && 'ml-11'}`}>Total:</p>

                                    <p className={`font-normal text-sm text-gray-600 float-right ml-auto ${rightSideBar === 'booking' && 'mr-5'}`}>
                                      ${' '}
                                      {/* {bookings.reduce((acc, curr) => {
                                    return (
                                      acc +
                                      curr.rooms.reduce((acc, curr) => {
                                        if (curr.rate) {
                                          return acc + curr.rate.payable;
                                        }
                                        return acc;
                                      }, 0)
                                    );
                                  }, 0) + activitiesCost} */}
                                      {bookings.reduce((acc, curr) => {
                                        return (
                                          acc +
                                          curr.rooms.reduce((innerAcc, curr) => {
                                            if (curr.rate) {
                                              return innerAcc + (curr.rate.payable || curr.rate.nett || 0);
                                            }
                                            return innerAcc;
                                          }, 0)
                                        );
                                      }, 0) + activitiesCost}
                                    </p>
                                  </div>
                                </div>
                                {/* END */}
                              </div>

                              {/* </Scrollbar> */}
                              <div className="col-span-6"></div>
                            </div>
                          </div>

                          {/* Tickets */}

                          <div className="h-5"></div>
                        </div>
                      )}

                      {/* ORIGINAL  */}
                      {1 > 2 && (
                        <div className="pl-5">
                          <div style={deeShadow} className="p-5 mb-5 bg-white rounded-lg w-[340px]">
                            {/* TABS */}
                            {/* TODO: Deal with border color and dynamic colors */}

                            {/* MAKE CONTENT OF DIV BELOW A SCROLLABLE AREA */}
                            <div className={`grid grid-cols-12`}>
                              {/* <div className="col-span-3">
                            <div className="text-base brand-text-color-800 font-bold">Your itinerary</div>
                          </div> */}
                              {/* <Scrollbar style={{ width: 320, height: 675 }}> */}

                              <div className="col-span-12">
                                <div className="flex">
                                  <div
                                    className={`text-sm mr-2 cursor-pointer ${rightSideBar === 'itinerary' ? 'brand-text-color-800 font-bold' : 'brand-text-grey-v2 font-normal'}`}
                                    onClick={() => setRightSideBar('itinerary')}
                                  >
                                    Itinerary details
                                  </div>
                                  <div
                                    className={`text-sm mr-2 cursor-pointer ${rightSideBar === 'booking' ? 'brand-text-color-800 font-bold' : 'brand-text-grey-v2 font-normal'}`}
                                    onClick={() => setRightSideBar('booking')}
                                  >
                                    Booking details
                                  </div>
                                </div>

                                {/* START */}

                                <div className="wrapper">
                                  <ol className="c-timeline">
                                    {bookings.map((a, num) =>
                                      rightSideBar === 'booking' ? (
                                        <div key={uuidv4()}>
                                          {num === 0 && (
                                            <>
                                              <div className="border-b border-slate-400 mb-2"></div>
                                              <div className="mx-3">
                                                <p className="font-normal text-sm text-ellipsis overflow-hidden mb-5">Properties</p>
                                              </div>
                                            </>
                                          )}
                                          <div className="mx-5">
                                            <div className="flex mb-2">
                                              <p className="font-normal text-xs brand-text-grey-v2 text-ellipsis overflow-hidden mb-px">
                                                {properties[getPropObj(a.propUid, properties)].name}: {a.days > 1 ? a.days + ' Nights' : a.days + ' Night'}
                                              </p>
                                              {resRequestPayable(a.propUid, properties) && (
                                                <p className="font-normal text-xs text-gray-600 float-right ml-auto">
                                                  $
                                                  {/* {numberWithCommas(
                                                a.rooms.reduce((acc, room) => {
                                                  acc += room.rate.payable;
                                                  return acc;
                                                }, 0)
                                              )} */}
                                                  {numberWithCommas(
                                                    a.rooms.reduce((acc, room) => {
                                                      acc += (room.rate && (room.rate.payable || room.rate.nett)) || 0;
                                                      return acc;
                                                    }, 0),
                                                  )}
                                                </p>
                                              )}
                                            </div>
                                            {num + 1 === bookings.length && <div className="border-b border-slate-400 my-2 mt-3"></div>}
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {num === 0 ? (
                                            <>
                                              {/* ARRIVAL DETAILS */}
                                              <li key={uuidv4()} className="c-timeline__item pl-5">
                                                {(() => {
                                                  const transferObject = transfersData.find((transfer) => transfer.propUid === a.propUid && transfer.objType === 'arrival');

                                                  if (!transferObject || !transferObject.formValues || !transferObject.formValues.type) {
                                                    return (
                                                      <div className="c-timeline__content">
                                                        <p className="font-normal text-sm brand-text-grey-v2">ARRIVAL DETAILS</p>
                                                        <div className="icon-wrapper">
                                                          <MdErrorOutline size={20} />
                                                        </div>
                                                        <div className={`font-normal text-xs brand-text-grey-1-v2`}>No transfer details</div>
                                                      </div>
                                                    );
                                                  } else {
                                                    let circleIcon;
                                                    if (transferObject.formValues.type === 'flight' || transferObject.formValues.type === 'bookFlight') {
                                                      circleIcon = <PiAirplaneTiltLight size={20} />;
                                                    } else if (transferObject.formValues.type === 'location' || transferObject.formValues.type === 'safari') {
                                                      circleIcon = <GiJeep size={20} />;
                                                    } else if (transferObject.formValues.type === 'camp') {
                                                      circleIcon = <MdPersonOutline size={20} />;
                                                    } else if (transferObject.formValues.type === 'ownArrangements') {
                                                      circleIcon = <MdPersonOutline size={20} />;
                                                    }

                                                    return (
                                                      <div className="c-timeline__content">
                                                        <p className="font-normal text-sm brand-text-grey-1-v2">ARRIVAL DETAILS</p>
                                                        <div className="icon-wrapper">{circleIcon}</div>
                                                        <div>
                                                          {transfersData.length > 0 ? (
                                                            <TransferDetails transfer={transferObject} airports={airports} rightSideBar={rightSideBar} />
                                                          ) : (
                                                            'No transfer data'
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                })()}
                                              </li>

                                              {/* PROPERTY & ACTIVITY DETAILS */}
                                              <li key={uuidv4()} className="c-timeline__item pl-5">
                                                <div className="c-timeline__content">
                                                  <p className="font-normal text-sm brand-text-grey-v2">PROPERTY {num + 1}</p>
                                                  <div className="icon-wrapper">
                                                    <MdOutlineOtherHouses size={20} />
                                                  </div>
                                                  <p className="font-bold text-sm text-ellipsis overflow-hidden">{properties[getPropObj(a.propUid, properties)].name}</p>
                                                  <p className="font-normal text-xs brand-text-grey-1-v2 text-ellipsis overflow-hidden">
                                                    {properties[getPropObj(a.propUid, properties)].park.name} ({properties[getPropObj(a.propUid, properties)].supplier.name})
                                                  </p>
                                                  <div className="flex">
                                                    <p className="font-normal text-xs brand-text-grey-v2 mr-1">Arrival:</p>
                                                    <p className="font-normal text-xs brand-text-grey-1-v2">
                                                      {typeof a.startDay === 'string'
                                                        ? moment.utc(a.startDay).format('dddd Do MMMM YYYY')
                                                        : moment
                                                            .utc(a.startDay + new Date().getTimezoneOffset() * -60000)
                                                            .hour(12)
                                                            .minute(0)
                                                            .second(0)
                                                            .millisecond(0)
                                                            .format('dddd Do MMMM YYYY')}{' '}
                                                    </p>
                                                  </div>
                                                  <div className="flex">
                                                    <p className="font-normal text-xs brand-text-grey-v2 mr-1">Departure:</p>
                                                    <p className="font-normal text-xs brand-text-grey-1-v2">
                                                      {typeof a.endDay === 'string'
                                                        ? moment.utc(a.endDay).format('dddd Do MMMM YYYY')
                                                        : moment
                                                            .utc(a.endDay + new Date().getTimezoneOffset() * -60000)
                                                            .hour(12)
                                                            .minute(0)
                                                            .second(0)
                                                            .millisecond(0)
                                                            .format('dddd Do MMMM YYYY')}{' '}
                                                    </p>
                                                  </div>
                                                  <div className="flex">
                                                    <p className="font-normal text-xs brand-text-grey-1-v2">{a.days > 1 ? a.days + ' Nights' : a.days + ' Night'}</p>
                                                    <p className="font-normal text-xs brand-text-grey-1-v2 mx-1">|</p>
                                                    <p className="font-normal text-xs brand-text-grey-1-v2">
                                                      {a.rooms.reduce((acc, room) => acc + room.ad + room.ch + room.chi + room.inf, 0)}{' '}
                                                      {a.rooms.reduce((acc, room) => acc + room.ad + room.ch + room.chi + room.inf, 0) > 1 ? 'people' : 'person'}
                                                    </p>
                                                    <p className="font-normal text-xs brand-text-grey-1-v2 mx-1">|</p>
                                                    <p className="font-normal text-xs brand-text-grey-1-v2">
                                                      {a.rooms.length} room
                                                      {a.rooms.length > 1 && 's'}
                                                    </p>
                                                  </div>
                                                  {/* <p className="font-normal text-xs brand-text-grey-v2">
																					
                                          Departure: {moment(new Date(a.endDay.seconds * 1000)).format("dddd Do MMMM YYYY")}
                                        </p> */}

                                                  {resRequestDisplay(
                                                    a.propUid,

                                                    properties,
                                                  ) && (
                                                    <div className="flex justify-between">
                                                      <p className="font-semibold text-xs brand-text-grey-v2">Total</p>
                                                      <div className="ml-auto">
                                                        {resRequestPayable(a.propUid, properties) && (
                                                          <p className="font-normal text-xs brand-text-grey-v2">
                                                            $
                                                            {numberWithCommas(
                                                              a.rooms.reduce((acc, room) => {
                                                                if (room.rate) {
                                                                  acc += room.rate.payable || room.rate.nett || 0;
                                                                }
                                                                return acc;
                                                              }, 0),
                                                            )}
                                                          </p>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}

                                                  {/* {transfersData !== [] && <TransferDetails transfersData={transfersData} id={a.id} rightSideBar={rightSideBar} />} */}
                                                </div>
                                              </li>
                                              {activitiesData && activitiesData.length > 0 && <ActivityDetails activitiesData={activitiesData} id={a.id} rightSideBar={rightSideBar} />}

                                              {/* TRANSFER DETAILS */}
                                              <li key={uuidv4()} className="c-timeline__item pl-5">
                                                {(() => {
                                                  const transferObject = transfersData.find((transfer) => transfer.propUid === a.propUid && transfer.uuid === a.id);

                                                  if (!transferObject || !transferObject.formValues || !transferObject.formValues.type) {
                                                    return (
                                                      <div className="c-timeline__content">
                                                        <p className="font-normal text-sm brand-text-grey-v2">TRANSFER</p>
                                                        <div className="icon-wrapper">
                                                          <MdErrorOutline size={20} />
                                                        </div>
                                                        <div className={`font-normal text-xs brand-text-grey-1-v2`}>No transfer details</div>
                                                      </div>
                                                    );
                                                  } else {
                                                    let circleIcon;
                                                    if (transferObject.formValues.type === 'flight' || transferObject.formValues.type === 'bookFlight') {
                                                      circleIcon = <PiAirplaneTiltLight size={20} />;
                                                    } else if (transferObject.formValues.type === 'location' || transferObject.formValues.type === 'safari') {
                                                      circleIcon = <GiJeep size={20} />;
                                                    } else if (transferObject.formValues.type === 'camp') {
                                                      circleIcon = <MdPersonOutline size={20} />;
                                                    } else if (transferObject.formValues.type === 'ownArrangements') {
                                                      circleIcon = <MdPersonOutline size={20} />;
                                                    }

                                                    return (
                                                      <div className="c-timeline__content">
                                                        <p className="font-normal text-sm brand-text-grey-1-v2">TRANSFER</p>
                                                        <div className="icon-wrapper">{circleIcon}</div>
                                                        <div>
                                                          {transfersData.length > 0 ? (
                                                            <TransferDetails transfer={transferObject} airports={airports} rightSideBar={rightSideBar} />
                                                          ) : (
                                                            'No transfer data'
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                })()}
                                              </li>
                                            </>
                                          ) : num < bookings.length - 1 ? (
                                            <>
                                              {/* PROPERTY & ACTIVITY DETAILS */}
                                              <li key={uuidv4()} className="c-timeline__item pl-5">
                                                <div className="c-timeline__content">
                                                  <p className="font-normal text-sm brand-text-grey-v2">PROPERTY {num + 1}</p>
                                                  <div className="icon-wrapper">
                                                    <MdOutlineOtherHouses size={20} />
                                                  </div>
                                                  <p className="font-bold text-sm text-ellipsis overflow-hidden">{properties[getPropObj(a.propUid, properties)].name}</p>
                                                  <p className="font-normal text-xs brand-text-grey-1-v2 text-ellipsis overflow-hidden">
                                                    {properties[getPropObj(a.propUid, properties)].park.name} {properties[getPropObj(a.propUid, properties)].supplier.name}
                                                  </p>
                                                  <p className="font-normal text-xs brand-text-grey-1-v2">
                                                    Arrival:{' '}
                                                    {typeof a.startDay === 'string'
                                                      ? moment.utc(a.startDay).format('dddd Do MMMM YYYY')
                                                      : moment
                                                          .utc(a.startDay + new Date().getTimezoneOffset() * -60000)
                                                          .hour(12)
                                                          .minute(0)
                                                          .second(0)
                                                          .millisecond(0)
                                                          .format('dddd Do MMMM YYYY')}{' '}
                                                  </p>
                                                  <p className="font-normal text-xs brand-text-grey-1-v2">
                                                    Departure:{' '}
                                                    {typeof a.endDay === 'string'
                                                      ? moment.utc(a.endDay).format('dddd Do MMMM YYYY')
                                                      : moment
                                                          .utc(a.endDay + new Date().getTimezoneOffset() * -60000)
                                                          .hour(12)
                                                          .minute(0)
                                                          .second(0)
                                                          .millisecond(0)
                                                          .format('dddd Do MMMM YYYY')}{' '}
                                                  </p>
                                                  <p className="font-normal text-xs brand-text-grey-1-v2">{a.days > 1 ? a.days + ' Nights' : a.days + ' Night'}</p>
                                                  {/* <p className="font-normal text-xs brand-text-grey-v2">
																					
                                          Departure: {moment(new Date(a.endDay.seconds * 1000)).format("dddd Do MMMM YYYY")}
                                        </p> */}
                                                  <div className="flex justify-between">
                                                    {resRequestDisplay(
                                                      a.propUid,

                                                      properties,
                                                    ) && (
                                                      <>
                                                        <div>
                                                          {/* <p className="font-normal text-xs text-gray-600 px-1">|</p> */}
                                                          <p className="font-normal text-xs brand-text-grey-1-v2">
                                                            {a.rooms.reduce((acc, room) => acc + room.ad + room.ch + room.chi + room.inf, 0)}{' '}
                                                            {a.rooms.reduce((acc, room) => acc + room.ad + room.ch + room.chi + room.inf, 0) > 1 ? 'people' : 'person'}
                                                          </p>

                                                          <p className="font-normal text-xs brand-text-grey-1-v2">
                                                            {a.rooms.length} room
                                                            {a.rooms.length > 1 && 's'}
                                                          </p>
                                                          <div className="flex justify-between">
                                                            <p className="font-semibold text-xs brand-text-grey-1-v2">Total</p>
                                                            <div className="ml-auto">
                                                              {resRequestPayable(a.propUid, properties) && (
                                                                <p className="font-normal text-xs brand-text-grey-1-v2">
                                                                  $
                                                                  {numberWithCommas(
                                                                    a.rooms.reduce((acc, room) => {
                                                                      if (room.rate) {
                                                                        acc += room.rate.payable || room.rate.nett || 0;
                                                                      }
                                                                      return acc;
                                                                    }, 0),
                                                                  )}
                                                                </p>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>{' '}
                                                  {/* {activitiesData !== [] && <ActivityDetails activitiesData={activitiesData} id={a.id} rightSideBar={rightSideBar} />} */}
                                                  {/* {transfersData !== [] && <TransferDetails transfersData={transfersData} id={a.id} rightSideBar={rightSideBar} />} */}
                                                </div>
                                              </li>
                                              {activitiesData && activitiesData.length > 0 && <ActivityDetails activitiesData={activitiesData} id={a.id} rightSideBar={rightSideBar} />}

                                              {/* TRANSFER DETAILS */}
                                              <li key={uuidv4()} className="c-timeline__item pl-5">
                                                {(() => {
                                                  const transferObject = transfersData.find((transfer) => transfer.propUid === a.propUid && transfer.uuid === a.id);

                                                  if (!transferObject || !transferObject.formValues || !transferObject.formValues.type) {
                                                    return (
                                                      <div className="c-timeline__content">
                                                        <p className="font-normal text-sm brand-text-grey-v2">TRANSFER</p>
                                                        <div className="icon-wrapper">
                                                          <MdErrorOutline size={20} />
                                                        </div>
                                                        <div className={`font-normal text-xs brand-text-grey-1-v2`}>No transfer details</div>
                                                      </div>
                                                    );
                                                  } else {
                                                    let circleIcon;
                                                    if (transferObject.formValues.type === 'flight' || transferObject.formValues.type === 'bookFlight') {
                                                      circleIcon = <PiAirplaneTiltLight size={20} />;
                                                    } else if (transferObject.formValues.type === 'location' || transferObject.formValues.type === 'safari') {
                                                      circleIcon = <GiJeep size={20} />;
                                                    } else if (transferObject.formValues.type === 'camp') {
                                                      circleIcon = <MdPersonOutline size={20} />;
                                                    } else if (transferObject.formValues.type === 'ownArrangements') {
                                                      circleIcon = <MdPersonOutline size={20} />;
                                                    }

                                                    return (
                                                      <div className="c-timeline__content">
                                                        <p className="font-normal text-sm brand-text-grey-1-v2">TRANSFER</p>
                                                        <div className="icon-wrapper">{circleIcon}</div>
                                                        <div>
                                                          {transfersData.length > 0 ? (
                                                            <TransferDetails transfer={transferObject} airports={airports} rightSideBar={rightSideBar} />
                                                          ) : (
                                                            'No transfer data'
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                })()}
                                              </li>
                                            </>
                                          ) : (
                                            <>
                                              {/* PROPERTY & ACTIVITY DETAILS */}
                                              <li key={uuidv4()} className="c-timeline__item pl-5">
                                                <div className="c-timeline__content">
                                                  <p className="font-normal text-sm brand-text-grey-v2">PROPERTY {num + 1}</p>
                                                  <div className="icon-wrapper">
                                                    <MdOutlineOtherHouses size={20} />
                                                  </div>
                                                  <p className="font-bold text-sm text-ellipsis overflow-hidden">{properties[getPropObj(a.propUid, properties)].name}</p>
                                                  <p className="font-normal text-xs brand-text-grey-1-v2 text-ellipsis overflow-hidden">
                                                    {properties[getPropObj(a.propUid, properties)].park.name} {properties[getPropObj(a.propUid, properties)].supplier.name}
                                                  </p>
                                                  <p className="font-normal text-xs brand-text-grey-1-v2">
                                                    Arrival:{' '}
                                                    {typeof a.startDay === 'string'
                                                      ? moment.utc(a.startDay).format('dddd Do MMMM YYYY')
                                                      : moment
                                                          .utc(a.startDay + new Date().getTimezoneOffset() * -60000)
                                                          .hour(12)
                                                          .minute(0)
                                                          .second(0)
                                                          .millisecond(0)
                                                          .format('dddd Do MMMM YYYY')}{' '}
                                                  </p>
                                                  <p className="font-normal text-xs brand-text-grey-1-v2">
                                                    Departure:{' '}
                                                    {typeof a.endDay === 'string'
                                                      ? moment.utc(a.endDay).format('dddd Do MMMM YYYY')
                                                      : moment
                                                          .utc(a.endDay + new Date().getTimezoneOffset() * -60000)
                                                          .hour(12)
                                                          .minute(0)
                                                          .second(0)
                                                          .millisecond(0)
                                                          .format('dddd Do MMMM YYYY')}{' '}
                                                  </p>
                                                  <p className="font-normal text-xs brand-text-grey-1-v2">{a.days > 1 ? a.days + ' Nights' : a.days + ' Night'}</p>
                                                  {/* <p className="font-normal text-xs brand-text-grey-v2">
																					
                                          Departure: {moment(new Date(a.endDay.seconds * 1000)).format("dddd Do MMMM YYYY")}
                                        </p> */}
                                                  <div className="flex justify-between">
                                                    {resRequestDisplay(
                                                      a.propUid,

                                                      properties,
                                                    ) && (
                                                      <>
                                                        <div>
                                                          {/* <p className="font-normal text-xs text-gray-600 px-1">|</p> */}
                                                          <p className="font-normal text-xs brand-text-grey-1-v2">
                                                            {a.rooms.reduce((acc, room) => acc + room.ad + room.ch + room.chi + room.inf, 0)}{' '}
                                                            {a.rooms.reduce((acc, room) => acc + room.ad + room.ch + room.chi + room.inf, 0) > 1 ? 'people' : 'person'}
                                                          </p>

                                                          <p className="font-normal text-xs brand-text-grey-1-v2">
                                                            {a.rooms.length} room
                                                            {a.rooms.length > 1 && 's'}
                                                          </p>
                                                          <div className="flex justify-between">
                                                            <p className="font-semibold text-xs brand-text-grey-1-v2">Total</p>
                                                            <div className="ml-auto">
                                                              {resRequestPayable(a.propUid, properties) && (
                                                                <p className="font-normal text-xs brand-text-grey-1-v2">
                                                                  $
                                                                  {numberWithCommas(
                                                                    a.rooms.reduce((acc, room) => {
                                                                      if (room.rate) {
                                                                        acc += room.rate.payable || room.rate.nett || 0;
                                                                      }
                                                                      return acc;
                                                                    }, 0),
                                                                  )}
                                                                </p>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>{' '}
                                                  {/* {activitiesData !== [] && <ActivityDetails activitiesData={activitiesData} id={a.id} rightSideBar={rightSideBar} />} */}
                                                  {/* {transfersData !== [] && <TransferDetails transfersData={transfersData} id={a.id} rightSideBar={rightSideBar} />} */}
                                                </div>
                                              </li>
                                              {activitiesData && activitiesData.length > 0 && <ActivityDetails activitiesData={activitiesData} id={a.id} rightSideBar={rightSideBar} />}
                                              {/* DEPARTURE DETAILS */}
                                              <li key={uuidv4()} className="c-timeline__item pl-5">
                                                {(() => {
                                                  const transferObject = transfersData.find((transfer) => transfer.propUid === a.propUid && transfer.objType === 'departure');

                                                  if (!transferObject || !transferObject.formValues || !transferObject.formValues.type) {
                                                    return (
                                                      <div className="c-timeline__content">
                                                        <p className="font-normal text-sm brand-text-grey-v2">DEPARTURE DETAILS</p>
                                                        <div className="icon-wrapper">
                                                          <MdErrorOutline size={20} />
                                                        </div>
                                                        <div className={`font-normal text-xs brand-text-grey-1-v2`}>No transfer details</div>
                                                      </div>
                                                    );
                                                  } else {
                                                    let circleIcon;
                                                    if (transferObject.formValues.type === 'flight' || transferObject.formValues.type === 'bookFlight') {
                                                      circleIcon = <PiAirplaneTiltLight size={20} />;
                                                    } else if (transferObject.formValues.type === 'location' || transferObject.formValues.type === 'safari') {
                                                      circleIcon = <GiJeep size={20} />;
                                                    } else if (transferObject.formValues.type === 'camp') {
                                                      circleIcon = <MdPersonOutline size={20} />;
                                                    } else if (transferObject.formValues.type === 'ownArrangements') {
                                                      circleIcon = <MdPersonOutline size={20} />;
                                                    }

                                                    return (
                                                      <div className="c-timeline__content">
                                                        <p className="font-normal text-sm brand-text-grey-1-v2">DEPARTURE DETAILS</p>
                                                        <div className="icon-wrapper">{circleIcon}</div>
                                                        <div>
                                                          {transfersData.length > 0 ? (
                                                            <TransferDetails transfer={transferObject} airports={airports} rightSideBar={rightSideBar} />
                                                          ) : (
                                                            'No transfer data'
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                })()}
                                              </li>
                                            </>
                                          )}

                                          {rightSideBar !== 'booking' && (
                                            <div></div>
                                            // <div className="border-b border-slate-400 mb-5 ml-11"></div>
                                          )}
                                        </>
                                      ),
                                    )}
                                  </ol>
                                  {rightSideBar !== 'booking' && <div className="border-b border-2 border-[#8CDBE2] mb-5 ml-5"></div>}
                                  {rightSideBar === 'booking' && (
                                    <div className="flex">
                                      <p
                                        className={`font-normal text-xs brand-text-grey-v2 text-ellipsis overflow-hidden mb-px ${rightSideBar === 'booking' && 'ml-5'} ${
                                          rightSideBar === 'booking' && 'mt-5'
                                        }`}
                                      >
                                        Activities:
                                      </p>

                                      <p className={`font-normal text-xs text-gray-600 float-right ml-auto ${rightSideBar === 'booking' && 'mr-5'} ${rightSideBar === 'booking' && 'mt-5'}`}>
                                        $ {activitiesCost}
                                      </p>
                                    </div>
                                  )}
                                  {/* {rightSideBar !== "booking" && (
																<div className="border-b border-slate-400 my-5 ml-11"></div>
															)} */}
                                  {rightSideBar === 'booking' && <div className="border-b border-slate-400 my-5 mx-5"></div>}
                                  <div className="flex">
                                    <p className={`font-normal text-sm brand-text-grey-v2 text-ellipsis overflow-hidden mb-px ml-5 ${rightSideBar !== 'booking' && 'ml-11'}`}>Total:</p>

                                    <p className={`font-normal text-sm text-gray-600 float-right ml-auto ${rightSideBar === 'booking' && 'mr-5'}`}>
                                      ${' '}
                                      {/* {bookings.reduce((acc, curr) => {
                                    return (
                                      acc +
                                      curr.rooms.reduce((acc, curr) => {
                                        if (curr.rate) {
                                          return acc + curr.rate.payable;
                                        }
                                        return acc;
                                      }, 0)
                                    );
                                  }, 0) + activitiesCost} */}
                                      {bookings.reduce((acc, curr) => {
                                        return (
                                          acc +
                                          curr.rooms.reduce((innerAcc, curr) => {
                                            if (curr.rate) {
                                              return innerAcc + (curr.rate.payable || curr.rate.nett || 0);
                                            }
                                            return innerAcc;
                                          }, 0)
                                        );
                                      }, 0) + activitiesCost}
                                    </p>
                                  </div>
                                </div>
                                {/* END */}
                              </div>

                              {/* </Scrollbar> */}
                              <div className="col-span-6"></div>
                            </div>
                          </div>

                          {/* Tickets */}

                          <div className="h-5"></div>
                        </div>
                      )}
                    </div>
                    {/* RESULTS END */}

                    {/* RIGHT COLUMN FOR RESULTS END */}
                  </div>
                  {/* LEFT AND RIGHT LAYOUT FOR PROPERTIES & RESULTS START */}
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ConfirmItinerary;
