import React from 'react';

function ButtonPrimarySmall({ text, onClick, className, color = 'green', isLoading = false, disabled = false, children }) {
  const handleClick = async (event) => {
    if (!isLoading && !disabled && onClick) {
      await onClick(event);
    }
  };

  const bgColor = color === 'dark' ? 'bg-nomadBlue-800' : 'bg-nomadEvergreen-700';
  const hoverBgColor = color === 'dark' ? 'hover:bg-nomadBlue-700' : 'hover:bg-nomadEvergreen-800';

  return (
    <button
      className={`${bgColor} text-white text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out ${hoverBgColor} hover:text-white ${(isLoading || disabled) ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
      onClick={handleClick}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          {text}
        </div>
      ) : children || text}
    </button>
  );
}

export default ButtonPrimarySmall;