import React from 'react';
import { Link } from 'react-router-dom';
import { PiArrowCircleRightLight } from 'react-icons/pi';

function DashboardCard({ title, subtitle, image, link, comingSoon }) {
  return (
    <Link to={link} className="block bg-white shadow-md rounded-lg overflow-hidden">
      <div className="relative">
        <img src={image} alt={title} className="w-full h-48 object-cover" />
        {comingSoon && (
          <img src="https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/0c5b1224-b4ba-4820-f5a8-49a3e456e500/83x83" alt="Coming Soon" className="absolute bottom-0 right-4 transform translate-y-1/2" />
        )}
      </div>
      <div className="p-6 flex justify-between min-h-[170px]">
        <div className="w-2/3">
          <h2 className="text-2xl font-semibold text-nomadBlue mb-2">{title}</h2>
          <p className="text-gray-600">{subtitle}</p>
        </div>
        <div className="self-end">
          <PiArrowCircleRightLight className="h-12 w-12 text-nomadEvergreen-700 hover:text-nomadEvergreen-800" />
        </div>
      </div>
    </Link>
  );
}

export default DashboardCard;
