import React, { useState } from 'react';
import ReadMoreButton from '../../components/ReadMoreButton';
import { urlFor } from '../../../../db/sanity';
import { FaCheckCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

function ActivitiesTab({ camp }) {
  // Checks if any of the sanity key details are null
  const hasNonEmptyDetails = camp.activities?.keyDetails?.some((detail) => detail && detail.trim() !== '');

    // New state for image loading status
    const [imageStatus, setImageStatus] = useState({});

    // Function to handle image load
    const handleImageLoad = (index) => {
      setImageStatus(prevStatus => ({
        ...prevStatus,
        [index]: { loading: false, error: false }
      }));
    };
  
    // Function to handle image error
    const handleImageError = (index) => {
      setImageStatus(prevStatus => ({
        ...prevStatus,
        [index]: { loading: false, error: true }
      }));
    };
  
    // Function to get image URL
    const getImageUrl = (image) => {
      if (!image) return '';
      if (typeof image === 'string') return image;
      if (image.asset && image.asset._ref) return urlFor(image.asset._ref).url();
      return '';
    };
  
    // Fallback image URL
    const fallbackImageUrl = 'https://example.com/fallback-image.jpg'; // Replace with your fallback image URL

    
    return (
      <div className="mt-8">
        {/* SECTION - DESCRIPTION AND OVERVIEW BOX */}
        <div className="grid grid-cols-1 md:grid-cols-10 gap-8">
          <div className="md:col-span-6 text-base font-sans">
            <div className="text-4xl font-roca text-nomadBlue-800 mb-6">Activities</div>
            {camp.activities && camp.activities.info ? <ReadMoreButton text={camp.activities.info} maxRows={5} buttonText="Show" /> : <p>Description coming soon.</p>}
          </div>
          {hasNonEmptyDetails && (
            <div className="md:col-start-8 col-span-3">
              <div className="bg-white rounded-lg border border-[#f0ece7] p-6 font-['Open Sans']">
                <h3 className="text-sm font-bold uppercase text-gray-600 mb-4">Overview</h3>
                <ul className="list-none">
                  {camp.activities.keyDetails.map(
                    (detail, index) =>
                      detail &&
                      detail.trim() !== '' && (
                        <li key={index} className="flex items-center text-sm text-gray-600 mb-2">
                          <FaCheckCircle className="mr-2 text-[#073937]" />
                          <span>{detail}</span>
                        </li>
                      ),
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
    
        {/* SECTION - ACTIVITY TYPES */}
        <div className="mt-12">
          {camp.activities && camp.activities.activityTypes ? (
            camp.activities.activityTypes.map((activity, index) => (
              <div key={index} className={`grid grid-cols-1 md:grid-cols-2 gap-8 mb-12 ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}`}>
                <div className={`relative min-h-[400px] ${index % 2 === 0 ? 'md:order-1' : 'md:order-2'}`}>
                  {imageStatus[index]?.loading !== false && (
                    <div className="absolute inset-0 w-full h-full bg-gray-200 animate-pulse"></div>
                  )}
                  <motion.img
                    src={imageStatus[index]?.error ? fallbackImageUrl : getImageUrl(activity.image || activity.imageUrl)}
                    alt={activity.name}
                    className="absolute inset-0 w-full h-full object-cover"
                    onLoad={() => handleImageLoad(index)}
                    onError={() => handleImageError(index)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: imageStatus[index]?.loading === false ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                  />
                </div>
                <div className={`flex flex-col justify-center font-sans ${index % 2 === 0 ? 'md:order-2' : 'md:order-1'}`}>
                  <div className="text-4xl font-roca text-nomadBlue mb-6">{activity.name}</div>
                  <p>{activity.description}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No activities available.</p>
          )}
        </div>
      </div>
    );
}

export default ActivitiesTab;
