import 'react-confirm-alert/src/react-confirm-alert.css';

import { addDays, dateShort, getPropObj, minusDays } from './generalFunctions';
import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { propertyAccom, propertySelected, removeOne } from './availabilityFunctions';

import PropertiesSearchBox from '../components/PropertiesSearchBox';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { db } from '../db/firebase.config';
import { deeShadow } from '../css/additional-styles/custom';
import { rebuildGuestsFromSubcollection, rebuildGuestsFromSubcollectionFull } from './itineraryFunctions';
import { sendUpdateItineraryGuests } from './sendDataFunctions';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const moment = require('moment');

const cleanupGuests = (guests) => {
  const cleanedGuests = { ...guests };

  cleanedGuests.adultAges = cleanedGuests.adultAges.map((adult) => {
    const cleanedAdult = {};
    for (const key in adult) {
      if (adult[key] !== undefined && adult[key] !== null) {
        cleanedAdult[key] = adult[key];
      }
    }
    return cleanedAdult;
  });

  cleanedGuests.childAges = cleanedGuests.childAges.map((child) => {
    const cleanedChild = {};
    for (const key in child) {
      if (child[key] !== undefined && child[key] !== null) {
        cleanedChild[key] = child[key];
      }
    }
    return cleanedChild;
  });

  return cleanedGuests;
};

function updateItineraryData(itineraryData, cleanedGuests) {
  const updatedItineraryData = itineraryData.map((item) => {
    // Update guestsMerged object
    // const updatedGuestsMerged = {
    //   children: cleanedGuests.children,
    //   adults: cleanedGuests.adults,
    //   adultAges: cleanedGuests.adultAges.map(({ uuid, age }) => ({ uuid, age })),
    //   childAges: cleanedGuests.childAges.map(({ uuid, age }) => ({ uuid, age })),
    // };

    console.log('cleanedGuests check:', JSON.stringify(cleanedGuests, null, 2));
    const updatedGuestsMerged = {
      ...item.guestsMerged,
      adultAges: cleanedGuests.adultAges.map(({ uuid, age, isGuide }) => ({
        uuid,
        age,
        ...(isGuide && { isGuide }) // Only include isGuide if it exists and is true
      })),
      childAges: cleanedGuests.childAges.map(({ uuid, age, isGuide }) => ({
        uuid,
        age,
        ...(isGuide && { isGuide }) // Only include isGuide if it exists and is true
      }))
    };

    // Update selectedAges and selectedAgesAdults arrays in each room
    const updatedRooms = item.rooms.map((room) => {
      const updatedSelectedAges = room.selectedAges
        ? room.selectedAges.map((selectedAge) => {
            const matchedChild = cleanedGuests.childAges.find((child) => child.uuid === selectedAge.uuid);
            return matchedChild ? { ...selectedAge, age: matchedChild.age } : selectedAge;
          })
        : [];

      const updatedSelectedAgesAdults = room.selectedAgesAdults
        ? room.selectedAgesAdults.map((selectedAdult) => {
            const matchedAdult = cleanedGuests.adultAges.find((adult) => adult.uuid === selectedAdult.uuid);
            return matchedAdult ? { ...selectedAdult, age: matchedAdult.age } : selectedAdult;
          })
        : [];

      return {
        ...room,
        selectedAges: updatedSelectedAges,
        selectedAgesAdults: updatedSelectedAgesAdults,
      };
    });

    return {
      ...item,
      guestsMerged: updatedGuestsMerged,
      rooms: updatedRooms,
    };
  });

  return updatedItineraryData;
}

function updateTransfersData(transfersData, cleanedGuests) {
  const updatedTransfersData =
    transfersData && transfersData.length > 0
      ? transfersData.map((transfer) => {
          const updatedSelectedAges = transfer.selectedAges
            ? transfer.selectedAges.map((selectedAge) => {
                const matchedChild = cleanedGuests.childAges.find((child) => child.uuid === selectedAge.uuid);
                return matchedChild ? { ...selectedAge, age: matchedChild.age } : selectedAge;
              })
            : [];

          const updatedSelectedAgesAdults = transfer.selectedAgesAdults
            ? transfer.selectedAgesAdults.map((selectedAdult) => {
                const matchedAdult = cleanedGuests.adultAges.find((adult) => adult.uuid === selectedAdult.uuid);
                return matchedAdult ? { ...selectedAdult, age: matchedAdult.age } : selectedAdult;
              })
            : [];

          return {
            ...transfer,
            selectedAges: updatedSelectedAges,
            selectedAgesAdults: updatedSelectedAgesAdults,
          };
        })
      : [];

  return updatedTransfersData;
}

function updateRoomPax(roomPax, cleanedGuests) {
  const updatedRoomPax =
    roomPax && roomPax.length > 0
      ? roomPax.map((pax) => {
          const existingChildAges = pax.childAges || [];
          const existingAdultAges = pax.adultAges || [];

          const updatedChildAges = cleanedGuests.childAges.map((child) => {
            const existingChild = existingChildAges.find((existingChild) => existingChild.uuid === child.uuid);
            return existingChild ? { ...existingChild, age: child.age } : { uuid: child.uuid, age: child.age };
          });

          const updatedAdultAges = cleanedGuests.adultAges.map((adult) => {
            const existingAdult = existingAdultAges.find((existingAdult) => existingAdult.uuid === adult.uuid);
            return existingAdult ? { ...existingAdult, age: adult.age } : { uuid: adult.uuid, age: adult.age };
          });

          const updatedAdults = updatedAdultAges.length;
          const updatedChildren = updatedChildAges.length;

          return {
            ...pax,
            childAges: updatedChildAges,
            adultAges: updatedAdultAges,
            adults: updatedAdults,
            children: updatedChildren,
          };
        })
      : [];

  return updatedRoomPax;
}

const createNewGuests = async (booking, guests, setIsLoading, uuid, deleteGuest) => {
  //setIsLoading(true);
  const { uid, itineraryData, transfersData, roomPax } = booking;
  const newGuests = {
    children: 0,
    childAges: [],
    adults: 0,
    adultAges: [],
  };

  // guests.forEach((guest) => {
  //   if (guest.age < 21) {
  //     newGuests.children++;
  //     newGuests.childAges.push(guest);
  //   } else {
  //     newGuests.adults++;
  //     newGuests.adultAges.push(guest);
  //   }
  // });
  guests.forEach((guest) => {
    if (guest.age < 21) {
      newGuests.children++;
      newGuests.childAges.push({ ...guest, type: 'child' });
    } else {
      newGuests.adults++;
      newGuests.adultAges.push({ ...guest, type: 'adult' });
    }
  });

  const cleanedGuests = cleanupGuests(newGuests);
  console.log('cleanedGuests:', JSON.stringify(cleanedGuests, null, 2));
  const updatedItineraryData = updateItineraryData(itineraryData, cleanedGuests);
  const updatedTransfersData = updateTransfersData(transfersData, cleanedGuests);
  const updatedRoomPax = updateRoomPax(roomPax, cleanedGuests);
  console.log('updatedRoomPax:', JSON.stringify(updatedRoomPax, null, 2));

  try {
    const result = await sendUpdateItineraryGuests(uid, cleanedGuests, updatedItineraryData, updatedTransfersData, updatedRoomPax, uuid, deleteGuest);
    console.log(result);
    toast.success('Guests updated successfully');
    // await sendUpdateItineraryGuests(uid, newGuests);
  } catch (error) {
    // Handle error if needed
    console.error('Error updating itinerary guests:', error);
  }
  //setIsLoading(false);
};

const mergePaxDataOld = (tempBookings) => {
  const { guests, itineraryData, uid } = tempBookings;

  if (!guests) {
    return [];
  }

  const { children, childAges, adultAges, adults } = guests;

  const mergedPaxData = [
    ...adultAges.map((adult) => ({
      ...adult,
      firstName: adult.firstName ? adult.firstName : '',
      middleName: adult.middleName ? adult.middleName : '',
      lastName: adult.lastName ? adult.lastName : '',
      dateOfBirth: adult.dateOfBirth ? adult.dateOfBirth : null,
      nationality: adult.nationality ? adult.nationality : null,
      passportNumber: adult.passportNumber ? adult.passportNumber : null,
      isLeadPassenger: adult.isLeadPassenger ? adult.isLeadPassenger : null,
      //weight: adult.weight ? adult.weight : 0,
      dietaryRequirements: adult.dietaryRequirements ? adult.dietaryRequirements : null,
      dietaryRequirementsInfo: adult.dietaryRequirementsInfo ? adult.dietaryRequirementsInfo : '',
      healthRequirements: adult.healthRequirements ? adult.healthRequirements : null,
      healthRequirementsInfo: adult.healthRequirementsInfo ? adult.healthRequirementsInfo : '',
      otherInterestsInfo: adult.otherInterestsInfo ? adult.otherInterestsInfo : '',
      sex: adult.sex ? adult.sex : null,
      detailsComplete: adult.detailsComplete ? adult.detailsComplete : false,
      used: itineraryData.some((item) => item.rooms.some((room) => room.selectedAgesAdults && room.selectedAgesAdults.some((selectedAdult) => selectedAdult.uuid === adult.uuid))),
    })),
    ...childAges.map((child) => ({
      ...child,
      firstName: child.firstName ? child.firstName : '',
      middleName: child.middleName ? child.middleName : '',
      lastName: child.lastName ? child.lastName : '',
      dateOfBirth: child.dateOfBirth ? child.dateOfBirth : null,
      nationality: child.nationality ? child.nationality : null,
      passportNumber: child.passportNumber ? child.passportNumber : null,
      isLeadPassenger: child.isLeadPassenger ? child.isLeadPassenger : null,
      //weight: child.weight ? child.weight : 0,
      dietaryRequirements: child.dietaryRequirements ? child.dietaryRequirements : child.dietaryRequirements === false ? false : null,
      dietaryRequirementsInfo: child.dietaryRequirementsInfo ? child.dietaryRequirementsInfo : '',
      healthRequirements: child.healthRequirements ? child.healthRequirements : null,
      healthRequirementsInfo: child.healthRequirementsInfo ? child.healthRequirementsInfo : '',
      otherInterestsInfo: child.otherInterestsInfo ? child.otherInterestsInfo : '',
      sex: child.sex ? child.sex : null,
      detailsComplete: child.detailsComplete ? child.detailsComplete : false,
      used: itineraryData.some((item) => item.rooms.some((room) => room.selectedAges && room.selectedAges.some((selectedChild) => selectedChild.uuid === child.uuid))),
    })),
  ];

  return mergedPaxData;
};

const mergePaxData = async (tempBookings) => {
  const { guests, itineraryData, uid } = tempBookings;

  if (!guests) {
    return [];
  }

  // const rebuiltGuests = await rebuildGuestsFromSubcollection(db, uid, 'itineraries');
  const rebuiltGuests = await rebuildGuestsFromSubcollectionFull(db, uid, 'itineraries');
  // !const { children, childAges, adultAges, adults } = guests;
  const { children, childAges, adultAges, adults } = rebuiltGuests;

  const mergedPaxData = [
    ...adultAges.map((adult) => ({
      ...adult,
      firstName: adult.firstName ? adult.firstName : '',
      middleName: adult.middleName ? adult.middleName : '',
      lastName: adult.lastName ? adult.lastName : '',
      dateOfBirth: adult.dateOfBirth ? adult.dateOfBirth : null,
      nationality: adult.nationality ? adult.nationality : null,
      passportNumber: adult.passportNumber ? adult.passportNumber : null,
      isLeadPassenger: adult.isLeadPassenger ? adult.isLeadPassenger : null,
      //weight: adult.weight ? adult.weight : 0,
      dietaryRequirements: adult.dietaryRequirements ? adult.dietaryRequirements : adult.dietaryRequirements === false ? false : null,
      dietaryRequirementsInfo: adult.dietaryRequirementsInfo ? adult.dietaryRequirementsInfo : '',
      healthRequirements: adult.healthRequirements ? adult.healthRequirements : adult.healthRequirements === false ? false : null,
      healthRequirementsInfo: adult.healthRequirementsInfo ? adult.healthRequirementsInfo : '',
      otherInterestsInfo: adult.otherInterestsInfo ? adult.otherInterestsInfo : '',
      sex: adult.sex ? adult.sex : null,
      detailsComplete: adult.detailsComplete ? adult.detailsComplete : false,
      used: itineraryData.some((item) => item.rooms.some((room) => room.selectedAgesAdults && room.selectedAgesAdults.some((selectedAdult) => selectedAdult.uuid === adult.uuid))),
      ...(adult.isGuide && { isGuide: true }), // Only include isGuide if it's true
    })),
    ...childAges.map((child) => ({
      ...child,
      firstName: child.firstName ? child.firstName : '',
      middleName: child.middleName ? child.middleName : '',
      lastName: child.lastName ? child.lastName : '',
      dateOfBirth: child.dateOfBirth ? child.dateOfBirth : null,
      nationality: child.nationality ? child.nationality : null,
      passportNumber: child.passportNumber ? child.passportNumber : null,
      isLeadPassenger: child.isLeadPassenger ? child.isLeadPassenger : null,
      //weight: child.weight ? child.weight : 0,
      dietaryRequirements: child.dietaryRequirements ? child.dietaryRequirements : child.dietaryRequirements === false ? false : null,
      dietaryRequirementsInfo: child.dietaryRequirementsInfo ? child.dietaryRequirementsInfo : '',
      healthRequirements: child.healthRequirements ? child.healthRequirements : child.healthRequirements === false ? false : null,
      healthRequirementsInfo: child.healthRequirementsInfo ? child.healthRequirementsInfo : '',
      otherInterestsInfo: child.otherInterestsInfo ? child.otherInterestsInfo : '',
      sex: child.sex ? child.sex : null,
      detailsComplete: child.detailsComplete ? child.detailsComplete : false,
      used: itineraryData.some((item) => item.rooms.some((room) => room.selectedAges && room.selectedAges.some((selectedChild) => selectedChild.uuid === child.uuid))),
      ...(child.isGuide && { isGuide: true }), // Only include isGuide if it's true
    })),
  ];

  return mergedPaxData;
};

const groupJsonDataByJUuid = (jsonData) => {
  const groupedData = {};

  jsonData.forEach((item) => {
    if (groupedData[item.jUuid]) {
      groupedData[item.jUuid].push(item);
    } else {
      groupedData[item.jUuid] = [item];
    }
  });

  return Object.values(groupedData);
};

const fetchConsultantData = async (consultantUid) => {
  if (!consultantUid) return null;

  try {
    const consultantRef = doc(db, 'consultants', consultantUid);
    const consultantSnap = await getDoc(consultantRef);

    if (consultantSnap.exists()) {
      return consultantSnap.data();
    } else {
      console.log('No such consultant!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching consultant data:', error);
    return null;
  }
};

const updateAgentReference = async (bookingId, newReference) => {
  try {
    const bookingRef = doc(db, 'itineraries', bookingId);
    await updateDoc(bookingRef, {
      agentReference: newReference,
      syncState: 'readyToSync',
      dateModified: serverTimestamp(),
    });
    toast.success('Agent reference updated successfully');
  } catch (error) {
    console.error('Error updating agent reference:', error);
    toast.error('Failed to update agent reference');
    throw error;
  }
};

const updateClientName = async (bookingId, newClientName) => {
  try {
    const bookingRef = doc(db, 'itineraries', bookingId);
    await updateDoc(bookingRef, {
      clientName: newClientName,
      syncState: 'readyToSync',
      dateModified: serverTimestamp(),
    });
    toast.success('Client name updated successfully');
  } catch (error) {
    console.error('Error updating client name:', error);
    toast.error('Failed to update client name');
    throw error;
  }
};

const updateItineraryHiddenStatus = async (bookingId, hiddenStatus) => {
  try {
    const bookingRef = doc(db, 'itineraries', bookingId);
    await updateDoc(bookingRef, {
      hidden: hiddenStatus,
      syncState: 'readyToSync',
      dateModified: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.error('Error updating itinerary hidden status:', error);
    throw error;
  }
};

const passToTravelDesk = async (bookingId) => {
  try {
    const bookingRef = doc(db, 'itineraries', bookingId);
    await updateDoc(bookingRef, {
      withTravelDesk: true,
      syncState: 'readyToSync',
      dateModified: serverTimestamp(),
    });
    toast.success('Itinerary passed to Travel Desk successfully');
    return true;
  } catch (error) {
    console.error('Error passing itinerary to Travel Desk:', error);
    toast.error('Failed to pass itinerary to Travel Desk');
    throw error;
  }
};

const updateItineraryStatusToConfirmed = async (bookingId) => {
  try {
    const bookingRef = doc(db, 'itineraries', bookingId);
    await updateDoc(bookingRef, {
      status: 'confirmed',
      syncState: 'readyToSync',
      dateModified: serverTimestamp(),
    });
    toast.success('Itinerary successfully confirmed');
    return true;
  } catch (error) {
    console.error('Error confirming Itinerary:', error);
    toast.error('Failed to confirm itinerary');
    throw error;
  }
};


// Function that generates the invoice for a booking. Triggered via the view itinerary booking confirmation modal
const generateInvoice = async (bookingId) => {
  try {
    const bookingRef = doc(db, 'itineraries', bookingId);
    const bookingSnap = await getDoc(bookingRef);

    if (!bookingSnap.exists()) {
      throw new Error('Booking not found');
    }

    const bookingData = bookingSnap.data();

    // Fetch agent data
    const agentRef = doc(db, 'agents', bookingData.agent.uid);
    const agentSnap = await getDoc(agentRef);

    if (!agentSnap.exists()) {
      throw new Error('Agent not found');
    }

    const agentData = agentSnap.data();

    if (!agentData.nomadCompany) {
      throw new Error('Invalid or missing nomadCompany for agent');
    }

    const invoicePrefix = agentData.nomadCompany === 'ntl' ? 'NOM' : 'NOMDPRF';
    const reference = `${invoicePrefix}-${bookingData.code}/1`;

    const invoice = {
      totalInvoiced: bookingData.totalPrice,
      depositAmount: Math.ceil(bookingData.totalDeposit),
      reference: reference,
      invoiceAddress: agentData.addressFull || '',
      invoicePrinted: true,
      invoiceFromCompany: agentData.nomadCompany,
      vatNumber: agentData.vatNumber || '',
      tin: agentData.tin || '',
    };

    await updateDoc(bookingRef, {
      invoice: invoice,
      syncState: 'readyToSync',
      dateModified: serverTimestamp(),
    });

    return true;
  } catch (error) {
    console.error('Error generating invoice:', error);
    throw error;
  }
};

export { createNewGuests, mergePaxData, groupJsonDataByJUuid, fetchConsultantData, updateAgentReference, updateClientName, updateItineraryHiddenStatus, passToTravelDesk, updateItineraryStatusToConfirmed, generateInvoice   };
