import React from 'react';
import { FaFileDownload } from 'react-icons/fa';
import { handleFileDownload } from '../../../db/sanity'; 

function DocumentDownloadsBox({ title = 'Document Downloads', documents }) {
  const handleDownload = (file, name) => {
    if (file && file.asset && file.asset._ref) {
      // For Sanity files, use the existing handleFileDownload function
      handleFileDownload(file, name);
    } else if (typeof file === 'string' && (file.startsWith('https://storage.googleapis.com/') || file.startsWith('https://pub-6817318c353849619ce9be60b9c20ffd.r2.dev/'))) {
      // For Google Storage or Cloudflare R2 files, directly open the link
      window.open(file, '_blank');
    } else {
      console.error('Unexpected file structure:', file);
    }
  };

  return (
    <div className="bg-white rounded-lg border border-[#f0ece7] p-6 font-['Open Sans']">
      <h3 className="text-sm font-bold uppercase text-gray-600 mb-4">{title}</h3>
      <ul className="space-y-4">
        {documents.map((document, index) => (
          <li key={index} className="flex items-center justify-between">
            <div className="flex items-center text-sm text-gray-600">
              <FaFileDownload className="mr-2 text-[#073937]" />
              <span>{document.name}</span>
            </div>
            <button 
              onClick={() => handleDownload(document.file, document.name)}
              className="text-[#25363a] text-sm font-semibold underline"
            >
              Download
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DocumentDownloadsBox;