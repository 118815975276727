import { RiVipFill, RiVipLine } from "react-icons/ri";
import { formatPrice, formatPriceNearestDollar } from '../functions/generalFunctions';
import { getAirportName } from '../functions/transferFunctions';

import { IoAirplaneOutline } from 'react-icons/io5';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { PiTentLight } from 'react-icons/pi';
import { PiVanLight } from 'react-icons/pi';
import React from 'react';

const TransferOptions = ({ transfer, includeArrivalTransferVip, setIncludeArrivalTransferVip, includeDepartureTransferVip, setIncludeDepartureTransferVip, vipObject, totalPax, arrivalType, airportCode, airports }) => (
  <div className={`col-span-4 flex flex-col items-left p-4 bg-white rounded-lg border shadow-sm h-[185px]`}>
    <div className={``}>
      <div className="flex items-center">
        <RiVipFill size={30} className="text-black" />
        <div className="ml-auto">
          {/* <input type="checkbox" name="packedLunchCheck" className="checkbox checkbox-accent" defaultChecked={packedLunch} onChange={(e) => setPackedLunch(e.target.checked)} /> */}
          <input
            type="checkbox"
            name={arrivalType === 'Arrival' ? 'includeArrivalTransferVip' : 'includeDepartureTransferVip'}
            // className={`checkbox checkbox-accent`}
            className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
            checked={arrivalType === 'Arrival' ? includeArrivalTransferVip : includeDepartureTransferVip}
            onChange={(e) => {
              let isChecked = e.target.checked;
              if (arrivalType === 'Arrival') {
                setIncludeArrivalTransferVip(isChecked);
              } else {
                setIncludeDepartureTransferVip(isChecked);
              }
            }}
          />
          
        </div>
      </div>

      <div className="text-stone-950 text-[15px] font-semibold mt-3">VIP meet and assist at {getAirportName(airports, airportCode)}</div>
      <div className="text-black text-sm font-normal mt-2">{vipObject.supplier.displayName}</div>
      
      <div className="text-black text-sm font-normal ml-auto mt-5 text-right">Total: <span className="font-bold">${formatPriceNearestDollar(vipObject.supplier.perGroupPrice +(totalPax * vipObject.supplier.perPersonPrice))}</span></div>
    </div>
  </div>
);

const TransferArrivalDepartureVip = ({ objType, properties, transfer, includeArrivalTransferVip, setIncludeArrivalTransferVip, includeDepartureTransferVip, setIncludeDepartureTransferVip, vipObject, totalPax, arrivalType, airportCode, airports }) => {
  return (
    <TransferOptions
      transfer={transfer}
      includeArrivalTransferVip={includeArrivalTransferVip}
      setIncludeArrivalTransferVip={setIncludeArrivalTransferVip}
      includeDepartureTransferVip={includeDepartureTransferVip}
      setIncludeDepartureTransferVip={setIncludeDepartureTransferVip}
      vipObject={vipObject}
      totalPax={totalPax}
      arrivalType={arrivalType}
      airportCode={airportCode}
      airports={airports}
    />
  );
};

export default TransferArrivalDepartureVip;
