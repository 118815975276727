import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ItinerariesViewMenu from './ItinerariesViewMenu';
import { useSelector } from 'react-redux';
import ViewDetailedItinerary from '../components/ViewDetailedItinerary';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { loadBookingSnapshotView } from '../functions/loadDataFunctions';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function ViewItinerariesSlideOut({ isOpen, onClose, viewItinerariesMenu, parentUid, selectedViewUid, selectedViewLabel, selectedItemView }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { selectedCompanyPermissions } = useSelector((state) => state.companies);

  let uid;
  let coll;
  if (parentUid === selectedViewUid || selectedViewUid === null) {
    uid = parentUid;
    coll = 'itineraries';
  } else {
    coll = 'itinerariesVersionControl';
    uid = selectedViewUid;
  }
  // Modal for on load of page auth check
  const [isLoading, setIsLoading] = useState(true);

  const [booking, setBooking] = useState([]);
  const [bookingMaster, setBookingMaster] = useState([]);
  const [accessDenied, setAccessDenied] = useState(false);
  const [supplierReservations, setSupplierReservations] = useState([]);

  // Possibly all connected to guests but needs refactoring
  const { allCountries } = useSelector((state) => state.countries);
  var countriesShort = allCountries.result;
  let tempCountries = JSON.parse(JSON.stringify(countriesShort));

  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false);

  useEffect(() => {
    let unsubscribe;
    let unsubscribeSupplierReservations;

    async function fetchData() {
      unsubscribe = loadBookingSnapshotView(uid, coll, async (tempBookings) => {
        if (tempBookings.mojoItinerary || (!user.internalUser && tempBookings.hidden)) {
          setAccessDenied(true);
          setShowAccessDeniedModal(true);
          setIsLoading(false);
          return;
        }

        // Only load data if access is granted
        //const rebuiltGuests = await mergePaxData(tempBookings);
        //setGuests(rebuiltGuests);
        setBooking(tempBookings);
        setBookingMaster(tempBookings);
        //setVersion(tempBookings.version);

        setIsLoading(false);

        // Set up listener for supplierReservations
        const db = getFirestore();
        const supplierReservationsRef = collection(db, 'itineraries', uid, 'supplierReservations');
        unsubscribeSupplierReservations = onSnapshot(supplierReservationsRef, (snapshot) => {
          const reservations = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSupplierReservations(reservations);
        });
      });
    }
    fetchData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (unsubscribeSupplierReservations) {
        unsubscribeSupplierReservations();
      }
    };
  }, [navigate, uid, user.internalUser, selectedItemView]);

  // reset variables
  const resetForm = useCallback(() => {});

  const resetFormClose = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-0 flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="flex-1 h-full overflow-y-auto">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto">
                        <div className="bg-white px-4 pt-6 sm:px-6">
                          <div className="flex items-center ">
                            {' '}
                            <div className="ml-0 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative rounded-md bg-white text-black hover:text-black"
                                onClick={() => {
                                  resetForm();

                                  resetFormClose();
                                }}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>{' '}
                          <Dialog.Title className={`my-5 text-2xl font-base font-semibold leading-6 `}>
                            <div className="flex items-center justify-between">
                              Detailed Itinerary <ItinerariesViewMenu viewItinerariesMenu={viewItinerariesMenu} selectedItemView={selectedItemView} />
                            </div>
                          </Dialog.Title>
                        </div>

                        <div className="mb-5 mx-5">
                          <ViewDetailedItinerary
                            tabName="Detailed Itinerary"
                            icon={DocumentTextIcon}
                            booking={booking}
                            bookingMaster={bookingMaster}
                            supplierReservations={supplierReservations}
                            selectedViewLabel={selectedViewLabel}
                            hideStatusPill={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ViewItinerariesSlideOut;
