import 'react-datepicker/dist/react-datepicker.css';

import { Configure, Hits, InstantSearch, Pagination, connectSearchBox } from 'react-instantsearch-dom';
import { FaAsterisk, FaLongArrowAltLeft, FaLongArrowAltRight, FaTrash } from 'react-icons/fa';
import { FiPlus, } from 'react-icons/fi';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle, IoIosInformationCircle } from 'react-icons/io';
import { MdBlock, MdEventAvailable, MdKingBed, MdLocationPin } from 'react-icons/md';
import React, { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { getPropObj} from '../../functions/generalFunctions';
import {
  getRooms,
  handleDateChange,
  nextDate,
  prevDate,
  removeAllAvailability,
  renderMonths,
  resetDate,
} from '../../functions/availabilityFunctions';
import { getStock, stockCountCheck, stockCountCheckZero, updateDates } from '../../functions/stockFunctions';

import FullScreenPropertyModal from '../../components/FullScreenPropertyModal';
import { BsQuestionCircle } from 'react-icons/bs';
import ButtonOutlineSmall from '../../components/Buttons/ButtonOutlineSmall';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { CustomDateInputAvailability } from '../../functions/datePickerFunctions';
import DatePicker from 'react-datepicker';
import PropertiesSlideOutComp from '../../components/PropertiesSlideOutComp';
import ReactTooltip from 'react-tooltip';
import Sidebar from '../../partials/Sidebar';
import algoliasearch from 'algoliasearch';
import { deeShadow } from '../../css/additional-styles/custom';
import moment from 'moment';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export const INSTANT_SEARCH_INDEX_NAME = 'properties';

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

const VirtualSearchBox = connectSearchBox(() => null);

// !Search for properties start
function createURL(searchState) {
  return qs.stringify(searchState, { addQueryPrefix: true });
}

function searchStateToUrl({ location }, searchState) {
  if (Object.keys(searchState).length === 0) {
    return '';
  }

  // Remove configure search state from query parameters
  const { configure, ...rest } = searchState;
  return `${location.pathname}${createURL(rest)}`;
}

function urlToSearchState({ search }) {
  return qs.parse(search.slice(1));
}

const handleChangePropertiesSearch = (event) => {
  // !console.log(event.currentTarget.value);
};

function Availability() {
  // SEARCH
  function urlToSearchState({ search }) {
    return qs.parse(search.slice(1));
  }
  // !Search for properties start

  const [searchState, setSearchState] = useState(() => urlToSearchState(window.location));
  const timerRef = useRef(null);

  useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      window.history.pushState(searchState, null, searchStateToUrl({ location: window.location }, searchState));
    }, 400);
  }, [searchState]);
  // !Search for properties end

  const { user } = useSelector((state) => state.auth);
  // const { allProperties } = useSelector((state) => state.properties);
  // var propertiesShort = allProperties.result;

  // let tempProperties = JSON.parse(JSON.stringify(propertiesShort));
  // ************************************* // STATE START
  const [rooms, setRooms] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoadingStock, setIsLoadingStock] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [callUpdateDates, setCallUpdateDates] = useState(false);
  // Dates etc

  // Set start day & end day
  // DATES
  const [realDate, setRealDate] = useState(moment().format('YYYY-MM-DD'));
  const dateToUse = moment(realDate).format('YYYY-MM-DD');
  const [dateStart, setDateStart] = useState(dateToUse);
  const [arrowDate, setArrowDate] = useState(dateToUse);
  const [dateEnd, setDateEnd] = useState(moment(realDate).add(3, 'months').format('YYYY-MM-DD'));

  const dayNums = Array.from({ length: 21 }, (_, i) => i); // [0, 1, 2, ..., 20]

  const [properties, setProperties] = useState([]);

  const [property, setProperty] = useState([]);
  //const [renderProps, setRenderProps] = useState(false);

  // Form states
  const [monthDropDown, setMonthDropDown] = useState('select');

  // eslint-disable-next-line no-unused-vars
  const [flatPickrDateStart, setFlatPickrDateStart] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [flatPickrDateEnd, setFlatPickrDateEnd] = useState(null);
  const [fetchName] = useState('resReqProperties');

  // Show & hide room typescript
  const [showRoomVehicle, setShowRoomVehicle] = useState('Vehicle');
  const [showRoomGuide, setShowRoomGuide] = useState('Guide/Pilot Bed');
  const [showRoomActivity, setShowRoomActivity] = useState('Activity');

  const [showModal, setShowModal] = useState(false);
  // Declare a state variable called open and set its initial value to false
  const [open, setOpen] = useState(false);
  // ************************************* // STATE END

  // resetDate
  useEffect(() => {
    //call function when something change in state
    resetDate(dateStart, dateEnd, property, setProperty);
    //renderProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Make booking
  const [callMakeRooms, setCallMakeRooms] = useState(null);
  const [getPropertiesAndStock, SetGetPropertiesAndStock] = useState(null);

  useEffect(() => {
    if (callUpdateDates !== false) {
      updateDates(rooms, properties, setRooms, setProperties, dateStart, dateEnd, false, false, false, dateStart, false, false, false, false);
      setCallUpdateDates(false);
    }
  }, [callUpdateDates, dateEnd, dateStart, properties, rooms]);

  //Pagination for properties
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentProperties = properties.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ! NEW CODE CHECK EVERYTHING

  useEffect(() => {
    if (callMakeRooms !== null && getPropertiesAndStock !== null) {
      const propIndexCall = callMakeRooms;
      // !console.log("START ADD: " + propIndexCall);
      // if (!bookings.length || bookings[bookings.length - 1].propUid !== properties[propIndexCall].uid) {
      //   // !console.log("adding booking");
      //   addBooking(propIndexCall, properties[propIndexCall].uid, bookings, setBookings, date, rooms, packageTab, properties);
      // } else {
      //   toast.error("You can't add a property directly after the same property");
      // }
      if (getPropertiesAndStock === true) {
        // !console.log("loading stock 2");
        getStock(propIndexCall, dateStart, dateEnd, properties, setProperties, rooms, setRooms, null, null, null, null, dateStart, null);
      } else {
        // reload stock only if not in rooms array
        const propUid = properties[propIndexCall].uid;
        const containsPropUid = rooms.filter((room) => room.propUid === propUid).length > 0;
        // !console.log(containsPropUid);
        if (!containsPropUid) {
          // !console.log("loading stock 4");
          getStock(propIndexCall, dateStart, dateEnd, properties, setProperties, rooms, setRooms, null, null, null, null, dateStart, null);
        }
      }
      setCallMakeRooms(null);
      SetGetPropertiesAndStock(null);
      setTimeout(() => {
        setLoadingData(false);
      }, 500);
    }
  }, [callMakeRooms, dateEnd, dateStart, getPropertiesAndStock, properties, rooms]);

  // PROPERTY FILTERS ************************************************************* START
  const generateFilters = () => {
    //let filters = 'active:true';
    let filters = `active:true AND platformResRequest.active:true`;

    if (user.accessLevel === 'limited') {
      //filters = `(supplier.name:"Nomad Tanzania" OR supplier.name:"Booked Direct") AND ` + filters;
      filters = `(supplier.name:"Nomad Tanzania") AND ` + filters;
    }

    return filters;
  };

  const filters = generateFilters();

  //const filters = `active:true AND platformResRequest.active:true`;

  // slide out properties
  const [isSlideOutPropertiesOpen, setIsSlideOutPropertiesOpen] = useState(false);
  const handleOpenPropertiesSlideOut = () => setIsSlideOutPropertiesOpen(true);
  const handleClosePropertiesSlideOut = () => {
    setIsSlideOutPropertiesOpen(false);
  };

  // Property library full page modal
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
  const [selectedPropertyUid, setSelectedPropertyUid] = useState(null);

  const handleOpenPropertyModal = (uid) => {
    setSelectedPropertyUid(uid);
    setIsPropertyModalOpen(true);
  };

  const handleClosePropertyModal = () => {
    setIsPropertyModalOpen(false);
    setSelectedPropertyUid(null);
  };

  const [switchParkId, setSwitchParkId] = useState(null);
  const [switchId, setSwitchId] = useState(null);
  const [switchStartDate, setSwitchStartDate] = useState(null);
  const [switchEndDate, setSwitchEndDate] = useState(null);

  const [fetchCustom, setFetchCustom] = useState(true);
  const [customLocations, setCustomLocations] = useState([]);

  const contentWidth = '1235';

  // BEFORE RETURN

  return (
    <div className="flex h-screen overflow-hidden">
      <CircleSpinnerOverlay loading={loadingData} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}

            {/* Left: Title */}
            <div className="h-14 flex items-center pl-5">
              <div className="">
                <h1 className={`brand-text-color-800 font-normal font-roca text-4xl mt-8`}>Search for availability</h1>
                {/* <button
                  onClick={() => {
                    console.log('ROOMS');
                    console.log(JSON.stringify(rooms, undefined, 4));
                  }}
                >
                  console.log(rooms);
                </button> */}
              </div>
            </div>

            {isLoading ? (
              // <Spinner />
              <CircleSpinnerOverlay loading={isLoading} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />
            ) : (
              <div className="mt-4">
                <PropertiesSlideOutComp
                  isOpen={isSlideOutPropertiesOpen}
                  onClose={handleClosePropertiesSlideOut}
                  deeShadow={deeShadow}
                  searchClient={searchClient}
                  searchState={searchState}
                  setSearchState={setSearchState}
                  filters={filters}
                  //filters={user.accessLevel !== 'travelAgent' ? filters : 'active:true AND park.id:"fab842f3-c1d1-403f-9072-bd322a70e88a"'}
                  properties={properties}
                  setLoadingData={setLoadingData}
                  setCallMakeRooms={setCallMakeRooms}
                  SetGetPropertiesAndStock={SetGetPropertiesAndStock}
                  customLocations={customLocations}
                  setFetchCustom={setFetchCustom}
                  VirtualSearchBox={VirtualSearchBox}
                  setSwitchParkId={setSwitchParkId}
                  setSwitchId={setSwitchId}
                  setSwitchStartDate={setSwitchStartDate}
                  setSwitchEndDate={setSwitchEndDate}
                  // Used for rendering the property library modal
                  onOpenPropertyModal={handleOpenPropertyModal}
                />
                 {isPropertyModalOpen && <FullScreenPropertyModal isOpen={isPropertyModalOpen} onClose={handleClosePropertyModal} propertyUid={selectedPropertyUid} />}
                <div>
                  {/* LEFT AND RIGHT LAYOUT FOR PROPERTIES & RESULTS START */}
                  <div className="flex px-5 w-[1380px]">
                    {/* RIGHT COLUMN FOR RESULTS START */}
                    <div className="pr-5 absolute">
                      <div style={deeShadow} className={`p-5 h-16 bg-white rounded-lg flex flex-row w-[${contentWidth}px]`}>
                        {/* {rooms.length > 0 ? ( */}
                        <div className="flex divide-x">
                          <div className="flex flex-row place-items-center mr-4">
                            <div className="relative">
                              <DatePicker
                                className="form-input pl-9 text-slate-500 hover:text-slate-400 text-sm font-medium focus:border-slate-400 h-10 rounded-sm border-slate-400"
                                selected={realDate ? moment(realDate, 'YYYY-MM-DD').toDate() : null}
                                dateFormat="eee dd MMM yy"
                                minDate={new Date()}
                                disabled={!realDate}
                                onChange={(date) => {
                                  console.log('Selected date:', date);
                                  const selDate = moment(date).format('YYYY-MM-DD');
                                  setDateStart(moment(selDate, 'YYYY-MM-DD').subtract(0, 'days').format('YYYY-MM-DD'));
                                  setArrowDate(moment(selDate, 'YYYY-MM-DD').subtract(0, 'days').format('YYYY-MM-DD'));
                                  setRealDate(selDate);
                                  setDateEnd(moment(selDate).subtract(0, 'days').add(3, 'months').format('YYYY-MM-DD'));
                                  setCallUpdateDates(true);
                                }}
                                customInput={<CustomDateInputAvailability />} // Use the custom input component
                                //className="form-control"
                                calendarClassName="custom-datepicker datepicker-padding"
                                showPopperArrow={false}
                              />

                              <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                                <svg className="w-4 h-4 fill-current text-slate-500 ml-3" viewBox="0 0 16 16">
                                  <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row place-items-center pl-5 mr-4">
                            <div className="flex-grow text-right">
                              <select
                                className="form-select w-fit rounded-md border-slate-400"
                                value={monthDropDown}
                                onChange={(e) =>
                                  handleDateChange(
                                    e,
                                    setMonthDropDown,
                                    setFlatPickrDateStart,
                                    setFlatPickrDateEnd,
                                    setDateStart,
                                    setDateEnd,
                                    setArrowDate,
                                    rooms,
                                    properties,
                                    setRooms,
                                    setProperties,
                                    dateStart,
                                    dateEnd,
                                    setCallUpdateDates,
                                  )
                                }
                              >
                                {renderMonths()}
                              </select>
                            </div>{' '}
                          </div>
                          <div className="flex flex-row place-items-center pl-5 mr-4">
                            <ButtonOutlineSmall
                              text="Add Properties"
                              icon={<FiPlus />}
                              iconPosition="left"
                              onClick={handleOpenPropertiesSlideOut}
                              className="h-10 w-40 flex justify-center items-center"
                            />
                          </div>
                          <div className="flex flex-row place-items-center pl-5">
                            <MdEventAvailable size={20} /> <div className="pl-2 text-xs leading-normal mr-4">Availability</div>
                          </div>
                          <div className="flex flex-row place-items-center mr-4 pl-5">
                            <MdBlock size={20} /> <div className="pl-2 text-xs w-32 leading-normal">No Rooms Available</div>
                          </div>
                          <div className="flex flex-row place-items-center mr-4 pl-5">
                            <BsQuestionCircle size={18} /> <div className="pl-2 text-xs leading-normal">Dates not searched</div>
                          </div>
                          <div className="flex flex-row  place-items-center mr-2 pl-5">
                            <p className="">
                              <FaAsterisk size={18} />
                            </p>{' '}
                            <div className="pl-2 text-xs w-40 leading-normal">Room type contains provisional bookings</div>
                          </div>

                          {/* <div className="flex flex-row w-30 place-items-center mr-2 pl-5">
                              <TbLetterP size={18} /> <div className="pl-2 text-xs w-20 leading-tight">Per Person Sharing Rate</div>
                            </div> */}
                          {/* <div className="flex flex-row w-30 place-items-center mr-2 pl-5">
                            <TbLetterR size={18} /> <div className="pl-2 text-xs w-20 leading-tight">Availability On Request</div>
                          </div>
                          <div className="flex flex-row w-30 place-items-center mr-2 pl-5">
                            <TbLetterP size={18} /> <div className="pl-2 text-xs w-20 leading-tight">Per Person Sharing Rate</div>
                          </div>
                          <div className="flex flex-row w-30 place-items-center mr-2 pl-5">
                            <TbLetterU size={18} /> <div className="pl-2 text-xs w-20 leading-tight">Unit Rate</div>
                          </div> */}
                        </div>
                        {/* ) : (
                            <h2 className="text-sm brand-text-color-800 font-normal italic mb-5">Please select a property from the left</h2>
                          )} */}
                      </div>
                      <div className="h-5"></div>
                      {/* RESULTS START */}
                      {/* Results table */}
                      {rooms.length > 0 ? (
                        <div className="absolute">
                          <div style={deeShadow} className={`p-5 bg-white rounded-lg w-[${contentWidth}px] top-[65px]`}>
                            {/* Filters */}
                            <div className="flex items-center ml-2 text-xs">
                              {/* Show Vehicles */}
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={showRoomVehicle !== 'Vehicle'}
                                  onChange={() => {}}
                                  className="checkbox checkbox-sm ring-offset-0 focus:ring-0 focus:shadow-none checkbox-accent mr-2"
                                  onClick={() => {
                                    if (showRoomVehicle === 'Vehicle') {
                                      setShowRoomVehicle('');
                                    } else {
                                      setShowRoomVehicle('Vehicle');
                                    }
                                  }}
                                />
                                Show Vehicles
                              </div>
                              {/* Show Guide & Pilot Rooms */}
                              <div className="flex items-center ml-5">
                                <input
                                  type="checkbox"
                                  checked={showRoomGuide !== 'Guide/Pilot Bed'}
                                  onChange={() => {}}
                                  className="checkbox checkbox-sm ring-offset-0 focus:ring-0 focus:shadow-none checkbox-accent mr-2"
                                  onClick={() => {
                                    if (showRoomGuide === 'Guide/Pilot Bed') {
                                      setShowRoomGuide('');
                                    } else {
                                      setShowRoomGuide('Guide/Pilot Bed');
                                    }
                                  }}
                                />
                                Show Guide & Pilot Rooms
                              </div>
                              {/* Show Activities */}
                              <div className="flex items-center ml-5">
                                <input
                                  type="checkbox"
                                  checked={showRoomActivity !== 'Activity'}
                                  onChange={() => {}}
                                  className="checkbox checkbox-sm ring-offset-0 focus:ring-0 focus:shadow-none checkbox-accent mr-2"
                                  onClick={() => {
                                    if (showRoomActivity === 'Activity') {
                                      setShowRoomActivity('');
                                    } else {
                                      setShowRoomActivity('Activity');
                                    }
                                  }}
                                />
                                Show Activities
                              </div>
                            </div>

                            <div className="pt-5 grid grid-cols-26 gap-px">
                              {/* Actions */}
                              <div className="ml-2 relative bg-white overflow-hidden col-span-4 text-xs brand-text-custom-color-warning">
                                <button
                                  className=""
                                  onClick={() => {
                                    removeAllAvailability(properties, setProperties, setLoadingData, setRooms);
                                  }}
                                >
                                  Remove All
                                </button>
                              </div>

                              <div className="relative bg-white overflow-hidden col-span-full rounded-l-xl flex justify-end mb-5">
                                <div className="flex items-center gap-2 brand-bg-custom-color mr-2">
                                  <div className="pl-5 font-bold text-xs uppercase leading-loose">
                                    {moment(dateStart).format('ddd D MMM YYYY')}
                                    <span className="font-normal lowercase"> to </span>
                                    {moment(dateEnd).format('ddd D MMM YYYY')}
                                  </div>
                                  <div className="flex items-center gap-2 ml-4">
                                    {/* Previous button */}
                                    <button
                                      className="pt-0.5 border-x border-white px-2 text-slate-500 hover:text-slate-600 disabled:text-slate-400 disabled:cursor-not-allowed"
                                      disabled={moment(realDate).add(7, 'days').isAfter(moment(arrowDate))}
                                      onClick={() => {
                                        prevDate(setArrowDate, arrowDate);
                                      }}
                                    >
                                      <FaLongArrowAltLeft size={20} />
                                    </button>

                                    {/* Next button */}
                                    <button
                                      className="pt-0.5 mr-2 border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                                      onClick={() => {
                                        nextDate(setArrowDate, arrowDate);
                                      }}
                                    >
                                      <FaLongArrowAltRight size={20} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Results table */}
                            <div className="bg-white rounded-sm overflow-hidden">
                              {/* Header Row */}
                              <div className="grid grid-cols-26 gap-px">
                                {/* Accommodation label */}
                                <div className="mt-2 ml-3 p-0.5 sm:p-1.5 col-span-3 text-xs font-bold">Accommodation</div>
                                {/* Info Icon Header Placeholder */}
                                <div className="col-span-1"></div>
                                {/* Dates */}
                                {dayNums.map((num) => (
                                  <div key={uuidv4()} className="px-1 py-1">
                                    <div className="text-slate-500 text-xs text-center">{moment(arrowDate).add(num, 'days').format('ddd')}</div>
                                    <div className="text-slate-500 text-xs text-center">{moment(arrowDate).add(num, 'days').format('D')}</div>
                                    <div className="text-slate-500 text-xs text-center">{moment(arrowDate).add(num, 'days').format('MMM')}</div>
                                  </div>
                                ))}
                                {/* Collapse Button Header Placeholder */}
                                <div className="col-span-1"></div>
                              </div>

                              {isLoadingStock ? (
                                <CircleSpinnerOverlay loading={isLoadingStock} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />
                              ) : (
                                rooms.map((x, propertyCount) =>
                                  rooms[propertyCount].loading ? (
                                    <div key={uuidv4()} className="px-2 py-0.5">
                                      <div className="grid grid-cols-26 brand-bg-custom-color rounded-t rounded-b mb-1 animate-pulse">
                                        {/* Property Name */}
                                        <div className="ml-2 h-10 col-span-3 text-xs flex items-center">{rooms[propertyCount].name}</div>
                                        {/* Info Icon Placeholder */}
                                        <div className="col-span-1"></div>
                                        {/* Availability Icons Placeholder */}
                                        <div className="h-full flex col-span-21 justify-between items-center text-xs whitespace-nowrap">Checking availability...</div>
                                        {/* Collapse Button Placeholder */}
                                        <div className="col-span-1"></div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div key={uuidv4()} className="px-2 py-0.5">
                                      <div className={`grid grid-cols-26 brand-bg-custom-color rounded-t ${!rooms[propertyCount].collapsed ? '' : 'rounded-b'}`}>
                                        {/* Property Name */}
                                        <div className="ml-2 h-10 col-span-3 text-xs flex items-center">{rooms[propertyCount].name}</div>
                                        {/* Info Icon */}
                                        <div className="col-span-1 flex items-center justify-center">
                                          <IoIosInformationCircle size={18} className="ml-1" />
                                        </div>
                                        {/* Availability Icons */}
                                        {dayNums.map((day) => (
                                          <div key={uuidv4()} className="col-span-1 justify-center items-center">
                                            {stockCountCheck(day, propertyCount, arrowDate, rooms)}
                                          </div>
                                        ))}
                                        {/* Collapse Button */}
                                        <div className="col-span-1 flex justify-center items-center">
                                          <button
                                            onClick={() => {
                                              rooms[propertyCount].collapsed = !rooms[propertyCount].collapsed;
                                              setRooms([...rooms]);
                                            }}
                                          >
                                            {!rooms[propertyCount].collapsed ? <IoIosArrowDropupCircle size={20} /> : <IoIosArrowDropdownCircle size={20} />}
                                          </button>
                                        </div>
                                      </div>
                                      {/* Expanded Room Details */}
                                      {!rooms[propertyCount].collapsed ? (
                                        <div className="grid grid-cols-26 gap-px border-x border-b border-slate-200 rounded-b mb-0">
                                          {/* Room Types */}
                                          <div className="relative bg-white overflow-hidden col-span-4">
                                            <div className="flex flex-col py-0.5 sm:py-1.5 text-xs">
                                              {properties[getPropObj(x.propUid, properties)].rooms
                                                .filter((rm) => !rm.fetchError && (user.internalUser || !rm.hideExternalUsers)) // Filter out rooms with fetchError
                                                .map(
                                                  (rm, i) =>
                                                    rm.type !== showRoomVehicle &&
                                                    rm.type !== showRoomGuide &&
                                                    rm.type !== showRoomActivity && (
                                                      <div key={uuidv4()} className={`mb-0 ${i > 0 ? 'border-t border-slate-200' : ''}`}>
                                                        <div className="py-0.5 pl-2">{rm.name}</div>
                                                      </div>
                                                    ),
                                                )}
                                            </div>
                                          </div>
                                          {/* Room Availability */}
                                          {dayNums.map((day) => (
                                            <div key={uuidv4()} className="col-span-1">
                                              <div className="relative bg-white overflow-hidden">
                                                <div className="flex flex-col py-0.5 sm:py-1.5">
                                                  {stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'total', null)
                                                    ? getRooms(moment(arrowDate).add(day, 'days').format('YYYY-MM-DD'), propertyCount, rooms, 'total')
                                                    .filter(res => user.internalUser || !res.hideExternalUsers) // Filter out rooms with hideExternalUsers
                                                    .map(
                                                        (res, ii) =>
                                                          res.stockType !== showRoomVehicle &&
                                                          res.stockType !== showRoomGuide &&
                                                          res.stockType !== showRoomActivity && (
                                                            <button key={uuidv4()} className={`w-full text-left mb-0 ${ii > 0 ? 'border-t border-slate-200' : ''}`} disabled>
                                                              <div
                                                                className={`py-0.5 border-r border-slate-200 ${
                                                                  res.stockCount === 0
                                                                    ? stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res) > 0
                                                                      ? 'bg-red-700 px-2'
                                                                      : 'bg-red-700 text-white'
                                                                    : ''
                                                                }`}
                                                              >
                                                                <div
                                                                  data-tip
                                                                  data-for={`${stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res) > 0 && 'Provisional' + res.stockUuid}`}
                                                                  className={`text-xs text-center font-semibold truncate items-center ${
                                                                    res.stockCount > 0 && stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res) > 0
                                                                      ? 'text-black underline cursor-pointer'
                                                                      : stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res) > 0
                                                                      ? 'text-white underline cursor-pointer'
                                                                      : ''
                                                                  }`}
                                                                >
                                                                  <ReactTooltip id={'Provisional' + res.stockUuid} type="dark" effect="solid">
                                                                    <span>Provisional: {stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res)}</span>
                                                                  </ReactTooltip>
                                                                  {/* {res.stockCount !== 0 ? res.stockCount : stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res)} */}
                                                                  {res.stockCount !== 0 ? res.stockCount : 0}
                                                                  {stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res) > 0 && '*'}
                                                                </div>
                                                              </div>
                                                            </button>
                                                          ),
                                                      )
                                                    : properties[getPropObj(x.propUid, properties)].rooms
                                                    .filter((rm) => !rm.fetchError && (user.internalUser || !rm.hideExternalUsers))
                                                        .map(
                                                          (rm, ri) =>
                                                            rm.type !== showRoomVehicle &&
                                                            rm.type !== showRoomGuide &&
                                                            rm.type !== showRoomActivity && (
                                                              <div key={uuidv4()} className={`w-full text-left mb-0 ${ri > 0 ? 'border-t border-r border-slate-200' : 'border-r border-slate-200'}`}>
                                                                <div className="py-0.5 px-2">
                                                                  <div className="text-xs text-center font-semibold">-</div>
                                                                </div>
                                                              </div>
                                                            ),
                                                        )}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                          {/* Empty column for alignment */}
                                          <div className="col-span-1"></div>
                                        </div>
                                      ) : null}
                                    </div>
                                  ),
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="absolute">
                          <div style={deeShadow} className={`p-5 bg-white rounded-lg w-[${contentWidth}px] top-[65px] text-sm`}>
                            Please select a date range or month and then click Add Properties above.
                          </div>
                        </div>
                      )}
                    </div>
                    {/* RESULTS END */}
                    {/* RIGHT COLUMN FOR RESULTS END */}
                  </div>
                  {/* LEFT AND RIGHT LAYOUT FOR PROPERTIES & RESULTS START */}
                  {/* TEST BUTTON END */}
                </div>
              </div>
            )}
          </div>
        </main>
      </div>

      <div className="mt-5">
        {user.email === 'prideauxxxxxx@gmail.com' ? (
          <>
            <button
              onClick={() => {
                console.log('PROPERTIES');
                console.log(JSON.stringify(properties, undefined, 4));
              }}
            >
              console.log(properties);
            </button>
            <div className="w-4"></div>
            <button
              onClick={() => {
                console.log('ROOMS');
                console.log(JSON.stringify(rooms, undefined, 4));
              }}
            >
              console.log(rooms);
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Availability;
