import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from '../../../partials/Sidebar';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { Configure, InstantSearch, connectSearchBox } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch';
import SupplierKanbanBoard from './components/SupplierKanbanBoard.jsx';
import ManageBookingsSearchBox from '../../../components/ManageBookingsSearchBox';
import ManageBookingsSlideOut from '../../../components/ManageBookingsSlideOut';


import { LuRefreshCw } from 'react-icons/lu';
import { LuFilter } from 'react-icons/lu';

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
const searchClient = algoliasearch(appId, apiKey);
const VirtualSearchBox = connectSearchBox(() => null);

function ManageSuppliers() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [laneKeys, setLaneKeys] = useState({});
  const [refreshingLanes, setRefreshingLanes] = useState({});
  const { user } = useSelector((state) => state.auth);
  const [showAllSuppliers, setShowAllSuppliers] = useState(false);
  const [searchState, setSearchState] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  // Manage the slide out
  const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [selectedHit, setSelectedHit] = useState(null);
  const [isSlideOutOpen, setIsSlideOutOpen] = useState(false);

  const handleCloseSlideOut = useCallback(() => {
    setIsSlideOutOpen(false);
    setSelectedHit(null);
  }, []);

  useEffect(() => {
    // Update search state when showAllSuppliers changes
    setSearchState(prevState => ({
      ...prevState,
      configure: {
        ...prevState.configure,
        filters: generateFilters()
      }
    }));
  }, [showAllSuppliers]);

  const generateFilters = () => {
    if (!showAllSuppliers) {
      return `user.uid:${user.uid}`;
    }
    return '';
  };

  const handleChangePropertiesSearch = (event) => {
    // Optional: Add specific handling if needed
  };

  const handleRefresh = () => {
    setIsRefreshing(true);
    const allLanes = ['expiringProvisional', 'nomadProvisional', 'thirdPartyProvisional'];

    setRefreshingLanes((prevState) => {
      const newState = { ...prevState };
      allLanes.forEach((lane) => {
        newState[lane] = true;
      });
      return newState;
    });

    searchClient.clearCache();

    setTimeout(() => {
      setLaneKeys((prevKeys) => {
        const newKeys = { ...prevKeys };
        allLanes.forEach((lane) => {
          newKeys[lane] = (prevKeys[lane] || 0) + 1;
        });
        return newKeys;
      });

      setTimeout(() => {
        setRefreshingLanes({});
        setIsRefreshing(false);
      }, 500);
    }, 3000);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto">
        <main className="flex-grow px-5 py-8">
          <h1 className="brand-text-color-800 font-normal font-roca text-4xl mb-2">Manage Suppliers</h1>
          {isLoading ? (
            <CircleSpinnerOverlay 
              loading={isLoading} 
              color="rgba(0,0,0,0.5)" 
              overlayColor="rgba(255,255,255,0.5)" 
              message="Loading data..." 
            />
          ) : (
            <div className="flex flex-col h-[calc(100vh-12rem)] min-h-[500px]">
              <InstantSearch 
                indexName="supplierReservations" 
                searchClient={searchClient}
                searchState={searchState}
                onSearchStateChange={setSearchState}
              >
                <VirtualSearchBox />
                <Configure 
                  filters={generateFilters()} 
                  hitsPerPage={100} 
                />
                <div className="bg-white rounded-md shadow p-5 mb-5">
                  <div className="flex flex-wrap items-center justify-between gap-4">
                    <div className="flex-grow flex items-center space-x-4">
                      <ManageBookingsSearchBox onChange={handleChangePropertiesSearch} />
                      <div className="relative">
                        <button 
                          className="bg-nomadEvergreen-100 border-none rounded-full p-3 flex items-center justify-center hover:cursor-pointer" 
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <LuFilter className="text-nomadEvergreen-700 hover:text-nomadEvergreen-800 w-5 h-5" />
                        </button>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="showAllSuppliers"
                          className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                          checked={!showAllSuppliers}
                          onChange={() => setShowAllSuppliers(!showAllSuppliers)}
                        />
                        <label htmlFor="showAllSuppliers" className="ml-2 text-base font-medium text-gray-900">
                          Show only my suppliers
                        </label>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button 
                        onClick={handleRefresh} 
                        className="bg-nomadEvergreen-100 border-none rounded-full p-3 flex items-center justify-center hover:cursor-pointer" 
                        disabled={isRefreshing}
                      >
                        <LuRefreshCw 
                          className={`text-nomadEvergreen-700 hover:text-nomadEvergreen-800 w-5 h-5 ${
                            isRefreshing ? 'animate-spin' : ''
                          }`} 
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-md shadow p-5 mb-5">
                <SupplierKanbanBoard
  searchClient={searchClient}
  user={user}
  indexName="supplierReservations"
  filters={generateFilters()}
  laneKeys={laneKeys}
  refreshingLanes={refreshingLanes}
  showAllSuppliers={showAllSuppliers}
  onQuickView={(itineraryUid) => {
    setSelectedHit(itineraryUid);
    setIsSlideOutOpen(true);
  }}
/>
      </div>
              </InstantSearch>
            </div>
          )}
        </main>
      </div>
      <ManageBookingsSlideOut 
        isOpen={isSlideOutOpen} 
        onClose={handleCloseSlideOut} 
        selectedHit={selectedHit} 
        user={user} 
      />
    </div>
  );
}

export default ManageSuppliers;