import React, { useEffect, useState } from "react";
import { Tab } from '@headlessui/react';
import Sidebar from "../../../partials/Sidebar";
import TravelInfoTab from "./components/TravelInfoTab";
import { fetchTravelInfoData } from "../functions/propertyFunctions";

function TravelInfo() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [travelInfo, setTravelInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTravelInfo = async () => {
      try {
        const data = await fetchTravelInfoData("Tanzania");
        setTravelInfo(data);
        console.log("Travel Info Document:", JSON.stringify(data, null, 2));
      } catch (error) {
        console.error("Error fetching travel info:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTravelInfo();
  }, []);

  const tabs = travelInfo ? travelInfo.sections.map((section) => ({
    name: section.tabTitle,
    component: <TravelInfoTab tabData={section} />,
  })) : [];

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="max-w-7xl mx-auto">
              <header className="mb-8">
                <h1 className="text-4xl font-roca text-nomadBlue mb-2">
                  Travel Info
                </h1>
                <p className="text-lg font-sans max-w-4xl">
                  Important travel information for Tanzania.
                </p>
              </header>

              <div className="bg-white rounded-md shadow p-5">
                {isLoading ? (
                  <div>Loading...</div>
                ) : tabs.length > 0 ? (
                  <Tab.Group>
                    <Tab.List className="flex space-x-1 mb-8 rounded-xl bg-white shadow-md">
                      {tabs.map((tab) => (
                        <Tab
                          key={tab.name}
                          className={({ selected }) =>
                            `w-full rounded-lg py-4 text-sm font-medium leading-5 flex items-center justify-center
                            ${selected ? 'bg-[#073937] text-white shadow' : 'text-[#3e3d3d] hover:bg-gray-100'}`
                          }
                        >
                          {tab.name}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                      {tabs.map((tab, idx) => (
                        <Tab.Panel key={idx}>
                          {tab.component}
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>
                ) : (
                  <div>No travel information available.</div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default TravelInfo;