// tripAdvisorFunctions.js
import axios from 'axios';

const API_BASE_URL = '/api/tripAdvisor';

export const fetchPropertyDetails = async (tripAdvisorId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}?tripAdvisorId=${tripAdvisorId}&endpoint=details`);
    return response.data;
  } catch (error) {
    console.error('Error fetching property details:', error);
    throw error;
  }
};

export const fetchPropertyPhotos = async (tripAdvisorId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}?tripAdvisorId=${tripAdvisorId}&endpoint=photos`);
    return response.data;
  } catch (error) {
    console.error('Error fetching property photos:', error);
    throw error;
  }
};

export const fetchPropertyReviews = async (tripAdvisorId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}?tripAdvisorId=${tripAdvisorId}&endpoint=reviews`);
    return response.data;
  } catch (error) {
    console.error('Error fetching property reviews:', error);
    throw error;
  }
};

export const getTripAdvisorData = async (tripAdvisorId) => {
  try {
    const [detailsResponse, reviewsResponse] = await Promise.all([
      fetchPropertyDetails(tripAdvisorId),
      fetchPropertyReviews(tripAdvisorId),
    ]);

    const mergedProperty = {
      ...detailsResponse,
      reviews: reviewsResponse,
    };

    console.log('Merged Property:', mergedProperty);
    return mergedProperty;
  } catch (error) {
    console.error('Error fetching TripAdvisor data:', error);
    throw error;
  }
};