import Select, { components } from 'react-select';

import React from 'react';

const CustomValueContainer = ({ children, ...props }) => {
  const iconContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '8px',
  };

  const childrenWithoutPlaceholder = React.Children.toArray(children).filter((child) => child.type !== components.Placeholder);

  return (
    <components.ValueContainer {...props}>
      <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
        {props.selectProps.icon && <div style={iconContainerStyle}>{props.selectProps.icon}</div>}
        {childrenWithoutPlaceholder}
      </div>
    </components.ValueContainer>
  );
};

const CustomSelectNoBorderTransfers = ({
  value,
  defaultValue,
  onChange,
  options,
  placeholder,
  instanceId,
  icon,
  width,
  menuHeight = 200,
  isSearchable = true,
  showDropdownIndicator = true,
  customStyles,
  customComponents = {},
  isDisabled = false,
  color1 = '#1D253F', // Default color1
  color2 = '#7F7F7F', // Default color2
  color3 = '#000000', // Default color3
  color4 = '#FFFFFF', // Default color4
  ...props
}) => {
  const defaultStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0',
      border: 'none',
      boxShadow: 'none',
      width: width ? `${width}px` : '100%',
      fontSize: '16px', // Tailwind's text-base equivalent
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      minHeight: 'auto', // Remove any minimum height
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0', // Remove padding to fit text height
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: color1, // Tailwind's text-gray-800
      fontWeight: '400', // Tailwind's font-semibold
      fontSize: '14px', // Tailwind's text-base equivalent
      //textDecoration: 'underline', // Underline the text
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      cursor: 'pointer', // Change cursor to pointer
      ':hover': {
        color: '#7f2111', // Tailwind's text-orange-900
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: color1, // Tailwind's text-gray-800
      fontWeight: '400', // Tailwind's font-semibold
      fontSize: '14px', // Tailwind's text-base equivalent
      textDecoration: 'underline', // Underline the text
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px', // Tailwind's text-base equivalent
      color: state.isSelected ? color4 : color1, // Tailwind's text-gray-800
      fontWeight: '400', // Tailwind's font-semibold
      backgroundColor: state.isSelected ? color1 : color4,
      ':hover': {
        backgroundColor: color1,
        color: color4,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
      width: width ? `${width}px` : '100%',
      height: `${menuHeight}px`,
    }),
  };

  const styles = customStyles ? customStyles : defaultStyles;
  const mergedComponents = {
    ValueContainer: CustomValueContainer,
    IndicatorSeparator: () => null, // Remove the pipe
    DropdownIndicator: showDropdownIndicator ? components.DropdownIndicator : null,
    ...customComponents,
  };

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      isSearchable={isSearchable}
      maxMenuHeight={menuHeight}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      value={value}
      defaultValue={defaultValue}
      styles={styles}
      instanceId={instanceId}
      components={mergedComponents}
      isDisabled={isDisabled}
      icon={icon}
      {...props}
    />
  );
};

export default CustomSelectNoBorderTransfers;
