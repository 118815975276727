import '../css/autocomplete-theme-classic/customAutoComplete.css';

import React, { Fragment, createElement, useEffect, useRef } from 'react';

import { autocomplete } from '@algolia/autocomplete-js';
import { createRoot } from 'react-dom/client';

export function CustomAutoComplete(props) {
  const containerRef = useRef(null);
  const panelRootRef = useRef(null);
  const rootRef = useRef(null);

  const { placeholder, detachedMediaQuery, initialState, openOnFocus, onReset, getSources } = props;

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: {
        createElement,
        Fragment,
        render: () => {},
      },
      render({ children }, root) {
        if (!panelRootRef.current) {
          rootRef.current = root;
          panelRootRef.current = createRoot(root);
        }
        if (rootRef.current !== root) {
          rootRef.current = root;
        }
        panelRootRef.current.render(children);
      },
      placeholder,
      detachedMediaQuery,
      initialState,
      openOnFocus,
      onReset,
      getSources,
      classNames: {
        form: 'custom-form',
        input: 'custom-input',
        hint: 'custom-hint',
        dropdown: 'custom-dropdown',
        suggestions: 'custom-suggestions',
        suggestion: 'custom-suggestion',
        cursor: 'custom-cursor',
      },
    });

    return () => {
      search.destroy();
    };
  }, [placeholder, detachedMediaQuery, initialState, openOnFocus, onReset, getSources]);

  return <div ref={containerRef} />;
}
