import 'react-datepicker/dist/react-datepicker.css';
import 'flatpickr/dist/flatpickr.css';
import '../css/datePickerStyles.css';

import { Configure, Hits, InstantSearch, Pagination, connectHits, connectSearchBox } from 'react-instantsearch-dom';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import {
  arrivalDepartureTimeOptions,
  filterTransferRouteRatesSingle,
  findMatchingFlightRoutes,
  findTransfer,
  findTransferRatesAll,
  getAeroCrsCode,
  getAirportCodeFromUid,
  getAirportCodes,
  getAirportDetails,
  getAirportName,
  getAirportProperties,
  getLocationDetails,
  hideOwnArrangements,
  processVip,
} from '../functions/transferFunctions';
import { calculateFlightCost, calculateFlightPricing, getAvailability, getDeepLink, processFlights } from '../functions/aeroFunctions';
import { capitalizeFirstLetter, findAirportByUid, formatPrice, getPropObj, nomadVehicleRates, nonNomadVehicleRates } from '../functions/generalFunctions';
import { closeForm, handleFormSubmitAirport, handleFormSubmitLocation, handleFormSubmitOwnArrangementsSingle, handleOptionClick } from '../functions/transferFormsFunctions';
import {
  loadConnectingAirports,
  loadFlightLocations,
  loadFlightRoutes,
  loadTransferRoute,
  loadTransferRouteUid,
  loadTransferRoutes,
  loadTransferRoutesOwnArrangements,
} from '../functions/loadDataFunctions';

import CustomModal from '../components/CustomModal';
import CustomSelect from '../components/CustomSelect';
import CustomSelectNoBorderTransfers from '../components/CustomSelectNoBorderTransfers';
import { FALSE } from 'sass';
import { FaLocationDot } from 'react-icons/fa6';
import { FaLongArrowAltRight } from 'react-icons/fa';
import FlightResultsComponent from '../components/FlightResultsComponent';
import { IoLocationOutline } from 'react-icons/io5';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { LiaPlaneSolid } from 'react-icons/lia';
import { LocationItem } from '../components/LocationItem';
import { LuChevronDown } from 'react-icons/lu';
import { LuGitBranch } from 'react-icons/lu';
import PackedLunch from './PackedLunch';
import SearchBox from '../components/SearchBox';
import { TbTriangle } from 'react-icons/tb';
import TransferAddOnCard from './TransferAddOnCard';
import TransferArrivalDeparture from './TransferArrivalDeparture';
import TransferArrivalDepartureVip from './TransferArrivalDepartureVip';
import TransferInfoCard from './TransferInfoCard';
import TransferInfoFlightCard from './TransferInfoFlightCard';
import TransferRouteComponent from '../components/TransferRouteComponent';
//import { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import algoliasearch from 'algoliasearch';
import { first } from 'lodash';
import { getTimeDifference } from '../functions/generalFunctions';
import moment from 'moment';
import qs from 'qs';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;

const searchClient = algoliasearch(appId, apiKey);

function TransferSlideOutContent({
  isOpen,
  onClose,
  transfer,
  deeShadow,
  SlPlane,
  timeRef,
  MdOutlineLocationOn,
  FaCampground,
  CgUnavailable,
  BiTransfer,
  setSelectedTransferTuuid,
  airports,
  transfers,
  properties,
  freshUuid,
  locations,
  customLocations,
  setTransfers,
  setIsModalOpen,
  setRefreshTransferRates,
  bookings,
  setBookings,
  booking,
  agentObject,
  suppliers,
  airlines,
  createEdit,
  createVersion,
  transfersDataFlights,
  user,
  advancedMode,
}) {
  console.log('CREATEEDIT  1', createEdit);

  advancedMode = true;

  // useEffect(() => {
  //   if (isOpen && !transfer) {
  //     // If the slide-out is open but the required data is not available, close the slide-out
  //     onClose();
  //   }
  // }, [isOpen, transfer, onClose]);

  useEffect(() => {
    if (isOpen && !transfer) {
      // If the slide-out is open but the required data is not available, close the slide-out
      onClose();
    }
  }, [isOpen, transfer, onClose]);

  // if (!transfer) {
  //   // If the required data is not available, don't render the slide-out
  //   return null;
  // }
  // ORIGINAL START
  // #region
  const [flightToShow, setFlightToShow] = useState(null);
  const [matchingAirports, setMatchingAirports] = useState(null);
  const [locationToShow, setLocationToShow] = useState(null);

  // Handle border and showing forms:
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);

  // OWN ARRANGEMENTS
  const [sortedLocations, setSortedLocations] = useState([]);
  const [locationName, setLocationName] = useState(null);
  const [locationUid, setLocationUid] = useState(null);
  const [supplierName, setSupplierName] = useState(null);
  const [supplierUid, setSupplierUid] = useState(null);
  const [airport, setAirport] = useState(false);
  const [airportName, setAirportName] = useState(null);
  const [airportUid, setAirportUid] = useState(null);
  const [airlineNameOwnArrangements, setAirlineNameOwnArrangements] = useState(null);
  const [airlineUidOwnArrangements, setAirlineUidOwnArrangements] = useState(null);
  const [fltNum, setFltNum] = useState('');
  const [timeOwnArrangements, setTimeOwnArrangements] = useState('Select time');
  const [dateOwnArrangements, setDateOwnArrangements] = useState(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);
  const [timeFlightOwnArrangements, setTimeFlightOwnArrangements] = useState('Select time');
  const [dateFlightOwnArrangements, setDateFlightOwnArrangements] = useState(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);
  const [flash, setFlash] = useState(false);
  const [noTransfers, setNoTransfers] = useState(false);

  const [addFlightDetails, setAddFlightDetails] = useState(false);
  const [addFlightDetailsLater, setAddFlightDetailsLater] = useState(false);
  const [detailsNow, setDetailsNow] = useState(false);
  const [includeDepartureTransfer, setIncludeDepartureTransfer] = useState(true);
  const [includeArrivalTransfer, setIncludeArrivalTransfer] = useState(true);
  const [includeArrivalTransferVip, setIncludeArrivalTransferVip] = useState(false);
  const [includeDepartureTransferVip, setIncludeDepartureTransferVip] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [finalFlightUid, setFinalFlightUid] = useState(null);
  const [finalOriginFlightUid, setFinalOriginFlightUid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [flightResults, setFlightResults] = useState(false);
  const [flightResultsSecond, setFlightResultsSecond] = useState(false);

  const [currentPages, setCurrentPages] = useState([]);
  const [buttonsToShow, setButtonsToShow] = useState(100); // User 7
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [selectedFlightIndex, setSelectedFlightIndex] = useState(null);

  const [localSelectedIndex, setLocalSelectedIndex] = useState(null);
  const [localSelectedIndexSecond, setLocalSelectedIndexSecond] = useState(null);

  const [showFlightDetails, setShowFlightDetails] = useState(false);
  const [localSelectedIndices, setLocalSelectedIndices] = useState([]);

  const [flightPage, setFlightPage] = useState(1);

  const [loadInfants, setLoadInfants] = useState(0);

  const [flightInfants, setFlightInfants] = useState(loadInfants);

  const [searchAgain, setSearchAgain] = useState(false);
  const [reCheckPax, setReCheckPax] = useState(false);

  const [flightCost, setFlightCost] = useState(0);
  const [flightCostSecond, setFlightCostSecond] = useState(0);

  const [connectionType, setConnectionType] = useState(null);
  const [connectionClick, setConnectionClick] = useState(null);

  const [airlineName, setAirlineName] = useState('');
  const [airlineNameSecond, setAirlineNameSecond] = useState('');

  //const [dateAdjusted, setDateAdjusted] = useState(transfer.formValues && transfer.formValues.dateAdjusted ? transfer.formValues.dateAdjusted : false);
  const [dateAdjusted, setDateAdjusted] = useState(transfer.formValues && transfer.formValues.dateAdjusted === 'true' ? true : false);

  //filters for parks

  const [parkFilters, setParkFilters] = useState([]);
  const [airportFilters, setAirportFilters] = useState([]);

  const [searchStateLocation, setSearchStateLocation] = useState(null);
  const [searchStateAirport, setSearchStateAirport] = useState(null);
  const timerRef = useRef(null);

  const [numHits, setNumHits] = useState(1000);
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchFiltersOwnArrangements, setSearchFiltersOwnArrangements] = useState([]);

  const [airportSearchFilters, setAirportSearchFilters] = useState([]);

  const [selectedFlightAlgolia, setSelectedFlightAlgolia] = useState('Search by airport');
  const [selectedFlightAlgoliaName, setSelectedFlightAlgoliaName] = useState('Search by airport');

  const [selectedLocationAlgolia, setSelectedLocationAlgolia] = useState('');
  const [selectedLocationAlgoliaName, setSelectedLocationAlgoliaName] = useState('Search by airport, property or other');
  const [selectedLocationAlgoliaUid, setSelectedLocationAlgoliaUid] = useState('');
  const [selectedParkAlgoliaUid, setSelectedParkAlgoliaUid] = useState('');
  const [selectedLocationAlgoliaType, setSelectedLocationAlgoliaType] = useState('');

  const [locationTime, setLocationTime] = useState('Select time');
  const [addLater, setAddLater] = useState(false);

  const [addLaterOwnArrangements, setAddLaterOwnArrangements] = useState(false);

  const checkCustomLocations = customLocations.find((location) => location.propUid === transfer.propUid && location.uuid === transfer.uuid);

  const [parkId, setParkId] = useState(
    properties[getPropObj(transfer.propUid, properties)].mobileLocation ? checkCustomLocations.parkId : properties[getPropObj(transfer.propUid, properties)].park.id,
  );
  const [locationId, setLocationId] = useState(
    properties[getPropObj(transfer.propUid, properties)].mobileLocation ? checkCustomLocations.parkId : properties[getPropObj(transfer.propUid, properties)].park.id,
  );
  // const [airportId, setAirportId] = useState(
  //   properties[getPropObj(transfer.propUid, properties)].mobileLocation ? checkCustomLocations.airportId : properties[getPropObj(transfer.propUid, properties)].airport.id,
  // );

  const [airportId, setAirportId] = useState(null);

  // useEffect to ensure fresh airportId is set
  useEffect(() => {
    if (transfer && transfer.propUid) {
      const propObj = getPropObj(transfer.propUid, properties);
      const property = properties[propObj];

      if (property) {
        const checkCustomLocations = customLocations.find((location) => location.propUid === transfer.propUid && location.uuid === transfer.uuid);

        const newAirportId = property.mobileLocation ? checkCustomLocations?.airportId : property.airport?.id;

        setAirportId(newAirportId);
      }
    }
  }, [transfers, transfer, properties, customLocations]);

  const [modalOpen, setModalOpen] = useState(true);
  const [formattedMatchingAirports, setFormattedMatchingAirports] = useState([]);
  const [nearestAirport, setNearestAirport] = useState([]);

  const [flightPricing, setFlightPricing] = useState([]);
  const [flightPricingSecond, setFlightPricingSecond] = useState([]);

  const [packedLunch, setPackedLunch] = useState(true);
  const [packedLunchChargeable, setPackedLunchChargeable] = useState(true);

  const [flightLocations, setFlightLocations] = useState([]);

  const [locationRoute, setLocationRoute] = useState(null);

  const [accessLevel, setAccessLevel] = useState('limited');
  const [accessLevelHideOwnArrangements, setAccessLevelHideOwnArrangements] = useState('xxx');

  useEffect(() => {
    const fetchAirportDetails = async () => {
      const fetchedAirportDetails = await getAirportDetails(airports, properties, transfer, customLocations);
      setNearestAirport(fetchedAirportDetails);
      console.log('fetchedAirportDetails');
      console.log(JSON.stringify(fetchedAirportDetails, undefined, 4));
    };

    fetchAirportDetails();
  }, []); // the empty array [] makes it run only once on component mount

  useEffect(() => {
    const formatAndSortMatchingAirports = (matchedAirports) => {
      if (!matchedAirports) return [];

      const tempAirports = airports.filter((airport) => matchedAirports.some((matchedAirport) => matchedAirport.destination.id === airport.uid));

      return tempAirports
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((airport) => ({
          value: airport.uid,
          label: airport.name,
        }));
    };

    if (matchingAirports) {
      const formatted = formatAndSortMatchingAirports(matchingAirports);
      setFormattedMatchingAirports(formatted);
    }
  }, [airports, formattedMatchingAirports, matchingAirports]);

  useEffect(() => {
    console.log('Locations:');
    console.log(JSON.stringify(locations, null, 2));
    const sorted = locations.sort((a, b) => a.name.localeCompare(b.name));
    setSortedLocations(sorted.map((loc) => ({ value: loc.uid, label: `${loc.name}` })));
  }, [locations]);

  useEffect(() => {
    if (!transfer.formValues || !transfer.edit) {
      setSelectedLocation(sortedLocations.find((location) => location.value === selectedOption));
    }
  }, [selectedOption, setSelectedLocation, sortedLocations, transfer.formValues, transfer.edit]);

  const [resetTimeButton, setResetTimeButton] = useState(false);
  // const [loadAdults, setLoadAdults] = useState(0);
  // const [loadChildren, setLoadChildren] = useState(0);

  const [loadAdults, setLoadAdults] = useState(
    transfer && transfer.totalAd
      ? transfer.totalAd
      : booking.rooms.reduce((total, room) => {
          return room.roomType !== 'Vehicle' ? total + room.ad : total;
        }, 0),
  );

  const [loadChildren, setLoadChildren] = useState(
    transfer && transfer.totalCh
      ? transfer.totalCh
      : booking.rooms.reduce((total, room) => {
          return room.roomType !== 'Vehicle' ? total + room.ch + room.chi : total;
        }, 0),
  );

  const [flightAdults, setFlightAdults] = useState(loadAdults);
  const [flightChildren, setFlightChildren] = useState(loadChildren);
  const [selectedAges, setSelectedAges] = useState(transfer.selectedAges);
  const [selectedAgesAdults, setSelectedAgesAdults] = useState(transfer.selectedAgesAdults);

  const [showButtons, setShowButtons] = useState(transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' ? true : false);
  const [showButtonsSecond, setShowButtonsSecond] = useState(transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' ? true : false);

  useEffect(() => {
    if (transfer.formValues && transfer.formValues.flightDetails && flightResults) {
      let foundFlightIndex = -1;
      let foundOuterIndex = -1;

      for (let outerIndex = 0; outerIndex < flightResults.length; outerIndex++) {
        const innerFlights = flightResults[outerIndex].flight;

        const flightIndex = innerFlights.findIndex((flight) => flight.STD === transfer.formValues.flightDetails.STD && flight.STA === transfer.formValues.flightDetails.STA);

        if (flightIndex >= 0) {
          foundFlightIndex = flightIndex;
          foundOuterIndex = outerIndex;
          break;
        }
      }

      if (foundFlightIndex >= 0 && foundOuterIndex >= 0) {
        const localIndex = foundFlightIndex % buttonsToShow;
        const pageForThisFlight = Math.floor(foundFlightIndex / buttonsToShow);

        setLocalSelectedIndex(!resetTimeButton ? localIndex : []);
        setCurrentPages((prevPages) => {
          const newPages = [...prevPages];
          newPages[foundOuterIndex] = pageForThisFlight;
          return newPages;
        });
      }
    }
  }, [buttonsToShow, flightResults, transfer.formValues, resetTimeButton]);

  const matchingBooking = bookings.find((booking) => booking.id === transfer.uuid);
  console.log('matchingBooking: ', JSON.stringify(matchingBooking, null, 2));
  const isSafariTransferWithMatchingBooking = () =>
    nonNomadVehicleRates(matchingBooking.rateName) || (properties[getPropObj(transfer.propUid, properties)].supplier.name === 'Nomad Tanzania' && nomadVehicleRates(matchingBooking.rateName));

  //const isTownTransfer = () => transfer.routeTransfer.transferType === 'townTransfer';

  const isTownTransfer = () => true;

  // New transfer route

  const [transferRouteRates, setTransferRouteRates] = useState(null);
  const [selectedRouteIndex, setSelectedRouteIndex] = useState(null);
  const [showButtonsRoute, setShowButtonsRoute] = useState(false);

  const [stoPriceCost, setStoPriceCost] = useState(0);
  const [netRackCost, setNetRackCost] = useState(0);
  const [saleTotal, setSaleTotal] = useState(0);
  const [rateId, setRateId] = useState(null);
  const [rateType, setRateType] = useState(null);
  const [totalUnits, setTotalUnits] = useState(0);
  const [supplierId, setSupplierId] = useState(null);
  const [maxCapacity, setMaxCapacity] = useState(0);
  const [transferUnits, setTransferUnits] = useState(null);
  const [transferRouteUid, setTransferRouteUid] = useState(null);
  const [transferType, setTransferType] = useState(null);
  const [distanceMins, setDistanceMins] = useState(null);
  const [gameDriveTime, setGameDriveTime] = useState(null);
  const [transferRouteName, setTransferRouteName] = useState(null);

  // Vip object
  const [airportCode, setAirportCode] = useState(null);

  let vipObject = null;

  // function filterTransferRouteRatesSingle(transferRouteRates, properties, matchingBooking) {
  //   // Get supplier IDs from properties based on matching bookings
  //   const supplierId1 = properties[getPropObj(matchingBooking.propUid, properties)].supplier.id;
  //   //const supplierId2 = properties[getPropObj(matchingBookingNext.propUid, properties)].supplier.id;

  //   // Use a Set to keep track of added supplier IDs
  //   const addedSupplierIds = new Set();
  //   const filteredRates = [];

  //   for (let rate of transferRouteRates) {
  //     if ((rate.supplier.id === supplierId1 && !addedSupplierIds.has(rate.supplier.id) && rate.active) || (rate.availableAll && rate.active)) {
  //       filteredRates.push(rate);
  //       addedSupplierIds.add(rate.supplier.id);
  //     }
  //   }

  //   return filteredRates;
  // }

  // function filterTransferRouteRatesSingle(transferRouteRates, properties, matchingBooking, selectedLocation) {
  //   // Get supplier IDs from properties based on matching bookings
  //   const supplierId1 = properties[getPropObj(matchingBooking.propUid, properties)].supplier.id;
  //   //const supplierId2 = properties[getPropObj(matchingBookingNext.propUid, properties)].supplier.id;
  //   const selectedRate1 = matchingBooking.rateName;

  //   // Check if either selected rate is "fullBoardNomad"
  //   const isFullBoardNomad = selectedRate1 === 'fullBoardNomad';
  //   const nomadSupplierId = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
  //   const isNomadGamePackage = selectedRate1 === 'gamePackage' && supplierId1 === nomadSupplierId;
  //   // Use a Set to keep track of added supplier IDs
  //   const isSelectedLocationAirport = selectedLocation && selectedLocation.type === 'airport';

  //   const addedSupplierIds = new Set();
  //   const filteredRates = [];

  //   for (let rate of transferRouteRates) {
  //     if (rate.active) {
  //       // Need to add in that property matches and airport matches.
  //       const isAirportTransfer = rate.type === 'propertyAirportTransfer';

  //       if (isFullBoardNomad || isNomadGamePackage) {
  //         // If fullBoardNomad, include "Safari Vehicle" rates and rates meeting other conditions
  //         if (rate.type === 'nomadGuideVehicle' || (rate.supplier.id === supplierId1 && !isAirportTransfer) || rate.availableAll || (isAirportTransfer && isSelectedLocationAirport)) {
  //           filteredRates.push(rate);
  //           addedSupplierIds.add(rate.supplier.id);
  //         }
  //       } else {
  //         // For non-fullBoardNomad, exclude "Safari Vehicle" rates and handle propertyAirportTransfer separately
  //         if (
  //           (rate.type !== 'nomadGuideVehicle' && rate.type !== 'propertyAirportTransfer' && (rate.supplier.id === supplierId1 || rate.availableAll)) ||
  //           (isAirportTransfer && isSelectedLocationAirport)
  //         ) {
  //           filteredRates.push(rate);
  //           addedSupplierIds.add(rate.supplier.id);
  //         }
  //       }
  //     }
  //   }

  //   return filteredRates;
  // }

  useEffect(() => {
    console.log('useEffect triggered');
    async function fetchData() {
      const parkFiltersLoad = await loadTransferRoutes(parkId, transfer.objType, isSafariTransferWithMatchingBooking());
      console.log('Specific onClick triggered! ', transfer.propUid);
      console.log('park.id: ', parkId);
      console.log('testLoad: ', parkFiltersLoad);
      console.log(JSON.stringify(parkFiltersLoad, undefined, 4));
      setParkFilters(parkFiltersLoad);

      // ! Set a town transfer filter

      // function
      // setParkFiltersTown(xxx);

      const parkFilters = parkFiltersLoad.map((id) => `park:${id}`).join(' OR ');

      console.log('parkFilters: ', parkFilters);
      console.log(JSON.stringify(parkFilters, undefined, 4));

      setSearchFilters(parkFilters);

      setSelectedParkAlgoliaUid(
        transfer.formValues && transfer.edit && (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp')
          ? transfer.objType === 'arrival'
            ? transfer.formValues.originUid
            : transfer.formValues.destinationUid
          : '',
      );

      setSelectedLocationAlgoliaName(
        transfer.formValues &&
          transfer.edit &&
          transfer.formValues.type &&
          (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp' || transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
          ? transfer.formValues.locationName === ''
            ? 'Search by airport, property or other'
            : transfer.formValues.locationName
          : 'Search by airport, property or other',
      );
      setSelectedLocationAlgoliaUid(
        transfer.formValues &&
          transfer.edit &&
          (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp' || transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') &&
          transfer.formValues.locationUid,
      );

      setSelectedLocationAlgoliaType(
        transfer.formValues &&
          transfer.edit &&
          (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp' || transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
          ? transfer.formValues.locationType
          : '',
      );

      if (
        transfer.formValues &&
        transfer.edit &&
        (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp' || transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') &&
        transfer.formValues.airport === true
      ) {
        setSelectedLocationAlgoliaType('airport');
      }

      if (transfer.formValues && transfer.edit && (transfer.formValues.type === 'camp' || transfer.formValues.type === 'location') && transfer.formValues.addFlightDetailsLater) {
        setAddFlightDetailsLater(true);
      }

      if (transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' && transfer.formValues.fltNumFlight) {
        setFltNum(transfer.formValues.fltNumFlight);
      }

      if (transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' && transfer.formValues.addFlightDetailsLater) {
        setAddFlightDetailsLater(true);
      }
      // if (transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' && transfer.formValues.detailsNow) {
      //   setDetailsNow(true);
      // } else {
      //   setConnectionType('');
      // }

      if (transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight') {
        if (transfer.formValues.detailsNow === true) {
          setDetailsNow(true);
        } else if (transfer.formValues.detailsNow === false) {
          setDetailsNow(false);
          setConnectionType('');
        } else {
          setDetailsNow(true);
        }
      }

      if (transfer.formValues && transfer.edit && transfer.formValues.type === 'location' && transfer.formValues.addFlightDetailsLater) {
        setAddFlightDetailsLater(true);
      }

      if (transfer.formValues && transfer.edit && transfer.formValues.type === 'location') {
        if (transfer.formValues.detailsNow === true) {
          setDetailsNow(true);
        } else if (transfer.formValues.detailsNow === false) {
          setDetailsNow(false);
        } else {
          setDetailsNow(true);
        }
      }

      // if (transfer.formValues && transfer.edit && transfer.formValues.type === 'camp' && transfer.formValues.detailsNow) {
      //   setDetailsNow(true);
      // }

      if (transfer.formValues && transfer.edit && transfer.formValues.type === 'camp') {
        if (transfer.formValues.detailsNow === true) {
          setDetailsNow(true);
        } else if (transfer.formValues.detailsNow === false) {
          setDetailsNow(false);
        } else {
          setDetailsNow(true);
        }
      }

      if (transfer.formValues && transfer.edit && transfer.formValues.type === 'camp' && transfer.formValues.addLaterOwnArrangements === true) {
        setAddLaterOwnArrangements(true);
        console.log('setAddLaterOwnArrangements true');
      } else {
        setAddLaterOwnArrangements(false);
      }

      setLocationTime(transfer.formValues && transfer.edit && transfer.formValues.time !== 'Select time' ? transfer.formValues.time : 'Select time');
    }

    // if (transfer.formValues && (transfer.formValues.type === 'location' || transfer.formValues.type === 'camp' || transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')) {
    //   fetchData();
    //   console.log('setAddLaterOwnArrangements fetchData');
    // } else {
    //   setAddLaterOwnArrangements(false);
    //   console.log('setAddLaterOwnArrangements false');
    // }

    if (transfer.formValues && ['location', 'camp', 'flight', 'bookFlight'].includes(transfer.formValues.type)) {
      console.log('Condition met, calling fetchData');
      fetchData();
      console.log('setAddLaterOwnArrangements fetchData');
    } else {
      console.log('Condition not met, setting addLaterOwnArrangements to false');
      setAddLaterOwnArrangements(false);
    }

    setPackedLunch(!transfer.formValues ? true : transfer.formValues.packedLunch ? transfer.formValues.packedLunch : false);
    setPackedLunchChargeable(!transfer.formValues ? true : transfer.formValues.packedLunchChargeable ? transfer.formValues.packedLunchChargeable : false);
    console.log('transfer.formValues check: ', transfer.formValues);
    console.log('packedLunchChargeable check: ', packedLunchChargeable);
  }, [transfer]);

  // New code for setIncludeArrivalTransfer && setIncludeDepartureTransfer
  useEffect(() => {
    if (isOpen) {
      if (transfer.objType === 'arrival' && transfer.formValues && transfer.formValues.type === 'bookFlight' && transfer.formValues.includeArrivalTransfer) {
        setIncludeArrivalTransfer(true);
      } else if (transfer.objType === 'arrival' && transfer.formValues && transfer.formValues.type === 'bookFlight' && !transfer.formValues.includeArrivalTransfer) {
        setIncludeArrivalTransfer(false);
      } else {
        setIncludeArrivalTransfer(true);
      }

      if (transfer.objType === 'departure' && transfer.formValues && transfer.formValues.type === 'bookFlight' && transfer.formValues.includeDepartureTransfer) {
        setIncludeDepartureTransfer(true);
      } else if (transfer.objType === 'departure' && transfer.formValues && transfer.formValues.type === 'bookFlight' && !transfer.formValues.includeDepartureTransfer) {
        setIncludeDepartureTransfer(false);
      } else {
        setIncludeDepartureTransfer(true);
      }

      // VIP checkboxes
      if (transfer.objType === 'arrival' && transfer.formValues && transfer.formValues.includeArrivalVip) {
        setIncludeArrivalTransferVip(true);
      } else if (transfer.objType === 'arrival' && transfer.formValues && !transfer.formValues.includeArrivalVip) {
        setIncludeArrivalTransferVip(false);
      } else {
        setIncludeArrivalTransferVip(false);
      }

      if (transfer.objType === 'departure' && transfer.formValues && transfer.formValues.includeDepartureVip) {
        setIncludeDepartureTransferVip(true);
      } else if (transfer.objType === 'departure' && transfer.formValues && !transfer.formValues.includeDepartureVip) {
        setIncludeDepartureTransferVip(false);
      } else {
        setIncludeDepartureTransferVip(false);
      }

      if (transfer.formValues && transfer.formValues.airportCodeVip) {
        setAirportCode(transfer.formValues.airportCodeVip);
      }
    }
  }, [isOpen, transfer]);

  useEffect(() => {
    console.log(flightPricing);
  }, [flightPricing]);

  // SET CAMP DETAILS
  useEffect(() => {
    if (transfer.formValues && transfer.edit && transfer.formValues.type === 'camp') {
      setLocationName(
        transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.locationName
          ? transfer.formValues.locationName === ''
            ? 'Search by airport, property or other'
            : null
          : null,
      );
      setLocationUid(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.locationUid ? transfer.formValues.locationUid : null);
      if (transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.locationUid === '') {
        setAirport(false);
      } else {
        if (locations.find((location) => location.uid === transfer.formValues.locationUid).type === 'airport') {
          setAirport(true);
        } else {
          setAirport(false);
        }
      }

      setSupplierName(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.supplierName ? transfer.formValues.supplierName : null);
      setSupplierUid(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.supplierUid ? transfer.formValues.supplierUid : null);

      setDateOwnArrangements(
        transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.dateOwnArrangements
          ? transfer.formValues.dateOwnArrangements
          : transfer.objType === 'arrival'
          ? transfer.startDay
          : transfer.endDay,
      );

      const defaultTime = 'Select time';

      setTimeOwnArrangements(
        transfer.formValues &&
          transfer.formValues.type === 'camp' &&
          transfer.formValues.timeOwnArrangements &&
          arrivalDepartureTimeOptions.some((option) => option.value === transfer.formValues.timeOwnArrangements)
          ? transfer.formValues.timeOwnArrangements
          : defaultTime,
      );

      setAirlineUidOwnArrangements(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.airlineUidOwnArrangements ? transfer.formValues.airlineUidOwnArrangements : null);
      setAirlineNameOwnArrangements(
        transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.airlineNameOwnArrangements ? transfer.formValues.airlineNameOwnArrangements : null,
      );

      setFltNum(transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.fltNum ? transfer.formValues.fltNum : null);

      setDateFlightOwnArrangements(
        transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.dateFlightOwnArrangements
          ? transfer.formValues.dateFlightOwnArrangements
          : transfer.objType === 'arrival'
          ? transfer.startDay
          : transfer.endDay,
      );

      setTimeFlightOwnArrangements(
        transfer.formValues && transfer.formValues.type === 'camp' && transfer.formValues.timeFlightOwnArrangements ? transfer.formValues.timeFlightOwnArrangements : 'Select time',
      );
    }
  }, []);

  // SET LOCATION DETAILS
  useEffect(() => {
    if (transfer.formValues && transfer.edit && transfer.formValues.type === 'location') {
      setAirlineUidOwnArrangements(transfer.formValues && transfer.formValues.airlineUid ? transfer.formValues.airlineUid : null);
      setAirlineNameOwnArrangements(transfer.formValues && transfer.formValues.airlineName ? transfer.formValues.airlineName : null);

      setFltNum(transfer.formValues && transfer.formValues.fltNum ? transfer.formValues.fltNum : null);

      setDateFlightOwnArrangements(transfer.formValues && transfer.formValues.dateFlight ? transfer.formValues.dateFlight : transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);

      setTimeFlightOwnArrangements(transfer.formValues && transfer.formValues.timeFlight ? transfer.formValues.timeFlight : 'Select time');

      setAddLater(transfer.formValues && transfer.edit && transfer.formValues.type === 'location' && transfer.formValues.addLater && transfer.formValues.addLater);

      setLocationRoute(
        transfer.formValues && transfer.edit && transfer.formValues.type === 'location' && transfer.formValues.supplierName
          ? {
              suppliers: [
                {
                  id: transfer.formValues.supplierUid,
                  name: transfer.formValues.supplierName,
                },
              ],
            }
          : null,
      );
    }
  }, []);

  // NEW ROUTE
  // NEW ROUTE
  useEffect(() => {
    const fetchLocationRoute = async () => {
      if (transfer.formValues && transfer.formValues.type === 'location' && (transfer.formValues.transferRouteUid || transfer.formValues.transferRoute)) {
        // New transfer route
        const getLocationRoute = await loadTransferRouteUid(transfer.formValues.transferRouteUid ? transfer.formValues.transferRouteUid : transfer.formValues.transferRoute);
        setLocationRoute(getLocationRoute);
        setTransferRouteRates(null);
        setSelectedRouteIndex(null);
        setShowButtonsRoute(true);
        setTransferRouteUid(null);
        setTransferType(null);
        setDistanceMins(null);
        setGameDriveTime(null);
        setTransferRouteName(null);

        setRateId(transfer.formValues.rateId ? transfer.formValues.rateId : null);
        setRateType(transfer.formValues.rateType ? transfer.formValues.rateType : null);
        setSupplierName(transfer.formValues.supplierName ? transfer.formValues.supplierName : null);
        setSupplierId(transfer.formValues.supplierUid ? transfer.formValues.supplierUid : null);
        setStoPriceCost(transfer.formValues.stoPriceCost ? transfer.formValues.stoPriceCost : null);
        setNetRackCost(transfer.formValues.netCost ? transfer.formValues.netCost : null);
        setSaleTotal(transfer.formValues.saleTotal ? transfer.formValues.saleTotal : null);
        setTotalUnits(transfer.formValues.units ? transfer.formValues.units : null);
        setMaxCapacity(transfer.formValues.maxCapacity ? transfer.formValues.maxCapacity : null);

        const rates = getLocationRoute.rates;
        const getRateObjectAll = findTransferRatesAll(rates, transfer.endDay);

        // Create a selectedLocation object based on the form values
        const selectedLocation = {
          type: transfer.formValues.locationType,
          // Add airport uid here when needed HP
        };

        // console.log('filteredRates', JSON.stringify(filterTransferRouteRatesSingle(getRateObjectAll, properties, matchingBooking, selectedLocation), null, 2));

        // const filteredRates = filterTransferRouteRatesSingle(getRateObjectAll, properties, matchingBooking, selectedLocation);
        // console.log('filteredRates.id', filteredRates[0].id);
        // console.log('filteredRates.type', filteredRates[0].type);
        // setRateId(transfer.formValues.rateId ? transfer.formValues.rateId : filteredRates[0].id ? filteredRates[0].id : null);
        // setRateType(transfer.formValues.rateType ? transfer.formValues.rateType : filteredRates[0].type ? filteredRates[0].type : null);

        setTransferRouteRates(filterTransferRouteRatesSingle(getRateObjectAll, properties, matchingBooking, selectedLocation));
        setTransferRouteUid(getLocationRoute.uid);
        setTransferType(getLocationRoute.type);
        setDistanceMins(getLocationRoute.distanceMins);
        setGameDriveTime(getLocationRoute.gameDriveTime);
        setTransferRouteName(getLocationRoute.route);
        console.log('useEffect fetchLocationRoute 1');
      } else {
        console.log('useEffect fetchLocationRoute 2');
      }
    };

    if (transfer.edit) {
      fetchLocationRoute();
    }
  }, [matchingBooking, properties, transfer.edit, transfer.endDay, transfer.formValues]);
  // useEffect(() => {
  //   const fetchLocationRoute = async () => {
  //     if (transfer.formValues && transfer.formValues.type === 'location' && transfer.formValues.transferRouteUid) {
  //       // New transfer route
  //       const getLocationRoute = await loadTransferRouteUid(transfer.formValues.transferRouteUid);
  //       setLocationRoute(getLocationRoute);
  //       setTransferRouteRates(null);
  //       setSelectedRouteIndex(null);
  //       setShowButtonsRoute(true);
  //       setTransferRouteUid(null);
  //       setTransferType(null);
  //       setDistanceMins(null);
  //       setGameDriveTime(null);
  //       setTransferRouteName(null);

  //       const rates = getLocationRoute.rates;
  //       const getRateObjectAll = findTransferRatesAll(rates, transfer.endDay);
  //       setTransferRouteRates(filterTransferRouteRatesSingle(getRateObjectAll, properties, matchingBooking));
  //       setTransferRouteUid(getLocationRoute.uid);
  //       setTransferType(getLocationRoute.type);
  //       setDistanceMins(getLocationRoute.distanceMins);
  //       setGameDriveTime(getLocationRoute.gameDriveTime);
  //       setTransferRouteName(getLocationRoute.route);
  //       console.log('useEffect fetchLocationRoute 1');
  //     } else {
  //       console.log('useEffect fetchLocationRoute 2');
  //     }
  //   };

  //   if (transfer.edit) {
  //     fetchLocationRoute();
  //   }
  // }, [matchingBooking, properties, transfer.edit, transfer.endDay, transfer.formValues]);

  useEffect(() => {
    if (transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight') {
      setAirlineUidOwnArrangements(transfer.formValues && transfer.formValues.airlineUid ? transfer.formValues.airlineUid : null);
      setAirlineNameOwnArrangements(transfer.formValues && transfer.formValues.airlineNameFlight ? transfer.formValues.airlineNameFlight : null);

      setFltNum(transfer.formValues && transfer.formValues.fltNumFlight ? transfer.formValues.fltNumFlight : null);

      setTimeFlightOwnArrangements(transfer.formValues && transfer.formValues.timeFlight ? transfer.formValues.timeFlight : 'Select time');
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const parkFiltersLoad = await loadTransferRoutes(parkId, transfer.objType, isSafariTransferWithMatchingBooking());
      if (!parkFiltersLoad || parkFiltersLoad.length === 0) {
        setNoTransfers(true);
        return;
      } else {
        setNoTransfers(false);
      }
      setParkFilters(parkFiltersLoad);

      const parkFilters = parkFiltersLoad.map((id) => `park:${id}`).join(' OR ');
      setSearchFilters(parkFilters);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      console.log('searchFiltersOwnArrangements check');
      const parkFiltersLoad = await loadTransferRoutesOwnArrangements(parkId, transfer.objType);

      const parkFilters = parkFiltersLoad.map((id) => `park:${id}`).join(' OR ');
      setSearchFiltersOwnArrangements(parkFilters);
    };

    fetchData();
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  async function handleOptionClickGetFlights(originCode, destinationCode, firstFlight) {
    console.log('CHECK FLIGHTS');
    console.log('destinationCode', destinationCode);
    console.log(JSON.stringify(destinationCode, undefined, 4));
    console.log('originCode', originCode);
    console.log(JSON.stringify(originCode, undefined, 4));
    setIsLoading(true);
    setShowFlightDetails(true);
    console.log('adults', flightAdults);
    console.log('children', flightChildren);

    let originAirportCodes = Array.isArray(originCode) ? originCode : getAirportCodes(airports, originCode);
    let destinationAirportCodes = Array.isArray(destinationCode) ? destinationCode : getAirportCodes(airports, destinationCode);

    if (selectedDirectMultipleValue !== 'Direct') {
      console.log('originCode', originCode);
      console.log('destinationCode', destinationCode);
      originAirportCodes = [originCode];
      destinationAirportCodes = [destinationCode];
    }

    processFlights(
      originAirportCodes,
      destinationAirportCodes,
      properties,
      transfer.objType === 'arrival' ? transfer : transfer,
      transfer.objType === 'departure' ? transfer : false,
      customLocations,
      airports,
      flightAdults,
      flightChildren,
      flightInfants,
      createEdit,
      createVersion,
      airlines,
    )
      .then(async (results) => {
        console.log('All flights processed:', JSON.stringify(results, null, 2));
        // update results to check for flights without flightRouteUid but with matching fltnum, STD, STA, and flightcode and filter them out
        // This fixes duplicate flights from AeroCRS
        const filteredFlightResults = results.map((obj) => {
          return {
            ...obj,
            flight: obj.flight.filter((flight, index, self) => {
              if (!flight.flightRouteUid) {
                return (
                  self.findIndex(
                    (f) =>
                      f.fltnum === flight.fltnum && f.STD === flight.STD && f.STA === flight.STA && f.flightcode === flight.flightcode && f.fromcode === flight.fromcode && f.tocode === flight.tocode,
                  ) === index
                );
              }
              return true;
            }),
          };
        });

        // After the main loop, group or merge the results as needed

        let mergedResults = {};

        //console.log('filteredFlightResults debug', JSON.stringify(filteredFlightResults, undefined, 4));

        filteredFlightResults.forEach((flightResult) => {
          if (flightResult.dateAdjusted) {
            //console.log('flightResult.dateAdjusted debug', flightResult.dateAdjusted);
            setDateAdjusted(true);
          }
          const mergeKey = flightResult.merge;
          if (!mergedResults[mergeKey]) {
            mergedResults[mergeKey] = {
              merge: mergeKey,
              count: 0,
              flight: [],
              dateAdjusted: flightResult.dateAdjusted,
            };
          }

          // Check for duplicate flight entries
          const isDuplicate = mergedResults[mergeKey].flight.some(
            (existingFlight) => existingFlight.flightRouteUid === flightResult.flight[0].flightRouteUid && existingFlight.flightcode === flightResult.flight[0].flightcode,
          );

          if (!isDuplicate) {
            // If not a duplicate, increment the count and push the flight object
            mergedResults[mergeKey].count += flightResult.count;
            mergedResults[mergeKey].flight.push(...flightResult.flight);
          }
        });

        // Convert mergedResults object to an array
        const mergedFlightsArray = Object.values(mergedResults);

        console.log('mergedFlightsArray 1');
        console.log(JSON.stringify(mergedFlightsArray, undefined, 4));

        console.log(mergedFlightsArray);

        //sort by date
        mergedFlightsArray.forEach((result) => {
          result.flight.sort((a, b) => {
            const dateA = new Date(a.STD);
            const dateB = new Date(b.STD);
            return dateA - dateB;
          });
        });

        console.log('mergedFlightsArray');

        console.log(JSON.stringify(mergedFlightsArray, undefined, 4));

        //remove duplicate UID and sort by date - START
        mergedFlightsArray.forEach((flightGroup) => {
          const uniqueFlightsWithUID = {};
          const flightsWithoutUID = [];

          flightGroup.flight.forEach((flight) => {
            if (flight.uid) {
              if (!uniqueFlightsWithUID[flight.uid]) {
                uniqueFlightsWithUID[flight.uid] = flight;
              }
            } else {
              flightsWithoutUID.push(flight);
            }
          });

          flightGroup.flight = [...Object.values(uniqueFlightsWithUID), ...flightsWithoutUID];

          // Sort flights by STD
          flightGroup.flight.sort((a, b) => new Date(a.STD) - new Date(b.STD));
        });
        //remove duplicate UID and sort by date - END
        if (firstFlight) {
          setLocalSelectedIndices([]);
          setLocalSelectedIndex([]);
        } else {
          setLocalSelectedIndicesSecond([]);
          setLocalSelectedIndexSecond([]);
        }

        console.log('mergedFlightsArray NEW');

        console.log(JSON.stringify(mergedFlightsArray, undefined, 4));

        // Add flight pricing to each flight in mergedFlightsArray
        const updatedFlightsArray = await Promise.all(
          mergedFlightsArray.map(async (mergedFlight) => {
            const updatedFlightsArray = await Promise.all(
              mergedFlight.flight.map(async (flight) => {
                const flightPricing = await getFlightPricing(flight, mergedFlight.dateAdjusted);
                return { ...flight, flightPricing };
              }),
            );
            return { ...mergedFlight, flight: updatedFlightsArray };
          }),
        );

        // Log the updated flights array with flightPricing
        console.log('Updated Flights Array with Pricing:');
        console.log(JSON.stringify(updatedFlightsArray, null, 2));

        // ! NEW CODE START - If property is Greystoke Mahale or Chada Katavi only show flight 'NOM'
        let result;
        if (agentObject.accessLevel === accessLevel && !user.internalUser) {
          if (isSpecialProperty(transfer.propUid)) {
            const filteredFlightsArray = updatedFlightsArray
              .map((item) => ({
                ...item,
                flight: item.flight.filter((flight) => flight.airlineDesignator === 'NOM'),
              }))
              .filter((item) => item.flight.length > 0);

            // If no flights remain, return an empty array
            result = filteredFlightsArray.length > 0 ? filteredFlightsArray : [];
          } else {
            result = updatedFlightsArray;
          }
        } else {
          result = updatedFlightsArray;
        }

        // ! NEW CODE END

        // update count of flights
        const updatedFlightsArrayCount = result.map((obj) => {
          return {
            ...obj,
            count: obj.flight.length,
          };
        });

        // Log the updated flights array with flightPricing
        console.log('Updated Flights Array with Count:');
        console.log(JSON.stringify(updatedFlightsArrayCount, null, 2));

        if (firstFlight) {
          setFlightResults(updatedFlightsArrayCount);
        } else {
          console.log('SECOND FLIGHT RESULTS', JSON.stringify(updatedFlightsArrayCount, null, 2));
          setFlightResultsSecond(updatedFlightsArrayCount);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log('An error occurred:', error);
        setIsLoading(false);
      });
  }

  function handleConfirmClickSubmitFlight(e) {
    // Check if any flight has been selected
    const checkPassed = localSelectedIndices.some((index) => index !== null);

    if (!checkPassed) {
      // Prevent form submission if the check fails
      e.preventDefault();
      toast.error('Please select a flight and time.');
    }
  }

  // load the airports for algolia search
  const fetchAirportFilters = async () => {
    const airportFiltersLoad = await loadFlightRoutes(airportId, transfer.objType);
    console.log('Specific onClick triggered! ', transfer.propUid);
    console.log('airport.id: ', airportId);
    console.log('testLoad: ', airportFiltersLoad);
    console.log(JSON.stringify(airportFiltersLoad, undefined, 4));
    setAirportFilters(airportFiltersLoad);

    const airportFilters = airportFiltersLoad.map((id) => `uid:${id}`).join(' OR ');
    console.log('airportFilters: ', airportFilters);
    console.log(JSON.stringify(airportFilters, undefined, 4));

    setAirportSearchFilters(airportFilters);
  };

  const getFlightPricing = async (flight, liveDateAdjusted) => {
    let airlineDesignator = flight.airlineDesignator;

    const flightPricing = await calculateFlightPricing(
      flight, // Pass the correct flight result for the outerIndex
      null,
      flightAdults,
      flightChildren,
      flightInfants,
      airlineDesignator,
      liveDateAdjusted,
      agentObject,
      selectedAges,
      selectedAgesAdults,
      true,
      classXl,
      transfersDataFlights,
      transfer,
    );

    return flightPricing;
  };

  const handleButtonClick = async (localIndex, outerIndex, secondFlight) => {
    console.log('Checking flight vars');
    console.log(dateAdjusted);
    console.log(JSON.stringify(flightResults, undefined, 4));

    const currentPageForThisRow = currentPages[outerIndex] || 0;
    const globalIndex = currentPageForThisRow * buttonsToShow + localIndex;

    if (!secondFlight) {
      // If the first flight is selected
      const flightsForOuterIndex = flightResults[outerIndex].flight;
      setLocalSelectedIndices((prevIndices) => {
        const newIndices = Array(prevIndices.length).fill(null);
        newIndices[outerIndex] = localIndex;
        console.log('Updated Indices:', newIndices); // Debugging line
        return newIndices;
      });

      setSelectedFlightIndex(globalIndex);
      setSelectedFlight({
        ...flightsForOuterIndex[globalIndex],
        flightsArray: flightsForOuterIndex,
      });
      console.log('selectedFLight');
      console.log(JSON.stringify(selectedFlight, undefined, 4));
      let airlineName = flightsForOuterIndex[globalIndex].airlineName;
      setAirlineName(airlineName);
      let airlineDesignator = flightsForOuterIndex[globalIndex].airlineDesignator;
      const flightPricing = await calculateFlightPricing(
        flightResults[outerIndex], // Pass the correct flight result for the outerIndex
        globalIndex,
        flightAdults,
        flightChildren,
        flightInfants,
        airlineDesignator,
        dateAdjusted,
        agentObject,
        selectedAges,
        selectedAgesAdults,
        false,
        classXl,
        transfersDataFlights,
        transfer,
      );

      console.log('flightPricing');
      console.log(JSON.stringify(flightPricing, undefined, 4));

      setFlightPricing(flightPricing);
      setFlightCost(flightPricing.saleFare.total);
    } else {
      // If the second flight is selected
      const flightsForOuterIndex = flightResultsSecond[outerIndex].flight;
      setLocalSelectedIndicesSecond((prevIndices) => {
        const newIndices = Array(prevIndices.length).fill(null);
        newIndices[outerIndex] = localIndex;
        console.log('Updated Indices:', newIndices); // Debugging line
        return newIndices;
      });

      setSelectedFlightIndexSecond(globalIndex);
      setSelectedFlightSecond({
        ...flightsForOuterIndex[globalIndex],
        flightsArray: flightsForOuterIndex,
      });
      console.log('selectedFLight');
      console.log(JSON.stringify(selectedFlight, undefined, 4));
      let airlineName = flightsForOuterIndex[globalIndex].airlineName;
      setAirlineNameSecond(airlineName);
      let airlineDesignator = flightsForOuterIndex[globalIndex].airlineDesignator;
      const flightPricing = await calculateFlightPricing(
        flightResultsSecond[outerIndex], // Pass the correct flight result for the outerIndex
        globalIndex,
        flightAdults,
        flightChildren,
        flightInfants,
        airlineDesignator,
        dateAdjusted,
        agentObject,
        selectedAges,
        selectedAgesAdults,
        false,
        classXl,
        transfersDataFlights,
        transfer,
      );

      console.log('flightPricing');
      console.log(JSON.stringify(flightPricing, undefined, 4));

      setFlightPricingSecond(flightPricing);
      setFlightCostSecond(flightPricing.saleFare.total);
    }
  };

  // deal with conditional classname for location and flights
  let containerClassName = 'p-5 bg-white rounded-lg';

  // Apply additional conditions to modify the class name
  if (transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'location') && !selectedContent) {
    containerClassName = ''; // Remove the "hidden" class
  } else if (selectedContent === 'dropOffLocation' || selectedContent === 'pickupAirport' || selectedContent === 'pickupLocation') {
    containerClassName = ''; // Remove the "hidden" class
  } else {
    containerClassName += ' hidden'; // Add the "hidden" class
  }

  const shouldHide = !(
    (transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'location')) ||
    selectedContent === 'dropOffLocation' ||
    selectedContent !== 'pickupAirport' ||
    selectedContent !== 'pickupLocation'
  );

  const VirtualSearchBox = connectSearchBox(() => null);

  const handleChangeBookFlightSelect = (selectedOption) => {
    setDateFlightOwnArrangements(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);
    setTimeOwnArrangements('Select time');
    setTimeFlightOwnArrangements('Select time');
    setAirlineNameOwnArrangements(null);
    setAirlineUidOwnArrangements(null);
    setFltNum(null);
    setAirport(false);

    setSelectedLocationAlgolia(selectedOption.value);
    setSelectedLocationAlgoliaName(selectedOption.label);
    console.log('Current selected value: ', selectedOption.value);

    console.log('selectedOption.type');
    console.log(selectedOption.type);
    setSelectedLocationAlgoliaType(selectedOption.type);

    console.log('selectedOption.location');
    console.log(selectedOption.location);
    setSelectedLocationAlgoliaUid(selectedOption.location);
    setSelectedParkAlgoliaUid(selectedOption.park);
  };

  // handle change for location
  const handleChange = async (selectedOption) => {
    setSelectedLocationAlgolia(selectedOption.value);
    setSelectedLocationAlgoliaName(selectedOption.label);
    console.log('selectedOption.type');
    console.log(selectedOption.type);
    setSelectedLocationAlgoliaType(selectedOption.type);
    // vip
    if (selectedOption.type === 'airport') {
      console.log('airport location');
      console.log(selectedOption.value);
      console.log(selectedOption.label);
      setAirportCode(selectedOption.code);
      //const airportCode = selectedOption.code;
      console.log(selectedOption.code);

      //console.log(JSON.stringify(airport, null, 2));
    }

    console.log('selectedOption.location');
    console.log(selectedOption.location);
    setSelectedLocationAlgoliaUid(selectedOption.location);
    setSelectedParkAlgoliaUid(selectedOption.park);

    // possibly get transfer route here from transferRoutes
    setLocationRoute(null);
    const getLocationRoute = await loadTransferRoute(transfer.objType === 'arrival' ? selectedOption.park : parkId, transfer.objType === 'arrival' ? parkId : selectedOption.park);
    setLocationRoute(getLocationRoute);

    // New transfer route

    setTransferRouteRates(null);
    setSelectedRouteIndex(null);
    setShowButtonsRoute(false);
    setStoPriceCost(0);
    setNetRackCost(0);
    setSaleTotal(0);
    setRateId(null);
    setTotalUnits(0);
    setSupplierId(null);
    setMaxCapacity(0);
    setTransferUnits(null);
    setTransferRouteUid(null);
    setTransferType(null);
    setDistanceMins(null);
    setGameDriveTime(null);
    setTransferRouteName(null);

    const rates = getLocationRoute.rates;
    const getRateObjectAll = findTransferRatesAll(rates, transfer.endDay);
    setTransferRouteRates(filterTransferRouteRatesSingle(getRateObjectAll, properties, matchingBooking, selectedOption));
    setTransferRouteUid(getLocationRoute.uid);
    setTransferType(getLocationRoute.type);
    //alert(getLocationRoute.type);
    setDistanceMins(getLocationRoute.distanceMins);
    setGameDriveTime(getLocationRoute.gameDriveTime);
    setTransferRouteName(getLocationRoute.route);

    console.log('getLocationRoute: ', JSON.stringify(getLocationRoute, null, 2));
  };

  const handleChangeBookFlight = async (selectedOption, connecting) => {
    if (selectedDirectMultipleValue !== 'Direct' && !connecting) {
      console.log('Multiple flights selected');
      console.log('selectedOption.value', selectedOption.uid);
      console.log('selectedOption: ', JSON.stringify(selectedOption, null, 2));
      console.log('checkCustomLocations.airportId', checkCustomLocations.airportId);
      const getConnectingAirports = await loadConnectingAirports(
        transfer.objType === 'arrival' ? selectedOption.uid : checkCustomLocations.airportId,
        transfer.objType === 'arrival' ? checkCustomLocations.airportId : selectedOption.uid,
      );

      console.log('getConnectingAirports: ', JSON.stringify(getConnectingAirports, null, 2));

      // Usage
      const connectingAirportsDropDownResults = createConnectingAirportsDropdown(getConnectingAirports, airports);
      setConnectingAirportsDropDownOptions(connectingAirportsDropDownResults);

      console.log('connectingAirportsDropDownResults: ', JSON.stringify(connectingAirportsDropDownResults, null, 2));
    }

    setSelectedFlightIndex(null);
    setSelectedFlight(null);

    if (!connecting) {
      if (selectedDirectMultipleValue === 'Direct') {
        setSelectedFlightAlgolia(selectedOption.codes);
      } else {
        setSelectedFlightAlgolia(selectedOption.code);
      }

      console.log(selectedFlightAlgolia);
      setSelectedFlightAlgoliaName(selectedOption.label);
    }

    if (selectedDirectMultipleValue === 'Direct' || connecting) {
      setSearchAgain(true);
      setShowButtons(false);
    } else if (selectedDirectMultipleValue === 'Multiple' && !connecting && connectingAirportsDropDownOptions) {
      setSearchAgain(false);
      setShowButtonsSecond(false);
    }
    setConnectionClick(null);
    setConnectionType(null);
  };

  const CustomOption = ({ label, data }) => (
    <div key={data.value}>
      <div style={{ fontSize: '0.925rem' }}>{label}</div>
      <div style={{ fontSize: '0.725rem', textTransform: 'capitalize' }}>{data.type}</div>
    </div>
  );

  const CustomOptionAirports = ({ label, data }) => (
    <div key={data.value}>
      <div style={{ fontSize: '0.925rem' }}>{label}</div>
      <div style={{ fontSize: '0.725rem', textTransform: 'capitalize' }}>{data.name}</div>
      <div style={{ fontSize: '0.725rem', textTransform: 'capitalize' }}>{data.code}</div>
    </div>
  );

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      borderWidth: 2,
      borderColor: '#2A99A3',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#2A99A3',
      },
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      color: state.isSelected ? 'white' : '#000',
      backgroundColor: state.isSelected ? '#2A99A3' : 'white',
      ':hover': {
        backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
        color: state.isSelected ? 'white' : 'white',
        zIndex: 999,
      },
    }),
  };

  const customStylesBookFight = {
    control: (provided) => ({
      ...provided,
      height: '36px',
      borderRadius: '8px',
      border: '2px solid #2A99A3',
      boxShadow: 'none',
      width: '350px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      color: state.isSelected ? 'white' : '#000',
      backgroundColor: state.isSelected ? '#2A99A3' : 'white',
      ':hover': {
        backgroundColor: state.isSelected ? '#2A99A3' : '#2A99A3',
        color: state.isSelected ? 'white' : 'white',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999,
      width: '350px',
      height: '125px',
      maxHeight: '125px', // Set the max height of the dropdown menu
      overflowY: 'auto', // Add a scrollbar if the content is larger than the max height
    }),
  };

  // algolia hits and select for Locations
  const HitsLocations = connectHits(({ hits, index }) => {
    console.log(`Number of items in select: ${hits.length}`);

    console.log('hits all: ', JSON.stringify(hits, null, 2));

    return (
      <CustomSelect
        placeholder={selectedLocationAlgoliaName}
        defaultValue={{ label: selectedLocationAlgoliaName, value: selectedLocationAlgoliaName }}
        options={hits.map((hit) => ({
          value: `${hit.objectID}-${index}`,
          label: hit.name,
          type: hit.type,
          park: hit.park,
          location: hit.uid,
          code: hit.code,
        }))}
        onChange={handleChange}
        customComponents={{
          Option: (props) => (
            <components.Option {...props}>
              <CustomOption label={props.data.label} data={props.data} />
            </components.Option>
          ),
        }}
        isSearchable={true}
        icon={<FaLocationDot size={25} />}
        showDropdownIndicator={false}
        //width={280}
      />
    );
  });

  const HitsLocationsBookFlight = connectHits(({ hits, index }) => {
    console.log(`Number of items in select: ${hits.length}`);
    return (
      <>
        <CustomSelect
          placeholder={selectedLocationAlgoliaName}
          defaultValue={{ label: selectedLocationAlgoliaName, value: selectedLocationAlgoliaName }}
          options={hits.map((hit, index) => {
            console.log(`hit ID: ${hit.objectID}, index: ${index}`);
            return {
              value: `${hit.objectID}-${index}`,
              label: hit.name,
              type: hit.type,
              park: hit.park,
              location: hit.uid,
            };
          })}
          onChange={handleChangeBookFlightSelect}
          customComponents={{
            Option: (props) => (
              <components.Option {...props}>
                <CustomOption label={props.data.label} data={props.data} />
              </components.Option>
            ),
          }}
          isSearchable={true}
          icon={<FaLocationDot size={25} />}
          showDropdownIndicator={false}
          //width={280}
        />
      </>
    );
  });

  // algolia hits and select for Airports
  const HitsAirports = connectHits(({ hits }) => (
    <CustomSelect
      placeholder={selectedFlightAlgoliaName}
      defaultValue={{ label: selectedFlightAlgoliaName, value: selectedFlightAlgoliaName }}
      options={hits.map((hit, index) => ({
        //value: hit.objectID,
        value: `${hit.objectID}-${index}`,
        label: hit.name,
        code: hit.code,
        codes: hit.codes,
        multipleCodes: hit.multipleCodes,
        uid: hit.uid,
      }))}
      onChange={(selectedOption) => {
        console.log('Selected option changed:', selectedOption);
        handleChangeBookFlight(selectedOption, false);
      }}
      customComponents={{
        Option: (props) => (
          <components.Option {...props}>
            <CustomOptionAirports label={props.data.label} data={props.data} />
          </components.Option>
        ),
      }}
      isSearchable={true}
      icon={<FaLocationDot size={25} />}
      showDropdownIndicator={false}
      //width={280}isLoading
      isDisabled={isLoading || (flightResults && flightResults.length > 0) || (flightResults && flightResults.length === 0) || (flightResultsSecond && flightResultsSecond.length > 0)}
    />
  ));

  const timeOptions = Array.from({ length: 24 * 4 }, (_, i) => i * 15).map((time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return { value: formattedTime, label: formattedTime };
  });

  const timeOptionsFive = Array.from({ length: 24 * 12 }, (_, i) => i * 5).map((time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return { value: formattedTime, label: formattedTime };
  });

  const handleChangeTime = (selectedOption) => {
    setLocationTime(selectedOption.value);
    console.log(locationTime);
  };

  const totalPax = flightAdults + flightChildren + flightInfants;

  // NEW OWN ARRANGEMENTS
  function handleFltNumInputChange(e) {
    setFltNum(e.target.value);
  }

  const handleChangeTimeOwnArrangements = (selectedOption) => {
    setTimeOwnArrangements(selectedOption.value);
  };

  const handleChangeFlightTimeOwnArrangements = (selectedOption) => {
    setTimeFlightOwnArrangements(selectedOption.value);
  };

  const handleChangeDateOwnArrangements = (selectedOption) => {
    setDateOwnArrangements(selectedOption.value);
  };

  const handleChangeDateFlightOwnArrangements = (selectedOption) => {
    setDateFlightOwnArrangements(selectedOption.value);
  };

  // Handle form reset
  const handleFormReset = () => {
    // Reset your form values here

    setDateFlightOwnArrangements(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay);
    setTimeOwnArrangements('Select time');
    setTimeFlightOwnArrangements('Select time');
    setAirlineNameOwnArrangements(null);
    setAirlineUidOwnArrangements(null);
    setFltNum(null);
    setAirport(false);
    setSupplierName(null);
    setSupplierUid(null);

    setSelectedLocationAlgolia('');
    setSelectedLocationAlgoliaName('Search by airport, property or other');
    setSelectedLocationAlgoliaUid('');
    setSelectedParkAlgoliaUid('');
    setSelectedLocationAlgoliaType('');

    setFlash(true);
    setTimeout(() => setFlash(false), 1000); // Reset the flash state after 1 second
  };
  // #endregion

  // NEW DESIGN

  const [selectedBtn, setSelectedBtn] = useState(-1);

  const resetForm = useCallback(() => {
    setSelectedContent(null);
  });

  useEffect(() => {
    if (isOpen) {
      if (transfer.formValues && transfer.edit) {
        setSelectedContent(null);
        if (transfer.formValues.type === 'bookFlight') {
          setSelectedBtn(getSelectedTab('bookAFlight'));

          setSelectedContent('bookAFlight');
          setShowButtons(true);
          setShowButtonsSecond(true);
          console.log('setSelectedContent: ', selectedContent);
        } else if (transfer.formValues.type === 'location') {
          setSelectedBtn(getSelectedTab('pickupLocation'));

          setSelectedContent('pickupLocation');
          console.log('setSelectedContent: ', selectedContent);
        } else if (transfer.formValues.type === 'camp') {
          setSelectedBtn(getSelectedTab('arrivalCamp'));

          setSelectedContent('arrivalCamp');
          console.log('setSelectedContent: ', selectedContent);
        } else {
          setShowFlightDetails(false);
          setSelectedFlightAlgolia('Search by airport');
          setSelectedFlightAlgoliaName('Search by airport');
          setFlightResults(null);
          setLocationRoute(null);
          setSelectedFlightIndex(null);
          setSelectedFlight(null);
          setConnectionType(null);
          setConnectionClick(null);
        }
      }
      console.log('setSelectedContent effect: ', selectedContent);
      console.log('setSelectedBtn effect: ', selectedBtn);
      //setSelectedTab(selectedBtn);
    } else {
      setSelectedContent(null);
      setSelectedBtn(-1);
      return;
    }
  }, [isOpen]);

  console.log('selectedBtn: ', selectedBtn);

  //const tabNames = ['Book Flight', 'Road Transfer', 'Own Arrangements']; // Custom tab names

  // USER ACCESSLEVEL LOGIC

  // if (agentObject.accessLevel === 'limited') {
  //   if (
  //     transfer.propUid === 'e867a3b5-1b63-4913-a052-f69d08523647' ||
  //     transfer.propUid === 'cd3dc792-9604-4363-8246-7a55421ff961' ||
  //     nextTransfer.propUid === 'e867a3b5-1b63-4913-a052-f69d08523647' ||
  //     nextTransfer.propUid === 'cd3dc792-9604-4363-8246-7a55421ff961'
  //   ) {
  //     tabNames = ['Book Flight', 'Own Arrangements']; // property before or after is either of these: Greystoke Mahale or Chada Katavi
  //   } else {
  //     tabNames = ['Own Arrangements']; // Just show option for own arrangements
  //   }
  // } else {
  //   tabNames = ['Book Flight', 'Road Transfer', 'Other arrangements']; // original
  // }

  function isSpecialProperty(propUid) {
    const specialPropertyIds = ['e867a3b5-1b63-4913-a052-f69d08523647', 'cd3dc792-9604-4363-8246-7a55421ff961'];
    return specialPropertyIds.includes(propUid);
  }

  const [selectedTab, setSelectedTab] = useState(-1);
  let tabNames;
  // Custom tab names
  // first condition shows flights in some cases and own arrangements
  if (agentObject.accessLevel === accessLevel && !user.internalUser) {
    if (isSpecialProperty(transfer.propUid)) {
      tabNames = ['Book Flight', 'Own Arrangements'];
    } else {
      tabNames = ['Own Arrangements'];
    }
    // second condition hide own arrangements
    //} else if (agentObject.accessLevel === accessLevelHideOwnArrangements && !user.internalUser) {
  } else if (hideOwnArrangements(advancedMode)) {
    tabNames = ['Book Flight', 'Road Transfer'];
    // else show all
  } else {
    tabNames = ['Book Flight', 'Road Transfer', 'Own Arrangements'];
  }

  console.log('user level');
  console.log(JSON.stringify(user, null, 2));

  function getSelectedTab(type) {
    if (agentObject.accessLevel === accessLevel && !user.internalUser) {
      if (isSpecialProperty(transfer.propUid)) {
        if (type === 'bookAFlight') {
          return 0;
        } else if (type === 'arrivalCamp') {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (type === 'arrivalCamp') {
          return 0;
        } else {
          return -1;
        }
      }
    } else if (hideOwnArrangements(advancedMode)) {
      if (type === 'bookAFlight') {
        return 0;
      } else if (type === 'pickupLocation') {
        return 1;
      } else {
        return -1;
      }
    } else {
      if (type === 'bookAFlight') {
        return 0;
      } else if (type === 'pickupLocation') {
        return 1;
      } else if (type === 'arrivalCamp') {
        return 2;
      }
    }
  }

  const [allowClose, setAllowClose] = useState(false);

  // NEW MULTIPLE FLIGHTS NEW FLIGHTS

  function createConnectingAirportsDropdown(getConnectingAirports, airports) {
    const results =
      getConnectingAirports && getConnectingAirports.connectingAirports
        ? airports
            .filter((airport) => getConnectingAirports.connectingAirports.includes(airport.uid) && airport.alternativeAirports === false)
            .map((airport, index) => ({
              value: `${airport.objectID}-${index}`,
              label: airport.name,
              code: airport.code,
              codes: airport.codes,
              multipleCodes: airport.multipleCodes,
              uid: airport.uid,
            }))
        : [];
    return results;
  }

  const [changeFlight, setChangeFlight] = useState(true);

  // Load back in flights
  useEffect(() => {
    const fetchData = async () => {
      if (transfer.formValues && transfer.formValues.flightDetails) {
        setChangeFlight(false);
        const savedFlightDetails = transfer.formValues.flightDetails;

        const savedFlightIndex = 0;

        let availableFlightsData = [];
        availableFlightsData.push({
          count: 0,
          flight: [],
        });

        // Add flight pricing to each flight in mergedFlightsArray
        const updatedFlightsArray = await Promise.all(
          availableFlightsData.map(async (mergedFlight) => {
            const updatedFlightsArray = await Promise.all(
              mergedFlight.flight.map(async (flight) => {
                const flightPricing = await getFlightPricing(flight, mergedFlight.dateAdjusted);
                return { ...flight, flightPricing };
              }),
            );
            return { ...mergedFlight, flight: updatedFlightsArray };
          }),
        );

        // Log the updated flights array with flightPricing
        console.log('Updated Flights Array with Pricing:');
        console.log(JSON.stringify(updatedFlightsArray, null, 2));

        setFlightResults(updatedFlightsArray);
        setSelectedFlightIndex(savedFlightIndex);
        setSelectedFlight(savedFlightDetails);
        setShowFlightDetails(true);
        setSelectedOption('bookAFlight');
        setAirlineName(transfer.formValues.airlineName);

        //Second flight
        if (transfer.formValues.flightDetailsSecond) {
          setSelectedDirectMultipleValue('Multiple');
          setSelectedDirectMultipleLabel('Multiple');
          setConnectingAirport(false);
          const savedFlightDetailsSecond = transfer.formValues.flightDetailsSecond;

          const savedFlightIndexSecond = 0;
          let availableFlightsDataSecond = [];

          availableFlightsDataSecond.push({
            count: 0,
            flight: [],
          });
          setFlightResultsSecond(availableFlightsDataSecond);
          setSelectedFlightIndexSecond(savedFlightIndexSecond);
          setSelectedFlightSecond(savedFlightDetailsSecond);
          setAirlineNameSecond(transfer.formValues.airlineNameSecond ? transfer.formValues.airlineNameSecond : '');

          setFlightCostSecond(
            transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
              ? transfer.formValues.flightPricingSecond
                ? transfer.formValues.flightPricingSecond.saleFare.total
                : 0
              : 0,
          );

          setFlightPricingSecond(
            transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
              ? transfer.formValues.flightPricingSecond
                ? transfer.formValues.flightPricingSecond
                : []
              : [],
          );
          const index = 0;

          setLocalSelectedIndicesSecond([index]);
        }

        setFlightCost(
          transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.flightPricing.saleFare.total : 0,
        );

        setFlightPricing(transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.flightPricing : []);

        setConnectionType(transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.connectionType : null);
        setConnectionClick(transfer.formValues && transfer.edit && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? transfer.formValues.connectionClick : null);

        setLocationName(
          transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') && transfer.formValues.locationName ? transfer.formValues.locationName : null,
        );
        setLocationUid(
          transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') && transfer.formValues.locationUid ? transfer.formValues.locationUid : null,
        );

        setSupplierName(
          transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') && transfer.formValues.supplierName ? transfer.formValues.supplierName : null,
        );
        setSupplierUid(
          transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') && transfer.formValues.supplierUid ? transfer.formValues.supplierUid : null,
        );
        const index = 0;
        setLocalSelectedIndices([index]);

        fetchAirportFilters();
      }
    };

    fetchData();
  }, [transfer]);

  const [changeRoute, setChangeRoute] = useState(false);

  const resetSearch = () => {
    setSelectedFlight(null);
    setFlightResultsSecond(false);
    setSelectedFlightIndexSecond(null);
    setSelectedFlightSecond(null);
    setFlightPricingSecond(0);
    setFlightCostSecond(0);
    setChangeFlight(true);
    setShowButtons(false);
    setShowButtonsSecond(false);
    setConnectingAirport(true);
  };

  const resetFlights = () => {
    resetFlightsOpen();
    setSelectedFlightAlgolia('Search by airport');
    setSelectedFlightAlgoliaName('Search by airport');
    setSelectedConnectingLabel(transfer.objType === 'departure' ? "Select 'Arrival' airport" : "Select 'Departure' airport");
    setFlightToSearchAirport(null);
  };

  const resetFlightsOpen = () => {
    setIncludeArrivalTransfer(true);
    setIncludeDepartureTransfer(true);
    setSelectedDirectMultipleValue('Direct');
    setSelectedDirectMultipleLabel('Direct');
    setClassValue('economy');
    setClassLabel('Economy');
    setConnectingAirport(false);
    setFlightResults(false);
    setFlightResultsSecond(false);
    setSelectedConnectingValue(null);

    setSelectedFlightIndex(null);
    setSelectedFlightIndexSecond(null);
    setSelectedFlightSecond(null);
    setFlightPricingSecond(0);
    setFlightPricing(0);
    setFlightCost(0);
    setFlightCostSecond(0);
    setShowFlightDetails(false);
    setConnectingAirportsDropDownOptions([]);
    setConnectingAirportUid(null);

    setSelectedFlight(null);
    setClassXl(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setChangeRoute(false);
      resetFlights();
      setSelectedRouteIndex(null);
    }
  }, [isOpen]);

  useEffect(() => {
    async function fetchData() {
      // 02/06/24 start working on flightToSearch - this works until I click 'change' on card and then it breaks
      let flightToSearch = null;
      let connectionFlight = null;

      if (transfer.objType === 'arrival' && transfer.formValues && transfer.formValues.type === 'bookFlight' && transfer.formValues.flightDetails) {
        flightToSearch = airports.find((airport) => airport.code === transfer.formValues.flightDetails.fromcode);
        console.log('flightToSearch');
        console.log(JSON.stringify(flightToSearch, undefined, 4));
        setSelectedFlightAlgolia('Search by airport');
        setSelectedFlightAlgoliaName(
          transfer.formValues && transfer.formValues.flightDetails && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
            ? getAirportName(airports, getAeroCrsCode(airports, transfer.formValues.flightDetails.fromcode))
            : 'Search by airport',
        );
      } else if (transfer.objType === 'departure' && transfer.formValues && transfer.formValues.type === 'bookFlight' && transfer.formValues.flightDetails) {
        flightToSearch = airports.find((airport) => airport.code === transfer.formValues.flightDetails.tocode);
        setSelectedFlightAlgolia('Search by airport');
        setSelectedFlightAlgoliaName(
          transfer.formValues && transfer.formValues.flightDetails && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
            ? getAirportName(airports, getAeroCrsCode(airports, transfer.formValues.flightDetails.tocode))
            : 'Search by airport',
        );
      }

      if (transfer.objType === 'arrival' && transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') && transfer.formValues.flightDetailsSecond) {
        connectionFlight = airports.find((airport) => airport.code === transfer.formValues.flightDetailsSecond.fromcode);
        setConnectingAirportUid(connectionFlight.uid);
      } else if (transfer.objType === 'departure' && transfer.formValues && transfer.formValues.type === 'bookFlight' && transfer.formValues.flightDetailsSecond) {
        connectionFlight = airports.find((airport) => airport.code === transfer.formValues.flightDetailsSecond.fromcode);
        setConnectingAirportUid(connectionFlight.uid);
        flightToSearch = airports.find((airport) => airport.code === transfer.formValues.flightDetailsSecond.tocode);
        setSelectedFlightAlgolia(flightToSearch.code);
        setSelectedFlightAlgoliaName(
          transfer.formValues && transfer.formValues.flightDetailsSecond && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')
            ? getAirportName(airports, getAeroCrsCode(airports, transfer.formValues.flightDetailsSecond.tocode))
            : 'Search by airport',
        );
      }

      setSelectedConnectingLabel(connectionFlight === null ? (transfer.objType === 'departure' ? "Select 'Arrival' airport" : "Select 'Departure' airport") : connectionFlight.name);
      setSelectedConnectingValue(connectionFlight === null ? null : connectionFlight.code);

      setFlightToSearchAirport(transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? flightToSearch : null);

      // New fix 07/06/24 - start
      let flightToCheck = selectedFlightSecond ? selectedFlightSecond : selectedFlight;
      if (flightToCheck !== null) {
        const flightFiltersLoad = await loadFlightLocations(flightToCheck, transfer.objType, airports);
        const airportParkFilters = flightFiltersLoad.map((id) => `park:${id}`).join(' OR ');
        setFlightLocations(airportParkFilters);
      } else {
        setFlightLocations([]);
      }
      // New fix 07/06/24 - end
    }

    if (isOpen && transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')) {
      fetchData();
    }
  }, [isOpen]);

  const [selectedDirectMultipleValue, setSelectedDirectMultipleValue] = useState('Direct');
  const [selectedDirectMultipleLabel, setSelectedDirectMultipleLabel] = useState('Direct');
  const [connectingAirportsDropDownOptions, setConnectingAirportsDropDownOptions] = useState([]);
  const [connectingAirport, setConnectingAirport] = useState(false);
  const [connectingAirportUid, setConnectingAirportUid] = useState(null);
  const [flightToSearchAirport, setFlightToSearchAirport] = useState(null);
  const [selectedFlightIndexSecond, setSelectedFlightIndexSecond] = useState(null);
  const [selectedFlightSecond, setSelectedFlightSecond] = useState(null);

  const [localSelectedIndicesSecond, setLocalSelectedIndicesSecond] = useState([]);
  const [currentPagesSecond, setCurrentPagesSecond] = useState([]);

  const [selectedConnectingValue, setSelectedConnectingValue] = useState(null);
  const [selectedConnectingLabel, setSelectedConnectingLabel] = useState(transfer.objType === 'departure' ? "Select 'Arrival' airport" : "Select 'Departure' airport");

  const [classValue, setClassValue] = useState('Direct');
  const [classLabel, setClassLabel] = useState('Direct');

  const handleDirectMultiple = async (selectedOption, event) => {
    fetchAirportFilters();
    setShowFlightDetails(false);
    setSelectedFlightAlgolia('Search by airport');
    setSelectedFlightAlgoliaName('Search by airport');
    setFlightResults(null);
    //handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
    //setSelectedBtn(index);
    setSelectedFlightIndex(null);
    setSelectedFlight(null);
    setConnectionType(null);
    setConnectionClick(null);
    // setSelectedTab(index);
    setChangeFlight(true);
    resetFlights();

    //
    //resetFlights();
    setConnectingAirport(false);
    setSelectedDirectMultipleLabel(selectedOption.label);
    console.log('setSelectedDirectMultipleLabel', selectedOption.label);
    setSelectedDirectMultipleValue(selectedOption.value);
    console.log('setSelectedDirectMultipleValue', selectedOption.value);
  };

  const directMultipleOptions = [
    { value: 'Direct', label: 'Direct' },
    { value: 'Multiple', label: 'Multiple' },
  ];

  // New fix 07/06/24 - start
  useEffect(() => {
    async function fetchData() {
      const flightFiltersLoad = await loadFlightLocations(flightToCheck, transfer.objType, airports);
      const airportParkFilters = flightFiltersLoad.map((id) => `park:${id}`).join(' OR ');
      setFlightLocations(airportParkFilters);
    }
    let flightToCheck = selectedFlightSecond ? selectedFlightSecond : selectedFlight;
    if (isOpen && transfer.formValues && flightToCheck !== null && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight')) {
      fetchData();
    }
  }, [airports, isOpen, selectedFlight, selectedFlightSecond, transfer.formValues, transfer.objType]);
  // New fix 07/06/24 - end

  const [hasSufficientTime, setHasSufficientTime] = useState(true);
  const [classXl, setClassXl] = useState(false);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={() => allowClose && onClose()}>
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-0 flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="flex-1 h-full overflow-y-auto">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto">
                        <div className="bg-white px-4 pt-6 sm:px-6">
                          <Dialog.Title className={`mt-5 text-2xl font-base font-normal leading-6 `}>
                            <div className="flex justify-between">
                              <div className="flex">
                                <div className="ml-0 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="relative rounded-md bg-white text-black hover:text-black"
                                    onClick={() => {
                                      if (!transfer.formValues) {
                                        //setSelectedTransferTuuid(null);
                                        //onClose();
                                        closeForm(null, transfer.tuuid, transfers, setTransfers, onClose);
                                      } else {
                                        closeForm(null, transfer.tuuid, transfers, setTransfers, onClose);
                                      }
                                    }}
                                  >
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                  </button>
                                  <span className={`text-base text-black ml-5`}>{transfer.objType === 'departure' ? 'Add departure ' : 'Add arrival '}</span>
                                  {(user.email === 'prideaux@gmail.com' || user.email === 'roger.prideaux@gmail.com') && (
                                    <div className="flex text-xs">
                                      <div className="w-4"></div>
                                      <button
                                        onClick={async () => {
                                          console.log(JSON.stringify(selectedFlight, undefined, 4));
                                        }}
                                      >
                                        selectedFlight
                                      </button>
                                      <div className="w-4"></div>
                                      <button
                                        onClick={async () => {
                                          console.log(JSON.stringify(selectedFlightSecond, undefined, 4));
                                        }}
                                      >
                                        selectedFlightSecond
                                      </button>
                                      <div className="w-4"></div>
                                      <button
                                        onClick={async () => {
                                          console.log(JSON.stringify(vipObject, undefined, 4));
                                        }}
                                      >
                                        vipObject
                                      </button>

                                      {/* <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(flightToSearchAirport, undefined, 4));
                                      }}
                                    >
                                      flightToSearchAirport
                                    </button>

                                    <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(selectedContent, undefined, 4));
                                      }}
                                    >
                                      selectedContent
                                    </button>
                                    <div className="w-4"></div>
                                    <button
                                      onClick={async () => {
                                        console.log(JSON.stringify(transferRouteRates, undefined, 4));
                                      }}
                                    >
                                      transferRouteRates
                                    </button> */}
                                    </div>
                                  )}
                                </div>
                              </div>{' '}
                            </div>
                          </Dialog.Title>
                          <div className="mt-8 mb-5 text-2xl font-semibold">
                            {transfer.objType === 'departure' ? 'Departure from ' : 'Arrival to '} {properties[getPropObj(transfer.propUid, properties)].name}
                          </div>
                          <div className="">
                            <nav className="flex divide-x divide-gray-300 rounded-xl overflow-hidden">
                              {tabNames.map(
                                (name, index) => {
                                  console.log('index check: ', index);
                                  console.log('selectedBtn check: ', selectedBtn);
                                  console.log('setSelectedContent effect1: ', selectedContent);

                                  const isAvailable = (name === 'Own Arrangements' && !hideOwnArrangements(advancedMode)) || (name === 'Road Transfer' && !noTransfers) || name === 'Book Flight';

                                  return isAvailable ? (
                                    // selectedTab === index && (
                                    <button
                                      key={index}
                                      type="button"
                                      className={`flex-1 py-3 text-sm font-medium 
                                ${selectedBtn === index ? 'bg-[#1D253F] text-white' : 'bg-gray-300 text-black'}
                                hover:bg-[#1D253F] hover:text-white h-[67px]`} // Simplified and corrected hover effect
                                      //data-target={index === 0 ? 'bookAFlight' : index === 1 ? 'pickupLocation' : index === 2 ? 'arrivalCamp' : null}
                                      data-target={(() => {
                                        // first condition shows flights in some cases and own arrangements
                                        if (agentObject.accessLevel === accessLevel && !user.internalUser) {
                                          if (isSpecialProperty(transfer.propUid)) {
                                            if (index === 0) return 'bookAFlight';
                                            else if (index === 1) return 'arrivalCamp';
                                          } else {
                                            if (index === 0) return 'arrivalCamp';
                                          }
                                          // second condition hide own arrangements
                                        }
                                        // else if (hideOwnArrangements(advancedMode)) {
                                        //   if (index === 0) return 'bookAFlight';
                                        //   else if (index === 1) return 'pickupLocation';
                                        //   // else show all
                                        // }
                                        else {
                                          if (index === 0) return 'bookAFlight';
                                          else if (index === 1) return 'pickupLocation';
                                          else return 'arrivalCamp';
                                        }
                                      })()}
                                      onClick={(event) => {
                                        console.log(`Clicked tab index: ${index}`);
                                        // first condition shows flights in some cases and own arrangements
                                        if (agentObject.accessLevel === accessLevel && !user.internalUser) {
                                          if (isSpecialProperty(transfer.propUid)) {
                                            if (index === 0) {
                                              fetchAirportFilters();
                                              setShowFlightDetails(false);
                                              setSelectedFlightAlgolia('Search by airport');
                                              setSelectedFlightAlgoliaName('Search by airport');
                                              setFlightResults(null);
                                              handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
                                              setSelectedBtn(index);
                                              setSelectedFlightIndex(null);
                                              setSelectedFlight(null);
                                              setConnectionType(null);
                                              setConnectionClick(null);
                                              // setSelectedTab(index);
                                              setChangeFlight(true);
                                              resetFlights();
                                            } else if (index === 1) {
                                              handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
                                              setSelectedBtn(index);
                                              //setSelectedTab(index);
                                              setChangeFlight(true);
                                              resetFlights();
                                              setAddLaterOwnArrangements(false);
                                            }
                                          } else {
                                            if (index === 0) {
                                              handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
                                              setSelectedBtn(index);
                                              //setSelectedTab(index);
                                              setChangeFlight(true);
                                              resetFlights();
                                              setAddLaterOwnArrangements(false);
                                            }
                                          }
                                          // second condition hide own arrangements
                                        }
                                        // else if (hideOwnArrangements(advancedMode)) {
                                        //   if (index === 0) {
                                        //     fetchAirportFilters();
                                        //     setShowFlightDetails(false);
                                        //     setSelectedFlightAlgolia('Search by airport');
                                        //     setSelectedFlightAlgoliaName('Search by airport');
                                        //     setFlightResults(null);
                                        //     handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
                                        //     setSelectedBtn(index);
                                        //     setSelectedFlightIndex(null);
                                        //     setSelectedFlight(null);
                                        //     setConnectionType(null);
                                        //     setConnectionClick(null);
                                        //     // setSelectedTab(index);
                                        //     setChangeFlight(true);
                                        //     resetFlights();
                                        //   } else if (index === 1) {
                                        //     const currentTarget = event.currentTarget;
                                        //     if (noTransfers) {
                                        //       toast.error('No transfers available.');
                                        //       return;
                                        //     } else {
                                        //       const syntheticEvent = {
                                        //         ...event,
                                        //         currentTarget,
                                        //       };
                                        //       setLocationTime('Select time');
                                        //       setLocationRoute(null);
                                        //       handleOptionClick(syntheticEvent, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
                                        //       setSelectedBtn(index);
                                        //       //setSelectedTab(index);
                                        //       setChangeFlight(true);
                                        //       resetFlights();
                                        //     }
                                        //     // NEW TRANSFER ROUTE
                                        //     setTransferRouteRates(null);
                                        //     setSelectedRouteIndex(null);
                                        //     setShowButtonsRoute(false);
                                        //     setStoPriceCost(0);
                                        //     setNetRackCost(0);
                                        //     setSaleTotal(0);
                                        //     setRateId(null);
                                        //     setTotalUnits(0);
                                        //     setSupplierId(null);
                                        //     setMaxCapacity(0);
                                        //     setTransferUnits(null);
                                        //     setTransferRouteUid(null);
                                        //     setTransferType(null);
                                        //     setDistanceMins(null);
                                        //     setGameDriveTime(null);
                                        //     setTransferRouteName(null);
                                        //   }
                                        //   // else show all
                                        // }
                                        else {
                                          if (index === 0) {
                                            fetchAirportFilters();
                                            setShowFlightDetails(false);
                                            setSelectedFlightAlgolia('Search by airport');
                                            setSelectedFlightAlgoliaName('Search by airport');
                                            setFlightResults(null);
                                            handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
                                            setSelectedBtn(index);
                                            setSelectedFlightIndex(null);
                                            setSelectedFlight(null);
                                            setConnectionType(null);
                                            setConnectionClick(null);
                                            // setSelectedTab(index);
                                            setChangeFlight(true);
                                            resetFlights();
                                          } else if (index === 1) {
                                            const currentTarget = event.currentTarget;
                                            if (noTransfers) {
                                              toast.error('No transfers available.');
                                              return;
                                            } else {
                                              const syntheticEvent = {
                                                ...event,
                                                currentTarget,
                                              };
                                              setLocationTime('Select time');
                                              setLocationRoute(null);
                                              handleOptionClick(syntheticEvent, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
                                              setSelectedBtn(index);
                                              //setSelectedTab(index);
                                              setChangeFlight(true);
                                              resetFlights();
                                            }
                                            // NEW TRANSFER ROUTE
                                            setTransferRouteRates(null);
                                            setSelectedRouteIndex(null);
                                            setShowButtonsRoute(false);
                                            setStoPriceCost(0);
                                            setNetRackCost(0);
                                            setSaleTotal(0);
                                            setRateId(null);
                                            setTotalUnits(0);
                                            setSupplierId(null);
                                            setMaxCapacity(0);
                                            setTransferUnits(null);
                                            setTransferRouteUid(null);
                                            setTransferType(null);
                                            setDistanceMins(null);
                                            setGameDriveTime(null);
                                            setTransferRouteName(null);
                                          } else if (index === 2) {
                                            handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
                                            setSelectedBtn(index);
                                            //setSelectedTab(index);
                                            setChangeFlight(true);
                                            resetFlights();
                                            setAddLaterOwnArrangements(false);
                                          }
                                        }

                                        //setSelectedBtn(index);
                                        handleFormReset();
                                      }}
                                    >
                                      <div className="flex justify-center">
                                        {/* {index === 0 ? (
                                          <LiaPlaneSolid size={25} className="mr-2" />
                                        ) : index === 1 ? (
                                          <IoLocationOutline size={25} className="mr-2" />
                                        ) : (
                                          <TbTriangle size={25} className="mr-2" />
                                        )}{' '} */}
                                        {(() => {
                                          // first condition shows flights in some cases and own arrangements
                                          if (agentObject.accessLevel === accessLevel && !user.internalUser) {
                                            if (isSpecialProperty(transfer.propUid)) {
                                              if (index === 0) {
                                                return <LiaPlaneSolid size={25} className="mr-2" />;
                                              } else if (index === 1) {
                                                return <TbTriangle size={25} className="mr-2" />;
                                              }
                                            } else {
                                              if (index === 0) {
                                                return <TbTriangle size={25} className="mr-2" />;
                                              }
                                            }
                                            // second condition hide own arrangements
                                          }
                                          // else if (hideOwnArrangements(advancedMode)) {
                                          //   if (index === 0) {
                                          //     return <LiaPlaneSolid size={25} className="mr-2" />;
                                          //   } else if (index === 1) {
                                          //     return <IoLocationOutline size={25} className="mr-2" />;
                                          //   }
                                          // }

                                          // else show all
                                          else {
                                            if (index === 0) {
                                              return <LiaPlaneSolid size={25} className="mr-2" />;
                                            } else if (index === 1) {
                                              return <IoLocationOutline size={25} className="mr-2" />;
                                            } else {
                                              return <TbTriangle size={25} className="mr-2" />;
                                            }
                                          }
                                        })()}
                                        <span>{name}</span>
                                      </div>
                                    </button>
                                  ) : null;
                                },
                                // ),
                              )}
                            </nav>
                          </div>
                          {selectedBtn === -1 && <div className="text-center text-sm my-10">Select an option above</div>}

                          <div key={uuidv4()} className="pt-5">
                            {/* Pick up from airport */}

                            {/* Location pick up NEW */}
                            <form
                              id="pickupLocation"
                              className={`${
                                transfer.formValues && transfer.edit && transfer.formValues.type === 'location' && !selectedContent ? '' : selectedContent === 'pickupLocation' ? '' : 'hidden'
                              }  bg-white rounded-lg ${flash ? 'flash-effect' : ''}`}
                              onSubmit={(e) => {
                                e.preventDefault(); // Prevent the form from refreshing the page
                                if (selectedLocationAlgoliaName !== 'Search by airport, property or other') {
                                  handleFormSubmitLocation(e, transfers, setTransfers, onClose, bookings, setBookings, properties);
                                } else {
                                  // Show a toast error
                                  toast.error('Please select a location.');
                                }
                              }}
                            >
                              <input type="hidden" name="formType" value={transfer.objType} />
                              <input type="hidden" name="tuuid" value={transfer.tuuid} />
                              <input type="hidden" name="locationUid" value={selectedLocationAlgoliaUid} />

                              <input type="hidden" name="locationName" value={selectedLocationAlgoliaName} />
                              <input type="hidden" name="time" value={locationTime} />

                              <input type="hidden" name="originUid" value={transfer.objType === 'arrival' ? selectedParkAlgoliaUid : parkId} />
                              <input type="hidden" name="destinationUid" value={transfer.objType === 'arrival' ? parkId : selectedParkAlgoliaUid} />

                              <input type="hidden" name="locationType" value={selectedLocationAlgoliaType} />
                              <input type="hidden" name="totalPax" value={totalPax} />

                              {/* New from Own Arrangements */}
                              <input type="hidden" name="airlineUidOwnArrangements" value={airlineUidOwnArrangements} />
                              <input type="hidden" name="airlineNameOwnArrangements" value={airlineNameOwnArrangements} />
                              <input type="hidden" name="airport" value={selectedLocationAlgoliaType} />
                              <input type="hidden" name="dateFlightOwnArrangements" value={dateFlightOwnArrangements} />
                              <input type="hidden" name="timeFlightOwnArrangements" value={timeFlightOwnArrangements} />
                              <input type="hidden" name="addFlightDetailsLater" value={addFlightDetailsLater} />
                              <input type="hidden" name="rateType" value={rateType} />
                              <input type="hidden" name="fltNum" value={fltNum} />

                              {/* NEW ROUTE */}
                              <input type="hidden" name="maxCapacity" value={maxCapacity} />
                              <input
                                type="hidden"
                                name="supplier"
                                value={JSON.stringify({
                                  id: supplierId,
                                  name: supplierName,
                                })}
                              />
                              <input type="hidden" name="stoPriceCost" value={stoPriceCost} />
                              <input type="hidden" name="netRackCost" value={netRackCost} />
                              <input type="hidden" name="saleTotal" value={saleTotal} />
                              <input type="hidden" name="rateId" value={rateId} />
                              <input type="hidden" name="totalUnits" value={totalUnits} />
                              <input type="hidden" name="transferRouteUid" value={transferRouteUid} />
                              <input type="hidden" name="transferType" value={transfer.objType === 'arrival' ? 'arrivalRoadTransferTown' : 'departureRoadTransferTown'} />
                              <input type="hidden" name="distanceMins" value={distanceMins} />
                              <input type="hidden" name="gameDriveTime" value={gameDriveTime} />
                              <input type="hidden" name="transferRouteName" value={transferRouteName} />
                              <input type="hidden" name="detailsNow" value={detailsNow} />
                              <input type="hidden" name="includeArrivalTransferVip" value={includeArrivalTransferVip} />
                              <input type="hidden" name="includeDepartureTransferVip" value={includeDepartureTransferVip} />
                              <input type="hidden" name="airportCode" value={airportCode} />

                              <div>
                                {transfer.objType === 'arrival' ? (
                                  <div className="flex w-full">
                                    <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                      {/* Close to 5.5 columns  */}
                                      <label htmlFor="supplier-select" className="text-xs">
                                        {transfer.objType === 'departure' ? 'Departure from' : 'Departure from'}
                                      </label>
                                      <InstantSearch indexName="locations" searchClient={searchClient} searchState={searchStateLocation} onSearchStateChange={setSearchStateLocation}>
                                        <Configure filters={searchFilters} hitsPerPage={searchFilters === '' ? 0 : numHits} />
                                        <HitsLocations />
                                        <VirtualSearchBox />
                                      </InstantSearch>
                                    </div>
                                    <div className="flex-1" style={{ flexBasis: '8.33%' }}>
                                      {' '}
                                      {/* Exactly 1 column */}
                                      <div className="p-4 text-white">
                                        <div className="flex-1 flex items-center justify-center mt-5">
                                          <FaLongArrowAltRight size={30} className="text-[#1D253F]" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                      {' '}
                                      {/* Close to 5.5 columns again */}
                                      <label htmlFor="supplier-select" className="text-xs">
                                        {transfer.objType === 'departure' ? 'Arrival at' : 'Arrival at'}
                                      </label>
                                      <CustomSelect
                                        placeholder={selectedLocationAlgoliaName}
                                        defaultValue={{ label: properties[getPropObj(transfer.propUid, properties)].name, value: properties[getPropObj(transfer.propUid, properties)].name }}
                                        onChange={handleChange}
                                        customComponents={{
                                          Option: (props) => (
                                            <components.Option {...props}>
                                              <CustomOption label={props.data.label} data={props.data} />
                                            </components.Option>
                                          ),
                                        }}
                                        isSearchable={true}
                                        icon={<FaLocationDot size={25} />}
                                        showDropdownIndicator={false}
                                        isDisabled={true}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex w-full">
                                    <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                      {' '}
                                      {/* Close to 5.5 columns again */}
                                      <label htmlFor="supplier-select" className="text-xs">
                                        {transfer.objType === 'departure' ? 'Departure from' : 'Departure from'}
                                      </label>
                                      <CustomSelect
                                        placeholder={selectedLocationAlgoliaName}
                                        defaultValue={{ label: properties[getPropObj(transfer.propUid, properties)].name, value: properties[getPropObj(transfer.propUid, properties)].name }}
                                        onChange={handleChange}
                                        customComponents={{
                                          Option: (props) => (
                                            <components.Option {...props}>
                                              <CustomOption label={props.data.label} data={props.data} />
                                            </components.Option>
                                          ),
                                        }}
                                        isSearchable={true}
                                        icon={<FaLocationDot size={25} />}
                                        showDropdownIndicator={false}
                                        isDisabled={true}
                                      />
                                    </div>
                                    <div className="flex-1" style={{ flexBasis: '8.33%' }}>
                                      {' '}
                                      {/* Exactly 1 column */}
                                      <div className="p-4 text-white">
                                        <div className="flex-1 flex items-center justify-center mt-5">
                                          <FaLongArrowAltRight size={30} className="text-[#1D253F]" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                      {/* Close to 5.5 columns  */}
                                      <label htmlFor="supplier-select" className="text-xs">
                                        {transfer.objType === 'departure' ? 'Arrival at' : 'Arrival at'}
                                      </label>
                                      <InstantSearch indexName="locations" searchClient={searchClient} searchState={searchStateLocation} onSearchStateChange={setSearchStateLocation}>
                                        <Configure filters={searchFilters} hitsPerPage={searchFilters === '' ? 0 : numHits} />
                                        <HitsLocations />
                                        <VirtualSearchBox />
                                      </InstantSearch>
                                    </div>
                                  </div>
                                )}

                                {transferRouteRates && transferRouteRates.length > 0 ? (
                                  <div className="w-full mb-5">
                                    <div>
                                      <TransferRouteComponent
                                        transferRouteRates={transferRouteRates}
                                        selectedRouteIndex={selectedRouteIndex}
                                        setSelectedRouteIndex={setSelectedRouteIndex}
                                        showButtonsRoute={showButtonsRoute}
                                        setShowButtonsRoute={setShowButtonsRoute}
                                        transfer={transfer}
                                        // transferUnits={transferUnits}
                                        setStoPriceCost={setStoPriceCost}
                                        setNetRackCost={setNetRackCost}
                                        setSaleTotal={setSaleTotal}
                                        setRateId={setRateId}
                                        passengers={flightAdults + flightChildren + flightInfants}
                                        setTotalUnits={setTotalUnits}
                                        setSupplierName={setSupplierName}
                                        setSupplierId={setSupplierId}
                                        setMaxCapacity={setMaxCapacity}
                                        selectedLocationAlgoliaType={selectedLocationAlgoliaType}
                                        handlesOwnAirportTransfer={properties[getPropObj(transfer.propUid, properties)].handlesOwnAirportTransfer}
                                        setRateType={setRateType}
                                      />
                                    </div>
                                  </div>
                                ) : transferRouteRates !== null ? (
                                  <TransferInfoCard icon={IoMdInformationCircleOutline} title="No transfers Found" text={'Try searching another location'} />
                                ) : null}

                                {selectedLocationAlgoliaName !== 'Search by airport, property or other' && showButtonsRoute && selectedRouteIndex !== null && (
                                  <div className="grid grid-cols-12 gap-4">
                                    <div className="mb-0 mt-10 flex items-start col-span-12">
                                      <input
                                        type="checkbox"
                                        name="detailsNow"
                                        // className="checkbox checkbox-accent"
                                        className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
                                        checked={detailsNow}
                                        onChange={(e) => {
                                          let isChecked = e.target.checked;
                                          setDetailsNow(isChecked);
                                          if (isChecked) {
                                            // setConnectionClick(false);
                                            //setConnectionType('road');
                                          }
                                        }}
                                      />

                                      <span className="label-text ml-2 text-sm">Add {transfer.objType} details now</span>
                                    </div>
                                    <div
                                      className={`${selectedLocationAlgoliaName !== 'Search by airport, property or other' && selectedLocationAlgoliaType !== 'airport' ? 'col-span-3' : 'col-span-3'}`}
                                    >
                                      {/* <label htmlFor="supplier-select" className="text-xs">
                                        Supplier
                                      </label>

                                      <CustomSelect
                                        placeholder={locationRoute === null ? '' : locationRoute.suppliers[0].name}
                                        onChange={console.log('supplier selected')}
                                        options={{
                                          label: locationRoute === null ? '' : locationRoute.suppliers[0].name,
                                          value: locationRoute === null ? '' : locationRoute.suppliers[0].name,
                                        }}
                                        defaultValue={{
                                          label: locationRoute === null ? '' : locationRoute.suppliers[0].name,
                                          value: locationRoute === null ? '' : locationRoute.suppliers[0].name,
                                        }}
                                        instanceId="location-select"
                                        isSearchable={false}
                                        showDropdownIndicator={false}
                                        isDisabled={true}
                                      /> */}
                                      {selectedLocationAlgoliaName !== 'Search by airport, property or other' &&
                                        selectedLocationAlgoliaType !== 'airport' &&
                                        showButtonsRoute &&
                                        selectedRouteIndex !== null &&
                                        detailsNow && (
                                          <div className="col-span-3">
                                            <>
                                              <label htmlFor="time-select" className="text-xs">
                                                {transfer.objType === 'departure' ? 'Drop off' : 'Pick up'} time
                                              </label>
                                              <CustomSelect
                                                placeholder={locationTime}
                                                onChange={handleChangeTime}
                                                options={timeOptions}
                                                defaultValue={{ label: locationTime, value: locationTime }}
                                                instanceId="location-select"
                                                isSearchable={false}
                                                showDropdownIndicator={false}
                                              />
                                            </>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                )}

                                {/* {selectedLocationAlgoliaName !== 'Search by airport, property or other' && showButtonsRoute && selectedRouteIndex !== null && (
                                  <div className={`mt-3 flex items-start ${selectedLocationAlgoliaType === 'airport' ? 'hidden' : ''}`}>
                                    <input
                                      type="checkbox"
                                      name="addLater"
                                      className="checkbox checkbox-accent"
                                      checked={addLater}
                                      onChange={(e) => {
                                        let isChecked = e.target.checked;
                                        setAddLater(isChecked);
                                      }}
                                    />{' '}
                                    <span className="label-text ml-2 text-sm">{transfer.objType === 'departure' ? 'Add drop off time later' : 'Add arrival time later'}</span>
                                  </div>
                                )} */}

                                {selectedLocationAlgoliaType === 'airport' && showButtonsRoute && selectedRouteIndex !== null && detailsNow && (
                                  <>
                                    <div className="text-sm font-semibold pt-6 mb-3">
                                      {transfer.objType === 'departure' ? 'Enter your departure flight details' : 'Enter your arrival flight details'}
                                    </div>

                                    <div className="grid grid-cols-12 gap-4">
                                      <div className="col-span-3">
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Airline
                                        </label>
                                        <CustomSelect
                                          placeholder={airlineNameOwnArrangements === null ? 'Select airline' : airlineNameOwnArrangements}
                                          value={
                                            airlineNameOwnArrangements && airlineUidOwnArrangements
                                              ? { label: airlineNameOwnArrangements, value: airlineUidOwnArrangements }
                                              : { label: 'Select airline', value: null }
                                          }
                                          onChange={(selectedOption) => {
                                            setAirlineNameOwnArrangements(selectedOption.label);
                                            setAirlineUidOwnArrangements(selectedOption.value);
                                          }}
                                          options={airlines
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((airport) => ({
                                              value: airport.uid,
                                              label: airport.name,
                                            }))}
                                          instanceId="airline-select"
                                          isSearchable={true}
                                          showDropdownIndicator={false}
                                        />
                                      </div>
                                      <div className="col-span-3">
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Flight number
                                        </label>
                                        <input
                                          className="w-full rounded-lg text-sm h-[50px] border border-[#7F7F7F] focus:border-2 focus:border-[#1D253F] focus:ring-0 focus:outline-none hover:border-[#1D253F]"
                                          type="text"
                                          placeholder="Flight number"
                                          name="fltNum"
                                          onBlur={handleFltNumInputChange}
                                          defaultValue={fltNum}
                                        />
                                      </div>
                                      <div className="col-span-3">
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Flight date
                                        </label>
                                        <CustomSelect
                                          placeholder={moment(dateFlightOwnArrangements).format('Do MMM YYYY')}
                                          value={dateFlightOwnArrangements ? { label: moment(dateFlightOwnArrangements).format('Do MMM YYYY'), value: dateFlightOwnArrangements } : null}
                                          onChange={handleChangeDateFlightOwnArrangements}
                                          options={[
                                            {
                                              value: transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay,
                                              label: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay).format('Do MMM YYYY'),
                                            },
                                            {
                                              value: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                                                .add(1, 'days')
                                                .format('YYYY-MM-DD'),
                                              label: moment(
                                                moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                                                  .add(1, 'days')
                                                  .format('YYYY-MM-DD'),
                                              ).format('Do MMM YYYY'),
                                            },
                                          ]}
                                          instanceId="flight-date-select"
                                          isSearchable={false}
                                          showDropdownIndicator={false}
                                          menuHeight={80}
                                        />
                                      </div>
                                      <div className="col-span-3">
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Flight {transfer.objType} time
                                        </label>
                                        <CustomSelect
                                          placeholder={timeFlightOwnArrangements}
                                          options={timeOptionsFive}
                                          defaultValue={timeFlightOwnArrangements ? { label: timeFlightOwnArrangements, value: timeFlightOwnArrangements } : null}
                                          onChange={handleChangeFlightTimeOwnArrangements}
                                          instanceId="flight-time-select"
                                          isSearchable={false}
                                          showDropdownIndicator={false}
                                          menuHeight={200}
                                        />
                                      </div>
                                    </div>

                                    {/* <div className="ml-0 mb-3 mt-5 flex items-start">
                                      <input
                                        type="checkbox"
                                        name="addFlightDetailsLater"
                                        className="checkbox checkbox-accent"
                                        checked={addFlightDetailsLater}
                                        onChange={(e) => {
                                          let isChecked = e.target.checked;
                                          setAddFlightDetailsLater(isChecked);
                                          // Set your variable here based on the isChecked value
                                        }}
                                      />

                                      <span className="label-text ml-2 text-sm">Complete details later</span>
                                    </div> */}
                                  </>
                                )}

                                <TransferInfoCard
                                  icon={IoMdInformationCircleOutline}
                                  title="Airstrip nearby!"
                                  text={
                                    !properties[getPropObj(transfer.propUid, properties)].mobileLocation ? (
                                      <p className="">
                                        The closest airstrip {transfer.objType === 'arrival' ? 'to' : 'from'} {properties[getPropObj(transfer.propUid, properties)].name}:{' '}
                                        {getAirportProperties(transfer.propUid, properties).airport.name} ({getAirportProperties(transfer.propUid, properties).airport.code}) -{' '}
                                        {getAirportProperties(transfer.propUid, properties).airport.distance} mins
                                      </p>
                                    ) : (
                                      <p className="">
                                        The closest airstrip {transfer.objType === 'arrival' ? 'to' : 'from'} {properties[getPropObj(transfer.propUid, properties)].name}:{' '}
                                        {getAirportName(airports, getAirportCodeFromUid(airports, checkCustomLocations.airportId))} ({getAirportCodeFromUid(airports, checkCustomLocations.airportId)})
                                        - {checkCustomLocations.airportDistance} mins
                                      </p>
                                    )
                                  }
                                />

                                {(transfer.objType === 'departure' || transfer.objType === 'arrival') && showButtonsRoute && selectedRouteIndex !== null && (
                                  <div className="text-black text-[25px] font-semibold mt-5">Add-on</div>
                                )}

                                <div className="grid grid-cols-12 gap-4 mt-3">
                                  {selectedLocationAlgoliaType === 'airport' &&
                                    showButtonsRoute &&
                                    selectedRouteIndex !== null &&
                                    (() => {
                                      console.log(selectedLocationAlgoliaName);
                                      //const airportCode = airportCode;
                                      console.log(airportCode);
                                      const airportService = processVip(airports, airportCode, transfer, transfer.objType === 'arrival' ? 'arrival' : 'departure');

                                      if (airportService) {
                                        // Set the VIP object in state
                                        //setVipObject(airportService);
                                        vipObject = airportService;

                                        return (
                                          <>
                                            <input type="hidden" name="vipObject" value={JSON.stringify(vipObject)} />
                                            <TransferArrivalDepartureVip
                                              transfer={transfer}
                                              includeArrivalTransferVip={includeArrivalTransferVip}
                                              setIncludeArrivalTransferVip={setIncludeArrivalTransferVip}
                                              includeDepartureTransferVip={includeDepartureTransferVip}
                                              setIncludeDepartureTransferVip={setIncludeDepartureTransferVip}
                                              vipObject={vipObject}
                                              totalPax={flightAdults + flightChildren + flightInfants}
                                              arrivalType={transfer.objType === 'arrival' ? 'Arrival' : 'Departure'}
                                              airportCode={airportCode}
                                              airports={airports}
                                            />
                                          </>
                                        );
                                      }

                                      return null;
                                    })()}
                                  {showButtonsRoute && selectedRouteIndex !== null && (
                                    <PackedLunch
                                      packedLunch={packedLunch}
                                      setPackedLunch={setPackedLunch}
                                      packedLunchChargeable={packedLunchChargeable}
                                      setPackedLunchChargeable={setPackedLunchChargeable}
                                      transfer={transfer}
                                      properties={properties}
                                      booking={booking}
                                      show={true}
                                    />
                                  )}

                                  <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                  <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                </div>
                                <div className="flex mt-5 justify-end gap-4 mb-20">
                                  <button
                                    className="bg-white text-nomadBlue-500 border border-nomadBlue-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadBlue-500 hover:border-nomadBlue-700flex items-center justify-center"
                                    type="button"
                                    onClick={(e) => {
                                      if (!transfer.formValues) {
                                        setSelectedTransferTuuid(null);
                                        onClose();
                                      } else {
                                        closeForm(e, transfer.tuuid, transfers, setTransfers, onClose);
                                      }
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  {showButtonsRoute && selectedRouteIndex !== null && (
                                    <button
                                      className="bg-nomadBlue-800 text-white text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadBlue-700 hover:text-white"
                                      type="submit"
                                    >
                                      Select
                                    </button>
                                  )}
                                </div>
                              </div>
                            </form>

                            {/* Arrive at camp */}
                            <form
                              id="arrivalCamp"
                              className={`${
                                transfer.formValues && transfer.edit && transfer.formValues.type === 'camp' && !selectedContent ? '' : selectedContent === 'arrivalCamp' ? '' : 'hidden'
                              } bg-white rounded-lg ${flash ? 'flash-effect' : ''}`}
                              onSubmit={(e) => handleFormSubmitOwnArrangementsSingle(e, transfers, setTransfers, onClose, bookings, setBookings, properties)}
                            >
                              <input type="hidden" name="formType" value={transfer.objType === 'departure' ? 'departure' : 'arrival'} />
                              <input type="hidden" name="tuuid" value={transfer.tuuid} />
                              <input type="hidden" name="transferType" value={transfer.objType === 'departure' ? 'departure' : 'arrival'} />
                              <input type="hidden" name="locationUid" value={selectedLocationAlgoliaUid} />
                              <input type="hidden" name="locationName" value={selectedLocationAlgoliaName} />
                              <input type="hidden" name="airport" value={selectedLocationAlgoliaType} />
                              <input type="hidden" name="supplierUid" value={supplierUid} />
                              <input type="hidden" name="supplierName" value={supplierName} />
                              {/* <input type="hidden" name="dateOwnArrangements" value={dateOwnArrangements} /> */}
                              <input type="hidden" name="timeOwnArrangements" value={timeOwnArrangements} />

                              {/* <input type="hidden" name="addFlightDetails" value={addFlightDetails} /> */}
                              <input type="hidden" name="airlineUidOwnArrangements" value={airlineUidOwnArrangements} />
                              <input type="hidden" name="airlineNameOwnArrangements" value={airlineNameOwnArrangements} />
                              <input type="hidden" name="dateFlightOwnArrangements" value={dateFlightOwnArrangements} />
                              <input type="hidden" name="timeFlightOwnArrangements" value={timeFlightOwnArrangements} />
                              <input type="hidden" name="addFlightDetailsLater" value={addFlightDetailsLater} />
                              <input type="hidden" name="addLaterOwnArrangements" value={addLaterOwnArrangements} />
                              <input type="hidden" name="fltNum" value={fltNum} />

                              <input type="hidden" name="includeArrivalTransferVip" value={includeArrivalTransferVip} />
                              <input type="hidden" name="includeDepartureTransferVip" value={includeDepartureTransferVip} />
                              <input type="hidden" name="airportCode" value={airportCode} />
                              <input type="hidden" name="totalPax" value={totalPax} />

                              <div>
                                <TransferInfoCard
                                  icon={IoMdInformationCircleOutline}
                                  title="No transfer included!"
                                  text={transfer.objType === 'departure' ? ' Services end when you leave the property' : ' Services start when you arrive at the property '}
                                />
                                <div className="mb-0 mt-10 flex items-start">
                                  <input
                                    type="checkbox"
                                    name="detailsNow"
                                    // className="checkbox checkbox-accent"
                                    className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
                                    checked={detailsNow}
                                    onChange={(e) => {
                                      let isChecked = e.target.checked;
                                      setDetailsNow(isChecked);
                                      if (isChecked) {
                                        // setConnectionClick(false);
                                        //setConnectionType('road');
                                      }
                                    }}
                                  />

                                  <span className="label-text ml-2 text-sm">Add {transfer.objType} details now</span>
                                </div>
                                {detailsNow && transfer.objType === 'arrival' && (
                                  <div className="flex w-full mt-5">
                                    <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                      {/* Close to 5.5 columns  */}
                                      <label htmlFor="supplier-select" className="text-xs">
                                        {transfer.objType === 'departure' ? 'Departure from' : 'Departure from'}
                                      </label>
                                      <InstantSearch indexName="locations" searchClient={searchClient} searchState={searchStateLocation} onSearchStateChange={setSearchStateLocation}>
                                        <Configure filters={searchFiltersOwnArrangements} hitsPerPage={searchFiltersOwnArrangements === '' ? 0 : numHits} />
                                        <HitsLocations />
                                        <VirtualSearchBox />
                                      </InstantSearch>
                                    </div>
                                    <div className="flex-1" style={{ flexBasis: '8.33%' }}>
                                      {' '}
                                      {/* Exactly 1 column */}
                                      <div className="p-4 text-white">
                                        <div className="flex-1 flex items-center justify-center mt-5">
                                          <FaLongArrowAltRight size={30} className="text-[#1D253F]" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                      {' '}
                                      {/* Close to 5.5 columns again */}
                                      <label htmlFor="supplier-select" className="text-xs">
                                        {transfer.objType === 'departure' ? 'Arrival at' : 'Arrival at'}
                                      </label>
                                      <CustomSelect
                                        placeholder={selectedLocationAlgoliaName}
                                        defaultValue={{ label: properties[getPropObj(transfer.propUid, properties)].name, value: properties[getPropObj(transfer.propUid, properties)].name }}
                                        onChange={handleChange}
                                        customComponents={{
                                          Option: (props) => (
                                            <components.Option {...props}>
                                              <CustomOption label={props.data.label} data={props.data} />
                                            </components.Option>
                                          ),
                                        }}
                                        isSearchable={true}
                                        icon={<FaLocationDot size={25} />}
                                        showDropdownIndicator={false}
                                        isDisabled={true}
                                      />
                                    </div>
                                  </div>
                                )}

                                {detailsNow && transfer.objType === 'departure' && (
                                  <>
                                    <div className="flex w-full mt-5">
                                      <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                        {' '}
                                        {/* Close to 5.5 columns again */}
                                        <label htmlFor="supplier-select" className="text-xs">
                                          {transfer.objType === 'departure' ? 'Departure from' : 'Departure from'}
                                        </label>
                                        <CustomSelect
                                          placeholder={selectedLocationAlgoliaName}
                                          defaultValue={{ label: properties[getPropObj(transfer.propUid, properties)].name, value: properties[getPropObj(transfer.propUid, properties)].name }}
                                          onChange={handleChange}
                                          customComponents={{
                                            Option: (props) => (
                                              <components.Option {...props}>
                                                <CustomOption label={props.data.label} data={props.data} />
                                              </components.Option>
                                            ),
                                          }}
                                          isSearchable={true}
                                          icon={<FaLocationDot size={25} />}
                                          showDropdownIndicator={false}
                                          isDisabled={true}
                                          //width={380}
                                        />
                                      </div>
                                      <div className="flex-1" style={{ flexBasis: '8.33%' }}>
                                        {' '}
                                        {/* Exactly 1 column */}
                                        <div className="p-4 text-white">
                                          <div className="flex-1 flex items-center justify-center mt-5">
                                            <FaLongArrowAltRight size={30} className="text-[#1D253F]" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-1" style={{ flexBasis: '45.83%' }}>
                                        {/* Close to 5.5 columns  */}
                                        <label htmlFor="supplier-select" className="text-xs">
                                          {transfer.objType === 'departure' ? 'Arrival at' : 'Arrival at'}
                                        </label>
                                        <InstantSearch indexName="locations" searchClient={searchClient} searchState={searchStateLocation} onSearchStateChange={setSearchStateLocation}>
                                          <Configure filters={searchFiltersOwnArrangements} hitsPerPage={searchFiltersOwnArrangements === '' ? 0 : numHits} />
                                          <HitsLocations />
                                          <VirtualSearchBox />
                                        </InstantSearch>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {detailsNow && selectedLocationAlgoliaName !== 'Search by airport, property or other' && (
                                  <>
                                    <div className="grid grid-cols-12 gap-4">
                                      <div className="col-span-8">
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Transfer by
                                        </label>
                                        <CustomSelect
                                          placeholder={supplierName === null ? 'Select supplier' : supplierName}
                                          onChange={(selectedOption) => {
                                            setSupplierName(selectedOption.label);
                                            setSupplierUid(selectedOption.value);
                                          }}
                                          options={suppliers
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((supplier) => ({
                                              value: supplier.uid,
                                              label: supplier.name,
                                            }))}
                                          value={supplierName && supplierUid ? { label: supplierName, value: supplierUid } : { label: 'Select supplier', value: null }}
                                          instanceId="supplier-select"
                                          isSearchable={true}
                                          showDropdownIndicator={false}
                                        />
                                      </div>
                                      <div className="col-span-4">
                                        <label htmlFor="supplier-select" className="text-xs">
                                          {capitalizeFirstLetter(transfer.objType)} time
                                        </label>

                                        <CustomSelect
                                          placeholder="Select a time"
                                          onChange={handleChangeTimeOwnArrangements}
                                          options={arrivalDepartureTimeOptions}
                                          defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                          instanceId="time-select"
                                          isSearchable={false}
                                          showDropdownIndicator={false}
                                          //width={380}
                                        />
                                      </div>
                                    </div>

                                    {/* <div className={`mt-3 flex items-start ${selectedLocationAlgoliaType === 'airport' ? 'hidden' : ''}`}>
                                      <input
                                        type="checkbox"
                                        name="addLaterOwnArrangements"
                                        className="checkbox checkbox-accent"
                                        checked={addLaterOwnArrangements}
                                        onChange={(e) => {
                                          let isChecked = e.target.checked;
                                          setAddLaterOwnArrangements(isChecked);
                                        }}
                                      />{' '}
                                      <span className="label-text ml-2 text-sm">{transfer.objType === 'departure' ? 'Add departure time later' : 'Add arrival time later'}</span>
                                    </div> */}
                                  </>
                                )}
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-3">{/* Content for first 3 col div */}</div>
                                  <div className="col-span-3">{/* Content for second 3 col div */}</div>
                                  <div className="col-span-3">{/* Content for third 3 col div */}</div>
                                  <div className="col-span-3">{/* Content for fourth 3 col div */}</div>
                                </div>
                                {detailsNow && selectedLocationAlgoliaType === 'airport' && (
                                  <>
                                    <div className="text-sm font-semibold pt-6 mb-3">Enter your flight details in case of delays</div>

                                    <div className="grid grid-cols-12 gap-4">
                                      <div className="col-span-3">
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Airline
                                        </label>
                                        <CustomSelect
                                          placeholder={airlineNameOwnArrangements === null ? 'Select airline' : airlineNameOwnArrangements}
                                          value={
                                            airlineNameOwnArrangements && airlineUidOwnArrangements
                                              ? { label: airlineNameOwnArrangements, value: airlineUidOwnArrangements }
                                              : { label: 'Select airline', value: null }
                                          }
                                          onChange={(selectedOption) => {
                                            setAirlineNameOwnArrangements(selectedOption.label);
                                            setAirlineUidOwnArrangements(selectedOption.value);
                                          }}
                                          options={airlines
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((airport) => ({
                                              value: airport.uid,
                                              label: airport.name,
                                            }))}
                                          instanceId="airline-select"
                                          isSearchable={true}
                                          showDropdownIndicator={false}
                                        />
                                      </div>
                                      <div className="col-span-3">
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Flight number
                                        </label>
                                        <input
                                          className="w-full rounded-lg text-sm h-[50px] border border-[#7F7F7F] focus:border-2 focus:border-[#1D253F] focus:ring-0 focus:outline-none hover:border-[#1D253F]"
                                          type="text"
                                          placeholder="Flight number"
                                          name="fltNum"
                                          onBlur={handleFltNumInputChange}
                                          defaultValue={fltNum}
                                        />
                                      </div>
                                      <div className="col-span-3">
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Flight date
                                        </label>
                                        <CustomSelect
                                          placeholder={moment(dateFlightOwnArrangements).format('Do MMM YYYY')}
                                          value={dateFlightOwnArrangements ? { label: moment(dateFlightOwnArrangements).format('Do MMM YYYY'), value: dateFlightOwnArrangements } : null}
                                          onChange={handleChangeDateFlightOwnArrangements}
                                          options={[
                                            {
                                              value: transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay,
                                              label: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay).format('Do MMM YYYY'),
                                            },
                                            {
                                              value: moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                                                .add(1, 'days')
                                                .format('YYYY-MM-DD'),
                                              label: moment(
                                                moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay)
                                                  .add(1, 'days')
                                                  .format('YYYY-MM-DD'),
                                              ).format('Do MMM YYYY'),
                                            },
                                          ]}
                                          instanceId="flight-date-select"
                                          isSearchable={false}
                                          showDropdownIndicator={false}
                                          menuHeight={80}
                                        />
                                      </div>
                                      <div className="col-span-3">
                                        <label htmlFor="supplier-select" className="text-xs">
                                          Flight {transfer.objType} time
                                        </label>
                                        <CustomSelect
                                          placeholder={timeFlightOwnArrangements}
                                          options={timeOptionsFive}
                                          defaultValue={timeFlightOwnArrangements ? { label: timeFlightOwnArrangements, value: timeFlightOwnArrangements } : null}
                                          onChange={handleChangeFlightTimeOwnArrangements}
                                          instanceId="flight-time-select"
                                          isSearchable={false}
                                          showDropdownIndicator={false}
                                          menuHeight={200}
                                        />
                                      </div>
                                    </div>

                                    {/* <div className="mt-5 mb-3 flex items-start">
                                      <input
                                        type="checkbox"
                                        name="addFlightDetailsLater"
                                        className="checkbox checkbox-accent"
                                        defaultChecked={transfer.formValues && transfer.edit && transfer.formValues.type === 'camp' ? transfer.formValues.addFlightDetailsLater : addFlightDetailsLater}
                                        onChange={(e) => {
                                          let isChecked = e.target.checked;
                                          setAddFlightDetailsLater(isChecked);
                                        }}
                                      />

                                      <span className="label-text ml-2 text-sm">Complete details later</span>
                                    </div> */}
                                    {(transfer.objType === 'departure' || transfer.objType === 'arrival') && <div className="text-black text-[25px] font-semibold mt-5">Add-on</div>}

                                    {/* {transfer.objType === 'departure' && <div className="text-black text-[25px] font-semibold mt-5">Add-on</div>} */}
                                    {/* <div className="text-black text-[25px] font-semibold mt-5">Add-on</div> */}
                                    <div className="grid grid-cols-12 gap-4 mt-3">
                                      {selectedLocationAlgoliaType === 'airport' &&
                                        (() => {
                                          console.log(selectedLocationAlgoliaName);
                                          //const airportCode = airportCode;
                                          console.log(airportCode);
                                          const airportService = processVip(airports, airportCode, transfer, transfer.objType === 'arrival' ? 'arrival' : 'departure');

                                          if (airportService) {
                                            // Set the VIP object in state
                                            //setVipObject(airportService);
                                            vipObject = airportService;

                                            return (
                                              <>
                                                <input type="hidden" name="vipObject" value={JSON.stringify(vipObject)} />
                                                <TransferArrivalDepartureVip
                                                  transfer={transfer}
                                                  includeArrivalTransferVip={includeArrivalTransferVip}
                                                  setIncludeArrivalTransferVip={setIncludeArrivalTransferVip}
                                                  includeDepartureTransferVip={includeDepartureTransferVip}
                                                  setIncludeDepartureTransferVip={setIncludeDepartureTransferVip}
                                                  vipObject={vipObject}
                                                  totalPax={flightAdults + flightChildren + flightInfants}
                                                  arrivalType={transfer.objType === 'arrival' ? 'Arrival' : 'Departure'}
                                                  airportCode={airportCode}
                                                  airports={airports}
                                                />
                                              </>
                                            );
                                          }

                                          return null;
                                        })()}
                                      <PackedLunch
                                        packedLunch={packedLunch}
                                        setPackedLunch={setPackedLunch}
                                        packedLunchChargeable={packedLunchChargeable}
                                        setPackedLunchChargeable={setPackedLunchChargeable}
                                        transfer={transfer}
                                        properties={properties}
                                        booking={booking}
                                        show={true}
                                      />
                                      <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                      <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                    </div>
                                  </>
                                )}
                                <div className="flex mt-5 justify-end gap-4">
                                  <button
                                    className="bg-white text-nomadBlue-500 border border-nomadBlue-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadBlue-500 hover:border-nomadBlue-700 flex items-center justify-center"
                                    type="button"
                                    onClick={(e) => {
                                      if (!transfer.formValues) {
                                        //setSelectedTransferTuuid(null);
                                        onClose();
                                      } else {
                                        closeForm(e, transfer.tuuid, transfers, setTransfers, onClose);
                                      }
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="bg-nomadBlue-800 text-white text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadBlue-700 hover:text-white"
                                    type="submit"
                                  >
                                    Select
                                  </button>
                                </div>
                              </div>
                            </form>

                            {/* Book flight*/}
                            <form
                              id="bookAFlight"
                              className={`${
                                transfer.formValues && transfer.edit && transfer.formValues.type === 'bookFlight' && !selectedContent ? '' : selectedContent === 'bookAFlight' ? '' : 'hidden'
                              }  bg-white rounded-lg`}
                              onSubmit={(e) => handleFormSubmitAirport(e, transfers, setTransfers, onClose, setRefreshTransferRates, bookings, setBookings, properties, customLocations, airports)}
                            >
                              <div>
                                <input type="hidden" name="formType" value={transfer.objType} />
                                <input type="hidden" name="tuuid" value={transfer.tuuid} />
                                <input type="hidden" name="formName" value="bookFlight" />
                                <input type="hidden" name="flightDetails" value={selectedFlight ? JSON.stringify(selectedFlight) : ''} />
                                <input type="hidden" name="flightDetailsSecond" value={selectedFlightSecond ? JSON.stringify(selectedFlightSecond) : ''} />
                                <input type="hidden" name="flightAdults" value={flightAdults} />
                                <input type="hidden" name="flightChildren" value={flightChildren} />
                                <input type="hidden" name="flightInfants" value={flightInfants} />
                                <input type="hidden" name="airlineName" value={airlineName} />
                                <input type="hidden" name="airlineNameSecond" value={airlineNameSecond} />
                                <input type="hidden" name="dateAdjusted" value={dateAdjusted} />
                                <input type="hidden" name="flightPricing" value={JSON.stringify(flightPricing)} />
                                <input type="hidden" name="flightPricingSecond" value={JSON.stringify(flightPricingSecond)} />
                                <input type="hidden" name="connectionType" value={connectionType} />
                                <input type="hidden" name="connectionClick" value={connectionClick} />
                                <input type="hidden" name="locationUid" value={selectedLocationAlgoliaUid} />
                                <input type="hidden" name="locationName" value={selectedLocationAlgoliaName} />
                                <input type="hidden" name="supplierName" value={supplierName} />
                                <input type="hidden" name="supplierUid" value={supplierUid} />
                                <input type="hidden" name="airlineUidFlight" value={airlineUidOwnArrangements} />
                                <input type="hidden" name="airlineNameFlight" value={airlineNameOwnArrangements} />
                                <input type="hidden" name="timeFlightFlight" value={timeFlightOwnArrangements} />
                                <input type="hidden" name="directMultiple" value={selectedDirectMultipleValue} />
                                <input type="hidden" name="detailsNow" value={detailsNow} />
                                <input type="hidden" name="fltNum" value={fltNum} />
                                <input type="hidden" name="includeArrivalTransferVip" value={includeArrivalTransferVip} />
                                <input type="hidden" name="includeDepartureTransferVip" value={includeDepartureTransferVip} />

                                {/* <input type="hidden" name="vipObject" value={JSON.stringify(vipObject)} /> */}
                                <div>
                                  <>
                                    {!isLoading && (
                                      <div className="flex">
                                        {/* {changeFlight && !flightResults && (*/}
                                        <div>
                                          <CustomSelectNoBorderTransfers
                                            placeholder={
                                              <div className="flex items-center">
                                                {selectedDirectMultipleLabel} <LuChevronDown className="ml-2" />
                                              </div>
                                            }
                                            defaultValue={{
                                              label: (
                                                <div className="flex items-center">
                                                  {selectedDirectMultipleLabel} <LuChevronDown className="ml-2" />
                                                </div>
                                              ),
                                              value: selectedDirectMultipleValue,
                                            }}
                                            onChange={(selectedOption, event) => handleDirectMultiple(selectedOption, event)}
                                            options={directMultipleOptions.map((directMultiple) => ({
                                              label: directMultiple.label,
                                              value: directMultiple.value,
                                            }))}
                                            //defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                            instanceId="directMultiple-select"
                                            isSearchable={true}
                                            showDropdownIndicator={false}
                                            width={80}
                                            menuHeight={80}
                                            // color1={'#385157'} // Default color1
                                            // color2={'#E5E7EB'}
                                          />
                                        </div>
                                        {/* )}*/}
                                        {/* {changeFlight && !flightResults && ( */}
                                        <div className="ml-2 mr-4 flex items-center justify-center ">
                                          <input
                                            type="checkbox"
                                            name="classXl"
                                            // className="checkbox checkbox-accent"
                                            className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
                                            checked={classXl}
                                            onChange={(e) => {
                                              let isChecked = e.target.checked;
                                              setClassXl(isChecked);
                                            }}
                                          />

                                          <span className="label-text ml-2 text-sm">XL Fares</span>
                                        </div>
                                        {/* )}*/}

                                        {/* <div
                                          className="ml-auto mr-2 mt-1 text-sm text-cyan-600 cursor-pointer underline"
                                          onClick={(event) => {
                                            console.log('Resetting flights');
                                            // setChangeFlight(true);
                                            // setChangeRoute(true);
                                            // resetFlights();
                                            fetchAirportFilters();
                                            setShowFlightDetails(false);
                                            setSelectedFlightAlgolia('Search by airport');
                                            setSelectedFlightAlgoliaName('Search by airport');
                                            setFlightResults(null);
                                            //handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable);
                                            //setSelectedBtn(index);
                                            setSelectedFlightIndex(null);
                                            setSelectedFlight(null);
                                            setConnectionType(null);
                                            setConnectionClick(null);
                                            // setSelectedTab(index);
                                            setChangeFlight(true);
                                            resetFlights();
                                          }}
                                        >
                                          Route Options
                                        </div> */}
                                      </div>
                                    )}
                                  </>
                                  {transfer.objType === 'arrival' ? (
                                    <div className="flex w-full">
                                      <div className="flex-1" style={{ flexBasis: selectedDirectMultipleValue === 'Direct' ? '45.83%' : '32%' }}>
                                        {/* Close to 5.5 columns  */}

                                        <label htmlFor="supplier-select" className="text-xs">
                                          {transfer.objType === 'departure' ? 'Departure from' : 'Departure from'}
                                        </label>
                                        <InstantSearch indexName="airports" searchClient={searchClient} searchState={searchStateAirport} onSearchStateChange={setSearchStateAirport}>
                                          <Configure filters={airportSearchFilters} hitsPerPage={airportSearchFilters === '' ? 0 : numHits} />

                                          <HitsAirports />
                                          <VirtualSearchBox />
                                        </InstantSearch>
                                      </div>
                                      {selectedDirectMultipleValue === 'Direct' ? (
                                        <div className="flex-1" style={{ flexBasis: '8.33%' }}>
                                          {' '}
                                          {/* Exactly 1 column */}
                                          <div className="p-4 text-white">
                                            <div className="flex-1 flex items-center justify-center mt-5">
                                              <FaLongArrowAltRight size={30} className="text-[#1D253F]" />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="flex" style={{ width: '36%' }}>
                                          <div className="flex-1" style={{ width: '7%' }}>
                                            {/* Content here */}
                                          </div>
                                          <div className="flex-1" style={{ flexBasis: '86%' }}>
                                            {' '}
                                            {/* Close to 5.5 columns again */}
                                            <label htmlFor="connecting-select" className="text-xs">
                                              Connecting Airport
                                            </label>
                                            <CustomSelect
                                              placeholder={selectedConnectingLabel}
                                              defaultValue={{
                                                label: selectedConnectingLabel,
                                                value: selectedConnectingValue,
                                              }}
                                              options={connectingAirportsDropDownOptions}
                                              onChange={(event) => {
                                                setConnectingAirport(true);
                                                console.log('event', event);
                                                setConnectingAirportUid(event.uid);
                                                setSelectedConnectingValue(event.value);
                                                setSelectedConnectingLabel(event.label);
                                                handleChangeBookFlight(event, true);
                                              }}
                                              customComponents={{
                                                Option: (props) => (
                                                  <components.Option {...props}>
                                                    <CustomOptionAirports label={props.data.label} data={props.data} />
                                                  </components.Option>
                                                ),
                                              }}
                                              isSearchable={true}
                                              icon={<FaLocationDot size={25} />} // Pass the icon as a React component
                                              showDropdownIndicator={false}
                                              isDisabled={flightResultsSecond.length > 0 || isLoading ? true : false}
                                              //width={380}
                                            />
                                          </div>
                                          <div className="flex-1" style={{ width: '7%' }}>
                                            {/* Content here */}
                                          </div>
                                        </div>
                                      )}
                                      <div className="flex-1" style={{ flexBasis: selectedDirectMultipleValue === 'Direct' ? '45.83%' : '32%' }}>
                                        {' '}
                                        {/* Close to 5.5 columns again */}
                                        <label htmlFor="supplier-select" className="text-xs">
                                          {transfer.objType === 'departure' ? 'Arrival at' : 'Arrival at'}
                                        </label>
                                        <CustomSelect
                                          placeholder={selectedLocationAlgoliaName}
                                          defaultValue={{
                                            label: !properties[getPropObj(transfer.propUid, properties)].mobileLocation
                                              ? getAirportProperties(transfer.propUid, properties).airport.name
                                              : getAirportName(airports, getAirportCodeFromUid(airports, checkCustomLocations.airportId)),
                                            value: !properties[getPropObj(transfer.propUid, properties)].mobileLocation
                                              ? getAirportProperties(transfer.propUid, properties).airport.name
                                              : getAirportName(airports, getAirportCodeFromUid(airports, checkCustomLocations.airportId)),
                                          }}
                                          onChange={handleChange}
                                          customComponents={{
                                            Option: (props) => (
                                              <components.Option {...props}>
                                                <CustomOption label={props.data.label} data={props.data} />
                                              </components.Option>
                                            ),
                                          }}
                                          isSearchable={true}
                                          icon={<FaLocationDot size={25} />}
                                          showDropdownIndicator={false}
                                          isDisabled={true}
                                          //width={380}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="flex w-full">
                                      <div className="flex-1" style={{ flexBasis: selectedDirectMultipleValue === 'Direct' ? '45.83%' : '32%' }}>
                                        {/* Close to 5.5 columns  */}
                                        <label htmlFor="supplier-select" className="text-xs">
                                          {transfer.objType === 'departure' ? 'Departure from' : 'Departure from'}
                                        </label>
                                        <CustomSelect
                                          placeholder={selectedLocationAlgoliaName}
                                          defaultValue={{
                                            label: !properties[getPropObj(transfer.propUid, properties)].mobileLocation
                                              ? getAirportProperties(transfer.propUid, properties).airport.name
                                              : getAirportName(airports, getAirportCodeFromUid(airports, checkCustomLocations.airportId)),
                                            value: !properties[getPropObj(transfer.propUid, properties)].mobileLocation
                                              ? getAirportProperties(transfer.propUid, properties).airport.name
                                              : getAirportName(airports, getAirportCodeFromUid(airports, checkCustomLocations.airportId)),
                                          }}
                                          onChange={handleChange}
                                          customComponents={{
                                            Option: (props) => (
                                              <components.Option {...props}>
                                                <CustomOption label={props.data.label} data={props.data} />
                                              </components.Option>
                                            ),
                                          }}
                                          isSearchable={true}
                                          icon={<FaLocationDot size={25} />}
                                          showDropdownIndicator={false}
                                          isDisabled={true}
                                          //width={380}
                                        />
                                      </div>
                                      {selectedDirectMultipleValue === 'Direct' ? (
                                        <div className="flex-1" style={{ flexBasis: '8.33%' }}>
                                          {' '}
                                          {/* Exactly 1 column */}
                                          <div className="p-4 text-white">
                                            <div className="flex-1 flex items-center justify-center mt-5">
                                              <FaLongArrowAltRight size={30} className="text-[#1D253F]" />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="flex" style={{ width: '36%' }}>
                                          <div className="flex-1" style={{ width: '7%' }}>
                                            {/* Content here */}
                                          </div>
                                          <div className="flex-1" style={{ flexBasis: '86%' }}>
                                            {' '}
                                            {/* Close to 5.5 columns again */}
                                            <label htmlFor="connecting-select" className="text-xs">
                                              Connecting Airport
                                            </label>
                                            <CustomSelect
                                              placeholder={selectedConnectingLabel}
                                              defaultValue={{
                                                label: selectedConnectingLabel,
                                                value: selectedConnectingValue,
                                              }}
                                              options={connectingAirportsDropDownOptions}
                                              onChange={(event) => {
                                                setConnectingAirport(true);
                                                console.log('event', event);
                                                setConnectingAirportUid(event.uid);
                                                setSelectedConnectingValue(event.value);
                                                setSelectedConnectingLabel(event.label);
                                                handleChangeBookFlight(event, true);
                                              }}
                                              customComponents={{
                                                Option: (props) => (
                                                  <components.Option {...props}>
                                                    <CustomOptionAirports label={props.data.label} data={props.data} />
                                                  </components.Option>
                                                ),
                                              }}
                                              isSearchable={true}
                                              icon={<FaLocationDot size={25} />} // Pass the icon as a React component
                                              showDropdownIndicator={false}
                                              isDisabled={flightResultsSecond.length > 0 || isLoading ? true : false}
                                              //width={380}
                                            />
                                          </div>
                                          <div className="flex-1" style={{ width: '7%' }}>
                                            {/* Content here */}
                                          </div>
                                        </div>
                                      )}
                                      <div className="flex-1" style={{ flexBasis: selectedDirectMultipleValue === 'Direct' ? '45.83%' : '32%' }}>
                                        {' '}
                                        {/* Close to 5.5 columns again */}
                                        <label htmlFor="supplier-select" className="text-xs">
                                          {transfer.objType === 'departure' ? 'Arrival at' : 'Arrival at'}
                                        </label>
                                        <InstantSearch indexName="airports" searchClient={searchClient} searchState={searchStateAirport} onSearchStateChange={setSearchStateAirport}>
                                          <Configure filters={airportSearchFilters} hitsPerPage={airportSearchFilters === '' ? 0 : numHits} />

                                          <HitsAirports />
                                          <VirtualSearchBox />
                                        </InstantSearch>
                                      </div>
                                    </div>
                                  )}

                                  {searchAgain && (
                                    <div className="w-56 m-3 bg-white">
                                      <p className="text-sm text-white mb-2"> - </p>
                                      {(async () => {
                                        console.log('searchAgain triggered');
                                        setSearchAgain(false);

                                        let flightToSearch = airports.find((airport) => airport.uid === airportId);
                                        let flightToSearchSecond = airports.find((airport) => airport.code === selectedFlightAlgolia[0]);

                                        if (connectingAirport && flightToSearchAirport !== null) {
                                          //setConnectingAirport(false);
                                          flightToSearch = airports.find((airport) => airport.uid === connectingAirportUid);

                                          //setConnectingAirportCode
                                        }

                                        console.log('flightToSearch', flightToSearch);
                                        const flightToSearchCode = flightToSearch.code;
                                        if (transfer.objType === 'arrival' && flightToSearchAirport === null && selectedDirectMultipleValue === 'Direct') {
                                          await handleOptionClickGetFlights(selectedFlightAlgolia, flightToSearchCode, true);
                                          setFlightToSearchAirport(flightToSearch);
                                          console.log('flightToSearch');
                                        } else if (transfer.objType === 'departure' && flightToSearchAirport === null && selectedDirectMultipleValue === 'Direct') {
                                          await handleOptionClickGetFlights(flightToSearchCode, selectedFlightAlgolia, true);
                                          setFlightToSearchAirport(flightToSearchSecond);

                                          console.log('depFlightToSearch');
                                        } else {
                                          if (transfer.objType === 'arrival' && connectingAirportUid !== null) {
                                            if (!flightResults) {
                                              flightToSearch = airports.find((airport) => airport.code === selectedFlightAlgolia);
                                              flightToSearchSecond = airports.find((airport) => airport.uid === connectingAirportUid);
                                              await handleOptionClickGetFlights(flightToSearch.code, flightToSearchSecond.code, true);
                                              setFlightToSearchAirport(flightToSearchSecond);
                                            } else {
                                              flightToSearch = airports.find((airport) => airport.uid === connectingAirportUid);
                                              flightToSearchSecond = airports.find((airport) => airport.uid === airportId);
                                              await handleOptionClickGetFlights(flightToSearch.code, flightToSearchSecond.code, true);
                                              setFlightToSearchAirport(flightToSearch);
                                            }
                                          } else if (transfer.objType === 'departure' && connectingAirportUid !== null) {
                                            if (!flightResults) {
                                              flightToSearch = airports.find((airport) => airport.uid === airportId);
                                              flightToSearchSecond = airports.find((airport) => airport.uid === connectingAirportUid);
                                              await handleOptionClickGetFlights(flightToSearch.code, flightToSearchSecond.code, true);
                                              setFlightToSearchAirport(flightToSearchSecond);
                                              console.log('selectedFlightAlgolia DEBUG 0');
                                            } else {
                                              flightToSearch = airports.find((airport) => airport.uid === connectingAirportUid);
                                              console.log('selectedFlightAlgolia DEBUG', selectedFlightAlgolia);
                                              flightToSearchSecond = airports.find((airport) => airport.code === selectedFlightAlgolia);
                                              await handleOptionClickGetFlights(flightToSearch.code, flightToSearchSecond.code, true);
                                              setFlightToSearchAirport(flightToSearch);
                                            }
                                          }
                                        }
                                      })()}
                                    </div>
                                  )}

                                  <div className="flex flex-wrap mb-3 mt-5 w-full">
                                    <>
                                      {isLoading ? (
                                        <div className="flex items-center justify-center w-full">
                                          <div className="flex items-center">
                                            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 50 50">
                                              <circle
                                                className="path"
                                                cx="25"
                                                cy="25"
                                                r="20"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="5"
                                                strokeLinecap="round"
                                                strokeDasharray="30, 70"
                                                strokeDashoffset="0"
                                              />
                                            </svg>
                                            <div className="text-sm animate-pulse">Getting flights...</div>
                                          </div>
                                        </div>
                                      ) : flightResults && flightResults.length > 0 ? (
                                        <div className="w-full">
                                          <FlightResultsComponent
                                            flightResults={flightResults}
                                            selectedFlightIndex={selectedFlightIndex}
                                            selectedFlight={selectedFlight}
                                            showButtons={showButtons}
                                            currentPages={currentPages}
                                            buttonsToShow={buttonsToShow}
                                            airports={airports}
                                            flightToSearchAirport={transfer.objType === 'arrival' ? flightToSearchAirport : airports.find((airport) => airport.code === selectedFlightAlgolia)}
                                            //flightToSearchAirport={flightToSearchAirport}
                                            localSelectedIndices={localSelectedIndices}
                                            setLocalSelectedIndices={setLocalSelectedIndices}
                                            setLocalSelectedIndex={setSelectedFlightIndex}
                                            handleButtonClick={handleButtonClick}
                                            connectingAirport={connectingAirport}
                                            airportId={airportId}
                                            connectingAirportUid={connectingAirportUid}
                                            transfer={transfer}
                                            handleOptionClickGetFlights={handleOptionClickGetFlights}
                                            flightCost={flightCost}
                                            airlineName={airlineName}
                                            flightPricing={flightPricing}
                                            setSelectedFlightIndex={setSelectedFlightIndex}
                                            setShowButtons={setShowButtons}
                                            searchAgain={searchAgain}
                                            properties={properties}
                                            secondFlight={false}
                                            flightResultsSecond={flightResultsSecond}
                                            changeFlight={changeFlight}
                                            resetSearch={resetSearch}
                                            firstFlight={true}
                                            selectedFlightFirst={selectedFlight}
                                            selectedFlightSecond={selectedFlightSecond}
                                            setHasSufficientTime={setHasSufficientTime}
                                          />
                                          {flightResultsSecond &&
                                            flightResultsSecond.length > 0 &&
                                            (() => {
                                              let flightToSearchSecond = airports.find((airport) => airport.uid === airportId);
                                              return (
                                                <FlightResultsComponent
                                                  flightResults={flightResultsSecond}
                                                  selectedFlightIndex={selectedFlightIndexSecond}
                                                  selectedFlight={selectedFlightSecond}
                                                  showButtons={showButtonsSecond}
                                                  currentPages={currentPagesSecond}
                                                  buttonsToShow={buttonsToShow}
                                                  airports={airports}
                                                  flightToSearchAirport={flightToSearchSecond}
                                                  localSelectedIndices={localSelectedIndicesSecond}
                                                  setLocalSelectedIndices={setLocalSelectedIndicesSecond}
                                                  setLocalSelectedIndex={setLocalSelectedIndexSecond}
                                                  handleButtonClick={handleButtonClick}
                                                  connectingAirport={connectingAirport}
                                                  airportId={airportId}
                                                  connectingAirportUid={connectingAirportUid}
                                                  transfer={transfer}
                                                  handleOptionClickGetFlights={handleOptionClickGetFlights}
                                                  flightCost={flightCostSecond}
                                                  airlineName={airlineNameSecond}
                                                  flightPricing={flightPricingSecond}
                                                  setSelectedFlightIndex={setSelectedFlightIndexSecond}
                                                  setShowButtons={setShowButtonsSecond}
                                                  properties={properties}
                                                  secondFlight={true}
                                                  flightResultsSecond={flightResultsSecond}
                                                  changeFlight={changeFlight}
                                                  resetSearch={resetSearch}
                                                  firstFlight={false}
                                                  selectedFlightFirst={selectedFlight}
                                                  selectedFlightSecond={selectedFlightSecond}
                                                  setHasSufficientTime={setHasSufficientTime}
                                                />
                                              );
                                            })()}

                                          {selectedDirectMultipleValue === 'Multiple' && flightResultsSecond.length === 0 ? (
                                            <TransferInfoCard
                                              icon={IoMdInformationCircleOutline}
                                              title="No flights Found"
                                              text={'Try searching to another airport, or choose a multi-sector connection'}
                                            />
                                          ) : selectedDirectMultipleValue === 'Multiple' && !flightResultsSecond ? (
                                            <div className="mt-5 text-sm mb-3">
                                              <span className="">Select first flight to search for second flight</span>
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : flightResults.length === 0 ? (
                                        <TransferInfoCard icon={IoMdInformationCircleOutline} title="No flights Found" text={'Try searching to another airport, or choose a multi-sector connection'} />
                                      ) : (
                                        <div className="text-sm mt-5">
                                          {selectedFlightAlgolia === 'Search by airport' ? 'Select an airport to search flights' : 'Select an airport to search flights'}
                                        </div>
                                      )}
                                    </>
                                  </div>
                                  {((selectedDirectMultipleValue === 'Direct' && !searchAgain && !isLoading && flightResults !== null && flightResults.length > 0) ||
                                    (selectedDirectMultipleValue === 'Multiple' &&
                                      !searchAgain &&
                                      !isLoading &&
                                      flightResults !== null &&
                                      flightResults.length > 0 &&
                                      flightResultsSecond !== null &&
                                      flightResultsSecond.length > 0)) &&
                                    (() => {
                                      const getSelectedOuterIndex = (selectedFlightIndex, flightResults) => {
                                        let accumulatedCount = 0;
                                        for (let i = 0; i < flightResults.length; i++) {
                                          accumulatedCount += flightResults[i].flight.length;
                                          if (selectedFlightIndex < accumulatedCount) {
                                            return i;
                                          }
                                        }
                                        return null;
                                      };

                                      const getSelectedOuterIndexSecond = (selectedFlightIndex, flightResults) => {
                                        if (flightResults.length > 0) {
                                          let accumulatedCount = 0;
                                          for (let i = 0; i < flightResults.length; i++) {
                                            accumulatedCount += flightResults[i].flight.length;
                                            if (selectedFlightIndex < accumulatedCount) {
                                              return i;
                                            }
                                          }
                                          return null;
                                        } else {
                                          return null;
                                        }
                                      };

                                      const selectedOuterIndex = transfer.formValues?.flightDetails && !changeRoute ? 1 : getSelectedOuterIndex(selectedFlightIndex, flightResults);
                                      const flightResultsLength = transfer.formValues?.flightDetails && !changeRoute ? 1 : flightResults[selectedOuterIndex].flight.length;

                                      const selectedOuterIndexSecond =
                                        transfer.formValues?.flightDetailsSecond && !changeRoute ? 1 : getSelectedOuterIndexSecond(selectedFlightIndexSecond, flightResultsSecond);
                                      let flightResultsLengthSecond;
                                      if (selectedDirectMultipleValue === 'Multiple') {
                                        flightResultsLengthSecond = transfer.formValues?.flightDetailsSecond && !changeRoute ? 1 : flightResultsSecond[selectedOuterIndexSecond].flight.length;
                                      }
                                      return selectedDirectMultipleValue === 'Direct'
                                        ? //  ? selectedFlightIndex !== null && selectedOuterIndex !== null && selectedFlightIndex < flightResultsLength
                                          selectedFlightIndex !== null && selectedOuterIndex !== null
                                        : selectedFlightIndex !== null &&
                                            selectedOuterIndex !== null &&
                                            //selectedFlightIndex < flightResultsLength &&
                                            selectedFlightIndexSecond !== null &&
                                            selectedOuterIndexSecond !== null;
                                      // &&
                                      // selectedFlightIndexSecond < flightResultsLengthSecond
                                    })() && (
                                      <div>
                                        <div className="mb-0 mt-10 flex items-start">
                                          <input
                                            type="checkbox"
                                            name="detailsNow"
                                            // className="checkbox checkbox-accent"
                                            className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
                                            checked={detailsNow}
                                            onChange={(e) => {
                                              let isChecked = e.target.checked;
                                              setDetailsNow(isChecked);
                                              if (isChecked) {
                                                // setConnectionClick(false);
                                                //setConnectionType('road');
                                              }
                                            }}
                                          />

                                          <span className="label-text ml-2 text-sm">Add {transfer.objType} details now</span>
                                        </div>
                                        {flightPage === 1 && detailsNow && (
                                          <>
                                            <div className="text-black text-sm font-semibold mt-5 mb-5">
                                              <span className="">{transfer.objType === 'arrival' ? 'How will you arrive at the airport?' : 'Where are you connecting to?'}</span>
                                            </div>
                                            <div className="text-sm mb-5 flex items-center">
                                              <div className="flex items-center">
                                                <input
                                                  type="radio"
                                                  name="connectionType"
                                                  value="true"
                                                  className="form-radio bg-white"
                                                  onChange={(e) => {
                                                    console.log(e.target.value);
                                                    setConnectionClick(e.target.value === 'true');
                                                    setConnectionType('flight');
                                                  }}
                                                  //checked={connectionClick === true}
                                                  checked={connectionType === 'flight'}
                                                  required
                                                />
                                                <span className="label-text ml-2 text-sm">{transfer.objType === 'arrival' ? 'Inbound' : 'Outbound'} flight</span>
                                              </div>
                                              <div className="ml-3 flex items-center">
                                                <input
                                                  type="radio"
                                                  name="connectionType"
                                                  value="false"
                                                  className="form-radio bg-white"
                                                  onChange={(e) => {
                                                    const handleOnChange = async () => {
                                                      console.log(e.target.value);
                                                      setConnectionClick(e.target.value === 'true');
                                                      // LOAD LOCATIONS
                                                      const flightFiltersLoad = await loadFlightLocations(
                                                        selectedDirectMultipleValue === 'Direct' ? selectedFlight : selectedFlightSecond,
                                                        transfer.objType,
                                                        airports,
                                                      );
                                                      const airportParkFilters = flightFiltersLoad.map((id) => `park:${id}`).join(' OR ');
                                                      setFlightLocations(airportParkFilters);

                                                      setConnectionType('road');
                                                    };
                                                    handleOnChange();
                                                  }}
                                                  //checked={connectionClick === false}
                                                  checked={connectionType === 'road'}
                                                  required
                                                />
                                                <span className="label-text ml-2 text-sm">Road transfer</span>
                                              </div>
                                            </div>

                                            {connectionType === 'flight' && detailsNow && (
                                              <>
                                                <div className="grid grid-cols-12 gap-4">
                                                  <div className="col-span-3">
                                                    <label htmlFor="supplier-select" className="text-xs">
                                                      Airline
                                                    </label>
                                                    <CustomSelect
                                                      placeholder={airlineNameOwnArrangements === null ? 'Select airline' : airlineNameOwnArrangements}
                                                      // value={supplierName && supplierUid ? { label: supplierName, value: supplierUid } : { label: 'Select supplier', value: null }}
                                                      value={
                                                        airlineNameOwnArrangements && airlineUidOwnArrangements
                                                          ? { label: airlineNameOwnArrangements, value: airlineUidOwnArrangements }
                                                          : { label: 'Select airline', value: null }
                                                      }
                                                      onChange={(selectedOption) => {
                                                        setAirlineNameOwnArrangements(selectedOption.label);
                                                        setAirlineUidOwnArrangements(selectedOption.value);
                                                      }}
                                                      options={airlines
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((airport) => ({
                                                          value: airport.uid,
                                                          label: airport.name,
                                                        }))}
                                                      //defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                                      instanceId="airline-select"
                                                      isSearchable={true}
                                                      showDropdownIndicator={false}
                                                      // width={180}
                                                    />
                                                  </div>
                                                  <div className="col-span-3">
                                                    <label htmlFor="supplier-select" className="text-xs">
                                                      Flight number
                                                    </label>
                                                    {/* <input className="w-44 rounded-lg border-2 border-[#2A99A3] text-sm h-10" type="text" placeholder="Flight number" name="fltNum" value={fltNum} onChange={handleFltNumInputChange} /> */}
                                                    <input
                                                      className="w-full rounded-lg text-sm h-[50px] border border-[#7F7F7F] focus:border-2 focus:border-[#1D253F] focus:ring-0 focus:outline-none hover:border-[#1D253F]"
                                                      type="text"
                                                      placeholder="Flight number"
                                                      name="fltNum"
                                                      //value={fltNum}
                                                      onBlur={handleFltNumInputChange}
                                                      defaultValue={fltNum}
                                                    />
                                                  </div>

                                                  <div className="col-span-3">
                                                    <label htmlFor="supplier-select" className="text-xs">
                                                      Flight {transfer.objType} time
                                                    </label>
                                                    <CustomSelect
                                                      placeholder={timeFlightOwnArrangements}
                                                      options={timeOptionsFive}
                                                      defaultValue={timeFlightOwnArrangements ? { label: timeFlightOwnArrangements, value: timeFlightOwnArrangements } : null}
                                                      onChange={handleChangeFlightTimeOwnArrangements}
                                                      instanceId="flight-time-select"
                                                      isSearchable={false}
                                                      showDropdownIndicator={false}
                                                      menuHeight={200}
                                                    />
                                                  </div>
                                                  <div className="col-span-3"></div>
                                                </div>
                                                {/* <div className="mb-3 mt-5 flex items-start">
                                                  <input
                                                    type="checkbox"
                                                    name="addFlightDetailsLater"
                                                    className="checkbox checkbox-accent"
                                                    checked={addFlightDetailsLater}
                                                    onChange={(e) => {
                                                      let isChecked = e.target.checked;
                                                      setAddFlightDetailsLater(isChecked);
                                                    }}
                                                  />

                                                  <span className="label-text ml-2 text-sm">Complete details later</span>
                                                </div> */}
                                              </>
                                            )}
                                          </>
                                        )}

                                        {connectionType === 'road' && detailsNow && (
                                          <>
                                            <div className="grid grid-cols-12 gap-4">
                                              <div className="col-span-6">
                                                <label htmlFor="supplier-select" className="text-xs">
                                                  Transfer by
                                                </label>
                                                <CustomSelect
                                                  placeholder={supplierName === null ? 'Select supplier' : supplierName}
                                                  value={supplierName && supplierUid ? { label: supplierName, value: supplierUid } : { label: 'Select supplier', value: null }}
                                                  onChange={(selectedOption) => {
                                                    setSupplierName(selectedOption.label);
                                                    setSupplierUid(selectedOption.value);
                                                  }}
                                                  options={suppliers
                                                    .sort((a, b) => a.name.localeCompare(b.name))
                                                    .map((supplier) => ({
                                                      value: supplier.uid,
                                                      label: supplier.name,
                                                    }))}
                                                  instanceId="supplier-select"
                                                  isSearchable={true}
                                                  showDropdownIndicator={false}
                                                />
                                              </div>
                                              <div className="col-span-6">
                                                <label htmlFor="locations-select" className="text-xs">
                                                  {transfer.objType === 'departure' ? 'To' : 'From'} Location
                                                </label>
                                                <InstantSearch indexName="locations" searchClient={searchClient} searchState={searchStateLocation} onSearchStateChange={setSearchStateLocation}>
                                                  <Configure filters={flightLocations} hitsPerPage={flightLocations === '' ? 0 : numHits} />
                                                  <HitsLocationsBookFlight />
                                                  <VirtualSearchBox />
                                                </InstantSearch>
                                              </div>
                                            </div>

                                            {/* <div className="mb-3 mt-5 flex items-start">
                                              <input
                                                type="checkbox"
                                                name="addFlightDetailsLater"
                                                className="checkbox checkbox-accent"
                                                checked={addFlightDetailsLater}
                                                onChange={(e) => {
                                                  let isChecked = e.target.checked;
                                                  setAddFlightDetailsLater(isChecked);
                                                }}
                                              />

                                              <span className="label-text ml-2 text-sm">Complete details later</span>
                                            </div> */}
                                          </>
                                        )}

                                        {!searchAgain && ((selectedDirectMultipleValue === 'Direct' && selectedFlight) || (selectedDirectMultipleValue === 'Multiple' && selectedFlightSecond)) && (
                                          <div>
                                            <div className="text-black text-[25px] font-semibold mt-10">Add-on</div>

                                            <div className="grid grid-cols-12 gap-4 mt-3">
                                              <TransferArrivalDeparture
                                                objType={transfer.objType}
                                                properties={properties}
                                                transfer={transfer}
                                                includeArrivalTransfer={includeArrivalTransfer}
                                                setIncludeArrivalTransfer={setIncludeArrivalTransfer}
                                                includeDepartureTransfer={includeDepartureTransfer}
                                                setIncludeDepartureTransfer={setIncludeDepartureTransfer}
                                              />

                                              {(() => {
                                                let getFlight;
                                                let airportCode;
                                                //getFlight = transfer.objType === 'arrival' ? (selectedDirectMultipleValue === 'Direct' ? selectedFlight : selectedFlightSecond) : selectedFlight;

                                                // selectedFlight issue
                                                if (transfer.objType === 'arrival') {
                                                  getFlight = selectedFlight;
                                                  airportCode = getFlight.fromcode;
                                                } else if (transfer.objType === 'departure') {
                                                  getFlight = selectedFlight;
                                                  airportCode = getFlight.fromcode;
                                                }
                                                //airportCode = transfer.objType === 'arrival' || transfer.objType === 'propertyTransfer' ? getFlight.tocode : getFlight.fromcode;
                                                const airportService = processVip(airports, airportCode, transfer, transfer.objType === 'arrival' ? 'arrival' : 'departure');

                                                if (airportService) {
                                                  // Set the VIP object in state
                                                  //setVipObject(airportService);
                                                  vipObject = airportService;

                                                  return (
                                                    <>
                                                      <input type="hidden" name="vipObject" value={JSON.stringify(vipObject)} />
                                                      <TransferArrivalDepartureVip
                                                        transfer={transfer}
                                                        includeArrivalTransferVip={includeArrivalTransferVip}
                                                        setIncludeArrivalTransferVip={setIncludeArrivalTransferVip}
                                                        includeDepartureTransferVip={includeDepartureTransferVip}
                                                        setIncludeDepartureTransferVip={setIncludeDepartureTransferVip}
                                                        vipObject={vipObject}
                                                        totalPax={flightAdults + flightChildren + flightInfants}
                                                        arrivalType={transfer.objType === 'arrival' ? 'Arrival' : 'Departure'}
                                                        airportCode={airportCode}
                                                        airports={airports}
                                                      />
                                                    </>
                                                  );
                                                }

                                                return null;
                                              })()}
                                              <PackedLunch
                                                packedLunch={packedLunch}
                                                setPackedLunch={setPackedLunch}
                                                packedLunchChargeable={packedLunchChargeable}
                                                setPackedLunchChargeable={setPackedLunchChargeable}
                                                transfer={transfer}
                                                properties={properties}
                                                booking={booking}
                                                show={true}
                                              />
                                              <div className="col-span-4 flex flex-col items-center justify-between p-4"></div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                </div>

                                <div className="flex">
                                  <div className="my-5 ml-auto mb-10">
                                    {flightPage === 1 && (
                                      <button
                                        className="bg-white text-nomadBlue-500 border border-nomadBlue-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadBlue-500 hover:border-nomadBlue-700flex items-center justify-center"
                                        type="button"
                                        disabled={searchAgain}
                                        onClick={(e) => {
                                          if (!transfer.formValues) {
                                            onClose();
                                          } else {
                                            closeForm(e, transfer.tuuid, transfers, setTransfers, onClose);
                                          }
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    )}
                                    {showButtons &&
                                      flightResults &&
                                      flightResults.length > 0 &&
                                      flightPage === 1 &&
                                      ((selectedDirectMultipleValue === 'Direct' && selectedFlight) || (selectedDirectMultipleValue === 'Multiple' && selectedFlightSecond)) && (
                                        <button
                                          className="ml-3 bg-nomadBlue-800 text-white text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadBlue-700 hover:text-white"
                                          type="submit"
                                          disabled={searchAgain}
                                          onClick={(e) => {
                                            if (hasSufficientTime) {
                                              handleConfirmClickSubmitFlight(e);
                                            } else {
                                              e.preventDefault();
                                              toast.error('Select flights with at least 10 minutes between flights');
                                            }
                                          }}
                                        >
                                          Select
                                        </button>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function TransferSlideOutSingle({
  isOpen,
  onClose,
  transfer,
  deeShadow,
  SlPlane,
  timeRef,
  MdOutlineLocationOn,
  FaCampground,
  CgUnavailable,
  BiTransfer,
  setSelectedTransferTuuid,
  airports,
  transfers,
  properties,
  freshUuid,
  locations,
  customLocations,
  setTransfers,
  setIsModalOpen,
  setRefreshTransferRates,
  bookings,
  setBookings,
  booking,
  agentObject,
  suppliers,
  airlines,
  createEdit,
  createVersion,
  transfersDataFlights,
  user,
  advancedMode,
}) {
  const hasMatchingTransfer = (transfers, tuuid) => {
    return transfers.some((transfer) => transfer.tuuid === tuuid && transfer.edit === true);
  };
  //if (!transfer || !bookings || bookings.length === 0 || (transfer.formValues && !transfer.edit)) {
  if (!transfer || !transfer.edit || !hasMatchingTransfer(transfers, transfer.tuuid)) {
    // If the required data is not available, don't render the slide-out
    console.log('no transfer');
    return null;
  }
  // else if (!isOpen) {
  //   return null;
  // }

  console.log('bookings TEST');
  console.log(JSON.stringify(bookings, undefined, 4));
  // if (transfer.edit) {
  return (
    <TransferSlideOutContent
      isOpen={isOpen}
      onClose={onClose}
      transfer={transfer}
      deeShadow={deeShadow}
      SlPlane={SlPlane}
      timeRef={timeRef}
      MdOutlineLocationOn={MdOutlineLocationOn}
      FaCampground={FaCampground}
      CgUnavailable={CgUnavailable}
      BiTransfer={BiTransfer}
      setSelectedTransferTuuid={setSelectedTransferTuuid}
      airports={airports}
      transfers={transfers}
      properties={properties}
      freshUuid={freshUuid}
      locations={locations}
      customLocations={customLocations}
      setTransfers={setTransfers}
      setIsModalOpen={setIsModalOpen}
      setRefreshTransferRates={setRefreshTransferRates}
      bookings={bookings}
      setBookings={setBookings}
      booking={booking}
      agentObject={agentObject}
      suppliers={suppliers}
      airlines={airlines}
      createEdit={createEdit}
      createVersion={createVersion}
      transfersDataFlights={transfersDataFlights}
      user={user}
      advancedMode={advancedMode}
    />
  );
  // }
}

export default TransferSlideOutSingle;
