import { FaEdit, FaPlusCircle, FaSave, FaTrashAlt, FaWindowClose } from 'react-icons/fa';
import { FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { IoIosArrowDown, IoIosArrowDropdownCircle, IoIosArrowDropupCircle, IoIosArrowUp, IoMdWarning } from 'react-icons/io';
import { MdConnectingAirports, MdContentCopy, MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useRef, useState } from 'react';
import { TbArrowsRandom, TbBuildingWarehouse } from 'react-icons/tb';
import { addItem, deleteItem, handleCut, handlePaste } from '../functions/finalCustomFunctions';
import {
  cancelFinalInputData,
  cancelFinalInputDataEdit,
  deleteFinalInputItem,
  editFinalInputItem,
  feeTypeOptions,
  getLabelForFinalValue,
  getShortenedLabelForValue,
  handleAddFinalPropertyItem,
  moveItemDown,
  moveItemUp,
  resetEditPropertyItem,
  resetFinalInputs,
  saveFinalInputData,
  saveFinalInputDataEdit,
  toggleFinalCollapse,
} from '../functions/finalPageFunctions';
import { findBookingById, getSupplierName } from '../functions/generalFunctions';

import { Badge } from '../components/ui/Badge';
import FinalFoundItemCard from './FinalFoundItem';
import { GiJeep } from 'react-icons/gi';
import Select from 'react-select';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const FinalPageComp = ({
  item,
  index,
  finalPageData,
  setFinalPageData,
  properties,
  getPropObj,
  rateLabelMap,
  formatPrice,
  suppliers,
  finalInputs,
  setFinalInputs,
  customFinalItems,
  setCustomFinalItems,
  customFinalCut,
  setCustomFinalCut,
  handleOpenSlideOut,
  setCustomItemToEdit,
  setAddFinalItemDetails,
  bookings,
  finalItem,
  firstDay,
  setFinalSlideOutDate,
}) => {
  // USER
  const { user } = useSelector((state) => state.auth);
  // ...
  let searchId = item.uuid;
  const foundItems = customFinalItems.filter((searchItem) => searchItem.id === searchId && !searchItem.departure);
  const foundItemsDeparture = customFinalItems.filter((search) => search.id === searchId && search.departure && finalItem && (search.pickupDropoff === undefined || search.pickupDropoff === false) && !search.discount); 

  const pickupDropoff = false;
  // DAY
  const firstDayMoment = moment(firstDay, 'YYYY-MM-DD');
  const dayMoment = moment(item.startDay, 'YYYY-MM-DD');
  let day = firstDay === item.startDay ? 1 : dayMoment.diff(firstDayMoment, 'days') + 1;

  let parentDate = item.startDay;

  return (
    <>
      {/* found items */}
      {foundItems.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`bg-[rgba(42,153,163,0.1)] border border-slate-200 brand-text-grey-v2 mb-7 
                ${customFinalCut && customFinalCut.uuid === foundItem.uuid ? 'animate-pulse' : ''}`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <FinalFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalCut={customFinalCut}
            handlePaste={handlePaste}
            addItem={addItem}
            handleCut={handleCut}
            deleteItem={deleteItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            setCustomFinalCut={setCustomFinalCut}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            handleOpenSlideOut={handleOpenSlideOut}
            setCustomItemToEdit={setCustomItemToEdit}
            setAddFinalItemDetails={setAddFinalItemDetails}
            formatPrice={formatPrice}
            firstDay={firstDay}
            setFinalSlideOutDate={setFinalSlideOutDate}
          />
        </div>
      ))}

      {/* main items */}
      <div key={uuidv4()} style={{ zIndex: 1000 - index }}>
        <div
          className={`bg-white border border-slate-200 brand-text-grey-v2 mb-7`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          {/* add / paste top */}
          {user.internalUser && (
            <div className="fixed -top-7 left-0 right-0 flex justify-center group">
              {customFinalCut ? (
                <button
                  className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                  style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                  onClick={() => handlePaste('bottom', null, customFinalCut, customFinalItems, searchId, pickupDropoff, setCustomFinalItems, setCustomFinalCut, parentDate)}
                  disabled={!customFinalCut}
                >
                  <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-right text-nomadEvergreen-700`}>
                    <span className="mr-1.5">Paste item</span>
                    <FaRegPaste size={16} />
                  </span>
                </button>
              ) : (
                <button
                  className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                  style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                  onClick={() => {
                    setFinalSlideOutDate(item.startDay);
                    addItem('bottom', null, searchId, pickupDropoff, handleOpenSlideOut, setAddFinalItemDetails);
                  }}
                >
                  <span className={`text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-center text-nomadEvergreen-700`}>+ Add Item</span>
                </button>
              )}
            </div>
          )}

          {/* DAY */}
          {/* <div className="fixed top-4 -left-12 flex -rotate-90"> */}

          <div className="fixed -top-6 -left-0 flex">
            <div className="text-left" style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}>
              {/* <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-right text-nomadEvergreen-700`}> */}
              <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-0 pr-3 py-0.5 text-right text-nomadEvergreen-700`}>
                <span className="tracking-widest">DAY {day}</span>
                {/* <FaRegPaste size={16} /> */}
              </span>
            </div>
          </div>

          {/* add / paste bottom */}
          {finalItem && user.internalUser && (
            <div className="fixed -bottom-7 left-0 right-0 flex justify-center group">
              {customFinalCut ? (
                <button
                  className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                  style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                  onClick={() => handlePaste('top', null, customFinalCut, customFinalItems, searchId, pickupDropoff, setCustomFinalItems, setCustomFinalCut, parentDate, true)}
                  disabled={!customFinalCut}
                >
                  <span className={`flex items-center justify-center text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5  text-right text-nomadEvergreen-700`}>
                    <span className="mr-1.5">Paste item</span>
                    <FaRegPaste size={16} />
                  </span>
                </button>
              ) : (
                <button
                  className="text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                  style={{ WebkitTransform: 'translateZ(0)' /* Safari */ }}
                  onClick={() => {
                    setFinalSlideOutDate(item.startDay);
                    addItem('top', null, searchId, pickupDropoff, handleOpenSlideOut, setAddFinalItemDetails, true);
                  }}
                >
                  <span className={`text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5 text-center text-nomadEvergreen-700`}>+ Add Item</span>
                </button>
              )}
            </div>
          )}

          <div className={`mb-0`}>
            <div className={`grid grid-cols-12`}>
              {/* IMG */}

              {/* ROOM NAME + DESCRIPTION */}
              <div className="col-span-12 bg-white">
                {/* Number of days */}
                <div
                  className="text-right fixed top-4 right-0"
                  style={{
                    WebkitTransform: 'translateZ(0)',
                    perspective: '1000' /* Safari */,
                  }}
                >
                  {/* <span
className={`text-sm font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5  brand-bg-color-light-v2 rounded-l-lg text-right capitalize`}
>
{item.days} night{item.days > 1 && "s"}
</span> */}
                </div>
                {/* Property name */}
                <div className="grid grid-cols-12 border-b border-slate-200">
                  <div className="text-xs font-medium truncate text-ellipsis overflow-hidden col-span-10 ml-0 border-r border-slate-200 py-2 px-2">
                    <div className="flex items-center">
                      {/* <TbBuildingWarehouse size={30} /> */}

                      {/* IMG */}

                      <div className="row-span-1 relative">
                        <img
                          className="ml-0 mr-2 object-cover w-10 h-10 rounded-full"
                          src={
                            properties[getPropObj(item.propUid, properties)].heroImageUrl
                              ? `${properties[getPropObj(item.propUid, properties)].heroImageUrl}/320x200`
                              : 'https://firebasestorage.googleapis.com/v0/b/nomadhornbill.appspot.com/o/images%2Fproperties%2Fdefault%2Fdefault.png?alt=media&token=60f73247-fa43-4829-9c56-260803159067'
                          }
                          alt={properties[getPropObj(item.propUid, properties)].name || 'Property image'}
                        />
                      </div>
                      {/* {!item.collapsed ? (
                        <IoIosArrowDropupCircle
                          size={18}
                          className="text-nomadEvergreen-700 cursor-pointer	"
                          onClick={() => toggleFinalCollapse(item.uuid, finalPageData, resetFinalInputs, setFinalPageData, setFinalInputs)} // add this line
                        />
                      ) : (
                        <IoIosArrowDropdownCircle
                          size={18}
                          className="text-nomadEvergreen-700 cursor-pointer	"
                          onClick={() => toggleFinalCollapse(item.uuid, finalPageData, resetFinalInputs, setFinalPageData, setFinalInputs)} // add this line
                        />
                      )} */}
                      <div className="ml-2 flex flex-col">
                        <span className="flex items-center">
                          <span className="text-base w-14">{moment(item.startDay).format('MMM D')}</span>
                          <span className="text-base font-semibold ml-5 text-black flex items-center">
                            {item.propName} for {item.days} night{item.days === 1 ? '' : 's'}{' '}
                            {!item.karaniPriced && (
                              <span className="ml-2 flex items-center">
                                <Badge variant="warning" size="sm" className="mr-1 text-black">
                                  Not Karani Priced
                                </Badge>
                                {/* <IoMdWarning size={20} className="text-orange-600" /> */}
                              </span>
                            )}
                          </span>
                        </span>

                        <span className="flex">
                          <span className="w-14">{moment(item.startDay).format('ddd')}</span>
                          <span>
                            <span className="flex ml-5">
                              {properties[getPropObj(item.propUid, properties)].supplier.name === 'Booked Direct' ? (
                                <p className="brand-text-custom-color-warning ml-1">Booked Direct</p>
                              ) : (
                                properties[getPropObj(item.propUid, properties)].supplier.name
                              )}{' '}
                              |
                              <span className="ml-1">
                                {moment(item.startDay).format('Do MMM YYYY')} - {moment(item.endDay).format('Do MMM YYYY')} | {rateLabelMap[item.rateName]} |{' '}
                                {((start, end) => {
                                  const startDay = moment(start, 'YYYY-MM-DD');
                                  const endDay = moment(end, 'YYYY-MM-DD');
                                  const nights = endDay.diff(startDay, 'days');
                                  return `${nights} night${nights === 1 ? '' : 's'}`;
                                })(item.startDay, item.endDay)}{' '}
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 text-xs border-r border-slate-200 py-2 text-nomadBlue-500 flex items-center justify-center">
                    ${item.propertyItems?.length > 0 ? formatPrice(item.propertyItems.reduce((totals, currentItem) => totals + currentItem?.total ?? 0, 0)) : 0}
                  </div>
                  <div className="col-span-1 py-2 flex justify-center items-center">
                    {item.propertyItems.length === 0 && <IoMdWarning size={20} className="text-orange-600" />}

                    {!item.collapsed ? (
                      <IoIosArrowUp
                        size={20}
                        className={`cursor-pointer mt-1 ${item.propertyItems.length === 0 && 'ml-3'} text-nomadEvergreen-700`}
                        onClick={() => toggleFinalCollapse(item.uuid, finalPageData, resetFinalInputs, setFinalPageData, setFinalInputs)} // add this line
                      />
                    ) : (
                      <IoIosArrowDown
                        size={20}
                        className={`cursor-pointer mt-1 ${item.propertyItems.length === 0 && 'ml-3'} text-nomadEvergreen-700`}
                        onClick={() => toggleFinalCollapse(item.uuid, finalPageData, resetFinalInputs, setFinalPageData, setFinalInputs)} // add this line
                      />
                    )}
                  </div>
                </div>

                {/* Column headers*/}
                {/* TODO Add description column*/}

                {!item.collapsed && (
                  <div className={`grid grid-cols-12 text-xs font-medium border-b border-slate-200`}>
                    <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">FEE TYPE</div>
                    <div className="col-span-2 ml-5 border-r border-slate-200 py-2 text-black">SUPPLIER</div>
                    <div className="col-span-4">
                      <div className={`grid grid-cols-12 text-xs`}>
                        <div className="col-span-11 ml-5 border-r border-slate-200 py-2 text-black">DESCRIPTION</div>
                        <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center"></div>
                      </div>
                    </div>
                    <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">
                      <span className="block">NTS/UNITS</span>
                    </div>

                    <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">
                      <span className="block">PAX/UNITS</span>
                    </div>
                    <div className="col-span-1 ml-4 border-r border-slate-200 py-2 text-black">NET RATE ($)</div>
                    <div className="col-span-1 ml-5 border-r border-slate-200 py-2 text-black">TOTAL</div>
                    <div className="col-span-1 ml-5 py-2"></div>
                  </div>
                )}

                {/* Column propertyItems*/}
                {!item.collapsed &&
                  item.propertyItems
                    .sort((a, b) => (a.edit === b.edit ? 0 : a.edit ? 1 : -1))
                    .map((propertyItem, itemIndex) =>
                      item.editPropertyItem !== propertyItem.uuid ? (
                        <div key={uuidv4()} className={`grid grid-cols-12 text-xs border-b border-slate-200`}>
                          <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{getShortenedLabelForValue(propertyItem.feeTypeUid)}</div>
                          <div className="col-span-2 ml-5 border-r border-slate-200 py-2">{getSupplierName(propertyItem.supplier, suppliers)}</div>
                          <div className="col-span-4">
                            <div className={`grid grid-cols-12 text-xs`}>
                              <div className="col-span-11 ml-5 border-r border-slate-200 py-2 pr-5">{propertyItem.description}</div>
                              <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group">
                                <svg viewBox="0 0 24 24" className={`w-6 h-6 ${propertyItem.star ? 'text-nomadEvergreen-700' : 'text-gray-300'}`}>
                                  <path d="M12 2l2.4 7.2h7.6l-6.2 4.6l2.4 7.2L12 16.4l-6.2 4.6l2.4-7.2L2 9.2h7.6L12 2z" className="star-path fill-current"></path>
                                </svg>
                              </div>
                              {/* <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center">{propertyItem.star}</div> */}
                            </div>
                          </div>
                          {/* <div className="col-span-5 ml-5 border-r border-slate-200 py-2">{propertyItem.description}</div> */}
                          <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{propertyItem.units}</div>
                          <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{propertyItem.pax}</div>
                          <div className="col-span-1 ml-5 border-r border-slate-200 py-2">${formatPrice(propertyItem.net)}</div>
                          <div className="col-span-1 ml-5 border-r border-slate-200 py-2"> ${formatPrice(propertyItem.total)}</div>
                          <div className="col-span-1 ml-5 py-2 flex items-center">
                            {propertyItem.edit && user.internalUser && (
                              <div className="grid grid-cols-12 items-center justify-center">
                                <div className="col-span-4 flex justify-center items-center px-2 py-2">
                                  <FaEdit
                                    size={20}
                                    className="text-nomadEvergreen-700 cursor-pointer"
                                    onClick={() => editFinalInputItem(item.uuid, propertyItem.uuid, finalPageData, setFinalInputs, setFinalPageData)}
                                  />
                                </div>

                                <div className="col-span-4 flex justify-center items-center px-2 py-2">
                                  <FaTrashAlt size={18} className="text-red-700 cursor-pointer" onClick={() => deleteFinalInputItem(item.uuid, propertyItem.uuid, finalPageData, setFinalPageData)} />
                                </div>
                                <div className="col-span-4 flex flex-col justify-center items-center px-2 py-1">
                                  <IoIosArrowUp size={18} className="cursor-pointer mb-1" onClick={() => moveItemUp(item.uuid, propertyItem.uuid, finalPageData, setFinalPageData)} />
                                  <IoIosArrowDown size={18} className="cursor-pointer" onClick={() => moveItemDown(item.uuid, propertyItem.uuid, finalPageData, setFinalPageData)} />
                                </div>
                              </div>
                              // <div className="flex items-center cursor-pointer">
                              //   <div className="flex items-center cursor-pointer" onClick={() => editFinalInputItem(item.uuid, propertyItem.uuid, finalPageData, setFinalInputs, setFinalPageData)}>
                              //     <FaEdit size={18} className="text-nomadEvergreen-700" />
                              //   </div>
                              //   <div className="flex items-center cursor-pointer ml-5" onClick={() => deleteFinalInputItem(item.uuid, propertyItem.uuid, finalPageData, setFinalPageData)}>
                              //     <FaTrashAlt size={18} className="text-red-700" />
                              //   </div>
                              // </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div key={uuidv4()}>
                          <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                            <div className="col-span-1 px-3  border-r border-slate-200 py-2">
                              <Select
                                value={
                                  finalInputs.feeTypeUid
                                    ? {
                                        label: finalInputs.feeTypeName,
                                        value: finalInputs.feeTypeUid,
                                      }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  setFinalInputs({
                                    ...finalInputs,
                                    feeTypeUid: selectedOption.value,
                                    feeTypeName: selectedOption.label,
                                  })
                                }
                                options={feeTypeOptions}
                                menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '5rem',
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }), // Set the z-index for the dropdown menu
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    width: '10rem', // width for the dropdown menu
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: '12px', // equivalent to text-xs in Tailwind
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: '12px', // equivalent to text-xs in Tailwind
                                  }),
                                }}
                                placeholder="Select"
                                components={{
                                  DropdownIndicator: null, // This line removes the dropdown arrow
                                }}
                              />
                            </div>
                            <div className="col-span-2 px-5  border-r border-slate-200 py-2">
                              <Select
                                value={
                                  finalInputs.supplier
                                    ? {
                                        label: getSupplierName(finalInputs.supplier, suppliers),
                                        value: finalInputs.supplier,
                                      }
                                    : null
                                }
                                onChange={(selectedOption) => {
                                  setFinalInputs({
                                    ...finalInputs,
                                    supplier: selectedOption.value,
                                    star: selectedOption.value !== item.supplier ? true : finalInputs.star,
                                  });
                                }}
                                options={suppliers
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((supplier) => ({
                                    value: supplier.uid,
                                    label: supplier.name,
                                  }))}
                                menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '10.5rem',
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }), // Set the z-index for the dropdown menu
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    width: '20rem', // width for the dropdown menu
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: '12px', // equivalent to text-xs in Tailwind
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: '12px', // equivalent to text-xs in Tailwind
                                  }),
                                }}
                                placeholder="Select"
                                components={{
                                  DropdownIndicator: null, // This line removes the dropdown arrow
                                }}
                              />
                            </div>
                            <div className="col-span-4">
                              <div className={`grid grid-cols-12 text-xs`}>
                                <div className="col-span-11 ml-5 border-r border-slate-200 py-2 pr-5">
                                  {' '}
                                  <input
                                    type="text"
                                    maxLength="255"
                                    defaultValue={finalInputs.description}
                                    //onBlur={(e) => setFinalInputs({ ...finalInputs, description: e.target.value })}
                                    onBlur={(e) =>
                                      setFinalInputs({
                                        ...finalInputs,
                                        description: e.target.value,
                                      })
                                    }
                                    className="text-xs w-full"
                                  />
                                </div>
                                <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group">
                                  <input
                                    type="checkbox"
                                    id="starCheckbox"
                                    className="hidden"
                                    //checked={finalInputs.star}
                                    onClick={() => {
                                      if (finalInputs.supplier !== item.supplier) {
                                        toast.error(`Supplier must be '${getSupplierName(item.supplier, suppliers)}' to be unstarred`);
                                        return;
                                      }
                                      const newStarValue = !finalInputs.star;
                                      setFinalInputs({
                                        ...finalInputs,
                                        star: newStarValue,
                                      });
                                      console.log(newStarValue);
                                    }}
                                  />
                                  <label htmlFor="starCheckbox" className="cursor-pointer">
                                    <svg viewBox="0 0 24 24" className={`w-6 h-6 ${finalInputs.star ? 'text-nomadEvergreen-700' : 'text-gray-300'}`}>
                                      <path d="M12 2l2.4 7.2h7.6l-6.2 4.6l2.4 7.2L12 16.4l-6.2 4.6l2.4-7.2L2 9.2h7.6L12 2z" className="star-path fill-current"></path>
                                    </svg>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                              {/* <input
                                type="number"
                                min="0"
                                defaultValue={finalInputs.units}
                                onBlur={(e) => {
                                  let value = Math.floor(Number(e.target.value));
                                  value = value < 0 ? 0 : value; // check if value is less than 0
                                  setFinalInputs({
                                    ...finalInputs,
                                    units: value,
                                  });
                                }}
                                className="w-full text-xs inputPax"
                                style={{
                                  appearance: 'textfield',
                                }} // add this
                              /> */}
                              <input
                                type="number"
                                min="1"
                                defaultValue={finalInputs.units === 0 ? item.days : finalInputs.units}
                                // onChange={(e) => {
                                //   let value = Math.max(1, Math.floor(Number(e.target.value)));
                                //   setFinalInputs({
                                //     ...finalInputs,
                                //     units: value,
                                //   });
                                // }}
                                onBlur={(e) => {
                                  let value = e.target.value === '' ? 1 : Math.max(1, Math.floor(Number(e.target.value)));
                                  setFinalInputs({
                                    ...finalInputs,
                                    units: value,
                                  });
                                }}
                                className="w-full text-xs inputPax"
                                style={{
                                  appearance: 'textfield',
                                }}
                              />
                            </div>
                            <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                              <input
                                type="number"
                                min="0"
                                defaultValue={finalInputs.pax > 0 ? finalInputs.pax : item.pax}
                                onBlur={(e) => {
                                  let value = Math.floor(Number(e.target.value));
                                  value = value < 0 ? 0 : value; // check if value is less than 0
                                  setFinalInputs({
                                    ...finalInputs,
                                    pax: value,
                                  });
                                }}
                                className="w-full text-xs inputPax"
                                style={{
                                  appearance: 'textfield',
                                }} // add this
                              />
                            </div>
                            <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                              <input
                                type="number"
                                //min="0"
                                step="0.01"
                                defaultValue={finalInputs.net}
                                onInput={(e) => {
                                  const value = e.target.value;
                                  if (!/^-?\d*\.?\d{0,2}$/.test(value)) {
                                    e.target.value = value.slice(0, -1);
                                  }
                                }}
                                onBlur={(e) => {
                                  //let value = Math.floor(Number(e.target.value));
                                  // Rounds up to 2 decimal points
                                  let value = Number(Number(e.target.value).toFixed(2));
                                  //value = value < 0 ? 0 : value; // check if value is less than 0
                                  setFinalInputs({
                                    ...finalInputs,
                                    net: value,
                                  });
                                }}
                                className="w-full text-xs inputPax"
                                style={{
                                  appearance: 'textfield',
                                }} // add this
                              />
                            </div>
                            <div className="col-span-1 ml-5 border-r border-slate-200 py-2 flex items-center">${finalInputs.units * finalInputs.pax * finalInputs.net}</div>

                            <div className="col-span-1 ml-5 py-2 flex items-center"></div>
                          </div>
                          <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                            <div className="col-span-4 ml-5 py-2">
                              <div className="flex items-center cursor-pointer">
                                <div
                                  className="flex items-center cursor-pointer"
                                  onClick={() => saveFinalInputDataEdit(item.uuid, propertyItem.uuid, finalPageData, setFinalPageData, finalInputs, resetFinalInputs, setFinalInputs)}
                                >
                                  <FaSave size={18} className="text-nomadEvergreen-700" />
                                  <span className="ml-2">Save row</span>
                                </div>
                                <div
                                  className="flex items-center cursor-pointer ml-5"
                                  onClick={() => cancelFinalInputDataEdit(item.uuid, propertyItem.uuid, finalPageData, setFinalPageData, resetFinalInputs, setFinalInputs)}
                                >
                                  <FaWindowClose size={18} className="text-nomadEvergreen-700" />
                                  <span className="ml-2">Cancel</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-2 ml-5 py-2"></div>
                            <div className="col-span-2 ml-5 py-2"></div>
                            <div className="col-span-2 ml-5 py-2"></div>
                            <div className="col-span-2 ml-5 py-2"></div>
                          </div>{' '}
                        </div>
                      ),
                    )}

                {/* Add new item */}
                {!item.collapsed && item.addPropertyItem && (
                  <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                    <div className="col-span-1 px-3  border-r border-slate-200 py-2">
                      <Select
                        value={
                          finalInputs.feeTypeUid
                            ? {
                                label: finalInputs.feeTypeName,
                                value: finalInputs.feeTypeUid,
                              }
                            : null
                        }
                        onChange={(selectedOption) =>
                          setFinalInputs({
                            ...finalInputs,
                            feeTypeUid: selectedOption.value,
                            feeTypeName: selectedOption.label,
                          })
                        }
                        options={feeTypeOptions}
                        menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '5rem',
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }), // Set the z-index for the dropdown menu
                          }),
                          menu: (provided) => ({
                            ...provided,
                            width: '10rem', // width for the dropdown menu
                          }),
                          option: (provided) => ({
                            ...provided,
                            fontSize: '12px', // equivalent to text-xs in Tailwind
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            fontSize: '12px', // equivalent to text-xs in Tailwind
                          }),
                        }}
                        placeholder="Select"
                        components={{
                          DropdownIndicator: null, // This line removes the dropdown arrow
                        }}
                      />
                    </div>
                    <div className="col-span-2 px-5  border-r border-slate-200 py-2">
                      <Select
                        value={
                          finalInputs.supplier
                            ? {
                                label: getSupplierName(finalInputs.supplier, suppliers),
                                value: finalInputs.supplier,
                              }
                            : item.supplier
                            ? {
                                label: getSupplierName(item.supplier, suppliers),
                                value: item.supplier,
                              }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setFinalInputs({
                            ...finalInputs,
                            supplier: selectedOption.value,
                            star: selectedOption.value !== item.supplier ? true : finalInputs.star,
                          });
                        }}
                        options={suppliers
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((supplier) => ({
                            value: supplier.uid,
                            label: supplier.name,
                          }))}
                        menuPortalTarget={document.body} // Render the menu portal in the body to avoid clipping
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '10.5rem',
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }), // Set the z-index for the dropdown menu
                          }),
                          menu: (provided) => ({
                            ...provided,
                            width: '20rem', // width for the dropdown menu
                          }),
                          option: (provided) => ({
                            ...provided,
                            fontSize: '12px', // equivalent to text-xs in Tailwind
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            fontSize: '12px', // equivalent to text-xs in Tailwind
                          }),
                        }}
                        placeholder="Select"
                        components={{
                          DropdownIndicator: null, // This line removes the dropdown arrow
                        }}
                      />
                    </div>
                    <div className="col-span-4">
                      <div className={`grid grid-cols-12 text-xs`}>
                        <div className="col-span-11 ml-5 border-r border-slate-200 py-2 flex pr-5">
                          {' '}
                          <input
                            type="text"
                            maxLength="255"
                            defaultValue={finalInputs.description}
                            //onBlur={(e) => setFinalInputs({ ...finalInputs, description: e.target.value })}
                            onBlur={(e) =>
                              setFinalInputs({
                                ...finalInputs,
                                description: e.target.value,
                              })
                            }
                            className="text-xs w-full"
                          />
                        </div>
                        <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group">
                          <input
                            type="checkbox"
                            id="starCheckbox"
                            className="hidden"
                            //checked={finalInputs.star}
                            onClick={() => {
                              if (finalInputs.supplier !== item.supplier) {
                                toast.error(`Supplier must be '${getSupplierName(item.supplier, suppliers)}' to be unstarred`);
                                return;
                              }
                              const newStarValue = !finalInputs.star;
                              setFinalInputs({
                                ...finalInputs,
                                star: newStarValue,
                              });
                              console.log(newStarValue);
                            }}
                          />
                          <label htmlFor="starCheckbox" className="cursor-pointer">
                            <svg viewBox="0 0 24 24" className={`w-6 h-6 ${finalInputs.star ? 'text-nomadEvergreen-700' : 'text-gray-300'}`}>
                              <path d="M12 2l2.4 7.2h7.6l-6.2 4.6l2.4 7.2L12 16.4l-6.2 4.6l2.4-7.2L2 9.2h7.6L12 2z" className="star-path fill-current"></path>
                            </svg>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-span-5 px-5 border-r border-slate-200 py-2">
<input
type="text"
maxLength="255"
defaultValue={finalInputs.description}
//onBlur={(e) => setFinalInputs({ ...finalInputs, description: e.target.value })}
onBlur={(e) =>
setFinalInputs({
...finalInputs,
description: e.target.value,
})
}
className="w-full text-xs"
/>
</div> */}
                    <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                      {/* <input
                        type="number"
                        min="0"
                        defaultValue={finalInputs.units}
                        onBlur={(e) => {
                          let value = Math.floor(Number(e.target.value));
                          value = value < 0 ? 0 : value; // check if value is less than 0
                          setFinalInputs({
                            ...finalInputs,
                            units: value,
                          });
                        }}
                        className="w-full text-xs inputPax"
                        style={{
                          appearance: 'textfield',
                        }} // add this
                      /> */}
                      <input
                        type="number"
                        min="1"
                        defaultValue={finalInputs.units === 0 ? item.days : finalInputs.units}
                        // onChange={(e) => {
                        //   let value = Math.max(1, Math.floor(Number(e.target.value)));
                        //   setFinalInputs({
                        //     ...finalInputs,
                        //     units: value,
                        //   });
                        // }}
                        onBlur={(e) => {
                          let value = e.target.value === '' ? 1 : Math.max(1, Math.floor(Number(e.target.value)));
                          setFinalInputs({
                            ...finalInputs,
                            units: value,
                          });
                        }}
                        className="w-full text-xs inputPax"
                        style={{
                          appearance: 'textfield',
                        }}
                      />
                    </div>
                    <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                      <input
                        type="number"
                        min="0"
                        defaultValue={finalInputs.pax > 0 ? finalInputs.pax : item.pax}
                        onBlur={(e) => {
                          let value = Math.floor(Number(e.target.value));
                          value = value < 0 ? 0 : value; // check if value is less than 0
                          setFinalInputs({
                            ...finalInputs,
                            pax: value,
                          });
                        }}
                        className="w-full text-xs inputPax"
                        style={{
                          appearance: 'textfield',
                        }} // add this
                      />
                    </div>
                    <div className="col-span-1 px-5 border-r border-slate-200 py-2">
                      <input
                        type="number"
                        //min="0"
                        step="0.01"
                        onInput={(e) => {
                          const value = e.target.value;
                          if (!/^-?\d*\.?\d{0,2}$/.test(value)) {
                            e.target.value = value.slice(0, -1);
                          }
                        }}
                        defaultValue={finalInputs.net}
                        onBlur={(e) => {
                          // let value = Math.floor(Number(e.target.value));
                          // Rounds up to 2 decimal points
                          let value = Number(Number(e.target.value).toFixed(2));
                          //value = value < 0 ? 0 : value; // check if value is less than 0
                          setFinalInputs({
                            ...finalInputs,
                            net: value,
                          });
                        }}
                        className="w-full text-xs inputPax"
                        style={{
                          appearance: 'textfield',
                        }} // add this
                      />
                    </div>
                    <div className="col-span-1 ml-5 border-r border-slate-200 py-2 flex items-center">${finalInputs.units * finalInputs.pax * finalInputs.net}</div>

                    <div className="col-span-1 ml-5 py-2 flex items-center"></div>
                  </div>
                )}

                {/* Columns add item*/}
                {!item.collapsed && !item.addPropertyItem && user.internalUser && (
                  <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                    <div className="col-span-4 ml-5 py-2">
                      <div
                        className="flex cursor-pointer"
                        onClick={() => handleAddFinalPropertyItem(item.uuid, item.supplier, resetFinalInputs, resetEditPropertyItem, finalPageData, setFinalPageData, setFinalInputs)}
                      >
                        <FaPlusCircle size={18} className="text-green-600 self-center" />
                        <span className="ml-2 text-green-600 pt-1">Add new item</span>
                      </div>
                    </div>
                    <div className="col-span-2 ml-5 py-2"></div>
                    <div className="col-span-2 ml-5 py-2"></div>
                    <div className="col-span-2 ml-5 py-2"></div>
                    <div className="col-span-2 ml-5 py-2"></div>
                  </div>
                )}

                {/* Close * Save buttons*/}
                {!item.collapsed && item.addPropertyItem && (
                  <div className={`grid grid-cols-12 text-xs font-semibold border-b border-slate-200`}>
                    <div className="col-span-4 ml-5 py-2">
                      <div className="flex items-center cursor-pointer">
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => {
                            const updatedFinalInputs = {
                              ...finalInputs,
                              units: finalInputs.units === 0 ? item.days : finalInputs.units,
                              pax: finalInputs.pax === 0 ? item.pax : finalInputs.pax,
                            };
                            saveFinalInputData(item.uuid, updatedFinalInputs, finalPageData, setFinalPageData, resetFinalInputs, setFinalInputs, item.supplier);
                          }}
                        >
                          <FaSave size={18} className="text-nomadEvergreen-700" />
                          <span className="ml-2">Save row</span>
                        </div>
                        <div className="flex items-center cursor-pointer ml-5" onClick={() => cancelFinalInputData(finalPageData, setFinalPageData)}>
                          <FaWindowClose size={18} className="text-nomadEvergreen-700" />
                          <span className="ml-2">Cancel</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 ml-5 py-2"></div>
                    <div className="col-span-2 ml-5 py-2"></div>
                    <div className="col-span-2 ml-5 py-2"></div>
                    <div className="col-span-2 ml-5 py-2"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* TODO TRANSFERS AND ACTIVITIES*/}
      </div>

      {/* found items final */}
      {foundItemsDeparture.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`bg-[rgba(42,153,163,0.1)] border border-slate-200 brand-text-grey-v2 mb-7 
                  ${customFinalCut && customFinalCut.uuid === foundItem.uuid ? 'animate-pulse' : ''}`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <FinalFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalCut={customFinalCut}
            handlePaste={handlePaste}
            addItem={addItem}
            handleCut={handleCut}
            deleteItem={deleteItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            setCustomFinalCut={setCustomFinalCut}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            handleOpenSlideOut={handleOpenSlideOut}
            setCustomItemToEdit={setCustomItemToEdit}
            setAddFinalItemDetails={setAddFinalItemDetails}
            formatPrice={formatPrice}
            firstDay={firstDay}
            setFinalSlideOutDate={setFinalSlideOutDate}
          />
        </div>
      ))}
    </>
  );
};

export default FinalPageComp;
