import { IoAirplaneOutline } from 'react-icons/io5';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { PiTentLight } from 'react-icons/pi';
import { PiVanLight } from 'react-icons/pi';
import React from 'react';
import { getPropObj } from '../functions/generalFunctions';

const TransferOptions = ({ objType, properties, transfer, includeArrivalTransfer, setIncludeArrivalTransfer, includeDepartureTransfer, setIncludeDepartureTransfer }) => (
  <div className={`col-span-4 flex flex-col items-left p-4 bg-white rounded-lg border shadow-sm h-[185px]`}>
    <div className={``}>
      <div className="flex items-center">
        <PiVanLight size={30} className="text-black" />
        <IoIosArrowRoundForward size={20} className="text-cyan-600 mx-1" />
        {objType === 'arrival' ? <PiTentLight size={30} className="text-black" /> : <IoAirplaneOutline size={30} className="text-black" />}

        <div className="ml-auto">
          {/* <input type="checkbox" name="packedLunchCheck" className="checkbox checkbox-accent" defaultChecked={packedLunch} onChange={(e) => setPackedLunch(e.target.checked)} /> */}
          <input
            type="checkbox"
            name={objType === 'arrival' ? 'includeArrivalTransfer' : 'includeDepartureTransfer'}
            //className={`checkbox checkbox-accent`}
            className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]" 
            checked={objType === 'arrival' ? includeArrivalTransfer : includeDepartureTransfer}
            onChange={(e) => {
              let isChecked = e.target.checked;
              if (objType === 'arrival') {
                setIncludeArrivalTransfer(isChecked);
              } else {
                setIncludeDepartureTransfer(isChecked);
              }
            }}
          />
        </div>
      </div>

      <div className="text-stone-950 text-[15px] font-semibold mt-3">{objType === 'arrival' ? 'Transfer to Property' : 'Transfer to airport'}</div>
      <div className="text-black text-sm font-normal mt-5"></div>
      {/* <div className="flex items-center">
        <div className={`text-neutral-500 text-sm font-normal ${packedLunch ? '' : 'hidden'}`}>Chargeable</div>
        <div className={`ml-auto ${packedLunch ? '' : 'hidden'}`}>
          <input type="checkbox" name="packedLunchChargeableCheck" className="checkbox checkbox-accent" defaultChecked={packedLunchChargeable} />{' '}
        </div>
      </div> */}
    </div>
  </div>
);

const TransferArrivalDeparture = ({ objType, properties, transfer, includeArrivalTransfer, setIncludeArrivalTransfer, includeDepartureTransfer, setIncludeDepartureTransfer }) => {
  return (
    <TransferOptions
      objType={objType}
      properties={properties}
      transfer={transfer}
      includeArrivalTransfer={includeArrivalTransfer}
      setIncludeArrivalTransfer={setIncludeArrivalTransfer}
      includeDepartureTransfer={includeDepartureTransfer}
      setIncludeDepartureTransfer={setIncludeDepartureTransfer}
    />
    // <>
    //   {objType === 'arrival' && (
    //     // <div className={`mt-2 ml-0 flex items-start`}>
    //     //   <input
    //     //     type="checkbox"
    //     //     name="includeArrivalTransfer"
    //     //     className={`checkbox checkbox-accent`}
    //     //     checked={includeArrivalTransfer}
    //     //     onChange={(e) => {
    //     //       let isChecked = e.target.checked;
    //     //       setIncludeArrivalTransfer(isChecked);
    //     //     }}
    //     //   />

    //     //   <span className="label-text ml-2 text-sm">Include transfer from arrival airport to {properties[getPropObj(transfer.propUid, properties)].name}</span>
    //     // </div>
    //     <TransferOptions
    //       objType={objType}
    //       properties={properties}
    //       transfer={transfer}
    //       includeArrivalTransfer={includeArrivalTransfer}
    //       setIncludeArrivalTransfer={setIncludeArrivalTransfer}
    //       includeDepartureTransfer={includeDepartureTransfer}
    //       setIncludeDepartureTransfer={setIncludeDepartureTransfer}
    //     />
    //   )}
    //   {objType === 'departure' && (
    //     // <div className={`mt-2 ml-0 flex items-start`}>
    //     //   <input
    //     //     type="checkbox"
    //     //     name="includeDepartureTransfer"
    //     //     className="checkbox checkbox-accent"
    //     //     checked={includeDepartureTransfer}
    //     //     onChange={(e) => {
    //     //       let isChecked = e.target.checked;
    //     //       setIncludeDepartureTransfer(isChecked);
    //     //     }}
    //     //   />

    //     //   <span className="label-text ml-2 text-sm">Include transfer to departure airport from {properties[getPropObj(transfer.propUid, properties)].name}</span>
    //     // </div>
    //     <TransferOptions
    //     objType={objType}
    //     properties={properties}
    //     transfer={transfer}
    //     includeArrivalTransfer={includeArrivalTransfer}
    //     setIncludeArrivalTransfer={setIncludeArrivalTransfer}
    //     includeDepartureTransfer={includeDepartureTransfer}
    //     setIncludeDepartureTransfer={setIncludeDepartureTransfer}
    //   />
    //   )}
    // </>
  );
};

export default TransferArrivalDeparture;
