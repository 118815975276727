import 'react-confirm-alert/src/react-confirm-alert.css';

import { addDoc, collection, deleteField, doc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { addExtra, calculateRoomTotals, countSafariParkNights, longStayCheck, processAgesRoom, processAgesRooms, updateItineraryStatusDelete, updateItineraryStatusEdit, updateItineraryStatusEditItinerary } from './itineraryFunctions';
import {
  getPropObj,
  getTotalFromActivities,
  getTotalFromBookings,
  getTotalFromBookingsForVehicles,
  getTotalFromCustomItems,
  getTotalFromFinalPageData,
  getTotalFromFinalPageTransferData,
  getTotalFromOtherArrangements,
  getTotalFromTransfers,
} from './generalFunctions';
import { loadBooking, loadItineraryVersion } from './loadDataFunctions';

import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { countGuides } from './paxFunctions';
import { db } from '../db/firebase.config';
import moment from 'moment';
import { resRequestCreate } from './platformFunctions';
import { toast } from 'react-toastify';

const searchItineraries = async () => {
  try {
    const nameKey = 'name';
    const agentName = 'Agent name';
    const consultantName = '';
    const userName = '';
    const dataDeparture = '';
    const dateBooked = '';
    const properties = '';

    const queryConstraints = [];
    if (agentName !== '') queryConstraints.push(where(`agent.${nameKey}`, '==', agentName));
    if (consultantName !== '') queryConstraints.push(where(`consultant.${nameKey}`, '==', consultantName));
    if (userName !== '') queryConstraints.push(where(`user.${nameKey}`, '==', userName));
    const q = query(collection(db, 'itineraries'), ...queryConstraints);

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log('No results');
    } else {
      querySnapshot.forEach((doc) => {
        console.log(doc.id, ' => ', doc.data());
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const loadGetItinerary = async (resReqReservationId, setGetItineraryData, setCurrentItineraryIds) => {
  if (resReqReservationId !== null) {
    const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;

    const url = '/api/resreq';

    var data = JSON.stringify({
      method: 'rv_get_itinerary',
      params: [
        {
          bridge_username: '',
          bridge_password: '',
          link_id: link_id,
        },
        resReqReservationId,
      ],
      id: 1,
    });

    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(async function (response) {
        console.log(response.status);
        console.log(JSON.stringify(response.data, undefined, 4));

        if (response.status === 200) {
          if (response.data.error && response.data.error.faultCode >= 0) {
            return toast.error('There has been an error with the ResRequest API. Please try again later.');
            //setIsLoading(false);
          }
          console.log('rv_get_itinerary');
          console.log(JSON.stringify(response.data.result, undefined, 4));
          setGetItineraryData(response.data.result);
          const itineraryIds = response.data.result.map((itinerary) => itinerary.id);
          setCurrentItineraryIds(itineraryIds);
          return response.data.result;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
};

const updateItineraryWithGuests = async (db, uid, itineraryObject, guests) => {
  // Update the main itinerary document
  const itineraryRef = doc(db, 'itineraries', uid);
  await updateDoc(itineraryRef, itineraryObject);

  // Update or create guests in the subcollection
  const guestsCollectionRef = collection(db, 'itineraries', uid, 'guests');

  const upsertGuest = async (guest, type) => {
    const guestData = {
      ...guest,
      type: type,
    };

    const guestId = guest.uuid || doc(guestsCollectionRef).id;
    await setDoc(doc(guestsCollectionRef, guestId), guestData, { merge: true });

    return guestId;
  };

  // Update adult guests
  for (const adult of guests.adultAges) {
    await upsertGuest(adult, 'adult');
  }

  // Update child guests
  for (const child of guests.childAges) {
    await upsertGuest(child, 'child');
  }

  // Update the main itinerary document with the total count of guests
  // await updateDoc(itineraryRef, {
  //   totalAdults: guests.adults,
  //   totalChildren: guests.children,
  // });

  return uid;
};

const createItineraryVersionControl = async (db, itineraryObject, guestsStore) => {
  // Create the main version control document
  const versionControlData = {
    ...itineraryObject,
    //versionDate: new Date(),
  };

  const docRef = await addDoc(collection(db, 'itinerariesVersionControl'), versionControlData);
  const versionControlId = docRef.id;

  // Create guests in the subcollection
  const guestsCollectionRef = collection(db, 'itinerariesVersionControl', versionControlId, 'guests');

  const addGuest = async (guest, type) => {
    const guestData = {
      ...guest,
      type: type,
    };

    const guestId = guest.uuid || doc(guestsCollectionRef).id;
    await setDoc(doc(guestsCollectionRef, guestId), guestData);

    return guestId;
  };

  // Add adult guests
  for (const adult of guestsStore.adultAges) {
    await addGuest(adult, 'adult');
  }

  // Add child guests
  for (const child of guestsStore.childAges) {
    await addGuest(child, 'child');
  }

  // Update the main version control document with the total count of guests
  // await setDoc(docRef, {
  //   totalAdults: guestsStore.adults,
  //   totalChildren: guestsStore.children,
  // }, { merge: true });

  return versionControlId;
};

const updateItinerary = async (
  resReqReservationId,
  bookings,
  currentItineraryIds,
  properties,
  agentRates,
  uid,
  itineraryStore,
  setNavigateToManageItineraries,
  activitiesDataTemp,
  transfers,
  jsonData,
  finalPageData,
  version,
  setCallUpdateDates,
  deeShadow,
  setStep,
  setIsLoading,
  timestampStartedEdit,
  mojoItinerary,
  user,
  otherArrangementsData,
  activitiesDataStore,
  bookingsDataStore,
  quoteMode,
  dateModified,
  finalPageTransferData,
  itineraryName,
  itineraryRenamed,
  roomPax,
  guests,
  newVersion,
  userVersionLive,
  customFinalItems,
  packageJsonVersion,
  createVersionCheck,
  agentObject,
  resRequestStatus,
  resRequestStatusId,
  vcVersion,
  guestsStore,
  dateCreated,
  hidden,
  reprintInvoice,
  status,
  advancedMode,
) => {
  const liveItinerary = await loadBooking(uid);
  console.log('itineraryStore', JSON.stringify(itineraryStore, null, 2));
  console.log('liveItinerary', JSON.stringify(liveItinerary, null, 2));

  // setIsLoading(false);
  // return;
  // let currentVersion = 1;
  // if (newVersion) {
  //   console.log('newVersion');
  //   currentVersion = await loadItineraryVersion(uid);
  //   console.log('newVersion', userVersion);
  // }

  // if (processQuoteMode) {
  //   console.log('processQuoteMode');
  //   await updateItineraryStatusEdit(resReqReservationId, '20', 'draft', uid, user.uid);
  // } else if (quoteMode) {
  //   console.log('quoteMode');
  //   await updateItineraryStatusEdit(resReqReservationId, '0', 'quotation', uid, user.uid);
  // setIsLoading(false);
  // return alert(quoteMode);
  // }

  const nomadTanzaniaBookings = bookings.filter((booking) => resRequestCreate(booking.propUid, properties));
  const totalNomadTanzaniaDays = nomadTanzaniaBookings.reduce((total, booking) => total + booking.days, 0);
  const totalSafariNights = countSafariParkNights(bookings);

  // Deal with accommodation
  var accommodation = [];
  // RESREQUESTCREATE === TRUE DON'T ADD  NORTHERN GUIDE VEHICLES
  nomadTanzaniaBookings.forEach((booking) => {
    const endDay = new Date(booking.endDay);
    const startDay = new Date(booking.startDay);
    let selectedRate;
    //if (totalNomadTanzaniaDays > 4 && moment(endDay).isAfter(moment('2023-05-31'))) {
    if (longStayCheck(totalNomadTanzaniaDays, startDay, createVersionCheck, totalSafariNights, 'edit', dateCreated)) {
      selectedRate = agentRates.circuit[booking.rateName];
    } else {
      selectedRate = agentRates.standard[booking.rateName];
    }
    console.log(selectedRate);
    for (let x = 0; x < booking.rooms.length; x++) {
      console.log('booking.startDay');
      console.log(booking.startDay);
      if (!booking.rooms[x].northernGuideVehicleSupplement) {
        const ageCounts = processAgesRoom(booking.rooms[x]);
        let adNew = ageCounts.adNew;
        let chNew = ageCounts.chNew;
        let chiNew = ageCounts.chiNew;
        let infNew = 0;
        accommodation.push([
          booking.rooms[x].selectedRoom,
          booking.startDay,
          booking.endDay,
          [
            ['RS1', '1'],
            ['RS2', `${adNew}`],
            ['RS3', `${chNew}`],
            ['11ed2692eeb9680c8f91ac1f6b1b6a6e', `${chiNew}`],
            ['11ed2692dae5ee328f91ac1f6b1b6a6e', `${infNew}`],
            // ['RS1', '1'],
            // ['RS2', `${booking.rooms[x].ad}`],
            // ['RS3', `${booking.rooms[x].ch}`],
            // ['11ed2692eeb9680c8f91ac1f6b1b6a6e', `${booking.rooms[x].chi}`],
            // ['11ed2692dae5ee328f91ac1f6b1b6a6e', `${booking.rooms[x].inf}`],
          ],
          '',
          selectedRate,
        ]);
      }
    }
  });

  // TEMP CODE
  console.log('ACCOMMODATION 1');
  console.log(JSON.stringify(accommodation, undefined, 4));

  // RESREQUESTCREATE === FALSE ADD NORTHERN GUIDE VEHICLES
  bookings.forEach((booking) => {
    let selectedRate = '11ed2a8f8ed73de68f91ac1f6b1b6a6e';

    for (let x = 0; x < booking.rooms.length; x++) {
      if (booking.rooms[x].northernGuideVehicleSupplement) {
        if (booking.startDay !== booking.endDay) {
          accommodation.push([
            booking.rooms[x].selectedRoom,
            booking.startDay,
            booking.endDay,
            [
              ['RS1', '1'],
              ['RS2', `0`],
              ['RS3', `0`],
              ['11ed2692eeb9680c8f91ac1f6b1b6a6e', `0`],
              ['11ed2692dae5ee328f91ac1f6b1b6a6e', `0`],
            ],
            '',
            selectedRate,
          ]);
        }
      }
    }
  });

  // TEMP CODE
  //setIsLoading(false);
  console.log('ACCOMMODATION 2');
  console.log(JSON.stringify(accommodation, undefined, 4));

  // NEW CODE
  activitiesDataTemp.forEach((activity, index) => {
    // Check if the second element of the activities array exists
    if (activity.activities[1]) {
      // Find activities with type resRequestAccom
      const resRequestAccomActivities = activity.activities[1].filter((a) => a.type === 'resRequestAccom');

      // Iterate over each resRequestAccom activity
      resRequestAccomActivities.forEach((resRequestAccomActivity) => {
        // Extract the necessary data from the activity object
        const selectedRoom = resRequestAccomActivity.ids.resRequestRoom;
        const startDay = resRequestAccomActivity.selectedActivityDate;

        // Set end date
        const momentDate = moment(startDay, 'YYYY-MM-DD');

        // Add one day to the moment object
        const newMomentDate = momentDate.add(1, 'days');

        // Convert the moment object back to a string in the original format
        const newSelectedActivityDate = newMomentDate.format('YYYY-MM-DD');
        const endDay = newSelectedActivityDate;

        let selectedRate;

        //if (totalNomadTanzaniaDays > 4 && endDay > new Date('2023-05-31')) {
        if (longStayCheck(totalNomadTanzaniaDays, startDay, createVersionCheck, totalSafariNights, 'edit', dateCreated)) {
          selectedRate = agentRates.circuit[bookings[index].rateName];
        } else {
          selectedRate = agentRates.standard[bookings[index].rateName];
        }

        //count total adults and children
        const totals = calculateRoomTotals(bookings, index);
        // Create the accommodation data structure and push it to the accommodation array
        const ageCounts = processAgesRooms(bookings, index);
        let adNew = ageCounts.adNew;
        let chNew = ageCounts.chNew;
        let chiNew = ageCounts.chiNew;
        let infNew = 0;
        const accommodationData = [
          selectedRoom,
          startDay,
          endDay,
          [
            // ['RS1', resRequestAccomActivity.activityUnits.toString()],
            // ['RS2', totals.totalAd],
            // ['RS3', totals.totalCh],
            // ['11ed2692eeb9680c8f91ac1f6b1b6a6e', totals.totalChi],
            // ['11ed2692dae5ee328f91ac1f6b1b6a6e', totals.totalInf],
            ['RS1', resRequestAccomActivity.activityUnits.toString()],
            ['RS2', adNew.toString()],
            ['RS3', chNew.toString()],
            ['11ed2692eeb9680c8f91ac1f6b1b6a6e', chiNew.toString()],
            ['11ed2692dae5ee328f91ac1f6b1b6a6e', infNew.toString()],
          ],
          '',
          selectedRate,
        ];
        accommodation.push(accommodationData);
      });
    }
  });

  // TEMP CODE
  // setIsLoading(false);
  console.log('ACCOMMODATION 3');
  console.log(JSON.stringify(accommodation, undefined, 4));

  // console.log('BOOKINGS NEW');
  // console.log(JSON.stringify(bookings, undefined, 4));
  // setIsLoading(false);
  // return;

  const itineraryData = bookings;
  const dateStart = bookings[0].startDay;
  const img = `${properties[getPropObj(bookings[0].propUid, properties)].heroImageUrl}/320x200`;
  const dateEnd = bookings[bookings.length - 1].endDay;
  //Totals
  // const totalAdults = Math.max(
  // 	...bookings.map(({ rooms }) => Math.max(...rooms.map(({ ad }) => ad)))
  // );

  // OLD CODE
  // const totalAdults = Math.max(...bookings.map(({ rooms }) => rooms.reduce((sum, { ad }) => sum + ad, 0)));
  // const totalChildren = Math.max(...bookings.map(({ rooms }) => rooms.reduce((sum, { ch, chi, inf }) => sum + ch + chi + inf, 0)));

  let maxTotalGuests = 0;
  let bookingWithHighestTotalPax;

  // Find the booking with the highest total number of guests
  bookings.forEach((booking) => {
    const totalGuestsInBooking = booking.rooms.reduce((sum, room) => {
      return sum + room.ad + room.ch + room.chi + room.inf;
    }, 0);

    if (totalGuestsInBooking > maxTotalGuests) {
      maxTotalGuests = totalGuestsInBooking;
      bookingWithHighestTotalPax = booking;
    }
  });

  // Calculate the total adults and total children for the booking with the highest total number of guests
  const totalAdults = bookingWithHighestTotalPax ? bookingWithHighestTotalPax.rooms.reduce((sum, room) => sum + room.ad, 0) : 0;

  const totalChildren = bookingWithHighestTotalPax ? bookingWithHighestTotalPax.rooms.reduce((sum, room) => sum + room.ch + room.chi + room.inf, 0) : 0;

  // total guides
  const totalGuides = countGuides(guests);

  
  // ! MAKE totalPrice -> START

  let propertiesTotal;
  // finalPageData total
  if ((!user.internalUser && user.fullBoardOnly) || !user.betaTester || mojoItinerary === true) {
    const totalProperties = bookings.reduce((total, booking) => {
      let price = 0;

      if (booking.rooms.some((room) => room.hasOwnProperty('rate') && room.rate && (room.rate.payable || room.rate.nett))) {
        price = booking.rooms
          .filter((room) => room.hasOwnProperty('rate') && room.rate && (room.rate.payable || room.rate.nett))
          .reduce((price, room) => {
            return price + ((room.rate && (room.rate.payable || room.rate.nett)) || 0);
          }, 0);
      }
      return total + price;
    }, 0);
    propertiesTotal = totalProperties;
  } else {
    // finalPageData total
    let finalPageDataTotal = 0;
    finalPageDataTotal = finalPageData.length > 0 && getTotalFromFinalPageData(finalPageData);
    propertiesTotal = finalPageDataTotal + getTotalFromBookings(bookings);
  }

  console.log('DEBUG TOTAL COST');

  console.log('propertiesTotal: ' + propertiesTotal);

  // transfers total
  let transfersDataTotal = 0;
  let transfersTotal = 0;
  transfersDataTotal = transfers.length > 0 && getTotalFromTransfers(transfers);
  console.log('transfersDataTotal: ' + transfersDataTotal);
  const finalPageTransferDataTotal = getTotalFromFinalPageTransferData(finalPageTransferData);
  console.log('transfersDataTotal total + final page transfers: ' + transfersDataTotal + finalPageTransferDataTotal);
  transfersTotal = transfersDataTotal + finalPageTransferDataTotal;

  const customItemsTotal = getTotalFromCustomItems(customFinalItems);

  // transfer vehicles total
  const bookingsForVehicles = getTotalFromBookingsForVehicles(bookings, properties);

  // activities total
  let activitiesDataTotal = 0;
  activitiesDataTotal = getTotalFromActivities(activitiesDataTemp);
  console.log('activitiesDataTemp');
  console.log(JSON.stringify(activitiesDataTemp, undefined, 4));

  console.log('activitiesDataTotal: ' + activitiesDataTotal);
  console.log('otherArrangementsData: ' + getTotalFromOtherArrangements(otherArrangementsData));

  const totalPrice = getTotalFromBookings(bookings) +
  getTotalFromActivities(activitiesDataTemp) +
  getTotalFromTransfers(transfers) +
  getTotalFromBookingsForVehicles(bookings, properties) +
  getTotalFromOtherArrangements(otherArrangementsData) +
  getTotalFromCustomItems(customFinalItems) +
  getTotalFromFinalPageData(finalPageData) +
  getTotalFromFinalPageTransferData(finalPageTransferData)

  const totalPriceOriginal = propertiesTotal + activitiesDataTotal + transfersTotal + getTotalFromOtherArrangements(otherArrangementsData) + bookingsForVehicles + customItemsTotal;
  //const totalPrice = jsonData.reduce((total, current) => total + current.saleTotalFinal, 0);

  console.log('totalPrice: ' + totalPrice);
  // ! MAKE totalPrice -> END

  // Flatten the activitiesDataTemp array
  const activitiesData = activitiesDataTemp.map((activityData) => {
    const { activities, ...rest } = activityData;
    const modifiedActivities = activities.map((activity) => {
      return { values: activity };
    });
    return { ...rest, activities: modifiedActivities };
  });

  const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;

  const transfersData = transfers;

  const url = '/api/resreq';

  // calcualte now, and difference between now and timestampStartedEdit
  const now = new Date();
  let duration = moment(now).diff(moment(timestampStartedEdit)); // Get the difference in milliseconds
  let milliseconds = duration;

  // check total length of accommodation rather than nomadTanzaniaBookings so that we add northern guide vehicles
  if (accommodation.length > 0) {
    // if (nomadTanzaniaBookings.length > 0) {

    let data;

    data = JSON.stringify({
      method: 'rv_update_itinerary',
      params: [
        {
          bridge_username: '',
          bridge_password: '',
          link_id: link_id,
        },
        resReqReservationId,
        currentItineraryIds,
        accommodation,
        '',
      ],
      id: 1,
    });
    // RP 13/05/24 - START
    if (resReqReservationId === null) {
      let agentCommission = user.internalUser ? agentObject.rateBandOffline.commission : agentObject.rateBand.commission;

      if (typeof agentCommission === 'number') {
        agentCommission = agentCommission.toString();
      }

      data = JSON.stringify({
        method: 'rv_create',
        params: [
          {
            bridge_username: '',
            bridge_password: '',
            link_id: link_id,
          },
          accommodation,
          '',
          resRequestStatusId,
          itineraryName,
          '',
          'No notes',
          '',
          agentObject.resRequestId,
          '',
          '',
          agentCommission,
          '',
          '',
          user.resRequestId,
          '',
          agentObject.resRequestId,
        ],
        id: 1,
      });
    }
    // RP 13/05/24 - END

    console.log('resReqReservationId: ', resReqReservationId);
    console.log('DEBUG DATA');
    console.log(data);
    console.log(JSON.stringify(data, undefined, 4));

    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(async function (response) {
        console.log(response.status);
        console.log(JSON.stringify(response.data, undefined, 4));

        if (response.status === 200) {
          if (response.data.error) {
            setIsLoading(false);

            if (response.data.error.faultCode === 800 && response.data.error.faultString === 'Not enough stock available.') {
              setCallUpdateDates(true);
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                      <p className="text-sm font-semibold pb-2">No Availability</p>
                      <p className="text-sm pb-2">One or more of the rooms you selected are now fully booked.</p>
                      <p className="text-sm pb-0">Click "Reload Stock" to update the availability and return</p>
                      <p className="text-sm pb-2">to the Properties page.</p>
                      <div className="flex mt-5 justify-end">
                        <div>
                          <button
                            className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                            onClick={async () => {
                              setStep('bookings');
                              onClose();
                            }}
                          >
                            Reload stock
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                },
              });
            } else {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                      <p className="text-sm font-semibold pb-2">Error saving data to ResRequest</p>
                      <p className="text-sm pb-2">{response.data.error.faultString}</p>
                      <p className="text-sm pb-2"></p>
                      <div className="flex mt-5 justify-end">
                        <div>
                          <button
                            className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                            onClick={async () => {
                              //setStep('bookings');
                              onClose();
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                },
              });
              console.log(response.data.error.faultString);
            }

            //alert("Error: " + response.data.error.faultString);
          } else {
            console.log('rv_update_itinerary');
            console.log(JSON.stringify(response.data.result, undefined, 4));

            //const oldItinerary = itineraryStore;
            const oldItinerary = liveItinerary;
            const data = {};
            for (let prop in oldItinerary) {
              data[prop] = oldItinerary[prop];
            }

            try {
              console.log('createItinerary 3: ');

              // Total nights
              const start = moment(dateStart, 'YYYY-MM-DD');
              const end = moment(dateEnd, 'YYYY-MM-DD');
              const totalNights = end.diff(start, 'days');

              // NEW CODE - START
              const itineraryObject = {
                ...(resReqReservationId === null ? { resReqReservationId: response.data.result } : {}),
                //dateExpiryNomad
                resRequestStatus: resRequestStatusId,
                itineraryData,
                activitiesData,
                itineraryName,
                itineraryRenamed,
                dateModified: new Date(),
                timestampStartedEdit,
                timeToCreateEditMilliseconds: milliseconds,
                dateStart: dateStart,
                dateEnd: dateEnd,
                dateStartTimestamp: moment.utc(dateStart).valueOf(),
                dateEndTimestamp: moment.utc(dateEnd).valueOf(),
                totalNights,
                img: img,
                totalAdults,
                totalChildren,
                totalPrice,
                itineraryUrl: deleteField(),
                resRequestSummaryUrl: deleteField(),
                costingUrl: deleteField(),
                invoiceUrl: deleteField(),
                transfersData,
                jsonData,
                calculatedPricing: finalPageData,
                transfersPricing: finalPageTransferData,
                otherArrangementsData,
                version,
                quoteMode: quoteMode,
                roomPax,
                guests: true,
                userVersion: newVersion ? userVersionLive + 1 : userVersionLive,
                customFinalItems,
                editVersion: packageJsonVersion,
                hidden,
                reprintInvoice,
                totalGuides,
                advancedMode,
              };


              const setUndefinedToNull = (obj, path = '') => {
                console.log('Entered setUndefinedToNull with obj:', obj, 'and path:', path);
                for (const [key, value] of Object.entries(obj)) {
                  const newPath = path ? `${path}.${key}` : key;

                  if (value === undefined) {
                    console.error(`Undefined value found at path: ${newPath}. Setting to null.`);
                    obj[key] = null; // Use null instead of "null", unless you intend to make it a string
                  } else if (typeof value === 'object' && value !== null) {
                    setUndefinedToNull(value, newPath);
                  }
                }
              };

              const processEntireObject = (itineraryObjectData) => {
                console.log('Entered processEntireObject with itineraryObjectData:', itineraryObjectData);
                console.log('all addDoc json START');
                console.log(JSON.stringify(itineraryObjectData, undefined, 4));
                setUndefinedToNull(itineraryObjectData);
                console.log('Processed object to replace undefined with null:');
                console.log(JSON.stringify(itineraryObjectData, undefined, 4));
                console.log('all addDoc json END');
              };

              // Run the function to process the entire intineraryObject object
              processEntireObject(itineraryObject);
              data.version = vcVersion; // 11/06/24 - added version
              data.userVersion = userVersionLive; // 11/06/24 - added version
              processEntireObject(data);

              // console.log('itinerariesVersionControl');
              // console.log(JSON.stringify(data, undefined, 4));

              // !const docRef = await addDoc(collection(db, 'itinerariesVersionControl'), data);
              try {
                const versionControlId = await createItineraryVersionControl(db, data, guestsStore);
                console.log(`Version control document created with ID: ${versionControlId}`);
              } catch (error) {
                console.error('Failed to create version control document:', error);
              }

              // NEW CODE - END

              // !const addUid = doc(db, 'itineraries', uid);
              // !await updateDoc(addUid, itineraryObject);
              const updatedItineraryId = await updateItineraryWithGuests(db, uid, itineraryObject, guests);

              // update resRequest status
              if (response.data.result !== null && resReqReservationId === null) {
                await updateItineraryStatusEditItinerary(response.data.result, resRequestStatusId, status, uid, user.uid, bookings[0].startDay, false);
                console.log('updated resRequest status');
              }

              // EXTRAS START

              const countNorthernGuideVehicles = bookingsDataStore.reduce((acc, booking) => {
                return acc + booking.rooms.filter((room) => room.northernGuideVehicleSupplement).length;
              }, 0);

              // Delete extras - start
              if (
                activitiesDataStore.some((property) => property.activities.some((activityArr) => activityArr.some((activity) => activity.type === 'resRequestExtra'))) ||
                countNorthernGuideVehicles > 0
              ) {
                console.log('found extra to delete');
                const extras = await getExtra(resReqReservationId);
                console.log('extras:');
                console.log(JSON.stringify(extras, undefined, 4));
                const promisesDelete = [];

                // Iterate over the 'extras' array and perform the deleteExtra calls
                extras.forEach((extra) => {
                  const id = extra.id;
                  promisesDelete.push(deleteExtra(resReqReservationId, id));
                  console.log('resReqReservationId: ' + resReqReservationId + ' id: ' + id);
                });

                Promise.all(promisesDelete).then(() => {
                  // Create extras - start
                  const promises = [];
                  // ACTIVITY EXTRAS
                  for (let index = 0; index < activitiesDataTemp.length; index++) {
                    const property = activitiesDataTemp[index];
                    property.activities.forEach((activityArr) => {
                      activityArr.forEach(async (activity) => {
                        if (activity.type === 'resRequestExtra') {
                          //const resReqReservationId = response.data.result;
                          let extraId;
                          if (bookings[index].rateName.toLowerCase().includes('fullboard') || bookings[index].rateName.toLowerCase().includes('fullboardexclusive')) {
                            extraId = activity.ids.resRequestExtraFullBoard ? activity.ids.resRequestExtraFullBoard : activity.ids.resRequestExtra;
                          } else {
                            extraId = activity.ids.resRequestExtra;
                          }
                          const date = activity.selectedActivityDate;
                          const totals = calculateRoomTotals(bookings, index);
                          const totalChildren = totals.totalCh + totals.totalChi + totals.totalInf;
                          const propId = properties[getPropObj(property.propUid, properties)].platformResRequest.id;

                          console.log('*** totalChildren: ' + totalChildren);
                          promises.push(addExtra(resReqReservationId, extraId, date, totals.totalAd, totalChildren, activity.activityUnits, propId, date, ''));
                          console.log(`Property ${index} has an activity of type resRequestExtra`);
                        }
                      });
                    });
                  }

                  console.log('EXTRAS 1');
                  console.log(JSON.stringify(promises, undefined, 4));

                  // NORTHERN GUIDE VEHICLE EXTRAS
                  bookings.forEach((booking) => {
                    const note = 'Nomad Northern Guide vehicle while at ' + booking.propName;

                    for (let x = 0; x < booking.rooms.length; x++) {
                      if (booking.rooms[x].northernGuideVehicleSupplement) {
                        // const resReqReservationId = response.data.result;
                        const start = moment(booking.startDay);
                        const end = moment(booking.endDay);
                        //const totalDays = end.diff(start, 'days') + 1; // +1 to make it inclusive
                        const totalDays = end.diff(start, 'days'); // +1 to make it inclusive
                        promises.push(addExtra(resReqReservationId, booking.rooms[x].northernGuideVehicleResRequestId, booking.startDay, 0, 0, totalDays, '', booking.endDay, note));
                      }
                    }
                  });

                  console.log('EXTRAS 2');
                  console.log(JSON.stringify(promises, undefined, 4));

                  Promise.all(promises).then(() => {
                    console.log('createItinerary 4: ');
                    setNavigateToManageItineraries(true);
                  });
                });
              } else {
                console.log('createItinerary 5: ');
                setNavigateToManageItineraries(true);
              }
            } catch (error) {
              console.log(error);
            }

            return response.data.result;
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    //

    if (resReqReservationId !== null) {
      await updateItineraryStatusDelete(resReqReservationId, uid);
    }

    console.log('createItinerary 6: ');

    // NEW CODE - START
    const itineraryObject = {
      resRequestStatus: resRequestStatusId,
      itineraryData,
      activitiesData,
      itineraryName,
      itineraryRenamed,
      dateModified: new Date(),
      timestampStartedEdit,
      timeToCreateEditMilliseconds: milliseconds,
      dateStart: dateStart,
      dateEnd: dateEnd,
      dateStartTimestamp: moment.utc(dateStart).valueOf(),
      dateEndTimestamp: moment.utc(dateEnd).valueOf(),
      img: img,
      totalAdults,
      totalChildren,
      totalPrice,
      itineraryUrl: deleteField(),
      resRequestSummaryUrl: deleteField(),
      costingUrl: deleteField(),
      invoiceUrl: deleteField(),
      transfersData,
      jsonData,
      calculatedPricing: finalPageData,
      transfersPricing: finalPageTransferData,
      otherArrangementsData,
      version,
      quoteMode: quoteMode,
      roomPax,
      guests: true,
      userVersion: newVersion ? userVersionLive + 1 : userVersionLive,
      customFinalItems,
      editVersion: packageJsonVersion,
      hidden,
      reprintInvoice,
      totalGuides,
      advancedMode,
    };

    const setUndefinedToNull = (obj, path = '') => {
      console.log('Entered setUndefinedToNull with obj:', obj, 'and path:', path);
      for (const [key, value] of Object.entries(obj)) {
        const newPath = path ? `${path}.${key}` : key;

        if (value === undefined) {
          console.error(`Undefined value found at path: ${newPath}. Setting to null.`);
          obj[key] = null; // Use null instead of "null", unless you intend to make it a string
        } else if (typeof value === 'object' && value !== null) {
          setUndefinedToNull(value, newPath);
        }
      }
    };

    const processEntireObject = (itineraryObjectData) => {
      console.log('Entered processEntireObject with itineraryObjectData:', itineraryObjectData);
      console.log('all addDoc json START');
      console.log(JSON.stringify(itineraryObjectData, undefined, 4));
      setUndefinedToNull(itineraryObjectData);
      console.log('Processed object to replace undefined with null:');
      console.log(JSON.stringify(itineraryObjectData, undefined, 4));
      console.log('all addDoc json END');
    };

    // Run the function to process the entire intineraryObject object
    processEntireObject(itineraryObject);

    // NEW CODE - END
    console.log('createItinerary 8: ');
    //const oldItinerary = itineraryStore;
    const oldItinerary = liveItinerary;
    const data = {};
    for (let prop in oldItinerary) {
      data[prop] = oldItinerary[prop];
    }
    data.version = vcVersion; // 11/06/24 - added version
    data.userVersion = userVersionLive; // 11/06/24 - added version
    processEntireObject(data);

    // !const docRef = await addDoc(collection(db, 'itinerariesVersionControl'), data);
    try {
      const versionControlId = await createItineraryVersionControl(db, data, guestsStore);
      console.log(`Version control document created with ID: ${versionControlId}`);
    } catch (error) {
      console.error('Failed to create version control document:', error);
    }

    // NEW CODE - END

    // !const addUid = doc(db, 'itineraries', uid);
    // !await updateDoc(addUid, itineraryObject);
    const updatedItineraryId = await updateItineraryWithGuests(db, uid, itineraryObject, guests);

    // const docRef = await addDoc(collection(db, 'itinerariesVersionControl'), data);

    // const addUid = doc(db, 'itineraries', uid);
    // await updateDoc(addUid, itineraryObject);

    console.log('createItinerary 9: ');
    setNavigateToManageItineraries(true);
  }
};

const getExtra = async (resReqReservationId) => {
  const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
  const url = '/api/resreq';

  var data = JSON.stringify({
    method: 'rv_get_extra',
    params: [
      {
        bridge_username: '',
        bridge_password: '',
        link_id: link_id,
      },
      resReqReservationId,
    ],
    id: 1,
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios(config);
    console.log('response.status: ' + response.status);
    console.log(JSON.stringify(response.data, undefined, 4));

    if (response.status === 200) {
      if (response.data.error && response.data.result === null) {
        return Promise.reject(response.data.error.faultString);
      } else {
        console.log('got extras deleted');
        return Promise.resolve(response.data.result);
      }
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteExtra = async (resReqReservationId, extraId) => {
  const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
  const url = '/api/resreq';

  var data = JSON.stringify({
    method: 'rv_delete_extra',
    params: [
      {
        bridge_username: '',
        bridge_password: '',
        link_id: link_id,
      },
      resReqReservationId,
      extraId,
      'Updated itinerary',
    ],
    id: 1,
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios(config);
    console.log('response.status: ' + response.status);
    console.log(JSON.stringify(response.data, undefined, 4));

    if (response.status === 200) {
      if (response.data.error && response.data.result === null) {
        return Promise.reject(response.data.error.faultString);
      } else {
        console.log('extra deleted');
        return Promise.resolve(true);
      }
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const convertFinalPageDataFirestoreTimestampsToISOStrings = (arrayOfObjects) => {
  return arrayOfObjects.map((obj) => {
    // Deep clone the object to avoid mutating the original
    const newObj = JSON.parse(JSON.stringify(obj));

    // Convert Firestore timestamps to Date objects and then to ISO strings
    if (newObj.startDay && newObj.startDay.seconds !== undefined) {
      const startDayDate = new Date(newObj.startDay.seconds * 1000);
      newObj.startDay = startDayDate.toISOString();
    }

    if (newObj.endDay && newObj.endDay.seconds !== undefined) {
      const endDayDate = new Date(newObj.endDay.seconds * 1000);
      newObj.endDay = endDayDate.toISOString();
    }

    return newObj;
  });
};

export { searchItineraries, loadGetItinerary, updateItinerary, convertFinalPageDataFirestoreTimestampsToISOStrings };
