import React from 'react';

const ViewCompTableHeader = () => {
  return (
    <div className="hidden sm:grid sm:grid-cols-16 gap-2 p-4 text-xs font-medium bg-gray-50 text-gray-500">
      <div className="sm:col-span-5 py-2 sm:py-0">DESCRIPTION</div>
      <div className="sm:col-span-3 py-2 sm:py-0">SUPPLIER</div>
      <div className="sm:col-span-2 text-left sm:text-center py-2 sm:py-0">NIGHTS</div>
      <div className="sm:col-span-2 text-left sm:text-center py-2 sm:py-0">UNITS</div>
      <div className="sm:col-span-2 py-2 sm:py-0">UNIT PRICE</div>
      <div className="sm:col-span-2 py-2 sm:py-0">TOTAL</div>
    </div>
  );
};

export default ViewCompTableHeader;