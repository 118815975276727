import React from 'react';

const ItineraryVersionMenu = ({ itinerariesVcMenu }) => {
  return (
    <div className="top-[-60px] w-[320px] flex justify-end items-end" style={{ marginTop: '-60px' }}>
      <div className="flex mt-1">
        <div className="text-gray-800 text-base font-semibold">{itinerariesVcMenu}</div>
      </div>
    </div>
  );
};

export default ItineraryVersionMenu;
