import {
  CalendarIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardIcon,
  ClockIcon,
  CreditCardIcon,
  EllipsisVerticalIcon,
  InformationCircleIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import { Checkbox, Dialog, DialogPanel, DialogTitle, Menu, MenuButton, MenuItem, MenuItems, Transition, TransitionChild } from '@headlessui/react';
import React, { Fragment, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { addDays, differenceInDays, differenceInHours, differenceInMinutes, format, formatDistanceToNow, isBefore, isToday, isWithinInterval, isYesterday, parseISO } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { updateAgentReference, updateClientName } from '../../../../functions/viewItineraryFunctions';
import ButtonOutlineSmall from '../../../../components/Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from '../../../../components/Buttons/ButtonPrimarySmall';
import { ExtendProvisionalStatus } from '../functions/ExtendProvisionalFunction';
import ItineraryEmailModal from '../../../../components/SendEmailModal';
import ReactTooltip from 'react-tooltip';
import InfoBox from '../../../../components/InfoBox';
import { fetchConsultantData, updateItineraryHiddenStatus} from '../../../../functions/viewItineraryFunctions';
import { generateItineraryEmailHTML } from '../utils/emailTemplates';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getItineraryStatusLabel } from '../../../../constants/lookups';

const OrderDetails = ({ booking, user, selectedCompanyPermissions, setIsSlideOutOpen }) => {
  const navigate = useNavigate();

  const [consultant, setConsultant] = useState(null);
  const [bookingOwner, setBookingOwner] = useState(null);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isReferenceDialogOpen, setIsReferenceDialogOpen] = useState(false);
  const [newReference, setNewReference] = useState(booking.agentReference || '');
  const [isClientNameDialogOpen, setIsClientNameDialogOpen] = useState(false);
  const [newClientName, setNewClientName] = useState(booking.clientName || '');
  const showFinancialDetails = booking.status === 'confirmed' || booking.status === 'cancelled' || booking.status === 'pendingCancellation';
  const [isExtendDialogOpen, setIsExtendDialogOpen] = useState(false);
  const [canExtend, setCanExtend] = useState(false);
  const [formattedStatus, setFormattedStatus] = useState('');
  const [isExtending, setIsExtending] = useState(false);
  const [newExpiryDate, setNewExpiryDate] = useState(null);
  const extendButtonTooltipId = `extend-button-tooltip-${booking.uid}`;
  const showExtendButton = booking.status.toLowerCase() === 'provisional';
  const [sendNotificationEmail, setSendNotificationEmail] = useState(false);
  const [copySelfEmail, setCopySelfEmail] = useState(false);

  const [isAdminExtendDialogOpen, setIsAdminExtendDialogOpen] = useState(false);
  const [adminExtensionDays, setAdminExtensionDays] = useState(1);
  const [isAdminExtending, setIsAdminExtending] = useState(false);

  const [extensionDays, setExtensionDays] = useState(0);

  //Show toast for when the user sets 'hidden' variable
  const toggleHiddenStatus = async () => {
    try {
      await updateItineraryHiddenStatus(booking.uid, !booking.hidden);
      toast.success(`Itinerary ${booking.hidden ? 'unhidden' : 'hidden'} successfully`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // You might want to update the local state or trigger a refetch here
    } catch (error) {
      console.error('Error updating hidden status:', error);
      toast.error('Failed to update itinerary visibility. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  useEffect(() => {
    if (isExtendDialogOpen) {
      const days = calculateExtensionDays();
      setExtensionDays(days);
      const currentExpiryDate = booking.dateExpiry ? booking.dateExpiry.toDate() : new Date();
      const newDate = addDays(currentExpiryDate, days);

      // Adjust to end of UTC day

      const newExpiryDateUTC = new Date(Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), 23, 59, 59, 999));

      setNewExpiryDate(newExpiryDateUTC);
    }
  }, [isExtendDialogOpen, booking.dateExpiry]);

  const handleReferenceUpdate = async () => {
    try {
      await updateAgentReference(booking.uid, newReference);
      setIsReferenceDialogOpen(false);
      // You might want to update the local state or refetch the booking data here
    } catch (error) {
      console.error('Error updating reference:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };



  const handleClientNameUpdate = async () => {
    try {
      await updateClientName(booking.uid, newClientName);
      setIsClientNameDialogOpen(false);
      // You might want to update the local state or refetch the booking data here
    } catch (error) {
      console.error('Error updating client name:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    if (booking.dateExpiry && booking.status === 'provisional') {
      const expiryDate = booking.dateExpiry.toDate();
      const now = new Date();
      const daysUntilExpiry = differenceInDays(expiryDate, now);

      if (user.internalUser) {
        setCanExtend(daysUntilExpiry <= 2);
      } else {
        setCanExtend(daysUntilExpiry <= 2 && !booking.provisionalExtended);
      }
    } else {
      setCanExtend(false);
    }
  }, [booking.dateExpiry, booking.status, booking.resReqReservationId, user.internalUser, booking.provisionalExtended]);

  useEffect(() => {
    setFormattedStatus(getItineraryStatusLabel(booking.status));
  }, [booking.status]);

  const calculateExtensionDays = () => {
    const today = new Date();
    const startDate = booking.dateStart ? parseISO(booking.dateStart) : null;
    const currentExpiryDate = booking.dateExpiry ? booking.dateExpiry.toDate() : null;

    if (!startDate || !currentExpiryDate) {
      console.error('Missing start date or expiry date');
      return 1; // Default to 1 day if dates are missing
    }

    const daysUntilStart = differenceInDays(startDate, today);

    if (daysUntilStart < 30) {
      return 1;
    } else {
      const isHighSeason = (date) => {
        const month = date.getMonth();
        const day = date.getDate();
        return (
          month === 5 || // June
          month === 6 || // July
          (month === 11 && day >= 20) || // December 20th onwards
          (month === 0 && day <= 4) // January 1st to 4th
        );
      };

      if (daysUntilStart < 80 || isHighSeason(startDate) || isWithinInterval(startDate, { start: new Date(startDate.getFullYear(), 11, 20), end: new Date(startDate.getFullYear() + 1, 0, 4) })) {
        return 3;
      } else {
        return 7;
      }
    }
  };

  const formatStatus = (status) => {
    if (!status) return '';

    return status
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const formatExpiryInfo = () => {
    if (booking.status.toLowerCase() !== 'provisional' || !booking.dateExpiry) return null;

    const expiryDate = booking.dateExpiry.toDate();
    const now = new Date();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedExpiryDate = utcToZonedTime(expiryDate, userTimeZone);
    const zonedNow = utcToZonedTime(now, userTimeZone);

    const formatRelativeTime = () => {
      const totalHours = differenceInHours(zonedExpiryDate, zonedNow);
      const days = differenceInDays(zonedExpiryDate, zonedNow);
      const hours = totalHours % 24;

      if (totalHours < 24) {
        const minutes = differenceInMinutes(zonedExpiryDate, zonedNow) % 60;
        return `${hours} hours ${minutes} mins`;
      } else if (days === 1) {
        return `1 day ${hours} hours`;
      } else {
        return `${days} days ${hours} hours`;
      }
    };

    const tooltipId = `expiry-tooltip-${booking.uid}`;
    const localExpiryFormatted = formatInTimeZone(expiryDate, userTimeZone, "EEEE do MMMM yyyy 'at' HH:mm zzz");
    const utcExpiryFormatted = formatInTimeZone(expiryDate, 'UTC', "EEEE do MMMM yyyy 'at' HH:mm 'UTC'");

    return (
      <>
        <span className="text-amber-600">- Expires in {formatRelativeTime()}</span>
        <InformationCircleIcon className="inline-block h-5 w-5 text-gray-400 cursor-help ml-1" data-tip data-for={tooltipId} />
        <ReactTooltip id={tooltipId} place="top" effect="solid">
          <div className="text-sm">
            <p>This provisional will expire on {localExpiryFormatted}</p>
            <p></p>
            <p>You can extend your provisional within 48 hours of the expiry date.</p>
          </div>
        </ReactTooltip>
      </>
    );
  };

  const handleExtend = async () => {
    setIsExtending(true);
    const extensionDays = calculateExtensionDays();
    const currentExpiryDate = booking.dateExpiry ? booking.dateExpiry.toDate() : new Date();
    const newExpiryDate = addDays(currentExpiryDate, extensionDays);

    try {
      await ExtendProvisionalStatus(booking, newExpiryDate, user.uid, user, sendNotificationEmail, copySelfEmail, consultant);
      console.log(`Extension successful. Extended by ${extensionDays} days.`);

      // Show success toast
      toast.success(`Expiry date extended to ${formatDateTimeWithShortTZ(newExpiryDate)}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Update local state or trigger a refresh here
      setIsExtendDialogOpen(false);
      // You might want to update the booking object here or trigger a refetch
    } catch (error) {
      console.error('Error extending expiry date:', error);

      // Show error toast
      toast.error('Failed to extend expiry date. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsExtending(false);
    }
  };

  // Admin internal user extend
  const handleAdminExtend = async () => {
    setIsAdminExtending(true);
    const currentExpiryDate = booking.dateExpiry ? booking.dateExpiry.toDate() : new Date();
    const newExpiryDate = addDays(currentExpiryDate, adminExtensionDays);

    try {
      await ExtendProvisionalStatus(booking, newExpiryDate, user.uid, user, sendNotificationEmail, copySelfEmail, consultant);
      console.log(`Admin extension successful. Extended by ${adminExtensionDays} days.`);

      toast.success(`Expiry date extended to ${formatDateTimeWithShortTZ(newExpiryDate)}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setIsAdminExtendDialogOpen(false);
      // You might want to update the booking object here or trigger a refetch
    } catch (error) {
      console.error('Error extending expiry date:', error);

      toast.error('Failed to extend expiry date. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsAdminExtending(false);
    }
  };

  useEffect(() => {
    const getConsultantData = async () => {
      if (booking.consultant?.uid) {
        try {
          const consultantData = await fetchConsultantData(booking.consultant.uid);
          setConsultant(consultantData);
        } catch (error) {
          console.error('Error fetching consultant data:', error);
          // Silently fail, consultant will remain null
        }
      }

      // Fetch booking owner data
      if (booking.bookingOwner?.uid) {
        try {
          const bookingOwnerData = await fetchConsultantData(booking.bookingOwner.uid);
          setBookingOwner(bookingOwnerData);
        } catch (error) {
          console.error('Error fetching booking owner data:', error);
          // Silently fail, bookingOwner will remain null
        }
      }
    };

    getConsultantData();
  }, [booking.consultant?.uid, booking.bookingOwner?.uid]);

  // Generate the standard email content
  const { header, body, footer, subject } = generateItineraryEmailHTML({
    booking,
    userAddedContent: `Additional information for ${booking.clientName}'s itinerary:`,
    consultant: consultant,
  });

  // handle the edit guests slideout state 
  const handleOpenGuestsSlideout = () => {
    setIsSlideOutOpen(true);
  };


  // format dates to dd MMM yyyy
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return format(parseISO(dateString), 'dd MMM yyyy');
  };

  // Format last modified date
  const formatFirestoreTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.seconds) {
      return 'N/A';
    }
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);

    if (isToday(date)) {
      return `Today at ${format(date, 'h:mm a')}`;
    } else if (isYesterday(date)) {
      return `Yesterday at ${format(date, 'h:mm a')}`;
    } else if (date > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)) {
      // If within the last week
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return format(date, "MMMM d, yyyy 'at' h:mm a");
    }
  };

  const formatDateTimeWithShortTZ = (date) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return formatInTimeZone(date, userTimeZone, "dd MMMM yyyy 'at' HH:mm zzz");
  };

  const formattedDateModified = formatFirestoreTimestamp(booking.dateModified);

  const getTotalGuests = () => {
    const adults = booking.totalAdults || 0;
    const children = booking.totalChildren || 0;
    return adults + children;
  };

  // format currencies
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  };

  const getDateStatus = (dateString) => {
    if (!dateString) return 'neutral';
    const date = parseISO(dateString);
    const today = new Date();
    if (isBefore(date, today)) return 'overdue';
    if (isBefore(date, new Date(today.setDate(today.getDate() + 14)))) return 'approaching';
    return 'neutral';
  };

  const totalPrice = booking.totalPrice || 0;
  const totalPaid = booking.totalPaid || 0;
  const totalDeposit = booking.totalDeposit || 0;
  const outstanding = totalPrice - totalPaid;
  const balanceDue = Math.min(totalPrice - totalDeposit, outstanding);

  const isDepositPaid = totalPaid >= totalDeposit;
  const isFullyPaid = outstanding === 0;

  const depositDueStatus = isDepositPaid ? 'paid' : getDateStatus(booking.depositDueDate);
  const balanceDueStatus = isFullyPaid ? 'paid' : getDateStatus(booking.balanceDueDate);


  const showOriginatingAgent = 
  selectedCompanyPermissions.accessLevel === 'limited' || 
  user.internalUser || 
  booking.originatingAgent;

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      {/* Header */}
      <div className="flex items-start justify-between p-6 bg-gray-50">
        <div>
          <h2 className="text-lg font-semibold flex items-center gap-2 group">
            Ref: {booking.code ? `${booking.code} - ` : ''}
            {booking.clientName || 'Unknown Client'}
          </h2>
          <p className="text-sm text-gray-500">
            Status: {formattedStatus} {formatExpiryInfo()}
          </p>
          {showExtendButton && (
            <div className="mt-2 relative inline-block">
              <button
                onClick={() => canExtend && setIsExtendDialogOpen(true)}
                className={`inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md ${
                  canExtend ? 'text-blue-700 bg-blue-100 hover:bg-blue-200' : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                } focus:outline-none transition ease-in-out duration-150`}
                disabled={!canExtend}
                data-tip
                data-for={extendButtonTooltipId}
              >
                <CalendarIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                Extend Expiry
              </button>
              {!canExtend && (
                <ReactTooltip id={extendButtonTooltipId} place="bottom" effect="solid">
                  <span>You can extend your provisional within 2 days of the expiry date.</span>
                </ReactTooltip>
              )}
            </div>
          )}
        </div>
        <div className="flex items-center gap-2">
          {user.internalUser && (
            <Menu as="div" className="relative">
              <MenuButton className="p-2 rounded-md border border-gray-300 hover:bg-gray-50">
                <EllipsisVerticalIcon className="h-4 w-4" />
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="absolute right-0 mt-2 w-56 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <button
                        onClick={handleOpenGuestsSlideout}
                        className={`${active ? 'bg-gray-100' : ''} block w-full text-left px-4 py-2 text-sm text-gray-700`}
                      >
                        View/Edit Guests
                      </button>
                    )}
                  </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <button
                          onClick={() => navigate(`/itinerary/edit/editItinerary/${booking.uid}`, { state: { booking } })}
                          className={`${active ? 'bg-gray-100' : ''} block w-full text-left px-4 py-2 text-sm text-gray-700`}
                        >
                          Edit Itinerary
                        </button>
                      )}
                    </MenuItem>
                    {user.internalUser && (
                      <>
                        <MenuItem>
                          {({ active }) => (
                            <button onClick={() => setIsEmailModalOpen(true)} className={`${active ? 'bg-gray-100' : ''} block w-full text-left px-4 py-2 text-sm text-gray-700`}>
                              Send Itinerary via email
                            </button>
                          )}
                        </MenuItem>
                        {booking.status.toLowerCase() === 'provisional' &&
                          (user.email === 'egidius@nomad-tanzania.net' ||
                            user.email === 'egidius@nomad-tanzania.com' ||
                            user.email === 'harry.prowse@nomad-tanzania.com' ||
                            user.email === 'harry.prowse@nomad-tanzania.net' ||
                            user.email === 'trevor@nomad-tanzania.com' ||
                            user.email === 'trevor@nomad-tanzania.net') && (
                            <MenuItem>
                              {({ active }) => (
                                <button onClick={() => setIsAdminExtendDialogOpen(true)} className={`${active ? 'bg-gray-100' : ''} block w-full text-left px-4 py-2 text-sm text-gray-700`}>
                                  Extend Provisional (Admin)
                                </button>
                              )}
                            </MenuItem>
                          )}
                        <MenuItem>
                          {({ active }) => (
                            <button onClick={toggleHiddenStatus} className={`${active ? 'bg-gray-100' : ''} block w-full text-left px-4 py-2 text-sm text-gray-700`}>
                              {booking.hidden ? 'Unhide Itinerary' : 'Mark Itinerary as Hidden'}
                            </button>
                          )}
                        </MenuItem>
                      </>
                    )}
                  </div>
                </MenuItems>
              </Transition>
            </Menu>
          )}
        </div>
      </div>

      {/* Content */}
      {booking.hidden && (
        <div className="px-6 text-sm">
          <InfoBox icon={InformationCircleIcon} text="This itinerary is currently being hidden from external users. " bgColor="bg-[#F09D38]" textColor="text-white" />{' '}
        </div>
      )}
      <div className="p-6 text-sm">
        <div className="space-y-6">
          <div>
            <h3 className="font-semibold mb-3">Itinerary Details</h3>
            <ul className="space-y-3">
              <li className="flex justify-between">
                <span className="text-gray-500">Client Name</span>
                <div className="flex items-center">
                  <span>{booking.clientName || 'N/A'}</span>
                  <button onClick={() => setIsClientNameDialogOpen(true)} className="ml-2 p-1 rounded-full hover:bg-gray-100 transition-colors duration-200">
                    <PencilIcon className="h-4 w-4 text-gray-400" />
                  </button>
                </div>
              </li>
              <li className="flex justify-between">
                <span className="text-gray-500">Travel dates</span>
                <span>
                  {formatDate(booking.dateStart)} - {formatDate(booking.dateEnd)}
                </span>
              </li>
              <li className="flex justify-between">
                <span className="text-gray-500">Total nights</span>
                <span>{booking.totalNights || 'N/A'}</span>
              </li>
              <li className="flex justify-between">
                <span className="text-gray-500">Total guests</span>
                <span>{getTotalGuests()}</span>
              </li>
            </ul>
          </div>

          <hr className="border-gray-200" />
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="font-semibold mb-3">Booked by</h3>
              <address className="not-italic text-gray-500">
                <p>{booking.consultant?.name || 'No consultant assigned'}</p>
                <p>{booking.agent?.name || 'No agent assigned'}</p>
              </address>
            </div>
            <div>
              <h3 className="font-semibold mb-3">
                Your reference
                <button onClick={() => setIsReferenceDialogOpen(true)} className="ml-2 p-1 rounded-full hover:bg-gray-100 transition-colors duration-200">
                  <PencilIcon className="h-4 w-4 text-gray-400" />
                </button>
              </h3>
              <p className="text-gray-500">{booking.agentReference || 'No agent reference provided'}</p>
              {showOriginatingAgent && (
                <p className="text-gray-500">O/A - {booking.originatingAgent || 'Not provided'}</p>
              )}
            </div>
          </div>
          <hr className="border-gray-200" />
          <div>
            <h3 className="font-semibold mb-3">Nomad Consultant</h3>
            <dl className="space-y-3">
              <div className="flex justify-between">
                <dt className="text-gray-500">Name</dt>
                <dd>{bookingOwner?.fullName || booking.bookingOwner?.name || 'No owner assigned'}</dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-gray-500">Email</dt>
                <dd>
                  <a href={`mailto:${bookingOwner?.email || booking.bookingOwner?.email || 'requests@nomad-tanzania.com'}`} className="text-blue-600 hover:underline">
                    {bookingOwner?.email || booking.bookingOwner?.email || 'requests@nomad-tanzania.com'}
                  </a>
                </dd>
              </div>
              {/* <div className="flex justify-between">
                <dt className="text-gray-500">Phone</dt>
                <dd>
                  <a href="tel:+1234567890" className="text-blue-600 hover:underline">
                    +1 234 567 890
                  </a>
                </dd>
              </div> */}
            </dl>
          </div>
          <hr className="border-gray-200" />

          <div>
            <h3 className="font-semibold mb-3">Financial Summary</h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <ul className="space-y-3">
                <li className="flex justify-between items-center">
                  <span className="text-gray-600">Total Cost</span>
                  <span className="font-medium">{formatCurrency(totalPrice)}</span>
                </li>
                {showFinancialDetails && (
                  <>
                    <li className="flex justify-between items-center">
                      <span className="text-gray-600">Payments Received</span>
                      <span className="font-medium text-green-600">{formatCurrency(totalPaid)}</span>
                    </li>
                    <li className="flex justify-between items-center pt-2 border-t border-gray-200">
                      <span className="text-gray-600 font-semibold">Outstanding Balance</span>
                      <span className="font-semibold text-red-600">{formatCurrency(outstanding)}</span>
                    </li>
                  </>
                )}
              </ul>
              {!showFinancialDetails && <p className="text-gray-600 mt-2">Additional financial details will be available once the booking is confirmed.</p>}
            </div>
            {showFinancialDetails && (
              <>
                <h4 className="font-semibold mt-4 mb-2">Payment Schedule</h4>
                <div className="space-y-2">
                  {totalDeposit > 0 && (
                    <div
                      className={`flex justify-between items-center p-2 rounded ${
                        depositDueStatus === 'paid' ? 'bg-green-100' : depositDueStatus === 'overdue' ? 'bg-red-100' : depositDueStatus === 'approaching' ? 'bg-yellow-100' : 'bg-gray-50'
                      }`}
                    >
                      <div className="flex items-center">
                        {depositDueStatus === 'paid' ? <CheckCircleIcon className="h-5 w-5 mr-2 text-green-500" /> : <CreditCardIcon className="h-5 w-5 mr-2 text-gray-500" />}
                        <span className="text-gray-600">Deposit</span>
                      </div>
                      <div className="text-right">
                        {depositDueStatus === 'paid' ? (
                          <span className="font-medium text-green-600">Paid</span>
                        ) : (
                          <>
                            <span className={`font-medium ${depositDueStatus === 'overdue' ? 'text-red-600' : depositDueStatus === 'approaching' ? 'text-yellow-600' : 'text-gray-600'}`}>
                              Due {formatDate(booking.depositDueDate)}
                            </span>
                            <br />
                            <span className="text-sm text-gray-500">{formatCurrency(totalDeposit)}</span>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={`flex justify-between items-center p-2 rounded ${
                      balanceDueStatus === 'paid' ? 'bg-green-100' : balanceDueStatus === 'overdue' ? 'bg-red-100' : balanceDueStatus === 'approaching' ? 'bg-yellow-100' : 'bg-gray-50'
                    }`}
                  >
                    <div className="flex items-center">
                      {balanceDueStatus === 'paid' ? <CheckCircleIcon className="h-5 w-5 mr-2 text-green-500" /> : <CreditCardIcon className="h-5 w-5 mr-2 text-gray-500" />}
                      <span className="text-gray-600">Balance</span>
                    </div>
                    <div className="text-right">
                      {balanceDueStatus === 'paid' ? (
                        <span className="font-medium text-green-600">Paid</span>
                      ) : (
                        <>
                          <span className={`font-medium ${balanceDueStatus === 'overdue' ? 'text-red-600' : balanceDueStatus === 'approaching' ? 'text-yellow-600' : 'text-gray-600'}`}>
                            Due {formatDate(booking.balanceDueDate)}
                          </span>
                          <br />
                          <span className="text-sm text-gray-500">{formatCurrency(balanceDue)}</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <div>
            <h3 className="font-semibold mb-3">Payment Information</h3>
            <dl>
              <div className="flex justify-between">
                <dt className="flex items-center gap-1 text-gray-500">
                  <CreditCardIcon className="h-4 w-4" />
                  Deposit due on 14th June
                </dt>
                $4,000
              </div>
            </dl>
            <dl>
              <div className="flex justify-between">
                <dt className="flex items-center gap-1 text-gray-500">
                  <CreditCardIcon className="h-4 w-4" />
                  Balance due on 14th June
                </dt>
                $4,000
              </div>
            </dl>
          </div> */}
        </div>
      </div>

      {/* Footer */}
      <div className="flex items-center justify-between border-t border-gray-200 bg-gray-50 px-6 py-3">
        <div className="text-xs text-gray-500">
          Updated <time dateTime={booking.dateModified?.toDate().toISOString()}>{formattedDateModified}</time>
        </div>
        {/* <div className="flex gap-2">
          <button className="p-1 rounded-md border border-gray-300 hover:bg-gray-100">
            <ChevronLeftIcon className="h-4 w-4" />
            <span className="sr-only">Previous Order</span>
          </button>
          <button className="p-1 rounded-md border border-gray-300 hover:bg-gray-100">
            <ChevronRightIcon className="h-4 w-4" />
            <span className="sr-only">Next Order</span>
          </button>
        </div> */}
      </div>

      <ItineraryEmailModal
        modalTitle="Send Itinerary to customer"
        isOpen={isEmailModalOpen}
        setIsOpen={setIsEmailModalOpen}
        prefillContent={body}
        booking={booking}
        generateEmailContent={generateItineraryEmailHTML}
        staticHeader={header}
        staticFooter={footer}
        from={`${user.firstName} ${user.lastName} <${user.email}>`} // Send from logged in user
        to={consultant ? `${consultant.fullName} <${consultant.email}>` : ''}
        subject={subject}
      />
  
      {/* Reference Edit Dialog */}
      <Transition appear show={isReferenceDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsReferenceDialogOpen(false)}>
          <TransitionChild as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Edit Your Reference
                  </DialogTitle>
                  <div className="mt-2">
                    <input
                      type="text"
                      value={newReference}
                      onChange={(e) => setNewReference(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      placeholder="Enter new reference"
                    />
                  </div>

                  <div className="mt-4 flex justify-end space-x-2">
                    <ButtonOutlineSmall text="Cancel" onClick={() => setIsReferenceDialogOpen(false)} color="dark" />
                    <ButtonPrimarySmall text="Update" onClick={handleReferenceUpdate} color="dark" />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Extend Expiry Dialog */}
      <Transition appear show={isExtendDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsExtendDialogOpen(false)}>
          <TransitionChild as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Extend Expiry Date
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Are you sure you would like to extend the expiry date?</p>
                    {newExpiryDate && (
                      <>
                        <p className="text-sm text-gray-700 mt-2">
                          This will extend the expiry date by{' '}
                          <strong>
                            {extensionDays} {extensionDays === 1 ? 'day' : 'days'}
                          </strong>
                          .
                        </p>
                        <p className="text-sm text-gray-700 mt-1">
                          New expiry date: <strong>{formatDateTimeWithShortTZ(newExpiryDate)}</strong>
                        </p>
                      </>
                    )}
                    <p className="text-sm text-gray-500 mt-2">Please note, this will only extend Nomad properties.</p>
                  </div>
                  <div className="mt-4 space-y-2">
                    <div className="flex items-center">
                      <Checkbox
                        checked={sendNotificationEmail}
                        onChange={(checked) => {
                          setSendNotificationEmail(checked);
                          if (!checked) {
                            setCopySelfEmail(false);
                          }
                        }}
                        className={`${
                          sendNotificationEmail ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                      >
                        <span className="sr-only">{user.internalUser ? 'Send notification email to customer' : 'Send notification email to myself'}</span>
                        <span
                          aria-hidden="true"
                          className={`${
                            sendNotificationEmail ? 'translate-x-5' : 'translate-x-0'
                          } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                        />
                      </Checkbox>
                      <span className="ml-3 text-sm"> {user.internalUser ? 'Send notification email to customer' : 'Send notification email to myself'}</span>
                    </div>

                    {user.internalUser && sendNotificationEmail && (
                      <div className="flex items-center">
                        <Checkbox
                          checked={copySelfEmail}
                          onChange={setCopySelfEmail}
                          className={`${
                            copySelfEmail ? 'bg-blue-600' : 'bg-gray-200'
                          } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                        >
                          <span className="sr-only">Copy yourself in the email</span>
                          <span
                            aria-hidden="true"
                            className={`${
                              copySelfEmail ? 'translate-x-5' : 'translate-x-0'
                            } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                          />
                        </Checkbox>
                        <span className="ml-3 text-sm">Copy yourself in the email?</span>
                      </div>
                    )}
                  </div>
                  <div className="mt-4 flex justify-end space-x-2">
                    <ButtonOutlineSmall text="Cancel" onClick={() => setIsExtendDialogOpen(false)} color="dark" />
                    <ButtonPrimarySmall text={isExtending ? 'Extending...' : 'Extend'} onClick={handleExtend} color="dark" isLoading={isExtending} />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Client Name Edit Dialog */}
      <Transition appear show={isClientNameDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsClientNameDialogOpen(false)}>
          <TransitionChild as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Edit Client Name
                  </DialogTitle>
                  <div className="mt-2">
                    <input
                      type="text"
                      value={newClientName}
                      onChange={(e) => setNewClientName(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      placeholder="Enter new client name"
                    />
                  </div>

                  <div className="mt-4 flex justify-end space-x-2">
                    <ButtonOutlineSmall text="Cancel" onClick={() => setIsClientNameDialogOpen(false)} color="dark" />
                    <ButtonPrimarySmall text="Update" onClick={handleClientNameUpdate} color="dark" />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Admin Extend Expiry Dialog */}
      <Transition appear show={isAdminExtendDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsAdminExtendDialogOpen(false)}>
          <TransitionChild as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Extend Expiry Date (Admin)
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Enter the number of days to extend the expiry date:</p>
                    <input
                      type="number"
                      min="1"
                      max="30"
                      value={adminExtensionDays}
                      onChange={(e) => setAdminExtensionDays(Math.min(30, Math.max(1, parseInt(e.target.value) || 1)))}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    {adminExtensionDays > 0 && (
                      <p className="text-sm text-gray-700 mt-2">
                        New expiry date: <strong>{formatDateTimeWithShortTZ(addDays(booking.dateExpiry ? booking.dateExpiry.toDate() : new Date(), adminExtensionDays))}</strong>
                      </p>
                    )}
                    <p className="text-sm text-gray-500 mt-2">Please note, this will only extend Nomad properties.</p>
                  </div>
                  <div className="mt-4 space-y-2">
                    <div className="flex items-center">
                      <Checkbox
                        checked={sendNotificationEmail}
                        onChange={setSendNotificationEmail}
                        className={`${
                          sendNotificationEmail ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                      >
                        <span className="sr-only">Send notification email to customer</span>
                        <span
                          aria-hidden="true"
                          className={`${
                            sendNotificationEmail ? 'translate-x-5' : 'translate-x-0'
                          } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                        />
                      </Checkbox>
                      <span className="ml-3 text-sm">Send notification email to customer</span>
                    </div>
                    <div className="flex items-center">
                      <Checkbox
                        checked={copySelfEmail}
                        onChange={setCopySelfEmail}
                        className={`${
                          copySelfEmail ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                      >
                        <span className="sr-only">Copy yourself in the email</span>
                        <span
                          aria-hidden="true"
                          className={`${
                            copySelfEmail ? 'translate-x-5' : 'translate-x-0'
                          } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                        />
                      </Checkbox>
                      <span className="ml-3 text-sm">Copy yourself in the email?</span>
                    </div>{' '}
                  </div>

                  <div className="mt-4 flex justify-end space-x-2">
                    <ButtonOutlineSmall text="Cancel" onClick={() => setIsAdminExtendDialogOpen(false)} color="dark" />
                    <ButtonPrimarySmall text={isAdminExtending ? 'Extending...' : 'Extend'} onClick={handleAdminExtend} color="dark" isLoading={isAdminExtending} />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default OrderDetails;
