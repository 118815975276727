import 'react-confirm-alert/src/react-confirm-alert.css';
import '@algolia/autocomplete-theme-classic/dist/theme.css';
import '../../../css/autocomplete-theme-classic/index.css';
import '../../../css/instantSearch/index.css';
import 'flatpickr/dist/flatpickr.min.css';
import '../../../css/datePickerStyles.css';
import 'react-datepicker/dist/react-datepicker.css';

import { CalendarIcon, HomeIcon, InformationCircleIcon, LightBulbIcon } from '@heroicons/react/24/outline';
import { CiCalendar, CiGlobe } from 'react-icons/ci';
import { Configure, Hits, InstantSearch, Pagination, connectSearchBox } from 'react-instantsearch-dom';
import {
  CustomDateInput,
  CustomDateInputBorder,
  CustomDateInputBorderNomadBlue,
  CustomDateInputBorderRound,
  CustomDateInputNoBorder,
  CustomDateInputNoBorderNewHeader,
} from '../../../functions/datePickerFunctions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from '../../../components/ui/Dropdown';
import {
  FaAsterisk,
  FaCampground,
  FaChevronDown,
  FaEdit,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaPlusCircle,
  FaRegArrowAltCircleDown,
  FaRegArrowAltCircleUp,
  FaRegTrashAlt,
  FaSave,
  FaTrashAlt,
  FaWindowClose,
} from 'react-icons/fa';
import { FiChevronDown, FiColumns, FiEye, FiPlus, FiRefreshCw, FiZap } from 'react-icons/fi';
import {
  IoIosArrowDown,
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
  IoIosArrowUp,
  IoIosInformationCircle,
  IoMdCloseCircleOutline,
  IoMdInformationCircleOutline,
  IoMdWarning,
} from 'react-icons/io';
import {
  MdAccessTime,
  MdAddCircleOutline,
  MdBlock,
  MdContentCopy,
  MdDateRange,
  MdDiversity3,
  MdEventAvailable,
  MdNightsStay,
  MdNumbers,
  MdOutlineDiscount,
  MdOutlineLocationOn,
  MdOutlineModeEdit,
  MdOutlineSave,
  MdOutlineTune,
} from 'react-icons/md';
import { PiParkFill, PiTentDuotone } from 'react-icons/pi';
import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { TbBeach, TbClipboardOff, TbSwitchHorizontal, TbTransferIn } from 'react-icons/tb';
import _, { get } from 'lodash';
import { addActivity, checkIfAllActivitiesEmpty, filterActivities, getActivitiesLength, handleClickActivities, updateActivityDates } from '../../../functions/activityFunctions';
import {
  addBooking,
  addRoom,
  cloneRoom,
  expandCollapse,
  expandCollapseAll,
  getConfigurationOptions,
  getRoomDetails,
  incDecBookingNights,
  incDecRoomGuests,
  rateLabelMap,
  reCountBookings,
  rebuildBookings,
  rebuildGuestsFromSubcollection,
  refreshRates,
  removeAllBookings,
  removeProperty,
  removeRoom,
  resetAvailableRooms,
  roomSelect,
  validateBookingDate,
} from '../../../functions/itineraryFunctions';
import {
  addNewRoom,
  aggregateGuests,
  checkBookingsForMinusOne,
  checkGuestUsage,
  decrementAdults,
  decrementChildren,
  deleteRoom,
  duplicateRoom,
  handleAdultAgeChangeRoom,
  handleAdultChange,
  handleChildAgeChange,
  handleChildAgeChangeRoom,
  handleChildChange,
  handleRemoveAdultUuid,
  handleRemoveChild,
  handleRemoveChildUuid,
  incrementAdults,
  incrementChildren,
  isGuideCheck,
  isUuidUsedInBookings,
  removeLastAdultWithoutMutating,
  removeLastAge,
  removeSelectedAgesAdultUuid,
  removeSelectedAgesUuid,
  updateGuestsMergedInBookings,
} from '../../../functions/paxFunctions';
import {
  addOneDayIfBST,
  adjustDateForBST,
  calculateDaysToAddForVehicles,
  calculateTotalPax,
  createJsonObject,
  findAirlineByCompanyCode,
  findCustomLocation,
  findNextBooking,
  formatFireStoreDate,
  formatPrice,
  getHighestPropertyPax,
  getIdFromStatus,
  getPropObj,
  getPropertyName,
  getRoomImg,
  getRoomType,
  getSupplierName,
  getTotalForProperty,
  getTotalFromActivities,
  getTotalFromBookings,
  getTotalFromBookingsForVehicles,
  getTotalFromCustomItems,
  getTotalFromFinalPageData,
  getTotalFromFinalPageTransferData,
  getTotalFromOtherArrangements,
  getTotalFromTransfers,
  isBST,
  make2digits,
  numberWithCommas,
  orderByFeeType,
} from '../../../functions/generalFunctions';
import { availableTransfers, buildCustomLocations, createTransferObject, createTransfersArray, getAeroCrsCode, getAirportName } from '../../../functions/transferFunctions';
import {
  calculatePropertyVehicles,
  filterVehicleRoom,
  filterVehicleRooms,
  filterVehicleRoomsNoSet,
  filterVehicleTransfers,
  generateVehicleMessage,
  processNorthernGuideVehicleSupplement,
  removeVehicleRooms,
} from '../../../functions/vehicleFunctions';
import {
  categorizeChargeType,
  categorizeChargeTypeGetUid,
  categorizeChargeTypeId,
  collapseExpandAllItems,
  countNorthernGuideNights,
  getLabelForFinalValues,
  getOtherArrangementsByType,
  processFeeTypes,
  resetFinalInputs,
  updatePropertyData,
  updateTransferData,
} from '../../../functions/finalPageFunctions';
import { checkBookingArrays, updateActivitiesData, updateTransfers } from '../../../functions/rebuildingFunctions';
import { checkUnallocatedItems, handleCheckUnallocated, hornbillDiscount, setAddToFalse } from '../../../functions/finalCustomFunctions';
import { collection, getDocs, query } from 'firebase/firestore';
import { convertFinalPageDataFirestoreTimestampsToISOStrings, loadGetItinerary, updateItinerary } from '../../../functions/editItineraryFunctions';
import { flatpickrOptionsBooking, getRooms, nextDate, prevDate, removeAll, resetDate, sameDate } from '../../../functions/availabilityFunctions';
import { getRoomAvailability, getStock, stockCountCheck, stockCountCheckZero, updateDates } from '../../../functions/stockFunctions';
import {
  loadAgent,
  loadAgentCommission,
  loadAgentDetails,
  loadAgentRates,
  loadBookingVc,
  loadBookings,
  loadConsultantDetails,
  loadCountItinerariesVc,
  loadItinerariesVc,
  loadItineraryVersion,
  loadProperties,
  loadUserVersion,
} from '../../../functions/loadDataFunctions';
import { updateBookingsNotModified, validateBookingRules } from '../../../functions/sharedItineraryFunctions';

import ActivitiesSlideOutComp from '../../../components/ActivitiesSlideOutComp';
import { AiOutlineNumber } from 'react-icons/ai';
import { BiCustomize } from 'react-icons/bi';
import { BiDollar } from 'react-icons/bi';
import { BiTransfer } from 'react-icons/bi';
import Breadcrumbs from '../../../components/Breadcrumbs';
import BreadcrumbsTR from '../../../components/BreadcrumbsTR';
import { BsQuestionCircle } from 'react-icons/bs';
import { BsStars } from 'react-icons/bs';
import ButtonOutlineSmall from '../../../components/Buttons/ButtonOutlineSmall';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonPrimarySmall from '../../../components/Buttons/ButtonPrimarySmall';
import ButtonTransferAdd from '../../../components/Buttons/ButtonTransferAdd';
import { CgUnavailable } from 'react-icons/cg';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { CustomAutoComplete } from '../../../components/CustomAutoComplete';
import CustomModalClient from '../../../components/CustomModalClient';
import CustomSelect from '../../../components/CustomSelect';
import CustomSelectNoBorderWizard from '../../../components/CustomSelectNoBorderWizard';
import CustomSelectRooms from '../../../components/CustomSelectRooms';
import DatePicker from 'react-datepicker';
import DateRangeComp from '../../../components/DateRangeComp';
import EarlyAccessHero from '../../../components/EarlyAccessHero';
import { FaTent } from 'react-icons/fa6';
import FinalActivity from '../../../components/FinalActivity';
import FinalDiscount from '../../../components/FinalDiscount';
import FinalDropPickup from '../../../components/FinalDropPickup';
import FinalPageComp from '../../../components/FinalPageComp';
import FinalSlideOut from '../../../components/FinalSlideOut';
import FinalTransferItem from '../../../components/FinalTransferItem';
import FinalUnallocated from '../../../components/FinalUnallocated';
import FinalVip from '../../../components/FinalVip';
import Flatpickr from 'react-flatpickr';
import FullScreenPropertyModal from '../../../components/FullScreenPropertyModal';
import { GiJeep } from 'react-icons/gi';
import { GoPerson } from 'react-icons/go';
import { GoVersions } from 'react-icons/go';
import { GrStatusInfo } from 'react-icons/gr';
import GuestCount from '../../../components/GuestCount';
import GuestsPopover from '../../../components/GuestsPopover';
import Header from '../../../partials/Header';
import InfoBox from '../../../components/InfoBox';
import { IoPersonOutline } from 'react-icons/io5';
import ItineraryNameEditor from '../../../components/ItineraryNameEditor';
import ItinerarySearch from '../../../components/ItinerarySearch';
import ItineraryVersionMenu from '../../../components/ItineraryVersionMenu';
import { LuFilePlus } from 'react-icons/lu';
import { MdOutlineSupportAgent } from 'react-icons/md';
import NightCount from '../../../components/NightCount';
import { PiVanLight } from 'react-icons/pi';
import PricingSummaryModal from '../../../components/PricingSummaryModal';
import PropertiesSearchBox from '../../../components/PropertiesSearchBox';
import PropertiesSlideOutComp from '../../../components/PropertiesSlideOutComp';
import { PropertyItem } from '../../../components/PropertyItem';
import ReactPlayer from 'react-player';
import ReactTooltip from 'react-tooltip';
import { RiCloseCircleLine } from 'react-icons/ri';
import Sidebar from '../../../partials/Sidebar';
import { SlPlane } from 'react-icons/sl';
import { Switch } from '@headlessui/react';
import TransferCard from '../../../components/TransferCard';
import TransferPropertyCard from '../../../components/TransferPropertyCard';
import TransferSingle from '../../../components/TransferSingle';
import TransferSlideOutInter from '../../../components/TransferSlideOutInter';
import TransferSlideOutSingle from '../../../components/TransferSlideOutSingle';
import ViewItinerariesSlideOut from '../../../components/ViewItinerariesSlideOut';
import { VscNotebookTemplate } from 'react-icons/vsc';
import algoliasearch from 'algoliasearch';
import { confirmAlert } from 'react-confirm-alert';
import { db } from '../../../db/firebase.config';
import { deeShadow } from '../../../css/additional-styles/custom';
import { loadActivities } from '../../../functions/loadDataFunctions';
import moment from 'moment';
import packageJson from '../../../../package.json';
import qs from 'qs';
import { resRequestDisplay } from '../../../functions/platformFunctions';
import semver from 'semver';
import { toast } from 'react-toastify';
import { updateEarlyAccessRequested } from '../../../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

export const INSTANT_SEARCH_INDEX_NAME = 'agents';

//export const INSTANT_SEARCH_QUERY_SUGGESTIONS = "agents_query_suggestions";

export const INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTES = ['name'];

// #region
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

//const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  //padding: grid * 2,
  //margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'bg-white' : 'bg-white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'bg-white' : 'bg-white',
  //padding: grid,
  //width: 250,
});
// #endregion

const VirtualSearchBox = connectSearchBox(() => null);

// !Search for properties start
function createURL(searchState) {
  return qs.stringify(searchState, { addQueryPrefix: true });
}

function searchStateToUrl({ location }, searchState) {
  if (Object.keys(searchState).length === 0) {
    return '';
  }

  // Remove configure search state from query parameters
  const { configure, ...rest } = searchState;
  return `${location.pathname}${createURL(rest)}`;
}

function urlToSearchState({ search }) {
  return qs.parse(search.slice(1));
}

const handleChangePropertiesSearch = (event) => {
  // !console.log(event.currentTarget.value);
};
// !Search for properties end

function EditItinerary() {
  // Redux
  const dispatch = useDispatch();
  // Transfers
  const { allAirports } = useSelector((state) => state.airports);
  var airportsShort = allAirports.result;
  let tempAirports = JSON.parse(JSON.stringify(airportsShort));

  const { allAirlines } = useSelector((state) => state.airlines);
  var airlinesShort = allAirlines.result;
  let tempAirlines = JSON.parse(JSON.stringify(airlinesShort));

  const { allLocations } = useSelector((state) => state.locations);
  var locationsShort = allLocations.result;
  let tempLocations = JSON.parse(JSON.stringify(locationsShort));

  const { allSuppliers } = useSelector((state) => state.suppliers);
  var suppliersShort = allSuppliers.result;
  let tempSuppliers = JSON.parse(JSON.stringify(suppliersShort));

  const { allTemplateSearchOptions } = useSelector((state) => state.templateSearchOptions);
  var templateSearchOptionsShort = allTemplateSearchOptions.result;
  let tempTemplateSearchOptions = JSON.parse(JSON.stringify(templateSearchOptionsShort));

  // Transfers

  // function onReset() {
  //   // !console.log("onReset");
  //   setSelectedAgent(false);
  //   setSelectedAgentItems(false);
  //   setSelectedAgentName(false);
  //   setSelectedAgentUid(false);
  //   setSelectedConsultantName(false);
  //   setSelectedConsultantUid(false);
  //   setRateName(false);
  //   setRateId(false);
  //   setAgentResRequestId(false);
  //   setConsultantResRequestId(false);
  // }

  // #region
  const { user } = useSelector((state) => state.auth);
  const { uid } = useParams();
  const navigate = useNavigate();
  const onDragEnd = (result) => {
    setFetchCustom(true);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Set new order of bookings
    const bookingsReorder = reorder(bookings, result.source.index, result.destination.index);

    // Check no two properties are next to each other
    if (bookingsReorder.length > 1) {
      for (let i = 0; i < bookingsReorder.length; i++) {
        if (i + 1 !== bookingsReorder.length) {
          // if (bookingsReorder[i].propUid === bookingsReorder[i + 1].propUid) {
          //   setRecount(true);
          //   return toast.error("You can't add a property directly after/before the same property");
          // } else {
          //   // Reset all rooms available status to null
          //   resetAvailableRooms(bookings, setBookings);
          // }
          if (!user.internalUser && bookingsReorder[i].propUid === bookingsReorder[i + 1].propUid) {
            setRecount(true);
            return toast.error("You can't add a property directly after/before the same property");
          } else {
            // Reset all rooms available status to null
            resetAvailableRooms(bookings, setBookings);
          }
        }
      }
      setBookings(bookingsReorder);

      reCountBookings(bookingsReorder, setBookings, realDate, rooms, setRooms, properties, setRefreshRatesEffect, true);
    }
  };

  const onDragStart = () => {
    //resetAvailableRooms(bookings);
  };

  // ************************************* // STATE START
  const [onlyProperties, setOnlyProperties] = useState(false);
  const [showEditOptions, setShowEditOptions] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [firstLoadAvailability, setFirstLoadAvailability] = useState(false);
  const [mojoItinerary, setMojoItinerary] = useState(false);

  // Create null values
  const Autocomplete = null;
  const keyAgents = null;
  const onReset = null;
  const getAlgoliaResults = null;
  const AgentItem = null;
  // New
  const [enquiryUid, setEnquiryUid] = useState(null);
  const [selectedEnquiryTitle, setSelectedEnquiryTitle] = useState(null);
  const [internalUser, setInternalUser] = useState(null);
  const [canCreateNewEnquiry, setCanCreateNewEnquiry] = useState(null);
  const [newEnquiry, setNewEnquiry] = useState(null);
  const selectAgent = null;
  //const agents = null;
  const createNewEnquiry = null;
  const INSTANT_SEARCH_INDEX_NAME_ENQUIRIES = null;
  const keyEnquiries = null;
  const onResetEnquiries = null;
  const EnquiryItem = null;

  const [recount, setRecount] = useState(false);

  const [refreshRatesEffect, setRefreshRatesEffect] = useState(false);

  const [showBookings, setShowBookings] = useState(true);
  const [rooms, setRooms] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isLoadingStock, setIsLoadingStock] = useState(false);
  const [showPage, setShowPage] = useState(true);

  let packageSelection;
  //let showPackageTabs;
  if (!user.internalUser && user.fullBoardOnly) {
    packageSelection = 'fullBoard';
    //showPackageTabs = false;
  } else if (!user.internalUser && !user.fullBoardOnly) {
    packageSelection = 'gamePackage';
    //showPackageTabs = false;
  } else if (user.internalUser && !user.fullBoardOnly) {
    packageSelection = 'gamePackage';
    //showPackageTabs = true;
  } else {
    packageSelection = 'fullBoard';
    //showPackageTabs = true;
  }

  console.log('packageSelection', packageSelection);

  const [packageTab, setPackageTab] = useState(packageSelection);

  // const [callItinerary, setCallItinerary] = useState(null);
  const [callUpdateDates, setCallUpdateDates] = useState(null);

  // Get stock
  //const [callGetStock, setCallGetStock] = useState(null);

  // Make booking
  const [callMakeRooms, setCallMakeRooms] = useState(null);

  // DATES
  // DATES
  const [realDate, setRealDate] = useState(moment().format('YYYY-MM-DD'));
  const dateToUse = moment(realDate).format('YYYY-MM-DD');
  const [dateStart, setDateStart] = useState(dateToUse);
  const [arrowDate, setArrowDate] = useState(dateToUse);
  const [dateEnd, setDateEnd] = useState(moment(realDate).add(3, 'months').format('YYYY-MM-DD'));

  const [timestampStartedEdit, setTimestampStartedEdit] = useState(new Date());
  // Start
  // const startYearArray = dateStart.split("-");
  // const startYearA = startYearArray[0];
  // const startMonthA = startYearArray[1] - 1;
  // const startDayA = startYearArray[2];

  // End
  // const endYearArray = dateEnd.split("-");
  // const endYearA = endYearArray[0];
  // const endMonthA = endYearArray[1] - 1;
  // const endDayA = endYearArray[2];

  // const [startDay, setStartDay] = useState(new Date(startYearA, startMonthA, startDayA));
  // const [endDay, setEndDay] = useState(new Date(endYearA, endMonthA, endDayA));
  // const [date, setDate] = useState(
  // 	new Date(startYearA, startMonthA, startDayA)
  // );
  // fix date
  const dayNums = [0, 1, 2, 3, 4, 5, 6];

  // Properties

  const [property, setProperty] = useState([]);
  const [properties, setProperties] = useState([]);
  const [bookings, setBookings] = useState([]);

  const [bookingsControl, setBookingsControl] = useState([]);
  const [bookingsStore, setBookingsStore] = useState([]);
  const [itineraryStore, setItineraryStore] = useState([]);

  // Transfers start
  const [transfers, setTransfers] = useState([]);
  const [transfersLatest, setTransfersLatest] = useState([]);
  // Transfers end

  //roomPax details
  const [roomPax, setRoomPax] = useState([
    {
      adults: 0,
      children: 0,
      childAges: [],
      adultAges: [],
      uuid: uuidv4(),
    },
  ]);

  const [guests, setGuests] = useState({
    adults: 0,
    children: 0,
    childAges: [],
    adultAges: [],
  });

  const [guestsStore, setGuestsStore] = useState({
    adults: 0,
    children: 0,
    childAges: [],
    adultAges: [],
  });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [selectedAges, setSelectedAges] = useState([]); // Array of child ages
  const [selectedAgesAdults, setSelectedAgesAdults] = useState([]); // Array of adult ages

  // updates guests in bookings
  useEffect(() => {
    updateGuestsMergedInBookings(bookings, guests, properties, setBookings);
  }, [guests]);

  const [tempAd, setTempAd] = useState(false);
  const [tempCh, setTempCh] = useState(false);

  // version control
  const [createEdit, setCreateEdit] = useState('edit');
  const [newUserVersion, setNewUserVersion] = useState(false);

  const [userVersion, setUserVersion] = useState(0);

  //const [reloadVehicles, setReloadVehicles] = useState(false);

  // Final page

  const [visitedFinalPage, setVisitedFinalPage] = useState(true); // set to true on edit itineraries page
  const [finalPageData, setFinalPageData] = useState([]);
  const [finalPageTransferData, setFinalPageTransferData] = useState([]);
  const [otherArrangementsData, setOtherArrangementsData] = useState([]);

  const [customFinalItems, setCustomFinalItems] = useState([]);

  const [customFinalCut, setCustomFinalCut] = useState(null);

  const [addFinalItemDetails, setAddFinalItemDetails] = useState(null);

  const [customItemToEdit, setCustomItemToEdit] = useState(null);
  const [finalSlideOutDate, setFinalSlideOutDate] = useState(null);

  const [otherArrangementsEdit, setOtherArrangementsEdit] = useState(false);

  const [highestTotalPax, setHighestTotalPax] = useState(0);

  const [selectedFinalBooking, setSelectedFinalBooking] = useState(null);

  // const [isFocusedFinalDescription, setIsFocusedFinalDescription] = useState(false);

  // const handleFocusFinalDescription = () => setIsFocusedFinalDescription(true);
  // const handleBlurFinalDescription = () => setIsFocusedFinalDescription(false);

  const [finalInputs, setFinalInputs] = useState({
    feeType: '',
    supplier: '',
    description: '',
    units: 0,
    pax: 0,
    net: 0,
    total: 0,
    uuid: '',
    edit: true,
    star: true,
  });

  const [finalTransferInputs, setFinalTransferInputs] = useState({
    feeType: '',
    supplier: '',
    description: '',
    units: 0,
    pax: 0,
    net: 0,
    total: 0,
    uuid: '',
    edit: true,
    star: true,
  });

  // Find the flyingDoctorInsurance item
  //const flyingDoctorInsuranceItem = otherArrangementsData.find((item) => item.flyingDoctorInsurance !== undefined);

  // Toggle the checked state of flyingDoctorInsurance
  // const handleOwnArrangementCheckboxChange = (type, unit, unitPrice) => {
  //   let findObject = otherArrangementsData.find((item) => item.type === type);
  //   if (!findObject.checked) {
  //     findObject.saleTotal = unit * unitPrice;
  //   } else {
  //     findObject.saleTotal = findObject.saleTotal > 0 ? 0 : unit * unitPrice;
  //   }

  //   findObject.checked = true;

  //   setOtherArrangementsData((otherArrangementsData) => [...otherArrangementsData]);
  // };

  // Toggle the checked state of flyingDoctorInsurance
  const handleOwnArrangementCheckboxChange = (type, unit, unitPrice, checked) => {
    let findObject = otherArrangementsData.find((item) => item.type === type);

    findObject.saleTotal = findObject.saleTotal > 0 ? 0 : unit * unitPrice;

    findObject.checked = !checked;

    // if (type === 'otherArrangementsPreferredGuide') {
    //   findObject.checked = findObject.saleTotal >= 0 ? true : false;
    // }

    setOtherArrangementsData((otherArrangementsData) => [...otherArrangementsData]);
  };

  // Transfer functions

  const resetEditTransferItem = () => {
    const updatedPageTransferData = finalPageTransferData.map((data) => {
      return {
        ...data,
        editTransferItem: false,
      };
    });
    //console.log("updatedPageData", updatedPageData);

    setFinalPageTransferData(updatedPageTransferData);
  };

  const resetFinalTransferInputs = (transferSupplierUid) => {
    setFinalTransferInputs((inputs) => ({
      ...inputs,
      feeType: '',
      feeTypeUid: '',
      feeTypeName: '',
      supplier: transferSupplierUid ? transferSupplierUid : '',
      description: '',
      units: 0,
      pax: 0,
      net: 0,
      total: 0,
      uuid: uuidv4(),
      edit: true,
      star: true,
    }));
  };

  // slide outs
  const [isSlideOutOpen, setIsSlideOutOpen] = useState(false);

  const handleOpenSlideOut = () => setIsSlideOutOpen(true);
  const handleCloseSlideOut = () => {
    setIsSlideOutOpen(false);
    setAddFinalItemDetails(null);
    setCustomItemToEdit(null);
  };

  // ViewItinerariesSlideOut
  const [isViewItinerariesSlideOutOpen, setIsViewItinerariesSlideOutOpen] = useState(false);
  const handleOpenViewItinerariesSlideOut = () => setIsViewItinerariesSlideOutOpen(true);
  const handleCloseViewItinerariesSlideOut = () => {
    setIsViewItinerariesSlideOutOpen(false);
  };

  // slide out properties
  const [isSlideOutPropertiesOpen, setIsSlideOutPropertiesOpen] = useState(false);
  const handleOpenPropertiesSlideOut = () => setIsSlideOutPropertiesOpen(true);
  const handleClosePropertiesSlideOut = () => {
    setIsSlideOutPropertiesOpen(false);
  };

  // Property library full page modal
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
  const [selectedPropertyUid, setSelectedPropertyUid] = useState(null);

  const handleOpenPropertyModal = (uid) => {
    setSelectedPropertyUid(uid);
    setIsPropertyModalOpen(true);
  };

  const handleClosePropertyModal = () => {
    setIsPropertyModalOpen(false);
    setSelectedPropertyUid(null);
  };

  // slide out activities
  const [isSlideOutActivitiesOpen, setIsSlideOutActivitiesOpen] = useState(false);
  const handleOpenActivitiesSlideOut = () => setIsSlideOutActivitiesOpen(true);
  const handleCloseActivitiesSlideOut = () => {
    setIsSlideOutActivitiesOpen(false);
  };

  const [isSlideOutTransferSingleOpen, setIsSlideOutTransferSingleOpen] = useState(false);

  const handleOpenSlideOutTransferSingle = () => {
    setTimeout(() => {
      setIsSlideOutTransferSingleOpen(true);
    }, 10);
  };

  const handleCloseSlideOutTransferSingle = () => {
    setIsSlideOutTransferSingleOpen(false);
    setSelectedTransferTuuid(null);
  };

  const [isSlideOutTransferSingleOpenDeparture, setIsSlideOutTransferSingleOpenDeparture] = useState(false);

  const handleOpenSlideOutTransferSingleDeparture = () => {
    setTimeout(() => {
      setIsSlideOutTransferSingleOpenDeparture(true);
    }, 10);
  };

  const handleCloseSlideOutTransferSingleDeparture = () => {
    setIsSlideOutTransferSingleOpenDeparture(false);
    setSelectedTransferTuuid(null);
  };

  const [isSlideOutTransferPropertyOpen, setIsSlideOutTransferPropertyOpen] = useState(false);

  const handleOpenSlideOutTransferProperty = () => {
    setTimeout(() => {
      setIsSlideOutTransferPropertyOpen(true);
    }, 100);
  };

  const handleCloseSlideOutTransferProperty = () => {
    setIsSlideOutTransferPropertyOpen(false);
    setSelectedTransferTuuid(null);
  };

  // OTHER ARRANGEMENTS

  const handleOtherArrangementsDescriptionChange = (value, type) => {
    let findObject = otherArrangementsData.find((item) => item.type === type);
    findObject.description = value;
    findObject.editing = false;
    setOtherArrangementsData((otherArrangementsData) => [...otherArrangementsData]);
    //setOtherArrangementsEdit(false);
  };

  const handleOtherArrangementsUnitsChange = (value, type) => {
    let findObject = otherArrangementsData.find((item) => item.type === type);
    if (Number(value) === 0) {
      return toast.error("Please enter a value for 'PAX/UNITS'");
    }
    findObject.units = Number(value);
    findObject.saleTotal = Number(value) * findObject.unitPrice;
    findObject.editing = false;
    setOtherArrangementsData((otherArrangementsData) => [...otherArrangementsData]);
    //setOtherArrangementsEdit(false);
  };

  const handleOtherArrangementsUnitPriceChange = (value, type) => {
    let findObject = otherArrangementsData.find((item) => item.type === type);
    if (Number(value) < 0) {
      return toast.error("Please enter a value or 0 for 'NET RATE'");
    }

    if (type === 'otherArrangementsFlyingDoctor') {
      findObject.description = 'Would you like to include Flying Doctors insurance for all passengers? (' + findObject.units + ' passengers x $' + Number(value) + ' per person)';
    }

    findObject.unitPrice = Number(value);
    findObject.saleTotal = Number(value) * findObject.units;
    findObject.editing = false;
    setOtherArrangementsData((otherArrangementsData) => [...otherArrangementsData]);
    //setOtherArrangementsEdit(false);
  };

  const handleOtherArrangementsEditSave = (type) => {
    let findObject = otherArrangementsData.find((item) => item.type === type);

    findObject.editing = !findObject.editing;
    setOtherArrangementsData((otherArrangementsData) => [...otherArrangementsData]);
  };

  // Create the select options from bookings not present in finalPageData

  // console.log("finalPageData PDX", finalPageData);

  // Create the select options from bookings not present in finalPageData
  const selectOptions = bookings
    .filter((booking) => (!finalPageData || !Array.isArray(finalPageData) ? true : !finalPageData.some((finalPageDataItem) => finalPageDataItem.uuid === booking.id)))
    .map((booking) => ({
      value: booking.id,
      label: `${booking.propName} - ${moment(booking.startDay).format('DD/MM/YYYY')} - ${moment(booking.endDay).format('DD/MM/YYYY')}`,
    }));

  // ************************************* // STATE END

  //Final page
  useEffect(() => {
    setFinalInputs((inputs) => ({
      ...inputs,
      total: inputs.units * inputs.pax * inputs.net,
    }));
  }, []);

  const [rateName, setRateName] = useState(false);
  const [rateId, setRateId] = useState(false);
  const [agentRates, setAgentRates] = useState([]);
  const [agentObject, setAgentObject] = useState([]);
  const [agentCommission, setAgentCommission] = useState([]);

  const [version, setVersion] = useState(0);

  const [consultants, setConsultants] = useState([]);

  const [selectedAgent, setSelectedAgent] = useState(false);
  const [selectedAgentItems, setSelectedAgentItems] = useState(false);
  const [selectedConsultant, setSelectedConsultant] = useState(false);

  const [selectedAgentName, setSelectedAgentName] = useState(false);
  const [selectedAgentUid, setSelectedAgentUid] = useState(false);
  const [agentResRequestId, setAgentResRequestId] = useState(false);
  const [agents, setAgents] = useState([]);
  // RP 13/05/24 - START
  const [resRequestStatus, setResRequestStatus] = useState(0);
  const [status, setStatus] = useState(null);
  // RP 13/05/24 - END

  const [openPricingModalId, setOpenPricingModalId] = useState(null);

  const [selectedConsultantName, setSelectedConsultantName] = useState(false);
  const [selectedConsultantUid, setSelectedConsultantUid] = useState(false);
  const [consultantResRequestId, setConsultantResRequestId] = useState(false);
  const [consultantLoaded, setConsultantLoaded] = useState([]);

  const [clientName, setClientName] = useState('');

  // Show & hide room types
  const [showRoomVehicle, setShowRoomVehicle] = useState('Vehicle');
  const [showRoomGuide, setShowRoomGuide] = useState('Guide/Pilot Bed');
  const [showRoomActivity, setShowRoomActivity] = useState('Activity');

  const [bookingUid, setBookingUid] = useState(null);
  const [refreshBtn, setRefreshBtn] = useState(0);

  const [getPropertiesAndStock, SetGetPropertiesAndStock] = useState(null);

  // Itinerary name and edit name
  const [itineraryName, setItineraryName] = useState('Untitled Itinerary');
  const [editItineraryName, setEditItineraryName] = useState(false);

  // rates
  const [fetchingRates, setFetchingRates] = useState(false);
  const [nextTopInfo, setNextTopInfo] = useState(true);
  const [loadStock, setLoadStock] = useState(false);
  const [updateRoomAvailability, setUpdateRoomAvailability] = useState(false);

  // Activities
  const [step, setStep] = useState('bookings');
  const [activities, setActivities] = useState([]);
  const [activitiesList, setActivitiesList] = useState([]);
  const [activitiesData, setActivitiesData] = useState([]);
  const [activitiesDataStore, setActivitiesDataStore] = useState([]);
  const [activitiesDataLatest, setActivitiesDataLatest] = useState([]);
  const [activitiesDataRemoved, setActivitiesDataRemoved] = useState([]);

  const [bookingsDataStore, setBookingsDataStore] = useState([]);

  const [selectedActivityProperty, setSelectedActivityProperty] = useState(null);
  const [selectedActivityPropertyUid, setSelectedActivityPropertyUid] = useState(null);
  const [selectedActivityPropertyId, setSelectedActivityPropertyId] = useState(null);

  const [clickedActivityPropertyId, setClickedActivityPropertyId] = useState(null);

  const [selectedActivityDay, setSelectedActivityDay] = useState(null);
  const [selectedActivityDate, setSelectedActivityDate] = useState(null);

  const [activitiesTab, setActivitiesTab] = useState('prebooked');
  const [resetActivities, setResetActivities] = useState(null);
  const [activitiesCost, setActivitiesCost] = useState(0);

  // Transfers
  const [transfersData, setTransfersData] = useState([]);

  //
  const [getItineraryData, setGetItineraryData] = useState(null);
  const [currentItineraryIds, setCurrentItineraryIds] = useState(null);
  const [resReqReservationId, setResReqReservationId] = useState(null);
  const [itinerariesVcMenu, setItinerariesVcMenu] = useState(null);
  const [viewItinerariesMenu, setViewItinerariesMenu] = useState(null);
  const [selectedViewUid, setSelectedViewUid] = useState(null);
  const [selectedViewLabel, setSelectedViewLabel] = useState('Current version');
  const [selectedItemView, setSelectedItemView] = useState(uid);

  const [itinerariesVcOptions, setItinerariesVcOptions] = useState(null);

  const [docUid, setDocUid] = useState(uid);
  const [navigateToManageItineraries, setNavigateToManageItineraries] = useState(false);
  const [selectedItemVc, setSelectedItemVc] = useState(uid);
  const [itineraryStatus, setItineraryStatus] = useState(null);
  const [replaceItinerary, setReplaceItinerary] = useState(true);
  const [refreshAllRates, setRefreshAllRates] = useState(true); // was true

  // quote mode
  const [quoteMode, setQuoteMode] = useState(false);
  const [showQuoteMode, setShowQuoteMode] = useState(false);
  const [dateModified, setDateModified] = useState(false);
  const [itineraryRenamed, setItineraryRenamed] = useState(false);

  const [masterVersion, setMasterVersion] = useState(false);
  const [createVersion, setCreateVersion] = useState(null);
  const [createVersionCheck, setCreateVersionCheck] = useState('2.3.27');

  const [hidden, setHidden] = useState(user.internalUser ? true : false);
  const [reprintInvoice, setReprintInvoice] = useState(true);

  const handleEditItineraryClick = () => {
    setEditItineraryName(true);
  };

  const handleItinerarySaveClick = () => {
    if (itineraryName !== '') {
      setEditItineraryName(false);
      setItineraryRenamed(true);
    } else {
      toast.error('You must enter an itinerary name');
    }
  };

  const handleTextChange = (event) => {
    setItineraryName(event.target.value);
  };

  // ************************************* // STATE END

  // 05/06/24
  const [tempBookingsStore, setTempBookingsStore] = useState(null);
  const [transfersDataFlights, setTransfersDataFlights] = useState([]);
  const [commissionAgent, setCommissionAgent] = useState(null);
  const [commissionUser, setCommissionUser] = useState(null);
  const [dateCreated, setDateCreated] = useState(null);

  // const rebuildGuestsFromSubcollection = async (db, itineraryId) => {
  //   const guestsCollectionRef = collection(db, 'itineraries', itineraryId, 'guests');
  //   const guestsQuery = query(guestsCollectionRef);
  //   const guestsSnapshot = await getDocs(guestsQuery);

  //   const rebuiltGuests = {
  //     adults: 0,
  //     children: 0,
  //     childAges: [],
  //     adultAges: [],
  //   };

  //   guestsSnapshot.forEach((doc) => {
  //     const guestData = doc.data();
  //     const guestWithId = { ...guestData, uuid: doc.id };

  //     if (guestData.type === 'adult') {
  //       rebuiltGuests.adults += 1;
  //       rebuiltGuests.adultAges.push(guestWithId);
  //     } else if (guestData.type === 'child') {
  //       rebuiltGuests.children += 1;
  //       rebuiltGuests.childAges.push(guestWithId);
  //     }
  //   });

  //   return rebuiltGuests;
  // };
  // Load bookings loadBooking

  const [advancedMode, setAdvancedMode] = useState(true);
  const [hornbillDiscountAvailable, setHornbillDiscountAvailable] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      console.log('docUid: ' + docUid);
      //const callCollection = docUid === uid ? "itineraries" : "itinerariesVersionControl";
      let tempBookings = docUid === uid ? await loadBookings(docUid) : await loadBookingVc(docUid);

      // 05/06/24
      setTempBookingsStore(tempBookings);
      // RP 13/05/24 - START
      setStatus(tempBookings[0].status);
      // RP 13/05/24 - END

      // RP 22/05/24 - START
      setCreateVersionCheck(tempBookings[0].createVersion || createVersionCheck);

      setIsFamTrip(tempBookings[0].isFamTrip ? tempBookings[0].isFamTrip : false);
      // RP 22/05/24 - END
      const firestoreTimestamp = tempBookings[0].dateCreated;
      const dateCreated = firestoreTimestamp ? new Date(firestoreTimestamp.seconds * 1000 + firestoreTimestamp.nanoseconds / 1000000) : null;
      setDateCreated(dateCreated);
      console.log('dateCreated', dateCreated);

      if (tempBookings[0].locked || (!user.internalUser && (tempBookings[0].status === 'confirmed' || tempBookings[0].status === 'cancelled' || tempBookings[0].user.internalUser))) {
        setIsLoading(false);
        setShowPage(false);

        // Show the modal/alert
        confirmAlert({
          customUI: ({ onClose }) => {
            // Overlay click is not handled, so clicking outside doesn't close the alert
            return (
              <div className="custom-ui-overlay" onClick={(e) => e.stopPropagation()}>
                <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                  <p className="text-sm font-semibold pb-2">Sorry you don't have permission to edit this itinerary</p>
                  <p className="text-sm pb-2">Click the button below to go to the Manage Itineraries page</p>
                  <div className="flex mt-5 justify-end">
                    <div>
                      {/* <button
                        className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                        onClick={() => {
                          setRefreshBtn(false);
                          onClose();
                        }}
                      >
                        Cancel
                      </button> */}
                      <ButtonPrimarySmall
                        onClick={async () => {
                          navigate('/itinerary/manage/manageItineraries/', { replace: true });
                          onClose();
                        }}
                        color="dark"
                        text="Go to Manage Itineraries"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          },
          closeOnEscape: false,
          closeOnClickOutside: false,
        });

        // Prevent further execution in this useEffect
        return;
      }

      console.log(JSON.stringify(tempBookings, undefined, 4));

      console.log(tempBookings[0].clientName);

      setClientName(tempBookings[0].clientName);
      setSelectedAgentName(tempBookings[0].agent.name);
      setSelectedAgentUid(tempBookings[0].agent.uid);
      setAgentResRequestId(tempBookings[0].agent.ResRequestId);
      setCreateVersion(tempBookings[0].createVersion ? tempBookings[0].createVersion : '0.0.0');

      setSelectedConsultantName(tempBookings[0].consultant.name);
      setSelectedConsultantUid(tempBookings[0].consultant.uid);
      setConsultantResRequestId(tempBookings[0].consultant.resRequestId);

      setItineraryStatus(tempBookings[0].status.charAt(0).toUpperCase() + tempBookings[0].status.slice(1));

      setItineraryName(tempBookings[0].itineraryName);
      setItineraryRenamed(tempBookings[0].itineraryRenamed ? true : false);

      setMojoItinerary(tempBookings[0].mojoItinerary ? tempBookings[0].mojoItinerary : false);

      // roomPax
      if (tempBookings[0].roomPax) {
        setRoomPax(tempBookings[0].roomPax);
      } else {
        console.log('tempBookings[0].roomPax: ');
      }

      // Set activities
      if (tempBookings[0].activitiesData) {
        const originalActivitiesData = tempBookings[0].activitiesData.map((activityData) => {
          const { activities, ...rest } = activityData;
          const originalActivities = activities.map((activity) => {
            return activity.values;
          });
          return { ...rest, activities: originalActivities };
        });
        setActivitiesData(originalActivitiesData);
        setActivitiesDataStore(originalActivitiesData);
        setActivitiesDataLatest(originalActivitiesData);
        setTransfers(tempBookings[0].transfersData ? tempBookings[0].transfersData : []);
        setTransfersLatest(tempBookings[0].transfersData ? tempBookings[0].transfersData : []);
        // Convert dates for final page data
        //const convertedFinalPageData = convertFinalPageDataFirestoreTimestampsToISOStrings(tempBookings[0].calculatedPricing);

        // Set the converted data to state
        setFinalPageData(tempBookings[0].calculatedPricing ? tempBookings[0].calculatedPricing : []);
        setFinalPageTransferData(tempBookings[0].transfersPricing ? tempBookings[0].transfersPricing : []);
        //console.log('tempBookings[0].otherArrangementsData[0].flyingDoctorInsurance: ' + tempBookings[0].otherArrangementsData[0].flyingDoctorInsurance);
        if (
          tempBookings[0].otherArrangementsData &&
          tempBookings[0].otherArrangementsData[0] &&
          tempBookings[0].otherArrangementsData[0].flyingDoctorInsurance &&
          !tempBookings[0].otherArrangementsData[0].edit
        ) {
          const flyingDoctorUnits = getHighestPropertyPax(tempBookings[0].itineraryData);
          const flyingDoctorUnitPrice = 25;
          setOtherArrangementsData([
            {
              name: 'Flying Doctors insurance',
              description: `Would you like to include Flying Doctors insurance for all passengers? (${flyingDoctorUnits} passengers x ${'$' + flyingDoctorUnitPrice})`,
              startDay: tempBookings[0].startDay,
              units: flyingDoctorUnits,
              unitPrice: flyingDoctorUnitPrice,
              supplierName: 'AMREF Flying Doctors Limited',
              supplierUid: '9b8a3fd8-cc88-443f-b629-f3c70856ae5a',
              saleTotal: tempBookings[0].otherArrangementsData[0].flyingDoctorInsurance,
              creatioType: 'otherArrangementsFlyingDoctor',
              type: 'otherArrangementsFlyingDoctor',
              edit: false,
            },
          ]);
        } else if (tempBookings[0].otherArrangementsData) {
          setOtherArrangementsData(tempBookings[0].otherArrangementsData);
        }

        const loadedAgent = await loadAgent(tempBookings[0].agent.uid);
        console.log('loadedAgent');
        console.log(JSON.stringify(loadedAgent, undefined, 4));
        setAgentCommission(user.internalUser ? loadedAgent.rateBandOffline.commission : loadedAgent.rateBand.commission);
        setAgentObject(loadedAgent);
        setCommissionAgent(tempBookings[0].agent);
        setCommissionUser(tempBookings[0].user);

        if (user.consultant) {
          // load and populate agents and consultant details

          // agents
          const loadAgents = await loadAgentDetails(user.consultant.companyId);
          setAgents(loadAgents);
          console.log('loadAgents');
          console.log(JSON.stringify(loadAgents, undefined, 4));
          if (!internalUser && loadAgents.length > 0) {
            // selectAgent(loadAgents[0].uid, loadAgents);
          }
          // consultant
          const loadConsultant = await loadConsultantDetails(user.consultant.uid);
          console.log('loadConsultant');
          setConsultantLoaded(loadConsultant);
          console.log(JSON.stringify(loadConsultant, undefined, 4));

          if (!internalUser && loadAgents.length > 0) {
            setSelectedConsultantName(loadConsultant.fullName);
            setSelectedConsultantUid(loadConsultant.uid);
            setConsultantResRequestId(loadConsultant.resRequestId);
            setSelectedConsultant(true);
          }
        }

        // RP 13/05/24 - START
        setResRequestStatus(tempBookings[0].resRequestStatus);
        // RP 13/05/24 - END

        setVersion(tempBookings[0].version);

        let totalCost = 0;
        originalActivitiesData.forEach((property) => {
          property.activities.forEach((day) => {
            day.forEach((activity) => {
              totalCost += activity.cost;
            });
          });
        });
        setActivitiesCost(totalCost);
        console.log('total cost: ' + totalCost);
      }

      await loadItinerariesVc(
        uid,
        setItinerariesVcMenu,
        setDocUid,
        setReplaceItinerary,
        selectedItemVc,
        setSelectedItemVc,
        tempBookings[0].userVersion,
        setRefreshAllRates,
        setFirstLoadAvailability,
        setFirstLoad,
        tempBookings[0].status,
        setStep,
        handleOpenViewItinerariesSlideOut,
        setViewItinerariesMenu,
        setSelectedViewUid,
        setSelectedViewLabel,
        selectedItemView,
        setSelectedItemView,
        setItinerariesVcOptions,
      );

      setSelectedAgent(true);
      setSelectedConsultant(true);

      // if (firstLoad) {
      await loadGetItinerary(tempBookings[0].resReqReservationId, setGetItineraryData, setCurrentItineraryIds);
      //   setFirstLoad(false);
      // }
      setResReqReservationId(tempBookings[0].resReqReservationId);

      const tempUserVersion = tempBookings[0].userVersion ? tempBookings[0].userVersion : 1;

      setUserVersion(tempUserVersion);

      // const fetchUserVersion = async () => {
      //   const getUserVersion = await loadItineraryVersion(uid);
      //   console.log('getUserVersion', getUserVersion);
      //   console.log('userVersion', tempUserVersion);

      //   console.log('docUid', docUid);

      //   console.log('uid', uid);

      //   if (tempUserVersion < getUserVersion || docUid !== uid) {
      //     setMasterVersion(true);
      //     setNewUserVersion(true);

      //     console.log('masterVersion');
      //   } else {
      //     setMasterVersion(false);
      //     setNewUserVersion(false);
      //   }
      // };
      // fetchUserVersion();

      console.log('docUid', docUid);

      console.log('uid', uid);

      if (docUid !== uid) {
        setMasterVersion(true);
        setNewUserVersion(true);

        console.log('masterVersion');
      } else {
        setMasterVersion(false);
        setNewUserVersion(false);
      }

      console.log('tempBookings[0].itineraryData');

      console.log(JSON.stringify(tempBookings[0].itineraryData, undefined, 4));
      for (let i = 0; i < tempBookings[0].itineraryData.length; i++) {
        const formattedDate = tempBookings[0].itineraryData[i].startDay.seconds
          ? formatFireStoreDate(tempBookings[0].itineraryData[i].startDay.seconds, tempBookings[0].itineraryData[i].startDay.nanoseconds)
          : tempBookings[0].itineraryData[i].startDay;

        if (i === 0) {
          console.log('START DAY TEST');
          console.log(tempBookings[0].itineraryData[i].startDay);

          const formattedStart = moment.utc(formattedDate).subtract(0, 'days').format('YYYY-MM-DD');

          setDateStart(formattedStart);
          setArrowDate(formattedStart);

          const formattedEnd = moment.utc(formattedDate).add(30, 'days').format('YYYY-MM-DD');
          setDateEnd(formattedEnd);

          setRealDate(formattedStart);
        }

        if (tempBookings[0].itineraryData[i].startDay.seconds) {
          tempBookings[0].itineraryData[i].startDay = formatFireStoreDate(tempBookings[0].itineraryData[i].startDay.seconds, tempBookings[0].itineraryData[i].startDay.nanoseconds);
        }

        if (tempBookings[0].itineraryData[i].endDay.seconds) {
          tempBookings[0].itineraryData[i].endDay = formatFireStoreDate(tempBookings[0].itineraryData[i].endDay.seconds, tempBookings[0].itineraryData[i].endDay.nanoseconds);
        }

        await loadProperties(tempBookings[0].itineraryData[i].propUid, properties, navigate);
      }

      // make sure that no bookings/properties are non-nomad when on provisional or pendingProvisional status - START
      console.log('Deal with properties supplier');
      console.log(JSON.stringify(properties, undefined, 4));

      console.log(tempBookings[0].status);
      for (let i = 0; i < tempBookings[0].itineraryData.length; i++) {
        if (
          (tempBookings[0].status === 'provisional' || tempBookings[0].status === 'pendingProvisional') &&
          !user.internalUser &&
          properties[getPropObj(tempBookings[0].itineraryData[i].propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67' &&
          !tempBookings[0].itineraryData[i].propUid.includes('_D-I-R-E-C-T_')
        ) {
          console.log('show modal');
          console.log(tempBookings[0].itineraryData[i].propName);
          setIsLoading(false);
          setShowPage(false);

          // Show the modal/alert
          confirmAlert({
            customUI: ({ onClose }) => {
              // Overlay click is not handled, so clicking outside doesn't close the alert
              return (
                <div className="custom-ui-overlay" onClick={(e) => e.stopPropagation()}>
                  <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                    <p className="text-sm font-semibold pb-2">Unable to edit itinerary</p>
                    <p className="text-sm pb-2">Your itinerary is currently under provisional status and contains third party properties.</p>
                    <p className="text-sm pb-2">Please contact your sales person if you wish to make a change.</p>
                    <div className="flex mt-5 justify-end">
                      <div>
                        {/* <button
                          className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                          onClick={() => {
                            setRefreshBtn(false);
                            onClose();
                          }}
                        >
                          Cancel
                        </button> */}
                        <ButtonPrimarySmall
                          onClick={async () => {
                            navigate('/itinerary/manage/manageItineraries/', { replace: true });
                            onClose();
                          }}
                          color="dark"
                          text="Go to Manage Itineraries"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
            closeOnEscape: false,
            closeOnClickOutside: false,
          });

          // Prevent further execution in this useEffect
          return;
        }
        //console.log(tempBookings[0].itineraryData[i].status);
      }

      // make sure that no bookings/properties are non-nomad when on provisional or pendingProvisional status - END

      // Set date to 12:00 midday UTC

      if (tempBookings[0].status === 'provisional' || tempBookings[0].status === 'confirmed' || tempBookings[0].status === 'pendingProvisional' || tempBookings[0].status === 'pendingConfirmation') {
        setUpdateRoomAvailability(true);
      }
      if (tempBookings[0].status === 'quotation' || tempBookings[0].resRequestStatus === 0) {
        setQuoteMode(true);
        //setShowQuoteMode(true);
        setShowQuoteMode(false);
      }

      if (tempBookings[0].dateModified) {
        setDateModified(true);
      }

      // Set advanced mode
      setAdvancedMode(tempBookings[0]?.advancedMode === true);
      setHornbillDiscountAvailable(tempBookings[0]?.hornbillDiscountAvailable === true);

      if (firstLoad) {
        setBookingsStore(tempBookings[0].itineraryData);
        const copyOfItineraryStore = _.cloneDeep(tempBookings[0]);

        console.log('copyOfItineraryStore UID', copyOfItineraryStore.uid);
        console.log(JSON.stringify(copyOfItineraryStore, undefined, 4));
        console.log('copyOfItineraryStore UID', copyOfItineraryStore.totalPrice);
        setItineraryStore(copyOfItineraryStore);
        const copyOfBookings = _.cloneDeep(bookings);
        setBookingsControl(copyOfBookings);
        // copy for removing vehicles later
        setBookingsDataStore(tempBookings[0].itineraryData);
        setFirstLoad(false);
      }
      console.log('tempBookings[0].itineraryData');
      console.log(JSON.stringify(tempBookings[0].itineraryData, undefined, 4));
      let bookingsAddTo = tempBookings[0].itineraryData;

      // setItineraryDataFlights a copy of itineraryData
      const copyOfTransfersData = _.cloneDeep(tempBookings[0].transfersData);
      setTransfersDataFlights(copyOfTransfersData);

      // for (let i = 0; i < bookingsAddTo.length; i++) {
      //   bookingsAddTo[i].unit = 0;
      //   bookingsAddTo[i].ad = 0;
      //   bookingsAddTo[i].ch = 0;
      //   bookingsAddTo[i].chi = 0;
      //   bookingsAddTo[i].inf = 0;
      // }

      // customFinalItems

      if (tempBookings[0].customFinalItems) {
        setCustomFinalItems(tempBookings[0].customFinalItems);
      }

      //roomPax and guests
      // TODO VERY NEW GUESTS ETC
      // setGuests
      if (tempBookings[0].guests) {
        //setGuests(tempBookings[0].guests);
        try {
          let guestsCollection = docUid === uid ? 'itineraries' : 'itinerariesVersionControl';

          const rebuiltGuests = await rebuildGuestsFromSubcollection(db, docUid, guestsCollection);
          console.log('rebuiltGuests');
          const guestResult = rebuiltGuests;
          setGuests(guestResult);
          setGuestsStore(guestResult);
          setBookings(bookingsAddTo);
        } catch (error) {
          console.error('Error fetching guests:', error);
        }

        setBookings(bookingsAddTo);
      } else {
        // Calculate the max number of adults and children in any booking
        let maxAdults = 0;

        bookingsAddTo.forEach((booking) => {
          let totalAdults = 0;
          booking.rooms.forEach((room) => {
            totalAdults += room.ad;
          });
          maxAdults = Math.max(maxAdults, totalAdults);
        });

        // Update the guests object
        const updatedGuests = {
          adults: maxAdults,
          children: 0, // Setting as zero for now
          adultAges: Array.from({ length: maxAdults }, () => ({ uuid: uuidv4(), age: 99 })),
          childAges: [], // Empty array
        };

        setGuests(updatedGuests);

        // Update each booking and room

        let newBookings = bookingsAddTo.map((booking) => {
          const guestsMerged = { ...updatedGuests };

          let lastAssignedAdultIndex = 0; // Track the last assigned adult index for each booking

          booking.rooms.forEach((room) => {
            // Assign adults from the last assigned index
            room.selectedAgesAdults = guestsMerged.adultAges.slice(lastAssignedAdultIndex, lastAssignedAdultIndex + room.ad);
            lastAssignedAdultIndex += room.ad; // Update the index for the next room

            // Calculate and set the total children count
            const totalChildren = room.ch + room.chi + room.inf;
            room.ch = totalChildren;
            room.chi = 0;
            room.inf = 0;

            // Set selectedAges for children
            room.selectedAges = Array.from({ length: totalChildren }, () => -1);
          });

          return { ...booking, guestsMerged };
        });

        // const newBookings = bookingsAddTo.map((booking) => {
        //   const guestsMerged = { ...updatedGuests };

        //   booking.rooms.forEach((room) => {
        //     room.selectedAgesAdults = guestsMerged.adultAges.slice(0, room.ad);

        //     const totalChildren = room.ch + room.chi + room.inf;
        //     room.ch = totalChildren;
        //     room.chi = 0;
        //     room.inf = 0;

        //     room.selectedAges = Array.from({ length: totalChildren }, () => -1);
        //   });

        //   return { ...booking, guestsMerged };
        // });

        console.log('newBookings');
        console.log(JSON.stringify(newBookings, undefined, 4));
        console.log('properties');
        console.log(JSON.stringify(properties, undefined, 4));

        // Now set the state with the updated bookings array

        setBookings(newBookings);
      }

      // Create a new array based on bookingsAddTo to avoid direct mutation

      // Update the state with the new bookings array

      // Now set the state with the updated bookings array

      // console.log('bookingsAddTo');
      // console.log(JSON.stringify(bookingsAddTo, undefined, 4));

      //setBookingsControl(bookingsAddTo);

      console.log('Properties: ' + JSON.stringify(properties, undefined, 4));
      console.log('Bookings: ' + JSON.stringify(bookings, undefined, 4));

      // fix adding notModified here to ensure it is added 14/08/24
      bookingsAddTo.forEach((booking) => {
        booking.notModified = true;
        if (booking.prices && !booking.lastPricedRoomsArray) {
          //booking.lastPricedRoomsArray = booking.rooms;
          booking.lastPricedRoomsArray = JSON.parse(JSON.stringify(booking.rooms));
          console.log('updated lastPricedRoomsArray 2');
        }

        console.log('booking.notModified set to true 2');
      });

      // Karani legacy check
      // ! 25-Sept-24
      bookingsAddTo.forEach((booking) => {
        const propKey = getPropObj(booking.propUid, properties);
        const platformKaraniActive = properties[propKey]?.platformKarani?.active;

        if (platformKaraniActive && properties[propKey]?.supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
          if (typeof booking.price === 'undefined' || typeof booking.prices === 'undefined') {
            // Legacy pricing check
            booking.notKaraniPriced = true;
          }
        }
      });

      await loadAgentRates(tempBookings[0].agent.rateId, setAgentRates, tempBookings[0].agent, bookingsAddTo, setBookings, false);
      setLoadStock(true);

      // New code fix
      setFetchCustom(true);
    }
    if (replaceItinerary) {
      setRooms([]);
      // setProperties([]);
      // setBookings([]);

      // New code fix
      setFirstLoadAvailability(true);

      setReplaceItinerary(false);
      //setVisitedFinalPage(false);

      //setRefreshAllRates(true);

      setIsLoading(true);
      fetchData();
    }
  }, [bookings, docUid, firstLoad, navigate, properties, replaceItinerary, selectedItemVc, uid]);

  // LOAD STOCK DATA
  const [iteration, setIteration] = useState(0);

  const isPropUidUnique = (rooms, propUid) => {
    return !rooms.some((room) => room.propUid === propUid);
  };
  useEffect(() => {
    if (loadStock) {
      if (iteration < properties.length) {
        if (isPropUidUnique(rooms, properties[iteration].uid)) {
          getStock(
            iteration,
            dateStart,
            dateEnd,
            properties,
            setProperties,
            rooms,
            setRooms,
            null,
            reCountBookings,
            bookings,
            setBookings,
            realDate,
            null,
            bookingsStore,
            updateRoomAvailability,
            activitiesData,
          );
        }

        setIteration(iteration + 1);
      } else {
        setLoadStock(false);
        setIsLoading(false);
      }
    }
  }, [iteration, loadStock, properties, rooms, dateStart, dateEnd, bookings, realDate, bookingsStore, updateRoomAvailability, activitiesData]);

  useEffect(() => {
    if (recount) {
      setTimeout(() => {
        reCountBookings(bookings, setBookings, realDate, rooms, setRooms, properties, setRefreshRatesEffect, true);

        setRecount(false);
      }, 500);
    }
  }, [bookings, realDate, properties, recount, rooms]);

  useEffect(() => {
    if (refreshRatesEffect) {
      if (bookings.some((booking) => booking.rooms.length !== 0)) {
        setRefreshAllRates(true);
      }
      setRefreshRatesEffect(false);
    }
  }, [bookings, refreshRatesEffect]);

  // resetDate
  useEffect(() => {
    //call function when something change in state
    resetDate(dateStart, dateEnd, property, setProperty);
  }, [dateEnd, dateStart, property]);

  useEffect(() => {
    if (callMakeRooms !== null && getPropertiesAndStock !== null) {
      const propIndexCall = callMakeRooms;
      // !console.log("START ADD: " + propIndexCall);
      if (user.internalUser || !bookings.length || bookings[bookings.length - 1].propUid !== properties[propIndexCall].uid) {
        // !console.log("adding booking");
        // NORTHERN GUIDE SUPPLEMENT
        const { northernGuideVehicleSupplement, northernGuideVehiclePrice, northernGuideVehicleResRequestId } = processNorthernGuideVehicleSupplement(
          properties,
          propIndexCall,
          agentObject,
          agentRates,
        );
        setRefreshBtn(true);
        addBooking(
          propIndexCall,
          properties[propIndexCall].uid,
          bookings,
          setBookings,
          realDate,
          rooms,
          packageTab,
          properties,
          northernGuideVehicleSupplement,
          northernGuideVehiclePrice,
          northernGuideVehicleResRequestId,
          guests,
          user,
          null,
          switchId,
          switchStartDate,
          switchEndDate,
        );
        if (switchId) {
          handleClosePropertiesSlideOut();
        }

        setSwitchParkId(null);
        setSwitchId(null);
        setSwitchStartDate(null);
        setSwitchEndDate(null);

        //setRefreshRatesEffect(true);
      } else {
        toast.error("You can't add a property directly after the same property");
      }

      if (getPropertiesAndStock === true) {
        console.log('loading stock 2');
        getStock(
          propIndexCall,
          dateStart,
          dateEnd,
          properties,
          setProperties,
          rooms,
          setRooms,
          null,
          reCountBookings,
          bookings,
          setBookings,
          realDate,
          null,
          bookingsStore,
          updateRoomAvailability,
          activitiesData,
        );
      } else {
        // reload stock only if not in rooms array
        const propUid = properties[propIndexCall].uid;
        const containsPropUid = rooms.filter((room) => room.propUid === propUid).length > 0;
        // !console.log(containsPropUid);
        if (!containsPropUid) {
          // !console.log("loading stock 4");
          getStock(
            propIndexCall,
            dateStart,
            dateEnd,
            properties,
            setProperties,
            rooms,
            setRooms,
            null,
            reCountBookings,
            bookings,
            setBookings,
            realDate,
            setRefreshRatesEffect,
            bookingsStore,
            updateRoomAvailability,
            activitiesData,
          );
        }
      }
      setCallMakeRooms(null);
      SetGetPropertiesAndStock(null);
      setTimeout(() => {
        setLoadingData(false);
      }, 500);
    }
  }, [activitiesData, bookings, bookingsStore, callMakeRooms, realDate, dateEnd, dateStart, getPropertiesAndStock, packageTab, properties, rooms, updateRoomAvailability]);

  // link to confirmation page
  useEffect(() => {
    if (navigateToManageItineraries) {
      setIsLoading(false);
      if (itineraryStore.status === 'draft' || itineraryStore.status === 'quotation' || itineraryStore.status === 'expired') {
        navigate(`/itinerary/confirm/confirmItinerary/${uid}`, {
          replace: true,
        });
      } else {
        toast.success('Your Edit has been saved');
        navigate(`/itinerary/view/viewItinerary/${uid}`, { replace: true });
        //navigate('/itinerary/manage/manageItineraries/', { replace: true });
      }
      setNavigateToManageItineraries(false);
    }
  }, [itineraryStore.resRequestStatus, itineraryStore.status, navigate, navigateToManageItineraries, uid]);

  // NOW
  useEffect(() => {
    if (firstLoadAvailability && !rooms.some((room) => room.loading) && !loadingData && !isLoading) {
      sameDate(setArrowDate, arrowDate);
      setRefreshAllRates(false);
      //setFetchingRates(true); // commented to stop loading rates 10/05/24
      console.log('DEBUG');
      console.log(agentRates);
      console.log(agentCommission);

      // Karani legacy check
      // bookings.forEach((booking) => {
      //   const propKey = getPropObj(booking.propUid, properties);
      //   const platformKaraniActive = properties[propKey]?.platformKarani?.active;

      //   if (platformKaraniActive && properties[propKey]?.supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
      //     if (typeof booking.price === 'undefined' || typeof booking.prices === 'undefined') {
      //       // Legacy pricing check
      //       booking.notKaraniPriced = true;
      //     }
      //   }
      // });

      //31/-5/24
      bookingsDataStore.forEach((booking) => {
        booking.notModified = true;
        if (booking.prices && !booking.lastPricedRoomsArray) {
          //booking.lastPricedRoomsArray = booking.rooms;
          booking.lastPricedRoomsArray = JSON.parse(JSON.stringify(booking.rooms));
          console.log('updated lastPricedRoomsArray 1');
        }
        console.log('booking.notModified set to true');
        const propKey = getPropObj(booking.propUid, properties);
        const platformKaraniActive = properties[propKey]?.platformKarani?.active;

        if (platformKaraniActive && properties[propKey]?.supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
          if (typeof booking.price === 'undefined' || typeof booking.prices === 'undefined') {
            // Legacy pricing check
            //booking.notKaraniPriced = true;
            booking.notKaraniPriced = false;
          }
        }
      });

      //refreshRates(bookings, setBookings, setRefreshBtn, properties, setFetchingRates, agentRates, agentCommission, guests, agentObject, createVersionCheck); // commented to stop loading rates 10/05/24

      // fix guests 05/06/24 -> START
      // setGuests
      // ! USED TWICE - START
      // if (tempBookingsStore[0].guests) {
      //   setGuests(tempBookingsStore[0].guests);
      //   setBookings(bookingsDataStore);
      // } else {
      //   // Calculate the max number of adults and children in any booking
      //   let maxAdults = 0;

      //   bookingsDataStore.forEach((booking) => {
      //     let totalAdults = 0;
      //     booking.rooms.forEach((room) => {
      //       totalAdults += room.ad;
      //     });
      //     maxAdults = Math.max(maxAdults, totalAdults);
      //   });

      //   // Update the guests object
      //   const updatedGuests = {
      //     adults: maxAdults,
      //     children: 0, // Setting as zero for now
      //     adultAges: Array.from({ length: maxAdults }, () => ({ uuid: uuidv4(), age: 99 })),
      //     childAges: [], // Empty array
      //   };

      //   setGuests(updatedGuests);

      //   // Update each booking and room

      //   const newBookings = bookingsDataStore.map((booking) => {
      //     const guestsMerged = { ...updatedGuests };

      //     let lastAssignedAdultIndex = 0; // Track the last assigned adult index for each booking

      //     booking.rooms.forEach((room) => {
      //       // Assign adults from the last assigned index
      //       room.selectedAgesAdults = guestsMerged.adultAges.slice(lastAssignedAdultIndex, lastAssignedAdultIndex + room.ad);
      //       lastAssignedAdultIndex += room.ad; // Update the index for the next room

      //       // Calculate and set the total children count
      //       const totalChildren = room.ch + room.chi + room.inf;
      //       room.ch = totalChildren;
      //       room.chi = 0;
      //       room.inf = 0;

      //       // Set selectedAges for children
      //       room.selectedAges = Array.from({ length: totalChildren }, () => -1);
      //     });

      //     return { ...booking, guestsMerged };
      //   });

      //   console.log('newBookings 1');
      //   console.log(JSON.stringify(newBookings, undefined, 4));
      //   console.log('properties');
      //   console.log(JSON.stringify(properties, undefined, 4));

      //   // Now set the state with the updated bookings array
      //   setBookings(newBookings);
      // }
      // ! USED TWICE - END
      //setBookings(bookingsDataStore);
      // fix guests 05/06/24 -> END

      setFirstLoadAvailability(false);
    } else {
      // setFirstLoadAvailability(false);
    }
  }, [agentCommission, agentRates, arrowDate, bookings, firstLoadAvailability, isLoading, loadingData, properties, rooms]);

  useEffect(() => {
    if (callUpdateDates !== null) {
      updateDates(
        rooms,
        properties,
        setRooms,
        setProperties,
        dateStart,
        dateEnd,
        reCountBookings,
        bookings,
        setBookings,
        dateStart,
        setRefreshRatesEffect,
        bookingsStore, // NEW ADDED
        updateRoomAvailability, // NEW ADDED
        activitiesData, // NEW ADDED
      );
      if (bookings.length > 0) {
        reCountBookings(bookings, setBookings, dateStart, rooms, setRooms, properties, setRefreshRatesEffect, false);
        // reCountBookings(bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect, true);
      }
      setCallUpdateDates(null);
    }
  }, [bookings, callUpdateDates, realDate, dateEnd, dateStart, properties, rooms, bookingsStore, updateRoomAvailability, activitiesData]);

  const hasRunForTransfers = useRef(false);

  useEffect(() => {
    if (step === 'transfers' && !hasRunForTransfers.current) {
      const updatedBookings = filterVehicleRoomsNoSet(bookings);

      //setBookings((bookings) => [...bookings]);

      transfers.forEach(async (item) => {
        await calculatePropertyVehicles(updatedBookings, setBookings, transfers, item.tuuid, properties);
        console.log(item.tuuid);
      });

      hasRunForTransfers.current = true;
    } else if (step !== 'transfers') {
      hasRunForTransfers.current = false;
    }
  }, [bookings, properties, step, transfers, transfersLatest]);

  useEffect(() => {
    if (
      (step === 'activities' && (user.accessLevel === 'limited' || user.accessLevel === 'travelAgent') && !semver.gte(createVersionCheck, '2.5.0')) ||
      (step === 'activities' && mojoItinerary === true)
    ) {
      // BUILD VEHICLE DATA FOR ACTIVITIES PAGE START --------------------------------------------------------------------------------
      // move to next step or do not use transfers
      const tempBookings = filterVehicleRoomsNoSet(bookings);
      //setBookings(tempBookings);

      transfers.forEach(async (item) => {
        console.log('calculatePropertyVehicles rebuilding');
        await calculatePropertyVehicles(tempBookings, setBookings, transfers, item.tuuid, properties);
        console.log(item.tuuid);
      });
      // BUILD VEHICLE DATA FOR ACTIVITIES PAGE END --------------------------------------------------------------------------------
    }
  }, [step]);

  // Save booking to FB
  const saveDataToDatabase = async (latestHiddenValue, latestReprintInvoiceValue) => {
    console.log('latestHiddenValue', latestHiddenValue);
    setIsLoading(true);

    if (bookings.length > 0) {
      // !console.log(bookings.length);
      // !console.log("bookings.length");

      for (let i = 0; i < bookings.length; i++) {
        // Delete items from bookings array
        delete bookings[i].selectedRoom;
        delete bookings[i].selectedName;
        delete bookings[i].refresh;
        delete bookings[i].expanded;
        delete bookings[i].addRoom;
        delete bookings[i].unit;
        delete bookings[i].ad;
        delete bookings[i].ch;
        delete bookings[i].chi;
        delete bookings[i].inf;
        delete bookings[i].inf;

        for (let ii = 0; ii < bookings[i].rooms.length; ii++) {
          delete bookings[i].rooms[ii].edit;
          // delete bookings[i].rooms[ii].maxCapacity;
          // delete bookings[i].rooms[ii].maxAdults;
        }
      }

      // jsonData
      let jsonData = [];

      //setIsLoading(false);

      // if (!mojoItinerary || mojoItinerary === null || mojoItinerary === undefined) {
      jsonData = await createJsonObject(
        properties,
        bookings,
        transfers,
        activitiesData,
        airports,
        finalPageData,
        otherArrangementsData,
        finalPageTransferData,
        suppliers,
        customLocations,
        customFinalItems,
      );

      if (!jsonData) {
        setIsLoading(false);
        return;
      }
      // }

      console.log('jsonData b4 update');
      console.log(JSON.stringify(jsonData, undefined, 4));
      // Fetch the count of existing documents
      const existingDocCount = await loadCountItinerariesVc(uid);
      const userVersionLive = await loadItineraryVersion(uid);

      //quote mode
      // let processQuoteMode = false;
      // if (itineraryStatus === 'quotation' && !quoteMode) {
      //   processQuoteMode = true;
      // }

      // let sendQuoteMode = quoteMode;
      // if (quoteMode && itineraryStatus === 'quotation') {
      //   sendQuoteMode = false;
      // }

      //return alert(processQuoteMode);

      const totalGuests = bookings.reduce((maxGuests, booking) => {
        const { rooms } = booking;
        const totalGuests = rooms.reduce((sum, room) => {
          sum += room.ad + room.ch + room.chi + room.inf;
          return sum;
        }, 0);
        if (totalGuests > maxGuests) {
          maxGuests = totalGuests;
          maxBooking = booking;
        }
        return maxGuests;
      }, 0);

      const newItineraryName = !itineraryRenamed ? clientName + ' x ' + totalGuests : itineraryName;
      // RP 13/05/24 - START
      const resRequestStatusId = getIdFromStatus(status);
      // RP 13/05/24 - END

      // Add 1 to this count to get the new version
      const newVersion = existingDocCount + 2;
      const vcVersion = existingDocCount + 1;
      await updateItinerary(
        resReqReservationId,
        bookings,
        currentItineraryIds,
        properties,
        agentRates,
        uid,
        itineraryStore,
        setNavigateToManageItineraries,
        activitiesData,
        transfers,
        jsonData,
        finalPageData,
        newVersion,
        setCallUpdateDates,
        deeShadow,
        setStep,
        setIsLoading,
        timestampStartedEdit,
        mojoItinerary,
        user,
        otherArrangementsData,
        activitiesDataStore,
        bookingsDataStore,
        quoteMode,
        dateModified,
        finalPageTransferData,
        newItineraryName,
        itineraryRenamed,
        roomPax,
        guests,
        newUserVersion,
        userVersionLive,
        customFinalItems,
        packageJson.version,
        createVersionCheck,
        agentObject,
        resRequestStatus,
        resRequestStatusId,
        vcVersion,
        guestsStore,
        dateCreated,
        latestHiddenValue,
        latestReprintInvoiceValue,
        status,
        advancedMode,
      );
    }
  };

  // #endregion

  // !Search for properties start

  const [searchState, setSearchState] = useState(() => urlToSearchState(window.location));
  const timerRef = useRef(null);

  useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      window.history.pushState(searchState, null, searchStateToUrl({ location: window.location }, searchState));
    }, 400);
  }, [searchState]);
  // !Search for properties end

  // Set defaults for adding up guests
  let maxGuests = 0;
  let maxBooking = null;

  useEffect(() => {
    const filteredActivitiesWithAvailability = filterActivities(resetActivities, rooms, selectedActivityDate, selectedActivityPropertyUid);
    if (filteredActivitiesWithAvailability !== null) {
      setActivitiesList(filteredActivitiesWithAvailability);
      setResetActivities(null);

      // Check if any of the activities are bookable
      if (filteredActivitiesWithAvailability.some((activity) => activity.bookable)) {
        setActivitiesTab('prebooked');
      }
      // Check if any of the activities are not bookable
      else if (filteredActivitiesWithAvailability.some((activity) => !activity.bookable)) {
        setActivitiesTab('other');
      }
    }
  }, [resetActivities, rooms, selectedActivityDate, selectedActivityPropertyUid]);

  const [customLocations, setCustomLocations] = useState([]);

  const [fetchCustom, setFetchCustom] = useState(true);

  const [needVehicleUpdate, setNeedVehicleUpdate] = useState(false);

  // Custom locations use effect
  useEffect(() => {
    async function fetchCustomLocations() {
      const result = await buildCustomLocations(bookings, properties);
      setCustomLocations(result);
      // Indicate that vehicle updates are needed
      // Toggle to trigger update
    }

    if (bookings.length && properties && fetchCustom && !rooms.some((room) => room.loading)) {
      fetchCustomLocations();
      setFetchCustom(false);
      setNeedVehicleUpdate(true);
    }
  }, [bookings, fetchCustom, properties, rooms]);

  const hasRunForVehicles = useRef(false);
  useEffect(() => {
    if (needVehicleUpdate && !hasRunForVehicles.current) {
      const updatedBookings = filterVehicleRoomsNoSet(bookings);

      //setBookings((bookings) => [...bookings]);

      transfers.forEach(async (item) => {
        await calculatePropertyVehicles(updatedBookings, setBookings, transfers, item.tuuid, properties);
        console.log(item.tuuid);
      });

      hasRunForVehicles.current = true;
      setNeedVehicleUpdate(false);
    } else if (!needVehicleUpdate) {
      hasRunForVehicles.current = false;
    }
  }, [bookings, needVehicleUpdate, properties, transfers]);

  // TRANSFERS

  const handleClickActivitiesCallback = useCallback(
    (index, indx, item) => {
      const uniqueActivities = handleClickActivities(
        index,
        indx,
        item,
        activities,
        setSelectedActivityProperty,
        setSelectedActivityDay,
        setSelectedActivityDate,
        setSelectedActivityPropertyUid,
        setSelectedActivityPropertyId,
        customLocations,
      );
      setResetActivities(() => uniqueActivities);
    },
    [activities, setSelectedActivityProperty, setSelectedActivityDay, setSelectedActivityDate, setSelectedActivityPropertyUid],
  );

  const hourRef = useRef(null);
  const minuteRef = useRef(null);

  // TRANSFER FORMS ************************************************************* START
  const timeRef = useRef(null);

  const [selectedTransferTuuid, setSelectedTransferTuuid] = useState(null);
  const [selectedTransferNext, setSelectedTransferNext] = useState(null);
  const [selectedTransferPrev, setSelectedTransferPrev] = useState(null);
  const [selectedTransferCurrent, setSelectedTransferCurrent] = useState(null);
  const [selectedTransferSingleDepartureCurrent, setSelectedTransferSingleDepartureCurrent] = useState(null);
  const [selectedTransferSingleArrivalCurrent, setSelectedTransferSingleArrivalCurrent] = useState(null);

  const [selectedBooking, setSelectedBooking] = useState(null);

  const [airports, setAirports] = useState(tempAirports);
  const [airlines, setAirlines] = useState(tempAirlines);
  const [locations, setLocations] = useState(tempLocations);
  const [suppliers, setSuppliers] = useState(tempSuppliers);
  const [templateSearchOptions, setTemplateSearchOptions] = useState(tempTemplateSearchOptions);

  const [availableFlights, setAvailableFlights] = useState('start');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTransferRates, setRefreshTransferRates] = useState(false);

  const handleAddTransferClick = async (transfer) => {
    console.log('BEFORE routeTransfers');

    const index = transfers.findIndex((transfer) => transfer.objType === 'arrivalTransfer');

    await availableTransfers(transfer, transfers, index, properties, 'arrivalTransfer', setTransfers, airports, transfers, locations, customLocations);
    console.log('AFTER routeTransfers');
  };

  // PROPERTY FILTERS ************************************************************* START
  const generateFilters = () => {
    let filters = 'active:true';

    if ((user.internalUser === false && user.fullBoardOnly === true) || mojoItinerary === true) {
      filters = `(supplier.name:"Nomad Tanzania" OR supplier.name:"Booked Direct") AND ` + filters;
    } else if (user.internalUser === false && user.fullBoardOnly === false) {
      filters = `(platformKarani.active:true OR supplier.name:"Booked Direct") AND ` + filters;
    } else if (user.internalUser === true) {
      console.log('internal user');
    } else {
      filters = `(supplier.name:"Nomad Tanzania" OR supplier.name:"Booked Direct") AND ` + filters;
    }

    return filters;
  };

  const filters = generateFilters();

  // PROPERTY FILTERS ************************************************************* END
  const flatpickrRef = useRef(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [pickerDate, setPickerDate] = useState(false);
  const handlePlaceholderClick = () => {
    setShowDatePicker(true);

    // Using a timeout to ensure the Flatpickr component is rendered before we attempt to open it
    setTimeout(() => {
      if (flatpickrRef.current) {
        flatpickrRef.current.flatpickr.open();
      }
    }, 0);
  };

  // FINAL PAGE BUILD

  const [finalCollapse, setFinalCollapse] = useState(true);

  const hasRunFinalStep = useRef(false);

  useEffect(() => {
    if (step === 'final' && !hasRunFinalStep.current) {
      // Mark as run for this 'final' step
      hasRunFinalStep.current = true;

      //Transfers
      updateTransferData(finalPageTransferData, setFinalPageTransferData);
      //Properties
      updatePropertyData(finalPageData, setFinalPageData);
      collapseExpandAllItems(finalPageData, setFinalPageData, finalPageTransferData, setFinalPageTransferData, customFinalItems, setCustomFinalItems, setFinalCollapse, true);
    } else if (step !== 'final') {
      // Reset when step is not 'final', allowing it to run again when 'final' is reached next time
      hasRunFinalStep.current = false;
    }
  }, [step, finalPageTransferData, finalPageData, customFinalItems]);

  // THEN
  // useEffect(() => {
  //   if (firstLoadAvailability && !rooms.some((room) => room.loading) && !loadingData && !isLoading) {
  //     const updatedBookings = bookings.map((booking) => {
  //       const matchingBookingStore = bookingsStore.find((store) => store.id === booking.id);

  //       if (matchingBookingStore) {
  //         const filteredRooms = matchingBookingStore.rooms.filter((room) => room.rate?.payable || room.rate?.nett);

  //         const updatedRooms = filteredRooms.map((room) => ({
  //           ...room,
  //           edit: false,
  //         }));

  //         return {
  //           ...booking,
  //           rooms: updatedRooms,
  //         };
  //       }

  //       return {
  //         ...booking,
  //         rooms: booking.rooms.map((room) => ({
  //           ...room,
  //           edit: false,
  //         })),
  //       };
  //     });

  //     setBookings(updatedBookings);

  //     setFirstLoadAvailability(false);
  //     //setRefreshAllRates(false);
  //     setRefreshBtn(false);
  //   }
  //   setRefreshBtn(false);
  // }, [bookings, bookingsStore, firstLoadAvailability, isLoading, loadingData, rooms]);

  //NEW PROPERTIES WORK
  const [firstSearch, setFirstSearch] = useState(true);
  //const [startSearchButton, setStartSearchButton] = useState('start');
  const [startSearchButton, setStartSearchButton] = useState(user.internalUser === false && user.fullBoardOnly === false ? 'search' : 'start');

  const [tripType, setTripType] = useState('safariBeach');
  const [existingClient, setExistingClient] = useState(user.internalUser === false ? false : true);
  const [searchRequestResult, setSearchRequestResult] = useState(null);
  const [showTemplates, setShowTemplates] = useState(false);

  function handleClientNameInputChange(e) {
    setClientName(e.target.value);
    setSelectedConsultantName(consultantLoaded.fullName);
    setSelectedConsultantUid(consultantLoaded.uid);
    setConsultantResRequestId(consultantLoaded.resRequestId);
    // update your state here based on the name and value
  }

  //custom select

  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = templateSearchOptions.map((templateSearchOption) => ({
    value: templateSearchOption.uid,
    label: templateSearchOption.nameShort || templateSearchOption.name,
    name: templateSearchOption.name,
    description: templateSearchOption.description,
    type: templateSearchOption.type,
    park: templateSearchOption.park,
    uid: templateSearchOption.uid,
    nameShort: templateSearchOption.nameShort,
    category: templateSearchOption.category,
    country: templateSearchOption.country,
    heroImageUrl: templateSearchOption.heroImageUrl,
  }));

  // Sort the options array
  const sortedOptions = options.sort((a, b) => {
    if (a.type === 'park' && b.type !== 'park') return -1;
    if (a.type !== 'park' && b.type === 'park') return 1;
    if (a.type === b.type) {
      return a.label.localeCompare(b.label); // Sort alphabetically within each group
    }
    return 0;
  });

  const formatOptionLabel = ({ label, description }) => (
    <div className="flex items-center space-x-3">
      <FaTent size={20} className="" />
      {/* <img src={image} alt={label} className="h-6 w-6 rounded-full object-cover" /> */}
      <div>
        <div className="font-semibold">{label}</div>
        <div className="text-xs text-gray-500">"{description}"</div>
      </div>
    </div>
  );

  const MultiValueLabel = ({ children, ...props }) => <components.MultiValueLabel {...props}>{props.data.label}</components.MultiValueLabel>;

  const Option = ({ innerProps, isDisabled, isFocused, isSelected, ...props }) => (
    <div>
      <div {...innerProps} className={`flex items-center space-x-3 py-3 px-4 ${isDisabled ? 'opacity-50' : ''} ${isFocused ? 'bg-gray-100' : ''} ${isSelected ? 'bg-teal-50' : ''}`}>
        <div className="relative flex-shrink-0 w-9 h-9 rounded-full overflow-hidden">
          {props.data.type === 'property' && props.data.heroImageUrl ? (
            <img src={`${props.data.heroImageUrl}/83x83`} alt={props.data.label} className="w-full h-full object-cover" />
          ) : (
            <PiParkFill className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600 w-6 h-6" />
          )}
        </div>
        <div className="flex-grow min-w-0">
          <div className="text-nomadBlue-900 text-sm font-semibold truncate leading-tight mb-1">{props.data.label}</div>
          <div className="text-nomadGray-700 text-sm font-normal truncate leading-tight">
            {props.data.type === 'property' ? (props.data.description && typeof props.data.description === 'string' ? props.data.description : 'Unknown Park') : props.data.description}
          </div>
        </div>
        <div className="text-right">
          {props.data.type === 'property' ? null : (
            <span
              className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                props.data.category === 'safariPark' ? 'bg-green-50 text-green-700' : props.data.category === 'beach' ? 'bg-blue-50 text-blue-700' : 'bg-gray-50 text-gray-700'
              }`}
            >
              {props.data.category === 'safariPark' ? 'Safari Region' : props.data.category === 'beach' ? 'Coastal Region' : 'Region'}
            </span>
          )}
        </div>
        {/* <div className="flex-shrink-0 w-6 h-6 bg-teal-600 rounded flex items-center justify-center">
          <MdAddCircleOutline className="text-white w-4 h-4" />
        </div> */}
      </div>
      <div className="h-px bg-gray-200 mx-4" /> {/* This is the divider */}
    </div>
  );

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const customStylesTags = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      borderColor: state.isFocused ? '#489EF8' : '#e5e7eb',
      borderWidth: state.isFocused ? '1px' : '1px',
      borderRadius: '0.75rem',
      minHeight: '50px',
      '&:hover': {
        borderColor: state.isFocused ? '#489EF8' : '#e5e7eb',
      },

      width: '688px',
      fontWeight: 'normal',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#f0f9fa' : 'white',
      color: 'inherit',
      '&:hover': {
        backgroundColor: '#e6f7f8',
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: '#e6f7f8',
      borderRadius: '0.375rem',
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      fontWeight: 'semibold',
      color: '#2998a2',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: '#2998a2',
      '&:hover': {
        backgroundColor: '#2998a2',
        color: 'white',
      },
    }),
  };

  // ViewItinerariesSlideOut drop down -> start
  const handleViewChange = (option) => {
    setSelectedItemView(option.value);
    setSelectedViewUid(option.value);
    setSelectedViewLabel(option.label);
  };

  useEffect(() => {
    if (itinerariesVcOptions?.length > 0) {
      setViewItinerariesMenu(
        <div className="flex items-center justify-end">
          <Dropdown>
            <DropdownButton className="flex items-center justify-between w-full px-3 py-2 text-sm bg-white border rounded-tl-md rounded-tr-md rounded-bl-none rounded-br-none shadow-sm focus:outline-none">
              <div className="flex items-center">
                <FiEye className="mr-2 text-white" />
                <span>{itinerariesVcOptions.find((option) => option.value === selectedItemView)?.label || 'Select Version'}</span>
              </div>
              <FiChevronDown className="w-5 h-5 ml-2" />
            </DropdownButton>
            <DropdownMenu className="w-full" anchor="bottom end">
              {itinerariesVcOptions.map((option) => (
                <DropdownItem key={option.value} onClick={() => handleViewChange(option)}>
                  <DropdownLabel>{option.label}</DropdownLabel>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>,
      );
    }
  }, [selectedViewUid, itinerariesVcOptions, selectedItemView]);
  // ViewItinerariesSlideOut drop down -> end

  // NEW SEARCH
  const [availableOnly, setAvailableOnly] = useState(true);
  // Price level
  const [selectedPriceLevel, setSelectedPriceLevel] = useState('All');
  const [selectedPriceLevelUid, setSelectedPriceLevelUid] = useState('all');

  const handleChangePriceLevel = (selectedOption) => {
    setSelectedPriceLevel(selectedOption.label);
    console.log('selectedPriceLevel', selectedOption.label);
    setSelectedPriceLevelUid(selectedOption.value);
    console.log('selectedPriceLevelUid', selectedOption.value);
  };

  const priceLevel = [
    { name: 'All', uid: 'all' },
    { name: 'High', uid: 'ba371388-7f4f-40bc-bc71-d9bbf59bac37' },
    { name: 'Medium', uid: '29943f8e-2279-44b5-aef5-6b2e4c194045' },
    { name: 'Low', uid: '1dd4e1f0-f1aa-4c0b-ab7b-1a4a1426c4bc' },
  ];

  const [selectedSafariDuration, setSelectedSafariDuration] = useState('5 days');
  const [selectedSafariDurationValue, setSelectedSafariDurationValue] = useState(5);

  const handleSafariDuration = (selectedOption) => {
    setSelectedSafariDuration(selectedOption.label);
    console.log('setSelectedSafariDuration', selectedOption.label);
    setSelectedSafariDurationValue(selectedOption.value);
    console.log('setSelectedSafariDurationValue', selectedOption.value);
  };

  const safariDuration = [
    { value: 4, label: '4 days' },
    { value: 5, label: '5 days' },
    { value: 6, label: '6 days' },
    { value: 7, label: '7 days' },
    { value: 8, label: '8 days' },
    { value: 9, label: '9 days' },
    { value: 10, label: '10 days' },
    { value: 11, label: '11 days' },
  ];

  const [selectedBeachDuration, setSelectedBeachDuration] = useState('5 days');
  const [selectedBeachDurationValue, setSelectedBeachDurationValue] = useState(5);

  const handleBeachDuration = (selectedOption) => {
    setSelectedBeachDuration(selectedOption.label);
    console.log('setSelectedBeachDuration', selectedOption.label);
    setSelectedBeachDurationValue(selectedOption.value);
    console.log('setSelectedBeachDurationValue', selectedOption.value);
  };

  const beachDuration = [
    { value: 0, label: 'No Beach' },
    { value: 1, label: '1 night' },
    { value: 2, label: '2 nights' },
    { value: 3, label: '3 nights' },
    { value: 4, label: '4 nights' },
    { value: 5, label: '5 nights' },
    { value: 6, label: '6 nights' },
    { value: 7, label: '7 nights' },
    { value: 8, label: '8 nights' },
    { value: 9, label: '9 nights' },
    { value: 10, label: '10 nights' },
  ];

  // left page width
  const leftColWidth = 860; // 980
  const leftColWidthHeader = 1200;
  const leftColWidthResults = 1200;
  const rightColWidth = 320;

  const [wizardPage, setWizardPage] = useState(false);
  const [arrivalCityNight, setArrivalCityNight] = useState(true);
  const [departureCityNight, setDepartureCityNight] = useState(false);

  const { companies, selectedCompany, selectedCompanyPermissions } = useSelector((state) => state.companies);

  // useEffect(() => {
  //   const loadAgentObject = async (uid) => {
  //     const loadedAgentObject = await loadAgent(uid);

  //     const selectedAgent = loadedAgentObject;

  //     // If an agent is found, set the values based on the selected agent
  //     if (selectedAgent) {
  //       setRateName(selectedAgent.rateBand.name);
  //       setRateId(selectedAgent.rateBand.id);
  //       setSelectedAgentName(selectedAgent.name);
  //       setSelectedAgentUid(selectedAgent.uid);
  //       setAgentResRequestId(selectedAgent.resRequestId);
  //       setAgentCommission(selectedAgent.rateBand.commission);
  //       setAgentObject(selectedAgent);
  //       await loadAgentRates(selectedAgent.rateBand.id, setAgentRates, null, bookings, setBookings, false);
  //     } else {
  //       // Handle the case where no agent is found
  //       console.error(`No agent found with uid: ${uid}`);
  //     }
  //   };

  //   loadAgentObject(selectedCompany.uid);
  // }, []);
  const [enquiries, setEnquiries] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  //Travel agent slideout
  const [switchSlideOut, setSwitchSlideOut] = useState(false);
  const [switchParkId, setSwitchParkId] = useState(null);
  const [switchId, setSwitchId] = useState(null);
  const [switchStartDate, setSwitchStartDate] = useState(null);
  const [switchEndDate, setSwitchEndDate] = useState(null);

  useEffect(() => {
    if (switchSlideOut) {
      handleOpenPropertiesSlideOut();
      setSwitchSlideOut(false);
    }
  }, [switchSlideOut]);

  const [isFamTrip, setIsFamTrip] = useState(false);

  const [originatingAgent, setOriginatingAgent] = useState('');

  if (!selectedCompanyPermissions.allowBookings && selectedCompany?.name) {
    console.log('selectedCompanyPermissions.allowBookings', selectedCompanyPermissions.allowBookings);
    return (
      <div>
        <div className="flex h-screen overflow-hidden">
          <CircleSpinnerOverlay loading={loadingData} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />

          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto ">
            {/*  Site header */}
            {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

            <main>
              <main>
                <EarlyAccessHero user={user} />
              </main>
            </main>
          </div>
        </div>
      </div>
    );
  }

  // BEFORE RETURN
  if (showPage && selectedCompanyPermissions.allowBookings && selectedCompany?.name) {
    return (
      <div>
        <InstantSearch searchClient={searchClient} indexName={INSTANT_SEARCH_INDEX_NAME}>
          <div className="flex h-screen overflow-hidden">
            <CircleSpinnerOverlay loading={loadingData} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto ">
              {/*  Site header */}
              {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

              <main>
                <div className="container mx-auto min-w-[1300px] max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="">
                    {/* Page header */}
                    <div className="app-container"></div>
                    {/* Left: Title */}
                    <div className="h-14 mt-8 flex items-center">
                      <div className="flex w-full items-center justify-between">
                        <h1 className="brand-text-color-800 font-normal font-roca text-4xl">
                          Editing Itinerary:
                          {itineraryStore.code ? ' (#' + itineraryStore.code + ')' : ''}
                        </h1>
                        <BreadcrumbsTR startSearchButton={startSearchButton} step={step} createEdit={createEdit} />
                      </div>
                    </div>

                    {isLoading ? (
                      // <Spinner />
                      <CircleSpinnerOverlay loading={isLoading} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />
                    ) : (
                      <div className={`${createEdit === 'edit' && step !== 'final' ? 'mt-14' : 'mt-4'}`}>
                        {/* BREAD CRUMBS */}
                        {/* <div className={`${createEdit === 'edit' ? 'mb-0' : ''}`}>
                      {(nextTopInfo || wizardPage) && <Breadcrumbs startSearchButton={startSearchButton} step={step} createEdit={createEdit} />}
                    </div> */}
                        <ViewItinerariesSlideOut
                          isOpen={isViewItinerariesSlideOutOpen}
                          onClose={handleCloseViewItinerariesSlideOut}
                          viewItinerariesMenu={viewItinerariesMenu}
                          parentUid={uid}
                          selectedViewUid={selectedViewUid}
                          selectedViewLabel={selectedViewLabel}
                          selectedItemView={selectedItemView}
                        />

                        {/* LEFT AND RIGHT LAYOUT FOR PROPERTIES & RESULTS START */}
                        {step === 'bookings' && (
                          <div>
                            {/* LEFT AND RIGHT LAYOUT FOR PROPERTIES & RESULTS START */}
                            {/* <div className="grid grid-cols-2 gap-5 px-5  w-[1640px] "> */}

                            {/* Featured Itineraries */}
                            {/* Search Details */}
                            {showTemplates ? (
                              // <div className="mx-5">
                              <div className="">
                                <div
                                  style={{
                                    ...deeShadow,
                                    // width: `${leftColWidthHeader}px`,
                                  }}
                                  className={`mb-5 px-5  bg-white rounded-lg h-fit top-0`}
                                >
                                  {/* TABS */}
                                  {/* TODO: Deal with border color and dynamic colors */}
                                  <div className={``}>
                                    <div className="">
                                      {/* TABS */}
                                      {/* TODO: Deal with border color and dynamic colors */} {/* START DATE & GUESTS */}
                                      <div
                                        style={{
                                          display: 'grid',
                                          gridTemplateColumns: 'auto auto auto auto auto   1fr auto',
                                          gap: '10px',
                                          justifyItems: 'start',
                                          width: '100%',
                                        }}
                                      >
                                        {/* COL 1 */}
                                        <div
                                          style={{
                                            padding: '10px',
                                            paddingRight: '20px',
                                            borderRight: '1px solid #d2dbdb',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <div className="text-left">
                                            <div className="flex text-left">
                                              <CiCalendar />
                                              <div className="ml-2 text-gray-600 text-xs font-normal">START DATE</div>
                                            </div>
                                            <div className="flex mt-1" style={{ position: 'relative', zIndex: 9999 }}>
                                              <DatePicker
                                                //className="underline cursor-pointer text-sm brand-text-color-v2 brand-text-color-v2-hover font-medium hover:border-0 border-0 focus:border-0 border-0 outline-none shadow-white underline-offset-2 p-0"
                                                style={{ color: '#2A99A3', textAlign: 'left', padding: '0px' }}
                                                selected={moment(realDate, 'YYYY-MM-DD').toDate()}
                                                dateFormat="eee dd MMM yy"
                                                minDate={new Date()}
                                                onChange={(date) => {
                                                  const dateValidation = validateBookingDate(date, user);
                                                  if (!dateValidation.isValid) {
                                                    return toast.error(dateValidation.error);
                                                  }
                                                  console.log('Selected date:', date);
                                                  const selDate = moment(date).format('YYYY-MM-DD');
                                                  const dateToUse = moment(selDate, 'YYYY-MM-DD').subtract(0, 'days').format('YYYY-MM-DD');
                                                  setDateStart(dateToUse);
                                                  setArrowDate(dateToUse);
                                                  setRealDate(selDate);
                                                  setDateEnd(moment(selDate).subtract(0, 'days').add(3, 'months').format('YYYY-MM-DD'));
                                                  setCallUpdateDates(true);
                                                  filterVehicleRooms(bookings, setBookings);
                                                  rebuildBookings(bookings, setBookings);
                                                  setFetchCustom(true);
                                                }}
                                                customInput={<CustomDateInputNoBorderNewHeader />}
                                                calendarClassName="custom-datepicker-new datepicker-padding-new"
                                                showPopperArrow={false}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* COL 2 */}
                                        <div
                                          style={{
                                            padding: '10px',
                                            paddingLeft: '20px',
                                            paddingRight: '20px',
                                            borderRight: '1px solid #d2dbdb',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <div>
                                            <div className="flex">
                                              <GoPerson />
                                              <div className="ml-2 text-gray-600 text-xs font-normal">GUESTS</div>
                                            </div>
                                            <div className="flex mt-0">
                                              <div className="text-gray-800 text-base font-semibold underline">
                                                {' '}
                                                <div className={` cursor-pointer border border-white focus:outline-none focus:border-white`}>
                                                  <Popover className="relative">
                                                    <Popover.Button className="border border-white focus:outline-none focus:border-white" onClick={() => setIsPopoverOpen(true)}>
                                                      <div id="totalPax" className={`rounded-sm  border border-white flex items-center pl-0 focus:outline-none focus:border-white`}>
                                                        {' '}
                                                        <span className="ml-auto text-nomadBlue-600 text-base font-semibold underline brand-text-color-v2-hover">
                                                          {roomPax.reduce((acc, room) => acc + room.adults, 0)} adult{roomPax.reduce((acc, room) => acc + room.adults, 0) !== 1 && 's'}{' '}
                                                          {roomPax.reduce((acc, room) => acc + room.children, 0)} kid{roomPax.reduce((acc, room) => acc + room.children, 0) !== 1 && 's'}
                                                        </span>
                                                        {/* <FaChevronDown className="mx-2 brand-text-color-v2 hover:underline underline-offset-2" /> */}
                                                      </div>
                                                    </Popover.Button>
                                                    {isPopoverOpen && (
                                                      <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="opacity-0 translate-y-1"
                                                        enterTo="opacity-100 translate-y-0"
                                                        leave="transition ease-in duration-150"
                                                        leaveFrom="opacity-100 translate-y-0"
                                                        leaveTo="opacity-0 translate-y-1"
                                                      >
                                                        {/* <Popover.Panel className="absolute left-1/2 transform -translate-x-1/2 mt-2 flex w-screen max-w-max z-[999]"></Popover.Panel> */}
                                                        <Popover.Panel className="absolute z-[9999] left-0 mt-2 w-screen max-w-max">
                                                          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                                                            <div className="m-5">
                                                              <p className="text-md font-semibold">Guests</p>
                                                              <>
                                                                {roomPax.map((room, roomIndex) => (
                                                                  <div key={room.uuid}>
                                                                    <div className="row-2 grid grid-cols-12 m-5">
                                                                      {/* Room Title and Adult Counter */}
                                                                      <div className="col-span-2 px-2 flex items-center">
                                                                        {/* <IoIosArrowDropupCircle size={20} className="mr-2" /> */}
                                                                        Room {roomIndex + 1}:
                                                                      </div>
                                                                      <div className="col-span-3 px-2">
                                                                        {/* Adult Counter */}
                                                                        <div className="text-xs mb-1">Adults</div>
                                                                        <div className="grid grid-rows-1 grid-flow-col bg-white border border-slate-200 rounded-lg h-9 py-0.5">
                                                                          <button
                                                                            className="border-r border-slate-200"
                                                                            onClick={() => {
                                                                              if (room.adults > 1) {
                                                                                handleAdultChange(roomIndex, -1, setRoomPax);
                                                                              } else {
                                                                                toast.error('There must be at least one adult in a room');
                                                                              }
                                                                            }}
                                                                          >
                                                                            -
                                                                          </button>
                                                                          <div className="px-2 text-xs font-normal text-center align-middle leading-6">{room.adults}</div>
                                                                          <button className="border-l border-slate-200" onClick={() => handleAdultChange(roomIndex, 1, setRoomPax)}>
                                                                            +
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                      <div className="col-span-3 px-2">
                                                                        {/* Child Counter */}
                                                                        <div className="text-xs mb-1">Children</div>
                                                                        <div className="grid grid-rows-1 grid-flow-col bg-white border border-slate-200 rounded-lg h-9 py-0.5">
                                                                          <button className="border-r border-slate-200" onClick={() => handleChildChange(roomIndex, -1, setRoomPax)}>
                                                                            -
                                                                          </button>
                                                                          <div className="px-2 text-xs font-normal text-center align-middle leading-6">{room.children}</div>
                                                                          <button className="border-l border-slate-200" onClick={() => handleChildChange(roomIndex, 1, setRoomPax)}>
                                                                            +
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                      <div className="col-span-4 px-2 flex justify-end items-center">
                                                                        {roomPax.length > 1 && (
                                                                          <FaRegTrashAlt
                                                                            size={20}
                                                                            className="cursor-pointer text-nomadGray-600 mr-2"
                                                                            onClick={() => deleteRoom(roomIndex, setRoomPax)}
                                                                          />
                                                                        )}
                                                                        <MdContentCopy size={20} className="cursor-pointer text-nomadGray-600" onClick={() => duplicateRoom(roomIndex, setRoomPax)} />
                                                                      </div>
                                                                      {/* Additional Room Options */}
                                                                    </div>

                                                                    {/* Child Age Selectors */}
                                                                    <div className="row-2 grid grid-cols-12 ml-5">
                                                                      {room.childAges.map((child, childIndex) => (
                                                                        <Fragment key={child.uuid}>
                                                                          {childIndex % 5 === 0 && <div className="col-span-2 px-2 mb-5">{/* <div className="m-5 mt text-md">Room 1:</div> */}</div>}

                                                                          <div key={childIndex} className="col-span-2 px-2 mb-5">
                                                                            {/* <div className="text-xs mb-1">Child {childIndex + 1} age</div> */}
                                                                            <div className="flex items-center">
                                                                              <div className="text-xs mb-1">Child {childIndex + 1} age</div>
                                                                              <RiCloseCircleLine
                                                                                size={16}
                                                                                className="ml-1 text-red-600 cursor-pointer"
                                                                                onClick={() => handleRemoveChild(roomIndex, child.uuid, setRoomPax)}
                                                                              />
                                                                            </div>
                                                                            <Select
                                                                              className="border-r border-l border-slate-200 w-[84px]"
                                                                              options={[...Array(21).keys()].map((num) => ({ value: num, label: num.toString() }))}
                                                                              defaultValue={{ label: child.age.toString(), value: child.uuid }}
                                                                              onChange={(selectedOption) => handleChildAgeChange(roomIndex, childIndex, selectedOption.value, setRoomPax)}
                                                                              menuPortalTarget={document.body}
                                                                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                                            />
                                                                          </div>
                                                                        </Fragment>
                                                                      ))}
                                                                    </div>
                                                                  </div>
                                                                ))}
                                                                <div className="row-2 grid grid-cols-12 ml-5">
                                                                  <div className="col-span-4 px-2 mb-5">
                                                                    <div className="mx-5 text-nomadBlue-600 text-base cursor-pointer" onClick={() => addNewRoom(setRoomPax)}>
                                                                      + Add room
                                                                    </div>
                                                                  </div>
                                                                  <div className="col-span-8 px-2 flex justify-end items-center">
                                                                    <ButtonPrimarySmall color={'dark'} text={'Done'} className={'float-right mt-4'} onClick={() => setIsPopoverOpen(false)} />
                                                                  </div>
                                                                </div>
                                                              </>
                                                            </div>
                                                          </div>
                                                        </Popover.Panel>
                                                      </Transition>
                                                    )}
                                                  </Popover>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* COL 2a */}
                                        <div
                                          style={{
                                            padding: '10px',
                                            paddingRight: '20px',
                                            paddingLeft: '14px',
                                            borderRight: '1px solid #d2dbdb',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <div className="text-left">
                                            <div className="flex text-left">
                                              <PiTentDuotone />
                                              <div className="ml-2 text-gray-600 text-xs font-normal">SAFARI DURATION</div>
                                            </div>
                                            <div className="flex mt-1">
                                              <CustomSelectNoBorderWizard
                                                placeholder={selectedSafariDuration}
                                                defaultValue={{ label: selectedSafariDuration, value: selectedSafariDuration }}
                                                onChange={(selectedOption) => handleSafariDuration(selectedOption)}
                                                options={safariDuration.map((safari) => ({
                                                  label: safari.label,
                                                  value: safari.value,
                                                }))}
                                                //defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                                instanceId="safari-duration-select"
                                                isSearchable={false}
                                                showDropdownIndicator={false}
                                                width={100}
                                                menuHeight={120}
                                                color1={'#385157'} // Default color1
                                                color2={'#E5E7EB'}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* COL 2b */}
                                        <div
                                          style={{
                                            padding: '10px',
                                            paddingRight: '20px',
                                            paddingLeft: '14px',
                                            borderRight: '1px solid #d2dbdb',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <div className="text-left">
                                            <div className="flex text-left">
                                              <TbBeach />
                                              <div className="ml-2 text-gray-600 text-xs font-normal">BEACH DURATION</div>
                                            </div>
                                            <div className="flex mt-1">
                                              <CustomSelectNoBorderWizard
                                                placeholder={selectedBeachDuration}
                                                defaultValue={{ label: selectedBeachDuration, value: selectedBeachDuration }}
                                                onChange={(selectedOption) => handleBeachDuration(selectedOption)}
                                                options={beachDuration.map((beach) => ({
                                                  label: beach.label,
                                                  value: beach.value,
                                                }))}
                                                //defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                                instanceId="beach-duration-select"
                                                isSearchable={false}
                                                showDropdownIndicator={false}
                                                width={100}
                                                menuHeight={120}
                                                color1={'#385157'} // Default color1
                                                color2={'#E5E7EB'}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* COL 2c */}
                                        <div
                                          style={{
                                            padding: '10px',
                                            paddingRight: '20px',
                                            paddingLeft: '14px',
                                            borderRight: '1px solid #d2dbdb',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-center',
                                          }}
                                        >
                                          <div className="text-left">
                                            <div className="flex text-left">
                                              <BiDollar />
                                              <div className="ml-2 text-gray-600 text-xs font-normal">PRICE POINT</div>
                                            </div>
                                            <div className="flex mt-1">
                                              <CustomSelectNoBorderWizard
                                                placeholder={selectedPriceLevel}
                                                defaultValue={{ label: selectedPriceLevel, value: selectedPriceLevel }}
                                                onChange={(selectedOption) => handleChangePriceLevel(selectedOption)}
                                                options={priceLevel.map((price) => ({
                                                  label: price.name,
                                                  value: price.uid,
                                                }))}
                                                //defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                                instanceId="price-level-select"
                                                isSearchable={false}
                                                showDropdownIndicator={false}
                                                width={100}
                                                menuHeight={160}
                                                color1={'#385157'} // Default color1
                                                color2={'#E5E7EB'}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* COL 2d */}
                                        <div
                                          style={{
                                            padding: '10px',
                                            paddingRight: '20px',
                                            paddingLeft: '7px',
                                            borderRight: '1px solid #d2dbdb',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-center',
                                          }}
                                        >
                                          <div className="flex justify-between items-center mb-2">
                                            <span className="text-gray-600 text-xs font-normal">Overnight on arrival</span>
                                            <input
                                              type="checkbox"
                                              name="arrivalNight"
                                              className="checkbox checkbox-xs border-nomadBlue-400 checked:border-nomadBlue-400  [--chkbg:#6996A0] [--chkfg:white] ml-2"
                                              checked={arrivalCityNight}
                                              onChange={(e) => {
                                                let isChecked = e.target.checked;
                                                setArrivalCityNight(isChecked);
                                              }}
                                            />
                                          </div>
                                          <div className="flex justify-between items-center">
                                            <span className="text-gray-600 text-xs font-normal">Overnight on departure</span>
                                            <input
                                              type="checkbox"
                                              name="departureNight"
                                              className="checkbox checkbox-xs border-nomadBlue-400 checked:border-nomadBlue-400 [--chkbg:#6996A0] [--chkfg:white] ml-2"
                                              checked={departureCityNight}
                                              onChange={(e) => {
                                                let isChecked = e.target.checked;
                                                setDepartureCityNight(isChecked);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        {/* COL 3 */}
                                        <div
                                          style={{
                                            padding: '10px',
                                            paddingLeft: '14px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                          }}
                                        >
                                          <div className="flex items-center space-x-4">
                                            <div
                                              className="flex cursor-pointer brand-text-color-v2-hover"
                                              onClick={async () => {
                                                setShowTemplates(false);
                                                setNextTopInfo(false);
                                                setWizardPage(false);
                                                console.log('edit');
                                              }}
                                            >
                                              <FaLongArrowAltLeft className="mr-2" />
                                              <span className="text-xs font-medium">Back</span>
                                            </div>

                                            <ButtonPrimarySmall
                                              onClick={async () => {
                                                setShowTemplates(false);
                                                const totalAdults = roomPax.reduce((acc, room) => {
                                                  return acc + room.adults;
                                                }, 0);

                                                if (totalAdults === 0) {
                                                  return toast.error('Please select at least one adult');
                                                } else {
                                                  //setNextTopInfo(true);
                                                  aggregateGuests(roomPax, setGuests);
                                                  console.log('next');
                                                }

                                                if (startSearchButton === 'search') {
                                                  if (selectedOptions.length === 0) {
                                                    return toast.error('Please select at least one preferred park or property.');
                                                  }
                                                  const searchRequest = {
                                                    locations: [],
                                                    dateStart: dateStart,
                                                    type: tripType,
                                                    totalRooms: roomPax.length,
                                                    priceCategoryId: selectedPriceLevelUid,
                                                    totalPax: roomPax.reduce((acc, room) => acc + room.adults, 0) + roomPax.reduce((acc, room) => acc + room.children, 0),
                                                    availablePropertiesOnly: availableOnly,
                                                    beachNights: selectedBeachDurationValue,
                                                    safariNights: selectedSafariDurationValue,
                                                    arrivalCityNight: arrivalCityNight,
                                                    departureCityNight: departureCityNight,
                                                  };

                                                  searchRequest.locations = selectedOptions.map((selectedOption) => ({
                                                    name: selectedOption.name,
                                                    id: selectedOption.uid,
                                                    parentParkId: selectedOption.park.id,
                                                    type: selectedOption.type,
                                                    country: selectedOption.country,
                                                    category: selectedOption.category,
                                                  }));

                                                  setSearchRequestResult(searchRequest);
                                                  setShowTemplates(true);
                                                } else {
                                                  setNextTopInfo(true);
                                                }
                                              }}
                                              text="Search Again"
                                              color="dark"
                                            />
                                          </div>
                                        </div>
                                        {/* COL 4 */}
                                        {/* <div
style={{
padding: '10px',
paddingRight: '20px',
//borderRight: '1px solid #d2dbdb',
display: 'flex',
alignItems: 'flex-start',
justifyContent: 'flex-start',
}}
>
<div className="flex items-center justify-center mt-5">
<div
className="flex cursor-pointer brand-text-color-v2-hover"
onClick={async () => {
setShowTemplates(false);
setNextTopInfo(false);
console.log('edit');
}}
>
<MdOutlineModeEdit />
<div className="ml-2 text-gray-600 text-xs font-normal underline brand-text-color-v2-hover">EDIT OPTIONS</div>
</div>
</div>
</div> */}
                                        {/* COL 5 */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  // style={{
                                  //   width: `${leftColWidthResults}px`,
                                  // }}
                                  className={`mb-5 rounded-lg min-h-[280px] z-0`}
                                >
                                  {/* <h2 className="text-2xl font-bold mb-4">Matched Itineraries</h2> */}
                                  <ItinerarySearch
                                    searchRequest={searchRequestResult}
                                    bookings={bookings}
                                    setBookings={setBookings}
                                    setProperties={setProperties}
                                    dateStart={dateStart}
                                    dateEnd={dateEnd}
                                    agentObject={agentObject}
                                    agentRates={agentRates}
                                    rooms={rooms}
                                    setRooms={setRooms}
                                    packageTab={packageTab}
                                    guests={guests}
                                    setLoadingData={setLoadingData}
                                    setShowTemplates={setShowTemplates}
                                    setNextTopInfo={setNextTopInfo}
                                    setStartSearchButton={setStartSearchButton}
                                    setFetchCustom={setFetchCustom}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="grid grid-flow-col auto-cols-max rounded-lg bg-white p-4">
                                {/* //! SECTION 1: START ---------------------------------------------------------------------------------- */}
                                <div className="">
                                  {' '}
                                  {/* PROPERTIES START */}
                                  <div>
                                    {/* SECTION 1: PROPERTIES START w-[800px] */}

                                    <div
                                      style={{
                                        // ...deeShadow,
                                        width: !nextTopInfo && showEditOptions ? `${leftColWidthHeader}px` : `${leftColWidth}px`,
                                      }}
                                      className={`${!nextTopInfo ? 'p-5' : 'px-5 py-1 border-2'} brand-section-bg-color-v2 rounded-xl mr-5`}
                                    >
                                      {!nextTopInfo && showEditOptions ? (
                                        <>
                                          {/* NEW TOP BUTTONS */}
                                          {user.fullBoardOnly === false && user.accessLevel !== 'travelAgent' && (
                                            <div className="mt-5" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '0px' }}>
                                              <div>{/* Content for first column that adapts to remaining space */}</div>

                                              {/* <div className="w-[400px] h-[56px] p-[4px] flex text-base font-semibold rounded-full shadow bg-white ">
                                      <div
                                        className={`w-[200px] h-12 p-4 hover:underline flex items-center justify-center cursor-pointer ${
                                          startSearchButton === 'start' ? 'bg-nomadBlue-700 text-white rounded-full shadow ' : 'text-nomadBlue-900'
                                        }`}
                                        onClick={() => {
                                          console.log('start');
                                          setStartSearchButton('start');
                                        }}
                                      >
                                        {firstSearch ? <FiPlus size={20} className="mr-2" /> : <MdOutlineModeEdit size={20} className="mr-2" />}
                                        <span>{firstSearch ? 'Start New' : 'Edit Options'}</span>
                                      </div>
                                      <div
                                        className={`w-[200px] h-12 hover:underline  flex items-center justify-center cursor-pointer ${
                                          startSearchButton === 'search' ? 'bg-nomadBlue-700 text-white rounded-full shadow ' : 'text-nomadBlue-900'
                                        }`}
                                        onClick={() => {
                                          console.log('search');
                                          setStartSearchButton('search');
                                        }}
                                      >
                                        <BsStars size={20} className="mr-2" />
                                        <span>Itinerary Wizard</span>
                                      </div>
                                    </div> */}

                                              <div className="w-[400px] h-[56px] p-[4px] flex text-base font-semibold rounded-full shadow bg-white ">
                                                <div
                                                  className={`w-[200px] h-12 p-4 hover:underline flex items-center justify-center cursor-pointer ${
                                                    startSearchButton === 'search' ? 'bg-nomadBlue-700 text-white rounded-full shadow ' : 'text-nomadBlue-900'
                                                  }`}
                                                  onClick={() => {
                                                    console.log('search');
                                                    setStartSearchButton('search');
                                                  }}
                                                >
                                                  <FiZap size={20} className="mr-2" />
                                                  <span>Quick Start</span>
                                                </div>
                                                <div
                                                  className={`w-[200px] h-12 hover:underline  flex items-center justify-center cursor-pointer ${
                                                    startSearchButton === 'start' ? 'bg-nomadBlue-700 text-white rounded-full shadow ' : 'text-nomadBlue-900'
                                                  }`}
                                                  onClick={() => {
                                                    console.log('start');
                                                    setStartSearchButton('start');
                                                  }}
                                                >
                                                  {firstSearch ? <BiCustomize size={20} className="mr-2" /> : <BiCustomize size={20} className="mr-2" />}
                                                  <span>Advanced Mode</span>
                                                </div>
                                              </div>

                                              <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                            </div>
                                          )}

                                          {/* NEW TOP TRIP TYPE */}
                                          {startSearchButton === 'search' && (
                                            <>
                                              {1 > 2 && (
                                                <div className="mt-10" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                                  <div>{/* Content for first column that adapts to remaining space */}</div>
                                                  <div style={{ width: '336px' }}>
                                                    {' '}
                                                    <p className="text-gray-600 text-sm font-bold mb-1">TRIP TYPE</p>
                                                  </div>
                                                  <div style={{ width: '336px' }}>{/* Content for third column */}</div>
                                                  <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                                </div>
                                              )}

                                              {1 > 2 && (
                                                <div className="mt-2" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                                  <div>{/* Content for first column that adapts to remaining space */}</div>

                                                  <div className="w-[336px] h-12 flex rounded-full bg-white shadow">
                                                    <div
                                                      className={`w-[168px] h-12 ${
                                                        tripType === 'safariBeach' ? 'bg-stone-200 rounded-full shadow' : ''
                                                      }  text-slate-800 flex items-center justify-center text-sm font-semibold cursor-pointer hover:underline`}
                                                      onClick={() => setTripType('safariBeach')}
                                                    >
                                                      Safari and Beach
                                                    </div>

                                                    <div
                                                      className={`w-[168px] h-12 ${
                                                        tripType === 'safari' ? 'bg-stone-200 rounded-full shadow' : ''
                                                      }  text-slate-800 flex items-center justify-center text-sm font-semibold cursor-pointer hover:underline`}
                                                      onClick={() => setTripType('safari')}
                                                    >
                                                      Safari only
                                                    </div>
                                                  </div>

                                                  <div style={{ width: '336px' }}>{/* Content for third column */}</div>
                                                  <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                                </div>
                                              )}

                                              {/* SEARCH PARKS AND PROPERTIES */}
                                              <div className="mt-4" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '0px' }}>
                                                <div>{/* Content for first column that adapts to remaining space */}</div>
                                                <div style={{ width: '688px' }}>
                                                  <InfoBox
                                                    icon={LightBulbIcon}
                                                    text="Use Quick Start to search for availability across a curated set of itineraries"
                                                    bgColor="bg-blue-50"
                                                    textColor="text-nomadBlue-700"
                                                    fontSize="text-sm"
                                                  />
                                                  <span className="text-gray-600 text-sm font-bold mt-6  mb-1 flex">
                                                    SELECT PREFERRED PARKS OR PROPERTIES <span className="ml-2 text-slate-400 text-sm font-normal">(OPTIONAL)</span>
                                                  </span>
                                                </div>

                                                <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                              </div>
                                              <div className="mt-2" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '0px' }}>
                                                <div>{/* Content for first column that adapts to remaining space */}</div>

                                                <div className="w-[688px] flex text-base font-semibold">
                                                  <Select
                                                    isMulti
                                                    options={sortedOptions}
                                                    value={selectedOptions}
                                                    onChange={handleChange}
                                                    styles={customStylesTags}
                                                    classNamePrefix="react-select"
                                                    formatOptionLabel={formatOptionLabel}
                                                    components={{ Option, MultiValueLabel }}
                                                  />
                                                </div>

                                                <div>{/* Content for third column that adapts to remaining space */}</div>
                                              </div>
                                            </>
                                          )}

                                          {/* NEW TOP Date / Guests */}
                                          <div className="mt-10" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                            <div>{/* Content for first column that adapts to remaining space */}</div>
                                            <div style={{ width: '336px' }}>
                                              {' '}
                                              <p className="text-gray-600 text-sm font-bold mb-1">TRIP DETAILS</p>
                                            </div>
                                            <div style={{ width: '336px' }}>{/* Content for third column */}</div>
                                            <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                          </div>
                                          <div className="mt-1" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                            <div>{/* Content for first column that adapts to remaining space */}</div>
                                            <div style={{ width: '336px' }}>
                                              {' '}
                                              <div className="relative">
                                                {!showDatePicker ? (
                                                  <>
                                                    <div>
                                                      <p className="text-slate-800 text-sm font-normal mb-1">Date</p>
                                                      <div className="relative">
                                                        {' '}
                                                        {/* Ensure relative positioning for the positioning of the SVG */}
                                                        <div
                                                          className="form-input pl-9 text-slate-500 hover:text-slate-500 font-medium focus:border-nomadFormsBlue rounded-xl h-[50px] border border-gray-200 flex items-center justify-start cursor-pointer"
                                                          onClick={handlePlaceholderClick}
                                                        >
                                                          <div>Select a date...</div>
                                                        </div>
                                                        <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                                                          {' '}
                                                          {/* Adjusted for proper centering of the SVG */}
                                                          <svg className="w-4 h-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                                            <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                                                          </svg>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <p className="text-slate-800 text-sm font-normal mb-1">Date</p>
                                                    <div className="flex items-center space-x-2">
                                                      <div className="relative flex-grow">
                                                        <DatePicker
                                                          selected={moment(realDate, 'YYYY-MM-DD').toDate()}
                                                          dateFormat="eee dd MMM yy"
                                                          minDate={new Date()}
                                                          disabled={!realDate}
                                                          onChange={(date) => {
                                                            const dateValidation = validateBookingDate(date, user);
                                                            if (!dateValidation.isValid) {
                                                              return toast.error(dateValidation.error);
                                                            }
                                                            console.log('Selected date:', date);
                                                            const selDate = moment(date).format('YYYY-MM-DD');
                                                            const dateToUse = moment(selDate, 'YYYY-MM-DD').subtract(0, 'days').format('YYYY-MM-DD');
                                                            setDateStart(dateToUse);
                                                            setArrowDate(dateToUse);
                                                            setRealDate(selDate);
                                                            setDateEnd(moment(selDate).subtract(0, 'days').add(3, 'months').format('YYYY-MM-DD'));
                                                            setCallUpdateDates(true);
                                                            setPickerDate(true);
                                                            setTransfers([]);
                                                            setActivities([]);
                                                            setActivitiesList([]);
                                                            setActivitiesData([]);
                                                            filterVehicleRooms(bookings, setBookings);
                                                            rebuildBookings(bookings, setBookings);
                                                            setFetchCustom(true);
                                                          }}
                                                          customInput={<CustomDateInputBorderNomadBlue />}
                                                          className="form-control custom-datepicker custom-datepicker-nomadBlue datepicker-padding"
                                                          calendarClassName="custom-datepicker custom-datepicker-nomadBlue datepicker-padding"
                                                          showPopperArrow={false}
                                                        />
                                                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                                                          <svg className="w-4 h-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                                            <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                                                          </svg>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                            <div style={{ width: '336px' }}>
                                              {' '}
                                              <p className="text-slate-800 text-sm font-normal mb-1">Guests</p>
                                              <Popover className="relative">
                                                <Popover.Button
                                                  //className={`w-full  rounded-xl h-[50px] ${isPopoverOpen ? 'border-2 border-stone-500' : 'border border-black'}`}
                                                  className={`w-full  rounded-xl h-[50px] border border-gray-200 hover:border-2 hover:border-[#489EF8] focus:border-2 focus:border-[#489EF8] ${
                                                    guests.adults + guests.children === 0 ? 'bg-white' : 'bg-gray-200'
                                                  }`}
                                                  onClick={() => {
                                                    if (guests.adults + guests.children === 0) {
                                                      setIsPopoverOpen(true);
                                                    }
                                                  }}
                                                >
                                                  <div id="totalPaxandRooms" className={`w-full text-sm  flex items-center justify-between px-3`}>
                                                    <div className="flex items-center">
                                                      <IoPersonOutline size={20} className="mr-2" />
                                                      <span>
                                                        {roomPax.reduce((total, room) => total + room.adults + room.children, 0)}{' '}
                                                        {roomPax.reduce((total, room) => total + room.adults + room.children, 0) === 1 ? 'Guest' : 'Guests'}, {roomPax.length}{' '}
                                                        {roomPax.length === 1 ? 'Room' : 'Rooms'}
                                                      </span>
                                                    </div>
                                                    {guests.adults + guests.children === 0 && <FaChevronDown className="" />}
                                                  </div>
                                                </Popover.Button>
                                                {isPopoverOpen && (
                                                  <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0 translate-y-1"
                                                    enterTo="opacity-100 translate-y-0"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100 translate-y-0"
                                                    leaveTo="opacity-0 translate-y-1"
                                                  >
                                                    {/* <Popover.Panel className="absolute mt-2 flex w-screen max-w-max z-[999]"> */}
                                                    <Popover.Panel className="absolute left-1/2 transform -translate-x-1/2 mt-2 flex w-screen max-w-max z-[999]">
                                                      <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                                                        <div className="m-5">
                                                          <p className="text-md font-semibold">Guests</p>
                                                          <>
                                                            {roomPax.map((room, roomIndex) => (
                                                              <div key={room.uuid}>
                                                                <div className="row-2 grid grid-cols-12 m-5">
                                                                  {/* Room Title and Adult Counter */}
                                                                  <div className="col-span-2 px-2 flex items-center">
                                                                    {/* <IoIosArrowDropupCircle size={20} className="mr-2" /> */}
                                                                    Room {roomIndex + 1}:
                                                                  </div>
                                                                  <div className="col-span-3 px-2">
                                                                    {/* Adult Counter */}
                                                                    <div className="text-xs mb-1">Adults</div>
                                                                    <div className="grid grid-rows-1 grid-flow-col bg-white border border-slate-200 rounded-lg h-9 py-0.5">
                                                                      <button
                                                                        className="border-r border-slate-200"
                                                                        onClick={() => {
                                                                          if (room.adults > 1) {
                                                                            handleAdultChange(roomIndex, -1, setRoomPax);
                                                                          } else {
                                                                            toast.error('There must be at least one adult in a room');
                                                                          }
                                                                        }}
                                                                      >
                                                                        -
                                                                      </button>
                                                                      <div className="px-2 text-xs font-normal text-center align-middle leading-6">{room.adults}</div>
                                                                      <button className="border-l border-slate-200" onClick={() => handleAdultChange(roomIndex, 1, setRoomPax)}>
                                                                        +
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                  <div className="col-span-3 px-2">
                                                                    {/* Child Counter */}
                                                                    <div className="text-xs mb-1">Children</div>
                                                                    <div className="grid grid-rows-1 grid-flow-col bg-white border border-slate-200 rounded-lg h-9 py-0.5">
                                                                      <button className="border-r border-slate-200" onClick={() => handleChildChange(roomIndex, -1, setRoomPax)}>
                                                                        -
                                                                      </button>
                                                                      <div className="px-2 text-xs font-normal text-center align-middle leading-6">{room.children}</div>
                                                                      <button className="border-l border-slate-200" onClick={() => handleChildChange(roomIndex, 1, setRoomPax)}>
                                                                        +
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                  <div className="col-span-4 px-2 flex justify-end items-center">
                                                                    {roomPax.length > 1 && (
                                                                      <FaRegTrashAlt size={20} className="cursor-pointer brand-text-grey-v2 mr-2" onClick={() => deleteRoom(roomIndex, setRoomPax)} />
                                                                    )}
                                                                    <MdContentCopy size={20} className="cursor-pointer brand-text-grey-v2" onClick={() => duplicateRoom(roomIndex, setRoomPax)} />
                                                                  </div>
                                                                  {/* Additional Room Options */}
                                                                </div>

                                                                {/* Child Age Selectors */}
                                                                <div className="row-2 grid grid-cols-12 ml-5">
                                                                  {room.childAges.map((child, childIndex) => (
                                                                    <Fragment key={child.uuid}>
                                                                      {childIndex % 5 === 0 && <div className="col-span-2 px-2 mb-5">{/* <div className="m-5 mt text-md">Room 1:</div> */}</div>}

                                                                      <div key={childIndex} className="col-span-2 px-2 mb-5">
                                                                        {/* <div className="text-xs mb-1">Child {childIndex + 1} age</div> */}
                                                                        <div className="flex items-center">
                                                                          <div className="text-xs mb-1">Child {childIndex + 1} age</div>
                                                                          <RiCloseCircleLine
                                                                            size={16}
                                                                            className="ml-1 text-red-600 cursor-pointer"
                                                                            onClick={() => handleRemoveChild(roomIndex, child.uuid, setRoomPax)}
                                                                          />
                                                                        </div>
                                                                        <Select
                                                                          className="border-r border-l border-slate-200 w-[84px]"
                                                                          options={[...Array(21).keys()].map((num) => ({ value: num, label: num.toString() }))}
                                                                          defaultValue={{ label: child.age.toString(), value: child.uuid }}
                                                                          onChange={(selectedOption) => handleChildAgeChange(roomIndex, childIndex, selectedOption.value, setRoomPax)}
                                                                          menuPortalTarget={document.body}
                                                                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                                        />
                                                                      </div>
                                                                    </Fragment>
                                                                  ))}
                                                                </div>
                                                              </div>
                                                            ))}
                                                            <div className="row-2 grid grid-cols-12 ml-5">
                                                              <div className="col-span-4 px-2 mb-5">
                                                                <div className="mx-5 text-nomadBlue-600 text-base cursor-pointer" onClick={() => addNewRoom(setRoomPax)}>
                                                                  + Add room
                                                                </div>
                                                              </div>
                                                              <div className="col-span-8 px-2 flex justify-end items-center">
                                                                <ButtonPrimarySmall color={'dark'} text={'Done'} className={'float-right mt-4'} onClick={() => setIsPopoverOpen(false)} />
                                                              </div>
                                                            </div>
                                                          </>
                                                        </div>
                                                      </div>
                                                    </Popover.Panel>
                                                  </Transition>
                                                )}
                                              </Popover>
                                            </div>
                                            <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                          </div>

                                          {/* NEW safari duration & beach duration */}

                                          {startSearchButton === 'search' && (
                                            <div className="mt-10" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                              <div>{/* Content for first column that adapts to remaining space */}</div>

                                              <div style={{ width: '336px' }}>
                                                {' '}
                                                <p className="text-slate-800 text-sm font-normal mb-1">Total Nights on Safari</p>
                                                <CustomSelect
                                                  placeholder={selectedSafariDuration}
                                                  defaultValue={{ label: selectedSafariDuration, value: selectedSafariDuration }}
                                                  onChange={(selectedOption) => handleSafariDuration(selectedOption)}
                                                  options={safariDuration.map((safari) => ({
                                                    label: safari.label,
                                                    value: safari.value,
                                                  }))}
                                                  //defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                                  instanceId="safari-duration-select"
                                                  isSearchable={false}
                                                  showDropdownIndicator={true}
                                                  //width={100}
                                                  menuHeight={120}
                                                  color1={'#489EF8'} // Default color1
                                                  color2={'#E5E7EB'}
                                                />
                                              </div>

                                              <div style={{ width: '336px' }}>
                                                <p className="text-slate-800 text-sm font-normal mb-1">Total Nights at Beach</p>
                                                <CustomSelect
                                                  placeholder={selectedBeachDuration}
                                                  defaultValue={{ label: selectedBeachDuration, value: selectedBeachDuration }}
                                                  onChange={(selectedOption) => handleBeachDuration(selectedOption)}
                                                  options={beachDuration.map((beach) => ({
                                                    label: beach.label,
                                                    value: beach.value,
                                                  }))}
                                                  //defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                                  instanceId="beach-duration-select"
                                                  isSearchable={false}
                                                  showDropdownIndicator={true}
                                                  //width={100}
                                                  menuHeight={120}
                                                  color1={'#489EF8'} // Default color1
                                                  color2={'#E5E7EB'}
                                                />
                                              </div>

                                              <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                            </div>
                                          )}

                                          {/* NEW TOP Agent / Enquiry */}
                                          {user.internalUser && (
                                            <>
                                              <div className="mt-10" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                                <div>{/* Content for first column that adapts to remaining space */}</div>
                                                <div style={{ width: '336px' }}>
                                                  {' '}
                                                  <p className="text-gray-600 text-sm font-bold mb-1">CLIENT DETAILS</p>
                                                </div>
                                                <div style={{ width: '336px' }}>{/* Content for third column */}</div>
                                                <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                              </div>
                                              <div className="mt-1" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                                <div>{/* Content for first column that adapts to remaining space */}</div>
                                                <div style={{ width: '336px' }}>
                                                  <div>
                                                    <p className="text-slate-800 text-sm font-normal mb-1">Agent </p>
                                                    {
                                                      internalUser ? (
                                                        <div className="">
                                                          {/* <InstantSearch searchClient={searchClient} indexName={INSTANT_SEARCH_INDEX_NAME_AGENTS} searchState={searchState} onSearchStateChange={setSearchState}> */}

                                                          <InstantSearch searchClient={searchClient} indexName="INSTANT_SEARCH_INDEX_NAME_AGENTS">
                                                            <CustomAutoComplete
                                                              //key={keyAgents}
                                                              key={keyAgents + uuidv4()}
                                                              placeholder="Search agents"
                                                              detachedMediaQuery="none"
                                                              initialState={{
                                                                query: !selectedAgentName ? '' : selectedAgentName,
                                                              }}
                                                              openOnFocus={true}
                                                              onReset={onReset}
                                                              getSources={({ query }) => [
                                                                {
                                                                  sourceId: 'name',
                                                                  getItems() {
                                                                    return getAlgoliaResults({
                                                                      searchClient,
                                                                      queries: [
                                                                        {
                                                                          indexName: 'agents',
                                                                          query,
                                                                          params: {
                                                                            filters: 'active:true',
                                                                          },
                                                                        },
                                                                      ],
                                                                    });
                                                                  },
                                                                  templates: {
                                                                    item({ item, components }) {
                                                                      return (
                                                                        <AgentItem
                                                                          hit={item}
                                                                          components={components}
                                                                          setSelectedAgentName={setSelectedAgentName}
                                                                          setSelectedAgentUid={setSelectedAgentUid}
                                                                          consultants={consultants}
                                                                          setConsultants={setConsultants}
                                                                          setSelectedAgent={setSelectedAgent}
                                                                          setSelectedAgentItems={setSelectedAgentItems}
                                                                          setSelectedConsultant={setSelectedConsultant}
                                                                          setSelectedConsultantName={setSelectedConsultantName}
                                                                          setConsultantResRequestId={setConsultantResRequestId}
                                                                          setSelectedConsultantUid={setSelectedConsultantUid}
                                                                          setRateName={setRateName}
                                                                          setRateId={setRateId}
                                                                          setAgentResRequestId={setAgentResRequestId}
                                                                          setAgentRates={setAgentRates}
                                                                          setRefreshRatesEffect={setRefreshRatesEffect}
                                                                          setAgentCommission={setAgentCommission}
                                                                          setAgentObject={setAgentObject}
                                                                          bookings={bookings}
                                                                          setBookings={setBookings}
                                                                          setTransfers={setTransfers}
                                                                          setTransfersLatest={setTransfersLatest}
                                                                          user={user}
                                                                          //searchEnquiries={searchEnquiries}
                                                                        />
                                                                      );
                                                                    },
                                                                  },
                                                                },
                                                              ]}
                                                            />
                                                          </InstantSearch>
                                                        </div>
                                                      ) : null
                                                      // (
                                                      //   <div className="">
                                                      //     {createEdit === 'create' && (
                                                      //       <CustomSelect
                                                      //         value={{
                                                      //           label: selectedCompany.name,
                                                      //           value: selectedCompany.uid,
                                                      //         }}
                                                      //         onChange={(selectedOption) => selectAgent(selectedOption.value, agents)}
                                                      //         options={{
                                                      //           label: selectedCompany.name,
                                                      //           value: selectedCompany.uid,
                                                      //         }}
                                                      //         //defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                                      //         instanceId="agent-select"
                                                      //         isSearchable={false}
                                                      //         showDropdownIndicator={false}
                                                      //         //  width={180}
                                                      //         menuHeight={80}
                                                      //         color1={'#6996A0'}
                                                      //         color2={'#E5E7EB'}
                                                      //         isDisabled={true}
                                                      //       />
                                                      //     )}
                                                      //   </div>
                                                      // )
                                                    }
                                                  </div>
                                                </div>
                                                <div style={{ width: '336px' }}>
                                                  {
                                                    !selectedAgentUid ? (
                                                      <div>
                                                        <div className="w-60 flex justify-between">
                                                          <p className="text-slate-800 text-sm font-normal mb-1">Enquiry</p>
                                                          {/* {canCreateNewEnquiry && !newEnquiry ? (
<p
  onClick={() => {
    createNewEnquiry();
  }}
  className="text-xs font-bold brand-text-color-v2 cursor-pointer"
>
  Create new enquiry
</p>
) : canCreateNewEnquiry && newEnquiry ? (
<p
  onClick={() => {
    setNewEnquiry(false);
    setClientName('');
  }}
  className="text-xs font-bold brand-text-custom-color-warning cursor-pointer"
>
  Cancel new enquiry
</p>
) : null} */}
                                                        </div>
                                                        <input
                                                          disabled
                                                          id="enquiryName"
                                                          className="w-full rounded-xl text-sm h-[50px] border border-gray-200 focus:border-2 focus:border-[#489EF8] focus:ring-0 focus:outline-none hover:border-[#489EF8] hover:border-2"
                                                          //className="w-full rounded-sm h-11 text-sm"
                                                          type="text"
                                                          placeholder="Select an agent"
                                                          //required
                                                        />
                                                      </div>
                                                    ) : existingClient ? (
                                                      <div>
                                                        <div className="w-60 flex justify-between">
                                                          <p className="text-slate-800 text-sm font-normal mb-1">Enquiry</p>
                                                          {/* {!internalUser && !newEnquiry ? (
<p
  onClick={() => {
    createNewEnquiry();
  }}
  className="text-xs font-bold brand-text-color-v2 cursor-pointer"
>
  Create new enquiry
</p>
) : !internalUser && newEnquiry ? (
<p
  onClick={() => {
    setNewEnquiry(false);
    setClientName('');
  }}
  className="text-xs font-bold brand-text-custom-color-warning cursor-pointer"
>
  Cancel new enquiry
</p>
) : null} */}
                                                        </div>
                                                        <div className="">
                                                          {!newEnquiry ? (
                                                            <InstantSearch searchClient={searchClient} indexName={INSTANT_SEARCH_INDEX_NAME_ENQUIRIES}>
                                                              <VirtualSearchBox />

                                                              <CustomAutoComplete
                                                                key={keyEnquiries + uuidv4()}
                                                                placeholder="Search enquiries"
                                                                detachedMediaQuery="none"
                                                                initialState={{
                                                                  query: !selectedEnquiryTitle ? '' : selectedEnquiryTitle,
                                                                }}
                                                                openOnFocus={true}
                                                                onReset={onResetEnquiries}
                                                                getSources={({ query }) => [
                                                                  {
                                                                    sourceId: 'title',
                                                                    getItems() {
                                                                      return getAlgoliaResults({
                                                                        searchClient,
                                                                        queries: [
                                                                          {
                                                                            indexName: 'enquiries',
                                                                            query,
                                                                            params: {
                                                                              filters: 'active:true AND agent.id:' + selectedAgentUid,
                                                                            },
                                                                          },
                                                                        ],
                                                                      });
                                                                    },
                                                                    templates: {
                                                                      item({ item, components }) {
                                                                        return (
                                                                          <EnquiryItem
                                                                            hit={item}
                                                                            components={components}
                                                                            setSelectedEnquiryTitle={setSelectedEnquiryTitle}
                                                                            setClientName={setClientName}
                                                                            setSelectedConsultantName={setSelectedConsultantName}
                                                                            setSelectedConsultantUid={setSelectedConsultantUid}
                                                                            setSelectedConsultant={setSelectedConsultant}
                                                                            setConsultantResRequestId={setConsultantResRequestId}
                                                                            setEnquiryUid={setEnquiryUid}
                                                                          />
                                                                        );
                                                                      },
                                                                    },
                                                                  },
                                                                ]}
                                                              />
                                                            </InstantSearch>
                                                          ) : (
                                                            <input
                                                              disabled
                                                              id="enquiryName"
                                                              className="w-60 rounded-sm h-11 text-sm"
                                                              type="text"
                                                              value={'Processing: ' + clientName}
                                                              //placeholder="Client name"
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                    ) : null
                                                    // (
                                                    //   <div>
                                                    //     <input
                                                    //       className="w-full rounded-xl text-sm h-[50px] border border-[#E5E7EB] focus:border-2 focus:border-[#6996A0] focus:ring-0 focus:outline-none hover:border-[#999075]"
                                                    //       type="text"
                                                    //       placeholder="Client name"
                                                    //       name="clientName"
                                                    //       //value={clientName}
                                                    //       onBlur={handleClientNameInputChange}
                                                    //       defaultValue={clientName}
                                                    //     />
                                                    //   </div>
                                                    // )
                                                  }
                                                </div>
                                                <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                              </div>
                                              {selectedAgentUid.length > 0 && (
                                                <div className="mt-2" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                                  <div>{/* Content for first column that adapts to remaining space */}</div>
                                                  <div style={{ width: '336px' }}>
                                                    {' '}
                                                    <p className="text-gray-600 text-sm font-bold mb-1">{/* Content for second column */}</p>
                                                  </div>
                                                  <div style={{ width: '336px' }}>
                                                    {!user.internalUser && (
                                                      <div className="flex">
                                                        <input
                                                          type="checkbox"
                                                          name="existingClient"
                                                          className="checkbox border-nomadBlue-400 checked:border-nomadBlue-400 [--chkbg:#6996A0] [--chkfg:white]"
                                                          checked={existingClient}
                                                          onChange={(e) => {
                                                            if (existingClient) {
                                                              onResetEnquiries();
                                                            }
                                                            let isChecked = e.target.checked;
                                                            setExistingClient(isChecked);
                                                            setClientName('');
                                                            // Set your variable here based on the isChecked value
                                                          }}
                                                        />
                                                        <span className="ml-2 text-neutral-500 text-sm font-normal">Existing client</span>
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                                </div>
                                              )}
                                            </>
                                          )}

                                          {/* Client Click */}
                                          {/* <div
onClick={() => {
console.log('First log');
console.log('Second log');
setModalOpen(true);

// confirmAlert({
//   customUI: ({ onClose }) => (
//     <CustomConfirmAlertClient
//       onClose={onClose}
//       existingClient={existingClient}
//       setExistingClient={setExistingClient}
//       clientName={clientName}
//       handleClientNameInputChange={handleClientNameInputChange}
//       selectedAgentUid={selectedAgentUid}
//       onResetEnquiries={onResetEnquiries}
//     />
//   ),
//   closeOnClickOutside: false, // Prevent closing on outside click
//   closeOnEscape: false, // Prevent closing on escape key
// });
}}
>
Click Client
</div> */}

                                          {/* NEW OTHER OPTIONS */}
                                          {user.internalUser && startSearchButton === 'search' && (
                                            <>
                                              <div className="mt-10" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                                <div>{/* Content for first column that adapts to remaining space */}</div>
                                                <div style={{ width: '336px' }}>
                                                  {' '}
                                                  <p className="text-gray-600 text-sm font-bold mb-1">OTHER OPTIONS</p>
                                                </div>
                                                <div style={{ width: '336px' }}>{/* Content for third column */}</div>
                                                <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                              </div>

                                              {startSearchButton === 'search' && (
                                                <div className="mt-2" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                                  <div>{/* Content for first column that adapts to remaining space */}</div>

                                                  <div style={{ width: '336px' }}>
                                                    {' '}
                                                    <p className="text-slate-800 text-sm font-normal mb-1">Price Point</p>
                                                    <CustomSelect
                                                      placeholder={selectedPriceLevel}
                                                      defaultValue={{ label: selectedPriceLevel, value: selectedPriceLevel }}
                                                      onChange={(selectedOption) => handleChangePriceLevel(selectedOption)}
                                                      options={priceLevel.map((price) => ({
                                                        label: price.name,
                                                        value: price.uid,
                                                      }))}
                                                      //defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                                      instanceId="price-level-select"
                                                      isSearchable={false}
                                                      showDropdownIndicator={true}
                                                      //  width={180}
                                                      menuHeight={160}
                                                      color1={'#489EF8'} // Default color1
                                                      color2={'#E5E7EB'}
                                                    />
                                                  </div>

                                                  <div style={{ width: '336px' }} className="">
                                                    {' '}
                                                    <div className="text-gray-600 text-sm font-bold mb-1">
                                                      {' '}
                                                      <div className="flex items-center text-right ml-auto text-gray-800 text-sm font-normal mt-10">
                                                        <input
                                                          type="checkbox"
                                                          name="availableOnly"
                                                          className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
                                                          checked={availableOnly}
                                                          onChange={(e) => {
                                                            let isChecked = e.target.checked;
                                                            setAvailableOnly(isChecked);
                                                          }}
                                                        />
                                                        <span className="ml-2 text-neutral-500 text-sm font-normal">Prioritise properties with live availability</span>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                                </div>
                                              )}
                                            </>
                                          )}

                                          {/* FAM TRIP */}
                                          <div className="mt-10" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto 1fr', gap: '16px' }}>
                                            <div>{/* Content for first column that adapts to remaining space */}</div>
                                            <div style={{ width: '336px' }}>
                                              <div className="text-gray-600 text-sm font-bold mb-1">
                                                {' '}
                                                {user.internalUser && (
                                                  <div className="flex items-center text-right ml-auto text-gray-800 text-sm font-normal">
                                                    <input
                                                      type="checkbox"
                                                      name="isFamTrip"
                                                      className="checkbox border-nomadFormsBlue checked:border-nomadFormsBlue [--chkbg:#489EF8] [--chkfg:white]"
                                                      checked={isFamTrip}
                                                      onChange={(e) => {
                                                        let isChecked = e.target.checked;
                                                        setIsFamTrip(isChecked);
                                                        setRefreshRatesEffect(true);
                                                      }}
                                                    />
                                                    <span className="ml-2 text-neutral-500 text-sm font-normal">Fam Trip</span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            <div style={{ width: '336px' }}>{/* Content for third column */}</div>
                                            <div>{/* Content for fourth column that adapts to remaining space */}</div>
                                          </div>

                                          {/* NEXT BUTTON */}
                                          <div className="">
                                            <div className="flex flex-wrap items-center mt-4">
                                              {/* <div className="w-4/12"></div> */}
                                              <div className="w-full">
                                                <ButtonPrimary
                                                  text={startSearchButton === 'search' ? 'Find Itineraries' : 'Create Itinerary'}
                                                  onClick={async () => {
                                                    // const selectedDate = moment(dateStart);
                                                    // const oneDayAfterToday = moment.utc().startOf('day').add(1, 'days');
                                                    // const totalAdults = roomPax.reduce((acc, room) => {
                                                    //   return acc + room.adults;
                                                    // }, 0);

                                                    // console.log('selectedConsultantName: ', selectedConsultantName);
                                                    // console.log('consultantResRequestId: ', consultantResRequestId);
                                                    // console.log('clientName: ', clientName);
                                                    // console.log('pickerDate: ', pickerDate);

                                                    // if (!pickerDate) {
                                                    //   return toast.error('Please fill in all fields');
                                                    // } else if (selectedDate.isBefore(oneDayAfterToday)) {
                                                    //   // Show toast error
                                                    //   return toast.error("Selected date must be at least one day after today's date.");
                                                    // } else if (totalAdults === 0) {
                                                    //   return toast.error('Please select at least one adult');
                                                    // } else if (user.internalUser && enquiryUid === null) {
                                                    //   return toast.error('Please select an Agent and an Enquiry');
                                                    // } else {
                                                    //   //setNextTopInfo(true);
                                                    //   if (guests.adults + guests.children === 0) {
                                                    //     aggregateGuests(roomPax, setGuests);
                                                    //   }
                                                    //   console.log('next');
                                                    // }

                                                    const selectedDate = moment(dateStart);
                                                    // const today = moment.utc().startOf('day').subtract(1, 'days');;
                                                    // const oneWeekFromToday = moment.utc().startOf('day').add(6, 'days');
                                                    const totalAdults = roomPax.reduce((acc, room) => {
                                                      return acc + room.adults;
                                                    }, 0);

                                                    if (!pickerDate) {
                                                      return toast.error('Please fill in all fields');
                                                    }

                                                    const dateValidation = validateBookingDate(selectedDate, user);
                                                    if (!dateValidation.isValid) {
                                                      return toast.error(dateValidation.error);
                                                    }

                                                    // // Different validation for internal vs external users
                                                    // if (!pickerDate) {
                                                    //   return toast.error('Please fill in all fields');
                                                    // } else if (user.internalUser) {
                                                    //   // Internal users can book from today onwards
                                                    //   if (selectedDate.isBefore(today)) {
                                                    //     return toast.error("Selected date cannot be before today's date.");
                                                    //   }
                                                    // } else {
                                                    //   // External users must book at least 1 week in advance
                                                    //   if (selectedDate.isBefore(oneWeekFromToday)) {
                                                    //     return toast.error('You can only make bookings at least 1 week away. If booking within 1 week, please contact your consultant');
                                                    //   }
                                                    // }

                                                    if (totalAdults === 0) {
                                                      return toast.error('Please select at least one adult');
                                                    } else if (user.internalUser && enquiryUid === null) {
                                                      return toast.error('Please select an Agent and an Enquiry');
                                                    } else {
                                                      if (guests.adults + guests.children === 0) {
                                                        aggregateGuests(roomPax, setGuests);
                                                      }
                                                      console.log('next');
                                                    }

                                                    if (startSearchButton === 'search') {
                                                      setAdvancedMode(false); // Quick Start path
                                                      setWizardPage(true);
                                                      // if (tripType === 'safari') {
                                                      //   setSelectedBeachDuration('No Beach');
                                                      //   setSelectedBeachDurationValue(0);
                                                      // } else {
                                                      //   //setSelectedBeachDuration('5 days');
                                                      //   // setSelectedBeachDurationValue(5);
                                                      // }
                                                      if (selectedOptions.length === 0) {
                                                        return toast.error('Please select at least one preferred park or property.');
                                                      }
                                                      if (!selectedPriceLevelUid) {
                                                        return toast.error('Please select a Price Point.');
                                                      }

                                                      setProperties([]);
                                                      setRooms([]);
                                                      setBookings([]);
                                                      setCustomLocations([]);
                                                      const searchRequest = {
                                                        locations: [],
                                                        dateStart: dateStart,
                                                        type: tripType,
                                                        totalRooms: roomPax.length,
                                                        priceCategoryId: selectedPriceLevelUid,
                                                        totalPax: roomPax.reduce((acc, room) => acc + room.adults, 0) + roomPax.reduce((acc, room) => acc + room.children, 0),
                                                        availablePropertiesOnly: availableOnly,
                                                        beachNights: selectedBeachDurationValue,
                                                        safariNights: selectedSafariDurationValue,
                                                        arrivalCityNight: arrivalCityNight,
                                                        departureCityNight: departureCityNight,
                                                      };

                                                      searchRequest.locations = selectedOptions.map((selectedOption) => ({
                                                        name: selectedOption.name,
                                                        id: selectedOption.uid,
                                                        parentParkId: selectedOption.park.id,
                                                        type: selectedOption.type,
                                                        country: selectedOption.country,
                                                        category: selectedOption.category,
                                                      }));

                                                      setSearchRequestResult(searchRequest);
                                                      setShowTemplates(true);
                                                    } else {
                                                      setAdvancedMode(true); // Advanced Mode path
                                                      setFirstSearch(false);
                                                      setNextTopInfo(true);
                                                    }
                                                  }}
                                                  className={'float-right mt-4'}
                                                />
                                                {/* Next
</ButtonPrimary> */}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : showEditOptions && startSearchButton === 'start' ? (
                                        <>
                                          {/* NEW HEADER DIVS */}
                                          <div
                                            style={{
                                              display: 'grid',
                                              gridTemplateColumns: 'auto auto auto auto 1fr',
                                              gap: '10px',
                                              justifyItems: 'start',
                                              width: '100%',
                                            }}
                                          >
                                            {/* COL 1 */}
                                            {/* <div
    style={{
      padding: '10px',
      borderRight: '1px solid #d2dbdb',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    }}
  >
    <div className="pr-5">
      <div className="flex">
        <CiGlobe />
        <div className="ml-2 text-gray-600 text-xs font-normal">CLIENT</div>
      </div>
      <div className="flex mt-1">
        <div className="text-gray-800 text-base font-semibold">{clientName}</div>
      </div>
    </div>
  </div> */}
                                            {/* COL 2 */}
                                            <div
                                              style={{
                                                padding: '10px',
                                                borderRight: '1px solid #d2dbdb',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                              }}
                                            >
                                              <div className="px-5">
                                                <div className="flex text-left">
                                                  <CiCalendar />
                                                  <div className="ml-2 text-gray-600 text-xs font-normal">START DATE</div>
                                                </div>
                                                <div className="flex mt-1">
                                                  <DatePicker
                                                    //className="underline cursor-pointer text-sm brand-text-color-v2 brand-text-color-v2-hover font-medium hover:border-0 border-0 focus:border-0 border-0 outline-none shadow-white underline-offset-2 p-0"
                                                    style={{ color: '#2A99A3', textAlign: 'left', padding: '0px' }}
                                                    selected={moment(realDate, 'YYYY-MM-DD').toDate()}
                                                    dateFormat="eee dd MMM yy"
                                                    minDate={new Date()}
                                                    onChange={(date) => {
                                                      const dateValidation = validateBookingDate(date, user);
                                                      if (!dateValidation.isValid) {
                                                        return toast.error(dateValidation.error);
                                                      }
                                                      console.log('Selected date:', date);
                                                      const selDate = moment(date).format('YYYY-MM-DD');
                                                      const dateToUse = moment(selDate, 'YYYY-MM-DD').subtract(0, 'days').format('YYYY-MM-DD');
                                                      setDateStart(dateToUse);
                                                      setArrowDate(dateToUse);
                                                      setRealDate(selDate);
                                                      setDateEnd(moment(selDate).subtract(0, 'days').add(3, 'months').format('YYYY-MM-DD'));
                                                      setCallUpdateDates(true);
                                                      filterVehicleRooms(bookings, setBookings);
                                                      rebuildBookings(bookings, setBookings);
                                                      setFetchCustom(true);
                                                    }}
                                                    customInput={<CustomDateInputNoBorderNewHeader />}
                                                    calendarClassName="custom-datepicker-new datepicker-padding-new"
                                                    showPopperArrow={false}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {/* COL 3 */}
                                            <div
                                              style={{
                                                padding: '10px',
                                                borderRight: '1px solid #d2dbdb',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                              }}
                                            >
                                              <div className="px-5">
                                                <div className="flex">
                                                  <GoPerson />
                                                  <div className="ml-2 text-gray-600 text-xs font-normal">GUESTS</div>
                                                </div>
                                                <div className="flex mt-0">
                                                  <div className="text-gray-800 text-base font-semibold underline">
                                                    {' '}
                                                    <div className={` cursor-pointer border border-white focus:outline-none focus:border-white`}>
                                                      <GuestsPopover guests={guests} setGuests={setGuests} bookings={bookings} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* COL 4 */}

                                            {showQuoteMode && (
                                              <div
                                                style={{
                                                  padding: '10px',
                                                  //borderRight: '1px solid #d2dbdb',
                                                  display: 'flex',
                                                  alignItems: 'flex-start',
                                                  justifyContent: 'flex-start',
                                                }}
                                              >
                                                {/* <div className="pr-3">
<div className="flex">
  <VscNotebookTemplate />
  <div className="ml-2 text-gray-600 text-xs font-normal">QUOTE MODE</div>
</div>
<div className="flex mt-1">
  <div className="text-gray-800 text-base font-semibold">
    {' '}
    <Switch
      checked={quoteMode}
      onChange={setQuoteMode}
      className="mr-2 group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-[#7F1F1D] focus:ring-offset-2"
    >
      <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-full bg-white" />
      <span
        aria-hidden="true"
        className={`${
          quoteMode ? 'bg-[#7F1F1D]' : 'bg-gray-200'
        } pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
      />
      <span
        aria-hidden="true"
        className={`${
          quoteMode ? 'translate-x-5' : 'translate-x-0'
        } pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
      />
    </Switch>
  </div>
</div>
</div> */}
                                              </div>
                                            )}

                                            {/* Col to replace {clientName} */}
                                            <div
                                              style={{
                                                padding: '10px',
                                                // borderRight: '1px solid #d2dbdb',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                              }}
                                            ></div>

                                            {/* COL 5 */}
                                            <div
                                              style={{
                                                padding: '10px',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-end',
                                                marginLeft: 'auto',
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="flex cursor-pointer brand-text-color-v2-hover"
                                                  onClick={async () => {
                                                    if (user.accessLevel === 'travelAgent') {
                                                      setStartSearchButton('search');
                                                    }
                                                    setNextTopInfo(false);
                                                    setWizardPage(false);
                                                    console.log('edit');
                                                    setShowBookings(true);
                                                  }}
                                                >
                                                  <MdOutlineModeEdit />
                                                  <div className="ml-2 text-gray-600 text-xs font-normal underline hover:text-nomadEvergreen-700">Back to Search</div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {/* NEW HEADER DIVS row 1 */}
                                          <div
                                            style={{
                                              display: 'grid',
                                              gridTemplateColumns: 'auto auto auto auto 1fr',
                                              gap: '10px',
                                              justifyItems: 'start',
                                              width: '100%',
                                            }}
                                          >
                                            {/* COL 3 */}
                                            <div
                                              style={{
                                                padding: '10px',
                                                borderRight: '1px solid #d2dbdb',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                              }}
                                            >
                                              <div className="pr-5">
                                                <div className="flex text-left">
                                                  <CiCalendar />
                                                  <div className="ml-2 text-gray-600 text-xs font-normal">START DATE</div>
                                                </div>
                                                <div className="flex mt-1">
                                                  <DatePicker
                                                    //className="underline cursor-pointer text-sm brand-text-color-v2 brand-text-color-v2-hover font-medium hover:border-0 border-0 focus:border-0 border-0 outline-none shadow-white underline-offset-2 p-0"
                                                    style={{ color: '#2A99A3', textAlign: 'left', padding: '0px' }}
                                                    selected={moment(realDate, 'YYYY-MM-DD').toDate()}
                                                    dateFormat="eee dd MMM yy"
                                                    minDate={new Date()}
                                                    onChange={(date) => {
                                                      const dateValidation = validateBookingDate(date, user);
                                                      if (!dateValidation.isValid) {
                                                        return toast.error(dateValidation.error);
                                                      }
                                                      console.log('Selected date:', date);
                                                      const selDate = moment(date).format('YYYY-MM-DD');
                                                      const dateToUse = moment(selDate, 'YYYY-MM-DD').subtract(0, 'days').format('YYYY-MM-DD');
                                                      setDateStart(dateToUse);
                                                      setArrowDate(dateToUse);
                                                      setRealDate(selDate);
                                                      setDateEnd(moment(selDate).subtract(0, 'days').add(3, 'months').format('YYYY-MM-DD'));
                                                      setCallUpdateDates(true);
                                                      filterVehicleRooms(bookings, setBookings);
                                                      rebuildBookings(bookings, setBookings);
                                                      setFetchCustom(true);
                                                    }}
                                                    customInput={<CustomDateInputNoBorderNewHeader />}
                                                    calendarClassName="custom-datepicker-new datepicker-padding-new"
                                                    showPopperArrow={false}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {/* COL 4 */}
                                            <div
                                              style={{
                                                padding: '10px',
                                                borderRight: '1px solid #d2dbdb',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                              }}
                                            >
                                              <div className="px-5">
                                                <div className="flex">
                                                  <GoPerson />
                                                  <div className="ml-2 text-gray-600 text-xs font-normal">GUESTS</div>
                                                </div>
                                                <div className="flex mt-0">
                                                  <div className="text-gray-800 text-base font-semibold underline">
                                                    {' '}
                                                    <div className={` cursor-pointer border border-white focus:outline-none focus:border-white`}>
                                                      <GuestsPopover guests={guests} setGuests={setGuests} bookings={bookings} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* COL 5 */}
                                            {/* COL 3 */}
                                            <div
                                              style={{
                                                padding: '10px',
                                                borderRight: '1px solid #d2dbdb',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                              }}
                                            >
                                              <div className="px-5">
                                                <div className="flex">
                                                  <LuFilePlus />
                                                  <div className="ml-2 text-gray-600 text-xs font-normal">NEW VERSION</div>
                                                </div>
                                                <div className="flex mt-1">
                                                  <div className="text-gray-800 text-base font-semibold">
                                                    {' '}
                                                    {createEdit === 'edit' && (
                                                      <div className="flex items-end text-right ml-auto text-sm mr-5">
                                                        <Switch
                                                          checked={masterVersion || newUserVersion}
                                                          onChange={(eventArgument) => {
                                                            console.log('Switch onChange event argument:', eventArgument); // Log to inspect what is received
                                                            if (!masterVersion) {
                                                              // Assuming 'eventArgument' is the new checked value (true/false). Adjust based on your findings.
                                                              setNewUserVersion(eventArgument === true);
                                                            }
                                                          }}
                                                          className={`group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-[#489EF8] focus:ring-offset-2 ${
                                                            masterVersion ? 'cursor-not-allowed' : 'cursor-pointer'
                                                          }`}
                                                          disabled={masterVersion}
                                                        >
                                                          <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-full bg-white" />
                                                          <span
                                                            aria-hidden="true"
                                                            className={`${
                                                              newUserVersion ? 'bg-[#489EF8]' : 'bg-gray-200'
                                                            } pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
                                                          />
                                                          <span
                                                            aria-hidden="true"
                                                            className={`${
                                                              newUserVersion ? 'translate-x-5' : 'translate-x-0'
                                                            } pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
                                                          />
                                                        </Switch>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            {/* COL 3a */}
                                            {user.internalUser && (
                                              <div
                                                style={{
                                                  padding: '10px',
                                                  borderRight: '1px solid #d2dbdb',
                                                  display: 'flex',
                                                  alignItems: 'flex-start',
                                                  justifyContent: 'flex-start',
                                                }}
                                              >
                                                <div className="px-5">
                                                  <div className="flex">
                                                    <MdOutlineTune />
                                                    <div className="ml-2 text-gray-600 text-xs font-normal">ADVANCED MODE</div>
                                                  </div>
                                                  <div className="flex mt-1">
                                                    <div className="text-gray-800 text-base font-semibold">
                                                      {' '}
                                                      {createEdit === 'edit' && (
                                                        <div className="flex items-end text-right ml-auto text-sm mr-5">
                                                          <Switch
                                                            checked={advancedMode}
                                                            onChange={(checked) => {
                                                              setAdvancedMode(checked);
                                                            }}
                                                            className={`group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-[#489EF8] focus:ring-offset-2`}
                                                          >
                                                            <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-full bg-white" />
                                                            <span
                                                              aria-hidden="true"
                                                              className={`${
                                                                advancedMode ? 'bg-[#489EF8]' : 'bg-gray-200'
                                                              } pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
                                                            />
                                                            <span
                                                              aria-hidden="true"
                                                              className={`${
                                                                advancedMode ? 'translate-x-5' : 'translate-x-0'
                                                              } pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
                                                            />
                                                          </Switch>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}

                                            {/* COL 4 */}
                                            <div
                                              style={{
                                                padding: '10px',
                                                // borderRight: '1px solid #d2dbdb',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                              }}
                                            >
                                              {/* <div className="px-5">
            <div className="flex">
              <GoVersions />
              <div className="ml-2 text-gray-600 text-xs font-normal">VERSION</div>
            </div>
            <div className="flex mt-1">
              <div className="text-gray-800 text-base font-semibold">{itinerariesVcMenu}</div>
            </div>
          </div> */}
                                            </div>
                                          </div>
                                        </>
                                      )}

                                      {/* DATES END */}
                                    </div>

                                    {/* //! SECTION 1: END ---------------------------------------------------------------------------------- */}

                                    {/* SPACER */}
                                    <div className="h-5"></div>
                                  </div>
                                  {/* //! SECTION 2: START ---------------------------------------------------------------------------------- */}
                                  {!showBookings ? (
                                    <div className="pr-5">
                                      <div
                                        style={{
                                          ...deeShadow,
                                          width: `${leftColWidth}px`,
                                        }}
                                        className={`p-5 mb-5 bg-white rounded-lg`}
                                      >
                                        {/* TABS */}
                                        {/* TODO: Deal with border color and dynamic colors */}
                                        <div className={`grid grid-cols-12`}>
                                          <div className="col-span-6">
                                            <div className="text-xl brand-text-color-800 font-semibold mb-2">Availability</div>
                                            <div className="flex items-center mb-5 text-nomadEvergreen-700">
                                              <MdDateRange size={20} className="inline-block w-5 h-5" />
                                              <button
                                                className="font-normal text-sm inline-block ml-2"
                                                onClick={() => {
                                                  setShowBookings(!showBookings);
                                                }}
                                              >
                                                Hide room availability
                                              </button>
                                            </div>
                                          </div>
                                          <div className="col-span-6"></div>
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          ...deeShadow,
                                          width: `${leftColWidth}px`,
                                        }}
                                        className={`p-5 h-16 bg-white rounded-lg flex flex-row `}
                                      >
                                        {rooms.length > 0 ? (
                                          <div className="flex divide-x">
                                            <div className="flex flex-row place-items-center">
                                              <MdEventAvailable size={20} /> <div className="pl-2 text-xs leading-normal mr-4">Availability</div>
                                            </div>
                                            <div className="flex flex-row place-items-center mr-4 pl-5">
                                              <MdBlock size={20} /> <div className="pl-2 text-xs w-32 leading-normal">No Rooms Available</div>
                                            </div>
                                            <div className="flex flex-row place-items-center mr-4 pl-5">
                                              <BsQuestionCircle size={18} /> <div className="pl-2 text-xs leading-normal">Dates not searched</div>
                                            </div>
                                            <div className="flex flex-row  place-items-center mr-2 pl-5">
                                              <p className="">
                                                <FaAsterisk size={18} />
                                              </p>{' '}
                                              <div className="pl-2 text-xs w-80 leading-normal">Room type contains provisional bookings</div>
                                            </div>
                                          </div>
                                        ) : (
                                          <h2 className="text-sm brand-text-color-800 font-normal italic mb-5">Please select a property on the right</h2>
                                        )}
                                      </div>
                                      <div className="h-5"></div>

                                      {rooms.length > 0 && (
                                        <div>
                                          <div
                                            style={{
                                              ...deeShadow,
                                              width: `${leftColWidth}px`,
                                            }}
                                            className={`p-5 bg-white rounded-lg`}
                                          >
                                            {/* Filters */}
                                            <div className="grid grid-cols-11 gap-px ml-2 text-xs">
                                              <div className="col-span-2 float-left gap-2 place-items-center">
                                                <input
                                                  type="checkbox"
                                                  checked={showRoomVehicle !== 'Vehicle' && 'checked'}
                                                  onChange={() => {}}
                                                  className="checkbox checkbox-sm ring-offset-0 focus:ring-0 focus:shadow-none checkbox-accent mr-2"
                                                  onClick={() => {
                                                    if (showRoomVehicle === 'Vehicle') {
                                                      setShowRoomVehicle('');
                                                    } else {
                                                      setShowRoomVehicle('Vehicle');
                                                    }
                                                  }}
                                                />
                                                Show Vehicles
                                              </div>
                                              <div className="col-span-3 float-left gap-2 place-items-center">
                                                <input
                                                  type="checkbox"
                                                  checked={showRoomGuide !== 'Guide/Pilot Bed' && 'checked'}
                                                  onChange={() => {}}
                                                  className="checkbox checkbox-sm ring-offset-0 focus:ring-0 focus:shadow-none checkbox-accent mr-2"
                                                  onClick={() => {
                                                    if (showRoomGuide === 'Guide/Pilot Bed') {
                                                      setShowRoomGuide('');
                                                    } else {
                                                      setShowRoomGuide('Guide/Pilot Bed');
                                                    }
                                                  }}
                                                />
                                                Show Guide & Pilot Rooms
                                              </div>
                                              <div className="col-span-3 float-left gap-2 place-items-center">
                                                <input
                                                  type="checkbox"
                                                  checked={showRoomActivity !== 'Activity' && 'checked'}
                                                  onChange={() => {}}
                                                  className="checkbox checkbox-sm ring-offset-0 focus:ring-0 focus:shadow-none checkbox-accent mr-2"
                                                  onClick={() => {
                                                    if (showRoomActivity === 'Activity') {
                                                      setShowRoomActivity('');
                                                    } else {
                                                      setShowRoomActivity('Activity');
                                                    }
                                                  }}
                                                />
                                                Show Activities
                                              </div>
                                            </div>

                                            <div className="pt-5 grid grid-cols-11 gap-px">
                                              {/* Right: Actions */}

                                              <div className="ml-2 relative bg-white overflow-hidden col-span-3 text-xs brand-text-custom-color-warning">
                                                {/* <button
                className=""
                onClick={async () => {
                  await removeAll(properties, setProperties, setLoadingData, setRooms);
                  await removeAllBookings(setBookings, bookings, setRefreshBtn);
                  setFetchCustom(true);
                }}
              >
                Remove All
              </button> */}
                                              </div>

                                              <div className="relative bg-white overflow-hidden col-span-8 rounded-l-xl">
                                                <div className="flow-root sm:auto-cols-max gap-2 brand-bg-custom-color mr-2">
                                                  <div className="h-full grid grid-flow-col gap-2 float-left ml-4 align-middle font-bold text-xs font-xs uppercase leading-loose">
                                                    {moment(dateStart).format('ddd')} {moment(dateStart).format('D')} {moment(dateStart).format('MMM')} {moment(dateStart).format('YYYY')}
                                                    <span className="font-normal lowercase">to</span>
                                                    {moment(dateEnd).format('ddd')} {moment(dateEnd).format('D')} {moment(dateEnd).format('MMM')} {moment(dateEnd).format('YYYY')}
                                                  </div>
                                                  <div className="grid grid-flow-col sm:auto-cols-max float-right gap-2">
                                                    {/* Previous month button */}
                                                    <button
                                                      className="pt-0.5 border-x border-white px-2 text-slate-500 hover:text-slate-600  disabled:text-slate-400 disabled:cursor-not-allowed"
                                                      disabled={moment(realDate).add(7, 'days').isAfter(moment(arrowDate)) ? true : false}
                                                      onClick={() => {
                                                        prevDate(setArrowDate, arrowDate);
                                                      }}
                                                    >
                                                      <FaLongArrowAltLeft size={20} />
                                                    </button>

                                                    {/* Next month button */}
                                                    <button
                                                      className="pt-0.5 mr-2 border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                                                      //disabled={month === 11 ? true : false}
                                                      onClick={() => {
                                                        nextDate(setArrowDate, arrowDate);
                                                      }}
                                                    >
                                                      <FaLongArrowAltRight size={20} />
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* Results table */}
                                            <div className="bg-white rounded-sm  overflow-hidden">
                                              {/* Days of the week */}
                                              <div className="grid grid-cols-11 gap-px">
                                                {/* START BLANK */}
                                                <div className="grow flex flex-col relative mt-2 ml-3 p-0.5 sm:p-1.5 overflow-hidden col-span-3 text-xs font-xs">
                                                  Accommodation
                                                  <div className="absolute bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-white to-transparent pointer-events-none" aria-hidden="true"></div>
                                                </div>

                                                {/* END BLANK */}
                                                {dayNums.map((num, i) => {
                                                  return (
                                                    <div key={uuidv4()} className="px-1 py-2">
                                                      {/* <div className="text-slate-500 text-sm font-medium text-center lg:hidden">{num}</div> */}
                                                      <div className="text-slate-500 text-xs font-xs text-center hidden lg:block">
                                                        {/* {moment(arrowDate).add(num, 'days').format('D')} {moment(arrowDate).add(num, 'days').format('MMM')} */}
                                                        <div key={uuidv4()} className="px-1 py-0">
                                                          <div className="text-slate-500 text-xs text-center">{moment(arrowDate).add(num, 'days').format('ddd')}</div>
                                                          <div className="text-slate-500 text-xs text-center">{moment(arrowDate).add(num, 'days').format('D')}</div>
                                                          <div className="text-slate-500 text-xs text-center">{moment(arrowDate).add(num, 'days').format('MMM')}</div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                              {isLoadingStock ? (
                                                // <Spinner />
                                                <CircleSpinnerOverlay loading={isLoadingStock} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />
                                              ) : (
                                                rooms.map((x, propertyCount) =>
                                                  rooms[propertyCount].loading ? (
                                                    <div key={uuidv4()} className="px-2 py-0.5">
                                                      <div className={`grid grid-cols-11 brand-bg-custom-color rounded-t rounded-b mb-1 animate-pulse`}>
                                                        <div className="ml-2 h-8 col-span-3 text-xs flex flex-row place-items-center">{rooms[propertyCount].name}</div>
                                                        <div className=" h-full flex col-span-7 justify-between place-items-center text-xs">Checking availability...</div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div key={uuidv4()} className="px-2 py-0.5">
                                                      <div className={`grid grid-cols-11 brand-bg-custom-color rounded-t ${!rooms[propertyCount].collapsed ? null : 'rounded-b'}`}>
                                                        {/* SET PROPERTY NAME */}
                                                        <div className="ml-2 h-8 col-span-3 text-xs flex flex-row place-items-center">
                                                          {rooms[propertyCount].name} <IoIosInformationCircle size={18} className="ml-1" />
                                                        </div>
                                                        {/* SET AVAILABLITY ICONS */}
                                                        {dayNums.map((day) => (
                                                          <div key={uuidv4()}>
                                                            {/* Rooms available for this day */}

                                                            {stockCountCheck(day, propertyCount, arrowDate, rooms)}
                                                          </div>
                                                        ))}
                                                        {/* SET REMOVE?COMPACT ICONS */}
                                                        <div key={uuidv4()} className="grid grid-cols-2">
                                                          <div key={uuidv4()} className="mt-1.5 h-full flex flex-col justify-between place-items-center"></div>
                                                          <div className="mt-1 h-full flex flex-col justify-between place-items-center brand-text-color-v2">
                                                            <button
                                                              onClick={() => {
                                                                rooms[propertyCount].collapsed = !rooms[propertyCount].collapsed;
                                                                setRooms((rooms) => [...rooms]);
                                                              }}
                                                            >
                                                              {!rooms[propertyCount].collapsed ? <IoIosArrowDropupCircle size={20} /> : <IoIosArrowDropdownCircle size={20} />}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* ADD COLLAPSE VARIABLE TO ROOMS ARRAY */}
                                                      {!rooms[propertyCount].collapsed ? (
                                                        <div className="grid grid-cols-11 gap-px border-x border-b border-slate-200 rounded-b mb-0">
                                                          {/* START LIST ROOM TYPES */}
                                                          <div className="relative bg-white overflow-hidden col-span-3 ">
                                                            <div className="grow flex flex-col relative py-0.5 sm:py-1.5 overflow-hidden auto-cols-max text-xs ">
                                                              {
                                                                // Rooms in original Room order from properties

                                                                properties[getPropObj(x.propUid, properties)].rooms
                                                                  .filter((rm) => !rm.fetchError && (user.internalUser || !rm.hideExternalUsers)) // Filter out rooms with fetchError
                                                                  .map(
                                                                    (rm, i) =>
                                                                      rm.type !== showRoomVehicle &&
                                                                      rm.type !== showRoomGuide &&
                                                                      rm.type !== showRoomActivity && (
                                                                        <div key={uuidv4()} className={`relative w-full text-left mb-0 ${i > 0 && 'border-t border-slate-200'}`}>
                                                                          <div className="py-0.5 rounded overflow-hidden">
                                                                            {/* Room name */}
                                                                            <div className="text-xs truncate pl-2">{rm.name}</div>
                                                                            {/* Room time */}
                                                                          </div>
                                                                        </div>
                                                                      ),
                                                                  )
                                                              }
                                                            </div>
                                                          </div>
                                                          {/* END LIST ROOM TYPES */}
                                                          {dayNums.map((day, i) => (
                                                            <div key={uuidv4()}>
                                                              {/* Rooms */}

                                                              <div key={uuidv4()} className="relative bg-white overflow-hidden">
                                                                <div className="grow flex flex-col relative py-0.5 sm:py-1.5 overflow-hidden ">
                                                                  <div className="h-full flex flex-col justify-between ">
                                                                    {stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'total', null)
                                                                      ? getRooms(moment(arrowDate).add(day, 'days').format('YYYY-MM-DD'), propertyCount, rooms, 'total')
                                                                          .filter((res) => user.internalUser || !res.hideExternalUsers) // Filter out rooms with hideExternalUsers
                                                                          .map(
                                                                            (res, ii) =>
                                                                              res.stockType !== showRoomVehicle &&
                                                                              res.stockType !== showRoomGuide &&
                                                                              res.stockType !== showRoomActivity && (
                                                                                <button key={uuidv4()} className={`w-full text-left mb-0 ${ii > 0 ? 'border-t border-slate-200' : ''}`} disabled>
                                                                                  <div
                                                                                    className={`py-0.5 border-r border-slate-200 ${
                                                                                      res.stockCount === 0
                                                                                        ? stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res) > 0
                                                                                          ? 'bg-red-700 px-2'
                                                                                          : 'bg-red-700 text-white'
                                                                                        : ''
                                                                                    }`}
                                                                                  >
                                                                                    <div
                                                                                      data-tip
                                                                                      data-for={`${
                                                                                        stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res) > 0 &&
                                                                                        'Provisional' + res.stockUuid
                                                                                      }`}
                                                                                      className={`text-xs text-center font-semibold truncate items-center ${
                                                                                        res.stockCount > 0 && stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res) > 0
                                                                                          ? 'text-black underline cursor-pointer'
                                                                                          : stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res) > 0
                                                                                          ? 'text-white underline cursor-pointer'
                                                                                          : ''
                                                                                      }`}
                                                                                    >
                                                                                      <ReactTooltip id={'Provisional' + res.stockUuid} type="dark" effect="solid">
                                                                                        <span>Provisional: {stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res)}</span>
                                                                                      </ReactTooltip>
                                                                                      {/* {res.stockCount !== 0 ? res.stockCount : stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res)} */}
                                                                                      {res.stockCount !== 0 ? res.stockCount : 0}
                                                                                      {stockCountCheckZero(day, propertyCount, arrowDate, rooms, 'provisional', res) > 0 && '*'}
                                                                                    </div>
                                                                                  </div>
                                                                                </button>
                                                                              ),
                                                                          )
                                                                      : properties[getPropObj(x.propUid, properties)].rooms
                                                                          .filter((rm) => !rm.fetchError && (user.internalUser || !rm.hideExternalUsers)) // Filter out rooms with fetchError
                                                                          .map(
                                                                            (rm, ri) =>
                                                                              rm.type !== showRoomVehicle &&
                                                                              rm.type !== showRoomGuide &&
                                                                              rm.type !== showRoomActivity && (
                                                                                <div
                                                                                  key={uuidv4()}
                                                                                  className={`relative w-full text-left mb-0 ${ri > 0 && 'border-t border-slate-200'}
                                                              }`}
                                                                                >
                                                                                  <div className={`px-2 py-0.5 overflow-hidden border-r border-slate-200`}>
                                                                                    {/* Stock numbers */}
                                                                                    <div className="text-xs text-center font-semibold truncate">
                                                                                      - {/* {res.stockName} {addDays(startDay, day).getDate()} */}
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              ),
                                                                          )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ))}
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  ),
                                                )
                                              )}
                                            </div>
                                          </div>{' '}
                                        </div>
                                      )}
                                    </div>
                                  ) : packageTab == null ? (
                                    ''
                                  ) : !nextTopInfo ? null : (
                                    <div className="pr-5">
                                      <div
                                        style={{
                                          // ...deeShadow,
                                          width: `${leftColWidth}px`,
                                        }}
                                        className={`bg-white rounded-lg  pb-5`}
                                      >
                                        {/* TABS */}
                                        {/* TODO: Deal with border color and dynamic colors */}
                                        <div className={`grid grid-cols-12`}>
                                          <div className="col-span-6 mb-4">
                                            <div className={`text-xl brand-text-color-800 font-semibold mb-2`}>Add Properties</div>
                                            <div className="flex items-center font-normal text-sm mb-2">
                                              <span>Add/switch properties.</span>
                                              <IoMdInformationCircleOutline size={18} className="ml-2" />
                                            </div>
                                          </div>

                                          <div className="col-span-6 flex justify-end items-center gap-4">
                                            {bookings.length > 0 && (
                                              <ButtonOutlineSmall
                                                text="Availability Table"
                                                icon={<FiColumns />}
                                                iconPosition="left"
                                                onClick={() => {
                                                  setShowBookings(!showBookings);
                                                }}
                                              />
                                            )}
                                            {advancedMode && <ButtonOutlineSmall text="Add Properties" icon={<FiPlus />} iconPosition="left" onClick={handleOpenPropertiesSlideOut} />}
                                          </div>
                                        </div>
                                        {bookings.length === 0 && (
                                          <>
                                            <div className={`bg-white border-2 border-dashed rounded-xl py-4 mb-10 px-5 h-28 flex items-center justify-center`}>
                                              <h2 className="text-sm brand-text-grey-1-v2 font-normal">Click '+ Add Properties' to start</h2>
                                            </div>
                                            <div className="h-0.5"></div>
                                          </>
                                        )}
                                        <DragDropContext
                                          onDragEnd={onDragEnd}
                                          onDragStart={() => {
                                            if (!bookings.some((booking) => booking.rooms.length > 0 && booking.rooms.some((room) => room.edit))) {
                                              setIsPopoverOpen(false);
                                              expandCollapseAll(bookings);
                                            } else {
                                              setIsPopoverOpen(false);
                                              // Optionally, handle the case when dragging is not allowed
                                              // For example, show a message or do nothing
                                            }
                                          }}
                                        >
                                          <Droppable droppableId="droppable" isDropDisabled={user.accessLevel === 'travelAgent'} className="">
                                            {(provided, snapshot) => (
                                              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} className="">
                                                {bookings.map((item, index) => {
                                                  console.log('item start: ', JSON.stringify(item, null, 2));
                                                  // Rates drop down - start
                                                  const propertiesObj = properties[getPropObj(item.propUid, properties)]; // Get the specific properties object for this item

                                                  // Determine if the current rateName and creatioUid match any existing options for this specific item
                                                  const currentOptionExists =
                                                    propertiesObj &&
                                                    ((propertiesObj.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' && propertiesObj.rates[item.rateName]) ||
                                                      propertiesObj.thirdPartyRates.some(
                                                        (rateObj) => rateObj.active === true && rateObj.type === item.rateName && (!item.creatioUid || rateObj.creatioUid === item.creatioUid),
                                                      ));

                                                  if (!currentOptionExists) {
                                                    item.rateName = 'select';
                                                  }
                                                  // Rates drop down - end
                                                  console.log('item');
                                                  console.log(item.propUid);
                                                  console.log(JSON.stringify(item, undefined, 4));
                                                  // // Only run the following logic if supplier name is not "Nomad Tanzania"
                                                  //if (properties[getPropObj(item.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67') { //16/06/24
                                                  //Nomad Tanzania
                                                  // const foundRateObj = properties[getPropObj(item.propUid, properties)].thirdPartyRates.find(
                                                  //   (rateObj) => rateObj.type === item.rateName && (!item.creatioUid || rateObj.creatioUid === item.creatioUid)
                                                  // );

                                                  const foundRateObj = properties[getPropObj(item.propUid, properties)].thirdPartyRates.find(
                                                    (rateObj) => rateObj.type === item.rateName && (!item.creatioUid || rateObj.creatioUid === item.creatioUid) && rateObj.active === true,
                                                  );

                                                  // If found, update item.creatioUid
                                                  if (foundRateObj && foundRateObj.creatioUid) {
                                                    item.creatioUid = foundRateObj.creatioUid;
                                                    item.rateCreatioUid = foundRateObj.creatioUid;
                                                    item.rateKaraniId = foundRateObj.karaniId;
                                                    item.rateFullName = foundRateObj.name;
                                                    // console.log("foundRateObj.creatioUid:", foundRateObj.creatioUid);
                                                  } else if (foundRateObj) {
                                                    item.rateKaraniId = foundRateObj.karaniId;
                                                  }
                                                  //} //16/06/24

                                                  console.log('item.rateName:', item.rateName);
                                                  console.log('item.creatioUid:', item.creatioUid);
                                                  return (
                                                    <Draggable
                                                      key={item.id}
                                                      draggableId={item.id}
                                                      index={index}
                                                      isDragDisabled={user.accessLevel === 'travelAgent' || (!advancedMode && !user.internalUser)}
                                                    >
                                                      {/* START LIST PROPERTIES BY DAYS */}
                                                      {(provided, snapshot) =>
                                                        // resRequestDisplay(
                                                        // 	item.propUid,
                                                        // 	properties
                                                        // ) &&
                                                        rooms.some((room) => room.loading) || fetchingRates || item.remove ? (
                                                          <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                            className={`bg-white border rounded-xl drop-shadow-md border-slate-200 h-40 flex items-center justify-center text-center mb-5 animate-pulse`}
                                                          >
                                                            <div className="flex">
                                                              <div className="pr">Loading property...</div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            // style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                            style={{
                                                              ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                                              //zIndex: 9999 - index,
                                                            }}
                                                            // className={`bg-white border rounded-xl pt-4 mb-5 drop-shadow-md ${
                                                            //   (item.rooms.length === 0 || !resRequestDisplay(item.propUid, properties)) && "brand-text-grey-v2"
                                                            //     ? "border-slate-200"
                                                            //     : item.rooms.some((room) => !room.available)
                                                            //     ? "border-orange-600"
                                                            //     : "border-green-600"
                                                            // }`}

                                                            //${'z-[' + (9999 - index) + ']'}
                                                            className={`bg-white border rounded-xl pt-4 mb-5 drop-shadow-md 


                                  ${
                                    item.rooms.length === 0 || !resRequestDisplay(item.propUid, properties) || item.rooms.some((room) => room.selectedRoom === '') // Check if any room has selectedRoom as an empty string
                                      ? 'border-slate-200 brand-text-grey-v2'
                                      : item.rooms.some((room) => !room.available) ||
                                        ((item.vehiclesPropertiesRule === 'none' || item.vehicles === 'none') && !properties[getPropObj(item.propUid, properties)].northernGuide)
                                      ? 'border-orange-600'
                                      : 'border-green-600'
                                  }
                                  
`}
                                                          >
                                                            {/* ROW 1 (Property info) */}

                                                            <div className={`mb-5`}>
                                                              {properties[getPropObj(item.propUid, properties)].bookedDirect && (
                                                                <div
                                                                  className="text-right fixed -top-0.5"
                                                                  style={{
                                                                    WebkitTransform: 'translate(-50%, 0)',
                                                                    left: '50%',
                                                                    perspective: '1000' /* Safari */,
                                                                  }}
                                                                >
                                                                  <span
                                                                    className={`text-[12px] font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5  brand-bg-custom-color-warning text-white rounded-b-lg text-center capitalize`}
                                                                  >
                                                                    Accommodation booked direct
                                                                  </span>
                                                                </div>
                                                              )}
                                                              <div className={`grid grid-cols-12`}>
                                                                {/* IMG */}
                                                                <div className="col-span-1 bg-white">
                                                                  <div className="row-span-1 relative">
                                                                    <img
                                                                      className="ml-4 object-cover w-16 h-16 rounded-full"
                                                                      src={
                                                                        properties[getPropObj(item.propUid, properties)].heroImageUrl
                                                                          ? `${properties[getPropObj(item.propUid, properties)].heroImageUrl}/320x200`
                                                                          : 'https://firebasestorage.googleapis.com/v0/b/nomadhornbill.appspot.com/o/images%2Fproperties%2Fdefault%2Fdefault.png?alt=media&token=60f73247-fa43-4829-9c56-260803159067'
                                                                      }
                                                                      alt={properties[getPropObj(item.propUid, properties)].name || 'Property image'}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                {/* ROOM NAME + DESCRIPTION */}
                                                                <div key={uuidv4()} className="col-span-8 bg-white ml-10 mt-1">
                                                                  <div className="pt-0 flex w-full">
                                                                    <div className="text-xl font-bold  truncate text-ellipsis overflow-hidden pr-3">
                                                                      {properties[getPropObj(item.propUid, properties)].name}
                                                                    </div>
                                                                    <button
                                                                      onClick={() => {
                                                                        console.log('Properties SlideOut button clicked');

                                                                        const property = properties[getPropObj(item.propUid, properties)];
                                                                        const customLocation = customLocations.find((location) => location.uuid === item.id);
                                                                        let parentParkId = customLocation.parentParkId;
                                                                        setSwitchParkId(parentParkId);
                                                                        setSwitchId(item.id);
                                                                        setSwitchStartDate(item.startDay);
                                                                        setSwitchEndDate(item.endDay);
                                                                        setSwitchSlideOut(true);
                                                                      }}
                                                                      className="flex items-center gap-1"
                                                                    >
                                                                      <TbSwitchHorizontal className="text-nomadEvergreen-700" size={20} />
                                                                      <span className="text-nomadEvergreen-700">Switch</span>
                                                                    </button>
                                                                  </div>
                                                                  <div className="mt-1">
                                                                    <div className="text-sm font-normal leading-tight pb-1 brand-text-grey-v2 w-96 truncate overflow-ellipsis overflow-hidden">
                                                                      {(() => {
                                                                        const result = (() => {
                                                                          const property = properties[getPropObj(item.propUid, properties)];
                                                                          const customLocation = customLocations.find((location) => location.propUid === item.propUid);
                                                                          let displayText;
                                                                          let titleText;

                                                                          if (property.mobileLocation && customLocation) {
                                                                            const park = property.customLocations.find((park) => park.parkId === customLocation.parkId);
                                                                            //titleText = displayText;
                                                                            displayText = park ? park.parkName : 'Camp location closed';
                                                                            titleText = displayText;
                                                                          } else {
                                                                            displayText = property.park.name;
                                                                            titleText = property.park.name;
                                                                          }

                                                                          return {
                                                                            displayText,
                                                                            titleText,
                                                                          };
                                                                        })();

                                                                        return (
                                                                          <div className="text-sm font-normal leading-tight pb-1 text-nomadGray-600 w-9/12 overflow-hidden" title={result.titleText}>
                                                                            {result.displayText}
                                                                          </div>
                                                                        );
                                                                      })()}
                                                                    </div>
                                                                  </div>
                                                                  <div className="pb-2 pt-4 flex items-center">
                                                                    <MdDateRange className="inline-block w-5 h-5 brand-text-grey-v2" />
                                                                    <div className="pl-2 text-sm font-normal truncate text-nomadGray-600">
                                                                      {moment(item.startDay).format('ddd, Do MMM YYYY')} - {moment(item.endDay).format('ddd, Do MMM YYYY')}
                                                                    </div>
                                                                  </div>

                                                                  {(item.vehiclesPropertiesRule === 'none' || item.vehicles === 'none') &&
                                                                  item.rooms.length > 0 &&
                                                                  !properties[getPropObj(item.propUid, properties)].northernGuide ? (
                                                                    <div className="pb-2 pt-4 flex items-center">
                                                                      <IoMdWarning size={16} className={`text-orange-600`} />
                                                                      <div className="">
                                                                        <div className="pl-2 text-xs font-normal truncate text-orange-600">No Vehicles available for this rate type.</div>
                                                                        <div className="pl-2 text-xs font-normal truncate text-orange-600">Select new dates or change rate type.</div>
                                                                      </div>
                                                                    </div>
                                                                  ) : (
                                                                    <>
                                                                      {/* New condition 3 */}
                                                                      {(() => {
                                                                        const property = properties[getPropObj(item.propUid, properties)];
                                                                        const supplierName = property.supplier.name;
                                                                        const supplierId = property.supplier.id; //ded3a3ed-aeaf-4495-9069-7754a649de67 // Nomad Tanzania
                                                                        const rateName = item.rateName;
                                                                        if (
                                                                          item.rateName === 'gamePackagePrivate' &&
                                                                          properties[getPropObj(item.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67'
                                                                        ) {
                                                                          //Nomad Tanzania
                                                                          return (
                                                                            <InfoBox
                                                                              icon={PiVanLight}
                                                                              text={`Includes use of a private ${properties[getPropObj(item.propUid, properties)].supplier.name} vehicle and guide`}
                                                                            />
                                                                          );
                                                                        } else if (
                                                                          item.rateName === 'gamePackageShared' &&
                                                                          properties[getPropObj(item.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67' // 'Nomad Tanzania'
                                                                        ) {
                                                                          return (
                                                                            <InfoBox
                                                                              icon={PiVanLight}
                                                                              text={`Includes use of a shared ${properties[getPropObj(item.propUid, properties)].supplier.name} vehicle and
                                      guide`}
                                                                            />
                                                                          );
                                                                        } else if (
                                                                          supplierId === 'ded3a3ed-aeaf-4495-9069-7754a649de67' &&
                                                                          rateName !== 'fullBoard' &&
                                                                          rateName !== 'fullBoardExclusive'
                                                                        ) {
                                                                          return <InfoBox icon={PiVanLight} text="Includes use of a Nomad private vehicle and guide" />;
                                                                        } else if (rateName === 'fullBoardNomad') {
                                                                          return <InfoBox icon={PiVanLight} text="Includes use of a private Nomad vehicle and guide" />;
                                                                        } else if (rateName === 'gamePackageShared') {
                                                                          return (
                                                                            <InfoBox
                                                                              icon={PiVanLight}
                                                                              text={`Includes use of shared ${properties[getPropObj(item.propUid, properties)].supplier.name} vehicle and guide`}
                                                                            />
                                                                          );
                                                                        } else if (rateName === 'gamePackagePrivate') {
                                                                          return (
                                                                            <InfoBox
                                                                              icon={PiVanLight}
                                                                              text={`Includes use of private ${properties[getPropObj(item.propUid, properties)].supplier.name} vehicle and guide`}
                                                                            />
                                                                          );
                                                                        }
                                                                      })()}
                                                                    </>
                                                                  )}

                                                                  <div className={`${item.expanded && 'hidden'}`}>
                                                                    {item.rooms.length === 0 ? (
                                                                      <div className="text-xs text-left leading-5 pt-2 px-2 italic">No rooms added - click add room below</div>
                                                                    ) : (
                                                                      // Rooms with dots
                                                                      Object.entries(
                                                                        item.rooms.reduce((acc, room) => {
                                                                          if (
                                                                            room.roomType !== 'Vehicle' &&
                                                                            (room.roomType !== 'Exclusive' ||
                                                                              properties[getPropObj(item.propUid, properties)].propertyType !== 'private' ||
                                                                              (createEdit === 'edit' && !semver.gt(createVersion, '2.4.0')))
                                                                          ) {
                                                                            const key = room.selectedRoom;
                                                                            if (!acc[key]) {
                                                                              acc[key] = [];
                                                                            }
                                                                            acc[key].push(room);
                                                                          }
                                                                          return acc;
                                                                        }, {}),
                                                                      ).map(([roomType, rooms]) => {
                                                                        const hasUnavailableRooms = rooms.some((room) => !room.available);
                                                                        const hasEmptySelection = rooms.some((room) => room.selectedRoom === '');
                                                                        const allUnavailable = rooms.every((room) => !room.available);

                                                                        const statusColor = resRequestDisplay(item.propUid, properties)
                                                                          ? hasEmptySelection
                                                                            ? 'brand-text-grey-v2'
                                                                            : hasUnavailableRooms && !allUnavailable
                                                                            ? 'text-orange-600'
                                                                            : allUnavailable
                                                                            ? 'text-orange-600'
                                                                            : 'text-green-600'
                                                                          : 'text-nomadGray-600';

                                                                        return (
                                                                          <div key={uuidv4()} className="flex flex-col">
                                                                            <div className="flex-1">
                                                                              <span className="inline-flex items-center">
                                                                                <span className={`text-xs font-semibold ${statusColor}`}>
                                                                                  {rooms[0].selectedName}
                                                                                  <span className="ml-1">{rooms.length > 0 ? ` (x${rooms.length})` : ''}</span>
                                                                                </span>
                                                                                {hasUnavailableRooms &&
                                                                                  !allUnavailable &&
                                                                                  resRequestDisplay(item.propUid, properties) &&
                                                                                  rooms.map((room, index) => (
                                                                                    <span key={index} className={`ml-1 h-2 w-2 rounded-full ${room.available ? 'bg-green-600' : 'bg-orange-600'}`} />
                                                                                  ))}
                                                                                {hasUnavailableRooms && (
                                                                                  <div className="ml-2">
                                                                                    <IoMdWarning size={16} className={statusColor} />
                                                                                  </div>
                                                                                )}
                                                                              </span>
                                                                            </div>
                                                                            {/* Adults and children - start */}
                                                                            <div className="flex-1">
                                                                              <span className="capitalize text-xs font-normal text-nomadGray-600 ml-1">
                                                                                {rooms.reduce((sum, room) => sum + room.ad, 0)} Adult
                                                                                {rooms.reduce((sum, room) => sum + room.ad, 0) > 1 && 's'}
                                                                                {', '}
                                                                              </span>{' '}
                                                                              <span className="capitalize text-xs font-normal text-nomadGray-600 ml-1">
                                                                                {rooms.reduce((sum, room) => sum + room.ch + room.chi + room.inf, 0)} Child
                                                                                {rooms.reduce((sum, room) => sum + room.ch + room.chi + room.inf, 0) !== 1 && 'ren'}
                                                                              </span>
                                                                            </div>
                                                                            {/* Adults and children - end */}
                                                                          </div>
                                                                        );
                                                                      })
                                                                      // Object.entries(
                                                                      //   item.rooms.reduce((acc, room) => {
                                                                      //     if (
                                                                      //       room.roomType !== 'Vehicle' &&
                                                                      //       (room.roomType !== 'Exclusive' ||
                                                                      //         properties[getPropObj(item.propUid, properties)].propertyType !== 'private' ||
                                                                      //         (createEdit === 'edit' && !semver.gt(createVersion, '2.4.0')))
                                                                      //     ) {
                                                                      //       const key = room.selectedRoom;
                                                                      //       if (!acc[key]) {
                                                                      //         acc[key] = [];
                                                                      //       }
                                                                      //       acc[key].push(room);
                                                                      //     }
                                                                      //     return acc;
                                                                      //   }, {}),
                                                                      // ).map(([roomType, rooms]) => (
                                                                      //   <div key={uuidv4()} className="flex flex-col">
                                                                      //     <div className="flex-1">
                                                                      //       <span className="inline-flex items-center">
                                                                      //         {resRequestDisplay(item.propUid, properties) ? (
                                                                      //           <span
                                                                      //             className={`capitalize text-xs font-semibold ${
                                                                      //               rooms.some((room) => !room.available)
                                                                      //                 ? rooms.some((room) => room.selectedRoom === '')
                                                                      //                   ? 'brand-text-grey-v2'
                                                                      //                   : 'text-orange-600'
                                                                      //                 : rooms.some((room) => room.selectedRoom === '')
                                                                      //                 ? 'brand-text-grey-v2'
                                                                      //                 : 'text-green-600'
                                                                      //             }`}
                                                                      //           >
                                                                      //             {rooms[0].selectedName}
                                                                      //             <span className="ml-1">{rooms.length > 1 ? ` (x${rooms.length})` : ''}</span>
                                                                      //           </span>
                                                                      //         ) : (
                                                                      //           <span className="capitalize text-xs font-bold text-nomadGray-600">
                                                                      //             {rooms[0].selectedName}
                                                                      //             <span className="ml-1">{rooms.length > 1 ? ` (x${rooms.length})` : ''}</span>
                                                                      //           </span>
                                                                      //         )}
                                                                      //         {rooms.map((room, index) => (
                                                                      //           <span
                                                                      //             key={index}
                                                                      //             className={`ml-1 h-2 w-2 rounded-full ${
                                                                      //               !room.available
                                                                      //                 ? room.selectedRoom === ''
                                                                      //                   ? 'bg-gray-300'
                                                                      //                   : 'bg-orange-600'
                                                                      //                 : room.selectedRoom === ''
                                                                      //                 ? 'bg-gray-300'
                                                                      //                 : 'bg-green-600'
                                                                      //             }`}
                                                                      //           />
                                                                      //         ))}
                                                                      //         {rooms.some((room) => !room.available) && (
                                                                      //           <div className="ml-2">
                                                                      //             <IoMdWarning size={16} className={`${!resRequestDisplay(item.propUid, properties) && 'text-nomadGray-600'}`} />
                                                                      //           </div>
                                                                      //         )}
                                                                      //       </span>
                                                                      //     </div>
                                                                      //     {/* Adults and children - start */}
                                                                      //     <div className="flex-1">
                                                                      //       <span className="capitalize text-xs font-normal text-nomadGray-600 ml-1">
                                                                      //         {rooms.reduce((sum, room) => sum + room.ad, 0)} Adult
                                                                      //         {rooms.reduce((sum, room) => sum + room.ad, 0) > 1 && 's'}
                                                                      //         {', '}
                                                                      //       </span>{' '}
                                                                      //       <span className="capitalize text-xs font-normal text-nomadGray-600 ml-1">
                                                                      //         {rooms.reduce((sum, room) => sum + room.ch + room.chi + room.inf, 0)} Child
                                                                      //         {rooms.reduce((sum, room) => sum + room.ch + room.chi + room.inf, 0) !== 1 && 'ren'}
                                                                      //       </span>
                                                                      //     </div>
                                                                      //     {/* Adults and children - end */}
                                                                      //   </div>
                                                                      // ))

                                                                      // Object.keys(
                                                                      //   item.rooms.reduce((acc, room) => {
                                                                      //     //if (room.roomType !== 'Vehicle'  && room.roomType !== 'Exclusive') {
                                                                      //     if (
                                                                      //       room.roomType !== 'Vehicle' &&
                                                                      //       (room.roomType !== 'Exclusive' ||
                                                                      //         properties[getPropObj(item.propUid, properties)].propertyType !== 'private' ||
                                                                      //         (createEdit === 'edit' && !semver.gt(createVersion, '2.4.0')))
                                                                      //     ) {
                                                                      //       if (acc[room.selectedRoom]) {
                                                                      //         acc[room.selectedRoom] += 1;
                                                                      //       } else {
                                                                      //         acc[room.selectedRoom] = 1;
                                                                      //       }
                                                                      //     }
                                                                      //     return acc;
                                                                      //   }, {}),
                                                                      // ).map((roomType) => (
                                                                      //   <div key={uuidv4()} className="flex flex-col">
                                                                      //     <div className="flex-1">
                                                                      //       <span
                                                                      //         className={`inline-flex items-center ${
                                                                      //           item.rooms.some((room) => room.selectedRoom === roomType && !room.available)
                                                                      //             ? item.rooms.some((room) => room.selectedRoom === '')
                                                                      //               ? 'brand-text-grey-v2'
                                                                      //               : 'text-orange-600'
                                                                      //             : item.rooms.some((room) => room.selectedRoom === '')
                                                                      //             ? 'brand-text-grey-v2'
                                                                      //             : 'text-green-600'
                                                                      //         }`}
                                                                      //       >
                                                                      //         {resRequestDisplay(item.propUid, properties) ? (
                                                                      //           <>
                                                                      //             <span className={`capitalize text-xs font-semibold`}>
                                                                      //               {item.rooms.find((room) => room.selectedRoom === roomType).selectedName}
                                                                      //             </span>

                                                                      //             <span className={`text-xs font-semibold ml-1`}>
                                                                      //               {' (x'}
                                                                      //               {item.rooms.reduce((acc, room) => {
                                                                      //                 if (room.selectedRoom === roomType) {
                                                                      //                   return acc + 1;
                                                                      //                 }
                                                                      //                 return acc;
                                                                      //               }, 0)}
                                                                      //               {') '}
                                                                      //             </span>
                                                                      //           </>
                                                                      //         ) : (
                                                                      //           <>
                                                                      //             <span className={`capitalize text-xs font-bold text-nomadGray-600`}>
                                                                      //               {item.rooms.find((room) => room.selectedRoom === roomType).selectedName}
                                                                      //             </span>

                                                                      //             <span className={`capitalize text-xs font-bold ml-1 text-nomadGray-600`}>
                                                                      //               {' (*'}
                                                                      //               {item.rooms.reduce((acc, room) => {
                                                                      //                 if (room.selectedRoom === roomType) {
                                                                      //                   return acc + 1;
                                                                      //                 }
                                                                      //                 return acc;
                                                                      //               }, 0)}
                                                                      //               {') '}
                                                                      //             </span>
                                                                      //           </>
                                                                      //         )}
                                                                      //         {item.rooms.some((room) => room.selectedRoom === roomType && !room.available) && (
                                                                      //           <div className="ml-2">
                                                                      //             <IoMdWarning size={16} className={`${!resRequestDisplay(item.propUid, properties) && 'text-nomadGray-600'}`} />
                                                                      //           </div>
                                                                      //         )}
                                                                      //       </span>
                                                                      //     </div>
                                                                      //     {/* Adults and children - start */}
                                                                      //     <div className="flex-1">
                                                                      //       <span className={`capitalize text-xs font-normal text-nomadGray-600 ml-1`}>
                                                                      //         {item.rooms.reduce((acc, room) => {
                                                                      //           if (room.selectedRoom === roomType) {
                                                                      //             return acc + room.ad;
                                                                      //           }
                                                                      //           return acc;
                                                                      //         }, 0)}{' '}
                                                                      //         Adult
                                                                      //         {item.rooms.reduce((acc, room) => {
                                                                      //           if (room.selectedRoom === roomType) {
                                                                      //             return acc + room.ad;
                                                                      //           }
                                                                      //           return acc;
                                                                      //         }, 0) > 1 && 's'}
                                                                      //         {', '}
                                                                      //       </span>{' '}
                                                                      //       <span className={`capitalize text-xs font-normal text-nomadGray-600 ml-1`}>
                                                                      //         {item.rooms.reduce((acc, room) => {
                                                                      //           if (room.selectedRoom === roomType) {
                                                                      //             return acc + room.ch + room.chi + room.inf;
                                                                      //           }
                                                                      //           return acc;
                                                                      //         }, 0)}{' '}
                                                                      //         Child
                                                                      //         {item.rooms.reduce((acc, room) => {
                                                                      //           if (room.selectedRoom === roomType) {
                                                                      //             return acc + room.ch + room.chi + room.inf;
                                                                      //           }
                                                                      //           return acc;
                                                                      //         }, 0) > 1 && 'ren'}
                                                                      //         {item.rooms.reduce((acc, room) => {
                                                                      //           if (room.selectedRoom === roomType) {
                                                                      //             return acc + room.ch + room.chi + room.inf;
                                                                      //           }
                                                                      //           return acc;
                                                                      //         }, 0) === 0 && 'ren'}
                                                                      //       </span>
                                                                      //     </div>
                                                                      //     {/* Adults and children - end */}
                                                                      //   </div>
                                                                      // ))
                                                                    )}
                                                                  </div>
                                                                </div>
                                                                {/* DATES & CLOSE BTN */}
                                                                <div className="col-span-3 bg-white ">
                                                                  <div className="float-right text-right mr-4">
                                                                    <div className="ml-auto flex justify-end mb-2">
                                                                      <div
                                                                        className="text-right"
                                                                        style={{
                                                                          WebkitTransform: 'translateZ(0)',
                                                                          perspective: '1000' /* Safari */,
                                                                        }}
                                                                      >
                                                                        {item.rooms.length > 0 && (
                                                                          <span
                                                                            className={`text-xs font-semibold
truncate text-ellipsis overflow-hidden
px-3 py-1.5
rounded-full
shadow-sm
text-right
${
  !resRequestDisplay(item.propUid, properties)
    ? 'bg-slate-200 brand-text-grey-v2'
    : item.rooms.some((room) => room.selectedRoom === '') // Check if any room has selectedRoom as an empty string
    ? 'bg-slate-200 brand-text-grey-v2'
    : item.rooms.some((room) => !room.available) || ((item.vehiclesPropertiesRule === 'none' || item.vehicles === 'none') && !properties[getPropObj(item.propUid, properties)].northernGuide)
    ? 'bg-[#e67e22] bg-opacity-30 text-[#774d1c]'
    : 'bg-[#8cb560] bg-opacity-30 text-[#21574a]'
}
`}
                                                                          >
                                                                            {/* {!resRequestDisplay(item.propUid, properties)
                                                  ? 'On request'
                                                  : item.rooms.some((room) => !room.available) ||
                                                    ((item.vehiclesPropertiesRule === 'none' || item.vehicles === 'none') &&
                                                      !properties[getPropObj(item.propUid, properties)].northernGuide)
                                                  ? 'Unavailable'
                                                  : 'Available'} */}
                                                                            {!resRequestDisplay(item.propUid, properties)
                                                                              ? 'On request'
                                                                              : item.rooms.some((room) => room.selectedRoom === '') // Check if any room has selectedRoom as an empty string
                                                                              ? 'On request'
                                                                              : item.rooms.some((room) => !room.available) ||
                                                                                ((item.vehiclesPropertiesRule === 'none' || item.vehicles === 'none') &&
                                                                                  !properties[getPropObj(item.propUid, properties)].northernGuide)
                                                                              ? 'Unavailable'
                                                                              : 'Available'}
                                                                          </span>
                                                                        )}
                                                                      </div>{' '}
                                                                      {advancedMode && (
                                                                        <button
                                                                          className="pl-3"
                                                                          onClick={async () => {
                                                                            // First, update the item's remove property in the state
                                                                            setBookings((currentBookings) => {
                                                                              return currentBookings.map((booking) => {
                                                                                if (booking.id === item.id) {
                                                                                  return { ...booking, remove: true };
                                                                                }
                                                                                return booking;
                                                                              });
                                                                            });

                                                                            // Ensure the state update has been processed
                                                                            await new Promise((resolve) => setTimeout(resolve, 0));

                                                                            // Then, call removeProperty
                                                                            await removeProperty(
                                                                              item.id,
                                                                              item.propUid,
                                                                              getPropObj(item.propUid, properties),
                                                                              rooms,
                                                                              setRooms,
                                                                              bookings,
                                                                              setBookings,
                                                                              realDate,
                                                                              setLoadingData,
                                                                              properties,
                                                                              setRefreshBtn,
                                                                              setRefreshRatesEffect,
                                                                              user,
                                                                            );
                                                                            setFetchCustom(true);
                                                                          }}
                                                                        >
                                                                          <RiCloseCircleLine className="brand-text-color-300" size={20} />
                                                                        </button>
                                                                      )}
                                                                    </div>
                                                                    {/* Property Rates */}
                                                                    <div className="flex items-center text-xs mt-5 mb-2 font-normal">
                                                                      <Select
                                                                        value={{
                                                                          value: currentOptionExists
                                                                            ? item.rateName && item.creatioUid
                                                                              ? `${item.rateName}#RT#${item.creatioUid}`
                                                                              : item.rateName
                                                                            : 'select',
                                                                          label: currentOptionExists
                                                                            ? item.rateName && propertiesObj
                                                                              ? propertiesObj.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67'
                                                                                ? rateLabelMap[item.rateName]
                                                                                : propertiesObj.thirdPartyRates.find(
                                                                                    (rateObj) => rateObj.type === item.rateName && (!item.creatioUid || rateObj.creatioUid === item.creatioUid),
                                                                                  )?.name
                                                                              : 'Please select'
                                                                            : 'Please select',
                                                                        }}
                                                                        maxMenuHeight={120}
                                                                        styles={{
                                                                          control: (provided) => ({
                                                                            ...provided,
                                                                            height: '36px',
                                                                            borderRadius: '8px',
                                                                            border: '2px solid #2A99A3',
                                                                            boxShadow: 'none',
                                                                            width: '200px',
                                                                            marginRight: '10px',
                                                                          }),
                                                                          option: (provided, state) => ({
                                                                            ...provided,
                                                                            color: state.isSelected ? 'white' : '#666',
                                                                            backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                                                                            ':hover': {
                                                                              backgroundColor: state.isSelected ? '#2A99A3' : '#F5F5F5',
                                                                            },
                                                                          }),
                                                                        }}
                                                                        options={
                                                                          properties[getPropObj(item.propUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' &&
                                                                          properties[getPropObj(item.propUid, properties)].platformResRequest.rates // NOMAD
                                                                            ? Object.keys(properties[getPropObj(item.propUid, properties)].rates)
                                                                                .filter((key) => properties[getPropObj(item.propUid, properties)].rates[key] === true)
                                                                                .map((key) => ({
                                                                                  value: key,
                                                                                  label: rateLabelMap[key],
                                                                                }))
                                                                            : properties[getPropObj(item.propUid, properties)].thirdPartyRates
                                                                                .filter((rateObj) => rateObj.active === true)
                                                                                .map((rateObj) => ({
                                                                                  value: rateObj.creatioUid ? `${rateObj.type}#RT#${rateObj.creatioUid}` : rateObj.type,
                                                                                  label: rateObj.name,
                                                                                  creatioUid: rateObj.creatioUid,
                                                                                  karaniId: rateObj.karaniId,
                                                                                }))
                                                                        }
                                                                        isSearchable={false}
                                                                        className="react-select-container"
                                                                        classNamePrefix="react-select"
                                                                        //isDisabled={user.internalUser === false && user.fullBoardOnly === true}
                                                                        onChange={(option) => {
                                                                          if (option.value === 'select') {
                                                                            return toast.error(`You must select a rate type`);
                                                                          } else {
                                                                            // alert(option.value);
                                                                          }

                                                                          const [selectedRateName, selectedCreationUid] = option.value.split('#RT#');
                                                                          item.rateName = selectedRateName;
                                                                          item.rateCreatioUid = option.creatioUid;
                                                                          item.rateKaraniId = option.karaniId;
                                                                          item.rateFullName = option.label;

                                                                          console.log('selectedRateName: ', selectedRateName);
                                                                          if (selectedCreationUid) {
                                                                            item.creatioUid = selectedCreationUid;
                                                                          }

                                                                          if (bookings.some((booking) => booking.rooms.length !== 0)) {
                                                                            setRefreshAllRates(true);
                                                                          }
                                                                          setBookings((bookings) => [...bookings]);

                                                                          if (item.rooms.length > 0) {
                                                                            item.refresh = true;
                                                                          }

                                                                          // remove bookings if rate type is changed
                                                                          filterVehicleRoom(item.id, bookings, setBookings);
                                                                          filterVehicleTransfers(item, transfers, setTransfers, transfersLatest, setTransfersLatest);
                                                                        }}
                                                                      />

                                                                      {/* Nights */}
                                                                      <Select
                                                                        value={{
                                                                          value: item.days,
                                                                          label: item.days === 0 ? 'Day Room' : `${item.days} Night${item.days > 1 ? 's' : ''}`,
                                                                        }}
                                                                        onChange={async (option) => {
                                                                          setFetchCustom(true);
                                                                          const value = option.value;
                                                                          console.log(value);

                                                                          incDecBookingNights(value, bookings, item.id, setBookings, properties, setRefreshRatesEffect, setRefreshBtn, user);
                                                                          reCountBookings(bookings, setBookings, dateStart, rooms, setRooms, properties, setRefreshRatesEffect, false);
                                                                          item.refresh = true;
                                                                          if (bookings.some((booking) => booking.rooms.length !== 0)) {
                                                                            setRefreshAllRates(true);
                                                                          }

                                                                          setBookings((bookings) => [...bookings]);
                                                                          //}
                                                                        }}
                                                                        options={[
                                                                          ...(properties[getPropObj(item.propUid, properties)].dayRoomAvailable ? [{ value: 0, label: 'Day Room' }] : []),
                                                                          ...Array.from(
                                                                            {
                                                                              length: 99,
                                                                            },
                                                                            (_, i) => i + item.minNights,
                                                                          ).map((count) => ({
                                                                            value: count,
                                                                            label: `${count} ${count === 1 ? 'Night' : 'Nights'}`,
                                                                          })),
                                                                        ]}
                                                                        className="react-select-container"
                                                                        classNamePrefix="react-select"
                                                                        menuPortalTarget={document.body} // render menu as a child of the body element
                                                                        maxMenuHeight={140}
                                                                        styles={{
                                                                          control: (provided) => ({
                                                                            ...provided,
                                                                            height: '36px',
                                                                            borderRadius: '8px',
                                                                            border: '2px solid #2A99A3',
                                                                            boxShadow: 'none',
                                                                            width: '125px',
                                                                          }),
                                                                          option: (provided, state) => ({
                                                                            ...provided,
                                                                            fontSize: '12px',
                                                                            color: state.isSelected ? 'white' : '#666',
                                                                            backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                                                                            ':hover': {
                                                                              backgroundColor: state.isSelected ? '#2A99A3' : '#F5F5F5',
                                                                            },
                                                                          }),
                                                                        }}
                                                                        isSearchable={false}
                                                                      />
                                                                      <div className="grid grid-rows-1 grid-flow-col border border-slate-200 rounded-md brand-text-color-v2 font-normal"></div>
                                                                    </div>
                                                                    {!fetchingRates ? (
                                                                      <div className="float-right text-base font-semibold pt-3">
                                                                        <div className="flex items-center group relative">
                                                                          <span className="text-sm font-normal mr-2">Total:</span>
                                                                          <span>
                                                                            $
                                                                            {(() => {
                                                                              const total = getTotalForProperty(item);
                                                                              if (isNaN(total)) {
                                                                                return resRequestDisplay(item.propUid, properties) ? '' : 'NA...';
                                                                              }
                                                                              return formatPrice(total);
                                                                            })()}
                                                                          </span>
                                                                          <InformationCircleIcon
                                                                            className="h-5 w-5 ml-2 text-gray-500 cursor-pointer transition-colors hover:text-gray-700"
                                                                            onClick={() => setOpenPricingModalId(item.id)}
                                                                          />
                                                                        </div>
                                                                        {/* Component to open the quick view of property pricing*/}
                                                                        <PricingSummaryModal
                                                                          isOpen={openPricingModalId === item.id}
                                                                          closeModal={() => setOpenPricingModalId(null)}
                                                                          propertyInfo={item}
                                                                        />

                                                                        <p>
                                                                          {(() => {
                                                                            const total = getTotalForProperty(item);
                                                                            return isNaN(formatPrice(total)) && !resRequestDisplay(item.propUid, properties) ? '' : null;
                                                                          })()}
                                                                        </p>
                                                                      </div>
                                                                    ) : (
                                                                      <div className="float-right text-sm pt-3">{resRequestDisplay(item.propUid, properties) ? 'Total: fetching...' : 'Total: 0'}</div>
                                                                    )}
                                                                  </div>
                                                                  {refreshAllRates && bookings.some((booking) => booking.rooms.length !== 0) && (
                                                                    <></>
                                                                    // <div
                                                                    //   className="float-right text-sm pr-4 pt-1 animate-pulse cursor-pointer"
                                                                    //   onClick={() => {

                                                                    //   }}
                                                                    // >
                                                                    //   Click to refresh rates
                                                                    // </div>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {/* ROW 2 (Expanded room selection area) */}

                                                            <div className={`${!item.expanded && 'hidden'}`}>
                                                              <div className={`grid grid-cols-1 mb-4`}>
                                                                <div className={`text-lg font-normal mx-4 py-2 flex justify-between`}>
                                                                  <div>
                                                                    {properties[getPropObj(item.propUid, properties)].bookedDirect ? 'Pax' : 'Rooms'} {checkGuestUsage(item, guests)}
                                                                  </div>
                                                                </div>
                                                                {item.rooms.length !== 0 && (
                                                                  <div
                                                                    className={`col-span-1 rounded-md border mx-4 mb-5 ${
                                                                      item.rooms.some((room) => room.edit === true) ? 'brand-edit-room-bg-color-v2 border-2 border-dashed' : ''
                                                                    }`}
                                                                  >
                                                                    {item.rooms.length === 0 ? (
                                                                      <div className="text-xs text-left leading-5 px-2">No rooms added - click add room below</div>
                                                                    ) : (
                                                                      item.rooms
                                                                        .filter(
                                                                          (room) =>
                                                                            room.roomType !== 'Vehicle' &&
                                                                            (room.roomType !== 'Exclusive' ||
                                                                              properties[getPropObj(item.propUid, properties)].propertyType !== 'private' ||
                                                                              (createEdit === 'edit' && !semver.gt(createVersion, '2.4.0'))),
                                                                        )
                                                                        .map((room, roomIndex) => (
                                                                          <div key={uuidv4()}>
                                                                            {!room.edit ? (
                                                                              <div className={`grid grid-cols-12`}>
                                                                                <div className="col-span-10 text-left">
                                                                                  <div className="flex">
                                                                                    <div className="w-20 mr-4">
                                                                                      <img
                                                                                        className={`object-cover h-20 w-20 ${roomIndex === 0 && 'rounded-tl-md'} ${
                                                                                          roomIndex === item.rooms.length - 1 && 'rounded-bl-md'
                                                                                        }`}
                                                                                        src={getRoomImg(getPropObj(item.propUid, properties), room.selectedRoom, properties)}
                                                                                        alt={room.selectedName}
                                                                                      />
                                                                                    </div>
                                                                                    <div>
                                                                                      {resRequestDisplay(item.propUid, properties) ? (
                                                                                        <div
                                                                                          className={`text-sm font-medium pt-4 pb-2  ${
                                                                                            room.selectedRoom === ''
                                                                                              ? 'brand-text-grey-v2'
                                                                                              : (!room.available && 'text-orange-600') || (room.available && 'text-green-600')
                                                                                          }`}
                                                                                        >
                                                                                          Room {roomIndex + 1}:{' '}
                                                                                          <div className="inline-flex items-center">
                                                                                            <span className="capitalize">{room.selectedName}</span>
                                                                                            {!room.available && (
                                                                                              <div className="ml-2">
                                                                                                <IoMdWarning size={20} />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      ) : (
                                                                                        <div className={`text-sm font-medium pt-4 brand-text-grey-v2`}>
                                                                                          Room {roomIndex + 1}:{' '}
                                                                                          <div className="inline-flex items-center">
                                                                                            <span className="capitalize">{room.selectedName}</span>
                                                                                            {!room.available && (
                                                                                              <div className="ml-2">
                                                                                                <IoMdWarning size={20} className="brand-text-grey-v2" />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}

                                                                                      <div className="text-xs font-normal">
                                                                                        {room.ad} Adult
                                                                                        {room.ad > 1 && 's'}, {room.ch + room.chi + room.inf} Child
                                                                                        {room.ch + room.chi + room.inf > 1 && 'ren'}
                                                                                        {room.ch + room.chi + room.inf === 0 && 'ren'}
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>

                                                                                <div className="col-span-2 pt-5">
                                                                                  <div className={`grid grid-cols-12 pr-4 `}>
                                                                                    <div className="col-span-4 text-center"></div>

                                                                                    {!bookings.some((booking) => booking.rooms.length > 0 && booking.rooms.some((room) => room.edit)) &&
                                                                                      !item.addRoom &&
                                                                                      !item.guestsMerged.childAges.includes(-1) && (
                                                                                        <div className={`col-span-4 text-center`}>
                                                                                          <button
                                                                                            onClick={async () => {
                                                                                              // setBookings((bookings) => [...bookings]);
                                                                                              item.addRoom = false;
                                                                                              item.rooms.forEach((room) => {
                                                                                                room.edit = false;
                                                                                              });
                                                                                              room.edit = true;
                                                                                              setSelectedAges(room.selectedAges);
                                                                                              setSelectedAgesAdults(room.selectedAgesAdults);
                                                                                              setBookings((bookings) => [...bookings]);
                                                                                              // !console.log(room.edit);
                                                                                              setTempAd(room.ad);
                                                                                              setTempCh(room.ch);
                                                                                              if (room.roomConfig === 'Other') {
                                                                                                item.selectedRoomConfig = 'Other';
                                                                                                item.customRoomConfig = room.customRoomConfig;
                                                                                              }
                                                                                            }}
                                                                                          >
                                                                                            <MdOutlineModeEdit
                                                                                              size={20}
                                                                                              className={`cursor-default brand-text-grey-v2 ${
                                                                                                room.selectedAges.includes(-1) ? 'text-red-600' : 'brand-text-grey-v2'
                                                                                              }`}
                                                                                            />
                                                                                          </button>
                                                                                        </div>
                                                                                      )}

                                                                                    {item.addRoom && <div className={`col-span-4 text-center`}></div>}

                                                                                    {!bookings.some((booking) => booking.rooms.length > 0 && booking.rooms.some((room) => room.edit)) &&
                                                                                      !item.addRoom &&
                                                                                      !item.guestsMerged.childAges.includes(-1) && (
                                                                                        <div className="col-span-4 text-center">
                                                                                          {' '}
                                                                                          <button
                                                                                            onClick={async () => {
                                                                                              await removeRoom(
                                                                                                roomIndex,
                                                                                                item.id,
                                                                                                room.selectedRoom,
                                                                                                bookings,
                                                                                                setBookings,
                                                                                                realDate,
                                                                                                rooms,
                                                                                                setRooms,
                                                                                                setRefreshBtn,
                                                                                                properties,
                                                                                                setRefreshRatesEffect,
                                                                                              );
                                                                                              await reCountBookings(
                                                                                                bookings,
                                                                                                setBookings,
                                                                                                dateStart,
                                                                                                rooms,
                                                                                                setRooms,
                                                                                                properties,
                                                                                                setRefreshRatesEffect,
                                                                                                true,
                                                                                              );
                                                                                              setRefreshAllRates(true);
                                                                                              setSelectedAges([]);
                                                                                              setSelectedAgesAdults([]);
                                                                                              item.selectedRoomConfig = '';
                                                                                              item.customRoomConfig = '';
                                                                                              setBookings((bookings) => [...bookings]);
                                                                                            }}
                                                                                          >
                                                                                            <FaRegTrashAlt size={20} className="cursor-default brand-text-grey-v2" />
                                                                                          </button>
                                                                                        </div>
                                                                                      )}
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            ) : (
                                                                              <div className="col-span-1 text-left align-left min-h-[28rem] flex flex-col overflow-y-auto">
                                                                                <div className="flex">
                                                                                  <div className={`text-sm brand-text-color-800 font-bold p-2`}>
                                                                                    Edit {properties[getPropObj(item.propUid, properties)].bookedDirect ? 'pax' : 'room'}
                                                                                  </div>
                                                                                  <div className="ml-auto text-center p-3">
                                                                                    {' '}
                                                                                    <button
                                                                                      onClick={async () => {
                                                                                        await removeRoom(
                                                                                          roomIndex,
                                                                                          item.id,
                                                                                          room.selectedRoom,
                                                                                          bookings,
                                                                                          setBookings,
                                                                                          realDate,
                                                                                          rooms,
                                                                                          setRooms,
                                                                                          setRefreshBtn,
                                                                                          properties,
                                                                                          setRefreshRatesEffect,
                                                                                        );
                                                                                        await reCountBookings(
                                                                                          bookings,
                                                                                          setBookings,
                                                                                          dateStart,
                                                                                          rooms,
                                                                                          setRooms,
                                                                                          properties,
                                                                                          setRefreshRatesEffect,
                                                                                          true,
                                                                                        );
                                                                                        setRefreshAllRates(true);
                                                                                        setSelectedAges([]);
                                                                                        setSelectedAgesAdults([]);
                                                                                        item.selectedRoomConfig = '';
                                                                                        item.customRoomConfig = '';
                                                                                        setBookings((bookings) => [...bookings]);
                                                                                      }}
                                                                                    >
                                                                                      <FaRegTrashAlt size={20} className="cursor-default brand-text-grey-v2" />
                                                                                    </button>
                                                                                  </div>
                                                                                </div>
                                                                                <>
                                                                                  <div className="row-2 grid grid-cols-12">
                                                                                    {/* Room select */}
                                                                                    <div className="col-span-3">
                                                                                      <div className="px-2 pt-5">
                                                                                        {/* {properties[item.propNum].platformResRequest && ( */}

                                                                                        <CustomSelectRooms
                                                                                          //value={room.selectedRoom}
                                                                                          value={{ label: room.selectedName, value: room.selectedRoom }}
                                                                                          //defaultValue={{ label: 'Select room type', value: 'Select room type' }}
                                                                                          onChange={(e) => {
                                                                                            // !console.log("ROOM SELECTED", e.target.value);
                                                                                            room.selectedRoom = e.value;
                                                                                            room.selectedName = e.name;
                                                                                            room.roomType = getRoomType(properties[getPropObj(item.propUid, properties)].rooms, e.value);

                                                                                            console.log('item.propertyType', item.propertyType);
                                                                                            room.ad = 0;
                                                                                            room.ch = 0;
                                                                                            room.chi = 0;
                                                                                            room.inf = 0;
                                                                                            room.roomConfig = '';
                                                                                            room.selectedAges = [];
                                                                                            room.selectedAgesAdults = [];
                                                                                            setSelectedAges([]);
                                                                                            setSelectedAgesAdults([]);
                                                                                            setBookings((bookings) => [...bookings]);
                                                                                          }}
                                                                                          options={properties[getPropObj(item.propUid, properties)].rooms
                                                                                            // .filter(
                                                                                            //   (res) =>
                                                                                            //     (properties[getPropObj(item.propUid, properties)].propertyType === 'private'
                                                                                            //       ? res.type === 'Room' ||
                                                                                            //         res.type === 'Guide/Pilot Bed' ||
                                                                                            //         (res.type === 'Exclusive' && createEdit === 'edit' && !semver.gt(createVersion, '2.4.0'))
                                                                                            //       : res.type === 'Exclusive' || res.type === 'Room' || res.type === 'Guide/Pilot Bed') &&
                                                                                            //     res.active &&
                                                                                            //     ((item.days === 0 && res.dayRoom === true) ||
                                                                                            //       (item.days > 0 && (res.dayRoom !== true || res.overnight === true))) &&
                                                                                            //     (user.internalUser || !res.hideExternalUsers),
                                                                                            // )
                                                                                            .filter((res) => {
                                                                                              const hasGuide = item.guestsMerged.adultAges.some((adult) => adult.isGuide === true);

                                                                                              return (
                                                                                                (properties[getPropObj(item.propUid, properties)].propertyType === 'private'
                                                                                                  ? res.type === 'Room' ||
                                                                                                    (res.type === 'Guide/Pilot Bed' && hasGuide) ||
                                                                                                    (res.type === 'Exclusive' && createEdit === 'edit' && !semver.gt(createVersion, '2.4.0'))
                                                                                                  : res.type === 'Exclusive' || res.type === 'Room' || (res.type === 'Guide/Pilot Bed' && hasGuide)) &&
                                                                                                res.active &&
                                                                                                ((item.days === 0 && res.dayRoom === true) ||
                                                                                                  (item.days > 0 && (res.dayRoom !== true || res.overnight === true))) &&
                                                                                                (user.internalUser || !res.hideExternalUsers)
                                                                                              );
                                                                                            })
                                                                                            .sort((a, b) => {
                                                                                              const order = {
                                                                                                Room: 1,
                                                                                                'Guide/Pilot Bed': 2,
                                                                                              };
                                                                                              return order[a.type] - order[b.type];
                                                                                            })
                                                                                            .map((res) => ({
                                                                                              label: `${res.name} ${getRoomAvailability(res.id, item, rooms)}`,
                                                                                              value: res.id,
                                                                                              name: res.name,
                                                                                            }))}
                                                                                          // placeholder="Select room type"
                                                                                          instanceId="room-select"
                                                                                          isSearchable={false}
                                                                                          showDropdownIndicator={true}
                                                                                          height={30}
                                                                                          color1={'#6996A0'}
                                                                                          color2={'#E5E7EB'}
                                                                                        />
                                                                                      </div>
                                                                                    </div>

                                                                                    {/* Room select End */}

                                                                                    {/* Adults add/remove */}
                                                                                    <div className="col-span-3 px-2">
                                                                                      <div className="text-xs mb-1">Adults</div>
                                                                                      <div className="grid grid-rows-1 grid-flow-col bg-white border-2 border-slate-200 rounded-lg py-0.5 h-9">
                                                                                        <div className="px-2 text-xs font-normal text-center align-middle leading-6">{room.ad}</div>
                                                                                        <button
                                                                                          className="border-l border-slate-200"
                                                                                          onClick={() => {
                                                                                            const adultAges = item.guestsMerged.adultAges;

                                                                                            // Calculate UUID counts from existing bookings excluding the current room
                                                                                            const existingBookingsUuids = bookings
                                                                                              .find((booking) => booking.id === item.id)
                                                                                              ?.rooms.flatMap((room) => room.selectedAgesAdults || [])
                                                                                              .reduce((acc, selectedAdult) => {
                                                                                                acc[selectedAdult.uuid] = (acc[selectedAdult.uuid] || 0) + 1;
                                                                                                return acc;
                                                                                              }, {});

                                                                                            // Combine UUIDs from existingBookingsUuids and selectedAgesAdults
                                                                                            const combinedUuids = { ...existingBookingsUuids };
                                                                                            selectedAgesAdults.forEach((adult) => {
                                                                                              if (adult.uuid) {
                                                                                                combinedUuids[adult.uuid] = (combinedUuids[adult.uuid] || 0) + 1;
                                                                                              }
                                                                                            });

                                                                                            // Determine the oldest available adult
                                                                                            const ageOptions = adultAges.filter((adult) => !combinedUuids[adult.uuid]).sort((a, b) => b.age - a.age);

                                                                                            const totalGuests = room.ad + room.ch + room.chi + room.inf;
                                                                                            const roomDetails = getRoomDetails(item.propUid, room.selectedRoom, properties);

                                                                                            // Check if there are any available adults left to be assigned
                                                                                            const areThereAvailableAdults = ageOptions.length > 0;

                                                                                            if (areThereAvailableAdults && room.ad < roomDetails.max_adults && totalGuests < roomDetails.max_capacity) {
                                                                                              const oldestAvailableAdult = ageOptions[0];

                                                                                              // Automatically assign the oldest available adult
                                                                                              handleAdultAgeChangeRoom(
                                                                                                item.id,
                                                                                                room.ad,
                                                                                                { age: oldestAvailableAdult.age, uuid: oldestAvailableAdult.uuid },
                                                                                                setSelectedAgesAdults,
                                                                                              );
                                                                                              //item.customRoomConfig = '';
                                                                                              room.roomConfig = '';
                                                                                              room.ad++;
                                                                                              setBookings((bookings) => [...bookings]);
                                                                                            } else if (!areThereAvailableAdults) {
                                                                                              // Show toast if no more available adults
                                                                                              toast.error("All available adults have been assigned. Select 'Edit guests' above to change PAX");
                                                                                            } else {
                                                                                              // Show toast for max capacity error
                                                                                              toast.error(
                                                                                                `Room has a max capacity of ${roomDetails.max_capacity} ${
                                                                                                  roomDetails.max_capacity > 1 ? 'people' : 'person'
                                                                                                } (${roomDetails.max_adults} adult${roomDetails.max_adults > 1 ? 's' : ''})`,
                                                                                              );
                                                                                            }
                                                                                          }}
                                                                                          // disabled={
                                                                                          //   properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                                          //   getRoomType(properties[getPropObj(item.propUid, properties)].rooms, room.selectedRoom) !== 'Exclusive'
                                                                                          //   //&&
                                                                                          //   //getRoomType(properties[getPropObj(item.propUid, properties)].rooms, room.selectedRoom) !== 'Guide/Pilot Bed'
                                                                                          // }
                                                                                        >
                                                                                          +
                                                                                        </button>
                                                                                      </div>
                                                                                    </div>
                                                                                    {/* Adults add/remove END */}

                                                                                    {/* Children add/remove */}
                                                                                    <div className="col-span-3 px-2">
                                                                                      <div className="text-xs mb-1">
                                                                                        {/* {properties[getPropObj(item.propUid, properties)].supplier.name !== 'Nomad Tanzania' ? 'Children' : 'Child (Age 21-16)'} */}
                                                                                        {properties[getPropObj(item.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67'
                                                                                          ? 'Children'
                                                                                          : 'Children'}{' '}
                                                                                        (0 - {properties[getPropObj(item.propUid, properties)].maxChildAge})
                                                                                      </div>
                                                                                      <div className="grid grid-rows-1 grid-flow-col bg-white border border-slate-200 rounded-lg h-9 py-0.5">
                                                                                        <div className="px-2 text-xs font-normal text-center align-middle leading-6">{room.ch}</div>
                                                                                        <button
                                                                                          className="border-l border-slate-200"
                                                                                          onClick={() => {
                                                                                            // Step 1: Get childAgesLength
                                                                                            const childAges = item.guestsMerged.childAges;
                                                                                            const childAgesLength = childAges.length;

                                                                                            // Step 2: Check the length of all rooms' selectedAges
                                                                                            // let totalSelectedAges = 0;
                                                                                            // item.rooms.forEach((room) => {
                                                                                            //   if (room.roomType !== 'Vehicle') {
                                                                                            //     totalSelectedAges += room.selectedAges.length;
                                                                                            //   }
                                                                                            // });
                                                                                            function calculateTotalSelectedAges(item) {
                                                                                              let totalSelectedAges = 0;
                                                                                              item.rooms.forEach((room) => {
                                                                                                if (room.roomType !== 'Vehicle') {
                                                                                                  totalSelectedAges += room.ch;
                                                                                                }
                                                                                              });
                                                                                              return totalSelectedAges;
                                                                                            }
                                                                                            //totalSelectedAges -= room.ch;

                                                                                            const totalGuests = room.ad + room.ch + room.chi + room.inf;
                                                                                            const roomDetails = getRoomDetails(item.propUid, room.selectedRoom, properties);

                                                                                            // Check if all available children have already been assigned
                                                                                            //if (selectedAges.length >= item.guestsMerged.childAges.length) {
                                                                                            console.log('totalGuests: ', totalGuests);
                                                                                            console.log('totalSelectedAges: ', calculateTotalSelectedAges(item));
                                                                                            console.log('room.ch: ', room.ch);
                                                                                            console.log('childAgesLength: ', childAgesLength);
                                                                                            //if (totalSelectedAges + room.ch >= childAgesLength) {
                                                                                            //if (totalSelectedAges + item.ch < childAgesLength
                                                                                            if (room.ch > selectedAges.length) {
                                                                                              toast.error('Select an age for each child before adding more');
                                                                                            } else if (calculateTotalSelectedAges(item) >= childAgesLength) {
                                                                                              toast.error("All available children have been assigned. Select 'Edit guests' above to change PAX");
                                                                                              // room.ch--;
                                                                                              // setBookings((bookings) => [...bookings]);
                                                                                            } else if (totalGuests < roomDetails.max_capacity) {
                                                                                              // Increment children count
                                                                                              room.ch++;

                                                                                              //item.customRoomConfig = '';
                                                                                              room.roomConfig = '';

                                                                                              setBookings((bookings) => [...bookings]);
                                                                                            } else {
                                                                                              // Show toast error for exceeding room capacity
                                                                                              toast.error(
                                                                                                `Room has a max capacity of ${roomDetails.max_capacity} ${
                                                                                                  roomDetails.max_capacity > 1 ? 'people' : 'person'
                                                                                                } (${roomDetails.max_adults} adult${roomDetails.max_adults > 1 ? 's' : ''})`,
                                                                                              );
                                                                                            }
                                                                                          }}
                                                                                          // disabled={
                                                                                          //   properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                                          //   getRoomType(properties[getPropObj(item.propUid, properties)].rooms, room.selectedRoom) !== 'Exclusive'
                                                                                          //   //&&
                                                                                          //   //getRoomType(properties[getPropObj(item.propUid, properties)].rooms, room.selectedRoom) !== 'Guide/Pilot Bed'
                                                                                          // }
                                                                                        >
                                                                                          +
                                                                                        </button>
                                                                                      </div>
                                                                                    </div>
                                                                                    {/* Children add/remove END */}

                                                                                    {/* Room config */}
                                                                                    {room.ad + room.ch > 0 && properties[getPropObj(item.propUid, properties)].propertyType !== 'private' ? (
                                                                                      <div className="col-span-3">
                                                                                        <div className="text-xs mb-1 ml-2">Room configuration</div>
                                                                                        <div className="px-2">
                                                                                          <Select
                                                                                            className="mt-1 text-xs"
                                                                                            value={
                                                                                              room.roomConfig
                                                                                                ? {
                                                                                                    label: room.roomConfig,
                                                                                                    value: room.roomConfig,
                                                                                                  }
                                                                                                : null
                                                                                            }
                                                                                            onChange={(selectedOption) => {
                                                                                              console.log(selectedOption.value);
                                                                                              room.roomConfig = selectedOption.value;
                                                                                              setBookings((bookings) => [...bookings]);
                                                                                            }}
                                                                                            options={getConfigurationOptions(room.selectedRoom, room.ad, room.ch, item.propUid, properties)}
                                                                                            //options={getConfigurationOptions(item)}
                                                                                            placeholder="Select"
                                                                                            isSearchable={false}
                                                                                            classNamePrefix="react-select"
                                                                                            menuPortalTarget={document.body}
                                                                                            maxMenuHeight={140}
                                                                                            styles={{
                                                                                              control: (provided) => ({
                                                                                                ...provided,
                                                                                              }),
                                                                                              option: (provided, state) => ({
                                                                                                ...provided,
                                                                                                fontSize: '12px',
                                                                                                color: state.isSelected ? 'white' : '#666',
                                                                                                backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                                                                                                ':hover': {
                                                                                                  backgroundColor: state.isSelected ? '#2A99A3' : '#F5F5F5',
                                                                                                },
                                                                                              }),
                                                                                            }}
                                                                                          />
                                                                                        </div>
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div className="col-span-3"> </div>
                                                                                    )}
                                                                                    {/* Room config END */}
                                                                                  </div>

                                                                                  <div className="row-2 grid grid-cols-12">
                                                                                    {/* Room select */}
                                                                                    <div className="col-span-3"></div>
                                                                                    <div className="col-span-3"></div>
                                                                                    <div className="col-span-6 mx-2">
                                                                                      {' '}
                                                                                      {room.roomConfig === 'Other' && (
                                                                                        <input
                                                                                          type="text"
                                                                                          className="mt-2 text-xs border border-gray-300 rounded px-2 py-1 w-full h-9"
                                                                                          placeholder="Enter custom config"
                                                                                          defaultValue={item.customRoomConfig || ''}
                                                                                          onChange={(e) => {
                                                                                            item.customRoomConfig = e.target.value;
                                                                                            //setBookings((bookings) => [...bookings]);
                                                                                          }}
                                                                                        />
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                </>
                                                                                {/* TEST2 */}
                                                                                {room.ad > 0 && (
                                                                                  <>
                                                                                    <div className="row-1">
                                                                                      <div className={`text-sm brand-text-color-800 font-normal px-5 pt-5 pb-1`}>Adults:</div>
                                                                                    </div>

                                                                                    <div className="row-2 grid grid-cols-12 ml-5">
                                                                                      {Array.from({ length: room.ad }).map((_, adultIndex) => {
                                                                                        const adultAges = item.guestsMerged.adultAges;

                                                                                        const bookingWithId = bookings.find((booking) => booking.id === item.id);

                                                                                        let existingBookingsUuids = {};
                                                                                        if (bookingWithId) {
                                                                                          existingBookingsUuids = bookingWithId.rooms
                                                                                            .filter((roomItem) => roomItem.uuid !== room.uuid)
                                                                                            .flatMap((roomItem) => roomItem.selectedAgesAdults || [])
                                                                                            .reduce((acc, selectedAdult) => {
                                                                                              acc[selectedAdult.uuid] = (acc[selectedAdult.uuid] || 0) + 1;
                                                                                              return acc;
                                                                                            }, {});
                                                                                        }

                                                                                        // Calculate UUID counts for currently selected ages excluding the current adult
                                                                                        const selectedUuidCounts = selectedAgesAdults.reduce((acc, selectedAdult, index) => {
                                                                                          if (index !== adultIndex && selectedAdult) {
                                                                                            acc[selectedAdult.uuid] = (acc[selectedAdult.uuid] || 0) + 1;
                                                                                          }
                                                                                          return acc;
                                                                                        }, {});

                                                                                        // Filter out UUIDs that are fully used and create options for the Select component
                                                                                        const ageOptions = adultAges
                                                                                          .filter((adult) => {
                                                                                            const isUuidAvailable = !selectedUuidCounts[adult.uuid] && !existingBookingsUuids[adult.uuid];
                                                                                            const isAgeGroupAvailableInRoom = !room.selectedAgesAdults.some(
                                                                                              (roomAdult) => roomAdult.uuid === adult.uuid,
                                                                                            );
                                                                                            return isUuidAvailable && isAgeGroupAvailableInRoom;
                                                                                          })
                                                                                          .map((adult) => ({
                                                                                            value: adult.uuid,
                                                                                            label: isGuideCheck(guests, adult.uuid) ? '(G)' : adult.age === 99 ? 'Ad' : adult.age.toString(),
                                                                                          }));

                                                                                        const selectedAdult = selectedAgesAdults[adultIndex];
                                                                                        const selectedUuid = selectedAdult?.uuid;
                                                                                        const selectedAge = selectedAdult?.age;

                                                                                        const lengthOfExistingBookingsUuids = Object.keys(existingBookingsUuids).length;

                                                                                        console.log('ageOptions adults', ageOptions);
                                                                                        console.log('existingBookingsUuids', existingBookingsUuids);
                                                                                        console.log('existingBookingsUuids.length', existingBookingsUuids.length);
                                                                                        console.log('lengthOfExistingBookingsUuids', lengthOfExistingBookingsUuids);
                                                                                        console.log('selectedUuidCounts', selectedUuidCounts);
                                                                                        console.log('selectedUuidCounts.length', selectedUuidCounts.length);

                                                                                        // return ageOptions.length + lengthOfExistingBookingsUuids > 0 ? (
                                                                                        return ageOptions.length + selectedAgesAdults.length > 0 ? (
                                                                                          <div key={adultIndex} className="col-span-2 px-2 mb-5">
                                                                                            <div className="flex items-center">
                                                                                              <div className="text-xs mb-1">Adult {adultIndex + 1} Age</div>
                                                                                              <RiCloseCircleLine
                                                                                                size={16}
                                                                                                className="ml-1 text-red-600 cursor-pointer"
                                                                                                onClick={() => {
                                                                                                  //item.customRoomConfig = '';
                                                                                                  room.roomConfig = '';
                                                                                                  removeSelectedAgesAdultUuid(
                                                                                                    bookings,
                                                                                                    item.id,
                                                                                                    room.uuid,
                                                                                                    selectedUuid,
                                                                                                    setBookings,
                                                                                                    selectedAgesAdults,
                                                                                                    setSelectedAgesAdults,
                                                                                                  );
                                                                                                }}
                                                                                              />
                                                                                            </div>

                                                                                            <Select
                                                                                              className="border-r border-l border-slate-200 w-[90px]"
                                                                                              options={ageOptions}
                                                                                              value={
                                                                                                selectedUuid
                                                                                                  ? {
                                                                                                      label: isGuideCheck(guests, selectedUuid)
                                                                                                        ? '(G)'
                                                                                                        : selectedAge === 99
                                                                                                        ? 'Ad'
                                                                                                        : selectedAge.toString() || '',
                                                                                                      value: selectedUuid,
                                                                                                    }
                                                                                                  : null
                                                                                              }
                                                                                              onChange={(selectedOption) => {
                                                                                                const selectedAdult = item.guestsMerged.adultAges.find((adult) => adult.uuid === selectedOption.value);
                                                                                                if (selectedAdult) {
                                                                                                  handleAdultAgeChangeRoom(
                                                                                                    item.id,
                                                                                                    adultIndex,
                                                                                                    { age: selectedAdult.age, uuid: selectedAdult.uuid },
                                                                                                    setSelectedAgesAdults,
                                                                                                  );
                                                                                                }
                                                                                              }}
                                                                                              placeholder="-"
                                                                                              menuPortalTarget={document.body}
                                                                                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                                                            />
                                                                                          </div>
                                                                                        ) : (
                                                                                          adultIndex >= ageOptions.length && (
                                                                                            <div key={adultIndex + 1} className="col-span-12 px-2 mb-5 text-sm text-center text-orange-600">
                                                                                              Select 'Edit guests' above to change PAX
                                                                                            </div>
                                                                                          )
                                                                                        );
                                                                                      })}
                                                                                    </div>
                                                                                  </>
                                                                                )}
                                                                                {/* TEST 2a */}
                                                                                {room.ch > 0 && (
                                                                                  <>
                                                                                    <div className="row-1">
                                                                                      <div className={`text-sm brand-text-color-800 font-normal px-5 pb-1 ${item.ad > 0 ? 'pt-0' : 'pt-5'}`}>
                                                                                        Children:
                                                                                      </div>
                                                                                    </div>
                                                                                    <div className="row-2 grid grid-cols-12 ml-5">
                                                                                      {Array.from({ length: room.ch }).map((_, childIndex) => {
                                                                                        const childAges = item.guestsMerged.childAges;

                                                                                        const existingBookingsUuids = bookings
                                                                                          .find((booking) => booking.id === item.id)
                                                                                          ?.rooms.filter((roomItem) => roomItem.uuid !== room.uuid)
                                                                                          .flatMap((roomItem) => roomItem.selectedAges || [])
                                                                                          .reduce((acc, selectedChild) => {
                                                                                            acc[selectedChild.uuid] = (acc[selectedChild.uuid] || 0) + 1;
                                                                                            return acc;
                                                                                          }, {});

                                                                                        // Calculate UUID counts for currently selected ages excluding the current child
                                                                                        const selectedUuidCounts = selectedAges.reduce((acc, selectedChild, index) => {
                                                                                          if (index !== childIndex && selectedChild) {
                                                                                            acc[selectedChild.uuid] = (acc[selectedChild.uuid] || 0) + 1;
                                                                                          }
                                                                                          return acc;
                                                                                        }, {});

                                                                                        // Filter out UUIDs that are fully used and create options for the Select component
                                                                                        const ageOptions = childAges
                                                                                          .filter((child) => {
                                                                                            const isUuidAvailable =
                                                                                              !selectedUuidCounts[child.uuid] &&
                                                                                              (!existingBookingsUuids[child.uuid] ||
                                                                                                room.selectedAges.some((roomChild) => roomChild.uuid === child.uuid));
                                                                                            return isUuidAvailable;
                                                                                          })
                                                                                          .map((child) => ({ value: child.uuid, label: child.age.toString() }));

                                                                                        const selectedChild = selectedAges[childIndex];
                                                                                        const selectedUuid = selectedChild?.uuid;
                                                                                        const selectedAge = selectedChild?.age;

                                                                                        //const lengthOfExistingBookingsUuids = Object.keys(existingBookingsUuids).length;

                                                                                        const lengthOfExistingBookingsUuids = Object.keys(existingBookingsUuids).filter(
                                                                                          (key) => key !== 'undefined',
                                                                                        ).length;

                                                                                        console.log('ageOptions', ageOptions);
                                                                                        console.log('ageOptions.length', ageOptions.length);
                                                                                        console.log('existingBookingsUuids.length child', existingBookingsUuids.length);
                                                                                        console.log('existingBookingsUuids', existingBookingsUuids);
                                                                                        console.log('lengthOfExistingBookingsUuids', lengthOfExistingBookingsUuids);
                                                                                        console.log('selectedUuidCounts', selectedUuidCounts);
                                                                                        console.log('existingBookingsUuids', existingBookingsUuids);
                                                                                        console.log(JSON.stringify(existingBookingsUuids, undefined, 4));

                                                                                        return ageOptions.length > 0 ? (
                                                                                          <div key={childIndex} className="col-span-2 px-2 mb-5">
                                                                                            <div className="flex items-center">
                                                                                              <div className="text-xs mb-1">Child {childIndex + 1} Age</div>
                                                                                              <RiCloseCircleLine
                                                                                                size={16}
                                                                                                className="ml-1 text-red-600 cursor-pointer"
                                                                                                onClick={() => {
                                                                                                  //item.customRoomConfig = '';
                                                                                                  room.roomConfig = '';
                                                                                                  removeSelectedAgesUuid(
                                                                                                    bookings,
                                                                                                    item.id,
                                                                                                    room.uuid,
                                                                                                    selectedUuid,
                                                                                                    setBookings,
                                                                                                    selectedAges,
                                                                                                    setSelectedAges,
                                                                                                  );
                                                                                                }}
                                                                                              />
                                                                                            </div>

                                                                                            <Select
                                                                                              className="border-r border-l border-slate-200 w-[90px]"
                                                                                              options={ageOptions}
                                                                                              value={selectedUuid ? { label: selectedAge?.toString() || '', value: selectedUuid } : null}
                                                                                              onChange={(selectedOption) => {
                                                                                                const selectedChild = item.guestsMerged.childAges.find((child) => child.uuid === selectedOption.value);
                                                                                                if (selectedChild) {
                                                                                                  handleChildAgeChangeRoom(
                                                                                                    item.id,
                                                                                                    childIndex,
                                                                                                    { age: selectedChild.age, uuid: selectedChild.uuid },
                                                                                                    setSelectedAges,
                                                                                                  );
                                                                                                }
                                                                                              }}
                                                                                              placeholder="-"
                                                                                              menuPortalTarget={document.body}
                                                                                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                                                            />
                                                                                          </div>
                                                                                        ) : (
                                                                                          childIndex >= ageOptions.length && (
                                                                                            <div key={childIndex + 1} className="col-span-12 px-2 mb-5 text-sm text-center text-orange-600">
                                                                                              Select 'Edit guests' above to change PAX
                                                                                            </div>
                                                                                          )
                                                                                        );
                                                                                      })}
                                                                                    </div>
                                                                                  </>
                                                                                )}
                                                                                <div className="row-3">
                                                                                  {' '}
                                                                                  <div className="mb-4 flex justify-end gap-4">
                                                                                    <ButtonOutlineSmall
                                                                                      className=""
                                                                                      onClick={() => {
                                                                                        room.edit = false;
                                                                                        setBookings((bookings) => [...bookings]);
                                                                                      }}
                                                                                      text="Cancel"
                                                                                    />
                                                                                    <ButtonPrimarySmall
                                                                                      className="mx-2"
                                                                                      onClick={() => {
                                                                                        let continueEdit = true;
                                                                                        if (
                                                                                          room.roomConfig === 'Other' &&
                                                                                          room.customRoomConfig === '' &&
                                                                                          properties[getPropObj(item.propUid, properties)].propertyType !== 'private'
                                                                                        ) {
                                                                                          toast.error(`You must enter a custom config if Room configuration is 'Other'`);
                                                                                          return;
                                                                                        }

                                                                                        if (room.roomConfig === '' && properties[getPropObj(item.propUid, properties)].propertyType !== 'private') {
                                                                                          toast.error(`You must select a "Room configuration"`);
                                                                                          return;
                                                                                        }

                                                                                        if (
                                                                                          item.selectedRoomConfig === 'Other' &&
                                                                                          item.customRoomConfig === '' &&
                                                                                          properties[getPropObj(item.propUid, properties)].propertyType !== 'private'
                                                                                        ) {
                                                                                          toast.error(`You must enter a custom config if Room configuration is 'Other'`);
                                                                                          return;
                                                                                        }

                                                                                        if (
                                                                                          item.selectedRoomConfig === '' &&
                                                                                          properties[getPropObj(item.propUid, properties)].propertyType !== 'private'
                                                                                        ) {
                                                                                          toast.error(`You must select a "Room configuration"`);
                                                                                          return;
                                                                                        }

                                                                                        room.customRoomConfig = item.customRoomConfig;
                                                                                        item.customRoomConfig = '';
                                                                                        item.notModified = false;

                                                                                        if (
                                                                                          properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                                          getRoomType(properties[getPropObj(item.propUid, properties)].rooms, room.selectedRoom) !== 'Exclusive' &&
                                                                                          //&&
                                                                                          //getRoomType(properties[getPropObj(item.propUid, properties)].rooms, room.selectedRoom) !== 'Guide/Pilot Bed'
                                                                                          room.selectedName !== 'select'
                                                                                        ) {
                                                                                          console.log('condition 1');
                                                                                          // handleBooking(bookings, requiredIndex, properties, requiredIndexRoom, setBookings, date, rooms, setRooms, setRefreshRatesEffect);
                                                                                        } else if (
                                                                                          (properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                                            getRoomType(properties[getPropObj(item.propUid, properties)].rooms, room.selectedRoom) === 'Exclusive' &&
                                                                                            //||
                                                                                            // getRoomType(properties[getPropObj(item.propUid, properties)].rooms, room.selectedRoom) === 'Guide/Pilot Bed'
                                                                                            room.selectedRoom !== 'select' &&
                                                                                            (room.ch > 0 || room.ad > 0)) ||
                                                                                          (properties[getPropObj(item.propUid, properties)].propertyType !== 'private' &&
                                                                                            room.selectedRoom !== 'select' &&
                                                                                            (room.ch > 0 || room.ad > 0))
                                                                                        ) {
                                                                                          console.log('condition 2');
                                                                                          //handleBooking(bookings, requiredIndex, properties, requiredIndexRoom, setBookings, date, rooms, setRooms, setRefreshRatesEffect);
                                                                                        } else {
                                                                                          continueEdit = false;
                                                                                          toast.error('You must select a room type and add one adult or one child');
                                                                                        }
                                                                                        const lengthOfSelectedAges = selectedAges.length;
                                                                                        const lengthOfSelectedAgesAdults = selectedAgesAdults.length;
                                                                                        if (continueEdit) {
                                                                                          //alert(room.ch);
                                                                                          if (lengthOfSelectedAges === room.ch) {
                                                                                            room.selectedAges = selectedAges;
                                                                                          } else {
                                                                                            toast.error(`You must select an age for each child`);
                                                                                            return;
                                                                                          }

                                                                                          if (lengthOfSelectedAgesAdults === room.ad) {
                                                                                            room.selectedAgesAdults = selectedAgesAdults;
                                                                                          } else {
                                                                                            item.unit = 0;
                                                                                            item.ad = 0;
                                                                                            item.ch = 0;
                                                                                            item.chi = 0;
                                                                                            item.inf = 0;

                                                                                            setBookings((bookings) => [...bookings]);
                                                                                            setSelectedAges([]);
                                                                                            setSelectedAgesAdults([]);
                                                                                            toast.error(`There has been an error add room PAX again`);
                                                                                            return;
                                                                                          }

                                                                                          room.edit = false;
                                                                                          //new
                                                                                          if (room.ad !== tempAd || room.ch !== tempCh) {
                                                                                            item.rebuild = true;
                                                                                            item.refresh = true;
                                                                                          }
                                                                                          setTempAd(false);
                                                                                          setTempCh(false);

                                                                                          setBookings((bookings) => [...bookings]);
                                                                                          console.log('EDIT ROOM');
                                                                                          reCountBookings(bookings, setBookings, dateStart, rooms, setRooms, properties, setRefreshRatesEffect, true);
                                                                                          setRefreshAllRates(true);
                                                                                          setBookings((bookings) => [...bookings]);
                                                                                        }

                                                                                        setBookings((bookings) => [...bookings]);
                                                                                        setSelectedAges([]);
                                                                                        setSelectedAgesAdults([]);
                                                                                      }}
                                                                                      // disabled={room.selectedName === "Exclusive Use"}
                                                                                      text="Save"
                                                                                    />
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            )}
                                                                            <div className={`${item.rooms.length !== roomIndex + 1 && 'border-b border-slate-200'} my-0`}></div>
                                                                          </div>
                                                                        ))
                                                                    )}
                                                                  </div>
                                                                )}

                                                                {item.rooms.length === 0 || item.addRoom ? (
                                                                  <div
                                                                    className={`col-span-1 bg-white text-left align-left leading-5 mx-4 rounded-md border border-slate-200 min-h-[26rem] flex flex-col overflow-y-auto`}
                                                                  >
                                                                    <div className="flex">
                                                                      <div className={`text-sm brand-text-color-800 font-semibold p-2`}>
                                                                        Add {properties[getPropObj(item.propUid, properties)].bookedDirect ? 'pax' : 'room'}
                                                                      </div>
                                                                      {item.rooms.length > 0 && (
                                                                        <div className="ml-auto flex justify-end mr-2">
                                                                          <button
                                                                            onClick={async () => {
                                                                              item.addRoom = false;

                                                                              item.unit = 0;
                                                                              item.ad = 0;
                                                                              item.ch = 0;
                                                                              item.chi = 0;
                                                                              item.inf = 0;
                                                                              item.selectedRoom = '';

                                                                              setBookings((bookings) => [...bookings]);
                                                                              setSelectedAges([]);
                                                                              setSelectedAgesAdults([]);
                                                                            }}
                                                                          >
                                                                            <RiCloseCircleLine className="brand-text-color-300 cursor-default" size={20} />
                                                                          </button>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                    <>
                                                                      <div className="row-2 grid grid-cols-12">
                                                                        {/* Room select */}
                                                                        <div className="col-span-3">
                                                                          <div className="px-2 pt-5">
                                                                            <CustomSelectRooms
                                                                              value={
                                                                                properties[getPropObj(item.propUid, properties)].rooms.find((room) => room.id === item.selectedRoom)
                                                                                  ? {
                                                                                      label: properties[getPropObj(item.propUid, properties)].rooms.find((room) => room.id === item.selectedRoom).name,
                                                                                      value: item.selectedRoom,
                                                                                    }
                                                                                  : { label: 'Select room type', value: 'Select room type' }
                                                                              }
                                                                              defaultValue={{ label: 'Select room type', value: 'Select room type' }}
                                                                              onChange={(selectedOption) => {
                                                                                roomSelect(selectedOption, item.id, bookings, setBookings);
                                                                                item.ad = 0;
                                                                                item.ch = 0;
                                                                                item.chi = 0;
                                                                                item.inf = 0;
                                                                                item.selectedRoomConfig = '';
                                                                                setSelectedAges([]);
                                                                                setSelectedAgesAdults([]);
                                                                              }}
                                                                              options={properties[getPropObj(item.propUid, properties)].rooms
                                                                                // .filter(
                                                                                //   (res) =>
                                                                                //     (properties[getPropObj(item.propUid, properties)].propertyType === 'private'
                                                                                //       ? res.type === 'Room' ||
                                                                                //         res.type === 'Guide/Pilot Bed' ||
                                                                                //         (res.type === 'Exclusive' && createEdit === 'edit' && !semver.gt(createVersion, '2.4.0'))
                                                                                //       : res.type === 'Exclusive' || res.type === 'Room' || res.type === 'Guide/Pilot Bed') &&
                                                                                //     res.active &&
                                                                                //     ((item.days === 0 && res.dayRoom === true) ||
                                                                                //       (item.days > 0 && (res.dayRoom !== true || res.overnight === true))) &&
                                                                                //     (user.internalUser || !res.hideExternalUsers),
                                                                                // )
                                                                                .filter((res) => {
                                                                                  const hasGuide = item.guestsMerged.adultAges.some((adult) => adult.isGuide === true);

                                                                                  return (
                                                                                    (properties[getPropObj(item.propUid, properties)].propertyType === 'private'
                                                                                      ? res.type === 'Room' ||
                                                                                        (res.type === 'Guide/Pilot Bed' && hasGuide) ||
                                                                                        (res.type === 'Exclusive' && createEdit === 'edit' && !semver.gt(createVersion, '2.4.0'))
                                                                                      : res.type === 'Exclusive' || res.type === 'Room' || (res.type === 'Guide/Pilot Bed' && hasGuide)) &&
                                                                                    res.active &&
                                                                                    ((item.days === 0 && res.dayRoom === true) ||
                                                                                      (item.days > 0 && (res.dayRoom !== true || res.overnight === true))) &&
                                                                                    (user.internalUser || !res.hideExternalUsers)
                                                                                  );
                                                                                })
                                                                                .sort((a, b) => {
                                                                                  const order = {
                                                                                    Room: 1,
                                                                                    'Guide/Pilot Bed': 2,
                                                                                  };
                                                                                  return order[a.type] - order[b.type];
                                                                                })
                                                                                .map((res) => ({
                                                                                  label: `${res.name} ${getRoomAvailability(res.id, item, rooms)}`,
                                                                                  value: res.id,
                                                                                  name: res.name,
                                                                                }))}
                                                                              placeholder="Select room type"
                                                                              instanceId="room-select"
                                                                              isSearchable={false}
                                                                              showDropdownIndicator={true}
                                                                              height={30}
                                                                              color1={'#6996A0'}
                                                                              color2={'#E5E7EB'}
                                                                            />
                                                                          </div>
                                                                        </div>
                                                                        {/* Room select END */}

                                                                        {/* Adults add/remove */}
                                                                        <div className="col-span-3 px-2">
                                                                          <div className="text-xs mb-1">Adults</div>
                                                                          {/* BUTTON START */}
                                                                          <div className="grid grid-rows-1 grid-flow-col bg-white border-2 border-slate-200 rounded-lg py-0.5 h-9">
                                                                            <button
                                                                              className=" border-r border-slate-200"
                                                                              onClick={() => {
                                                                                incDecRoomGuests('dec', bookings, item.id, 'ad', getPropObj(item.propUid, properties), properties);
                                                                                //item.customRoomConfig = '';
                                                                                item.selectedRoomConfig = '';
                                                                                // Update bookings state
                                                                                setBookings((bookings) => [...bookings]);

                                                                                // Check if there are no adults left after decrementing, or remove the last adult
                                                                                setSelectedAgesAdults((currentSelectedAgesAdults) => {
                                                                                  if (item.ad === 0) {
                                                                                    // If no adults are left after decrementing, clear the selectedAgesAdults state
                                                                                    return [];
                                                                                  } else {
                                                                                    // Remove the last adult from selectedAgesAdults state
                                                                                    return currentSelectedAgesAdults.slice(0, -1);
                                                                                  }
                                                                                });
                                                                              }}
                                                                              // disabled={
                                                                              //   // properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                              //   // getRoomType(properties[getPropObj(item.propUid, properties)].rooms, item.selectedRoom) !== 'Exclusive' &&
                                                                              //   getRoomType(properties[getPropObj(item.propUid, properties)].rooms, item.selectedRoom) !== 'Guide/Pilot Bed'
                                                                              // }
                                                                            >
                                                                              -
                                                                            </button>
                                                                            <div className="px-2 text-xs font-normal text-center align-middle leading-6">{item.ad}</div>
                                                                            <button
                                                                              className="border-l border-slate-200"
                                                                              // onClick={() => {
                                                                              //   incDecRoomGuests('inc', bookings, item.id, 'ad', getPropObj(item.propUid, properties), properties);

                                                                              //   setBookings((bookings) => [...bookings]);
                                                                              // }}

                                                                              onClick={() => {
                                                                                const adultAges = item.guestsMerged.adultAges;

                                                                                // Calculate UUID counts from existing bookings
                                                                                const existingBookingsUuids = bookings
                                                                                  .find((booking) => booking.id === item.id)
                                                                                  ?.rooms.flatMap((room) => room.selectedAgesAdults || [])
                                                                                  .reduce((acc, selectedAdult) => {
                                                                                    acc[selectedAdult.uuid] = (acc[selectedAdult.uuid] || 0) + 1;
                                                                                    return acc;
                                                                                  }, {});

                                                                                // Calculate UUID counts for currently selected ages
                                                                                const selectedUuidCounts = selectedAgesAdults.reduce((acc, selectedAdult) => {
                                                                                  acc[selectedAdult.uuid] = (acc[selectedAdult.uuid] || 0) + 1;
                                                                                  return acc;
                                                                                }, {});

                                                                                // Determine the oldest available adult
                                                                                const ageOptions = adultAges
                                                                                  .filter((adult) => {
                                                                                    const isUuidAvailable = !selectedUuidCounts[adult.uuid] && !existingBookingsUuids[adult.uuid];
                                                                                    return isUuidAvailable;
                                                                                  })
                                                                                  .sort((a, b) => b.age - a.age);

                                                                                if (ageOptions.length > 0) {
                                                                                  const oldestAvailableAdult = ageOptions[0];

                                                                                  //const addObject = incDecRoomGuests('inc', bookings, item.id, 'ad', getPropObj(item.propUid, properties), properties);

                                                                                  // Automatically assign the oldest available adult
                                                                                  handleAdultAgeChangeRoom(
                                                                                    item.id,
                                                                                    item.ad,
                                                                                    { age: oldestAvailableAdult.age, uuid: oldestAvailableAdult.uuid },
                                                                                    setSelectedAgesAdults,
                                                                                  );

                                                                                  // NEW CODE TO CHECK AN ADULT WAS ADDED
                                                                                  const addObject = incDecRoomGuests('inc', bookings, item.id, 'ad', getPropObj(item.propUid, properties), properties);
                                                                                  //item.customRoomConfig = '';
                                                                                  item.selectedRoomConfig = '';

                                                                                  // NEW CODE TO REMOVE AN ADULT FROM SELECTED ADULTS IF MAX PAX IS REACHED
                                                                                  if (!addObject) {
                                                                                    setSelectedAgesAdults((currentSelectedAgesAdults) => {
                                                                                      return currentSelectedAgesAdults.slice(0, -1);
                                                                                    });
                                                                                  }

                                                                                  // Increment adult count
                                                                                  //const incDecRoomGuests('inc', bookings, item.id, 'ad', getPropObj(item.propUid, properties), properties);
                                                                                  setBookings((bookings) => [...bookings]);
                                                                                } else {
                                                                                  // Show toast if no adults are available
                                                                                  toast.error("All available adults have been assigned. Select 'Edit guests' above to change PAX");
                                                                                }
                                                                              }}
                                                                              // disabled={
                                                                              //   //properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                              //   //getRoomType(properties[getPropObj(item.propUid, properties)].rooms, item.selectedRoom) !== 'Exclusive'
                                                                              //   //&&
                                                                              //   //getRoomType(properties[getPropObj(item.propUid, properties)].rooms, item.selectedRoom) !== 'Guide/Pilot Bed'
                                                                              // }
                                                                            >
                                                                              +
                                                                            </button>
                                                                          </div>
                                                                          {/* BUTTON END */}
                                                                        </div>
                                                                        {/* Adults add/remove END */}

                                                                        {/* Children add/remove */}
                                                                        <div className="col-span-3 px-2">
                                                                          {' '}
                                                                          {/* BUTTON START */}
                                                                          <div className="text-xs mb-1">
                                                                            {properties[getPropObj(item.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67'
                                                                              ? 'Children'
                                                                              : 'Children'}{' '}
                                                                            (0 - {properties[getPropObj(item.propUid, properties)].maxChildAge})
                                                                          </div>
                                                                          <div className="grid grid-rows-1 grid-flow-col bg-white border border-slate-200 rounded-lg h-9 py-0.5">
                                                                            <button
                                                                              className="border-r border-slate-200"
                                                                              onClick={() => {
                                                                                if (item.ch === selectedAges.length) {
                                                                                  removeLastAge(setSelectedAges, selectedAges);
                                                                                }
                                                                                console.log('selectedAges.length', selectedAges.length);
                                                                                console.log('item.ch', item.ch);
                                                                                incDecRoomGuests('dec', bookings, item.id, 'ch', getPropObj(item.propUid, properties), properties);
                                                                                console.log('item.ch', item.ch);
                                                                                if (item.ch === 0) {
                                                                                  setSelectedAges([]);
                                                                                }

                                                                                console.log('selectedAges.length', selectedAges.length);
                                                                                console.log('item.ch', item.ch);
                                                                                // item.customRoomConfig = '';
                                                                                item.selectedRoomConfig = '';

                                                                                setBookings((bookings) => [...bookings]);
                                                                              }}
                                                                              // disabled={
                                                                              //   // properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                              //   // getRoomType(properties[getPropObj(item.propUid, properties)].rooms, item.selectedRoom) !== 'Exclusive' &&
                                                                              //   getRoomType(properties[getPropObj(item.propUid, properties)].rooms, item.selectedRoom) !== 'Guide/Pilot Bed'
                                                                              // }
                                                                            >
                                                                              -
                                                                            </button>
                                                                            <div className="px-2 text-xs font-normal text-center align-middle leading-6">{item.ch}</div>
                                                                            <button
                                                                              className="border-l border-slate-200"
                                                                              // onClick={() => {
                                                                              //   incDecRoomGuests('inc', bookings, item.id, 'ch', getPropObj(item.propUid, properties), properties);

                                                                              //   setBookings((bookings) => [...bookings]);
                                                                              // }}

                                                                              onClick={() => {
                                                                                // Step 1: Get childAgesLength
                                                                                const childAges = item.guestsMerged.childAges;
                                                                                const childAgesLength = childAges.length;

                                                                                // Step 2: Check the length of all rooms' selectedAges
                                                                                let totalSelectedAges = 0;
                                                                                // item.rooms.forEach((room) => {
                                                                                //   totalSelectedAges += room.selectedAges.length;
                                                                                // });

                                                                                item.rooms.forEach((room) => {
                                                                                  if (room.roomType !== 'Vehicle') {
                                                                                    totalSelectedAges += room.selectedAges.length;
                                                                                  }
                                                                                });

                                                                                //if (totalSelectedAges < childAgesLength && item.ch < childAgesLength) {
                                                                                if (totalSelectedAges + item.ch < childAgesLength) {
                                                                                  // If all is ok, update the bookings
                                                                                  incDecRoomGuests('inc', bookings, item.id, 'ch', getPropObj(item.propUid, properties), properties);
                                                                                  //item.customRoomConfig = '';
                                                                                  item.selectedRoomConfig = '';
                                                                                  setBookings([...bookings]);
                                                                                } else {
                                                                                  // If not ok, show error toast
                                                                                  toast.error("All available children have been assigned. Select 'Edit guests' above to change PAX");
                                                                                }
                                                                              }}
                                                                              // disabled={
                                                                              //   //properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                              //  // getRoomType(properties[getPropObj(item.propUid, properties)].rooms, item.selectedRoom) !== 'Exclusive'
                                                                              //   //&&
                                                                              //   //getRoomType(properties[getPropObj(item.propUid, properties)].rooms, item.selectedRoom) !== 'Guide/Pilot Bed'
                                                                              // }
                                                                            >
                                                                              +
                                                                            </button>
                                                                          </div>
                                                                          {/* BUTTON END */}
                                                                        </div>
                                                                        {/* Children add/remove END */}

                                                                        {/* Room config */}
                                                                        {item.ad + item.ch > 0 && properties[getPropObj(item.propUid, properties)].propertyType !== 'private' ? (
                                                                          // ||
                                                                          // (properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                          //   properties[getPropObj(item.propUid, properties)].rooms.some((room) => room.type === 'Exclusive') &&
                                                                          //   item.selectedName !== 'Select room type' &&
                                                                          //   item.selectedName !== '')
                                                                          <div className="col-span-3">
                                                                            <div className="text-xs mb-1 ml-2">Room configuration</div>
                                                                            <div className="px-2">
                                                                              <Select
                                                                                className="mt-1 text-xs"
                                                                                value={
                                                                                  item.selectedRoomConfig
                                                                                    ? {
                                                                                        label: item.selectedRoomConfig,
                                                                                        value: item.selectedRoomConfig,
                                                                                      }
                                                                                    : null
                                                                                }
                                                                                onChange={(selectedOption) => {
                                                                                  item.selectedRoomConfig = selectedOption ? selectedOption.value : '';
                                                                                  setBookings((bookings) => [...bookings]);
                                                                                }}
                                                                                options={getConfigurationOptions(item.selectedRoom, item.ad, item.ch, item.propUid, properties)}
                                                                                placeholder="Select"
                                                                                isSearchable={false}
                                                                                classNamePrefix="react-select"
                                                                                menuPortalTarget={document.body}
                                                                                maxMenuHeight={140}
                                                                                styles={{
                                                                                  control: (provided) => ({
                                                                                    ...provided,
                                                                                  }),
                                                                                  option: (provided, state) => ({
                                                                                    ...provided,
                                                                                    fontSize: '12px',
                                                                                    color: state.isSelected ? 'white' : '#666',
                                                                                    backgroundColor: state.isSelected ? '#2A99A3' : 'white',
                                                                                    ':hover': {
                                                                                      backgroundColor: state.isSelected ? '#2A99A3' : '#F5F5F5',
                                                                                    },
                                                                                  }),
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </div>
                                                                        ) : (
                                                                          <div className="col-span-3"> </div>
                                                                        )}
                                                                        {/* Room config END */}
                                                                      </div>

                                                                      <div className="row-2 grid grid-cols-12">
                                                                        {/* Room select */}
                                                                        <div className="col-span-3"></div>
                                                                        <div className="col-span-3"></div>
                                                                        <div className="col-span-6 mx-2">
                                                                          {item.selectedRoomConfig === 'Other' && (
                                                                            <input
                                                                              type="text"
                                                                              className="mt-2 text-xs border border-gray-300 rounded px-2 py-1 w-full h-9"
                                                                              placeholder="Enter custom config"
                                                                              value={item.customRoomConfig || ''}
                                                                              onChange={(e) => {
                                                                                item.customRoomConfig = e.target.value;
                                                                                setBookings((bookings) => [...bookings]);
                                                                              }}
                                                                            />
                                                                          )}
                                                                        </div>
                                                                      </div>
                                                                    </>

                                                                    {/* TEST1 */}

                                                                    {item.ad > 0 && (
                                                                      <>
                                                                        <div className="row-1">
                                                                          <div className={`text-sm brand-text-color-800 font-normal px-5 pt-5 pb-1`}>Adults:</div>
                                                                        </div>
                                                                        <div className="row-2 grid grid-cols-12 ml-5">
                                                                          {Array.from({ length: item.ad }).map((_, adultIndex) => {
                                                                            const adultAges = item.guestsMerged.adultAges;

                                                                            // Calculate UUID counts from existing bookings
                                                                            const existingBookingsUuids = bookings
                                                                              .find((booking) => booking.id === item.id)
                                                                              ?.rooms.flatMap((room) => room.selectedAgesAdults || [])
                                                                              .reduce((acc, selectedAdult) => {
                                                                                acc[selectedAdult.uuid] = (acc[selectedAdult.uuid] || 0) + 1;
                                                                                return acc;
                                                                              }, {});

                                                                            // Calculate UUID counts for currently selected ages excluding the current adult
                                                                            const selectedUuidCounts = selectedAgesAdults.reduce((acc, selectedAdult, index) => {
                                                                              if (index !== adultIndex && selectedAdult) {
                                                                                acc[selectedAdult.uuid] = (acc[selectedAdult.uuid] || 0) + 1;
                                                                              }
                                                                              return acc;
                                                                            }, {});

                                                                            const ageOptions = adultAges
                                                                              .filter((adult) => {
                                                                                const isUuidAvailable = !selectedUuidCounts[adult.uuid] && !existingBookingsUuids[adult.uuid];
                                                                                return isUuidAvailable;
                                                                              })
                                                                              .map((adult) => ({
                                                                                value: adult.uuid,
                                                                                label: isGuideCheck(guests, adult.uuid) ? '(G)' : adult.age === 99 ? 'Ad' : adult.age.toString(),
                                                                              }))
                                                                              .sort((a, b) => {
                                                                                if (a.label === '(G)') return -1;
                                                                                if (b.label === '(G)') return 1;
                                                                                if (a.label === 'Ad') return -1;
                                                                                if (b.label === 'Ad') return 1;
                                                                                return b.label.localeCompare(a.label, undefined, { numeric: true });
                                                                              });

                                                                            const selectedAdult = selectedAgesAdults[adultIndex];
                                                                            const selectedUuid = selectedAdult?.uuid;
                                                                            console.log('ageOptions', ageOptions);

                                                                            return ageOptions.length > 0 ? (
                                                                              <div key={adultIndex} className="col-span-2 px-2 mb-5">
                                                                                <div className="text-xs mb-1">Adult {adultIndex + 1} Age</div>
                                                                                <Select
                                                                                  className="border-r border-l border-slate-200 w-[90px]"
                                                                                  options={ageOptions}
                                                                                  value={selectedUuid ? ageOptions.find((option) => option.value === selectedUuid) : null}
                                                                                  onChange={(selectedOption) => {
                                                                                    const selectedAdult = item.guestsMerged.adultAges.find((adult) => adult.uuid === selectedOption.value);
                                                                                    if (selectedAdult) {
                                                                                      handleAdultAgeChangeRoom(
                                                                                        item.id,
                                                                                        adultIndex,
                                                                                        { age: selectedAdult.age, uuid: selectedAdult.uuid },
                                                                                        setSelectedAgesAdults,
                                                                                      );
                                                                                    }
                                                                                  }}
                                                                                  placeholder="-"
                                                                                  menuPortalTarget={document.body}
                                                                                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                                                />
                                                                              </div>
                                                                            ) : (
                                                                              adultIndex >= ageOptions.length && (
                                                                                <div key={adultIndex + 1} className="col-span-12 px-2 mb-5 text-sm text-center text-orange-600">
                                                                                  Select 'Edit guests' above to change PAX
                                                                                </div>
                                                                              )
                                                                            );
                                                                          })}
                                                                        </div>
                                                                      </>
                                                                    )}
                                                                    {/* TEST 3 */}
                                                                    {item.ch > 0 && (
                                                                      <>
                                                                        <div className="row-1">
                                                                          <div className={`text-sm brand-text-color-800 font-normal px-5 pb-1 ${item.ad > 0 ? 'pt-0' : 'pt-5'}`}>Children:</div>
                                                                        </div>
                                                                        <div className="row-2 grid grid-cols-12 ml-5">
                                                                          {Array.from({ length: item.ch }).map((_, childIndex) => {
                                                                            const childAges = item.guestsMerged.childAges;

                                                                            // Calculate UUID counts from existing bookings
                                                                            const existingBookingsUuids = bookings
                                                                              .find((booking) => booking.id === item.id)
                                                                              ?.rooms.flatMap((room) => room.selectedAges || [])
                                                                              .reduce((acc, selectedChild) => {
                                                                                acc[selectedChild.uuid] = (acc[selectedChild.uuid] || 0) + 1;
                                                                                return acc;
                                                                              }, {});

                                                                            // Calculate UUID counts for currently selected ages excluding the current child
                                                                            const selectedUuidCounts = selectedAges.reduce((acc, selectedChild, index) => {
                                                                              if (index !== childIndex && selectedChild) {
                                                                                acc[selectedChild.uuid] = (acc[selectedChild.uuid] || 0) + 1;
                                                                              }
                                                                              return acc;
                                                                            }, {});

                                                                            // Filter out UUIDs that are fully used and create options for the Select component
                                                                            const ageOptions = childAges
                                                                              .filter((child) => {
                                                                                const isUuidAvailable = !selectedUuidCounts[child.uuid] && !existingBookingsUuids[child.uuid];
                                                                                return isUuidAvailable;
                                                                              })
                                                                              .map((child) => ({ value: child.uuid, label: child.age.toString() }));

                                                                            const selectedChild = selectedAges[childIndex];
                                                                            const selectedUuid = selectedChild?.uuid;
                                                                            const selectedAge = selectedChild?.age;

                                                                            console.log('ageOptions', ageOptions);

                                                                            return ageOptions.length > 0 ? (
                                                                              <div key={childIndex} className="col-span-2 px-2 mb-5">
                                                                                <div className="text-xs mb-1">Child {childIndex + 1} Age</div>

                                                                                <Select
                                                                                  className="border-r border-l border-slate-200 w-[90px]"
                                                                                  options={ageOptions}
                                                                                  value={selectedUuid ? { label: selectedAge?.toString() || '', value: selectedUuid } : null}
                                                                                  onChange={(selectedOption) => {
                                                                                    const selectedChild = item.guestsMerged.childAges.find((child) => child.uuid === selectedOption.value);
                                                                                    if (selectedChild) {
                                                                                      handleChildAgeChangeRoom(
                                                                                        item.id,
                                                                                        childIndex,
                                                                                        { age: selectedChild.age, uuid: selectedChild.uuid },
                                                                                        setSelectedAges,
                                                                                      );
                                                                                    }
                                                                                  }}
                                                                                  placeholder="-"
                                                                                  menuPortalTarget={document.body}
                                                                                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                                                />
                                                                              </div>
                                                                            ) : (
                                                                              childIndex >= ageOptions.length && (
                                                                                <div key={childIndex + 1} className="col-span-12 px-2 mb-5 text-sm text-center text-orange-600">
                                                                                  Select 'Edit guests' above to change PAX
                                                                                </div>
                                                                              )
                                                                            );
                                                                          })}
                                                                        </div>
                                                                      </>
                                                                    )}

                                                                    <div className="row-3">
                                                                      {' '}
                                                                      <div className="mb-4 text-right">
                                                                        {' '}
                                                                        {!item.guestsMerged.childAges.includes(-1) && (
                                                                          <ButtonPrimarySmall
                                                                            text={`Add ${properties[getPropObj(item.propUid, properties)].bookedDirect ? 'pax' : 'room'}`}
                                                                            onClick={() => {
                                                                              if (
                                                                                item.selectedRoomConfig === 'Other' &&
                                                                                item.customRoomConfig === '' &&
                                                                                properties[getPropObj(item.propUid, properties)].propertyType !== 'private'
                                                                              ) {
                                                                                toast.error(`You must enter a custom config if Room configuration is 'Other'`);
                                                                                return;
                                                                              }

                                                                              if (item.selectedRoomConfig === '' && properties[getPropObj(item.propUid, properties)].propertyType !== 'private') {
                                                                                toast.error(`You must select a "Room configuration"`);
                                                                                return;
                                                                              }
                                                                              let continueEdit = true;
                                                                              if (
                                                                                properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                                getRoomType(properties[getPropObj(item.propUid, properties)].rooms, item.selectedRoom) !== 'Exclusive' &&
                                                                                //&&
                                                                                //getRoomType(properties[getPropObj(item.propUid, properties)].rooms, room.selectedRoom) !== 'Guide/Pilot Bed'
                                                                                item.selectedName !== 'select'
                                                                              ) {
                                                                                console.log('condition 1');
                                                                                // handleBooking(bookings, requiredIndex, properties, requiredIndexRoom, setBookings, date, rooms, setRooms, setRefreshRatesEffect);
                                                                              } else if (
                                                                                (properties[getPropObj(item.propUid, properties)].propertyType === 'private' &&
                                                                                  getRoomType(properties[getPropObj(item.propUid, properties)].rooms, item.selectedRoom) === 'Exclusive' &&
                                                                                  //||
                                                                                  // getRoomType(properties[getPropObj(item.propUid, properties)].rooms, room.selectedRoom) === 'Guide/Pilot Bed'
                                                                                  item.selectedRoom !== 'select' &&
                                                                                  (item.ch > 0 || item.ad > 0)) ||
                                                                                (properties[getPropObj(item.propUid, properties)].propertyType !== 'private' &&
                                                                                  item.selectedRoom !== 'select' &&
                                                                                  (item.ch > 0 || item.ad > 0))
                                                                              ) {
                                                                                console.log('condition 2');
                                                                                //handleBooking(bookings, requiredIndex, properties, requiredIndexRoom, setBookings, date, rooms, setRooms, setRefreshRatesEffect);
                                                                              } else {
                                                                                continueEdit = false;
                                                                                toast.error('You must select a room type and add one adult or one child');
                                                                              }
                                                                              if (continueEdit) {
                                                                                const lengthOfSelectedAges = selectedAges.length;
                                                                                const lengthOfSelectedAgesAdults = selectedAgesAdults.length;
                                                                                //const agesArray = Object.values(selectedAges).map((entry) => entry.age);

                                                                                if (lengthOfSelectedAges === item.ch && lengthOfSelectedAgesAdults === item.ad) {
                                                                                  addRoom(
                                                                                    bookings,
                                                                                    setBookings,
                                                                                    item.id,
                                                                                    realDate,
                                                                                    rooms,
                                                                                    setRooms,
                                                                                    properties,
                                                                                    setRefreshBtn,
                                                                                    setFetchingRates,
                                                                                    agentRates,
                                                                                    setRefreshRatesEffect,
                                                                                    selectedAges,
                                                                                    selectedAgesAdults,
                                                                                  );
                                                                                  //setFetchingRates(true);
                                                                                  setRefreshAllRates(true);
                                                                                  setBookings((bookings) => [...bookings]);
                                                                                  setSelectedAges([]);
                                                                                  setSelectedAgesAdults([]);
                                                                                } else {
                                                                                  toast.error(`You must select an age for each guest`);
                                                                                }
                                                                              }
                                                                            }}
                                                                            className="mt-5 mr-2"
                                                                          />
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                ) : (
                                                                  <div className="text-center my-auto">
                                                                    {!bookings.some((booking) => booking.rooms.length > 0 && booking.rooms.some((room) => room.edit)) &&
                                                                      !item.guestsMerged.childAges.includes(-1) && (
                                                                        <div
                                                                          className="text-nomadEvergreen-700 mb-4 cursor-default"
                                                                          onClick={() => {
                                                                            item.addRoom = true;
                                                                            //set default roomConfig
                                                                            item.selectedRoomConfig = 'Select';
                                                                            item.customRoomConfig = '';
                                                                            item.selectedName = '';

                                                                            item.rooms.forEach((room) => {
                                                                              room.edit = false;
                                                                            });
                                                                            setSelectedAges([]);
                                                                            setSelectedAgesAdults([]);
                                                                            setBookings((bookings) => [...bookings]);
                                                                          }}
                                                                        >
                                                                          + Add {properties[getPropObj(item.propUid, properties)].bookedDirect ? 'pax' : 'room'}
                                                                        </div>
                                                                      )}
                                                                    <div className="border-b border-slate-200 mx-5"></div>
                                                                  </div>
                                                                )}
                                                              </div>
                                                            </div>

                                                            {/* ROW 4 (Expanded/collapse button) */}
                                                            {!bookings.some((booking) => booking.rooms.length > 0 && booking.rooms.some((room) => room.edit)) && (
                                                              <div className={`content-end`}>
                                                                <div className={`grid grid-cols-12 pb-3`}>
                                                                  <button
                                                                    className="col-span-12 h-5 rounded-b-md flex justify-center brand-text-color-300"
                                                                    onClick={() => {
                                                                      if (
                                                                        (properties[getPropObj(item.propUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' &&
                                                                          item.rateName !== 'select') ||
                                                                        (properties[getPropObj(item.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67' &&
                                                                          item.creatioUid !== undefined)
                                                                      ) {
                                                                        for (let i = 0; i < bookings.length; i++) {
                                                                          if (bookings[i].id !== item.id) {
                                                                            bookings[i].expanded = false;
                                                                            item.addRoom = false;
                                                                          }
                                                                        }
                                                                        expandCollapse(item.id, bookings);
                                                                        //set default roomConfig
                                                                        item.selectedRoomConfig = 'Select';

                                                                        setBookings((bookings) => [...bookings]);
                                                                      } else {
                                                                        toast.error(`You must select a rate type`);
                                                                      }
                                                                    }}
                                                                  >
                                                                    {(item.rooms.length === 0 && !item.expanded) ||
                                                                    (item.rooms.length === 1 && item.rooms[0].roomType === 'Exclusive' && !item.expanded) ? (
                                                                      <div className="flex items-center text-nomadEvergreen-700">
                                                                        + Add {properties[getPropObj(item.propUid, properties)].bookedDirect ? 'pax' : 'room'}
                                                                      </div>
                                                                    ) : item.expanded ? (
                                                                      <div className="flex items-center text-nomadEvergreen-700">
                                                                        <FaRegArrowAltCircleUp size={20} />
                                                                      </div>
                                                                    ) : (
                                                                      <div
                                                                        className={`flex items-center ${
                                                                          item.guestsMerged.childAges.includes(-1) || item.rooms.some((room) => room.selectedAges && room.selectedAges.includes(-1))
                                                                            ? 'text-red-600'
                                                                            : 'text-nomadEvergreen-700'
                                                                        }`}
                                                                      >
                                                                        <FaRegArrowAltCircleDown size={20} />
                                                                      </div>
                                                                    )}
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            )}
                                                          </div>
                                                        )
                                                      }
                                                    </Draggable>
                                                  );
                                                })}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </div>

                                      {/* SPACER FOR BOTTOM TICKETS */}
                                      <div className="h-44"></div>
                                    </div>
                                  )}
                                  {/* //! SECTION 2: END ---------------------------------------------------------------------------------- */}
                                </div>
                                {/* LEFT COLUMN FOR PROPERTIES END */}

                                {/* RIGHT COLUMN START */}

                                {/* //! SECTION 3: RIGHT HAND COL Utitled Itinerary  ---------------------------------------------------------------------------------- */}
                                {nextTopInfo && (
                                  <div className="">
                                    {/* <div style={deeShadow} className="px-5 pt-5 bg-white rounded-lg w-[500px] h-[820px] left-[1060px] fixed"> */}

                                    <div className="sticky top-[60px]">
                                      {createEdit === 'edit' && (
                                        <div className="mb-[18px]">
                                          <ItineraryVersionMenu itinerariesVcMenu={itinerariesVcMenu} />
                                        </div>
                                      )}
                                      <div className="px-5 pt-3 bg-white rounded-lg w-[320px] border-2 h-fit top-0">
                                        {/* TABS */}
                                        {/* TODO: Deal with border color and dynamic colors */}
                                        <ItineraryNameEditor
                                          editItineraryName={editItineraryName}
                                          itineraryName={itineraryName}
                                          handleTextChange={handleTextChange}
                                          handleItinerarySaveClick={handleItinerarySaveClick}
                                          handleEditItineraryClick={handleEditItineraryClick}
                                        />

                                        {createEdit === 'edit' && (
                                          <div className="flex pt-3">
                                            <MdNumbers size={20} />
                                            <div className="ml-2 text-zinc-800 text-sm font-normal">{itineraryStore.code ? 'Reference: ' + itineraryStore.code : ''}</div>
                                          </div>
                                        )}

                                        <div className="flex pt-3">
                                          <MdOutlineSupportAgent size={20} />
                                          <div className="ml-2 text-zinc-800 text-sm font-normal">{selectedAgentName}</div>
                                        </div>

                                        {clientName !== '' && (
                                          <div className="flex pt-3">
                                            <CiGlobe size={20} />
                                            <div className="ml-2 text-zinc-800 text-sm font-normal">{clientName}</div>
                                          </div>
                                        )}

                                        {createEdit === 'edit' && (
                                          <div className="flex pt-3">
                                            <GrStatusInfo size={20} />
                                            <div className="ml-2 text-zinc-800 text-sm font-normal">{itineraryStatus}</div>
                                          </div>
                                        )}

                                        <GuestCount bookings={bookings} />

                                        {bookings.length > 0 && <DateRangeComp bookings={bookings} />}
                                        {bookings.length > 0 && <NightCount bookings={bookings} />}

                                        <div className="flex text-left mt-5 border-b border-gray-300 w-full "></div>
                                        {bookings.length > 0 ? (
                                          <div className="mt-5">
                                            <div className="flex brand-text-grey-1-v2">
                                              {/* Total Properties */}
                                              {!fetchingRates && !refreshAllRates && !rooms.some((room) => room.loading) ? (
                                                <div className="flex justify-between w-full">
                                                  <div className={`text-left text-sm text-zinc-800`}>Total:</div>
                                                  <div className="text-black text-lg font-medium">${formatPrice(getTotalFromBookings(bookings))}</div>
                                                </div>
                                              ) : (
                                                <div className="flex justify-between w-full">
                                                  <div className={`text-left text-sm text-zinc-800`}>Total:</div>
                                                  <div className="text-black text-lg font-medium">{fetchingRates ? 'Fetching Rates' : '$0.00'}</div>
                                                </div>
                                              )}
                                            </div>

                                            {
                                              // !firstLoadAvailability && refreshAllRates && bookings.some((booking) => booking.rooms.length !== 0) && (
                                              // !firstLoadAvailability && refreshAllRates && bookings.every((booking) => !booking.addRoom && booking.rooms.every((room) => !room.edit)) && (
                                              (refreshBtn ||
                                                //                                                (!firstLoadAvailability && refreshAllRates && bookings.every((booking) => !booking.addRoom && booking.rooms.every((room) => !room.edit)))) && (
                                                (refreshAllRates && bookings.every((booking) => !booking.addRoom && booking.rooms.every((room) => !room.edit)))) &&
                                                !rooms.some((room) => room.loading) && (
                                                  <div className="flex-1 text-center mt-3 cursor-pointer">
                                                    <button
                                                      className="ml-auto bg-white font-normal text-base text-nomadEvergreen-700 py-1 px-4 h-10 w-fit animate-pulse inline-flex items-center justify-center"
                                                      onClick={() => {
                                                        // NEW CODE TO CHECK FOR DAYROOMS - START
                                                        let isErrorDisplayed = false; // Flag to track if an error is displayed

                                                        for (const booking of bookings) {
                                                          if (!isErrorDisplayed) {
                                                            if (booking.days === 0 && booking.rateName !== 'dayRoom') {
                                                              toast.error(
                                                                `Please ensure you select 'Day Room' in both the 'Rate Name' and 'Total Nights' dropdowns to proceed for ${booking.propName}`,
                                                              );
                                                              isErrorDisplayed = true; // Set the flag to true when the error is displayed
                                                              break; // Stop the loop
                                                            }
                                                            if (booking.rateName === 'dayRoom' && booking.days !== 0) {
                                                              toast.error(
                                                                `Please ensure you select 'Day Room' in both the 'Rate Name' and 'Total Nights' dropdowns to proceed for ${booking.propName}`,
                                                              );
                                                              isErrorDisplayed = true; // Set the flag to true when the error is displayed
                                                              break; // Stop the loop
                                                            }
                                                          }
                                                        }

                                                        if (isErrorDisplayed) return;
                                                        // NEW CODE TO CHECK FOR DAYROOMS - END

                                                        // NEW CODE TO CHECK FOR DAYROOMS - START
                                                        let isErrorDisplayedRate = false; // Flag to track if an error is displayed

                                                        for (const booking of bookings) {
                                                          if (!isErrorDisplayedRate) {
                                                            if (booking.rateName === 'select') {
                                                              toast.error(`Please select a rate for ${booking.propName}`);
                                                              isErrorDisplayedRate = true; // Set the flag to true when the error is displayed
                                                              break; // Stop the loop
                                                            }
                                                          }
                                                        }
                                                        if (isErrorDisplayedRate) return;
                                                        // NEW CODE TO CHECK FOR DAYROOMS - END
                                                        if (
                                                          bookings.every(
                                                            (booking) =>
                                                              booking.rooms &&
                                                              Array.isArray(booking.rooms) &&
                                                              booking.rooms.length > 0 &&
                                                              !booking.addRoom &&
                                                              booking.rooms.every((room) => !room.edit) &&
                                                              // Add check for exclusive room
                                                              !(booking.rooms.length === 1 && booking.rooms[0].roomType === 'Exclusive'),
                                                          )
                                                        ) {
                                                          setRefreshAllRates(false);
                                                          setFetchingRates(true);
                                                          refreshRates(
                                                            bookings,
                                                            setBookings,
                                                            setRefreshBtn,
                                                            properties,
                                                            setFetchingRates,
                                                            agentRates,
                                                            agentCommission,
                                                            guests,
                                                            agentObject,
                                                            createVersionCheck,
                                                            createEdit,
                                                            user,
                                                            isFamTrip,
                                                            commissionAgent,
                                                            commissionUser,
                                                            dateCreated,
                                                          );
                                                        } else {
                                                          if (bookings.some((booking) => booking.rooms.length === 1 && booking.rooms[0].roomType === 'Exclusive')) {
                                                            toast.error('Add rooms to Exclusive properties');
                                                          } else {
                                                            toast.error('Please complete all rooms before refreshing rates');
                                                          }
                                                        }
                                                      }}
                                                    >
                                                      <FiRefreshCw className="mr-2" />
                                                      Click to update price
                                                    </button>

                                                    <button
                                                      className="w-full bg-gray-300 text-gray-500 text-sm font-semibold py-3 mt-8 rounded-lg shadow cursor-not-allowed"
                                                      onClick={async () => {
                                                        console.log('clicked');
                                                      }}
                                                      disabled={true}
                                                    >
                                                      Next: Add{' '}
                                                      {((user.internalUser === false &&
                                                        bookings.every((booking) => !booking.addRoom && booking.rooms.length !== 0 && booking.rooms.every((room) => !room.edit))) ||
                                                        mojoItinerary === true) &&
                                                      !semver.gte(createVersionCheck, '2.5.0')
                                                        ? 'Activities'
                                                        : ' Transfers'}
                                                    </button>
                                                  </div>
                                                )
                                            }

                                            {!fetchingRates && !refreshAllRates && !rooms.some((room) => room.loading) && (
                                              <>
                                                {
                                                  // (!user.internalUser && user.fullBoardOnly) || !user.betaTester || mojoItinerary === true ? (
                                                  // semver.gte(createVersionCheck, '2.5.0')
                                                  // (createEdit === 'edit' && semver.gte(createVersionCheck, '2.5.0'))

                                                  // (user.accessLevel !== 'full' &&
                                                  //   bookings.every((booking) => !booking.addRoom && booking.rooms.length !== 0 && booking.rooms.every((room) => !room.edit))) ||
                                                  // mojoItinerary === true ? (
                                                  //   !refreshBtn ? (

                                                  ((user.internalUser === false &&
                                                    bookings.every((booking) => !booking.addRoom && booking.rooms.length !== 0 && booking.rooms.every((room) => !room.edit))) ||
                                                    mojoItinerary === true) &&
                                                  !semver.gte(createVersionCheck, '2.5.0') ? (
                                                    !refreshBtn ? (
                                                      // GO TO ACTIVITIES
                                                      <button
                                                        className="w-full bg-nomadEvergreen-700 text-white text-sm font-semibold py-3 mt-8 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadEvergreen-800 hover:text-white"
                                                        onClick={async () => {
                                                          if (validateBookingRules(bookings, properties, user, customLocations, setQuoteMode, setStep, setBookings)) return;

                                                          console.log(refreshBtn);
                                                          if (fetchingRates) {
                                                            return toast.error(`Rates are still being fetched, please wait a few seconds then click "Confirm" again`);
                                                          }
                                                          //await saveDataToDatabase();

                                                          // BUILD TRANSFER DATA FOR ACTIVITIES PAGE START

                                                          if (checkBookingArrays(bookings, bookingsStore) && transfersLatest.length > 0) {
                                                            setLoadingData(true);
                                                            console.log('TRANSFERS 1');
                                                            setTransfers(transfersLatest);
                                                            for (const booking of bookings) {
                                                              booking.rebuild = false;
                                                            }
                                                            setBookings((bookings) => [...bookings]);
                                                            setStep('transfers');
                                                            setLoadingData(false);
                                                            // END
                                                          } else {
                                                            console.log('TRANSFERS 2');
                                                            setLoadingData(true);

                                                            const newTransfers = await createTransfersArray(bookings, createTransferObject, transfers);

                                                            console.log('CHECK newTransfers');
                                                            console.log(JSON.stringify(newTransfers, undefined, 4));

                                                            // Deal with Safari transfers - START --------------------------------------------------------------------------------
                                                            async function allTransfers() {
                                                              const propertyTransfers = newTransfers.filter((transfer) => transfer.objType === 'propertyTransfer');
                                                              const firstIndex = newTransfers.findIndex((transfer) => transfer === propertyTransfers[0]);

                                                              const lastIndex = newTransfers.findIndex((transfer) => transfer === propertyTransfers[propertyTransfers.length - 1]);

                                                              for (let index = 0; index < newTransfers.length; index++) {
                                                                const transfer = newTransfers[index];

                                                                if (transfer.objType === 'arrival') {
                                                                  // do something
                                                                } else if (transfer.objType === 'arrivalTransfer') {
                                                                  // do something else
                                                                } else if (transfer.objType === 'propertyTransfer') {
                                                                  if (transfer.tuuid === propertyTransfers[0].tuuid && index === firstIndex) {
                                                                    // do something special for the first propertyTransfer

                                                                    if (bookings.length > 1) {
                                                                      // console.log('availableTransfers 1');
                                                                      // console.log(transfer.propUid);
                                                                      // console.log(JSON.stringify(transfer, undefined, 4));

                                                                      await availableTransfers(
                                                                        transfer,
                                                                        newTransfers,
                                                                        index,
                                                                        properties,
                                                                        'propertyTransfer',
                                                                        setTransfers,
                                                                        airports,
                                                                        transfers,
                                                                        locations,
                                                                        customLocations,
                                                                      );
                                                                    }

                                                                    // SAFARI VEHICLES END
                                                                  } else if (transfer.tuuid === propertyTransfers[propertyTransfers.length - 1].tuuid && index === lastIndex) {
                                                                    // do something special for the last propertyTransfer
                                                                  } else {
                                                                    // do yet another thing for all other propertyTransfers

                                                                    if (bookings.length > 1) {
                                                                      console.log('availableTransfers 1');
                                                                      console.log(transfer.propUid);
                                                                      console.log(JSON.stringify(transfer, undefined, 4));
                                                                      await availableTransfers(
                                                                        transfer,
                                                                        newTransfers,
                                                                        index,
                                                                        properties,
                                                                        'propertyTransfer',
                                                                        setTransfers,
                                                                        airports,
                                                                        transfers,
                                                                        locations,
                                                                        customLocations,
                                                                      );
                                                                    }

                                                                    // SAFARI VEHICLES END
                                                                  }
                                                                } else if (transfer.objType === 'departureTransfer') {
                                                                  // do something completely different
                                                                } else if (transfer.objType === 'departure') {
                                                                  // do one more thing
                                                                }
                                                              }
                                                              // REBUILD PROCESS
                                                              if (!checkBookingArrays(bookings, bookingsStore) && transfers.length > 0) {
                                                                // PROCESS REBUILD OF TRANSFERS
                                                                console.log('TRANSFERS 3');
                                                                const { newTransfersData, newTransfersLatest } = await updateTransfers(
                                                                  bookings,
                                                                  newTransfers,
                                                                  transfersLatest,
                                                                  properties,
                                                                  setTransfers,
                                                                  airports,
                                                                  locations,
                                                                  customLocations,
                                                                  setBookings,
                                                                  bookingsStore,
                                                                );

                                                                setTransfers(newTransfersData);
                                                                setTransfersLatest(newTransfersLatest);
                                                                setLoadingData(false);

                                                                // return setStep('transfers');
                                                              }

                                                              setLoadingData(false);
                                                              //setStep("transfers");

                                                              setTransfers(newTransfers);
                                                            }
                                                            // Deal with Safari transfers - END
                                                            console.log('FIX 1 :', newTransfers);
                                                            await allTransfers(newTransfers);
                                                            for (const booking of bookings) {
                                                              booking.rebuild = false;
                                                            }
                                                            setBookings((bookings) => [...bookings]);
                                                            hasRunForTransfers.current = false;

                                                            // END
                                                          }
                                                          // rebuild
                                                          console.log('CHECKING BOOKINGS');
                                                          console.log('bookings.length ', bookings.length); // Should print 2
                                                          console.log('bookingsStore.length ', bookingsStore.length); // Should print 1
                                                          if (checkBookingArrays(bookings, bookingsStore) && activitiesDataLatest.length > 0) {
                                                            console.log('ACTIVITIES 1');
                                                            // Set to activities
                                                            // START
                                                            setIsLoading(true);

                                                            // START
                                                            setActivitiesData(activitiesDataLatest);
                                                            setIsLoading(true);
                                                            setActivitiesList([]);
                                                            setSelectedActivityProperty(null);
                                                            setSelectedActivityPropertyUid(null);
                                                            setSelectedActivityDay(null);
                                                            setSelectedActivityDate(null);
                                                            loadActivities(bookings, setActivities, setIsLoading, customLocations, user);
                                                            setStep('activities');
                                                            // END
                                                          } else if (!checkBookingArrays(bookings, bookingsStore) && activitiesData.length > 0) {
                                                            // START
                                                            setIsLoading(true);
                                                            //const tempActivitiesData = updateActivitiesData(bookings, activitiesData);
                                                            const resetActivitiesData = updateActivityDates(bookings, activitiesData);
                                                            const { newActivitiesData, removedActivities } = updateActivitiesData(bookings, activitiesData, rooms, properties);
                                                            //const { newActivitiesData, removedActivities } = updateActivitiesData(bookings, activitiesData, rooms, properties);
                                                            setActivitiesData(newActivitiesData);
                                                            setActivitiesDataRemoved(removedActivities);

                                                            console.log('ACTIVITIES 2');
                                                            console.log(JSON.stringify(newActivitiesData, undefined, 4));
                                                            console.log('removedActivities');
                                                            console.log(JSON.stringify(removedActivities, undefined, 4));

                                                            // Set to activities

                                                            //setActivitiesData(tempActivitiesData);
                                                            setIsLoading(true);
                                                            setActivitiesList([]);
                                                            setSelectedActivityProperty(null);
                                                            setSelectedActivityPropertyUid(null);
                                                            setSelectedActivityDay(null);
                                                            setSelectedActivityDate(null);
                                                            loadActivities(bookings, setActivities, setIsLoading, customLocations, user);
                                                            setStep('activities');
                                                            // END
                                                          } else {
                                                            console.log('ACTIVITIES 3');
                                                            // START
                                                            setIsLoading(true);

                                                            const activitiesDataTemp = bookings.map((booking) => {
                                                              const { propUid, days, id } = booking;
                                                              const activities = Array.from(
                                                                {
                                                                  length: days + 1,
                                                                },
                                                                () => [],
                                                              );
                                                              console.log('id ', id);
                                                              return {
                                                                propUid,
                                                                id,
                                                                activities,
                                                              };
                                                            });
                                                            setActivitiesData(activitiesDataTemp);

                                                            setActivitiesList([]);
                                                            setSelectedActivityProperty(null);
                                                            setSelectedActivityPropertyUid(null);
                                                            setSelectedActivityDay(null);
                                                            setSelectedActivityDate(null);
                                                            setActivitiesCost(0);
                                                            loadActivities(bookings, setActivities, setIsLoading, customLocations, user);
                                                            setStep('activities');
                                                            // END
                                                          }
                                                        }}
                                                      >
                                                        Next: Add Activities
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="w-full bg-gray-300 text-gray-500 text-sm font-semibold py-3 mt-8 rounded-lg shadow cursor-not-allowed"
                                                        onClick={async () => {
                                                          console.log('clicked');
                                                        }}
                                                        disabled={true}
                                                      >
                                                        Next: Add Activities
                                                      </button>
                                                    )
                                                  ) : (
                                                    // GO TO TRANSFERS
                                                    !refreshBtn &&
                                                    bookings.every((booking) => !booking.addRoom && booking.rooms.length !== 0 && booking.rooms.every((room) => !room.edit)) && (
                                                      <button
                                                        className="w-full bg-nomadEvergreen-700 text-white text-sm font-semibold py-3 mt-8 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadEvergreen-800 hover:text-white"
                                                        onClick={async () => {
                                                          if (validateBookingRules(bookings, properties, user, customLocations, setQuoteMode, setStep, setBookings)) return;

                                                          if (checkBookingArrays(bookings, bookingsStore) && transfersLatest.length > 0) {
                                                            setLoadingData(true);
                                                            console.log('TRANSFERS 1');
                                                            setTransfers(transfersLatest);
                                                            for (const booking of bookings) {
                                                              booking.rebuild = false;
                                                            }
                                                            setBookings((bookings) => [...bookings]);
                                                            setStep('transfers');
                                                            setLoadingData(false);
                                                            // END
                                                          } else {
                                                            console.log('TRANSFERS 2');
                                                            setLoadingData(true);

                                                            // Rebuild
                                                            // if (checkBookingArrays(bookings, bookingsStore) && transfersDataLatest.length > 0) {

                                                            // const getCustomLocations =
                                                            // 	await buildCustomLocations(
                                                            // 		bookings,
                                                            // 		properties
                                                            // 	);
                                                            // setCustomLocations(
                                                            // 	getCustomLocations
                                                            // );

                                                            const newTransfers = await createTransfersArray(bookings, createTransferObject, transfers);

                                                            console.log('CHECK newTransfers');
                                                            console.log(JSON.stringify(newTransfers, undefined, 4));

                                                            // Deal with Safari transfers - START
                                                            async function allTransfers() {
                                                              const propertyTransfers = newTransfers.filter((transfer) => transfer.objType === 'propertyTransfer');
                                                              const firstIndex = newTransfers.findIndex((transfer) => transfer === propertyTransfers[0]);

                                                              const lastIndex = newTransfers.findIndex((transfer) => transfer === propertyTransfers[propertyTransfers.length - 1]);

                                                              for (let index = 0; index < newTransfers.length; index++) {
                                                                const transfer = newTransfers[index];

                                                                if (transfer.objType === 'arrival') {
                                                                  // do something
                                                                } else if (transfer.objType === 'arrivalTransfer') {
                                                                  // do something else
                                                                } else if (transfer.objType === 'propertyTransfer') {
                                                                  if (transfer.tuuid === propertyTransfers[0].tuuid && index === firstIndex) {
                                                                    // do something special for the first propertyTransfer

                                                                    if (bookings.length > 1) {
                                                                      // console.log('availableTransfers 1');
                                                                      // console.log(transfer.propUid);
                                                                      // console.log(JSON.stringify(transfer, undefined, 4));

                                                                      await availableTransfers(
                                                                        transfer,
                                                                        newTransfers,
                                                                        index,
                                                                        properties,
                                                                        'propertyTransfer',
                                                                        setTransfers,
                                                                        airports,
                                                                        transfers,
                                                                        locations,
                                                                        customLocations,
                                                                      );
                                                                    }

                                                                    // SAFARI VEHICLES END
                                                                  } else if (transfer.tuuid === propertyTransfers[propertyTransfers.length - 1].tuuid && index === lastIndex) {
                                                                    // do something special for the last propertyTransfer
                                                                  } else {
                                                                    // do yet another thing for all other propertyTransfers

                                                                    if (bookings.length > 1) {
                                                                      console.log('availableTransfers 1');
                                                                      console.log(transfer.propUid);
                                                                      console.log(JSON.stringify(transfer, undefined, 4));
                                                                      await availableTransfers(
                                                                        transfer,
                                                                        newTransfers,
                                                                        index,
                                                                        properties,
                                                                        'propertyTransfer',
                                                                        setTransfers,
                                                                        airports,
                                                                        transfers,
                                                                        locations,
                                                                        customLocations,
                                                                      );
                                                                    }

                                                                    // SAFARI VEHICLES END
                                                                  }
                                                                } else if (transfer.objType === 'departureTransfer') {
                                                                  // do something completely different
                                                                } else if (transfer.objType === 'departure') {
                                                                  // do one more thing
                                                                }
                                                              }
                                                              // REBUILD PROCESS
                                                              if (!checkBookingArrays(bookings, bookingsStore) && transfers.length > 0) {
                                                                // PROCESS REBUILD OF TRANSFERS
                                                                console.log('TRANSFERS 3');
                                                                const { newTransfersData, newTransfersLatest } = await updateTransfers(
                                                                  bookings,
                                                                  newTransfers,
                                                                  transfersLatest,
                                                                  properties,
                                                                  setTransfers,
                                                                  airports,
                                                                  locations,
                                                                  customLocations,
                                                                  setBookings,
                                                                  bookingsStore,
                                                                );

                                                                setTransfers(newTransfersData);
                                                                setTransfersLatest(newTransfersLatest);
                                                                setLoadingData(false);

                                                                return setStep('transfers');
                                                              }

                                                              setLoadingData(false);
                                                              //setStep("transfers");

                                                              setTransfers(newTransfers);
                                                            }
                                                            // Deal with Safari transfers - END
                                                            console.log('FIX 1 :', newTransfers);
                                                            await allTransfers(newTransfers);
                                                            for (const booking of bookings) {
                                                              booking.rebuild = false;
                                                            }
                                                            setBookings((bookings) => [...bookings]);
                                                            hasRunForTransfers.current = false;

                                                            setStep('transfers');
                                                            //setReloadVehicles(true);

                                                            // END
                                                          }
                                                        }}
                                                      >
                                                        Next: Add Transfers
                                                      </button>
                                                    )
                                                  )
                                                }
                                              </>
                                            )}
                                          </div>
                                        ) : (
                                          <button
                                            className="w-full bg-gray-300 text-gray-500 text-sm font-semibold py-3 mt-8 rounded-lg shadow cursor-not-allowed"
                                            onClick={async () => {
                                              console.log('clicked');
                                            }}
                                            disabled={true}
                                          >
                                            Next: Add Transfers
                                          </button>
                                        )}

                                        <div className="flex text-left  w-full my-5 "></div>
                                      </div>

                                      {/* LEGEND */}
                                      {/* <div style={deeShadow} className="px-5 pt-3 bg-white rounded-lg w-[320px] h-fit top-0 mt-5">

    <div className="flex text-left">
      <div className="text-zinc-800 text-sm font-normal">LEGEND</div>
    </div>
    <div className="flex items-center space-x-4 my-3">
      <div className="flex items-center">
        <div className="w-2 h-2 bg-green-600"></div>
        <span className="ml-2 text-green-600 text-xs">Available</span>
      </div>
      <div className="flex items-center">
        <div className="w-2 h-2 bg-orange-600"></div>
        <span className="ml-2 text-orange-600 text-xs">Unavailable</span>
      </div>
      <div className="flex items-center">
        <div className="w-2 h-2 bg-slate-400"></div>
        <span className="ml-2 text-slate-400 text-xs">Unknown</span>
      </div>
    </div>
    <div className="flex text-left w-full my-5 "></div>
  </div> */}
                                    </div>
                                    <PropertiesSlideOutComp
                                      isOpen={isSlideOutPropertiesOpen}
                                      onClose={handleClosePropertiesSlideOut}
                                      deeShadow={deeShadow}
                                      searchClient={searchClient}
                                      searchState={searchState}
                                      setSearchState={setSearchState}
                                      filters={
                                        user.accessLevel === 'travelAgent'
                                          ? `active:true AND park.parentParkId:"${switchParkId}"`
                                          : switchParkId === null
                                          ? filters
                                          : filters + ` AND park.parentParkId:"${switchParkId}"`
                                      }
                                      //filters={user.accessLevel !== 'travelAgent' ? filters : 'active:true AND park.id:"fab842f3-c1d1-403f-9072-bd322a70e88a"'}
                                      properties={properties}
                                      setLoadingData={setLoadingData}
                                      setCallMakeRooms={setCallMakeRooms}
                                      SetGetPropertiesAndStock={SetGetPropertiesAndStock}
                                      customLocations={customLocations}
                                      setFetchCustom={setFetchCustom}
                                      VirtualSearchBox={VirtualSearchBox}
                                      setSwitchParkId={setSwitchParkId}
                                      setSwitchId={setSwitchId}
                                      setSwitchStartDate={setSwitchStartDate}
                                      setSwitchEndDate={setSwitchEndDate}
                                      // Used for rendering the property library modal
                                      onOpenPropertyModal={handleOpenPropertyModal}
                                      switchId={switchId}
                                    />
                                    {isPropertyModalOpen && <FullScreenPropertyModal isOpen={isPropertyModalOpen} onClose={handleClosePropertyModal} propertyUid={selectedPropertyUid} />}
                                  </div>
                                )}
                                {/* //! SECTION 3: PROPERTIES END  ---------------------------------------------------------------------------------- */}
                              </div>
                            )}

                            {/* RIGHT COLUMN END */}
                          </div>
                        )}

                        {/* LEFT AND RIGHT LAYOUT FOR TRANSFERS & RESULTS START */}
                        {step === 'transfers' && (
                          <div>
                            {/* SlideOuts */}

                            <div className="grid grid-flow-col auto-cols-max rounded-lg bg-white p-4">
                              {/* //! SECTION 1: START ---------------------------------------------------------------------------------- */}
                              <div className="">
                                {' '}
                                {/* PROPERTIES START */}
                                {/* //! SECTION 2: START ---------------------------------------------------------------------------------- */}
                                <div className="pr-5">
                                  <div
                                    style={{
                                      // ...deeShadow,
                                      width: `${leftColWidth}px`,
                                    }}
                                    className={`bg-white rounded-lg pb-5`}
                                  >
                                    {/* TABS */}
                                    {/* TODO: Deal with border color and dynamic colors */}
                                    <div className={`p-5 grid grid-cols-12`}>
                                      <div className="col-span-8">
                                        <div className={`text-xl brand-text-color-800 font-semibold mb-2`}>Add Transfers</div>
                                      </div>
                                    </div>
                                    {/* Arrivial details */}
                                    {/* Arrivial transfer WIP1 */}
                                    {}
                                    {transfers.map((transfer, index) => {
                                      const transferNext = index + 1 < transfers.length ? transfers[index + 1] : null;
                                      const transferCurrent = transfers.find((transfer) => transfer.objType === 'arrival');

                                      if (transfer.objType === 'arrival' && transfer.formValues && transfer.edit === false) {
                                        return (
                                          <div>
                                            <TransferCard
                                              index={index}
                                              transfer={transfer}
                                              transferNext={transferNext}
                                              properties={properties}
                                              transfers={transfers}
                                              setTransfers={setTransfers}
                                              TbTransferIn={TbTransferIn}
                                              MdOutlineModeEdit={MdOutlineModeEdit}
                                              IoMdWarning={IoMdWarning}
                                              SlPlane={SlPlane}
                                              MdAccessTime={MdAccessTime}
                                              MdDateRange={MdDateRange}
                                              GiJeep={GiJeep}
                                              CgUnavailable={CgUnavailable}
                                              FaCampground={FaCampground}
                                              MdOutlineLocationOn={MdOutlineLocationOn}
                                              BiTransfer={BiTransfer}
                                              setIsModalOpen={setIsModalOpen}
                                              airports={airports}
                                              bookings={bookings}
                                              setBookings={setBookings}
                                              handleOpenSlideOutTransferSingle={handleOpenSlideOutTransferSingle}
                                              handleOpenSlideOutTransferSingleDeparture={handleOpenSlideOutTransferSingleDeparture}
                                              handleOpenSlideOutTransferProperty={handleOpenSlideOutTransferProperty}
                                              setSelectedTransferTuuid={setSelectedTransferTuuid}
                                              booking={bookings[0]}
                                              setSelectedBooking={setSelectedBooking}
                                              setSelectedTransferSingleArrivalCurrent={setSelectedTransferSingleArrivalCurrent}
                                              setSelectedTransferSingleDepartureCurrent={setSelectedTransferSingleDepartureCurrent}
                                              setSelectedTransferCurrent={setSelectedTransferCurrent}
                                              setSelectedTransferNext={setSelectedTransferNext}
                                              setSelectedTransferPrev={setSelectedTransferPrev}
                                            />
                                          </div>
                                        );
                                      } else if (transfer.objType === 'arrival') {
                                        console.log('FEEDBACK 1');
                                        console.log('isModalOpen: ' + isModalOpen);
                                        console.log('selectedTransferTuuid: ' + selectedTransferTuuid);
                                        console.log('transfer.tuuid: ' + transfer.tuuid);
                                        return (
                                          <div key={uuidv4()}>
                                            {(selectedTransferTuuid === transfer.tuuid && isSlideOutTransferSingleOpen) || (transfer.edit && isSlideOutTransferSingleOpen) ? (
                                              <>
                                                <div id={`transfer-single-root-${transfer.tuuid}`}></div>
                                              </>
                                            ) : (
                                              <div key={uuidv4()} className="flex justify-center pb-5">
                                                <ButtonTransferAdd
                                                  onClick={async () => {
                                                    console.log('autoConfirm 1');
                                                    transfer.edit = true;
                                                    setSelectedTransferTuuid(transfer.tuuid);
                                                    setSelectedTransferSingleArrivalCurrent(transfer);
                                                    setSelectedTransferNext(transferNext);
                                                    setSelectedBooking(bookings[0]);
                                                    //setIsModalOpen(true);
                                                    handleOpenSlideOutTransferSingle();
                                                  }}
                                                  text="Add Arrival Option"
                                                />
                                              </div>
                                            )}
                                          </div>
                                        );
                                      }
                                    })}

                                    <TransferSlideOutSingle
                                      isOpen={isSlideOutTransferSingleOpen}
                                      onClose={handleCloseSlideOutTransferSingle}
                                      //transfer={transfers.find((transfer) => transfer.tuuid === selectedTransferTuuid)}
                                      transfer={selectedTransferSingleArrivalCurrent}
                                      deeShadow={deeShadow}
                                      SlPlane={SlPlane}
                                      timeRef={timeRef}
                                      MdOutlineLocationOn={MdOutlineLocationOn}
                                      FaCampground={FaCampground}
                                      CgUnavailable={CgUnavailable}
                                      BiTransfer={BiTransfer}
                                      setSelectedTransferTuuid={setSelectedTransferTuuid}
                                      airports={airports}
                                      transfers={transfers}
                                      properties={properties}
                                      freshUuid={selectedTransferTuuid}
                                      locations={locations}
                                      customLocations={customLocations}
                                      setTransfers={setTransfers}
                                      setIsModalOpen={setIsModalOpen}
                                      setRefreshTransferRates={setRefreshTransferRates}
                                      bookings={bookings}
                                      setBookings={setBookings}
                                      booking={selectedBooking}
                                      agentObject={agentObject}
                                      suppliers={suppliers}
                                      airlines={airlines}
                                      createEdit={createEdit}
                                      createVersion={createVersion}
                                      transfersDataFlights={transfersDataFlights}
                                      user={user}
                                      advancedMode={advancedMode}
                                    />

                                    {/* Arrivial transfer WIP2 */}
                                    {transfers.some((transfer) => transfer.objType === 'arrival' && transfer.formValues && transfer.formValues.type !== 'camp') &&
                                      transfers.map((transfer, index) => {
                                        const transferNext = index + 1 < transfers.length ? transfers[index + 1] : null;
                                        const transferPrev = index + 1 < transfers.length ? transfers[index - 1] : null;
                                        console.log('transfer.objType: ' + transfer.objType);
                                        console.log('transfer.formValues: ' + transfer.formValues);
                                        console.log('transfer.edit: ' + transfer.edit);
                                        console.log('selectedTransferTuuid: ' + selectedTransferTuuid);
                                        if (transfer.objType === 'arrivalTransfer' && transfer.formValues && transfer.edit === false) {
                                          return (
                                            <div>
                                              <TransferCard
                                                index={index}
                                                transfer={transfer}
                                                transferNext={transferNext}
                                                properties={properties}
                                                transfers={transfers}
                                                setTransfers={setTransfers}
                                                TbTransferIn={TbTransferIn}
                                                MdOutlineModeEdit={MdOutlineModeEdit}
                                                IoMdWarning={IoMdWarning}
                                                SlPlane={SlPlane}
                                                MdAccessTime={MdAccessTime}
                                                MdDateRange={MdDateRange}
                                                GiJeep={GiJeep}
                                                CgUnavailable={CgUnavailable}
                                                FaCampground={FaCampground}
                                                MdOutlineLocationOn={MdOutlineLocationOn}
                                                BiTransfer={BiTransfer}
                                                setIsModalOpen={setIsModalOpen}
                                                airports={airports}
                                                bookings={bookings}
                                                setBookings={setBookings}
                                                handleOpenSlideOutTransferSingle={handleOpenSlideOutTransferSingle}
                                                handleOpenSlideOutTransferSingleDeparture={handleOpenSlideOutTransferSingleDeparture}
                                                handleOpenSlideOutTransferProperty={handleOpenSlideOutTransferProperty}
                                                setSelectedTransferTuuid={setSelectedTransferTuuid}
                                                booking={bookings[0]}
                                                setSelectedBooking={setSelectedBooking}
                                                setSelectedTransferSingleArrivalCurrent={setSelectedTransferSingleArrivalCurrent}
                                                setSelectedTransferSingleDepartureCurrent={setSelectedTransferSingleDepartureCurrent}
                                                setSelectedTransferCurrent={setSelectedTransferCurrent}
                                                setSelectedTransferNext={setSelectedTransferNext}
                                                setSelectedTransferPrev={setSelectedTransferPrev}
                                              />
                                            </div>
                                          );
                                        }
                                      })}
                                    {/* LAYOUT PROPERTIES CARD */}
                                    <div className={`px-5 pb-5`}>
                                      {bookings.map((item, index) => {
                                        const nextItem = index < bookings.length - 1 ? bookings[index + 1] : null;
                                        const prevItem = index > 0 ? bookings[index - 1] : null;

                                        return (
                                          <div key={uuidv4()}>
                                            <TransferPropertyCard
                                              item={item}
                                              properties={properties}
                                              customLocations={customLocations}
                                              generateVehicleMessage={generateVehicleMessage}
                                              getPropObj={getPropObj}
                                              bookings={bookings}
                                            />
                                            {/* WIP3 */}
                                            <div>
                                              {(() => {
                                                // SECTION 1 -> Check for arrival and arrivalTransfer logic first and
                                                // first propertyTransfer is set and if so prev propertyTransfer are set

                                                let tuuid;

                                                const transfer = transfers.find((transfer) => transfer.objType === 'propertyTransfer' && transfer.uuid === item.id);

                                                const nextTransfer = transfers.find(
                                                  (transfer) => index < bookings.length - 1 && transfer.objType === 'propertyTransfer' && transfer.uuid === nextItem.id,
                                                );

                                                const prevTransfer = prevItem
                                                  ? transfers.find((transfer) => index < bookings.length - 1 && transfer.objType === 'propertyTransfer' && transfer.uuid === prevItem.id)
                                                  : null;
                                                console.log('prevTransfer is: ', prevTransfer);

                                                if (transfer) {
                                                  tuuid = transfer.tuuid;
                                                  console.log('1 WIP3 tuuid set');
                                                }
                                                const propertyTransfers = transfers.filter((transfer) => transfer.objType === 'propertyTransfer');

                                                console.log('2 WIP3 arrival & arrivalTransfer logic matched');

                                                // Now proceed with rest of logic

                                                // SECTION 2 - possibly show transfer card
                                                const matchedTransfer = propertyTransfers[index];
                                                if (matchedTransfer && matchedTransfer.tuuid === tuuid) {
                                                  console.log('3 WIP3 matchedTransfer.tuuid', matchedTransfer.tuuid);

                                                  if (
                                                    matchedTransfer.objType === 'propertyTransfer' &&
                                                    matchedTransfer.formValues &&
                                                    matchedTransfer.edit === false
                                                    //&& selectedTransferTuuid === transfer.tuuid
                                                  ) {
                                                    console.log('4 WIP3 TransferCard');
                                                    return (
                                                      <>
                                                        <div>
                                                          <TransferCard
                                                            index={index}
                                                            transfer={matchedTransfer}
                                                            transferNext={nextTransfer}
                                                            properties={properties}
                                                            transfers={transfers}
                                                            setTransfers={setTransfers}
                                                            TbTransferIn={TbTransferIn}
                                                            MdOutlineModeEdit={MdOutlineModeEdit}
                                                            IoMdWarning={IoMdWarning}
                                                            SlPlane={SlPlane}
                                                            MdAccessTime={MdAccessTime}
                                                            MdDateRange={MdDateRange}
                                                            GiJeep={GiJeep}
                                                            CgUnavailable={CgUnavailable}
                                                            FaCampground={FaCampground}
                                                            MdOutlineLocationOn={MdOutlineLocationOn}
                                                            BiTransfer={BiTransfer}
                                                            setIsModalOpen={setIsModalOpen}
                                                            airports={airports}
                                                            bookings={bookings}
                                                            setBookings={setBookings}
                                                            handleOpenSlideOutTransferSingle={handleOpenSlideOutTransferSingle}
                                                            handleOpenSlideOutTransferSingleDeparture={handleOpenSlideOutTransferSingleDeparture}
                                                            handleOpenSlideOutTransferProperty={handleOpenSlideOutTransferProperty}
                                                            setSelectedTransferTuuid={setSelectedTransferTuuid}
                                                            booking={item}
                                                            setSelectedBooking={setSelectedBooking}
                                                            setSelectedTransferSingleArrivalCurrent={setSelectedTransferSingleArrivalCurrent}
                                                            setSelectedTransferSingleDepartureCurrent={setSelectedTransferSingleDepartureCurrent}
                                                            setSelectedTransferCurrent={setSelectedTransferCurrent}
                                                            setSelectedTransferNext={setSelectedTransferNext}
                                                            setSelectedTransferPrev={setSelectedTransferPrev}
                                                          />
                                                        </div>
                                                      </>
                                                    );
                                                  } else if ((selectedTransferTuuid === tuuid && isSlideOutTransferPropertyOpen) || (transfer.edit && isSlideOutTransferPropertyOpen)) {
                                                    return (
                                                      <>
                                                        {' '}
                                                        <div id={`transfer-inter-root-${index}`}></div>
                                                      </>
                                                    );
                                                  } else if (index < bookings.length - 1) {
                                                    console.log('5 WIP3 Show button');
                                                    return (
                                                      <div className="flex justify-center pb-5">
                                                        <ButtonTransferAdd
                                                          onClick={async () => {
                                                            console.log('TRANSFER');
                                                            console.log(JSON.stringify(transfer, undefined, 4));

                                                            //setAvailableFlights(null);

                                                            //setAvailableFlights("start");

                                                            console.log('autoConfirm 3');
                                                            transfer.edit = true;
                                                            setSelectedTransferTuuid(tuuid);

                                                            //setSelectedTransferTuuid(transfer.tuuid);
                                                            setSelectedTransferCurrent(transfer);
                                                            setSelectedTransferNext(nextTransfer);
                                                            setSelectedTransferPrev(prevTransfer);
                                                            setSelectedBooking(item);
                                                            //setIsModalOpen(true);
                                                            handleOpenSlideOutTransferProperty();

                                                            //setIsModalOpen(true);
                                                          }}
                                                          text="Add Transfer"
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                }
                                              })()}
                                            </div>

                                            {nextItem && <></>}
                                          </div>
                                        );
                                      })}

                                      {/* <TransferSlideOutInter
  isOpen={isSlideOutTransferPropertyOpen}
  onClose={handleCloseSlideOutTransferProperty}
  transfer={selectedTransferCurrent}
  nextTransfer={selectedTransferNext}
  prevTransfer={selectedTransferPrev}
  transfers={transfers}
  setTransfers={setTransfers}
  deeShadow={deeShadow}
  SlPlane={SlPlane}
  timeRef={timeRef}
  setSelectedTransferTuuid={setSelectedTransferTuuid}
  GiJeep={GiJeep}
  MdOutlineLocationOn={MdOutlineLocationOn}
  CgUnavailable={CgUnavailable}
  BiTransfer={BiTransfer}
  TbTransferIn={TbTransferIn}
  freshUuid={uuidv4()}
  routeTransfer={
    selectedTransferCurrent && selectedTransferCurrent.routeTransfer && selectedTransferCurrent.routeTransfer.available
      ? selectedTransferCurrent.routeTransfer.available
      : null
  }
  // TownTransfer={transfer.townTransfer.available}
  availableFlights={availableFlights}
  airports={airports}
  properties={properties}
  transferType={'propertyTransfer'}
  customLocations={customLocations}
  setIsModalOpen={setIsModalOpen}
  setRefreshTransferRates={setRefreshTransferRates}
  bookings={bookings}
  setBookings={setBookings}
  booking={selectedBooking}
  airlines={airlines}
  agentObject={agentObject}
  createEdit={createEdit}
  createVersion={createVersion}
  transfersDataFlights={transfersDataFlights}
  user={user}
  suppliers={suppliers}
/> */}
                                      {/* {isSlideOutTransferPropertyOpen && ( */}
                                      <TransferSlideOutInter
                                        isOpen={isSlideOutTransferPropertyOpen}
                                        onClose={handleCloseSlideOutTransferProperty}
                                        transfer={selectedTransferCurrent}
                                        nextTransfer={selectedTransferNext}
                                        prevTransfer={selectedTransferPrev}
                                        transfers={transfers}
                                        setTransfers={setTransfers}
                                        deeShadow={deeShadow}
                                        SlPlane={SlPlane}
                                        timeRef={timeRef}
                                        setSelectedTransferTuuid={setSelectedTransferTuuid}
                                        GiJeep={GiJeep}
                                        MdOutlineLocationOn={MdOutlineLocationOn}
                                        CgUnavailable={CgUnavailable}
                                        BiTransfer={BiTransfer}
                                        TbTransferIn={TbTransferIn}
                                        freshUuid={uuidv4()}
                                        routeTransfer={
                                          selectedTransferCurrent && selectedTransferCurrent.routeTransfer && selectedTransferCurrent.routeTransfer.available
                                            ? selectedTransferCurrent.routeTransfer.available
                                            : null
                                        }
                                        availableFlights={availableFlights}
                                        airports={airports}
                                        properties={properties}
                                        transferType={'propertyTransfer'}
                                        customLocations={customLocations}
                                        setIsModalOpen={setIsModalOpen}
                                        setRefreshTransferRates={setRefreshTransferRates}
                                        bookings={bookings}
                                        setBookings={setBookings}
                                        booking={selectedBooking}
                                        airlines={airlines}
                                        agentObject={agentObject}
                                        createEdit={createEdit}
                                        createVersion={createVersion}
                                        transfersDataFlights={transfersDataFlights}
                                        user={user}
                                        suppliers={suppliers}
                                        advancedMode={advancedMode}
                                      />
                                      {/* )} */}
                                    </div>
                                    {/* Departure details */}
                                    {transfers.map((transfer, index) => {
                                      const transferNext = index + 1 < transfers.length ? transfers[index + 1] : null;
                                      const transferCurrent = transfers.find((transfer) => transfer.objType === 'departure');
                                      console.log('departure transfer details: ', JSON.stringify(transferCurrent, null, 2));

                                      if (transfer.objType === 'departure' && transfer.formValues && transfer.edit === false) {
                                        return (
                                          <div key={uuidv4()}>
                                            <TransferCard
                                              index={index}
                                              transfer={transfer}
                                              transferNext={transferNext}
                                              properties={properties}
                                              transfers={transfers}
                                              setTransfers={setTransfers}
                                              TbTransferIn={TbTransferIn}
                                              MdOutlineModeEdit={MdOutlineModeEdit}
                                              IoMdWarning={IoMdWarning}
                                              SlPlane={SlPlane}
                                              MdAccessTime={MdAccessTime}
                                              MdDateRange={MdDateRange}
                                              GiJeep={GiJeep}
                                              CgUnavailable={CgUnavailable}
                                              FaCampground={FaCampground}
                                              MdOutlineLocationOn={MdOutlineLocationOn}
                                              BiTransfer={BiTransfer}
                                              setIsModalOpen={setIsModalOpen}
                                              airports={airports}
                                              bookings={bookings}
                                              setBookings={setBookings}
                                              handleOpenSlideOutTransferSingle={handleOpenSlideOutTransferSingle}
                                              handleOpenSlideOutTransferSingleDeparture={handleOpenSlideOutTransferSingleDeparture}
                                              handleOpenSlideOutTransferProperty={handleOpenSlideOutTransferProperty}
                                              setSelectedTransferTuuid={setSelectedTransferTuuid}
                                              booking={bookings[bookings.length - 1]}
                                              setSelectedBooking={setSelectedBooking}
                                              setSelectedTransferSingleArrivalCurrent={setSelectedTransferSingleArrivalCurrent}
                                              setSelectedTransferSingleDepartureCurrent={setSelectedTransferSingleDepartureCurrent}
                                              setSelectedTransferCurrent={setSelectedTransferCurrent}
                                              setSelectedTransferNext={setSelectedTransferNext}
                                              setSelectedTransferPrev={setSelectedTransferPrev}
                                            />
                                          </div>
                                        );
                                      } else if (transfer.objType === 'departure') {
                                        console.log('FEEDBACK 1');
                                        console.log('isModalOpen: ' + isModalOpen);
                                        console.log('selectedTransferTuuid: ' + selectedTransferTuuid);
                                        console.log('transfer.tuuid: ' + transfer.tuuid);
                                        return (
                                          <div key={uuidv4()}>
                                            {(selectedTransferTuuid === transfer.tuuid && isSlideOutTransferSingleOpenDeparture) || (transfer.edit && isSlideOutTransferSingleOpenDeparture) ? (
                                              <>
                                                <div id={`transfer-single-root-${transfer.tuuid}`}></div>
                                              </>
                                            ) : (
                                              transfers.map((transfer) => (
                                                <div key={transfer.id}>
                                                  {transfer.objType === 'departure' && (
                                                    <>
                                                      <div key={uuidv4()} className="flex justify-center pb-5">
                                                        <ButtonTransferAdd
                                                          onClick={() => {
                                                            console.log('autoConfirm 1');
                                                            transfer.edit = true;
                                                            // setSelectedTransferTuuid(transfer.tuuid);
                                                            // setIsModalOpen(true);

                                                            setSelectedTransferTuuid(transfer.tuuid);
                                                            //setSelectedTransferNext(transferNext);
                                                            setSelectedTransferSingleDepartureCurrent(transferCurrent);
                                                            setSelectedBooking(bookings[bookings.length - 1]);

                                                            //setIsModalOpen(true);
                                                            handleOpenSlideOutTransferSingleDeparture();
                                                          }}
                                                          text="Add Departure Option"
                                                        />
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              ))
                                            )}
                                          </div>
                                        );
                                      }
                                    })}

                                    <TransferSlideOutSingle
                                      isOpen={isSlideOutTransferSingleOpenDeparture}
                                      onClose={handleCloseSlideOutTransferSingleDeparture}
                                      //transfer={transfers.find((transfer) => transfer.tuuid === selectedTransferTuuid)}
                                      transfer={selectedTransferSingleDepartureCurrent}
                                      deeShadow={deeShadow}
                                      SlPlane={SlPlane}
                                      timeRef={timeRef}
                                      MdOutlineLocationOn={MdOutlineLocationOn}
                                      FaCampground={FaCampground}
                                      CgUnavailable={CgUnavailable}
                                      BiTransfer={BiTransfer}
                                      setSelectedTransferTuuid={setSelectedTransferTuuid}
                                      airports={airports}
                                      transfers={transfers}
                                      properties={properties}
                                      freshUuid={selectedTransferTuuid}
                                      locations={locations}
                                      customLocations={customLocations}
                                      setTransfers={setTransfers}
                                      setIsModalOpen={setIsModalOpen}
                                      setRefreshTransferRates={setRefreshTransferRates}
                                      bookings={bookings}
                                      setBookings={setBookings}
                                      booking={selectedBooking}
                                      agentObject={agentObject}
                                      suppliers={suppliers}
                                      airlines={airlines}
                                      createEdit={createEdit}
                                      createVersion={createVersion}
                                      transfersDataFlights={transfersDataFlights}
                                      user={user}
                                      advancedMode={advancedMode}
                                    />

                                    {/* // ACTIVITY RATE: START ---------------------------------------------------------------------------------- */}
                                    {bookings.length === 0 && (
                                      <>
                                        <div className={`bg-white border-2 border-dashed rounded-xl py-4 mb-10 px-5 mx-5 h-28 flex items-center justify-center`}>
                                          <h2 className="text-sm brand-text-grey-1-v2 font-normal">Please select activities to the right</h2>
                                        </div>
                                        <div className="h-0.5"></div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                {/* //! SECTION 2: END ---------------------------------------------------------------------------------- */}
                              </div>
                              {/* LEFT COLUMN FOR PROPERTIES END */}

                              {/* RIGHT COLUMN START */}
                              <div className="">
                                <div className="sticky top-[60px]">
                                  {createEdit === 'edit' && (
                                    <div className="mb-[18px]">
                                      <ItineraryVersionMenu itinerariesVcMenu={itinerariesVcMenu} />
                                    </div>
                                  )}
                                  {/* <div style={deeShadow} className="px-5 pt-5 bg-white rounded-lg w-[500px] h-[820px] left-[1060px] fixed"> */}
                                  <div className="px-5 pt-3 bg-white rounded-lg w-[320px] border-2 h-fit top-0">
                                    {/* TABS */}
                                    {/* TODO: Deal with border color and dynamic colors */}
                                    <ItineraryNameEditor
                                      editItineraryName={editItineraryName}
                                      itineraryName={itineraryName}
                                      handleTextChange={handleTextChange}
                                      handleItinerarySaveClick={handleItinerarySaveClick}
                                      handleEditItineraryClick={handleEditItineraryClick}
                                    />

                                    {createEdit === 'edit' && (
                                      <div className="flex pt-3">
                                        <MdNumbers size={20} />
                                        <div className="ml-2 text-zinc-800 text-sm font-normal">{itineraryStore.code ? 'Reference: ' + itineraryStore.code : ''}</div>
                                      </div>
                                    )}

                                    <div className="flex pt-3">
                                      <MdOutlineSupportAgent size={20} />
                                      <div className="ml-2 text-zinc-800 text-sm font-normal">{selectedAgentName}</div>
                                    </div>

                                    {clientName !== '' && (
                                      <div className="flex pt-3">
                                        <CiGlobe size={20} />
                                        <div className="ml-2 text-zinc-800 text-sm font-normal">{clientName}</div>
                                      </div>
                                    )}

                                    {createEdit === 'edit' && (
                                      <div className="flex pt-3">
                                        <GrStatusInfo size={20} />
                                        <div className="ml-2 text-zinc-800 text-sm font-normal">{itineraryStatus}</div>
                                      </div>
                                    )}

                                    <GuestCount bookings={bookings} />

                                    {bookings.length > 0 && <DateRangeComp bookings={bookings} />}
                                    {bookings.length > 0 && <NightCount bookings={bookings} />}

                                    <div className="flex text-left mt-5 border-b border-gray-300 w-full "></div>

                                    {/* ADD TRANSFER PRICING ETC */}

                                    {bookings.length > 0 ? (
                                      <div className="mt-5">
                                        <div className="flex brand-text-grey-1-v2">
                                          {/* Total Transfers */}
                                          {!fetchingRates ? (
                                            <div className="flex justify-between w-full">
                                              <div className={`text-left text-sm text-zinc-800`}>Total:</div>
                                              <div className="text-black text-lg font-medium">
                                                ${formatPrice(getTotalFromBookings(bookings) + getTotalFromTransfers(transfers) + getTotalFromBookingsForVehicles(bookings, properties))}
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="flex justify-between w-full">
                                              <div className={`text-left text-sm text-zinc-800`}>Total:</div>
                                              <div className="text-black text-lg font-medium">{fetchingRates ? 'Fetching Rates' : '$0.00'}</div>
                                            </div>
                                          )}
                                        </div>
                                        {
                                          //refreshTransferRates && bookings.some((booking) => booking.rooms.length !== 0) && (
                                          (refreshBtn || (refreshTransferRates && bookings.every((booking) => !booking.addRoom && booking.rooms.every((room) => !room.edit)))) && (
                                            <div className="flex-1 text-right mr-4 mt-3 cursor-pointer">
                                              <button
                                                className="ml-auto bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit animate-pulse"
                                                onClick={() => {
                                                  // setRefreshTransferRates(false);
                                                  // setFetchingRates(true);
                                                  //getFares(transfersData, setTransfersData, setFetchingRates);
                                                  //refreshTransferRates(transfersData, setTransfersData, setFetchingRates);
                                                }}
                                              >
                                                Click to refresh rates
                                              </button>
                                            </div>
                                          )
                                        }
                                        {!refreshBtn && !fetchingRates && !refreshTransferRates && !rooms.some((room) => room.loading) && (
                                          <div className="flex flex-col items-end gap-4">
                                            <button
                                              className="w-full bg-nomadEvergreen-700 text-white text-sm font-semibold py-3 mt-8 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadEvergreen-800 hover:text-white"
                                              onClick={async () => {
                                                //Check call transfers are complete
                                                const checkTransfers = (transfers) => {
                                                  let lastPropertyTransfer = transfers
                                                    .slice()
                                                    .reverse()
                                                    .find((obj) => obj.objType === 'propertyTransfer');
                                                  let arrivalFormValues = transfers.find((obj) => obj.objType === 'arrival' && obj.formValues);
                                                  //let arrivalFormValuesIsCamp = arrivalFormValues && arrivalFormValues.formValues.type === "camp";

                                                  for (let transfer of transfers) {
                                                    if (transfer.objType === 'arrival' || transfer.objType === 'departure') {
                                                      if (!transfer.formValues) {
                                                        return false;
                                                      }
                                                    } else if (transfer.objType === 'arrivalTransfer') {
                                                      if (!transfer.formValues && !arrivalFormValues) {
                                                        return false;
                                                      }
                                                    } else if (transfer.objType === 'propertyTransfer') {
                                                      if (!transfer.formValues && transfer !== lastPropertyTransfer) {
                                                        return false;
                                                      }
                                                    }
                                                  }
                                                  return true;
                                                };

                                                // ! Transfers check - START
                                                if (!checkTransfers(transfers)) {
                                                  //return toast.error(`Please complete all transfers`);
                                                }
                                                // ! Transfers check - END

                                                // ! hasInvalidTransfers check - START
                                                const hasInvalidTransfers = transfers.some((transfer) => transfer.isInvalid === true);
                                                console.log(hasInvalidTransfers);
                                                if (hasInvalidTransfers) {
                                                  return toast.error('Please delete invalid transfers');
                                                }
                                                // ! hasInvalidTransfers check - END

                                                console.log(refreshBtn);
                                                if (fetchingRates) {
                                                  return toast.error(`Rates are still being fetched, please wait a few seconds then click "Confirm" again`);
                                                }
                                                //await saveDataToDatabase();

                                                // rebuild
                                                console.log('CHECKING BOOKINGS');
                                                console.log('bookings.length ', bookings.length);
                                                console.log('bookingsStore.length ', bookingsStore.length);
                                                if (checkBookingArrays(bookings, bookingsStore) && activitiesDataLatest.length > 0) {
                                                  console.log('ACTIVITIES 1');
                                                  // Set to activities
                                                  // START
                                                  setIsLoading(true);

                                                  // START
                                                  setActivitiesData(activitiesDataLatest);
                                                  setIsLoading(true);
                                                  setActivitiesList([]);
                                                  setSelectedActivityProperty(null);
                                                  setSelectedActivityPropertyUid(null);
                                                  setSelectedActivityDay(null);
                                                  setSelectedActivityDate(null);
                                                  loadActivities(bookings, setActivities, setIsLoading, customLocations, user);
                                                  setStep('activities');
                                                  // END
                                                } else if (!checkBookingArrays(bookings, bookingsStore) && activitiesData.length > 0) {
                                                  // START
                                                  setIsLoading(true);
                                                  //const tempActivitiesData = updateActivitiesData(bookings, activitiesData);

                                                  const resetActivitiesData = updateActivityDates(bookings, activitiesData);
                                                  const { newActivitiesData, removedActivities } = updateActivitiesData(bookings, resetActivitiesData, rooms, properties);
                                                  //const { newActivitiesData, removedActivities } = updateActivitiesData(bookings, activitiesData, rooms, properties);
                                                  setActivitiesData(newActivitiesData);
                                                  setActivitiesDataRemoved(removedActivities);

                                                  console.log('ACTIVITIES 2');
                                                  console.log(JSON.stringify(newActivitiesData, undefined, 4));
                                                  console.log('removedActivities');
                                                  console.log(JSON.stringify(removedActivities, undefined, 4));

                                                  // Set to activities

                                                  //setActivitiesData(tempActivitiesData);
                                                  setIsLoading(true);
                                                  setActivitiesList([]);
                                                  setSelectedActivityProperty(null);
                                                  setSelectedActivityPropertyUid(null);
                                                  setSelectedActivityDay(null);
                                                  setSelectedActivityDate(null);
                                                  loadActivities(bookings, setActivities, setIsLoading, customLocations, user);
                                                  setStep('activities');
                                                  // END
                                                } else {
                                                  console.log('ACTIVITIES 3');
                                                  // START
                                                  setIsLoading(true);

                                                  const activitiesDataTemp = bookings.map((booking) => {
                                                    const { propUid, days, id } = booking;
                                                    const activities = Array.from(
                                                      {
                                                        length: days + 1,
                                                      },
                                                      () => [],
                                                    );
                                                    console.log('id ', id);
                                                    return {
                                                      propUid,
                                                      id,
                                                      activities,
                                                    };
                                                  });
                                                  setActivitiesData(activitiesDataTemp);

                                                  setActivitiesList([]);
                                                  setSelectedActivityProperty(null);
                                                  setSelectedActivityPropertyUid(null);
                                                  setSelectedActivityDay(null);
                                                  setSelectedActivityDate(null);
                                                  setActivitiesCost(0);
                                                  loadActivities(bookings, setActivities, setIsLoading, customLocations, user);
                                                  setStep('activities');
                                                  // END
                                                }
                                              }}
                                            >
                                              Next: Add Activities
                                            </button>
                                            <button
                                              className="w-full bg-white text-nomadEvergreen-700 border border-gray-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadEvergreen-800 hover:border-nomadGray-900 flex items-center justify-center"
                                              onClick={async () => {
                                                confirmAlert({
                                                  customUI: ({ onClose }) => {
                                                    return (
                                                      <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                                                        <p className="text-sm font-semibold pb-2">Would you like to go back to the properties page?</p>
                                                        <p className="text-sm pb-2">Please note, you might lose some transfers if you change details on the properties page.</p>
                                                        <div className="flex mt-5 justify-end">
                                                          <div className="flex justify-end gap-4">
                                                            <ButtonOutlineSmall
                                                              onClick={() => {
                                                                setRefreshBtn(false);

                                                                onClose();
                                                              }}
                                                              text="Cancel"
                                                              color="dark"
                                                            />
                                                            <ButtonPrimarySmall
                                                              onClick={async () => {
                                                                console.log(refreshBtn);
                                                                if (fetchingRates) {
                                                                  return toast.error(`Rates are still being fetched, please wait a few seconds then click "Confirm" again`);
                                                                }
                                                                //Activities rebuild
                                                                setBookingsStore(JSON.parse(JSON.stringify(bookings)));
                                                                setTransfersLatest([...transfers]);
                                                                setActivities([]);
                                                                setActivitiesList([]);
                                                                //setActivitiesData([]);
                                                                setSelectedActivityProperty(null);
                                                                setSelectedActivityPropertyUid(null);
                                                                setSelectedActivityDay(null);
                                                                setSelectedActivityDate(null);
                                                                setResetActivities(null);
                                                                setActivitiesCost(0);
                                                                updateBookingsNotModified(bookings, setBookings);
                                                                setStep('bookings');
                                                                onClose();
                                                              }}
                                                              text="Proceed anyway"
                                                              color="dark"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  },
                                                });
                                              }}
                                            >
                                              Back
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ) : null}

                                    <div className="flex text-left  w-full my-5 "></div>
                                  </div>

                                  {/* // SECTION 4: REMOVED TRANSFERS START  ---------------------------------------------------------------------------------- */}
                                  {transfersLatest.some((transfer) => transfer.removed === true) && (
                                    <div style={deeShadow} className="mt-5 px-5 pt-3 pb-5 bg-white rounded-lg w-[320px]">
                                      <div className={``}>
                                        {/* TABS */}
                                        {/* TODO: Deal with border color and dynamic colors */}
                                        <div className={`flex items-center pb-5`}>
                                          <div className={`text-lg font-normal brand-text-color-800 mb-0`}>Transfers removed</div>
                                        </div>
                                        <div className="text-base font-normal leading-tight pb-5 brand-text-grey-v2">
                                          Some transfers have been removed due to changing the properties order or adding/removing properties.
                                        </div>
                                        <div className="text-base font-normal leading-tight pb-1 brand-text-grey-v2 truncate text-ellipsis overflow-hidden italic">
                                          Please select transfers again to the left.
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {/* // SECTION 4: REMOVED TRANSFERS  END  ---------------------------------------------------------------------------------- */}
                                </div>

                                {/* //! SECTION 3: TRANSFERS END  ---------------------------------------------------------------------------------- */}
                              </div>

                              {/* //! SECTION 3: TRANSFERS RIGHT END  ---------------------------------------------------------------------------------- */}
                            </div>

                            {/* RIGHT COLUMN END */}
                          </div>
                        )}

                        {/* LEFT AND RIGHT LAYOUT FOR ACTIVITIES & RESULTS START */}
                        {step === 'activities' && (
                          <div>
                            <div className="grid grid-flow-col auto-cols-max rounded-lg bg-white p-4">
                              {/* //! SECTION 1: START ---------------------------------------------------------------------------------- */}
                              <div className="">
                                {' '}
                                {/* PROPERTIES START */}
                                {/* //! SECTION 2: START ---------------------------------------------------------------------------------- */}
                                <div className="pr-5">
                                  <div
                                    style={{
                                      // ...deeShadow,
                                      width: `${leftColWidth}px`,
                                    }}
                                    className={`bg-white rounded-lg`}
                                  >
                                    {/* TABS */}
                                    {/* TODO: Deal with border color and dynamic colors */}
                                    <div className={`p-5 grid grid-cols-12`}>
                                      <div className="col-span-8">
                                        <div className={`text-xl brand-text-color-800 font-semibold mb-2`}>Add Activities</div>
                                        <div className="flex items-center font-normal text-sm mb-2">
                                          <span>Click activities that you want to add to the itinerary</span>
                                          <IoMdInformationCircleOutline size={18} className="ml-2" />
                                        </div>
                                      </div>
                                    </div>

                                    <ActivitiesSlideOutComp
                                      isOpen={isSlideOutActivitiesOpen}
                                      onClose={handleCloseActivitiesSlideOut}
                                      deeShadow={deeShadow}
                                      properties={properties}
                                      activitiesTab={activitiesTab}
                                      setActivitiesTab={setActivitiesTab}
                                      activitiesList={activitiesList}
                                      bookings={bookings}
                                      selectedActivityProperty={selectedActivityProperty}
                                      selectedActivityPropertyUid={selectedActivityPropertyUid}
                                      selectedActivityPropertyId={selectedActivityPropertyId}
                                      selectedActivityDate={selectedActivityDate}
                                      selectedActivityDay={selectedActivityDay}
                                      activitiesData={activitiesData}
                                      setActivitiesData={setActivitiesData}
                                      setActivitiesCost={setActivitiesCost}
                                    />

                                    <div className={`px-5 pb-5`}>
                                      {bookings.map((item, index) => (
                                        <div key={uuidv4()} className={`bg-white border rounded-xl pt-4 mb-5 drop-shadow-md border-slate-200`}>
                                          {/* ROW 1 (Property info) */}

                                          <div className={`mb-5`}>
                                            {properties[getPropObj(item.propUid, properties)].bookedDirect && (
                                              <div
                                                className="text-right fixed -top-0.5"
                                                style={{
                                                  WebkitTransform: 'translate(-50%, 0)',
                                                  left: '50%',
                                                  perspective: '1000' /* Safari */,
                                                }}
                                              >
                                                <span
                                                  className={`text-[12px] font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5  brand-bg-custom-color-warning text-white rounded-b-lg text-center capitalize`}
                                                >
                                                  Accommodation booked direct
                                                </span>
                                              </div>
                                            )}
                                            <div className={`grid grid-cols-12`}>
                                              {/* IMG */}
                                              <div className="col-span-1 bg-white">
                                                <div className="text-right fixed top-4 right-0">
                                                  <span className={`text-[12px] font-medium truncate text-ellipsis overflow-hidden pl-3 pr-3 py-0.5  bg-[#97C1e6] rounded-l-lg text-right capitalize`}>
                                                    {item.days + 1} days
                                                  </span>
                                                </div>

                                                <div className="row-span-1 relative">
                                                  <img
                                                    className="ml-4 object-cover w-16 h-16 rounded-full"
                                                    src={
                                                      properties[getPropObj(item.propUid, properties)].heroImageUrl
                                                        ? `${properties[getPropObj(item.propUid, properties)].heroImageUrl}/320x200`
                                                        : 'https://firebasestorage.googleapis.com/v0/b/nomadhornbill.appspot.com/o/images%2Fproperties%2Fdefault%2Fdefault.png?alt=media&token=60f73247-fa43-4829-9c56-260803159067'
                                                    }
                                                    alt={properties[getPropObj(item.propUid, properties)].name || 'Property image'}
                                                  />
                                                </div>
                                              </div>
                                              {/* ROOM NAME + DESCRIPTION */}
                                              <div key={uuidv4()} className="col-span-11 bg-white ml-10 mt-3">
                                                <div className="pt-0">
                                                  <div className="text-lg font-medium w-72 truncate text-ellipsis overflow-hidden">{properties[getPropObj(item.propUid, properties)].name}</div>
                                                </div>
                                                <div className="mt-0">
                                                  <div className="text-sm font-normal leading-tight pb-1 brand-text-grey-v2 w-72 truncate text-ellipsis overflow-hidden">
                                                    {properties[getPropObj(item.propUid, properties)].park.name}
                                                  </div>
                                                </div>

                                                <div className="flex flex-col mt-2">
                                                  <div className="text-sm font-normal leading-tight pb-0 brand-text-grey-v2 w-72 truncate text-ellipsis overflow-hidden">
                                                    Pax: {item.rooms.reduce((total, room) => total + room.ad + room.ch + room.chi + room.inf, 0)} | Rooms:{' '}
                                                    {item.rooms.filter((room) => room.roomType !== 'Vehicle').length}
                                                  </div>
                                                  <div className="pb-0 pt-0 flex items-center">
                                                    <MdDateRange className="inline-block w-5 h-5 brand-text-grey-v2" />

                                                    <div className="pl-2 text-sm font-normal brand-text-grey-v2 flex justify-between items-center w-full">
                                                      <div className="truncate">
                                                        {moment(item.startDay).format('Do MMM YYYY')} - {moment(item.endDay).format('Do MMM YYYY')}
                                                      </div>
                                                      <div className="flex-grow" />
                                                      <div className="pl-2 ml-auto mr-5">
                                                        Total: $
                                                        {formatPrice(
                                                          activitiesData[index].activities.reduce((acc, day) => {
                                                            return acc + day.reduce((dayAcc, activity) => dayAcc + activity.cost, 0);
                                                          }, 0),
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="px-5 text-sm brand-text-grey-1-v2 font-normal">ACTIVITIES</div>
                                          {getActivitiesLength(item.propUid, findCustomLocation(customLocations, item.propUid).parkId, activities) > 0 ? (
                                            clickedActivityPropertyId === item.id || !checkIfAllActivitiesEmpty(activitiesData, index) ? (
                                              Array.from({ length: item.days + 1 }, (_, indx) => (
                                                <div key={uuidv4()}>
                                                  <div
                                                    className={`bg-white border-b-2 border-x-2 border-dashed py-4 px-5 mx-5 items-center justify-center text-center
${indx === 0 && 'rounded-t-xl border-y-2'}
${indx === item.days && 'rounded-b-xl border-t-0 mb-5'}
${index === selectedActivityProperty && indx === selectedActivityDay && 'bg-slate-100'}
`}
                                                  >
                                                    <div className="flex">
                                                      <p
                                                        className={`text-sm brand-text-grey-1-v2 block ${
                                                          index === selectedActivityProperty && indx === selectedActivityDay ? 'font-normal' : 'font-normal'
                                                        }`}
                                                      >
                                                        Day {indx + 1}
                                                      </p>
                                                      <div
                                                        className="flex text-sm ml-auto text-nomadEvergreen-700 cursor-pointer"
                                                        onClick={() => {
                                                          setClickedActivityPropertyId(item.id);
                                                          handleClickActivitiesCallback(index, indx, item);
                                                          //setActivitiesTab("prebooked")
                                                          handleOpenActivitiesSlideOut();
                                                        }}
                                                      >
                                                        Click to add activity
                                                        <FiPlus size={18} className="ml-2" />
                                                      </div>
                                                    </div>
                                                    <div className={`grid grid-cols-2 gap-4 ${activitiesData[index].activities[indx].length > 0 && 'pt-5'}`}>
                                                      {activitiesData[index].activities[indx].map((activity, i) => (
                                                        <div key={uuidv4()} className={`bg-white overflow-hidden drop-shadow rounded-md`}>
                                                          <div className="flex items-start">
                                                            <img className="object-cover w-[90px] h-[90px] rounded-l-md" src={activity.images[0].img} alt={activity.images[0].description} />
                                                            <div className="ml-3 flex flex-col text-left m-2">
                                                              <div className="flex flex-row text-left">
                                                                <div className="text-xs font-semibold">{activity.displayName}</div>
                                                                {activity.caution && (
                                                                  <IoMdWarning
                                                                    size={15}
                                                                    className="ml-3 text-orange-600"
                                                                    onClick={() => {
                                                                      confirmAlert({
                                                                        customUI: ({ onClose }) => {
                                                                          return (
                                                                            <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                                                                              <div className="flex items-center text-base font-semibold pb-2">
                                                                                <IoMdWarning size={30} className="mr-3 text-orange-600" />
                                                                                <p>Alert</p>
                                                                              </div>
                                                                              <p className="text-sm pb-2">Maximum quantity of {activity.stockCount} available </p>
                                                                              <p className="text-sm pb-2">for this activity on this day.</p>
                                                                              <div className="flex mt-5 justify-end">
                                                                                <div>
                                                                                  <button
                                                                                    className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                                                                                    onClick={() => {
                                                                                      onClose();
                                                                                    }}
                                                                                  >
                                                                                    Close
                                                                                  </button>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          );
                                                                        },
                                                                      });
                                                                    }}
                                                                  />
                                                                )}
                                                              </div>
                                                              <div className="text-xs font-normal">{activity.supplier.name}</div>
                                                              <div className="text-xs font-normal pb-0">
                                                                Quantity:{' '}
                                                                <input
                                                                  type="number"
                                                                  //className="inline-block w-12"
                                                                  value={activity.activityUnits}
                                                                  min={1}
                                                                  max={activity.maxUnits}
                                                                  onChange={(e) => {
                                                                    const selectedValue = parseInt(e.target.value, 10);
                                                                    console.log('Selected units:', selectedValue);

                                                                    // Find the index of the element in the activitiesData array containing the activity with the matching UUID
                                                                    const dataIndex = activitiesData.findIndex((property) =>
                                                                      property.activities.some((day) => day.some((act) => act.uuid === activity.uuid)),
                                                                    );

                                                                    // Make a deep copy of the activitiesData array
                                                                    const updatedActivitiesData = JSON.parse(JSON.stringify(activitiesData));

                                                                    // Update the activityUnits and cost values
                                                                    updatedActivitiesData[dataIndex].activities.forEach((day) => {
                                                                      day.forEach((act) => {
                                                                        if (act.uuid === activity.uuid) {
                                                                          act.activityUnits = selectedValue;
                                                                          act.cost = selectedValue * act.costPerUnit;
                                                                        }
                                                                      });
                                                                    });

                                                                    // Update the state with the modified activitiesData array
                                                                    setActivitiesData(updatedActivitiesData);

                                                                    // Get the total cost of activities
                                                                    let totalCost = 0;
                                                                    updatedActivitiesData.forEach((property) => {
                                                                      property.activities.forEach((day) => {
                                                                        day.forEach((activity) => {
                                                                          totalCost += activity.cost;
                                                                        });
                                                                      });
                                                                    });
                                                                    console.log('totalCost:', totalCost);
                                                                    setActivitiesCost(totalCost);
                                                                  }}
                                                                  style={{
                                                                    width: 'auto',
                                                                    fontSize: '0.75rem', // Equivalent to Tailwind's text-xs
                                                                    height: '1.25rem',
                                                                    paddingTop: '0.5rem',
                                                                    paddingRight: '0rem',
                                                                    paddingBottom: '0.5rem',
                                                                    paddingLeft: '0.5rem',
                                                                  }}
                                                                />
                                                                {activity.priceType}
                                                              </div>
                                                            </div>
                                                            <div className="ml-auto flex flex-col justify-between h-16 m-2">
                                                              <IoMdCloseCircleOutline
                                                                size={18}
                                                                className="ml-auto"
                                                                onClick={() => {
                                                                  const newActivitiesData = [...activitiesData];
                                                                  newActivitiesData[index].activities[indx].splice(i, 1);

                                                                  //Calculate total cost of activities
                                                                  let totalCost = 0;
                                                                  newActivitiesData.forEach((property) => {
                                                                    property.activities.forEach((day) => {
                                                                      day.forEach((activity) => {
                                                                        totalCost += activity.cost;
                                                                      });
                                                                    });
                                                                  });
                                                                  setActivitiesCost(totalCost);

                                                                  setActivitiesData(newActivitiesData);
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>
                                                </div>
                                              ))
                                            ) : (
                                              <div key={uuidv4()}>
                                                <div
                                                  className={`bg-white border-b-2 border-x-2 border-dashed py-4 px-5 mx-5 items-center justify-center text-center rounded-t-xl border-y-2 rounded-b-xl mb-5 cursor-pointer`}
                                                  // onClick={() => setClickedActivityPropertyId(item.id)}
                                                  onClick={() => {
                                                    setClickedActivityPropertyId(item.id);
                                                    setActivitiesList([]);
                                                    setActivitiesTab('prebooked');
                                                  }}
                                                >
                                                  <p className={`text-sm brand-text-grey-1-v2 block font-normal`}>Add activities</p>
                                                </div>
                                              </div>
                                            )
                                          ) : (
                                            <div key={uuidv4()}>
                                              <div
                                                className={`bg-white border-b-2 border-x-2 border-dashed py-4 px-5 mx-5 items-center justify-center text-center rounded-t-xl border-y-2 rounded-b-xl mb-5`}
                                                // onClick={() => {
                                                //   setClickedActivityPropertyId(item.id);
                                                //   setActivitiesList([]);
                                                //   setActivitiesTab("prebooked")
                                                // }}
                                              >
                                                <p className={`text-sm brand-text-grey-1-v2 block font-normal`}>No Activities Available at this property</p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>

                                    {/* // ACTIVITY RATE: START ---------------------------------------------------------------------------------- */}
                                    {bookings.length === 0 && (
                                      <>
                                        <div className={`bg-white border-2 border-dashed rounded-xl py-4 mb-10 px-5 mx-5 h-28 flex items-center justify-center`}>
                                          <h2 className="text-sm brand-text-grey-1-v2 font-normal">Please select activities to the right</h2>
                                        </div>
                                        <div className="h-0.5"></div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                {/* //! SECTION 2: END ---------------------------------------------------------------------------------- */}
                              </div>
                              {/* LEFT COLUMN FOR PROPERTIES END */}

                              {/* RIGHT COLUMN START */}

                              {/* //! SECTION 3: ACTIVITIES START 1  ---------------------------------------------------------------------------------- */}

                              <div className="">
                                <div className="sticky top-[60px]">
                                  {createEdit === 'edit' && (
                                    <div className="mb-[18px]">
                                      <ItineraryVersionMenu itinerariesVcMenu={itinerariesVcMenu} />
                                    </div>
                                  )}
                                  {/* <div style={deeShadow} className="px-5 pt-5 bg-white rounded-lg w-[500px] h-[820px] left-[1060px] fixed"> */}
                                  <div className="px-5 pt-3 bg-white rounded-lg w-[320px] border-2 h-fit top-0">
                                    {/* TABS */}
                                    {/* TODO: Deal with border color and dynamic colors */}
                                    <ItineraryNameEditor
                                      editItineraryName={editItineraryName}
                                      itineraryName={itineraryName}
                                      handleTextChange={handleTextChange}
                                      handleItinerarySaveClick={handleItinerarySaveClick}
                                      handleEditItineraryClick={handleEditItineraryClick}
                                    />

                                    {createEdit === 'edit' && (
                                      <div className="flex pt-3">
                                        <MdNumbers size={20} />
                                        <div className="ml-2 text-zinc-800 text-sm font-normal">{itineraryStore.code ? 'Reference: ' + itineraryStore.code : ''}</div>
                                      </div>
                                    )}

                                    <div className="flex pt-3">
                                      <MdOutlineSupportAgent size={20} />
                                      <div className="ml-2 text-zinc-800 text-sm font-normal">{selectedAgentName}</div>
                                    </div>

                                    {clientName !== '' && (
                                      <div className="flex pt-3">
                                        <CiGlobe size={20} />
                                        <div className="ml-2 text-zinc-800 text-sm font-normal">{clientName}</div>
                                      </div>
                                    )}

                                    {createEdit === 'edit' && (
                                      <div className="flex pt-3">
                                        <GrStatusInfo size={20} />
                                        <div className="ml-2 text-zinc-800 text-sm font-normal">{itineraryStatus}</div>
                                      </div>
                                    )}

                                    <GuestCount bookings={bookings} />

                                    {bookings.length > 0 && <DateRangeComp bookings={bookings} />}
                                    {bookings.length > 0 && <NightCount bookings={bookings} />}

                                    <div className="flex text-left mt-5 border-b border-gray-300 w-full "></div>

                                    {/* ADD ACTIVITIES PRICING ETC */}
                                    {bookings.length > 0 ? (
                                      <div className="mt-5">
                                        <div className="flex brand-text-grey-1-v2">
                                          {/* Total Activities */}
                                          {!fetchingRates ? (
                                            <div className="flex justify-between w-full">
                                              <div className={`text-left text-sm text-zinc-800`}>Total:</div>
                                              <div className="text-black text-lg font-medium">
                                                Total: $
                                                {formatPrice(
                                                  getTotalFromBookings(bookings) +
                                                    getTotalFromActivities(activitiesData) +
                                                    (!mojoItinerary ? getTotalFromTransfers(transfers) + getTotalFromBookingsForVehicles(bookings, properties) : 0),
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="flex justify-between w-full">
                                              <div className={`text-left text-sm text-zinc-800`}>Total:</div>
                                              <div className="text-black text-lg font-medium">{fetchingRates ? 'Fetching Rates' : '$0.00'}</div>
                                            </div>
                                          )}
                                        </div>
                                        {
                                          //refreshAllRates && bookings.some((booking) => booking.rooms.length !== 0) && (
                                          (refreshBtn || (refreshAllRates && bookings.every((booking) => !booking.addRoom && booking.rooms.every((room) => !room.edit)))) && (
                                            <div className="flex-1 text-right mr-4 mt-3 cursor-pointer">
                                              <button
                                                className="ml-auto bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit animate-pulse"
                                                onClick={() => {
                                                  // if (
                                                  //   bookings.every(
                                                  //     (booking) =>
                                                  //       booking.rooms && Array.isArray(booking.rooms) && booking.rooms.length > 0 && !booking.addRoom && booking.rooms.every((room) => !room.edit),
                                                  //   )
                                                  // ) {
                                                  //   setRefreshAllRates(false);
                                                  //   setFetchingRates(true);
                                                  //   refreshRates(
                                                  //     bookings,
                                                  //     setBookings,
                                                  //     setRefreshBtn,
                                                  //     properties,
                                                  //     setFetchingRates,
                                                  //     agentRates,
                                                  //     agentCommission,
                                                  //     guests,
                                                  //     agentObject,
                                                  //     createVersionCheck,
                                                  //     createEdit,
                                                  //     user,
                                                  //     isFamTrip,
                                                  //     commissionAgent,
                                                  //     commissionUser,
                                                  //     dateCreated,
                                                  //   );
                                                  // } else {
                                                  //   toast.error('Please complete all rooms before refreshing rates');
                                                  // }
                                                  if (
                                                    bookings.every(
                                                      (booking) =>
                                                        booking.rooms &&
                                                        Array.isArray(booking.rooms) &&
                                                        booking.rooms.length > 0 &&
                                                        !booking.addRoom &&
                                                        booking.rooms.every((room) => !room.edit) &&
                                                        // Add check for exclusive room
                                                        !(booking.rooms.length === 1 && booking.rooms[0].roomType === 'Exclusive'),
                                                    )
                                                  ) {
                                                    setRefreshAllRates(false);
                                                    setFetchingRates(true);
                                                    refreshRates(
                                                      bookings,
                                                      setBookings,
                                                      setRefreshBtn,
                                                      properties,
                                                      setFetchingRates,
                                                      agentRates,
                                                      agentCommission,
                                                      guests,
                                                      agentObject,
                                                      createVersionCheck,
                                                      createEdit,
                                                      user,
                                                      isFamTrip,
                                                      commissionAgent,
                                                      commissionUser,
                                                      dateCreated,
                                                    );
                                                  } else {
                                                    if (bookings.some((booking) => booking.rooms.length === 1 && booking.rooms[0].roomType === 'Exclusive')) {
                                                      toast.error('Add rooms to Exclusive properties');
                                                    } else {
                                                      toast.error('Please complete all rooms before refreshing rates');
                                                    }
                                                  }
                                                }}
                                              >
                                                Click to refresh rates
                                              </button>
                                            </div>
                                          )
                                        }
                                        {!fetchingRates && !refreshAllRates && !rooms.some((room) => room.loading) && (
                                          <div className="flex flex-col items-end gap-4">
                                            <button
                                              className="w-full bg-nomadEvergreen-700 text-white text-sm font-semibold py-3 mt-8 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadEvergreen-800 hover:text-white"
                                              onClick={async () => {
                                                console.log('final clicked');
                                                const createPropertyItemForBooking = (bookingItem) => {
                                                  let totalPayable = 0;

                                                  const rooms = bookingItem.rooms.filter((room) => room.roomType !== 'Vehicle');
                                                  const roomsVehicles = bookingItem.rooms.filter((room) => room.roomType === 'Vehicle');

                                                  if (rooms && rooms.length === 0) {
                                                    return null;
                                                  }

                                                  if (rooms && rooms.length) {
                                                    totalPayable = rooms.reduce((acc, room) => acc + (room.rate ? room.rate.payable || room.rate.nett || 0 : 0), 0);
                                                  }

                                                  //total pax
                                                  const totalAdults = rooms.reduce((acc, room) => acc + room.ad, 0);
                                                  const totalCh = rooms.reduce((acc, room) => acc + room.ch, 0);
                                                  const totalChi = rooms.reduce((acc, room) => acc + room.chi, 0);
                                                  const totalInf = rooms.reduce((acc, room) => acc + room.inf, 0);
                                                  const totalChildren = totalCh + totalChi + totalInf;

                                                  const newPropertyItem = {
                                                    feeType: 'accommodation',
                                                    feeTypeUid: '64a1caf7-e922-4629-b90b-164809033192', // 3rd Party Accommodation
                                                    feeTypeName: getLabelForFinalValues('64a1caf7-e922-4629-b90b-164809033192'),
                                                    supplier: properties[getPropObj(bookingItem.propUid, properties)].supplier.id,
                                                    description: `Total cost for ${totalAdults} ${totalAdults > 1 ? 'adults' : 'adult'} and ${totalChildren} ${
                                                      totalChildren === 0 || totalChildren > 1 ? 'children' : 'child'
                                                    }  including park fees on ${rateLabelMap[bookingItem.rateName]}`,
                                                    units: 1,
                                                    pax: 1,
                                                    net: totalPayable,
                                                    total: totalPayable,
                                                    uuid: uuidv4(),
                                                    edit: false,
                                                    star: true,
                                                  };

                                                  if (properties[getPropObj(bookingItem.propUid, properties)].platformResRequest.rates === true) {
                                                    return newPropertyItem;
                                                  }
                                                  return null;
                                                };

                                                const createPropertyItemForBookingKaraniResReq = (bookingItem) => {
                                                  let totalPayable = 0;

                                                  const rooms = bookingItem.rooms.filter((room) => room.roomType !== 'Vehicle');
                                                  const roomsVehicles = bookingItem.rooms.filter((room) => room.roomType === 'Vehicle');

                                                  if (rooms && rooms.length === 0) {
                                                    return null;
                                                  }

                                                  if (rooms && rooms.length) {
                                                    totalPayable = rooms.reduce((acc, room) => acc + (room.rate ? room.rate.payable || room.rate.nett || 0 : 0), 0);
                                                  }

                                                  //total pax
                                                  const totalAdults = rooms.reduce((acc, room) => acc + room.ad, 0);
                                                  const totalCh = rooms.reduce((acc, room) => acc + room.ch, 0);
                                                  const totalChi = rooms.reduce((acc, room) => acc + room.chi, 0);
                                                  const totalInf = rooms.reduce((acc, room) => acc + room.inf, 0);
                                                  const totalChildren = totalCh + totalChi + totalInf;

                                                  const newPropertyItem = {
                                                    feeType: 'accommodation',
                                                    feeTypeUid: '64a1caf7-e922-4629-b90b-164809033192', // 3rd Party Accommodation
                                                    feeTypeName: getLabelForFinalValues('64a1caf7-e922-4629-b90b-164809033192'),
                                                    supplier: properties[getPropObj(bookingItem.propUid, properties)].supplier.id,
                                                    description: `Total cost for ${totalAdults} ${totalAdults > 1 ? 'adults' : 'adult'} and ${totalChildren} ${
                                                      totalChildren === 0 || totalChildren > 1 ? 'children' : 'child'
                                                    }  including park fees on ${rateLabelMap[bookingItem.rateName]}`,
                                                    units: 1,
                                                    pax: 1,
                                                    net: totalPayable,
                                                    total: totalPayable,
                                                    uuid: uuidv4(),
                                                    edit: false,
                                                    star: true,
                                                  };

                                                  //if (properties[getPropObj(bookingItem.propUid, properties)].platformResRequest.rates === true) {
                                                  return newPropertyItem;
                                                  // }
                                                  //return null;
                                                };

                                                const createPropertyItemForBookingKarani = (bookingItem, pricingItem) => {
                                                  const rooms = bookingItem.rooms.filter((room) => room.roomType !== 'Vehicle');
                                                  const roomsVehicles = bookingItem.rooms.filter((room) => room.roomType === 'Vehicle');

                                                  if (rooms && rooms.length === 0) {
                                                    return null;
                                                  }
                                                  let supplierUid = properties[getPropObj(bookingItem.propUid, properties)].supplier.id;
                                                  if (categorizeChargeTypeId(pricingItem) === 21 && bookingItem.rateName === 'fullBoardNomad') {
                                                    supplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
                                                  }

                                                  const newPropertyItem = {
                                                    feeType: categorizeChargeType(pricingItem),
                                                    feeTypeUid: categorizeChargeTypeGetUid(pricingItem),
                                                    feeTypeName: getLabelForFinalValues(categorizeChargeTypeGetUid(pricingItem)),
                                                    supplier: supplierUid,
                                                    description: pricingItem.summary,
                                                    units: pricingItem.nights,
                                                    pax: pricingItem.count,
                                                    net: pricingItem.salerate,
                                                    total: pricingItem.saletotal,
                                                    uuid: uuidv4(),
                                                    edit: false,
                                                    star: true,
                                                  };

                                                  return newPropertyItem;
                                                };

                                                const createPropertyItemForBookingNomad = (bookingItem, comp) => {
                                                  console.log('createPropertyItemForBookingNomad');
                                                  const rooms = bookingItem.rooms.filter((room) => room.roomType !== 'Vehicle');
                                                  //const roomsVehicles = bookingItem.rooms.filter((room) => room.roomType === 'Vehicle');

                                                  //if ((rooms && rooms.length === 0) || (roomsVehicles && roomsVehicles.length === 0)) {
                                                  if (rooms && rooms.length === 0) {
                                                    return null;
                                                  }
                                                  //total pax
                                                  const totalAdults = rooms.reduce((acc, room) => acc + room.ad, 0);
                                                  const totalCh = rooms.reduce((acc, room) => acc + room.ch, 0);
                                                  const totalChi = rooms.reduce((acc, room) => acc + room.chi, 0);
                                                  const totalInf = rooms.reduce((acc, room) => acc + room.inf, 0);
                                                  const totalChildren = totalCh + totalChi + totalInf;
                                                  const totalPax = totalAdults + totalChildren;

                                                  //const net = Math.round(comp.totalNett / totalPax / bookingItem.days);
                                                  const net = Math.round((comp.totalNett / totalPax / bookingItem.days) * 100) / 100;

                                                  const startDate = moment(comp.period_from);
                                                  const endDate = moment(comp.period_to);

                                                  // Calculate the difference in days and add 1
                                                  const nights = endDate.diff(startDate, 'days') + 1;

                                                  let newPropertyItem;
                                                  //if (comp.component_name !== 'Vehicle') {
                                                  newPropertyItem = {
                                                    feeType: comp.component_name === 'Accommodation' ? 'accommodation' : 'parkFees',
                                                    feeTypeUid: comp.component_name === 'Accommodation' ? 'e52813a4-3d2b-4f1a-acff-4c1c7b5ae158' : '3483e4ef-046b-4233-afdc-4a1e0902729d',
                                                    feeTypeName:
                                                      comp.component_name === 'Accommodation'
                                                        ? getLabelForFinalValues('e52813a4-3d2b-4f1a-acff-4c1c7b5ae158')
                                                        : getLabelForFinalValues('3483e4ef-046b-4233-afdc-4a1e0902729d'),
                                                    supplier: properties[getPropObj(bookingItem.propUid, properties)].supplier.id,
                                                    description: `${comp.component_name} for ${totalAdults} ${totalAdults > 1 ? 'adults' : 'adult'}${
                                                      totalChildren > 0 ? ` and ${totalChildren} ${totalChildren > 1 ? 'children' : 'child'}` : ''
                                                    }${
                                                      comp.component_count > 1
                                                        ? ', ' + moment(comp.period_from).format('Do MMM YYYY') + ' - ' + moment(comp.period_to).add(0, 'days').format('Do MMM YYYY')
                                                        : ''
                                                    }`,
                                                    units: nights, // number of nights
                                                    pax: totalPax,
                                                    net: net,
                                                    total: comp.totalNett,
                                                    uuid: uuidv4(),
                                                    edit: false,
                                                    star: true,
                                                  };

                                                  if (properties[getPropObj(bookingItem.propUid, properties)].platformResRequest.rates === true) {
                                                    return newPropertyItem;
                                                  }

                                                  return null;
                                                };

                                                const createPropertyItemForBookingVehicle = (bookingItem, bookings, properties) => {
                                                  console.log('createPropertyItemForBookingVehicle');
                                                  const rooms = bookingItem.rooms.filter((room) => room.roomType === 'Vehicle');
                                                  const roomsOther = bookingItem.rooms.filter((room) => room.roomType !== 'Vehicle');

                                                  //total pax
                                                  const totalAdults = roomsOther.reduce((acc, room) => acc + room.ad, 0);
                                                  const totalCh = roomsOther.reduce((acc, room) => acc + room.ch, 0);
                                                  const totalChi = roomsOther.reduce((acc, room) => acc + room.chi, 0);
                                                  const totalInf = roomsOther.reduce((acc, room) => acc + room.inf, 0);
                                                  const totalChildren = totalCh + totalChi + totalInf;
                                                  const totalPax = totalAdults + totalChildren;

                                                  if (rooms && rooms.length === 0) {
                                                    return null;
                                                  }

                                                  let newPropertyItem;

                                                  let description = 'Unknown';
                                                  let price = 0;
                                                  // deal with northern guide logic

                                                  let daysToAdd = 0;
                                                  if (rooms[0].vehicleType === 'northernGuide') {
                                                    const nextBooking = findNextBooking(bookings, bookingItem.id);
                                                    daysToAdd = calculateDaysToAddForVehicles(bookingItem, nextBooking, properties);
                                                  }

                                                  if (rooms[0].vehicleType === 'northernGuide' && rooms[0].northernGuideVehicleSupplement) {
                                                    description = `Private Nomad car and guide supplement for ${totalPax} pax at ${bookingItem.propName}`;
                                                    price = Number(rooms[0].northernGuideVehiclePrice);
                                                  } else if (rooms[0].vehicleType === 'northernGuide' && !rooms[0].northernGuideVehicleSupplement) {
                                                    description = `Private Nomad car and guide at ${bookingItem.propName}`;
                                                  } else if (rooms[0].vehicleType === 'camp') {
                                                    description = `Private Nomad car and guide at ${bookingItem.propName}`;
                                                  }
                                                  let units = bookingItem.days + daysToAdd;
                                                  newPropertyItem = {
                                                    feeType: '',
                                                    feeTypeUid: '02f2a5eb-d622-43b3-abab-2fdfd8a02d67',
                                                    feeTypeName: getLabelForFinalValues('02f2a5eb-d622-43b3-abab-2fdfd8a02d67'),
                                                    supplier: 'ded3a3ed-aeaf-4495-9069-7754a649de67',
                                                    description: description,
                                                    units: units,
                                                    pax: rooms.length,
                                                    net: price,
                                                    total: price * units * rooms.length,
                                                    uuid: uuidv4(),
                                                    edit: false,
                                                    star: true,
                                                  };
                                                  // }

                                                  // if (properties[getPropObj(bookingItem.propUid, properties)].platformResRequest.rates === true || ) {
                                                  //   return newPropertyItem;
                                                  // }
                                                  return newPropertyItem;
                                                };

                                                function summarizeComponents(booking) {
                                                  const componentsSummary = {};
                                                  const componentCount = {};

                                                  // Filter out rooms with "roomType": "Room"
                                                  const rooms = booking.rooms.filter((room) => room.roomType !== 'Vehicle');
                                                  const roomsVehicles = booking.rooms.filter((room) => room.roomType === 'Vehicle');

                                                  // Iterate over each room
                                                  rooms.forEach((room) => {
                                                    // Ensure the room has rate.periods and iterate over them
                                                    room.rate.periods.forEach((period, periodIndex) => {
                                                      const { period_from, period_to } = period;

                                                      // Iterate over each component in the period
                                                      period.components.forEach((component) => {
                                                        const { component_name, component_nett } = component;

                                                        // Track the count of each component_name
                                                        if (!componentCount[component_name]) {
                                                          componentCount[component_name] = 0;
                                                        }
                                                        componentCount[component_name] += 1;

                                                        // Create a unique identifier for each period-component combination
                                                        const uniqueId = `${component_name}_${periodIndex}_${period_from}_${period_to}`;

                                                        // Initialize or update the component summary
                                                        if (!componentsSummary[uniqueId]) {
                                                          componentsSummary[uniqueId] = { length: 0, totalNett: 0, periodIndex, period_from, period_to };
                                                        }
                                                        componentsSummary[uniqueId].length += 1;
                                                        componentsSummary[uniqueId].totalNett += component_nett;
                                                      });
                                                    });
                                                  });

                                                  // Convert the summary object to an array of objects
                                                  return Object.entries(componentsSummary).map(([id, data]) => {
                                                    const componentName = id.split('_')[0];
                                                    return {
                                                      component_name: componentName,
                                                      length: data.length,
                                                      totalNett: data.totalNett,
                                                      periodIndex: data.periodIndex,
                                                      period_from: data.period_from,
                                                      period_to: data.period_to,
                                                      component_count: componentCount[componentName],
                                                    };
                                                  });
                                                }

                                                //transfer items not flight/bookFlight
                                                const createTransferItemForBooking = (transfer, matchingTransfers, index, booking) => {
                                                  console.log('createTransferItemForBooking');
                                                  let totalPayable = 0;

                                                  function getTransferFeeType(value) {
                                                    switch (value) {
                                                      case 'flight':
                                                      case 'bookFlight':
                                                        return 'flight';
                                                      case 'ownArrangements':
                                                      case 'camp':
                                                        return 'ownArrangements';
                                                      case 'safari':
                                                      case 'location':
                                                        return 'roadTransfer';
                                                      default:
                                                        return 'Select';
                                                    }
                                                  }

                                                  let supplier;
                                                  if (transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight') {
                                                    const airlineDetails = findAirlineByCompanyCode(airlines, transfer.formValues.flightDetails.airlineDesignator);
                                                    supplier = airlineDetails.supplier.id;
                                                  } else {
                                                    supplier = transfer.formValues.supplierUid ? transfer.formValues.supplierUid : '8ae5bed6-e15e-4e79-8ab0-e934c2645abc';
                                                  }

                                                  let description = 'N/A';
                                                  if (transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight') {
                                                    description = `${getAirportName(airports, getAeroCrsCode(airports, transfer.formValues.flightDetails.fromcode))} (${
                                                      transfer.formValues.flightDetails.fromcode
                                                    }) > ${getAirportName(airports, getAeroCrsCode(airports, transfer.formValues.flightDetails.tocode))} (${transfer.formValues.flightDetails.tocode})`;
                                                  } else if (transfer.formValues.type === 'camp') {
                                                    description = `You'll make your own way`;
                                                  } else if (transfer.formValues.type === 'ownArrangements') {
                                                    if (transfer.formValues.airportTransfer) {
                                                      description = `${transfer.formValues.originAirportName} (${transfer.formValues.originAirportCode}) > ${transfer.formValues.destinationAirportName} (${transfer.formValues.destinationAirportCode})`;
                                                    } else {
                                                      description = `You'll make your own way`;
                                                    }
                                                  } else if (transfer.formValues.type === 'safari') {
                                                    function getNextBookingById(bookings, bookingId) {
                                                      // Find the index of the booking in the array that matches the bookingId
                                                      const currentIndex = bookings.findIndex((b) => b.id === bookingId);

                                                      // Check if the booking is found and it is not the last one in the array
                                                      if (currentIndex !== -1 && currentIndex + 1 < bookings.length) {
                                                        // Return the next booking
                                                        return bookings[currentIndex + 1];
                                                      }

                                                      // Return null if the booking is the last one or not found
                                                      return null;
                                                    }
                                                    const nextBooking = getNextBookingById(bookings, booking.id);

                                                    description = `${booking.propName} > ${properties[getPropObj(nextBooking.propUid, properties)].name}`;
                                                  } else if (transfer.formValues.type === 'location') {
                                                    if (transfer.objType === 'arrival') {
                                                      description = `${transfer.formValues.locationName} > ${booking.propName}`;
                                                    } else {
                                                      description = `${booking.propName} > ${transfer.formValues.locationName}`;
                                                    }
                                                  }

                                                  const newTransferItem = {
                                                    feeType: getTransferFeeType(transfer.formValues.type),
                                                    feeTypeUid:
                                                      getTransferFeeType(transfer.formValues.type) === 'flight' || getTransferFeeType(transfer.formValues.type) === 'bookFlight'
                                                        ? '537f3a40-d0dd-43d3-835a-8496f7cbeb77'
                                                        : 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add',
                                                    feeTypeName:
                                                      getTransferFeeType(transfer.formValues.type) === 'flight' || getTransferFeeType(transfer.formValues.type) === 'bookFlight'
                                                        ? getLabelForFinalValues('537f3a40-d0dd-43d3-835a-8496f7cbeb77')
                                                        : getLabelForFinalValues('c4bce0fd-55a8-43f8-b5b1-bcf406c81add'),
                                                    supplier: supplier,
                                                    description: description,
                                                    units: 1,
                                                    pax: 1,
                                                    net: totalPayable,
                                                    total: transfer.formValues.saleTotal ? transfer.formValues.saleTotal : 0,
                                                    uuid: uuidv4(),
                                                    edit: false,
                                                    star: true,
                                                  };
                                                  console.log('newTransferItem added');
                                                  return newTransferItem;
                                                };

                                                const createTransferItemForBookingPicnic = (transfer, matchingTransfers, index, booking) => {
                                                  console.log('createTransferItemForBookingPicnic');
                                                  if (transfer.formValues?.packedLunch) {
                                                    let totalPayable = 0;

                                                    function getTransferFeeType(value) {
                                                      switch (value) {
                                                        case 'flight':
                                                        case 'bookFlight':
                                                          return 'flight';
                                                        case 'ownArrangements':
                                                        case 'camp':
                                                          return 'ownArrangements';
                                                        case 'safari':
                                                        case 'location':
                                                          return 'roadTransfer';
                                                        default:
                                                          return 'Select';
                                                      }
                                                    }

                                                    let supplier = booking.propUid ? properties[getPropObj(booking.propUid, properties)].supplier.id : '8ae5bed6-e15e-4e79-8ab0-e934c2645abc';

                                                    const newTransferItem = {
                                                      feeType: 'Activities & Extras',
                                                      include: true,
                                                      feeTypeUid: 'c536f381-457d-45d1-a503-75c370798f27',
                                                      feeTypeName: getLabelForFinalValues('c536f381-457d-45d1-a503-75c370798f27'),
                                                      supplier: supplier,
                                                      description: `Picnic lunch en route from ${booking.propName}`,
                                                      units: 1,
                                                      pax: transfer.formValues.packedLunchUnits,
                                                      net: transfer.formValues.packedLunchChargeable ? transfer.formValues.packedLunchUnitPrice : 0,
                                                      total: transfer.formValues.packedLunchChargeable ? transfer.formValues.packedLunchUnitPrice * transfer.formValues.packedLunchUnits : 0,
                                                      uuid: uuidv4(),
                                                      edit: false,
                                                      star: true,
                                                    };
                                                    console.log('newTransferItem added');
                                                    return newTransferItem;
                                                  } else {
                                                    return false;
                                                  }
                                                };

                                                //transfer items for flight/bookFlight
                                                const createTransferItemsForBooking = (transfer, matchingTransfers, index, booking, bookings) => {
                                                  console.log('createTransferItemForBooking');
                                                  let totalPayable = 0;

                                                  function getTransferFeeType(value) {
                                                    switch (value) {
                                                      case 'flight':
                                                      case 'bookFlight':
                                                        return 'flight';
                                                      case 'ownArrangements':
                                                      case 'camp':
                                                        return 'ownArrangements';
                                                      case 'safari':
                                                      case 'location':
                                                        return 'roadTransfer';
                                                      default:
                                                        return 'Select';
                                                    }
                                                  }

                                                  function processTransferDetails(flightDetails, flightPricing) {
                                                    let supplier;
                                                    let newTransferItems = [];
                                                    if (transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight') {
                                                      const airlineDetails = findAirlineByCompanyCode(airlines, flightDetails.airlineDesignator);
                                                      supplier = airlineDetails.supplier.id;
                                                    }
                                                    let description = 'N/A';
                                                    if (transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight') {
                                                      description = `${getAirportName(airports, getAeroCrsCode(airports, flightDetails.fromcode))} (${flightDetails.fromcode}) > ${getAirportName(
                                                        airports,
                                                        getAeroCrsCode(airports, flightDetails.tocode),
                                                      )} (${flightDetails.tocode})`;
                                                    }
                                                    console.log('transfer.formValues.adults', transfer.formValues.adults);
                                                    // For Adults
                                                    let adults;
                                                    let adultsTrue;
                                                    let inducementMsg = '';
                                                    if (flightPricing.passengers) {
                                                      adults = flightPricing.passengers.adults;
                                                      adultsTrue = flightPricing.passengers.adultsTrue;
                                                      if (flightPricing.passengers.inducement) {
                                                        inducementMsg = ` (including inducement of ${
                                                          flightPricing.passengers.adultsMin - (adultsTrue + flightPricing.passengers.children + flightPricing.passengers.infants)
                                                        })`;
                                                      }
                                                      console.log('NEW ADULTS 1');
                                                    } else {
                                                      adults = transfer.formValues.adults;
                                                      adultsTrue = transfer.formValues.adults;
                                                      console.log('OLD ADULTS 1');
                                                    }

                                                    if (adults > 0) {
                                                      newTransferItems.push({
                                                        feeType: getTransferFeeType(transfer.formValues.type),
                                                        feeTypeUid:
                                                          getTransferFeeType(transfer.formValues.type) === 'flight' || getTransferFeeType(transfer.formValues.type) === 'bookFlight'
                                                            ? '537f3a40-d0dd-43d3-835a-8496f7cbeb77'
                                                            : 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add',
                                                        feeTypeName:
                                                          getTransferFeeType(transfer.formValues.type) === 'flight' || getTransferFeeType(transfer.formValues.type) === 'bookFlight'
                                                            ? getLabelForFinalValues('537f3a40-d0dd-43d3-835a-8496f7cbeb77')
                                                            : getLabelForFinalValues('c4bce0fd-55a8-43f8-b5b1-bcf406c81add'),
                                                        supplier: supplier,
                                                        description: description + ' - adult fare' + inducementMsg,
                                                        units: 1,
                                                        pax: adults,
                                                        net: flightPricing.saleFare.adultFare,
                                                        total: flightPricing.saleFare.adultFare * adults,
                                                        uuid: uuidv4(),
                                                        edit: false,
                                                        star: true,
                                                      });
                                                    }

                                                    console.log('transferItem test: ' + JSON.stringify(newTransferItems, null, 2));

                                                    // For Children
                                                    let children;
                                                    if (flightPricing.passengers) {
                                                      children = flightPricing.passengers.children;
                                                    } else {
                                                      children = transfer.formValues.children;
                                                    }
                                                    if (children > 0) {
                                                      newTransferItems.push({
                                                        // Same structure as above, just change the 'description' and pricing details for children
                                                        feeType: getTransferFeeType(transfer.formValues.type),
                                                        feeTypeUid:
                                                          getTransferFeeType(transfer.formValues.type) === 'flight' || getTransferFeeType(transfer.formValues.type) === 'bookFlight'
                                                            ? '537f3a40-d0dd-43d3-835a-8496f7cbeb77'
                                                            : 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add',
                                                        feeTypeName:
                                                          getTransferFeeType(transfer.formValues.type) === 'flight' || getTransferFeeType(transfer.formValues.type) === 'bookFlight'
                                                            ? getLabelForFinalValues('537f3a40-d0dd-43d3-835a-8496f7cbeb77')
                                                            : getLabelForFinalValues('c4bce0fd-55a8-43f8-b5b1-bcf406c81add'),
                                                        supplier: supplier,
                                                        description: description + ' - child fare',
                                                        units: 1,
                                                        pax: children,
                                                        net: flightPricing.saleFare.childFare,
                                                        total: flightPricing.saleFare.childFare * children,
                                                        uuid: uuidv4(),
                                                        edit: false,
                                                        star: true,
                                                      });
                                                    }

                                                    // For Infants
                                                    let infants;
                                                    if (flightPricing.passengers) {
                                                      infants = flightPricing.passengers.infants;
                                                    } else {
                                                      infants = transfer.formValues.infants;
                                                    }
                                                    if (infants > 0) {
                                                      newTransferItems.push({
                                                        // Same structure as above, just change the 'description' and pricing details for infants
                                                        feeType: getTransferFeeType(transfer.formValues.type),
                                                        feeTypeUid:
                                                          getTransferFeeType(transfer.formValues.type) === 'flight' || getTransferFeeType(transfer.formValues.type) === 'bookFlight'
                                                            ? '537f3a40-d0dd-43d3-835a-8496f7cbeb77'
                                                            : 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add',
                                                        feeTypeName:
                                                          getTransferFeeType(transfer.formValues.type) === 'flight' || getTransferFeeType(transfer.formValues.type) === 'bookFlight'
                                                            ? getLabelForFinalValues('537f3a40-d0dd-43d3-835a-8496f7cbeb77')
                                                            : getLabelForFinalValues('c4bce0fd-55a8-43f8-b5b1-bcf406c81add'),
                                                        supplier: supplier,
                                                        description: description + ' - infant fare',
                                                        units: 1,
                                                        pax: infants,
                                                        net: flightPricing.saleFare.infantFare,
                                                        total: flightPricing.saleFare.infantFare * infants,
                                                        uuid: uuidv4(),
                                                        edit: false,
                                                        star: true,
                                                      });
                                                    }

                                                    // For tax
                                                    if (adultsTrue + children + infants > 0) {
                                                      newTransferItems.push({
                                                        // Same structure as above, just change the 'description' and pricing details for tax
                                                        feeType: getTransferFeeType(transfer.formValues.type),
                                                        feeTypeUid:
                                                          getTransferFeeType(transfer.formValues.type) === 'flight' || getTransferFeeType(transfer.formValues.type) === 'bookFlight'
                                                            ? '537f3a40-d0dd-43d3-835a-8496f7cbeb77'
                                                            : 'c4bce0fd-55a8-43f8-b5b1-bcf406c81add',
                                                        feeTypeName:
                                                          getTransferFeeType(transfer.formValues.type) === 'flight' || getTransferFeeType(transfer.formValues.type) === 'bookFlight'
                                                            ? getLabelForFinalValues('537f3a40-d0dd-43d3-835a-8496f7cbeb77')
                                                            : getLabelForFinalValues('c4bce0fd-55a8-43f8-b5b1-bcf406c81add'),
                                                        supplier: supplier,
                                                        description: description + ' - tax',
                                                        units: 1,
                                                        pax: adultsTrue + children + infants,
                                                        net: flightPricing.saleFare.tax,
                                                        total: (adultsTrue + children + infants) * flightPricing.saleFare.tax,
                                                        uuid: uuidv4(),
                                                        edit: false,
                                                        star: true,
                                                      });
                                                    }

                                                    return newTransferItems;
                                                  }

                                                  let transferItems = [];
                                                  if (transfer.formValues.flightDetails && transfer.formValues.flightPricing) {
                                                    transferItems = transferItems.concat(processTransferDetails(transfer.formValues.flightDetails, transfer.formValues.flightPricing));
                                                  }

                                                  if (transfer.formValues.flightDetailsSecond && transfer.formValues.flightPricingSecond) {
                                                    transferItems = transferItems.concat(processTransferDetails(transfer.formValues.flightDetailsSecond, transfer.formValues.flightPricingSecond));
                                                  }

                                                  console.log('newTransferItem added');
                                                  console.log('transferItem test: ' + JSON.stringify(transferItems, null, 2));
                                                  return transferItems;
                                                };

                                                if (!visitedFinalPage) {
                                                  setVisitedFinalPage(true);
                                                  let newFinalPageData = bookings.map((item) => {
                                                    let propertyItems = [];

                                                    // NOMAD PROPERTY
                                                    if (
                                                      properties[getPropObj(item.propUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' &&
                                                      properties[getPropObj(item.propUid, properties)]?.platformResRequest.rates
                                                    ) {
                                                      const result = summarizeComponents(item); // Replace bookingObject with booking data

                                                      console.log('summarizeComponents 2');
                                                      console.log(JSON.stringify(result, undefined, 4));
                                                      if (result && result.length > 0) {
                                                        result.forEach((comp, compIndex) => {
                                                          const newItem = createPropertyItemForBookingNomad(item, comp);
                                                          if (newItem) {
                                                            propertyItems.push(newItem);
                                                          }
                                                        });
                                                      }
                                                      console.log(JSON.stringify(propertyItems, undefined, 4));

                                                      // VEHICLES
                                                      const newItemVehicle = createPropertyItemForBookingVehicle(item, bookings, properties);
                                                      if (newItemVehicle) {
                                                        propertyItems.push(newItemVehicle);
                                                      }
                                                      // KARANI PROPERTY
                                                    } else if (properties[getPropObj(item.propUid, properties)]?.platformKarani?.active && !item.notKaraniPriced) {
                                                      console.log('KARANI PROPERTY');

                                                      item.prices.forEach((price) => {
                                                        const propertyItem = createPropertyItemForBookingKarani(item, price);
                                                        if (propertyItem) {
                                                          propertyItems.push(propertyItem);
                                                        }
                                                      });
                                                      // VEHICLES
                                                      const newItemVehicle = createPropertyItemForBookingVehicle(item, bookings, properties);
                                                      if (newItemVehicle) {
                                                        propertyItems.push(newItemVehicle);
                                                      }
                                                      // OTHER PROPERTY
                                                    } else {
                                                      console.log('summarizeComponents not 2');
                                                      console.log(properties[getPropObj(item.propUid, properties)].supplier.id);
                                                      const propertyItem = createPropertyItemForBooking(item);
                                                      if (propertyItem) {
                                                        propertyItems.push(propertyItem);
                                                      }
                                                      console.log('propertyItems');
                                                      console.log(JSON.stringify(propertyItems, undefined, 4));
                                                      // VEHICLES
                                                      const newItemVehicle = createPropertyItemForBookingVehicle(item, bookings, properties);
                                                      if (newItemVehicle) {
                                                        propertyItems.push(newItemVehicle);
                                                      }
                                                    }

                                                    return {
                                                      uuid: item.id,
                                                      propUid: item.propUid,
                                                      propName: item.propName,
                                                      rateName: item.rateName,
                                                      days: item.days,
                                                      startDay: item.startDay,
                                                      endDay: item.endDay,
                                                      propertyItems: propertyItems,
                                                      addProperty: false,
                                                      editPropertyItem: false,
                                                      collapsed: true,
                                                      supplier: properties[getPropObj(item.propUid, properties)].supplier.id,
                                                      pax: calculateTotalPax(item),
                                                      karaniPriced: item.prices ? true : false,
                                                    };
                                                  });
                                                  console.log('final clicked 1');
                                                  newFinalPageData = processFeeTypes(newFinalPageData, 'properties', properties);
                                                  console.log('updatedFinalPageData 1', JSON.stringify(newFinalPageData, null, 2));
                                                  newFinalPageData = orderByFeeType(newFinalPageData, 'properties');
                                                  setFinalPageData(newFinalPageData);

                                                  // TRANSFERS
                                                  const matchingTransfers = transfers.filter((transfer) => transfer.formValues);
                                                  let newFinalPageTransfersData = matchingTransfers.map((transfer, index) => {
                                                    const booking = bookings.find((b) => b.id === transfer.uuid && b.startDay === transfer.startDay);

                                                    // Initialize variables at the top of each iteration
                                                    let tempTransferItems = []; // Use for 'bookFlight' or 'flight' types
                                                    let transferItem = []; // Use for other types
                                                    let transferItemPicnic = [];

                                                    // Determine the transfer items based on the type of transfer
                                                    if (transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight') {
                                                      tempTransferItems = createTransferItemsForBooking(transfer, matchingTransfers, index, booking);
                                                      console.log('Generated transfer items for booking: ', JSON.stringify(tempTransferItems, null, 2));
                                                      transferItemPicnic = createTransferItemForBookingPicnic(transfer, matchingTransfers, index, booking, bookings);
                                                      if (transferItemPicnic) {
                                                        tempTransferItems.unshift(transferItemPicnic);
                                                      }

                                                      console.log('Generated picnic 1: ', JSON.stringify(transferItemPicnic, null, 2));
                                                    } else {
                                                      transferItem = createTransferItemForBooking(transfer, matchingTransfers, index, booking, bookings);
                                                      console.log('Generated single transfer item for booking: ', JSON.stringify(transferItem, null, 2));
                                                      // PICNIC
                                                      transferItemPicnic = createTransferItemForBookingPicnic(transfer, matchingTransfers, index, booking, bookings);
                                                      console.log('createTransferItemForBookingPicnic 1');
                                                      // Merge two objects
                                                      if (transferItemPicnic) {
                                                        tempTransferItems = [transferItem, transferItemPicnic];
                                                      } else {
                                                        tempTransferItems = [transferItem];
                                                      }
                                                    }

                                                    // Determine origin and destination names based on transfer type
                                                    let originName, destinationName;
                                                    if (transfer.objType === 'arrival') {
                                                      originName = 'Arrival';
                                                      destinationName = booking.propName;
                                                    } else if (transfer.objType === 'propertyTransfer') {
                                                      const bookingNext = bookings.find((b) => b.id === transfers[index + 1]?.uuid);
                                                      originName = booking.propName;
                                                      destinationName = bookingNext ? bookingNext.propName : 'Unknown'; // Default to 'Unknown' if next booking is not found
                                                    } else if (transfer.objType === 'departure') {
                                                      originName = booking.propName;
                                                      destinationName = 'Departure';
                                                    }

                                                    // Construct and return the new transfer data object
                                                    return {
                                                      uuid: booking.id,
                                                      tuuid: transfer.tuuid,
                                                      objType: transfer.objType,
                                                      propUid: booking.propUid,
                                                      originName: originName,
                                                      destinationName: destinationName,
                                                      propName: booking.propName,
                                                      days: booking.days,
                                                      startDay: booking.startDay,
                                                      endDay: booking.endDay,
                                                      // transferItems:
                                                      //   transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight'
                                                      //     ? tempTransferItems
                                                      //     : transferItem
                                                      //     ? [transferItem]
                                                      //     : [],
                                                      transferItems:
                                                        transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight'
                                                          ? tempTransferItems
                                                          : tempTransferItems
                                                          ? tempTransferItems
                                                          : [],
                                                      addTransfer: false,
                                                      editTransferItem: false,
                                                      collapsed: true,
                                                    };
                                                  });

                                                  console.log('final clicked transfer 1');
                                                  newFinalPageTransfersData = processFeeTypes(newFinalPageTransfersData, 'transfers', properties);
                                                  setFinalPageTransferData(newFinalPageTransfersData);
                                                  console.log(JSON.stringify(newFinalPageTransfersData, undefined, 4));
                                                }

                                                if (visitedFinalPage) {
                                                  let updatedFinalPageData = bookings.map((bookingItem, index) => {
                                                    // Attempt to find the item in the finalPageData array.
                                                    const finalItem = finalPageData.find((pageDataItem) => pageDataItem.uuid === bookingItem.id);

                                                    // If existingFinalPageItem is undefined (i.e., no match was found), default to an object with an empty propertyItems array.
                                                    const existingFinalPageItem = finalItem || {
                                                      uuid: bookingItem.id,
                                                      propUid: bookingItem.propUid,
                                                      propName: bookingItem.propName,
                                                      rateName: bookingItem.rateName,
                                                      days: bookingItem.days,
                                                      startDay: bookingItem.startDay,
                                                      endDay: bookingItem.endDay,
                                                      propertyItems: [],
                                                      addProperty: false,
                                                      addPropertyItem: false,
                                                      editPropertyItem: false,
                                                      collapsed: true,
                                                      supplier: properties[getPropObj(bookingItem.propUid, properties)].supplier.id,
                                                      pax: calculateTotalPax(bookingItem),
                                                      karaniPriced: bookingItem.prices ? true : false,
                                                    };

                                                    let propertyItem;
                                                    existingFinalPageItem.propertyItems = existingFinalPageItem.propertyItems.filter((propertyItem) => propertyItem.edit === true);
                                                    // NOMAD PROPERTY
                                                    if (
                                                      properties[getPropObj(bookingItem.propUid, properties)].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' &&
                                                      properties[getPropObj(bookingItem.propUid, properties)]?.platformResRequest.rates
                                                    ) {
                                                      const result = summarizeComponents(bookingItem); // Replace bookingObject with your booking data
                                                      console.log('summarizeComponents 2 a');
                                                      console.log(JSON.stringify(result, undefined, 4));
                                                      if (result && result.length > 0) {
                                                        result.forEach((comp, compIndex) => {
                                                          const newItem = createPropertyItemForBookingNomad(bookingItem, comp);
                                                          existingFinalPageItem.propertyItems.push(newItem);
                                                        });
                                                      }
                                                      console.log(JSON.stringify(existingFinalPageItem, undefined, 4));
                                                      // VEHICLES
                                                      const newItemVehicle = createPropertyItemForBookingVehicle(bookingItem, bookings, properties);
                                                      if (newItemVehicle) {
                                                        existingFinalPageItem.propertyItems.push(newItemVehicle);
                                                      }
                                                      // KARANI PROPERTY
                                                    } else if (properties[getPropObj(bookingItem.propUid, properties)]?.platformKarani?.active && !bookingItem.notKaraniPriced) {
                                                      console.log('KARANI PROPERTY');

                                                      // TEMP FIX 30/05/24 - start
                                                      // bookingItem.prices.forEach((price) => {
                                                      //   const propertyItemCall = createPropertyItemForBookingKarani(bookingItem, price);

                                                      //   if (propertyItemCall) {
                                                      //     existingFinalPageItem.propertyItems.push(propertyItemCall);
                                                      //   }
                                                      // });
                                                      // if (Array.isArray(bookingItem.prices)) {
                                                      //   bookingItem.prices.forEach((price) => {
                                                      //     const propertyItemCall = createPropertyItemForBookingKarani(bookingItem, price);
                                                      //     if (propertyItemCall) {
                                                      //       existingFinalPageItem.propertyItems.push(propertyItemCall);
                                                      //     }
                                                      //   });
                                                      // } else {
                                                      //   console.error('Prices is undefined or not an array', bookingItem);
                                                      // }
                                                      // TEMP FIX 30/05/24 - end

                                                      // 31/05/24 - start
                                                      if (bookingItem.prices) {
                                                        bookingItem.prices.forEach((price) => {
                                                          const propertyItemCall = createPropertyItemForBookingKarani(bookingItem, price);

                                                          if (propertyItemCall) {
                                                            existingFinalPageItem.propertyItems.push(propertyItemCall);
                                                          }
                                                        });
                                                      } else {
                                                        console.log('bookingItem.prices is undefined');
                                                        console.log(bookingItem.propName);
                                                        console.log('Test fix');
                                                        console.log(properties[getPropObj(bookingItem.propUid, properties)].supplier.id);
                                                        propertyItem = createPropertyItemForBookingKaraniResReq(bookingItem);
                                                      }
                                                      // 31/05/24 - end

                                                      // VEHICLES
                                                      const newItemVehicle = createPropertyItemForBookingVehicle(bookingItem, bookings, properties);
                                                      if (newItemVehicle) {
                                                        existingFinalPageItem.propertyItems.push(newItemVehicle);
                                                      }
                                                      console.log('newItemVehicle KARANI');
                                                      console.log(JSON.stringify(newItemVehicle, undefined, 4));
                                                      // OTHER PROPERTY
                                                    } else {
                                                      console.log('summarizeComponents not 2 a');
                                                      console.log(properties[getPropObj(bookingItem.propUid, properties)].supplier.id);
                                                      propertyItem = createPropertyItemForBooking(bookingItem);
                                                      // // VEHICLES
                                                      // const newItemVehicle = createPropertyItemForBookingVehicle(bookingItem);
                                                      // if (newItemVehicle) {
                                                      //   existingFinalPageItem.propertyItems.push(newItemVehicle);
                                                      // }
                                                    }

                                                    if (
                                                      properties[getPropObj(bookingItem.propUid, properties)].platformResRequest.rates === true ||
                                                      (properties[getPropObj(bookingItem.propUid, properties)]?.platformKarani?.active === true && !bookingItem.notKaraniPriced)
                                                    ) {
                                                      if (propertyItem) {
                                                        existingFinalPageItem.propertyItems.push(propertyItem);
                                                      }
                                                    } else {
                                                      // VEHICLES
                                                      const newItemVehicle = createPropertyItemForBookingVehicle(bookingItem, bookings, properties);
                                                      if (newItemVehicle) {
                                                        existingFinalPageItem.propertyItems.push(newItemVehicle);
                                                      }
                                                      console.log('newItemVehicle');
                                                      console.log(JSON.stringify(newItemVehicle, undefined, 4));
                                                    }
                                                    return {
                                                      ...existingFinalPageItem,
                                                      // new data
                                                      days: bookingItem.days,
                                                      startDay: bookingItem.startDay,
                                                      endDay: bookingItem.endDay,
                                                      // new data
                                                      addProperty: false,
                                                      rateName: bookingItem.rateName,
                                                      addPropertyItem: false,
                                                      editPropertyItem: false,
                                                      collapsed: true,
                                                      transferCollapsed: true,
                                                      activitiesCollapsed: true,
                                                      supplier: properties[getPropObj(bookingItem.propUid, properties)].supplier.id,
                                                      pax: calculateTotalPax(bookingItem),
                                                      karaniPriced: bookingItem.prices ? true : false,
                                                    };
                                                  });
                                                  console.log('final clicked 2');
                                                  updatedFinalPageData = processFeeTypes(updatedFinalPageData, 'properties', properties);
                                                  console.log('updatedFinalPageData 2', JSON.stringify(updatedFinalPageData, null, 2));
                                                  updatedFinalPageData = orderByFeeType(updatedFinalPageData, 'properties');
                                                  setFinalPageData(updatedFinalPageData);

                                                  // TRANSFERS
                                                  const matchingTransfers = transfers.filter((transfer) => transfer.formValues);
                                                  let updatedFinalPageTransfersData = matchingTransfers.map((transfer, index) => {
                                                    const booking = bookings.find((b) => b.id === transfer.uuid);

                                                    const existingFinalPageTransferItem = finalPageTransferData.find(
                                                      (pageDataItem) => pageDataItem.tuuid === transfer.tuuid && pageDataItem.startDay === transfer.startDay,
                                                    );

                                                    // Initialize these variables at the start of each loop iteration
                                                    let tempTransferItems = [];
                                                    let transferItem = [];
                                                    let transferItemPicnic = [];

                                                    // WORKING - Determine the transfer items based on the type of transfer
                                                    if (transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight') {
                                                      tempTransferItems = createTransferItemsForBooking(transfer, matchingTransfers, index, booking); // This should return an array of items
                                                      transferItemPicnic = createTransferItemForBookingPicnic(transfer, matchingTransfers, index, booking, bookings);
                                                      if (transferItemPicnic) {
                                                        tempTransferItems.unshift(transferItemPicnic);
                                                      }
                                                    } else {
                                                      transferItem = createTransferItemForBooking(transfer, matchingTransfers, index, booking, bookings); // This should return a single item
                                                      transferItemPicnic = createTransferItemForBookingPicnic(transfer, matchingTransfers, index, booking, bookings);
                                                      console.log('createTransferItemForBookingPicnic 2');
                                                      console.log(JSON.stringify(transferItemPicnic, null, 2));
                                                      // Merge two objects
                                                      if (transferItemPicnic) {
                                                        tempTransferItems = [transferItem, transferItemPicnic];
                                                        console.log('one');
                                                      } else {
                                                        tempTransferItems = [transferItem];
                                                        console.log('two');
                                                      }
                                                    }

                                                    if (existingFinalPageTransferItem) {
                                                      // Filter out items that are not marked for editing
                                                      existingFinalPageTransferItem.transferItems = existingFinalPageTransferItem.transferItems.filter((item) => item.edit === true);

                                                      // Add the appropriate items to the existing final page transfer item
                                                      existingFinalPageTransferItem.transferItems = [
                                                        ...existingFinalPageTransferItem.transferItems,
                                                        //...(transferItem ? [transferItem] : []),
                                                        ...tempTransferItems,
                                                      ];
                                                    }

                                                    // Determine origin and destination names based on transfer type
                                                    let originName, destinationName;
                                                    if (transfer.objType === 'arrival') {
                                                      originName = 'Arrival';
                                                      destinationName = booking.propName;
                                                    } else if (transfer.objType === 'propertyTransfer') {
                                                      const bookingNext = bookings.find((b) => b.id === transfers[index + 1]?.uuid);
                                                      originName = booking.propName;
                                                      destinationName = bookingNext ? bookingNext.propName : 'Unknown'; // Default to 'Unknown' if next booking is not found
                                                    } else if (transfer.objType === 'departure') {
                                                      originName = booking.propName;
                                                      destinationName = 'Departure';
                                                    }

                                                    // Construct and return the new or updated transfer data object
                                                    return {
                                                      ...(existingFinalPageTransferItem || {}), // Spread existing data, if any
                                                      uuid: booking.id,
                                                      tuuid: transfer.tuuid,
                                                      objType: transfer.objType,
                                                      propUid: booking.propUid,
                                                      originName: originName,
                                                      destinationName: destinationName,
                                                      propName: booking.propName,
                                                      days: booking.days,
                                                      startDay: booking.startDay,
                                                      endDay: booking.endDay,
                                                      transferItems: existingFinalPageTransferItem
                                                        ? existingFinalPageTransferItem.transferItems
                                                        : transfer.formValues.type === 'bookFlight' || transfer.formValues.type === 'flight'
                                                        ? tempTransferItems
                                                        : tempTransferItems
                                                        ? tempTransferItems
                                                        : [],
                                                      addTransfer: false,
                                                      editTransferItem: false,
                                                      collapsed: true,
                                                    };
                                                  });

                                                  console.log('final clicked transfer 2');
                                                  updatedFinalPageTransfersData = processFeeTypes(updatedFinalPageTransfersData, 'transfers', properties);
                                                  setFinalPageTransferData(updatedFinalPageTransfersData);
                                                  console.log(JSON.stringify(updatedFinalPageTransfersData, undefined, 4));
                                                }
                                                // set OTHER ARRANGEMENTS - START
                                                // const getHighestTotalPax = getHighestPropertyPax(bookings);
                                                // setHighestTotalPax(getHighestTotalPax);

                                                let newOtherArrangementsData = [];

                                                const getFlyingDoctorItem = getOtherArrangementsByType(otherArrangementsData, 'otherArrangementsFlyingDoctor');
                                                const flyingDoctorUnits = getHighestPropertyPax(bookings);
                                                const flyingDoctorUnitPrice = getFlyingDoctorItem?.unitPrice >= 0 ? getFlyingDoctorItem.unitPrice : 25;

                                                // const flyingDoctorUnitPrice = 25;
                                                const newFlyingDoctorItem = {
                                                  name: 'Flying Doctors insurance',
                                                  description: `Would you like to include Flying Doctors insurance for all passengers? (${flyingDoctorUnits} passengers x ${
                                                    '$' + flyingDoctorUnitPrice
                                                  })`,
                                                  startDay: bookings[0].startDay,
                                                  units: flyingDoctorUnits,
                                                  unitPrice: flyingDoctorUnitPrice,
                                                  supplierName: 'AMREF Flying Doctors Limited',
                                                  supplierUid: '9b8a3fd8-cc88-443f-b629-f3c70856ae5a',
                                                  saleTotal: getFlyingDoctorItem.saleTotal === 0 ? 0 : flyingDoctorUnits * flyingDoctorUnitPrice,
                                                  creatioType: 'otherArrangementsFlyingDoctor',
                                                  type: 'otherArrangementsFlyingDoctor',
                                                  edit: true,
                                                  checked: getFlyingDoctorItem.checked === false ? false : true,
                                                  editing: false,
                                                };

                                                const flyingDoctorItem = newFlyingDoctorItem;

                                                const getPreferredGuideItem = getOtherArrangementsByType(otherArrangementsData, 'otherArrangementsPreferredGuide');
                                                const makeUnits = !getPreferredGuideItem.units
                                                  ? countNorthernGuideNights(bookings)
                                                  : getPreferredGuideItem.units !== countNorthernGuideNights(bookings)
                                                  ? getPreferredGuideItem.units
                                                  : countNorthernGuideNights(bookings);
                                                // getPreferredGuideItem.units !== countNorthernGuideNights(bookings) ? getPreferredGuideItem.units : countNorthernGuideNights(bookings);

                                                const getPreferredGuideUnitPrice = getPreferredGuideItem?.unitPrice >= 0 ? getPreferredGuideItem.unitPrice : 125;
                                                const newPreferredGuideItem = {
                                                  name: 'Preferred Guide Supplement',
                                                  description: !getPreferredGuideItem || getPreferredGuideItem.description.length === 0 ? 'Add guide name(s)' : getPreferredGuideItem.description,
                                                  startDay: bookings[0].startDay,
                                                  units: makeUnits,
                                                  unitPrice: getPreferredGuideUnitPrice,
                                                  supplierName: 'Nomad Tanzania',
                                                  supplierUid: 'ded3a3ed-aeaf-4495-9069-7754a649de67',
                                                  saleTotal: !getPreferredGuideItem || getPreferredGuideItem.saleTotal === 0 ? 0 : makeUnits * getPreferredGuideUnitPrice,
                                                  creatioType: 'otherArrangementsPreferredGuide',
                                                  type: 'otherArrangementsPreferredGuide',
                                                  edit: true,
                                                  checked: getPreferredGuideItem.checked ? getPreferredGuideItem.checked : false,
                                                  editing: false,
                                                };

                                                const preferredGuideItem = newPreferredGuideItem;

                                                newOtherArrangementsData.push(flyingDoctorItem);
                                                if (countNorthernGuideNights(bookings) !== 0) {
                                                  newOtherArrangementsData.push(preferredGuideItem);
                                                }

                                                setOtherArrangementsData(newOtherArrangementsData);

                                                handleCheckUnallocated(customFinalItems, setCustomFinalItems, bookings, transfers, activitiesData);
                                                setAddFinalItemDetails(null);

                                                let updatedCustomFinalItems = setAddToFalse(customFinalItems);
                                                //setCustomFinalItems(updatedCustomFinalItems);

                                                // apply hornbillDiscount - start
                                                if (hornbillDiscountAvailable) {
                                                  const hornbillDiscountCheck = hornbillDiscount(bookings, properties);
                                                  console.log('hornbillDiscountCheck', hornbillDiscountCheck);
                                                  // remove any updatedCustomFinalItems where discount = true
                                                  updatedCustomFinalItems = updatedCustomFinalItems.filter((item) => item.discount !== true);
                                                  if (hornbillDiscountCheck) {
                                                    const lastBookingsId = bookings[bookings.length - 1].id;
                                                    updatedCustomFinalItems.push({
                                                      id: lastBookingsId,
                                                      uuid: uuidv4(),
                                                      collapsed: true,
                                                      add: false,
                                                      edit: false,
                                                      departure: true,
                                                      type: 'customOther',
                                                      discount: true,
                                                      fields: [
                                                        {
                                                          feeTypeUid: 'e52813a4-3d2b-4f1a-acff-4c1c7b5ae158',
                                                          feeTypeName: 'Nomad Accommodation',
                                                          date: bookings[bookings.length - 1].endDay,
                                                          supplierUid: 'ded3a3ed-aeaf-4495-9069-7754a649de67',
                                                          supplierName: 'Nomad Tanzania',
                                                          description: `Hornbill Early Access Offer Discount of -$${hornbillDiscountCheck}`,
                                                          units: 1,
                                                          pax: 1,
                                                          net: -hornbillDiscountCheck,
                                                          total: -hornbillDiscountCheck,
                                                          uuid: uuidv4(),
                                                          edit: false,
                                                          star: true,
                                                        },
                                                      ],
                                                    });
                                                  }
                                                }
                                                // apply hornbillDiscount - end
                                                setCustomFinalItems(updatedCustomFinalItems);

                                                console.log(updatedCustomFinalItems);
                                                setStep('final');
                                              }}
                                            >
                                              Next: Summary
                                            </button>

                                            <button
                                              className="w-full bg-white text-nomadEvergreen-700 border border-gray-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadEvergreen-800 hover:border-nomadGray-900 flex items-center justify-center"
                                              // onClick={async () => {
                                              //   console.log("BACK");
                                              //   setStep("bookings");
                                              // }}
                                              onClick={async () => {
                                                confirmAlert({
                                                  customUI: ({ onClose }) => {
                                                    return (
                                                      <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                                                        {(user.internalUser === false && !semver.gte(createVersionCheck, '2.5.0')) || mojoItinerary === true ? (
                                                          <p className="text-sm font-semibold pb-2">Would you like to go back to the properties page?</p>
                                                        ) : (
                                                          <p className="text-sm font-semibold pb-2">Would you like to go back to the transfers page?</p>
                                                        )}
                                                        <p className="text-sm pb-2">Please note, you might lose some activities data if you change details on the properties page.</p>
                                                        <div className="flex mt-5 justify-end">
                                                          <div className="flex justify-end gap-4">
                                                            <ButtonOutlineSmall
                                                              onClick={() => {
                                                                setRefreshBtn(false);
                                                                onClose();
                                                              }}
                                                              text="Cancel"
                                                              color="dark"
                                                            />
                                                            <ButtonPrimarySmall
                                                              onClick={async () => {
                                                                console.log(refreshBtn);
                                                                if (fetchingRates) {
                                                                  return toast.error(`Rates are still being fetched, please wait a few seconds then click "Confirm" again`);
                                                                }
                                                                // build
                                                                //setBookingsStore([...bookings]);
                                                                setBookingsStore(JSON.parse(JSON.stringify(bookings)));

                                                                console.log('BACK');

                                                                //Activities rebuild
                                                                setActivitiesDataLatest([...activitiesData]);
                                                                //setActivitiesDataLatest(activitiesData);

                                                                setActivities([]);
                                                                setActivitiesList([]);
                                                                //setActivitiesData([]);
                                                                setSelectedActivityProperty(null);
                                                                setSelectedActivityPropertyUid(null);
                                                                setSelectedActivityDay(null);
                                                                setSelectedActivityDate(null);
                                                                setResetActivities(null);
                                                                setActivitiesCost(0);
                                                                if ((user.internalUser === false && !semver.gte(createVersionCheck, '2.5.0')) || mojoItinerary === true) {
                                                                  updateBookingsNotModified(bookings, setBookings);
                                                                  setStep('bookings');
                                                                } else {
                                                                  setStep('transfers');
                                                                }
                                                                //setStep("transfers");
                                                                onClose();
                                                              }}
                                                              text="Proceed anyway"
                                                              color="dark"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  },
                                                });
                                              }}
                                            >
                                              Back
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ) : null}

                                    <div className="flex text-left  w-full my-5 "></div>
                                  </div>

                                  {/* // SECTION 4: REMOVED ACTIVITIES START  ---------------------------------------------------------------------------------- */}
                                  {activitiesDataRemoved?.some((property) => property.activities?.some((day) => day?.some((activity) => activity.removed))) && (
                                    <div style={deeShadow} className="mt-5 px-5 pt-3 pb-5 bg-white rounded-lg w-[320px]">
                                      <div className={``}>
                                        {/* TABS */}
                                        {/* TODO: Deal with border color and dynamic colors */}
                                        <div className={`flex items-center pb-5`}>
                                          <div className={`text-lg font-semibold brand-text-color-800 mb-0`}>Removed activities</div>
                                        </div>
                                        {activitiesDataRemoved?.map((property, propertyIndex) => {
                                          return (
                                            <>
                                              <div key={uuidv4()} className="text-base font-semibold leading-tight pb-1 brand-text-grey-v2 truncate text-ellipsis overflow-hidden">
                                                {getPropertyName(property.propUid, properties)}
                                                {/* id {property.id} */}
                                              </div>

                                              {property.activities?.map((day, dayIndex) =>
                                                day.map((activity, activityIndex) =>
                                                  activity.removed ? (
                                                    <>
                                                      <div className="text-sm font-normal leading-tight pb-1 brand-text-grey-v2 truncate text-ellipsis overflow-hidden">
                                                        {moment(activity.selectedActivityDate).subtract(0, 'days').format('Do MMM YYYY')}
                                                      </div>
                                                      <div key={activity.uuid}>
                                                        <div className="text-sm font-normal leading-tight pb-1 brand-text-grey-v2 truncate text-ellipsis overflow-hidden">{activity.name}</div>
                                                        <div className="text-sm font-normal leading-tight pb-1 brand-text-grey-v2 truncate text-ellipsis overflow-hidden">{activity.removalReason}</div>
                                                      </div>
                                                      {(dayIndex !== property.activities.length - 1 || activityIndex !== day.length - 1) && <div className="border-b border-slate-400 my-4"></div>}
                                                      {dayIndex === property.activities.length - 1 && activityIndex === day.length - 1 && <div className="mb-2"></div>}
                                                    </>
                                                  ) : null,
                                                ),
                                              )}
                                              {propertyIndex !== activitiesDataRemoved.length - 1 && <div className="mb-2"></div>}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}

                                  {/* // SECTION 4: REMOVED ACTIVITIES  END  ---------------------------------------------------------------------------------- */}
                                </div>

                                {/* //! SECTION 3: ACTIVITIES END  ---------------------------------------------------------------------------------- */}
                              </div>
                            </div>

                            {/* RIGHT COLUMN END */}
                          </div>
                        )}

                        {/* LAYOUT FOR FINAL PAGE */}
                        {step === 'final' && (
                          <div>
                            <div className="grid grid-flow-col auto-cols-max rounded-lg bg-white pt-4">
                              {/* //! SECTION 1: START ---------------------------------------------------------------------------------- */}
                              <div className="">
                                {' '}
                                {/* PROPERTIES START */}
                                {/* //! SECTION 2: START ---------------------------------------------------------------------------------- */}
                                <div className="pr-5">
                                  <div className="bg-white rounded-lg w-[1235px]">
                                    {/* TABS */}

                                    <div className={`px-5 pt-5 grid grid-cols-12`}>
                                      <div className="col-span-12">
                                        <div className={`text-xl brand-text-color-800 font-semibold mb-2`}>Summary and Extras</div>
                                        {customFinalItems?.some((item) => item.discount === true) && (
                                          <InfoBox
                                            icon={MdOutlineDiscount}
                                            text={`Congratulations - A discount of $${formatPrice(
                                              Math.abs(customFinalItems.find((item) => item.discount === true)?.fields[0]?.total || 0),
                                            )} has been applied for booking in Hornbill`}
                                            bgColor="bg-green-50"
                                            textColor="text-green-700"
                                            fontSize="text-sm"
                                          />
                                        )}
                                        <div className="flex items-center">
                                          <div className="flex items-center font-normal text-sm mb-2">
                                            <span>Review your itinerary and choose any optional extras</span>
                                            <IoMdInformationCircleOutline size={18} className="ml-2" />
                                          </div>
                                          <div className="flex text-sm font-normal ml-auto">
                                            <div>
                                              {customFinalCut && (
                                                <div className="text-red-600">
                                                  <button onClick={() => setCustomFinalCut(null)} className="flex items-center ">
                                                    <span className="mr-2">Clear clipboard</span>
                                                    <TbClipboardOff size={20} />
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                            <div>
                                              <div className="ml-5 text-black">
                                                <button
                                                  onClick={() =>
                                                    collapseExpandAllItems(
                                                      finalPageData,
                                                      setFinalPageData,
                                                      finalPageTransferData,
                                                      setFinalPageTransferData,
                                                      customFinalItems,
                                                      setCustomFinalItems,
                                                      setFinalCollapse,
                                                      false,
                                                    )
                                                  }
                                                  className="flex items-center"
                                                >
                                                  <span className="mr-2">Expand all</span>
                                                  <IoIosArrowDown size={20} />
                                                </button>
                                              </div>
                                            </div>

                                            <div>
                                              <div className="ml-5 text-black">
                                                <button
                                                  onClick={() =>
                                                    collapseExpandAllItems(
                                                      finalPageData,
                                                      setFinalPageData,
                                                      finalPageTransferData,
                                                      setFinalPageTransferData,
                                                      customFinalItems,
                                                      setCustomFinalItems,
                                                      setFinalCollapse,
                                                      true,
                                                    )
                                                  }
                                                  className="flex items-center"
                                                >
                                                  <span className="mr-2">Collapse all</span>
                                                  <IoIosArrowUp size={20} />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* TODO: Deal with border color and dynamic colors */}
                                    <div className={`px-5 pb-7 grid grid-cols-12`}>
                                      <div className="col-span-12">
                                        <div className={`flex items-center justify-between mb-0`}>
                                          <div className="flex items-center"></div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mx-5">
                                      <div className="mb-0">
                                        <FinalSlideOut
                                          isOpen={isSlideOutOpen}
                                          onClose={handleCloseSlideOut}
                                          customFinalItems={customFinalItems}
                                          setCustomFinalItems={setCustomFinalItems}
                                          addFinalItemDetails={addFinalItemDetails}
                                          customItemToEdit={customItemToEdit}
                                          finalSlideOutDate={finalSlideOutDate}
                                        />
                                      </div>
                                      {bookings.map((booking, bi) => {
                                        const firstDay = dateStart;
                                        let arrivalTransfer = null;
                                        if (bi === 0) {
                                          arrivalTransfer = transfers.find((transfer) => transfer.uuid === booking.id && transfer.objType === 'arrival' && transfer.formValues);
                                        }
                                        let departureTransfer = null;
                                        if (bi === bookings.length - 1) {
                                          departureTransfer = transfers.find((transfer) => transfer.uuid === booking.id && transfer.objType === 'departure' && transfer.formValues);
                                        }

                                        let propertyTransfer = null;
                                        if (bi >= 0 && bi < bookings.length - 1) {
                                          propertyTransfer = transfers.find((transfer) => transfer.uuid === booking.id && transfer.objType === 'propertyTransfer' && transfer.formValues);
                                        }

                                        // FLATTEN ACTIVITIES
                                        const flattenActivities = (activitiesData) => {
                                          return activitiesData.reduce((acc, item) => {
                                            const flattenedActivities = item.activities.flat().map((activity) => ({ ...activity, parent: item }));

                                            return [...acc, ...flattenedActivities];
                                          }, []);
                                        };

                                        console.log('FINAL ITEM VARS');
                                        console.log('bookings.length', bookings.length);
                                        console.log('bi', bi);
                                        console.log('arrivalTdepartureTransferransfer', departureTransfer);

                                        return (
                                          <div key={uuidv4()} className="mx-0">
                                            {/* slideout */}
                                            {/* <FinalSlideOut isOpen={isSlideOutOpen} onClose={handleCloseSlideOut} /> */}

                                            {/* {bi + 1}: {booking.propName} */}
                                            {/* CHECK FOR CUSTOM */}

                                            {/* VIP ARRIVAL */}
                                            {arrivalTransfer && arrivalTransfer?.formValues.includeArrivalVip && (
                                              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                                <FinalVip
                                                  item={arrivalTransfer}
                                                  bookings={bookings}
                                                  booking={booking}
                                                  airports={airports}
                                                  //pickupDropoff={'pickup'}
                                                  arrivalDeparture={'arrival'}
                                                  customFinalItems={customFinalItems}
                                                  setCustomFinalItems={setCustomFinalItems}
                                                  customFinalCut={customFinalCut}
                                                  setCustomFinalCut={setCustomFinalCut}
                                                  handleOpenSlideOut={handleOpenSlideOut}
                                                  setCustomItemToEdit={setCustomItemToEdit}
                                                  setAddFinalItemDetails={setAddFinalItemDetails}
                                                  properties={properties}
                                                  transfers={transfers}
                                                  shouldCollapse={finalCollapse}
                                                  firstDay={firstDay}
                                                  setFinalSlideOutDate={setFinalSlideOutDate}
                                                />
                                              </div>
                                            )}

                                            {/* ARRIVAL TRANSFER */}
                                            {arrivalTransfer &&
                                              finalPageTransferData
                                                .filter((item) => item.uuid === booking.id && item.objType === 'arrival')
                                                .map((item, index) => {
                                                  // const nextItem = index < bookings.length - 1 ? bookings[index + 1] : null;
                                                  // const prevItem = index > 0 ? bookings[index - 1] : null;

                                                  return (
                                                    <div key={uuidv4()} style={{ zIndex: 1000 - index }}>
                                                      <FinalTransferItem
                                                        item={item}
                                                        index={index}
                                                        suppliers={suppliers}
                                                        finalTransferInputs={finalTransferInputs}
                                                        setFinalTransferInputs={setFinalTransferInputs}
                                                        finalPageTransferData={finalPageTransferData}
                                                        setFinalPageTransferData={setFinalPageTransferData}
                                                        resetFinalTransferInputs={resetFinalTransferInputs}
                                                        resetEditTransferItem={resetEditTransferItem}
                                                        setFinalInputs={setFinalInputs}
                                                        customFinalItems={customFinalItems}
                                                        setCustomFinalItems={setCustomFinalItems}
                                                        customFinalCut={customFinalCut}
                                                        setCustomFinalCut={setCustomFinalCut}
                                                        handleOpenSlideOut={handleOpenSlideOut}
                                                        setCustomItemToEdit={setCustomItemToEdit}
                                                        setAddFinalItemDetails={setAddFinalItemDetails}
                                                        transfers={transfers}
                                                        properties={properties}
                                                        firstDay={firstDay}
                                                        setFinalSlideOutDate={setFinalSlideOutDate}
                                                      />
                                                    </div>
                                                  );
                                                })}

                                            {/* ARRIVAL PICKUP */}
                                            {arrivalTransfer && arrivalTransfer?.formValues.includeArrivalTransfer && (
                                              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                                <FinalDropPickup
                                                  item={arrivalTransfer}
                                                  bookings={bookings}
                                                  booking={booking}
                                                  airports={airports}
                                                  pickupDropoff={'pickup'}
                                                  customFinalItems={customFinalItems}
                                                  setCustomFinalItems={setCustomFinalItems}
                                                  customFinalCut={customFinalCut}
                                                  setCustomFinalCut={setCustomFinalCut}
                                                  handleOpenSlideOut={handleOpenSlideOut}
                                                  setCustomItemToEdit={setCustomItemToEdit}
                                                  setAddFinalItemDetails={setAddFinalItemDetails}
                                                  properties={properties}
                                                  transfers={transfers}
                                                  shouldCollapse={finalCollapse}
                                                  firstDay={firstDay}
                                                  setFinalSlideOutDate={setFinalSlideOutDate}
                                                />
                                              </div>
                                            )}

                                            {/* PROPERTY */}
                                            {finalPageData
                                              .filter((item) => item.uuid === booking.id)
                                              .map((item, index) => (
                                                <FinalPageComp
                                                  key={item.uuid}
                                                  item={item}
                                                  index={index}
                                                  finalPageData={finalPageData}
                                                  setFinalPageData={setFinalPageData}
                                                  properties={properties}
                                                  getPropObj={getPropObj}
                                                  rateLabelMap={rateLabelMap}
                                                  formatPrice={formatPrice}
                                                  suppliers={suppliers}
                                                  finalInputs={finalInputs}
                                                  setFinalInputs={setFinalInputs}
                                                  resetFinalInputs={resetFinalInputs}
                                                  customFinalItems={customFinalItems}
                                                  setCustomFinalItems={setCustomFinalItems}
                                                  customFinalCut={customFinalCut}
                                                  setCustomFinalCut={setCustomFinalCut}
                                                  handleOpenSlideOut={handleOpenSlideOut}
                                                  setCustomItemToEdit={setCustomItemToEdit}
                                                  setAddFinalItemDetails={setAddFinalItemDetails}
                                                  bookngs={bookings}
                                                  finalItem={bookings.length === bi + 1 && !departureTransfer?.formValues ? true : false}
                                                  firstDay={firstDay}
                                                  setFinalSlideOutDate={setFinalSlideOutDate}
                                                />
                                              ))}

                                            {/* ACTIVITIES */}
                                            {flattenActivities(activitiesData)
                                              .filter((activity) => activity.parent.id === booking.id)
                                              .map((activity, index) => (
                                                <div key={activity.uuid} style={{ zIndex: 1000 - index }}>
                                                  <FinalActivity
                                                    item={activity}
                                                    index={index}
                                                    bookings={bookings}
                                                    booking={booking}
                                                    activitiesData={activitiesData}
                                                    customFinalItems={customFinalItems}
                                                    setCustomFinalItems={setCustomFinalItems}
                                                    customFinalCut={customFinalCut}
                                                    setCustomFinalCut={setCustomFinalCut}
                                                    handleOpenSlideOut={handleOpenSlideOut}
                                                    setCustomItemToEdit={setCustomItemToEdit}
                                                    setAddFinalItemDetails={setAddFinalItemDetails}
                                                    shouldCollapse={finalCollapse}
                                                    firstDay={firstDay}
                                                    setFinalSlideOutDate={setFinalSlideOutDate}
                                                    finalItem={bookings.length === bi + 1 && !departureTransfer?.formValues ? true : false}
                                                  />
                                                </div>
                                              ))}

                                            {propertyTransfer && propertyTransfer?.formValues.includeDepartureTransfer && propertyTransfer.objType === 'propertyTransfer' && (
                                              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                                <FinalDropPickup
                                                  item={propertyTransfer}
                                                  bookings={bookings}
                                                  booking={booking}
                                                  airports={airports}
                                                  pickupDropoff={'dropoff'}
                                                  customFinalItems={customFinalItems}
                                                  setCustomFinalItems={setCustomFinalItems}
                                                  customFinalCut={customFinalCut}
                                                  setCustomFinalCut={setCustomFinalCut}
                                                  handleOpenSlideOut={handleOpenSlideOut}
                                                  setCustomItemToEdit={setCustomItemToEdit}
                                                  setAddFinalItemDetails={setAddFinalItemDetails}
                                                  properties={properties}
                                                  transfers={transfers}
                                                  shouldCollapse={finalCollapse}
                                                  firstDay={firstDay}
                                                  setFinalSlideOutDate={setFinalSlideOutDate}
                                                />
                                              </div>
                                            )}

                                            {/* VIP PROPERTY DEPARTURE */}
                                            {propertyTransfer && propertyTransfer?.formValues.includeDepartureVip && (
                                              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                                <FinalVip
                                                  item={propertyTransfer}
                                                  bookings={bookings}
                                                  booking={booking}
                                                  airports={airports}
                                                  //pickupDropoff={'dropoff'}
                                                  arrivalDeparture={'departure'}
                                                  customFinalItems={customFinalItems}
                                                  setCustomFinalItems={setCustomFinalItems}
                                                  customFinalCut={customFinalCut}
                                                  setCustomFinalCut={setCustomFinalCut}
                                                  handleOpenSlideOut={handleOpenSlideOut}
                                                  setCustomItemToEdit={setCustomItemToEdit}
                                                  setAddFinalItemDetails={setAddFinalItemDetails}
                                                  properties={properties}
                                                  transfers={transfers}
                                                  shouldCollapse={finalCollapse}
                                                  firstDay={firstDay}
                                                  setFinalSlideOutDate={setFinalSlideOutDate}
                                                />
                                              </div>
                                            )}

                                            {/* INTER PROPERTY TRANSFER */}
                                            {propertyTransfer &&
                                              finalPageTransferData
                                                .filter((item) => item.uuid === booking.id && item.objType === 'propertyTransfer')
                                                .map((item, index) => {
                                                  // const nextItem = index < bookings.length - 1 ? bookings[index + 1] : null;
                                                  // const prevItem = index > 0 ? bookings[index - 1] : null;

                                                  return (
                                                    <div key={uuidv4()} style={{ zIndex: 1000 - index }}>
                                                      <FinalTransferItem
                                                        item={item}
                                                        index={index}
                                                        suppliers={suppliers}
                                                        finalTransferInputs={finalTransferInputs}
                                                        setFinalTransferInputs={setFinalTransferInputs}
                                                        finalPageTransferData={finalPageTransferData}
                                                        setFinalPageTransferData={setFinalPageTransferData}
                                                        resetFinalTransferInputs={resetFinalTransferInputs}
                                                        resetEditTransferItem={resetEditTransferItem}
                                                        setFinalInputs={setFinalInputs}
                                                        customFinalItems={customFinalItems}
                                                        setCustomFinalItems={setCustomFinalItems}
                                                        customFinalCut={customFinalCut}
                                                        setCustomFinalCut={setCustomFinalCut}
                                                        handleOpenSlideOut={handleOpenSlideOut}
                                                        setCustomItemToEdit={setCustomItemToEdit}
                                                        setAddFinalItemDetails={setAddFinalItemDetails}
                                                        transfers={transfers}
                                                        properties={properties}
                                                        firstDay={firstDay}
                                                        setFinalSlideOutDate={setFinalSlideOutDate}
                                                      />
                                                    </div>
                                                  );
                                                })}

                                            {/* VIP PROPERTY ARRIVAL */}
                                            {propertyTransfer && propertyTransfer?.formValues.includeArrivalVip && (
                                              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                                <FinalVip
                                                  item={propertyTransfer}
                                                  bookings={bookings}
                                                  booking={booking}
                                                  airports={airports}
                                                  //pickupDropoff={'pickup'}
                                                  arrivalDeparture={'arrival'}
                                                  customFinalItems={customFinalItems}
                                                  setCustomFinalItems={setCustomFinalItems}
                                                  customFinalCut={customFinalCut}
                                                  setCustomFinalCut={setCustomFinalCut}
                                                  handleOpenSlideOut={handleOpenSlideOut}
                                                  setCustomItemToEdit={setCustomItemToEdit}
                                                  setAddFinalItemDetails={setAddFinalItemDetails}
                                                  properties={properties}
                                                  transfers={transfers}
                                                  shouldCollapse={finalCollapse}
                                                  firstDay={firstDay}
                                                  setFinalSlideOutDate={setFinalSlideOutDate}
                                                />
                                              </div>
                                            )}

                                            {/* ARRIVAL PICKUP */}
                                            {propertyTransfer && propertyTransfer?.formValues.includeArrivalTransfer && propertyTransfer.objType === 'propertyTransfer' && (
                                              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                                <FinalDropPickup
                                                  item={propertyTransfer}
                                                  bookings={bookings}
                                                  booking={booking}
                                                  airports={airports}
                                                  pickupDropoff={'pickup'}
                                                  customFinalItems={customFinalItems}
                                                  setCustomFinalItems={setCustomFinalItems}
                                                  customFinalCut={customFinalCut}
                                                  setCustomFinalCut={setCustomFinalCut}
                                                  handleOpenSlideOut={handleOpenSlideOut}
                                                  setCustomItemToEdit={setCustomItemToEdit}
                                                  setAddFinalItemDetails={setAddFinalItemDetails}
                                                  properties={properties}
                                                  transfers={transfers}
                                                  shouldCollapse={finalCollapse}
                                                  firstDay={firstDay}
                                                  setFinalSlideOutDate={setFinalSlideOutDate}
                                                />
                                              </div>
                                            )}

                                            {/* DEPARTURE DROPOFF */}
                                            {departureTransfer && departureTransfer?.formValues.includeDepartureTransfer && (
                                              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                                <FinalDropPickup
                                                  item={departureTransfer}
                                                  bookings={bookings}
                                                  booking={booking}
                                                  airports={airports}
                                                  pickupDropoff={'dropoff'}
                                                  customFinalItems={customFinalItems}
                                                  setCustomFinalItems={setCustomFinalItems}
                                                  customFinalCut={customFinalCut}
                                                  setCustomFinalCut={setCustomFinalCut}
                                                  handleOpenSlideOut={handleOpenSlideOut}
                                                  setCustomItemToEdit={setCustomItemToEdit}
                                                  setAddFinalItemDetails={setAddFinalItemDetails}
                                                  properties={properties}
                                                  transfers={transfers}
                                                  shouldCollapse={finalCollapse}
                                                  firstDay={firstDay}
                                                  setFinalSlideOutDate={setFinalSlideOutDate}
                                                />
                                              </div>
                                            )}

                                            {/* VIP DEPARTURE */}
                                            {departureTransfer && departureTransfer?.formValues.includeDepartureVip && (
                                              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                                                <FinalVip
                                                  item={departureTransfer}
                                                  bookings={bookings}
                                                  booking={booking}
                                                  airports={airports}
                                                  //pickupDropoff={'dropoff'}
                                                  arrivalDeparture={'arrival'}
                                                  customFinalItems={customFinalItems}
                                                  setCustomFinalItems={setCustomFinalItems}
                                                  customFinalCut={customFinalCut}
                                                  setCustomFinalCut={setCustomFinalCut}
                                                  handleOpenSlideOut={handleOpenSlideOut}
                                                  setCustomItemToEdit={setCustomItemToEdit}
                                                  setAddFinalItemDetails={setAddFinalItemDetails}
                                                  properties={properties}
                                                  transfers={transfers}
                                                  shouldCollapse={finalCollapse}
                                                  firstDay={firstDay}
                                                  setFinalSlideOutDate={setFinalSlideOutDate}
                                                />
                                              </div>
                                            )}

                                            {/* DEPARTURE TRANSFER */}
                                            {departureTransfer &&
                                              finalPageTransferData
                                                .filter((item) => item.uuid === booking.id && item.objType === 'departure')
                                                .map((item, index) => {
                                                  // const nextItem = index < bookings.length - 1 ? bookings[index + 1] : null;
                                                  // const prevItem = index > 0 ? bookings[index - 1] : null;

                                                  return (
                                                    <div key={uuidv4()} style={{ zIndex: 1000 - index }}>
                                                      <FinalTransferItem
                                                        item={item}
                                                        index={index}
                                                        suppliers={suppliers}
                                                        finalTransferInputs={finalTransferInputs}
                                                        setFinalTransferInputs={setFinalTransferInputs}
                                                        finalPageTransferData={finalPageTransferData}
                                                        setFinalPageTransferData={setFinalPageTransferData}
                                                        resetFinalTransferInputs={resetFinalTransferInputs}
                                                        resetEditTransferItem={resetEditTransferItem}
                                                        setFinalInputs={setFinalInputs}
                                                        customFinalItems={customFinalItems}
                                                        setCustomFinalItems={setCustomFinalItems}
                                                        customFinalCut={customFinalCut}
                                                        setCustomFinalCut={setCustomFinalCut}
                                                        handleOpenSlideOut={handleOpenSlideOut}
                                                        setCustomItemToEdit={setCustomItemToEdit}
                                                        setAddFinalItemDetails={setAddFinalItemDetails}
                                                        transfers={transfers}
                                                        properties={properties}
                                                        firstDay={firstDay}
                                                        setFinalSlideOutDate={setFinalSlideOutDate}
                                                        departureButtons={false}
                                                      />
                                                    </div>
                                                  );
                                                })}
                                          </div>
                                        );
                                      })}

                                      {/* Discount Items */}
                                      {(() => {
                                        const foundItemsDiscount = customFinalItems.filter((item) => item.discount);

                                        return (
                                          foundItemsDiscount.length > 0 && (
                                            <div key={uuidv4()} style={{ zIndex: 1000 - 1 }}>
                                              <FinalDiscount
                                                key={foundItemsDiscount[0].uuid}
                                                foundItem={foundItemsDiscount[0]}
                                                customFinalCut={customFinalCut}
                                                handlePaste={null}
                                                addItem={null}
                                                handleCut={null}
                                                deleteItem={null}
                                                customFinalItems={customFinalItems}
                                                setCustomFinalItems={setCustomFinalItems}
                                                setCustomFinalCut={setCustomFinalCut}
                                                searchId={null}
                                                pickupDropoff={null}
                                                handleOpenSlideOut={handleOpenSlideOut}
                                                setCustomItemToEdit={setCustomItemToEdit}
                                                setAddFinalItemDetails={setAddFinalItemDetails}
                                                formatPrice={formatPrice}
                                                firstDay={bookings[0].startDay}
                                                setFinalSlideOutDate={setFinalSlideOutDate}
                                              />
                                            </div>
                                          )
                                        );
                                      })()}

                                      {/* UNALLOCATED */}
                                      <div key={uuidv4()} className="mx-2">
                                        {/* Directly evaluate and conditionally render based on the count */}
                                        {customFinalItems.filter((item) => item.id === 'unallocated').length >= 1 && (
                                          <div style={{ zIndex: 1000 }}>
                                            <FinalUnallocated
                                              item={false} // Pass the unallocated item here
                                              index={1}
                                              customFinalItems={customFinalItems}
                                              setCustomFinalItems={setCustomFinalItems}
                                              customFinalCut={customFinalCut}
                                              setCustomFinalCut={setCustomFinalCut}
                                              handleOpenSlideOut={handleOpenSlideOut}
                                              setCustomItemToEdit={setCustomItemToEdit}
                                              setAddFinalItemDetails={setAddFinalItemDetails}
                                              firstDay={bookings[0].startDay}
                                              setFinalSlideOutDate={setFinalSlideOutDate}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    {/* LAYOUT OTHER REQUIREMENTS */}
                                    <div className={`mt-14 px-5 pb-5`}>
                                      {/* FINAL: PROPERTIES */}
                                      <div className="bg-white border-t  border-l rounded-t-xl drop-shadow-md border-slate-200 grid grid-cols-12 text-sm font-semibold">
                                        <div className="col-span-10 pl-5 border-r border-slate-200 py-2">OTHER ARRANGEMENTS</div>
                                        <div className="col-span-1 pl-5 border-r border-slate-200 py-2">TOTAL</div>
                                        <div className="col-span-1 pl-5  border-slate-200 py-2"></div>
                                      </div>

                                      <div key={uuidv4()}>
                                        <div
                                          className={`bg-white border border-slate-200 brand-text-grey-v2`}
                                          style={{
                                            WebkitTransform: 'translateZ(0)' /* Safari */,
                                          }}
                                        >
                                          {/* ROW 1 (Property info) */}

                                          <div className={`mb-0`}>
                                            <div className={`grid grid-cols-12`}>
                                              {/* IMG */}

                                              {/* ROOM NAME + DESCRIPTION */}
                                              <div className="col-span-12 bg-white">
                                                {/* ITEM 1 */}
                                                <div className="grid grid-cols-12 border-slate-200 text-xs font-medium">
                                                  <div className="text-xs truncate text-ellipsis overflow-hidden col-span-2 ml-5 border-r border-slate-200 py-2">
                                                    <div className="flex items-center">
                                                      <span className="text-black">NAME</span>
                                                    </div>
                                                  </div>
                                                  <div className="col-span-5 ml-5 border-r border-slate-200 py-2 text-black">DESCRIPTION</div>
                                                  <div className="col-span-1  ml-5 border-r border-slate-200 py-2 flex items-center text-black">PAX/UNITS</div>

                                                  <div className="col-span-1  ml-5 border-r border-slate-200 py-2 flex items-center text-black">NET RATE ($)</div>

                                                  <div className="col-span-1  ml-5 border-r border-slate-200 py-2 flex items-center text-black">ADD</div>
                                                  <div className="col-span-1 ml-5 border-r border-slate-200 py-2"></div>
                                                  <div className="col-span-1 ml-5 border-slate-200 py-2"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* TODO TRANSFERS AND ACTIVITIES*/}
                                      </div>

                                      <div key={uuidv4()}>
                                        <div
                                          className={`bg-white border border-slate-200 brand-text-grey-v2`}
                                          style={{
                                            WebkitTransform: 'translateZ(0)' /* Safari */,
                                          }}
                                        >
                                          {/* ROW 1 (Property info) */}

                                          <div className={`mb-0`}>
                                            <div className={`grid grid-cols-12`}>
                                              {/* IMG */}

                                              {/* MAP OTHER ARRAGEMENTS */}
                                              <div className="col-span-12 bg-white">
                                                {/* Flying Doctors insurance */}
                                                {otherArrangementsData
                                                  .filter((item) => item.type === 'otherArrangementsFlyingDoctor')
                                                  .map((item, index) => {
                                                    return (
                                                      <div className="grid grid-cols-12 border-b border-slate-200">
                                                        <div className="text-xs font-medium truncate text-ellipsis overflow-hidden col-span-2 ml-5 border-r border-slate-200 py-2">
                                                          <div className="flex items-center">
                                                            <span className="">{item.name}</span>
                                                          </div>
                                                        </div>
                                                        <div className="col-span-5 text-xs ml-5 border-r border-slate-200 py-2 pr-5">{item.description}</div>
                                                        <div className="col-span-1 text-xs ml-5 border-r border-slate-200 py-2 pr-5">
                                                          {/* {!item.editing ? ( */}
                                                          {item.units}
                                                        </div>
                                                        <div className="col-span-1 text-xs px-5 border-r border-slate-200 py-2">
                                                          {!item.editing ? (
                                                            '$' + formatPrice(item.unitPrice)
                                                          ) : (
                                                            <input
                                                              type="number"
                                                              min="0"
                                                              defaultValue={item.unitPrice}
                                                              onBlur={(e) => handleOtherArrangementsUnitPriceChange(e.target.value, 'otherArrangementsFlyingDoctor')}
                                                              className="w-full text-xs"
                                                              style={{
                                                                appearance: 'textfield',
                                                              }}
                                                            />
                                                          )}
                                                        </div>
                                                        <div className="col-span-1 text-xs ml-5 border-r border-slate-200 py-2 flex items-center">
                                                          <label htmlFor="add" className="mr-5">
                                                            ADD
                                                          </label>
                                                          <input
                                                            type="checkbox"
                                                            id="addFlyingDOctorInsurance"
                                                            name="addFlyingDOctorInsurance"
                                                            value="addFlyingDOctorInsurance"
                                                            //checked={item.saleTotal > 0 && item.checked}
                                                            checked={item.checked}
                                                            onChange={() => handleOwnArrangementCheckboxChange(item.type, item.units, item.unitPrice, item.checked)}
                                                            className={`form-checkbox border-black text-black bg-white`}
                                                          />
                                                        </div>

                                                        {/* <div className="col-span-1 text-xs ml-5 border-r border-slate-200 py-2">${formatPrice(flyingDoctorInsuranceItem.flyingDoctorInsurance)}</div> */}

                                                        <div className="col-span-1 text-xs ml-5 border-r border-slate-200 py-2">${formatPrice(item.saleTotal)}</div>
                                                        <div className="col-span-1 ml-5 py-2 flex items-center">
                                                          {item.edit && (
                                                            <div className="flex items-center cursor-pointer">
                                                              {!item.editing ? (
                                                                <div className="flex items-center cursor-pointer" onClick={() => handleOtherArrangementsEditSave('otherArrangementsFlyingDoctor')}>
                                                                  <FaEdit size={18} className="text-nomadEvergreen-700" />
                                                                  <span className="ml-2 font-semibold text-xs">Edit</span>
                                                                </div>
                                                              ) : (
                                                                <div className="flex items-center cursor-pointer" onClick={() => handleOtherArrangementsEditSave('otherArrangementsFlyingDoctor')}>
                                                                  <FaSave size={18} className="text-nomadEvergreen-700" />
                                                                  <span className="ml-2 font-semibold text-xs">Save</span>
                                                                </div>
                                                              )}
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  })}

                                                {/* Preferred Guide Supplement */}
                                                {otherArrangementsData
                                                  .filter((item) => item.type === 'otherArrangementsPreferredGuide')
                                                  .map((item, index) => {
                                                    return (
                                                      <div className="grid grid-cols-12 border-b border-slate-200">
                                                        <div className="text-xs font-medium truncate text-ellipsis overflow-hidden col-span-2 ml-5 border-r border-slate-200 py-2">
                                                          <div className="flex items-center">
                                                            <span className="">{item.name}</span>
                                                          </div>
                                                        </div>
                                                        <div className="col-span-5 text-xs ml-5 border-r border-slate-200 py-2 pr-5">
                                                          {!item.editing ? (
                                                            item.description
                                                          ) : (
                                                            <input
                                                              type="text"
                                                              maxLength="255"
                                                              defaultValue={item.description}
                                                              onBlur={(e) => handleOtherArrangementsDescriptionChange(e.target.value, 'otherArrangementsPreferredGuide')}
                                                              className="text-xs w-full"
                                                            />
                                                          )}
                                                        </div>
                                                        <div className="col-span-1 text-xs ml-5 border-r border-slate-200 py-2 pr-5">
                                                          {!item.editing ? (
                                                            item.units
                                                          ) : (
                                                            <input
                                                              type="number"
                                                              min="1"
                                                              defaultValue={item.units}
                                                              onBlur={(e) => handleOtherArrangementsUnitsChange(e.target.value, 'otherArrangementsPreferredGuide')}
                                                              className="w-full text-xs"
                                                            />
                                                          )}
                                                        </div>
                                                        <div className="col-span-1 text-xs px-5 border-r border-slate-200 py-2">
                                                          {!item.editing ? (
                                                            '$' + formatPrice(item.unitPrice)
                                                          ) : (
                                                            <input
                                                              type="number"
                                                              min="0"
                                                              defaultValue={item.unitPrice}
                                                              onBlur={(e) => handleOtherArrangementsUnitPriceChange(e.target.value, 'otherArrangementsPreferredGuide')}
                                                              className="w-full text-xs"
                                                              style={{
                                                                appearance: 'textfield',
                                                              }}
                                                            />
                                                          )}
                                                        </div>
                                                        <div className="col-span-1 text-xs ml-5 border-r border-slate-200 py-2 flex items-center">
                                                          <label htmlFor="add" className="mr-5">
                                                            ADD
                                                          </label>
                                                          <input
                                                            type="checkbox"
                                                            id="addFlyingDOctorInsurance"
                                                            name="addFlyingDOctorInsurance"
                                                            value="addFlyingDOctorInsurance"
                                                            //checked={item.saleTotal > 0 && item.checked}
                                                            checked={item.checked}
                                                            onChange={() => handleOwnArrangementCheckboxChange(item.type, item.units, item.unitPrice, item.checked)}
                                                            className={`form-checkbox border-black text-black bg-white`}
                                                          />
                                                        </div>

                                                        {/* <div className="col-span-1 text-xs ml-5 border-r border-slate-200 py-2">${formatPrice(flyingDoctorInsuranceItem.flyingDoctorInsurance)}</div> */}

                                                        <div className="col-span-1 text-xs ml-5 border-r border-slate-200 py-2">${formatPrice(item.saleTotal)}</div>
                                                        <div className="col-span-1 ml-5 py-2 flex items-center">
                                                          {item.edit && (
                                                            <div className="flex items-center cursor-pointer">
                                                              {!item.editing ? (
                                                                <div className="flex items-center cursor-pointer" onClick={() => handleOtherArrangementsEditSave('otherArrangementsPreferredGuide')}>
                                                                  <FaEdit size={18} className="text-nomadEvergreen-700" />
                                                                  <span className="ml-2 font-semibold text-xs">Edit</span>
                                                                </div>
                                                              ) : (
                                                                <div className="flex items-center cursor-pointer" onClick={() => handleOtherArrangementsEditSave('otherArrangementsPreferredGuide')}>
                                                                  <FaSave size={18} className="text-nomadEvergreen-700" />
                                                                  <span className="ml-2 font-semibold text-xs">Save</span>
                                                                </div>
                                                              )}
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  })}

                                                {/* BOTTOM TOTAL */}
                                                <div className="grid grid-cols-12 border-b border-slate-200">
                                                  <div className="col-span-10 text-xs ml-5 border-r border-slate-200 py-2"></div>
                                                  <div className="col-span-1 text-xs font-semibold ml-5 border-r border-slate-200 py-2">
                                                    ${formatPrice(getTotalFromOtherArrangements(otherArrangementsData))}
                                                  </div>
                                                  <div className="col-span-1 text-xs ml-5 border-slate-200 py-2"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* TODO TRANSFERS AND ACTIVITIES*/}
                                      </div>

                                      {/* bottom */}
                                      <div
                                        className={`bg-white border-b border-r border-l rounded-b-xl py-2 mb-0 drop-shadow-md border-slate-200 h-8`}
                                        style={{
                                          WebkitTransform: 'translateZ(0)' /* Safari */,
                                        }}
                                      ></div>

                                      {/* FINAL: TRANSFERS */}

                                      {/* FINAL: ACTIVITIES */}
                                    </div>

                                    {/* LAYOUT VERSIONS */}
                                    {createEdit === 'edit' && (
                                      <div className={`px-5 pb-5`}>
                                        {/* FINAL: PROPERTIES */}
                                        <div className="bg-white border-t border-r border-l rounded-t-xl drop-shadow-md border-slate-200 grid grid-cols-12 text-sm font-semibold">
                                          <div className="col-span-12 pl-5 border-slate-200 py-2">VERSION CONTROL</div>
                                        </div>

                                        <div key={uuidv4()}>
                                          <div
                                            className={`bg-white border border-slate-200 brand-text-grey-v2`}
                                            style={{
                                              WebkitTransform: 'translateZ(0)' /* Safari */,
                                            }}
                                          >
                                            {/* ROW 1 (Property info) */}

                                            <div className={`mb-0`}>
                                              <div className={`grid grid-cols-12`}>
                                                {/* IMG */}

                                                {/* ROOM NAME + DESCRIPTION */}
                                                <div className="col-span-12 bg-white">
                                                  {/* ITEM 1 */}
                                                  <div className="grid grid-cols-12 border-slate-200 text-xs font-normal">
                                                    <div className="text-xs truncate text-ellipsis overflow-hidden col-span-2 ml-5 border-r border-slate-200 py-2">
                                                      <div className="flex items-center">
                                                        <span className="">Save as new version</span>
                                                      </div>
                                                    </div>
                                                    <div className="col-span-8 ml-5 border-r border-slate-200 py-2">Selecting this option will save the itinerary as a new quote version. </div>

                                                    <div className="col-span-2 border-slate-200 py-2 flex justify-end">
                                                      <div className="flex items-end text-right ml-auto text-xs mr-5">
                                                        NEW VERSION:
                                                        <Switch
                                                          checked={masterVersion || newUserVersion}
                                                          onChange={(eventArgument) => {
                                                            console.log('Switch onChange event argument:', eventArgument); // Log to inspect what is received
                                                            if (!masterVersion) {
                                                              // Assuming 'eventArgument' is the new checked value (true/false). Adjust based on your findings.
                                                              setNewUserVersion(eventArgument === true);
                                                            }
                                                          }}
                                                          className={`ml-2 group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-[#489EF8] focus:ring-offset-2 ${
                                                            masterVersion ? 'cursor-not-allowed' : 'cursor-pointer'
                                                          }`}
                                                          disabled={masterVersion}
                                                        >
                                                          <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-full bg-white" />
                                                          <span
                                                            aria-hidden="true"
                                                            className={`${
                                                              newUserVersion ? 'bg-[#489EF8]' : 'bg-gray-200'
                                                            } pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
                                                          />
                                                          <span
                                                            aria-hidden="true"
                                                            className={`${
                                                              newUserVersion ? 'translate-x-5' : 'translate-x-0'
                                                            } pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
                                                          />
                                                        </Switch>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {/* bottom */}
                                        <div
                                          className={`bg-white border-b border-r border-l rounded-b-xl py-2 mb-0 drop-shadow-md border-slate-200 h-8`}
                                          style={{
                                            WebkitTransform: 'translateZ(0)' /* Safari */,
                                          }}
                                        ></div>
                                      </div>
                                    )}

                                    {/* BUTTONS START */}
                                    {bookings.length > 0 ? (
                                      <div className="mb-0">
                                        <div className="h-44 brand-grey-bg-color-v2 rounded-b-lg border-t">
                                          <div className="">
                                            <div className="mx-5 mt-5 flex">
                                              <div className="flex-1 text-left">
                                                <div className="flex">
                                                  <div className="font-bold">
                                                    <ItineraryNameEditor
                                                      editItineraryName={editItineraryName}
                                                      itineraryName={itineraryName}
                                                      handleTextChange={handleTextChange}
                                                      handleItinerarySaveClick={handleItinerarySaveClick}
                                                      handleEditItineraryClick={handleEditItineraryClick}
                                                    />
                                                  </div>

                                                  <div className="ml-2 brand-text-grey-1-v2">
                                                    {' '}
                                                    |{' '}
                                                    {bookings.reduce((maxGuests, booking) => {
                                                      const { rooms } = booking;
                                                      const totalGuests = rooms.reduce((sum, room) => {
                                                        sum += room.ad + room.ch + room.chi + room.inf;
                                                        return sum;
                                                      }, 0);
                                                      if (totalGuests > maxGuests) {
                                                        maxGuests = totalGuests;
                                                        maxBooking = booking;
                                                      }
                                                      return maxGuests;
                                                    }, 0)}{' '}
                                                    Guest
                                                    {bookings.reduce((maxGuests, booking) => {
                                                      const { rooms } = booking;
                                                      const totalGuests = rooms.reduce((sum, room) => {
                                                        sum += room.ad + room.ch + room.chi + room.inf;
                                                        return sum;
                                                      }, 0);
                                                      if (totalGuests > maxGuests) {
                                                        maxGuests = totalGuests;
                                                        maxBooking = booking;
                                                      }
                                                      return maxGuests;
                                                    }, 0) === 1
                                                      ? ''
                                                      : 's'}
                                                  </div>

                                                  <div className="ml-2 brand-text-grey-1-v2">
                                                    {' '}
                                                    | {moment(bookings[bookings.length - 1].endDay).diff(moment(bookings[0].startDay), 'days')} Night
                                                    {moment(bookings[bookings.length - 1].endDay).diff(moment(bookings[0].startDay), 'days') > 1 ? `s` : ``}
                                                  </div>
                                                </div>
                                              </div>

                                              {/* <div className={`flex-1 text-right`}> </div> */}
                                            </div>

                                            <div className="mx-5 flex brand-text-grey-1-v2">
                                              <div className="flex-1 text-left">
                                                <span>
                                                  {bookings.length > 1
                                                    ? moment(bookings[0].startDay).format('ddd, MMM D, YYYY') + ' - ' + moment(bookings[bookings.length - 1].endDay).format('ddd, MMM D, YYYY')
                                                    : moment(bookings[0].startDay).format('ddd, MMM D, YYYY') + ' - ' + moment(bookings[0].endDay).format('ddd, MMM D, YYYY')}
                                                </span>
                                              </div>

                                              {/* Total Final page */}
                                              {!fetchingRates ? (
                                                <div className={`flex-1 text-right text-black`}>
                                                  Total: $
                                                  {formatPrice(
                                                    getTotalFromBookings(bookings) +
                                                      getTotalFromActivities(activitiesData) +
                                                      getTotalFromTransfers(transfers) +
                                                      getTotalFromBookingsForVehicles(bookings, properties) +
                                                      getTotalFromOtherArrangements(otherArrangementsData) +
                                                      getTotalFromCustomItems(customFinalItems) +
                                                      getTotalFromFinalPageData(finalPageData) +
                                                      getTotalFromFinalPageTransferData(finalPageTransferData),
                                                    // This part calculates the total for propertyItems with edit = true
                                                  )}
                                                </div>
                                              ) : (
                                                <div className={`flex-1 text-right`}>Total: fetching...</div>
                                              )}
                                            </div>
                                            {
                                              //refreshAllRates && bookings.some((booking) => booking.rooms.length !== 0) && (
                                              (refreshBtn || (refreshAllRates && bookings.every((booking) => booking.rooms && !booking.addRoom && booking.rooms.every((room) => !room.edit)))) && (
                                                <div className="flex-1 text-right mr-4 mt-3 cursor-pointer">
                                                  <button
                                                    className="ml-auto bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit animate-pulse"
                                                    onClick={() => {
                                                      // if (
                                                      //   bookings.every(
                                                      //     (booking) =>
                                                      //       booking.rooms && Array.isArray(booking.rooms) && booking.rooms.length > 0 && !booking.addRoom && booking.rooms.every((room) => !room.edit),
                                                      //   )
                                                      // ) {
                                                      //   setRefreshAllRates(false);
                                                      //   setFetchingRates(true);
                                                      //   refreshRates(
                                                      //     bookings,
                                                      //     setBookings,
                                                      //     setRefreshBtn,
                                                      //     properties,
                                                      //     setFetchingRates,
                                                      //     agentRates,
                                                      //     agentCommission,
                                                      //     guests,
                                                      //     agentObject,
                                                      //     createVersionCheck,
                                                      //     createEdit,
                                                      //     user,
                                                      //     isFamTrip,
                                                      //     commissionAgent,
                                                      //     commissionUser,
                                                      //     dateCreated,
                                                      //   );
                                                      // } else {
                                                      //   toast.error('Please complete all rooms before refreshing rates');
                                                      // }
                                                      if (
                                                        bookings.every(
                                                          (booking) =>
                                                            booking.rooms &&
                                                            Array.isArray(booking.rooms) &&
                                                            booking.rooms.length > 0 &&
                                                            !booking.addRoom &&
                                                            booking.rooms.every((room) => !room.edit) &&
                                                            // Add check for exclusive room
                                                            !(booking.rooms.length === 1 && booking.rooms[0].roomType === 'Exclusive'),
                                                        )
                                                      ) {
                                                        setRefreshAllRates(false);
                                                        setFetchingRates(true);
                                                        refreshRates(
                                                          bookings,
                                                          setBookings,
                                                          setRefreshBtn,
                                                          properties,
                                                          setFetchingRates,
                                                          agentRates,
                                                          agentCommission,
                                                          guests,
                                                          agentObject,
                                                          createVersionCheck,
                                                          createEdit,
                                                          user,
                                                          isFamTrip,
                                                          commissionAgent,
                                                          commissionUser,
                                                          dateCreated,
                                                        );
                                                      } else {
                                                        if (bookings.some((booking) => booking.rooms.length === 1 && booking.rooms[0].roomType === 'Exclusive')) {
                                                          toast.error('Add rooms to Exclusive properties');
                                                        } else {
                                                          toast.error('Please complete all rooms before refreshing rates');
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    Click to refresh rates
                                                  </button>
                                                </div>
                                              )
                                            }

                                            {modalOpen && (
                                              <div className="z-20 fixed inset-0">
                                                <CustomModalClient isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                                                  <div className="fixed inset-0 bg-white bg-opacity-90 flex justify-center items-center">
                                                    <div className="fixed top-0 p-5 top-1/2 transform -translate-y-1/2 bg-white rounded-lg" style={deeShadow}>
                                                      <div className="text-xs pb-2">
                                                        <p className="text-sm font-semibold pb-2">Proceed to next Step</p>
                                                        {!user.internalUser && <p className="text-sm pb-2">You’re almost done! Please add the client name or select an existing enquiry to proceed.</p>}
                                                        <p className="text-sm pb-6">On the next page, you can choose to finalise your booking, hold it, or save it as a quote.</p>
                                                        {!user.internalUser && (
                                                          <>
                                                            <p className="text-slate-800 text-sm font-normal mb-1">{existingClient ? 'Enquiry' : 'Client name'}</p>
                                                            {existingClient ? (
                                                              <div className="w-1/2 relative">
                                                                <CustomSelect
                                                                  placeholder={'Select Enquiry'}
                                                                  defaultValue={{ label: 'Select Enquiry', value: 'Select Enquiry' }}
                                                                  onChange={(selectedOption) => {
                                                                    console.log('Selected option:', JSON.stringify(selectedOption, null, 2));
                                                                    setEnquiryUid(selectedOption.enquiry.uid);
                                                                    setSelectedEnquiryTitle(selectedOption.enquiry.title);
                                                                    setClientName(selectedOption.enquiry.clientName);
                                                                    setSelectedConsultantUid(selectedOption.enquiry.agentConsultant.id); // set to uid
                                                                    setConsultantResRequestId('NA');
                                                                    setSelectedConsultantName(selectedOption.enquiry.agentConsultant.name === '' ? null : selectedOption.enquiry.agentConsultant.name);
                                                                    setSelectedConsultant(true);
                                                                  }}
                                                                  options={enquiries.map((enquiry) => ({
                                                                    label: enquiry.title,
                                                                    value: enquiry.uid,
                                                                    enquiry: enquiry,
                                                                  }))}
                                                                  //defaultValue={{ label: timeOwnArrangements, value: timeOwnArrangements }}
                                                                  instanceId="enquiry-select"
                                                                  isSearchable={true}
                                                                  showDropdownIndicator={true}
                                                                  //width={100}
                                                                  menuHeight={120}
                                                                  color1={'#385157'} // Default color1
                                                                  color2={'#E5E7EB'}
                                                                />
                                                              </div>
                                                            ) : (
                                                              <input
                                                                className="w-1/2 rounded-xl text-sm h-[50px] border border-[#E5E7EB] focus:border-2 focus:border-[#6996A0] focus:ring-0 focus:outline-none hover:border-[#999075]"
                                                                type="text"
                                                                placeholder="Client name"
                                                                name="clientName"
                                                                onBlur={handleClientNameInputChange}
                                                                defaultValue={clientName}
                                                              />
                                                            )}

                                                            {selectedAgentUid.length > 0 && (
                                                              <div className="flex mt-1">
                                                                <input
                                                                  type="checkbox"
                                                                  name="existingClient"
                                                                  className="checkbox border-nomadBlue-400 checked:border-nomadBlue-400 [--chkbg:#6996A0] [--chkfg:white]"
                                                                  checked={existingClient}
                                                                  onChange={(e) => {
                                                                    if (existingClient) {
                                                                      onResetEnquiries();
                                                                    }
                                                                    let isChecked = e.target.checked;
                                                                    setExistingClient(isChecked);
                                                                    setClientName('');
                                                                  }}
                                                                />
                                                                <span className="ml-2 text-neutral-500 text-sm font-normal">Existing client</span>
                                                              </div>
                                                            )}
                                                            {agentObject.accessLevel === 'limited' && (
                                                              <>
                                                                <p className="text-slate-800 text-sm font-normal mt-5 mb-1">Originating Agent</p>
                                                                <input
                                                                  className="w-1/2 rounded-xl text-sm h-[50px] border border-[#E5E7EB] focus:border-2 focus:border-[#6996A0] focus:ring-0 focus:outline-none hover:border-[#999075]"
                                                                  type="text"
                                                                  placeholder="Originating Agent"
                                                                  name="originatingAgent"
                                                                  value={originatingAgent}
                                                                  onChange={(e) => setOriginatingAgent(e.target.value)}
                                                                />
                                                              </>
                                                            )}
                                                          </>
                                                        )}

                                                        <div className="flex mt-5 justify-end">
                                                          <div className="flex justify-end gap-4">
                                                            <ButtonOutlineSmall
                                                              onClick={() => {
                                                                if (!user.internalUser) {
                                                                  setEnquiryUid(null);
                                                                  setSelectedEnquiryTitle(false);
                                                                  setClientName('');
                                                                  setSelectedConsultantUid(false); // set to uid
                                                                  setConsultantResRequestId(false);
                                                                  setSelectedConsultantName('Select an enquiry');
                                                                  setSelectedConsultant(false);
                                                                  setExistingClient(false);
                                                                }
                                                                setModalOpen(false);
                                                              }}
                                                              text="Cancel"
                                                            />
                                                            <ButtonPrimarySmall
                                                              onClick={async () => {
                                                                if (!selectedConsultantName || !consultantResRequestId || clientName === '') {
                                                                  return toast.error(`Enter a 'Client Name' or select an 'Enquiry'`);
                                                                }
                                                                //  else if (agentObject.accessLevel === 'limited' && originatingAgent === '') {
                                                                //   return toast.error(`Enter an 'Originating Agent'`);
                                                                // }
                                                                setModalOpen(false);
                                                                //alert('saveDataToDatabase');
                                                                await saveDataToDatabase();
                                                              }}
                                                              text="Continue"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </CustomModalClient>
                                              </div>
                                            )}

                                            {!fetchingRates && !refreshAllRates && !rooms.some((room) => room.loading) && (
                                              <div className="flex justify-end gap-4 mt-8 mr-4">
                                                <button
                                                  className="bg-white text-nomadEvergreen-700 border border-gray-500 text-xs font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:text-nomadEvergreen-800 hover:border-nomadGray-900 flex items-center justify-center"
                                                  onClick={async () => {
                                                    setStep('activities');
                                                  }}
                                                >
                                                  Back
                                                </button>
                                                <button
                                                  className="bg-nomadEvergreen-700 text-white text-sm font-semibold px-6 py-3 rounded-lg shadow transition duration-300 ease-in-out hover:bg-nomadEvergreen-800 hover:text-white"
                                                  onClick={async () => {
                                                    const hasIncompleteGuideNames = otherArrangementsData.some((item) => {
                                                      return item.type === 'otherArrangementsPreferredGuide' && item.description === 'Add guide name(s)' && item.units !== 0 && item.saleTotal !== 0;
                                                    });

                                                    if (hasIncompleteGuideNames) {
                                                      return toast.error("Please add guide name(s) for 'Preferred Guide Supplement'");
                                                    }
                                                    for (let pageData of finalPageData) {
                                                      if (pageData.propertyItems && pageData.propertyItems.length > 0) {
                                                        for (let item of pageData.propertyItems) {
                                                          if (!item.supplier || item.supplier === '' || item.supplier.length < 1) {
                                                            return toast.error(`You must enter a 'Supplier' for each item in the 'PROPERTIES' section`);
                                                          }
                                                        }
                                                      }
                                                    }
                                                    // if (clientName === '') {
                                                    //   return toast.error(`Please enter a client name`);
                                                    // }

                                                    if (checkUnallocatedItems(customFinalItems)) {
                                                      return toast.error("Move or delete items from 'Unallocated items' before proceeding.");
                                                    }

                                                    // Check for pricing not zero
                                                    // if (finalPageData.some((item) => item.propertyItems.length === 0)) {
                                                    //   return toast.error('Pricing must be added to every property');
                                                    // }

                                                    // onlyProperties
                                                    // 	? // Go to confirmation pate
                                                    let title;
                                                    let p1;
                                                    let p2;
                                                    let cancelBtn;
                                                    let proceedBtn;
                                                    if (createEdit === 'edit') {
                                                      if (itineraryStore?.status === 'draft' || itineraryStore?.status === 'quotation' || itineraryStore?.status === 'expired') {
                                                        title = 'Would you like to proceed to the Confirmation Page?';
                                                        p1 = 'Please note, you will be unable to return to this screen.';
                                                        p2 = 'Please complete the confirmation within 10 minutes.';
                                                        cancelBtn = 'Cancel';
                                                        proceedBtn = 'Proceed';
                                                      } else {
                                                        title = 'Would you like to save your updates to this itinerary?';
                                                        p1 = 'Please note, the status of your itinerary will not change.';
                                                        p2 = `Current status: ${status}`;
                                                        cancelBtn = 'Back';
                                                        proceedBtn = 'Save';
                                                      }
                                                    } else {
                                                      title = 'Would you like to proceed to the Confirmation Page?';
                                                      p1 = 'Please note, you will be unable to return to this screen.';
                                                      p2 = 'Please complete the confirmation within 10 minutes.';
                                                      cancelBtn = 'Cancel';
                                                      proceedBtn = 'Proceed';
                                                    }

                                                    // confirmAlert({
                                                    //   customUI: ({ onClose }) => {
                                                    //     return (
                                                    //       <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                                                    //         <p className="text-sm font-semibold pb-2">{title}</p>
                                                    //         <p className="text-sm pb-2">{p1}</p>
                                                    //         {p2 !== '' && <p className="text-sm pb-2">{p2}</p>}
                                                    //         <div className="flex mt-5 justify-end">
                                                    //           <div>
                                                    //             <button
                                                    //               className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                                                    //               onClick={() => {
                                                    //                 setRefreshBtn(false);
                                                    //                 onClose();
                                                    //               }}
                                                    //             >
                                                    //               {cancelBtn}
                                                    //             </button>
                                                    //             <button
                                                    //               className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                                                    //               onClick={async () => {
                                                    //                 console.log(refreshBtn);
                                                    //                 if (fetchingRates) {
                                                    //                   return toast.error(`Rates are still being fetched, please wait a few seconds then click "Confirm" again`);
                                                    //                 }

                                                    //                 // alert(
                                                    //                 // 	"No not submit to database yet!"
                                                    //                 // );

                                                    //                 if (createEdit === 'create') {
                                                    //                   if (user.internalUser) {
                                                    //                     await saveDataToDatabase();
                                                    //                   } else {
                                                    //                     setEnquiryUid(null);
                                                    //                     setSelectedEnquiryTitle(false);
                                                    //                     setClientName('');
                                                    //                     setSelectedConsultantUid(false); // set to uid
                                                    //                     setConsultantResRequestId(false);
                                                    //                     setSelectedConsultantName('Select an enquiry');
                                                    //                     setSelectedConsultant(false);
                                                    //                     setExistingClient(false);
                                                    //                     setModalOpen(true);
                                                    //                     //await saveDataToDatabase();
                                                    //                   }
                                                    //                 } else {
                                                    //                   await saveDataToDatabase();
                                                    //                 }

                                                    //                 onClose();
                                                    //               }}
                                                    //             >
                                                    //               {proceedBtn}
                                                    //             </button>
                                                    //           </div>
                                                    //         </div>
                                                    //       </div>
                                                    //     );
                                                    //   },
                                                    // });

                                                    if (createEdit === 'create') {
                                                      if (user.internalUser) {
                                                        setModalOpen(true);
                                                      } else {
                                                        setEnquiryUid(null);
                                                        setSelectedEnquiryTitle(false);
                                                        setClientName('');
                                                        setSelectedConsultantUid(false); // set to uid
                                                        setConsultantResRequestId(false);
                                                        setSelectedConsultantName('Select an enquiry');
                                                        setSelectedConsultant(false);
                                                        setExistingClient(false);
                                                        setModalOpen(true);
                                                        //await saveDataToDatabase();
                                                      }
                                                    } else {
                                                      let latestHiddenValue = true;

                                                      let latestReprintInvoiceValue = true;
                                                      if (user.internalUser && (status === 'confirmed' || status === 'cancelled')) {
                                                        latestReprintInvoiceValue = true;
                                                      } else {
                                                        latestReprintInvoiceValue = false;
                                                      }

                                                      if (!user.internalUser) {
                                                        latestHiddenValue = false;
                                                      }

                                                      confirmAlert({
                                                        customUI: ({ onClose }) => {
                                                          return (
                                                            <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                                                              <p className="text-sm font-semibold pb-2">{title}</p>
                                                              <p className="text-sm pb-2">{p1}</p>
                                                              {p2 !== '' && <p className="text-sm pb-2">{p2}</p>}

                                                              {user.internalUser && (
                                                                <div className="flex items-center mt-5">
                                                                  <input
                                                                    type="checkbox"
                                                                    id="hidden"
                                                                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                                                    defaultChecked={hidden} // Use defaultChecked
                                                                    //onChange={(e) => setHidden(e.target.checked)} // Update parent state
                                                                    onChange={(e) => {
                                                                      setHidden(e.target.checked);
                                                                      // Update the variable immediately
                                                                      latestHiddenValue = e.target.checked;
                                                                    }}
                                                                  />
                                                                  <label htmlFor="hidden" className="ml-2 text-sm font-medium text-gray-900">
                                                                    Set Itinerary to hidden
                                                                  </label>
                                                                </div>
                                                              )}

                                                              {user.internalUser && (status === 'confirmed' || status === 'cancelled') && (
                                                                <div className="flex items-center mt-5">
                                                                  <input
                                                                    type="checkbox"
                                                                    id="reprintInvoice"
                                                                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                                                    defaultChecked={reprintInvoice} // Use defaultChecked
                                                                    onChange={(e) => {
                                                                      setReprintInvoice(e.target.checked);
                                                                      // Update the variable immediately
                                                                      latestReprintInvoiceValue = e.target.checked;
                                                                    }}
                                                                  />
                                                                  <label htmlFor="reprintInvoice" className="ml-2 text-sm font-medium text-gray-900">
                                                                    Reprint Sales Invoice
                                                                  </label>
                                                                </div>
                                                              )}

                                                              <div className="flex mt-5 justify-end">
                                                                <ButtonOutlineSmall
                                                                  className="ml-auto"
                                                                  onClick={() => {
                                                                    setRefreshBtn(false);
                                                                    onClose();
                                                                  }}
                                                                  text={cancelBtn}
                                                                />

                                                                <ButtonPrimarySmall
                                                                  className="ml-5"
                                                                  onClick={async () => {
                                                                    console.log(refreshBtn);
                                                                    if (fetchingRates) {
                                                                      return toast.error(`Rates are still being fetched, please wait a few seconds then click "Confirm" again`);
                                                                    }

                                                                    // alert(
                                                                    // 	"No not submit to database yet!"
                                                                    // );

                                                                    // if (createEdit === 'create') {
                                                                    //   if (user.internalUser) {
                                                                    //     await saveDataToDatabase();
                                                                    //   } else {
                                                                    //     setEnquiryUid(null);
                                                                    //     setSelectedEnquiryTitle(false);
                                                                    //     setClientName('');
                                                                    //     setSelectedConsultantUid(false); // set to uid
                                                                    //     setConsultantResRequestId(false);
                                                                    //     setSelectedConsultantName('Select an enquiry');
                                                                    //     setSelectedConsultant(false);
                                                                    //     setExistingClient(false);
                                                                    //     setModalOpen(true);
                                                                    //     //await saveDataToDatabase();
                                                                    //   }
                                                                    // } else {
                                                                    //   await saveDataToDatabase();
                                                                    // }
                                                                    await saveDataToDatabase(latestHiddenValue, latestReprintInvoiceValue);
                                                                    onClose();
                                                                  }}
                                                                  text={proceedBtn}
                                                                />
                                                              </div>
                                                            </div>
                                                          );
                                                        },
                                                      });

                                                      //await saveDataToDatabase();
                                                    }
                                                  }}
                                                >
                                                  Next: Save or Book
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                    {/* BUTTONS END */}
                                  </div>
                                </div>
                                {/* //! SECTION 2: END ---------------------------------------------------------------------------------- */}
                              </div>
                              {/* LEFT COLUMN FOR PROPERTIES END */}
                            </div>

                            {/* RIGHT COLUMN END */}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="mt-5">
                    {user.email === 'harry.prowse@nomad-tanzania.net' && (
                      <div className="flex text-[10px] w-full">
                        <button
                          onClick={async () => {
                            const jsonObj = await createJsonObject(
                              properties,
                              bookings,
                              transfers,
                              activitiesData,
                              airports,
                              finalPageData,
                              otherArrangementsData,
                              finalPageTransferData,
                              suppliers,
                              customLocations,
                              customFinalItems,
                            );
                            console.log(JSON.stringify(jsonObj, undefined, 4));
                          }}
                        >
                          createJsonObject
                        </button>
                        {/* <div className="w-4"></div>
                      <button
                        onClick={async () => {
                          console.log(agentCommission);
                        }}
                      >
                        agentCommission
                      </button>
                      <div className="w-4"></div>
                      <button
                        onClick={() => {
                          // Total nights
                          const start = moment(dateStart, 'YY-MM-DD');
                          const end = moment(dateEnd, 'YY-MM-DD');
                          const totalNights = end.diff(start, 'days');
                          console.log('totalNights: ' + totalNights);
                        }}
                      >
                        total nights
                      </button> */}

                        <div className="w-4"></div>
                        <button
                          onClick={() => {
                            console.log('itineraries');
                            console.log(JSON.stringify(bookings, undefined, 4));
                          }}
                        >
                          (bookings);
                        </button>
                        <div className="w-4"></div>
                        <button
                          onClick={() => {
                            console.log('transfers');
                            console.log(JSON.stringify(transfers, undefined, 4));
                          }}
                        >
                          (transfers);
                        </button>
                        <div className="w-4"></div>
                        <button
                          onClick={() => {
                            console.log(JSON.stringify(customFinalItems, undefined, 4));
                          }}
                        >
                          customFinalItems{' '}
                        </button>
                        <div className="w-4"></div>
                        <button
                          onClick={() => {
                            setCustomFinalItems([]);
                          }}
                        >
                          clear customFinalItems{' '}
                        </button>

                        <div className="w-4"></div>
                        <button
                          onClick={() => {
                            console.log(JSON.stringify(guests, undefined, 4));
                          }}
                        >
                          guests{' '}
                        </button>

                        <div className="w-4"></div>
                        <button
                          onClick={() => {
                            console.log(JSON.stringify(guestsStore, undefined, 4));
                          }}
                        >
                          guestsStore{' '}
                        </button>
                      </div>
                    )}

                    {user.email === 'prideaux@gmail.com' || user.email === 'roger.prideaux@gmail.com' ? (
                      <div className="">
                        <div className="flex text-[10px] w-full">
                          {/* <div className="w-4"></div>

                          <button
                            onClick={() => {
                              reCountBookings(bookings, setBookings, realDate, rooms, setRooms, properties, setRefreshRatesEffect, true);
                            }}
                          >
                            reCountBookings()
                          </button> */}
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log('PROPERTIES');
                              console.log(JSON.stringify(properties, undefined, 4));
                            }}
                          >
                            (properties);
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log('agentRates');
                              console.log(JSON.stringify(agentRates, undefined, 4));
                            }}
                          >
                            (agentRates);
                          </button>

                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log('agentObject');
                              console.log(JSON.stringify(agentObject, undefined, 4));
                            }}
                          >
                            (agentObject);
                          </button>

                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log('itineraries');
                              console.log(JSON.stringify(bookings, undefined, 4));
                            }}
                          >
                            (bookings);
                          </button>

                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log('ROOMS');
                              console.log(JSON.stringify(rooms, undefined, 4));
                            }}
                          >
                            (rooms);
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(activities, undefined, 4));
                            }}
                          >
                            activities;
                          </button>

                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(activitiesList, undefined, 4));
                            }}
                          >
                            activitiesList;
                          </button>

                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(activitiesData, undefined, 4));
                            }}
                          >
                            activitiesData;
                          </button>

                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(activitiesCost, undefined, 4));
                            }}
                          >
                            activitiesCost;
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(finalPageTransferData, undefined, 4));
                            }}
                          >
                            finalPageTransferData{' '}
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(otherArrangementsData, undefined, 4));
                            }}
                          >
                            otherArrangementsData{' '}
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(roomPax, undefined, 4));
                            }}
                          >
                            roomPax{' '}
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(selectedAges, undefined, 4));
                            }}
                          >
                            selectedAges{' '}
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(selectedAgesAdults, undefined, 4));
                            }}
                          >
                            selectedAgesAdults{' '}
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(guests, undefined, 4));
                            }}
                          >
                            guests{' '}
                          </button>

                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(airports, undefined, 4));
                            }}
                          >
                            airports{' '}
                          </button>
                        </div>
                        <div className="flex text-[10px] w-full mb-20">
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(transfers, undefined, 4));
                            }}
                          >
                            transfers;
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(activitiesDataStore, undefined, 4));
                            }}
                          >
                            transfersDataStore;
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(transfersLatest, undefined, 4));
                            }}
                          >
                            transfersLatest;
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(customLocations, undefined, 4));
                            }}
                          >
                            customLocations
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(bookingsStore, undefined, 4));
                            }}
                          >
                            bookingsStore
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(activitiesDataLatest, undefined, 4));
                            }}
                          >
                            setActivitiesDataLatest
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(activitiesDataRemoved, undefined, 4));
                            }}
                          >
                            activitiesDataRemoved
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(finalPageData, undefined, 4));
                            }}
                          >
                            finalPageData
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={async () => {
                              const jsonObj = await createJsonObject(
                                properties,
                                bookings,
                                transfers,
                                activitiesData,
                                airports,
                                finalPageData,
                                otherArrangementsData,
                                finalPageTransferData,
                                suppliers,
                                customLocations,
                                customFinalItems,
                              );
                              console.log(JSON.stringify(jsonObj, undefined, 4));
                            }}
                          >
                            createJsonObject
                          </button>

                          {/* <div className="w-4"></div>
                          <button
                            onClick={async () => {
                              //const jsonObj = await createJsonObject(properties, bookings, transfers, activitiesData, airports, finalPageData);
                              console.log(JSON.stringify(otherArrangementsData, undefined, 4));
                            }}
                          >
                            otherArrangementsData
                          </button> */}

                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(customFinalItems, undefined, 4));
                            }}
                          >
                            customFinalItems{' '}
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              setCustomFinalItems([]);
                            }}
                          >
                            clear customFinalItems{' '}
                          </button>

                          {/* <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(guestsStore, undefined, 4));
                            }}
                          >
                            guestsStore{' '}
                          </button>
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(quoteMode, undefined, 4));
                            }}
                          >
                            quoteMode{' '}
                          </button> */}
                          <div className="w-4"></div>
                          <button
                            onClick={() => {
                              console.log(JSON.stringify(advancedMode, undefined, 4));
                            }}
                          >
                            advancedMode{' '}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </main>
            </div>
          </div>
        </InstantSearch>
      </div>
    );
  } else {
    // Return a spinner if companies has not loaded yet
    return (
      <div>
        <div className="flex h-screen overflow-hidden">
          <CircleSpinnerOverlay loading={loadingData} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />

          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        </div>
      </div>
    );
  }
}

export default EditItinerary;
