import React from "react";
import { IoStar, IoStarHalf, IoStarOutline } from "react-icons/io5";
import { format } from "date-fns";
import ReadMoreButton from "./ReadMoreButton";

function ReviewCard({ title, text, name, date, rating }) {
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<IoStar key={i} className="text-[#FFD700]" size={16} />);
      } else if (i === Math.floor(rating) && rating % 1 >= 0.5) {
        stars.push(<IoStarHalf key={i} className="text-[#FFD700]" size={16} />);
      } else {
        stars.push(<IoStarOutline key={i} className="text-[#FFD700]" size={16} />);
      }
    }
    return stars;
  };

  return (
    <div className="bg-white rounded-lg border border-[#f0ece7] p-6 font-['Open Sans']">
      <div className="flex justify-between items-center mb-2">
        <span className="font-semibold text-sm text-gray-600">{name}</span>
        <span className="text-sm text-gray-500">{format(new Date(date), "d MMM yyyy")}</span>
      </div>
      <div className="flex items-center mb-2">{renderStars(rating)}</div>
      <h3 className="text-base font-bold text-gray-600 mb-2">{title}</h3>
      <ReadMoreButton text={text} maxRows={3} buttonText="Read more" />
    </div>
  );
}

export default ReviewCard;