import React from 'react';

function InfoBox({ 
  icon: Icon, 
  text, 
  bgColor = 'bg-blue-50', 
  textColor = 'text-nomadEvergreen-700', 
  fontSize = 'text-sm' 
}) {
  return (
    <div className={`flex items-center ${bgColor} ${textColor} mb-2 mt-4 p-2 px-3 rounded-lg`}>
      {Icon && <Icon className="flex-shrink-0 w-5 h-5 mr-3" />}
      <div className={`${fontSize} font-medium`}>{text}</div>
    </div>
  );
}

export default InfoBox;